import React from 'react';
import { BenefitCardAction } from '@leagueplatform/wallet-api';
import { useActionLink } from 'hooks/use-action-link.hook';

interface ActionLinkProps {
  action: BenefitCardAction;
}

export const ActionLink = ({ action }: ActionLinkProps) => {
  const { LinkComponent, handleActionClick } = useActionLink(action);
  const { icon_url: icon, nav_icon: secondaryIcon } = action;

  if (!LinkComponent) {
    return null;
  }

  return (
    <LinkComponent
      primaryIcon={icon}
      secondaryIcon={secondaryIcon}
      action={action}
      onClickCallback={handleActionClick}
    />
  );
};
