import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { TLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { ConfirmationModal } from 'components/confirmation-modal/confirmation-modal.component';
import { DangerButton } from '@leagueplatform/genesis-commons';
import { useParams } from '@leagueplatform/routing';

export interface LeaveTeamModalProps {
  teamName: string;
  isSettingsDropdownOpen: boolean;
  setIsSettingsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDismiss: () => void;
  leaveChallengeTeam: () => void;
  isLoading: boolean;
}

export const LeaveTeamModal = ({
  teamName,
  isSettingsDropdownOpen,
  setIsSettingsDropdownOpen,
  onDismiss,
  leaveChallengeTeam,
  isLoading,
}: LeaveTeamModalProps) => {
  const { formatMessage } = useIntl();
  const { teamId } = useParams<{ teamId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = () =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.LEAVE_TEAM,
      detail: TLP_DETAIL.LEAVE_TEAM,
      team_name: teamName,
      team_id: teamId,
    });
  const onClick = () => {
    leaveChallengeTeam();
    setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
    handleAnalyticsClick();
  };

  return (
    <ConfirmationModal
      heading={formatMessage({ id: 'LEAVE_TEAM_QUESTION' }, { teamName })}
      description={formatMessage(
        { id: 'LEAVE_TEAM_DESCRIPTION' },
        { teamName },
      )}
      isSettingsDropdownOpen={isSettingsDropdownOpen}
      onDismiss={onDismiss}
      onMountAnalytics={() =>
        trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
          screen_name: TLP_SCREEN_NAMES.LEAVE_TEAM,
          team_name: teamName,
          team_id: teamId,
        })
      }
    >
      <DangerButton onClick={onClick} isLoading={isLoading}>
        {formatMessage({ id: 'LEAVE' })}
      </DangerButton>
    </ConfirmationModal>
  );
};
