import React from 'react';
import {
  Flex,
  HeadingOne,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { PageHeaderBackground } from '@leagueplatform/web-common-components';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';

export interface WelcomeBannerProps {
  heading: string;
  subheading?: string;
}
export type WelcomeBannerWSNode = MasonryEngineNode<
  WidgetType.WELCOME_BANNER,
  WelcomeBannerProps
>;
export const WelcomeBanner = ({ heading, subheading }: WelcomeBannerProps) => (
  <PageHeaderBackground>
    <Flex align="center" width="100%" mt={0}>
      <Flex flexDirection="column">
        <HeadingOne textAlign="left" marginTop="oneAndHalf">
          {heading}
        </HeadingOne>
        {subheading && (
          <BodyOneSecondary marginTop="threeQuarters">
            {subheading}
          </BodyOneSecondary>
        )}
      </Flex>
    </Flex>
  </PageHeaderBackground>
);
