import React from 'react';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import {
  JOURNEY_ROUTES,
  ProgramBanner,
  ProgramLayout,
  ProgramRichText,
  ProgramTabs,
} from '@leagueplatform/health-journey-common';
import { useRewardProgramLandingPage } from 'hooks/use-reward-program-landing-page.hook';
import { Box, Responsive } from '@leagueplatform/genesis-core';
import { RewardProgramOverviewTab } from 'components/reward-program-overview-tab/reward-program-overview-tab.component';
import { useIntl } from '@leagueplatform/locales';
import { RewardProgramProgressTab } from 'components/reward-program-progress-tab/reward-program-progress-tab.component';
import { RewardProgramSideBar } from 'components/reward-program-sidebar/reward-program-sidebar.component';
import { RewardProgramHeader } from 'components/reward-program-header/reward-program-header.component';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  ANALYTICS_FIELDS,
  ANALYTICS_VALUES,
  REWARDS_PROGRAM_TABS,
} from 'constants/analytics.constants';
import { PROGRAM_TABS } from 'constants/program-tabs.constants';
import { useGetCurrentTab } from 'hooks/use-get-current-tab.hook';
import { useHistory } from '@leagueplatform/routing';

export const RewardProgramLandingPage = () => {
  const { isLoading, rewardProgram, isError } = useRewardProgramLandingPage();

  const { formatMessage } = useIntl();

  const currentTab = useGetCurrentTab(rewardProgram);

  const history = useHistory();

  if (isError) {
    return <FullPageError route={JOURNEY_ROUTES.journeyHome()} />;
  }

  if (isLoading || !rewardProgram) {
    return <LoadingIndicator />;
  }

  const { campaign } = rewardProgram;

  const endedComponentAttributes =
    rewardProgram.endedComponentAttributes?.componentAttributes;

  const showVisibilityBanner =
    new Date(rewardProgram?.visibilityEndDate) > new Date() &&
    new Date(rewardProgram?.campaign.data.endDate) < new Date() &&
    endedComponentAttributes;

  return (
    <>
      <ProgramLayout
        header={<RewardProgramHeader rewardProgram={rewardProgram} />}
        content={
          <ProgramTabs
            currentTab={currentTab}
            tabs={[
              {
                tabContent: (
                  <RewardProgramOverviewTab
                    campaignId={rewardProgram.id}
                    campaign={campaign}
                    status={rewardProgram.status}
                  />
                ),
                tabName: formatMessage({ id: 'OVERVIEW' }),
                tabKey: PROGRAM_TABS.OVERVIEW,
                onClick: () => {
                  history.push({ search: `?page=${PROGRAM_TABS.OVERVIEW}` });
                  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                    [ANALYTICS_FIELDS.PRODUCT_AREA]:
                      ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
                    [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
                      ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
                    [ANALYTICS_FIELDS.SCREEN_NAME]:
                      ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL,
                    [ANALYTICS_FIELDS.DETAIL]:
                      ANALYTICS_VALUES.DETAIL_NAVIGATION_TAB_CLICKED,
                    [ANALYTICS_FIELDS.SUB_DETAIL]:
                      REWARDS_PROGRAM_TABS.OVERVIEW,
                    [ANALYTICS_FIELDS.CAMPAIGN_NAME]: campaign.data.name,
                    [ANALYTICS_FIELDS.CAMPAIGN_ID]: rewardProgram.campaignId,
                    [ANALYTICS_FIELDS.CAMPAIGN_TYPE]: campaign.data.type,
                    [ANALYTICS_FIELDS.CAMPAIGN_STATUS]: rewardProgram.status,
                  });
                },
              },
              {
                tabContent: (
                  <RewardProgramProgressTab rewardProgram={rewardProgram} />
                ),
                tabName: formatMessage({ id: 'PROGRESS' }),
                tabKey: PROGRAM_TABS.PROGRESS,
                onClick: () => {
                  history.push({ search: `?page=${PROGRAM_TABS.PROGRESS}` });
                  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                    [ANALYTICS_FIELDS.PRODUCT_AREA]:
                      ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
                    [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
                      ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
                    [ANALYTICS_FIELDS.SCREEN_NAME]:
                      ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL,
                    [ANALYTICS_FIELDS.DETAIL]:
                      ANALYTICS_VALUES.DETAIL_NAVIGATION_TAB_CLICKED,
                    [ANALYTICS_FIELDS.SUB_DETAIL]:
                      REWARDS_PROGRAM_TABS.PROGRESS,
                    [ANALYTICS_FIELDS.CAMPAIGN_NAME]: campaign.data.name,
                    [ANALYTICS_FIELDS.CAMPAIGN_ID]: rewardProgram.campaignId,
                    [ANALYTICS_FIELDS.CAMPAIGN_TYPE]: campaign.data.type,
                    [ANALYTICS_FIELDS.CAMPAIGN_STATUS]: rewardProgram.status,
                  });
                },
              },
            ]}
          />
        }
        sidebar={<RewardProgramSideBar rewardProgram={rewardProgram} />}
      />
      {showVisibilityBanner && (
        <Responsive down="laptop">
          <Box
            css={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              padding: '$one',
              backgroundColor: '$warningBackgroundSubdued',
              zIndex: 1,
            }}
          >
            <ProgramBanner
              boldedText={endedComponentAttributes.title}
              basicText={
                <ProgramRichText richText={endedComponentAttributes.richText} />
              }
              icon={endedComponentAttributes.iconUrl}
            />
          </Box>
        </Responsive>
      )}
    </>
  );
};
