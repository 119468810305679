import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { USER_CONNECTED_DEVICE_CAMPAIGN_MODES } from '@leagueplatform/health-journey-api';
import { QuietButton, PrimaryButton } from '@leagueplatform/genesis-commons';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { VERIFIABLE_AUTOMATIC_ONLY } from '@leagueplatform/health-journey-common';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { ConnectDevicesModal } from './connect-devices-modal.component';

export interface DeviceTrackingModalProps {
  onClose: () => void;
  userHasSufficientWearablePermissions: boolean;
  programDataPoints: string[];
  programAddHandler: (campaignMode: string) => void;
  setDisplayUnavailableOnWebModal: (shouldShowModal: boolean) => void;
}

export const DeviceTrackingModal = ({
  onClose,
  userHasSufficientWearablePermissions,
  programDataPoints,
  programAddHandler,
  setDisplayUnavailableOnWebModal,
}: DeviceTrackingModalProps) => {
  const { data: manualProgramTrackingDisabled } = useFeatureFlagQuery(
    VERIFIABLE_AUTOMATIC_ONLY,
  );

  const { formatMessage, $tmap, formatList } = useIntl();

  // Static Assets
  const connectedDeviceImage = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.CONNECT_DEVICES,
  ) as string;

  const getDataPointMessage = $tmap({
    distance: 'INSIGHT_CARD_HEADER_DISTANCE',
    steps: 'INSIGHT_CARD_HEADER_STEPS',
    active_duration: 'INSIGHT_CARD_HEADER_ACTIVE_DURATION',
    mindful_duration: 'INSIGHT_CARD_HEADER_MINDFUL_DURATION',
    floors_climbed: 'INSIGHT_CARD_HEADER_FLOORS_CLIMBED',
    energy_burned: 'INSIGHT_CARD_HEADER_ENERGY_BURNED',
  });

  return (
    <ConnectDevicesModal
      img={connectedDeviceImage}
      altTxt={formatMessage({ id: 'CONNECTED_DEVICES_IMAGE_ALT' })}
      heading={
        userHasSufficientWearablePermissions
          ? formatMessage({
              id: 'DEVICES_CONNECTED_TITLE',
            })
          : formatMessage({
              id: 'TRACK_AUTOMATICALLY',
            })
      }
      body={
        userHasSufficientWearablePermissions
          ? formatMessage(
              {
                id: 'DEVICES_CONNECTED_DESCRIPTION',
              },
              {
                dataPoint: formatList(
                  programDataPoints.map((dataPoint) =>
                    getDataPointMessage(dataPoint as any),
                  ),
                ),
              },
            )
          : formatMessage({
              id: 'CONNECT_TO_TRACK',
            })
      }
      onClose={onClose}
    >
      {userHasSufficientWearablePermissions ? (
        <PrimaryButton
          onClick={() =>
            programAddHandler(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.AUTOMATIC)
          }
        >
          {formatMessage({ id: 'TRACK_CHALLENGE_AUTOMATICALLY' })}
        </PrimaryButton>
      ) : (
        <PrimaryButton onClick={() => setDisplayUnavailableOnWebModal(true)}>
          {formatMessage({ id: 'CONNECT_APPS_DEVICES' })}
        </PrimaryButton>
      )}
      {!manualProgramTrackingDisabled && (
        <QuietButton
          onClick={() =>
            programAddHandler(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.MANUAL)
          }
          marginTop="half"
        >
          {formatMessage({ id: 'DONT_CONNECT_APPS_DEVICES' })}
        </QuietButton>
      )}
    </ConnectDevicesModal>
  );
};
