import { GLOBAL_TOKENS } from '../theme/globalTokens';
import { GenesisTheme, ClientTheme } from '../theme/types';

export const themeWithGlobalTokens = ({
  radii,
  ...theme
}: ClientTheme): GenesisTheme => {
  const completeTheme = {
    ...theme,
    ...GLOBAL_TOKENS,
    radii: {
      ...GLOBAL_TOKENS.radii,
      ...radii,
    },
  };

  return completeTheme;
};
