{
  "dragDescriptionKeyboard": "הקש על Enter כדי להתחיל לגרור.",
  "dragDescriptionKeyboardAlt": "הקש Alt + Enter כדי להתחיל לגרור.",
  "dragDescriptionLongPress": "לחץ לחיצה ארוכה כדי להתחיל לגרור.",
  "dragDescriptionTouch": "הקש פעמיים כדי להתחיל בגרירה.",
  "dragDescriptionVirtual": "לחץ כדי להתחיל לגרור.",
  "dragItem": "גרור את {itemText}",
  "dragSelectedItems": "גרור {count, plural, one {פריט נבחר #} other {# פריטים שנבחרו}}",
  "dragSelectedKeyboard": "הקש על Enter כדי לגרור {count, plural, one {# פריט שנבחר} other {# פריטים שנבחרו}}.",
  "dragSelectedKeyboardAlt": "הקש Alt + Enter כדי לגרור {count, plural, one {# פריט שנבחר} other {# פריטים שנבחרו}}.",
  "dragSelectedLongPress": "לחץ לחיצה ארוכה כדי לגרור {count, plural, one {# פריט שנבחר} other {# פריטים שנבחרו}}.",
  "dragStartedKeyboard": "התחלת לגרור. הקש על Tab כדי לנווט לנקודת הגרירה ולאחר מכן הקש על Enter כדי לשחרר או על Escape כדי לבטל.",
  "dragStartedTouch": "התחלת לגרור. נווט לנקודת השחרור ולאחר מכן הקש פעמיים כדי לשחרר.",
  "dragStartedVirtual": "התחלת לגרור. נווט לנקודת השחרור ולאחר מכן לחץ או הקש על Enter כדי לשחרר.",
  "dropCanceled": "השחרור בוטל.",
  "dropComplete": "השחרור הושלם.",
  "dropDescriptionKeyboard": "הקש על Enter כדי לשחרר. הקש על Escape כדי לבטל את הגרירה.",
  "dropDescriptionTouch": "הקש פעמיים כדי לשחרר.",
  "dropDescriptionVirtual": "לחץ כדי לשחרר.",
  "dropIndicator": "מחוון שחרור",
  "dropOnItem": "שחרר על {itemText}",
  "dropOnRoot": "שחרר על",
  "endDragKeyboard": "גורר. הקש על Enter כדי לבטל את הגרירה.",
  "endDragTouch": "גורר. הקש פעמיים כדי לבטל את הגרירה.",
  "endDragVirtual": "גורר. לחץ כדי לבטל את הגרירה.",
  "insertAfter": "הוסף אחרי {itemText}",
  "insertBefore": "הוסף לפני {itemText}",
  "insertBetween": "הוסף בין {beforeItemText} לבין {afterItemText}"
}
