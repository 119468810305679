import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { SELF_REPORTED_DATA_ENABLED } from 'constants/feature-flags.constants';

export const useIsSelfReportedDataEnabled = () => {
  const { isLoading, data: isSelfReportedDataEnabled } = useFeatureFlagQuery(
    SELF_REPORTED_DATA_ENABLED,
  );

  return {
    isLoading,
    isSelfReportedDataEnabled,
  };
};
