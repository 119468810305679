import { FaqSection, FaqQueryResponse } from '../types/faq.types';

export function getNormalizedFaqItems(data: FaqQueryResponse) {
  return data.benefit_product_types.reduce<FaqSection[]>(
    (sections, productType) => [
      ...sections,
      ...productType.sections.map((section) => ({
        productType: productType.benefit_product_type,
        country: productType.country,
        heading: section.faq_article_section_display_text.text,
        questions: section.faqs.map((faq) => ({
          text: faq.question_display_text.text,
          url: faq.url.url,
        })),
        linkText: section.faq_article_section_link_text?.text,
        linkUrl: section.faq_article_section_link_url?.text,
      })),
    ],
    [],
  );
}
