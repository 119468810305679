import { getWalletPath } from 'utils/get-wallet-path.util';
import { normalizeId } from 'utils/normalize-id.util';
import { getProductIcon, ProductIconType } from 'utils/get-product-icon.util';
import {
  NAVIGATION_TYPE,
  WalletItemNavigationBenefit,
} from '@leagueplatform/wallet-api';

function getWalletDetailsPath(
  navigationItem: WalletItemNavigationBenefit,
  currentUserRole: string | undefined,
  memberUserId: string | undefined,
) {
  const walletPath = getWalletPath(currentUserRole, memberUserId);
  const id =
    navigationItem.type === 'benefit'
      ? navigationItem.benefit_id || navigationItem.benefit_product_type
      : normalizeId(navigationItem.id);
  return `${walletPath}/${id}`;
}

export function getNavItemProps(
  item: WalletItemNavigationBenefit,
  userRole: string | undefined,
  memberUserId: string | undefined,
) {
  const id = normalizeId(item.id);
  const url = getWalletDetailsPath(item, userRole, memberUserId);

  switch (item.type) {
    case NAVIGATION_TYPE.BENEFIT:
      return {
        benefitProductType: item.benefit_product_type,
        iconUrl:
          item.benefit_logo?.src ||
          item.icon_url ||
          getProductIcon(item?.benefit_product_type as ProductIconType),
        id: item.benefit_id,
        title: item?.title || item.name.text,
        subtitle: item?.subtitle || item.vendor_name.text,
        status: item.status,
        url,
        vendorId: item.vendor_id,
      };
    case NAVIGATION_TYPE.OTHER:
    default:
      return {
        iconUrl:
          item.benefit_logo?.src ||
          item.icon_url ||
          getProductIcon(id as ProductIconType | undefined),
        id,
        title: item?.title || item.name?.text,
        subtitle: item?.subtitle || item.vendor_name?.text,
        status: 'active',
        url,
      };
  }
}
