import React from 'react';

import { Link as RouterLink } from '@leagueplatform/routing';

import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { SidebarCard } from '@leagueplatform/web-common-components';
import { RecentAchievementsProps } from 'types/achievement.types';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  HeadingText,
  Box,
  StackLayout,
  TextAction,
  useMediaQuery,
  queryHelpers,
  Icon,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { NoRecentAchievements } from './no-recent-achievements.view';

interface HeadingProps {
  children: string;
}

const Heading = ({ children }: HeadingProps) => (
  <HeadingText
    level="2"
    size="md"
    css={{ marginBottom: '$oneAndHalf', color: '$onSurfaceTextSubdued' }}
  >
    {children}
  </HeadingText>
);

export const RecentAchievements = ({
  achievements,
}: RecentAchievementsProps) => {
  const { formatMessage } = useIntl();
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));

  const sendViewAllAchievementsAnalytics = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'health journey progress',
      detail: 'view all achievements',
    });
  };

  return (
    <SidebarCard
      padding="oneAndHalf"
      data-testid="recent-achievements-container"
    >
      <Box>
        <Heading>{formatMessage({ id: 'ACHIEVEMENTS' })}</Heading>
        {achievements.length === 0 ? (
          <NoRecentAchievements />
        ) : (
          <StackLayout orientation="vertical">
            <Box css={{ width: '100%' }}>
              <StackLayout
                horizontalAlignment={
                  achievements.length === 3 ? 'spaceBetween' : 'start'
                }
                orientation={isAboveLaptop ? 'vertical' : 'horizontal'}
                as="ul"
                css={{ marginBottom: '$one' }}
              >
                {achievements.map((achievement) => (
                  <StackLayout
                    as="li"
                    orientation="horizontal"
                    verticalAlignment="center"
                    css={{
                      marginRight: '$two',
                      marginBottom: '$one',
                    }}
                    key={achievement.id}
                  >
                    <Icon icon={achievement.image.large} size="$twoAndHalf" />

                    <UtilityText
                      css={{
                        typography: '$caption',
                        textAlign: 'center',
                        marginLeft: '$half',
                      }}
                    >
                      {achievement.name}
                    </UtilityText>
                  </StackLayout>
                ))}
              </StackLayout>
            </Box>
            <TextAction
              as={RouterLink}
              to={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
              onClick={sendViewAllAchievementsAnalytics}
            >
              {formatMessage({ id: 'VIEW_ALL' })}
            </TextAction>
          </StackLayout>
        )}
      </Box>
    </SidebarCard>
  );
};
