import React from 'react';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
} from 'react-aria-components';
import type { CalendarProps, DateValue } from 'react-aria-components';
import {
  Box,
  GDSStyleObject,
  styled,
  focusOutlineInner,
  Icon,
  GDSIconProps,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

const StyledCalendarFull = styled(Box, {
  '.calendar': {
    width: '677px',
    height: '100%',

    '@mobile': {
      width: '375px',
    },
  },
  '.calendar-grid-navigation': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.calendar-grid-previous-button': {
    marginLeft: '$two',
    background: 'none',
    border: 'none',
  },
  '.calendar-grid-next-button': {
    marginRight: '$two',
    background: 'none',
    border: 'none',
  },
  '.calendar-grid-header': {
    marginBottom: '$half',
    tableLayout: 'fixed',
    width: '100%',
    height: '100%',
  },
  '.calendar-grid-cell': {
    width: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    cursor: 'pointer',

    '&[data-selected]': {
      ...focusOutlineInner,
      color: '$onSurfaceTextReversed',
      background: '$surfaceBackgroundTertiary',
      borderRadius: 9999,
    },
    '&[data-disabled], &[data-unavailable]': {
      color: '$onSurfaceTextSubdued',
      cursor: 'not-allowed',
    },
  },
});

export interface CalendarViewProps<T extends DateValue>
  extends CalendarProps<T> {
  ChevronRight?: GDSIconProps;
  ChevronLeft?: GDSIconProps;
  css?: GDSStyleObject;
}

export const CalendarView = <T extends DateValue>({
  ChevronRight,
  ChevronLeft,
  css,
  ...props
}: CalendarViewProps<T>) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <StyledCalendarFull css={css}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <Calendar {...props} className="calendar">
          <div className="calendar-grid-navigation">
            <Button slot="previous" className="calendar-grid-previous-button">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Icon icon="interfaceChevronLeft" {...ChevronLeft} />
              <VisuallyHidden>
                {formatMessage({ id: 'PERVIOUS_MONTH' })}
              </VisuallyHidden>
            </Button>
            <Heading />
            <Button slot="next" className="calendar-grid-next-button">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Icon icon="interfaceChevronRight" {...ChevronRight} />
              <VisuallyHidden>
                {formatMessage({ id: 'NEXT_MONTH' })}
              </VisuallyHidden>
            </Button>
          </div>
          <CalendarGrid weekdayStyle="short" className="calendar-grid-header">
            <CalendarGridHeader>
              {(day) => <CalendarHeaderCell>{day}</CalendarHeaderCell>}
            </CalendarGridHeader>
            <CalendarGridBody className="calendar-grid-body">
              {(date) => (
                <CalendarCell date={date} className="calendar-grid-cell" />
              )}
            </CalendarGridBody>
          </CalendarGrid>
        </Calendar>
      </StyledCalendarFull>
    </div>
  );
};
