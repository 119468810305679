import { keyframes } from '@stitches/react';

export const transition = '$transitions$defaultTime ease-in-out';

const animations = {
  overlayShow: {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  overlayClose: {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  contentShow: {
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
  },
  contentClose: {
    '0%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
    '100%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  },
  leftOpen: {
    '0%': { transform: 'translateX(-100%)' },
    '100%': { transform: 'translateX(0)' },
  },
  leftClose: {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: 'translateX(-100%)' },
  },
  rightOpen: {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(0)' },
  },
  rightClose: {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: 'translateX(100%)' },
  },
  bottomOpen: {
    '0%': { transform: 'translateY(100%)' },
    '100%': { transform: 'translateY(0)' },
  },
  bottomClose: {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(100%)' },
  },
};

export type KeyframeAnimationType = keyof typeof animations;
export const animate = (frames: KeyframeAnimationType) =>
  `${keyframes(animations[frames])} ${transition}`;
