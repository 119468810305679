import React, { ReactNode } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useParams } from '@leagueplatform/routing';
import { Box, genesisStyled, css, Link } from '@leagueplatform/genesis-commons';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { CLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import {
  SettingsDropdown,
  SETTINGS_BUTTON_LABEL,
} from '../settings-dropdown/settings-dropdown.component';

const ACTIVE_BUTTON_STYLES = {
  backgroundColor: 'primary.background.pressed',
  color: 'onSurface.text.reversed',
  textDecoration: 'none',
};
const FOCUS_BUTTON_STYLES = {
  backgroundColor: 'primary.background.hovered',
  color: 'onSurface.text.reversed',
  textDecoration: 'none',
};

const SettingsButton = genesisStyled(Link)(() =>
  css({
    '&:hover': FOCUS_BUTTON_STYLES,
    '&:focus': FOCUS_BUTTON_STYLES,
    '&:active': ACTIVE_BUTTON_STYLES,
  }),
);

export interface SettingsCogProps {
  children: ReactNode;
  modalIsOpen: boolean;
  isSettingsDropdownOpen: boolean;
  setIsSettingsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettingsCog = ({
  children,
  modalIsOpen,
  isSettingsDropdownOpen,
  setIsSettingsDropdownOpen,
}: SettingsCogProps) => {
  const { formatMessage } = useIntl();
  const { teamId } = useParams<{ teamId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: teamId
        ? TLP_SCREEN_NAMES.TEAM_DETAILS
        : SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail,
    });

  return (
    <Box position="relative">
      <SettingsButton
        as="button"
        id={SETTINGS_BUTTON_LABEL}
        alignItems="center"
        aria-expanded={isSettingsDropdownOpen}
        aria-haspopup="true"
        aria-label={formatMessage({ id: 'SETTINGS' })}
        border="none"
        backgroundColor="surface.background.primary"
        display="flex"
        onClick={() => {
          setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
          if (!isSettingsDropdownOpen) {
            handleAnalyticsClick(CLP_DETAIL.SETTINGS);
          }
        }}
        paddingX="one"
        paddingY="threeQuarters"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isSettingsDropdownOpen && ACTIVE_BUTTON_STYLES)}
      >
        <ChallengeIcon iconType={ICON_TYPES.COG} />
        {/*
          We want to display the word "Settings" when tablet and above,
          but visually hide it on smaller screen sizes. Button has an
          aria-label for consistent labeling
        */}
        <Box
          as="span"
          display={{ _: 'none', tablet: 'block' }}
          marginLeft="half"
        >
          {formatMessage({ id: 'SETTINGS' })}
        </Box>
      </SettingsButton>
      {isSettingsDropdownOpen && (
        <SettingsDropdown
          isOpen={isSettingsDropdownOpen}
          setIsOpen={setIsSettingsDropdownOpen}
          modalIsOpen={modalIsOpen}
        >
          {children}
        </SettingsDropdown>
      )}
    </Box>
  );
};
