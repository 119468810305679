import { useQuery } from 'react-query';
import {
  getChallengeConfig,
  ChallengePrivacyConfigDeserialisedData,
} from '@leagueplatform/health-journey-api';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';

export const CHALLENGE_PRIVACY_SETTING_QUERY_KEY = 'ChallengeConfig';

export const useGetChallengePrivacySetting = () => {
  const { data: challengeData } = useGetChallengeByIdData();
  const userChallengeId = challengeData?.data.userChallenge?.data.id;
  return useQuery<ChallengePrivacyConfigDeserialisedData | null, Error>(
    [CHALLENGE_PRIVACY_SETTING_QUERY_KEY, userChallengeId],
    () => getChallengeConfig(userChallengeId),
    {
      enabled: !!userChallengeId,
      // since we are using enabled here, the query will ignore query client invalidateQueries for challenge privacy config calls that would normally result in the query refetching, so we have cacheTime 0 to account for that when navigating back to the setting page after successfully updating privacy settings
      cacheTime: 0,
    },
  );
};
