import { useQuery, useQueryClient } from 'react-query';

export const API_DELAY_OVERLAY_ENABLED_MESSAGE_TYPE =
  'api_delay_overlay_enabled';

/**
 * @param shouldObserveUpdates {boolean} Do you want to rerender every time there is a change to the overlay state?
 * @returns {{delayOverlayActive: true, setDelayOverlayActive: function}} `delayOverlayActive` – For the view to determine when show/hide the overlay
 * `setDelayOverlayActive` – manually toggle the overlay on/off
 */
export const useApiDelayOverlay = (shouldObserveUpdates = true) => {
  const queryClient = useQueryClient();
  const { data: delayOverlayActive } = useQuery(
    [API_DELAY_OVERLAY_ENABLED_MESSAGE_TYPE],
    () => {},
    {
      enabled: shouldObserveUpdates,
      staleTime: Infinity,
    },
  );

  const setDelayOverlayActive = (isEnabled: boolean) =>
    queryClient.setQueryData(
      [API_DELAY_OVERLAY_ENABLED_MESSAGE_TYPE],
      isEnabled,
    );

  return {
    delayOverlayActive,
    setDelayOverlayActive,
  };
};
