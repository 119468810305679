import {
  BenefitCardAction,
  BENEFIT_CARD_ACTION,
  CategorizedActions,
} from '@leagueplatform/wallet-api';
import { CLAIM_URL } from 'utils/utils';

export const navIconMock = {
  url: 'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/provider_services.png',
  alt: 'Nav Icon',
};

export const overviewActionMock: BenefitCardAction = {
  body: 'Overview HTML example',
  font_awesome_icon: {
    icon_path: '',
    name: 'info-circle',
    unicode: 'f05a',
  },
  name: 'Overview',
  system_type: 'coverage_overview',
  tag_type: 'coverage_overview',
  id: 'overview-id',
  type: BENEFIT_CARD_ACTION.CONTENT,
  banners: {},
  indicator: false,
};

export const contentActionMock: BenefitCardAction = {
  body: 'Instructions HTML example',
  font_awesome_icon: {
    icon_path: '',
    name: 'info-circle',
    unicode: 'f05a',
  },
  name: 'Usage Instructions',
  system_type: 'usage_instructions',
  tag_type: 'usage_instructions',
  id: 'useage-id',
  type: BENEFIT_CARD_ACTION.CONTENT,
  banners: {},
  indicator: false,
};

export const primaryExternalActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path: '',
    name: 'external-link-alt',
    unicode: 'f35d',
  },
  name: 'Submit a Claim to Sun Life',
  url: 'https://sunlife.ca/member',
  primary: true,
  system_type: 'visit_url',
  tag_type: 'visit_url',
  id: 'primary-action-id',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const phoneActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/phone.png',
    name: 'phone',
    unicode: 'f095',
  },
  name: 'Call Sun Life for Support',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  title: 'Call Primary Title',
  description: 'Description for Title',
  url: 'tel:1-800-361-6212',
  system_type: 'call_phone',
  id: 'cell-phone-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const internalActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/circle_right_arrow.png',
    name: 'internal-link-alt',
    unicode: 'f35d',
  },
  name: 'Submit Claim',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: CLAIM_URL,
  system_type: 'visit_url',
  id: 'external-link-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const benefitDocumentActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/book_search.png',
    name: 'download',
    unicode: 'f019',
  },
  name: 'Download the KPMG Handbook',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: 'https://content.league.com/content/5a4fc52495ac1cae4bb630c6ab24a3a3',
  system_type: 'benefit_document',
  tag_type: 'benefit_document',
  id: 'benefit-document-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const externalLinkActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/circle_right_arrow.png',
    name: 'external-link-alt',
    unicode: 'f35d',
  },
  name: 'Download the Sun Life iOS App',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: 'https://itunes.apple.com/ca/app/my-sun-life-canada/id453274313?mt=8',
  system_type: 'visit_url',
  id: 'external-link-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const externalLinkActionNoPassedIconMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/circle_right_arrow.png',
    name: 'external-link-alt',
    unicode: 'f35d',
  },
  name: 'Download the Sun Life iOS App',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: 'https://itunes.apple.com/ca/app/my-sun-life-canada/id453274313?mt=8',
  system_type: 'visit_url',
  id: 'external-link-id',
  banners: {},
  indicator: false,
};

export const saveCardActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/card.png',
    name: 'credit-card',
    unicode: 'f09d',
  },
  name: 'Save Card',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: '/member/wallet/6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_vision/save',
  system_type: 'save_card',
  id: 'save-card-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const printCardActionMock: BenefitCardAction = {
  id: 'print',
  name: 'Print Card',
  banners: {},
  indicator: false,
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  system_type: 'print_card',
};

export const unavailableActionMock: BenefitCardAction = {
  name: 'Coverage Booklet Summary',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: '',
  font_awesome_icon: {
    name: 'book',
    unicode: 'f02d',
    icon_path:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
  },
  system_type: 'benefit_document',
  tag_type: 'benefit_document',
  indicator: false,
  indicator_description: '',
  banners: {},
  unavailable_action: {
    logo_url:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
    title: "There's no benefit booklet available",
    description:
      "<p style='text-align:center'>Please try again later or talk with a member advocate to get help.</p> <p>Don't worry, you're still covered. We're working on getting your plan information to you.</p>",
    phone_number: '123456789',
  },
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const genericContentModalActionMock: BenefitCardAction = {
  name: 'Generic Modal',
  type: BENEFIT_CARD_ACTION.CONTENT,
  system_type: 'modal_content',
  url: '',
  body: `<h3>secondary title to show it can handle rich text</h3><p>Lorem ipsum dolor sit amet, <span style="color:hotpink">consectetur adipiscing elit</span>. Maecenas vitae ultricies tellus. Vivamus dapibus vehicula massa eu malesuada. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent fermentum, nulla et dapibus condimentum, dolor dolor tincidunt sem, et semper purus sem in justo. Quisque laoreet non nunc a iaculis. Nunc in dolor fringilla lectus tristique posuere. Etiam accumsan lobortis justo eu pellentesque. Nulla posuere justo tristique turpis ullamcorper lobortis. Sed risus libero, aliquet in ligula vel, dictum placerat <a href="#" target="_blank">dolor</a>.</p>`,
  title: `Modal Title`,
  banners: {},
  indicator: false,
  font_awesome_icon: {
    name: 'book',
    unicode: 'f02d',
    icon_path:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
  },
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const longGenericContentModalActionMock: BenefitCardAction = {
  name: 'Generic Modal With Long Content',
  type: BENEFIT_CARD_ACTION.CONTENT,
  system_type: 'modal_content',
  url: '',
  body: `<h3>secondary title to show it can handle rich text</h3><p>Lorem ipsum dolor sit amet, <span style="color:hotpink">consectetur adipiscing elit</span>. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non neque nec nibh dignissim commodo. Nulla volutpat accumsan leo, non condimentum purus ornare cursus. Maecenas facilisis sagittis augue vitae volutpat. Donec at eleifend diam. Nullam et ex sollicitudin, dignissim ligula eu, commodo ipsum. Suspendisse vitae purus vel nisi ultrices pulvinar. Vestibulum maximus sapien eu justo tincidunt, a laoreet tellus mollis. Nam hendrerit magna sed lacus porttitor sagittis. Ut sit amet mollis dui, pellentesque euismod massa. Nulla turpis felis, volutpat ac lectus sed, finibus egestas odio.

  Vivamus dapibus lorem a sapien malesuada varius. Nulla facilisi. Sed nec tincidunt lacus. Aliquam pharetra quam tortor, sit amet egestas magna laoreet et. Aenean eu molestie nisi. Maecenas dapibus dolor lorem, luctus fermentum lorem vulputate volutpat. Suspendisse euismod cursus dapibus. Vivamus porttitor ullamcorper massa sit amet dignissim. Fusce quam dui, finibus at gravida sed, imperdiet quis augue. Vestibulum ac ligula eu mauris scelerisque tincidunt non at mauris. Aliquam tempor neque at accumsan tristique. Nullam ac nisi eu ipsum mollis fermentum nec eget magna. Pellentesque cursus tellus at leo vulputate, non lacinia mauris gravida. Sed tincidunt fermentum mollis. Nulla vestibulum nunc lectus, ut molestie diam gravida placerat.

  Nulla et ante congue, gravida orci et, mattis erat. Aliquam aliquet in enim sit amet pretium. Aliquam erat volutpat. Aliquam convallis sollicitudin tincidunt. Vestibulum egestas laoreet interdum. Etiam tincidunt, sapien a cursus tincidunt, lorem felis bibendum diam, vitae auctor odio enim quis mi. Nam metus nisl, rutrum sed aliquam vel, ultrices sit amet est. Vestibulum bibendum lacus vel velit condimentum, quis gravida sem molestie. Donec ac malesuada nulla, at accumsan diam. Nunc at ante non neque fermentum egestas. Fusce ornare, lorem vel mollis dapibus, est enim bibendum diam, at dictum eros ante at risus. Nulla erat lorem, tincidunt in ligula nec, mollis tempus massa. Sed arcu sem, elementum at vulputate ac, vehicula non tellus. Morbi at erat laoreet, aliquam risus a, eleifend odio.

  <a href="#" target="_blank">dolor</a>.</p>`,
  title: `Modal Title`,
  banners: {},
  indicator: false,
  font_awesome_icon: {
    name: 'book',
    unicode: 'f02d',
    icon_path:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
  },
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const emailLinkActionMock: BenefitCardAction = {
  font_awesome_icon: {
    icon_path:
      'https://storage.googleapis.com/league_staging_public_static_content/wallet/icons/circle_right_arrow.png',
    name: 'external-link-alt',
    unicode: 'f35d',
  },
  name: 'Download the Sun Life iOS App',
  type: BENEFIT_CARD_ACTION.OPEN_URL,
  url: 'mailto:webmaster@example.com',
  system_type: 'visit_url',
  id: 'email-link-id',
  banners: {},
  indicator: false,
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const reportCardLostOrStolenActionMock: BenefitCardAction = {
  name: 'Report Card Lost or Stolen',
  type: BENEFIT_CARD_ACTION.CONTENT,
  system_type: 'modal_content',
  url: '',
  banners: {},
  indicator: false,
  nav_icon: {
    url: 'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
    alt: 'test',
  },
  font_awesome_icon: {
    name: 'book',
    unicode: 'f02d',
    icon_path:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
  },
  unavailable_action: {
    title: `Report Card Lost or Stolen`,
    description: `<p>If card is lost or stolen, content member services immediately.</p>`,
    phone_number: '123456789',
  },
};

export const orderNewCardActionMock: BenefitCardAction = {
  name: 'Order New Card',
  type: BENEFIT_CARD_ACTION.ORDER_CARD,
  system_type: 'order_card',
  url: '',
  banners: {},
  indicator: false,
  nav_icon: {
    url: 'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
    alt: 'test',
  },
  font_awesome_icon: {
    name: 'book',
    unicode: 'f02d',
    icon_path:
      'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
  },
  icon_url:
    'https://storage.googleapis.com/league_testprod_public_static_content/wallet/icons/book_search.png',
};

export const mockActionCategories: CategorizedActions[] = [
  {
    title: 'Documents',
    actions: [
      benefitDocumentActionMock,
      unavailableActionMock,
      contentActionMock,
    ],
  },
  {
    title: 'Other',
    actions: [
      primaryExternalActionMock,
      phoneActionMock,
      externalLinkActionMock,
      saveCardActionMock,
      reportCardLostOrStolenActionMock,
    ],
  },
];

export const mockContentActions: BenefitCardAction[] = [
  overviewActionMock,
  contentActionMock,
];

export const mockActions = [
  overviewActionMock,
  contentActionMock,
  primaryExternalActionMock,
  phoneActionMock,
  benefitDocumentActionMock,
  externalLinkActionMock,
  saveCardActionMock,
  unavailableActionMock,
  genericContentModalActionMock,
  longGenericContentModalActionMock,
];
