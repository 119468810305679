import React, { forwardRef, FC } from 'react';
import { Icon, IconSource } from 'components/icon/icon';
import type { GDSCustomizableComponent } from '../../../types';
import { styled } from '../../../theme';

type GDSAddOnPosition = 'start' | 'end';
export interface GDSAddOnProps extends GDSCustomizableComponent {
  position: GDSAddOnPosition;
  icon?: IconSource;
  iconLabel?: string;
  children?: React.ReactNode;
}

const StyledAddOn = styled('div', {
  typography: '$bodyTwo',
  color: '$onSurfaceTextPrimary',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBlockStart: '$half',
  paddingBlockEnd: '$half',
  backgroundColor: '$surfaceBackgroundSecondary',
  border: '$borderWidths$thin solid $inputBorderDefault',
  variants: {
    position: {
      start: {
        borderStartStartRadius: '$medium',
        borderEndStartRadius: '$medium',
        borderInlineEnd: 'none',
        paddingInlineStart: '$threeQuarters',
        paddingInlineEnd: '$half',
      },
      end: {
        borderStartEndRadius: '$medium',
        borderEndEndRadius: '$medium',
        borderInlineStart: 'none',
        paddingInlineStart: '$half',
        paddingInlineEnd: '$threeQuarters',
      },
    },
  },
});

const addonPositionByString = {
  start: 'Start',
  end: 'End',
};

const getStringForClassName = (position: GDSAddOnPosition) =>
  addonPositionByString[position];

export const AddOn: FC<GDSAddOnProps> = forwardRef(
  (
    { position, icon, iconLabel, children, css, ...props }: GDSAddOnProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <StyledAddOn
      className={`GDS-input-add-on GDS-input-addon-${getStringForClassName(
        position,
      )}`}
      position={position}
      css={css}
      ref={ref}
      {...props}
    >
      {icon ? <Icon icon={icon} label={iconLabel} /> : children}
    </StyledAddOn>
  ),
);
