import * as React from 'react';
import {
  HealthProgramCarousels,
  useHealthProgramsCategory,
} from '@leagueplatform/health-programs';
import { SCREEN_NAMES } from 'constants/analytics';
import { MetricType } from '@leagueplatform/dashboard-api';

type HealthProgramsProps = {
  metric: MetricType;
};

export function HealthPrograms({ metric }: HealthProgramsProps) {
  const healthProgramsCategory = useHealthProgramsCategory({
    categoryId: metric,
  });

  return (
    <HealthProgramCarousels
      isLoading={healthProgramsCategory.isLoading}
      carousels={[
        {
          title: healthProgramsCategory.name,
          programs: healthProgramsCategory.programs,
        },
      ]}
      carouselsCategory={SCREEN_NAMES.DASHBOARD_METRIC_SCREEN}
    />
  );
}
