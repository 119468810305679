import React from 'react';
import { StyledSVG } from 'utils/styled-svg.component';
import { StyledPath } from 'utils/styled-path.component';
import { ColorProps, SizeProps } from '@leagueplatform/genesis-commons';

export interface ChallengeTokenIconProps {
  circleColor?: ColorProps['backgroundColor'];
  starColor?: ColorProps['backgroundColor'];
  size?: SizeProps['size'];
}

// Default token styling is the orange token

export const ChallengeTokenAsset = ({
  circleColor = 'warning.icon',
  starColor = 'warning.background.secondary',
  size = 'threeQuarters',
}: ChallengeTokenIconProps) => (
  <StyledSVG
    size={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="tokenIcon"
  >
    <StyledPath
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.1421 16 15.5 12.6421 15.5 8.5C15.5 4.35786 12.1421 1 8 1C3.85786 1 0.5 4.35786 0.5 8.5C0.5 12.6421 3.85786 16 8 16Z"
      fillColor={circleColor}
      strokeColor={circleColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <StyledPath
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47858 4.78666L9.50658 7.00199H11.5066C11.7132 6.99388 11.9027 7.11639 11.9802 7.30817C12.0576 7.49994 12.0063 7.71968 11.8519 7.85732L10.1186 9.56532L11.0806 11.7747C11.1667 11.9821 11.111 12.2213 10.9422 12.3694C10.7735 12.5175 10.5291 12.5416 10.3346 12.4293L8.00991 11.122L5.68258 12.4293C5.48811 12.5416 5.2437 12.5175 5.07491 12.3694C4.90612 12.2213 4.85048 11.9821 4.93658 11.7747L5.89858 9.56666L4.16525 7.85866C4.00965 7.72201 3.95691 7.50222 4.03356 7.30985C4.11021 7.11748 4.29965 6.99418 4.50658 7.00199H6.50658L7.53325 4.78666C7.62522 4.61068 7.80735 4.50037 8.00591 4.50037C8.20448 4.50037 8.3866 4.61068 8.47858 4.78666Z"
      fillColor={starColor}
    />
  </StyledSVG>
);
