import { leagueFetch } from '@leagueplatform/league-fetch';
import { MESSAGING_THREADS_PATH } from './constants/messaging-requests.constants';

export const patchThreadIsRead = async (threadId: string) => {
  const response = await leagueFetch(`${MESSAGING_THREADS_PATH}/${threadId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: threadId,
        type: 'thread',
        attributes: {
          isRead: true,
        },
      },
    }),
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while patching!');
};
