import React, { forwardRef } from 'react';
import * as CSS from 'csstype';
import css from '@styled-system/css';
import { Box, BoxProps } from '../../Box';

export interface LinkProps
  extends Partial<Omit<HTMLAnchorElement, 'children'>>,
    BoxProps {
  href?: string;
  disabled?: boolean;
  /**
   * @deprecated The position prop of Genesis Link components cannot be overridden directly to ensure their focus states render properly
   * @see Genesis Storybook documentation for examples of how to manipulate the position of a Link component
   */
  position?: CSS.PositionProperty;
}

/**
 * @deprecated  use `TextAction` from `genesis-core` instead
 */

export const Link: React.FunctionComponent<LinkProps> = forwardRef(
  ({ tabIndex = 0, disabled, href, children, ...props }, ref) => {
    const hrefElement = disabled ? null : href;
    return (
      <Box
        as="a"
        href={hrefElement}
        tabIndex={tabIndex}
        borderRadius="small"
        color="interactive.action.primary"
        fontSize="body1"
        fontWeight="medium"
        display="inline-block"
        ref={ref}
        css={css({
          textDecoration: 'none',
          position: 'relative',
          '::before': {
            content: '""',
            position: 'absolute',
            top: '-2px',
            left: '-2px',
            right: '-2px',
            bottom: '-2px',
            borderRadius: props.borderRadius || 'small',
          },
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
          ':focus': {
            outline: 0,
            borderWidth: 'thin',
            borderColor: 'interactive.focus.outer',
          },
          ':focus::before': {
            boxShadow: 'focusOutline',
          },
          ...(disabled && {
            '&:hover': {
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            color: 'interactive.action.disabled',
          }),
        })}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

Link.displayName = 'Link';
