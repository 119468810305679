import { Claim } from '@leagueplatform/claims-b2b';
import { getPaymentDataFromPricingDetails } from './get-payment-data-from-pricing-details.util';

// TODO: merge with (and de-camelize) TransactionGroup?
interface Item {
  claim: Claim;
  payment: any;
  credit: any;
  marketplace_transaction: any;
}

/**
 *
 * @param {*} items - array of payments & claims
 * @param {*} type - account type (i.e. 'hsa')
 *
 * Reshape the data returned from `get_purchase_and_claim_history` into
 * a more useful format to be displayed to the user.
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const mapHistoryToTransactions = (items: any[] = [], type: string) =>
  items.map((item) => {
    //   marketplaceTransaction will return a Lifemarket transaction. we would need to change the data from get_purchase_and_claim_history in order to rename
    const {
      claim,
      payment,
      credit,
      marketplace_transaction: lifemarketTransaction,
    } = item as Item;

    if (payment) {
      const paymentData = getPaymentDataFromPricingDetails(
        payment.pricing_details,
        type,
      );

      return {
        type: 'LIFEMARKET',
        date: payment.created_timestamp,
        paymentId: payment.service_id,
        invoiceId: payment.invoice_id,
        key: payment.payment_id,
        amount: paymentData.amount,
        amountType: 'debit',
        currency: payment.pricing_details.currency,
        status: payment.payment_status,
        service: payment.service_details,
        title: payment.service_details?.title,
        accountsList: paymentData.accountsUsed,
      };
    }

    if (claim) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { policy_allocations, amount_approved } = claim;
      const accountsList =
        policy_allocations?.map((allocation) => {
          if (allocation.type === 'psa') return 'LSA';
          return allocation.type.toUpperCase();
        }) ?? null;
      const policyAllocation = policy_allocations?.find(
        (allocation) => allocation.type === type,
      );
      const getAmount = () => {
        if (policyAllocation) return policyAllocation.amount_approved.value;
        if (amount_approved) return amount_approved.value;
        return null;
      };
      return {
        type: 'CLAIM',
        claimId: claim.claim_id,
        key: claim.claim_id,
        referenceId: claim.reference_id,
        status: claim.status,
        date: claim.date_created,
        title: claim.vendor,
        amount: getAmount(),
        amountType: 'debit',
        currency: amount_approved?.currency ?? null,
        accountsList: accountsList?.length > 1 ? accountsList : null,
      };
    }

    if (credit) {
      let creditType = credit.type;

      if (!creditType && creditType === '') {
        creditType = 'credit';
      }

      return {
        type: creditType.toUpperCase(),
        date: credit.timestamp,
        description: credit.description,
        currency: credit.currency,
        amount: credit.amount,
        key: credit.timestamp,
        amountType: 'credit',
      };
    }

    if (lifemarketTransaction) {
      // if amount is greater than 0, this transaction is a positive credit
      // if amount is less than 0 and negative, this transaction is a negative debit
      const isCredit = lifemarketTransaction.amount > 0;

      return {
        type: lifemarketTransaction.type.toUpperCase(),
        date: lifemarketTransaction.timestamp,
        title: lifemarketTransaction.description,
        invoiceId: lifemarketTransaction.id,
        key: lifemarketTransaction.id,
        amount: isCredit
          ? lifemarketTransaction.amount
          : lifemarketTransaction.amount * -1,
        amountType: isCredit ? 'credit' : 'debit',
        currency: lifemarketTransaction.currency,
        isLifemarketTransaction: true,
      };
    }

    return null;
  });
