import * as React from 'react';
import {
  StackLayout,
  Card,
  Icon,
  GDSIconProps,
} from '@leagueplatform/genesis-core';

type CalloutBannerProps = {
  icon: GDSIconProps['icon'];
  label: GDSIconProps['label'];
  children: React.ReactNode;
};

export function CalloutBanner({ icon, label, children }: CalloutBannerProps) {
  return (
    <Card.Flat css={{ backgroundColor: '$surfaceCardPrimary' }}>
      <Card.Section padding="$threeQuarters">
        <StackLayout
          orientation="horizontal"
          spacing="$half"
          verticalAlignment="center"
        >
          <Icon
            css={{
              borderRadius: '$circle',
              backgroundColor: '$surfaceBackgroundPrimary',
              objectFit: 'scale-down',
            }}
            size="$twoAndHalf"
            icon={icon}
            label={label}
          />
          {children}
        </StackLayout>
      </Card.Section>
    </Card.Flat>
  );
}
