import { useState } from 'react';

export function useConfirm({ onConfirm = () => {}, onCancel = () => {} } = {}) {
  const [isConfirming, setIsConfirming] = useState(false);

  return {
    isConfirming,
    setIsConfirming,
    onConfirm: () => {
      onConfirm();
      setIsConfirming(false);
    },
    onCancel: () => {
      onCancel();
      setIsConfirming(false);
    },
  };
}
