import React, { useEffect, useState } from 'react';
import {
  BodyTwo,
  Box,
  css,
  Flex,
  genesisStyled,
  HeadingFour,
  HeadingThree,
  PrimaryButton,
  QuietButton,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { Link, useLocation, useParams } from '@leagueplatform/routing';
import { Carousel } from '@leagueplatform/web-common-components';

import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  EVENT_NAME,
  trackAnalyticsEvent,
  sendAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  getResourceTypeFromSuggestion,
  PRODUCT_AREA,
  SCREEN_NAME,
} from 'constants/analytics.constants';
import { getSanitizedURL } from 'utils/get-sanitized-redirect-url.utils';
import { useAssessmentOutcomeQuery } from 'hooks/queries/use-assessment-outcome-query.hook';
import SuggestionCard from './suggestion-card.component';
import FrictionScreen from './friction-screen.component';
import ErrorScreen from './error-screen.component';
import { HOME_URL } from '../../constants';

const StyledBox = genesisStyled(Box)(
  css({
    marginBottom: 160,
  }),
);

function RightNavButtonAnalyticsFn() {
  sendAnalyticsEvent({
    category: 'Other Suggestions',
    action: 'Scroll Carousel',
    params: {
      carousel_name: 'Other Suggestions',
    },
  });
}

function onPrimaryCTAClick(suggestionsAmount: number) {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES,
    detail: 'view all suggestions',
    num_resources: suggestionsAmount,
  });
}

function onSecondaryCTAClick(suggestionsAmount: number) {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES,
    detail: 'close',
    num_resources: suggestionsAmount,
  });
}

interface OnCardClickParams {
  resourceName: string;
  resourceType: string | null;
  resourceId: string;
  numResources: number;
  isFotl: boolean;
  carouselName: string | null;
  carouselIndex: number | null;
  carouselNumResources: number | null;
}

function onCardClick({
  resourceName,
  resourceType,
  resourceId,
  numResources,
  isFotl,
  carouselName,
  carouselIndex,
  carouselNumResources,
}: OnCardClickParams) {
  trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES,
    detail: resourceName,
    resource_type: resourceType,
    resource_id: resourceId,
    num_resources: numResources,
    is_fotl: isFotl,
    carousel_name: carouselName,
    carousel_index: carouselIndex,
    carousel_num_resources: carouselNumResources,
  });
}

interface OutcomesPageParams {
  moduleId: string;
}

export function OutcomesPage() {
  const [showFrictionScreenByTimer, setShowFrictionScreenByTimer] =
    useState(true);
  const location = useLocation();
  const { moduleId } = useParams() as OutcomesPageParams;
  const {
    isLoading,
    isError,
    data: outcomeScreen,
    refetch,
  } = useAssessmentOutcomeQuery(moduleId);

  const suggestionsAmount = outcomeScreen?.suggestions?.length ?? 0;
  const [primarySuggestion, secondarySuggestion, ...otherSuggestions] =
    outcomeScreen?.suggestions || [];

  const startLoadingTimer = () =>
    setTimeout(() => setShowFrictionScreenByTimer(false), 3000);

  const reloadSuggestions = () => {
    setShowFrictionScreenByTimer(true);
    refetch();
    startLoadingTimer();
  };

  const params = new URLSearchParams(location.search);
  const isFromHome = params.get('b') === '1';

  const closeUrl = isFromHome
    ? HOME_URL
    : getModulePath(LEAGUE_MODULE_NAMES.healthJourney);

  const showErrorScreen = isError || !outcomeScreen || suggestionsAmount === 0;
  const showFrictionScreen = showFrictionScreenByTimer || isLoading;

  const primaryCtaUrl = getSanitizedURL(outcomeScreen?.primary_cta_url);

  useEffect(() => {
    const frictionScreenTimer = startLoadingTimer();

    if (!showFrictionScreen && !showErrorScreen)
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
        screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES,
      });

    return () => {
      clearTimeout(frictionScreenTimer);
    };
  }, [showFrictionScreen, showErrorScreen]);

  if (showFrictionScreen) return <FrictionScreen />;

  if (showErrorScreen)
    return <ErrorScreen onReloadSuggestions={reloadSuggestions} />;

  const {
    screen_title: screenTitle,
    title,
    subtitle,
    suggestions_title: suggestionsTitle,
    primary_cta: primaryCta,
    secondary_cta: secondaryCta,
  } = outcomeScreen!;

  return (
    <>
      <StyledBox width={586} marginX="auto" marginTop="one">
        <Box as="header">
          <SubtitleOne as="p" textAlign="center">
            {screenTitle}
          </SubtitleOne>
          <HeadingThree as="h1" marginTop="five">
            {title}
          </HeadingThree>
          <BodyTwo marginTop="oneAndHalf">{subtitle}</BodyTwo>
        </Box>
        <Box as="main">
          <SuggestionCard
            suggestion={primarySuggestion}
            onCardClick={() =>
              onCardClick({
                resourceName: primarySuggestion.title,
                resourceType: getResourceTypeFromSuggestion(primarySuggestion),
                resourceId: primarySuggestion.id,
                numResources: suggestionsAmount,
                isFotl: true,
                carouselName: null,
                carouselIndex: null,
                carouselNumResources: null,
              })
            }
          />

          {suggestionsAmount === 2 && (
            <SuggestionCard
              suggestion={secondarySuggestion}
              onCardClick={() =>
                onCardClick({
                  resourceName: secondarySuggestion.title,
                  resourceType:
                    getResourceTypeFromSuggestion(secondarySuggestion),
                  resourceId: secondarySuggestion.id,
                  numResources: suggestionsAmount,
                  isFotl: true,
                  carouselName: null,
                  carouselIndex: null,
                  carouselNumResources: null,
                })
              }
            />
          )}

          {suggestionsAmount >= 3 && (
            <Box marginTop="twoAndHalf">
              <Carousel
                heading={
                  suggestionsTitle && (
                    <HeadingFour as="h3">{suggestionsTitle}</HeadingFour>
                  )
                }
                name="suggestions-amount"
                RightNavButtonAnalyticsFn={RightNavButtonAnalyticsFn}
              >
                {[secondarySuggestion, ...otherSuggestions].map(
                  (suggestion, index, array) => (
                    <div key={suggestion.id}>
                      <SuggestionCard
                        suggestion={suggestion}
                        onCardClick={() =>
                          onCardClick({
                            resourceName: suggestion.title,
                            resourceType:
                              getResourceTypeFromSuggestion(suggestion),
                            resourceId: suggestion.id,
                            numResources: suggestionsAmount,
                            isFotl: false,
                            carouselName: 'Other Suggestions',
                            carouselIndex: index + 1,
                            carouselNumResources: array.length,
                          })
                        }
                        width={285}
                        imageHeight={113}
                        marginTop="one"
                      />
                    </div>
                  ),
                )}
              </Carousel>
            </Box>
          )}
        </Box>
      </StyledBox>
      <Flex
        as="footer"
        position="fixed"
        height={128}
        width="100%"
        bottom={0}
        backgroundColor="surface.background.secondary"
        justifyContent="center"
        alignItems="center"
        flexDirection="row-reverse"
      >
        <PrimaryButton
          as={Link}
          to={primaryCtaUrl}
          onClick={() => {
            onPrimaryCTAClick(suggestionsAmount);
          }}
          textDecoration="none"
        >
          {primaryCta}
        </PrimaryButton>
        <QuietButton
          as={Link}
          to={closeUrl}
          onClick={() => {
            onSecondaryCTAClick(suggestionsAmount);
          }}
          textDecoration="none"
          marginRight="twoAndHalf"
        >
          {secondaryCta}
        </QuietButton>
      </Flex>
    </>
  );
}
