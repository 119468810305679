{
  "dragDescriptionKeyboard": "Pritisnite tipko Enter za začetek vlečenja.",
  "dragDescriptionKeyboardAlt": "Pritisnite tipki Alt + Enter za začetek vlečenja.",
  "dragDescriptionLongPress": "Pritisnite in zadržite za začetek vlečenja.",
  "dragDescriptionTouch": "Dvotapnite za začetek vlečenja.",
  "dragDescriptionVirtual": "Kliknite za začetek vlečenja.",
  "dragItem": "Povleci {itemText}",
  "dragSelectedItems": "Povlecite {count, plural, one {# izbran element} other {izbrane elemente (#)}}",
  "dragSelectedKeyboard": "Pritisnite tipko Enter, da povlečete {count, plural, one {# izbrani element} other {# izbranih elementov}}.",
  "dragSelectedKeyboardAlt": "Pritisnite tipki Alt + Enter, da povlečete {count, plural, one {# izbrani element} other {# izbranih elementov}}.",
  "dragSelectedLongPress": "Pritisnite in zadržite, da povlečete {count, plural, one {# izbrani element} other {# izbranih elementov}}.",
  "dragStartedKeyboard": "Vlečenje se je začelo. Pritisnite tipko Tab za pomik na mesto, kamor želite spustiti elemente, in pritisnite tipko Enter, da jih spustite, ali tipko Escape, da prekličete postopek.",
  "dragStartedTouch": "Vlečenje se je začelo. Pomaknite se na mesto, kamor želite spustiti elemente, in dvotapnite, da jih spustite.",
  "dragStartedVirtual": "Vlečenje se je začelo. Pomaknite se na mesto, kamor želite spustiti elemente, in kliknite ali pritisnite tipko Enter, da jih spustite.",
  "dropCanceled": "Spust je preklican.",
  "dropComplete": "Spust je končan.",
  "dropDescriptionKeyboard": "Pritisnite tipko Enter, da spustite. Pritisnite tipko Escape, da prekličete vlečenje.",
  "dropDescriptionTouch": "Dvotapnite, da spustite.",
  "dropDescriptionVirtual": "Kliknite, da spustite.",
  "dropIndicator": "indikator spusta",
  "dropOnItem": "Spusti na mesto {itemText}",
  "dropOnRoot": "Spusti na mesto",
  "endDragKeyboard": "Vlečenje. Pritisnite tipko Enter za preklic vlečenja.",
  "endDragTouch": "Vlečenje. Dvotapnite za preklic vlečenja.",
  "endDragVirtual": "Vlečenje. Kliknite, da prekličete vlečenje.",
  "insertAfter": "Vstavi za {itemText}",
  "insertBefore": "Vstavi pred {itemText}",
  "insertBetween": "Vstavi med {beforeItemText} in {afterItemText}"
}
