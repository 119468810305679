import { assert, StructError } from 'superstruct';
import type { Struct } from 'superstruct';
import { LeagueConfigError } from './league-config-error';

/**
 * Given a value and a superstruct schema, validates that this value conforms to
 * that schema.
 */
export function assertConfigSchema<T>(
  value: unknown,
  schema: Struct<T>,
): asserts value is T {
  try {
    assert(value, schema);
  } catch (error) {
    if (!(error instanceof StructError)) {
      // some error other than a validation error has occured. Just throw it.
      throw error;
    }

    const messages: string[] = [];

    error.failures().forEach((failure) => {
      const pathStr = failure.path.join('.');
      const pathBase = failure.path.slice(0, -1);
      const pathBaseStr = pathBase.join('.');

      /**
       * whether the error is at the level of the config object itself (it's completely
       * missing or it's not an object at all).
       */
      const isRoot = !failure.path.length;

      if (failure.value === undefined) {
        // the issue is that an expected value is missing.
        if (isRoot) {
          // nothing was provided at all.
          messages.push('No League config object was provided');
        } else if (failure.path.length < 2) {
          // a config object was provided, but a required module (e.g., "core") is missing.
          messages.push(
            `Property "${failure.key}" is required but missing in the League config object.`,
          );
        } else {
          // a propery inside one of the config modules is missing.
          messages.push(
            `Property "${failure.key}" is required but missing on "${pathBaseStr}".`,
          );
        }
      } else if (failure.type === 'never') {
        // the issue is that a property we're not expecting was encountered.
        messages.push(
          `"${failure.key}" is not a valid property on "${pathBaseStr}".`,
        );
      } else if (isRoot) {
        // the issue is that something that isn't even an object was provided.
        messages.push(
          `Expected a valid League config object, but got "${failure.value}".`,
        );
      } else {
        // the issue is that the value of a legitimate property is not valid.
        messages.push(
          `The value "${failure.value}" is invalid for property "${pathStr}"`,
        );
      }
    });

    throw new LeagueConfigError(
      `Errors found in League configuration!\n\n${messages
        .map((message) => `- ${message}`)
        .join('\n')}`,
    );
  }
}
