/*
 * Theme fallback values are maintained manually based on additions in the Figma script manifest
 * at https://github.com/EverlongProject/genesis-figma-script/blob/main/src/manifest.json
 */

export const fallbackTokens: { [key: string]: any } = {
  'colors.input.background.default': 'colors.interactive.background.default',
  'colors.input.background.disabled': 'colors.interactive.background.disabled',
  'colors.input.background.hovered': 'colors.interactive.background.hovered',
  'colors.input.background.pressed': 'colors.interactive.background.pressed',
  'colors.input.border.critical': 'colors.interactive.border.critical.default',
  'colors.input.border.default': 'colors.interactive.border.default',
  'colors.input.border.disabled': 'colors.interactive.border.disabled',
  'colors.input.border.hovered': 'colors.interactive.border.hovered',
  'colors.input.border.success': 'colors.success.border.default',
  'colors.input.border.warning': 'colors.warning.border.default',
  'colors.surface.skeleton.shimmer.background': '#E7E7E7FF',
  'colors.surface.skeleton.shimmer.overlay':
    'linear-gradient(90.0deg, #FFFFFF00 0%, #FFFFFF80 50%, #FFFFFFE5 70%, #FFFFFF00 100%)',
  'colors.onSurface.border.card': 'colors.onSurface.border.subdued',
  'borderWidths.card': 'borderWidths.thin',
  'borderRadii.card': 'borderRadii.medium',
};
