import * as React from 'react';
import {
  Box,
  VisuallyHidden,
  useThemeStrings,
} from '@leagueplatform/genesis-core';
import {
  Ellipsis,
  PaginationIconLink,
  PaginationLink,
} from './components/pagination-link';
import { getMiddleNumbersArray } from './utils/get-middle-numbers.util';
import { usePagination } from './hooks/use-pagination.hook';
import { PaginationProps } from './types/pagination.types';

/**
 * A pagination ui component to navigate through multiple pages of content.
 *
 * @component
 * @param {boolean} [compactMode=false] - If true, only displays the previous/next buttons. Otherwise, shows page numbers as well.
 * @param {Object} [css] - Additional CSS styles to customize the component's appearance.
 * @param {number} [defaultPage=1] - The initial page to display.
 * @param {boolean} [isNextPageActiveOverride] - If present, overrides the next button state to be active or disabled. Only use if you don't know total pages and need to manually control next button.
 * @param {boolean} [isPrevPageActiveOverride] - If present, overrides the previous button state to be active or disabled. Only use if you don't know total pages and need to manually control previous button.
 * @param {string} [label] - A label override name for the entire pagination component.
 * @param {string} [labelNextPage] - A label override for the "next page" button.
 * @param {string} [labelPrevPage] - A label override for the "previous page" button.
 * @param {MiddleRange} [middleRange=3] -  Determines how many page numbers to show between the first and last pages (1, 3, 5, or 7).
 * @param {Function} [onPageChange] - Callback function triggered when the user changes the page. Returns an object that contains the new page number, page name, and button type.
 * @param {number} [page] - The selected page in a controlled pagination.
 * @param {number} [totalPages] - The total number of pages available. If not present page numbers are hidden.
 *
 * @example
 * <Pagination totalPages={10} onPageChange={handlePageChange} />
 */

export const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      compactMode = false,
      css,
      defaultPage = 1,
      isNextPageActiveOverride,
      isPrevPageActiveOverride,
      label,
      labelNextPage,
      labelPrevPage,
      middleRange = 3,
      onPageChange,
      page: pageProp,
      totalPages,
    },
    ref,
  ) => {
    const {
      paginationPage,
      paginationLabel,
      paginationLabelFirstPage,
      paginationLabelLastPage,
      paginationLabelNextPage,
      paginationLabelPrevPage,
    } = useThemeStrings();
    const [currentPage, onPageClick] = usePagination({
      defaultPage,
      page: pageProp,
      onPageChange,
    });
    const [displayedMiddlePages, setDisplayedMiddlePages] = React.useState<
      number[]
    >([]);
    // Needs to know if it should show pages
    const isPageNumbersEnabled =
      compactMode === false && !!totalPages && totalPages > 0;

    // If currently selected page is the middle 3 number,
    // keep the selected number in the middle till first or last is a sibling number
    const middlePageNumbers = React.useMemo(() => {
      if (!isPageNumbersEnabled || totalPages <= 2) {
        return [];
      }
      return getMiddleNumbersArray(currentPage, middleRange, 1, totalPages);
    }, [currentPage, middleRange, totalPages, isPageNumbersEnabled]);

    React.useEffect(() => {
      if (middlePageNumbers) {
        setDisplayedMiddlePages(middlePageNumbers);
      }
    }, [middlePageNumbers, totalPages]);

    // Show an ellipsis if there is a gap between the first page and the first middle range number
    const isPreviousEllipsisVisible = displayedMiddlePages[0] - 1 > 1;
    // Show an ellipsis if there is a gap between the last page and the last middle range number
    const isNextEllipsisVisible =
      totalPages &&
      totalPages > displayedMiddlePages[displayedMiddlePages.length - 1] + 1;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    const isPrevButtonDisabled =
      isPrevPageActiveOverride !== undefined
        ? !isPrevPageActiveOverride
        : isFirstPage === true;
    const isNextButtonDisabled =
      isNextPageActiveOverride !== undefined
        ? !isNextPageActiveOverride
        : isLastPage === true;
    const prevButtonLabel = labelPrevPage ?? paginationLabelPrevPage;
    const nextButtonLabel = labelNextPage ?? paginationLabelNextPage;

    if (totalPages === 0) {
      return null;
    }

    return (
      <Box
        as="nav"
        aria-label={label ?? paginationLabel}
        css={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '$one',
          color: '$interactiveActionSubdued',
          '[aria-current="page"]': {
            color: '$interactiveActionPrimary',
            borderColor: 'currentColor',
          },
          ...css,
        }}
        className="GDS-pagination"
        ref={ref}
      >
        <Box
          as="ul"
          css={{
            alignItems: 'center',
            display: 'flex',
            gap: '$one',
            listStyleType: 'none',
            margin: '$none',
            padding: '$none',
          }}
        >
          <Box as="li">
            <PaginationIconLink
              isDisabled={isPrevButtonDisabled}
              onClick={() =>
                onPageClick({
                  buttonType: 'previous',
                  pageName: prevButtonLabel,
                  pageNumber: currentPage - 1,
                })
              }
              icon="interfaceChevronLeft"
            >
              <VisuallyHidden>{prevButtonLabel}</VisuallyHidden>
            </PaginationIconLink>
          </Box>
          {/* TODO: Determine if should be hidden if pages is only 1 */}
          {isPageNumbersEnabled && (
            <Box as="li">
              <PaginationLink
                onClick={() =>
                  onPageClick({
                    buttonType: 'page',
                    pageName: '1',
                    pageNumber: 1,
                  })
                }
                isSelected={currentPage === 1}
              >
                1 <VisuallyHidden>{paginationLabelFirstPage}</VisuallyHidden>
              </PaginationLink>
            </Box>
          )}
          {isPreviousEllipsisVisible && (
            <Box as="li">
              <Ellipsis />
            </Box>
          )}
          {isPageNumbersEnabled &&
            displayedMiddlePages.map((page) => (
              <Box as="li" key={`page-${page}`}>
                <PaginationLink
                  onClick={() =>
                    onPageClick({
                      pageNumber: page,
                      pageName: `${page}`,
                      buttonType: 'page',
                    })
                  }
                  isSelected={page === currentPage}
                >
                  {page !== currentPage && (
                    <VisuallyHidden>{paginationPage} </VisuallyHidden>
                  )}
                  {page}
                </PaginationLink>
              </Box>
            ))}

          {isNextEllipsisVisible && (
            <Box as="li">
              <Ellipsis />
            </Box>
          )}
          {isPageNumbersEnabled && totalPages > 1 && (
            <Box as="li">
              <PaginationLink
                onClick={() =>
                  onPageClick({
                    buttonType: 'page',
                    pageName: `${totalPages}`,
                    pageNumber: totalPages,
                  })
                }
                isSelected={currentPage === totalPages}
              >
                {totalPages}{' '}
                <VisuallyHidden>{paginationLabelLastPage}</VisuallyHidden>
              </PaginationLink>
            </Box>
          )}

          <Box as="li">
            <PaginationIconLink
              onClick={() =>
                onPageClick({
                  buttonType: 'next',
                  pageName: nextButtonLabel,
                  pageNumber: currentPage + 1,
                })
              }
              isDisabled={isNextButtonDisabled}
              icon="interfaceChevronRight"
            >
              <VisuallyHidden>{nextButtonLabel}</VisuallyHidden>
            </PaginationIconLink>
          </Box>
        </Box>
      </Box>
    );
  },
);

Pagination.displayName = 'Pagination';
