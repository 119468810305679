import { WidgetType } from '@leagueplatform/masonry-api';
import { GenericCarousel } from 'components/generic-carousel/generic-carousel.component';
import { StackWidget } from 'components/stack-widget/stack-widget.component';
import {
  WidgetTypeToSimpleComponentMap,
  WidgetTypeToComponentMapType,
} from '../utils/custom-module-utils';

/**
 * Complex widgets are widgets which are componsed of other Single component widgets
 * Eg, Stack, Carousel can contain items of type 'xs_visual_card', 'text_with_small_visual' etc.
 * Read more about them here - https://everlong.atlassian.net/wiki/spaces/MAS/pages/3585179687/Complex+components
 *
 * We had to create a separate map for complex components to handle cyclic dependecy.
 */
export const WidgetTypeToComplexComponentMap: WidgetTypeToComponentMapType = {
  ...WidgetTypeToSimpleComponentMap,
  [WidgetType.STACK]: StackWidget,
  [WidgetType.GENERIC_CAROUSEL]: GenericCarousel,
};
