import { SizeProps, genesisStyled, css } from '@leagueplatform/genesis-commons';

interface StyledSVGProps {
  size?: SizeProps['size'];
}

export const StyledSVG = genesisStyled.svg(({ size }: StyledSVGProps) =>
  css({
    width: size,
    height: size,
  }),
);
