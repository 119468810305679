import * as React from 'react';
import {
  MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import {
  LargeSkeleton,
  SmallSkeleton,
  ExtraSmallSkeleton,
  HeroBannerSkeleton,
} from '@leagueplatform/masonry-widgets';

export interface MasonryLoaderNodeProps {
  type: 'extraSmall' | 'small' | 'large' | 'heroBanner';
}

export type MasonryLoaderNode = MasonryEngineNode<
  'loader',
  MasonryLoaderNodeProps
>;

export type MasonryLoaderRendererProps =
  MasonryEngineNodeRendererProps<MasonryLoaderNode>;

export const MasonryLoaderRenderer = ({ type }: MasonryLoaderRendererProps) => {
  switch (type) {
    case 'extraSmall':
      return <ExtraSmallSkeleton />;
    case 'small':
      return <SmallSkeleton />;
    case 'large':
      return <LargeSkeleton />;
    case 'heroBanner':
      return <HeroBannerSkeleton />;
    default:
      return null;
  }
};
