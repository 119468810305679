import React from 'react';
import { TextInput } from '@leagueplatform/genesis-commons';
import { fieldProps } from './field-shape';

export const TextField = ({
  field: { name, ...field },
  disabled = false,
  readOnly = false,
  hasError = false,
  required,
  inputId,
  placeholder = null,
}) => (
  <TextInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...field}
    required={required}
    name={name}
    id={inputId}
    disabled={Boolean(disabled ?? readOnly)}
    error={hasError}
    placeholder={placeholder}
  />
);

TextField.propTypes = fieldProps;
