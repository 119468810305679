import React, { useState } from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  JOURNEY_ROUTES,
  ENABLE_CHALLENGES_FAQ,
  ENABLE_CHALLENGE_ACTIVITIES_TAB,
  AchievementCalloutBanner,
  ProgramLayout,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { Responsive, StackLayout, Tabs } from '@leagueplatform/genesis-core';
import { FullPageError } from '@leagueplatform/web-common-components';
import { EmptyActivityState } from 'components/empty-activity-state/empty-activity-state.component';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { HighlightFeed } from 'components/highlight-feed/highlight-feed.component';
import { ChallengeLink } from 'components/challenge-link/challenge-link.component';
import { CHALLENGE_HIGHLIGHT_FEED_TYPES } from '@leagueplatform/health-journey-api';
import { TAB_NAME } from 'constants/analytics.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { ChallengeFAQ } from './components/challenge-faq/challenge-faq.component';
import { ChallengeHeading } from './components/challenge-heading/challenge-heading.component';
import { ChallengeDetails } from './components/challenge-details/challenge-details.component';
import { Leaderboard } from './components/leaderboard/leaderboard.component';
import { CLPSkeletonLoader } from './components/clp-skeleton-loader/clp-skeleton-loader.component';
import { Sidebar } from './components/sidebar/sidebar.component';
import { useGetChallengeByIdData } from './hooks/use-get-challenge-by-id-data.hook';
import { isEnrollmentOpen } from '../../utils/enrollment-period-status.util';
import { JoinTeam } from './components/sidebar/components/join-team/join-team.component';
import { LegalDisclaimer } from './components/challenge-legal-disclaimer/challenge-legal-disclaimer.component';
import { ChallengeActivities } from './components/challenge-activities/challenge-activities.component';
import { SignUpModal } from './components/sign-up-modal/sign-up-modal.component';
import { MobileResponsiveSignUpButton } from './components/sign-up-button/sign-up-button.component';

export const ChallengeLandingPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [displaySignUpModal, setDisplaySignUpModal] = useState(false);
  const { challengeId } = useParams<{ challengeId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  const { data: isChallengeActivitiesTabEnabled } = useFeatureFlagQuery(
    ENABLE_CHALLENGE_ACTIVITIES_TAB,
  );
  const { data: isFAQEnabled } = useFeatureFlagQuery(ENABLE_CHALLENGES_FAQ);
  const { isLoading, error, data: challengeData } = useGetChallengeByIdData();

  if (isLoading) return <CLPSkeletonLoader />;

  // TODO: differentiate between 404 error (show page not found error instead)
  if (error || !challengeData?.data)
    return <FullPageError route={JOURNEY_ROUTES.programsHome()} />;

  const {
    challengeType,
    detailsRichText,
    endEnroll,
    headerImageUrl,
    headerImageAltText,
    maxTeamSize,
    prizeRichText,
    status,
    startEnroll,
    rulesRichText,
    title,
    bannerText,
    achievementImage,
  } = challengeData.data;
  const isSoloChallenge = challengeType === 'solo';
  const enrollmentOpen = isEnrollmentOpen(
    startEnroll,
    endEnroll,
    defaultTimezone,
  );
  const userIsEnrolled =
    challengeData.data?.userChallenge?.data?.enrollmentStatus === 'enrolled';
  const userChallengeId = challengeData.data?.userChallenge?.data.id;
  const teamsEnabled = maxTeamSize > 0;
  const userIsOnTeam = !!challengeData.data.challengeTeam?.data.id;
  // TODO: move userCanJoinTeam logic to select method on query (https://everlong.atlassian.net/browse/CHAL-1854)
  const userCanJoinTeam =
    userIsEnrolled && teamsEnabled && !userIsOnTeam && enrollmentOpen;
  const teamId = challengeData.data.challengeTeam?.data.id;
  const teamName = challengeData.data.challengeTeam?.data.name;
  const showActivitiesTab = userIsEnrolled && isChallengeActivitiesTabEnabled;

  const handleTabsChange = (tabName: string) => {
    // analytics event for clicking a tab
    trackChallengesAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail: tabName,
    });
  };
  const displaySignUpButton =
    challengeType === 'solo'
      ? !userIsEnrolled
      : enrollmentOpen && !userIsEnrolled;

  return (
    <ProgramLayout
      header={
        <ChallengeHeading
          bannerText={bannerText}
          image={headerImageUrl}
          imageAltText={headerImageAltText}
          status={status}
          title={title}
          userIsEnrolled={userIsEnrolled}
          challengeType={challengeType}
        />
      }
      content={
        <StackLayout horizontalAlignment="stretch" css={{ width: '100%' }}>
          <Tabs.Root
            onValueChange={handleTabsChange}
            defaultValue={TAB_NAME.OVERVIEW}
          >
            <Tabs.TabList
              divider
              tabDistribution="hugContents"
              css={{ marginBottom: '$oneAndHalf' }}
            >
              <Tabs.Tab value={TAB_NAME.OVERVIEW}>
                {formatMessage({
                  id: 'OVERVIEW',
                })}
              </Tabs.Tab>
              {showActivitiesTab && (
                <Tabs.Tab value={TAB_NAME.ACTIVITIES}>
                  {formatMessage({
                    id: 'ACTIVITIES',
                  })}
                </Tabs.Tab>
              )}
              {!isSoloChallenge && (
                <>
                  <Tabs.Tab value={TAB_NAME.HIGHLIGHTS}>
                    {formatMessage({ id: 'HIGHLIGHTS' })}
                  </Tabs.Tab>
                  <Tabs.Tab value={TAB_NAME.LEADERBOARD}>
                    {formatMessage({ id: 'LEADERBOARD' })}
                  </Tabs.Tab>
                </>
              )}
            </Tabs.TabList>
            <Tabs.TabPanel value={TAB_NAME.OVERVIEW}>
              <StackLayout horizontalAlignment="stretch" spacing="$twoAndHalf">
                {prizeRichText && (
                  <ChallengeDetails
                    heading={formatMessage({ id: 'PRIZE' })}
                    content={prizeRichText}
                  />
                )}
                {detailsRichText && (
                  <ChallengeDetails
                    heading={formatMessage({ id: 'DETAILS' })}
                    content={detailsRichText}
                  />
                )}
                {rulesRichText && (
                  <ChallengeDetails
                    heading={formatMessage({ id: 'RULES' })}
                    content={rulesRichText}
                  />
                )}
                {achievementImage && (
                  <AchievementCalloutBanner
                    image={achievementImage}
                    text={formatMessage({
                      id: 'EARN_ACHIEVEMENT_COMPLETING_ACTIVITIES_CHALLENGE',
                    })}
                  />
                )}
                <Responsive down="laptop">
                  {/* Display team related content in main content on smaller screen sizes and hide on laptop */}
                  {teamsEnabled && userIsOnTeam && teamId && teamName && (
                    <ChallengeLink
                      to={JOURNEY_ROUTES.getChallengeTeamDetails(
                        challengeId,
                        teamId,
                      )}
                      title={formatMessage({ id: 'YOUR_TEAM' })}
                      description={teamName}
                    />
                  )}
                  {userCanJoinTeam && (
                    <StackLayout
                      css={{ marginBottom: '$one' }}
                      horizontalAlignment="stretch"
                    >
                      <JoinTeam />
                    </StackLayout>
                  )}
                </Responsive>
                {isFAQEnabled && <ChallengeFAQ />}
                <LegalDisclaimer />
              </StackLayout>
            </Tabs.TabPanel>

            {showActivitiesTab && (
              <Tabs.TabPanel value={TAB_NAME.ACTIVITIES}>
                <ChallengeActivities
                  challengeStatus={status}
                  userChallengeId={userChallengeId}
                />
              </Tabs.TabPanel>
            )}
            {!isSoloChallenge && (
              <>
                <Tabs.TabPanel value={TAB_NAME.HIGHLIGHTS}>
                  <HighlightFeed
                    feedType={CHALLENGE_HIGHLIGHT_FEED_TYPES.CHALLENGE}
                    challengeOrTeamId={challengeId}
                    challengeTitle={title}
                    userChallengeId={
                      challengeData.data?.userChallenge?.data?.id
                    }
                  />
                </Tabs.TabPanel>
                <Tabs.TabPanel value={TAB_NAME.LEADERBOARD}>
                  {status === CHALLENGE_STATUS.UPCOMING ? (
                    <EmptyActivityState
                      activityDescription={formatMessage({
                        id: 'NO_LEADERBOARD_ACTIVITY_DESCRIPTION',
                      })}
                    />
                  ) : (
                    <Leaderboard teamsEnabled={teamsEnabled} />
                  )}
                </Tabs.TabPanel>
              </>
            )}
          </Tabs.Root>
          {displaySignUpButton && (
            <MobileResponsiveSignUpButton
              setDisplaySignUpModal={setDisplaySignUpModal}
            />
          )}
          <SignUpModal
            isOpen={displaySignUpModal}
            onClose={() => {
              setDisplaySignUpModal(false);
            }}
            // Note: userCanJoinTeam prop's value should be teamsEnabled && !userIsOnTeam and NOT the userCanJoinTeam const, as this will cause a flicker on the success screen when the userIsEnrolled value gets updated upon sign up success
            userCanJoinTeam={teamsEnabled && !userIsOnTeam}
          />
        </StackLayout>
      }
      sidebar={
        <Sidebar
          userIsEnrolled={userIsEnrolled}
          teamsEnabled={teamsEnabled}
          userIsOnTeam={userIsOnTeam}
          userCanJoinTeam={userCanJoinTeam}
          teamId={teamId}
          teamName={teamName}
          setDisplaySignUpModal={setDisplaySignUpModal}
          displaySignUpButton={displaySignUpButton}
        />
      }
    />
  );
};
