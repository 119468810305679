import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import {
  colour,
  vars,
  Text,
  Tooltip,
  TooltipContentV2,
} from '@leagueplatform/ui-kit';
import {
  BodyTwo,
  HeadingOne,
  Box,
  SubtitleOne,
  Link,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { LearnMoreMobileView } from 'components/learn-more-mobile.view';
import { updateUserSelections } from '../utils/update-user-selections.utils';

import {
  MultiChoiceAnswers,
  MultiSelectAnswers,
  AnswerInput,
  AnswerDropDown,
} from './answer-types';

const Progress = styled(Text)`
  text-transform: uppercase;
  line-height: 18px;
`;

const Description = styled(Text)`
  line-height: 24px;
`;

const LearnMoreText = styled(Text)`
  line-height: 24px;
  text-decoration: dashed;
  border-bottom: 2px dashed ${colour('neutral.grayLight')};
`;

const ProgressBar = ({ questionNumber, totalQuestions }) => {
  const { formatMessage } = useIntl();
  return (
    questionNumber &&
    totalQuestions && (
      <Progress m={0} fontSize={0} fontWeight="bold" color="greyDark">
        {formatMessage(
          { id: 'QUESTION_PROGRESS' },
          { questionNumber, totalQuestions },
        )}
      </Progress>
    )
  );
};

const AnswerComponent = (
  questionId,
  questionType,
  answers,
  setShowArrow,
  setCurrentUserSelections,
  userSelections,
  isSkippable,
  setIsResuming,
) => {
  const onChangeHandler = (answer) => {
    setIsResuming(false);
    setCurrentUserSelections(
      updateUserSelections({
        userSelections,
        questionId,
        answer,
      }),
    );
  };
  switch (questionType) {
    case 'multiple_choice':
      return (
        <MultiChoiceAnswers
          questionId={questionId}
          answers={answers}
          onChangeHandler={onChangeHandler}
          setShowArrow={setShowArrow}
          userSelections={userSelections}
        />
      );
    case 'multiple_selection':
      return (
        <MultiSelectAnswers
          questionId={questionId}
          answers={answers}
          setShowArrow={setShowArrow}
          onChangeHandler={onChangeHandler}
          userSelections={userSelections}
        />
      );
    case 'input':
      return (
        <AnswerInput
          questionId={questionId}
          answers={answers}
          setShowArrow={setShowArrow}
          onChangeHandler={onChangeHandler}
          userSelections={userSelections}
          isSkippable={isSkippable}
        />
      );
    case 'drop_down':
      return (
        <AnswerDropDown
          questionId={questionId}
          answers={answers}
          setShowArrow={setShowArrow}
          onChangeHandler={onChangeHandler}
          userSelections={userSelections}
        />
      );
    default:
      return null;
  }
};

export const Question = ({
  questionId,
  questionNumber,
  totalQuestions,
  title,
  answerTitle,
  description,
  questionType,
  answers,
  setShowArrow,
  setCurrentUserSelections,
  userSelections,
  learnMoreTitle,
  learnMoreContent,
  isSkippable,
  setIsResuming,
}) => {
  const { formatMessage } = useIntl();
  const isMultipleChoice = questionType === 'multiple_choice';
  const isMultipleSelection = questionType === 'multiple_selection';
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const tooltipId = `${questionId}-tooltip`;
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  let answerTitleText = answerTitle;

  if (isMultipleChoice) {
    answerTitleText = formatMessage({ id: 'SELECT_ONE' });
  }

  if (isMultipleSelection) {
    answerTitleText = formatMessage({ id: 'SELECT_ALL_THAT_APPLY' });
  }

  return (
    <Box width={isMobile ? '100%' : '500px'}>
      {isMobile ? (
        <Box
          textAlign="center"
          width="70%"
          paddingLeft="four"
          css={{ position: 'absolute', top: '20px' }}
        >
          <ProgressBar
            questionNumber={questionNumber}
            totalQuestions={totalQuestions}
          />
        </Box>
      ) : (
        <ProgressBar
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
        />
      )}
      <HeadingOne fontSize="heading2" id={questionId}>
        {title}
      </HeadingOne>
      {isMobile ? (
        <BodyTwo color="neutral.grayDark">{description}</BodyTwo>
      ) : (
        <Description color="neutral.grayDark" mt={1} fontSize={2}>
          {description}
        </Description>
      )}
      {isMobile && isLearnMoreOpen && (
        <LearnMoreMobileView setIsLearnMoreOpen={setIsLearnMoreOpen}>
          <HeadingThree>{learnMoreTitle}</HeadingThree>
          <Text fontSize={2} color="neutral.grayDark">
            {learnMoreContent}
          </Text>
        </LearnMoreMobileView>
      )}
      {isMobile && learnMoreTitle && (
        <SubtitleOne
          as={Link}
          color="interactive.action.primary"
          onClick={() => setIsLearnMoreOpen(true)}
        >
          {learnMoreTitle}
        </SubtitleOne>
      )}
      {!isMobile && learnMoreTitle && (
        <Tooltip id={tooltipId} describedBy={tooltipId} inline zIndex="2">
          <LearnMoreText color="purple" fontSize={2}>
            {learnMoreTitle}
          </LearnMoreText>
          <TooltipContentV2
            borderRadius={4}
            borderColour={vars.colour.neutral.grayLight}
            padding="20px"
            width="480px"
            top
            positionShift={{
              x: 0,
              y: 50,
            }}
          >
            <Text m={0} fontSize={2} color="neutral.grayDark">
              {learnMoreContent}
            </Text>
          </TooltipContentV2>
        </Tooltip>
      )}
      <Box marginTop="two">
        {!isMobile && answerTitleText?.length > 0 && (
          <Box mb={isMultipleChoice ? 2 : 0}>
            <Text color="neutral.gray" m={0} id="answer-title">
              {answerTitleText}
            </Text>
          </Box>
        )}

        {AnswerComponent(
          questionId,
          questionType,
          answers,
          setShowArrow,
          setCurrentUserSelections,
          userSelections,
          isSkippable,
          setIsResuming,
        )}
      </Box>
    </Box>
  );
};

Question.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  answerTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  setShowArrow: PropTypes.func.isRequired,
  setCurrentUserSelections: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
  learnMoreTitle: PropTypes.string.isRequired,
  learnMoreContent: PropTypes.string.isRequired,
  isSkippable: PropTypes.bool,
  setIsResuming: PropTypes.func.isRequired,
};

Question.defaultProps = {
  isSkippable: false,
};

ProgressBar.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
};
