import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { WidgetType } from '@leagueplatform/masonry-api';
import {
  Detail2UpCardWithCTAButtonClick,
  DetailCarouselHeaderButtonClick,
  DetailCarouselHeaderSelectItem,
  DetailCarouselScrollLeft,
  DetailCarouselScrollRight,
  DetailHeroBannerButtonClick,
  DetailLargeVisualCardButtonClick,
  DetailProgressBarButtonClick,
  DetailProgressRingButtonClick,
  DetailSmallVisualCardButtonClick,
  DetailStackSelectItem,
  DetailTextWithCTAButtonClick,
  DetailXSVisualCardButtonClick,
  DetailNotificationBannerButtonClick,
  DetailNotificationCardButtonClick,
  DetailMetricCardButtonClick,
  DetailNotificationBannerDismissClick,
} from '../constants/analytics-event-detail-mapping';
import { MasonryConfig } from '../types/config.types';
import { WidgetDataForDefaultWidgets } from '../types/default-widget-data.types';
import {
  AllWidgetsDisplayData,
  CarouselCardData,
  ComplexWidgetItemData,
  TwoUpCardContentData,
} from '../types/widget-display-data.types';

const getDataForAnalyticsByWidgetType = (
  type: WidgetType,
  widgetAttributesAndTraits: AllWidgetsDisplayData,
) => {
  switch (type) {
    case WidgetType.XS_VISUAL_CARD:
      return {
        text_detail: widgetAttributesAndTraits.heading,
        text_sub_detail: widgetAttributesAndTraits.body,
        link_url: widgetAttributesAndTraits.linkUrl,
      };
    case WidgetType.PROGRESS_BAR:
      return {
        text_detail: widgetAttributesAndTraits.eyebrow,
        current_progress: widgetAttributesAndTraits.currentText,
        maximum_progress: widgetAttributesAndTraits.suffixText,
        remaining_progress: widgetAttributesAndTraits.footerText,
        progress_percent: widgetAttributesAndTraits.progress,
        link_url: widgetAttributesAndTraits.action,
      };
    case WidgetType.PROGRESS_RING:
      return {
        text_detail: widgetAttributesAndTraits.eyebrow,
        current_progress: widgetAttributesAndTraits.currentText,
        maximum_progress: widgetAttributesAndTraits.suffixText,
        progress_percent: widgetAttributesAndTraits.progress,
        link_url: widgetAttributesAndTraits.action,
      };
    case WidgetType.HERO_BANNER:
      return {
        text_detail: widgetAttributesAndTraits.buttonLabel,
        link_url: widgetAttributesAndTraits.buttonAction,
      };

    case WidgetType.NOTIFICATION_CARD:
      return {
        text_detail: widgetAttributesAndTraits.heading,
        text_sub_detail: widgetAttributesAndTraits.subheading,
        link_url: widgetAttributesAndTraits?.url,
      };
    case WidgetType.METRIC_CARD:
      return {
        text_detail: widgetAttributesAndTraits.heading,
      };
    default:
      return {
        text_detail: widgetAttributesAndTraits.title,
        text_sub_detail: widgetAttributesAndTraits.description,
        link_url: widgetAttributesAndTraits.linkUrl,
      };
  }
};

export const widgetAnalyticsEvents = (
  config: MasonryConfig | null,
  widgetType: WidgetType,
  widgetAttributesAndTraits: AllWidgetsDisplayData,
  productIdentifier: WidgetDataForDefaultWidgets['product_identifier'],
) => {
  /**
   * func trackMasonryAnalytics
   * @param {EVENT_NAME} event: The name of the event being tracked.
   * @param {object} widgetProperties: analytics properties that are unique to the widget event.
   * @returns A function that calls trackAnalyticsEvent with common analytics properties used across default masonry widgets.
   */
  const trackMasonryAnalytics =
    (event: EVENT_NAME, widgetProperties: object) => () =>
      trackAnalyticsEvent(event, {
        product_area: config?.analytics?.productArea,
        sub_product_area: config?.appId,
        screen_name: config?.analytics?.screenName,
        product_identifier: productIdentifier,
        ...widgetProperties,
      });

  const getCarouselAnalyticEvents = () => {
    const carouselRollup = {
      carousel_array: widgetAttributesAndTraits?.items?.map(
        (carouselItem: CarouselCardData) => carouselItem.title,
      ),
      carousel_name: widgetAttributesAndTraits?.title,
      carousel_num_resources: widgetAttributesAndTraits?.items?.length,
    };
    return {
      onScrollLeft: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselScrollLeft,
        ...carouselRollup,
      }),
      onScrollRight: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselScrollRight,
        ...carouselRollup,
      }),
      onSelectItem: (item: CarouselCardData, index: number) =>
        trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
          detail: DetailCarouselHeaderSelectItem,
          sub_detail: item.title,
          text_detail: item.overline,
          carousel_index: index,
          link_url: item.linkUrl,
          ...carouselRollup,
        })(),
      onSelectHeader: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselHeaderButtonClick,
        sub_detail: widgetAttributesAndTraits?.headerLinkHint,
        link_url: widgetAttributesAndTraits?.headerLinkUrl,
        ...carouselRollup,
      }),
    };
  };

  const getTwoUpCardAnalyticsEvent = () => ({
    onItemClick: (
      { heading, body, actionLink }: TwoUpCardContentData,
      index: number,
    ) =>
      trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: Detail2UpCardWithCTAButtonClick,
        text_detail: heading,
        text_sub_detail: body,
        container_index: index,
        link_url: actionLink,
      })(),
  });

  const getNotificationBannerAnalyticsEvent = () => ({
    // Returning onCardClick callback only when there is a url present for the widget
    ...(widgetAttributesAndTraits?.url && {
      onCardClick: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailNotificationBannerButtonClick,
        text_detail: widgetAttributesAndTraits?.heading,
        text_sub_detail: widgetAttributesAndTraits?.body,
        link_url: widgetAttributesAndTraits?.url,
      }),
    }),
    onDismiss: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
      detail: DetailNotificationBannerDismissClick,
      text_detail: widgetAttributesAndTraits?.heading,
      text_sub_detail: widgetAttributesAndTraits?.body,
      link_url: widgetAttributesAndTraits?.url,
    }),
  });

  const getStackAnalyticsEvents = () => {
    const stackRollup = {
      stack_array: widgetAttributesAndTraits.items.map(
        (stackItem: ComplexWidgetItemData) =>
          stackItem?.attributes?.title || stackItem?.attributes?.heading,
      ),
      stack_name: widgetAttributesAndTraits.heading,
      stack_num_resources: widgetAttributesAndTraits.items.length,
    };
    return {
      onItemClick: (
        { attributes, type }: ComplexWidgetItemData,
        index: number,
      ) =>
        trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
          detail: DetailStackSelectItem,
          stack_index: index,
          component_detail: type,
          ...getDataForAnalyticsByWidgetType(
            type as WidgetType,
            attributes as AllWidgetsDisplayData,
          ),
          ...stackRollup,
        })(),
    };
  };

  const getGenericCarouselAnalyticsEvents = () => {
    const carouselRollup = {
      carousel_array: widgetAttributesAndTraits.items.map(
        (stackItem: ComplexWidgetItemData) =>
          stackItem?.attributes?.title || stackItem?.attributes?.heading,
      ),
      carousel_name: widgetAttributesAndTraits.heading,
      carousel_num_resources: widgetAttributesAndTraits.items.length,
    };
    return {
      onScrollLeft: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselScrollLeft,
        ...carouselRollup,
      }),
      onScrollRight: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselScrollRight,
        ...carouselRollup,
      }),
      onItemClick: (
        { attributes, type }: ComplexWidgetItemData,
        index: number,
      ) =>
        trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
          detail: DetailCarouselHeaderSelectItem,
          carousel_index: index,
          component_detail: type,
          ...getDataForAnalyticsByWidgetType(
            type as WidgetType,
            attributes as AllWidgetsDisplayData,
          ),
          ...carouselRollup,
        })(),
      onSelectHeader: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
        detail: DetailCarouselHeaderButtonClick,
        sub_detail: widgetAttributesAndTraits?.headerButtonLabel,
        link_url: widgetAttributesAndTraits?.headerButtonAction,
        ...carouselRollup,
      }),
    };
  };

  // Use this function when there are common details to be passed to the analytics
  // In case of passing additional properties, create a separate function and
  // add a mapping to the widget type
  // - Pass widget detail to this function
  // - This function returns a callback function which can be used to pass widget attributes
  const getCommonAnalyticsEvent = (widgetDetail: string) => () => ({
    onCardClick: trackMasonryAnalytics(EVENT_NAME.BUTTON_CLICKED, {
      detail: widgetDetail,
      ...getDataForAnalyticsByWidgetType(widgetType, widgetAttributesAndTraits),
    }),
  });

  const getWidgetAnalyticsFromType: {
    [widgetType: string]: () => {
      [analyticEvents: string]: (...args: any[]) => void;
    };
  } = {
    /* ---- Complex Widgets ----*/
    [WidgetType.CAROUSEL]: getCarouselAnalyticEvents,
    [WidgetType.TWO_UP_CARD]: getTwoUpCardAnalyticsEvent,
    [WidgetType.STACK]: getStackAnalyticsEvents,
    [WidgetType.GENERIC_CAROUSEL]: getGenericCarouselAnalyticsEvents,
    /* ---- Simple Widgets ----*/
    [WidgetType.HERO_BANNER]: getCommonAnalyticsEvent(
      DetailHeroBannerButtonClick,
    ),
    [WidgetType.PROGRESS_BAR]: getCommonAnalyticsEvent(
      DetailProgressBarButtonClick,
    ),
    [WidgetType.PROGRESS_RING]: getCommonAnalyticsEvent(
      DetailProgressRingButtonClick,
    ),
    [WidgetType.XS_VISUAL_CARD]: getCommonAnalyticsEvent(
      DetailXSVisualCardButtonClick,
    ),
    [WidgetType.NOTIFICATION_BANNER]: getNotificationBannerAnalyticsEvent,
    [WidgetType.NOTIFICATION_CARD]: getCommonAnalyticsEvent(
      DetailNotificationCardButtonClick,
    ),
    [WidgetType.TEXT_WITH_CTA_CARD]: getCommonAnalyticsEvent(
      DetailTextWithCTAButtonClick,
    ),
    [WidgetType.LARGE_VISUAL_CARD]: getCommonAnalyticsEvent(
      DetailLargeVisualCardButtonClick,
    ),
    [WidgetType.SMALL_VISUAL_CARD]: getCommonAnalyticsEvent(
      DetailSmallVisualCardButtonClick,
    ),
    [WidgetType.METRIC_CARD]: getCommonAnalyticsEvent(
      DetailMetricCardButtonClick,
    ),
  };

  const widgetAnalyticsEventsFn = getWidgetAnalyticsFromType[widgetType];

  return widgetAnalyticsEventsFn ? widgetAnalyticsEventsFn() : {};
};
