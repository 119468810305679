import Cookies from 'js-cookie';
import { Auth } from '@leagueplatform/auth';
import { getContentUrlConfigValue } from '../utils/get-content-url';

export const uploadFile = async (file: File) => {
  if (!file) throw new Error('File is required');
  const formData = createFormData(file);
  const uploadRequest = Auth.initialized
    ? await createAuth0Request(formData)
    : await createLegacyRequest(formData);

  return fetchUpload(uploadRequest);
};

const createFormData = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);

  return formData;
};

const createLegacyRequest = async (body: FormData) => {
  const sessionId = Cookies.get('SID2');
  if (!sessionId) throw new Error('Authentication required');
  let contentServerUrl = getContentUrlConfigValue();
  const url = `${contentServerUrl}/contentsave/${sessionId}`;

  return new Request(url, {
    method: 'POST',
    body,
  });
};

const createAuth0Request = async (body: FormData) => {
  const accessToken = await Auth.getToken();
  let contentServerUrl = getContentUrlConfigValue();
  const url = `${contentServerUrl}/content`;

  return new Request(url, {
    method: 'POST',
    body,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  });
};

const fetchUpload = async (request: Request) => {
  const response = await fetch(request);
  if (!response.ok) {
    throw new Error(`fetchUpload error: ${response.status}`);
  }
  const data = await response.json();

  return data;
};
