import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import {
  TEAMS_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';
import {
  TeamResponseData,
  IndividualTeamDeserialisedData,
} from './types/teams-data.type';

const { CHALLENGE, USER, USER_CHALLENGE } = CHALLENGE_DATA_TYPES;
export const getTeamDataById = async (
  teamId: string,
  errorContext?: ErrorContext,
): Promise<IndividualTeamDeserialisedData> => {
  const response = await leagueFetch(
    `${TEAMS_PATH}/${teamId}?include=${CHALLENGE},${USER},${USER_CHALLENGE}`,
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (!response.ok) {
    throw new Error(`getTeamDataById error: ${response.status}`);
  }
  const responseJson: TeamResponseData = await response.json();
  return deserialise(responseJson);
};
