export enum DELAY_DISPLAY_STATES {
  NEVER_SHOW = -1,
  ALWAYS_SHOW = 0,
  SHORT_DELAY = 200,
  MEDIUM_DELAY = 500,
}

export enum DELAY_STATUS {
  IDLE = 'idle', // Blank/Passive state – no ui
  DELAY = 'delay', // Delay flow
  ACTIVE = 'active', // Flow initiated
  WAITING = 'waiting', // Pending timeout
}

export interface ApiDelayConfig {
  delay: DELAY_DISPLAY_STATES | number;
  minDuration: number;
  maxDuration: number;
}

type PendingTimers = Promise<(DELAY_STATUS | undefined)[]>;

export interface Timer {
  stopTimer?(): void;
  pendingTimer?: Promise<DELAY_STATUS>;
  activeTimer?: number | void;
  run(): void;
}

export interface ApiDelayControllerState {
  timers: Partial<Record<DELAY_STATUS, Timer>>;
  hasResponse: boolean;
  status: DELAY_STATUS;
  stateTransition(): void;
  getNextStatus(): DELAY_STATUS;
  runActionForStatus(status: DELAY_STATUS): void;
  handleCleanup(): void;
  waitForPendingTimers(): PendingTimers;
}

export interface ApiDelayControllerPublic {
  init(): void;
  finish(): PendingTimers;
  status: DELAY_STATUS;
}
