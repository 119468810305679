import html2canvas from 'html2canvas';
// TODO: move utilitiy functions into their own separate files

export const CLAIM_URL = '/member/submit-claim?requested_benefit_type=';

export const saveWalletCard = (cardHtml, cardType, cardName) => {
  if (!cardHtml) throw new Error('cardHtml is required');
  if (!cardType) throw new Error('cardType is required');
  // create iframe and position it off screen
  const iframe = document.createElement('iframe');
  iframe.width = 342;
  iframe.height = 202;
  iframe.style.position = 'absolute';
  iframe.style.left = '100%';
  document.body.appendChild(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(cardHtml);
  iframe.contentWindow.document.close();

  /* This hides the stripe which is apparently not well rendered by html2canvas */
  const cardElem = iframe.contentDocument.querySelector('.card');
  if (cardElem) {
    cardElem.classList.add('saving');
  }

  const elem = iframe.contentWindow.document.documentElement;
  const logos = iframe.contentDocument.querySelectorAll('.logo');

  /* The background images are inconsistently rendered by browsers when saving. Let's try images! */
  [].forEach.call(logos, (logo) => {
    const imageStyle = getComputedStyle(logo)?.backgroundImage;
    const imageUrl = /"(.+)"/g.exec(imageStyle)?.[1];
    const img = new Image();
    img.src = imageUrl;
    img.style.width = '100%';
    logo.appendChild(img);
    logo.classList.add('hide');
  });

  if (elem) {
    return html2canvas(elem).then(
      (canvas) => {
        const a = document.createElement('a');
        const fileName = `${cardName}-${cardType.replace(/_/g, '-')}.png`;

        if (canvas.msToBlob) {
          // IE implementation
          const blob = canvas.msToBlob();
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          a.href = canvas
            .toDataURL()
            .replace('image/png', 'image/octet-stream');
          a.download = fileName;
          a.click();
        }

        if (iframe.remove) {
          iframe.remove();
        } else {
          iframe.parentNode.removeChild(iframe);
        }

        return Promise.resolve(true);
      },
      (error) => Promise.reject(error),
    );
  }

  return Promise.reject(new Error('No html was passed to render'));
};
