import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import {
  Box,
  css,
  genesisStyled,
  QuietButton,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Icon, ICONS, Modal } from '@leagueplatform/ui-kit';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import * as React from 'react';

type HealthLiteracyModalProps = {
  ariaLabelledBy: string;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  metric?: string;
};
const StyledModalContainer = genesisStyled(Modal.Container)(
  css({
    position: 'relative',
    maxWidth: 620,
    borderRadius: 'medium',
    overflow: 'auto',
  }),
);

const StyledModalBody = genesisStyled(Modal.Body)(
  css({
    padding: 'oneAndHalf',
    paddingTop: 'half',
  }),
);

export const HealthLiteracyModal = ({
  ariaLabelledBy,
  onClose,
  children,
  metric,
}: HealthLiteracyModalProps) => {
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    if (metric) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.DASHBOARD,
        screen_name: SCREEN_NAMES.DATATYPE_CONTENT_SCREEN,
        metric_type: metric,
      });
    }
  }, [metric]);

  return (
    <Modal.Wrapper onClose={onClose} options={null}>
      <StyledModalContainer aria-labelledby={ariaLabelledBy}>
        <Box position="absolute" right="5px" top="5px">
          <QuietButton onClick={onClose}>
            <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
          </QuietButton>
        </Box>
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
};
