import * as React from 'react';
import { Link } from '@leagueplatform/routing';
import { FlexProps } from '@leagueplatform/genesis-commons';
import { ListItem } from './list-item';

type ListItemLinkProps = FlexProps & {
  children: React.ReactNode;
  to: string;
};

export function ListItemLink({ to, children, ...props }: ListItemLinkProps) {
  return (
    <ListItem
      as={Link}
      textDecoration="none"
      // TO DO focus and hover styles still need to be confirmed by design ,these are placeholders
      focusStyle={{
        backgroundColor: 'interactive.action.disabled',
      }}
      hoverStyle={{
        backgroundColor: 'interactive.background.disabled',
      }}
      to={to}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </ListItem>
  );
}
