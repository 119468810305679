import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ACHIEVEMENTS_ENABLED } from '../constants/feature-flag-names.constants';

export const useIsAchievementsEnabled = () => {
  const { data: isFeatureFlagOn } = useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  return {
    isFeatureFlagOn,
  };
};
