import * as React from 'react';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { PlanProgressV1 } from './plan-progress-v1.component';
import { PlanProgress } from './plan-progress.component';

// TODO: Remove this component when PlanProgress v2 is approved for launch in production
export const PlanProgressSwitcher = () => {
  const { data: isV2BalanceFlag, isLoading } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.WALLET_LIVE_BALANCE_V2,
  );

  // This should almost never appear as feature flags are called before wallet is
  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  if (!isV2BalanceFlag) {
    return <PlanProgressV1 />;
  }

  return <PlanProgress />;
};
