import React from 'react';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { Overline, Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

interface EyebrowEarnPointsTypes {
  completionPoints: number;
}

export const EyebrowEarnPoints = ({
  completionPoints,
}: EyebrowEarnPointsTypes) => {
  const { formatMessage } = useIntl();

  return (
    <Flex display="inline-flex" marginBottom="half" alignItems="center">
      <Icon icon={ICONS.STAR_FILLED} size={16} aria-hidden />
      <Overline as="p" marginLeft="half">
        {formatMessage(
          { id: 'EARN_NUMBER_POINTS_FULL' },
          { points: completionPoints },
        )}
      </Overline>
    </Flex>
  );
};
