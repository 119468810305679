import * as React from 'react';
import { UtilityText, StackLayout, Icon } from '@leagueplatform/genesis-core';
import { uniqueId } from 'lodash';
import { BadgePill } from '../badge-pill/badge-pill.component';
import { BadgeItem } from './badge-item.type';

export interface BadgeListProps {
  badges: BadgeItem[];
  backgroundColor?: string;
}

/**
 * @param {BadgeItem[]} badges - Accepts array of Badge items
 */

export const BadgeList = ({ badges, backgroundColor }: BadgeListProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    spacing="$threeQuarters"
    css={{
      flexWrap: 'wrap',
    }}
  >
    {badges?.map(({ image, imageAltText, body }: BadgeItem) => (
      <BadgePill key={uniqueId()} backgroundColor={backgroundColor}>
        {image && (
          <Icon icon={image} label={imageAltText} size="$threeQuarters" />
        )}
        {body && (
          <UtilityText
            emphasis="emphasized"
            size="sm"
            css={{
              color: '$onSurfaceTextPrimary',
              textAlign: 'center',
            }}
          >
            {body}
          </UtilityText>
        )}
      </BadgePill>
    ))}
  </StackLayout>
);
