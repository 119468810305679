import React from 'react';
import { ProgressRing } from '@leagueplatform/web-common-components';
import {
  Box,
  HeadingText,
  ParagraphText,
  UtilityText,
  StackLayout,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

type MasonryProgressRingCardNodeProperties = {
  heading?: string;
  eyebrow?: string;
  currentText: string;
  suffixText?: string;
  footerText?: string;
  image?: string;
  imageAltText?: string;
  progress: number;
  emphasizeCurrentValue?: boolean;
};

export type MasonryProgressRingCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type MasonryProgressRingCardNode = MasonryEngineNode<
  'progressRing',
  MasonryProgressRingCardNodeProperties,
  MasonryProgressRingCardNodeActions
>;

export type MasonryProgressRingCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryProgressRingCardNode>;

const PROGRESS_RING_DIMENSION = 82;

export const MasonryProgressRingCardRenderer = ({
  heading,
  eyebrow,
  currentText,
  suffixText = '',
  footerText,
  image,
  imageAltText,
  progress,
  onClick,
  emphasizeCurrentValue = false,
}: MasonryProgressRingCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ConditionalLinkCardRenderer
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      hasLink={Boolean(onClick?.payload.url)}
      css={{
        marginTop: `calc(${PROGRESS_RING_DIMENSION}px/2)`,
        overflow: 'visible',
        '& .GDS-card-click-area': {
          overflow: 'visible',
        },
      }}
    >
      <StackLayout
        horizontalAlignment="stretch"
        css={{ padding: '$oneAndHalf', overflow: 'visible' }}
      >
        <Box
          css={{
            width: `${PROGRESS_RING_DIMENSION}px`,
            height: `${PROGRESS_RING_DIMENSION}px`,
            position: 'relative',
            // Progress Ring Height + Card Padding
            transform: `${`translateY(calc(-50% - ${'$space$oneAndHalf'}))`}`,
            // Progress Ring Height - Card Padding + Progress Ring Margin Bottom
            marginBottom: `${`calc(-${PROGRESS_RING_DIMENSION}px/2 - ${'$space$oneAndHalf'} + ${'$space$threeQuarters'})`}`,
            alignSelf: 'center',
          }}
        >
          <ProgressRing
            size={PROGRESS_RING_DIMENSION}
            total={100}
            current={progress}
            customRingFillColor="success.border.default"
            customRingBackgroundColor="success.background.subdued"
            screenReaderText={`${currentText} ${suffixText || ''}`}
            image={image}
            imageAltText={imageAltText}
          />
        </Box>

        <StackLayout
          spacing="$threeQuarters"
          horizontalAlignment="center"
          css={{ width: '100%', textAlign: 'center' }}
        >
          {eyebrow && (
            <UtilityText
              css={{
                color: '$onSurfaceTextSubdued',
              }}
              size="eyebrow"
            >
              {eyebrow}
            </UtilityText>
          )}
          {heading && (
            <HeadingText
              level="display"
              css={{ marginTop: '$quarter !important' }}
              size="sm"
            >
              {heading}
            </HeadingText>
          )}
          <ParagraphText as="h3" size="sm">
            <ConditionalLinkRenderer
              disableDefaultOnClick
              ref={primaryActionRef}
              url={onClick?.payload.url}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
              css={{
                color: '$onSurfaceTextPrimary',
              }}
            >
              <ParagraphText
                as="span"
                size="sm"
                css={{
                  fontWeight: emphasizeCurrentValue ? 'bold' : '400',
                }}
              >
                {currentText}
              </ParagraphText>{' '}
              {suffixText}
            </ConditionalLinkRenderer>
          </ParagraphText>
          {footerText && (
            <ParagraphText size="xs" css={{ color: '$onSurfaceTextSubdued' }}>
              {footerText}
            </ParagraphText>
          )}
        </StackLayout>
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
