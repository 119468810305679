import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Button,
  GDSButtonProps,
  GDSIconProps,
  Icon,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';

export type UserBarProps = {
  label: string;
  isOpen: boolean;
  onClick: (event: any) => void;
  buttonProps?: Partial<GDSButtonProps<'button'>>;
  iconProps?: Partial<GDSIconProps>;
};

export const UserBar = ({
  label,
  isOpen,
  onClick,
  buttonProps,
  iconProps,
}: UserBarProps) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      id="user_dropdown_menu_button"
      onClick={onClick}
      aria-haspopup="dialog"
      aria-expanded={isOpen}
      aria-controls="user_dropdown_menu"
      size="toolbar"
      priority="tertiary"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
      css={{
        fontSize: '$bodyTwo',
        fontWeight: 500,
        borderRadius: '$none',
        border: '$none',
        span: { display: 'flex', alignItems: 'center' },
        '&:hover': {
          color: '$onSurfaceTextPrimary',
        },
        ...buttonProps?.css,
      }}
    >
      {label}
      <VisuallyHidden>
        {formatMessage({ id: 'USER_DROPDOWN_BTN_LABEL' })}
      </VisuallyHidden>
      <Icon
        icon="interfaceChevronDown"
        size="$oneAndHalf"
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...iconProps}
        css={{
          marginLeft: '$quarter',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          transition: 'transform 0.5s ease',
          ...iconProps?.css,
        }}
      />
    </Button>
  );
};
