import { BenefitCardAction } from '@leagueplatform/wallet-api';
import { useWalletDetailQuery } from './use-wallet-detail-query.hook';

export function useWalletPrimaryActionQuery(
  userId?: string,
  benefitId?: string,
  dependentId?: string,
) {
  return useWalletDetailQuery<BenefitCardAction | undefined>(
    userId,
    benefitId,
    dependentId,
    {
      select: (data) => data.primary_action,
    },
  );
}
