import React, { forwardRef, FunctionComponent } from 'react';
import css from '@styled-system/css';
import { BoxProps } from '../../Box';
import { InputProps } from '../Input';
import { TextInputBase } from '../TextInputBase';

export interface TextInputProps extends InputProps, BoxProps {
  type?: string;
}

/**
 * @deprecated  use `TextInput` from `genesis-core` instead
 */

export const TextInput: FunctionComponent<TextInputProps> = forwardRef(
  (props, ref) => (
    <TextInputBase
      ref={ref}
      height="three"
      type="text"
      css={css({
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      })}
      {...props}
    />
  )
);

TextInput.displayName = 'TextInput';
