import * as React from 'react';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

export const ProgramSponsor = ({
  image,
  sponsorName,
}: {
  image: string;
  sponsorName: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout spacing="$one">
      <HeadingText
        level="3"
        size={{
          '@initial': 'xl',
          '@mobile': 'lg',
        }}
      >
        {formatMessage({ id: 'PROGRAM_CREATED_BY' })}
      </HeadingText>
      <Image
        src={image}
        height="56px"
        width="auto"
        alt={formatMessage({ id: 'SPONSOR_LOGO' }, { name: sponsorName })}
      />
    </StackLayout>
  );
};
