import React, { useState, useEffect } from 'react';
import { USER_CONNECTED_DEVICE_CAMPAIGN_MODES } from '@leagueplatform/health-journey-api';
import { AcceptPrivacyPolicy } from '../accept-privacy-policy/accept-privacy-policy.component';
import { WearablesConnection } from '../wearables-connection/wearables-connection.component';
import { SignUpSuccess } from '../sign-up-success/sign-up-success.component';
import {
  SIGN_UP_MODAL_PAGES,
  Values,
} from '../../constants/sign-up-modal.constants';
import { SignUpExit } from '../sign-up-exit/sign-up-exit.component';

interface SignUpModalContentProps {
  currentStep: keyof typeof SIGN_UP_MODAL_PAGES;
  setNextModalNavigationStep: (next: keyof typeof SIGN_UP_MODAL_PAGES) => void;
  onClose: () => void;
  reset: () => void;
  userCanJoinTeam: boolean;
}

export const SignUpModalContent = ({
  currentStep,
  setNextModalNavigationStep,
  onClose,
  reset,
  userCanJoinTeam,
}: SignUpModalContentProps) => {
  const [trackingType, setTrackingType] = useState<
    Values<typeof USER_CONNECTED_DEVICE_CAMPAIGN_MODES>
  >(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.MANUAL);

  const {
    ACCEPT_PRIVACY_POLICY,
    WEARABLES_CONNECTION,
    SIGN_UP_SUCCESS,
    EXIT,
    WEARABLES_MANUAL_TRACK_CONFIRMATION,
  } = SIGN_UP_MODAL_PAGES;

  // Reset to privacy policy on mounting of this component so that modal doesn't open to sign up success in the case where user closes the sign up modal while sign up mutation is still being called and eventually succeeds, leaves the challenge, and then tries to sign up again
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), []);

  switch (currentStep) {
    case ACCEPT_PRIVACY_POLICY:
      return (
        <AcceptPrivacyPolicy
          trackingType={trackingType}
          setNextModalNavigationStep={setNextModalNavigationStep}
        />
      );
    case WEARABLES_CONNECTION:
    case WEARABLES_MANUAL_TRACK_CONFIRMATION:
      return (
        <WearablesConnection
          setNextModalNavigationStep={setNextModalNavigationStep}
          trackingType={trackingType}
          setTrackingType={setTrackingType}
          currentStep={currentStep}
        />
      );
    case SIGN_UP_SUCCESS:
      return (
        <SignUpSuccess onClose={onClose} userCanJoinTeam={userCanJoinTeam} />
      );
    case EXIT:
      return <SignUpExit onClose={onClose} />;
    default:
      return (
        <AcceptPrivacyPolicy
          trackingType={trackingType}
          setNextModalNavigationStep={setNextModalNavigationStep}
        />
      );
  }
};
