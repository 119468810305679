import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_HEALTH_PROFILE_MODULE_MESSAGE_TYPE =
  'get_health_profile_module';

export const getHealthProfileModule = (moduleId: string) =>
  SocketAsFetch.fetch({
    message_type: GET_HEALTH_PROFILE_MODULE_MESSAGE_TYPE,
    info: {
      id: moduleId,
    },
  });
