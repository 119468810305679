import { genesisStyled } from '@leagueplatform/genesis-commons';

export const Arrow = genesisStyled.img`
  display: block;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export interface ArrowButtonProps {
  label: string;
  onClick: () => void;
  hidden?: boolean;
  backgroundColor?: string;
  hoverColor?: string;
}
