import { useConfigProperty } from '@leagueplatform/core';
import { useGetCareCollection } from './use-care-collection.hook';
import { useGetUserProfile } from './use-get-user-profile.hook';

export const useCareCollectionPage = (careCollectionId: string) => {
  const { data, isLoading, isError, error } =
    useGetCareCollection(careCollectionId);

  const {
    data: dataProfile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
  } = useGetUserProfile();

  const showCareCollectionFeedbackComponent = useConfigProperty(
    'careCollections.showCareCollectionFeedbackComponent',
    false,
  );

  const showFeedbackCard =
    showCareCollectionFeedbackComponent &&
    !!data?.data?.attributes?.typeformUrl;

  const tenantId: string | undefined = useConfigProperty('core.tenantId');

  return {
    data,
    isLoading: isLoading || isLoadingProfile,
    isError: isError || isErrorProfile,
    error: error || errorProfile,
    showFeedbackCard,
    userId: dataProfile?.user_id,
    tenantId,
  };
};
