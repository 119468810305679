import React from 'react';
import {
  GDSIconProps,
  Icon,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';

interface ActivityCardEyebrowProps {
  iconProps?: GDSIconProps | null;
  children: React.ReactNode;
}

export const ActivityCardEyebrow = ({
  iconProps,
  children,
}: ActivityCardEyebrowProps) => (
  <StackLayout
    orientation="horizontal"
    spacing="$quarter"
    verticalAlignment="center"
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {iconProps && <Icon {...iconProps} />}
    <ParagraphText size="xs" emphasis="subtle">
      {children}
    </ParagraphText>
  </StackLayout>
);
