import * as React from 'react';
import { useTheme } from '@leagueplatform/genesis-commons';
import { useChart } from '../providers/chart-data-provider';

export function GridColumnLine() {
  const { xMax, yMax } = useChart();
  const theme = useTheme();

  return (
    <line
      x1={xMax}
      y1={0}
      x2={xMax}
      y2={yMax}
      stroke={theme.colors.onSurface.border.subdued}
      strokeWidth={theme.borderWidths.thick}
      strokeDasharray="5"
    />
  );
}
