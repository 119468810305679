import React from 'react';
import { ParagraphText, HeadingText } from '@leagueplatform/genesis-core';
import {
  Box,
  Flex,
  Image,
  QuietButton,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { Dependent } from '@leagueplatform/wallet-api';
import { useIntl } from '@leagueplatform/locales';
import { WalletCard } from 'components/wallet-card/wallet-card.component';
import { Modal } from '@leagueplatform/ui-kit';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { useDependentCardViewerModal } from './hooks/use-dependent-card-viewer-modal.hook';

interface DependentCardViewerProps {
  benefitId: string;
  dependent: Dependent;
  onClose: () => void;
}

export const DependentCardViewerModal = ({
  benefitId,
  dependent,
  onClose: handleClose,
}: DependentCardViewerProps) => {
  const { cardImage, cardName, isLoading } = useDependentCardViewerModal({
    benefitId,
    dependent,
  });
  const { formatMessage } = useIntl();

  const title = formatMessage(
    { id: 'DEPENDENT_CARD_HOLDER' },
    {
      dependentName: `${dependent?.first_name} ${dependent?.last_name}`,
    },
  );

  return (
    <Modal.Wrapper onClose={handleClose} options={{}}>
      <Flex
        borderRadius="medium"
        backgroundColor="surface.background.primary"
        flexDirection={['column', 'column', 'row']}
        padding="two"
        paddingTop="three"
        position="relative"
        role="dialog"
        aria-label={title}
      >
        <Box position="absolute" right="one" top="one">
          <QuietButton
            borderColor="transparent"
            borderRadius="circle"
            borderStyle="solid"
            borderWidth="thin"
            onClick={handleClose}
            paddingX="none"
            paddingY="none"
            size="two"
          >
            <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            <Image
              src={handleStaticAsset(WALLET_ASSET_KEYS.CLOSE_ICON) as string}
              role="presentation"
              size="threeQuarters"
            />
          </QuietButton>
        </Box>
        {isLoading ? (
          // Dimensions to match WalletCard
          <Box
            height="164px"
            position="relative"
            top="-50%"
            width={['280px', '280px', '450px']}
          >
            <LoadingIndicator />
          </Box>
        ) : (
          <Flex flexDirection="column">
            <HeadingText
              id="modalTitle"
              level="1"
              size="xxl"
              css={{ fontWeight: '$bodyOne' }}
            >
              <ParagraphText emphasis="subtle" as="span">
                {title}
              </ParagraphText>{' '}
              <Box as="span" display="block">
                {cardName}
              </Box>
            </HeadingText>
            <Box marginTop="two">
              <WalletCard card={cardImage} />
            </Box>
          </Flex>
        )}
      </Flex>
    </Modal.Wrapper>
  );
};
