import React from 'react';

export const DocumentIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.04348C2.67157 1.04348 2 1.74425 2 2.6087V21.3913C2 22.2557 2.67157 22.9565 3.5 22.9565H20.5C21.3284 22.9565 22 22.2557 22 21.3913V2.6087C22 1.74425 21.3284 1.04348 20.5 1.04348H3.5ZM1 2.6087C1 1.16795 2.11929 0 3.5 0H20.5C21.8807 0 23 1.16795 23 2.6087V21.3913C23 22.832 21.8807 24 20.5 24H3.5C2.11929 24 1 22.832 1 21.3913V2.6087Z"
      fill="currentColor"
    />
    <path
      d="M12.6448 7.25C12.6448 6.83579 12.309 6.5 11.8948 6.5H5.39478C4.98056 6.5 4.64478 6.83579 4.64478 7.25C4.64478 7.66421 4.98056 8 5.39478 8H11.8948C12.309 8 12.6448 7.66421 12.6448 7.25Z"
      fill="#FF612B"
    />
    <path
      d="M18.6448 11.75C18.6448 11.3358 18.309 11 17.8948 11H5.39478C4.98056 11 4.64478 11.3358 4.64478 11.75C4.64478 12.1642 4.98056 12.5 5.39478 12.5H17.8948C18.309 12.5 18.6448 12.1642 18.6448 11.75Z"
      fill="currentColor"
    />
    <path
      d="M18.6448 16.25C18.6448 15.8358 18.309 15.5 17.8948 15.5H5.39478C4.98056 15.5 4.64478 15.8358 4.64478 16.25C4.64478 16.6642 4.98056 17 5.39478 17H17.8948C18.309 17 18.6448 16.6642 18.6448 16.25Z"
      fill="currentColor"
    />
  </svg>
);
