import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import {
  BodyTwoSecondary,
  Box,
  genesisStyled,
  Overline,
  SecondaryButton,
  TextProps,
  VisuallyHidden,
  css,
} from '@leagueplatform/genesis-commons';
import { Transaction } from './components/transaction.component';
import { useTransactions } from './hooks/use-transactions.hook';

const TRANSACTION_CAPTION_ID = 'transaction-table-caption-id';

const TransactionsBox = genesisStyled(Box)(
  css({
    overflowX: 'auto',
    marginBottom: 'four',
    '&:focus': {
      boxShadow: 'rgba(0, 0, 0, 0.5)',
      outline: 'interactive.focus.outer',
    },
  }),
);

const TableHeadingCell = (props: TextProps) => (
  <Overline
    as="th"
    paddingY="one"
    paddingX="quarter"
    verticalAlign="middle"
    borderBottomColor="onSurface.border.subdued"
    borderBottomStyle="solid"
    borderBottomWidth="thin"
    textAlign="left"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export const Transactions = () => {
  const {
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    lifemarketUrl,
    fetchNextPage,
    transactions,
  } = useTransactions();

  const { formatMessage } = useIntl();

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  if (transactions?.length === 0 && !hasNextPage) {
    return (
      <BodyTwoSecondary
        marginTop="one"
        marginBottom="four"
        marginLeft="quarter"
        fontStyle="italic"
      >
        {formatMessage({ id: 'NO_TRANSACTIONS_YET' })}
      </BodyTwoSecondary>
    );
  }

  return (
    <TransactionsBox
      tabIndex={0}
      role="region"
      aria-labelledby={TRANSACTION_CAPTION_ID}
    >
      <Box as="table" width={1} aria-live="polite">
        <VisuallyHidden as="caption" id={TRANSACTION_CAPTION_ID}>
          {formatMessage({ id: 'TRANSACTION_TABLE_CAPTION' })}
        </VisuallyHidden>
        <thead>
          <Box as="tr">
            <TableHeadingCell minWidth={100}>
              {formatMessage({ id: 'DATE' })}
            </TableHeadingCell>
            <TableHeadingCell maxWidth={120}>
              {formatMessage({ id: 'ID' })}
            </TableHeadingCell>
            <TableHeadingCell>
              {formatMessage({ id: 'DESCRIPTION' })}
            </TableHeadingCell>
            <TableHeadingCell>{formatMessage({ id: 'TYPE' })}</TableHeadingCell>
            <TableHeadingCell textAlign="right" minWidth={100}>
              {formatMessage({ id: 'AMOUNT' })}
            </TableHeadingCell>
            <TableHeadingCell minWidth={30}>
              <VisuallyHidden>
                {formatMessage({ id: 'LINK_TO_TRANSACTION_DETAILS' })}
              </VisuallyHidden>
            </TableHeadingCell>
          </Box>
        </thead>
        <tbody>
          {transactions.map((transaction, i) => (
            <Transaction
              transaction={transaction}
              lifemarketUrl={lifemarketUrl}
              // There is a chance that there is nothing unique in the transaction object
              // eslint-disable-next-line react/no-array-index-key
              key={`${transaction.key}-${i}`}
            />
          ))}
        </tbody>
      </Box>
      {hasNextPage ? (
        <SecondaryButton
          isLoading={isFetchingNextPage}
          onClick={fetchNextPage}
          marginTop="one"
        >
          {formatMessage({ id: 'LOAD_MORE' })}
        </SecondaryButton>
      ) : (
        <BodyTwoSecondary
          marginTop="one"
          marginLeft="quarter"
          fontStyle="italic"
        >
          {formatMessage({ id: 'NO_MORE_TRANSACTIONS' })}
        </BodyTwoSecondary>
      )}
    </TransactionsBox>
  );
};
