import * as React from 'react';
import {
  findGrantedWearablePermissions,
  doesUserHaveSufficientWearablePermissions,
  useGetWearablesConsentForDataPoints,
} from '@leagueplatform/health-journey-api';

export const useUserHasSufficientWearablePermissions = (contentConfig) => {
  const requiredWearableHealthPermissions = contentConfig?.dataFields;

  const { data: wearablesData = {}, isLoading } =
    useGetWearablesConsentForDataPoints(requiredWearableHealthPermissions);
  const { receiving_data_v2: devicesObj = {} } = wearablesData;

  // The user requires the necessary wearable permissions to start this program.
  const userHasSufficientWearablePermissions = React.useMemo(() => {
    if (!requiredWearableHealthPermissions) {
      return null;
    }

    const grantedWearableHealthPermissions =
      findGrantedWearablePermissions(devicesObj);

    return doesUserHaveSufficientWearablePermissions(
      requiredWearableHealthPermissions,
      grantedWearableHealthPermissions,
    );
  }, [devicesObj, requiredWearableHealthPermissions]);

  return { userHasSufficientWearablePermissions, isLoading };
};
