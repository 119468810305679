import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  EVENT_NAME,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';

export enum MESSAGING_SCREEN_NAMES {
  NEW_MESSAGE = 'new message',
  MESSAGE_THREAD = 'message thread',
  THREAD_DETAILS = 'thread details',
  MESSAGES = 'messages',
}

interface TrackMessagingAnalyticsProps {
  screen_name?: SCREEN_NAMES | MESSAGING_SCREEN_NAMES;
  detail?: string;
  number_message_participants?: number;
  is_care_advisor_selected?: boolean;
  is_subject_created?: boolean;
  thread_contains_care_advisor?: boolean;
  has_document?: boolean;
}
const baseAnalyticsObj = {
  product_area: PRODUCT_AREA.GET_CARE,
  sub_product_area: SUB_PRODUCT_AREA.MESSAGING,
};

export const trackMessagingAnalyticsEvent = (
  eventType: EVENT_NAME,
  analyticsProps: TrackMessagingAnalyticsProps,
) => {
  trackAnalyticsEvent(eventType, {
    ...baseAnalyticsObj,
    ...analyticsProps,
  });
};
