import { useGetHealthProgramsCategory } from 'pages/category/hooks/use-get-health-program-category.hook';
import { alphaSortObjectsByStringValue } from 'utils/filter-and-sort-objects-by-key.utils';

export const useHealthProgramsCategory = ({ categoryId }) => {
  const {
    data: programData,
    isError,
    isLoading,
  } = useGetHealthProgramsCategory(categoryId);
  const programs = programData?.programs
    ? alphaSortObjectsByStringValue(programData.programs, 'name')
    : [];
  const name = programData?.name || '';
  const description = programData?.description || '';

  return {
    name,
    description,
    programs,
    isLoading,
    isError,
  };
};
