import { AnalyticsBrowser } from '@segment/analytics-next';
import { getConfig } from '@leagueplatform/config';
import { User, EventProps } from '../analytics.types';

export const segment = new AnalyticsBrowser();

export const segmentIdentify = (user: User) => segment.identify(user.id, user);

export const sendSegmentEvent = (eventName: string, props: EventProps) => {
  segment.track(eventName, {
    ...props,
    tenant_id: getConfig().config.core.tenantId || '',
  });
};
