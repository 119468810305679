import { Box, styled } from '@leagueplatform/genesis-core';

export const Card = styled(Box, {
  '.card.styled': {
    cursor: 'default',
    color: '$onSurfaceTextPrimary',
    fontSize: '$bodyTwo',
    borderRadius: 18,
    borderColor: '$onSurfaceBorderSubdued',
    boxShadow: '$card',
    padding: '$one',
    height: 'auto',
    minHeight: '168px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '$half',

    '.row': {
      fontFamily: '$headingOne',
      padding: 0,
      position: 'relative',
    },
    '.details': {
      position: 'initial',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '$half',
    },
    '.cardholder': {
      position: 'initial',
    },
    '.description': {
      position: 'initial',
    },
    h1: {
      marginBottom: 0,
      color: 'inherit',
      fontWeight: 'medium',
      fontSize: '$bodyTwo',
    },
    // TODO: WALLET-1937
    //     Remove h1 style when title is
    //     returned from backend as h2
    //     h2 style added in preparation of this
    h2: {
      marginBottom: 0,
      color: 'inherit',
      fontWeight: 'medium',
      fontSize: '$bodyTwo',
    },
    '.light': {
      color: '$onSurfaceTextSubdued',
    },
    '.medium': {
      color: 'inherit',
    },
    '.col': {
      marginRight: 'half',
      flexShrink: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '.label': {
        color: '$onSurfaceTextSubdued',
        fontSize: '10px',
        whiteSpace: 'normal',
      },

      '.value, .name': {
        lineHeight: 'normal',
        fontSize: '$caption',
        color: 'inherit',
      },
      '.name': {
        fontWeight: 'medium',
        lineHeight: 'normal',
        fontSize: '$caption',
        color: 'inherit',
      },
    },
    '.dark': {
      fontSize: '$caption',
      color: 'inherit',
    },
    '.balance .value': {
      lineHeight: '52px',
    },
    '.title': {
      position: 'initial',
      width: '100%',
      color: 'inherit',
      div: {
        fontSize: '$caption',
      },
    },
    '.disclaimer': {
      fontSize: '10px',
      color: '$onSurfaceTextSubdued',
      position: 'initial',
    },
    '.effective-dates': {
      position: 'initial',
      maxWidth: '80%',
    },
    '.effective-dates p': {
      fontSize: '10px',
      color: '$onSurfaceTextSubdued',
    },
    '.logos': {
      width: '20%',
      right: '6%',
      padding: 0,
      position: 'absolute',
    },
    '.logo': {
      marginTop: '3%',
      height: '10%',
    },
  },
});
