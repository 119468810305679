import React from 'react';
import {
  BodyTwo,
  CoverImage,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import PropTypes from 'prop-types';

export default function SuggestionCardContent({ suggestion, imageHeight }) {
  return (
    <>
      <CoverImage
        src={suggestion.image_url}
        alt=""
        width="100%"
        height={imageHeight}
        borderRadius="medium"
        backgroundColor="surface.background.secondary"
      />
      <SubtitleOne as="h2" marginTop="half">
        {suggestion.title}
      </SubtitleOne>
      <BodyTwo marginTop="quarter">{suggestion.description}</BodyTwo>
    </>
  );
}

SuggestionCardContent.propTypes = {
  suggestion: PropTypes.shape({
    id: PropTypes.string,
    action_url: PropTypes.string,
    image_url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  imageHeight: PropTypes.number.isRequired,
};
