import React from 'react';
import { useRouteMatch, Switch, Route } from '@leagueplatform/routing';
import { SuccessPage } from 'components/success-page.view';
import { OutcomesPage } from 'components/outcomes/outcomes-page.component';
import { HealthAssessment } from './health-assessment-page.view';

export const AssessmentRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/success`} component={SuccessPage} />
      <Route exact path={`${path}/outcomes`} component={OutcomesPage} />
      <Route component={HealthAssessment} />
    </Switch>
  );
};
