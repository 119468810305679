import { ObjectOrArray } from 'styled-system';

export type GlobalRadii =
  | 'none'
  | 'small'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | 'huge'
  | 'pill'
  | 'circle'
  | 'thin'
  | 'thick';

export const globalRadii: ObjectOrArray<number | string> = {
  none: 0,
  thin: 1,
  thick: 2,
  small: 3,
  medium: 6,
  large: 9,
  extraLarge: 12,
  huge: 20,
  pill: 40,
  circle: '50%',
};
