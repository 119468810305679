import { UseQueryOptions } from 'react-query';
import { UserWalletBenefitCard, CardImages } from '@leagueplatform/wallet-api';
import { selectCardHtml } from '../utils/select-card-html.util';
import { useWalletDetailQuery } from './queries/use-wallet-detail-query.hook';

export function useWalletCardQuery<TData = CardImages>(
  userId: string | undefined,
  benefitId: string | undefined,
  dependentId?: string,
  options?: UseQueryOptions<
    UserWalletBenefitCard,
    unknown,
    CardImages | null | TData
  >,
) {
  return useWalletDetailQuery(userId, benefitId, dependentId, {
    select: (data: UserWalletBenefitCard) => {
      // Return full card image object if it exists and isn't html
      if (data?.card_images && data.card_images?.front.type !== 'html') {
        return data.card_images;
      }
      return selectCardHtml(data);
    },
    ...options,
  });
}
