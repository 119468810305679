import React from 'react';

export const Prize = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="prizeIcon"
  >
    <path
      d="M4.807 13.042L0.75 18L4.5 18.75L6 23.25L9.944 16.992"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.193 13.042L23.25 18L19.5 18.75L18 23.25L14.056 16.992"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17.25C16.5563 17.25 20.25 13.5563 20.25 9C20.25 4.44365 16.5563 0.75 12 0.75C7.44365 0.75 3.75 4.44365 3.75 9C3.75 13.5563 7.44365 17.25 12 17.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.531 4.29199L13.779 6.74999H15.906C16.1456 6.74413 16.3644 6.88581 16.457 7.1069C16.5496 7.32799 16.4972 7.58326 16.325 7.74999L14.372 9.66799L15.454 12.153C15.5518 12.3864 15.4896 12.6561 15.2995 12.8231C15.1094 12.9902 14.8338 13.0171 14.615 12.89L12 11.419L9.385 12.89C9.16617 13.0171 8.89063 12.9902 8.70051 12.8231C8.5104 12.6561 8.4482 12.3864 8.546 12.153L9.628 9.66799L7.675 7.74599C7.50277 7.57926 7.45037 7.32399 7.54301 7.1029C7.63564 6.88181 7.85436 6.74013 8.094 6.74599H10.221L11.47 4.29199C11.5739 4.09533 11.7781 3.97229 12.0005 3.97229C12.2229 3.97229 12.4271 4.09533 12.531 4.29199Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
