import React, { ReactNode } from 'react';
import {
  HeadingText,
  Modal,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';

export interface ConfirmationModalProps {
  heading: string;
  description: string;
  isSettingsDropdownOpen: boolean;
  onDismiss: React.Dispatch<React.SetStateAction<boolean>>;
  onMountAnalytics?: () => void;
  children: ReactNode;
}

export const ConfirmationModal = ({
  heading,
  description,
  isSettingsDropdownOpen,
  onDismiss,
  onMountAnalytics,
  children,
}: ConfirmationModalProps) => {
  if (isSettingsDropdownOpen && onMountAnalytics) {
    onMountAnalytics();
  }

  return (
    <Modal.Root open={isSettingsDropdownOpen} onOpenChange={onDismiss}>
      <Modal.Content>
        <StackLayout spacing="$oneAndHalf">
          <Modal.Title>
            <HeadingText size="xl" level="2">
              {heading}
            </HeadingText>
          </Modal.Title>
          <StackLayout spacing="$four">
            <Modal.Description>
              <UtilityText>{description}</UtilityText>
            </Modal.Description>
            <StackLayout
              orientation="horizontal"
              horizontalAlignment="end"
              css={{ width: '100%' }}
            >
              {children}
            </StackLayout>
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
