import { useQuery } from 'react-query';
import {
  getCareCollections,
  CareCollectionsQueryResponse,
  CARE_COLLECTIONS_DATA_TYPE,
} from '@leagueplatform/care-collections-api';

export const useGetCareCollections = (
  include?: CARE_COLLECTIONS_DATA_TYPE,
  queryTag?: string,
) =>
  useQuery<CareCollectionsQueryResponse>(['GET_CARE_COLLECTIONS'], () =>
    getCareCollections(include, queryTag),
  );
