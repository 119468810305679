import React, { useEffect, forwardRef, HTMLAttributes, useMemo } from 'react';
import { useActiveBreakpoint } from '../../hooks/use-active-breakpoint';
import {
  GDSBreakpoint,
  GDSCustomizableComponent,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  GDSResponsiveProp,
  GDSSpacingValues,
} from '../../types';
import { styled, GDSVariantProps } from '../../theme';
import { breakpoints } from '../../breakpoints/constants';
import { isColumn } from './column';
import {
  useResponsivePropCallback,
  useResponsiveProp,
} from '../../hooks/use-responsive-prop';

export type GDSColumnLayoutBackground = GDSVariantProps<
  typeof ColumnLayoutBase
>['background'];

export type GDSColumnLayoutProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      children?: React.ReactNode;
      className?: string;
      background?: GDSColumnLayoutBackground;
      as?: React.ElementType;
      spacingStart?: GDSResponsiveProp<GDSSpacingValues>;
      spacingEnd?: GDSResponsiveProp<GDSSpacingValues>;
      onBreakpointChange?: (args: {
        breakpoint: GDSBreakpoint;
        columns: ColumnCount;
      }) => any;
    }
  > &
    GDSCustomizableComponent &
    HTMLAttributes<HTMLElement>;

export enum ColumnCount {
  mobile = 4,
  mobileLandscape = 8,
  tablet = 8,
  laptop = 12,
  desktop = 12,
}

const ColumnLayoutBase = styled('div', {
  display: 'grid',
  gap: '$oneAndHalf',
  paddingInlineStart: '$$spacingStart',
  paddingInlineEnd: '$$spacingEnd',
  '@mobile': {
    gridTemplateColumns: `repeat(${ColumnCount.mobile}, 1fr)`,
    gap: '$one',
  },
  '@mobileLandscape': {
    gridTemplateColumns: `repeat(${ColumnCount.mobileLandscape}, 1fr)`,
  },
  '@tablet': {
    gridTemplateColumns: `repeat(${ColumnCount.tablet}, 1fr)`,
  },
  '@laptop': {
    gridTemplateColumns: `repeat(${ColumnCount.laptop}, 1fr)`,
  },
  '@desktop': {
    gridTemplateColumns: `repeat(${ColumnCount.desktop}, 1fr)`,
    maxWidth: breakpoints.desktop,
  },
  variants: {
    background: {
      primary: {
        backgroundColor: '$surfaceBackgroundPrimary',
      },
      secondary: {
        backgroundColor: '$surfaceBackgroundSecondary',
      },
      highlight: {
        backgroundColor: '$surfaceBackgroundTertiary',
      },
      transparent: {
        backgroundColor: 'transparent',
      },
    },
  },
});

export type GDSColumnLayoutComponent = <C extends React.ElementType = 'div'>(
  props: GDSColumnLayoutProps<C>,
) => React.ReactElement | null;

export const ColumnLayout: GDSColumnLayoutComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      onBreakpointChange,
      className,
      css,
      children,
      background = 'primary',
      spacingStart = {
        '@initial': '$oneAndHalf',
        '@mobile': '$oneAndHalf',
        '@mobileLandscape': '$two',
        '@tablet': '$two',
        '@laptop': '$twoAndHalf',
        '@desktop': '$twoAndHalf',
      },
      spacingEnd = {
        '@initial': '$oneAndHalf',
        '@mobile': '$oneAndHalf',
        '@mobileLandscape': '$two',
        '@tablet': '$two',
        '@laptop': '$twoAndHalf',
        '@desktop': '$twoAndHalf',
      },
      ...props
    }: GDSColumnLayoutProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const activeBreakpoint = useActiveBreakpoint();
    const getResponsivePropValue = useResponsivePropCallback();
    const enhancedChildren = useMemo(() => {
      let currentColumn: number = 1;
      return React.Children.map(children, (child) => {
        // for the Column's `offset` property to work, we need to keep track of it's index
        if (React.isValidElement(child) && isColumn(child)) {
          const width = getResponsivePropValue(child?.props?.width) ?? 0;
          const offset = getResponsivePropValue(child?.props?.offset) ?? 0;
          const startColumn = currentColumn + offset;
          currentColumn = startColumn + width;
          return React.cloneElement(child as React.ReactElement<any>, {
            startColumn,
          });
        }

        return null;
      });
    }, [children, getResponsivePropValue]);

    useEffect(() => {
      if (activeBreakpoint)
        onBreakpointChange?.({
          breakpoint: activeBreakpoint,
          columns: ColumnCount[activeBreakpoint],
        });
    }, [activeBreakpoint, onBreakpointChange]);

    return (
      <ColumnLayoutBase
        ref={ref}
        background={background}
        className={['GDS-column-layout', className].join(' ')}
        css={{
          $$spacingStart: `$space${useResponsiveProp(spacingStart)}`,
          $$spacingEnd: `$space${useResponsiveProp(spacingEnd)}`,
          ...css,
        }}
        {...props}
      >
        {enhancedChildren}
      </ColumnLayoutBase>
    );
  },
);

// @ts-ignore
ColumnLayout.displayName = 'ColumnLayout';
