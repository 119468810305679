import { MasonryEngineNodeRendererMap } from '@leagueplatform/masonry-engine';
import { WidgetType, LoaderType } from '@leagueplatform/masonry-api';
import {
  CarouselWSRenderer,
  ExtraSmallSkeletonWSRenderer,
  HeroBannerWSRenderer,
  HeroBannerWSSkeletonRenderer,
  ImageFooterWSRenderer,
  LargeSkeletonWSRenderer,
  LargeVisualCardWSRenderer,
  LayoutWSRenderer,
  MetricCardWSRenderer,
  NotificationBannerWSRenderer,
  NotificationCardWSRenderer,
  ProgressBarCardWSRenderer,
  ProgressRingCardWSRenderer,
  SectionHeaderWSRenderer,
  SmallSkeletonWSRenderer,
  SmallVisualCardWSRenderer,
  TextAndCTACardWSRenderer,
  TwoUpCardWSRenderer,
  WelcomeBanner,
  XSVisualCardWSRenderer,
  type MasonryWSDriverNode,
} from '@leagueplatform/masonry-widgets';
import {
  MasonrySpinner,
  MasonrySpinnerNode,
} from 'components/spinner/spinner.component';

export const MasonryWSDriverDefaultNodeRendererMap: MasonryEngineNodeRendererMap<
  MasonryWSDriverNode | MasonrySpinnerNode
> = {
  [LoaderType.EXTRA_SMALL]: ExtraSmallSkeletonWSRenderer,
  [LoaderType.HERO_BANNER]: HeroBannerWSSkeletonRenderer,
  [LoaderType.LARGE]: LargeSkeletonWSRenderer,
  [LoaderType.SMALL]: SmallSkeletonWSRenderer,
  [WidgetType.GENERIC_CAROUSEL]: CarouselWSRenderer,
  [WidgetType.HERO_BANNER]: HeroBannerWSRenderer,
  [WidgetType.HIDDEN]: () => null,
  [WidgetType.IMAGE_FOOTER]: ImageFooterWSRenderer,
  [WidgetType.LARGE_VISUAL_CARD]: LargeVisualCardWSRenderer,
  [WidgetType.METRIC_CARD]: MetricCardWSRenderer,
  [WidgetType.NOTIFICATION_BANNER]: NotificationBannerWSRenderer,
  [WidgetType.NOTIFICATION_CARD]: NotificationCardWSRenderer,
  [WidgetType.PROGRESS_BAR]: ProgressBarCardWSRenderer,
  [WidgetType.PROGRESS_RING]: ProgressRingCardWSRenderer,
  [WidgetType.SECTION_HEADER]: SectionHeaderWSRenderer,
  [WidgetType.SMALL_VISUAL_CARD]: SmallVisualCardWSRenderer,
  [WidgetType.STACK]: LayoutWSRenderer,
  [WidgetType.TEXT_WITH_CTA_CARD]: TextAndCTACardWSRenderer,
  [WidgetType.TWO_UP_CARD]: TwoUpCardWSRenderer,
  [WidgetType.WELCOME_BANNER]: WelcomeBanner,
  [WidgetType.XS_VISUAL_CARD]: XSVisualCardWSRenderer,
  containerSpinner: MasonrySpinner,
};
