// Imports types for FHIR
// Using r5 for now. May need to discuss supporting r4/r4b
import {
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from 'fhir/r4';

// Handlers for questions/answers by type
import {
  handleAnswerByType,
  handleQuestionByType,
} from './item-and-answer-handlers-by-type.util';

// Generates the answer array for items with nested items.
const handleAnswerWithNestedItem = (
  item: QuestionnaireItem,
  values: { [x: string]: any },
) => {
  // Checks if an answer to the item exists
  if (values[item.linkId]) {
    // If yes, generates the correct answer object
    const answer = handleAnswerByType(item.type, values[item.linkId]);
    // If the item has a nested item, the nested item needs to be nested inside the answer array.
    return {
      linkId: item.linkId,
      answer: [answer, { item: item.item }],
    };
  }
  // If the item has no answer, we're returning an empty object so it can be filtered out.
  return {};
};

// Generate an answer array for an item with no nested items.
const handleAnswerWithoutNestedItem = (
  item: QuestionnaireItem,
  values: { [x: string]: any },
) => {
  const answer: QuestionnaireResponseItemAnswer[] = handleAnswerByType(
    item.type,
    values[item.linkId],
  );
  // If an answer exists we need to pass it to the answer array.
  if (values[item.linkId]) {
    return {
      linkId: item.linkId,
      answer,
    };
  }
  // If the item has no answer, we're returning an empty object so it can be filtered out.
  return {};
};

const handleAnswerArray = (item: any, values: { [x: string]: any }) => {
  // Since groups will never have an answer and should always have nested items we can handle them separately.
  // We do need to maintain the nesting of grouped items for the response.
  if (item.type === 'group') {
    return {
      linkId: item.linkId,
      item: item.item,
    };
  }
  // Items with type of display will never have an answer and need to be
  if (item.type === 'display') {
    return {
      linkId: item.linkId,
    };
  }
  // Handle if the item has a nested item.
  if (item.item) {
    return handleAnswerWithNestedItem(item, values);
  }
  // Handle if the item doesn't have a nested item.
  return handleAnswerWithoutNestedItem(item, values);
};

// Iterates over the array of items regardless of depth and allows us to make any manipulations we want to make.
const iterateOverQuestionnaireToGenerateFrontEnd = (
  itemArray: QuestionnaireItem[],
) =>
  itemArray.map((item: QuestionnaireItem) => {
    // Allows us to manipulate items depending on their type
    // This logic has been extracted for clarity and to make it easier to extend.

    const parsedItem = handleQuestionByType(item);
    // If the item has a nested item we need to iterate one level deeper.
    if (item.item) {
      parsedItem.item = iterateOverQuestionnaireToGenerateFrontEnd(item.item);
      return parsedItem;
    }
    // Otherwise we return the updated item
    return parsedItem;
  });

// Iterates over the questionnaire item and cross references the fieldValues provided by the form to generate the QuestionnaireResponse
const iterateOverQuestionnaireItemToGenerateResponse = (
  itemArray: QuestionnaireItem[],
  values: { [x: string]: any },
): any =>
  itemArray
    .map((item: QuestionnaireItem) => {
      // If the item is nested, we need to iterate down another level.
      // Need to be flexible as there is no real limit to how deeply things can be nested.
      if (item.item) {
        return iterateOverQuestionnaireItemToGenerateResponse(
          item.item,
          values,
        );
      }
      // When we reach the end, we build the answer array.
      return handleAnswerArray(item, values);
    })
    .filter(
      (item: QuestionnaireResponseItem | { linkId?: string }) => item?.linkId,
    );

const generateQuestionnaireResponse = (
  FHIRQuestionnaire: Questionnaire,
  values: { [x: string]: any },
) => {
  // Check to make sure there are items attached to the questionnaire.
  if (FHIRQuestionnaire?.item && FHIRQuestionnaire.item.length > 0) {
    const questionnaireResponseItem = FHIRQuestionnaire.item
      .map((questionnaireItem) => {
        if (questionnaireItem.item) {
          // If there's a nested item, need to iterate over it.
          const responseItem = iterateOverQuestionnaireItemToGenerateResponse(
            questionnaireItem.item,
            values,
          );
          // Build the answer array based on type and override the original "item" array.
          return handleAnswerArray(
            { ...questionnaireItem, item: responseItem },
            values,
          );
        }
        return handleAnswerArray(questionnaireItem, values);
      })
      .filter((item: any) =>
        item?.item ? item.item.length > 0 : item?.linkId,
      );
    return {
      resourceType: 'QuestionnaireResponse',
      questionnaire: FHIRQuestionnaire.url || '',
      item: questionnaireResponseItem,
      status: 'completed',
    } as QuestionnaireResponse;
  }
  return null;
};

const validateItemsKeys = (items: QuestionnaireItem[]) => {
  const requiredKeys = ['linkId', 'type'];
  const invalidItems = items.filter((item) => {
    const itemKeys = Object.keys(item);
    const hasAllRequiredFields = requiredKeys.every((key) =>
      itemKeys.includes(key),
    );
    return !hasAllRequiredFields;
  });
  return invalidItems.length > 0;
};

export const getParsedFHIRQuestionnaireAndResponseBuilder = (
  FHIRQuestionnaire: Questionnaire,
) => {
  if (FHIRQuestionnaire?.item && FHIRQuestionnaire.item.length > 0) {
    const hasInvalidItems = validateItemsKeys(FHIRQuestionnaire.item);
    if (hasInvalidItems)
      return {
        isError: true,
        FHIRQuestionnaireItems: [],
        buildFHIRQuestionnaireResponse: () => {},
      };

    // Defines a callback function that can be called from the FE to build the response without needing to provide the questionnaire again.
    const buildFHIRQuestionnaireResponse = (values: { [x: string]: any }) =>
      generateQuestionnaireResponse(FHIRQuestionnaire, values);

    // Map over items in the questionnaire
    const FHIRQuestionnaireItems = iterateOverQuestionnaireToGenerateFrontEnd(
      FHIRQuestionnaire.item,
    );
    return {
      isError: false,
      FHIRQuestionnaireItems,
      buildFHIRQuestionnaireResponse,
    };
  }
  return {
    isError: false,
    FHIRQuestionnaireItems: [],
    buildFHIRQuestionnaireResponse: () => {},
  };
};
