/* eslint-disable class-methods-use-this */
import type {
  ConnectAPILeagueUser,
  EmbeddedAuthWithMobileHandoffInitConfig,
  EmbeddedAuthWithPartnerTokenInitConfig,
} from './auth-embedded.types';

const MISSING_CONFIG_ERROR_MESSAGE =
  'You must initialize this module with a getToken callback';

/**
 * Given an auth token, returns a Headers object to authenticate
 * `fetch` requests with.
 */
const getHeaders = (hostToken: string) => ({
  Authorization: `Bearer ${hostToken}`,
});

/**
 * Given an auth token and the base URL of the League API, returns a League User
 * data object if a user associated with that token exists, or `undefined` if no such user is found.
 */
const getBackendUser = async (token: string, apiUrl: string) => {
  const response = await fetch(`${apiUrl}/v1/linked-primary-partner-user`, {
    headers: getHeaders(token),
  });

  if (response.ok) {
    // A user was found.
    return ((await response.json()) as ConnectAPILeagueUser).data.attributes;
  }
  if (response.status === 404) {
    // No user was found.
    return undefined;
  }

  /**
   * We got an error response when trying to get an existing user,
   * but it's not a "user not found" error, so we consider it unexpected.
   */
  throw Error(await response.text());
};

/**
 * Given an auth token and the base URL of the League API, creates a League User
 * associated with that token in the backend and returns its data object.
 */
const createBackendUser = async (token: string, apiUrl: string) => {
  const response = await fetch(`${apiUrl}/v1/linked-primary-partner-users`, {
    method: 'POST',
    headers: getHeaders(token),
  });

  if (response.ok) {
    return ((await response.json()) as ConnectAPILeagueUser).data.attributes;
  }

  // An unexpected error happened when trying to create a user.
  throw Error(await response.text());
};

/**
 * Given an auth token, a League user ID, and the base URL of the League API,
 * calls an "update" API endpoint that will synchronize the user profile details
 * with data assumed to exist in the token's claims.
 */
const updateBackendUser = async (
  token: string,
  userId: string,
  apiUrl: string,
) => {
  const response = await fetch(
    `${apiUrl}/v1/linked-primary-partner-user/${userId}`,
    {
      method: 'PATCH',
      headers: getHeaders(token),
    },
  );
  if (response.status >= 400) {
    throw Error(await response.text());
  }
};

export class EmbeddedAuthWrapper {
  /**
   * Get the token provided by the host app.
   */
  async getToken(): Promise<string> {
    throw new Error(MISSING_CONFIG_ERROR_MESSAGE);
  }

  #leagueUser: ConnectAPILeagueUser['data']['attributes'] | undefined;

  /**
   * Get the League user data object returned by the Linked Primary Partner API
   * call.
   */
  async getUserId(): Promise<string> {
    throw new Error(MISSING_CONFIG_ERROR_MESSAGE);
  }

  initializeForMobileHandoff(config: EmbeddedAuthWithMobileHandoffInitConfig) {
    if (!config) {
      throw new Error(MISSING_CONFIG_ERROR_MESSAGE);
    }

    this.getToken = () => config.getToken();

    this.getUserId = () => config.getUserIdFromMobileHandoff();
  }

  /**
   * Initialize this module. Returns a promise which can be awaited, if desired,
   * for confirmation that initialization is complete, but it is not necessary
   * to do so before using `getToken()` or `getLeagueUser()`.
   */
  async initializeForPartnerToken(
    config: EmbeddedAuthWithPartnerTokenInitConfig,
  ) {
    if (!config) {
      throw new Error(MISSING_CONFIG_ERROR_MESSAGE);
    }

    /**
     * We will create a promise, and store its `resolve` and `reject` callbacks
     * in these closures. Using these, we can resolve or reject this promise
     * from outside its own constructor - specifically, we will resolve it
     * when we are successful with the asynchronous aspects of this `initialize` function,
     * or reject it we fail.
     *
     * Then, we can await this promise inside the `getToken` and `getLeagueUser`
     * methods, allowing consumers to call those functions without awaiting the `initialize`
     * method - in effect making `initialize()` a sync function, while still ensuring
     * that the token/user data is not actually *given* to the consumer before we're
     * done with any underlying asynchronous work.
     */
    let resolveInitializationPromise: () => void;
    let rejectInitializationPromise: (reason: unknown) => void;

    const initializationPromise = new Promise<void>((resolve, reject) => {
      resolveInitializationPromise = resolve;
      rejectInitializationPromise = reject;
    });

    /**
     * Re-assign to be an actual implemenation (see initial value above
     * for context).
     */
    this.getToken = async () => {
      // we don't want to resolve this promise until/unless we're done initializing.
      await initializationPromise;

      return config.getToken();
    };

    /**
     * Re-assign to be an actual implemenation (see initial value above
     * for context).
     */
    this.getUserId = async () => {
      // we don't want to resolve this promise until we're done initializing.
      await initializationPromise;

      // if we have successfully initialized, then `this.#leagueUser` is necessarily set.
      return this.#leagueUser!.leagueUserId;
    };

    const token = await config.getToken();

    try {
      /**
       * we now need to communicate with the Linked Primary Partner API, to pass
       * the partner token and get (or create) a corresponding League user entry.
       */

      /**
       * First, we try to get an existing user, if one exists.
       */
      this.#leagueUser = await getBackendUser(
        token,
        config.apiBaseUrlForLinkingPartnerToken,
      );

      if (!this.#leagueUser) {
        // No user was found. We need to create one.
        this.#leagueUser = await createBackendUser(
          token,
          config.apiBaseUrlForLinkingPartnerToken,
        );
      }
    } catch (e) {
      /**
       * something failed in the process of getting/creating a League user.
       * We'll reject the initialization promise (thereby throwing the reason
       * up to the `getToken()` and `getLeagueUser()` methods)
       * and also throw the reason immediately to the caller of `initialize()`.
       */
      rejectInitializationPromise!(e);
      throw e;
    }
    /**
     * We have successfully gotten or created a League user. We'll resolve the
     * initialization promise so that `getToken()` and `getLeagueUser()` are allowed
     * to proceed with resolving to their respective values.
     */
    resolveInitializationPromise!();

    /**
     * Finally, we update the backend League user's
     * profile.
     */
    await updateBackendUser(
      token,
      this.#leagueUser.leagueUserId,
      config.apiBaseUrlForLinkingPartnerToken,
    );
  }
}

export const EmbeddedAuth = new EmbeddedAuthWrapper();
