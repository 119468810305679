import React from 'react';
import {
  HeadingText,
  ParagraphText,
  styled,
  Box,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import {
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
  usePageViewAnalytics,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ErrorState,
  LoadingIndicator,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';
import {
  getModulePath,
  LEAGUE_MODULE_NAMES,
  useConfigProperty,
} from '@leagueplatform/core';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { RecentClaims } from '@leagueplatform/claims-b2b';
import { AddDependentBanner } from '@leagueplatform/dependents';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { CardLink } from 'components/card-link/card-link.component';
import { GridContainer } from 'components/grid-layout/grid-layout.component';
import { WalletItemNavigationSections } from './components/wallet-item-navigation-sections/wallet-item-navigation-sections.component';
import { WalletEnrollment } from './components/wallet-enrollment-state/wallet-enrollment.component';
import { useWalletLanding } from './hooks/use-wallet-landing.hook';

const withBrandingHeaderHeight = '232px';
const maximumWidth = 1280;

const Wrapper = styled(Box, {
  '@desktop': {
    marginLeft: 'calc(100vw - 100%)',
    marginRight: 0,
  },
});

const SidebarLayout = styled(Box, {
  gap: '$two',
  flexWrap: 'wrap',
  display: 'flex',

  '& > :first-child': {
    flexGrow: 1,
    flexBasis: '30ch',
  },

  '& > :last-child': {
    flexBasis: 0,
    flexGrow: 999,
    minInlineSize: '50%',
  },
});

function WalletLandingHeader() {
  const { formatMessage } = useIntl();
  const {
    showClaims,
    showLandingPageHeaderBranding,
    showLandingPageClaimsEntry,
  } = useWalletLanding();
  const groupLogo = useConfigProperty('core.ui.groupLogo');

  return (
    <PageHeaderBackground
      pageHeaderBackgroundHeight={
        showLandingPageHeaderBranding ? withBrandingHeaderHeight : undefined
      }
      height={
        showLandingPageHeaderBranding ? withBrandingHeaderHeight : undefined
      }
    >
      <Box
        css={{
          display: 'flex',
          width: '100%',
          maxWidth: maximumWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
          justifyContent: 'space-between',
          marginTop: '$twoAndHalf',
        }}
      >
        <StackLayout spacing="$two" css={{ flexDirection: 'column' }}>
          {showLandingPageHeaderBranding && groupLogo && groupLogo.element}
          <StackLayout spacing="$threeQuarters">
            <HeadingText size="xxl" level="1">
              {formatMessage({ id: 'WALLET' })}
            </HeadingText>
            {showLandingPageHeaderBranding && (
              <ParagraphText emphasis="subtle">
                {showLandingPageClaimsEntry
                  ? formatMessage({ id: 'WALLET_WITH_CLAIMS_LANDING_TITLE' })
                  : formatMessage({ id: 'WALLET_LANDING_TITLE' })}
              </ParagraphText>
            )}
          </StackLayout>
        </StackLayout>
        {showClaims && (
          <Image
            src={handleStaticAsset(WALLET_ASSET_KEYS.WALLET_IMAGE) as string}
            role="presentation"
            width={140}
            height={132}
          />
        )}
      </Box>
    </PageHeaderBackground>
  );
}

export function WalletLandingPage() {
  const { formatMessage } = useIntl();

  useDocumentTitle(formatMessage({ id: 'WALLET' }));
  usePageViewAnalytics({
    title: SCREEN_NAMES.HOME_PAGE,
    product_area: PRODUCT_AREA.WALLET,
  });

  const {
    enrollmentEndDate,
    groupId,
    isFirstTimeEnrollment,
    isGridLayoutEnabled,
    isLoading,
    isError,
    isEmpty,
    isNewHireEnrollment,
    showClaims,
    showLandingPageHeaderBranding,
    showPlanSelections,
    showQLEBanner,
    userId,
    userRole,
    showLandingPageClaimsEntry,
    slots,
  } = useWalletLanding();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <Wrapper css={{ padding: '$none $two' }}>
        <WalletLandingHeader />
        <Box css={{ marginTop: '$three' }}>
          <ErrorState />
        </Box>
      </Wrapper>
    );
  }

  if (isFirstTimeEnrollment) {
    return (
      <WalletEnrollment
        enrollmentEndDate={enrollmentEndDate}
        showPlanSelections={showPlanSelections}
        isNewHireEnrollment={isNewHireEnrollment}
        groupId={groupId}
      />
    );
  }

  const Banner = slots?.Banner;

  if (isEmpty) {
    return (
      <Wrapper css={{ padding: '$none $two' }}>
        <WalletLandingHeader />

        {Banner ? <Banner /> : null}
        <StackLayout
          spacing="$three"
          css={{
            // @ts-ignore-next-line
            marginBlockStart: '$two',
            ...(!showLandingPageClaimsEntry && {
              marginBlockStart: '$five',
              alignItems: 'center',
              justifyContent: 'center',
            }),
          }}
        >
          {showLandingPageClaimsEntry && (
            <>
              <StackLayout spacing="$one">
                <HeadingText size="xl" level="2">
                  {formatMessage({ id: 'CLAIMS' })}
                </HeadingText>
                <GridContainer
                  width="100%"
                  css={{ '& :first-child': { gridColumn: 'span 2' } }}
                >
                  <Box>
                    <CardLink
                      to={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                      title={formatMessage({ id: 'CLAIMS_BENEFIT_CARD_TITLE' })}
                      subtitle={formatMessage({
                        id: 'CLAIMS_BENEFIT_CARD_SUBTITLE',
                      })}
                      icon={
                        handleStaticAsset(
                          WALLET_ASSET_KEYS.CLAIMS_ENTRY,
                        ) as string
                      }
                      onClick={() =>
                        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                          product_area: PRODUCT_AREA.WALLET,
                          sub_product_area: SUB_PRODUCT_AREA.CLAIMS,
                          screen_name: SCREEN_NAMES.HOME_PAGE,
                          details: 'view insurance claims',
                        })
                      }
                    />
                  </Box>
                </GridContainer>
              </StackLayout>
              <HeadingText size="xl" level="2">
                {formatMessage({ id: 'COVERAGE' })}
              </HeadingText>
            </>
          )}

          <ErrorState
            headingString={formatMessage({
              id: 'WALLET_LANDING_BENEFITS_EMPTY_STATE_TITLE',
            })}
            bodyString={formatMessage({
              id: 'WALLET_LANDING_BENEFITS_EMPTY_STATE_DESCRIPTION',
            })}
            hideButton
          />
        </StackLayout>
      </Wrapper>
    );
  }

  return (
    <Wrapper css={{ padding: '$none $two' }}>
      <WalletLandingHeader />
      {Banner ? <Banner /> : null}
      <SidebarLayout
        css={{
          maxWidth: maximumWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: showLandingPageHeaderBranding
            ? '$two'
            : '$minusTwoAndHalf',
          marginBottom: '$two',
        }}
      >
        {!isGridLayoutEnabled && (
          <Box css={{ minWidth: 280 }}>
            <WalletItemNavigationSections
              userRole={userRole}
              userId={userId}
              layout="sidebar"
            />
          </Box>
        )}
        <StackLayout spacing="$three" css={{ alignItems: 'normal' }}>
          {!showLandingPageHeaderBranding && (
            <StackLayout
              spacing="$oneAndHalf"
              css={{
                marginTop: '$five',
                '@mobile': {
                  marginTop: '$none',
                },
              }}
            >
              <HeadingText level="2" size="xl">
                {formatMessage({ id: 'WALLET_LANDING_TITLE' })}
              </HeadingText>
              <ParagraphText emphasis="subtle">
                {formatMessage({ id: 'WALLET_LANDING_BODY' })}
              </ParagraphText>
            </StackLayout>
          )}
          {showLandingPageClaimsEntry && (
            <StackLayout spacing="$one">
              <HeadingText size="xl" level="2">
                {formatMessage({ id: 'CLAIMS' })}
              </HeadingText>
              <GridContainer
                width="100%"
                css={{ '& :first-child': { gridColumn: 'span 2' } }}
              >
                <Box>
                  <CardLink
                    to={getModulePath(LEAGUE_MODULE_NAMES.claims)}
                    title={formatMessage({ id: 'CLAIMS_BENEFIT_CARD_TITLE' })}
                    subtitle={formatMessage({
                      id: 'CLAIMS_BENEFIT_CARD_SUBTITLE',
                    })}
                    icon={
                      handleStaticAsset(
                        WALLET_ASSET_KEYS.CLAIMS_ENTRY,
                      ) as string
                    }
                    onClick={() =>
                      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                        product_area: PRODUCT_AREA.WALLET,
                        sub_product_area: SUB_PRODUCT_AREA.CLAIMS,
                        screen_name: SCREEN_NAMES.HOME_PAGE,
                        details: 'view insurance claims',
                      })
                    }
                  />
                </Box>
              </GridContainer>
            </StackLayout>
          )}
          {isGridLayoutEnabled && (
            <WalletItemNavigationSections
              userRole={userRole}
              userId={userId}
              showHeading={showLandingPageClaimsEntry}
            />
          )}
          {showClaims ? (
            <RecentClaims userId={userId} />
          ) : (
            !showLandingPageHeaderBranding && (
              <Image
                src={
                  handleStaticAsset(WALLET_ASSET_KEYS.WALLET_IMAGE) as string
                }
                role="presentation"
                width="200"
                height="190"
                display="block"
                marginX="auto"
              />
            )
          )}
          {showQLEBanner && (
            <Box>
              <AddDependentBanner />
            </Box>
          )}
        </StackLayout>
      </SidebarLayout>
    </Wrapper>
  );
}
