import { css } from 'styled-components';
import { vars } from '../vars';
import { verticalBox, flexAlign, flexPack } from './flexible';

export function fullScreen() {
  return css`
    position: relative;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    @media print {
      position: relative;
      width: 100%;
      height: auto;
    }
  `;
}

export function scrollable(
  options = { vertical: false, horizontal: false, hideScrollBar: false },
) {
  return css`
    -webkit-overflow-scrolling: touch;
    ${options.vertical ? 'overflow-y: auto;' : 'overflow-y: hidden;'}
    ${options.horizontal ? 'overflow-x: auto;' : 'overflow-x: hidden;'}
    ${options.hideScrollBar
      ? `
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }

      &::-webkit-scrollbar:vertical, &::-webkit-scrollbar:horizontal {
          width: 0px;
          height: 0px;
        }
      }
    `
      : ''}
  `;
}

export function textEllipsis() {
  return css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
}

export function multilineEllipsis(numberOfLines) {
  return css`
    /* This css is old. I don't want to try to update it, but it doesn't pass linter cause it's using outdated flex-box props */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${numberOfLines};
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export function fixedWidthCentered(width) {
  return css`
    ${verticalBox()};
    ${flexAlign('center')};
    ${flexPack('start')};

    > * {
      width: 100%;
      max-width: ${width || vars.fixedWidth}px;
    }
  `;
}

export function smallFixedWidthCentered() {
  return fixedWidthCentered(vars.smallFixedWidth);
}
