import React, { PropsWithChildren, createContext, useContext } from 'react';

type TabListContextProps = {
  tabListWrapper: HTMLDivElement | null;
  scrollLeftButton: HTMLButtonElement | null;
  scrollRightButton: HTMLButtonElement | null;
};

type TabListContextProviderProps = {
  tabListElements: TabListContextProps;
  children: React.ReactNode;
};

export const TabListContext = createContext<TabListContextProps | null>(null);

export const useTablistContext = () => {
  const tabListContext = useContext(TabListContext);

  if (!tabListContext) {
    throw new Error(
      'tabListContext has to be used within <TabListContext.Provider>',
    );
  }

  return tabListContext;
};

export const TabListContextProvider: React.FunctionComponent<
  PropsWithChildren<TabListContextProviderProps>
> = ({ tabListElements, children }) => (
  <TabListContext.Provider value={tabListElements}>
    {children}
  </TabListContext.Provider>
);
