import type { ErrorContext } from '@leagueplatform/observability';

export const getObservabilityContext = (
  context: ErrorContext,
): ErrorContext => ({
  ...context,
  tags: {
    dependentModule: 'masonry',
    supportTeam: 'masonry-web',
    ...context?.tags,
  },
});
