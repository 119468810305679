import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import type { Traits } from '@leagueplatform/masonry-api';
import { SupportedMedia } from '@leagueplatform/masonry-api';
import { DefaultOrientationMap } from '../utils/default-orientation-map';

export const useOrientationFromMediaQuery = ({
  defaultOrientation,
  traits,
}: {
  defaultOrientation: any;
  traits?: Traits;
}) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const isTablet = useMediaQuery(queryHelpers.only('tablet'));
  const isLaptop = useMediaQuery(queryHelpers.only('laptop'));
  const isDesktop = useMediaQuery(queryHelpers.up('desktop'));

  if (isMobile)
    return (
      traits?.mobile?.orientation || defaultOrientation[SupportedMedia.MOBILE]
    );
  if (isTablet)
    return (
      traits?.tablet?.orientation || defaultOrientation[SupportedMedia.TABLET]
    );
  if (isLaptop)
    return (
      traits?.laptop?.orientation || defaultOrientation[SupportedMedia.LAPTOP]
    );
  if (isDesktop)
    return (
      traits?.desktop?.orientation || defaultOrientation[SupportedMedia.DESKTOP]
    );

  return DefaultOrientationMap.Default;
};
