export enum InputType {
  QUANTITY = 'quantity',
  ENUM = 'enum',
  PERIOD = 'period',
}

export enum FieldType {
  QUANTITY = 'quantity',
  DATETIME = 'date-time',
}
