import React, { forwardRef, useMemo } from 'react';
import { Questionnaire } from 'fhir/r4';
import { getParsedFHIRQuestionnaireAndResponseBuilder } from 'utils/fhir-parser/fhir-parser.util';
import { FieldValues, FormProvider } from '@leagueplatform/web-common';
import { ErrorState } from '@leagueplatform/web-common-components';
import {
  Button,
  Fieldset,
  HeadingText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { RadioGroup } from 'components/radio-group/radio-group.component';
import { CheckboxGroup } from 'components/checkbox-group/checkbox-group.component';
import { Display } from 'components/display/display.component';
import { TextInput } from 'components/text-input/text-input.component';
import { TextAreaInput } from 'components/text-area-input/text-area-input.component';
import { BooleanGroup } from 'components/boolean-group/boolean-group.component';
import { SelectInput } from 'components/select-input/select-input.component';
import { ParsedQuestionnaireItem } from 'types/questionnaire.types';
import { useIntl } from '@leagueplatform/locales';

export interface DynamicFHIRFormProps {
  FHIRQuestionnaire: Questionnaire;
  setQuestionnaireAnswer: React.Dispatch<React.SetStateAction<any>>;
  handleSubmitInParent: boolean;
}

export const DynamicFHIRForm = forwardRef<
  HTMLButtonElement,
  DynamicFHIRFormProps
>(
  (
    {
      FHIRQuestionnaire,
      setQuestionnaireAnswer,
      handleSubmitInParent,
    }: DynamicFHIRFormProps,
    ref,
  ) => {
    const { formatMessage } = useIntl();
    const { isError, FHIRQuestionnaireItems, buildFHIRQuestionnaireResponse } =
      useMemo(() => {
        const parsedFHIRQuestionnaireAndResponseBuilder =
          getParsedFHIRQuestionnaireAndResponseBuilder(FHIRQuestionnaire);
        return {
          isError: parsedFHIRQuestionnaireAndResponseBuilder.isError,
          FHIRQuestionnaireItems:
            parsedFHIRQuestionnaireAndResponseBuilder.FHIRQuestionnaireItems,
          buildFHIRQuestionnaireResponse:
            parsedFHIRQuestionnaireAndResponseBuilder.buildFHIRQuestionnaireResponse,
        };
      }, [FHIRQuestionnaire]);

    const handleSubmit = (data: FieldValues) => {
      const response = buildFHIRQuestionnaireResponse(data);
      setQuestionnaireAnswer(response);
    };

    const renderQuestionnaireItems = (
      questionnaireItems: ParsedQuestionnaireItem[],
    ): (JSX.Element | null)[] =>
      questionnaireItems.map((item: ParsedQuestionnaireItem) => {
        if (item.type === 'boolean') {
          return (
            <BooleanGroup
              key={item.linkId}
              name={item.linkId}
              id={item.linkId}
              legend={item.text}
              required={!!item.required}
            />
          );
        }
        if (item.type === 'choice') {
          if (item.inputType === 'radio-button') {
            return (
              <RadioGroup
                key={item.linkId}
                answerOptions={item.answerOption || [{}]}
                name={item.linkId}
                id={item.linkId}
                legend={item.text}
                required={!!item.required}
              />
            );
          }
          if (item.inputType === 'drop-down') {
            return (
              <SelectInput
                key={item.linkId}
                answerOptions={item.answerOption || [{}]}
                name={item.linkId}
                id={item.linkId}
                legend={item.text}
                required={!!item.required}
              />
            );
          }
          if (item.inputType === 'check-box') {
            return (
              <CheckboxGroup
                key={item.linkId}
                answerOptions={item.answerOption || [{}]}
                name={item.linkId}
                id={item.linkId}
                legend={item.text}
                required={!!item.required}
              />
            );
          }
        }
        if (item.type === 'display') {
          return <Display key={item.linkId} text={item.text} />;
        }
        if (item.type === 'string') {
          return (
            <TextInput
              key={item.linkId}
              id={item.linkId}
              labelText={item.text}
              name={item.linkId}
              required={!!item.required}
              regex={
                item.extension?.find((ext: any) => ext.type === 'regex')
                  ?.valueString
              }
              maxLength={item.maxLength}
              defaultValue={item.initial && item.initial[0]?.valueString}
            />
          );
        }
        if (item.type === 'text') {
          return (
            <TextAreaInput
              key={item.linkId}
              name={item.linkId}
              id={item.linkId}
              labelText={item.text}
              regex={
                item.extension?.find((ext: any) => ext.type === 'regex')
                  ?.valueString
              }
              required={!!item.required}
              maxLength={item.maxLength}
              defaultValue={item.initial && item.initial[0]?.valueString}
            />
          );
        }
        if (item.type === 'group' && item.item) {
          return (
            // TODO: PCHAT-2596 check if fieldset complies with a11y guidelines
            <Fieldset
              key={item.linkId}
              legend={
                <HeadingText level="3" size="sm">
                  {item.text}
                </HeadingText>
              }
            >
              <StackLayout spacing="$one">
                {renderQuestionnaireItems(item.item)}
              </StackLayout>
            </Fieldset>
          );
        }
        return null;
      });

    if (isError) return <ErrorState />;

    return (
      <FormProvider
        formOptions={{
          mode: 'onSubmit',
        }}
        includesFormElement
        onError={() => {
          console.log('error');
        }}
        onSubmit={handleSubmit}
      >
        <StackLayout spacing="$one" css={{ marginTop: '$oneAndHalf' }}>
          <HeadingText level="2" size="xxl">
            {FHIRQuestionnaire?.title}
          </HeadingText>
          {renderQuestionnaireItems(FHIRQuestionnaireItems)}
          {handleSubmitInParent ? (
            <Button type="submit" ref={ref} css={{ visibility: 'hidden' }}>
              {formatMessage({ id: 'SUBMIT' })}
            </Button>
          ) : (
            <Button type="submit">{formatMessage({ id: 'SUBMIT' })}</Button>
          )}
        </StackLayout>
      </FormProvider>
    );
  },
);
