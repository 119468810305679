import { GlobalTokens } from './types';
import { breakpoints } from './breakpoints';
import { defaultBorderWidths as borderWidths } from './borderWidths';
import { globalRadii as radii } from './globalRadii';
import { zIndices } from './zIndices';
import { durations } from './durations';

export const GLOBAL_TOKENS: GlobalTokens = {
  breakpoints,
  borderWidths,
  radii,
  zIndices,
  durations,
};
