import React, { useRef } from 'react';
import {
  Image,
  SubtitleOne,
  Caption,
  SubtitleTwo,
  Box,
  HeadingThree,
  Text,
} from '@leagueplatform/genesis-commons';
import {
  StackLayout,
  ConditionalLinkCard,
  ConditionalLink,
} from '@leagueplatform/web-common-components';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ParagraphText } from '@leagueplatform/genesis-core';
import { BadgePill } from 'common/badge-pill/badge-pill.component';
import { CAROUSEL_CONTENT_CARD_WIDTH } from '../../constants';

export interface CarouselContentCardWidgetProps {
  title: string;
  overline?: string;
  description?: string;
  imageAltText: string;
  image: string;
  badge?: string;
  badgeImage?: string;
  linkUrl: string;
  onClick: (e: React.MouseEvent) => void;
}

export const CarouselContentCardWidget = ({
  title,
  overline,
  description,
  imageAltText,
  badge,
  badgeImage,
  image,
  linkUrl,
  onClick,
}: CarouselContentCardWidgetProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const overlineId = `cc-${title.replace(/\s+/g, '-')}`;

  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(linkUrl)}
      cardStyle={{
        width: CAROUSEL_CONTENT_CARD_WIDTH,
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Image
        src={image}
        alt={imageAltText}
        css={{ width: '100%', height: '176px', objectFit: 'cover' }}
        borderTopLeftRadius="medium"
        borderTopRightRadius="medium"
        backgroundColor="surface.background.secondary"
      />
      <Box margin="oneAndHalf">
        <StackLayout space="$half" css={{ textAlign: 'left' }}>
          <SubtitleOne
            as={HeadingThree}
            aria-describedby={overlineId}
            fontWeight="bold"
          >
            <ConditionalLink ref={linkRef} url={linkUrl} onClick={onClick}>
              {title}
            </ConditionalLink>
          </SubtitleOne>

          {overline && <Caption id={overlineId}>{overline}</Caption>}

          {description && (
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {description}
            </ParagraphText>
          )}
        </StackLayout>
        {badge && (
          <BadgePill>
            {badgeImage && (
              <Image
                src={badgeImage}
                alt=""
                width="2rem"
                height="2rem"
                marginRight="half"
              />
            )}
            <SubtitleTwo
              as={Text}
              color="onSurface.text.primary"
              textAlign="center"
            >
              {badge}
            </SubtitleTwo>
          </BadgePill>
        )}
      </Box>
    </ConditionalLinkCard>
  );
};
