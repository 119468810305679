import merge from 'lodash.merge';
import { isDev } from '@leagueplatform/app-environment';
import { leagueRoutesMap } from '@leagueplatform/league-routes';
import type { ErrorContext } from './sentry/sentry.types';

const ENABLE_DEV_FLAG_STRING = 'ENABLE_DEV_OBSERVABILITY';

/**
 * A list of tenants to disable observability initialization for
 * Note: This overrides the backend config
 */
const DISABLED_CLIENT_IDS = ['presenter-app-web'];

const isAppDisabled = (clientId?: string) =>
  clientId ? DISABLED_CLIENT_IDS.includes(clientId) : false;

export const combineErrorContexts = (contexts: Array<ErrorContext>) =>
  contexts.reduce((previousContext, currentContext) =>
    merge(previousContext, currentContext),
  );

export const isObservabilityEnabled = (clientId?: string) =>
  (!isDev() || sessionStorage.getItem(ENABLE_DEV_FLAG_STRING) === 'true') &&
  !isAppDisabled(clientId);

export const memoizedGetModuleNameFromUrl = (pathname?: string) => {
  const path = pathname || window.location.pathname;

  let cache: { [key: string]: string };
  return () => {
    /**
     * Memoized function that returns module name if path hasn't changed, otherwise:
     * .filter to find if URL includes a League module path
     * .reduce to get the longest path in case of multiple matches
     * cache path, and leagueRoutesMap key as the module name
     * return module name
     */

    if (cache?.path === path) return cache.moduleName;
    const moduleName = Object.entries(leagueRoutesMap)
      .filter(([, value]) => path.includes(String(value)))
      .reduce(
        (longestValue: [string, string], [key, value]) =>
          value.length > longestValue[1].length ? [key, value] : longestValue,
        ['', ''],
      )[0];

    cache = { moduleName, path };
    return moduleName;
  };
};
export const getModuleNameFromUrl = (pathname?: string) =>
  memoizedGetModuleNameFromUrl(pathname);

/**
 * Retrieves the domain name associated with the current module.
 *
 * This function extracts the module name from the current URL using  getModuleNameFromUrl and then
 * searches a predefined map of domain names to module names. If a matching
 * domain is found, its key is returned. Otherwise, the function returns
 * either 'uncategorized' (if the module name is empty or undefined)
 * or the module name itself as a fallback.

*/
type DomainArray = {
  [key: string]: string[];
};
export function getDomain(pathname?: string) {
  const moduleName = getModuleNameFromUrl(pathname)();

  const domains: DomainArray = {
    'about-me': ['about-me'],
    'care-landing': ['care-collections', 'care-discovery', 'care-documents'],
    claims: ['wallet', 'claims'],
    home: ['home'],
    journey: [
      'health-journey',
      'health-journey-history',
      'health-need-pages',
      'health-programs',
      'health-progress',
    ],
    'live-chat': ['messaging', 'live-chat'],
    // Not part of the Web SDK, but we still want to track it (for now)
    'support-experience': ['customer-support'],
  };

  if (moduleName === '' || !moduleName) {
    return 'uncategorized';
  }
  const domainKey = Object.keys(domains).find((key) =>
    domains[key as keyof DomainArray].includes(moduleName),
  );

  return domainKey ?? moduleName;
}

export const getDefaultConfigValues = () => ({
  dsn: 'https://fcb8e7e793e149acacb6ac0ad1546855@o4504039554088960.ingest.sentry.io/4504243159433216',
  enabled: true,
  maxBreadcrumbs: 100,
  errorsSampleRate: 1.0,
  tracesSampleRate: 0.01,
  integrateWithLeagueRouting: true,
});
