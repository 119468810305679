import React from 'react';
import SuccessIcon from 'assets/success-icon.svg';
import ErrorIcon from 'assets/error-icon.svg';
import InfoIcon from 'assets/info-icon.svg';
import { Image } from '@leagueplatform/genesis-commons';

interface ToastIconProps {
  iconType: keyof typeof ICON_TYPES;
}

export enum ICON_TYPES {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export const ToastIcon = ({ iconType }: ToastIconProps) => {
  switch (iconType) {
    case ICON_TYPES.ERROR:
      return <Image src={ErrorIcon} alt="" />;
    case ICON_TYPES.INFO:
      return <Image src={InfoIcon} alt="" />;
    case ICON_TYPES.SUCCESS:
      return <Image src={SuccessIcon} alt="" />;
    default:
      return null;
  }
};
