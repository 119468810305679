import { useLeagueInfiniteWSQuery } from '@leagueplatform/api';
import { normalizePolicyKind } from '@leagueplatform/web-common';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useEffect } from 'react';
import { mapHistoryToTransactions } from '../../utils/map-history-to-transactions.util';

export function useTransactionsQuery({
  productType,
  limit = 50,
}: {
  productType: string;
  limit?: number;
}) {
  const { userId } = useWalletParams();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const message_type = 'get_purchase_and_claim_history';
  const normalizedProductType = normalizePolicyKind(productType);

  const info = {
    user_id: userId,
    payment_types: [normalizedProductType],
    limit,
    also_include: ['free_purchase', 'credits'],
    version: 2,
  };

  // TODO: move to useInfiniteQuery
  const query = useLeagueInfiniteWSQuery(
    [message_type, userId, normalizedProductType],
    {
      message_type,
      info,
      responseKey: 'history',
      offsetParam: 'skip',
    },
    {
      enabled: !!productType,
    },
  );

  useEffect(
    () =>
      // remove query data from cache on unmount
      query.remove,
    [query.remove],
  );

  const flattenedData = query.data?.pages?.flat();
  const data = flattenedData
    ? mapHistoryToTransactions(flattenedData, normalizedProductType)
    : null;

  return { ...query, data };
}
