import React from 'react';
import {
  Box,
  StackLayout,
  TextAction,
  styled,
  queryHelpers,
  useMediaQuery,
  type GDSCustomizableComponent,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

const SkipContentBox = styled(Box, {
  position: 'absolute',
  zIndex: '$dropdown',
  '&:focus-within': {
    // include the focus outline when positioning
    left: '$quarter',
    top: '$quarter',
  },
});

export type AppBarHeaderProps = {
  children: React.ReactNode;
  navHeight?: number;
  enableSkipToContent?: boolean;
  headerCSS?: GDSCustomizableComponent['css'];
};

export const AppBarHeader = ({
  children,
  navHeight = APP_NAV_DEFAULT_HEIGHT,
  enableSkipToContent = true,
  headerCSS,
}: AppBarHeaderProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  return (
    <StackLayout
      id="app_nav"
      as="header"
      role="banner"
      horizontalAlignment="spaceBetween"
      data-testid="react_member_app_nav"
      orientation="horizontal"
      verticalAlignment="stretch"
      css={{
        color: '$onSurfaceTextSubdued',
        maxHeight: navHeight,
        minHeight: navHeight,
        paddingRight: '$quarter',
        paddingLeft: '$quarter',
        backgroundColor: '$surfaceBackgroundPrimary',
        boxShadow: '$dropdown',
        position: 'sticky',
        top: 0,
        zIndex: '$sticky',
        '@laptop': {
          paddingRight: '$one',
          paddingLeft: '$one',
        },
        '@desktop': {
          paddingRight: '$three',
          paddingLeft: '$three',
        },
        ...headerCSS,
      }}
    >
      {enableSkipToContent && (
        <SkipContentBox
          css={{
            top: isMobile ? '-180%' : '-150%',
          }}
        >
          <TextAction
            css={{
              padding: '$half',
              borderColor: '$onSurfaceBorderSubdued',
              borderWidth: 'thin',
              backgroundColor: '$surfaceBackgroundPrimary',
              '&&:focus': {
                outlineOffset: '0',
              },
            }}
            href="#main-content"
          >
            {formatMessage({ id: 'SKIP_TO_CONTENT_MESSAGE' })}
          </TextAction>
        </SkipContentBox>
      )}
      {children}
    </StackLayout>
  );
};
