import React from 'react';
import { CustomWidgetDataHooks } from 'types/custom-widget-data-hooks.types';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import { mergeDefaultAndCustomWidgetData } from 'utils/merge-default-and-custom-widget-data';
import { widgetAnalyticsEvents } from 'utils/widget-analytics-events';
import { AllWidgetProps } from 'types/widget-props.types';
import { MasonryConfig } from '../../types/config.types';

type WidgetWithCustomModuleProviderProps = {
  moduleProviderHook: NonNullable<
    CustomWidgetDataHooks[keyof CustomWidgetDataHooks]
  >;
  widget: ContainerWidgetItem;
  data: any;
  component: React.ElementType;
  config: MasonryConfig;
};

export const WidgetWithCustomModuleProvider = ({
  moduleProviderHook,
  widget,
  data,
  component: Component,
  config,
  ...props
}: WidgetWithCustomModuleProviderProps) => {
  const { type: widgetType } = data;
  const customWidgetData: any = moduleProviderHook({ widget });

  const widgetAttributesAndTraits = mergeDefaultAndCustomWidgetData(
    data,
    customWidgetData,
  );
  const widgetAnalyticsProps = widgetAnalyticsEvents(
    config,
    widgetType,
    widgetAttributesAndTraits as AllWidgetProps,
    data?.product_identifier,
  );
  return (
    <Component
      {...widgetAttributesAndTraits} // eslint-disable-line react/jsx-props-no-spreading
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      {...widgetAnalyticsProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};
