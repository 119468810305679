import { useQuery } from 'react-query';
import {
  getQuickActionsRest,
  getCareProviders,
  QuickActionsData,
  CareProvidersData,
} from '@leagueplatform/care-discovery-api';
import { CARE_TEAM } from '@leagueplatform/care-team';
import { getCareTeam } from '@leagueplatform/care-team-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

export const useGetCareLanding = () => {
  const {
    data: dataQuickActions,
    isLoading: isLoadingQuickActions,
    isError: isErrorQuickActions,
    isSuccess: isSuccessQuickActions,
    error: errorQuickActions,
  } = useQuery<QuickActionsData, Error>(['GET_QUICK_ACTIONS'], () =>
    getQuickActionsRest(),
  );

  const {
    data: dataCareProviders,
    isLoading: isLoadingCareProviders,
    isError: isErrorCareProviders,
    isSuccess: isSuccessCareProviders,
    error: errorCareProvider,
  } = useQuery<CareProvidersData, Error>(['GET_CARE_PROVIDERS'], () =>
    getCareProviders(),
  );

  const { data: careTeamFlag, isLoading: isLoadingCareTeamFlag } =
    useFeatureFlagQuery(CARE_TEAM);

  const { data: dataCareTeam, isLoading: isLoadingCareTeam } = useQuery(
    ['GET_CARE_TEAM'],
    () => getCareTeam(),
    {
      enabled: !!careTeamFlag, // only call if Care Team is enabled for the app
    },
  );

  return {
    isLoading:
      isLoadingQuickActions ||
      isLoadingCareProviders ||
      isLoadingCareTeam ||
      !!isLoadingCareTeamFlag,
    isError: isErrorQuickActions || isErrorCareProviders,
    isSuccess: isSuccessQuickActions && isSuccessCareProviders,
    error: [errorQuickActions, errorCareProvider],
    data: {
      quickActions: dataQuickActions?.data || [],
      careProviders: dataCareProviders?.data || [],
      careTeam: dataCareTeam?.data,
    },
  };
};
