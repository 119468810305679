import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Icon, ICONS, vars } from '@leagueplatform/ui-kit';
import { COVERED, NOT_COVERED, PARTIAL } from '../constants/coverage.constants';
import { CoverageType } from '../types/coverage-type.type';

interface IconProps {
  size?: string | number;
  title: string;
}

interface CoverageIconProps {
  coverage: CoverageType;
  embedded?: boolean;
}

export const CoveredIcon = ({ size, title, ...rest }: IconProps) => (
  <Icon
    icon={ICONS.CHECK}
    size={size}
    colour={vars.colour.functional.green}
    title={title}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

export const NotCoveredIcon = ({ size, title, ...rest }: IconProps) => (
  <Icon
    icon={ICONS.X}
    size={size}
    colour={vars.colour.red}
    title={title}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

export const PartialCoverageIcon = ({ size, title, ...rest }: IconProps) => (
  <Icon
    icon={ICONS.WARNING}
    size={size}
    colour={vars.colour.purple}
    title={title}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

export const CoverageIcon = ({ coverage, embedded }: CoverageIconProps) => {
  const { formatMessage } = useIntl();

  switch (coverage) {
    case COVERED:
      return (
        <CoveredIcon
          title={formatMessage({ id: 'COVERED' })}
          size={embedded ? '20px' : '1.5em'}
        />
      );
    case PARTIAL:
      return (
        <PartialCoverageIcon
          title={formatMessage({ id: 'COVERED_WITH_RESTRICTIONS' })}
          size={embedded ? '20px' : '1.5em'}
        />
      );
    case NOT_COVERED:
      return (
        <NotCoveredIcon
          title={formatMessage({ id: 'NOT_COVERED' })}
          size={embedded ? '20px' : '1.5em'}
        />
      );
    default:
      return null;
  }
};

export const ExampleIcon = (props: any) => (
  <Icon
    icon={ICONS.LIGHT_BULB}
    size="1.5rem"
    colour={vars.colour.yellow}
    aria-hidden
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export const RestrictionIcon = (props: any) => (
  <Icon
    icon={ICONS.ASTERISK}
    size="1.5rem"
    colour={vars.colour.purple}
    aria-hidden
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
