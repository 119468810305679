import React, { useRef } from 'react';
import { type Traits, WidgetType } from '@leagueplatform/masonry-api';
import {
  css,
  Flex,
  genesisStyled,
  SubtitleOne,
  Image,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import {
  StackLayout,
  ConditionalLinkCard,
  ConditionalLink,
} from '@leagueplatform/web-common-components';
import { ParagraphText } from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface XSVisualCardWidgetProps {
  actionLink?: string;
  body?: string;
  heading: string;
  image?: string;
  imageAltText?: string;
  onCardClick?: (e: React.MouseEvent) => void;
  traits?: Traits;
}

export type XSVisualCardWSNode = MasonryEngineNode<
  WidgetType.XS_VISUAL_CARD,
  XSVisualCardWidgetProps
>;

const StyledImage = genesisStyled(Image)(
  css({
    contentFit: 'cover',
    contentPosition: 'center',
    height: '42px',
    width: '42px',
  }),
);

export const XSVisualCardWidget = ({
  actionLink,
  body,
  heading,
  image,
  imageAltText,
  onCardClick = () => {},
  traits,
}: XSVisualCardWidgetProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  // TODO: Traits will be removed in Masonry 2.0
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(actionLink)}
      cardStyle={{
        padding: 'oneAndHalf',
        alignItems: 'center',
        backgroundColor:
          traitItems?.backgroundColor || 'surface.background.primary',
      }}
    >
      {image && (
        <Flex marginRight="threeQuarters" justifyContent="center">
          <StyledImage src={image} alt={imageAltText} />
        </Flex>
      )}
      <StackLayout space="$quarter">
        <SubtitleOne as={HeadingThree} fontWeight="bold">
          <ConditionalLink ref={linkRef} onClick={onCardClick} url={actionLink}>
            {heading}
          </ConditionalLink>
        </SubtitleOne>
        {body && (
          <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
            {body}
          </ParagraphText>
        )}
      </StackLayout>
    </ConditionalLinkCard>
  );
};
