import * as React from 'react';
import { Spinner, StackLayout } from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { useGetConfig } from 'hooks/use-get-config.hook';

export type MasonrySpinnerNode = MasonryEngineNode<'containerSpinner'>;

export const MasonrySpinner = () => {
  const { uiConfig } = useGetConfig();
  const {
    spinnerConfig: { tint, size, thickness, containerHeight } = {},
    CustomLoader,
  } = uiConfig;
  return (
    <StackLayout
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{ height: '100%', minHeight: containerHeight }}
    >
      {CustomLoader ? (
        <CustomLoader />
      ) : (
        <Spinner loading tint={tint} size={size} thickness={thickness} />
      )}
    </StackLayout>
  );
};
