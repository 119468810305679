import { Fieldset, HeadingText, Radio } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import { useFormContext } from '@leagueplatform/web-common';
import * as React from 'react';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { useSelectAddressStep } from '../hooks/use-select-address-step.hook';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';
import { MemberCard } from './member-selection-card.component';

const ADDRESS_HINT_ID = 'select-address-hint';

const { isDefault } = orderNewCardFormInputNames;

export const SelectAddressStep = () => {
  const { formatMessage } = useIntl();
  const { options } = useSelectAddressStep();
  const { register, formState } = useFormContext();

  useWalletDetailScreenLoaded(
    BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SELECT_ADDRESS_STEP,
  );

  const errorMessage = formState.errors[isDefault]?.message;

  return (
    <>
      <MemberCard headingLevel={4} />
      <Fieldset
        inputStatus={errorMessage ? 'error' : undefined}
        id={ADDRESS_HINT_ID}
        legend={
          <HeadingText size="lg" level="3">
            {formatMessage({ id: 'ORDER_NEW_CARD_ADDRESS_SELECT_HEADING' })}
          </HeadingText>
        }
        required
        statusMessage={errorMessage as string}
      >
        <StackLayout space="$one" css={{ marginTop: '$one' }}>
          {options.map((option, i) => (
            <Radio
              css={{
                borderRadius: '$medium',
                border: '$onSurfaceBorderDefault thin solid',
              }}
              hint={option.hint}
              id={`address-option-${i}`}
              key={`address-is-default-${option.isDefault}`}
              label={option.label}
              layout="row"
              value={`${option.isDefault}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(isDefault, {
                required: formatMessage({
                  id: 'WALLET_ORDER_NEW_CARD_SELECT_ADDRESS_OPTION_ERROR',
                }),
              })}
            />
          ))}
        </StackLayout>
      </Fieldset>
    </>
  );
};
