import { useQuery } from 'react-query';
import { getWearablesConsentForDataPoints } from '../get-wearables-consent-for-data-points';
import { WearablesConsentForDataPointsResponse } from '../types/wearables-consent-for-data-points-response.type';

export const GET_WEARABLES_CONSENT_FOR_DATA_POINTS_MESSAGE_TYPE =
  'wearablesConsentForDataPointsData';

export const useGetWearablesConsentForDataPoints = (
  dataPoints: string[] = [],
  devices: string[] = [],
) =>
  useQuery<WearablesConsentForDataPointsResponse, Error>(
    [GET_WEARABLES_CONSENT_FOR_DATA_POINTS_MESSAGE_TYPE, dataPoints, devices],
    () => getWearablesConsentForDataPoints(dataPoints, devices),
    { enabled: dataPoints?.length > 0 },
  );
