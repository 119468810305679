import React, { forwardRef, FunctionComponent } from 'react';
import css from '@styled-system/css';
import { BoxProps } from '../../Box';
import { InputProps } from '../Input';
import { TextInputBase } from '../TextInputBase';
import { useTheme } from '../../theme';

export interface TextareaProps extends InputProps, BoxProps {
  rows?: number;
  cols?: number;
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
  wrap?: 'hard' | 'soft' | 'off';
}

export const Textarea: FunctionComponent<TextareaProps> = forwardRef(
  ({ rows, cols, resize = 'both', wrap = 'soft', ...props }, ref) => {
    const { lineHeights, space, borderWidths } = useTheme();
    const defaultHeight =
      parseInt(lineHeights.body1, 10) * 3 + // Show 3 lines by default
      space.threeQuarters * 2 + // Add top and bottom padding b/c of box-sizing: border-box
      borderWidths.thin * 2; // Add top and bottom border width b/c of box-sizing: border-box

    return (
      <TextInputBase
        as="textarea"
        ref={ref}
        rows={rows}
        cols={cols}
        height={!rows ? defaultHeight : undefined}
        width={!cols ? '100%' : undefined}
        wrap={wrap}
        minHeight="three"
        // Set overflow-x to hidden by default to hide extra vertical space added
        // by firefox as firefox will add extra space for a horizontal scroll bar
        // even when text wraps so there wouldn't be a horizontal scroll
        overflowX={wrap === 'off' ? 'auto' : 'hidden'}
        overflowY="auto"
        css={css({ resize })}
        {...props}
      />
    );
  }
);

Textarea.displayName = 'Textarea';
