import React, { useEffect } from 'react';
import {
  StackLayout,
  VisuallyHidden,
  Box,
  HeadingText,
  ParagraphText,
  Button,
  Icon,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Badge, Carousel } from '@leagueplatform/web-common-components';
import { Achievement } from '@leagueplatform/rewards-api';
import { PointsIcon } from '@leagueplatform/rewards-common';
import { useAchievementsUpdates } from '../../hooks/use-achievements-updates.hook';
import { AchievementFullscreenModal } from './components/achievement-fullscreen-modal.view';

const modalTitleId = 'celebration-modal-title';

const sendSelectOptionAnalytics = (
  selectedOption: string,
  completedTotalAchievements: Achievement[],
) => {
  completedTotalAchievements.map((achievement) =>
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'achievement celebration',
      detail: selectedOption,
      sub_detail: achievement.name,
      achievement_id: achievement.id,
    }),
  );
};

export const CelebrationAchievementsModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { completedAchievements }: { completedAchievements: Achievement[] } =
    useAchievementsUpdates();

  useEffect(() => {
    completedAchievements.map((achievement) =>
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: 'rewards',
        sub_product_area: 'achievements',
        screen_name: 'achievement celebration',
        sub_detail: achievement.name,
        achievement_id: achievement.id,
      }),
    );
  });

  return (
    <AchievementFullscreenModal
      open={open}
      onClose={() => {
        sendSelectOptionAnalytics('close', completedAchievements);
        onClose();
      }}
      aria-labelledby={modalTitleId}
    >
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{ height: '100%' }}
      >
        <VisuallyHidden id={modalTitleId}>
          {formatMessage({ id: 'CONGRATS_COMPLETED' })}
        </VisuallyHidden>

        <StackLayout orientation="horizontal" css={{ maxWidth: '384px' }}>
          {completedAchievements.length > 0 && (
            <Carousel
              sideArrows
              showProgressDots
              name="celebration-achievements"
            >
              {completedAchievements.map((achievement) => (
                <Box key={achievement?.name}>
                  <StackLayout
                    horizontalAlignment="center"
                    css={{ width: '384px', textAlign: 'center' }}
                  >
                    <HeadingText
                      level="1"
                      size="xxl"
                      css={{ marginTop: '$one', marginBottom: '$half' }}
                    >
                      {achievement?.celebration_title}
                    </HeadingText>
                    <ParagraphText
                      css={{
                        textAlign: 'center',
                        color: '$onSurfaceTextSubdued',
                      }}
                      size="sm"
                    >
                      {achievement?.celebration_subtitle}
                    </ParagraphText>
                    <Box
                      css={{
                        marginTop: '$oneAndHalf',
                        marginBottom: '$half',
                        '@desktop': {
                          marginTop: '$four',
                          marginBottom: '$one',
                        },
                      }}
                    >
                      <Icon icon={achievement?.image?.large} size="216px" />
                    </Box>
                    <ParagraphText
                      css={{
                        textAlign: 'center',
                        color: '$onSurfaceTextSubdued',
                      }}
                    >
                      {formatMessage({ id: 'YOUVE_COMPLETED' })}
                    </ParagraphText>
                    <HeadingText level="2" size="xl">
                      {achievement?.name}
                    </HeadingText>
                    {achievement?.program_points && (
                      <StackLayout
                        horizontalAlignment="center"
                        css={{ marginTop: '$oneAndHalf' }}
                      >
                        <ParagraphText
                          size="sm"
                          css={{
                            textAlign: 'center',
                            color: '$onSurfaceTextSubdued',
                          }}
                        >
                          {formatMessage({ id: 'YOUVE_EARNED_GRAND_TOTAL' })}
                        </ParagraphText>
                        <Badge
                          css={{
                            fontWeight: '$semibold',
                            fontSize: '$subtitleTwo',
                            marginTop: '$half',
                            backgroundColor: '$decorativeBrandSecondaryDefault',
                          }}
                        >
                          <PointsIcon value={achievement?.program_points} />
                        </Badge>
                      </StackLayout>
                    )}
                  </StackLayout>
                </Box>
              ))}
            </Carousel>
          )}
        </StackLayout>
        <Button
          onClick={() => {
            sendSelectOptionAnalytics(
              'view achievements',
              completedAchievements,
            );
            onClose();
          }}
          css={{
            marginTop: '$oneAndHalf',
            '@desktop': {
              marginTop: '$four',
            },
          }}
          width="hugContents"
        >
          {formatMessage({ id: 'VIEW_ACHIEVEMENTS' })}
        </Button>
      </StackLayout>
    </AchievementFullscreenModal>
  );
};
