import React, { FC } from 'react';

// Common Dependencies
import {
  css,
  Flex,
  PrimaryButton,
  QuietButton,
  StyleProps,
} from '@leagueplatform/genesis-commons';

// Types
interface CircleButtonProps {
  css: StyleProps;
  disabled?: boolean;
  as: React.FC;
  onClick(): void;
  type?: string;
  label?: string;
}

export const CircleButton: FC<CircleButtonProps> = ({
  css: cssOverrides,
  as = PrimaryButton,
  disabled,
  children,
  onClick,
  type,
  label,
}) => (
  <Flex
    alignItems="center"
    as={type === 'submit' ? QuietButton : as}
    aria-label={label}
    disabled={disabled}
    borderRadius="circle"
    color="interactive.action.primary"
    justifyContent="center"
    opacity={1}
    size="three"
    transition="background 0.15s ease-out, color 0.15s ease-out"
    type={type || null}
    css={css({
      ...cssOverrides,
      '&:before': {
        borderRadius: '50%',
      },
    })}
    onClick={onClick}
  >
    {children}
  </Flex>
);
