import React from 'react';

export const Alert = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="alertIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M12.5984 17.9984C12.5984 18.3298 12.3298 18.5984 11.9984 18.5984C11.6671 18.5984 11.3984 18.3298 11.3984 17.9984C11.3984 17.6671 11.6671 17.3984 11.9984 17.3984C12.3298 17.3984 12.5984 17.6671 12.5984 17.9984Z"
      stroke="currentColor"
    />
    <path
      d="M12 14.4L12 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
