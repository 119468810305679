{
  "dragDescriptionKeyboard": "Drücken Sie die Eingabetaste, um den Ziehvorgang zu starten.",
  "dragDescriptionKeyboardAlt": "Alt + Eingabe drücken, um den Ziehvorgang zu starten.",
  "dragDescriptionLongPress": "Lang drücken, um mit dem Ziehen zu beginnen.",
  "dragDescriptionTouch": "Tippen Sie doppelt, um den Ziehvorgang zu starten.",
  "dragDescriptionVirtual": "Zum Starten des Ziehvorgangs klicken.",
  "dragItem": "{itemText} ziehen",
  "dragSelectedItems": "{count, plural, one {# ausgewähltes Objekt} other {# ausgewählte Objekte}} ziehen",
  "dragSelectedKeyboard": "Eingabetaste drücken, um {count, plural, one {# ausgewähltes Element} other {# ausgewählte Elemente}} zu ziehen.",
  "dragSelectedKeyboardAlt": "Alt + Eingabetaste drücken, um {count, plural, one {# ausgewähltes Element} other {# ausgewählte Elemente}} zu ziehen.",
  "dragSelectedLongPress": "Lang drücken, um {count, plural, one {# ausgewähltes Element} other {# ausgewählte Elemente}} zu ziehen.",
  "dragStartedKeyboard": "Ziehvorgang gestartet. Drücken Sie die Tabulatortaste, um zu einem Ablegeziel zu navigieren und drücken Sie dann die Eingabetaste, um das Objekt abzulegen, oder Escape, um den Vorgang abzubrechen.",
  "dragStartedTouch": "Ziehvorgang gestartet. Navigieren Sie zu einem Ablegeziel und tippen Sie doppelt, um das Objekt abzulegen.",
  "dragStartedVirtual": "Ziehvorgang gestartet. Navigieren Sie zu einem Ablegeziel und klicken Sie oder drücken Sie die Eingabetaste, um das Objekt abzulegen.",
  "dropCanceled": "Ablegen abgebrochen.",
  "dropComplete": "Ablegen abgeschlossen.",
  "dropDescriptionKeyboard": "Drücken Sie die Eingabetaste, um das Objekt abzulegen. Drücken Sie Escape, um den Vorgang abzubrechen.",
  "dropDescriptionTouch": "Tippen Sie doppelt, um das Objekt abzulegen.",
  "dropDescriptionVirtual": "Zum Ablegen klicken.",
  "dropIndicator": "Ablegeanzeiger",
  "dropOnItem": "Auf {itemText} ablegen",
  "dropOnRoot": "Ablegen auf",
  "endDragKeyboard": "Ziehvorgang läuft. Drücken Sie die Eingabetaste, um den Vorgang abzubrechen.",
  "endDragTouch": "Ziehvorgang läuft. Tippen Sie doppelt, um den Vorgang abzubrechen.",
  "endDragVirtual": "Ziehvorgang läuft. Klicken Sie, um den Vorgang abzubrechen.",
  "insertAfter": "Nach {itemText} einfügen",
  "insertBefore": "Vor {itemText} einfügen",
  "insertBetween": "Zwischen {beforeItemText} und {afterItemText} einfügen"
}
