import * as React from 'react';
import { useTheme } from '@leagueplatform/genesis-core';
import { Circle, Polygon } from '@visx/shape';

export const shapes = ['circle', 'square'] as const;
export type ShapeType = (typeof shapes)[number];

type ShapeProps = {
  x: number;
  y: number;
  radius: number;
  shape: ShapeType;
  fill: string;
  ariaLabel?: string;
};

const shapeMap: Record<
  ShapeType,
  (params: Omit<ShapeProps, 'shape'>) => JSX.Element
> = {
  circle: ({ x, y, radius, fill, ariaLabel }) => (
    <Circle
      cx={x}
      cy={y}
      r={radius}
      fill={fill}
      aria-label={ariaLabel}
      style={{ pointerEvents: 'none' }}
    />
  ),
  square: ({ x, y, radius, fill, ariaLabel }) => (
    <Polygon
      sides={4}
      rotate={90}
      center={{ x, y }}
      size={radius}
      fill={fill}
      aria-label={ariaLabel}
      style={{ pointerEvents: 'none' }}
    />
  ),
};

export function Shape({ shape, ...props }: ShapeProps) {
  return shapeMap[shape](props);
}

type UseColorMapParams = {
  shape: ShapeType;
};

export function useShapeTheme({ shape }: UseColorMapParams) {
  const theme = useTheme();

  const colorMap: Record<ShapeType, { light: string; dark: string }> = {
    circle: {
      light: theme.colors.decorativeBrandPrimaryBrightest,
      dark: theme.colors.decorativeBrandPrimaryDark,
    },
    square: {
      light: theme.colors.decorativeBrandSecondaryBrightest,
      dark: theme.colors.decorativeBrandSecondaryDark,
    },
  };

  return colorMap[shape];
}

type ShapeIconProps = {
  shape: ShapeType;
};

export function ShapeIcon({ shape }: ShapeIconProps) {
  const shapeTheme = useShapeTheme({ shape });
  const theme = useTheme();
  return (
    <svg
      height={theme.sizes.threeQuarters.value}
      width={theme.sizes.threeQuarters.value}
      viewBox="0 0 16 16"
    >
      <Shape shape={shape} x={8} y={8} radius={8} fill={shapeTheme.dark} />;
    </svg>
  );
}
