import React from 'react';
import {
  LoadingIndicator,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { HeadingText, Tabs } from '@leagueplatform/genesis-core';
import { CARD_STATUS, PILL_STATUS } from '@leagueplatform/wallet-api';
import { NavigationSidebar } from './components/navigation-sidebar/navigation-sidebar.component';
import { NavigationGrid } from './components/navigation-grid/navigation-grid.component';
import { useNavigationSections } from './hooks/use-navigation-sections';
import { CardStatuses } from '../../utils/parse-benefit-card-statuses.util';

interface WalletItemNavigationSectionsProps {
  userId?: string;
  userRole?: string;
  layout?: 'grid' | 'sidebar';
  showHeading?: boolean;
}

export const WalletItemNavigationSections = ({
  userId,
  userRole,
  layout = 'grid',
  showHeading = false,
}: WalletItemNavigationSectionsProps) => {
  const { isLoading, itemSectioned, benefitSections, cardStatuses, heading } =
    useNavigationSections();

  const { $tmap } = useIntl();

  const getCardStatusMessage = $tmap<CardStatuses>({
    active: 'WALLET_BENEFIT_STATUS_ACTIVE',
    [PILL_STATUS.PENDING]: 'WALLET_BENEFIT_STATUS_PENDING',
    [PILL_STATUS.PAST]: 'WALLET_BENEFIT_STATUS_PAST',
    [CARD_STATUS.CREATED]: 'WALLET_BENEFIT_STATUS_CREATED',
    [CARD_STATUS.READY]: 'WALLET_BENEFIT_STATUS_READY',
    [CARD_STATUS.IN_WAITING_PERIOD]: 'WALLET_BENEFIT_IN_WAITING_PERIOD',
    [CARD_STATUS.INACTIVE]: 'WALLET_BENEFIT_STATUS_INACTIVE',
    [CARD_STATUS.INACTIVE_GRACE_PERIOD]:
      'WALLET_BENEFIT_STATUS_INACTIVE_GRACE_PERIOD',
    [CARD_STATUS.ALLOCATED]: 'WALLET_BENEFIT_STATUS_ALLOCATED',
    [CARD_STATUS.OPTED_OUT]: 'WALLET_BENEFIT_STATUS_OPTED_OUT',
  });

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  if (layout === 'sidebar') {
    return (
      <NavigationSidebar
        userId={userId}
        userRole={userRole}
        navigationSections={itemSectioned}
      />
    );
  }

  if (cardStatuses.length === 1) {
    const singleBenefitSection = benefitSections[cardStatuses[0]];

    return (
      <StackLayout>
        {showHeading && (
          <HeadingText size="xl" level="2">
            {heading}
          </HeadingText>
        )}
        {singleBenefitSection &&
          singleBenefitSection.map((statuses) => (
            <React.Fragment key={statuses.section_id}>
              {statuses.sections.map((section) => (
                <NavigationGrid
                  key={`${statuses.section_id}-${section.section_id}`}
                  navigationSections={section}
                />
              ))}
            </React.Fragment>
          ))}
      </StackLayout>
    );
  }

  return (
    <StackLayout>
      {showHeading && (
        <HeadingText size="xl" level="2">
          {heading}
        </HeadingText>
      )}
      <Tabs.Root defaultValue={'active-tab' || `${cardStatuses[0]}-tab`}>
        <StackLayout space="$three">
          <Tabs.TabList tabDistribution="hugContents" divider>
            {cardStatuses.map((status) => (
              <Tabs.Tab value={`${status}-tab`} key={`${status}-tab`}>
                {getCardStatusMessage(status)}
              </Tabs.Tab>
            ))}
          </Tabs.TabList>

          {/* For each status create a tab-panel */}
          {cardStatuses.map((status) => (
            <Tabs.TabPanel value={`${status}-tab`} key={`${status}-tab-panel`}>
              <StackLayout>
                {benefitSections[status]?.map((statuses) => (
                  <React.Fragment key={statuses.section_id}>
                    {statuses.sections.map((section) => (
                      <NavigationGrid
                        key={`${statuses.section_id}-${section.section_id}`}
                        navigationSections={section}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </StackLayout>
            </Tabs.TabPanel>
          ))}
        </StackLayout>
      </Tabs.Root>
    </StackLayout>
  );
};
