import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import {
  Button,
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
  VisuallyHidden,
  queryHelpers,
  useMediaQuery,
  styled,
  Image,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { InternalOrExternalLink } from '@leagueplatform/web-common-components';
import { PageHeaderBackground } from '@leagueplatform/masonry-widgets';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from 'types/masonry-driver-node-actions';

type MasonryHeroBannerNodeProperties = {
  heading?: string;
  body?: string;
  eyebrow?: string;
  buttonLabel?: string;
  image?: string;
  imageAltText?: string;
  icon?: string;
  curved?: boolean;
  includeSafeArea?: boolean;
};

export type MasonryHeroBannerNodeActions = {
  onButtonClick?: MasonryDriverAction;
};

export type MasonryHeroBannerNode = MasonryEngineNode<
  'heroBanner',
  MasonryHeroBannerNodeProperties,
  MasonryHeroBannerNodeActions
>;

export type MasonryHeroBannerRendererProps =
  MasonryEngineNodeRendererProps<MasonryHeroBannerNode>;

const DecorativeIcon = styled(Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const MasonryHeroBannerRenderer = ({
  heading,
  body,
  eyebrow,
  buttonLabel,
  image,
  imageAltText = '',
  icon,
  curved,
  includeSafeArea = false,
  onButtonClick,
}: MasonryHeroBannerRendererProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(150);
  const isLaptopDown = useMediaQuery(queryHelpers.down('laptop'));
  const pageHeaderBackgroundHeightPadding = isLaptopDown ? 60 : 112;
  const invokeAction = useMasonryEngineActionEmitter();

  useLayoutEffect(() => {
    const reportWindowSize = () => {
      setContentHeight(contentRef?.current?.clientHeight ?? contentHeight);
    };
    if (contentHeight !== undefined)
      window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [contentHeight]);

  useEffect(() => {
    if (contentRef?.current?.clientHeight)
      setContentHeight(contentRef.current.clientHeight);
  }, []);

  return (
    <PageHeaderBackground
      height={`${contentHeight + pageHeaderBackgroundHeightPadding / 2}px`}
      pageHeaderBackgroundHeight={`${
        contentHeight + pageHeaderBackgroundHeightPadding
      }px`}
      backgroundImage={image}
      curved={curved}
      imageAltText={imageAltText}
    >
      <StackLayout
        orientation="horizontal"
        spacing="$one"
        horizontalAlignment="start"
        verticalAlignment="top"
        css={{ position: 'relative' }}
      >
        <StackItem
          horizontalAlignment="start"
          css={{
            marginRight: includeSafeArea ? '160px' : 'none',
          }}
          verticalAlignment="top"
          ref={contentRef}
        >
          <StackLayout
            orientation="horizontal"
            spacing="$oneAndHalf"
            horizontalAlignment="start"
            verticalAlignment="top"
          >
            {icon && (
              <StackItem
                css={{
                  flex: '1 1 100%',
                  overflow: 'hidden',
                  aspectRatio: '1 / 1',
                  maxWidth: '64px',
                  '@mobile': {
                    order: 2,
                  },
                  '@laptop': {
                    maxWidth: '80px',
                  },
                  '@desktop': {
                    maxWidth: '96px',
                  },
                }}
              >
                <DecorativeIcon src={icon} alt="" data-testid="hero-icon" />
              </StackItem>
            )}
            <StackItem horizontalAlignment="start" verticalAlignment="top">
              {eyebrow && <UtilityText size="eyebrow">{eyebrow}</UtilityText>}
              {heading && (
                <HeadingText
                  level="1"
                  size={{
                    '@initial': 'xl',
                    '@laptop': 'xxl',
                    '@desktop': 'xxl',
                  }}
                  css={{
                    marginTop: '$quarter',
                  }}
                >
                  {heading}
                </HeadingText>
              )}

              {body && (
                <ParagraphText
                  htmlStringOptions={BodyTextHtmlTagOptions}
                  css={{
                    typography: '$bodyTwo',
                    marginTop: '$threeQuarters',
                    color: '$onSurfaceTextPrimary',
                    '@laptop': {
                      typography: '$bodyOne',
                    },
                    '@desktop': {
                      typography: '$bodyOne',
                    },
                  }}
                >
                  {body}
                </ParagraphText>
              )}
              {buttonLabel && (
                <Button
                  size="medium"
                  priority="primary"
                  css={{
                    marginTop: '$one',
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...((onButtonClick as MasonryDeeplinkAction)?.payload
                    .url && {
                    as: InternalOrExternalLink,
                    disableDefaultOnClick: true,
                  })}
                  href={(onButtonClick as MasonryDeeplinkAction)?.payload.url}
                  onClick={(event: React.MouseEvent) => {
                    if (onButtonClick) {
                      invokeAction({
                        event,
                        ...onButtonClick,
                      });
                    }
                  }}
                >
                  {buttonLabel}
                </Button>
              )}
              {image && (
                <VisuallyHidden
                  data-testid="hero-img"
                  role="img"
                  aria-label={imageAltText}
                />
              )}
            </StackItem>
          </StackLayout>
        </StackItem>
      </StackLayout>
    </PageHeaderBackground>
  );
};
