import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ComposableActivityCard,
  JOURNEY_ROUTES,
} from '@leagueplatform/health-journey-common';
import { GDSHeadingLevel } from '@leagueplatform/genesis-core';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import {
  isCompletedActivity,
  isMissedActivity,
} from '../utils/activity-status-predicates';
import { VerifiableActivityProgress } from './verifiable-activity-progress';

// TEMPORARY FIX UNTIL BE SENDS US VALUES RESPECTING CONTRACT
type ActivityProgressData = Exclude<
  ConfigurableHealthActivityWSCC['activityProgressData'],
  undefined
>;
type ActivityProgressItem = ActivityProgressData[0];
function cleanUpFix(data: ActivityProgressItem) {
  if (
    data.currentProgress !== undefined &&
    data.currentProgress !== null &&
    // @ts-ignore
    data.currentProgress !== '' &&
    data.goalTotal !== undefined &&
    data.goalTotal !== null &&
    // @ts-ignore
    data.goalTotal !== '' &&
    data.dataType !== undefined &&
    data.dataType !== null &&
    // @ts-ignore
    data.dataType !== '' &&
    data.unit !== undefined &&
    data.unit !== null &&
    // @ts-ignore
    data.unit !== ''
  ) {
    return true;
  }

  return false;
}
// END OF TEMPORARY FIX

type ChallengeActivityCardProps = {
  activity: ConfigurableHealthActivityWSCC;
  level: GDSHeadingLevel;
};

export function ChallengeActivityCard({
  activity,
  level,
}: ChallengeActivityCardProps) {
  const { formatMessage } = useIntl();
  const { activityProgressData } = activity;

  return (
    <ComposableActivityCard
      cta={JOURNEY_ROUTES.getToolboxActivityDetails(activity.id)}
      icon={
        isCompletedActivity(activity)
          ? {
              icon: 'statusSuccessStatusFilled',
              tint: '$successIcon',
              label: formatMessage({ id: 'COMPLETED' }),
            }
          : undefined
      }
      title={{
        text: activity.name,
        level,
        textEmphasis: isMissedActivity(activity) ? 'subtle' : 'base',
      }}
      body={
        activityProgressData &&
        activityProgressData
          .filter(cleanUpFix)
          .map((data) => (
            <VerifiableActivityProgress
              progress={data.currentProgress}
              goal={data.goalTotal}
              type={data.dataType}
              unit={data.unit}
            />
          ))
      }
    />
  );
}
