import * as React from 'react';
import {
  Card,
  GDSColor,
  GDSIconProps,
  GDSHeadingLevel,
  HeadingText,
  Icon,
  StackLayout,
  UtilityText,
  useClickableCard,
  GDSTypographyResponsiveEmphasis,
  GDSHeadingSize,
  TextAction,
  useMediaQuery,
  queryHelpers,
} from '@leagueplatform/genesis-core';
import { Badge } from '@leagueplatform/web-common-components';
import { Link, LocationDescriptor } from '@leagueplatform/routing';

type BadgeProps = {
  body: string;
  image: string;
  imageTint?: GDSColor;
  backgroundColor: GDSColor;
};

type ImageProps = {
  image: string;
  shape?: 'circle' | 'square' | 'large';
  altText: string;
};

type IconProps = {
  icon: GDSIconProps['icon'];
  tint?: GDSColor;
  label: string;
};

type ImageOrIcon =
  | { image?: ImageProps; icon?: never }
  | { icon?: IconProps; image?: never };

type TitleProps = {
  text: string;
  level: GDSHeadingLevel;
  textEmphasis?: GDSTypographyResponsiveEmphasis;
  size?: GDSHeadingSize;
};

export type ComposableActivityCardProps = {
  badge?: BadgeProps;
  body?: React.ReactNode;
  cta?: LocationDescriptor;
  eyebrow?: React.ReactNode;
  footer?: React.ReactNode;
  headline?: React.ReactNode;
  title: TitleProps;
  onClick?: () => void;
} & ImageOrIcon;

type CardContentWrapperProps = {
  children: React.ReactNode;
  cta?: LocationDescriptor;
  onCardMouseUp: (event: React.SyntheticEvent<Element, Event>) => void;
  onCardMouseDown: (event: React.SyntheticEvent<Element, Event>) => void;
};

const LARGE_IMAGE_WIDTH = '130px';

export const CardContentWrapper = React.forwardRef<
  HTMLDivElement | null,
  CardContentWrapperProps
>(({ cta, children, onCardMouseDown, onCardMouseUp }, ref) => {
  if (cta) {
    return (
      <Card.ClickArea
        ref={ref}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        {children}
      </Card.ClickArea>
    );
  }
  return <div> {children}</div>;
});

export function ComposableActivityCard({
  badge,
  body,
  cta,
  eyebrow,
  footer,
  headline,
  icon,
  image,
  title,
  onClick,
}: ComposableActivityCardProps) {
  const isBelowMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const withLargeImage = image?.shape === 'large';
  const withImage = image && image.shape !== 'large';
  const displayImageAtTop = icon || isBelowMobile;

  return (
    <Card.Elevated
      css={{ width: '100%', backgroundColor: '$surfaceBackgroundPrimary' }}
    >
      <CardContentWrapper
        cta={cta}
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        <StackLayout horizontalAlignment="stretch">
          {withLargeImage && (
            <Card.Image
              image={image.image}
              imageAlt={image.altText}
              // the dom order changes on mobile so we need to hide this image initially and only show it on mobile
              css={{
                display: 'none',
                '@mobile': {
                  display: 'block',
                },
              }}
            />
          )}
          {headline}
          <StackLayout
            orientation="horizontal"
            spacing="$half"
            css={{
              paddingRight: '$oneAndHalf',
            }}
            verticalAlignment={displayImageAtTop ? 'top' : 'center'}
          >
            <StackLayout
              css={{
                paddingTop: '$one',
                paddingLeft: '$oneAndHalf',
                paddingBottom: '$one',
              }}
              spacing="$threeQuarters"
            >
              {badge && (
                <Badge
                  css={{
                    backgroundColor: badge.backgroundColor,
                    borderRadius: '$small',
                  }}
                >
                  <>
                    <Icon
                      icon={badge.image}
                      tint={badge.imageTint}
                      size="$one"
                    />
                    <UtilityText
                      size="eyebrow"
                      emphasis="emphasized"
                      css={{
                        paddingLeft: '$quarter',
                      }}
                    >
                      {badge.body}
                    </UtilityText>
                  </>
                </Badge>
              )}
              {eyebrow}
              <StackLayout
                spacing="$threeQuarters"
                horizontalAlignment="stretch"
                css={{
                  width: '100%',
                  // If there is no body, add more padding to the title.
                  paddingTop: !body ? '$half' : undefined,
                  paddingBottom: !body ? '$half' : undefined,
                }}
              >
                <HeadingText
                  size={title.size || 'sm'}
                  level={title.level}
                  emphasis={title.textEmphasis}
                >
                  {cta ? (
                    <TextAction
                      as={Link}
                      ref={primaryActionRef}
                      to={cta}
                      css={{
                        '&&': {
                          all: 'inherit',
                        },
                      }}
                      onClick={onClick}
                    >
                      {title.text}
                    </TextAction>
                  ) : (
                    title.text
                  )}
                </HeadingText>
                {body}
              </StackLayout>
              {footer}
            </StackLayout>
            {icon && (
              <Icon
                icon={icon.icon}
                tint={icon.tint}
                label={icon.label}
                css={{
                  marginTop: '$one',
                }}
              />
            )}
            {withImage && (
              <Card.Image
                image={image.image}
                imageAlt={image.altText}
                css={{
                  height: '$five',
                  width: '$five',
                  borderRadius: image.shape === 'circle' ? '$circle' : '$small',
                  margin: '$one',
                  marginRight: '$none',
                }}
              />
            )}
            {withLargeImage && (
              <Card.Image
                image={image.image}
                imageAlt={image.altText}
                // the dom order changes on mobile so we need to display this image initially and hide it on mobile
                css={{
                  height: '$five',
                  width: LARGE_IMAGE_WIDTH,
                  borderRadius: '$small',
                  margin: '$one $none',
                  '@mobile': {
                    display: 'none',
                  },
                }}
              />
            )}
          </StackLayout>
        </StackLayout>
      </CardContentWrapper>
    </Card.Elevated>
  );
}
