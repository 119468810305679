import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StackLayout,
  Fieldset,
  ParagraphText,
  Box,
  useMediaQuery,
  queryHelpers,
} from '@leagueplatform/genesis-core';
import { AnswerPill } from './answer-pill.view';

export const MultiChoiceAnswers = ({
  questionId,
  answers,
  onChangeHandler,
  setShowArrow,
  userSelections,
}) => {
  const userSelection = userSelections[questionId] || {};
  const {
    id: checkedAnswer = [],
    error: { hasError = false, errorMsg = '' },
  } = userSelection;
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  // show arrow if there is a checked answer
  useEffect(() => {
    setShowArrow(checkedAnswer.length > 0);
  }, [checkedAnswer, setShowArrow]);

  return (
    <>
      {hasError && errorMsg && (
        <Box mb={2} mt={0}>
          <ParagraphText color="red" fontSize={2} mt={0}>
            {errorMsg}
          </ParagraphText>
        </Box>
      )}
      <Fieldset>
        <StackLayout
          orientation="vertical"
          horizontalAlignment="stretch"
          spacing={isMobile ? '0' : '$one'}
          css={{ flexWrap: 'wrap' }}
        >
          {answers.map((answer) => (
            <AnswerPill
              id={answer.id}
              key={answer.id}
              name="multi-choice-answer"
              label={answer.text}
              type="radio"
              checked={checkedAnswer.includes(answer.id)}
              onChangeHandler={() => {
                onChangeHandler({ id: [answer.id] });
              }}
              description={answer.description}
              paddingBottom="quarter"
            />
          ))}
        </StackLayout>
      </Fieldset>
    </>
  );
};

MultiChoiceAnswers.propTypes = {
  questionId: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  setShowArrow: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
};
