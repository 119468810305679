import React from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import {
  Flex,
  css,
  genesisStyled,
  PrimaryButton,
  QuietButton,
} from '@leagueplatform/genesis-commons';

const TOKEN_PILL_BACKGROUND_HOVER = 'decorative.brand.primary.pastel';

const StyledQuietButton = genesisStyled(QuietButton)(
  css({
    '&:hover': {
      backgroundColor: TOKEN_PILL_BACKGROUND_HOVER,
      color: 'onSurface.text.primary',
    },
  }),
);

const Pill = ({ pill }) => {
  const { active, label, onClick } = pill;

  const pillStyles = {
    borderRadius: 'pill',
    fontSize: 'subtitle2',
    marginRight: 'quarter',
    paddingX: 'threeQuarters',
    paddingY: 'quarter',
  };

  const pillProps = {
    children: label,
    onClick,
  };

  return active ? (
    <PrimaryButton {...pillStyles} {...pillProps} />
  ) : (
    <StyledQuietButton {...pillStyles} {...pillProps} />
  );
};

const PillProps = PropTypes.shape({
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
});

Pill.propTypes = {
  pill: PillProps.isRequired,
};

export const PillTabs = ({ pills }) => {
  return (
    <Flex
      backgroundColor="surface.background.primary"
      borderColor="highlight.background.subdued"
      borderRadius="pill"
      borderWidth="thin"
      boxShadow="card"
      paddingLeft="half"
      paddingRight="quarter"
      paddingY="half"
    >
      {pills.map((pill) => (
        <Pill pill={pill} key={snakeCase(pill.label)} />
      ))}
    </Flex>
  );
};

PillTabs.propTypes = {
  pills: PropTypes.arrayOf(PillProps),
};

PillTabs.defaultProps = {
  pills: [],
};
