import React from 'react';
import {
  genesisStyled,
  Flex,
  Image,
  SubtitleTwo,
  BodyTwo,
  GenesisTheme,
} from '@leagueplatform/genesis-commons';
import { Link as RouterLink } from '@leagueplatform/routing';
import { StackLayout } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';

interface CardLinkProps {
  to: string;
  icon?: string;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

const CardContainer = genesisStyled(Flex)<{ theme: GenesisTheme }>`
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.large}px;
  padding: ${({ theme }) => theme.space.one}px;
  gap: ${({ theme }) => theme.space.one}px;
  background-color: ${({ theme }) =>
    theme.colors.interactive.background.default};
  border-width: ${({ theme }) => theme.borderWidths.thin}px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.interactive.border.default};
  position: relative;
  height: 100%;

  &:hover::after, &:focus::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: calc(${({ theme }) =>
      theme.radii.large - theme.borderWidths.thin}px);
    border-width: ${({ theme }) => theme.borderWidths.thin}px;
    border-color: inherit;
    border-style: solid;
    pointer-events: none;
  };

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.interactive.background.hovered};
    border-color: ${({ theme }) => theme.colors.interactive.border.hovered};
  };

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.interactive.focus.outer};
  };

`;

export const CardLink = ({
  to,
  icon,
  title,
  subtitle,
  onClick,
}: CardLinkProps) => (
  <CardContainer as={RouterLink} to={to} onClick={onClick}>
    {icon && <Image src={icon} alt="" size={48} />}
    <StackLayout css={{ flexGrow: 1 }}>
      <SubtitleTwo
        as="span"
        display="block"
        color="onSurface.text.primary"
        fontStyle="normal"
      >
        {title}
      </SubtitleTwo>
      {subtitle && (
        <BodyTwo as="span" marginTop="one">
          {subtitle}
        </BodyTwo>
      )}
    </StackLayout>
    <Image
      src={handleStaticAsset(WALLET_ASSET_KEYS.CHEVRON_RIGHT) as string}
      alt=""
      size={16}
    />
  </CardContainer>
);
