import type { LeagueCombinedConfig } from '@leagueplatform/config';

export type Environment = LeagueCombinedConfig['core']['appEnvironment'];

/**
 * @deprecated use the string union type {@link Environment `Environment`} instead
 */
export enum ENVIRONMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEV = 'development',
}
