export enum QUICK_ACTIONS_TYPE {
  quickAction = 'quick-action',
  quickActionsParent = 'quick-actions-parent',
}

export interface QuickActionsData {
  data: QuickAction[];
  included?: QuickAction[];
}

export interface QuickAction {
  id: string;
  type: QUICK_ACTIONS_TYPE;
  attributes: {
    title: string;
    url: string;
    icon?: string;
    details?: QuickActionDetails;
    description?: string;
  };
}

export interface QuickActionDetails {
  footer?: string;
  footerUrl?: string;
  secondFooter?: string;
  footerAction?: string;
}

export interface CareProvidersData {
  data: CareProvider[];
}

export interface CareProvider {
  id: string;
  type: 'care-provider';
  attributes: {
    title: string;
    agentType: string;
    description: string;
    iconUrl: string;
    type?: string;
    url: string;
    priceType?: string;
    availabilityText?: string;
    phoneNumber?: string;
  };
}
