import { useQuery } from 'react-query';
import {
  getUserAchievements,
  GET_USER_ACHIEVEMENTS_MESSAGE_TYPE,
} from '@leagueplatform/rewards-api';
import { captureError } from '@leagueplatform/observability';

import { useIsAchievementsEnabled } from './use-is-achievements-enabled.hook';

export const useAchievementsPage = () => {
  const {
    data: userAchievementsData,
    status: userAchievementsStatus,
    error: userAchievementsError,
    isLoading: userAchievementsIsLoading,
    isRefetching: userAchievementsIsRefetching,
    refetch,
  } = useQuery([GET_USER_ACHIEVEMENTS_MESSAGE_TYPE], getUserAchievements, {
    onError: (error) => {
      captureError(new Error(`Unable to get user's achievement: ${error}`));
    },
  });

  const { isFeatureFlagOn } = useIsAchievementsEnabled();
  return {
    isFeatureFlagOn,
    ready: !!(userAchievementsStatus === 'success'),
    userAchievements: userAchievementsData?.achievements_info ?? [],
    error: userAchievementsError,
    userAchievementsIsLoading,
    refetch,
    userAchievementsIsRefetching,
  };
};
