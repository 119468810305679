import {
  getUserConfig,
  GET_USER_CONFIG_MESSAGE_TYPE,
  UserConfiguration,
} from '@leagueplatform/wallet-api';
import { useQuery } from 'react-query';

export function useUserConfigQuery(userId?: string) {
  return useQuery<UserConfiguration>(
    [GET_USER_CONFIG_MESSAGE_TYPE, userId],
    () => getUserConfig(userId),
  );
}
