import * as React from 'react';
import { Box, styled } from '@leagueplatform/genesis-core';

export interface SquareImageContainerProps {
  src: string;
  alt: string;
  maxWidth: string;
  maxWidthDesktop?: string;
}

const StyledImgContainer = styled(Box, {
  borderRadius: 'medium',
  maxHeight: '$$maxImageWidth',
  maxWidth: '$$maxImageWidth',
  aspectRatio: '1 / 1',
  flex: 1,
  overflow: 'hidden',
  '@tablet': {
    maxHeight: '$$maxWidthDesktop',
    maxWidth: '$$maxWidthDesktop',
  },
});

const StyledImg = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const SquareImageContainer = ({
  src,
  maxWidth,
  maxWidthDesktop,
  alt,
}: SquareImageContainerProps) => (
  <StyledImgContainer
    css={{ $$maxImageWidth: maxWidth, $$maxWidthDesktop: maxWidthDesktop }}
  >
    <StyledImg src={src} alt={alt} />
  </StyledImgContainer>
);
