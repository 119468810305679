import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '../primitives.view';

export const RadioGroup = ({
  radioGroupLabel,
  flexDirection,
  align,
  justify,
  children,
}) => (
  <Flex
    flexDirection={flexDirection}
    alignItems={align}
    justifyContent={justify}
    aria-labelledby={radioGroupLabel}
    role="radiogroup"
  >
    {children}
  </Flex>
);

RadioGroup.propTypes = {
  radioGroupLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  flexDirection: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
};

RadioGroup.defaultProps = {
  flexDirection: 'column',
  align: 'auto',
  justify: 'auto',
};
