{
  "dragDescriptionKeyboard": "Naciśnij Enter, aby rozpocząć przeciąganie.",
  "dragDescriptionKeyboardAlt": "Naciśnij Alt + Enter, aby rozpocząć przeciąganie.",
  "dragDescriptionLongPress": "Naciśnij i przytrzymaj, aby rozpocząć przeciąganie.",
  "dragDescriptionTouch": "Dotknij dwukrotnie, aby rozpocząć przeciąganie.",
  "dragDescriptionVirtual": "Kliknij, aby rozpocząć przeciąganie.",
  "dragItem": "Przeciągnij {itemText}",
  "dragSelectedItems": "Przeciągnij {count, plural, one {# wybrany element} other {# wybranych elementów}}",
  "dragSelectedKeyboard": "Naciśnij Enter, aby przeciągnąć {count, plural, one {# wybrany element} other {# wybrane(-ych) elementy(-ów)}}.",
  "dragSelectedKeyboardAlt": "Naciśnij Alt + Enter, aby przeciągnąć {count, plural, one {# wybrany element} other {# wybrane(-ych) elementy(-ów)}}.",
  "dragSelectedLongPress": "Naciśnij i przytrzymaj, aby przeciągnąć {count, plural, one {# wybrany element} other {# wybrane(-ych) elementy(-ów)}}.",
  "dragStartedKeyboard": "Rozpoczęto przeciąganie. Naciśnij Tab, aby wybrać miejsce docelowe, a następnie naciśnij Enter, aby upuścić, lub Escape, aby anulować.",
  "dragStartedTouch": "Rozpoczęto przeciąganie. Wybierz miejsce, w którym chcesz upuścić element, a następnie dotknij dwukrotnie, aby upuścić.F",
  "dragStartedVirtual": "Rozpoczęto przeciąganie. Wybierz miejsce, w którym chcesz upuścić element, a następnie kliknij lub naciśnij Enter, aby upuścić.",
  "dropCanceled": "Anulowano upuszczenie.",
  "dropComplete": "Zakończono upuszczanie.",
  "dropDescriptionKeyboard": "Naciśnij Enter, aby upuścić. Naciśnij Escape, aby anulować przeciągnięcie.",
  "dropDescriptionTouch": "Dotknij dwukrotnie, aby upuścić.",
  "dropDescriptionVirtual": "Kliknij, aby upuścić.",
  "dropIndicator": "wskaźnik upuszczenia",
  "dropOnItem": "Upuść na {itemText}",
  "dropOnRoot": "Upuść",
  "endDragKeyboard": "Przeciąganie. Naciśnij Enter, aby anulować przeciągnięcie.",
  "endDragTouch": "Przeciąganie. Kliknij dwukrotnie, aby anulować przeciągnięcie.",
  "endDragVirtual": "Przeciąganie. Kliknij, aby anulować przeciąganie.",
  "insertAfter": "Umieść za {itemText}",
  "insertBefore": "Umieść przed {itemText}",
  "insertBetween": "Umieść między {beforeItemText} i {afterItemText}"
}
