import { TextAction, GDSHTMLTagOptions } from '@leagueplatform/genesis-core';
import { InternalOrExternalLinkButton } from '@leagueplatform/web-common-components';

export const BodyTextHtmlTagOptions: GDSHTMLTagOptions = {
  a: {
    component: InternalOrExternalLinkButton,
    props: {
      baseComponent: TextAction,
      // Stop event propagation to prevent triggering on card click event on click of link inside paragraph text
      onClick: (e: MouseEvent) => e.stopPropagation(),
    },
  },
};
