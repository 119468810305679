import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { ReactQueryWSHookParams } from '../types/react-query-ws-hook-params.types';

/**
 * @name useLeagueWSQuery
 * @desc A wrapper around React Queries useQuery
 * @param { object } info meta data associated with the request
 * @param { string } message_type the message expected by the API
 * @param { object } options all options available in React Query Infinite Queries
 */

export function useLeagueWSQuery<TData>(
  queryKey: QueryKey,
  { info, message_type }: ReactQueryWSHookParams,
  options?: UseQueryOptions<TData>,
) {
  return useQuery<TData>(
    queryKey,
    async () =>
      SocketAsFetch.fetch({
        message_type,
        info,
      }),
    // consumers can leverage any of RQ options in their query
    options,
  );
}
