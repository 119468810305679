import {
  Flex,
  Box,
  Caption,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import React from 'react';
import PropTypes from 'prop-types';

const PillLabel = genesisStyled(Caption)`
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

export const Pill = ({ color, children, simple, ...rest }) => {
  const colourScheme = {
    orange: {
      background: 'warning.background.subdued',
      circle: 'lightPalette.orange.regular',
    },
    purple: {
      background: 'highlight.background.subdued',
      circle: 'decorative.brand.primary.default',
    },
    blue: {
      background: 'highlight.background.subdued',
      circle: 'lightPalette.blue.regular',
    },
    red: {
      background: 'critical.background.subdued',
      circle: 'lightPalette.red.regular',
    },
    green: {
      background: 'success.background.subdued',
      circle: 'lightPalette.green.regular',
    },
    grey: {
      background: 'interactive.background.disabled',
      circle: 'onSurface.text.subdued',
    },
  };

  const getColours = () => colourScheme[color];

  return (
    <Flex
      alignItems="center"
      backgroundColor={getColours().background}
      borderRadius="pill"
      display="inline-flex"
      padding={5}
      paddingRight="half"
      {...rest}
    >
      {!simple && (
        <Box
          height={10}
          width={10}
          minWidth={10}
          backgroundColor={getColours().circle}
          borderRadius="circle"
        />
      )}
      <PillLabel fontWeight="bold" marginLeft={5} lineHeight="12px">
        {children}
      </PillLabel>
    </Flex>
  );
};

Pill.propTypes = {
  color: PropTypes.string,
  simple: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Pill.defaultProps = {
  color: 'grey',
  className: null,
  simple: false,
};
