import { MetricType } from '@leagueplatform/dashboard-api';
import { useIntl } from '@leagueplatform/locales';

const digits: Record<MetricType, number> = {
  distance: 1,
  steps: 0,
  active_duration: 0,
  mindful_duration: 0,
  floors_climbed: 0,
  energy_burned: 0,
};

export function useLocaleMetricValueFormatter() {
  const { formatNumber } = useIntl();

  function formatMetricValue(
    value: number | null,
    type: MetricType,
  ): string | null {
    const maximumFractionDigits = digits[type];

    if (value === null) {
      return null;
    }

    return formatNumber(value, { maximumFractionDigits });
  }

  return { formatMetricValue };
}
