import * as React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { HealthProgramsPage } from 'pages/main/health-programs-page.component';
import { HealthProgramDetails } from 'pages/program-details/health-program-details.component';
import { HealthProgramsCategoryPage } from 'pages/category/health-programs-category-page.component';
import { ENABLE_CHALLENGES_WELLNESS_AND_REWARDS } from '@leagueplatform/health-journey-common';
import { AllChallengesByType } from 'pages/all-challenges/all-challenges-by-type.component';

// TODO: rename as HealthProgramsSubroutes
export const HealthProgramsRoutes = () => {
  const { path } = useRouteMatch();

  const { data: isChallengesWellnessAndRewardsEnabled } = useFeatureFlagQuery(
    ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
  );

  return (
    <Switch>
      <Route
        path={`${path}/category/:categoryId`}
        component={HealthProgramsCategoryPage}
      />
      {isChallengesWellnessAndRewardsEnabled && (
        <Route exact path={`${path}/all-challenges`}>
          <AllChallengesByType />
        </Route>
      )}
      <Route path={`${path}/:programId`} component={HealthProgramDetails} />
      {/*
          If the new configurable journey experience is not enabled for the user (e.g. isConfigurableJourneyExperience is a falsy value)
          we fall back to rendering the old health programs page when we reach the /health-programs route. See capabilities/health-journey/src/health-journey-routes.tsx
          for more details.

          This route should be deleted when we deprecate the old Journey Experience
      */}
      <Route component={HealthProgramsPage} />
    </Switch>
  );
};
