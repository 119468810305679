import { MetricType } from '@leagueplatform/dashboard-api';
import { useIntl } from '@leagueplatform/locales';
import type { DataCategory } from 'types/data-type';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { useMemo } from 'react';

// Temporary type used in transition to dynamic dashboard
export type UnitMetricTypes =
  | MetricType
  | 'body_height'
  | 'body_weight'
  | 'waist_circumference';

const UNIT_METRIC_ICON: Record<UnitMetricTypes, string> = {
  distance: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  steps: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  active_duration: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  mindful_duration: DASHBOARD_ASSET_MAP.DASHBOARD_MINDFULNESS_ICON,
  floors_climbed: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  energy_burned: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  body_height: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
  body_weight: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
  waist_circumference: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
  // sleep_duration: DASHBOARD_ASSET_MAP.DASHBOARD_SLEEP_ICON,
  // heart_rate: DASHBOARD_ASSET_MAP.DASHBOARD_HEART_ICON,
  // blood_glucose: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  // blood_pressure: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  // blood_oxygen: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
  // body_temperature: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
};

export const isSupportedUnitMetricType = (
  input: UnitMetricTypes,
): input is UnitMetricTypes => input in UNIT_METRIC_ICON;

export const useDataTypeConfigMap = () => {
  const { $tmap } = useIntl();

  return useMemo(() => {
    const metric = $tmap<UnitMetricTypes>({
      distance: 'INSIGHT_CARD_HEADER_DISTANCE',
      steps: 'INSIGHT_CARD_HEADER_STEPS',
      active_duration: 'INSIGHT_CARD_HEADER_ACTIVE_DURATION',
      mindful_duration: 'INSIGHT_CARD_HEADER_MINDFUL_DURATION',
      floors_climbed: 'INSIGHT_CARD_HEADER_FLOORS_CLIMBED',
      energy_burned: 'INSIGHT_CARD_HEADER_ENERGY_BURNED',
      body_height: 'INSIGHT_CARD_HEADER_HEIGHT',
      body_weight: 'INSIGHT_CARD_HEADER_WEIGHT',
      waist_circumference: 'INSIGHT_CARD_HEADER_WAIST_CIRCUMFERENCE',
      // sleep_duration: 'INSIGHT_CARD_HEADER_SLEEP_DURATION',
      // heart_rate: 'INSIGHT_CARD_HEADER_HEART_RATE',
      // blood_glucose: 'INSIGHT_CARD_HEADER_BLOOD_GLUCOSE',
      // blood_pressure: 'INSIGHT_CARD_HEADER_BLOOD_PRESSURE',
      // blood_oxygen: 'INSIGHT_CARD_HEADER_BLOOD_OXYGEN',
      // body_temperature: 'INSIGHT_CARD_HEADER_BODY_TEMPERATURE',
    });

    const unit = $tmap<Extract<UnitMetricTypes, 'steps' | 'floors_climbed'>>({
      steps: 'INSIGHT_CARD_UNIT_STEPS',
      floors_climbed: 'INSIGHT_CARD_UNIT_FLOORS_CLIMBED',
    });

    return {
      metric,
      unit,
      icon: (unitMetricType: UnitMetricTypes) =>
        UNIT_METRIC_ICON[unitMetricType],
    };
  }, [$tmap]);
};

const CATEGORY_ICON: Record<DataCategory, string> = {
  activity: DASHBOARD_ASSET_MAP.DASHBOARD_ACTIVITY_ICON,
  body: DASHBOARD_ASSET_MAP.DASHBOARD_BODY_ICON,
  // blood: DASHBOARD_ASSET_MAP.DASHBOARD_BLOOD_ICON,
};

export const isSupportedDataCategory = (
  input: DataCategory,
): input is DataCategory => input in CATEGORY_ICON;

export const useDataTypeCategoryConfigMap = () => {
  const { $tmap } = useIntl();

  return useMemo(() => {
    const metric = $tmap<DataCategory>({
      activity: 'DATAPOINT_CATEGORY_ACTIVITY',
      body: 'DATAPOINT_CATEGORY_BODY',
    });

    return {
      metric,
      icon: (dataCategory: DataCategory) => CATEGORY_ICON[dataCategory],
    };
  }, [$tmap]);
};
