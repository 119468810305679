import * as React from 'react';
import { merge } from 'lodash';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import type { Traits } from '@leagueplatform/masonry-api';
import { WidgetType, Orientation } from '@leagueplatform/masonry-api';
import { StackItem } from '@leagueplatform/genesis-core';
import { StackWidgetWrapper } from 'components/stack-widget-wrapper/stack-widget-wrapper.component';
import { COMPLEX_WIDGET_CARDS_WIDTH } from '../../constants';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';
import { useOrientationFromMediaQuery } from '../../hooks/use-orientation-from-media-query.hook';
import { DefaultOrientationMap } from '../../utils/default-orientation-map';
// eslint-disable-next-line import/no-cycle
import { MasonryWSDriverNode } from '../../types/masonry-ws-driver-node';

export interface LayoutWSRendererProps {
  heading?: string;
  items: React.ReactElement[];
  traits: Traits;
  body?: string;
}

export type LayoutWSRendererNode = MasonryEngineNode<
  WidgetType.STACK,
  Omit<LayoutWSRendererProps, 'items'>,
  any,
  {
    items: Array<MasonryWSDriverNode>;
  }
>;

export const LayoutWSRenderer = ({
  heading,
  body,
  items,
  traits,
}: LayoutWSRendererProps) => {
  const traitItems = useGetTraitsForMedia(traits);
  const orientation = useOrientationFromMediaQuery({
    traits,
    defaultOrientation:
      DefaultOrientationMap[
        WidgetType.STACK as keyof typeof DefaultOrientationMap
      ],
  });

  if (!items) return null;

  return (
    <StackWidgetWrapper
      orientation={orientation}
      heading={heading}
      body={body}
      gridColumnCount={traitItems?.gridColumnCount}
    >
      {React.Children.map(items, (item) => (
        <StackItem
          verticalAlignment="fillContainer"
          css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH }}
        >
          {React.cloneElement(item as React.ReactElement, {
            node: merge(
              {},
              (item as unknown as React.ReactElement).props.node,
              {
                properties: {
                  isChildItem: orientation === Orientation.HORIZONTAL,
                },
              },
            ),
          })}
        </StackItem>
      ))}
    </StackWidgetWrapper>
  );
};
