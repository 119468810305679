import React, { Component } from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ToastMessage } from './toast-message.component';
import { Flex } from '@leagueplatform/ui-kit';
import { v4 as uuidv4 } from 'uuid';
import { Toast } from '../toast.types';

const ANIMATION_DURATION_MILIS = 333;

let instance: ToastManager;

interface ToastActions {
  add(toast: Toast): void;
  remove(removeToastId: string): void;
  removeAll(): void;
}

const ToastContainer = styled(Flex)`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  padding: 2.4rem;
  pointer-events: none;

  .transition-item-enter,
  .transition-item-exit-active,
  .transition-item-exit-done {
    transition-property: opacity, transform;
    transition-duration: ${ANIMATION_DURATION_MILIS}ms;
    transition-timing-function: linear, ease-in;
    opacity: 0;
    transform: translateY(100%);
  }

  .transition-item-enter-active,
  .transition-item-enter-done {
    transition-timing-function: linear, cubic-bezier(0.68, -0.55, 0.27, 1.55);

    opacity: 1;
    transform: translateY(0);
  }
`;

const registerToastManager = (ref: ToastManager) => {
  if (ref) {
    instance = ref;
    toastActions.add = ref.addToast;
    toastActions.remove = ref.removeToast;
    toastActions.removeAll = ref.removeAllToasts;
  }
};

const checkToastManagerInstance = () => {
  if (!instance)
    console.warn(
      'You are trying to call one of the ToastManager functions without initializing the ToastManager component first.',
    );
};

export const toastActions: ToastActions = {
  add: checkToastManagerInstance,
  remove: checkToastManagerInstance,
  removeAll: checkToastManagerInstance,
};

export class ToastManager extends Component<{}, { toasts: Array<Toast> }> {
  constructor(props: any) {
    super(props);
    this.state = { toasts: [] };
    registerToastManager(this);
  }

  messageTimeouts: Array<number> = [];

  public addToast = (toast: Toast) => {
    toast.messageId = uuidv4();
    this.setState({ toasts: [...this.state.toasts, toast] });

    if (toast.shouldAutoClose == false ? toast.shouldAutoClose : true)
      this.setToastTimeout(toast);
  };

  public removeToast = (removeToastId: Toast['messageId']) => {
    this.setState({
      toasts: this.state.toasts.filter(
        (toast) => toast.messageId !== removeToastId,
      ),
    });
  };

  public removeAllToasts = () => {
    this.setState({ toasts: [] });
  };

  private setToastTimeout = (toast: Toast) => {
    const timeout = window.setTimeout(() => {
      this.removeToast(toast.messageId);
    }, 5000);
    this.messageTimeouts.push(timeout);
  };

  private removeAllToastsAfterTimeout = () => {
    const timeout = window.setTimeout(() => {
      this.removeAllToasts();
    }, 3000);
    this.messageTimeouts.push(timeout);
  };

  private clearTimeouts = () => {
    this.messageTimeouts.map((timeout) => clearTimeout(timeout));
    this.messageTimeouts = [];
  };

  render() {
    const { toasts } = this.state;

    return (
      <ToastContainer className="toast-messages-container" aria-live="polite">
        <TransitionGroup>
          {toasts?.map((toast: Toast) => {
            const props = {
              ...toast,
              dismiss: this.removeToast,
              toastMessageMouseEntered: this.clearTimeouts,
              toastMessageMouseLeft: this.removeAllToastsAfterTimeout,
            };
            return (
              <CSSTransition
                timeout={ANIMATION_DURATION_MILIS}
                key={toast.messageId}
                classNames="transition-item"
              >
                <ToastMessage {...props} />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ToastContainer>
    );
  }
}
