import React, { useRef } from 'react';
import { Box, useTheme } from '@leagueplatform/genesis-commons';
import {
  ProgressRing,
  ConditionalLinkCard,
  ConditionalLink,
} from '@leagueplatform/web-common-components';
import {
  HeadingText,
  ParagraphText,
  UtilityText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { type Traits, WidgetType } from '@leagueplatform/masonry-api';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface ProgressRingCardWidgetProps {
  action?: string;
  currentText: string;
  eyebrow?: string;
  footerText?: string;
  heading?: string;
  image?: string;
  imageAltText?: string;
  onCardClick?: (e: React.MouseEvent) => void;
  progress: number;
  suffixText?: string;
  traits?: Traits;
}

export type ProgressRingCardWSNode = MasonryEngineNode<
  WidgetType.PROGRESS_RING,
  ProgressRingCardWidgetProps
>;

const PROGRESS_RING_DIMENSION = 82;

export const ProgressRingCardWidget = ({
  action,
  currentText,
  eyebrow,
  footerText,
  heading,
  image,
  imageAltText,
  onCardClick = () => {},
  progress,
  suffixText = '',
  traits,
}: ProgressRingCardWidgetProps) => {
  const { space } = useTheme();
  const linkRefState = useRef<HTMLAnchorElement>(null);
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <StackLayout horizontalAlignment="stretch">
      <ConditionalLinkCard
        linkRef={linkRefState}
        hasLink={Boolean(action)}
        cardStyle={{
          alignItems: 'center',
          backgroundColor: 'surface.background.primary',
          borderRadius: 'large',
          boxShadow: 'card',
          flexDirection: 'column',
          marginTop: `calc(${PROGRESS_RING_DIMENSION}px/2)`,
          padding: 'oneAndHalf',
        }}
      >
        <Box
          width={PROGRESS_RING_DIMENSION}
          height={PROGRESS_RING_DIMENSION}
          position="relative"
          // Progress Ring Height + Card Padding
          transform={`translateY(calc(-50% - ${space.oneAndHalf}px))`}
          // Progress Ring Height - Card Padding + Progress Ring Margin Bottom
          marginBottom={`calc(-${PROGRESS_RING_DIMENSION}px/2 - ${space.oneAndHalf}px + ${space.threeQuarters}px)`}
        >
          <ProgressRing
            current={progress}
            customRingBackgroundColor="success.background.subdued"
            customRingFillColor="success.border.default"
            image={image}
            imageAltText={imageAltText}
            screenReaderText={`${currentText} ${suffixText || ''}`}
            size={PROGRESS_RING_DIMENSION}
            total={100}
          />
        </Box>

        <StackLayout
          css={{ width: '100%', textAlign: 'center' }}
          horizontalAlignment="center"
          spacing="$threeQuarters"
        >
          {eyebrow && (
            <UtilityText
              css={{
                color: '$onSurfaceTextSubdued',
              }}
              size="eyebrow"
            >
              {eyebrow}
            </UtilityText>
          )}
          {heading && (
            <HeadingText
              level="display"
              css={{ marginTop: '$quarter !important' }}
              size="sm"
            >
              {heading}
            </HeadingText>
          )}
          <ParagraphText as="h3" size="sm">
            <ConditionalLink
              ref={linkRefState}
              url={action}
              onClick={onCardClick}
            >
              <ParagraphText
                as="span"
                size="sm"
                css={{
                  fontWeight: traitItems?.emphasizeCurrentValue
                    ? 'bold'
                    : '400',
                }}
              >
                {currentText}
              </ParagraphText>{' '}
              {suffixText}
            </ConditionalLink>
          </ParagraphText>
          {footerText && (
            <ParagraphText size="xs" css={{ color: '$onSurfaceTextSubdued' }}>
              {footerText}
            </ParagraphText>
          )}
        </StackLayout>
      </ConditionalLinkCard>
    </StackLayout>
  );
};
