import { useQuery } from 'react-query';
import { getOnboardingConfig } from 'queries/get-onboarding-config';

export const useGetOnboardingConfig = () => {
  const {
    data: onboardingConfig,
    isLoading,
    isError,
  } = useQuery(['get_show_onboarding'], getOnboardingConfig);

  if (isError) {
    return {
      showOnboarding: false,
      isLoading,
    };
  }

  return {
    showOnboarding: onboardingConfig?.attributes?.showOnboarding,
    isLoading,
  };
};
