import * as React from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import sub from 'date-fns/sub';
import isSameDay from 'date-fns/isSameDay';
import { captureError } from '@leagueplatform/observability';
import {
  getHealthMetrics,
  HealthMetric,
  HealthMetricsQueryParameters,
  MetricType,
  MetricUnit,
  healthMetricsV1Path,
} from '@leagueplatform/dashboard-api';

export type BackFillDataDefaults = {
  unit: MetricUnit;
  type: MetricType;
};

export function backfillChartData(
  backfillDataDefaults: BackFillDataDefaults,
  data: HealthMetric[],
  from: Date,
  targetSize: number,
): HealthMetric[] {
  const datumTemplate: Omit<HealthMetric, 'timestamp'> = {
    ...backfillDataDefaults,
    source: 'backfilled',
    trend: 0,
    value: 0,
  };

  // Create array template with generated data.
  const dataTemplate = Array.from(Array(targetSize)).map((_, index) => ({
    timestamp: sub(from, { days: index }).toISOString(),
    ...datumTemplate,
  }));

  // Map real data onto the template.
  data.forEach((datum) => {
    const index = dataTemplate.findIndex((t) =>
      isSameDay(new Date(t.timestamp), new Date(datum.timestamp)),
    );
    dataTemplate[index] = datum;
  });

  return dataTemplate;
}

export function useGetHealthMetrics(
  params: HealthMetricsQueryParameters,
  {
    // When set to true, fill in any missing gaps within the returned data with empty placeholder values.
    backfill = false,
  } = {},
) {
  const backfillDataDefaults = React.useRef<BackFillDataDefaults>();

  return useQuery(
    [healthMetricsV1Path, params],
    () => getHealthMetrics(params),
    {
      keepPreviousData: true,
      select(healthMetrics) {
        const dataLength = healthMetrics.data.length;

        let unit = healthMetrics.meta?.health_metric_unit;
        let type = params.filter?.type;

        // If for whatever reason the unit and type are undefined, attempt to pick them from the data.
        if (dataLength > 0) {
          if (!unit) {
            unit = healthMetrics.data[0].unit;
          }
          if (!type) {
            type = healthMetrics.data[0].type;
          }
        }

        if (type && unit) {
          backfillDataDefaults.current = {
            /**
             * TODO `type` coming from `params` may be an `Array<MetricType`>,
             * but the backfillDataDefaults ref expects a single `MetricType`.
             * Should address the discrepancy.
             */
            // @ts-expect-error
            type,
            unit,
          };
        }

        if (
          backfill &&
          params.from &&
          params.interval &&
          backfillDataDefaults.current &&
          // Only run the backfill function if there are missing datums.
          ((params.interval === 'weekly' && dataLength < 7) ||
            (params.interval === 'monthly' && dataLength < 30))
        ) {
          return {
            ...healthMetrics,
            data: backfillChartData(
              backfillDataDefaults.current,
              healthMetrics.data,
              new Date(params.from),
              params.interval === 'weekly' ? 7 : 30,
            ),
          };
        }

        return healthMetrics;
      },
      onError(error) {
        captureError(new Error(`Unable to fetch health metrics: ${error}`), {
          context: {
            parameters: {
              api: JSON.stringify(params),
              backfill,
            },
          },
        });
      },
    },
  );
}

// Currently hard-coding the interval to monthly with pages calculated every 30 days.
// Once the back-end supports cursor based pagination we can reimplement that instead of
// doing client side page calculations.  A major downside to calculating the page on the client in
// that we are unable to determine if there is more data available to be fetched.
export function useInfiniteHealthMetrics(
  params: Omit<HealthMetricsQueryParameters, 'from' | 'interval'>,
) {
  return useInfiniteQuery(
    [healthMetricsV1Path, params],
    ({ pageParam = new Date() }) =>
      getHealthMetrics({
        ...params,
        interval: 'monthly',
        from: pageParam.toISOString(),
      }),
    {
      getNextPageParam: (_lastPage, pages) =>
        sub(new Date(), { days: 30 * pages.length }),
      onError(error) {
        captureError(
          new Error(`Unable to fetch paginated health metrics: ${error}`),
          {
            context: {
              parameters: {
                api: JSON.stringify(params),
              },
            },
          },
        );
      },
    },
  );
}
