import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

interface Order {
  type: string;
  asc: boolean;
}

export const GET_CLAIMS_MESSAGE_TYPE = 'get_claims';

export const getClaims = async (
  userId?: string,
  limit: number = 3,
  order: Order = { type: 'last_updated', asc: false },
) => {
  const info = {
    user_id: userId,
    limit,
    order,
  };

  const params = {
    message_type: GET_CLAIMS_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
