import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { useLocation } from '@leagueplatform/routing';

import { clamp, last } from 'lodash';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { useIntl } from '@leagueplatform/locales';
import { colour, Text } from '@leagueplatform/ui-kit';
import {
  PrimaryButton as ForwardArrow,
  QuietButton,
  genesisStyled,
  css,
  VisuallyHidden,
  Flex,
} from '@leagueplatform/genesis-commons';

import {
  sendAnalyticsEvent,
  sendAnalyticsPageView,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { ASSESSMENT_ASSET_KEYS } from '../assessment.types';
import { ModuleWelcome } from './module-welcome/module-welcome.view';
import { TopicOnboarding } from './topic-onboarding.view';
import { Question } from './question.view';
import { PRODUCT_AREA, SCREEN_NAME } from '../constants/analytics.constants';
import { PULSE_CHECK_TYPE } from '../constants';

const ARROW_BUTTON_DIMENSIONS = '56px';

const ARROW_BUTTON_PLACEMENT = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
});

const ArrowWrapper = genesisStyled(Flex)`
  ${({ showArrow }) => !showArrow && 'display: none'};
  ${({ isMobile }) => !isMobile && 'position: absolute;'};
  ${({ placement }) => {
    if (placement === ARROW_BUTTON_PLACEMENT.RIGHT) {
      return 'right: 10%;';
    }
    if (placement === ARROW_BUTTON_PLACEMENT.LEFT) {
      return 'left: 10%;';
    }
    return '';
  }}
  top: 40%;
`;

const BackArrow = genesisStyled(QuietButton)(
  css({
    '&:hover, &:focus': {
      backgroundColor: 'decorative.brand.primary.brightest',
    },
  }),
);

const Arrow = styled.img`
  display: block;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledText = styled(Text)`
  color: ${colour('neutral.gray')};
`;

export const Fade = styled.div`
  transition: opacity 250ms cubic-bezier(0.39, 0.58, 0.57, 1);
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

// usePrevious is a custom hook used to keep track of the previous
// value of a state
const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const ModuleNavigator = ({
  moduleNavigation,
  currentTopic,
  currentIndex,
  moduleQuestionsLength,
  currentModuleQuestionNumber,
  setIsResuming,
  setCurrentIndex,
  moduleId,
  completeQuestion,
  userSelections,
  topics,
  moduleType,
  setHasAnsweredAtLeastOneQuestion,
  healthAssessmentIsEditing,
  introScreen,
  setCurrentUserSelections,
  isLoadingCompleteQuestion,
}) => {
  const location = useLocation();
  const {
    id: questionId,
    description,
    answerTitle,
    text,
    type,
    questionType,
    answers,
    isSkippable,
    learnMoreTitle,
    learnMoreContent,
  } = moduleNavigation[currentIndex];

  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const isBelowTablet = useMediaQuery(queryHelpers.down('tablet'));

  const {
    name: topicName,
    description: topicDescription,
    imageId,
    numOfQuestions,
  } = currentTopic;

  const [showBackArrow, setShowBackArrow] = useState(currentIndex !== 0);
  const [isAnimate, setIsAnimate] = useState(true);
  const [moduleTransitionedIn, setModuleTransitionedIn] = useState(false);

  useEffect(() => {
    if (type === 'question') {
      sendAnalyticsPageView({
        product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
        screen_name: SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE,
        assessment_id: moduleId,
        assessment_type: moduleType,
        assessment_questionnaire_category:
          moduleType === PULSE_CHECK_TYPE ? null : topicName?.toLowerCase(),
        current_step: currentModuleQuestionNumber,
        total_steps: moduleQuestionsLength,
        questionnaire_id: questionId,
        questionnaire_title: text,
      });
    }
  }, [
    type,
    currentIndex,
    moduleId,
    moduleType,
    topicName,
    questionId,
    text,
    moduleNavigation.length,
    currentModuleQuestionNumber,
    moduleQuestionsLength,
  ]);

  const submitAnswers = useCallback(() => {
    let completeAnswers = {};
    const { id: selectedAnswerId, value, unit } = userSelections[questionId];

    switch (questionType) {
      case 'multiple_choice':
      case 'multiple_selection':
      case 'drop_down':
        // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
        const key = `${questionType}_answers`;
        completeAnswers = {
          [key]: answers.filter(
            ({ id }) => selectedAnswerId && selectedAnswerId.includes(id),
          ),
        };
        break;
      case 'input':
        completeAnswers = {
          input_answer: {
            ...answers[0],
            value,
            value_unit_type: unit,
          },
        };
        break;
      default:
    }

    setIsResuming(false);

    const params = new URLSearchParams(location.search);
    const campaignId = params.get('campaign_id');
    const activityId = params.get('activity_id');
    const isFromHome = params.get('b') === '1';

    completeQuestion({
      moduleId,
      questionId,
      answers: completeAnswers,
      campaignId,
      activityId,
      isFromHome,
    });
  }, [
    answers,
    completeQuestion,
    moduleId,
    questionId,
    questionType,
    userSelections,
    location,
    setIsResuming,
  ]);

  // if on a question page, save user answers with completeQuestion
  // if on other page, increment index without saving
  const onForwardArrow = useCallback(() => {
    setIsAnimate(false);
    setShowBackArrow(true);

    if (type === 'question') {
      setHasAnsweredAtLeastOneQuestion(true);
      submitAnswers();
    } else {
      // increment index
      setCurrentIndex(clamp(currentIndex + 1, 0, moduleNavigation.length - 1));
      setIsResuming(false);
    }

    if (type === 'welcome') {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
        screen_name: SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_INTRO,
        detail: 'begin assessment',
        assessment_id: moduleId,
        assessment_type: moduleType,
        has_health_profile: healthAssessmentIsEditing,
      });
    } else {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
        screen_name:
          type === 'question'
            ? SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE
            : SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_CATEGORY_INTRO,
        detail:
          currentIndex === moduleNavigation.length - 1
            ? 'complete assessment'
            : 'continue assessment',
        assessment_questionnaire_category:
          moduleType === PULSE_CHECK_TYPE ? null : topicName?.toLowerCase(),
        assessment_id: moduleId,
        assessment_type: moduleType,
        current_step: type === 'question' ? currentModuleQuestionNumber : null,
        total_steps: type === 'question' ? moduleQuestionsLength : null,
        questionnaire_id: questionId ?? null,
        questionnaire_title: text ?? null,
      });
    }
  }, [
    currentIndex,
    moduleNavigation,
    submitAnswers,
    type,
    setHasAnsweredAtLeastOneQuestion,
    moduleQuestionsLength,
    currentModuleQuestionNumber,
    healthAssessmentIsEditing,
    moduleId,
    moduleType,
    questionId,
    text,
    topicName,
    setCurrentIndex,
    setIsResuming,
  ]);

  const decrementIndex = () => {
    setIsAnimate(false);
    setCurrentIndex(clamp(currentIndex - 1, 0, moduleNavigation.length));
    setIsResuming(false);
    if (currentIndex - 1 === 0) {
      setShowBackArrow(false);
    }
  };

  // currentIndex keeps track of the current index WITHIN THE ENTIRE
  // MODULE!!!

  // We need to wait for both the previous module to finish fading out AND the next module's props to be passed down
  // We can't guarantee which will happen first.
  // If we don't wait for both to happen questions will flicker.

  // Defining an empty ref value to track which module is currently displayed
  // Need to define separately as currentIndex (prop) and prevCurrentIndex (previous hook) may update before the previous module has finished fading out.
  const activeModuleIndex = useRef(currentIndex);

  useEffect(() => {
    // If the transition ends before the props for the next module have been
    // loaded, and there are no errors in the current question, we do nothing.
    if (
      !moduleTransitionedIn &&
      currentIndex === activeModuleIndex.current &&
      !userSelections[questionId]?.error?.hasError
    ) {
      return;
    }

    // If there is an error, re-display the current question
    if (
      !isAnimate &&
      !moduleTransitionedIn &&
      activeModuleIndex.current === currentIndex &&
      userSelections[questionId]?.error?.hasError
    ) {
      setIsAnimate(true);
    }

    // Check that no module has already faded in and no module is fading out.
    if (
      !isAnimate &&
      !moduleTransitionedIn &&
      activeModuleIndex.current !== currentIndex
    ) {
      activeModuleIndex.current = currentIndex;
      setIsAnimate(true);
    }
  }, [
    activeModuleIndex,
    isAnimate,
    currentIndex,
    moduleTransitionedIn,
    userSelections,
    questionId,
  ]);

  const [showArrow, setShowArrow] = useState(false);
  const { formatMessage } = useIntl();

  // keep track of previous index as well as current one, so we can see
  // which direction the user went
  const prevCurrentIndex = usePrevious(currentIndex);

  // This effect determines if we navigated to a topic page
  // if so, show the arrow
  useEffect(() => {
    if (type === 'topic' || type === 'welcome') {
      setShowArrow(true);
    }

    // prevCurrentIndex equal to undefined means that the user
    // is not moving backwards, but loading that screen for the first time.
    //
    // Both analytics events are called on page load, and the page being
    // of any type after onboarding.
    if (prevCurrentIndex === undefined && currentIndex === 0) {
      sendAnalyticsPageView({
        screen_name: `Assessment - ${moduleType} - Begin Assessment`,
      });
      sendAnalyticsEvent({
        category: `Assessment - ${moduleType}`,
        action: 'Begin Assessment',
        params: {
          module_id: moduleId,
          total_module_questions: moduleQuestionsLength,
        },
      });
    }
  }, [
    type,
    setShowArrow,
    currentIndex,
    moduleType,
    moduleId,
    prevCurrentIndex,
    moduleQuestionsLength,
  ]);

  const questionNumber =
    // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
    currentTopic.questionIds?.findIndex((id) => id === questionId) + 1;

  const renderModuleComponent = (componentType) => {
    switch (componentType) {
      case 'welcome':
        return (
          <ModuleWelcome
            topics={topics}
            setShowBackArrow={setShowBackArrow}
            moduleId={moduleId}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...introScreen}
          />
        );

      case 'question':
        return (
          <Question
            key={questionId}
            questionId={questionId}
            description={description}
            answerTitle={answerTitle}
            title={text}
            questionType={questionType}
            answers={answers}
            setShowArrow={setShowArrow}
            userSelections={userSelections}
            isSkippable={isSkippable}
            learnMoreTitle={learnMoreTitle}
            learnMoreContent={learnMoreContent}
            setCurrentUserSelections={setCurrentUserSelections}
            setIsResuming={setIsResuming}
            questionNumber={questionNumber}
            totalQuestions={numOfQuestions}
          />
        );
      default:
        return (
          <TopicOnboarding
            title={topicName}
            amtQuestions={numOfQuestions}
            description={topicDescription}
            imageUrl={getContentUrl(imageId)}
            moduleId={moduleId}
          />
        );
    }
  };

  // Defines the text for the forward arrow.
  const forwardArrowText = useCallback(() => {
    if (
      currentIndex === 0 &&
      type !== 'question' &&
      !healthAssessmentIsEditing
    ) {
      return formatMessage({ id: 'LETS_BEGIN' });
    }

    if (last(moduleNavigation).id === questionId) {
      return formatMessage({ id: 'COMPLETE' });
    }

    return formatMessage({ id: 'NEXT' });
  }, [
    healthAssessmentIsEditing,
    currentIndex,
    moduleNavigation,
    questionId,
    type,
    formatMessage,
  ]);

  return (
    <>
      <Flex
        width={type === 'welcome' && !isMobile ? '60%' : 'auto'}
        justify="center"
        marginX={isMobile ? 'oneAndHalf' : 'auto'}
        marginY={isMobile ? 'none' : 'auto'}
        aria-live="polite"
      >
        <Transition
          in={isAnimate}
          timeout={250}
          appear
          unmountOnExit
          onEnter={() => setModuleTransitionedIn(true)}
          onExited={() => setModuleTransitionedIn(false)}
        >
          {(state) => (
            <Fade state={state}>
              {isAnimate && renderModuleComponent(type)}
            </Fade>
          )}
        </Transition>
      </Flex>

      {isAnimate && (
        <Flex
          flexDirection="row"
          margin="one"
          justifyContent="center"
          gap="one"
        >
          <ArrowWrapper
            isMobile={isBelowTablet}
            showArrow={showBackArrow}
            placement={ARROW_BUTTON_PLACEMENT.LEFT}
          >
            <BackArrow
              width={ARROW_BUTTON_DIMENSIONS}
              height={ARROW_BUTTON_DIMENSIONS}
              paddingX="none"
              transition="background-color 200ms ease-in-out"
              backgroundColor="decorative.brand.primary.pastel"
              borderRadius="circle"
              onClick={() => {
                decrementIndex();
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
                  screen_name:
                    type === 'question'
                      ? SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE
                      : SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_CATEGORY_INTRO,
                  detail: 'previous step',
                  assessment_questionnaire_category:
                    moduleType === PULSE_CHECK_TYPE
                      ? null
                      : topicName?.toLowerCase(),
                  assessment_id: moduleId,
                  assessment_type: moduleType,
                  current_step:
                    type === 'question' ? currentModuleQuestionNumber : null,
                  total_steps:
                    type === 'question' ? moduleQuestionsLength : null,
                  questionnaire_id: questionId ?? null,
                  questionnaire_title: text ?? null,
                });
              }}
            >
              <VisuallyHidden>
                {formatMessage({ id: 'PREVIOUS' })}
              </VisuallyHidden>
              <Arrow
                src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.BACK_ARROW)}
                alt=""
              />
            </BackArrow>
          </ArrowWrapper>
          <ArrowWrapper
            isMobile={isBelowTablet}
            showArrow={showArrow}
            flexDirection="column"
            justify="center"
            alignItems="center"
            placement={ARROW_BUTTON_PLACEMENT.RIGHT}
          >
            <ForwardArrow
              borderRadius="circle"
              width={ARROW_BUTTON_DIMENSIONS}
              height={ARROW_BUTTON_DIMENSIONS}
              paddingX="none"
              onClick={onForwardArrow}
              transition="background-color 200ms ease-in-out"
              aria-live="polite"
              isLoading={isLoadingCompleteQuestion}
            >
              <Arrow
                src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.FORWARD_ARROW)}
                alt={forwardArrowText()}
              />
            </ForwardArrow>
            {!isMobile && (
              <StyledText mt={1} fontSize={1} aria-hidden="true">
                {forwardArrowText()}
              </StyledText>
            )}
          </ArrowWrapper>
        </Flex>
      )}
    </>
  );
};

ModuleNavigator.propTypes = {
  moduleNavigation: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        topicId: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.shape({
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string,
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          }),
        ),
        description: PropTypes.string,
        id: PropTypes.string,
        text: PropTypes.string,
        topicId: PropTypes.string,
        questionType: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        answerTitle: PropTypes.string.isRequired,
        isSkippable: PropTypes.bool,
        learnMoreTitle: PropTypes.string.isRequired,
        learnMoreContent: PropTypes.string.isRequired,
      }),
    ]),
  ),
  currentIndex: PropTypes.number.isRequired,
  moduleQuestionsLength: PropTypes.number.isRequired,
  currentModuleQuestionNumber: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentTopic: PropTypes.shape({
    color: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    imageId: PropTypes.string,
    name: PropTypes.string,
    numOfQuestions: PropTypes.number,
    url: PropTypes.string,
    questionIds: PropTypes.arrayOf(PropTypes.string),
  }),
  completeQuestion: PropTypes.func.isRequired,
  setHasAnsweredAtLeastOneQuestion: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
  moduleId: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
      imageId: PropTypes.string,
    }),
  ),
  moduleType: PropTypes.string.isRequired,
  healthAssessmentIsEditing: PropTypes.bool.isRequired,
  introScreen: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    timeToCompleteText: PropTypes.string,
  }),
  setCurrentUserSelections: PropTypes.func.isRequired,
  setIsResuming: PropTypes.func.isRequired,
  isLoadingCompleteQuestion: PropTypes.bool.isRequired,
};

ModuleNavigator.defaultProps = {
  moduleNavigation: [],
  currentTopic: {},
  topics: [],
  introScreen: null,
};
