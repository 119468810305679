import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  let size;
  const {
    'aria-hidden': ariaHidden,
    className,
    colour,
    fill,
    icon,
    id,
    role,
    size: svgSize,
    stroke,
    strokeWidth,
    title,
    viewBox,
  } = props;
  const svgProps = icon[0].props;
  const colourProp = icon[1] || 'fill';

  const applyColour = (itemProps) => {
    return {
      ...itemProps,
      fill:
        colourProp === 'fill'
          ? colour || fill || itemProps.fill
          : fill || itemProps.fill,
      stroke:
        colourProp === 'stroke'
          ? colour || stroke || itemProps.stroke
          : stroke || itemProps.stroke,
    };
  };

  const renderNodes = (nodes) => {
    const items = Array.isArray(nodes) ? nodes : [nodes];
    return items.map((item, idx) => {
      const { children } = item.props;
      const itemProps = applyColour(item.props);

      switch (item.type) {
        case 'g':
          return (
            <g
              key={idx} // eslint-disable-line
              {...itemProps}
            >
              {children && renderNodes(children)}
            </g>
          );
        case 'circle':
          return (
            <circle
              key={idx} // eslint-disable-line
              {...itemProps}
            />
          );
        default:
          return (
            <path
              key={idx} // eslint-disable-line
              {...itemProps}
            />
          );
      }
    });
  };

  const paths = renderNodes(svgProps.children);

  switch (svgSize) {
    case 'small':
      size = '2rem';
      break;
    case 'medium':
      size = '3rem';
      break;
    case 'large':
      size = '6rem';
      break;
    default:
      if (typeof svgSize === 'string') {
        size = svgSize;
      } else {
        size = `${svgSize}px`;
      }
  }

  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: size,
      height: size,
      strokeWidth,
    },
  };

  const hasTitle = !!title;

  return (
    <svg
      aria-hidden={hasTitle ? false : ariaHidden}
      aria-label={title}
      className={className}
      id={id}
      role={hasTitle ? 'img' : role}
      style={styles.svg}
      viewBox={svgProps.viewBox}
      {...(viewBox && { viewBox })}
    >
      {paths}
    </svg>
  );
};

Icon.defaultProps = {
  size: 'small',
  fill: null,
  stroke: null,
  title: undefined,
  colour: null,
  strokeWidth: 'initial',
  viewBox: undefined,
  className: undefined,
  'aria-hidden': true,
  id: undefined,
  role: undefined,
};

Icon.propTypes = {
  /** One of small, medium, large or unit in pixels */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The name of the icon */
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
  /** Fill colour */
  fill: PropTypes.string,
  /** Stroke colour */
  stroke: PropTypes.string,
  /** Stroke width */
  strokeWidth: PropTypes.string,
  /** Icon colour, if used fill and stroke will be ignored */
  colour: PropTypes.string,
  /** The title of the svg for accessibility */
  title: PropTypes.string,
  // For scaling of icons
  viewBox: PropTypes.string,
  className: PropTypes.string,
  'aria-hidden': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  role: PropTypes.string,
};

export default Icon;
