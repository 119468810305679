import * as React from 'react';
import { ErrorContext } from 'sentry/sentry.types';
import { captureError } from '../observability';
import { combineErrorContexts } from '../observability.utils';

type Props = {
  children: any;
  fallback?: React.ReactNode;
  errorContext?: ErrorContext;
  onError?: (err: Error) => void;
};

export class ObservabilityErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Creating a new error object avoids error dedupe in case of multiple Sentry instances
    const err = new Error(error);
    err.stack = error.stack;
    const { errorContext, onError } = this.props;

    captureError(
      err,
      combineErrorContexts([
        {
          errorName: `Error Boundary: UI Error - ${err.message}`,
          context: { 'Error Boundary Error Info': errorInfo },
        },
        errorContext || {},
      ]),
    );
    if (onError) onError(err);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return fallback || null;
    }
    return children;
  }
}
