export const createLifecycleTypes = (type) => {
  const BASE = type;
  const VISITED = `${type}_VISITED`;
  const EXITED = `${type}_EXITED`;
  return {
    BASE,
    VISITED,
    EXITED,
    visit: (props) => ({ type: VISITED, payload: { props } }),
    exit: (props) => ({ type: EXITED, payload: { props } }),
  };
};
