import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

export const useIsDashboardEnabled = () => {
  const ENABLE_DASHBOARD_WEB = 'enable_dashboard_web';
  const ENABLE_DASHBOARD_BE = 'enable_dashboard';

  const { isLoading: isWebLoading, data: isDashboardWebEnabled } =
    useFeatureFlagQuery(ENABLE_DASHBOARD_WEB);

  const { isLoading: isBeLoading, data: isDashboardBeEnabled } =
    useFeatureFlagQuery(ENABLE_DASHBOARD_BE);

  const isDashboardEnabled = isDashboardWebEnabled && isDashboardBeEnabled;

  const isLoading = isWebLoading || isBeLoading;

  return {
    isLoading,
    isDashboardEnabled,
  };
};
