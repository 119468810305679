import * as React from 'react';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  ImageMessage,
  Button,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import {
  useWalletDetailAnalytics,
  useWalletDetailScreenLoaded,
} from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { ACTIVITY_CONTENT_BASE_WIDTH } from '../constants/multi-step-footer-constants';

export interface ExitIntentScreenProps {
  cancelFunction: () => void;
  continueFunction: () => void;
}

export const ExitIntentScreen = ({
  cancelFunction,
  continueFunction,
}: ExitIntentScreenProps) => {
  const { formatMessage } = useIntl();
  const trackCancelEventCallback = useWalletDetailAnalytics();
  const LEAVE_OR_EXIT_WARNING_IMAGE = handleStaticAsset(
    WALLET_ASSET_KEYS.LEAVE_OR_EXIT_WARNING,
  ) as string;

  useWalletDetailScreenLoaded(BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_CANCEL_ORDER);

  const handleClose = React.useCallback(() => {
    trackCancelEventCallback(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_CANCEL_ORDER,
      detail: 'cancel order',
    });

    cancelFunction();
  }, [cancelFunction, trackCancelEventCallback]);

  return (
    <Box
      css={{
        display: 'flex',
        margin: '0 auto',
        backgroundColor: '$surfaceBackgroundPrimary',
        borderRadius: '$large',
        boxShadow: '$dropdown',
        flexDirection: 'column',
        overflow: 'hidden auto',
        width: ACTIVITY_CONTENT_BASE_WIDTH,
        gap: '$one',
        padding: '$three',
      }}
    >
      <ImageMessage image={LEAVE_OR_EXIT_WARNING_IMAGE} imageGap="$twoAndHalf">
        <StackLayout horizontalAlignment="center" spacing="$half">
          <HeadingText level="3" size="lg" css={{ textAlign: 'center' }}>
            {formatMessage({ id: 'CANCEL_ORDER_NEW_CARD_TITLE' })}
          </HeadingText>

          <ParagraphText>
            {formatMessage({ id: 'CANCEL_ORDER_NEW_CARD_DESC' })}
          </ParagraphText>

          <Button
            onClick={handleClose}
            width="fillContainer"
            css={{ marginTop: '$twoAndHalf', textTransform: 'capitalize' }}
          >
            {formatMessage({ id: 'CANCEL_ORDER' })}
          </Button>

          <Button
            priority="secondary"
            onClick={continueFunction}
            width="fillContainer"
            css={{ marginTop: '$one', textTransform: 'capitalize' }}
          >
            {formatMessage({ id: 'KEEP_GOING' })}
          </Button>
        </StackLayout>
      </ImageMessage>
    </Box>
  );
};
