import get from 'lodash/get';
import { units } from './breakpoints';
import { vars } from './vars';

/**
 * For now mock genesis values for space as POC. The values and key names are the same,
 * so is safe.
 *
 * Will replace once we import Genesis after
 * Done because importing Genesis v1.0.0 breaks tests due to missing `cjs` formatting.
 */

const spacingValues = [0, 4, 8, 12, 16, 24, 32, 40];
const genesisSpace = {
  none: spacingValues[0],
  quarter: spacingValues[1],
  half: spacingValues[2],
  threeQuarters: spacingValues[3],
  one: spacingValues[4],
  oneAndHalf: spacingValues[5],
  two: spacingValues[6],
  twoAndHalf: spacingValues[7],
};

export const theme = {
  ...vars,
  breakpoints: [
    `${units.phone}px`,
    `${units.tablet}px`,
    `${units.lap}px`,
    `${units.desktop}px`,
  ],
  colors: vars.colour,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  radii: [0, 2, 3, 5],
  space: { ...[0, 8, 16, 32, 64], genesis: genesisSpace },
};

/**
 * Helper to grab theme values out of the theme prop.
 * ```
 * const example = styled.div`
 *   font-size: ${getThemeValue('fontSizes.3')}px; // returns theme.fontSizes[3] - or - 20px
 * `;
 * ```
 * @param {String | Array} path - lodash object path of a value in theme (see https://lodash.com/docs/4.17.11#get)
 * @returns {String} a value from the Genesis theme
 */
export const getThemeValue =
  (path) =>
  ({ theme: themeProp }) =>
    get(themeProp, path);

/**
 * Helper to grab colours from the Genesis Design System colour palets inside your styled components.
 * ```
 * const example = styled.input`
 *   border-color: ${colour('greyLight')};
 * `;
 * ```
 * @param {String | Array} colourName - the name of a colour in Genesis Design System (Or a lodash object path).
 * @returns {String} a hex value from the Genesis colour palette.
 */
export const colour = (colourName) => getThemeValue(['colors', colourName]);

/**
 * Given a level on the Genesis space scale, returns a pixel value
 * ```
 * const example = styled.div`
 *   padding: ${spaceInPx('3')}; // returns theme.space[3] + 'px' - or - 32px
 * `;
 * ```
 * @param {String | Array} level - a level on the Genesis space scale
 * @returns {String} pixel value
 */
export const spaceInPx =
  (level) =>
  ({ theme: themeProp }) =>
    `${get(get(themeProp, 'space'), level)}px`;

/**
 * Given a level on the Genesis fontSizes scale, returns a pixel value
 * ```
 * const example = styled.div`
 *   font-size: ${fontSizeInPx('3')}; // returns theme.fontSizes[3] + 'px' - or - 20px
 * `;
 * ```
 * @param {String | Array} level - a level on the Genesis space scale
 * @returns {String} pixel value
 */
export const fontSizeInPx =
  (level) =>
  ({ theme: themeProp }) =>
    `${get(themeProp, ['fontSizes', level])}px`;

/**
 * Given a level on the Genesis radii scale, returns a pixel value
 * ```
 * const example = styled.div`
 *   border-radius: ${radiusInPx(2)}; // returns theme.radii[2] + 'px' - or - 3px
 * `;
 * ```
 * @param {String | Array} level - a level on the Genesis space scale
 * @returns {String} pixel value
 */
export const radiusInPx =
  (level) =>
  ({ theme: themeProp }) =>
    `${get(themeProp, ['radii', level])}px`;
