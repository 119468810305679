import { useIntl } from '@leagueplatform/locales';
import type { MetricUnit } from '../types/activity-details-props.types';

export const useMetricUnitTranslator = () =>
  useIntl().$tmap<MetricUnit>({
    count: 'COUNT',
    s: 'S',
    m: 'M',
    min: 'MIN',
    cal: 'CAL',
    kcal: 'KCAL',
    bmp: 'BMP',
    km: 'KM',
    'mg/dl': 'MG/DL',
  });
