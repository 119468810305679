/**
 * Cross-references the users current permissions against the required permissions and
 * returns true if all permissions are satisfied.
 */
export function doesUserHaveSufficientWearablePermissions(
  requiredPermissions: string[],
  grantedPermissions: string[],
) {
  const userHasSufficiantWearablePermissions =
    requiredPermissions.filter((requiredPermission) =>
      grantedPermissions.includes(requiredPermission),
    ).length === requiredPermissions.length;

  return userHasSufficiantWearablePermissions;
}
