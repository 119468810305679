import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';

export const DROPDOWN_MEGA_TOOLTIP_WIDTH = 260;
export const DROPDOWN_MEGA_TOOLTIP_MOBILE_WIDTH = '100%';
export const DROPDOWN_MEGA_TOOLTIP_MOBILE_HEIGHT = '337px';
export const SPACE_BELOW_BUTTON_AND_ARROW = '62px';
export const MOBILE_SPACE_BELOW_BUTTON_AND_ARROW = '48px';
export const BORDER_WIDTH = 10;
export const OVERLAY_Z_INDEX = 998;
export const TOOLTIP_Z_INDEX = 999;

const DropdownMegaTooltipBase = genesisStyled(Box)(({ isOpen }) =>
  css({
    padding: 'one',
    transitionProperty: 'opacity',
    transitionDuration: '250ms',
    animationTimingFunction: 'ease',
    opacity: isOpen ? '1' : '0',
    listStyle: 'none',
    position: 'absolute',
    display: isOpen ? 'flex' : 'auto',
    flexDirection: 'column',
    border: 'thin',
    backgroundColor: 'interactive.background.default',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '9px',
    zIndex: TOOLTIP_Z_INDEX,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '100%',
      width: 0,
      height: 0,
      borderWidth: `${BORDER_WIDTH}px`,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderBottomColor: 'interactive.background.default',
      borderTop: 'none',
    },
  }),
);

export const DropdownMegaTooltip = genesisStyled(DropdownMegaTooltipBase)(
  css({
    gap: '0',
    right: '0',
    top: SPACE_BELOW_BUTTON_AND_ARROW,
    marginRight: '-10px',
    minHeight: 'auto',
    width: DROPDOWN_MEGA_TOOLTIP_WIDTH,
    '&:after': {
      right: '10px',
    },
  }),
);

export const DropdownMegaTooltipMobile = genesisStyled(DropdownMegaTooltipBase)(
  css({
    gap: 'one',
    left: 0,
    top: MOBILE_SPACE_BELOW_BUTTON_AND_ARROW,
    minHeight: DROPDOWN_MEGA_TOOLTIP_MOBILE_HEIGHT,
    width: DROPDOWN_MEGA_TOOLTIP_MOBILE_WIDTH,
    borderRadius: '0 0 9px 9px',
  }),
);

export const Overlay = genesisStyled(Box)({
  position: 'fixed',
  top: MOBILE_SPACE_BELOW_BUTTON_AND_ARROW,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: OVERLAY_Z_INDEX,
});
