import * as React from 'react';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  MoreOptionsLink,
} from '@leagueplatform/health-profile-common';
import {
  StackLayout,
  Button,
  HeadingText,
  UtilityText,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  LoadingIndicator,
  Sidebar,
} from '@leagueplatform/web-common-components';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { useInfiniteHealthMetricsV2 } from 'hooks/use-get-health-metrics-v2.hook';
import { ListItem } from 'components/list-item';
import { ErrorPanel } from 'components/error-panel';
import { getValueUnitDisplayLabel } from 'utils/get-value-unit-display-label';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { HealthPrograms } from 'pages/details2/components/health-programs';
import { HealthLiteracyContent } from 'components/health-literacy/health-literacy-content';
import { useIsSelfReportedDataEnabled } from 'hooks/use-is-self-reported-data-enabled.hook';
import { AddMetricDataButton } from 'pages/details2/components/add-metric-data-button';

type AllRecordedDataProps = {
  match: {
    params: {
      dataType: string;
    };
  };
};

const handleAnalytics = (
  eventName: EVENT_NAME,
  metricType?: string,
  detail?: string,
) =>
  trackAnalyticsEvent(eventName, {
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.ALL_RECORDED_DATA,
    metric_type: metricType,
    ...(detail && { detail }),
  });

export const AllRecordedData = ({
  match: {
    params: { dataType },
  },
}: AllRecordedDataProps) => {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { isSelfReportedDataEnabled } = useIsSelfReportedDataEnabled();
  const {
    isLoading: isLoadingHealthMetrics,
    isError: isErrorHealthMetrics,
    isRefetching: isRefetchingHealthMetrics,
    data: healthMetrics,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteHealthMetricsV2({
    filter: {
      type: dataType,
    },
    fields: { type: ['type', 'value', 'source', 'unit', 'timestamp'] },
  });

  const metricDisplayName = healthMetrics?.pages[0].data[0].metricDisplayName;

  React.useEffect(() => {
    handleAnalytics(EVENT_NAME.SCREEN_LOADED, dataType);
  }, [dataType]);

  if (isLoadingHealthMetrics && !healthMetrics) {
    return <LoadingIndicator />;
  }
  const allMetricConfigs = healthMetrics?.pages[0].data[0].configs.data;
  const firstConfigName = allMetricConfigs?.[0].name;
  // display the config name with the entry if there are multiple metric configs
  // and if the `name` field of the metric configs are different
  const displayConfigName =
    Boolean(allMetricConfigs?.length) &&
    !allMetricConfigs?.every(
      (metricConfig) => metricConfig.name === firstConfigName,
    );
  // TODO: implement handleEditClick functionality after completion of
  // edit data screens in https://everlong.atlassian.net/browse/ABTM-4654
  const handleEditClick = () => {
    handleAnalytics(EVENT_NAME.BUTTON_CLICKED, dataType, 'edit reading');
  };

  const showHealthLiteracyOnAllRecordedDataScreen =
    healthMetrics?.pages[0].data[0].showHealthLiteracyOnAllRecordedDataScreen;
  const showAddDataButton =
    healthMetrics?.pages[0].data[0].addDataUrl && isSelfReportedDataEnabled;
  return (
    <HealthProfilePageWrapper
      title={formatMessage({
        id: 'ALL_RECORDED_DATA',
      })}
      sidebar={
        <Sidebar>
          <MoreOptionsCard>
            <MoreOptionsLink
              to="data-sources"
              onClick={() =>
                handleAnalytics(
                  EVENT_NAME.BUTTON_CLICKED,
                  dataType,
                  'view data sources',
                )
              }
            >
              {formatMessage({ id: 'VIEW_DATA_SOURCES' })}
            </MoreOptionsLink>
          </MoreOptionsCard>
          {showAddDataButton ? <AddMetricDataButton /> : null}
        </Sidebar>
      }
    >
      <HeadingText
        size="xxl"
        level="2"
        css={{ paddingTop: '$oneAndHalf', paddingBottom: '$twoAndHalf' }}
      >
        {metricDisplayName}
      </HeadingText>
      <StackLayout spacing="$oneAndHalf">
        {isErrorHealthMetrics ? (
          <ErrorPanel
            isRefetching={isRefetchingHealthMetrics}
            onRetry={refetch}
          />
        ) : (
          <Box as="ul" css={{ width: '100%' }}>
            {healthMetrics?.pages.map((page) =>
              page.data[0].values.data.map((dataMetric) => {
                const { id, source, timestamp, config } = dataMetric;

                const isSelfReported = config.data.source === 'self_reported';
                const date = formatDateWithUserProfileLocation(
                  timestamp,
                  DATE_FORMAT,
                );

                return (
                  <ListItem as="li" key={id}>
                    <StackLayout>
                      <UtilityText as="p" emphasis="emphasized">
                        {getValueUnitDisplayLabel({
                          value: dataMetric.value,
                          label: dataMetric.label,
                          unitOptions: dataMetric.config.data.unit,
                        }).map(([displayValue, displayUnit], index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={`${index} + ${dataMetric.type}`}>
                            {displayValue} {displayUnit}{' '}
                            {displayConfigName
                              ? ` (${config.data.name.toLocaleLowerCase()})`
                              : null}
                          </span>
                        ))}
                      </UtilityText>
                      <UtilityText emphasis="subtle">{source}</UtilityText>
                    </StackLayout>
                    <StackLayout
                      orientation="horizontal"
                      horizontalAlignment="end"
                      verticalAlignment="center"
                      spacing="$half"
                    >
                      <UtilityText
                        as="p"
                        emphasis="subtle"
                        css={{ paddingTop: '$half', paddingBottom: '$half' }}
                      >
                        {date}
                      </UtilityText>
                      {isSelfReported && isSelfReportedDataEnabled && (
                        <Button
                          onClick={handleEditClick}
                          priority="secondary"
                          quiet
                          icon="illustrativeCompose"
                          hideLabel
                        >
                          {formatMessage(
                            { id: 'EDIT_METRIC_DATA' },
                            {
                              metric: metricDisplayName,
                            },
                          )}
                        </Button>
                      )}
                    </StackLayout>
                  </ListItem>
                );
              }),
            )}
            {hasNextPage && (
              <StackLayout
                horizontalAlignment="center"
                css={{ marginTop: '$twoAndHalf' }}
              >
                <Button
                  priority="secondary"
                  disabled={isFetchingNextPage}
                  onClick={() => {
                    fetchNextPage();
                  }}
                >
                  {formatMessage({ id: 'LOAD_MORE' }).toLocaleUpperCase()}
                </Button>
              </StackLayout>
            )}
          </Box>
        )}
        {showHealthLiteracyOnAllRecordedDataScreen ? (
          <>
            <HealthPrograms metric={dataType} />
            <HealthLiteracyContent metric={dataType} />
          </>
        ) : null}
      </StackLayout>
    </HealthProfilePageWrapper>
  );
};
