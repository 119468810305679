import { MetricType, MetricUnit } from '@leagueplatform/dashboard-api';
import { useIntl } from '@leagueplatform/locales';
import { useDataTypeConfigMap } from 'constants/use-data-type-config';

export function useLocaleUnitFormatter() {
  const { $tmap } = useIntl();

  const metricConfig = useDataTypeConfigMap();

  const unitMap = $tmap<MetricUnit>({
    count: 'COUNT',
    s: 'S',
    m: 'M',
    min: 'MIN',
    cal: 'CAL',
    kcal: 'KCAL',
    bmp: 'BMP',
    km: 'KM',
    'mg/dl': 'MG/DL',
  });

  const formatUnit = (type: MetricType, unit: MetricUnit, value = 0) =>
    type === 'steps' || type === 'floors_climbed'
      ? metricConfig.unit(type, { count: value })
      : unitMap(unit);

  return { formatUnit };
}
