import { isProduction } from '@leagueplatform/app-environment';
import Cookies from 'js-cookie';

export interface LegacyAuthStore {
  get: (name: string) => string | undefined;
  set: (name: string, value: string) => void;
  remove: (name: string) => void;
}

// Legacy auth apps are only deployed on *.league.com or *.league.dev
function getCookieOptions() {
  if (isProduction()) {
    return {
      domain: '.league.com',
    };
  }

  return {
    domain: '.league.dev',
  };
}

// Cookie implementation of AuthCookieStore
export const LegacyAuthCookieStore: LegacyAuthStore = {
  get(name: string) {
    return Cookies.get(name);
  },

  set(name: string, value: string) {
    return Cookies.set(name, value, getCookieOptions());
  },

  remove(name: string) {
    return Cookies.remove(name, getCookieOptions());
  },
};
