import * as React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { MedicalCoverage } from '../medical-coverage/medical-coverage.component';
import { PlanProgressSwitcher } from '../plan-progress/plan-progress-switcher.component';

export const PlanProgressAndCoverage = () => (
  <StackLayout spacing="$twoAndHalf" css={{ alignItems: 'stretch' }}>
    {/* TODO: Confirm loading and error states */}
    <PlanProgressSwitcher />
    <MedicalCoverage />
  </StackLayout>
);
