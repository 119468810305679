import * as React from 'react';
import { useTheme } from '@leagueplatform/genesis-commons';
import { XYChart, Tooltip, AxisBottom, AxisLeft, Bar, Grid } from '../xychart';
import { BarGraph as BarGraphType } from './types/bar-graph';
import { useGetLeftAxisLabelOffset } from './utils/use-calculate-left-axis-label-offset';
import { useGetRoundedYMax } from './utils/get-rounded-y-max';
import { useMonthlyTicks } from '../utils/use-monthly-ticks';

const NUM_X_TICKS = 2;

type MonthlyBarGraphProps<Datum> = BarGraphType<Datum>;

export function MonthlyBarGraph<Datum>({
  data,
  accessors,
  title,
  description,
  axisLeftLabel,
  axisBottomLabel,
  ariaDatumLabel,
  renderTooltip,
  yMaxFromEnums,
}: MonthlyBarGraphProps<Datum>) {
  const theme = useTheme();
  const yMax = useGetRoundedYMax(data, accessors, 100, yMaxFromEnums);
  const labelOffset = useGetLeftAxisLabelOffset(yMax);

  const margin = {
    top: theme.space.three,
    bottom: theme.space.five,
    left: theme.space.three + labelOffset,
    right: theme.space.four,
  };

  const [formatBottomTick, xAxisTicks] = useMonthlyTicks(
    data.map(accessors.xAccessor),
  );

  return (
    <XYChart
      title={title}
      desc={description}
      data={data}
      accessors={accessors}
      margin={margin}
      xScale={{ type: 'band', paddingInner: 0.3, paddingOuter: 0.15 }}
      yScale={{
        type: 'linear',
        domain: [0, yMax],
      }}
    >
      <Grid
        rowTickValues={[0, yMax / NUM_X_TICKS, yMax]}
        columnTickValues={xAxisTicks}
        centerColumns
      />
      <AxisLeft
        label={axisLeftLabel}
        labelOffset={labelOffset}
        tickValues={[0, yMax / NUM_X_TICKS, yMax]}
      />
      <AxisBottom
        label={axisBottomLabel}
        tickValues={xAxisTicks}
        tickFormat={formatBottomTick}
      />
      {data.map((datum, i) => {
        const isLastBar = data.length - 1 === i;

        return (
          <Bar
            key={`bar-${accessors.xAccessor(datum)}:${accessors.yAccessor(
              datum,
            )}`}
            datum={datum}
            accessors={accessors}
            highlight={isLastBar}
            aria-label={ariaDatumLabel(datum)}
            radius={theme.radii.small}
          />
        );
      })}
      <Tooltip<Datum> snapToDatum>{renderTooltip}</Tooltip>
    </XYChart>
  );
}
