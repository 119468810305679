import * as React from 'react';
import {
  BodyTwo,
  Flex,
  Link,
  PrimaryButton,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { Avatar } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  CareTeamParticipant,
  CareTeamContactPoint,
} from '@leagueplatform/care-team-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { MESSAGING_LINKS } from '@leagueplatform/messaging';
import { CARE_DISCOVERY_ASSET_KEYS } from '../../types/care-discovery.types';

interface CareTeamCardProps {
  teamInfo: CareTeamParticipant[];
}

export const CareTeamCard = ({ teamInfo }: CareTeamCardProps) => {
  const { formatMessage } = useIntl();

  // TODO: CCC-301 check on role regarding which participant to pick from the array.
  const { member } = teamInfo[0];
  const phoneNumber = member.contactPoint.find(
    (contact: CareTeamContactPoint) => contact.system === 'phone',
  );
  const extensionNumber = member.contactPoint.find(
    (contact: CareTeamContactPoint) => contact.system === 'extension',
  );

  const careAdvisorPlaceholderImage = handleStaticAsset(
    CARE_DISCOVERY_ASSET_KEYS.CARE_ADVISOR_IMAGE,
  ) as string;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      backgroundColor="surface.card.primary"
      borderRadius="extraLarge"
      paddingY="two"
      paddingX="one"
      textAlign="center"
    >
      <Avatar
        imageSrc={member.photo ?? careAdvisorPlaceholderImage}
        userInitials=""
        marginBottom="half"
      />
      <SubtitleOne marginBottom="one">{member.name}</SubtitleOne>
      <BodyTwo marginBottom="oneAndHalf">{member.bio}</BodyTwo>
      <PrimaryButton
        as={Link}
        href={MESSAGING_LINKS.getStartNewMessage()}
        width="100%"
      >
        {formatMessage({ id: 'SEND_NEW_MESSAGE' })}
      </PrimaryButton>
      {phoneNumber?.value && (
        <BodyTwo marginTop="one">
          {formatMessage(
            { id: 'OR_CALL_PHONE' },
            {
              phoneNumber: phoneNumber?.value,
              extension: extensionNumber?.value
                ? `(ext. ${extensionNumber.value})`
                : '',
            },
          )}
        </BodyTwo>
      )}
    </Flex>
  );
};
