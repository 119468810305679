import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '../primitives.view';
import { noop } from 'lodash';
import { getColourObject } from '../../utils/helpers';

const Header = styled(Flex)`
  background-color: ${({ theme, backgroundColour }) =>
    backgroundColour || getColourObject(theme).offWhite};
`;

const HeaderContents = styled(Box)`
  flex-grow: 1;
`;

const CloseButton = styled(Box)`
  font-size: 40px;
  transition: opacity 250ms ease-out;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const ModalHeader = ({
  children,
  closeButton,
  onClose,
  className,
  ...props
}) => (
  <Header
    p={2}
    pl={3}
    pr={3}
    flexDirection="row"
    className={className}
    {...props}
  >
    <HeaderContents>{children}</HeaderContents>
    {closeButton && <CloseButton onClick={onClose}>&times;</CloseButton>}
  </Header>
);

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  closeButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ModalHeader.defaultProps = {
  closeButton: false,
  onClose: noop,
  className: null,
};
