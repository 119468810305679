import * as React from 'react';
import {
  Box,
  Button,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import { CONFIRMATION_MODAL_MIN_HEIGHT } from 'pages/challenge-landing-page/constants/challenge-landing-page.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { MODAL_CTA_MAX_WIDTH } from 'constants/challenges.constants';

const { QUIT_SIGN_UP } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { EXIT } = SIGN_UP_ANALYTICS_DETAILS;

const CLOSE_CONFIRMATION_MODAL_LABEL_ID = 'closeConfirmationModalLabel';

interface SignUpExitProps {
  onClose: () => void;
}
export const SignUpExit = ({ onClose }: SignUpExitProps) => {
  const { formatMessage } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleClickAnalytics = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: QUIT_SIGN_UP,
      detail,
    });
  const mindfulMan = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_MINDFUL_MAN,
  ) as string;

  return (
    <Box
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: CONFIRMATION_MODAL_MIN_HEIGHT,
      }}
    >
      <ModalContentWithImage
        image={{
          src: mindfulMan,
          alt: '',
        }}
      >
        <HeadingText
          level="3"
          size="lg"
          id={CLOSE_CONFIRMATION_MODAL_LABEL_ID}
          css={{ marginBottom: '$threeQuarters' }}
        >
          {formatMessage({ id: 'EXIT_CHALLENGE_SIGN_UP_CONFIRM_TITLE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({
            id: 'EXIT_CHALLENGE_SIGN_UP_CONFIRM_DESCRIPTION',
          })}
        </ParagraphText>
        <Box css={{ marginTop: '$four', flexWrap: 'wrap' }}>
          <Button
            onClick={() => {
              handleClickAnalytics(EXIT);
              onClose();
            }}
            width="fillContainer"
            css={{
              '@laptop': { maxWidth: MODAL_CTA_MAX_WIDTH },
              '@mobile': { maxWidth: 'none' },
            }}
          >
            {formatMessage({ id: 'EXIT' })}
          </Button>
        </Box>
      </ModalContentWithImage>
    </Box>
  );
};
