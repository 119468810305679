import React from 'react';
import {
  StackLayout,
  StackItem,
  HeadingText,
  ParagraphText,
  useClickableCard,
  Image,
  styled,
  GDSHeadingLevel,
  Icon,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  getIconToken,
  Indicator,
  getLabelByIndicator,
} from 'utils/icon-mapping';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from '../../types/masonry-driver-node-actions';

export type MasonryExtraSmallCardNodeProperties = {
  heading: string;
  headingLevel?: GDSHeadingLevel;
  image?: string;
  body?: string;
  imageAltText?: string;
  indicator?: Indicator;
  indicatorLabel?: string;
};

export type MasonryExtraSmallCardNodeActions = {
  onClick?: MasonryDriverAction;
};

export type MasonryExtraSmallCardNode = MasonryEngineNode<
  'extraSmallCard',
  MasonryExtraSmallCardNodeProperties,
  MasonryExtraSmallCardNodeActions
>;

export type MasonryExtraSmallCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryExtraSmallCardNode>;

const StyledImage = styled(Image, {
  contentFit: 'cover',
  contentPosition: 'center',
  width: '42px',
  height: '42px',
});

export const MasonryExtraSmallCardRenderer = ({
  heading,
  headingLevel = '3',
  image,
  body,
  imageAltText,
  indicator,
  indicatorLabel,
  onClick,
}: MasonryExtraSmallCardRendererProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean((onClick as MasonryDeeplinkAction)?.payload.url)}
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
      }}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing={image ? '$threeQuarters' : undefined}
        css={{
          padding: '$oneAndHalf',
        }}
      >
        {image && (
          <StackItem verticalAlignment="center">
            <StyledImage src={image} alt={imageAltText} />
          </StackItem>
        )}
        <StackLayout css={{ marginTop: '$quarter' }} verticalAlignment="center">
          <HeadingText level={headingLevel} size="sm">
            <ConditionalLinkRenderer
              ref={primaryActionRef}
              disableDefaultOnClick
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
              url={(onClick as MasonryDeeplinkAction)?.payload.url}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>
          {body && (
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {body}
            </ParagraphText>
          )}
        </StackLayout>
        {onClick && indicator && (
          <StackItem verticalAlignment="center">
            <Icon
              icon={getIconToken(indicator)}
              label={indicatorLabel || getLabelByIndicator(indicator)}
            />
          </StackItem>
        )}
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
