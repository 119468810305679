import React from 'react';
import { formatPhoneNumber } from '@leagueplatform/web-common';
import { StackLayout } from '@leagueplatform/web-common-components';
import {
  Button,
  TextAction,
  ParagraphText,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { isPhoneNumberAction } from 'utils/action-links.util';
import { useActionLink } from 'hooks/use-action-link.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletPrimaryActionQuery } from '../../hooks/queries/use-wallet-primary-action-query.hook';

export const PrimaryActionLink = () => {
  const { userId, benefitId } = useWalletParams();
  const { data: primaryAction } = useWalletPrimaryActionQuery(
    userId,
    benefitId,
  );

  const { LinkComponent, handleActionClick } = useActionLink(primaryAction);

  if (!primaryAction) {
    return null;
  }

  const phoneNumber = formatPhoneNumber(primaryAction.url?.replace(/\D/g, ''));
  const isPhoneActionLink = isPhoneNumberAction(primaryAction);
  const { icon_url: icon, nav_icon: secondaryIcon } = primaryAction;

  if (!isPhoneActionLink) {
    return (
      // We are ignoring TS here because genesis-core button makes children required,
      // however for this component children is added in the LinkComponent + action
      // @ts-ignore-next-line
      <Button
        priority="primary"
        css={{
          textAlign: 'center',
          display: 'block',
          margin: '0 auto',
          maxWidth: '280px',
          width: '100%',
          color: '$primaryTextDefault',
          '&:hover, &:focus': {
            textDecoration: 'none',
          },
          '&&': {
            justifyContent: 'center',
            textAlign: 'center',
          },
          '> span': {
            flex: 'none',
          },
        }}
        action={primaryAction}
        as={LinkComponent}
        onClickCallback={handleActionClick}
        primaryIcon={icon}
        secondaryIcon={secondaryIcon}
      />
    );
  }

  return (
    <StackLayout
      css={{
        border: 'solid',
        borderColor: '$onSurfaceBorderSubdued',
        padding: '$one',
        borderRadius: '$medium',
        borderWidth: '$thin',
        backgroundColor: '$surfaceBackgroundPrimary',
      }}
    >
      {/* Currently b2b & PBLs are handling this content differently
         PBLs will use title and b2b will use name for the heading. */}
      <HeadingText size="lg" level="3">
        {primaryAction?.title || primaryAction.name}
      </HeadingText>
      {primaryAction?.description && (
        <ParagraphText>{primaryAction.description}</ParagraphText>
      )}
      <TextAction onClick={handleActionClick} href={primaryAction.url}>
        {/* Currently b2b & PBLs are handling this content differently
              In the PBL a name will contain the phone number. */}
        {primaryAction?.title ? primaryAction.name : phoneNumber}
      </TextAction>
    </StackLayout>
  );
};
