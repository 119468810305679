import { useEffect, useState, useCallback } from 'react';

import {
  UseLottieAnimationReturnType,
  LottieAnimationProps,
  LottieAnimationItem,
} from '../types/lottie-animation.types';
import { useLottie } from './use-lottie';

/**
 * This hook creates and exposes a lottie animation instance via a ref object when provided with a lottieConfig object.
 * @param lottieConfig Lottie lottieConfig: [documentation](https://github.com/airbnb/lottie-web/wiki/loadAnimation-options)
 * @param animationRef  A React ref object that can be used to control the animation instance
 * @returns an object containing the `animation` instance and the `animationContainerRef` to indicate the element that should load the animation
 */
export const useLottieAnimation = ({
  lottieConfig,
  animationRef,
  animationData: animationDataPromise,
}: LottieAnimationProps): UseLottieAnimationReturnType => {
  const lottie = useLottie();
  const [containerRef, setContainerRef] = useState(null);
  const [animation, setAnimation] = useState<LottieAnimationItem | null>(null);
  const animationContainerRef = useCallback(setContainerRef, [setContainerRef]);

  const shouldLoadAnimation =
    lottie && // Lottie has been loaded
    !animation && // There is no existing animation instance
    containerRef && // There is a valid container to load the animation in to
    lottieConfig && // There is a valid config with which to load the animation
    animationDataPromise; // There is promisified animation data to load

  const loadAnimation = useCallback(
    (animationData) => {
      if (shouldLoadAnimation) {
        // We expect animationData to be a dynamic import promise for a JSON module.
        // Use the default export since lottie expects the object to be mutable.
        if (!animationData.default) {
          throw new Error(
            'animationData is not a dynamic import promise of a JSON module.',
          );
        }
        const animationInstance = lottie.loadAnimation({
          animationData: animationData.default,
          container: containerRef,
          ...lottieConfig,
        });

        setAnimation(animationInstance);
      }
    },
    [containerRef, lottie, lottieConfig, shouldLoadAnimation],
  );

  // If the animation has not yet loaded and all the necessary properties are available, load the animation
  useEffect(() => {
    if (shouldLoadAnimation) {
      animationDataPromise.then(loadAnimation);
    }
  }, [animationDataPromise, shouldLoadAnimation, loadAnimation]);

  useEffect(() => {
    if (animation && animationRef) {
      // Expose the animation item instance via the provided ref
      // eslint-disable-next-line no-param-reassign
      animationRef.current = animation;
    }

    return () => {
      if (animation) {
        animation.destroy();
      }
    };
  }, [animation, animationRef]);

  return { animation, animationContainerRef };
};
