import * as React from 'react';
import { HeadingText } from '@leagueplatform/genesis-core';
import { Box } from '@leagueplatform/genesis-commons';
import { StackLayout } from '@leagueplatform/web-common-components';
import { CardLink } from 'components/card-link/card-link.component';
import { GridContainer } from 'components/grid-layout/grid-layout.component';
import { WalletItemNavigationSection } from '@leagueplatform/wallet-api';
import { getNavItemProps } from '../../util/get-nav-item-props.util';
import { sendNavigationAnalyticEvent } from '../../util/send-navigation-analytic-event.util';

interface NavigationGridProps {
  navigationSections: WalletItemNavigationSection;
  userRole?: string;
  userId?: string;
}

const GRID_COLUMN_COUNT = 3;
const MIN_GRID_ITEM_WIDTH = '250px';

export const NavigationGrid = ({
  navigationSections,
  userRole,
  userId,
}: NavigationGridProps) => (
  <StackLayout>
    <HeadingText size="md" level="3">
      {navigationSections.name.text} ({navigationSections?.wallet_items?.length}
      )
    </HeadingText>
    <GridContainer
      gridColumnCount={GRID_COLUMN_COUNT}
      minGridItemWidth={MIN_GRID_ITEM_WIDTH}
      as="ul"
    >
      {navigationSections.wallet_items?.map((walletItem) => {
        const { url, title, subtitle, iconUrl } = getNavItemProps(
          walletItem,
          userRole,
          userId,
        );
        return (
          <Box as="li" key={url}>
            <CardLink
              title={title}
              subtitle={subtitle}
              icon={iconUrl}
              to={url}
              onClick={() =>
                sendNavigationAnalyticEvent(
                  walletItem.name.text,
                  walletItem.id,
                  walletItem.vendor_id,
                  walletItem.type,
                  walletItem.type,
                  walletItem.benefit_source,
                )
              }
            />
          </Box>
        );
      })}
    </GridContainer>
  </StackLayout>
);
