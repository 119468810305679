import React from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingTwo,
  BodyOne,
  Link,
} from '@leagueplatform/genesis-commons';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import { CopyLink } from 'components/copy-link/copy-link.component';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import highFive from 'assets/highFive.png';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  TLP_SCREEN_NAMES,
} from 'constants/analytics.constants';

export interface CreateTeamSuccessProps {
  teamPhoto?: string;
  teamId: string;
}

const MODAL_CONTENT_MARGIN_TOP = {
  _: 'none',
  tablet: 'five',
} as const;

export const CreateTeamSuccess = ({
  teamPhoto,
  teamId,
}: CreateTeamSuccessProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const generateTLPUrl = () => {
    const baseUrl = window.location.origin;
    const teamDetailsUrlSegment = JOURNEY_ROUTES.getChallengeTeamDetails(
      challengeId,
      teamId,
    );
    return baseUrl + teamDetailsUrlSegment;
  };
  const handleAnalyticsClick = (detail: string) => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.CREATE_TEAM_SUCCESS,
      detail,
    });
  };
  return (
    <Box paddingTop={{ _: 'four', tablet: 'five' }}>
      <ModalContentWithImage
        image={{
          src: teamPhoto?.length ? teamPhoto : highFive,
          alt: '',
        }}
        marginTop={MODAL_CONTENT_MARGIN_TOP}
        isSuccessScreen
      >
        <HeadingTwo marginTop="oneAndHalf">
          {formatMessage({ id: 'CREATE_TEAM_SUCCESS' })}
        </HeadingTwo>
        <BodyOne marginTop="one" marginBottom="two">
          {formatMessage({ id: 'CREATE_TEAM_SUCCESS_DESCRIPTION' })}
        </BodyOne>
        <CopyLink
          joinTeamLink={generateTLPUrl()}
          onClick={() => handleAnalyticsClick(SIGN_UP_ANALYTICS_DETAILS.COPY)}
        />
        <Box width="100%" textAlign="center">
          <Link
            href={JOURNEY_ROUTES.getChallengeTeamDetails(challengeId, teamId)}
            marginTop="two"
            onClick={() =>
              handleAnalyticsClick(SIGN_UP_ANALYTICS_DETAILS.VIEW_TEAM_DETAILS)
            }
          >
            {formatMessage({ id: 'VIEW_TEAM_DETAILS' })}
          </Link>
        </Box>
      </ModalContentWithImage>
    </Box>
  );
};
