import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColourObject } from '../../utils/helpers';

const ModalTitle = styled.h2`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${({ theme }) => getColourObject(theme).purpleText};
`;

export const ModalHeaderTitle = ({ children }) => (
  <ModalTitle level={2}>{children}</ModalTitle>
);

ModalHeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
