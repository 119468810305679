import type { MasonryEngineMessagingController } from '../masonry-engine-messaging-controller';
import {
  MasonryEngineStateController,
  MasonryEngineStateControllerStore,
} from '../masonry-engine-state-controller';
import {
  MasonryEngineNodeAction,
  MasonryEngineNodeAncestry,
} from './masonry-engine-node.types';
import { NarrowByType } from './util.types';
/**
 * An object with the parameters expected by a handler of a given {@link MasonryEngineNodeAction `MasonryEngineNodeAction`}.
 */
export type MasonryEngineActionHandlerParams<
  Action extends MasonryEngineNodeAction = MasonryEngineNodeAction,
> = Action & {
  event?: React.SyntheticEvent<Element>;
  ancestry: MasonryEngineNodeAncestry;
  payload: Action['payload'];
};

/**
 * An subset of the {@link MasonryEngineActionHandlerParams `MasonryEngineActionHandlerParams`}
 * object excluding the `ancestry` property. Used in action emitter
 * utilites that automatically add that property based on context.
 */
export type MasonryEngineActionHandlerAnonymousParams<
  Action extends MasonryEngineNodeAction = MasonryEngineNodeAction,
> = Action & {
  event?: React.SyntheticEvent<Element>;
  payload: Action['payload'];
};

/**
 * A function that will be given a {@link MasonryEngineActionHandlerParams `MasonryEngineActionHandlerParams`}
 * that was just emitted, as well as the {@link MasonryEngineActionController `MasonryEngineActionController`}'s action emitter,
 * which can be used to chain actions.
 *
 * NOTE!! THE FOLLOWING PARAGRAPH IS NOT CORRECT! The inclusion of the action emitter as an argument
 * to the action handler DOES NOT allow for "modifying before handling". it's the emitter
 * we're giving you, not "the original handler before you overrode it"! That pattern,
 * of "going in the middle", is what the MIDDLEWARE pattern is for.... a pattern
 * which the team chose not to go with :(
 *
 * This allows an interception of an action, for blocking the driver from handling it, or for modifying it before handling,
 * or for logging purposes, etc.
 *
 */
export type MasonryEngineActionHandler<
  /**
   * The specific action meant to be handled by this handler.
   */
  ThisAction extends MasonryEngineNodeAction,
  /**
   * A union of other known actions assumed to be supported by the `MasonryEngineActionController`
   * in which this handler is registered.
   */
  KnownActions extends MasonryEngineNodeAction = MasonryEngineNodeAction,
  ReturnValue extends unknown = unknown,
> = (
  params: MasonryEngineActionHandlerParams<ThisAction>,
  /**
   * This is the same as the `emitAction` method of the `MasonryEngineActionController` itself, but
   * without the required `stateControllerStore` parameter, since it will have already
   * been made available before the chaining.
   */
  actionEmitter: <Type extends string>(
    params: MasonryEngineActionHandlerParams<
      | KnownActions
      | (MasonryEngineNodeAction<Type> &
          (Type extends KnownActions['type']
            ? NarrowByType<KnownActions, Type>
            : {}))
    >,
  ) => Promise<unknown>,
  stateController: MasonryEngineStateController,
  messagingController: MasonryEngineMessagingController,
) => ReturnValue;

export type MasonryEngineActionHandlerMap<
  /**
   * A union of `MasonryEngineNodeAction`s. Ensures that all of them are represented
   * in the map, and that for each one, an appropriate handler is provided (e.g.
   * expecting the same payload).
   */
  Action extends MasonryEngineNodeAction,
> = {
  [type in Action['type']]: MasonryEngineActionHandler<
    NarrowByType<Action, type>,
    Action
  >;
};

/**
 * An enum with the event types expected by an event handler
 */
export enum MasonryEngineEvent {
  STARTED = 'started',
  ENDED = 'ended',
  ALL = 'all',
}

/**
 * A callback that will be called before a `MasonryEngineNodeAction` is fired,
 * after, or both.
 */
export type MasonryEngineEventListener<Action extends MasonryEngineNodeAction> =
  (
    params: MasonryEngineActionHandlerParams<Action>,
    resolvedValue: unknown,
  ) => void;

export type MasonryEngineActionController<
  /**
   * A union of known `MasonryEngineNodeAction` supported for this controller.
   */
  Action extends MasonryEngineNodeAction,
> = {
  /**
   * @method `registerHandler` use to register action handlers per namespace.
   */
  registerHandler: <Type extends string>(
    type: Type,
    namespace: Action['namespace'],
    handler: MasonryEngineActionHandler<
      Type extends Action['type']
        ? NarrowByType<Action, Type>
        : MasonryEngineNodeAction<Type, any>
    >,
  ) => void;

  /**
   * @method overrideHandler use by app developers to override single action handler for an App
   * To override default action for a driver, please use namespace "default"
   * Note: All action interceptor map are driver specific.
   * */
  overrideHandler: <Type extends string>(
    type: Type,
    namespace: Action['namespace'],
    handler: MasonryEngineActionHandler<
      Type extends Action['type']
        ? NarrowByType<Action, Type>
        : MasonryEngineNodeAction<Type, any>
    >,
  ) => void;
  emitAction: <Type extends string>(
    params: MasonryEngineActionHandlerParams<
      | Action
      | (MasonryEngineNodeAction<Type> &
          (Type extends Action['type'] ? NarrowByType<Action, Type> : {}))
    >,
    /**
     * Expects vanilla zustand store to be passed so that it can run independent of React.
     */
    stateControllerStore: MasonryEngineStateControllerStore,
    messagingController: MasonryEngineMessagingController,
  ) => Promise<unknown>;
  addEventListener: <Type extends string, Event extends MasonryEngineEvent>(
    actionType: Type,
    namespace: string,
    eventType: Event,
    listener: MasonryEngineEventListener<
      Type extends Action['type']
        ? NarrowByType<Action, Type>
        : MasonryEngineNodeAction<Type, any>
    >,
  ) => () => void;
  getRegisteredEventListeners: () => Record<string, number>;
};
