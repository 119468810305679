export const updateUserSelections = ({
  userSelections,
  questionId,
  answer = {},
  error = {
    hasError: false,
    errorMsg: '',
  },
}) => ({
  ...userSelections,
  [questionId]: {
    ...userSelections[questionId],
    ...answer,
    error,
  },
});
