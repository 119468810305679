import { useQuery, UseQueryOptions } from 'react-query';
import { GET_GROUP_MESSAGE_TYPE, getGroup } from '@leagueplatform/wallet-api';
import type { UserGroup } from '@leagueplatform/user-profile-api';

export function useGroupQuery<TSelect = UserGroup>(
  groupId?: string,
  options?: UseQueryOptions<UserGroup, unknown, TSelect>,
) {
  return useQuery<UserGroup, unknown, TSelect>(
    [GET_GROUP_MESSAGE_TYPE, groupId],
    // This function will not be enabled if groupId does not exist.
    // getGroup also throws an error if no groupId is passed.
    // @ts-ignore
    () => getGroup(groupId),
    {
      enabled: !!groupId,
      ...options,
    },
  );
}
