import { MasonryDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import { InsightHighlightCardNodeRenderer } from './node-renderers/insight-card-node-renderer';
import { HorizontalBarGraphNodeRenderer } from './node-renderers/horizontal-bar-graph-insight-node-renderer';
import { VerticalBarGraphInsightNodeRenderer } from './node-renderers/vertical-bar-graph-insight-node-renderer';

const NODE_TYPE_HORIZONTAL_BAR_GRAPH = 'horizontalBarChart';
const NODE_TYPE_VERTICAL_BAR_GRAPH = 'verticalBarChart';
const NODE_TYPE_INSIGHT_CARD = 'insightCard';

export function initMasonryRegistry() {
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    'default',
    NODE_TYPE_HORIZONTAL_BAR_GRAPH,
    HorizontalBarGraphNodeRenderer,
  );
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    'default',
    NODE_TYPE_VERTICAL_BAR_GRAPH,
    VerticalBarGraphInsightNodeRenderer,
  );
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    'default',
    NODE_TYPE_INSIGHT_CARD,
    InsightHighlightCardNodeRenderer,
  );
}
