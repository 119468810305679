import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { genesisFocus } from '@leagueplatform/ui-kit';
import {
  BodyOneSecondary,
  CoverImage,
  SubtitleOne,
  BodyOne,
  Flex,
  Link,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { onClickBannerActions } from '../../utils/on-click-banner-actions';

const StyledFlexLink = genesisStyled(Flex)`
  &:hover, &:focus {
    text-decoration: none;
    .pseudo-link {
      text-decoration: underline;
    }
  }
`;

export const ProgramLimitReached = ({ setShowProgramLimitModel }) => {
  // Static Assets
  const maxProgramEnrolmentImage = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.MAX_PROGRAM_ENROLMENT_IMAGE,
  );

  // Hooks
  const { formatMessage } = useIntl();

  return (
    <StyledFlexLink
      flexDirection={{ _: 'column', phone: 'row' }}
      as={Link}
      href="#"
      padding="oneAndHalf"
      marginBottom="three"
      backgroundColor="surface.background.secondary"
      alignItems="center"
      onClick={(e) => {
        e.preventDefault();
        onClickBannerActions(setShowProgramLimitModel);
      }}
      focusStyle={genesisFocus}
      cursor="pointer"
    >
      <CoverImage
        src={maxProgramEnrolmentImage}
        alt=""
        size={47}
        marginRight="oneAndHalf"
      />
      <Flex flexDirection="column">
        <SubtitleOne as="h2" marginBottom="quarter">
          {formatMessage({ id: 'LOOKS_LIKE_REACHED_PROGRAM_LIMIT' })}
        </SubtitleOne>
        <BodyOneSecondary>
          {formatMessage(
            {
              id: 'COMPLETE_ACTIVE_PROGRAM_BEFORE_NEW_ONE',
            },
            {
              ctaText: formatMessage({ id: 'LEARN_MORE' }),
              // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
              strong: (msg) => (
                <BodyOne
                  as="strong"
                  color="interactive.action.primary"
                  fontWeight="bold"
                >
                  {msg}
                </BodyOne>
              ),
            },
          )}
        </BodyOneSecondary>
      </Flex>
    </StyledFlexLink>
  );
};

ProgramLimitReached.propTypes = {
  setShowProgramLimitModel: PropTypes.func.isRequired,
};
