export const supportedMetrics = [
  'distance',
  'steps',
  'active_duration',
  // , 'sleep_duration'
  'mindful_duration',
  'floors_climbed',
  // , 'idle_hours_count'
  'energy_burned',
  // , 'calcium'
  // , 'min_heart_rate'
  // , 'max_heart_rate'
  // , 'workout_duration'
  // , 'stand_hours_count'
  // , 'cholesterol'
  // , 'carbohydrate'
  // , 'dietary_fiber'
  // , 'avg_heart_rate'
  // , 'energy_consumed'
  // , 'awake_duration'
  // , 'blood_glucose'
  // , 'bmi'
  // , 'body_fat'
  // , 'body_height'
  // , 'body_temperature'
  // , 'body_weight';
  // , 'diastolic'
  // , 'fat'
  // , 'in_bed_duration'
  // , 'insulin'
  // , 'protein'
  // , 'pulse'
  // , 'saturated_fat'
  // , 'sodium'
  // , 'spo2'
  // , 'systolic'
  // , 'water'
  // , 'sugars'
  // , 'unsaturated_fat';
] as const;
