import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_WALLET_MESSAGE_TYPE = 'get_wallet';

export const getWallet = async (userId?: string) => {
  const info = {
    user_id: userId,
    suppress_card_actions_and_templates: true,
    options: {
      context: 'web',
    },
  };

  const params = {
    message_type: GET_WALLET_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
