import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_GROUP_DASHBOARDS = 'get_group_dashboards';

interface GroupSchema {
  groupId: string;
}

export const getGroupDashboards = ({ groupId }: GroupSchema) =>
  SocketAsFetch.fetch({
    message_type: GET_GROUP_DASHBOARDS,
    info: {
      group_id: groupId,
    },
  });
