import camelcaseKeys from 'camelcase-keys';
import { useQuery } from 'react-query';
import {
  getUserSettings,
  GET_USER_SETTINGS_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import {
  GET_HEALTH_NEED_MESSAGE,
  getHealthNeed,
} from '@leagueplatform/care-collections-api';
import { normalizeHealthNeed } from '../utils/normalize-health-need';

export const useHealthNeed = (urlSlug: string) => {
  const {
    isSuccess: isUserSettingsSuccess,
    isLoading: isUserSettingsLoading,
    isError: isUserSettingsError,
    data: userSettingsData,
  } = useQuery([GET_USER_SETTINGS_MESSAGE_TYPE], () => getUserSettings(), {
    select: (data) => ({ lifemarketUrl: data.marketplace_url }),
  });

  const {
    isSuccess: isHealthNeedSuccess,
    isLoading: isHealthNeedLoading,
    isError: isHealthNeedError,
    data: healthNeedData,
  } = useQuery(
    [GET_HEALTH_NEED_MESSAGE, urlSlug],
    () => getHealthNeed(urlSlug),
    {
      select: (data) =>
        normalizeHealthNeed(camelcaseKeys(data, { deep: true })),
    },
  );

  return {
    isSuccess: isUserSettingsSuccess && isHealthNeedSuccess,
    isLoading: isUserSettingsLoading || isHealthNeedLoading,
    isError: isUserSettingsError || isHealthNeedError,
    data: {
      ...userSettingsData,
      ...healthNeedData,
    },
  };
};
