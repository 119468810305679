import React, { FC, Children, cloneElement, isValidElement } from 'react';
import { Fieldset, GDSFieldsetProps } from '@leagueplatform/genesis-core';
import { useInput } from '../hooks/use-input.hook';
import {
  ControlledInputCommonProps,
  PrunedGDSFieldProps,
} from '../types/react-hook-form-input.type';

interface ControlledFieldsetProps
  extends ControlledInputCommonProps,
    PrunedGDSFieldProps<GDSFieldsetProps> {}

export const ControlledFieldset: FC<ControlledFieldsetProps> = ({
  name,
  inputOptions = {},
  optionalStatus,
  children,
  ...props
}) => {
  const { field, inputValidationState } = useInput(
    name,
    inputOptions,
    optionalStatus,
  );
  const required = Boolean(inputOptions?.required);
  const { inputStatus, statusMessage } = inputValidationState;

  return (
    <Fieldset
      inputStatus={inputStatus}
      statusMessage={statusMessage}
      required={required}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child as React.ReactElement<any>, {
            ...field,
            inputStatus,
          }),
      )}
    </Fieldset>
  );
};
