import React, { useRef } from 'react';
import { ConditionalLinkCard } from '@leagueplatform/web-common-components';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { LargeImageCard } from 'components/common/large-image-card/large-image-card.component';
import { mapHeadingSizeToGenesisTokens } from 'utils/map-heading-size-to-genesis-tokens.util';
import { ProgressBarCardContent } from './progress-bar-card-content.component';
import {
  ProgressBarCardWidgetType,
  ProgressBarCardChildWidgetType,
} from './progress-bar-card-widget.type';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export type ProgressBarCardWSNode = MasonryEngineNode<
  WidgetType.PROGRESS_BAR,
  ProgressBarCardWidgetType
>;

const ProgressBarCardWithoutImage = ({
  action,
  currentText,
  emphasizeCurrentValue,
  eyebrow,
  footerText,
  heading,
  headingLevel,
  headingSize,
  linkRefState,
  onCardClick,
  progress,
  suffixText,
}: ProgressBarCardChildWidgetType & {
  linkRefState?: React.RefObject<HTMLAnchorElement>;
}) => (
  <ProgressBarCardContent
    action={action}
    currentText={currentText}
    emphasizeCurrentValue={emphasizeCurrentValue}
    eyebrow={eyebrow}
    footerText={footerText}
    heading={heading}
    headingLevel={headingLevel}
    headingSize={headingSize}
    linkRefState={linkRefState}
    onCardClick={onCardClick}
    progress={progress}
    suffixText={suffixText}
  />
);

const ProgressBarCardWithImage = ({
  action,
  currentText,
  emphasizeCurrentValue,
  eyebrow,
  footerText,
  heading,
  headingLevel,
  headingSize,
  image,
  imageAltText = '',
  isChildItem = false,
  linkRefState,
  onCardClick,
  progress,
  suffixText,
}: ProgressBarCardChildWidgetType &
  Required<Pick<ProgressBarCardWidgetType, 'image'>> & {
    linkRefState?: React.RefObject<HTMLAnchorElement>;
  }) => (
  <LargeImageCard
    image={image}
    imageAltText={imageAltText}
    isChildItem={isChildItem}
  >
    <ProgressBarCardContent
      action={action}
      eyebrow={eyebrow}
      heading={heading}
      headingLevel={headingLevel}
      headingSize={headingSize}
      currentText={currentText}
      emphasizeCurrentValue={emphasizeCurrentValue}
      footerText={footerText}
      linkRefState={linkRefState}
      onCardClick={onCardClick}
      progress={progress}
      suffixText={suffixText}
    />
  </LargeImageCard>
);

export const ProgressBarCardWidget = ({
  traits,
  ...props
}: ProgressBarCardWidgetType) => {
  const { image, action } = props;
  const linkRefState = useRef<HTMLAnchorElement>(null);
  const traitItems = useGetTraitsForMedia(traits);
  const headingSize = mapHeadingSizeToGenesisTokens(traitItems?.headingSize);

  return (
    <ConditionalLinkCard linkRef={linkRefState} hasLink={Boolean(action)}>
      {image ? (
        <ProgressBarCardWithImage
          image={image}
          linkRefState={linkRefState}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...traitItems}
          headingSize={headingSize}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      ) : (
        <ProgressBarCardWithoutImage
          action={action}
          linkRefState={linkRefState}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...traitItems}
          headingSize={headingSize}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
    </ConditionalLinkCard>
  );
};
