import React, { Component } from 'react';
import {
  Button,
  HeadingText,
  Modal,
  Box,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { FormattedMessage } from 'react-intl';

interface SessionTimeoutActions {
  start(timeoutInterval: number): void;
}

interface SessionTimeoutProps {
  getIsAuthenticated: () => Promise<boolean>;
  onSessionExpire: () => void;
  onSessionExtend: () => void;
}

const SESSION_TIMEOUT_BUFFER = 150000;

export const sessionTimeoutActions: SessionTimeoutActions = {
  start: () => {},
};

const registerSessionTimeout = (ref: SessionTimeout) => {
  if (ref) sessionTimeoutActions.start = ref.startTimeout;
};

export class SessionTimeout extends Component<
  SessionTimeoutProps,
  { idleTimeout: any; showModal: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      idleTimeout: null,
      showModal: false,
    };
    registerSessionTimeout(this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  startTimeout = (timeoutInterval: number) => {
    const { getIsAuthenticated } = this.props;
    const { idleTimeout } = this.state;
    const timeoutBeforePrompt = timeoutInterval - SESSION_TIMEOUT_BUFFER;

    // Don't set timeout if passed in interval is less than buffer
    if (timeoutBeforePrompt > 0) {
      if (idleTimeout) this.stopTimeout();

      this.setState({
        idleTimeout: setTimeout(() => {
          getIsAuthenticated().then((isAuthenticated: boolean) => {
            if (isAuthenticated) this.setState({ showModal: true });
          });
        }, timeoutBeforePrompt),
      });
    }
  };

  stopTimeout = () => {
    const { idleTimeout } = this.state;
    clearTimeout(idleTimeout);
  };

  render() {
    const { onSessionExpire, onSessionExtend } = this.props;
    const { showModal } = this.state;
    return (
      <Modal.Root open={showModal}>
        <Modal.Content showCloseButton={false}>
          <Modal.Title>
            <HeadingText css={{ textAlign: 'center' }} level="1" size="lg">
              <FormattedMessage id="SESSION_TIMEOUT" />
            </HeadingText>
          </Modal.Title>
          <UtilityText
            css={{
              marginTop: '$oneAndHalf',
              marginBottom: '$oneAndHalf',
              display: 'block',
            }}
          >
            <FormattedMessage id="SESSION_ABOUT_TO_TIMEOUT" />
          </UtilityText>
          <Box css={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              priority="secondary"
              onClick={() => {
                onSessionExpire();
                this.setState({ showModal: false });
              }}
            >
              <FormattedMessage id="SESSION_LOG_OUT" />
            </Button>
            <Button
              css={{ marginLeft: '$one' }}
              onClick={() => {
                onSessionExtend();
                this.setState({ showModal: false });
              }}
            >
              <FormattedMessage id="CONTINUE_SESSION" />
            </Button>
          </Box>
        </Modal.Content>
      </Modal.Root>
    );
  }
}
