import * as React from 'react';
import {
  Card,
  HeadingText,
  Icon,
  StackLayout,
  UtilityText,
  useMediaQuery,
  queryHelpers,
  Button,
  Box,
} from '@leagueplatform/genesis-core';
import { Link, type LocationDescriptor } from '@leagueplatform/routing';
import { Badge } from '@leagueplatform/web-common-components';
import type { ComposableActivityCardProps } from 'components/composable-activity-card/composable-activity-card';

type CTAProps = {
  href: LocationDescriptor;
  text: string;
  component?: React.FC<ComposableActivityCTAProps>;
  onClick?: (event?: React.MouseEvent) => void;
  titleId?: string;
};

export type ComposableActivityCTAProps = Omit<CTAProps, 'component'>;

export type ComposableActivityCTACardProps = Omit<
  ComposableActivityCardProps,
  'cta'
> & {
  id: string;
  cta: Omit<CTAProps, 'titleId'>;
};

const LARGE_IMAGE_WIDTH = '130px';

const ComposableActivityCTACardButton = ({
  href,
  text,
  onClick,
  titleId,
}: ComposableActivityCTAProps) => (
  <Button
    aria-describedby={titleId}
    css={{ padding: '$half $two $half', marginTop: '$none' }}
    as={Link}
    to={href}
    onClick={onClick}
  >
    {text}
  </Button>
);

export function ComposableActivityCTACard({
  id,
  badge,
  body,
  cta,
  eyebrow,
  footer,
  headline,
  icon,
  image,
  title,
}: ComposableActivityCTACardProps) {
  const isBelowMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const withLargeImage = image?.shape === 'large';
  const withImage = image && image.shape !== 'large';
  const displayImageAtTop = icon || isBelowMobile;
  const titleId = `health-journey-activity_${id}`;

  return (
    <Card.Elevated
      css={{ width: '100%', backgroundColor: '$surfaceBackgroundPrimary' }}
    >
      <StackLayout horizontalAlignment="stretch">
        {withLargeImage && (
          <Card.Image
            image={image.image}
            imageAlt={image.altText}
            // the dom order changes on mobile so we need to hide this image initially and only show it on mobile
            css={{
              display: 'none',
              '@mobile': {
                display: 'block',
              },
            }}
          />
        )}
        {headline}
        <StackLayout
          orientation="horizontal"
          spacing="$half"
          css={{
            paddingRight: '$oneAndHalf',
          }}
          verticalAlignment={displayImageAtTop ? 'top' : 'center'}
        >
          <StackLayout
            css={{
              paddingTop: '$one',
              paddingLeft: '$oneAndHalf',
              paddingBottom: '$one',
            }}
            spacing="$threeQuarters"
          >
            {badge && (
              <Badge
                css={{
                  backgroundColor: badge.backgroundColor,
                  borderRadius: '$small',
                }}
              >
                <>
                  <Icon icon={badge.image} tint={badge.imageTint} size="$one" />
                  <UtilityText
                    size="eyebrow"
                    emphasis="emphasized"
                    css={{
                      paddingLeft: '$quarter',
                    }}
                  >
                    {badge.body}
                  </UtilityText>
                </>
              </Badge>
            )}
            {eyebrow}
            <StackLayout
              spacing="$threeQuarters"
              horizontalAlignment="stretch"
              css={{
                width: '100%',
                // If there is no body, add more padding to the title.
                paddingTop: !body ? '$half' : undefined,
                paddingBottom: !body ? '$half' : undefined,
              }}
            >
              <HeadingText
                id={titleId}
                size={title.size || 'sm'}
                level={title.level}
                emphasis={title.textEmphasis}
              >
                {title.text}
              </HeadingText>
              {body}
            </StackLayout>
            {footer}
          </StackLayout>
          {icon && (
            <Icon
              icon={icon.icon}
              tint={icon.tint}
              label={icon.label}
              css={{
                marginTop: '$one',
              }}
            />
          )}
          {withImage && (
            <Card.Image
              image={image.image}
              imageAlt={image.altText}
              css={{
                height: '$five',
                width: '148px',
                borderRadius: image.shape === 'circle' ? '$circle' : '$small',
                margin: '$one',
                marginRight: '$none',
              }}
            />
          )}
          {withLargeImage && (
            <Card.Image
              image={image.image}
              imageAlt={image.altText}
              // the dom order changes on mobile so we need to display this image initially and hide it on mobile
              css={{
                height: '$five',
                width: LARGE_IMAGE_WIDTH,
                borderRadius: '$small',
                margin: '$one $none',
                '@mobile': {
                  display: 'none',
                },
              }}
            />
          )}
        </StackLayout>
        <Box
          css={{
            width: 'fillContainer',
            padding: '$none $oneAndHalf $oneAndHalf',
          }}
        >
          {React.createElement(
            cta?.component || ComposableActivityCTACardButton,
            {
              text: cta.text,
              onClick: cta?.onClick,
              href: cta.href,
              titleId,
            },
          )}
        </Box>
      </StackLayout>
    </Card.Elevated>
  );
}
