import { useMutation, useQueryClient } from 'react-query';
import { captureError } from '@leagueplatform/observability';
import {
  patchDashboardConfig,
  Units,
  DashboardConfigDeserialisedData,
  DASHBOARD_CONFIG_PATH,
  healthMetricsV3Path,
  healthMetricsV2Path,
  healthMetricsV1Path,
} from '@leagueplatform/dashboard-api';
import { useGetDashboardConfig } from './use-get-dashboard-config.hook';

export function usePatchDashboardConfig() {
  const queryClient = useQueryClient();

  const { data: dashboardConfigData } = useGetDashboardConfig();
  return useMutation(
    (units: Units[]) => patchDashboardConfig(units, dashboardConfigData!),
    {
      onMutate(units) {
        const previousData = queryClient.getQueryData(
          DASHBOARD_CONFIG_PATH,
        ) as DashboardConfigDeserialisedData;
        const unitsFromTheCache = previousData.data.units.map(
          (unit) => units.find((u) => u.unitType === unit.unitType) ?? unit,
        );
        queryClient.setQueryData(DASHBOARD_CONFIG_PATH, {
          ...previousData,
          data: {
            ...previousData.data,
            units: unitsFromTheCache,
          },
        });
        return previousData;
      },
      onError(error, unitPreferences, previousUnitPreferences) {
        captureError(
          new Error(
            `Unable to change the dashboard unit preferences: ${error}`,
          ),
          {
            context: {
              preferences: {
                changed: JSON.stringify(unitPreferences),
                previous: JSON.stringify(previousUnitPreferences),
              },
            },
          },
        );
        queryClient.setQueryData(
          DASHBOARD_CONFIG_PATH,
          previousUnitPreferences,
        );
      },
      onSuccess() {
        queryClient.invalidateQueries({
          predicate: (query) => {
            const queryKey = query.queryKey[0];
            if (typeof queryKey !== 'string') {
              return false;
            }
            return [
              healthMetricsV3Path,
              healthMetricsV2Path,
              healthMetricsV1Path,
            ].includes(queryKey);
          },
        });
      },
    },
  );
}
