/* eslint-disable class-methods-use-this */
import type {
  EmbeddedAuthWithMobileHandoffInitConfig,
  EmbeddedAuthWithPartnerTokenInitConfig,
} from '@leagueplatform/auth-embedded';
import { EmbeddedAuth } from '@leagueplatform/auth-embedded';
import {
  StandaloneAuth,
  type StandaloneAuthInitConfig,
} from '@leagueplatform/auth-standalone';
import { attachCookiesWithAccessToken } from './util/attach-cookies-with-access-token.util';

export type AuthInitConfig =
  | StandaloneAuthInitConfig
  | EmbeddedAuthWithPartnerTokenInitConfig
  | EmbeddedAuthWithMobileHandoffInitConfig;

/**
 * Used to discern between standalone and embedded config shapes.
 */
const isStandaloneAuthInitConfig = (
  config: AuthInitConfig,
): config is StandaloneAuthInitConfig =>
  !!(config as StandaloneAuthInitConfig).clientOptions;

const notInitializedErrorMessage = 'Auth was not initialized';

export enum Implementation {
  STANDALONE = 'STANDALONE',
  EMBEDDED = 'EMBEDDED',
}

class AuthWrapper {
  #implementation: Implementation | undefined;

  get implementation(): Implementation {
    if (!this.#implementation) {
      throw new Error(notInitializedErrorMessage);
    }

    return this.#implementation;
  }

  get initialized() {
    return !!this.#implementation;
  }

  #contentUrl = '';

  // used to cache the current token so we can avoid unnecessary cookie exchanges.
  #currentToken = '';

  async #attachContentServerCookie(token: string) {
    if (token !== this.#currentToken) {
      this.#currentToken = token;
      await attachCookiesWithAccessToken(this.#contentUrl, token);
    }
  }

  getUserId(): Promise<string | undefined> {
    // Throws by default; will be replaced with a real implementation at initialization
    throw new Error(notInitializedErrorMessage);
  }

  getToken(): Promise<string> {
    // Throws by default; will be replaced with a real implementation at initialization
    throw new Error(notInitializedErrorMessage);
  }

  // given a token getter, wraps it in common behavior.
  #setTokenGetter(tokenGetter: () => Promise<string>) {
    this.getToken = async () => {
      const token = await tokenGetter();

      /**
       * Fire-and-forget a call to the cookie attacher, so that it can refresh
       * the cookie if the token has changed since the last time it was called.
       */
      this.#attachContentServerCookie(token);

      return token;
    };
  }

  // Resolves to `true` if a token can currently be gotten, and false otherwise.
  async isAuthorized() {
    try {
      await this.getToken();
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Initialize this module. Returns a promise which can be awaited, if desired,
   * for confirmation that initialization is complete, but it is not necessary
   * to do so before using any of the other methods of this module.
   */
  async initialize(config: AuthInitConfig, contentUrl: string) {
    this.#contentUrl = contentUrl;
    this.#currentToken = '';

    if (isStandaloneAuthInitConfig(config)) {
      // This is standalone auth.

      this.#implementation = Implementation.STANDALONE;
      StandaloneAuth.initialize(config);

      this.getUserId = () =>
        StandaloneAuth.client
          .getUser()
          .then((user) => user?.['https://el/user_id']);

      this.#setTokenGetter(() => StandaloneAuth.client.getTokenSilently());
    } else {
      // This is embedded auth.
      this.#implementation = Implementation.EMBEDDED;

      this.getUserId = () => EmbeddedAuth.getUserId();

      this.#setTokenGetter(async () => {
        try {
          const token = await EmbeddedAuth.getToken();

          if (typeof token !== 'string' || !token) {
            throw new Error('not a valid token');
          }

          return token;
        } catch (error) {
          throw new Error(
            `Embedded auth encountered an error when calling the provided getToken function! ${error}`,
          );
        }
      });

      // We check whether the host app is being embedded in a mobile webview, or else default to the "normal" embed use case
      const initialization =
        'getUserIdFromMobileHandoff' in config
          ? EmbeddedAuth.initializeForMobileHandoff(config)
          : EmbeddedAuth.initializeForPartnerToken(config);

      /**
       * We await the initialization of the `EmbeddedAuth` module last because
       * we don't need to wait for it to resolve before making `getUserId` and
       * `getToken` (above) callable.
       *
       * Finally, we also grab the token from its token getter and use it
       * to immediately attach a cookie. We do this in only in the embedded case because
       * by definition of this use case the token getter ought to provide a valid token immediately,
       * whereas in the standalone case the user may not yet be authenticated at all.
       *
       * Note that we Promise.all() against initializing the embedded module and getting
       * a token because, as the API of the embedded module stipulates, it is not necessary to await
       * the `initialize()` function before using `getToken()` - it is only necessary
       * to CALL it first.
       */
      await Promise.all([
        initialization,
        EmbeddedAuth.getToken().then((token) =>
          this.#attachContentServerCookie(token),
        ),
      ]);
    }
  }
}

export const Auth = new AuthWrapper();
