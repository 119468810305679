import React from 'react';
import {
  Flex,
  Box,
  SubtitleTwo,
  Caption,
  VisuallyHidden,
  BackgroundColorSystemProp,
} from '@leagueplatform/genesis-commons';

export enum STATUS_OPTIONS {
  SUCCESS = 'SUCCESS',
  HIGHLIGHT = 'HIGHLIGHT',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
  DISABLED = 'DISABLED',
}

/**
 * @typedef StatusBadgeProps
 * @prop {keyof typeof STATUS_OPTIONS} status - Status should map to a key STATUS_OPTION, which determines the colors rendered for the status badge
 * @prop {string} label - Describes the status the badge relates to; labels should be short and under 2 words (design best practice)
 * @prop {boolean} displayIcon - UI circle indicator on status badge
 * @prop {boolean} smallText - Use smaller text sizing using Caption; default text size uses SubtitleTwo
 * @prop {string} accessibleDescription - Provides additional context for accessibility
 */

export interface StatusBadgeProps {
  status: keyof typeof STATUS_OPTIONS;
  label: string;
  displayIcon?: boolean;
  smallText?: boolean;
  accessibleDescription?: string;
}

interface StatusBadgeColorOptions {
  backgroundColor: Extract<
    BackgroundColorSystemProp,
    | 'success.background.subdued'
    | 'highlight.background.subdued'
    | 'warning.background.subdued'
    | 'critical.background.subdued'
    | 'surface.card.disabled'
  >;
  iconColor: Extract<
    BackgroundColorSystemProp,
    | 'success.icon'
    | 'highlight.icon'
    | 'warning.icon'
    | 'critical.icon'
    | 'interactive.icon.disabled'
  >;
}

interface ColorScheme {
  [key: string]: StatusBadgeColorOptions;
}

const colorScheme: ColorScheme = {
  SUCCESS: {
    backgroundColor: 'success.background.subdued',
    iconColor: 'success.icon',
  },
  HIGHLIGHT: {
    backgroundColor: 'highlight.background.subdued',
    iconColor: 'highlight.icon',
  },
  WARNING: {
    backgroundColor: 'warning.background.subdued',
    iconColor: 'warning.icon',
  },
  CRITICAL: {
    backgroundColor: 'critical.background.subdued',
    iconColor: 'critical.icon',
  },
  DISABLED: {
    backgroundColor: 'surface.card.disabled',
    iconColor: 'interactive.icon.disabled',
  },
};

export const getStatusColors = (
  status: keyof typeof STATUS_OPTIONS,
): StatusBadgeColorOptions => colorScheme[status];

export const StatusBadge = ({
  status,
  label,
  displayIcon,
  smallText,
  accessibleDescription,
}: StatusBadgeProps): JSX.Element => {
  const { backgroundColor, iconColor } = getStatusColors(status);

  return (
    <Flex
      width="fit-content"
      alignItems="center"
      paddingX="half"
      paddingY="quarter"
      backgroundColor={backgroundColor}
      borderRadius="pill"
    >
      {displayIcon && (
        <Box
          height={10}
          width={10}
          minWidth={10}
          backgroundColor={iconColor}
          borderRadius="circle"
          marginRight="quarter"
          role="presentation"
        />
      )}
      {smallText ? (
        <Caption>
          <VisuallyHidden>{accessibleDescription}&nbsp;</VisuallyHidden>
          {label}
        </Caption>
      ) : (
        <SubtitleTwo as="p">
          <VisuallyHidden>{accessibleDescription}&nbsp;</VisuallyHidden>
          {label}
        </SubtitleTwo>
      )}
    </Flex>
  );
};
