import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { getStatusColor, getColourObject } from '../../utils/helpers';

const Badge = styled.span`
  position: absolute;
  top: 0;
  left: -14px;
  background: ${({ theme }) => getColourObject(theme).white};
  border: 1px solid ${({ outer }) => outer};
  width: 27px;
  height: 27px;
  border-radius: 999px;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    background: ${({ inner }) => inner};
    width: 9px;
    height: 9px;
    border-radius: 999px;
    top: 8px;
    left: 8px;
    box-sizing: border-box;
  }
`;

const StatusCircle = ({ status }) => {
  const { inner, outer } = getStatusColor(status);
  return <Badge inner={inner} outer={outer} />;
};

StatusCircle.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusCircle;
