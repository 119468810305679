export enum SIGN_UP_MODAL_PAGES {
  ACCEPT_PRIVACY_POLICY = 'ACCEPT_PRIVACY_POLICY',
  WEARABLES_CONNECTION = 'WEARABLES_CONNECTION',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  EXIT = 'EXIT',
  WEARABLES_MANUAL_TRACK_CONFIRMATION = 'WEARABLES_MANUAL_TRACK_CONFIRMATION',
}

// This allows us to access both the values and types of the USER_CONNECTED_DEVICE_CAMPAIGN_MODES object for trackingType, without forcing specific casing if using keyof typeof directly (USER_CONNECTED_DEVICE_CAMPAIGN_MODES keys are uppercase, but values are lowercase).
export type Values<T> = T[keyof T];
