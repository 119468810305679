import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ScrollDiv = styled.div`
  overflow-x: auto;
  height: ${(props) => props.height};
`;

class ScrollLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastScrollY: 0,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll(evt) {
    const { isLoading, isEndOfList, load, bufferHeight } = this.props;
    const { scrollTop, scrollHeight, offsetHeight } = evt.currentTarget;
    if (!isLoading && !isEndOfList) {
      /* If current scroll top is greater than last AND we've scrolled enough, via math, to trigger a new fetch */
      if (
        scrollTop > this.state.lastScrollY &&
        scrollHeight - offsetHeight - scrollTop <= bufferHeight
      ) {
        load();
        this.setState({
          lastScrollY: scrollTop,
        });
      }
    }
  }

  render() {
    const { height } = this.props;
    return (
      <ScrollDiv onScroll={(evt) => this.onScroll(evt)} height={height}>
        {this.props.children}
      </ScrollDiv>
    );
  }
}

ScrollLoader.propTypes = {
  /** The load function to call on scroll */
  load: PropTypes.func.isRequired,
  /** Flag to indicate all items have been loaded  */
  isEndOfList: PropTypes.bool.isRequired,
  /** Flag to indicate loading status */
  isLoading: PropTypes.bool.isRequired,
  /** Buffer height controls how much scrolling must take place to trigger loading */
  bufferHeight: PropTypes.number,
  /** A height is required to enable scrolling of interior content, can override the default of 100% */
  height: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ScrollLoader.defaultProps = {
  bufferHeight: 300,
  height: '100%',
};

export default ScrollLoader;
