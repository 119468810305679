import { leagueFetch } from '@leagueplatform/league-fetch';
import { NewThreadData, ThreadDetailsResponseData } from 'types/threads.types';
import {
  MESSAGING_THREADS_PATH,
  MESSAGING_THREADS_PATH_V2,
} from './constants/messaging-requests.constants';

export interface PostMessagingThreadProps {
  newThreadData: NewThreadData;
  isQuestionnaireDisabled: boolean | undefined;
}

const getThreadFormDataValue = (
  newThreadData: NewThreadData,
  isQuestionnaireDisabled: boolean | undefined,
) => {
  const threadFormData = new FormData();
  const { subject = '', participants, text, document, answers } = newThreadData;
  const formattedThreadData = isQuestionnaireDisabled
    ? JSON.stringify({
        data: {
          type: 'thread',
          attributes: {
            subject,
            ...(text && { messageText: text }),
          },
          relationships: {
            participants: {
              data: participants,
            },
          },
        },
      })
    : JSON.stringify({
        data: {
          type: 'thread',
          attributes: {
            ...(text && { messageText: text }),
            answers,
          },
        },
      });
  threadFormData.append('data', formattedThreadData);
  if (document && document.length) {
    document.forEach((file: File) => threadFormData.append('document', file));
  }
  return threadFormData;
};

export const postMessagingThread = async ({
  newThreadData,
  isQuestionnaireDisabled,
}: PostMessagingThreadProps): Promise<ThreadDetailsResponseData> => {
  const requestPath = isQuestionnaireDisabled
    ? MESSAGING_THREADS_PATH
    : MESSAGING_THREADS_PATH_V2;

  const threadFormData = getThreadFormDataValue(
    newThreadData,
    isQuestionnaireDisabled,
  );
  const response = await leagueFetch(requestPath, {
    method: 'POST',
    body: threadFormData,
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while posting a new thread!');
};
