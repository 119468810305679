import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { Suggestion } from '../types/suggestion';

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum PRODUCT_AREA {
  HEALTH_ASSESSMENTS = 'health assessments',
  PULSE_CHECK_OUTCOMES = 'pulse check outcomes',
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum SCREEN_NAME {
  HEALTH_PROFILE = 'health profile',
  ASSESSMENT_ONBOARDING_INTRO = 'assessment onboarding intro',
  ASSESSMENT_QUESTIONNAIRE = 'assessment questionnaire',
  ASSESSMENT_QUESTIONNAIRE_INTRO = 'assessment questionnaire intro',
  ASSESSMENT_QUESTIONNAIRE_CATEGORY_INTRO = 'assessment questionnaire category intro',
  ASSESSMENT_EXIT_CONFIRMATION = 'assessment exit confirmation',
  ASSESSMENT_COMPLETED = 'assessment completed',
  PULSE_CHECK_OUTCOMES = 'pulse check outcomes',
  PULSE_CHECK_OUTCOMES_FRICTION_SCREEN = 'pulse check outcomes friction screen',
  PULSE_CHECK_OUTCOMES_ERROR_SCREEN = 'pulse check outcomes error screen',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
enum RESOURCE_TYPE {
  HEALTH_CAMPAIGN_ACTIVITY = 'health campaign activity',
  HEALTH_CAMPAIGN = 'health campaign',
  HEALTH_CONTENT = 'health content',
}

export function getResourceTypeFromSuggestion(suggestion: Suggestion) {
  const { action_url: actionUrl } = suggestion;

  if (/health-journey/.test(actionUrl)) {
    return RESOURCE_TYPE.HEALTH_CAMPAIGN_ACTIVITY;
  }

  if (/health-programs/.test(actionUrl)) {
    return RESOURCE_TYPE.HEALTH_CAMPAIGN;
  }

  if (actionUrl && isAbsoluteUrl(actionUrl)) {
    return RESOURCE_TYPE.HEALTH_CONTENT;
  }

  return null;
}
