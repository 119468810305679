import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { ChallengeTypes } from '@leagueplatform/health-journey-api';
import { ChallengesHealthProgramsCardData } from 'types/health-programs-response-data.type';
import { AllChallengesList } from 'components/all-challenges-list.component';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';

interface ChallengeByTypeContentProps {
  challengeType: ChallengeTypes;
  programs: ChallengesHealthProgramsCardData[];
}
export const ChallengeByTypeContent = ({
  challengeType,
  programs,
}: ChallengeByTypeContentProps) => {
  const { $tmap } = useIntl();

  const title = $tmap({
    group: 'VIEW_ALL_GROUP_CHALLENGES_HEADING',
    solo: 'VIEW_ALL_SOLO_CHALLENGES_HEADING',
  })(challengeType);

  const description = $tmap({
    group: 'VIEW_ALL_CHALLENGES_DESCRIPTION_GROUP',
    solo: 'VIEW_ALL_CHALLENGES_DESCRIPTION_SOLO',
  })(challengeType);

  return (
    <>
      <HeadingText
        size="xxl"
        emphasis="base"
        level="2"
        css={{ paddingBottom: '$half' }}
      >
        {title}
      </HeadingText>
      <ParagraphText size="base" emphasis="base">
        {description}
      </ParagraphText>
      <AllChallengesList programs={programs} />
    </>
  );
};
