import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { POST_START_ASYNC_MESSAGING } from './constants/messaging-requests.constants';
import { PostStartAsyncMessagingData } from './types/messaging.types';
import { MessagingQuestionnaireResponse } from './types/threads.types';

export const postStartAsyncMessaging = async (
  answers: MessagingQuestionnaireResponse[],
): Promise<PostStartAsyncMessagingData> => {
  const response = await leagueFetch(POST_START_ASYNC_MESSAGING, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'async-message',
        attributes: {
          answers,
        },
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`postStartAsyncMessaging error: ${response.status}`);
  }
  return deserialise(response.json());
};
