export const CHALLENGES_UTM_SOURCE = 'Challenges';
export const CONTENT_MAX_WIDTH = '792px';
export const BUTTON_CONTAINER_MAX_WIDTH = '384px';
export const PROFILE_PICTURE_SIZE = '140px';
export const PROFILE_SELECT_CONTAINER_FIXED_HEIGHT = '388px';
export const LOAD_MORE_BUTTON_MAX_WIDTH = '327px';
export const LEADERBOARD_SPINNER_FIXED_HEIGHT = '200px';
export const PRIVACY_POLICY_MAX_WIDTH = '475px';
export const MODAL_CTA_MAX_WIDTH = '280px';
export const MODAL_IMAGE_HEIGHT = 200;

export const CREATE_TEAM_BUTTON_CONTAINER_MAX_WIDTH = {
  _: '240px',
  tablet: '184px',
};
export const CREATE_TEAM_BUTTON_CONTAINER_MARGIN_RIGHT = {
  _: 'auto',
  phone: 'none',
} as const;
export const CREATE_TEAM_MODAL_HEADING_FONT = {
  _: 'heading2',
  tablet: 'heading1',
} as const;

export const USER_CHALLENGE_RANKS_BASE_PAGE_SIZE = 10;

// For highlights, BE has page size minimum of 10 and maximum of 100
export const HIGHLIGHTS_BASE_PAGE_SIZE = 10;
export const HIGHLIGHTS_MAX_PAGE_SIZE = 100;
export const DEBOUNCE_DELAY_INTERVAL = 400;

// <BackButtonArrow /> has an onClick as a required prop
// we are using it as a link, so we are passing a no-op
// function to the onClick method.
export const noopFunction = () => {};

export enum CHALLENGE_STATUS {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  CLOSED = 'closed',
}
