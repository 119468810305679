import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import { Button, Modal, Box, HeadingText } from '@leagueplatform/genesis-core';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';
import { SignUpModalContent } from './components/sign-up-modal-content/sign-up-modal-content.component';
import { isBackButtonShowable } from './utils/is-back-button-showable.util';
import { SIGN_UP_MODAL_PAGES } from './constants/sign-up-modal.constants';

const SIGN_UP_MODAL_LABEL_ID = 'signUpModalLabel';
interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  userCanJoinTeam: boolean;
}

export const SignUpModal = ({
  isOpen,
  onClose,
  userCanJoinTeam,
}: SignUpModalProps) => {
  const { formatMessage } = useIntl();
  const modalRef = React.useRef<HTMLDivElement>(null);

  // Using an array to keep track of the steps the user has navigated through
  // this is important for the "back" functionality navigating back to the
  // appropriate last page as users can navigate the flow in different ways
  const [signUpFlowHistory, setSignUpFlowHistory] = useState<
    (keyof typeof SIGN_UP_MODAL_PAGES)[]
  >([SIGN_UP_MODAL_PAGES.ACCEPT_PRIVACY_POLICY]);

  const setNextModalNavigationStep = (next: keyof typeof SIGN_UP_MODAL_PAGES) =>
    setSignUpFlowHistory([...signUpFlowHistory, next]);

  // current step is the last item in the history array
  const currentStep = signUpFlowHistory[signUpFlowHistory.length - 1];
  const showBackButton = isBackButtonShowable(currentStep);

  React.useEffect(() => {
    if (modalRef) {
      modalRef?.current?.focus();
    }
  }, [currentStep]);

  const SignUpFlowExitPages = [
    SIGN_UP_MODAL_PAGES.EXIT,
    SIGN_UP_MODAL_PAGES.SIGN_UP_SUCCESS,
  ];

  const onBackClick = () => {
    setSignUpFlowHistory([...signUpFlowHistory.slice(0, -1)]);
  };

  return (
    <Modal.Root
      open={isOpen}
      onOpenChange={() => {
        if (SignUpFlowExitPages.includes(currentStep as SIGN_UP_MODAL_PAGES)) {
          onClose();
        }
        setNextModalNavigationStep(SIGN_UP_MODAL_PAGES.EXIT);
      }}
    >
      <Modal.Content
        width="100%"
        layout="fullscreen"
        css={{
          '.GDS-modal': {
            top: 0,
            left: 0,
            height: '100%',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
          },
          '.GDS-modal-content': {
            display: 'flex',
            alignItems: 'center',
            '@mobile': { alignItems: 'flex-start' },
            height: '100%',
            padding: '$twoAndHalf',
            overflow: 'auto',
          },
        }}
        ref={modalRef}
      >
        <Modal.Title>
          <VisuallyHidden>
            <HeadingText id={SIGN_UP_MODAL_LABEL_ID} level="2" size="xl">
              {formatMessage({ id: 'SIGN_UP_FOR_THE_CHALLENGE' })}
            </HeadingText>
          </VisuallyHidden>
        </Modal.Title>
        <Box as="section" aria-live="polite" css={{ width: '100%' }}>
          <SignUpModalContent
            currentStep={currentStep}
            setNextModalNavigationStep={setNextModalNavigationStep}
            reset={() =>
              setSignUpFlowHistory([SIGN_UP_MODAL_PAGES.ACCEPT_PRIVACY_POLICY])
            }
            onClose={() => {
              setSignUpFlowHistory([SIGN_UP_MODAL_PAGES.ACCEPT_PRIVACY_POLICY]); // always reset to the privacy policy on close
              onClose();
            }}
            userCanJoinTeam={userCanJoinTeam}
          />
          {showBackButton && (
            <Button
              data-testingid="backButton"
              onClick={() => onBackClick()}
              css={{
                top: '$threeQuarters',
                '@mobile': { top: '$one' },
                '@desktop': { top: 'calc(50% - 26px)' },
                padding: '$one',
                borderRadius: '$circle',
                backgroundColor: '$decorativeBrandPrimaryPastel',
                color: '$onSurfaceTextSubdued',
                position: 'absolute',
                '@tablet': { left: '10%' },
                left: '$oneAndHalf',
                '&:focus': {
                  backgroundColor: '$decorativeBrandPrimaryPastel',
                },
                '&:hover': {
                  backgroundColor: '$decorativeBrandPrimaryBrightest',
                },
                svg: {
                  display: 'block',
                },
              }}
            >
              <ChallengeIcon aria-hidden="true" iconType={ICON_TYPES.ARROW} />
              <VisuallyHidden>{formatMessage({ id: 'BACK' })}</VisuallyHidden>
            </Button>
          )}
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
};
