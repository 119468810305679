import React from 'react';
import { useFormContext, useInput } from '@leagueplatform/web-common';
import {
  Card,
  Fieldset,
  InputHint,
  Radio,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Controller } from 'react-hook-form';

export type RadioInputProps = {
  id: string;
  altText?: string;
  options: Array<{
    label: string;
    value: number;
  }>;
  validationMessage: string;
  defaultValue?: number;
  hint?: string;
};

export function RadioInput({
  id,
  options,
  altText,
  validationMessage,
  defaultValue,
  hint,
}: RadioInputProps) {
  const { watch } = useFormContext();
  const currentValue = watch(id);

  const { inputValidationState } = useInput(id);

  options.sort((a, b) => b.value - a.value);

  return (
    <StackLayout horizontalAlignment="stretch">
      <Controller
        name={id}
        defaultValue={defaultValue}
        rules={{
          required: {
            value: true,
            message: validationMessage,
          },
        }}
        render={({ field }) => (
          <Fieldset
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputValidationState}
            legend={altText}
            hideLegend
            id={id}
            css={{
              '.GDS-input-status-message-wrapper': {
                marginTop: '$one',
              },
            }}
          >
            <StackLayout spacing="$one" horizontalAlignment="stretch">
              {options.map(({ label, value }) => (
                <Card.Elevated
                  css={{
                    borderColor:
                      // eslint-disable-next-line eqeqeq
                      currentValue == value
                        ? '$interactiveActionPrimary'
                        : '$onSurfaceBorderSubdued',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderRadius: '$medium',
                  }}
                >
                  <Radio
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id={label}
                    name={id}
                    checked={value === field.value}
                    value={value}
                    label={label}
                    layout="row"
                    css={{
                      '.GDS-radio-label-hint': {
                        padding: '$one',
                        minHeight: '100px',
                      },
                    }}
                    onChange={() => {
                      field.onChange(value);
                    }}
                  />
                </Card.Elevated>
              ))}
              {hint && <InputHint>{hint}</InputHint>}
            </StackLayout>
          </Fieldset>
        )}
      />
    </StackLayout>
  );
}
