import {
  GET_USER_CAMPAIGNS_MESSAGE_TYPE,
  getUserHealthCampaignById,
  UserHealthCampaignByIdResponse,
} from '@leagueplatform/health-journey-api';
import { useQuery } from 'react-query';
import { captureError } from '@leagueplatform/observability';

export function useGetUserHealthCampaignById(
  userHealthCampaignId: string,
  includeUserHealthActivities?: boolean,
  includeUserHealthCampaign?: boolean,
) {
  return useQuery<UserHealthCampaignByIdResponse, Error>(
    [GET_USER_CAMPAIGNS_MESSAGE_TYPE, userHealthCampaignId],
    () =>
      getUserHealthCampaignById(
        userHealthCampaignId,
        includeUserHealthActivities,
        includeUserHealthCampaign,
      ),
    {
      staleTime: Infinity,
      onError: (error) => {
        captureError(
          new Error(
            `Failed to get reward program(id: ${userHealthCampaignId}): ${error}`,
          ),
        );
      },
    },
  );
}
