import React from 'react';
import {
  GDSIconProps,
  Icon,
  UtilityText,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';

interface ActivityCardFooterItemProps {
  iconProps?: GDSIconProps | null;
  children: React.ReactNode;
}

export const ActivityCardFooterItem = ({
  iconProps,
  children,
}: ActivityCardFooterItemProps) => (
  <StackItem>
    <StackLayout
      orientation="horizontal"
      spacing="$half"
      verticalAlignment="center"
      horizontalAlignment="start"
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {iconProps && <Icon {...iconProps} />}
      <UtilityText size="sm">{children}</UtilityText>
    </StackLayout>
  </StackItem>
);
