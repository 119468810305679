import React, { useEffect } from 'react';
import { Field, FieldInputProps } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import {
  Textarea,
  genesisStyled,
  Box,
  css,
} from '@leagueplatform/genesis-commons';

const TEXTAREA_MAX_HEIGHT = 150;

// We are using a class selector here to apply borderRadius styles due to a limitation
// with the textarea/text input base components. See this thread for more details:
// https://everlong.slack.com/archives/CQEQA8XUK/p1675718259316389
// In future, this should be replaced with a genesis-core textarea
const StyledTextareaWrapper = genesisStyled(Box)(() =>
  css({
    '& > .Genesis-TextInputBase-Container::before': {
      borderRadius: 'extraLarge',
    },
  }),
);

/*
  credit for this useAutosizeTextArea hook: https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848
*/
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;

      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = `${Math.min(
        scrollHeight + 2,
        TEXTAREA_MAX_HEIGHT,
      )}px`;
    }
  }, [textAreaRef, value]);
};

interface AutoResizeTextareaProps {
  id: string;
  textareaName: string;
  textValue: string;
  disabled: boolean;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  validateTextarea: (value: string) => string | undefined;
  ariaDescribedby?: string;
}

export const AutoResizeTextarea = ({
  id,
  textareaName,
  textValue,
  disabled,
  textAreaRef,
  validateTextarea,
  ariaDescribedby,
}: AutoResizeTextareaProps) => {
  const { formatMessage } = useIntl();
  useAutosizeTextArea(textAreaRef.current, textValue);

  return (
    <Field name={textareaName} validate={validateTextarea}>
      {({ field }: { field: FieldInputProps<string> }) => {
        const { name, ...fieldVals } = field;
        return (
          <StyledTextareaWrapper>
            <Textarea
              disabled={disabled}
              name={name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...fieldVals}
              placeholder={formatMessage({ id: 'WRITE_A_MESSAGE' })}
              id={id}
              rows={1}
              paddingX="half"
              paddingY="half"
              minHeight="twoAndHalf"
              backgroundColor="surface.background.secondary"
              borderRadius="extraLarge"
              borderColor="transparent"
              hoverStyle={{ borderColor: 'transparent' }}
              resize="none"
              ref={textAreaRef}
              autoFocus
              aria-describedby={ariaDescribedby}
            />
          </StyledTextareaWrapper>
        );
      }}
    </Field>
  );
};
