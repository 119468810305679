import React from 'react';
import { useClickableCard } from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { LargeImageCard } from '@leagueplatform/masonry-widgets';
import { MasonryProgressBarCardContentRenderer } from './masonry-progress-bar-card-content-renderer.component';
import { MasonryProgressBarCardRendererType } from './masonry-progress-bar-card-renderer.type';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from '../../types/masonry-driver-node-actions';

type MasonryProgressBarCardNodeProperties =
  MasonryProgressBarCardRendererType & {
    primaryActionRef?: React.RefObject<HTMLAnchorElement>;
  };

export type MasonryProgressBarCardNodeActions = {
  onClick?: MasonryDriverAction;
};

export type MasonryProgressBarCardNode = MasonryEngineNode<
  'progressBar',
  MasonryProgressBarCardNodeProperties,
  MasonryProgressBarCardNodeActions
>;

export type MasonryProgressBarCardCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryProgressBarCardNode>;

const MasonryProgressBarCardWithoutImage = ({
  eyebrow,
  heading,
  currentText,
  progress,
  suffixText,
  footerText,
  emphasizeCurrentValue,
  onClick,
  primaryActionRef,
  headingLevel,
}: MasonryProgressBarCardCardRendererProps & {
  primaryActionRef?: React.RefObject<HTMLAnchorElement>;
}) => (
  <MasonryProgressBarCardContentRenderer
    eyebrow={eyebrow}
    heading={heading}
    currentText={currentText}
    progress={progress}
    suffixText={suffixText}
    footerText={footerText}
    linkRefState={primaryActionRef}
    emphasizeCurrentValue={emphasizeCurrentValue}
    onClick={onClick}
    headingLevel={headingLevel}
  />
);

const MasonryProgressBarCardWithImage = ({
  eyebrow,
  heading,
  image,
  imageAltText = '',
  currentText,
  progress,
  suffixText,
  footerText,
  onClick,
  isChildItem = false,
  emphasizeCurrentValue,
  primaryActionRef,
  headingLevel,
}: MasonryProgressBarCardCardRendererProps &
  Required<Pick<MasonryProgressBarCardCardRendererProps, 'image'>> & {
    primaryActionRef?: React.RefObject<HTMLAnchorElement>;
  }) => (
  <LargeImageCard
    image={image}
    imageAltText={imageAltText}
    isChildItem={isChildItem}
  >
    <MasonryProgressBarCardContentRenderer
      eyebrow={eyebrow}
      heading={heading}
      currentText={currentText}
      progress={progress}
      suffixText={suffixText}
      footerText={footerText}
      linkRefState={primaryActionRef}
      emphasizeCurrentValue={emphasizeCurrentValue}
      onClick={onClick}
      headingLevel={headingLevel}
    />
  </LargeImageCard>
);

export const MasonryProgressBarCardRenderer = ({
  ...props
}: MasonryProgressBarCardCardRendererProps) => {
  const { image, onClick } = props;
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean((onClick as MasonryDeeplinkAction)?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      imagePosition={{
        '@initial': 'start',
        '@mobile': 'top',
      }}
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
      }}
    >
      {image ? (
        <MasonryProgressBarCardWithImage
          image={image}
          primaryActionRef={primaryActionRef}
          onClick={onClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      ) : (
        <MasonryProgressBarCardWithoutImage
          primaryActionRef={primaryActionRef}
          onClick={onClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
    </ConditionalLinkCardRenderer>
  );
};
