import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  TEAMS_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

interface CreateTeamArgs {
  challengeId: string;
  teamName: string;
  teamPhoto: string;
  errorContext?: ErrorContext;
}

export const postCreateTeam = async ({
  challengeId,
  teamName,
  teamPhoto,
  errorContext,
}: CreateTeamArgs) => {
  const response = await leagueFetch(TEAMS_PATH, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: CHALLENGE_DATA_TYPES.CHALLENGE_TEAM,
        attributes: {
          name: teamName,
          avatarUrl: teamPhoto,
        },
        relationships: {
          challenge: {
            data: {
              id: challengeId,
              type: CHALLENGE_DATA_TYPES.CHALLENGE,
            },
          },
        },
      },
    }),
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while posting!');
};
