import React from 'react';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import { getObservabilityContext } from '@leagueplatform/masonry-api';
import { captureMessage } from '@leagueplatform/observability';
import { getProviderFromWidgetType } from 'utils/custom-module-utils';
import { useGetConfig } from 'hooks/use-get-config.hook';
import { useGetCustomWidgetModuleFromConfig } from 'hooks/use-get-custom-widget-module-from-config.hook';
import { DefaultWidget } from 'components/default-widget/default-widget';
import { WidgetTypeToComplexComponentMap } from 'constants/widget-type-to-complex-component-map';
import { logWarning } from 'utils/log-error';
import { WidgetDataForDefaultWidgets } from '../../types/default-widget-data.types';
import { WidgetWithCustomModuleProvider } from './widget-with-custom-module-provider.component';

type WidgetProviderFromWidgetTypeProps = {
  widget: ContainerWidgetItem;
  data: WidgetDataForDefaultWidgets;
};

export const WidgetProviderFromWidgetType = ({
  data,
  widget,
}: WidgetProviderFromWidgetTypeProps): React.ReactElement | null => {
  const config = useGetConfig();
  const { type: widgetType } = data;
  const { module: moduleKey } = widget;
  const customWidgetModule = useGetCustomWidgetModuleFromConfig({
    moduleKey,
  });

  const WidgetComponent = WidgetTypeToComplexComponentMap[widgetType];
  if (!WidgetComponent) {
    logWarning(
      `Unsupported widget type - %c${widgetType}%c found in container.`,
    );
    captureMessage(
      `Unsupported widget type - ${widgetType} found in container.`,
      getObservabilityContext({
        tags: {
          container: config.appId,
        },
      }),
    );
    return null;
  }

  if (widget.module !== 'default') {
    const widgetProviderHook = getProviderFromWidgetType(
      data,
      customWidgetModule,
      widget.module,
      config.appId,
    );
    if (widgetProviderHook) {
      return (
        <WidgetWithCustomModuleProvider
          moduleProviderHook={widgetProviderHook}
          data={data}
          widget={widget}
          component={WidgetComponent}
          config={config}
        />
      );
    }

    logWarning(
      `No custom widget provider found for widget type - %c${widgetType}%c and moduleKey - %c${widget.module}%c. Rendering widget with masonry data.`,
    );
    captureMessage(
      `No custom widget provider found for widget type: ${widgetType}`,
      getObservabilityContext({
        tags: {
          container: config.appId,
          widgetType,
          moduleKey: widget.module,
        },
      }),
    );
  }

  // Return null of there are no attributes for a default widget
  if (!data.attributes) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <DefaultWidget component={WidgetComponent} data={data} config={config} />
  );
};
