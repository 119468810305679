export const CHALLENGES_PATH = 'v2/challenges';
export const USER_CHALLENGE_PATH = 'v1/user-challenges';
export const CHALLENGE_PRIVACY_PATH = 'v1/challenge-config';
export const CHALLENGE_HIGHLIGHTS_PATH = 'v1/challenge-feed-items';
export const USER_CHALLENGES_RANKINGS_PATH =
  'v2/user-challenges-rankings?include=user';
export const AVATARS_PATH = 'v1/avatars?filter[type]=';
export enum CHALLENGE_HIGHLIGHT_FEED_TYPES {
  CHALLENGE = 'challenge',
  TEAM = 'team',
}
export const getHighlightFeedByIdPath = (
  feedType: CHALLENGE_HIGHLIGHT_FEED_TYPES,
  challengeId: string,
) => {
  const baseHighlightsPath = `${CHALLENGE_HIGHLIGHTS_PATH}?include=challenge,challenge-feed-item-reaction&filter`;

  if (feedType === CHALLENGE_HIGHLIGHT_FEED_TYPES.TEAM) {
    return `${baseHighlightsPath}[${CHALLENGE_HIGHLIGHT_FEED_TYPES.CHALLENGE}]=${challengeId}&filter[${CHALLENGE_HIGHLIGHT_FEED_TYPES.TEAM}]=`;
  }
  return `${baseHighlightsPath}[${feedType}]=`;
};
export const HIGHLIGHT_REACTIONS_PATH = 'v1/challenge-feed-item-reactions';
export const TEAMS_PATH = 'v1/challenge-teams';
export const TEAM_CHALLENGE_RANKS_PATH =
  'v2/team-challenges-rankings?filter[challenge]=';

export enum CHALLENGE_TYPES {
  USER = 'user',
  TEAM = 'team',
}
export enum CHALLENGE_DATA_TYPES {
  CHALLENGE = 'challenge',
  USER = 'user',
  USER_CHALLENGE = 'user-challenge',
  USER_CHALLENGE_RANK = 'user-challenge-rank',
  CHALLENGE_TEAM = 'challenge-team',
  CHALLENGE_TEAM_RANK = 'team-challenge-rank',
  CHALLENGE_CONFIG = 'challenge-config',
  CHALLENGE_FEED_ITEM = 'challenge-feed-item',
  CHALLENGE_FEED_ITEM_REACTION = 'challenge-feed-item-reaction',
}
export enum CHALLENGE_HIGHLIGHT_IMAGE_TYPES {
  USER = 'user-profile',
  TEAM = 'team-profile',
  HIGHLIGHT = 'header-image',
}
export enum CHALLENGE_HIGHLIGHT_REACTION_TYPES {
  LIKE = 'like',
  UNLIKE = 'unlike',
  VIEW_LIKES = 'view likes',
}
export enum CHALLENGE_PATCH_OPERATIONS {
  ADD = 'add',
  REMOVE = 'remove',
}
export enum CHALLENGE_HIGHLIGHT_EVENT_TYPES {
  CHALLENGE = 'challenge',
  TEAM = 'team',
  ACHIEVEMENT = 'achievement',
  STEPS = 'step',
  MINUTES = 'minute',
  COMPLETED_ACTIVITY = 'activityCompleted',
  MINDFUL_MINUTE = 'mindfulMinute',
}
export enum LEADERBOARD_RANKING_UNIT {
  STEPS = 'steps',
  MINUTES = 'minutes',
  ACTIVITIES = 'activities',
  TOKENS = 'tokens',
}
