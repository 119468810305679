import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { isDev } from '@leagueplatform/app-environment';

export const GET_GROUP_DASHBOARD_URL = 'get_group_dashboard_url';

interface BillingSchemaParameters {
  billing_division_name: string;
  year: string;
  month: string;
}

interface BillingSchema {
  groupId: string;
  dashboardId: string;
  useDevEmbedDomain: boolean;
  parameters: Array<BillingSchemaParameters>;
}

export const getGroupDashboardUrl = ({
  groupId,
  dashboardId,
  parameters,
}: BillingSchema) =>
  SocketAsFetch.fetch({
    message_type: GET_GROUP_DASHBOARD_URL,
    info: {
      group_id: groupId,
      dashboard_id: dashboardId,
      use_dev_embed_domain: isDev(),
      parameters,
    },
  });
