import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_ASSESSMENT_OUTCOME_MESSAGE_TYPE = 'get_assessment_outcome';

export const getAssessmentOutcome = (id: string) =>
  SocketAsFetch.fetch({
    message_type: GET_ASSESSMENT_OUTCOME_MESSAGE_TYPE,
    info: {
      id,
    },
  });
