/* typing this allows for autocompletion in the editor when using
the typography util in a style */
export type GDSTypographyToken =
  | '$headingOne'
  | '$headingTwo'
  | '$headingThree'
  | '$headingFour'
  | '$subtitleOne'
  | '$subtitleTwo'
  | '$bodyOne'
  | '$bodyOneSecondary'
  | '$bodyTwo'
  | '$bodyTwoSecondary'
  | '$buttonOne'
  | '$buttonTwo'
  | '$label'
  | '$overline'
  | '$caption'
  | '$tab';

export const typography = (token: GDSTypographyToken) => ({
  fontSize: token,
  fontWeight: token,
  fontFamily: token,
  lineHeight: token,
  letterSpacing: token,
  textTransform: token,
  textDecoration: token,
});
