import * as React from 'react';
import { Circle as VisxCircle } from '@visx/shape';
import { useTheme } from '@leagueplatform/genesis-core';

export type CircleProps = React.ComponentProps<typeof VisxCircle>;

/**
 * Wraps the visx Circle component and applies genesis styles.
 */
export function Circle(props: CircleProps) {
  const theme = useTheme();

  return (
    <VisxCircle
      tabIndex={0}
      fill={theme.colors.decorativeBrandPrimaryDark}
      stroke={theme.colors.surfaceBackgroundTertiary}
      strokeWidth={4}
      r={10}
      style={{
        // Adds radius to the outline when focused.
        borderRadius: 10,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
