import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const DISMISS_USER_HEALTH_ACTIVITY_MESSAGE_TYPE =
  'dismiss_user_health_activity';

export const dismissUserHealthActivity = (userActivityId: string) =>
  SocketAsFetch.fetch({
    message_type: DISMISS_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
    info: {
      user_health_activity_id: userActivityId,
    },
  });
