import { HealthActivityDeserializedData } from '@leagueplatform/health-journey-api';
import { UserHealthActivityStatus } from '@leagueplatform/health-journey-common';
import { useMemo } from 'react';

export function useSortActivitiesByStatus(
  activities: HealthActivityDeserializedData[],
) {
  const sortedActivities = useMemo(() => {
    const activeActivities: HealthActivityDeserializedData[] = [];
    const missedActivities: HealthActivityDeserializedData[] = [];
    const completedActivities: HealthActivityDeserializedData[] = [];

    activities.forEach((activity) => {
      switch (activity.status) {
        case UserHealthActivityStatus.ACTIVE:
        case UserHealthActivityStatus.UPCOMING:
          activeActivities.push(activity);
          break;
        case UserHealthActivityStatus.EXPIRED:
        case UserHealthActivityStatus.REMOVED:
          missedActivities.push(activity);
          break;
        case UserHealthActivityStatus.COMPLETED:
          completedActivities.push(activity);
          break;
        default:
      }
    });

    return { activeActivities, missedActivities, completedActivities };
  }, [activities]);

  return sortedActivities;
}
