import * as React from 'react';
import {
  css,
  genesisStyled,
  HeadingTwo,
  Flex,
  Image,
  HeadingFour,
  BodyOne,
  QuietButton,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { HtmlToReact } from '@leagueplatform/web-common';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { useGetHealthLiteracy } from 'hooks/use-get-health-literacy.hook';
import { HealthLiteracyDeserialisedData } from '@leagueplatform/dashboard-api';
import { Box } from '@leagueplatform/genesis-core';
import { HealthLiteracyModal } from './health-literacy-modal';

type HealthLiteracyProps = {
  metric: string;
};

const LiteracyCard = genesisStyled(Flex)(
  css({
    '&:active, &:focus': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      '&:before': { borderRadius: 'large' },
    },
    '&:not(:focus):not(:active):hover': {
      borderColor: 'onSurface.border.subdued',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
);

const StyledImage = genesisStyled(Image)(
  css({
    borderBottomLeftRadius: 'none',
    borderBottomRightRadius: 'none',
  }),
);
const HealthLiteracyHtmlToReactOptions = {
  h4: {
    component: HeadingFour,
    props: { marginBottom: 'one', marginTop: 'twoAndHalf' },
  },
  p: {
    component: BodyOne,
    props: {
      marginBottom: 'one',
    },
  },
  h2: {
    component: HeadingThree,
    props: { marginBottom: 'one', marginTop: 'twoAndHalf' },
  },
  h3: {
    component: HeadingThree,
    props: { marginBottom: 'one', marginTop: 'twoAndHalf' },
  },
};

// only display health literacy card if we have all the necessary data (card title, description, image, and health literacy description)
const displayHealthLiteracyContent = (
  healthLiteracyData: HealthLiteracyDeserialisedData,
) => {
  const { cardDescription, cardImage, cardTitle, healthLiteracyDescription } =
    healthLiteracyData.data;
  if (cardTitle && cardDescription && cardImage && healthLiteracyDescription) {
    return true;
  }
  return false;
};

export const HealthLiteracyContent = ({ metric }: HealthLiteracyProps) => {
  const [showHealthLiteracyModal, setShowHealthLiteracyModal] =
    React.useState(false);

  const {
    isLoading,
    isError,
    data: healthLiteracyData,
  } = useGetHealthLiteracy(metric);

  if (isLoading) {
    return null;
  }

  if (isError || !healthLiteracyData?.data) {
    // If there's an error don't show anything
    return null;
  }

  const {
    cardDescription,
    cardImage,
    cardTitle,
    description,
    healthLiteracyDescription,
    title,
  } = healthLiteracyData.data;
  return (
    <Box css={{ width: '100%' }}>
      <HeadingTwo marginBottom="one">{title}</HeadingTwo>
      <HtmlToReact htmlString={description} />
      {displayHealthLiteracyContent(healthLiteracyData) ? (
        <>
          <LiteracyCard
            as={QuietButton}
            marginTop="oneAndHalf"
            marginBottom="five"
            borderRadius="medium"
            border="solid"
            borderWidth="thin"
            borderColor="onSurface.border.subdued"
            boxShadow="card"
            flexDirection="column"
            justifyContent="center"
            maxWidth="388px"
            onClick={() => {
              setShowHealthLiteracyModal(true);
              trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                product_area: PRODUCT_AREA.DASHBOARD,
                screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
                metric_type: metric,
                detail: 'click datatype literacy content',
              });
            }}
          >
            <StyledImage
              src={cardImage}
              width="100%"
              maxWidth="100%"
              role="presentation"
              borderRadius="medium"
            />
            <Flex textAlign="left" flexDirection="column" padding="oneAndHalf">
              <HeadingFour marginBottom="one">{cardTitle}</HeadingFour>
              <HtmlToReact htmlString={cardDescription} />
            </Flex>
          </LiteracyCard>

          {showHealthLiteracyModal ? (
            <HealthLiteracyModal
              ariaLabelledBy="about-metric"
              onClose={() => setShowHealthLiteracyModal(false)}
              metric={metric}
            >
              <HeadingTwo
                marginBottom="two"
                id="about-metric"
                fontSize="subtitle1"
                textAlign="center"
              >
                {title}
              </HeadingTwo>
              <HeadingThree marginBottom="one">{cardTitle}</HeadingThree>
              <HtmlToReact
                options={HealthLiteracyHtmlToReactOptions}
                htmlString={healthLiteracyDescription}
              />
            </HealthLiteracyModal>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};
