import { vars } from '../vars';

export function leagueFont(
  options = { medium: false, bold: false, italic: false },
) {
  let fontWeight;
  if (options.medium) {
    fontWeight = 500;
  } else if (options.bold) {
    fontWeight = 700;
  } else {
    fontWeight = 300;
  }

  return `
      font-family: ${vars.text.fontFamily};
      font-weight: ${fontWeight};
      font-style: ${options.italic ? 'italic' : 'normal'};
  `;
}

// Save rewriting these two common styles
export const fontSizeLineHeight = (
  fontSize = 14,
  lineHeight = 20,
  unit = 'px',
) => `
  font-size: ${fontSize + unit};
  line-height: ${lineHeight + unit};
`;
