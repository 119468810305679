export type Shadows =
  | 'card'
  | 'dropdown'
  | 'focusRing'
  | 'focusOutline'
  | 'dangerRing';

export const getFocusShadows = (colors) => ({
  focusOutline: `0 0 0 2px ${colors.interactive.focus.outer}`,
  focusRing: `0 0 0 2px ${colors.surface.background.primary}, 0 0 0 4px ${colors.interactive.focus.outer}`,
  dangerRing: `0 0 0 2px white, 0 0 0 4px ${colors.critical.border.default}`,
});
