import React, {
  HTMLAttributes,
  useRef,
  forwardRef,
  useMemo,
  useEffect,
} from 'react';
import videojs, {
  Player,
  PlayerOptions as VideoJsPlayerOptions,
} from 'video.js';
import { Box, GDSCustomizableComponent } from '@leagueplatform/genesis-core';
import { GDSVideoFrameStyles } from './styles';
import './setup';
import 'video.js/dist/video-js.min.css';

// Types
export namespace GDSVideo {
  export type PlayerOptions = VideoJsPlayerOptions &
    Exclude<HTMLAttributes<HTMLVideoElement>, 'children'>;
  export type OnReady = (playerInstance: Player) => unknown;
  export type PlayerRef = Player | null | undefined;
}

export interface GDSVideoProps
  extends GDSVideo.PlayerOptions,
    GDSCustomizableComponent {
  onReady?: GDSVideo.OnReady;
}

// Constants
const GDS_VIDEO_STATIC_ERROR = `Unknown error with the video player, please reload the page.`;
export const GDSDefaultVideoJSOptions: GDSVideo.PlayerOptions = {
  autoplay: false,
  bigPlayButton: true,
  controlBar: {
    volumePanel: { inline: false },
    progressControl: { seekBar: true },
  },
  controls: true,
  disablePictureInPicture: false,
  // @ts-expect-error – known property
  experimentalSvgIcons: true,
  fluid: true,
  notSupportedMessage: GDS_VIDEO_STATIC_ERROR,
  playbackRates: [0.25, 0.5, 1, 1.5, 2],
  responsive: true,
  retryOnError: true,
};

export const Video = forwardRef<GDSVideo.PlayerRef, GDSVideoProps>(
  (props, ref) => {
    const { onReady, className, css, ...videoJsOptions } = props;

    /* REFS */
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef(typeof ref !== 'function' ? ref?.current : null);

    /* COMPUTED PROPERTIES */
    const aggregateOptions: GDSVideo.PlayerOptions = useMemo(() => {
      const { src, sources = [], ...rawOptions } = videoJsOptions ?? {};
      const normalizedSources = src ? [...sources, { src }] : sources;

      return {
        ...GDSDefaultVideoJSOptions,
        ...rawOptions,
        sources: normalizedSources,
      };
    }, [videoJsOptions]);

    /* SIDE EFFECTS */
    // Make sure Video.js player is only initialized once
    useEffect(() => {
      const { current: player } = playerRef;

      if (!player) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement('video');
        videoElement.classList.add('video-js', 'GDS-video');
        videoRef.current?.appendChild?.(videoElement);

        playerRef.current = videojs(
          videoElement,
          aggregateOptions,
          function onVideoReady() {
            videojs.log('player is ready');
            onReady?.(this);
          },
        );

        // Else update an existing player
      } else {
        const { autoplay, sources, src, aspectRatio } = aggregateOptions;
        player?.autoplay(autoplay);
        player?.src(src || sources);
        player?.aspectRatio(aspectRatio || undefined);
      }
    }, [aggregateOptions, onReady, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const { current: player } = playerRef;
      return () => {
        if (player && !player?.isDisposed()) {
          player?.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (
      /* Video Layout */
      <Box
        data-vjs-player
        className={[`GDS-video-layout`, className].join(' ')}
        css={{ ...GDSVideoFrameStyles, ...css }}
      >
        {/* Video Container */}
        <Box ref={videoRef} className="GDS-video-container" />
      </Box>
    );
  },
);

Video.displayName = 'Video';
export default Video;
