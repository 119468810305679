import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import { AvatarDeserialisedData } from 'challenges/types/avatars-data.type';
import {
  AVATARS_PATH,
  CHALLENGE_TYPES,
} from './constants/challenge-requests.constants';

export const getAvatars = async (
  avatarType: CHALLENGE_TYPES,
  errorContext?: ErrorContext,
): Promise<AvatarDeserialisedData> => {
  const response = await leagueFetch(`${AVATARS_PATH}${avatarType}`, {
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (!response.ok) {
    throw new Error(`getAvatars error: ${response.status}`);
  }
  const responseJson = await response.json();
  return deserialise(responseJson);
};
