import React, { forwardRef } from 'react';
import type {
  GDSCustomizableComponent,
  GDSHorizontalAlignmentValues,
  GDSVerticalAlignmentValues,
} from '../../../types';
import { styled } from '../../../theme';

type GDSBaseWidth = 'hugContents' | (string & {});

export interface GDSStackItemProps extends GDSCustomizableComponent {
  horizontalAlignment?: 'fillContainer' | GDSHorizontalAlignmentValues;
  verticalAlignment?: 'fillContainer' | GDSVerticalAlignmentValues;
  baseWidth?: GDSBaseWidth;
  growFactor?: number;
  shrinkFactor?: number;
  itemOrder?: number;
  children: React.ReactNode;
}

const BaseStackItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    horizontalAlignment: {
      fillContainer: {
        alignItems: 'stretch',
      },
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
    verticalAlignment: {
      fillContainer: {
        justifyContent: 'stretch',
        '& > *': {
          flexGrow: 1,
        },
      },
      top: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      bottom: {
        justifyContent: 'flex-end',
      },
    },
  },
});

const setFlexBasis = (baseWidth: GDSBaseWidth) =>
  baseWidth === 'hugContents' ? 'fit-content' : baseWidth;

export const StackItem = forwardRef<HTMLDivElement, GDSStackItemProps>(
  (
    {
      horizontalAlignment = 'fillContainer',
      verticalAlignment = 'fillContainer',
      growFactor,
      shrinkFactor,
      baseWidth,
      itemOrder,
      children,
      className,
      css,
      ...props
    },
    ref,
  ) => (
    <BaseStackItem
      horizontalAlignment={horizontalAlignment}
      verticalAlignment={verticalAlignment}
      ref={ref}
      className={['GDS-stack-item', className].join(' ')}
      css={{
        ...css,
        ...{ flexGrow: growFactor, flexShrink: shrinkFactor },
        ...(baseWidth && { flexBasis: setFlexBasis(baseWidth) }),
        ...(itemOrder && { order: itemOrder }),
      }}
      {...props}
    >
      {children}
    </BaseStackItem>
  ),
);
