import { BaseRoute } from '@leagueplatform/routing';
import { withSentryRouting } from '@sentry/react';
import { isNodeTestEnv } from '@leagueplatform/app-environment';

/**
 * A version of the base `Route` component that is enhanced to
 * integrate with Observability services.
 *
 * Note that we refrain from calling the Sentry HOC if we're
 * in a unit test context, since too many other modules in the SDK
 * currently mock the `@leagueplatform/routing` module destructively.
 */
export const RouteWithObservability = !isNodeTestEnv()
  ? withSentryRouting(BaseRoute)
  : BaseRoute;
