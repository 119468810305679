import * as React from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import {
  HeadingText,
  ParagraphText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  Image,
  Link as _Link,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  NAVIGATION_TYPE,
  WalletItemNavigationSectionGroup,
} from '@leagueplatform/wallet-api';
import { NavigationItem } from './components/navigation-item/navigation-item.component';
import { StatusFilterTabs } from './components/status-filter-tabs/status-filter-tabs.view';
import { useWalletItemNavigationSections } from '../../hooks/use-wallet-item-navigation-sections';
import { sendNavigationAnalyticEvent } from '../../util/send-navigation-analytic-event.util';
import { getNavItemProps } from '../../util/get-nav-item-props.util';
import { filterCardStatus } from '../../util/filter-card-status.util';

interface NavigationSidebarProps {
  userRole?: string;
  userId?: string;
  navigationSections: WalletItemNavigationSectionGroup[];
}

const Link = genesisStyled(_Link)(
  css({
    display: 'block',
    borderRadius: '0',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'surface.background.secondary',
    },
  }),
);

const Divider = genesisStyled(Box)(
  css({
    borderBottomStyle: 'solid',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomWidth: 'thin',
    height: 0,
    marginX: 'threeQuarters',
  }),
);

const SectionWrapper: React.FC = ({ children }) => (
  <Flex
    flexDirection="column"
    borderColor="onSurface.border.subdued"
    borderWidth="thin"
    borderStyle="solid"
    borderTopWidth="none"
    backgroundColor="surface.background.primary"
    borderBottomLeftRadius="large"
    borderBottomRightRadius="large"
  >
    {children}
  </Flex>
);

const SectionHeader = genesisStyled(Flex)(() =>
  css({
    background: 'inherit',
    border: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'surface.background.secondary',
      cursor: 'pointer',
      boxShadow: 'inset 4px 0px 0px #D6D6D6',
    },
  }),
);

export const NavigationSidebar = ({
  userRole,
  userId,
  navigationSections,
}: NavigationSidebarProps) => {
  const {
    activeSection,
    activeStatus,
    cardStatuses,
    setActiveSection,
    setActiveStatus,
  } = useWalletItemNavigationSections(navigationSections);
  const { formatMessage } = useIntl();

  const countBenefits = () => {
    const benefits = navigationSections?.find(
      (item) => item.section_id === NAVIGATION_TYPE.BENEFITS,
    );
    if (benefits && benefits.sections && benefits.sections.length > 0) {
      return benefits.sections.reduce(
        (result, section) =>
          section.wallet_items ? result + section.wallet_items.length : result,
        0,
      );
    }
    return null;
  };
  const benefitsTotal = countBenefits();

  return (
    <Flex
      flexDirection="column"
      as="nav"
      aria-label={formatMessage({ id: 'WALLET_LANDING_NAV_LABEL' })}
    >
      {navigationSections?.map((itemGroup) => (
        <Box
          marginBottom="one"
          boxShadow="card"
          key={itemGroup.section_id}
          borderRadius="large"
          overflow="hidden"
          as="section"
        >
          {(itemGroup.sections || itemGroup.wallet_items) &&
            // Headings
            (itemGroup.section_id !== NAVIGATION_TYPE.OTHER &&
            cardStatuses &&
            cardStatuses.length > 1 ? (
              <StatusFilterTabs
                cardStatuses={cardStatuses}
                setStatusFilter={setActiveStatus}
                statusFilter={activeStatus}
              />
            ) : (
              <Flex
                backgroundColor="surface.background.secondary"
                paddingX="one"
                paddingY="half"
                borderColor="onSurface.border.subdued"
                borderWidth="thin"
                borderStyle="solid"
                borderTopRightRadius="large"
                borderTopLeftRadius="large"
                as="h2"
              >
                <UtilityText as="span" size="eyebrow">
                  {itemGroup.name.text}
                </UtilityText>
                {itemGroup.section_id !== NAVIGATION_TYPE.OTHER &&
                  benefitsTotal && (
                    <UtilityText
                      as="span"
                      css={{ marginLeft: '$quarter' }}
                      size="xs"
                    >
                      ({benefitsTotal})
                    </UtilityText>
                  )}
              </Flex>
            ))}
          {itemGroup.wallet_items && (
            // "Other" section

            <SectionWrapper>
              {itemGroup?.wallet_items.map((item, itemIndex) => {
                const { url, id, title, subtitle, iconUrl } = getNavItemProps(
                  item,
                  userRole,
                  userId,
                );

                return (
                  <Link
                    as={RouterLink}
                    to={url}
                    key={id}
                    onClick={() => {
                      sendNavigationAnalyticEvent(
                        item?.title || item.name.text,
                        item.id,
                        item?.subtitle || item.vendor_id,
                        item.type,
                        item.type,
                        item.benefit_source,
                      );
                    }}
                  >
                    <NavigationItem
                      title={title}
                      subtitle={subtitle}
                      iconUrl={iconUrl}
                    />
                    {itemGroup.wallet_items &&
                      itemIndex < itemGroup.wallet_items.length - 1 && (
                        <Divider />
                      )}
                  </Link>
                );
              })}
            </SectionWrapper>
          )}
          {itemGroup.sections && (
            // "Benefits" section
            <SectionWrapper>
              {itemGroup.sections?.map((section, sectionIndex) => {
                const filteredItems =
                  section.wallet_items &&
                  filterCardStatus(section.wallet_items, activeStatus);

                const { section_id: sectionId } = section;
                const isActiveSection = activeSection === sectionId;

                return (
                  <React.Fragment key={sectionId}>
                    {filteredItems && filteredItems.length > 0 && (
                      <Box>
                        <HeadingText level="3" size="lg">
                          <SectionHeader
                            as="button"
                            aria-expanded={isActiveSection}
                            justifyContent="space-between"
                            alignItems="center"
                            paddingX="one"
                            paddingY="oneAndHalf"
                            onClick={() => {
                              setActiveSection(
                                isActiveSection ? '' : sectionId,
                              );
                            }}
                          >
                            <Box as="span" display="block" textAlign="left">
                              <HeadingText level="display" size="sm" as="span">
                                {section.name.text}
                                <ParagraphText
                                  as="span"
                                  css={{ marginLeft: '$quarter' }}
                                  emphasis="subtle"
                                  size="sm"
                                >{`(${filteredItems.length})`}</ParagraphText>
                              </HeadingText>
                            </Box>
                            <Image
                              marginLeft="one"
                              alt=""
                              src={
                                handleStaticAsset(
                                  isActiveSection
                                    ? WALLET_ASSET_KEYS.CARET_UP
                                    : WALLET_ASSET_KEYS.CARET_DOWN,
                                ) as string
                              }
                              width={12}
                              minWidth={12}
                              height={6}
                            />
                          </SectionHeader>
                        </HeadingText>
                        {isActiveSection &&
                          filteredItems.map((item, itemIndex) => {
                            const { url, id, ...navItem } = getNavItemProps(
                              item,
                              userRole,
                              userId,
                            );

                            return (
                              <Link
                                as={RouterLink}
                                to={url}
                                key={url}
                                onClick={() => {
                                  sendNavigationAnalyticEvent(
                                    item.name.text,
                                    item.benefit_id,
                                    item.vendor_id,
                                    item.benefit_product_type,
                                    section.section_id,
                                    item.benefit_source,
                                  );
                                }}
                              >
                                {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
                                <NavigationItem {...navItem} />
                                {/* eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue */}
                                {itemIndex < filteredItems?.length - 1 && (
                                  <Divider />
                                )}
                              </Link>
                            );
                          })}
                        {/* eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue */}
                        {sectionIndex < itemGroup.sections?.length - 1 && (
                          <Divider />
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
            </SectionWrapper>
          )}
        </Box>
      ))}
    </Flex>
  );
};
