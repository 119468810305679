import React from 'react';
import { useIntl } from '@leagueplatform/locales';

const formatStrong = (msg) => <strong>{msg}</strong>;

export const useMultipleBenefitsText = ({ strong = formatStrong }) => {
  const { formatMessage } = useIntl();

  const getMultipleBenefitsMessage = (benefits) => {
    const benefitsLength = benefits.length;
    const flexBenefits = benefits.slice(0, benefitsLength - 1);
    const lastBenefit = benefits[benefitsLength - 1];

    const lastBenefitText = (
      <>
        {formatMessage({ id: 'AND' })}{' '}
        {formatMessage(
          { id: 'FLEX_DOLLAR_INFO_TEXT_MULTIPLE' },
          { ...lastBenefit, strong },
        )}
        .
      </>
    );

    const leftoverFlexMessages = flexBenefits.map(
      (benefit) => (
        <>
          {formatMessage(
            { id: 'FLEX_DOLLAR_INFO_TEXT_MULTIPLE' },
            { ...benefit, strong },
          )}
          {benefitsLength === 2 ? ' ' : ', '}
        </>
      ),
      '',
    );

    return (
      <>
        {leftoverFlexMessages}
        {lastBenefitText}
      </>
    );
  };

  return { getMultipleBenefitsMessage };
};

export const useFlexDollarText = ({ leftoverFlexBenefits, ...props }) => {
  const { formatMessage } = useIntl();
  const { getMultipleBenefitsMessage } = useMultipleBenefitsText(props);

  if (leftoverFlexBenefits.length === 1) {
    return formatMessage(
      { id: 'FLEX_DOLLAR_INFO_TEXT_SINGLE' },
      { ...leftoverFlexBenefits[0] },
    );
  }
  if (leftoverFlexBenefits.length > 1) {
    return getMultipleBenefitsMessage(leftoverFlexBenefits);
  }
  return '';
};

// Used by coverage summary
export const useLeftoverFlexBenefitText = ({
  leftoverFlexBenefits,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { getMultipleBenefitsMessage } = useMultipleBenefitsText(props);

  if (leftoverFlexBenefits.length === 1) {
    return formatMessage(
      { id: 'LEFTOVER_FLEX_DOLLAR_INFO_TEXT_SINGLE' },
      {
        ...leftoverFlexBenefits[0],
        strong: formatStrong,
      },
    );
  }
  if (leftoverFlexBenefits.length > 1) {
    return getMultipleBenefitsMessage(leftoverFlexBenefits);
  }
  return '';
};
