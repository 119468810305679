/**
 * List of hard-coded card actions to treat as tabs.
 *
 * Card Actions and Tabs, how are they used?
 *
 * **NEW**
 * The action for Card Info will have an property `id` that web uses to specially handle it.
 *
 * Backend sends us cards actions in `get_wallet`'s response.
 * Each card in the response has a set of actions. On web some of those actions
 * should be handled as buttons, others as a tab to display more.
 *
 *
 * There are 2 different kinds of actions:
 * 1) `content`
 * - Has property `body` containing raw HTML that we render
 * - Generated in backend.
 * - **NEW** The action for Card info will have an `id` that web recognizes to handle it specially
 * 2) `open-url`
 * - Has property `url`,
 * - There are 3 ways to handle them:
 *   i) Phone call
 *   - Their `url` value is follows the format `tel:<phone_number>`. ex: tel:18009579777
 *   ii) External
 *   - Open link in new tab.
 *   - Recognizable if they have `http` (`src/apps/member-experience/pages/wallet/wallet.reducer.js`'s `mapNavItemsFromActions`)
 *   iii) Internal
 *   - These also branch T_T...
 *     a) Redirect somewhere in the app
 *     - ex: Submit claim -> open submit claim modal
 *     - on web, these are filtered out as card actions instead of tabs
 *     b) Show as tab
 *     - ex: coverage
 *     - Recognized by hard-coded list of url (`src/apps/member-experience/pages/wallet/wallet.reducer.js`'s `mapNavItemsFromActions`)
 */
// Tab ID's needed to render certain tabs for a benefit
// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum TAB_IDS {
  OVERVIEW = 'overview',
  OVERVIEW_FRENCH = 'apercu',
  COVERAGE = 'coverage',
  DEPENDENT_INFO = 'dependent-info',
  DEPENDENTS = 'dependents',
  TRANSACTIONS = 'purchases',
  LEAGUE_CREDIT = 'add',
  CARD_INFO = 'card-info',
  USAGE = 'usage',
}

// Action Types
export const ACTION_TYPES = {
  CONTENT: 'content',
  OPEN_URL: 'open-url',
};
export const CONTENT = 'content';
export const OPEN_URL = 'open-url';

// Action ID's
// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum ACTION_IDS {
  CARD_INFO = 'card-info',
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum PILL_STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
  PAST = 'past',
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export enum CARD_STATUS {
  CREATED = 'created',
  READY = 'ready',
  IN_WAITING_PERIOD = 'in_waiting_period',
  INACTIVE = 'inactive',
  INACTIVE_GRACE_PERIOD = 'inactive_grace_period',
  ACTIVE = 'active',
  ALLOCATED = 'allocated',
  OPTED_OUT = 'opted_out',
}
