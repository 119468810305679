import React from 'react';
import { styled } from '../theme';
import { useGenesisCoreInspector } from './use-genesis-core-inspector';

// Using styled components instead of GDS Core components to avoid circular dependencies.
// The styles for these components do not use theme tokens because we want to ensure there won't be any conflicts with colors and backgrounds.
const StyledDiv = styled('div', {
  border: '2px solid #575757',
  position: 'relative',
  display: 'inherit',
  overflow: 'inherit',
  paddingBlockStart: '24px',
});

const StyledText = styled('p', {
  position: 'absolute',
  backgroundColor: '#575757',
  color: '#FFFFFF',
  typography: '$label',
  margin: 0,
  padding: '$quarter',
  top: 0,
  right: 0,
  zIndex: 1,
});

/**
 * A component that wraps around other components and displays the component name and a border around them when the Genesis Core Inspector is enabled.
 *
 * @param children The children to be wrapped.
 * @param displayName The display name of the component. If not provided, the display name of the children will be used.
 *
 * @example
 * ```tsx
 * <GenesisCoreInspector displayName="MyComponent">
 *   <div>This is my component</div>
 * </GenesisCoreInspector>
 * ```
 */
export const GenesisCoreInspector = ({
  children,
  displayName,
}: {
  displayName?: string;
  children: React.JSX.Element;
}) => {
  const { isEnabled } = useGenesisCoreInspector();

  if (isEnabled) {
    return (
      <StyledDiv className="GDS-Core-Inspector">
        <StyledText>{displayName || children?.type.displayName}</StyledText>
        {children}
      </StyledDiv>
    );
  }
  return children;
};
