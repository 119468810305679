import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '../primitives.view';
import { getColourObject } from '../../utils/helpers';

const BodyContainer = styled(Box)`
  background-color: ${({ theme }) => getColourObject(theme).white};
  color: ${({ theme }) => getColourObject(theme).purpleText};
`;

export const ModalBody = ({ className, children, p }) => {
  return (
    <BodyContainer className={className} p={p}>
      {children}
    </BodyContainer>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  p: PropTypes.number,
};

ModalBody.defaultProps = {
  className: '',
  p: 3,
};
