import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, Button, Popover, styled } from '@leagueplatform/genesis-core';
import { StackLayout } from '@leagueplatform/web-common-components';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useCardActions } from '../hooks/use-card-actions.hook';
import { CardAction } from './card-action.component';

const DesktopContainer = styled('div', {
  display: 'block',
  '@mobile': {
    display: 'none',
  },
});

const MobileContainer = styled('div', {
  display: 'none',
  '@mobile': {
    display: 'block',
  },
});

interface CardActionsProps {
  cardRef?: React.RefObject<HTMLElement>;
}

export const CardActions = ({ cardRef }: CardActionsProps) => {
  const { formatMessage } = useIntl();
  const { actions } = useCardActions({ cardRef });
  const sendBenefitActionAnalyticsEvent = useWalletDetailAnalytics();

  if (!actions.length) return null;

  if (actions.length === 1) {
    return (
      <StackLayout as="ul">
        {actions.map((action) => (
          <Box as="li">
            <CardAction
              action={action}
              key={`${action.id}-card-action-desktop`}
            />
          </Box>
        ))}
      </StackLayout>
    );
  }

  return (
    <>
      {/* Mobile */}
      <MobileContainer>
        <Popover.Root>
          <Popover.Trigger>
            <Button
              icon="interfaceMeatballs"
              priority="secondary"
              width="fillContainer"
              onClick={() =>
                sendBenefitActionAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  detail: 'select card actions',
                })
              }
            >
              {formatMessage({ id: 'CARD_ACTIONS' })}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="start" side="bottom" showCloseButton={false}>
            <StackLayout as="ul">
              {actions.map((action) => (
                <Box as="li">
                  <CardAction
                    action={action}
                    key={`${action.id}-card-action-mobile`}
                  />
                </Box>
              ))}
            </StackLayout>
          </Popover.Content>
        </Popover.Root>
      </MobileContainer>
      {/* Desktop */}
      <DesktopContainer>
        <StackLayout as="ul">
          {actions.map((action) => (
            <Box as="li">
              <CardAction
                action={action}
                key={`${action.id}-card-action-desktop`}
              />
            </Box>
          ))}
        </StackLayout>
      </DesktopContainer>
    </>
  );
};
