import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_USER_BENEFIT_LIVE_BALANCES_MESSAGE_TYPE =
  'get_user_benefit_live_balances';

export const getUserBenefitLiveBalances = async (
  userId: string | undefined,
  benefitId: string,
  dependentId?: string,
  version: number = 1,
) => {
  const params = {
    message_type: GET_USER_BENEFIT_LIVE_BALANCES_MESSAGE_TYPE,
    info: {
      user_benefit_id: benefitId,
      user_id: userId,
      dependent_id: dependentId,
      version,
    },
  };

  return SocketAsFetch.fetch(params);
};
