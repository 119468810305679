import * as React from 'react';
import { Caption } from '@leagueplatform/genesis-commons';
import { StackLayout, Accordion } from '@leagueplatform/web-common-components';
import { CategoriesV2, ProviderServiceV2 } from '@leagueplatform/wallet-api';
import { BalanceGroup } from '../balance-group/balance-group.component';

interface BalanceCategoriesProps extends ProviderServiceV2 {
  networkName?: string;
  lastUpdated: string;
  onProviderToggle?: (
    accordionName: string,
    state: 'expand' | 'collapse',
    balanceType?: string,
  ) => void;
}

export const BalanceCategories = ({
  title,
  categories,
  networkName,
  lastUpdated,
  onProviderToggle,
}: BalanceCategoriesProps) => {
  const ACCORDION_NAME = React.useMemo(
    () => `accordion-${title.replaceAll(' ', '-').toLocaleLowerCase()}`,
    [title],
  );

  const handleOnValueChange = (toggledItems: string[]) => {
    if (!onProviderToggle) {
      return;
    }
    // TODO: Update analytics logging when we know better what should be logged
    const isCollapsed = toggledItems.includes(ACCORDION_NAME);

    if (isCollapsed) {
      onProviderToggle(title, 'collapse', ACCORDION_NAME);
    } else {
      onProviderToggle(title, 'expand', ACCORDION_NAME);
    }
  };

  return (
    <Accordion.Root
      type="multiple"
      defaultValue={[ACCORDION_NAME]}
      onValueChange={handleOnValueChange}
      css={{ width: '100%' }}
    >
      <Accordion.Item value={ACCORDION_NAME}>
        <Accordion.Header level="3" size="md">
          <Accordion.Trigger>{title}</Accordion.Trigger>
        </Accordion.Header>
        <StackLayout
          space="$oneAndHalf"
          as={Accordion.Content}
          key={`accordion-${title}-panel`}
        >
          {categories.map(
            ({
              title: categoryTitle,
              type,
              definition,
              balances,
            }: CategoriesV2) => (
              <BalanceGroup
                key={`${networkName}-balance-group-${type}`}
                title={categoryTitle}
                type={type}
                definition={definition}
                balances={balances}
                networkName={networkName}
              />
            ),
          )}
          <Caption>{lastUpdated}</Caption>
        </StackLayout>
      </Accordion.Item>
    </Accordion.Root>
  );
};
