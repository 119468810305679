import { Achievement, AchievementInfo } from '@leagueplatform/rewards-api';

export function getAchievementsByStatus(achievementsData: AchievementInfo[]) {
  const inProgressAchievements: Achievement[] = [];
  const completedAchievements: Achievement[] = [];

  achievementsData.forEach((achievementInfo: AchievementInfo) => {
    inProgressAchievements.push(...(achievementInfo.in_progress ?? []));
    completedAchievements.push(...(achievementInfo.completed ?? []));
  });
  return { inProgressAchievements, completedAchievements };
}
