import { getConfig } from '@leagueplatform/config';

export const getEnvironment = () => getConfig().config.core.appEnvironment;

export const isProduction = () => getEnvironment() === 'production';

export const isStaging = () => getEnvironment() === 'staging';

export const isDev = () => getEnvironment() === 'development';

/**
 * Returns `true` if called in the context of a running static test (e.g., Jest/unit tests).
 *
 * Note: **will not return `true` in the context of an end-to-end test.**
 */
export const isNodeTestEnv = () => {
  /**
   * Separate the name of the property from the access expression below,
   * so that ESBuild can't replace it with a hard-coded string literal.
   */
  const nodeEnvPropName = 'NODE_ENV';
  return (
    typeof process !== 'undefined' && process.env?.[nodeEnvPropName] === 'test'
  );
};
