import { BoxProps } from '../Box';

export type ErrorType = Boolean | string | number | Array<string>;

export interface InputProps
  extends Partial<Omit<Omit<HTMLInputElement, 'type'>, keyof BoxProps>> {
  name: string;
  value?: string;
  error?: ErrorType;
  required?: true;
  disabled?: boolean;
  className?: string;
}

export const visuallyHiddenInputStyles: any = {
  position: 'absolute',
  opacity: 0,
  zIndex: -1,
  width: 1,
  height: 1,
  overflow: 'hidden',
};
