import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import { Box } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { last } from 'lodash';

import {
  css,
  genesisStyled,
  Flex,
  Image,
  Link,
  SubtitleOne,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';

import {
  trackAnalyticsEvent,
  usePageViewAnalytics,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ASSESSMENT_ASSET_KEYS } from '../../assessment.types';
import { ProgressIndicator } from './progress-indicator.view';
import { OnboardingContent } from './onboarding-content.view';

const ArrowButton = genesisStyled('button')(
  css({
    border: 'thin',
    borderRadius: 'circle',
    padding: 'one',
    transition: 'background-color 0.3s ease',
    '&:focus': {
      outline: 'none',
      boxShadow: 'focusRing',
    },
  }),
);

const ForwardButton = genesisStyled(ArrowButton)(
  css({
    backgroundColor: 'primary.background.default',
    marginLeft: 'oneAndHalf',
    '&:hover': {
      backgroundColor: 'primary.background.hovered',
    },
  }),
);

const BackButton = genesisStyled(ArrowButton)(
  css({
    backgroundColor: 'decorative.brand.primary.pastel',
    marginRight: 'oneAndHalf',
    color: 'onSurface.text.subdued',
    '&:hover': {
      backgroundColor: 'decorative.brand.primary.brightest',
    },
  }),
);

const OnboardingContainer = genesisStyled(Flex)(
  css({
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  }),
);

const ProgressContainer = genesisStyled(Flex)`
  bottom: 15%;
  left: 0;
`;

export const Fade = styled.div`
  transition: opacity 250ms ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

export const Onboarding = ({
  pages,
  moduleId,
  completeOnboarding,
  type,
  activePageIndex,
  setActivePageIndex,
  healthAssessmentIsEditing,
  completionPoints,
}) => {
  const progressionItems = pages.map((page, index) => ({
    title: page.title,
    active: activePageIndex === index,
  }));
  const [isAnimate, setIsAnimate] = useState(true);
  const [moduleTransitionedIn, setModuleTransitionedIn] = useState(false);

  const activePage = pages[activePageIndex];
  const { formatMessage } = useIntl();

  const renderProgress = pages.length > 1;

  const forwardButtonText = formatMessage({ id: 'NEXT_STEP' });

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
    screen_name: SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO,
    assessment_type: type,
    has_health_profile: healthAssessmentIsEditing,
    text_detail: activePage.title,
  });

  const onComplete = () => {
    setIsAnimate(false);
    if (activePage.title === last(pages).title || !renderProgress) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
        screen_name: SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO,
        detail: 'complete onboarding',
        sub_detail: forwardButtonText,
        assessment_id: moduleId,
        assessment_type: type,
      });
      return completeOnboarding(moduleId);
    }

    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
      screen_name: SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO,
      detail: 'continue onboarding',
      sub_detail: forwardButtonText,
      assessment_id: moduleId,
      assessment_type: type,
    });
    return setActivePageIndex(activePageIndex + 1);
  };

  // We need to wait for both the previous module to finish fading out AND the next module's props to be passed down
  // We can't guarantee which will happen first.
  // If we don't wait for both to happen questions will flicker.

  // Defining an empty ref value to track which module is currently displayed
  // Need to define separately as activePageIndex (prop) and prevCurrentIndex (previous hook) may update before the previous module has finished fading out.
  const activeModuleIndex = useRef(activePageIndex);

  useEffect(() => {
    // If the transition ends before the props for the next module have been loaded we do nothing.
    if (
      !moduleTransitionedIn &&
      activePageIndex === activeModuleIndex.current
    ) {
      return;
    }

    // Check that no module has already faded in and no module is fading out.
    if (!isAnimate && !moduleTransitionedIn) {
      activeModuleIndex.current = activePageIndex;
      setIsAnimate(true);
    }
  }, [activeModuleIndex, isAnimate, activePageIndex, moduleTransitionedIn]);

  return (
    <OnboardingContainer>
      <Flex justifyContent="center" alignItems="center" flex="0 0 50vh">
        {activePageIndex > 0 && (
          <Box flex="0 0 74px">
            <BackButton
              data-testid="back-button"
              onClick={() => {
                setIsAnimate(false);
                setActivePageIndex(activePageIndex - 1);
              }}
            >
              <VisuallyHidden>
                {/* This string is just a placeholder. It will be replaced with more descriptive text as part of A11Y-607 */}
                {formatMessage({ id: 'GO_BACK' })}
              </VisuallyHidden>
              <Image
                src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.BACK_ARROW)}
                size="18px"
                display="block"
                role="presentation"
              />
            </BackButton>
          </Box>
        )}

        <Flex flex="0 0 60%" justifyContent="center" alignItems="center">
          <Transition
            in={isAnimate}
            timeout={250}
            appear
            unmountOnExit
            onEnter={() => setModuleTransitionedIn(true)}
            onExited={() => setModuleTransitionedIn(false)}
          >
            {(state) => (
              <Fade state={state} key={pages[activePageIndex].title}>
                {isAnimate && (
                  <OnboardingContent
                    data={activePage}
                    pageData={pages}
                    moduleId={moduleId}
                    assessmentType={type}
                    onComplete={onComplete}
                    numberOfPages={pages.length}
                    activePageIndex={activePageIndex}
                    healthAssessmentIsEditing={healthAssessmentIsEditing}
                    completionPoints={completionPoints}
                  />
                )}
              </Fade>
            )}
          </Transition>
        </Flex>
        {pages.length > 1 && (
          <ForwardButton data-testid="forward-button" onClick={onComplete}>
            {/* The string stored in forwardButtonText is temporary. It will be replaced with a more descriptive label as part of A11Y-607 */}
            <VisuallyHidden>{forwardButtonText}</VisuallyHidden>
            <Image
              size="18px"
              display="block"
              src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.FORWARD_ARROW)}
              aria-hidden="true"
            />
          </ForwardButton>
        )}
      </Flex>
      {renderProgress && (
        <Flex flexDirection="column" flex="0 0 25vh" justifyContent="center">
          <ProgressContainer
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <ProgressIndicator
              activeIndex={activePageIndex}
              progressionItems={progressionItems}
              setActivePageIndex={setActivePageIndex}
              setIsAnimate={setIsAnimate}
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
            <Link
              href="#"
              textAlign="left"
              marginTop="one"
              padding="one"
              onClick={(e) => {
                e.preventDefault();
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
                  screen_name: SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO,
                  detail: 'skip onboarding',
                  assessment_id: moduleId,
                  assessment_type: type,
                  has_health_profile: true,
                });
                completeOnboarding(moduleId);
              }}
            >
              <SubtitleOne as="span" color="interactive.action.primary">
                {formatMessage({ id: 'SKIP' })}
              </SubtitleOne>
            </Link>
          </ProgressContainer>
        </Flex>
      )}
    </OnboardingContainer>
  );
};

Onboarding.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      eyebrowHeadline: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  completeOnboarding: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  moduleId: PropTypes.string,
  completionPoints: PropTypes.number,
  activePageIndex: PropTypes.number.isRequired,
  setActivePageIndex: PropTypes.func.isRequired,
  healthAssessmentIsEditing: PropTypes.bool.isRequired,
};

Onboarding.defaultProps = {
  pages: [],
  moduleId: '',
  completionPoints: null,
};
