import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import isValid from 'date-fns/isValid';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import { DateInputPresenter } from './date-input.view';
import { LegacyUIKitDateInputPresenter } from './legacy-date-input.view';
import { useIntl } from 'react-intl';

const isNil = (val) => val === undefined || val === null;

function isDate(value) {
  if (isNil(value)) {
    return false;
  }

  // Check for an ISO-formatted string value
  if (isValid(parseISO(value))) {
    return true;
  }

  // Use toDate to check for non-string values (timestamps, date objects)
  if (typeof value !== 'string' && isValid(toDate(value))) {
    return true;
  }

  return false;
}

const isBlank = (str) => !str || /^\s*$/.test(str);

const getDay = (date) =>
  isDate(date) ? String(new Date(date).getUTCDate()) : undefined;
const getMonth = (date) =>
  isDate(date) ? String(new Date(date).getUTCMonth()) : undefined;
const getYear = (date) =>
  isDate(date) ? String(new Date(date).getUTCFullYear()) : undefined;

const constrainDigits = (numDigits) => (val) =>
  isNil(val) ? val : val.replace(/\D/g, '').substring(0, numDigits);

const handleInput = (handler) => (e) => {
  const { target: { value } = {} } = e;
  handler(e, value);
};

const dateFromParts = (dateParts) => {
  const clean = (d) => (isBlank(d) ? undefined : d);

  const date = new Date(
    Date.UTC(
      clean(dateParts.year),
      clean(dateParts.month),
      clean(dateParts.day),
    ),
  );
  return isValid(date) ? date : undefined;
};

const useDateInputHandlers = ({ onBlur: onBlurProp, onChange, value }) => {
  const { formatMessage } = useIntl();

  const initialDay = getDay(value);
  const initialMonth = getMonth(value);
  const initialYear = getYear(value);

  const [day, setDay] = useState(constrainDigits(2)(initialDay));
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(constrainDigits(4)(initialYear));

  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);

  const hasAnyFocus = () =>
    document.activeElement === dayInputRef.current ||
    document.activeElement === monthInputRef.current ||
    document.activeElement === yearInputRef.current;

  const validate = ({ day: dayValue, month: monthValue, year: yearValue }) => {
    const dayTooBig = parseInt(dayValue, 10) > 31;
    const dayNotInMonth =
      dayValue && getDaysInMonth(new Date(yearValue, monthValue)) < dayValue;
    if (dayTooBig || dayNotInMonth)
      return formatMessage({ id: 'INVALID_DAY_ERROR_MESSAGE' });
    return undefined;
  };

  const handleChange =
    (fieldName, updateState, constrain = (v) => v) =>
    (e, val) => {
      const constrainedValue = constrain(val);
      const dateParts = { day, month, year, [fieldName]: constrainedValue };
      const error = validate(dateParts);
      const date = dateFromParts(dateParts);
      updateState(constrainedValue);
      onChange(e, { ...dateParts, date, error });
    };

  const onChangeMonth = handleInput(handleChange('month', setMonth));
  const onChangeDay = handleInput(
    handleChange('day', setDay, constrainDigits(2)),
  );
  const onChangeYear = handleInput(
    handleChange('year', setYear, constrainDigits(4)),
  );

  const onBlur = (event) => {
    if (hasAnyFocus()) return;

    event?.persist();
    const dateParts = { day, month, year };
    const error = validate(dateParts);
    const date = dateFromParts(dateParts);

    if (onBlurProp) {
      onBlurProp(event, { ...dateParts, date, error });
    }
  };

  useEffect(() => {
    if (hasAnyFocus()) return;

    const newDay = getDay(value);
    const newMonth = getMonth(value);
    const newYear = getYear(value);

    if (!newDay || !newMonth || !newYear) return;

    if (newDay !== day || newMonth !== month || newYear !== year) {
      setMonth(getMonth(value));
      setDay(getDay(value));
      setYear(getYear(value));
    }
  }, [value]);

  return {
    day,
    month,
    year,
    onChangeMonth,
    onChangeDay,
    onChangeYear,
    onBlur,
    validate,
    handleChange,
    dayInputRef,
    monthInputRef,
    yearInputRef,
  };
};

export const DateInputContainer = (props) => {
  const { legacy } = props;
  const handlerProps = useDateInputHandlers(props);

  /*
    NOTE: "legacy" prop is deprecated and should not be used anymore!
  */
  return legacy ? (
    <LegacyUIKitDateInputPresenter {...props} {...handlerProps} />
  ) : (
    <DateInputPresenter {...props} {...handlerProps} />
  );
};

DateInputContainer.propTypes = {
  legacy: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DateInputContainer.defaultProps = {
  legacy: false,
  value: undefined,
};
