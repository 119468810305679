import React from 'react';
import {
  Box,
  Flex,
  BodyTwoSecondary,
  SubtitleTwo,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { Badge } from '@leagueplatform/web-common-components';
import {
  TeamChallengeRank,
  UserChallengeRank,
} from '@leagueplatform/health-journey-api';
import { useIntl } from '@leagueplatform/locales';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { CLP_DETAIL, TAB_NAME } from 'constants/analytics.constants';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { LoadMoreCTA } from 'components/leaderboard/load-more-cta/load-more-cta.component';
import { LeaderboardSkeletonLoader } from './leaderboard-skeleton-loader.component';
import { getDisplayedRankDetails } from './utils/get-displayed-rank-details.util';
import { ChallengeUnitIcon } from './challenge-unit-icon.component';

export interface LeaderboardTableProps {
  leaderboardData: (UserChallengeRank | TeamChallengeRank)[];
  displayViewMore?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
  leaderboardView?: number;
}
export const LeaderboardTable = ({
  leaderboardData,
  displayViewMore,
  isFetchingNextPage,
  fetchNextPage,
  leaderboardView,
}: LeaderboardTableProps) => {
  const { formatMessage } = useIntl();
  const { data: userData, isLoading: isUserLoading } = useGetUserProfile();

  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  if (isUserLoading) return <LeaderboardSkeletonLoader />;
  const handleAnalyticsClick = () => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail: CLP_DETAIL.VIEW_MORE,
      tab_name: TAB_NAME.LEADERBOARD,
      challenge_leaderboard_type: leaderboardView === 0 ? 'individual' : 'team',
    });
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      <table width="100%">
        <VisuallyHidden as="thead">
          <tr>
            <th>{formatMessage({ id: 'LEADERBOARD_RANKING' })}</th>
            <th>{formatMessage({ id: 'LEADERBOARD_USERNAME' })}</th>
            <th>{leaderboardData[0].rankingUnit}</th>
          </tr>
        </VisuallyHidden>
        <Box as="tbody">
          {leaderboardData.map((leaderboardRank) => {
            const {
              avatarUrl,
              currentUserOrTeamName,
              firstName,
              formattedName,
              id,
              isHighlightedRank,
              lastName,
              rank,
              teamName,
              score,
              rankingUnit,
            } = getDisplayedRankDetails(
              leaderboardRank,
              // TODO remove the "!" once we actually check the `isSuccess` of the query
              userData!,
              formatMessage,
            );

            return (
              <Flex
                alignItems="center"
                backgroundColor={
                  isHighlightedRank ? 'highlight.background.subdued' : 'inherit'
                }
                borderTopColor="onSurface.border.subdued"
                borderTopWidth="thin"
                key={id}
                padding="one"
                as="tr"
              >
                <BodyTwoSecondary minWidth="twoAndHalf" as="td">
                  {rank}
                </BodyTwoSecondary>
                <Box
                  as="td"
                  marginX="one"
                  size="twoAndHalf"
                  minWidth="twoAndHalf"
                >
                  <ChallengeProfilePicture
                    profilePicture={avatarUrl}
                    firstName={firstName?.length ? firstName : teamName}
                    lastName={lastName?.length ? lastName : ''}
                    backgroundColor="$decorativeBrandPrimaryDefault"
                  />
                </Box>
                <Box as="td">
                  <SubtitleTwo as="p">
                    {isHighlightedRank ? currentUserOrTeamName : formattedName}
                  </SubtitleTwo>
                </Box>
                <SubtitleTwo
                  as="td"
                  textAlign="right"
                  marginLeft="auto"
                  verticalAlign="middle"
                >
                  <Badge
                    css={{
                      backgroundColor: isHighlightedRank
                        ? 'none'
                        : '$surfaceBackgroundTertiary',
                      color: '$onSurfaceTextPrimary',
                      fontWeight: '$regular',
                      fontSize: '$subtitleOne',
                      '@mobile': { fontSize: '$overline' },
                    }}
                  >
                    <ChallengeUnitIcon
                      score={score}
                      rankingUnit={rankingUnit}
                    />
                  </Badge>
                </SubtitleTwo>
              </Flex>
            );
          })}
        </Box>
      </table>

      {displayViewMore && (
        <LoadMoreCTA
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          onClick={handleAnalyticsClick}
        />
      )}
    </Flex>
  );
};
