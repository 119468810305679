import {
  BodyOne,
  Box,
  Flex,
  Image,
  SubtitleOne,
  FlexProps,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import * as React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { Button } from '@leagueplatform/genesis-core';

type ErrorPanelProps = FlexProps & {
  onRetry: () => void;
  isRefetching: boolean;
};

export const ErrorPanel = ({
  onRetry,
  marginTop = 'three',
  isRefetching,
  ...props
}: ErrorPanelProps) => {
  const { formatMessage } = useIntl();
  const connectedDeviceImage = handleStaticAsset(
    DASHBOARD_ASSET_MAP.DASHBOARD_NO_HEALTH_METRIC_DATA,
  ) as string;

  return (
    <Flex
      backgroundColor="surface.background.secondary"
      borderWidth="thin"
      borderColor="onSurface.border.subdued"
      flexDirection="column"
      alignItems="center"
      marginTop={marginTop}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box marginTop="oneAndHalf" marginBottom="one">
        <Image width={215} src={connectedDeviceImage} alt="altTxt" />
      </Box>
      <Flex flexDirection="column" alignItems="center">
        <SubtitleOne marginBottom="quarter" fontWeight="semibold">
          {formatMessage({
            id: 'ERROR_STATE_TITLE',
          })}
        </SubtitleOne>
        <BodyOne textAlign="center" marginBottom="oneAndHalf">
          {formatMessage({ id: 'TROUBLE_DISPLAYING_DATA' })}
        </BodyOne>
        <Button
          loading={isRefetching}
          priority="secondary"
          onClick={onRetry}
          css={{ marginBottom: '$oneAndHalf' }}
        >
          {formatMessage({ id: 'RETRY' })}
        </Button>
      </Flex>
    </Flex>
  );
};
