import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  fetchAndSyncAchievementUpdates,
  FETCH_AND_SYNC_ACHIEVEMENTS_UPDATES_MESSAGE_TYPE,
} from '@leagueplatform/rewards-api';
import { captureError } from '@leagueplatform/observability';
import { getAchievementsByStatus } from '../utils/get-achievements-by-status.util';
import { ACHIEVEMENTS_ENABLED } from '../constants/feature-flag-names.constants';

export const useAchievementsUpdates = (
  shouldFetchAchievementUpdates?: boolean,
) => {
  const queryClient = useQueryClient();
  const {
    data: achievementsUpdateData,
    status: achievementsUpdateStatus,
    refetch: refetchAchievements,
    isFetching,
    isLoading,
  } = useQuery(
    [FETCH_AND_SYNC_ACHIEVEMENTS_UPDATES_MESSAGE_TYPE],
    fetchAndSyncAchievementUpdates,
    {
      enabled: shouldFetchAchievementUpdates || false,
      staleTime: Infinity,
      onError: (error) => {
        captureError(
          new Error(`Unable to fetch achievement updates: ${error}`),
        );
      },
    },
  );

  const [showCelebrationAchievementModal, toggleCelebrationAchievementModal] =
    useState(false);

  const { data: isAchievementsFeatureFlagOn } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  const { inProgressAchievements, completedAchievements } =
    getAchievementsByStatus(achievementsUpdateData?.achievements_info ?? []);

  return {
    clearAchievements: () =>
      queryClient.removeQueries(
        FETCH_AND_SYNC_ACHIEVEMENTS_UPDATES_MESSAGE_TYPE,
      ),
    isAchievementsFeatureFlagOn,
    isAchievementUpdatesReady:
      achievementsUpdateStatus === 'success' && !isLoading && !isFetching,
    inProgressAchievements,
    completedAchievements,
    hasCompletedAchievements:
      !!(achievementsUpdateStatus === 'success') &&
      completedAchievements.length,
    toggleCelebrationAchievementModal,
    showCelebrationAchievementModal,
    refetchAchievements,
  };
};
