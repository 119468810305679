import React, { useEffect } from 'react';
import { Select, Text } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';

const findAnswerIdFromValue = (answers, value) =>
  answers && answers.find((answer) => answer.value === value).id;

export const AnswerDropDown = ({
  questionId,
  answers,
  noSelectionLabel,
  onChangeHandler,
  setShowArrow,
  userSelections,
}) => {
  const { formatMessage } = useIntl();
  const userSelection = userSelections[questionId] || {};
  const {
    id: checkedAnswer = [],
    value: inputVal,
    error: { hasError = false, errorMsg = '' },
  } = userSelection;

  useEffect(() => {
    setShowArrow(checkedAnswer.length > 0);
  }, [checkedAnswer, setShowArrow]);

  return (
    <>
      <Select
        options={answers}
        noSelectionLabel={
          noSelectionLabel || formatMessage({ id: 'SELECT_ONE' })
        }
        hasError={hasError}
        name={questionId}
        onChange={({ altered, value }) => {
          if (altered) {
            onChangeHandler({
              id: [findAnswerIdFromValue(answers, value)],
              value,
            });
          }
        }}
        width="200px"
        selectedValue={inputVal}
        onSubmit={() => {}}
      />
      {hasError && errorMsg && (
        <Text color="red" fontSize={2} mt={0}>
          {errorMsg}
        </Text>
      )}
    </>
  );
};

AnswerDropDown.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  noSelectionLabel: PropTypes.string,
  questionId: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  setShowArrow: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
};

AnswerDropDown.defaultProps = {
  noSelectionLabel: '',
};
