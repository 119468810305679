import { useGetUserProfile } from '@leagueplatform/web-common';
import * as React from 'react';

interface SelectedMember {
  id?: string;
  name?: string;
}

const MemberSelectorContext = React.createContext<
  [undefined | SelectedMember, (user: SelectedMember | undefined) => void]
>([undefined, () => {}]);

export function MemberSelectorProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const selectedMemberState = React.useState<undefined | SelectedMember>(
    undefined,
  );
  return (
    <MemberSelectorContext.Provider value={selectedMemberState}>
      {children}
    </MemberSelectorContext.Provider>
  );
}
export function useSelectedMember(): [
  SelectedMember | undefined,
  (user: SelectedMember | undefined) => void,
] {
  const [value, setValue] = React.useContext(MemberSelectorContext);
  const setUser = React.useCallback(
    (user: SelectedMember | undefined) => {
      setValue(user);
    },
    [setValue],
  );

  // If no user set the current user as the default selected user.
  useGetUserProfile({
    enabled: !value,
    onSuccess: (userData) => {
      if (!value) {
        // TODO: Update how queries handle user & dependent id params
        setValue({
          id: undefined,
          name: `${userData.userProfile?.firstName} ${userData.userProfile?.lastName}`,
        });
      }
    },
  });

  return [value, setUser];
}
