{
  "dragDescriptionKeyboard": "Enter キーを押してドラッグを開始してください。",
  "dragDescriptionKeyboardAlt": "Alt+Enter キーを押してドラッグを開始します。",
  "dragDescriptionLongPress": "長押ししてドラッグを開始します。",
  "dragDescriptionTouch": "ダブルタップしてドラッグを開始します。",
  "dragDescriptionVirtual": "クリックしてドラッグを開始します。",
  "dragItem": "{itemText} をドラッグ",
  "dragSelectedItems": "{count, plural, one {# 個の選択項目} other {# 個の選択項目}} をドラッグ",
  "dragSelectedKeyboard": "Enter キーを押して、{count, plural, one {# 選択した項目} other {# 選択した項目}}をドラッグします。",
  "dragSelectedKeyboardAlt": "Alt+Enter キーを押して、{count, plural, one {# 選択した項目} other {# 選択した項目}}をドラッグします。",
  "dragSelectedLongPress": "長押しして、{count, plural, one {# 選択した項目} other {# 選択した項目}}をドラッグします。",
  "dragStartedKeyboard": "ドラッグを開始します。Tab キーを押してドロップターゲットにいどうし、Enter キーを押してドロップするか、Esc キーを押してキャンセルします。",
  "dragStartedTouch": "ドラッグを開始しました。ドロップのターゲットに移動し、ダブルタップしてドロップします。",
  "dragStartedVirtual": "ドラッグを開始しました。ドロップのターゲットに移動し、クリックまたは Enter キーを押してドロップします。",
  "dropCanceled": "ドロップがキャンセルされました。",
  "dropComplete": "ドロップが完了しました。",
  "dropDescriptionKeyboard": "Enter キーを押してドロップします。Esc キーを押してドラッグをキャンセルします。",
  "dropDescriptionTouch": "ダブルタップしてドロップします。",
  "dropDescriptionVirtual": "クリックしてドロップします。",
  "dropIndicator": "ドロップインジケーター",
  "dropOnItem": "{itemText} にドロップ",
  "dropOnRoot": "ドロップ場所",
  "endDragKeyboard": "ドラッグしています。Enter キーを押してドラッグをキャンセルします。",
  "endDragTouch": "ドラッグしています。ダブルタップしてドラッグをキャンセルします。",
  "endDragVirtual": "ドラッグしています。クリックしてドラッグをキャンセルします。",
  "insertAfter": "{itemText} の後に挿入",
  "insertBefore": "{itemText} の前に挿入",
  "insertBetween": "{beforeItemText} と {afterItemText} の間に挿入"
}
