import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@leagueplatform/genesis-commons';

const ProgressBarContainer = (props) => (
  <Flex
    height="half"
    backgroundColor="interactive.action.disabled"
    borderRadius="large"
    overflowX="hidden"
    width="100%"
    {...props}
  />
);

const lastSegmentStyles = {
  borderTopRightRadius: 'large',
  borderBottomRightRadius: 'large',
};

const ProgressBarSegment = ({ percent, color, isLast, ...props }) => (
  <Box
    width={`${percent}%`}
    transition="width 2s ease"
    backgroundColor={color}
    {...(isLast ? lastSegmentStyles : {})}
    {...props}
  />
);

ProgressBarSegment.propTypes = {
  color: PropTypes.string,
  percent: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
};

ProgressBarSegment.defaultProps = {
  color: 'transparent',
};

export const CompoundProgressBar = ({ segments, ...props }) => {
  /** Determine if the current segment should be treated as the last
   * @returns `true` if the current segment is the last, or if no subsequent segments have a percent value greater than 0
   */
  const shouldBeLastSegment = (segmentIndex) => {
    const nextSegments = segments.slice(segmentIndex + 1);
    return (
      segmentIndex === segments.length - 1 ||
      nextSegments.some(({ percent = undefined }) => !percent)
    );
  };

  return (
    <ProgressBarContainer {...props}>
      {segments.map((segment, index) => (
        <ProgressBarSegment {...segment} isLast={shouldBeLastSegment(index)} />
      ))}
    </ProgressBarContainer>
  );
};

CompoundProgressBar.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
};
