import { LEAGUE_CREDIT_TYPE } from 'common/league-credit-type';

const LEAGUE_CREDIT_ID = 'league-credit';

/**
 * Each element in a collection of `wallet_items` can have a different type.
 * This function maps each type's properties to a common set of component props for the URL.
 */
export const normalizeId = (id: string | undefined) => {
  if (id === LEAGUE_CREDIT_TYPE) return LEAGUE_CREDIT_ID;
  return id;
};
