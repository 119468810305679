import React, { FC } from 'react';
import * as RadixTabsPrimitive from '@radix-ui/react-tabs';
import { styled } from '../../theme';
import { GDSCustomizableComponent } from '../../types';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSTabsProps
  extends RadixTabsPrimitive.TabsProps,
    GDSCustomizableComponent {}

export const BaseTabsRoot = styled(RadixTabsPrimitive.Root, {
  width: '100%',
});

export const TabsRoot: FC<GDSTabsProps> = ({
  activationMode = 'manual',
  children,
  className,
  css,
  defaultValue,
  onValueChange,
  value,
  ...props
}) => (
  <GenesisCoreInspector displayName="Tabs">
    <BaseTabsRoot
      activationMode={activationMode}
      className={[`GDS-tabs`, className].join(' ')}
      css={css}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      value={value}
      {...props}
    >
      {children}
    </BaseTabsRoot>
  </GenesisCoreInspector>
);
