import { useInfiniteQuery } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import {
  getHighlightsFeedById,
  CHALLENGE_HIGHLIGHT_FEED_TYPES,
  MultipleHighlightsDeserialisedData,
  CHALLENGE_HIGHLIGHTS_PATH,
} from '@leagueplatform/health-journey-api';

export const useGetHighlightsFeedById = (
  feedType: CHALLENGE_HIGHLIGHT_FEED_TYPES,
  id: string,
) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  return useInfiniteQuery<MultipleHighlightsDeserialisedData, Error>(
    [CHALLENGE_HIGHLIGHTS_PATH, id, 'infinite'],
    ({ pageParam }) =>
      getHighlightsFeedById(feedType, id, challengeId, pageParam),
    {
      getNextPageParam: (lastPage) => {
        // If there is no next link OR there is no more data returned,
        // return undefined.
        // The check for no more data is necessary because the back end always
        // returns a next link, even when there is not a next page. They have
        // instructed us to stop refetching when there is no data returned.
        if (!lastPage.links.next || !lastPage.data.length) {
          return undefined;
        }
        // TODO: Remove this string manipulation after the merge of
        // https://everlong.atlassian.net/browse/WEBPLAT-2249
        // Which will allow for the passing of a full URL to leagueFetch
        const { next } = lastPage.links;
        return next.slice(next.indexOf('/v1/'));
      },
    },
  );
};
