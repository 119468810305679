import { useCallback, useState } from 'react';
import { usePostDismissWidget } from '@leagueplatform/masonry-api';
import type { WidgetLink } from '@leagueplatform/masonry-api';

export const useDismissibleAction = (
  dismissibleLink?: WidgetLink,
  onDismissAnalytics?: () => void,
) => {
  const [dismissed, setDismissed] = useState(false);

  const onDismiss = useCallback(() => {
    setDismissed(true);
    if (onDismissAnalytics) onDismissAnalytics();
  }, [setDismissed, onDismissAnalytics]);

  usePostDismissWidget(dismissed, dismissibleLink);

  return { dismissed, onDismiss };
};
