import React, { FC, PropsWithChildren } from 'react';
import { styled } from '../../../theme';
import type { GDSCustomizableComponent } from '../../../types';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

const HintBase = styled('span', {
  display: 'block',
  margin: 0,
  typography: '$caption',
  color: '$onSurfaceTextSubdued',
});

export interface GDSInputHint extends GDSCustomizableComponent {
  id?: string;
}

export const InputHint: FC<PropsWithChildren<GDSInputHint>> = ({
  children,
  className,
  css,
  id,
  ...props
}) => (
  <GenesisCoreInspector displayName="InputHint">
    <HintBase
      className={['GDS-input-hint', className].join(' ')}
      id={id}
      css={css}
      {...props}
    >
      {children}
    </HintBase>
  </GenesisCoreInspector>
);

InputHint.displayName = 'InputHint';
