import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export interface RequiredField {
  name: string;
  employee_viewable: boolean;
  employer_viewable: boolean;
  type: string;
  read_only: boolean;
}

interface GroupRequiredFields {
  groupId: string;
  fields: Array<RequiredField>;
}

export const setGroupRequiredFields = ({
  groupId,
  fields,
}: GroupRequiredFields) =>
  SocketAsFetch.fetch({
    message_type: 'set_group_required_fields',
    info: {
      group_id: groupId,
      fields,
    },
  });
