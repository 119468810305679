import React, { FC, forwardRef, useRef, useEffect } from 'react';
import type { GDSBaseSelectableProps } from '../../types';
import { styled } from '../../../../theme';
import { useTheme } from '../../../../hooks/use-theme';
import {
  SelectableLoader,
  SelectableLoaderWrapper,
} from '../selectable-loader';
import { GenesisCoreInspector } from '../../../../test-utils/genesis-core-inspector';

const getCheckmarkUri = (color: string) =>
  `"data:image/svg+xml;charset=UTF-8,%3csvg id='interfaceCheckmark' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='${encodeURIComponent(
    color,
  )}' fill-rule='evenodd' d='M19.507 6.448a.75.75 0 0 1 .045 1.06l-9.187 10a.75.75 0 0 1-1.105 0l-4.812-5.239a.75.75 0 1 1 1.104-1.014l4.26 4.636 8.636-9.398a.75.75 0 0 1 1.06-.045Z' clip-rule='evenodd'/%3e%3c/svg%3e"`;

const getDashUri = (color: string) =>
  `"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 2'%3e%3cpath fill='${encodeURIComponent(
    color,
  )}' fill-rule='evenodd' d='M0 .75A.75.75 0 0 1 .75 0h14.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 .75Z' clip-rule='evenodd'/%3e%3c/svg%3e"`;

const disabledStyles = {
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$inputBorderDisabled',
  backgroundColor: '$inputBackgroundDisabled',
};

const CheckboxBase = styled('input', {
  appearance: 'none',
  margin: 0,
  height: '$oneAndHalf',
  width: '$oneAndHalf',
  minWidth: '$oneAndHalf',
  backgroundColor: '$interactiveIconDefault',
  borderColor: '$onSurfaceBorderDefault',
  borderStyle: 'solid',
  borderWidth: '$thin',
  borderRadius: '$medium',
  transition:
    'background $defaultTime ease-in-out, border $defaultTime ease-in-out',
  '&:checked, &:indeterminate': {
    borderColor: '$interactiveActionPrimary',
    backgroundColor: '$interactiveActionPrimary',
    '&:before': {
      content: '',
      height: '100%',
    },
  },
  '&:indeterminate': {
    paddingInlineStart: '3px',
    paddingInlineEnd: '3px',
  },
  '&:disabled,&[aria-disabled="true"]': {
    ...disabledStyles,
    pointerEvents: 'none',
  },
  ' &[aria-readonly="true"]': disabledStyles,
  '&:checked ~ .GDS-selectable-loader, &:indeterminate ~ .GDS-selectable-loader':
    {
      borderColor: '$interactiveActionPrimary',
      backgroundColor: '$interactiveActionPrimary',
      '& svg': {
        color: '$onSurfaceTextReversed',
      },
    },
  variants: {
    inputStatus: {
      error: {
        borderColor: '$inputBorderCritical',
        '&:checked:not([disabled]), &:indeterminate:not([disabled])': {
          backgroundColor: '$inputBorderCritical',
          borderColor: '$inputBorderCritical',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderCritical',
          '& svg': {
            color: '$inputBorderCritical',
          },
        },
        '&:checked ~ .GDS-selectable-loader, &:indeterminate ~ .GDS-selectable-loader':
          {
            borderColor: '$inputBorderCritical',
            backgroundColor: '$inputBorderCritical',
            '& svg': {
              color: '$onSurfaceTextReversed',
            },
          },
      },
      warning: {
        borderColor: '$inputBorderWarning',
        '&:checked:not([disabled]), &:indeterminate:not([disabled])': {
          backgroundColor: '$inputBorderWarning',
          borderColor: '$inputBorderWarning',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderWarning',
          '& svg': {
            color: '$inputBorderWarning',
          },
        },
        '&:checked ~ .GDS-selectable-loader, &:indeterminate ~ .GDS-selectable-loader':
          {
            borderColor: '$inputBorderWarning',
            backgroundColor: '$inputBorderWarning',
            '& svg': {
              color: '$onSurfaceTextReversed',
            },
          },
      },
      success: {
        borderColor: '$inputBorderSuccess',
        '&:checked:not([disabled]), &:indeterminate:not([disabled])': {
          borderColor: '$inputBorderSuccess',
          backgroundColor: '$inputBorderSuccess',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderSuccess',
          '& svg': {
            color: '$inputBorderSuccess',
          },
        },
        '&:checked ~ .GDS-selectable-loader, &:indeterminate ~ .GDS-selectable-loader':
          {
            borderColor: '$inputBorderSuccess',
            backgroundColor: '$inputBorderSuccess',
            '& svg': {
              color: '$onSurfaceTextReversed',
            },
          },
      },
    },
  },
});

export interface GDSCheckboxIndicatorProps extends GDSBaseSelectableProps {
  indeterminate?: boolean;
}

export const CheckboxIndicator: FC<GDSCheckboxIndicatorProps> = forwardRef(
  (
    {
      id,
      name,
      indeterminate = false,
      inputStatus,
      loading,
      value,
      checked,
      disabled,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      'aria-readonly': ariaReadonly,
      onClick,
      onChange,
      onBlur,
      onFocus,
      className = '',
      css,
      ...props
    }: GDSCheckboxIndicatorProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    // Set indeterminate based on prop
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }
    }, [inputRef, indeterminate]);

    const theme = useTheme();

    return (
      <GenesisCoreInspector displayName="CheckboxIndicator">
        <SelectableLoaderWrapper>
          <CheckboxBase
            type="checkbox"
            id={id}
            value={value}
            name={name}
            checked={checked}
            className={['GDS-checkbox-input', className].join(' ')}
            inputStatus={inputStatus}
            {...(inputStatus === 'error' && { 'aria-invalid': true })}
            disabled={disabled}
            aria-disabled={ariaDisabled}
            aria-describedby={ariaDescribedby}
            aria-readonly={ariaReadonly}
            onClick={onClick}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref || inputRef}
            css={{
              ...css,
              '&:checked': {
                '&:before': {
                  content: `url(${getCheckmarkUri(
                    theme.colors.interactiveIconDefault.value,
                  )})`,
                },
              },
              '&:checked:disabled, &:checked[aria-disabled="true"]': {
                '&:before': {
                  content: `url(${getCheckmarkUri(
                    theme.colors.onSurfaceTextSubdued.value,
                  )})`,
                },
              },
              '&:checked[aria-readonly="true"]': {
                '&:before': {
                  content: `url(${getCheckmarkUri(
                    theme.colors.onSurfaceTextPrimary.value,
                  )})`,
                },
              },
              '&:indeterminate': {
                '&:before': {
                  content: `url(${getDashUri(
                    theme.colors.interactiveIconDefault.value,
                  )})`,
                },
              },
              '&:indeterminate:disabled, &:indeterminate[aria-disabled="true"]':
                {
                  '&:before': {
                    content: `url(${getDashUri(
                      theme.colors.onSurfaceTextSubdued.value,
                    )})`,
                  },
                },
              '&:indeterminate[aria-readonly="true"]': {
                '&:before': {
                  content: `url(${getDashUri(
                    theme.colors.onSurfaceTextPrimary.value,
                  )})`,
                },
              },
            }}
            {...props}
          />
          {loading && (
            <SelectableLoader inputType="checkbox" loading={loading} />
          )}
        </SelectableLoaderWrapper>
      </GenesisCoreInspector>
    );
  },
);

CheckboxIndicator.displayName = 'CheckboxIndicator';
