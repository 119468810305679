import React, { FC, useMemo } from 'react';

// Common Dependencies
import { Box, Flex, css } from '@leagueplatform/genesis-commons';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';

// Constants
const EASING = Object.freeze({
  IN_OUT_QUAD: `cubic-bezier(0.45, 0, 0.55, 1)`,
});

const baseDurationMs = 150;

const TRANSITION_DURATION_MS = Object.freeze({
  SHORT: baseDurationMs,
  MEDIUM: baseDurationMs * 2,
  LONG: baseDurationMs * 3,
  XLONG: baseDurationMs * 4,
});

const { MEDIUM, XLONG } = TRANSITION_DURATION_MS;
const { IN_OUT_QUAD } = EASING;

const TRANSITIONS = Object.freeze({
  PAGE: `${XLONG}ms ${IN_OUT_QUAD}`,
  HALF_PAGE: `${MEDIUM}ms ${IN_OUT_QUAD}`,
});

// Types
interface ActivitySlideProps {
  gap?: string;
  slideIndex: number;
  activeIndex: number;
}

// Styles
const slideTransition = `transform ${TRANSITIONS.PAGE}, opacity ${TRANSITIONS.PAGE}`;
const reducedMotionSlideTransition = `opacity ${TRANSITIONS.PAGE}`;
const reducedMotionSlideStyles = css({
  left: 0,
  transform: 'none',
  transition: reducedMotionSlideTransition,
});

/**
 * This hook determines each slide's transitionable styles based on the users preference for reduced motion
 * @returns `css()` styles
 */
const useSlideTransitionStyles = ({
  slideIndex,
  gap,
  activeIndex,
}: ActivitySlideProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const slideStyles = css({
    left: `calc(${slideIndex} * ${gap})`,
    transform: `translateX(calc(-${gap} * ${activeIndex}))`,
    transition: slideTransition,
  });

  return useMemo(
    () => (prefersReducedMotion ? reducedMotionSlideStyles : slideStyles),
    [prefersReducedMotion, slideStyles],
  );
};

export const Slide: FC<ActivitySlideProps> = ({
  children,
  gap = '100%',
  slideIndex,
  activeIndex,
}) => {
  const slideTransitionStyles = useSlideTransitionStyles({
    gap,
    slideIndex,
    activeIndex,
  });
  return (
    <Box
      aria-hidden={activeIndex !== slideIndex}
      css={slideTransitionStyles}
      height="100%"
      opacity={Number(activeIndex === slideIndex)}
      position="absolute"
      top={0}
      display="flex"
      width="100%"
    >
      {children}
    </Box>
  );
};

export const HealthActivitySlider: FC<{}> = ({ children }) => (
  <Flex height="100%" width="100%" position="relative">
    {children}
  </Flex>
);
