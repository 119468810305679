import React from 'react';
import type { WidgetDataV2ForCustomComponent } from '@leagueplatform/masonry-api';
import { useGetCustomWidgetModuleFromConfig } from 'hooks/use-get-custom-widget-module-from-config.hook';
import { getCustomWidgetComponent } from 'utils/custom-module-utils';
import { useGetConfig } from 'hooks/use-get-config.hook';

export interface CustomWidgetWithWidgetDataProps {
  // Using <any> for attributes type because it does not have a fixed type for custom components
  data: WidgetDataV2ForCustomComponent<any>;
}

export const CustomWidgetWithWidgetData = ({
  data,
}: CustomWidgetWithWidgetDataProps) => {
  const config = useGetConfig();
  const moduleProvider = useGetCustomWidgetModuleFromConfig({
    moduleKey: data.module,
  });

  const CustomWidgetComponent = getCustomWidgetComponent(
    moduleProvider,
    data.id,
    config?.appId,
    data.type,
  );

  if (!CustomWidgetComponent) return null;

  return <CustomWidgetComponent widget={data} />;
};
