import { leagueFetch } from '@leagueplatform/league-fetch';
import { captureError } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import { CARE_TEAM_PATH } from '../constants/care-team.constants';
import { CareTeamData } from '../types/care-team.types';

export const getCareTeam = async (): Promise<CareTeamData> => {
  const requestPath = `${CARE_TEAM_PATH}`;
  const response = await leagueFetch(requestPath);

  if (response.ok) {
    const clonedResponse = await response.clone().json();
    // Capture error if API returns zero participants or bad data
    if (!clonedResponse.data?.attributes?.participant?.length) {
      captureError(
        new Error('API getCareTeam: missing participants or bad data'),
        {
          tags: { requestUrl: response.url },
          context: {
            'Response Info': {
              response: JSON.stringify(clonedResponse),
            },
          },
        },
      );
    }
  }

  return deserialise(response.json());
};
