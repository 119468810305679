import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { Flex, Box, VisuallyHidden } from '@leagueplatform/genesis-commons';
import { Select, styled, TextInput } from '@leagueplatform/genesis-core';

const DateTextInput = styled(TextInput, {
  height: '$three',
  width: 'inherit',
  fontSize: '$bodyOne',
});

export const DateInputPresenter = ({
  day,
  onChangeDay,
  month,
  onChangeMonth,
  year,
  onChangeYear,
  onBlur,
  disabled,
  hasError,
  id,
  name,
  dayInputRef,
  monthInputRef,
  yearInputRef,
  errorMessageId,
  autoComplete,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const months = useMemo(
    () =>
      Array(12)
        .fill()
        .map((_, m) => ({
          label: formatDate(new Date(0, m, 10), {
            month: 'long',
          }),
          value: String(m),
        })),
    [formatDate],
  );

  const monthLabel = formatMessage({ id: 'MONTH' });
  const dayLabel = formatMessage({ id: 'DAY' });
  const yearLabel = formatMessage({ id: 'YEAR' });

  const getMonthAriaLabel = (monthValue) =>
    months?.find((option) => option.value === monthValue)?.label;

  return (
    <Flex onBlur={onBlur} id={id} name={name}>
      <Box width="50%">
        <Select
          id={`${name || id}_month`}
          options={months}
          onChange={onChangeMonth}
          onSubmit={() => {}}
          disabled={disabled}
          width={1}
          aria-required="true"
          placeholder={monthLabel}
          value={month}
          error={hasError}
          ref={monthInputRef}
          aria-labelledby={`${name || id}_month_label`}
          aria-describedby={hasError ? errorMessageId : undefined}
          inputStatus={hasError ? 'error' : ''}
          autoComplete={autoComplete}
          css={{
            '.GDS-select-menu': {
              height: '$three',
              fontSize: '$bodyOne',
            },
          }}
        />
        <VisuallyHidden id={`${name || id}_month_label`}>
          {month ? getMonthAriaLabel(month) : monthLabel}
        </VisuallyHidden>
      </Box>
      <Box marginLeft="half" width="20%">
        <DateTextInput
          fit
          id={`${name || id}_day`}
          type="text"
          placeholder={dayLabel}
          value={day}
          onChange={onChangeDay}
          disabled={disabled}
          aria-required="true"
          aria-label={dayLabel}
          ref={dayInputRef}
          error={hasError}
          aria-describedby={hasError ? errorMessageId : undefined}
          inputStatus={hasError ? 'error' : ''}
          autoComplete={autoComplete}
        />
      </Box>
      <Box marginLeft="half" width="30%">
        <DateTextInput
          fit
          id={`${name || id}_year`}
          type="text"
          placeholder={yearLabel}
          value={year}
          onChange={onChangeYear}
          disabled={disabled}
          aria-required="true"
          aria-label={yearLabel}
          ref={yearInputRef}
          error={hasError}
          aria-describedby={hasError ? errorMessageId : undefined}
          inputStatus={hasError ? 'error' : ''}
          autoComplete={autoComplete}
        />
      </Box>
    </Flex>
  );
};

const refPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current:
      typeof Element !== 'undefined'
        ? PropTypes.instanceOf(Element)
        : PropTypes.any,
  }),
]);

DateInputPresenter.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  onChangeDay: PropTypes.func,
  onChangeMonth: PropTypes.func,
  onChangeYear: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  dayInputRef: refPropType,
  monthInputRef: refPropType,
  yearInputRef: refPropType,
  errorMessageId: PropTypes.string,
  autoComplete: PropTypes.string,
};
DateInputPresenter.defaultProps = {
  day: '',
  month: '',
  year: '',
  autoComplete: 'off',
  onChangeDay: () => {},
  onChangeMonth: () => {},
  onChangeYear: () => {},
  onBlur: () => {},
  disabled: false,
  hasError: false,
  id: null,
  name: null,
  dayInputRef: null,
  monthInputRef: null,
  yearInputRef: null,
  errorMessageId: undefined,
};
