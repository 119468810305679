import Lightbulb from 'assets/example-assets/light-bulb.svg';
import { loadTheme } from '../load-theme';
// eslint-disable-next-line import/extensions
import { archetypeLightTheme } from './archetype-light-theme';
// eslint-disable-next-line import/extensions
import { archetypeDarkTheme } from './archetype-dark-theme';
import { leagueTheme } from './league-b2b-theme';

const options = {
  customIcons: { customLightbulb: Lightbulb },
  customStrings: {
    loadingText: 'Custom loading text',
  },
  themeName: 'wireframe',
};

export const storybookArchetypeLightTheme = loadTheme(archetypeLightTheme, {
  ...options,
  themeName: 'archetype-light',
});
export const storybookArchetypeDarkTheme = loadTheme(archetypeDarkTheme, {
  ...options,
  themeName: 'archetype-dark',
});
export const storybookLeagueTheme = loadTheme(leagueTheme, {
  ...options,
  themeName: 'league',
});
