import { useState } from 'react';
import { useParams } from '@leagueplatform/routing';
import camelcaseKeys from 'camelcase-keys';
import { useConfigProperty } from '@leagueplatform/core';

import { createNavigation } from 'utils/create-navigation.utils';
import { sanitizeQuestions } from 'utils/sanitize-questions.utils';
import { createUserSelections } from 'utils/create-user-selections.utils';
import { updateUserSelections } from 'utils/update-user-selections.utils';
import { getCurrentTopic } from 'utils/get-current-topic.utils';
import { getCurrentIndex } from 'utils/get-current-index.utils';
import { getCurrentModuleQuestionNumber } from 'utils/get-current-module-question-number.utils';
import { getUrlToRedirectOnExit } from 'utils/get-url-to-redirect-on-exit.utils';
import { createModuleTopics } from 'utils/create-module-topics.utils';

import { useGetHealthProfileModule } from './use-get-health-profile-module.hook';
import { useCompleteHealthProfileQuestion } from './use-complete-health-profile-question.hook';

export const useHealthAssessment = () => {
  const { moduleId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isResuming, setIsResuming] = useState(null);
  const [userSelections, setCurrentUserSelections] = useState(null);
  const [moduleNavigation, setModuleNavigation] = useState(null);
  const pointsEnabled = useConfigProperty('core.ui.features.pointsEnabled');

  const { healthProfileModule, isError, isLoading } =
    useGetHealthProfileModule(moduleId);

  const questions = healthProfileModule?.questions || [];
  const topics = healthProfileModule?.topics || [];
  const moduleTopics = createModuleTopics({ topics, questions });
  const currentQuestionIndex = healthProfileModule?.currentQuestionIndex;
  const healthAssessmentIsEditing =
    healthProfileModule?.healthAssessmentState !== 'incomplete';

  const defaultModuleNavigation =
    createNavigation(
      sanitizeQuestions(questions),
      healthProfileModule?.hideTopics,
      healthProfileModule?.introScreen,
    ) || [];

  const currentModuleNavigation = moduleNavigation ?? defaultModuleNavigation;

  const defaultUserSelections = createUserSelections(
    sanitizeQuestions(questions),
  );

  const moduleQuestions = currentModuleNavigation.filter(
    (item) => item.type === 'question',
  );
  const moduleQuestionsLength = moduleQuestions.length;
  const defaultIsResuming =
    currentQuestionIndex > 0 && currentQuestionIndex < moduleQuestionsLength;

  const defaultCurrentIndex = getCurrentIndex({
    currentQuestionIndex,
    questions,
    moduleNavigation: currentModuleNavigation,
  });

  const moduleCurrentIndexId = currentModuleNavigation[defaultCurrentIndex]?.id;

  const currentModuleQuestionNumber = getCurrentModuleQuestionNumber({
    moduleQuestions,
    moduleCurrentIndexId,
  });

  const urlToRedirectOnExit = getUrlToRedirectOnExit(
    healthProfileModule?.redirectUrl,
  );

  const completeHealthProfileQuestion = useCompleteHealthProfileQuestion({
    setCurrentIndex,
    currentIndex: currentIndex ?? defaultCurrentIndex,
    moduleNavigation: currentModuleNavigation,
    moduleId,
    points: healthAssessmentIsEditing
      ? 0
      : healthProfileModule?.completionPoints,
    type: healthProfileModule?.type,
    successScreen: healthProfileModule?.successScreen,
    redirectUrl: healthProfileModule?.redirectUrl,
    outcomesEnabled: healthProfileModule?.outcomesEnabled,
    onSuccess: (rawData) => {
      const data = camelcaseKeys(rawData, { deep: true });
      const { userAnswers } = data.userQuestion;
      const questionId = data.userQuestion.id;

      const updatedModuleNavigator = currentModuleNavigation.map((page) => ({
        ...page,
        userAnswers: page.id === questionId ? userAnswers : page.userAnswers,
      }));

      setModuleNavigation(updatedModuleNavigator);
    },
    onError: (data) => {
      const {
        info: {
          message: { info },
          reason,
        },
      } = data;

      const error = {
        hasError: true,
        errorMsg: reason,
      };

      setCurrentUserSelections(
        updateUserSelections({
          userSelections,
          questionId: info.question_id,
          // userSelection should already be updated with new answer
          answer: undefined,
          error,
        }),
      );
    },
  });

  const { isLoading: isLoadingCompleteQuestion } =
    completeHealthProfileQuestion;

  return {
    isLoading,
    isError,
    isResuming: isResuming ?? defaultIsResuming,
    moduleNavigation: moduleNavigation ?? defaultModuleNavigation,
    currentIndex: currentIndex ?? defaultCurrentIndex,
    moduleQuestionsLength,
    currentModuleQuestionNumber,
    currentTopic: getCurrentTopic({
      moduleNavigation: moduleNavigation ?? defaultModuleNavigation,
      topics: moduleTopics,
      currentIndex: currentIndex ?? defaultCurrentIndex,
    }),
    userSelections: userSelections ?? defaultUserSelections,
    topics: moduleTopics,
    onboardingPages: healthProfileModule?.onboardingPages,
    completionPoints: healthProfileModule?.completionPoints,
    type: healthProfileModule?.type,
    exitScreen: healthProfileModule?.exitScreen,
    healthAssessmentIsEditing,
    introScreen: healthProfileModule?.introScreen,
    urlToRedirectOnExit,
    setCurrentIndex,
    setIsResuming,
    setCurrentUserSelections,
    completeQuestion: (payload) => {
      completeHealthProfileQuestion.mutate(payload);
    },
    isLoadingCompleteQuestion,
    pointsEnabled,
  };
};
