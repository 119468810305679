const BE_SYSTEM_TYPES = {
  CoverageOverview: 'coverage_overview',
  CoverageCategories: 'coverage_categories',
  UsageInstructions: 'usage_instructions',
  ViewDependent: 'view_dependent',
  TransactionHistory: 'transaction_history',
  CardInfo: 'card_info',
  VisitUrl: 'visit_url',
  CallPhone: 'call_phone',
  BeneficiaryForm: 'beneficiary_form',
  BenefitDocument: 'benefit_document',
  SaveCard: 'save_card',
  SubmitClaim: 'submit_claim',
  BrowseCategory: 'browse_category',
  ContactInfo: 'contact_info',
  ViewProviderServices: 'view_provider_services', // US only
  UnavailableAction: 'unavailable_action',
  ModalContent: 'modal_content',
  PrintCard: 'print_card',
  OrderCard: 'order_card',
} as const;

// the following `system_type`s are special cases that are
// currently 'simulated' by the FE as they don't exist (yet) in the BE
const FE_SYSTEM_TYPES = {
  UsageOverview: 'usage_overview',
  LeagueCredit: 'league_credit',
  About: 'about',
  PlanProgressCoverageOverview: 'combined_plan_progress_coverage',
} as const;

export const SYSTEM_TYPES = { ...FE_SYSTEM_TYPES, ...BE_SYSTEM_TYPES } as const;
// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export type SYSTEM_TYPE = (typeof SYSTEM_TYPES)[keyof typeof SYSTEM_TYPES];

type ValueOf<T extends readonly string[]> = T[number];

export const TAB_SYSTEM_TYPES = [
  SYSTEM_TYPES.CoverageOverview,
  SYSTEM_TYPES.CoverageCategories,
  SYSTEM_TYPES.ViewDependent,
  SYSTEM_TYPES.TransactionHistory,
  SYSTEM_TYPES.LeagueCredit,
  SYSTEM_TYPES.ViewProviderServices,
] as const;

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export type TAB_SYSTEM_TYPE = ValueOf<typeof TAB_SYSTEM_TYPES>;
