import * as React from 'react';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { Box, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { getValueUnitDisplayLabel } from 'utils/get-value-unit-display-label';
import { ChartTooltip } from '../chart-tooltip';
import { HealthMetricDataV3 } from '../../range-graph/utils/use-health-metric-values';

type SingleMeasurementTooltipV3Props = {
  datum: HealthMetricDataV3[number];
};

export function SingleMeasurementTooltipV3({
  datum,
}: SingleMeasurementTooltipV3Props) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  return (
    <ChartTooltip>
      <StackLayout spacing="$quarter">
        <UtilityText
          size="xs"
          emphasis="subtle"
          css={{ typography: '$overline' }}
        >
          {datum.metric.data.name}
        </UtilityText>
        <StackLayout orientation="horizontal" spacing="$half">
          {getValueUnitDisplayLabel({
            value: datum.value,
            label: datum.label,
            unitOptions: datum.metric.data.unit,
          }).map(([displayValue, displayUnit], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`${index} + ${datum.type}`}>
              <UtilityText as="span" css={{ typography: '$headingOne' }}>
                {displayValue}
              </UtilityText>{' '}
              <UtilityText emphasis="subtle" css={{ typography: '$bodyOne' }}>
                {displayUnit}
              </UtilityText>
            </Box>
          ))}
        </StackLayout>
        <UtilityText size="eyebrow">
          {formatDateWithUserProfileLocation(datum.timestamp, DATE_FORMAT)}
        </UtilityText>
      </StackLayout>
    </ChartTooltip>
  );
}
