/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Portal } from '@leagueplatform/genesis-core';

export const ROOT_NODE_ID = 'root';
export const PORTAL_NODE_IDS = {
  MODAL_PORTAL: 'modal-portal',
};

/* It automatically sets a div element for the Modal Component when it renders. */
export const createModalPortalElement = (modalPortalId) => {
  const modalPortalElement = document.createElement('div');
  modalPortalElement.setAttribute('id', modalPortalId);

  return document.body.appendChild(modalPortalElement);
};

export const ModalPortal = ({
  rootId = ROOT_NODE_ID,
  modalPortalId = PORTAL_NODE_IDS.MODAL_PORTAL,
  children,
}) => {
  const rootEl = document.getElementById(rootId);
  let modalPortalEl = document.getElementById(modalPortalId);

  if (!modalPortalEl) {
    modalPortalEl = createModalPortalElement(modalPortalId);
  }

  useEffect(() => {
    rootEl?.setAttribute('aria-hidden', 'true');

    return () => rootEl?.removeAttribute('aria-hidden');
  }, [rootEl]);

  return <Portal container={modalPortalEl}>{children}</Portal>;
};
