import React, { FC } from 'react';
import { SkeletonBase } from './skeleton-base';
import type { GDSSkeletonBaseProps } from './skeleton-base';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

const ASPECT_RATIOS = {
  square: 1 / 1,
  standard: 4 / 3,
  tall: 3 / 4,
  wide: 16 / 9,
  panoramic: 11 / 4,
};

// The syntax to retain autocomplete of GDSAspectRatio but also allow for path strings
// https://github.com/microsoft/TypeScript/issues/29729#issuecomment-460346421
type AspectRatioValue = (string | number) & { what?: any };
type GDSAspectRatioToken = keyof typeof ASPECT_RATIOS;
type GDSAspectRatio = GDSAspectRatioToken | AspectRatioValue;

export const isGDSRatioToken = (
  ratioToken: GDSAspectRatio,
): ratioToken is GDSAspectRatioToken => ratioToken in ASPECT_RATIOS;

const getAspectRatio = (aspectRatio: GDSAspectRatio) => {
  if (isGDSRatioToken(aspectRatio)) return ASPECT_RATIOS[aspectRatio];
  return aspectRatio;
};

export interface GDSSkeletonRectangleProps extends GDSSkeletonBaseProps {
  aspectRatio?: GDSAspectRatio;
}

export const SkeletonRectangle: FC<GDSSkeletonRectangleProps> = ({
  aspectRatio = 'square',
  borderRadius,
  height,
  width,
  css,
  className,
  ...props
}) => (
  <GenesisCoreInspector displayName="SkeletonRectangle">
    <SkeletonBase
      className={['GDS-skeleton-rectangle', className].join(' ')}
      height={height}
      width={width}
      borderRadius={borderRadius}
      css={{
        ...css,
        'aspect-ratio': getAspectRatio(aspectRatio),
      }}
      {...props}
    />
  </GenesisCoreInspector>
);

SkeletonRectangle.displayName = 'SkeletonRectangle';
