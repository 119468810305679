import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ADMIN_GET_EMPLOYEE_MESSAGE_TYPE = 'admin_get_employee';

interface EmployeeSchema {
  groupId: string;
  userId: string;
}

export const adminGetEmployee = ({ groupId, userId }: EmployeeSchema) =>
  SocketAsFetch.fetch({
    message_type: ADMIN_GET_EMPLOYEE_MESSAGE_TYPE,
    info: {
      group_id: groupId,
      user_id: userId,
      version: 2,
    },
  });
