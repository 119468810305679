import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

export const Money = ({ value, currency, withCents }) => (
  <FormattedNumber
    value={value}
    currency={currency}
    minimumFractionDigits={withCents ? 2 : 0}
    style="currency" // eslint-disable-line react/style-prop-object
    currencyDisplay="symbol"
  />
);

Money.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  withCents: PropTypes.bool,
};

Money.defaultProps = {
  withCents: false,
};
