/* eslint-disable react/destructuring-assignment, react/prop-types, react/require-default-props */
/*
  Writing tests are in progress for this util, please refrain from using
  until they're done
*/

/*
  extended version of https://github.com/rafrex/react-router-hash-link
  to allow for scrolling to hashes without a link scrollToHash()
*/

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@leagueplatform/routing';

let hashFragment = '';
let observer = null;
let asyncTimerId = null;
let scrollFunction = null;

function reset() {
  hashFragment = '';
  if (observer !== null) observer.disconnect();
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

function getElAndScroll() {
  const element = document.getElementById(hashFragment);
  if (element !== null) {
    scrollFunction(element);
    reset();
    return true;
  }
  return false;
}

function hashLinkScroll() {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }
      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      // if the element doesn't show up in 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, 10000);
    }
  }, 0);
}

export function HashLink(props) {
  const { onClick, to, scroll, smooth, ...otherProps } = props;
  const handleClick = useCallback(
    (e) => {
      reset();
      if (onClick) onClick(e);
      if (typeof to === 'string') {
        hashFragment = to.split('#').slice(1).join('#');
      } else if (typeof to === 'object' && typeof to.hash === 'string') {
        hashFragment = to.hash.replace('#', '');
      }
      if (hashFragment !== '') {
        scrollFunction =
          scroll ||
          ((el) =>
            smooth
              ? el.scrollIntoView({ behavior: 'smooth' })
              : el.scrollIntoView());
        hashLinkScroll();
      }
    },
    [onClick, to, scroll, smooth],
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link {...otherProps} onClick={handleClick} to={to}>
      {props.children}
    </Link>
  );
}

HashLink.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  scroll: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
