import { useUserActiveGroupQuery } from './use-user-active-group-query.hook';

export const usePlanIdQuery = (userId: string | undefined) => {
  const { data = { plan_enrollment: {} } } = useUserActiveGroupQuery(userId);
  const { plan_enrollment: planEnrollment } = data;
  // TS registers planId as only being a string, but it could return undefined.
  // To align the two types I am setting it to empty string by default
  const [planId = ''] = Object.keys(planEnrollment ?? {});

  return planId;
};
