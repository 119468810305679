import React, { forwardRef } from 'react';
import { BoxProps } from '../Box';
import { BackgroundImage } from '../BackgroundImage';

export interface CoverImageProps extends BoxProps {
  src: string;
  alt: string;
}

export const CoverImage: React.FunctionComponent<CoverImageProps> = forwardRef(
  (props, ref) => (
    <BackgroundImage display="inline-block" ref={ref} {...props} />
  )
);

CoverImage.displayName = 'CoverImage';
