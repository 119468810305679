import React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import { Modal } from '@leagueplatform/genesis-core';
import { SelectAvatar } from 'components/select-avatar/select-avatar.component';
import { CHALLENGE_TYPES } from '@leagueplatform/health-journey-api';
import { getFileFromImageUrl } from 'utils/get-file-from-image-url.util';
import { useUploadUserProfilePhoto } from 'hooks/use-upload-profile-photo.hooks';

export interface TeamPhotoModalProps {
  displayTeamPhotoModal: boolean;
  closeTeamPhotoModal(): void;
  setTeamPhotoUrl: React.Dispatch<React.SetStateAction<string>>;
  setTeamPhotoRefId: React.Dispatch<React.SetStateAction<string>>;
}

export const TeamPhotoModal = ({
  displayTeamPhotoModal,
  closeTeamPhotoModal,
  setTeamPhotoUrl,
  setTeamPhotoRefId,
}: TeamPhotoModalProps): JSX.Element => {
  const modalOnDismiss = () => {
    closeTeamPhotoModal();
  };
  const uploadTeamPhoto = useUploadUserProfilePhoto();
  const confirmTeamProfilePhoto = async (photoUrl: string) => {
    const file = await getFileFromImageUrl(photoUrl, 'teamPhoto');
    const teamPhotoId = await uploadTeamPhoto(file);
    setTeamPhotoUrl(photoUrl);
    setTeamPhotoRefId(teamPhotoId);
    modalOnDismiss();
  };
  return (
    <Modal.Root open={displayTeamPhotoModal} onOpenChange={modalOnDismiss}>
      <Box paddingX={{ tablet: 'one' }} paddingTop={{ tablet: 'one' }}>
        <Modal.Content width="66vw">
          <SelectAvatar
            challengeType={CHALLENGE_TYPES.TEAM}
            onSubmit={confirmTeamProfilePhoto}
            signUpIsLoading={false}
          />
        </Modal.Content>
      </Box>
    </Modal.Root>
  );
};
