/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import {
  HeadingText,
  ParagraphText,
  Checkbox,
  Fieldset,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  LoadingIndicator,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { useFormContext } from '@leagueplatform/web-common';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { useSelectMembersStep } from '../hooks/use-select-members-step.hook';
import { OrderNewCardFormValues } from '../types/order-new-card-form.types';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';

const MEMBER_HINT_ID = 'select-member-hint';

const { memberList } = orderNewCardFormInputNames;

export const SelectMembersStep = () => {
  const { memberOptions: members, isLoading } = useSelectMembersStep();
  const { register, formState } = useFormContext<OrderNewCardFormValues>();
  const { formatMessage } = useIntl();

  useWalletDetailScreenLoaded(
    BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SELECT_MEMBER_STEP,
  );

  const errorMessage = formState.errors.memberList?.message;

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  return (
    <Fieldset
      hint={
        <ParagraphText>
          {formatMessage({ id: 'ORDER_NEW_CARD_MEMBER_SELECT_HELP_TEXT' })}
        </ParagraphText>
      }
      id={MEMBER_HINT_ID}
      inputStatus={errorMessage ? 'error' : undefined}
      legend={
        <HeadingText size="lg" level="3">
          {formatMessage({ id: 'ORDER_NEW_CARD_MEMBER_SELECT_HEADING' })}
        </HeadingText>
      }
      required
      statusMessage={errorMessage}
    >
      <StackLayout space="$one" css={{ marginTop: '$one' }}>
        {members.map((member) => (
          <Checkbox
            key={member.value}
            id={member.value}
            label={member.label}
            value={member.value}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register(memberList, {
              required: formatMessage({
                id: 'WALLET_ORDER_NEW_CARD_SELECT_MEMBER_ERROR',
              }),
            })}
          />
        ))}
      </StackLayout>
    </Fieldset>
  );
};
