/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useParams } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { ErrorState, SkeletonBox } from '@leagueplatform/web-common-components';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { useActivitiesByActivityStatusAndCampaign } from 'hooks/use-activities-by-activity-status-and-campaign.hook';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { ChallengeActivitySectionHeader } from './components/challenge-activity-section-header';
import {
  ChallengeActivityList,
  ChallengeActivityListProps,
} from './components/challenge-activity-list';
import { ChallengeActivitySection } from './components/challenge-activity-section';
import {
  ChallengeCompletedActivitiesPendingStateCard,
  ChallengeCompletedCard,
  ChallengePendingStateCard,
  ChallengeUpNextActivitiesPendingStateCard,
} from './components/challenge-activities-empty-state-card';
import {
  isActiveActivity,
  isCompletedActivity,
  isMissedActivity,
  isUpcomingActivity,
} from './utils/activity-status-predicates';
import { sortActivitiesInReverseChronologicalOrder } from './utils/activity-date';
import { ChallengeActivityCard } from './components/challenge-activity-card';

type UpNextActivitiesProps = Omit<ChallengeActivityListProps, 'level'> & {
  challengeStatus: string;
};

const NUM_VISIBLE_ACTIVITIES = 3;
type UpNextSectionContentProps = {
  isChallengePending: boolean;
  isChallengeCompleted: boolean;
  firstUpNextActivity?: ConfigurableHealthActivityWSCC;
};
function UpNextSectionContent({
  isChallengePending,
  isChallengeCompleted,
  firstUpNextActivity,
}: UpNextSectionContentProps) {
  if (isChallengePending) {
    return <ChallengeUpNextActivitiesPendingStateCard />;
  }
  if (isChallengeCompleted) {
    return <ChallengeCompletedCard />;
  }
  if (firstUpNextActivity) {
    return <ChallengeActivityCard activity={firstUpNextActivity} level="3" />;
  }
  return null;
}
function UpNextActivities({
  challengeStatus,
  activities,
}: UpNextActivitiesProps) {
  const activeActivities = activities.filter(isActiveActivity);
  const upcomingActivities = activities.filter(isUpcomingActivity);
  const upNextActivities =
    activeActivities.length > 0 ? activeActivities : upcomingActivities;
  /*
   * Group challenges end on a specific date, not when the user has completed all challenges.
   * If the user has no up next activities we can safely assume that they have completed
   * the challenge from their perspective. There could be a rare edge case if an activity
   * is dynamically added to a challenge making the `hasChallengeEnded` flip back to false, but for now
   * that possibility has been accepted.
   */
  const hasUserExhaustedActivities = upNextActivities.length === 0;
  const isChallengePending = challengeStatus === CHALLENGE_STATUS.UPCOMING;
  const isChallengeActive = challengeStatus === CHALLENGE_STATUS.ACTIVE;
  const isChallengeClosed = challengeStatus === CHALLENGE_STATUS.CLOSED;
  const isChallengeCompleted =
    (hasUserExhaustedActivities && isChallengeActive) || isChallengeClosed;
  // currently BE and challenges are configured to have 1 activity per day, we can update this once this changes
  const firstUpNextActivity = upNextActivities[0];
  // If there are active activities but the challenge is closed, we show the challenge completed empty state.

  const { formatMessage } = useIntl();

  return (
    <ChallengeActivitySection>
      <ChallengeActivitySectionHeader
        heading={formatMessage({ id: 'CHALLENGE_ACTIVITY_UP_NEXT' })}
      />
      <UpNextSectionContent
        isChallengeCompleted={isChallengeCompleted}
        isChallengePending={isChallengePending}
        firstUpNextActivity={firstUpNextActivity}
      />
    </ChallengeActivitySection>
  );
}

type CompletedActivitiesProps = Omit<ChallengeActivityListProps, 'level'> & {
  challengeId: string;
};

function CompletedActivities({
  activities,
  challengeId,
}: CompletedActivitiesProps) {
  const { formatMessage } = useIntl();
  const hasActivities = activities.length > 0;

  return (
    <ChallengeActivitySection>
      <ChallengeActivitySectionHeader
        heading={formatMessage({ id: 'CHALLENGE_ACTIVITY_COMPLETED' })}
        href={
          hasActivities
            ? JOURNEY_ROUTES.getChallengeCompletedActivities(challengeId)
            : undefined
        }
      />
      {hasActivities ? (
        <ChallengeActivityList
          activities={sortActivitiesInReverseChronologicalOrder(
            activities,
          ).slice(0, NUM_VISIBLE_ACTIVITIES)}
          level="3"
        />
      ) : (
        <ChallengeCompletedActivitiesPendingStateCard />
      )}
    </ChallengeActivitySection>
  );
}

type MissedActivitiesProps = Omit<ChallengeActivityListProps, 'level'> & {
  challengeId: string;
};

function MissedActivities({ activities, challengeId }: MissedActivitiesProps) {
  const { formatMessage } = useIntl();
  const hasActivities = activities.length > 0;

  return (
    <ChallengeActivitySection>
      <ChallengeActivitySectionHeader
        heading={formatMessage({ id: 'CHALLENGE_ACTIVITY_MISSED' })}
        href={
          hasActivities
            ? JOURNEY_ROUTES.getChallengeMissedActivities(challengeId)
            : undefined
        }
      />
      {hasActivities ? (
        <ChallengeActivityList
          activities={sortActivitiesInReverseChronologicalOrder(
            activities,
          ).slice(0, NUM_VISIBLE_ACTIVITIES)}
          level="4"
        />
      ) : (
        <ChallengePendingStateCard
          message={formatMessage({
            id: 'CHALLENGE_ACTIVITY_MISSED_EMPTY_STATE',
          })}
        />
      )}
    </ChallengeActivitySection>
  );
}

type ChallengeActivitiesProps = {
  challengeStatus: string;
  userChallengeId?: string;
};

export function ChallengeActivities({
  challengeStatus,
  userChallengeId,
}: ChallengeActivitiesProps) {
  const { challengeId } = useParams<{ challengeId: string }>();
  const {
    data: activities,
    isLoading,
    isError,
  } = useActivitiesByActivityStatusAndCampaign({
    activityStatuses: ['active', 'upcoming', 'completed', 'expired', 'removed'],
    campaignId: userChallengeId,
  });

  if (isLoading) {
    return (
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height="200px"
        marginBottom="one"
      />
    );
  }

  if (isError) {
    return <ErrorState />;
  }

  const allActivities = activities?.userHealthActivities ?? [];
  const completedActivities = allActivities.filter(isCompletedActivity);
  const missedActivities = allActivities.filter(isMissedActivity);

  return (
    <StackLayout spacing="$twoAndHalf" css={{ width: '100%' }}>
      <UpNextActivities
        challengeStatus={challengeStatus}
        activities={allActivities}
      />
      <CompletedActivities
        activities={completedActivities}
        challengeId={challengeId}
      />
      <MissedActivities
        activities={missedActivities}
        challengeId={challengeId}
      />
    </StackLayout>
  );
}
