import React, { useState } from 'react';
import { Flex, HeadingFour, Spinner } from '@leagueplatform/genesis-commons';
import {
  TeamChallengeRank,
  UserChallengeRank,
} from '@leagueplatform/health-journey-api';
import { ErrorState } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  ParagraphText,
  Popover,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { LeaderboardTable } from 'components/leaderboard/leaderboard-table.component';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { LEADERBOARD_SPINNER_FIXED_HEIGHT } from 'constants/challenges.constants';
import { GlobalLeaderboardToggle } from './components/global-leaderboard-toggle.component';
import { LeaderboardPodium } from './components/leaderboard-podium.component';
import { useGetLeaderboardData } from './hooks/use-get-leaderboard-data.hook';

export interface LeaderboardProps {
  teamsEnabled: boolean;
}

const LeaderboardViews = ['individual', 'team'];

export const Leaderboard = ({
  teamsEnabled,
}: LeaderboardProps): JSX.Element => {
  const { formatMessage } = useIntl();
  // LeaderboardView State is referencing the tab index value from reach that's being used in the leaderboard podium component for the toggle: index 0 is for the individual view and 1 the team view
  const [leaderboardView, setLeaderboardView] = useState(0);

  const {
    isLoading,
    error,
    data: leaderboardData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetLeaderboardData(leaderboardView, teamsEnabled);

  const { isLoading: isUserLoading } = useGetUserProfile();
  const pages = leaderboardData?.pages;
  let flattenedLeaderboardData: (UserChallengeRank | TeamChallengeRank)[] = []; // Initialize with an empty array
  if (pages) {
    // this reduce method flattens the multiple pages of leaderboard data to be a flat array of pages
    // update pages type once we refractor useGetLeaderboardData in XPNC-4047
    flattenedLeaderboardData = (pages as (typeof pages)[number][]).reduce<
      (UserChallengeRank | TeamChallengeRank)[]
    >((acc, page) => [...acc, ...page.data], []);
  }

  if (isLoading || isUserLoading)
    return (
      <GlobalLeaderboardToggle
        teamsEnabled={teamsEnabled}
        leaderboardViews={LeaderboardViews}
        setLeaderboardView={setLeaderboardView}
        isLoadingOrError={isLoading || !!error}
      >
        <Flex
          height={LEADERBOARD_SPINNER_FIXED_HEIGHT}
          justifyContent="center"
          color="decorative.brand.primary.default"
        >
          <Spinner isLoading />
        </Flex>
      </GlobalLeaderboardToggle>
    );

  if (
    error ||
    !flattenedLeaderboardData ||
    flattenedLeaderboardData?.length === 0
  )
    return (
      <GlobalLeaderboardToggle
        teamsEnabled={teamsEnabled}
        leaderboardViews={LeaderboardViews}
        setLeaderboardView={setLeaderboardView}
        isLoadingOrError={isLoading || !!error}
      >
        <Flex justifyContent="center" marginY="five">
          <ErrorState />
        </Flex>
      </GlobalLeaderboardToggle>
    );

  const { tooltipText, headerText } = leaderboardData?.pages[0].meta ?? {};

  return (
    <>
      <GlobalLeaderboardToggle
        teamsEnabled={teamsEnabled}
        leaderboardViews={LeaderboardViews}
        setLeaderboardView={setLeaderboardView}
        isLoadingOrError={isLoading || !!error}
      >
        <LeaderboardPodium
          leaderboardData={flattenedLeaderboardData}
          headerText={headerText ?? ''}
        />
      </GlobalLeaderboardToggle>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        css={{ padding: '$half' }}
      >
        <HeadingFour as="h2">
          {formatMessage({ id: 'LEADERBOARD_ALL_TIME_RANKING' })}
        </HeadingFour>
        <Popover.Root>
          <Popover.Trigger
            label={formatMessage({ id: 'TOOLTIP_ACTION_DESCRIPTION' })}
            priority="tertiary"
            size="small"
            quiet
            hideLabel
            icon="interfaceQuestionCircle"
            css={{
              '&:hover': {
                backgroundColor: '$onSurfaceBackgroundPrimary',
                color: '$onSurfaceIconPrimary',
              },
            }}
          />
          <Popover.Content align="end" side="top" css={{ width: '250px' }}>
            <ParagraphText size="sm">{tooltipText}</ParagraphText>
          </Popover.Content>
        </Popover.Root>
      </StackLayout>
      <LeaderboardTable
        leaderboardData={flattenedLeaderboardData}
        displayViewMore={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        leaderboardView={leaderboardView}
      />
    </>
  );
};
