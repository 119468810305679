import { useState } from 'react';
import { useUserSettingsQuery } from 'hooks/queries/use-user-settings-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import {
  Dependent,
  SYSTEM_TYPES,
  BenefitBanners,
  BenefitCardAction,
} from '@leagueplatform/wallet-api';
import { flattenActionList } from 'utils/flatten-actions-list.util';
import { useWalletDetailQuery } from '../../../hooks/queries/use-wallet-detail-query.hook';

export interface UseDependentsReturn {
  showDependentsMenuItem: boolean;
  onViewDependentCardClick: (dependent: Dependent) => void;
  currentDependent: Dependent | null;
  benefitId: string;
  onViewDependentCardClose: () => void;
  dependents?: Dependent[];
  banners?: BenefitBanners;
}

export const useDependents = (): UseDependentsReturn => {
  const { userId, benefitId = '' } = useWalletParams();
  const { data: dependents } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: (data) => data.dependents,
    },
  );
  const { data: banners } = useWalletDetailQuery(userId, benefitId, undefined, {
    select: (data) => {
      const actions = flattenActionList(data.categorized_actions, data.actions);

      return actions.find(
        (a: BenefitCardAction) => a.system_type === SYSTEM_TYPES.ViewDependent,
      )?.banners;
    },
  });

  const [currentDependent, setCurrentDependent] = useState<Dependent | null>(
    null,
  );
  const { data: showDependentsMenuItem = false } = useUserSettingsQuery(
    (data) => data.show_dependents_menu_item,
  );

  const onViewDependentCardClick = (dependent: Dependent) => {
    setCurrentDependent(dependent);
  };

  const onViewDependentCardClose = () => {
    setCurrentDependent(null);
  };

  return {
    showDependentsMenuItem,
    onViewDependentCardClick,
    benefitId,
    currentDependent,
    onViewDependentCardClose,
    dependents,
    banners,
  };
};
