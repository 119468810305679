import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  PLATFORM,
  QUICK_ACTIONS_PATH,
} from '../constants/care-discovery.constants';
import { QuickActionsData } from '../types/care-discovery.types';

export const getQuickActions = async (): Promise<QuickActionsData> => {
  const queryString = new URLSearchParams({
    platform: PLATFORM,
  });
  const requestPath = `${QUICK_ACTIONS_PATH}?${queryString}`;
  const response = await leagueFetch(requestPath);

  return response.json();
};
