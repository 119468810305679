import React from 'react';

export const AddDocumentIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9048 9.16797C10.8105 9.16797 10.7114 9.20809 10.632 9.29394C10.5513 9.38109 10.5 9.50705 10.5 9.64575V22.3569C10.5 22.4956 10.5513 22.6215 10.632 22.7087C10.7114 22.7945 10.8105 22.8346 10.9048 22.8346H21.7619C21.8562 22.8346 21.9552 22.7945 22.0347 22.7087C22.1153 22.6215 22.1667 22.4956 22.1667 22.3569V10.0013C22.1667 9.54106 21.7936 9.16797 21.3333 9.16797H10.9048ZM9.89801 8.61477C10.1579 8.33388 10.5191 8.16797 10.9048 8.16797H21.3333C22.3459 8.16797 23.1667 8.98878 23.1667 10.0013V22.3569C23.1667 22.7368 23.0274 23.1083 22.7687 23.3878C22.5087 23.6687 22.1475 23.8346 21.7619 23.8346H10.9048C10.5191 23.8346 10.1579 23.6687 9.89801 23.3878C9.6393 23.1083 9.5 22.7368 9.5 22.3569V9.64575C9.5 9.2658 9.6393 8.89435 9.89801 8.61477ZM12.2414 13.5569C12.2414 13.2807 12.4653 13.0569 12.7414 13.0569H19.9795C20.2557 13.0569 20.4795 13.2807 20.4795 13.5569C20.4795 13.833 20.2557 14.0569 19.9795 14.0569H12.7414C12.4653 14.0569 12.2414 13.833 12.2414 13.5569ZM12.2414 16.4902C12.2414 16.214 12.4653 15.9902 12.7414 15.9902H19.9795C20.2557 15.9902 20.4795 16.214 20.4795 16.4902C20.4795 16.7663 20.2557 16.9902 19.9795 16.9902H12.7414C12.4653 16.9902 12.2414 16.7663 12.2414 16.4902ZM12.2414 19.4235C12.2414 19.1474 12.4653 18.9235 12.7414 18.9235H16.3605C16.6366 18.9235 16.8605 19.1474 16.8605 19.4235C16.8605 19.6997 16.6366 19.9235 16.3605 19.9235H12.7414C12.4653 19.9235 12.2414 19.6997 12.2414 19.4235Z"
      fill="currentColor"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="15.5"
      stroke="currentColor"
    />
  </svg>
);
