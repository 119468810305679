import React, { useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { DangerButton } from '@leagueplatform/genesis-commons';
import { ConfirmationModal } from 'components/confirmation-modal/confirmation-modal.component';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { CLP_DETAIL, CLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { useLeaveChallenge } from './hooks/use-leave-challenge.hook';

export interface LeaveChallengeModalProps {
  isSettingsDropdownOpen: boolean;
  onDismiss: () => void;
}

export const LeaveChallengeModal = ({
  isSettingsDropdownOpen,
  onDismiss,
}: LeaveChallengeModalProps) => {
  const { formatMessage } = useIntl();
  const leaveChallengeMutation = useLeaveChallenge();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: CLP_SCREEN_NAMES.LEAVE_CHALLENGE,
      detail,
      is_critical_event: true,
    });

  // Calling onDismiss on unmounting of this component, so that the leave challenge modal doesn't automatically open when clicking the settings cog after signing up for a challenge. This happened when a user joins a challenge, then leaves, then tries rejoining and leaving the challenge a second time.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onDismiss, []);

  return (
    <ConfirmationModal
      heading={formatMessage({ id: 'LEAVE_CHALLENGE_QUESTION' })}
      description={formatMessage({
        id: 'LEAVE_CHALLENGE_DETAIL',
      })}
      isSettingsDropdownOpen={isSettingsDropdownOpen}
      onMountAnalytics={() =>
        trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
          screen_name: CLP_SCREEN_NAMES.LEAVE_CHALLENGE,
        })
      }
      onDismiss={() => {
        onDismiss();
        trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          screen_name: CLP_SCREEN_NAMES.LEAVE_CHALLENGE,
          detail: CLP_DETAIL.EXIT,
        });
      }}
    >
      <DangerButton
        onClick={() => {
          leaveChallengeMutation.mutate();
          handleAnalyticsClick(CLP_DETAIL.LEAVE_CHALLENGE);
        }}
        isLoading={leaveChallengeMutation.isLoading}
      >
        {formatMessage({ id: 'LEAVE' })}
      </DangerButton>
    </ConfirmationModal>
  );
};
