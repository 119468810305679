import { useIntl } from '@leagueplatform/locales';
import type { ErrorStateProps } from '@leagueplatform/web-common-components';

export enum ERROR_TYPES {
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
}

const defaultTryAgainHandler = () => window.location.reload();

export const useErrorMessage = (errorType: ERROR_TYPES): ErrorStateProps => {
  const { $tmap } = useIntl();
  type ErrorBody = {
    headingString: string;
    bodyString: string;
    buttonText?: string;
    buttonAction?: () => void;
    hideButton?: boolean;
  };

  const heading = $tmap<ERROR_TYPES>({
    [ERROR_TYPES.API_ERROR]: 'ERROR_HEADING_GENERIC',
    [ERROR_TYPES.NO_CONNECTION]: 'ERROR_HEADING_NO_CONNECTION',
  });

  const body = $tmap<ERROR_TYPES>({
    [ERROR_TYPES.API_ERROR]: 'ERROR_CAPTION_BACKEND',
    [ERROR_TYPES.NO_CONNECTION]: 'ERROR_CAPTION_NO_CONNECTION',
  });

  const ErrorMessages: {
    [ERROR_TYPES.API_ERROR]: ErrorBody;
    [ERROR_TYPES.NO_CONNECTION]: ErrorBody;
  } = {
    [ERROR_TYPES.API_ERROR]: {
      headingString: heading(ERROR_TYPES.API_ERROR),
      bodyString: body(ERROR_TYPES.API_ERROR),
      buttonAction: defaultTryAgainHandler,
    },
    [ERROR_TYPES.NO_CONNECTION]: {
      headingString: heading(ERROR_TYPES.NO_CONNECTION),
      bodyString: body(ERROR_TYPES.NO_CONNECTION),
      hideButton: true,
    },
  };

  return ErrorMessages[errorType];
};
