import React, { forwardRef } from 'react';
import * as RadixTooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipContent, GDSTooltipContentProps } from './tooltip-content';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

// Exporting Radix Tooltip Primitive for custom Tooltips
export const GDSTooltipPrimitive = RadixTooltipPrimitive;

export interface GDSTooltipProps
  extends RadixTooltipPrimitive.TooltipProps,
    GDSTooltipContentProps {
  content: string;
}

export const Tooltip = forwardRef<HTMLDivElement, GDSTooltipProps>(
  (
    {
      align,
      avoidCollisions,
      children,
      className,
      content,
      css,
      defaultOpen,
      onOpenChange,
      open,
      padding,
      side,
      sideOffset,
      ...props
    }: GDSTooltipProps,
    ref,
  ) => (
    <RadixTooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <GenesisCoreInspector displayName="TooltipTrigger">
        <RadixTooltipPrimitive.Trigger asChild className="GDS-tooltip-trigger">
          {children}
        </RadixTooltipPrimitive.Trigger>
      </GenesisCoreInspector>
      <GenesisCoreInspector displayName="TooltipContent">
        <TooltipContent
          align={align}
          avoidCollisions={avoidCollisions}
          className={className}
          css={css}
          padding={padding}
          ref={ref}
          side={side}
          sideOffset={sideOffset}
          {...props}
        >
          {content}
        </TooltipContent>
      </GenesisCoreInspector>
    </RadixTooltipPrimitive.Root>
  ),
);

Tooltip.displayName = 'Tooltip';
