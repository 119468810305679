import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { horizontalBox, flexAlign } from '../../utils/flexible';
import CheckedAsset from 'assets/checked.png';
import { bgImageContain } from '../../utils/background';
import { colour, radiusInPx, spaceInPx } from '../../theme';

const checkboxHeight = '20px';
const borderWidth = '2px';

const Container = styled.div`
  label {
    ${horizontalBox()};
    ${flexAlign('center')};
  }

  input {
    display: none;
  }

  .icon {
    width: ${checkboxHeight};
    height: ${checkboxHeight};
    margin-right: ${spaceInPx(1)};
    background-color: ${colour('white')};
    border: ${borderWidth} solid ${colour('darkGrey')};
    border-radius: ${radiusInPx(2)};
  }

  &[data-align='right'] {
    .icon {
      order: 2;
      margin-right: 0;
      margin-left: ${spaceInPx(1)};
    }

    span {
      order: 1;
    }
  }

  input:checked + label {
    .icon {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(${CheckedAsset});
        ${bgImageContain()};
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      label {
        pointer-events: none;
        color: ${colour('greyLight')};
      }
      .icon {
        opacity: 0.5;
        background-color: transparent;
      }
    `};
  ${({ error }) =>
    error
      ? css`
          .icon {
            border-color: ${colour('red')};
            background-color: ${colour('redLightest')};
          }
        `
      : css`
    &:hover .icon {
    border-color: ${colour('purpleLight')};
  `};
`;

const CheckBox = (props) => {
  const {
    inputClassName,
    children,
    boxAlign,
    checked,
    onChange,
    name,
    className,
    disabled,
    error,
  } = props;
  const id = name || Date.now();

  return (
    <Container
      disabled={disabled}
      error={error}
      className={`${className} checkbox`}
      data-align={boxAlign}
    >
      <input
        onChange={onChange}
        id={id}
        type="checkbox"
        checked={checked}
        className={inputClassName}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <div className="icon" />
        <span>{children}</span>
      </label>
    </Container>
  );
};

CheckBox.propTypes = {
  inputClassName: PropTypes.string,
  children: PropTypes.node,
  boxAlign: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

CheckBox.defaultProps = {
  inputClassName: '',
  children: null,
  name: undefined,
  boxAlign: 'left',
  checked: false,
  onChange: () => {},
  className: '',
  disabled: false,
  error: false,
};

export default CheckBox;
