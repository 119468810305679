import * as React from 'react';
import { GetProviderServicesResponse } from '@leagueplatform/wallet-api';
import { Box } from '@leagueplatform/genesis-core';
import { BodyTwo, SubtitleTwo, BodyOne } from '@leagueplatform/genesis-commons';
import { StackLayout, Accordion } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { DefinitionPopover } from 'components/definition-popover/definition-popover.component';

interface NetworkPanelProps {
  services: GetProviderServicesResponse['provider_services'][
    | 'in_network'
    | 'out_of_network'];
  networkName?: string;
  onToggle?: (serviceName: string, state: 'expand' | 'collapse') => void;
}

export function NetworkPanel({
  services = [],
  networkName,
  onToggle,
}: NetworkPanelProps) {
  const { formatMessage } = useIntl();
  const firstService = services?.[0]?.service_name?.replaceAll(' ', '+');
  const defaultValue = firstService ? [firstService] : [];
  const [activePanels, setActivePanels] =
    React.useState<string[]>(defaultValue);

  const handleOnToggle = React.useCallback(
    (currentPanels: string[]) => {
      if (currentPanels.length > activePanels.length) {
        // Accordion component always adds the newest clicked trigger to the end of currentPanels array
        const clickedPanel = currentPanels[currentPanels.length - 1];
        if (onToggle) {
          onToggle(clickedPanel.replaceAll('+', ' '), 'expand');
        }
        setActivePanels(currentPanels);

        return;
      }

      // Get the panel that does not exist in currentPanels
      const [clickedPanel] = activePanels.filter(
        (x) => !currentPanels.includes(x),
      );

      if (onToggle) {
        onToggle(clickedPanel.replaceAll('+', ' '), 'collapse');
      }
      setActivePanels(currentPanels);
    },
    [activePanels, onToggle],
  );

  if (services === null || services.length === 0) {
    return (
      <BodyOne>
        {formatMessage(
          { id: 'NETWORK_MISSING_COVERAGE' },
          { network: networkName },
        )}
      </BodyOne>
    );
  }

  return (
    <Accordion.Root
      type="multiple"
      defaultValue={defaultValue}
      onValueChange={handleOnToggle}
    >
      {services.map(({ service_name: serviceName = '', providers = [] }) => (
        <Accordion.Item
          value={serviceName.replaceAll(' ', '+')}
          key={serviceName}
        >
          <Accordion.Header size="md" level="3">
            <Accordion.Trigger>{serviceName}</Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <StackLayout space="$half">
              {providers.map(({ title, description, definition }) => (
                <Box
                  as="section"
                  key={title}
                  css={{
                    backgroundColor: '$surfaceBackgroundSecondary',
                    padding: '$one',
                    marginLeft: '$oneAndHalf',
                  }}
                >
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <SubtitleTwo marginBottom="quarter" as="h4">
                        {title}
                      </SubtitleTwo>
                      <BodyTwo>{description}</BodyTwo>
                    </div>
                    {definition?.description && (
                      <DefinitionPopover
                        description={definition.description}
                        title={definition.title}
                        triggerLabel={formatMessage({
                          id: 'PROVIDER_SERVICE_POPOVER_BUTTON_NAME',
                        })}
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </StackLayout>
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
