import React, { forwardRef, FunctionComponent } from 'react';
import css from '@styled-system/css';
import { Box, BoxProps } from '../../Box';
import {
  pickInputProps,
  pickGlobalHTMLProps,
  pickLayoutProps,
  omitLayoutProps,
  pickStyleProps,
} from '../../utils/propFilters';
import { InputProps, visuallyHiddenInputStyles } from '../Input';

export interface RadioProps extends InputProps, BoxProps {
  checked?: boolean;
  ref?: any;
}

const RadioIcon = (props) => (
  <Box
    {...props}
    css={css({
      position: 'relative',
      'input ~ &, input ~ &:after': {
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(.17, .67, .83, .67)',
      },
      'input ~ &': {
        backgroundColor: 'white',
        borderColor: 'currentcolor',
        borderStyle: 'solid',
        borderWidth: 'thin',
        position: 'relative',
        height: 'oneAndHalf',
        width: 'oneAndHalf',
        borderRadius: 'circle',
      },
      'input ~ &::before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        right: '-2px',
        bottom: '-2px',
        borderRadius: 'circle',
      },
      'input ~ &:after': {
        content: '""',
        width: 0,
        height: 0,
        backgroundColor: 'currentcolor',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transformOrigin: '50%',
        borderRadius: 'circle',
      },
      'input:checked ~ &, input:not(checked) ~ &:hover': {
        color: 'interactive.border.hovered',
      },
      'input:focus ~ &, input:focus:not(checked) ~ &': {
        borderColor: 'currentcolor',
      },
      'input:focus ~ &::before, input:focus:not(checked) ~ &::before': {
        boxShadow: 'focusOutline',
      },
      'input:disabled ~ &': {
        color: 'interactive.border.disabled',
        pointerEvents: 'none',
      },
      'input:checked ~ &': {
        backgroundColor: 'currentcolor',
      },
      'input:checked ~ &:after': {
        backgroundColor: 'white',
        width: 'half',
        height: 'half',
      },
      'input:disabled ~ &, input:checked:disabled ~ &': {
        backgroundColor: 'interactive.background.disabled',
      },
      'input:disabled:hover ~ &, input:checked:disabled:hover ~ &': {
        backgroundColor: 'interactive.background.disabled',
        color: 'interactive.border.disabled',
      },
      'input:checked:disabled ~ &:after': {
        backgroundColor: 'interactive.icon.disabled',
      },
    })}
  />
);

/**
 * @deprecated  use `Radio` from `genesis-core` instead
 */

export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  { checked, error, defaultChecked, ...props },
  ref
) {
  const propsForRadioInput = {
    ...pickInputProps(props),
    ...pickGlobalHTMLProps(props),
  } as RadioProps;

  const propsForInputContainer = pickLayoutProps(props);

  const styleProps = pickStyleProps(props);
  const propsForInputIcon = omitLayoutProps(styleProps);

  return (
    <Box {...propsForInputContainer}>
      <Box
        as="input"
        ref={ref}
        type="radio"
        checked={checked}
        defaultChecked={defaultChecked}
        css={css(visuallyHiddenInputStyles)}
        data-testid="genesis-radio-input"
        {...propsForRadioInput}
      />
      <RadioIcon
        aria-hidden="true"
        color="interactive.border.default"
        {...(Boolean(error) && {
          color: 'interactive.border.critical.default',
        })}
        data-testid="genesis-radio-icon"
        transitionDuration="base"
        {...propsForInputIcon}
      />
    </Box>
  );
}) as FunctionComponent<RadioProps>;

// The function name is lost in minification so we must provide a display name
// to identify the component
Radio.displayName = 'Radio';
