import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyOneSecondary,
  HeadingTwo,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ENABLE_NEW_JOURNEY_EXPERIENCE } from '@leagueplatform/health-journey-common';
import { HealthProgramsList } from './health-programs-list.component';
import { programsListPropType } from '../utils/health-programs-page.props';

export const HealthProgramCarousels = ({
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  isLoading,
  hideLoadingSkeleton,
  carousels,
  carouselsCategory,
  linkCta,
  linkUrl,
}) => {
  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );

  const theme = useTheme();

  if (!carousels) return null;

  if (hideLoadingSkeleton && isLoading) {
    return null;
  }

  if (isLoading) {
    return (
      <SkeletonBox
        height="300px"
        marginBottom={theme.space.twoAndHalf}
        width="100%"
      />
    );
  }

  return carousels?.map(({ title, description, programs, analyticsProps }) => {
    const titleView = (
      <HeadingTwo
        paddingBottom="half"
        as={isNewJourneyExperienceEnabled ? 'h3' : 'h2'}
      >
        {title}
      </HeadingTwo>
    );

    const descriptionView = <BodyOneSecondary>{description}</BodyOneSecondary>;

    return (
      <HealthProgramsList
        data={programs}
        description={descriptionView}
        key={title}
        title={titleView}
        analyticsContext={{
          ...analyticsProps,
          pageContext: carouselsCategory,
          carouselName: title,
        }}
        linkCta={linkCta}
        linkUrl={linkUrl}
      />
    );
  });
};

HealthProgramCarousels.propTypes = {
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      programs: programsListPropType,
      analyticsProps: PropTypes.objectOf(PropTypes.string),
    }),
  ),
  carouselsCategory: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types -- FIXME: automatically added for existing issue
  ready: PropTypes.bool,
  linkCta: PropTypes.string,
  linkUrl: PropTypes.string,
  hideLoadingSkeleton: PropTypes.bool,
};

HealthProgramCarousels.defaultProps = {
  carousels: [],
  carouselsCategory: '',
  ready: false,
  linkCta: '',
  linkUrl: '',
  hideLoadingSkeleton: false,
};
