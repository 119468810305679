import React from 'react';
import PropTypes from 'prop-types';

// components
import { HealthProgramCarousels } from './health-program-carousels.component';

// hooks
import { useHealthProgramCuratedCarousels } from '../hooks/use-health-program-curated-carousels.hook';

// utils
import { CURATED_CAROUSEL_TYPES } from '../utils/health-programs-curated-carousel-types.const';

export const CuratedHealthProgramCarousel = ({
  carouselType,
  carouselsCategory,
}) => {
  const type = !Object.values(CURATED_CAROUSEL_TYPES).includes(carouselType)
    ? CURATED_CAROUSEL_TYPES.HOMEFEED
    : carouselType;

  const {
    data: healthProgramSuggestedCarouselsData,
    isLoading: isHealthProgramCuratedCarouselsLoading,
  } = useHealthProgramCuratedCarousels(type);

  return (
    <HealthProgramCarousels
      isLoading={isHealthProgramCuratedCarouselsLoading}
      carousels={healthProgramSuggestedCarouselsData?.carousels}
      carouselsCategory={carouselsCategory}
    />
  );
};

CuratedHealthProgramCarousel.propTypes = {
  carouselType: PropTypes.string,
  carouselsCategory: PropTypes.string,
};

CuratedHealthProgramCarousel.defaultProps = {
  carouselType: '',
  carouselsCategory: '',
};
