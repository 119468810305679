import React from 'react';
import { Link, useParams } from '@leagueplatform/routing';
import {
  BodyOne,
  HeadingFour,
  Box,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { CLP_DETAIL } from 'constants/analytics.constants';

export const JoinTeam = () => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail,
    });
  return (
    <Box
      backgroundColor="decorative.brand.primary.pastel"
      borderColor="onSurface.border.subdued"
      borderRadius="medium"
      borderWidth="thin"
      boxShadow="card"
      padding="oneAndHalf"
    >
      <HeadingFour as="h2">{formatMessage({ id: 'UP_YOUR_GAME' })}</HeadingFour>
      <BodyOne marginTop="half" marginBottom="oneAndHalf">
        {formatMessage({ id: 'UP_YOUR_GAME_DESCRIPTION' })}
      </BodyOne>
      <PrimaryButton
        as={Link}
        width="100%"
        textDecoration="none"
        to={JOURNEY_ROUTES.getChallengeJoinTeam(challengeId)}
        onClick={() => handleAnalyticsClick(CLP_DETAIL.JOIN_CREATE_TEAM)}
      >
        {formatMessage({ id: 'JOIN_OR_CREATE_TEAM' })}
      </PrimaryButton>
    </Box>
  );
};
