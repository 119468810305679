import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { openInTab } from '@leagueplatform/web-common';
import { useConfigProperty } from '@leagueplatform/core';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { HealthSolution as HealthSolutionType } from '../../types/health-need-pages.types';
import { HealthSolution } from '../health-solution/health-solution.component';
import { LIFEMARKET } from '../../constants/health-solution-types.constants';
import { getHealthSolutionUrl } from '../../utils/get-health-solution-url';

interface HealthSolutionsListProps {
  healthSolutionsData: HealthSolutionType[];
  lifemarketUrl?: string;
  placeHolderImage?: string;
  healthNeedName: string;
}

const TOP_SOLUTION_INDEX_MODIFIER = 2;

export const HealthSolutionsList = ({
  healthSolutionsData,
  lifemarketUrl,
  placeHolderImage, // used for testing/storybook
  healthNeedName, // Needed only for analytics
}: HealthSolutionsListProps) => {
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  return (
    <>
      {healthSolutionsData.map(
        ({ imageSrc, title, description, link, type, id }, index) => (
          <HealthSolution
            key={uniqueId('health-solution-container-')}
            imageSrc={imageSrc || placeHolderImage}
            title={title}
            description={description}
            type={type}
            onCardClick={() => {
              sendAnalyticsEvent({
                category: `Health Needs - ${healthNeedName}`,
                action: `Select Recommended Health Solution - ${type}`,
                label: title,
                parameter: {
                  rank: index + TOP_SOLUTION_INDEX_MODIFIER, // Since top solution is always rank 1, do index + 2 to get rank 2 onward
                  solution_id: id,
                  solution_type: type,
                },
              });
              const healthSolutionUrl = getHealthSolutionUrl(
                link,
                type,
                lifemarketUrl,
              );

              if (type === LIFEMARKET) {
                handleLinkCallback(healthSolutionUrl);
              } else {
                openInTab(healthSolutionUrl);
              }
            }}
          />
        ),
      )}
    </>
  );
};
