import * as React from 'react';
import {
  useTheme,
  useThemeRawValues,
  StackLayout,
} from '@leagueplatform/genesis-core';
import type {
  VerticalBarGraphV2,
  VerticalBarGraphItemV2,
} from '@leagueplatform/dashboard-api';
import { Group } from '@visx/group';
import { BarGroup, BarRounded } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
import { GridRows } from '@visx/grid';
import useMeasure from 'react-use-measure';
import { useIntl } from '@leagueplatform/locales';
import { getStringWidth } from '@visx/text';
import { getAxisTicks } from '../core/bar-graph/utils/get-axis-ticks';
import { LegendV2 } from './legend';

type InsightCardVerticalBarGraphProps = VerticalBarGraphV2;

export type ChartDataItem = VerticalBarGraphItemV2 & {
  [key: number]: number;
};

const CHART_HEIGHT = 150;
const TICK_WIDTH = 8;

export function InsightCardVerticalBarGraphV2({
  title,
  description,
  items,
}: InsightCardVerticalBarGraphProps) {
  const theme = useTheme();
  const rawTheme = useThemeRawValues();
  const { formatNumber } = useIntl();

  const chartData = items.map((input) => {
    const newData: ChartDataItem = {
      ...input,
    };
    input.values.forEach((value, index) => {
      newData[index] = value;
    });
    return newData;
  });

  const largestValue = Math.max(
    ...chartData.map((values) => Math.max(...values.values)),
  );

  const keys = Object.keys(chartData[0].values).map(Number);

  // accessors
  const getDate = (d: VerticalBarGraphItemV2) => d.xAxisLabel;
  const ticks = getAxisTicks(largestValue);
  const yScaleMax = ticks[ticks.length - 1];

  const [ref, bounds] = useMeasure();
  const { width, height } = bounds;
  const margin = {
    top: 15,
    left: (getStringWidth(formatNumber(yScaleMax)) ?? 0) + TICK_WIDTH,
    bottom: 30,
  };

  // bounds
  const chartWidth = width - margin.left;
  const chartHeight = height - margin.top - margin.bottom;

  // scales
  const groupScale = scaleBand({
    domain: chartData.map(getDate),
    padding: 0.1,
    range: [0, chartWidth],
  });

  const xScale = scaleBand({
    domain: keys,
    range: [0, groupScale.bandwidth()],
  });
  const yScale = scaleLinear({
    domain: [0, yScaleMax],
    range: [chartHeight, 0],
  });

  return (
    <StackLayout spacing="$two">
      <LegendV2 items={items} />
      <svg
        ref={ref}
        width="100%"
        height={CHART_HEIGHT}
        viewBox={`0 0 ${width} ${height}`}
      >
        <title>{title}</title>
        <desc>{description}</desc>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            scale={yScale}
            width={chartWidth}
            strokeDasharray="7,7"
            strokeWidth={rawTheme.radii.thin}
            stroke={theme.colors.onSurfaceBorderSubdued}
            tickValues={ticks}
          />
          <BarGroup
            data={chartData}
            keys={keys}
            height={chartHeight}
            x0={getDate}
            x0Scale={groupScale}
            x1Scale={xScale}
            yScale={yScale}
            // we need to pass an empty function here, because the color prop is required by the visx library. but, within it there is no way to determine what group we are in, so we don't have a way to define a color at a group level. its intended use is different colors within a group, not between groups. so to get different colors per group, we are using a conditional value on the fill prop on BarRounded.
            color={() => ''}
          >
            {(barGroups) =>
              barGroups.map((barGroup, index) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => {
                    if (bar.value === 0) {
                      return null;
                    }
                    return (
                      <BarRounded
                        key={`bar-rounded-${bar.index}-${bar.x}`}
                        x={bar.x}
                        y={bar.y}
                        width={rawTheme.sizes.threeQuarters}
                        height={bar.height}
                        top
                        bottom
                        radius={rawTheme.radii.extraLarge}
                        fill={items[index].barColor}
                      />
                    );
                  })}
                </Group>
              ))
            }
          </BarGroup>
          <AxisBottom
            top={chartHeight - 1}
            scale={groupScale}
            hideAxisLine
            hideTicks
            tickLabelProps={() => ({
              fill: theme.colors.onSurfaceTextPrimary,
              fontSize: theme.fontSizes.bodyTwo,
              textAnchor: 'middle',
            })}
            tickLength={20}
          />
          <AxisLeft
            scale={yScale}
            tickValues={ticks}
            hideAxisLine
            hideTicks
            tickLabelProps={() => ({
              fontSize: theme.fontSizes.bodyTwo,
              fill: theme.colors.onSurfaceTextPrimary,
              textAnchor: 'end',
              dy: 4,
            })}
          />
        </Group>
      </svg>
    </StackLayout>
  );
}
