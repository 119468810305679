import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Carousel } from '@leagueplatform/web-common-components';
import { Link } from '@leagueplatform/routing';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { Achievement, REWARDS_EVENTS_ASSET_MAP } from 'constants/types';

import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  Box,
  Button,
  Modal,
  StackLayout,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import {
  CELEBRATION_MODAL_ACTIONS,
  REWARDS_EVENT_ANALYTICS,
} from 'constants/analytics.constants';
import { AnalyticsDictFactory } from 'utils/analytics-dict-factory.utils';
import { useIntl } from '@leagueplatform/locales';
import { CelebrationContent } from '../celebration-content/celebration-content.view';

interface CelebrationModalProps {
  celebrations: Achievement[];
  onClose: () => void;
}

export const CelebrationModal = ({
  celebrations,
  onClose,
}: CelebrationModalProps) => {
  const { formatMessage } = useIntl();

  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const confettiAnimationRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 2100);
  }, []);

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_CELEBRATION_MODAL,
      num_of_milestone_rewards: celebrations.length,
      has_points: celebrations.every((celebration) =>
        Boolean(celebration.reward.value),
      ),
      milestone_rewards_dict: AnalyticsDictFactory(
        celebrations.map((celebration) => ({
          milestone_reward_id: celebration.id,
          milestone_reward_name: celebration.name,
          has_points: Boolean(celebration.reward.value),
        })),
      ),
    });
  }, [celebrations]);

  const handleButtonAction = (
    action: CELEBRATION_MODAL_ACTIONS,
    cta_title?: string,
  ) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_CELEBRATION_MODAL,
      detail: action,
      sub_detail: cta_title,
      num_of_milestone_rewards: celebrations.length,
      milestone_rewards_dict: AnalyticsDictFactory(
        celebrations.map((celebration) => ({
          milestone_reward_id: celebration.id,
          milestone_reward_name: celebration.name,
          has_points: Boolean(celebration.reward.value),
        })),
      ),
      milestone_rewards_index: currentCarouselIndex,
    });

    onClose();
  };

  return (
    <Modal.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) handleButtonAction(CELEBRATION_MODAL_ACTIONS.CLOSE);
      }}
    >
      <Modal.Content
        css={{
          '.GDS-modal': { width: '100%', maxWidth: '848px' },
        }}
      >
        <Modal.Title>
          <VisuallyHidden>
            {formatMessage({ id: 'ACHIEVEMENT_EARNED' })}
          </VisuallyHidden>
        </Modal.Title>
        <StackLayout
          orientation="vertical"
          css={{
            position: 'relative',
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box css={{ position: 'absolute', top: 0, height: 300 }}>
            {
              handleStaticAsset(
                REWARDS_EVENTS_ASSET_MAP.ACHIEVEMENT_CONFETTI_ANIMATION,
                {
                  isComponent: true,
                  props: {
                    animationRef: confettiAnimationRef,
                    shouldPlay: showAnimation,
                  },
                },
              ) as ReactNode
            }
          </Box>
          <Box css={{ maxWidth: '384px', position: 'relative' }}>
            <Carousel
              sideArrows
              showProgressDots
              name="celebration-modal"
              onIndexChange={(index: number) => setCurrentCarouselIndex(index)}
            >
              {celebrations.map((achievement, index) => (
                <CelebrationContent
                  index={index}
                  isVisible={index === currentCarouselIndex}
                  achievement={achievement}
                />
              ))}
            </Carousel>
          </Box>
          <StackLayout
            spacing="$oneAndHalf"
            horizontalAlignment="center"
            orientation="horizontal"
            css={{
              width: '100%',
              flexWrap: 'wrap-reverse',
              marginTop: '$three',
            }}
          >
            {celebrations[currentCarouselIndex]
              .secondary_cta_destination_url && (
              <Box>
                <Button
                  priority="secondary"
                  as={Link}
                  to={
                    celebrations[currentCarouselIndex]
                      .secondary_cta_destination_url || '/#'
                  }
                  quiet
                  onClick={() =>
                    handleButtonAction(
                      CELEBRATION_MODAL_ACTIONS.SECONDARY,
                      celebrations[currentCarouselIndex].secondary_cta_title,
                    )
                  }
                >
                  {celebrations[currentCarouselIndex].secondary_cta_title}
                </Button>
              </Box>
            )}
            {celebrations[currentCarouselIndex].primary_cta_destination_url && (
              <Box>
                <Button
                  as={Link}
                  to={
                    celebrations[currentCarouselIndex]
                      .primary_cta_destination_url || '/#'
                  }
                  priority="primary"
                  role="link"
                  onClick={() =>
                    handleButtonAction(
                      CELEBRATION_MODAL_ACTIONS.PRIMARY,
                      celebrations[currentCarouselIndex].primary_cta_title,
                    )
                  }
                  css={{ textDecoration: 'none' }}
                >
                  {celebrations[currentCarouselIndex].primary_cta_title}
                </Button>
              </Box>
            )}
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
