import React from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  JOURNEY_ROUTES,
  ProgramDetailCard,
} from '@leagueplatform/health-journey-common';
import { ChallengeLink } from 'components/challenge-link/challenge-link.component';
import { ChallengeDetailsCardBanner } from 'components/challenge-details-card-banner/challenge-details-card-banner.component';
import { ProgressTracker } from 'components/progress-tracker/progress-tracker.component';
import { ChallengeDetailsContents } from 'components/challenge-details-contents/challenge-details-contents.component';
import { JoinTeam } from './components/join-team/join-team.component';
import { SignUpButton } from '../sign-up-button/sign-up-button.component';

export interface SidebarProps {
  userIsEnrolled: boolean;
  teamsEnabled: boolean;
  userIsOnTeam: boolean;
  userCanJoinTeam: boolean;
  teamId?: string;
  teamName?: string;
  setDisplaySignUpModal: React.Dispatch<React.SetStateAction<boolean>>;
  displaySignUpButton: boolean;
}

export const Sidebar = ({
  userIsEnrolled,
  teamsEnabled,
  userIsOnTeam,
  userCanJoinTeam,
  teamId,
  teamName,
  setDisplaySignUpModal,
  displaySignUpButton,
}: SidebarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  return (
    <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
      {userIsEnrolled && <ProgressTracker />}
      <ProgramDetailCard
        heading={formatMessage({ id: 'CHALLENGE_DETAILS' })}
        footer={<ChallengeDetailsCardBanner />}
      >
        <ChallengeDetailsContents />
      </ProgramDetailCard>
      {displaySignUpButton && (
        <SignUpButton setDisplaySignUpModal={setDisplaySignUpModal} />
      )}
      {teamsEnabled && userIsOnTeam && teamId && teamName && (
        <ChallengeLink
          to={JOURNEY_ROUTES.getChallengeTeamDetails(challengeId, teamId)}
          title={formatMessage({ id: 'YOUR_TEAM' })}
          description={teamName}
        />
      )}
      {userCanJoinTeam && <JoinTeam />}
    </StackLayout>
  );
};
