import React from 'react';
import {
  SkeletonBox,
  SlideInHorizontal,
} from '@leagueplatform/web-common-components';

import {
  css,
  genesisStyled,
  BodyOne,
  HeadingOne,
  Image,
  Flex,
} from '@leagueplatform/genesis-commons';

import { usePrefersReducedMotion } from '@leagueplatform/web-common';

import { FeatureSlideProps } from 'types/features-highlight.type';

const ImagePanel = genesisStyled(Flex)(
  css({
    flexBasis: 'calc((100% / 12) * 5)',
    justifyContent: 'center',
    paddingRight: 'five',
    paddingLeft: 'one',
  }),
);

const MainPanel = genesisStyled(Flex)(
  css({
    flexBasis: 'calc((100% / 12) * 7)',
    paddingRight: 'one',
    paddingY: 'two',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  }),
);

const CopyContainer = genesisStyled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc((100% / 7) * 5)',
  }),
);

const Illustration = genesisStyled(Image)(
  css({
    width: '256px',
    height: '256px',
    margin: 'auto',
    objectFit: 'contain',
  }),
);

export const FeatureSlide = ({
  description,
  heading,
  image,
  alt,
  isActive = true,
  isLoading = false,
}: FeatureSlideProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  return (
    <Flex>
      {isLoading ? (
        <ImagePanel>
          <SkeletonBox width="356px" height="375px" />
        </ImagePanel>
      ) : (
        <ImagePanel>
          {prefersReducedMotion ? (
            <Illustration src={image} alt={alt} />
          ) : (
            <SlideInHorizontal
              isActive={isActive}
              duration={500}
              distance="10%"
              stylesProp={{
                display: 'flex',
              }}
            >
              <Illustration src={image} alt={alt} />
            </SlideInHorizontal>
          )}
        </ImagePanel>
      )}
      <MainPanel paddingLeft={{ _: 'one', desktop: 'two' }}>
        <Flex flexDirection="column">
          <CopyContainer>
            {isLoading ? (
              <SkeletonBox height="40px" />
            ) : (
              <HeadingOne color="onSurface.text.primary" as="h2">
                {heading}
              </HeadingOne>
            )}
            {isLoading ? (
              <SkeletonBox height="86px" marginTop="one" />
            ) : (
              <BodyOne paddingTop="one" color="onSurface.text.subdued">
                {description}
              </BodyOne>
            )}
          </CopyContainer>
        </Flex>
      </MainPanel>
    </Flex>
  );
};
