import React, {
  forwardRef,
  useRef,
  AnchorHTMLAttributes,
  useMemo,
} from 'react';
import { tabStyles, TabIconLabelWrapper } from 'components/tabs/tab';
import { useTabElementIsInView } from 'components/tabs/use-tab-element-is-in-view';
import { useTablistContext } from 'components/tabs/tablist-context';
import { Icon, IconSource } from 'components/icon/icon';
import { styled } from '../../theme';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import type { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    GDSCustomizableComponent {
  icon?: IconSource;
  iconLabel?: string;
  width?: GDSResponsiveProp<number | string>;
}

const BaseNavLink = styled('a', {
  ...tabStyles,
});

export const NavItem = styled('li', {
  listStyle: 'none',
});

export const Link = forwardRef<HTMLAnchorElement, GDSLinkProps>(
  (
    {
      children,
      className,
      css,
      href,
      icon,
      iconLabel,
      target,
      width,
      ...props
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLAnchorElement>(null);
    const composedRefs = useComposedRefs(ref, internalRef);
    const tabWidth = useResponsiveProp(width);
    const tabIcon = useResponsiveProp(icon);
    const { elementIsInView, tabScrollOptions } = useTabElementIsInView();
    const { scrollLeftButton, scrollRightButton } = useTablistContext();

    const scrollElementIfNotInView = useMemo(
      () => () => {
        if (
          !elementIsInView(internalRef) &&
          (scrollLeftButton || scrollRightButton)
        ) {
          internalRef.current!.scrollIntoView(tabScrollOptions);
        }
      },
      [elementIsInView, tabScrollOptions, scrollLeftButton, scrollRightButton],
    );

    return (
      <GenesisCoreInspector displayName="NavItem">
        <NavItem
          className={[`GDS-nav-item`, className].join(' ')}
          css={{
            ...css,
            ...(width && {
              width: `${tabWidth}`,
            }),
          }}
        >
          <BaseNavLink
            className="GDS-nav-link"
            href={href}
            target={target}
            ref={composedRefs}
            onFocus={() => scrollElementIfNotInView()}
            {...props}
          >
            <TabIconLabelWrapper>
              {tabIcon && <Icon icon={tabIcon} label={iconLabel} />}
              {children}
            </TabIconLabelWrapper>
          </BaseNavLink>
        </NavItem>
      </GenesisCoreInspector>
    );
  },
);
