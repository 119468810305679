import { useTablistContext } from './tablist-context';

export const useTabElementIsInView = () => {
  const { tabListWrapper, scrollLeftButton, scrollRightButton } =
    useTablistContext();

  const tabScrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  };

  const elementIsInView = (tabElement: React.RefObject<HTMLElement>) => {
    if (!tabListWrapper || !tabElement.current) {
      return false;
    }

    const leftEdge = tabListWrapper.getBoundingClientRect();
    const scrollButtonWidth =
      (scrollLeftButton || scrollRightButton)?.getBoundingClientRect().width ||
      0;

    const leftOffset =
      leftEdge.left + (scrollLeftButton ? scrollButtonWidth : 0);
    const tablistWrapperWidth =
      tabListWrapper.offsetWidth - (scrollRightButton ? scrollButtonWidth : 0);

    const { left, right } = tabElement.current.getBoundingClientRect();

    return left >= leftOffset && right <= tablistWrapperWidth;
  };

  return {
    elementIsInView,
    tabScrollOptions,
  };
};
