import { getConfig } from '@leagueplatform/config';
import {
  sendGoogleAnalytics,
  sendGoogleAnalyticsPageView,
} from './google-analytics/google-analytics';
import { sendAnalyticsToLeague } from './league-analytics/league-analytics';
import { segmentIdentify, sendSegmentEvent } from './segment/segment';
import {
  User,
  EventProps,
  SendAnalyticsPageViewParams,
} from './analytics.types';
import { GA_EVENT, EVENT_NAME } from './constants';

/**
 * Utility function to identify current user in analytics services
 * @param {Object} user - User information
 */
export const identify = (user: User) => {
  if (!user) return;

  segmentIdentify(user);

  // invoke client onIdentify callback method, if provided
  try {
    getConfig().config.core.analytics?.onIdentify?.(user);
  } catch (error) {
    /* eslint-disable no-console */
    console.error('There was an error in the onIdentify method:', error);
  }
};

/**
 * New Analytics tracker method, sendAnalyticsEvent will be deprecated and replaced by trackEvent
 * @param {string} eventName - Event name
 * @param {object} props - Event payload
 */
export const trackAnalyticsEvent = (
  eventName: EVENT_NAME | string,
  props: EventProps = {},
) => {
  /**
   * Validation required when function is used in non-TypeScript environment.
   * Can be removed when TypeScript is universally adopted.
   */
  if (typeof eventName !== 'string')
    throw new Error('Analytics - eventName argument must be of type string');

  sendSegmentEvent(eventName, props);

  // Converting properties to legacy format + sending event to legacy systems
  const legacyParams = {
    category: props.screen_name,
    action: props.detail,
    label: props.sub_detail || props.sub_product_area,
    ...props,
  };

  sendGoogleAnalytics(legacyParams);
  sendAnalyticsToLeague(legacyParams, GA_EVENT);

  // invoke client onTrackEvent callback method, if provided
  try {
    getConfig().config.core.analytics?.onTrackEvent?.(eventName, props);
  } catch (error) {
    console.error('There was an error in the onTrackEvent method:', error);
  }
};

/**
 * @deprecated - This function will be deprecated in a near future, once Segment migration is completed. New implementations should use trackAnalyticsEvent.
 *
 * Utility function for sending analytics events to GA, League and Segment
 * @param {Object} params - Analytics event payload
 * @param {string} name - Analytics event name
 */

export const sendAnalyticsEvent = (params: any, name: string = GA_EVENT) => {
  sendGoogleAnalytics(params);
  sendAnalyticsToLeague(params, name);
  sendSegmentEvent(EVENT_NAME.BUTTON_CLICKED, params);
};

/**
 * Utility function for sending page view events to GA, League and Segment
 * @param {Object} params - Analytics page view payload
 * @param {string} name - Analytics page view name
 */

export const sendAnalyticsPageView = (
  params: SendAnalyticsPageViewParams,
  name: string = GA_EVENT,
) => {
  const props: SendAnalyticsPageViewParams = {
    screen_name: params.title as string,
    path: params.path || window.location.pathname,
    ...params,
  };

  sendGoogleAnalyticsPageView(props);
  sendAnalyticsToLeague(props, name);
  sendSegmentEvent(EVENT_NAME.SCREEN_LOADED, props);

  // invoke client onTrackPageViewEvent callback method, if provided
  try {
    getConfig().config.core.analytics?.onTrackPageViewEvent?.(
      props.screen_name as string,
      props,
    );
  } catch (error) {
    console.error(
      'There was an error in the onTrackPageViewEvent method:',
      error,
    );
  }
};
