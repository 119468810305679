import React from 'react';
import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';

import { Transition } from 'react-transition-group';
import { TransitionStates } from 'types/animation-transition-states.type';
import { TransitionProps } from 'types/transition-props.type';

const Element = genesisStyled(Box)(({ stylesProp }) =>
  css({
    ...stylesProp,
    opacity: 0,
  }),
);

const transitionStyles = {
  entering: {
    opacity: 0,
    visibility: 'visible',
    maxHeight: '90vh',
    overflow: 'hidden',
  },
  entered: {
    opacity: 1,
    visibility: 'visible',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  exiting: {
    opacity: 0,
    visibility: 'hidden',
    maxHeight: '0px',
    overflow: 'hidden',
  },
  exited: {
    opacity: 0,
    visibility: 'hidden',
    maxHeight: '0px',
  },
};

export const FadeAndGrow = ({
  isActive,
  stylesProp,
  duration,
  children = null,
}: TransitionProps) => {
  const halfTime = duration / 2;
  return (
    <Transition in={isActive} timeout={duration}>
      {(state: TransitionStates) => (
        <Element
          stylesProp={{
            ...stylesProp,
            overflow: 'hidden',
            transition: `opacity ${halfTime}ms ease-in-out, max-height ${duration}ms ease-in-out ${halfTime}ms, visibility ${duration}ms ease-in-out ${halfTime}ms`,
          }}
          style={{ ...transitionStyles[state] }}
        >
          {children && children}
        </Element>
      )}
    </Transition>
  );
};
