import { useIntl } from '@leagueplatform/locales';
import { BenefitType } from '@leagueplatform/wallet-api';

export function useBenefitTranslationMap() {
  const { $tmap } = useIntl();

  return $tmap<BenefitType>({
    psa: 'LIFESTYLE_SPENDING_ACCOUNT',
    hsa: 'HEALTH_SPENDING_ACCOUNT',
    hra: 'HEALTH_REIMBURSEMENT_ARRANGEMENT',
    healthPlan: 'HEALTH_INSURANCE',
    travel: 'TRAVEL',
    drugs: 'DRUGS',
    promo_code: 'LEAGUE_CREDIT',
    medical: 'MEDICAL',
  });
}
