import styled from 'styled-components';
import { vars, Box } from '@leagueplatform/ui-kit';

export const FieldSection = styled(Box)`
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      ${vars.colour.white},
      ${vars.colour.navyLightest} 20%,
      ${vars.colour.navyLightest} 80%,
      ${vars.colour.white}
    );
  }
`;
