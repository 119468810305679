import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';

export interface ChallengeUnitIconProps {
  // remove undefined once we switch to v2 of endpoint and the BE is returning these fields
  score?: number;
  rankingUnit?: string;
  isOnPodium?: boolean;
}

export const ChallengeUnitIcon = ({
  score = 0,
  rankingUnit,
  isOnPodium,
}: ChallengeUnitIconProps) => {
  const { formatNumber } = useIntl();
  return (
    <StackLayout
      orientation="horizontal"
      spacing="$half"
      verticalAlignment="center"
    >
      <UtilityText
        css={{
          color: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
        }}
      >
        {formatNumber(score)} {!isOnPodium && rankingUnit}
        {isOnPodium && <VisuallyHidden> {rankingUnit} </VisuallyHidden>}
      </UtilityText>
    </StackLayout>
  );
};
