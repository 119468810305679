import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ADMIN, HEALTH_ADMIN } from '@leagueplatform/web-common';

export function getWalletPath(
  currentUserRole: string | undefined,
  memberUserId: string | undefined,
) {
  const isAdmin = currentUserRole === ADMIN || currentUserRole === HEALTH_ADMIN;

  // if `isAdmin` is `true` but `memberUserId` is null,
  // the admin user is viewing their own wallet
  if (isAdmin && memberUserId) {
    return `/admin/wallet/${memberUserId}`;
  }

  return getModulePath(LEAGUE_MODULE_NAMES.wallet);
}
