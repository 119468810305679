import {
  WidgetType,
  Orientation,
  SupportedMedia,
} from '@leagueplatform/masonry-api';

export const DefaultOrientationMap = {
  Default: Orientation.VERTICAL,
  [WidgetType.STACK]: {
    [SupportedMedia.MOBILE]: Orientation.VERTICAL,
    [SupportedMedia.TABLET]: Orientation.VERTICAL,
    [SupportedMedia.LAPTOP]: Orientation.HORIZONTAL,
    [SupportedMedia.DESKTOP]: Orientation.HORIZONTAL,
  },
};
