import React from 'react';
import { HtmlToReact } from '@leagueplatform/web-common';
import { ParagraphText } from '@leagueplatform/genesis-core';

export interface DisplayProps {
  text?: string;
}

export const Display = ({ text }: DisplayProps) =>
  text ? (
    <ParagraphText>
      <HtmlToReact htmlString={text} />
    </ParagraphText>
  ) : null;
