import React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { JoinTeamPage } from 'pages/join-team-page/join-team-page.page';
import { SettingsPage } from 'pages/settings-page/settings-page.page';
import { ChallengeLandingPage } from './pages/challenge-landing-page/challenge-landing-page.page';
import { TeamLandingPage } from './pages/team-landing-page/team-landing-page.page';
import { ChallengeActivitiesHistoryPage } from './pages/challenge-activities-history-page/challenge-activities-history-page';

export const ChallengeRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:challengeId`} exact>
        <ChallengeLandingPage />
      </Route>
      <Route path={`${path}/:challengeId/settings`}>
        <SettingsPage />
      </Route>
      <Route path={`${path}/:challengeId/join-team`}>
        <JoinTeamPage />
      </Route>
      <Route path={`${path}/:challengeId/team/:teamId`}>
        <TeamLandingPage />
      </Route>
      <Route path={`${path}/:challengeId/activities/completed`}>
        <ChallengeActivitiesHistoryPage activityStatus="completed" />
      </Route>
      <Route path={`${path}/:challengeId/activities/missed`}>
        <ChallengeActivitiesHistoryPage activityStatus="missed" />
      </Route>
    </Switch>
  );
};
