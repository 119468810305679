import * as React from 'react';
import {
  HealthMetric,
  MetricType,
  MetricUnit,
} from '@leagueplatform/dashboard-api';
import { CHART_VIEW } from 'components/charts/constants/chart-views';
import {
  ActiveDurationBarChart,
  DistanceBarChart,
  EnergyBurnedBarChart,
  FloorsClimbedBarChart,
  StepsBarChart,
  MindfulDurationBarChart,
} from 'components/charts/metrics';
import { HealthMetricChart } from 'components/charts/types/chart';
import { useGroomedHealthMetrics } from '../hooks/health-metrics-chart.hooks';

type LegacyChartProps = {
  healthMetrics: HealthMetric[];
  chartView: CHART_VIEW;
  metricsFrom: Date;
  metric: MetricType;
  unit: MetricUnit;
  hasPreviousPage: boolean;
};

type Charts = Record<MetricType, React.ElementType<HealthMetricChart>>;

const charts: Charts = {
  distance: DistanceBarChart,
  steps: StepsBarChart,
  active_duration: ActiveDurationBarChart,
  energy_burned: EnergyBurnedBarChart,
  floors_climbed: FloorsClimbedBarChart,
  mindful_duration: MindfulDurationBarChart,
};

export function LegacyChart({
  healthMetrics,
  chartView,
  metricsFrom,
  unit,
  metric,
  hasPreviousPage,
}: LegacyChartProps) {
  const groomedHealthMetrics = useGroomedHealthMetrics({
    healthMetrics,
    metric,
    unit,
    metricsFrom,
    chartView,
    hasPreviousPage,
  });

  const Chart = charts[metric];

  return (
    <Chart
      view={chartView}
      data={groomedHealthMetrics}
      type={metric}
      unit={unit}
    />
  );
}
