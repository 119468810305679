import * as React from 'react';
import { GDSHeadingLevel, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '@leagueplatform/web-common';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { getActivityDate } from '../utils/activity-date';
import { ChallengeActivityCard } from './challenge-activity-card';

export type ChallengeActivityListProps = {
  activities: ConfigurableHealthActivityWSCC[];
  level: GDSHeadingLevel;
};

export function ChallengeActivityList({
  activities,
  level,
}: ChallengeActivityListProps) {
  const { formatDate } = useIntl();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};

  return (
    <>
      {activities.map((activity) => (
        <React.Fragment key={activity.id}>
          <UtilityText size="eyebrow">
            {formatDate(getActivityDate(activity), {
              ...DATE_FORMAT,
              timeZone: defaultTimezone,
            })}
          </UtilityText>
          <ChallengeActivityCard activity={activity} level={level} />
        </React.Fragment>
      ))}
    </>
  );
}
