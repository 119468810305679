import React from 'react';
import { Link } from '@leagueplatform/routing';
import { Flex, Box } from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';

export const HealthProgramErrorState = () => (
  <Flex
    padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
    flexDirection={{ _: 'column', phone: 'row' }}
    margin="0 auto"
    justifyContent="center"
  >
    <BackButtonArrow
      as={Link}
      color="onSurface.text.primary"
      fontSize="heading3"
      marginRight="one"
      marginY="half"
      to={JOURNEY_ROUTES.journeyHome}
    />
    <Box marginTop="three">
      <ErrorState />
    </Box>
  </Flex>
);
