import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_SUGGESTED_ACTIVITIES = 'get_suggested_user_health_activities';

export const getSuggestedUserActivities = async (
  groupId: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_SUGGESTED_ACTIVITIES,
      info: {
        suggested_activity_group_id: groupId,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
