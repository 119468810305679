import React from 'react';

export const Cog = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="cogIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70705 2.497C10.037 2.86331 10.5069 3.07245 11 3.07245C11.493 3.07245 11.9629 2.86331 12.2929 2.497L13.1341 1.57399C13.6281 1.02725 14.4127 0.850485 15.0935 1.13256C15.7742 1.41464 16.2038 2.09454 16.1663 2.83046L16.1032 4.07536C16.0785 4.56646 16.2626 5.04509 16.6101 5.39304C16.9575 5.74098 17.4359 5.9258 17.927 5.90182L19.1719 5.83869C19.9073 5.80243 20.5861 6.23248 20.8675 6.91292C21.1488 7.59336 20.972 8.37721 20.4257 8.87092L19.4991 9.70679C19.1333 10.0372 18.9245 10.5072 18.9245 11.0002C18.9245 11.4931 19.1333 11.9631 19.4991 12.2935L20.4257 13.1294C20.9724 13.6234 21.1492 14.408 20.8671 15.0887C20.585 15.7695 19.9051 16.1991 19.1692 16.1616L17.9243 16.0985C17.4321 16.0732 16.9522 16.2577 16.6037 16.6062C16.2552 16.9547 16.0708 17.4345 16.0961 17.9267L16.1592 19.1716C16.1918 19.9037 15.7629 20.5779 15.0859 20.8584C14.409 21.139 13.6289 20.9659 13.1341 20.4254L12.2973 19.4998C11.9671 19.134 11.4973 18.9252 11.0044 18.9252C10.5116 18.9252 10.0418 19.134 9.71149 19.4998L8.87118 20.4254C8.37713 20.9685 7.59564 21.1435 6.91716 20.863C6.23869 20.5826 5.80888 19.9068 5.8425 19.1734L5.90652 17.9285C5.93184 17.4363 5.74737 16.9564 5.39887 16.6079C5.05037 16.2594 4.57049 16.075 4.07829 16.1003L2.83338 16.1634C2.09776 16.2018 1.41758 15.7731 1.13487 15.0929C0.852161 14.4127 1.02811 13.6282 1.57425 13.1338L2.49992 12.298C2.86577 11.9675 3.07455 11.4976 3.07455 11.0046C3.07455 10.5116 2.86577 10.0417 2.49992 9.71124L1.57425 8.87092C1.02995 8.37718 0.854254 7.59486 1.13517 6.91579C1.41609 6.23673 2.09312 5.80716 2.82716 5.84224L4.07206 5.90538C4.56522 5.93131 5.04619 5.74659 5.39521 5.39723C5.74424 5.04786 5.92848 4.56672 5.90208 4.07359L5.8425 2.82779C5.80808 2.09407 6.23759 1.41759 6.91627 1.13664C7.59494 0.855685 8.37692 1.03063 8.87118 1.57399L9.70705 2.497Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15.0021C13.21 15.0021 15.0015 13.2106 15.0015 11.0006C15.0015 8.79067 13.21 6.99915 11 6.99915C8.79006 6.99915 6.99854 8.79067 6.99854 11.0006C6.99854 13.2106 8.79006 15.0021 11 15.0021Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
