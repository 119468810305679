import React, { FC } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { queryHelpers } from '../../breakpoints/query-helpers';
import type { GDSBreakpoint, GDSBreakpointOrNumber } from '../../types';

export type GDSResponsiveProps = {
  children: React.ReactNode;
  query?: string;
  up?: GDSBreakpointOrNumber;
  down?: GDSBreakpointOrNumber;
  between?: [GDSBreakpointOrNumber, GDSBreakpointOrNumber];
  only?: GDSBreakpoint;
  not?: GDSBreakpoint;
};

type GDSQueryHelperKey = keyof typeof queryHelpers;

const getMediaQueryFromProps = (otherProps: any, query?: string) => {
  if (query) return query;

  // Filter out props that are queryHelpers function names, alphabetically
  const queryHelperPropKeys = Object.keys(otherProps)
    .filter((key) => Object.keys(queryHelpers).includes(key))
    .sort() as GDSQueryHelperKey[];

  // Recreate an object with only the filtered keys
  const queryHelperProps: {
    [key: string]: any; // I don't know what else I can do for TypeScript here. It's not worth further efforts when the keys and values are guaranteed.
  } = queryHelperPropKeys.reduce(
    (result, key) => ({ ...result, [key]: otherProps[key] }),
    {},
  );

  // Emit console warnings for 0 props or more than 1
  if (!query && queryHelperPropKeys.length === 0) {
    console.warn(
      'No prop passed. Please provide one of: up, down, between, only, not, query.',
    );
    return 'false';
  }

  if (
    (query && queryHelperPropKeys.length > 0) ||
    (!query && queryHelperPropKeys.length > 1)
  ) {
    console.warn(
      'Too many props passed. Please provide only one of: up, down, between, only, not, query.',
    );
    return 'false';
  }

  // If the prop is a queryHelpers function, pass its value to the function of the same name
  const helperKey = queryHelperPropKeys[0];
  if (helperKey) {
    const helperFunction = queryHelpers[helperKey];
    const value = queryHelperProps[helperKey];
    return helperFunction(value);
  }

  return 'false';
};

export const Responsive: FC<GDSResponsiveProps> = ({
  children,
  query,
  ...otherProps
}) => {
  const queryValue = getMediaQueryFromProps(otherProps, query);
  const matches = useMediaQuery(queryValue);
  return matches ? <div>{children}</div> : null;
};

Responsive.displayName = 'Responsive';
