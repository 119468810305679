import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { GetWalletItemIdResponse } from 'types/queries/get-wallet-item-id.types';

export const GET_WALLET_ITEM_ID_MESSAGE_TYPE = 'get_wallet_item_id';

export const getWalletItemId = async (
  userId?: string,
  deepLink?: string,
): Promise<GetWalletItemIdResponse> => {
  const info = {
    user_id: userId,
    deeplink: deepLink,
  };

  const params = {
    message_type: GET_WALLET_ITEM_ID_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
