import cross from 'assets/cross.svg';
import backArrow from 'assets/back-arrow.svg';
import forwardArrow from 'assets/forward-arrow.svg';
import loadingAnimation from 'assets/outcomes-friction-animation.gif';
import { AnimatedLeagueLogo } from '@leagueplatform/ui-kit';
import { AssessmentAssets } from './assessment.types';

export const ASSESSMENT_ASSETS_MAP: AssessmentAssets = {
  ANIMATED_LOGO: AnimatedLeagueLogo,
  BACK_ARROW: backArrow,
  CROSS: cross,
  FORWARD_ARROW: forwardArrow,
  HANDS_LOADING_ANIMATION: loadingAnimation,
};
