import { useQuery } from 'react-query';
import {
  getUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';

export const useGetUserProfile = () =>
  useQuery([GET_USER_PROFILE_MESSAGE_TYPE], () => getUserProfile(), {
    staleTime: Infinity,
  });
