import React, { useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_AND_TIME_FORMAT_NO_TIMEZONE } from '@leagueplatform/web-common';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  Box,
  UtilityText,
  ParagraphText,
  Divider,
  styled,
} from '@leagueplatform/genesis-core';
import { Avatar } from '@leagueplatform/web-common-components';
import { Message, Document } from '@leagueplatform/messaging-api';
import { MessageDocument } from './message-document.component';
import { MessageTextWithInlineLinks } from './message-text-with-inline-links.component';

const StyledTextWrapper = styled('span', {
  wordBreak: 'break-word',
});
export interface MessageBoxedProps {
  fromCurrentUser?: boolean;
  hasFocus: boolean;
  messageDetails: Message;
  senderName?: string;
  gridRef: React.RefObject<HTMLElement>;
  photoUrl?: string;
  initials: string;
}

export const MessageBoxed = ({
  fromCurrentUser,
  hasFocus,
  messageDetails,
  gridRef,
  photoUrl,
  initials,
  senderName,
}: MessageBoxedProps) => {
  const { formatDate, formatMessage } = useIntl();
  const [displayFocusBorder, setDisplayFocusBorder] = React.useState(true);
  const cellRef = React.useRef<HTMLDivElement>(null);

  const { text, sentAt, documents } = messageDetails;
  useEffect(() => {
    const isFocusWithinGrid = gridRef.current?.contains(document.activeElement);
    if (hasFocus && isFocusWithinGrid) {
      cellRef.current?.focus();
    }
  }, [hasFocus, gridRef]);
  const messageDocuments = documents?.data;
  const user = fromCurrentUser ? formatMessage({ id: 'YOU' }) : senderName;
  const formattedDate = formatDate(
    sentAt,
    SHORT_DATE_AND_TIME_FORMAT_NO_TIMEZONE,
  );
  // if cell is focused, include border on styled container
  // otherwise, only focus on the element inside
  return (
    <Box
      css={{
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '$thin',
        marginBottom: '$threeQuarters',
        width: '100%',
        '&:hover': {
          backgroundColor: '$highlightBackgroundSubdued',
        },
        '&:focus-within': {
          backgroundColor: '$highlightBackgroundSubdued',
          borderColor: displayFocusBorder
            ? '$interactiveFocusOuter'
            : 'transparent',
        },
      }}
    >
      <Divider css={{ marginY: '$none' }} />
      <Box css={{ width: '100%' }} role="gridcell">
        <Box
          css={{
            padding: '$one',
            // hide default styles as the container has focus within to indicate focus of the whole row
            '&[class*="GDS"]:focus': {
              outline: 'none',
              boxShadow: 'none',
            },
          }}
          tabIndex={hasFocus ? 0 : -1}
          ref={cellRef}
        >
          <StyledTextWrapper>
            <Box
              css={{
                marginBottom: '$threeQuarters',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                userInitials={initials}
                imageSrc={photoUrl}
                width="two"
                height="two"
                fontSize="caption"
                marginRight="half"
              />
              <Box>
                <UtilityText emphasis="emphasized">{user}</UtilityText>
                <ParagraphText size="xs">{formattedDate}</ParagraphText>
              </Box>
            </Box>
            {text && (
              <ParagraphText>
                <MessageTextWithInlineLinks
                  text={text}
                  fromCurrentUser={fromCurrentUser}
                />
              </ParagraphText>
            )}

            {messageDocuments?.map((document: Document) => (
              <React.Fragment key={document.downloadUrl}>
                <VisuallyHidden>
                  {formatMessage({ id: 'WITH_DOCUMENT' })}
                </VisuallyHidden>
                <Box css={{ marginTop: '$quarter' }}>
                  <MessageDocument
                    document={document}
                    hasFocus={hasFocus}
                    color="onSurface.text.primary"
                    fromCurrentUser={fromCurrentUser}
                    onFocus={() => setDisplayFocusBorder(false)}
                    onBlur={() => setDisplayFocusBorder(true)}
                    withBoxedStyling
                  />
                </Box>
              </React.Fragment>
            ))}
          </StyledTextWrapper>
        </Box>
      </Box>
      <Divider css={{ marginY: '$none' }} />
    </Box>
  );
};
