import { LegacyAuthCookieStore, LegacyAuthStore } from './legacy-auth-store';

let authStore = LegacyAuthCookieStore;

// Allow consumers to override the AuthCookieStore implementation
export function overrideLegacyAuthStore(newLegacyAuthStore: LegacyAuthStore) {
  authStore = newLegacyAuthStore;
}

export function getLegacyAuthValues() {
  const sessionId = authStore.get('SID2');
  const userId = authStore.get('U');
  const deviceToken = authStore.get(`DT2_${userId}`);

  return { sessionId, deviceToken };
}

export function hasLegacyAuthValues() {
  const { sessionId, deviceToken } = getLegacyAuthValues();

  return !!(sessionId && deviceToken);
}

type LegacyAuthProperties = {
  deviceToken?: string;
  sessionId?: string;
  userId?: string;
};

export function setLegacyAuthValues({
  deviceToken,
  sessionId,
  userId,
}: LegacyAuthProperties) {
  if (userId) {
    authStore.set('U', userId);
    if (deviceToken) {
      authStore.set(`DT2_${userId}`, deviceToken);
    }
  }
  if (sessionId) {
    authStore.set('SID2', sessionId);
  }
}

export function clearLegacyAuthValues() {
  authStore.remove('U');
  authStore.remove('SID2');
}
