import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { HealthDevice } from '@leagueplatform/dashboard-api';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';

const SUPPORTED_SOURCES = [
  'apple_health',
  'fitbit',
  'garmin',
  'google_fit_sdk',
  'ihealth',
  'omron',
  'polar',
  'samsung_health',
  'strava',
  'withings',
  'health_plan',
  'no_source_data',
] satisfies HealthDevice[];

type SupportedSource = (typeof SUPPORTED_SOURCES)[number];

export const isSupportedHealthDevice = (
  healthDevice: HealthDevice,
): healthDevice is SupportedSource =>
  SUPPORTED_SOURCES.includes(healthDevice as SupportedSource);

export const ICONS = {
  apple_health: DASHBOARD_ASSET_MAP.DASHBOARD_APPLE_HEALTH,
  fitbit: DASHBOARD_ASSET_MAP.DASHBOARD_FITBIT,
  garmin: DASHBOARD_ASSET_MAP.DASHBOARD_GARMIN,
  google_fit_sdk: DASHBOARD_ASSET_MAP.DASHBOARD_GOOGLE_FIT,
  ihealth: DASHBOARD_ASSET_MAP.DASHBOARD_I_HEALTH,
  omron: DASHBOARD_ASSET_MAP.DASHBOARD_OMRON,
  polar: DASHBOARD_ASSET_MAP.DASHBOARD_POLAR,
  samsung_health: DASHBOARD_ASSET_MAP.DASHBOARD_SAMSUNG_HEALTH,
  strava: DASHBOARD_ASSET_MAP.DASHBOARD_STRAVA,
  withings: DASHBOARD_ASSET_MAP.DASHBOARD_WITHINGS,
  health_plan: DASHBOARD_ASSET_MAP.DASHBOARD_HEALTH_PLAN,
  no_source_data: DASHBOARD_ASSET_MAP.DASHBOARD_NO_DATA_SOURCE,
  // bluetooth: DASHBOARD_ASSET_MAP.DASHBOARD_BLUETOOTH,
  // vital_snap: DASHBOARD_ASSET_MAP.DASHBOARD_VITAL_SNAP,
  // biotel_care: DASHBOARD_ASSET_MAP.DASHBOARD_BIO_TEL_CAR,
  // iglucose: DASHBOARD_ASSET_MAP.DASHBOARD_I_GLUCOSE,
  // lose_it: DASHBOARD_ASSET_MAP.DASHBOARD_LOSE_IT,
  // misfit: DASHBOARD_ASSET_MAP.DASHBOARD_MISFIT,
  // underarmour: DASHBOARD_ASSET_MAP.DASHBOARD_UNDER_ARMOUR,
  // yoo_fitness: DASHBOARD_ASSET_MAP.DASHBOARD_YOO,
};

export const useDataSourceConfigMap = () => {
  const { $tmap } = useIntl();

  return useMemo(() => {
    const source = $tmap({
      apple_health: 'APPLE_HEALTH',
      fitbit: 'FITBIT',
      garmin: 'GARMIN',
      google_fit_sdk: 'GOOGLE_FIT',
      ihealth: 'IHEALTH',
      omron: 'OMRON',
      polar: 'POLAR',
      samsung_health: 'SAMSUNG_HEALTH',
      strava: 'STRAVA',
      withings: 'WITHINGS',
      health_plan: 'HEALTH_PLAN',
      no_source_data: 'NO_DATA_SOURCES',
      // bluetooth: 'BLUETOOTH'
      // vital_snap: 'VITAL_SNAP',
      // biotel_care: 'BIOTEL_CARE',
      // iglucose: 'IGLUCOSE',
      // lose_it: 'LOSE_IT',
      // misfit: 'MISFIT',
      // underarmour: 'UNDERARMOUR'
      // yoo_fitness: 'YOO_FITNESS',
    });

    return {
      source,
      icon: (dataSource: keyof typeof ICONS) => ICONS[dataSource],
    };
  }, [$tmap]);
};
