import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ParagraphText,
  FormField,
  TextInput,
  Icon,
} from '@leagueplatform/genesis-core';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  VisuallyHidden,
  Link,
} from '@leagueplatform/genesis-commons';
import { COVERED, NOT_COVERED, PARTIAL } from './constants/coverage.constants';
import { CoverageIcon } from './components/icons.component';
import { CategoryGroup } from './components/category-group.component';
import { Category } from './components/category.component';
import { EmptySearch } from './components/empty-search.component';
import { useCoverage } from './hooks/use-coverage.hook';

const ListItem = genesisStyled(Box)(
  css({
    borderBottom: 'solid',
    borderBottomWidth: 'thin',
    borderColor: 'onSurface.border.subdued',

    '&:last-of-type': {
      borderBottom: 'none',
    },
  }),
);

interface CoverageProps {
  userId?: string;
  benefitId?: string;
  embedded?: boolean;
  autofocus?: boolean;
  employer?: boolean;
  benefitType?: string;
  onCategorySelect?: () => void;
}

export function Coverage({
  // userId & benefitId are needed here because of CoverageModal in the Enrollment flow
  userId,
  benefitId,
  embedded = false,
  autofocus = false,
  employer = false,
  onCategorySelect = () => {},
  // this will no longer be needed once WALLET-2567 is merged
  benefitType,
}: CoverageProps) {
  const {
    categories,
    expandedIds,
    isTree,
    coverage,
    displayQuery,
    isFullyExpanded,
    isFullyCollapsed,
    onQueryChange,
    onToggleExpand,
    onCollapseAll,
    onExpandAll,
  } = useCoverage(employer, benefitType, userId, benefitId);

  const { formatMessage } = useIntl();

  return (
    <Box>
      <Box
        as="form"
        role="search"
        onSubmit={(event: React.FormEvent) => event.preventDefault()}
      >
        <VisuallyHidden id="search-input-description">
          {formatMessage({
            id: 'COVERAGE_SEARCH_BEHAVIOUR_DESCRIPTION',
          })}
        </VisuallyHidden>
        <FormField
          id={formatMessage({
            id: 'SEARCH',
          })}
          label={formatMessage({
            id: 'SEARCH_COVERAGE',
          })}
        >
          <TextInput
            leadingContent={<Icon icon="interfaceSearch" />}
            id={formatMessage({
              id: 'SEARCH_COVERAGE',
            })}
            aria-label={formatMessage({
              id: 'SEARCH_COVERAGE',
            })}
            aria-describedby="search-input-description"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onQueryChange(event.target.value)
            }
            autoFocus={embedded || autofocus}
          />
        </FormField>
        <Box aria-live="polite">
          {categories && categories.length === 0 ? (
            <EmptySearch query={displayQuery} />
          ) : (
            <>
              <Flex
                marginTop="oneAndHalf"
                marginBottom="one"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap="one"
              >
                <Flex
                  alignItems="center"
                  aria-hidden="true"
                  gap="oneAndHalf"
                  role="presentation"
                >
                  {coverage.includes(COVERED) && (
                    <ParagraphText
                      css={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginBlockEnd: '0 !important',
                      }}
                    >
                      <CoverageIcon coverage={COVERED} embedded={embedded} />
                      <Box as="span" marginLeft="half">
                        {formatMessage({ id: 'COVERED' })}
                      </Box>
                    </ParagraphText>
                  )}
                  {coverage.includes(PARTIAL) && (
                    <ParagraphText
                      css={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginBlockEnd: '0 !important',
                      }}
                    >
                      <CoverageIcon coverage={PARTIAL} embedded={embedded} />
                      <Box as="span" marginLeft="half">
                        {formatMessage({ id: 'COVERED_WITH_RESTRICTIONS' })}
                      </Box>
                    </ParagraphText>
                  )}
                  {coverage.includes(NOT_COVERED) && (
                    <ParagraphText
                      css={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginBlockEnd: '0 !important',
                      }}
                    >
                      <CoverageIcon
                        coverage={NOT_COVERED}
                        embedded={embedded}
                      />
                      <Box as="span" marginLeft="half">
                        {formatMessage({ id: 'NOT_COVERED' })}
                      </Box>
                    </ParagraphText>
                  )}
                </Flex>
                {isTree && (
                  <Flex
                    alignItems="center"
                    display="flex"
                    flexShrink={0}
                    gap="one"
                  >
                    <Box as="fieldset" display="contents">
                      <ParagraphText
                        as="legend"
                        css={{ marginBlockEnd: '0 !important' }}
                      >
                        {formatMessage({ id: 'CATEGORIES' })}:
                      </ParagraphText>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: use a proper button */}
                      <Link
                        aria-disabled={isFullyExpanded}
                        aria-pressed={isFullyExpanded}
                        as="button"
                        background="none"
                        border="none"
                        disabled={isFullyExpanded}
                        onClick={onExpandAll}
                        tabIndex={isFullyExpanded ? -1 : 0}
                      >
                        {formatMessage({ id: 'EXPAND_ALL' })}
                      </Link>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: use a proper button */}
                      <Link
                        aria-disabled={isFullyCollapsed}
                        aria-pressed={isFullyCollapsed}
                        as="button"
                        background="none"
                        border="none"
                        disabled={isFullyCollapsed}
                        onClick={onCollapseAll}
                        tabIndex={isFullyCollapsed ? -1 : 0}
                      >
                        {formatMessage({ id: 'COLLAPSE_ALL' })}
                      </Link>
                    </Box>
                  </Flex>
                )}
              </Flex>
              {categories && (
                <Box
                  borderRadius="medium"
                  borderStyle="solid"
                  borderWidth="thin"
                >
                  {isTree ? (
                    <Box as="ul">
                      {categories?.map((category) => (
                        <ListItem as="li" key={category.unique_id}>
                          <CategoryGroup
                            id={category.unique_id}
                            name={category.category}
                            coverage={category.coverage}
                            categories={category.sub!}
                            isExpanded={
                              isFullyExpanded ||
                              expandedIds.includes(category.unique_id)
                            }
                            onToggleExpand={onToggleExpand}
                            onCategorySelect={onCategorySelect}
                            embedded={embedded}
                          />
                        </ListItem>
                      ))}
                    </Box>
                  ) : (
                    <Box as="ul">
                      {categories?.map((category) => (
                        <ListItem as="li" key={category.unique_id}>
                          <Category
                            id={category.unique_id}
                            name={category.category}
                            coverage={category.coverage}
                            description={category.description}
                            examples={category.examples}
                            restriction={category.restriction}
                            onCategorySelect={onCategorySelect}
                            embedded={embedded}
                          />
                        </ListItem>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
