type IsValidUrlOptions = Omit<URL, 'toString' | 'searchParams' | 'toJSON'>;

const defaultOptions: IsValidUrlOptions = {
  protocol: 'http',
  pathname: '',
  hash: '',
  host: '',
  hostname: '',
  href: '',
  origin: '',
  password: '',
  port: '',
  search: '',
  username: '',
};

/**
 * Given a `url` string and an `options` object, validate that each url property includes the value specified by it's corresponding option
 * @param url {String} the url string to validate
 * @param options {Object} validation options. If the url property includes the value specified by the corresponding option it is considered valid
 * @returns
 */
export const isValidUrl = (
  url: string | URL,
  options: Partial<IsValidUrlOptions> = defaultOptions,
) => {
  const consolidatedOptions = {
    ...defaultOptions,
    ...options,
  };
  try {
    const urlObject = new URL(url);

    // If our URL object includes every value specified by our options, return true
    const isValid = Object.entries(consolidatedOptions).every(
      ([optionName, optionValue]) =>
        urlObject?.[optionName as keyof IsValidUrlOptions]?.includes(
          optionValue,
        ),
    );

    return isValid;
  } catch {
    return false;
  }
};
