import React from 'react';
import { Flex, css, genesisStyled } from '@leagueplatform/genesis-commons';

export const SIDEBAR_MIN_WIDTH = '282px';

const gapValue = { gap: 'oneAndHalf' };

export const addSidebarSpacing = css({
  ...gapValue,
  flexDirection: 'column',
});

const SidebarItemContainer = genesisStyled(Flex)(css({ ...gapValue }));

type SidebarProps = {
  children: React.ReactNode;
};

export function Sidebar({ children }: SidebarProps) {
  return (
    <SidebarItemContainer
      flexDirection={{ _: 'column', phone: 'row', laptop: 'column' }}
      justifyContent="space-between"
      alignItems={{ phone: 'center', laptop: 'initial' }}
      minWidth={{ laptop: SIDEBAR_MIN_WIDTH }}
      maxWidth={{ laptop: '282px' }}
      width="100%"
      padding={{ _: 'oneAndHalf', laptop: 'none' }}
      borderStyle={{ _: 'solid', laptop: 'none' }}
      borderWidth="thin"
      borderColor="onSurface.border.subdued"
      bottom={{ _: '0', laptop: 'initial' }}
      left={{ _: '0', laptop: 'initial' }}
      position={{ _: 'fixed', laptop: 'initial' }}
      backgroundColor={{ _: 'surface.background.primary', laptop: 'initial' }}
      marginY={{ laptop: 'oneAndHalf' }}
      zIndex="1"
    >
      {children}
    </SidebarItemContainer>
  );
}
