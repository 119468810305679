import { SkeletonRectangle } from '@leagueplatform/genesis-core';
import { LoaderType } from '@leagueplatform/masonry-api';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import React from 'react';

export type LargeSkeletonWSNode = MasonryEngineNode<LoaderType.LARGE>;

export const LargeSkeleton = () => (
  <SkeletonRectangle
    height={{
      '@initial': '196px',
      '@tablet': '220px',
      '@mobileLandscape': '220px',
      '@mobile': '396px',
    }}
  />
);
