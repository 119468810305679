import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '../primitives.view';
import { useModalA11yProps } from './use-modal-a11y-props.hook';
import { getColourObject } from '../../utils/helpers';

const Container = styled(Box)`
  width: ${({ width }) => width || '80%'};
  max-width: ${({ maxWidth }) => maxWidth || '720px'};
  max-height: ${({ maxHeight }) => maxHeight || '90%'};
  overflow-y: auto;
  border: 1px solid ${({ theme }) => getColourObject(theme).darkGrey};
`;

export const ModalContainer = ({ children, ...props }) => {
  const modalAriaProps = useModalA11yProps();

  return (
    <Container {...modalAriaProps} {...props}>
      {children}
    </Container>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
