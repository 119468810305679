import { deserialise } from 'kitsu-core';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { TEAM_CHALLENGE_RANKS_PATH } from './constants/challenge-requests.constants';
import {
  TeamChallengeRanksResponseData,
  DeserialisedTeamChallengeRanksResponseData,
} from './types/team-challenge-rank-data.type';

export const getTeamChallengeRanks = async (
  challengeId: string,
  teamId?: string,
  errorContext?: ErrorContext,
): Promise<DeserialisedTeamChallengeRanksResponseData> => {
  const baseFetchPath = `${TEAM_CHALLENGE_RANKS_PATH}${challengeId}&page[size]=10&include=challenge-team`;
  const fetchTeamRanksPath = teamId?.length
    ? `${baseFetchPath}&filter[team]=${teamId}`
    : baseFetchPath;

  const response = await leagueFetch(fetchTeamRanksPath, {
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (!response.ok) {
    throw new Error(`getTeamChallengeRanks error: ${response.status}`);
  }
  const responseJson: TeamChallengeRanksResponseData = await response.json();
  return deserialise(responseJson);
};
