import { QuestionnaireItem } from 'fhir/r4';

export const handleItemTypeString = (
  item: QuestionnaireItem,
): QuestionnaireItem => item;

export const handleAnswerTypeString = (value: string) => [
  {
    valueString: value,
  },
];
