import type { Context, Scope } from '@sentry/types';
import type { ErrorContext } from './sentry.types';

export const EVENT_IGNORE_CRITERIA = [
  '<unknown>',
  'ChunkLoadError',
  /WebSocket closed with reason: \(1006\)/,
];

export const setSentryContext = (errorContext: ErrorContext, scope: Scope) => {
  if (errorContext.tags) scope.setTags(errorContext.tags);

  if (errorContext.ownershipTeam || errorContext.tags?.moduleName)
    scope.setTag(
      'ownershipTeam',
      errorContext.ownershipTeam ?? errorContext.tags?.moduleName,
    );

  if (errorContext.context) {
    Object.entries(errorContext.context).forEach(
      (contextData: (string | Context)[]) => {
        scope.setContext(contextData[0] as string, contextData[1] as Context);
      },
    );
  }

  return scope;
};
