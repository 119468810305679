import {
  useInfiniteQuery,
  QueryKey,
  UseInfiniteQueryOptions,
} from 'react-query';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { UseLeagueInfiniteWSQueryHookParams } from './types/use-league-infinite-ws-query-hook.types';

/**
 * @name useLeagueInfiniteWSQuery
 * @desc A wrapper around React Queries Infinite Query which abstracts away complexities that surface while using Leagues paginated Websocket API endpoints
 * @param { object } info meta data associated with the request
 * @param { string } message_type the message expected by the API
 * @param { string } responseKey the key in the response payload that represents the desired data eg: { your_response_key: Array(5) }
 * @param { object } options all options available in React Query Infinite Queries
 */

export function useLeagueInfiniteWSQuery<TData>(
  queryKey: QueryKey,
  {
    info,
    message_type,
    responseKey,
    offsetParam = 'offset',
  }: UseLeagueInfiniteWSQueryHookParams,
  options?: UseInfiniteQueryOptions<TData[], unknown, TData[]>,
) {
  const { limit } = info;
  return useInfiniteQuery<TData[]>(
    queryKey,
    async ({ pageParam = 0 }) => {
      const res = await SocketAsFetch.fetch({
        message_type,
        info: { ...info, [offsetParam]: pageParam },
      });

      return res[responseKey];
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // manual check to see if request has more results
        if (lastPage.length < limit) {
          return undefined;
        }
        // since we don't get pagination meta data in the payload, this manually determines the next cursor
        return pages.length * limit;
      },
      // consumers can leverage any of RQ options in their query
      ...options,
    },
  );
}
