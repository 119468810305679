import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { WearablesConsentForDataPointsResponse } from './types/wearables-consent-for-data-points-response.type';

export const getWearablesConsentForDataPoints = async (
  dataPoints: string[] = [],
  devices: string[] = [],
  errorContext?: ErrorContext,
): Promise<WearablesConsentForDataPointsResponse> =>
  SocketAsFetch.fetch(
    {
      message_type: 'get_wearables_consent_for_data_points',
      info: {
        data_points: dataPoints,
        devices,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
