import * as React from 'react';
import {
  BodyOne,
  Box,
  Caption,
  genesisStyled,
  css,
  Flex,
  CoverImage,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import documentIcon from 'assets/documentIcon.svg';

interface Document {
  id: string;
  fileName: string;
  lastUpdatedAt: string;
  downloadUrl: string;
  uploadedBy: string; // TODO: Find out if this field will exist
}

export interface DocumentCardProps {
  document: Document;
  icon?: string;
}

const StyledLink = genesisStyled(Box)(() =>
  css({
    display: 'inline-block',
    paddingX: 'threeQuarters',
    paddingY: 'one',
    transition: '0.2s all',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'interactive.background.hovered',
    },
  }),
);
const WrappedText = genesisStyled(BodyOne)`
  overflow-wrap: anywhere;
`;

/**
 * WARNING! WIP! DO NOT USE YET!
 * This Component is a WIP awaiting feedback from design, please do not use it!
 * It will be updated and finalized in https://everlong.atlassian.net/browse/PCHAT-441
 * The Document Card is a UI element that displays a card representing a document with a default icon, the document name, the updated date, and who it was uploaded by. The document can be selected in order to view it in a new window.
 * @param document - the document to be displayed
 * @param icon - the icon to be displayed alongside the document. Defaults to a document icon
 */
export const DocumentCard = ({
  document,
  icon = documentIcon,
}: DocumentCardProps) => {
  const { formatMessage, formatDate } = useIntl();
  const { fileName, lastUpdatedAt, downloadUrl, uploadedBy } = document;
  return (
    <Box
      as="li"
      borderColor="onSurface.border.subdued"
      borderRadius="medium"
      borderStyle="solid"
      borderWidth="thin"
    >
      <StyledLink as="a" href={downloadUrl} rel="noreferrer" target="_blank">
        <Flex alignItems="center">
          <CoverImage
            src={icon}
            size="oneAndHalf"
            minWidth="oneAndHalf"
            alt="" // no alt text for the generic document icon
            marginRight="one"
          />
          <VisuallyHidden>
            {/* TODO: update visually hidden label based on functionality of this card */}
            {formatMessage({ id: 'VIEW_DOCUMENT' })}
          </VisuallyHidden>
          <Flex flexDirection="column">
            <WrappedText as="span" marginBottom="quarter">
              {fileName}
            </WrappedText>
            <Caption as="span">
              {formatMessage(
                { id: 'UPLOADED_DOCUMENT' },
                {
                  date: formatDate(lastUpdatedAt, SHORT_DATE_FORMAT),
                  user: uploadedBy, // TODO: This uploaded by may get removed
                },
              )}
            </Caption>
          </Flex>
        </Flex>
      </StyledLink>
    </Box>
  );
};
