import styled from 'styled-components';

export const ImageViewerButton = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;
ImageViewerButton.displayName = 'ImageViewerButton';

export const CloseButton = styled(ImageViewerButton)`
  position: fixed;
  top: 0px;
  right: 0px;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  border-radius: 0 0 0 4rem;
  z-index: 1010;

  & > svg {
    position: relative;
    top: -4px;
    left: 8px;
  }
`;
CloseButton.displayName = 'CloseButton';

export const ActionButton = styled(ImageViewerButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  line-height: 1;
`;
ActionButton.displayName = 'ActionButton';
