import {
  ApiDelayConfig,
  DELAY_DISPLAY_STATES,
  DELAY_STATUS,
  ApiDelayControllerState,
  ApiDelayControllerPublic,
  Timer,
} from '../types/api-delay-controller.types';
import { DEFAULT_API_DELAY_CONFIG } from '../constants/api-delay-config.constants';
import { useApiDelayOverlay } from './use-api-delay-overlay.hook';
// Constants
const { ALWAYS_SHOW, NEVER_SHOW } = DELAY_DISPLAY_STATES;
const { IDLE, DELAY, ACTIVE, WAITING } = DELAY_STATUS;

/**
 * The api delay controller is intended to control UI for potentially slow api calls and should be implemented directly in our api layer. The `.init()` method returned from this hook can be called in either the query function or `onMutate` method. The `.finish()` method can be called when we receive any response, (`onError`, `onSuccess`)
 * @description We want to display some UI for slow api calls. We do so if the delay duration has elapsed for at least the minDuration until a response is received via the `.finish()` method.
 * @param {ApiDelayConfig} config
 * @example
 * ```typescript
 * const config: ApiDelayConfig = {
 *    delay: number,
 *    minDuration: number,
 *    maxDuration: number
 * }
 * ```
 * @returns an `init` method, an async `finish` method and `status`.
 */
export const useApiDelayController = (config: Partial<ApiDelayConfig> = {}) => {
  const { delay, minDuration, maxDuration } = {
    ...DEFAULT_API_DELAY_CONFIG,
    ...config,
  };
  const { setDelayOverlayActive } = useApiDelayOverlay(false);

  /**
   * @namespace state                                 – Hidden properties for the API delay controller
   * @property {string}   state.status                – The current status of the API Delay Controller. When we attempt to set this value, we compute what the next state should be based on the current state and use that value instead.
   * @property {boolean}  state.hasResponse           – A flag to indicate whether a response has been received. Upon setting this value, we also determine if the status should change too
   * @property {object}   state.timers                - Actions and state related to each timer
   * @property {object}   state.timers[DELAY]         - Run the delay timer asynchronously. Is interruptible.
   * @property {object}   state.timers[ACTIVE]        - Run the min duration timer asynchronously. Not interruptible.
   * @property {object}   state.timers[WAITING]       - Run the max duration timer asynchronously. Is interruptible.
   * @property {object}   state.timers[IDLE]          - Remove the overlay UI and cleanup timers if we have a response
   * @property {function} state.stateTransition       – Transition to the next state and runs it's associated action.
   * @property {function} state.runActionForStatus    – Run an action for a given status. Ex run a timer, or cleanup
   * @property {function} state.handleCleanup         – Will resolve all async timers early that have stopTimer functions and will cleanup any outstanding setTimeouts
   * @property {function} state.waitForPendingTimers  – Will determine which timers are pending and will return a list of those async timer promises
   */
  const state: ApiDelayControllerState = {
    status: IDLE,
    hasResponse: false,
    timers: {
      [DELAY]: {
        stopTimer: undefined, // Function to stop timer prematurely if it is running
        pendingTimer: undefined, // Reference to setTimeout promise if it is running
        activeTimer: undefined, // setTimeoutId if it is running
        run() {
          const handleDelay = (resolve: (value: DELAY_STATUS) => void) => {
            // Expose promise stopTimer to allow for timer interruption
            this.stopTimer = () => resolve(DELAY);

            // Initiate delay timer
            const timer = window.setTimeout(() => {
              // Cleanup timer
              this.activeTimer = window.clearTimeout(timer);
              // Transition timers
              state.stateTransition();
              // Resolve timer with the status it was completed with
              resolve(DELAY);
            }, delay);

            this.activeTimer = timer;
          };

          // Register currently running timer
          this.pendingTimer = new Promise(handleDelay);
        },
      },
      [ACTIVE]: {
        pendingTimer: undefined, // Reference to setTimeout promise if it is running
        activeTimer: undefined, // setTimeoutId if it is running
        run() {
          const handleMinDuration = (
            resolve: (value: DELAY_STATUS) => void,
          ) => {
            const timer = window.setTimeout(() => {
              // Cleanup timer
              this.activeTimer = window.clearTimeout(timer);
              // Transition timers
              state.stateTransition();
              // Resolve timer with the status it was completed with
              resolve(ACTIVE);
            }, minDuration);

            this.activeTimer = timer;
          };

          // Register currently running timer
          this.pendingTimer = new Promise(handleMinDuration);
        },
      },
      [WAITING]: {
        stopTimer: undefined, // Function to stop timer prematurely if it is running
        pendingTimer: undefined, // Reference to setTimeout promise if it is running
        activeTimer: undefined, // setTimeoutId if it is running
        run() {
          const handleMaxDuration = (
            resolve: (value: DELAY_STATUS) => void,
          ) => {
            // Expose promise stopTimer to allow for timer interruption
            this.stopTimer = () => resolve(WAITING);

            const timer = window.setTimeout(() => {
              // Cleanup timer
              this.activeTimer = window.clearTimeout(timer);
              // Transition timers
              state.stateTransition();
              // Resolve timer with the status it was completed with
              resolve(WAITING);
            }, maxDuration - minDuration);

            this.activeTimer = timer;
          };

          // Register currently running timer
          this.pendingTimer = new Promise(handleMaxDuration);
        },
      },
    },
    stateTransition() {
      const { status: currentStatus } = this;
      const nextStatus = this.getNextStatus();

      const isValidUpdate = currentStatus !== nextStatus || nextStatus === IDLE;
      if (isValidUpdate) {
        this.status = nextStatus;
        this.runActionForStatus(nextStatus);
      }
    },
    getNextStatus() {
      const { timers, status, hasResponse } = this;
      const timerIsActive = timers[status]?.activeTimer;
      switch (status) {
        case DELAY:
          if (hasResponse) return IDLE; // Interrupt timer on response
          return ACTIVE;
        case ACTIVE:
          if (timerIsActive) return ACTIVE; // Maintain status as long as the timer is active
          if (hasResponse) return IDLE; // When the timer is done and we have a response, shut down
          return WAITING;
        case WAITING:
          return IDLE;
        case IDLE:
        default:
          if (hasResponse || delay === NEVER_SHOW) return IDLE;
          if (delay === ALWAYS_SHOW) return ACTIVE;
          return DELAY;
      }
    },
    runActionForStatus(status) {
      const currentTimer = this.timers[status];
      switch (status) {
        case DELAY:
        case WAITING:
          currentTimer?.run();
          break;
        case ACTIVE:
          setDelayOverlayActive(true);
          currentTimer?.run();
          break;
        case IDLE:
        default:
          setDelayOverlayActive(false);
          // If we have a response and the status is IDLE, we should interrupt and clean up the pending timer
          if (this.hasResponse) {
            this.handleCleanup();
          }
          break;
      }
    },
    handleCleanup() {
      Object.values(this.timers).forEach(({ stopTimer, activeTimer }) => {
        // Resolve async timers early if the timer has exposed it's promise stopTimer
        if (stopTimer) {
          stopTimer();
        }

        // Clean timeouts if not yet done
        if (activeTimer) {
          window.clearTimeout(activeTimer);
        }
      });
    },
    async waitForPendingTimers() {
      const pendingTimerCallback = ({ pendingTimer }: Timer) => pendingTimer;
      const pendingTimers = Object.values(this.timers)
        .filter(pendingTimerCallback)
        .map(pendingTimerCallback);

      return Promise.all(pendingTimers);
    },
  };

  // Define the public interface
  const publicInterface: ApiDelayControllerPublic = {
    init() {
      state.hasResponse = false;
      state.stateTransition();
    },
    async finish() {
      state.hasResponse = true;
      state.stateTransition();
      const status = await state.waitForPendingTimers();
      return status;
    },
    get status() {
      return state.status;
    },
  };

  return publicInterface;
};
