import React, { useEffect } from 'react';
import {
  BackButtonArrow,
  PageHeaderBackground,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import {
  BodyOneSecondary,
  Box,
  Flex,
  HeadingOne,
} from '@leagueplatform/genesis-commons';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useHistory, useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { useProgramLibraryAnalytics } from 'hooks/use-program-library-analytics.hook';
import { HealthProgramsGallery } from 'components/health-programs-gallery.component';
import { useHealthProgramsCategory } from './hooks/use-health-programs-category-page.hook';

export const HealthProgramsCategoryPage = () => {
  const { categoryId } = useParams();

  // hooks
  const { isLoading, name, description, programs } = useHealthProgramsCategory({
    categoryId,
  });
  const { formatMessage } = useIntl();
  const history = useHistory();
  const programLibraryAnalytics = useProgramLibraryAnalytics();
  const documentTitle = `${formatMessage({
    id: 'PAGE_TITLE_HEALTH_PROGRAM_CATEGORY',
  })} - ${name}`;

  useDocumentTitle(documentTitle);

  useEffect(() => {
    if (name) {
      programLibraryAnalytics.sendViewProgramCategory(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  if (isLoading) {
    return <SkeletonBox height="600px" data-testid="IS_LOADING" />;
  }

  const handleBackClick = () => {
    programLibraryAnalytics.sendLeaveProgramCategory(name);
    history.goBack();
  };

  return (
    <Flex
      padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
      marginX="auto"
      maxWidth={1440}
    >
      <PageHeaderBackground />
      <Flex flexDirection={{ _: 'column', phone: 'row' }}>
        <Box>
          <BackButtonArrow
            fontSize="heading3"
            color="onSurface.text.primary"
            marginY="half"
            marginRight="one"
            onClick={handleBackClick}
          />
        </Box>
        <Box>
          <HeadingOne
            marginBottom="five"
            fontSize={{ _: 'heading2', tablet: 'heading1' }}
          >
            {name}
          </HeadingOne>
          {description && (
            <BodyOneSecondary
              color="onSurface.text.subdued"
              marginBottom="three"
            >
              {description}
            </BodyOneSecondary>
          )}
          <HealthProgramsGallery
            programs={programs}
            analyticsContext={{
              pageContext: 'health campaign category',
              programCategory: name,
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
