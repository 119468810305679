import React from 'react';
import PropTypes from 'prop-types';
import {
  QuietButton,
  genesisStyled,
  css,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { Modal, Icon, ICONS } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

const StyledModalContainer = genesisStyled(Modal.Container)(
  css({
    backgroundColor: 'surface.background.primary',
    position: 'relative',
    maxWidth: 458,
    borderRadius: 'medium',
    overflow: 'scroll',
  }),
);

const StyledModalBody = genesisStyled(Modal.Body)(({ bodyStyleOverrides }) =>
  css({
    padding: 'twoAndHalf',
    ...bodyStyleOverrides,
  }),
);
const CloseButton = genesisStyled(QuietButton)(
  css({
    position: 'absolute',
    right: 0,
    padding: 'half',
    margin: 'one',
  }),
);

export const HealthProgramsProgramDetailsModal = ({
  onClose,
  children,
  'aria-labelledby': ariaLabelledBy,
  bodyStyleOverrides,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal.Wrapper onClose={onClose}>
      <StyledModalContainer aria-labelledby={ariaLabelledBy}>
        <CloseButton onClick={onClose}>
          <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
          <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
        </CloseButton>
        <StyledModalBody bodyStyleOverrides={bodyStyleOverrides}>
          {children}
        </StyledModalBody>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
};

HealthProgramsProgramDetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  bodyStyleOverrides: PropTypes.shape({
    padding: PropTypes.string,
  }),
};
HealthProgramsProgramDetailsModal.defaultProps = {
  bodyStyleOverrides: {},
};
