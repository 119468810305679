import React from 'react';
import {
  WidgetDataV2,
  WidgetDataV2ForCustomComponent,
  getObservabilityContext,
} from '@leagueplatform/masonry-api';
import { captureMessage } from '@leagueplatform/observability';
import { useGetConfig } from 'hooks/use-get-config.hook';
import { DefaultWidget } from 'components/default-widget/default-widget';
import { CustomWidgetWithWidgetData } from 'components/custom-widget/custom-widget-with-widget-data.component';
import { WidgetTypeToComplexComponentMap } from 'constants/widget-type-to-complex-component-map';
import { logWarning } from 'utils/log-error';

type WidgetProviderFromWidgetTypeV2Props = {
  data: WidgetDataV2<any> | WidgetDataV2ForCustomComponent<any>;
};

export const WidgetProviderFromWidgetTypeV2 = ({
  data,
}: WidgetProviderFromWidgetTypeV2Props): React.ReactElement | null => {
  const config = useGetConfig();
  const { type: widgetType, module } = data;

  /** Show custom component when there is module & type key in the response. */
  if (widgetType && module)
    return (
      <CustomWidgetWithWidgetData
        data={data as WidgetDataV2ForCustomComponent<any>}
      />
    );

  const WidgetComponent = WidgetTypeToComplexComponentMap[widgetType];
  if (!WidgetComponent) {
    logWarning(
      `Unsupported widget type - %c${widgetType}%c found in container.`,
    );
    captureMessage(
      `Unsupported widget type - ${widgetType} found in container.`,
      getObservabilityContext({
        tags: {
          container: config.appId,
        },
      }),
    );
    return null;
  }

  // Return null of there are no attributes for a default widget
  if (!data.attributes) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <DefaultWidget component={WidgetComponent} data={data} config={config} />
  );
};
