import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colour } from 'ui-kit/theme';

export const QuestionIcon = styled(({ isHovered, ...props }) => (
  <FontAwesomeIcon {...props} />
)).attrs({
  icon: 'question-circle',
  color: colour('purple'),
})``;

QuestionIcon.propTypes = {
  isHovered: PropTypes.bool,
};
QuestionIcon.defaultProps = {
  isHovered: false,
};
