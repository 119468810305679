import { getConfig } from '@leagueplatform/core';
import {
  type MasonryEngineActionHandlerMap,
  type MasonryEngineActionHandler,
} from '@leagueplatform/masonry-engine';
import { isExternalUrl } from '@leagueplatform/web-common';
import type {
  MasonryDriverAction,
  MasonryDeeplinkAction,
  MasonryDismissAction,
  MasonryUndismissAction,
  MasonryAnalyticsAction,
  MasonryOnLoadAction,
  MasonryReloadAction,
  MasonryHideAction,
  MasonryShowAction,
  MasonryToggleVisibilityAction,
} from '@leagueplatform/masonry-renderers';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  postMasonryNodeDismiss,
  deleteMasonryNodeDismiss,
} from '@leagueplatform/masonry-api';

const trackMasonryAnalytics = (event: EVENT_NAME, widgetProperties: object) =>
  trackAnalyticsEvent(event, {
    ...widgetProperties,
  });

const DeeplinkActionHandler: MasonryEngineActionHandler<
  MasonryDeeplinkAction,
  MasonryDriverAction
> = (action) => {
  const config = getConfig();
  const { url } = action.payload;
  const isExternalLink = isExternalUrl(url);

  if (isExternalLink) {
    action?.event?.preventDefault();

    // if there is a handler in the app config and the action is an external link, use the custom handler from config
    if (config.config.core?.customMethods?.handleLink)
      config.config.core.customMethods.handleLink(url);
    // if there is no custom handler in the config, open an external link in a new tab
    else window.open(url, '_blank', 'noreferrer');
  }
};

const DismissActionHandler: MasonryEngineActionHandler<
  MasonryDismissAction,
  MasonryDriverAction
> = (action, emitAction) => {
  const nodeId = action.ancestry[0];

  if (nodeId) {
    return Promise.all([
      // Send a POST request to the API to dismiss the node
      postMasonryNodeDismiss(nodeId),
      // Emit a hide action to hide this node
      emitAction({
        type: 'hide' as const,
        namespace: 'default',
        ancestry: action.ancestry,
        payload: {
          nodeIds: [nodeId],
        },
      }),
    ]);
  }

  return Promise.resolve();
};

const UndismissActionHandler: MasonryEngineActionHandler<
  MasonryUndismissAction,
  MasonryDriverAction
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = async (action, _, stateController) => {
  // Send a DELETE request to the API to undismiss the node
  deleteMasonryNodeDismiss(action.payload.nodeIds);

  action.payload.nodeIds?.forEach((nodeId: string) => {
    stateController.setNodeState(nodeId, (state) => ({
      ...state,
      isVisible: true,
    }));
  });
};

// Hide Nodes action handler. Payload requires an array of node ids
const HideActionHandler: MasonryEngineActionHandler<
  MasonryHideAction,
  MasonryDriverAction
> = (action, _, stateController) => {
  action.payload.nodeIds?.forEach((nodeId: string) => {
    stateController.setNodeState(nodeId, (state) => ({
      ...state,
      isVisible: false,
    }));
  });
};

// Show Nodes action handler. Payload requires an array of node ids
const ShowActionHandler: MasonryEngineActionHandler<
  MasonryShowAction,
  MasonryDriverAction
> = (action, _, stateController) => {
  action.payload.nodeIds?.forEach((nodeId: string) => {
    stateController.setNodeState(nodeId, (state) => ({
      ...state,
      isVisible: true,
    }));
  });
};

// Show/Hide Nodes action handler based on the previous state. Payload requires an array of node ids
const ToggleVisibilityActionHandler: MasonryEngineActionHandler<
  MasonryToggleVisibilityAction,
  MasonryDriverAction
> = (action, _, stateController) => {
  action.payload.nodeIds?.forEach((nodeId: string) => {
    stateController.setNodeState(nodeId, (state) => ({
      ...state,
      isVisible: !state.isVisible,
    }));
  });
};

const AnalyticsActionHandler: MasonryEngineActionHandler<
  MasonryAnalyticsAction,
  MasonryDriverAction
> = async (action) => {
  const { event_name: eventName, payload } = action.payload;
  if (eventName && payload) trackMasonryAnalytics(eventName, payload);
};

// Am empty action handler is required for onLoad actions as emitAction requires an action handler for every action passed to it
const OnLoadActionHandler: MasonryEngineActionHandler<
  MasonryOnLoadAction,
  MasonryDriverAction
> = async () => {};

/**
 * Empty reload action handler provided to use events for this action
 */
const ReloadActionHandler: MasonryEngineActionHandler<
  MasonryReloadAction,
  MasonryDriverAction
> = (params, _, __, messagingController) =>
  Promise.all(
    params.payload.nodeIds.map((nodeId) =>
      messagingController.sendMessage(nodeId, {
        type: 'reload',
        payload: {},
      }),
    ),
  );

export const masonryDriverActionHandlerMap: MasonryEngineActionHandlerMap<MasonryDriverAction> =
  {
    deeplink: DeeplinkActionHandler,
    dismiss: DismissActionHandler,
    undismiss: UndismissActionHandler,
    analytics: AnalyticsActionHandler,
    onLoad: OnLoadActionHandler,
    hide: HideActionHandler,
    show: ShowActionHandler,
    reload: ReloadActionHandler,
    toggleVisibility: ToggleVisibilityActionHandler,
  };
