import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useWalletItemsSectionedQuery } from 'pages/wallet-landing/hooks/queries/use-wallet-items-sectioned-query.hook';
import { parseBenefitCardStatuses } from 'pages/wallet-landing/utils/parse-benefit-card-statuses.util';
import { sortBenefitSectionsByStatus } from '../util/sort-benefit-sections-by-status.util';

export const useNavigationSections = () => {
  const { formatMessage } = useIntl();
  const { data: queryData, isLoading } = useWalletItemsSectionedQuery();
  const itemSectioned = queryData?.items_sectioned;
  const parsedCardStatuses = useMemo(
    () => parseBenefitCardStatuses(itemSectioned || []),
    [itemSectioned],
  );

  const sortedBenefitSections = useMemo(
    () => sortBenefitSectionsByStatus(parsedCardStatuses, itemSectioned),
    [parsedCardStatuses, itemSectioned],
  );

  const heading =
    itemSectioned?.find((item) => item.section_id === 'benefits')?.name.text ??
    formatMessage({ id: 'COVERAGE' });

  return {
    cardStatuses: parsedCardStatuses,
    isLoading,
    benefitSections: sortedBenefitSections,
    itemSectioned: itemSectioned || [],
    heading,
  };
};
