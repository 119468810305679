import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Box, Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import { Modal } from '@leagueplatform/ui-kit';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { CTAMediaObject } from '../cta-media-object/cta-media-object.component';
import { EyebrowEarnPoints } from '../eyebrow-earn-points/eyebrow-earn-points.component';

const Fade = genesisStyled.div`
  transition: opacity 400ms ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

export const AssessmentExitModal = ({
  setShowExit,
  exitScreen,
  ctaHandler,
  ctaAnalytics,
  secondaryAnalytics,
  completionPoints,
  sendPageAnalytics,
}) => {
  useEffect(() => {
    if (sendPageAnalytics) {
      sendPageAnalytics();
    }
  }, [sendPageAnalytics]);
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  return (
    <Modal.Wrapper onClose={() => setShowExit(false)}>
      <Flex
        width="100%"
        height="100%"
        position="fixed"
        backgroundColor="surface.background.primary"
        zIndex="sticky"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        aria-live="assertive"
      >
        <Box as="main" maxWidth="790px" padding="twoAndHalf">
          <Transition timeout={250} in appear unmountOnExit>
            {(state) => (
              <Fade state={state}>
                <CTAMediaObject
                  eyebrow={
                    completionPoints > 0 && (
                      <EyebrowEarnPoints completionPoints={completionPoints} />
                    )
                  }
                  title={exitScreen.title}
                  description={exitScreen.description}
                  ctaHandler={ctaHandler}
                  secondaryHandler={() => {
                    secondaryAnalytics();
                    setShowExit(false);
                  }}
                  ctaText={exitScreen.buttonOk}
                  secondaryText={exitScreen.buttonNo}
                  ctaAnalytics={ctaAnalytics}
                  image={exitScreen.image || getContentUrl(exitScreen.imageId)}
                  isMobile={isMobile}
                />
              </Fade>
            )}
          </Transition>
        </Box>
      </Flex>
    </Modal.Wrapper>
  );
};

AssessmentExitModal.propTypes = {
  setShowExit: PropTypes.func.isRequired,
  exitScreen: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonOk: PropTypes.string.isRequired,
    buttonNo: PropTypes.string.isRequired,
    imageId: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  ctaHandler: PropTypes.func,
  ctaAnalytics: PropTypes.func,
  sendPageAnalytics: PropTypes.func,
  secondaryAnalytics: PropTypes.func,
  completionPoints: PropTypes.number,
};

AssessmentExitModal.defaultProps = {
  ctaHandler: null,
  ctaAnalytics: () => undefined,
  secondaryAnalytics: () => undefined,
  sendPageAnalytics: null,
  completionPoints: 0,
};
