import Masonry from '@leagueplatform/masonry';
import { SCREEN_NAMES, usePageViewAnalytics } from '@leagueplatform/analytics';
import React from 'react';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  ABOUT_ME_MASONRY_API_VERSION,
  HEALTH_REPORT_APP_ID,
} from 'constants/masonry';
import {
  BackButtonArrow,
  MainPageContainer,
} from '@leagueplatform/web-common-components';
import {
  HeadingText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Link, useHistory } from '@leagueplatform/routing';
import { useAboutMeConfig } from '../../about-me.config';

export const HealthReportPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  usePageViewAnalytics({ screen_name: SCREEN_NAMES.HEALTH_REPORT_PAGE });
  useDocumentTitle(formatMessage({ id: 'HEALTH_REPORT_PAGE_HEADING' }));

  const { healthReportMasonryId = HEALTH_REPORT_APP_ID } = useAboutMeConfig();

  const HealthReportMasonry = Masonry.init({
    appId: healthReportMasonryId,
    analytics: {
      productArea: SCREEN_NAMES.HEALTH_REPORT_PAGE,
      screenName: SCREEN_NAMES.HEALTH_REPORT_PAGE,
    },
    apiVersion: ABOUT_ME_MASONRY_API_VERSION,
  });

  return (
    <MainPageContainer>
      <StackLayout
        orientation={{
          '@initial': 'vertical',
          '@laptop': 'horizontal',
          '@desktop': 'horizontal',
        }}
      >
        <StackItem css={{ alignSelf: 'start' }}>
          <BackButtonArrow
            as={Link}
            to="#"
            marginY="one"
            marginRight="one"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              history.goBack();
            }}
          />
        </StackItem>
        <StackLayout
          spacing="$one"
          horizontalAlignment="center"
          css={{
            '@laptop': { paddingRight: '$three' },
            '@desktop': { paddingRight: '$three' },
          }}
        >
          <HeadingText size="xxl" level="1">
            {formatMessage({ id: 'HEALTH_REPORT_PAGE_HEADING' })}
          </HeadingText>
          <HealthReportMasonry.Provider />
        </StackLayout>
      </StackLayout>
    </MainPageContainer>
  );
};
