import * as React from 'react';
import isToday from 'date-fns/isToday';
import {
  HealthMetric,
  MetricType,
  MetricUnit,
} from '@leagueplatform/dashboard-api';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import { backfillChartData } from 'hooks/use-get-health-metrics.hook';
import { Measurement } from '../types/measurement';

type UseIsSwitchingViewParams = {
  isPreviousData: boolean;
  chartView: CHART_VIEW;
};

/**
 * When switching views we want to show an intermediate empty state.  This
 * Keeps track when we switch views and gives us a way to know when we should render
 * the empty chart. This is necessary since we are using the same `useQuery` observer
 * with `keepPreviousData`.  When we switch views it would show weekly data on the monthly
 * chart until the API responds with new data.
 * TODO: This is a bit of a hack.  A couple better ideas when time permits would be to
 * - Use separate `useQuery` observers for weekly vs monthly chart.
 * - Manually delete the cache when switching views.
 */
function useIsSwitchingView({
  isPreviousData,
  chartView,
}: UseIsSwitchingViewParams) {
  const [previousView, setPreviousView] = React.useState(chartView);

  React.useEffect(() => {
    if (!isPreviousData && chartView !== previousView) {
      setPreviousView(chartView);
    }
  }, [previousView, chartView, isPreviousData]);

  const isSwitchingView = previousView !== chartView;

  return isSwitchingView;
}

function useTodaysValue(data?: HealthMetric[]) {
  const [todaysValue, setTodaysValue] = React.useState<number>();

  React.useEffect(() => {
    if (
      data?.length &&
      isToday(new Date(data[0].timestamp)) &&
      !todaysValue &&
      data[0].value !== null
    ) {
      setTodaysValue(data[0].value);
    }
  }, [data, todaysValue]);

  return todaysValue;
}

export function useLastUpdatedValue(healthMetrics: HealthMetric[]) {
  const [lastUpdated, setLastUpdated] = React.useState<string>();

  React.useEffect(() => {
    if (healthMetrics.length && !lastUpdated) {
      // Find the first non-backfilled datum.
      for (let i = 0; i < healthMetrics.length; i += 1) {
        if (healthMetrics[i].source !== 'backfilled') {
          setLastUpdated(healthMetrics[i].timestamp);
          break;
        }
      }
    }
  }, [healthMetrics, lastUpdated]);

  return lastUpdated;
}

/**
 * Average all health metric data points.
 */
function getAverageValue(healthMetrics: HealthMetric[]) {
  let average = 0;

  if (healthMetrics.length > 0)
    average =
      healthMetrics.reduce(
        (sum, healthMetric) =>
          healthMetric.value ? sum + healthMetric.value : sum,
        0,
      ) / healthMetrics.length;

  return average;
}

function useMeasurementType(
  metric: MetricType,
  unit: MetricUnit,
  healthMetrics: HealthMetric[],
) {
  const { formatUnit } = useLocaleUnitFormatter();

  const measurementType = formatUnit(
    metric,
    unit,
    healthMetrics.length > 0 && healthMetrics[0].value
      ? healthMetrics[0].value
      : 0,
  ).toLocaleLowerCase();

  return measurementType;
}

export function useAverageMeasurement(
  metric: MetricType,
  unit: MetricUnit,
  healthMetrics: HealthMetric[],
) {
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const measurementType = useMeasurementType(metric, unit, healthMetrics);

  const averageValue = getAverageValue(healthMetrics);

  const averageMeasurement: Measurement = {
    type: measurementType,
    value: formatMetricValue(averageValue, metric) ?? '',
  };

  return averageMeasurement;
}

export function useTodaysMeasurement(
  metric: MetricType,
  unit: MetricUnit,
  healthMetrics: HealthMetric[],
) {
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const measurementType = useMeasurementType(metric, unit, healthMetrics);

  const todaysValue = useTodaysValue(healthMetrics);
  const todaysMeasurement: Measurement = {
    type: measurementType,
    value: todaysValue
      ? formatMetricValue(todaysValue, metric) ?? ''
      : undefined,
  };

  return todaysMeasurement;
}

type UseGroomedHealthMetricsParams = {
  healthMetrics: HealthMetric[];
  metric: MetricType;
  unit: MetricUnit;
  metricsFrom: Date;
  chartView: CHART_VIEW;
  hasPreviousPage: boolean;
};

export function useGroomedHealthMetrics({
  healthMetrics,
  metric,
  unit,
  metricsFrom,
  chartView,
  hasPreviousPage,
}: UseGroomedHealthMetricsParams) {
  // True while the data is being fetched after switching views (weekly/monthly).
  const isSwitchingView = useIsSwitchingView({
    isPreviousData: hasPreviousPage,
    chartView,
  });

  const groomedHealthMetrics = isSwitchingView
    ? /* If the chart data has already been loaded and the user is switching views,
       * we back fill the data for the targeted view until data from the back-end returns.
       * this allows us to paint the chart with empty data and estimate
       * the axis values causing less shifting.
       */
      backfillChartData(
        { type: metric, unit },
        [],
        metricsFrom,
        chartView === CHART_VIEWS.WEEKLY ? 7 : 30,
      ).reverse()
    : // Shallow copy and reverse since chart data is returned by the API in ascending chronological order.
      [...healthMetrics].reverse();

  return groomedHealthMetrics;
}
