{
  "dragDescriptionKeyboard": "Натиснете „Enter“, за да започнете да плъзгате.",
  "dragDescriptionKeyboardAlt": "Натиснете Alt + Enter, за да започнете да плъзгате.",
  "dragDescriptionLongPress": "Натиснете продължително, за да започнете да плъзгате.",
  "dragDescriptionTouch": "Натиснете двукратно, за да започнете да плъзгате.",
  "dragDescriptionVirtual": "Щракнете, за да започнете да плъзгате.",
  "dragItem": "Плъзни {itemText}",
  "dragSelectedItems": "Плъзни {count, plural, one {# избран елемент} other {# избрани елемента}}",
  "dragSelectedKeyboard": "Натиснете Enter, за да плъзнете {count, plural, one {# избран елемент} other {# избрани елементи}}.",
  "dragSelectedKeyboardAlt": "Натиснете Alt и Enter, за да плъзнете {count, plural, one {# избран елемент} other {# избрани елементи}}.",
  "dragSelectedLongPress": "Натиснете продължително, за да плъзнете {count, plural, one {# избран елемент} other {# избрани елементи}}.",
  "dragStartedKeyboard": "Започна плъзгане. Натиснете „Tab“, за да се придвижите до целта, след което натиснете „Enter“ за пускане или натиснете „Escape“ за отмяна.",
  "dragStartedTouch": "Започна плъзгане. Придвижете се до целта, след което натиснете двукратно, за да пуснете.",
  "dragStartedVirtual": "Започна плъзгане. Придвижете се до целта, след което щракнете или натиснете „Enter“ за пускане.",
  "dropCanceled": "Пускането е отменено.",
  "dropComplete": "Пускането е завършено.",
  "dropDescriptionKeyboard": "Натиснете „Enter“ за пускане. Натиснете „Escape“ за отмяна на плъзгането.",
  "dropDescriptionTouch": "Натиснете двукратно за пускане.",
  "dropDescriptionVirtual": "Щракнете за пускане.",
  "dropIndicator": "индикатор за пускане",
  "dropOnItem": "Пусни върху {itemText}",
  "dropOnRoot": "Пусни върху",
  "endDragKeyboard": "Плъзгане. Натиснете „Enter“ за отмяна на плъзгането.",
  "endDragTouch": "Плъзгане. Натиснете двукратно за отмяна на плъзгането.",
  "endDragVirtual": "Плъзгане. Щракнете за отмяна.",
  "insertAfter": "Вмъкни след {itemText}",
  "insertBefore": "Вмъкни преди {itemText}",
  "insertBetween": "Вмъкни между {beforeItemText} и {afterItemText}"
}
