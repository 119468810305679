import { useCallback } from 'react';
import { useModalA11yProps } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { GetQuickActionsVersion } from '@leagueplatform/care-discovery-api';
import { useModalParam } from './use-modal-param.hook';
import { useQuickActions } from './use-quick-actions.hook';
import { MenuItem } from '../types/care-discovery.types';

interface ModalAriaPropsTypes {
  'aria-label': string;
  'aria-modal': string;
  role: string;
}

export const useGetCareTriage = (version?: GetQuickActionsVersion) => {
  const { formatMessage } = useIntl();
  const { matchModalPath, closeModal, linkToModalParam } = useModalParam();
  const { isLoading, actionItems, menuItems } = useQuickActions(version);
  const modalAriaProps = useModalA11yProps(
    formatMessage({ id: 'GET_CARE' }),
  ) as ModalAriaPropsTypes;
  const focusRef = useCallback((node) => node?.focus?.(), []);

  const isOpen = Boolean(matchModalPath('get-care'));
  const topicMatch = matchModalPath('get-care/:topicId/:subTopicId?');
  const {
    topicId,
    subTopicId,
  }: {
    topicId?: string;
    subTopicId?: string;
  } = topicMatch?.params || {};

  let topic;
  let topicMenuItems;
  let backButtonUrl;

  if (topicId && menuItems?.length) {
    topic = menuItems?.find((item: MenuItem) => item.id === topicId);
    backButtonUrl = linkToModalParam('get-care');
    topicMenuItems = topic?.detailPage?.menuItems;
  }

  if (subTopicId) {
    topic = topicMenuItems?.find((item: MenuItem) => item.id === subTopicId);
    backButtonUrl = linkToModalParam(`get-care/${topicId}`);
  }

  const hasBackButton = Boolean(topic);

  return {
    isOpen,
    closeModal,
    isLoading,
    actionItems,
    menuItems,
    modalAriaProps,
    focusRef,
    topic,
    topicId,
    backButtonUrl,
    hasBackButton,
  };
};
