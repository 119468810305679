import React from 'react';
import {
  Label,
  CoverImage,
  Image,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { Field } from 'formik';
import { PROFILE_PICTURE_SIZE } from 'constants/challenges.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

export interface AvatarRadioOptionProps {
  image: string;
  alt: string;
  selected?: boolean;
  value: string;
  disabled: boolean;
}

export const AvatarRadioOption = ({
  image,
  alt,
  selected,
  value,
  disabled,
}: AvatarRadioOptionProps) => {
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const inputId = `avatar_input_${value}`;
  const checkmark = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_CHECKMARK,
  ) as string;

  return (
    <>
      <VisuallyHidden>
        <Field
          id={inputId}
          type="radio"
          name="selectedAvatar"
          value={value}
          onFocus={() =>
            labelRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
          disabled={disabled}
        />
      </VisuallyHidden>
      <Label
        htmlFor={inputId}
        borderRadius="circle"
        size={PROFILE_PICTURE_SIZE}
        margin="threeQuarters"
        padding="quarter"
        position="relative"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(selected && {
          borderColor: 'success.border.default',
          borderWidth: 'thicker',
          padding: 'none',
        })}
        ref={labelRef}
      >
        <CoverImage src={image} size="100%" alt={alt} borderRadius="circle" />
        {selected && (
          <Image
            src={checkmark}
            alt=""
            position="absolute"
            bottom="quarter"
            right="quarter"
            width="oneAndHalf"
          />
        )}
      </Label>
    </>
  );
};
