import React from 'react';
import { Link as RouteLink } from '@leagueplatform/routing';
import {
  Flex,
  Box,
  HeadingFour,
  BodyOne,
  Link,
  BackgroundImage,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DEPENDENTS_ASSET_KEYS } from '../../types/dependents.types';

const memberScreenerUrl = '/member/screener';

function learnMoreClick() {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.WALLET,
    screen_name: 'dependents settings',
    detail: 'QLE - learn more',
    link: memberScreenerUrl,
    text_detail: 'QLE',
    text_sub_detail: 'QLE - help',
  });
}

export const AddDependentBanner = () => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      as="section"
      width={1}
      marginBottom="oneAndHalf"
      borderColor="decorative.brand.primary.bright"
      borderStyle="solid"
      borderWidth="thin"
      borderRadius="medium"
      backgroundColor="highlight.background.subdued"
      overflow="hidden"
    >
      <Box width="65%" padding="two">
        <HeadingFour marginBottom="half" as="h2">
          {formatMessage({
            id: 'HAVE_YOU_RECENTLY_EXPERIENCED_CHANGES',
          })}
        </HeadingFour>
        <BodyOne marginBottom="oneAndHalf">
          {formatMessage({ id: 'DID_YOU_RECENTLY' })}
        </BodyOne>
        <Link
          as={RouteLink}
          aria-label={formatMessage({ id: 'QLE_LINK_LABEL' })}
          to={memberScreenerUrl}
          onClick={() => {
            learnMoreClick();
          }}
        >
          {formatMessage({ id: 'LEARN_MORE' })}
        </Link>
      </Box>
      <BackgroundImage
        alt=""
        src={
          handleStaticAsset(
            DEPENDENTS_ASSET_KEYS.ADD_DEPENDENT_BANNER,
          ) as string
        }
        width="35%"
      />
    </Flex>
  );
};
