import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from '@leagueplatform/routing';
import { setOnboardingConfig } from 'queries/set-onboarding-config';
import { useOnboardingModuleConfig } from 'components/onboarding-module-config-context';

interface UseSetOnboardingProps {
  showOnboarding: boolean;
}

export const useSetOnboardingConfig = ({
  showOnboarding,
}: UseSetOnboardingProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { onOnboardingComplete } = useOnboardingModuleConfig();

  return useMutation(() => setOnboardingConfig({ showOnboarding }), {
    onSettled: () => {
      queryClient.invalidateQueries(['get_show_onboarding']);
      if (onOnboardingComplete) {
        onOnboardingComplete();
      } else {
        // to ensure we trigger the redirect logic in root app for b2b
        // please keep path to be / instead of /member
        history.push('/');
      }
    },
  });
};
