import * as React from 'react';
import { Button } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

type AddMetricDataButtonProps = {
  onClick?: () => void;
};

export function AddMetricDataButton({ onClick }: AddMetricDataButtonProps) {
  const { formatMessage } = useIntl();

  // TODO: Add the onClick handler when we implement the add-edit observation screen.
  return (
    <Button onClick={onClick} width="fillContainer">
      {formatMessage({
        id: 'ADD_DATA',
      })}
    </Button>
  );
}
