import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useOnEscape } from '../../utils/use-on-escape';
import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';

const TooltipContainer = genesisStyled('div')(({ inline, zIndex }) =>
  css({
    position: 'relative',
    display: inline ? 'inline-block' : 'block',
    zIndex: zIndex && zIndex,
  }),
);

const TooltipButton = genesisStyled(Box)(({ zIndex }) =>
  css({
    background: 'none',
    border: 'none',
    position: 'relative',
    '&:focus': {
      boxShadow: 'focusRing',
      outline: 'none',
    },
  }),
);

export const Tooltip = ({
  id,
  children,
  inline,
  onHover,
  zIndex,
  isHovered = false,
  tooltipText,
  describedBy,
}) => {
  const [isActive, setIsActive] = useState(isHovered);

  /**
   * on mouseleave, we wait a bit before hiding the tooltip.
   * Storing the this timeout here allows us to *clear it* if the cursor
   * re-enters the toolip content before the timeout is up.
   */
  const unHoverTimeoutRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    clearTimeout(unHoverTimeoutRef.current);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsActive(false);
    // When closing the tooltip set focus back to button
    buttonRef?.current.focus();
  }, [buttonRef]);

  const mouseEnterHandler = useCallback(() => {
    clearTimeout(unHoverTimeoutRef.current);
    setIsActive(true);
    onHover();
  }, [onHover]);

  const mouseLeaveHandler = useCallback(() => {
    unHoverTimeoutRef.current = setTimeout(() => {
      setIsActive(false);
    }, 250);
  }, []);

  const toggleTooltip = useCallback(() => {
    setIsActive((currentState) => {
      if (!currentState) {
        clearTimeout(unHoverTimeoutRef.current);
      }

      return !currentState;
    });
  }, []);

  useOnEscape(hideTooltip);

  useEffect(() => {
    setIsActive(isHovered);
  }, [isHovered]);

  const [firstChild, ...restOfChildren] = React.Children.toArray(children);

  return (
    <TooltipContainer
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      inline={inline}
      zIndex={isActive ? 2 : zIndex}
    >
      <TooltipButton
        as="button"
        // Stops the button from acting as a Submit Button
        // When button is rendered in a form
        type="button"
        aria-expanded={isActive}
        aria-controls={id}
        aria-label={tooltipText}
        onClick={toggleTooltip}
        ref={buttonRef}
        {...(describedBy && { 'aria-describedby': describedBy })}
      >
        {firstChild}
      </TooltipButton>
      {restOfChildren.map((child) => {
        return (
          child &&
          React.cloneElement(child, {
            isHovered: isActive,
            id,
          })
        );
      })}
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHovered: PropTypes.bool,
  inline: PropTypes.bool,
  onHover: PropTypes.func,
  zIndex: PropTypes.string,
  tooltipText: PropTypes.string,
  describedBy: PropTypes.string,
};

Tooltip.defaultProps = {
  isHovered: false,
  inline: false,
  onHover: noop,
  zIndex: null,
};
