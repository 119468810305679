import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  MetricType,
  MetricUnit,
  metricUnits,
} from 'types/activity-details-props.types';
import type { Formatter } from '@leagueplatform/locales';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { useMetricUnitTranslator } from 'hooks/use-metric-unit-translation';
import { isActiveActivity } from '../utils/activity-status-predicates';

interface PriorityActivityProgressProps {
  progress: number;
  goal: number;
  unit: MetricUnit;
  type: MetricType;
}

const digits: Record<MetricType, number> = {
  distance: 1,
  steps: 0,
  active_duration: 0,
  mindful_duration: 0,
  floors_climbed: 0,
  energy_burned: 0,
};

const styledText: Formatter = (msg) => (
  <UtilityText
    emphasis="emphasized"
    css={{
      color: '$onSurfaceTextSuccess',
    }}
  >
    {msg}
  </UtilityText>
);

// temporary mapping until Dynamic Dashboard is implemented.
function useLocaleUnitFormatter() {
  const { $tmap } = useIntl();

  const getMetricUnitTranlsation = useMetricUnitTranslator();

  const getUnit = $tmap<Extract<MetricType, 'steps' | 'floors_climbed'>>({
    steps: 'INSIGHT_CARD_UNIT_STEPS',
    floors_climbed: 'INSIGHT_CARD_UNIT_FLOORS_CLIMBED',
  });

  const formatUnit = (type: MetricType, unit: MetricUnit, value = 0) => {
    if (type === 'steps' || type === 'floors_climbed') {
      return getUnit(type, { count: value });
    }

    if (metricUnits.includes(unit)) {
      return getMetricUnitTranlsation(unit, { count: value });
    }

    return `${value} ${unit}`;
  };

  return { formatUnit };
}

function useLocaleMetricValueFormatter() {
  const { formatNumber } = useIntl();

  function formatMetricValue(
    value: number | null,
    type: MetricType,
  ): string | null {
    const maximumFractionDigits = digits[type];

    if (value === null) {
      return null;
    }

    return formatNumber(value, { maximumFractionDigits });
  }

  return { formatMetricValue };
}

export function VerifiableActivityProgress({
  progress,
  goal,
  unit,
  type,
}: PriorityActivityProgressProps) {
  const { formatMessage } = useIntl();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();
  if (!isActiveActivity) {
    return null;
  }
  return (
    <StackLayout spacing="$none">
      <ParagraphText as="span" emphasis="subtle">
        {formatMessage(
          { id: 'X_OF_Y_COMPLETED_WITH_UNIT' },
          {
            progress: formatMetricValue(progress, type),
            goal: formatMetricValue(goal, type),
            unit: formatUnit(type, unit, progress),
            span: styledText,
          },
        )}
      </ParagraphText>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        <StackItem growFactor={1}>
          <ProgressBar
            current={progress}
            total={goal}
            customBarBackgroundColor="interactive.border.disabled"
            customBarFillColor="success.border.default"
          />
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
}
