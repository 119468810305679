import React from 'react';
import type {
  ContainerCustomWidgetItemV2,
  ContainerWidgetItem,
} from '@leagueplatform/masonry-api';
import { useGetCustomWidgetModuleFromConfig } from 'hooks/use-get-custom-widget-module-from-config.hook';
import { getCustomWidgetComponent } from 'utils/custom-module-utils';
import { useGetConfig } from 'hooks/use-get-config.hook';

export interface CustomWidgetProps {
  widget: ContainerWidgetItem | ContainerCustomWidgetItemV2;
}

export const CustomWidget = ({ widget }: CustomWidgetProps) => {
  const config = useGetConfig();
  const moduleProvider = useGetCustomWidgetModuleFromConfig({
    moduleKey: widget.module,
  });

  const CustomWidgetComponent = getCustomWidgetComponent(
    moduleProvider,
    widget.id,
    config?.appId,
    widget.custom_type,
  );

  if (!CustomWidgetComponent) return null;

  return <CustomWidgetComponent widget={widget} />;
};
