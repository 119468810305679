import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  ParagraphText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';

interface InsightTrendProps {
  trend?: number;
}

export function InsightTrend({ trend }: InsightTrendProps) {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <Box
      css={{
        backgroundColor: '$surfaceBackgroundSecondary',
        padding: '$quarter',
        verticalAlign: 'middle',
        borderRadius: '$small',
      }}
    >
      {trend !== undefined && trend !== null ? (
        <>
          <ParagraphText
            css={{
              fontWeight: 'bold',
              display: 'inline',
            }}
            size="sm"
          >
            {formatNumber(trend, {
              style: 'percent',
              signDisplay: 'exceptZero',
            })}
          </ParagraphText>

          <ParagraphText
            css={{ display: 'inline', color: '$onSurfaceTextSubdued' }}
            size="xs"
          >
            {' '}
            {formatMessage({ id: 'SINCE_LAST_WEEK' })}
          </ParagraphText>
        </>
      ) : (
        <VisuallyHidden>
          {formatMessage({ id: 'NO_DATA_AVAILABLE' })}
        </VisuallyHidden>
      )}
    </Box>
  );
}
