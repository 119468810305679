import React, { useState } from 'react';
import { useConfigProperty } from '@leagueplatform/core';
import { useIntl } from '@leagueplatform/locales';
import { FontAwesomeIcon } from '@leagueplatform/ui-kit';
import {
  SubtleLink,
  Box,
  Flex,
  PrimaryButton,
  TextInput,
  BoxProps,
} from '@leagueplatform/genesis-commons';
import {
  VisuallyHidden,
  HeadingText,
  UtilityText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useLeagueCredit } from './hooks/use-league-credit.hook';

const TableCell = (props: BoxProps) => {
  const { children, color, fontWeight, textAlign } = props;
  return (
    <ParagraphText
      as="td"
      css={{
        color: color ? '$onSurfaceTextSubdued' : '',
        paddingTop: '$one',
        paddingBottom: '$one',
        paddingLeft: '$half',
        paddingRight: '$half',
        fontWeight: `${fontWeight}`,
        textAlign: `${textAlign}`,
      }}
    >
      {children}
    </ParagraphText>
  );
};

export const LeagueCredit = () => {
  const {
    balance,
    redeemedCodes,
    lifemarketUrl,
    learnMoreUrl,
    onSubmit: handleSubmit,
  } = useLeagueCredit();
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');
  const { formatMessage, formatMoney, formatDate } = useIntl();
  const [promoCodeValue, setPromoCodeValue] = useState('');

  return (
    <Box>
      <Flex
        backgroundColor="surface.background.secondary"
        borderRadius="medium"
        paddingY="one"
        paddingX="oneAndHalf"
        marginBottom="two"
        maxWidth={756}
      >
        <HeadingText
          level="1"
          size="xxl"
          as="p"
          css={{ fontFamily: '$bodyOne' }}
        >
          <UtilityText
            as="span"
            emphasis="emphasized"
            size="eyebrow"
            css={{
              width: '80px',
              marginRight: '$two',
              display: 'inline-block',
            }}
          >
            {formatMessage({ id: 'TOTAL_BALANCE' })}{' '}
          </UtilityText>
          {formatMoney(balance)}
        </HeadingText>
      </Flex>
      <HeadingText level="2" size="xl" css={{ marginBottom: '$one' }}>
        {formatMessage({ id: 'ABOUT' })}
      </HeadingText>
      {lifemarketUrl && (
        <ParagraphText emphasis="subtle" css={{ marginBottom: '$one' }}>
          {formatMessage(
            { id: 'LEAGUE_CREDIT_DESCRIPTION_1' },
            {
              lifemarketLink: (
                <SubtleLink
                  fontWeight="regular"
                  href={lifemarketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleLinkCallback(lifemarketUrl);
                  }}
                >
                  {formatMessage({ id: 'LIFEMARKET' })}
                </SubtleLink>
              ),
            },
          )}
        </ParagraphText>
      )}
      <ParagraphText emphasis="subtle" css={{ marginBottom: '$three' }}>
        {formatMessage(
          { id: 'LEAGUE_CREDIT_DESCRIPTION_2' },
          {
            learnMoreLink: (
              <SubtleLink
                fontWeight="regular"
                target="_blank"
                rel="noopener noreferrer"
                href={learnMoreUrl}
              >
                {formatMessage({ id: 'LEARN_MORE' })}
                <VisuallyHidden>
                  {formatMessage({ id: 'ABOUT' })}

                  {formatMessage({ id: 'LEAGUE_CREDIT' })}
                </VisuallyHidden>
              </SubtleLink>
            ),
          },
        )}
      </ParagraphText>
      <HeadingText size="xl" level="2" css={{ marginBottom: '$one' }}>
        {formatMessage({ id: 'DID_YOU_GET_A_PROMO_CODE' })}
      </HeadingText>
      <ParagraphText emphasis="subtle" css={{ marginBottom: '$two' }}>
        {formatMessage({ id: 'DID_YOU_GET_A_PROMO_CODE_DESCRIPTION' })}
      </ParagraphText>
      <Flex>
        <Flex
          as="form"
          className="add-code-form"
          onSubmit={(evt: React.SyntheticEvent) => {
            evt.preventDefault();
            handleSubmit(promoCodeValue);
          }}
        >
          <TextInput
            aria-label={formatMessage({ id: 'PROMO_CODE' })}
            className="promo-code-input"
            name="promocode"
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              setPromoCodeValue(event.currentTarget.value);
            }}
            placeholder={formatMessage({ id: 'ENTER_CODE' })}
          />
          <PrimaryButton
            height="100%"
            disabled={promoCodeValue.length === 0}
            type="submit"
            className="submit-promo-code"
            display="flex"
            aria-label={formatMessage({ id: 'SUBMIT' })}
          >
            <FontAwesomeIcon icon="tag" />
          </PrimaryButton>
        </Flex>
      </Flex>
      <Box marginTop="three">
        <Box width="100%" as="table">
          <Box as="caption">
            <HeadingText size="xl" level="2" css={{ textAlign: 'left' }}>
              {formatMessage({ id: 'CREDIT_INFORMATION' })}
            </HeadingText>
          </Box>
          {redeemedCodes?.length > 0 ? (
            <Box
              as="thead"
              fontSize="subtitle2"
              borderBottomWidth="thin"
              borderBottomColor="onSurface.border.subdued"
              borderBottomStyle="solid"
            >
              <Box as="tr">
                <Box
                  as="th"
                  paddingX="half"
                  paddingY="one"
                  textAlign="left"
                  width={1 / 2}
                >
                  <UtilityText
                    size="eyebrow"
                    css={{ color: '$onSurfaceTextPrimary' }}
                    as="span"
                  >
                    {formatMessage({ id: 'EXPIRY_DATE' })}
                  </UtilityText>
                </Box>
                <Box
                  as="th"
                  paddingX="half"
                  paddingY="one"
                  textAlign="right"
                  width={1 / 2}
                >
                  <UtilityText
                    size="eyebrow"
                    css={{ color: '$onSurfaceTextPrimary' }}
                    as="span"
                  >
                    {formatMessage({ id: 'AMOUNT' })}
                  </UtilityText>
                </Box>
              </Box>
            </Box>
          ) : null}
          <Box
            as="tbody"
            borderBottomWidth="thin"
            borderBottomColor="onSurface.border.subdued"
            borderBottomStyle="solid"
          >
            {redeemedCodes?.map((code) => (
              <Box
                as="tr"
                className="redeemed-promo-code"
                key={code.promo_code_id}
                width="100%"
              >
                <TableCell
                  as="td"
                  color="onSurface.text.subdued"
                  textAlign="left"
                >
                  {code.expires
                    ? formatDate(code.expires, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })
                    : formatMessage({ id: 'NO_EXPIRY_DATE' })}
                </TableCell>
                <TableCell textAlign="right">
                  {formatMoney({
                    F: code.balance,
                    C: code.currency,
                  })}
                </TableCell>
              </Box>
            ))}
          </Box>
          <Box as="tfoot" width="100%">
            <Box as="tr">
              <TableCell
                color="onSurface.text.subdued"
                fontWeight="bold"
                textAlign="left"
              >
                {formatMessage({ id: 'TOTAL_CREDIT' })}
              </TableCell>
              <TableCell as="td" fontWeight="bold" textAlign="right">
                {formatMoney(balance)}
              </TableCell>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
