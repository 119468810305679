import React, { useMemo, useState, useRef, useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import { useIntl } from '@leagueplatform/locales';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  HeadingTwo,
  Flex,
  useTheme,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import { HealthProgramsGallery } from '../../../../components/health-programs-gallery.component';
import { useHealthGoalPrograms } from '../../hooks/use-health-programs.hook';

export const HealthGoalPrograms = () => {
  const { formatMessage } = useIntl();
  const { data, isLoading } = useHealthGoalPrograms();
  const programCardRef = useRef(null);

  const sortedProgramsListByName = sortBy(data?.programs, 'name');
  const initialVisibleProgramsLength = 12;
  const isProgramsListGreaterThanTwelve =
    sortedProgramsListByName?.length > initialVisibleProgramsLength;

  const [numOfVisiblePrograms, setNumOfVisiblePrograms] = useState(
    initialVisibleProgramsLength,
  );
  const [firstNewProgramIndex, setFirstNewProgramIndex] =
    useState(numOfVisiblePrograms);

  const visibleHealthPrograms = useMemo(
    () => sortedProgramsListByName?.slice(0, numOfVisiblePrograms),
    [sortedProgramsListByName, numOfVisiblePrograms],
  );

  const isAllProgramsDisplayed =
    visibleHealthPrograms?.length === sortedProgramsListByName?.length;

  useEffect(() => {
    programCardRef?.current?.focus();
  }, [programCardRef, numOfVisiblePrograms]);

  const theme = useTheme();

  if (isLoading)
    return (
      <SkeletonBox
        height="600px"
        width="100%"
        marginBottom={theme.space.twoAndHalf}
      />
    );

  return (
    visibleHealthPrograms?.length > 0 && (
      <Flex flexDirection="column">
        <HeadingTwo paddingBottom="half" fontSize={{ phone: 'heading2' }}>
          {data?.name}
        </HeadingTwo>
        <div aria-live="polite">
          <HealthProgramsGallery
            programs={visibleHealthPrograms}
            firstNewProgramIndex={firstNewProgramIndex}
            ref={programCardRef}
          />
        </div>
        {isProgramsListGreaterThanTwelve && !isAllProgramsDisplayed && (
          <SubtleButton
            marginTop="twoAndHalf"
            width="166px"
            alignSelf="center"
            onClick={() => {
              setNumOfVisiblePrograms(
                numOfVisiblePrograms + initialVisibleProgramsLength,
              );
              setFirstNewProgramIndex(numOfVisiblePrograms);
            }}
          >
            {formatMessage({ id: 'SHOW_MORE' })}
          </SubtleButton>
        )}
        {isAllProgramsDisplayed && isProgramsListGreaterThanTwelve && (
          <SubtleButton
            marginTop="twoAndHalf"
            width="166px"
            alignSelf="center"
            onClick={() => {
              setNumOfVisiblePrograms(initialVisibleProgramsLength);
            }}
          >
            {formatMessage({ id: 'SHOW_LESS' })}
          </SubtleButton>
        )}
      </Flex>
    )
  );
};
