import type {
  GDSTheme,
  GDSTypographyStyle,
  GDSShadows,
  GDSShadow,
  StringTokens,
  NumberTokens,
  GDSColors,
} from '@leagueplatform/genesis-core';
import {
  toPx,
  toRem,
  getNegativeSpacing,
  getFontWeightNumberByName,
  getFontWeightTokenByNumber,
  getFallbackValues,
} from '@leagueplatform/genesis-core';
import { ClientTheme } from '../types';
import { getFocusShadows } from '../shadows';

const wordToNumber = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
};

const getMappedTypographyKey = (typographyKey: string) => {
  let mappedTypographyKey = typographyKey;
  Object.entries(wordToNumber).forEach(
    ([numberWord, numberValue]: [string, number]) => {
      const regexp = new RegExp(numberWord);
      mappedTypographyKey = mappedTypographyKey.replace(
        regexp,
        String(numberValue)
      );
    }
  );
  return mappedTypographyKey;
};

const getRemFontSizes = (themeInput: GDSTheme): ClientTheme['fontSizes'] => {
  const fontSizes: StringTokens = {};
  if (themeInput?.rem?.fontSizes) {
    Object.entries(themeInput.rem.fontSizes).forEach(
      ([fontSize, value]: [string, number]) => {
        const mappedFontSizeKey = getMappedTypographyKey(fontSize);
        fontSizes[mappedFontSizeKey] = String(value);
      }
    );
  }

  return toRem(fontSizes);
};

const getFontSizes = (themeInput: GDSTheme): ClientTheme['fontSizes'] => {
  const fontSizes: NumberTokens = {};
  if (themeInput?.typography) {
    Object.entries(themeInput.typography).forEach(
      ([typographyKey, { fontSize }]: [string, GDSTypographyStyle]) => {
        const mappedTypographyKey = getMappedTypographyKey(typographyKey);
        fontSizes[mappedTypographyKey] = fontSize;
      }
    );
  }

  return fontSizes;
};

const defaultFontWeights = {
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
};

const getRemLineHeights = (
  themeInput: GDSTheme
): ClientTheme['lineHeights'] => {
  const lineHeights: StringTokens = {};
  if (themeInput?.rem?.fontSizes) {
    Object.entries(themeInput.rem.lineHeights).forEach(
      ([lineHeight, value]: [string, number]) => {
        const mappedTypographyKey = getMappedTypographyKey(lineHeight);
        lineHeights[mappedTypographyKey] = String(value);
      }
    );
  }

  return toRem(lineHeights);
};

const getLineHeights = (themeInput: GDSTheme): ClientTheme['lineHeights'] => {
  const lineHeights: NumberTokens = {};
  if (themeInput?.typography) {
    Object.entries(themeInput.typography).forEach(
      ([typographyKey, { lineHeight }]: [string, GDSTypographyStyle]) => {
        const mappedLineHeightKey = getMappedTypographyKey(typographyKey);
        lineHeights[mappedLineHeightKey] = lineHeight;
      }
    );
  }

  return toPx(lineHeights);
};

const getShadows = (
  shadows: GDSShadows,
  colors: GDSColors
): ClientTheme['shadows'] => {
  const shadowTokens: StringTokens = {};
  Object.entries(shadows).forEach(
    ([shadowKey, { x, y, blur, spread, color }]: [string, GDSShadow]) => {
      shadowTokens[shadowKey] = `${x}px ${y}px ${blur}px ${spread}px ${color}`;
    }
  );
  const focusShadows = getFocusShadows(colors);
  return {
    ...shadowTokens,
    ...focusShadows,
  };
};

const getMappedFontWeight = ({ fontWeight }: GDSTypographyStyle) => {
  let fontWeightToken;

  if (typeof fontWeight === 'string') {
    const fontWeightValue = getFontWeightNumberByName(fontWeight as string);
    fontWeightToken = getFontWeightTokenByNumber(fontWeightValue);
    return fontWeightToken;
  }

  fontWeightToken = getFontWeightTokenByNumber(fontWeight);

  return fontWeight;
};

const getMappedFontFamily = (
  { fontFamily }: GDSTypographyStyle,
  fonts: ClientTheme['fonts']
) => {
  let font = fontFamily;
  Object.entries(fonts).forEach(([token, value]) => {
    if (font === value) {
      font = token;
    }
  });

  return font;
};

const mapTypographyTokenValues = (
  token: string,
  tokenValue: GDSTypographyStyle,
  { fonts, lineHeights, fontSizes }: any
) => ({
  ...tokenValue,
  fontSize: fontSizes[token],
  lineHeight: lineHeights[token],
  fontWeight: getMappedFontWeight(tokenValue),
  fontFamily: getMappedFontFamily(tokenValue, fonts),
  textTransform: tokenValue.textCase,
});

const getTypography = (theme: GDSTheme, commonsTheme) => {
  const typography = {};
  Object.entries(theme.typography).forEach(([token, tokenValue]) => {
    const mappedToken = getMappedTypographyKey(token);
    typography[token] = mapTypographyTokenValues(
      mappedToken,
      tokenValue,
      commonsTheme
    );
  });

  return typography;
};

export interface GenesisCommonsThemeOptions {
  useRems: boolean;
}

export const formatThemeForCommons = (
  theme: GDSTheme,
  { useRems }: GenesisCommonsThemeOptions = {
    useRems: false,
  }
): ClientTheme => {
  // Provide fallback values for missing tokens
  const resolvedTheme = getFallbackValues(theme);

  const {
    colors,
    spacing,
    borderRadii,
    fonts,
    shadows,
    fontWeights: coreFontWeights,
  } = resolvedTheme;
  const space = {
    ...spacing,
    ...getNegativeSpacing(spacing),
  };
  const fontSizes = useRems
    ? getRemFontSizes(resolvedTheme)
    : getFontSizes(resolvedTheme);
  const lineHeights = useRems
    ? getRemLineHeights(resolvedTheme)
    : getLineHeights(resolvedTheme);
  const fontWeights = coreFontWeights ?? defaultFontWeights;
  const commonsThemeBase = {
    colors,
    fonts,
    space,
    fontSizes,
    lineHeights,
    fontWeights,
  };

  return {
    ...commonsThemeBase,
    sizes: spacing,
    radii: borderRadii,
    shadows: getShadows(shadows, colors),
    typography: getTypography(theme, commonsThemeBase),
  };
};
