import { useHistory, useParams } from '@leagueplatform/routing';
import { useQueryClient, useMutation } from 'react-query';
import {
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
  GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
  GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
  quitHealthGoalProgram,
} from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { SHOW_PULSE_CHECK_MODAL } from 'constants/health-program-modal.constants';
import { useGetHealthProgram } from './use-get-health-program.hook';

export const useQuitHealthProgram = (userProgramId) => {
  const { programId } = useParams();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { data: programData } = useGetHealthProgram(programId);
  const program = programData?.program || {};
  const { campaign_content_config: contentConfig } = program;
  const pulseCheckUrl = contentConfig?.pulse_checks?.[0]?.ctaUrl;

  return useMutation(() => quitHealthGoalProgram(userProgramId), {
    onSuccess: () => {
      if (!pulseCheckUrl) {
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'PROGRAM_REMOVED',
          contentId: 'YOU_WILL_NO_LONGER_SEE_IN_JOURNEY',
        });
      }
    },
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_STATE_TITLE',
        contentId: 'PROGRAM_REMOVE_ERROR',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE);
      queryClient.invalidateQueries(GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE);
      queryClient.invalidateQueries(
        GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
      );
      if (pulseCheckUrl) {
        history.push(JOURNEY_ROUTES.journeyHome(), {
          programId,
          type: SHOW_PULSE_CHECK_MODAL,
        });
      } else {
        history.push(JOURNEY_ROUTES.journeyHome());
      }
    },
  });
};
