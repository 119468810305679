import {
  getFaqLinks,
  GET_FAQ_LINKS_MESSAGE_TYPE,
} from '@leagueplatform/wallet-api';
import { useQuery } from 'react-query';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { FaqQueryResponse, FaqSection } from '../../types/faq.types';
import { getNormalizedFaqItems } from '../../utils/get-normalized-faq-items.util';

export function useFaqLinksQuery(benefitProductType: string) {
  const { userId } = useWalletParams();

  return useQuery<FaqQueryResponse, unknown, FaqSection[]>(
    [GET_FAQ_LINKS_MESSAGE_TYPE, userId, benefitProductType],
    () => getFaqLinks(benefitProductType, userId),
    // TODO: Allow query to take RQ options
    { select: (data) => getNormalizedFaqItems(data) },
  );
}
