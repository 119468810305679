import { getPreferredFirstName } from 'utils/utils';
import { useGetUserProfile } from './use-get-user-profile.hook';

/**
 * Returns the preferred name of a member and falls back to the first name
 * if no preferred name exists for the user.
 * From apps/league-b2b/src/apps/member-experience/pages/HomeFeed/home-feed.selectors.js
 * @param {object} userProfile - Object containing user's preferredFirstName & firstName
 * preferredFirstName is undefined if none exist for the user
 * @returns {string} - preferred first name or first name
 */

export const useGetUserProfileData = () => {
  const { data } = useGetUserProfile();

  const preferredFirstName = getPreferredFirstName(data);

  return {
    avatar: data?.userProfile?.avatar,
    preferredFirstName,
    lastName: data?.userProfile?.lastName || null,
    data,
  };
};
