import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  ImageMessage,
  Button,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import * as React from 'react';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';

export interface EndScreenProps {
  onClick: () => void;
  status: 'success' | 'error' | 'idle' | 'loading';
}

export const EndScreen = ({ onClick, status }: EndScreenProps) => {
  const isSuccess = status === 'success';
  const isLoading = status === 'loading';
  const { formatMessage } = useIntl();
  const SUCCESS_IMAGE = handleStaticAsset(
    WALLET_ASSET_KEYS.ORDER_NEW_CARD_SUCCESS,
  ) as string;
  const ERROR_IMAGE = handleStaticAsset(
    WALLET_ASSET_KEYS.ORDER_NEW_CARD_ERROR,
  ) as string;

  const logScreenLoaded = useWalletDetailScreenLoaded;
  if (isSuccess) {
    logScreenLoaded(BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SUCCESS);
  }

  return (
    <Box css={{ padding: '$none $two' }}>
      <ImageMessage
        image={isSuccess ? SUCCESS_IMAGE : ERROR_IMAGE}
        imageGap="$twoAndHalf"
      >
        <StackLayout horizontalAlignment="center" spacing="$half">
          <HeadingText level="3" size="lg">
            {isSuccess
              ? formatMessage({ id: 'SUCCESS' })
              : formatMessage({ id: 'ERROR_STATE_TITLE' })}
          </HeadingText>

          <ParagraphText>
            <ParagraphText
              as="span"
              css={{ display: 'block', textAlign: 'center' }}
            >
              {isSuccess
                ? formatMessage({ id: 'MEMBER_CARD_ON_ITS_WAY' })
                : formatMessage({ id: 'ERROR_HEADING' })}
            </ParagraphText>
            <ParagraphText
              as="span"
              css={{ display: 'block', textAlign: 'center' }}
            >
              {isSuccess
                ? formatMessage({ id: 'MEMBER_CARD_ARRIVAL' })
                : formatMessage({ id: 'TRY_LATER' })}
            </ParagraphText>
          </ParagraphText>

          <Button
            onClick={onClick}
            width="fillContainer"
            css={{ marginTop: '$twoAndHalf' }}
            loading={isLoading}
            disabled={isLoading}
          >
            {isSuccess
              ? formatMessage({ id: 'CLOSE' })
              : formatMessage({ id: 'RESUBMIT' })}
          </Button>
        </StackLayout>
      </ImageMessage>
    </Box>
  );
};
