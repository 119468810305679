import { useUrlParam } from '@leagueplatform/web-common';

export const useModalParam = () => {
  const { urlParam, matchParamPath, setParam, removeParam, linkToParam } =
    useUrlParam('modal');

  return {
    modalParam: urlParam,
    matchModalPath: matchParamPath,
    openModalToPath: setParam,
    closeModal: removeParam,
    linkToModalParam: linkToParam,
  };
};
