import React from 'react';
import { TextAction } from 'components/text-action/text-action';
import { HeadingText } from 'components/typography/heading-text/heading-text';
import { UtilityText } from 'components/typography/utility-text/utility-text';
import { Button } from 'components/button/button';

export type Tag = {
  component: string | React.ElementType;
  props?: Record<string, string | number | object>;
};

export type GDSHTMLTagOptions = Record<string, Tag>;

// API format:
// 1. the html tag, eg h1,
// 2. the component to re-render it as,
// 3. and the props to spread on that component
export const defaultHTMLTagOptions: GDSHTMLTagOptions = {
  a: {
    component: TextAction,
  },
  b: {
    component: 'span',
    props: {
      as: 'strong',
      css: {
        fontWeight: 'bold',
      },
    },
  },
  p: {
    component: UtilityText,
    props: {
      as: 'p',
      css: {
        '> a': {
          textDecoration: 'underline',
        },
      },
    },
  },
  h1: {
    component: HeadingText,
    props: { level: '1', size: 'xxl' },
  },
  h2: {
    component: HeadingText,
    props: { level: '2', size: 'xl' },
  },
  h3: {
    component: HeadingText,
    props: { level: '3', size: 'lg' },
  },
  h4: {
    component: HeadingText,
    props: { level: '4', size: 'md' },
  },
  h5: {
    component: HeadingText,
    props: { level: '5', size: 'sm' },
  },
  h6: {
    component: HeadingText,
    props: { level: '6', size: 'xs' },
  },
  button: {
    component: Button,
  },
  ul: {
    component: UtilityText,
    props: {
      as: 'ul',
      css: {
        listStyle: 'revert !important',
        marginBlockEnd: '$oneAndHalf',
        padding: '0 0 0 18px',
      },
    },
  },
  ol: {
    component: UtilityText,
    props: {
      as: 'ol',
      css: {
        typography: '$bodyOne',
        listStyle: 'revert',
        marginBlockEnd: '$oneAndHalf',
        padding: '0 0 0 18px',
      },
    },
  },
  em: {
    component: 'em',
    props: { style: { fontStyle: 'italic' } },
  },
  i: {
    component: 'em',
    props: { style: { fontStyle: 'italic' } },
  },
  sup: {
    component: UtilityText,
    props: {
      as: 'sup',
      color: '$onSurfaceTextSubdued',
      size: 'eyebrow',
    },
  },
  sub: {
    component: UtilityText,
    props: {
      as: 'sub',
      color: '$onSurfaceTextSubdued',
      size: 'eyebrow',
    },
  },
};
