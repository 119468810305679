import React from 'react';
import {
  DEFAULT_LOCALE,
  supportedLocaleMapping,
} from '@leagueplatform/locales';
import { DropdownItem } from './language-selector-dropdown-item.component';
import {
  DROPDOWN_MEGA_TOOLTIP_WIDTH,
  SPACE_BELOW_BUTTON_AND_ARROW,
  SPACE_RIGHT_OF_DROPDOWN,
} from './language-selector-dropdown.constants';
import { DropdownTooltip } from './dropdown-tooltip.component';

interface DropDownProps {
  isOpen?: boolean;
  setLanguage: (locale: string) => void;
  selectedLanguage: string;
  countryCode: string;
}

export const Dropdown = ({
  isOpen = false,
  setLanguage,
  selectedLanguage,
  countryCode,
}: DropDownProps) => (
  <DropdownTooltip
    position="absolute"
    as="ul"
    width={DROPDOWN_MEGA_TOOLTIP_WIDTH}
    backgroundColor="surface.background.primary"
    boxShadow="dropdown"
    border="thin"
    top={SPACE_BELOW_BUTTON_AND_ARROW}
    right={SPACE_RIGHT_OF_DROPDOWN}
    borderRadius="medium"
    padding="one"
    isOpen={isOpen}
    id="language_selector_dropdown_menu"
    role="menu"
    hidden={!isOpen}
  >
    {supportedLocaleMapping[countryCode] ? (
      supportedLocaleMapping[countryCode].map((locale: string) => (
        <DropdownItem
          locale={locale}
          isSelected={selectedLanguage === locale}
          key={locale}
          setLanguage={() => {
            setLanguage(locale);
            // reloading page to refetch strings from backend once new language is selected.
            document.location.reload();
          }}
        />
      ))
    ) : (
      <DropdownItem
        locale={DEFAULT_LOCALE}
        isSelected={selectedLanguage === DEFAULT_LOCALE}
        setLanguage={() => {
          setLanguage(DEFAULT_LOCALE);
        }}
      />
    )}
  </DropdownTooltip>
);
