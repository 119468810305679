{
  "dragDescriptionKeyboard": "Appuyez sur Entrée pour commencer le déplacement.",
  "dragDescriptionKeyboardAlt": "Appuyez sur Alt + Entrée pour commencer à faire glisser.",
  "dragDescriptionLongPress": "Appuyez de manière prolongée pour commencer à faire glisser.",
  "dragDescriptionTouch": "Touchez deux fois pour commencer le déplacement.",
  "dragDescriptionVirtual": "Cliquez pour commencer le déplacement.",
  "dragItem": "Déplacer {itemText}",
  "dragSelectedItems": "Déplacer {count, plural, one {# élément sélectionné} other {# éléments sélectionnés}}",
  "dragSelectedKeyboard": "Appuyez sur Entrée pour faire glisser {count, plural, one {# élément sélectionné} other {# éléments sélectionnés}}.",
  "dragSelectedKeyboardAlt": "Appuyez sur Alt + Entrée pour faire glisser {count, plural, one {# élément sélectionné} other {# éléments sélectionnés}}.",
  "dragSelectedLongPress": "Appuyez de manière prolongée pour faire glisser {count, plural, one {# élément sélectionné} other {# éléments sélectionnés}}.",
  "dragStartedKeyboard": "Déplacement commencé. Appuyez sur Tabulation pour accéder à une cible de dépôt, puis appuyez sur Entrée pour déposer, ou appuyez sur Échap pour annuler.",
  "dragStartedTouch": "Déplacement commencé. Accédez à une cible de dépôt, puis touchez deux fois pour déposer.",
  "dragStartedVirtual": "Déplacement commencé. Accédez à une cible de dépôt, puis cliquez ou appuyez sur Entrée pour déposer.",
  "dropCanceled": "Dépôt annulé.",
  "dropComplete": "Dépôt terminé.",
  "dropDescriptionKeyboard": "Appuyez sur Entrée pour déposer. Appuyez sur Échap pour annuler le déplacement.",
  "dropDescriptionTouch": "Touchez deux fois pour déposer.",
  "dropDescriptionVirtual": "Cliquez pour déposer.",
  "dropIndicator": "indicateur de dépôt",
  "dropOnItem": "Déposer sur {itemText}",
  "dropOnRoot": "Déposer sur",
  "endDragKeyboard": "Déplacement. Appuyez sur Entrée pour annuler le déplacement.",
  "endDragTouch": "Déplacement. Touchez deux fois pour annuler le déplacement.",
  "endDragVirtual": "Déplacement. Cliquez pour annuler le déplacement.",
  "insertAfter": "Insérer après {itemText}",
  "insertBefore": "Insérer avant {itemText}",
  "insertBetween": "Insérer entre {beforeItemText} et {afterItemText}"
}
