import { useConfigProperty } from '@leagueplatform/core';

const defaultConfig = {
  maxFileSize: 20971520, // 20 MB
  allowedFileTypes:
    'image/png, image/jpeg, image/heic, application/pdf, image/bmp, image/tiff, image/gif, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  allowedFileTypesErrorList:
    '.ppt, .pptx, .doc, .docx, .xls, .xlsx, .csv, .pdf, .png, .jpeg, .jpg, .heic, .gif, .tiff, .tif, .bmp',
  allowedImageTypes: [
    'png',
    'image/png',
    'jpg',
    'image/jpg',
    'jpeg',
    'image/jpeg',
    'bmp',
    'image/bmp',
    'tiff',
    'image/tiff',
    'gif',
    'image/gif',
    'heic',
    'image/heic',
  ],
  messageInputCharacterLength: 10000,
};

export const useMessagingConfig = () =>
  useConfigProperty('messaging', defaultConfig);
