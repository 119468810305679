import * as React from 'react';
import {
  useRouteMatch,
  Switch,
  Redirect,
  Route,
} from '@leagueplatform/routing';

import {
  getModulePath,
  LEAGUE_MODULE_NAMES,
} from '@leagueplatform/league-routes';
import { AboutMeLandingPage } from 'pages/about-me-landing-page';
import {
  DashboardRoutes,
  useIsDashboardEnabled,
} from '@leagueplatform/dashboard';
import { HealthReportPage } from 'pages/health-report-page/health-report-page';

export const AboutMeRoutes = () => {
  const { path } = useRouteMatch();
  const { isDashboardEnabled } = useIsDashboardEnabled();
  return (
    <Switch>
      {/* The Apps and Devices screen doesn't exist on the web,
        we instead stay on the about me page and show the Apps and Devices modal. */}
      <Redirect
        from={`${path}/apps-and-devices`}
        to={{ pathname: path, state: { showAppsAndDevicesModal: true } }}
      />
      <Route exact path={path} component={AboutMeLandingPage} />
      {isDashboardEnabled ? (
        <Route
          path={`${getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}/insights`}
          component={DashboardRoutes}
        />
      ) : null}
      <Route
        path={`${getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}/hra-report`}
        component={HealthReportPage}
      />
    </Switch>
  );
};
