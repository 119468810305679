import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_FAQ_LINKS_MESSAGE_TYPE = 'get_faq_links';

export const getFaqLinks = async (
  benefitProductType: string,
  userId?: string,
) => {
  const info = {
    user_id: userId,
    benefit_product_type: benefitProductType,
  };

  const params = {
    message_type: GET_FAQ_LINKS_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
