import { archetypeLightTheme } from '@leagueplatform/genesis-core';
import { formatThemeForCommons } from './utils/format-theme-for-commons';
import { themeWithGlobalTokens } from '../utils/themeWithGlobalTokens';

export const commonsArchetypeLightTheme =
  formatThemeForCommons(archetypeLightTheme);

export const commonsArchetypeLightThemeWithGlobalTokens = themeWithGlobalTokens(
  commonsArchetypeLightTheme
);
