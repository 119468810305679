/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Ref, forwardRef, FC, isValidElement } from 'react';
import { useResponsiveProp } from 'hooks/use-responsive-prop';
import { VisuallyHidden } from 'components/visually-hidden/visually-hidden';
import type { GDSInputLabelProps } from '../types';
import { styled } from '../../../theme';
import { labelLegendStyles } from '../utilities';
import { LabelText } from './label-text';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

const LabelBase = styled('label', {
  ...labelLegendStyles,
  display: 'flex',
  flexWrap: 'wrap',
});

export const InputLabel: FC<GDSInputLabelProps> = forwardRef(
  (
    {
      htmlFor,
      hideLabel,
      children,
      required,
      tooltip,
      className,
      css,
      ...props
    }: GDSInputLabelProps,
    ref: Ref<HTMLLabelElement>,
  ) => {
    const responsiveHideLabel = useResponsiveProp(hideLabel);

    if (responsiveHideLabel)
      return (
        <VisuallyHidden>
          <label htmlFor={htmlFor} ref={ref} {...props}>
            <LabelText required={required}>{children}</LabelText>
          </label>
        </VisuallyHidden>
      );

    return (
      <GenesisCoreInspector displayName="InputLabel">
        <LabelBase
          htmlFor={htmlFor}
          ref={ref}
          className={['GDS-input-label', className].join(' ')}
          css={css}
          {...props}
        >
          <LabelText required={required}>{children}</LabelText>
          {isValidElement(tooltip) ? tooltip : null}
        </LabelBase>
      </GenesisCoreInspector>
    );
  },
);

InputLabel.displayName = 'InputLabel';
