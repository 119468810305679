import {
  isBeforeEnrollmentPeriod,
  isEnrollmentOpen,
} from '@leagueplatform/challenges';
import { ChallengeTypes } from '@leagueplatform/health-journey-api';
import { arrayToTag, SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { IntlFormatters } from 'react-intl';
import {
  ChallengesHealthProgramsCardData,
  HealthProgramsCardData,
} from 'types/health-programs-response-data.type';

export const getHealthContents = (
  program: HealthProgramsCardData,
  formatMessage: IntlFormatters['formatMessage'],
  formatNumber: IntlFormatters['formatNumber'],
) => {
  const {
    description,
    available_points: availablePoints,
    total_activities_count: total,
  } = program;

  const caption = arrayToTag([
    `${total} ${formatMessage(
      { id: 'ACTIVITIES_PLURALIZATION' },
      { count: total },
    ).toLowerCase()}`,
    availablePoints
      ? formatMessage(
          { id: 'VAL_POINTS' },
          {
            points: formatNumber(availablePoints, { style: 'decimal' }),
          },
        )
      : null,
  ]);

  return {
    caption,
    description,
  };
};

export const getChallengeContents = (
  program: ChallengesHealthProgramsCardData,
  formatMessage: IntlFormatters['formatMessage'],
  formatDate: IntlFormatters['formatDate'],
  defaultTimezone?: string,
) => {
  const {
    total_activities_count: totalActivities,
    challenge_info: {
      challenge_type: challengeType,
      start_enroll: startEnroll,
      end_enroll: endEnroll,
      start_date: startDate,
      end_date: endDate,
    },
  } = program;

  const isGroupChallenge = challengeType !== 'solo';

  let signUpText = formatMessage({ id: 'SIGN_UP_DATE_PASSED' });
  // dates in the all programs section come in UTC without a timeoffset
  // eg. 2024-01-01T00:00:00Z
  // dates in the all challenges section come with an offset based on the
  // user's profile location (defaultTimezone).
  // e.g. 2024-01-01T00:00:00-05:00
  // When rendering cards in the all programs section, we need to use UTC
  // as the timezone to render the correct date, while in the all challenges
  // section we need to use the default timezone coming from user's profile
  const isAllProgramsCard = startDate.charAt(startDate.length - 1) === 'Z';
  const dateFormatOptions = {
    ...SHORT_DATE_FORMAT,
    timeZone: isAllProgramsCard ? 'UTC' : defaultTimezone,
  };
  // startEnroll is returning with an undefined value in the all programs section which is causing app crashes. Checking it is defined before calculating enrollment period
  if (startEnroll && isBeforeEnrollmentPeriod(startEnroll, defaultTimezone)) {
    signUpText = formatMessage(
      { id: 'SIGN_UP_BEGINS_DATE' },
      {
        date: formatDate(startEnroll, dateFormatOptions),
      },
    );
  }
  // startEnroll is returning with an undefined value in the all programs section which is causing app crashes. Checking it is defined before calculating enrollment period
  if (
    startEnroll &&
    isEnrollmentOpen(startEnroll, endEnroll, defaultTimezone)
  ) {
    signUpText = formatMessage(
      { id: 'SIGN_UP_BY' },
      {
        date: formatDate(endEnroll, dateFormatOptions),
      },
    );
  }
  const caption = isGroupChallenge
    ? signUpText
    : `${totalActivities} ${formatMessage(
        { id: 'ACTIVITIES_PLURALIZATION' },
        { count: totalActivities },
      ).toLowerCase()}`;
  const description =
    isGroupChallenge &&
    formatMessage(
      { id: 'START_AND_END_DATES' },
      {
        startDate: formatDate(startDate, dateFormatOptions),
        endDate: formatDate(endDate, dateFormatOptions),
      },
    );
  return {
    caption,
    description,
  };
};

export function isProgramAChallenge(
  program: ChallengesHealthProgramsCardData | HealthProgramsCardData,
): program is ChallengesHealthProgramsCardData {
  return (
    (program as ChallengesHealthProgramsCardData).challenge_info !==
      undefined &&
    (program as ChallengesHealthProgramsCardData).challenge_info !== null
  );
}

export const PROGRAM_TYPE = 'program';

export type ProgramTypes = ChallengeTypes | typeof PROGRAM_TYPE;

export const getProgramType = (
  program: ChallengesHealthProgramsCardData | HealthProgramsCardData,
): ProgramTypes => program?.challenge_info?.challenge_type || PROGRAM_TYPE;

export interface HealthProgramsBadgeProps {
  programType: ProgramTypes;
}
