import React from 'react';
import PropTypes from 'prop-types';
import {
  HeadingOne,
  Image,
  Box,
  genesisStyled,
  HeadingFour,
} from '@leagueplatform/genesis-commons';

const MessageContent = genesisStyled(HeadingFour)`
  white-space: pre-wrap;
  max-width: 600px;
`;

const StateMessage = ({ icon, iconSize, title, message, children }) => {
  return (
    <Box padding="three" textAlign="center">
      <Image src={icon} size={iconSize} role="presentation" />
      <HeadingOne
        fontWeight="medium"
        marginTop="half"
        marginBottom="one"
        color="onSurface.text.subdued"
      >
        {title}
      </HeadingOne>
      <MessageContent
        fontWeight="normal"
        color="onSurface.text.subdued"
        as="h2"
      >
        {message}
      </MessageContent>
      {children && <Box marginTop="two">{children}</Box>}
    </Box>
  );
};

StateMessage.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  children: PropTypes.node,
};

StateMessage.defaultProps = {
  iconSize: '100px',
  children: null,
};

export default StateMessage;
