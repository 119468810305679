import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { FEATURE_HIGHLIGHT_PATH } from 'constants/features-highlight.constants';

export const getFeaturesHighlight = async (
  contentfulId: string,
  tenantId: string,
): Promise<any> => {
  const response = await leagueFetch(
    `${FEATURE_HIGHLIGHT_PATH}${contentfulId}`,
    {
      headers: {
        'X-League-Tenant-Id': tenantId,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`getFeaturesHighlight error: ${response.status}`);
  }

  const responseJson: any = await response.json();
  return deserialise(responseJson);
};
