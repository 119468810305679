import {
  CONFIGURABLE_ACTIVITY_PRIORITY,
  CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION,
} from '@leagueplatform/health-journey-common';

export enum COMPLETION_METHOD_TYPES {
  BASE = 'base',
  STEP = 'step',
  COUNTER = 'counter',
}

export enum ACTIVITY_CAMPAIGN_TYPE {
  OPTIN = 'opt-in',
  CHALLENGE = 'challenge',
  SUGGESTED = 'suggested',
  REWARD = 'reward',
}

export interface HelpfulTip {
  type: string;
  title: string;
  url: string;
  helpfulTipImage: string;
}

export interface ActivityCompletionMethod {
  type: COMPLETION_METHOD_TYPES;
  text: string;
  steps: string[];
}

export interface ActivityCampaignInfo {
  campaign_id: string;
  campaign_type: ACTIVITY_CAMPAIGN_TYPE;
  name: string;
  start_date: string;
  status: string;
}

export interface ActivityCta {
  mark_as_complete: boolean;
  text: string;
  url: string;
  completion_method: ActivityCompletionMethod;
}

export interface ActivityBaseBanner {
  description: string;
  title: string;
}

export interface ActivitySuggestionBanner extends ActivityBaseBanner {
  icon_url: string;
}

export interface ActivityProgress {
  count_progress?: number;
  helpful_tips?: Array<{ id: string; opened: boolean }>;
}

export interface HealthActivityProps {
  activityId: string;
  contentActivityId: string;
  activityPoints: number;
  activityProgress: ActivityProgress;
  activityTokens: number;
  activityType: string;
  campaignInfo: ActivityCampaignInfo;
  campaignMode: string;
  cta: ActivityCta;
  description: string;
  disclaimerBanner: ActivityBaseBanner;
  endDate: string;
  hasError: boolean;
  helpfulTips: HelpfulTip[];
  iconUrl: string;
  informationBanner: ActivityBaseBanner;
  name: string;
  pointsEarned: number;
  ready: boolean;
  redirectLink?: string;
  references: string;
  richTextDescription: string;
  startDate: string;
  status: string;
  suggestionBanner: ActivitySuggestionBanner;
  tagline: string;
  tokensEarned: number;
}

// Temporary activity interface until new configurable activity card switches to REST from WS
export interface ConfigurableHealthActivityWS {
  activity_image?: string;
  activity_points: number;
  activity_tokens: number;
  activity_verification_progress?: {
    count_progress: number;
  };
  campaign_info?: {
    campaign_id: string;
    campaign_type: ACTIVITY_CAMPAIGN_TYPE;
    description?: string;
    end_date: string;
    name: string;
    quit_date?: string;
    start_date: string;
    status: string;
    qualifies_for_reward_program?: boolean;
  };
  can_expire?: boolean;
  card_tagline?: string;
  completed_date?: string;
  completion_counter?: {
    goal?: number;
    unit?: string;
  };
  detail_level: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  end_date: string;
  front_of_the_line_activity?: boolean;
  goal_id?: string;
  icon_url?: string;
  id: string;
  name: string;
  points_earned?: number;
  priority: CONFIGURABLE_ACTIVITY_PRIORITY;
  required?: boolean;
  short_description?: string;
  sort_order?: number;
  start_date: string;
  status: string;
  suggested?: boolean;
  tagline?: string;
  tokens_earned?: number;
  type: string;
}

export const metricUnits = [
  'count',
  's',
  'm',
  'min',
  'cal',
  'kcal',
  'bmp',
  'km',
  'mg/dl',
] as const;
export type MetricUnit = (typeof metricUnits)[number];

export const supportedMetrics = [
  'distance',
  'steps',
  'active_duration',
  // , 'sleep_duration'
  'mindful_duration',
  'floors_climbed',
  // , 'idle_hours_count'
  'energy_burned',
] as const;

export type MetricType = (typeof supportedMetrics)[number];
/**
 * This interface should be used specifically for activities that have been
run through the `camelcaseKeys` function from `camelcase-keys`
 */
export interface ConfigurableHealthActivityWSCC {
  activityImage?: string;
  activityPoints: number;
  activityTokens: number;
  activityVerificationProgress?: {
    countProgress: number;
  };
  activityProgressData?: {
    // 🤠 TO DO
    currentProgress: number;
    dataType: MetricType;
    goalTotal: number;
    unit: MetricUnit;
  }[];
  campaignInfo?: {
    campaignId: string;
    campaignType: ACTIVITY_CAMPAIGN_TYPE;
    description?: string;
    endDate: string;
    name: string;
    quitDate?: string;
    startDate: string;
    status: string;
    qualifiesForRewardProgram?: boolean;
  };
  canExpire?: boolean;
  cardTagline?: string;
  completedDate?: string;
  dateRemoved?: string;
  completionCounter?: {
    goal?: number;
    unit?: string;
  };
  detailLevel: CONFIGURABLE_ACTIVITY_DETAIL_COMPOSITION;
  endDate: string;
  frontOfTheLineActivity?: boolean;
  goalId?: string;
  iconUrl?: string;
  id: string;
  name: string;
  pointsEarned?: number;
  priority: CONFIGURABLE_ACTIVITY_PRIORITY;
  required?: boolean;
  shortDescription?: string;
  sortOrder?: number;
  startDate: string;
  status: string;
  suggested?: boolean;
  tagline?: string;
  tokensEarned?: number;
  type: string;
}

export interface ActivitiesGroup {
  activities: ConfigurableHealthActivityWSCC[];
  title: string;
}
