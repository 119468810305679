function getMiddlePageNumbersForRange(
  startPage: number,
  endPage: number,
): number[] {
  const allPageNumbers = [];
  for (let i = startPage; i <= endPage; i += 1) {
    allPageNumbers.push(i);
  }

  return allPageNumbers;
}

export const getMiddleNumbersArray = (
  currentPage: number,
  middleRange: number,
  firstPage: number,
  lastPage: number,
) => {
  // Basic input validation
  if (middleRange % 2 === 0) {
    console.error('MiddleRange must be an odd number');
    return [];
  }

  const totalPages = lastPage - firstPage + 1;

  // Handle the case where middleRange is close to totalPages
  if (totalPages - 2 <= middleRange) {
    const startPage = totalPages > 2 ? firstPage + 1 : firstPage;
    const endPage = totalPages > 2 ? lastPage - 1 : lastPage;

    return getMiddlePageNumbersForRange(startPage, endPage);
  }
  // Calculate middleIndex and initial start/end pages
  const middleIndex = Math.floor(middleRange / 2);
  let startPage = currentPage - middleIndex;
  let endPage = currentPage + middleIndex;

  // Adjust start and end pages to exclude first and last
  if (startPage <= firstPage) {
    startPage = firstPage + 1;
    endPage = startPage + middleRange - 1;
  } else if (endPage >= lastPage) {
    endPage = lastPage - 1;
    startPage = endPage - middleRange + 1;
  }

  return getMiddlePageNumbersForRange(startPage, endPage);
};
