import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps } from '../../Box';
import { useTheme } from '../../theme';

export interface TextProps extends BoxProps {}

export type TextType = React.FunctionComponent<TextProps>;

export const TextBase: TextType = styled(Box)<TextProps>`` as TextType;

/**
 * @deprecated use `ParagraphText`, `UtilityText`, or `HeadingText` from `genesis-core` instead
 */

export const Text: TextType = forwardRef(
  ({ typography: typographyToken, ...props }, ref) => {
    const { typography } = useTheme();
    const typographyStyles = typographyToken && typography[typographyToken];
    return <TextBase as="p" ref={ref} {...typographyStyles} {...props} />;
  }
);

Text.displayName = 'Text';
