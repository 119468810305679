import React, { useMemo } from 'react';
import {
  ColorProps,
  genesisStyled,
  css,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { UtilityText, Box } from '@leagueplatform/genesis-core';
import {
  Document,
  getAuthenticatedDocument,
} from '@leagueplatform/messaging-api';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import {
  MESSAGING_SCREEN_NAMES,
  trackMessagingAnalyticsEvent,
} from 'utils/track-messaging-analytics.util';
import { downloadDocument } from 'utils/download-document/download-document.util';
import { formatFileSize } from 'utils/format-file-size.util';
import { useMessagingConfig } from '../../../messaging.config';

interface MessageDocumentProps {
  document?: Document;
  hasFocus: boolean;
  color: ColorProps['color'];
  fromCurrentUser?: boolean;
  onFocus: () => void;
  onBlur: () => void;
  withBoxedStyling?: boolean;
}

const StyledLink = genesisStyled(PrimaryButton)(
  ({ fromCurrentUser, withBoxedStyling }) =>
    css({
      padding: withBoxedStyling ? 'threeQuarters' : 'quarter',
      textDecoration: 'underline',
      textAlign: 'left',
      justifyContent: 'flex-start',
      backgroundColor: 'inherit',
      color: 'inherit',
      width: '23rem',
      '& svg': {
        flexShrink: 0,
      },
      // we need to define a max-width for the document icon
      // tenants who do not use a react-svg component for this override
      // will see a extremely large img element
      // this a temp solution until we work on the following todo
      // TODO: remove this as part of: https://everlong.atlassian.net/browse/PCHAT-4214
      // once we can use the same react-svg components for all tenants, we should be able to remove this
      '& > img': {
        maxWidth: '24px',
      },
      ...(withBoxedStyling && {
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderRadius: 'extraLarge',
        borderColor: 'onSurface.border.subdued',
      }),
      '> span > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '&:focus, &:hover': {
        backgroundColor: 'transparent',
        ...(withBoxedStyling && {
          borderColor: 'onSurface.border.subdued',
        }),
      },
      ...(fromCurrentUser && {
        '&:focus::before': {
          // boxShadow defaults to color if it is not set explicitly in the declaration
          // this allows us to set our appropriate color on messages from our user so the
          // focus is visible
          ...(!withBoxedStyling && {
            color: 'onSurface.border.subdued',
            boxShadow: '0 0 0 2px',
          }),
        },
      }),
    }),
);

export const MessageDocument = ({
  document,
  hasFocus,
  color,
  fromCurrentUser,
  onFocus,
  onBlur,
  withBoxedStyling,
}: MessageDocumentProps) => {
  const { allowedImageTypes } = useMessagingConfig();
  // Need to keep this above the null return since the useMemo hook can't be called conditionally
  // TODO: Update where/if we're able to access the fileType with the new approach
  const fileType = document?.fileType;
  const fileSize = formatFileSize(document?.fileSize || 0);
  const documentAnalyticsDetail = useMemo(() => {
    if (fileType) {
      const isImage = allowedImageTypes.includes(fileType);
      if (isImage) {
        return 'image';
      }
    }
    return 'file';
  }, [fileType, allowedImageTypes]);

  if (!document) return null;

  const handleDocument = async (
    downloadUrl: string,
    fileName: string,
    documentFileType: string = '',
  ) => {
    const documentDownloadResponse = await getAuthenticatedDocument(
      downloadUrl,
    );

    if (documentDownloadResponse.error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG',
      });
    }

    if (documentDownloadResponse.data) {
      const documentDownloadBlob = await documentDownloadResponse.data.blob();
      downloadDocument(documentDownloadBlob, fileName, documentFileType);
    }
  };

  return (
    <StyledLink
      tabIndex={hasFocus ? 0 : -1}
      color={color}
      onClick={() => {
        handleDocument(
          document.downloadUrl,
          document.fileName,
          document.fileType,
        );
        trackMessagingAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          screen_name: MESSAGING_SCREEN_NAMES.MESSAGE_THREAD,
          detail: documentAnalyticsDetail,
        });
      }}
      fromCurrentUser={fromCurrentUser}
      withBoxedStyling={withBoxedStyling}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {
        handleStaticAsset(MESSAGING_ASSET_KEYS.MESSAGING_DOCUMENT_ICON, {
          isComponent: true,
        }) as JSX.Element
      }
      {/* TODO: Update the source of the document name based on new approach */}
      <Box
        css={{
          maxWidth: '17.4rem',
          marginLeft: '$half',
        }}
      >
        <UtilityText
          css={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'inherit',
          }}
        >{`${document.fileName}.${document.fileType}`}</UtilityText>
        <UtilityText css={{ display: 'block', color: 'inherit' }}>
          {document.fileType?.toUpperCase()}
          {!!document?.fileSize && (
            <>
              <UtilityText
                aria-hidden="true"
                css={{
                  color: 'inherit',
                }}
              >
                &nbsp;•
              </UtilityText>
              <UtilityText
                css={{
                  color: 'inherit',
                }}
              >
                &nbsp;{fileSize}
              </UtilityText>
            </>
          )}
        </UtilityText>
      </Box>
    </StyledLink>
  );
};
