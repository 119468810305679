async function exchangeCookie(endpoint: string, accessToken: string) {
  const request = new Request(endpoint, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
    body: {} as BodyInit,
    credentials: 'include',
  });

  try {
    await fetch(request);
  } catch (ignore) {
    // Ignore errors if fetch fails
  }
}

// Returns the URLs for the "token cookie exchange" endpoint for the given exchangeBase.
function getCookieExchangeEndpoint(exchangeBase: string) {
  return `${exchangeBase}/auth0/exchange`;
}

/**
 * Makes a request to a League backend "cookie exchange" endpoint with a current access token.
 *
 * These endpoints store the access token in a HTTP-only cookie, for use by GET endpoints
 * (e.g. content server images and legacy REST SSO endpoints) that cannot accept tokens passed
 * via HTTP headers.
 */
export async function attachCookiesWithAccessToken(
  exchangeBase: string,
  accessToken: string,
) {
  const endpoint = getCookieExchangeEndpoint(exchangeBase);

  await exchangeCookie(endpoint, accessToken);
}
