import * as RadixPopoverPrimitive from '@radix-ui/react-popover';
import { FunctionComponent, PropsWithChildren } from 'react';
import { PopoverTrigger } from './popover-trigger';
import { PopoverContent } from './popover-content';
import { withClassName } from '../../utilities';

// Exporting Radix Popover Primitive for custom Popovers
export const GDSPopoverPrimitive = RadixPopoverPrimitive;

const PopoverClose: FunctionComponent<PropsWithChildren<{}>> = withClassName(
  RadixPopoverPrimitive.Close,
  'GDS-close-button',
);

// Composed GDS Popover
export const Popover = {
  Root: RadixPopoverPrimitive.Root,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  Close: PopoverClose,
};

Popover.Root.displayName = 'Popover.Root';
Popover.Trigger.displayName = 'Popover.Trigger';
Popover.Content.displayName = 'Popover.Content';
Popover.Close.displayName = 'Popover.Close';
