import * as React from 'react';
import { StackLayout, ParagraphText } from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { SectionHeader } from '../masonry-section-header-renderer/section-header.component';
import { GridContainer } from '../../common/grid-container/grid-container';

export interface MasonryGridLayoutNodeProperties {
  columnCount: number;
  heading?: string;
  body?: string;
}

export type MasonryGridLayoutNode = MasonryEngineNode<
  'gridLayout',
  MasonryGridLayoutNodeProperties,
  {},
  {
    items: MasonryEngineNode<string, any>[];
  }
>;

export type MasonryGridLayoutRendererProps =
  MasonryEngineNodeRendererProps<MasonryGridLayoutNode>;

export const MasonryGridLayoutRenderer = ({
  items,
  columnCount = 2,
  heading,
  body,
}: MasonryGridLayoutRendererProps) => {
  if (!items) return null;

  return (
    <StackLayout
      spacing="$one"
      horizontalAlignment="stretch"
      orientation="vertical"
    >
      {heading && <SectionHeader heading={heading} />}
      {body && <ParagraphText>{body}</ParagraphText>}
      <GridContainer gridColumnCount={columnCount}>{items}</GridContainer>
    </StackLayout>
  );
};
