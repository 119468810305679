import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  HIGHLIGHT_REACTIONS_PATH,
  CHALLENGE_HIGHLIGHT_REACTION_TYPES,
  CHALLENGE_PATCH_OPERATIONS,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

export const patchHighlightReaction = async (
  reactionId: string,
  operationType: CHALLENGE_PATCH_OPERATIONS,
  errorContext?: ErrorContext,
) => {
  const response = await leagueFetch(
    `${HIGHLIGHT_REACTIONS_PATH}/${reactionId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          id: reactionId,
          type: CHALLENGE_DATA_TYPES.CHALLENGE_FEED_ITEM_REACTION,
          attributes: {
            reactionType: CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE, // Can be other emotions in future
            operationType,
          },
        },
      }),
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while patching!');
};
