import { useState } from 'react';
import { useSubmitBenefitSelectionMutation } from './queries/use-submit-benefit-selection-mutation.hook';

/**
 * @function useFinalizeSelections - a React hook to enable the finalize selection flow
 * where a member explicitly confirms finalization
 *
 * @param {string} groupId - the member's group ID
 *
 * @returns {object}
 */

export function useFinalizeSelections(groupId: string) {
  const [isConfirmingFinalization, setIsConfirmingFinalization] =
    useState(false);
  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false);
  const [hasUncheckedError, setHasUncheckedError] = useState(false);
  const { mutate: submitBenefitSelectionQuery } =
    useSubmitBenefitSelectionMutation({
      groupId,
      allocateImmediately: true,
    });
  const resetState = () => {
    setIsConfirmingFinalization(false);
    setHasUncheckedError(false);
    setIsConfirmationChecked(false);
  };
  return {
    isConfirmationChecked,
    isConfirmingFinalization,
    hasUncheckedError,
    onFinalizeSelections: () => setIsConfirmingFinalization(true),
    onConfirmFinalizingSelections: () => {
      if (isConfirmationChecked) {
        resetState();
        submitBenefitSelectionQuery();
      } else {
        setHasUncheckedError(true);
      }
    },
    onCancelFinalizingSelections: () => resetState(),
    toggleConfirmationCheckbox: () =>
      setIsConfirmationChecked(!isConfirmationChecked),
  };
}
