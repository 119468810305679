import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Button } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  REWARDS_EVENT_ANALYTICS,
  TOAST_ACTIONS,
} from 'constants/analytics.constants';
import React from 'react';

interface CloseButtonProps {
  onClose: Function;
  activitiesLength: number;
}

export const CloseButton = ({
  onClose,
  activitiesLength,
}: CloseButtonProps) => {
  const { formatMessage } = useIntl();

  function closeToast() {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_TOAST,
      detail: TOAST_ACTIONS.DISMISSED,
      num_of_milestone_rewards: activitiesLength,
    });

    onClose();
  }

  return (
    <Button
      quiet
      priority="tertiary"
      icon="tinyClose"
      onClick={() => closeToast()}
      css={{ marginLeft: '$half', height: '$two' }}
      size="small"
      hideLabel
    >
      {formatMessage({ id: 'DISMISS_TOAST' })}
    </Button>
  );
};
