import { useRef, useEffect, useState, useCallback } from 'react';
import { throttle } from 'lodash';

export const useTabsScroll = () => {
  const [isScrolledStart, setIsScrolledStart] = useState(true);
  const [isScrolledEnd, setIsScrolledEnd] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const tabsList = useRef<HTMLDivElement | HTMLUListElement>(null);
  const listWidth = tabsList.current?.offsetWidth;
  const scrollAmount = listWidth ? listWidth / 2 : 0;

  const showScrollLeft = !isScrolledStart && hasOverflow;
  const showScrollRight = !isScrolledEnd && hasOverflow;

  const scrollLeft = useCallback(() => {
    if (tabsList.current) {
      tabsList.current.scrollLeft += -scrollAmount;
    }
  }, [scrollAmount]);

  const scrollRight = useCallback(() => {
    if (tabsList.current) {
      tabsList.current.scrollLeft += scrollAmount;
    }
  }, [scrollAmount]);

  // Hide and show Previous and Next buttons only if there is room too scroll
  const showHideControls = () => {
    // hide left button if tabs have been scrolled all the say to the left
    if (tabsList.current && tabsList.current.scrollLeft < 1) {
      setIsScrolledStart(true);
    } else {
      setIsScrolledStart(false);
    }
    // hide the right button if tabs have been scrolled all the way to the right
    if (
      tabsList.current &&
      Math.ceil(tabsList.current.scrollLeft) >=
        tabsList.current.scrollWidth - tabsList.current.offsetWidth
    ) {
      setIsScrolledEnd(true);
    } else {
      setIsScrolledEnd(false);
    }
  };

  // show the scroll buttons only if the list is longer than the scrollable wrapper
  const showControlsOnOverflow = () => {
    const { innerWidth } = window;

    let offsetWidth = 0;
    let scrollWidth = 0;

    if (tabsList.current) {
      offsetWidth = tabsList.current.offsetWidth;
      scrollWidth = tabsList.current.scrollWidth;
    }

    return scrollWidth > offsetWidth || scrollWidth > innerWidth
      ? setHasOverflow(true)
      : setHasOverflow(false);
  };

  useEffect(() => {
    const currentRef = tabsList?.current;

    const onScroll = throttle(() => {
      showHideControls();
    }, 500);

    const onResize = throttle(() => {
      showControlsOnOverflow();
    }, 500);

    currentRef?.addEventListener('scroll', onScroll);

    // check if tablist is overflowing on first load
    showControlsOnOverflow();

    // show/hide scroll controls on resize
    window.addEventListener('resize', onResize);

    return () => {
      currentRef?.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
      setHasOverflow(false);
      setIsScrolledEnd(false);
      setIsScrolledStart(true);
    };
  }, [tabsList]);

  return {
    scrollLeft,
    scrollRight,
    tabsList,
    showScrollLeft,
    showScrollRight,
  };
};
