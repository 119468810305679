import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import { GDSStyleObject, styled } from '@leagueplatform/genesis-core';
import { Link as RouterLink } from '@leagueplatform/routing';
import { isAbsoluteUrl } from '@leagueplatform/web-common';

export interface InternalOrExternalLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: React.ReactNode;
  isExternalUrl?: boolean;
  css?: GDSStyleObject;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const StyledLink = styled('a');

// A link component that will render an HTML link for external urls or a react router link for internal urls.
export const InternalOrExternalLink = forwardRef<
  HTMLAnchorElement | null,
  InternalOrExternalLinkProps
>(({ href, children, ...linkProps }, ref) => {
  const { isExternalUrl = isAbsoluteUrl(href) } = linkProps;

  const isExternalUrlOrPhoneNumber = isExternalUrl || href.includes('tel:');

  return (
    <StyledLink
      ref={ref}
      as={isExternalUrlOrPhoneNumber ? 'a' : RouterLink}
      to={isExternalUrlOrPhoneNumber ? undefined : href} // for Router Link
      href={isExternalUrlOrPhoneNumber ? href : undefined} // for HTML Link
      target={isExternalUrl ? '_blank' : undefined}
      rel={isExternalUrl ? 'noopener noreferrer' : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...linkProps}
    >
      {children}
    </StyledLink>
  );
});
