import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ReactSelect, { components as reactSelectComponents } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from '@leagueplatform/locales';
import { Box } from 'ui-kit/components/primitives.view';
import { useTheme } from '@leagueplatform/genesis-core';

export const makeOption = (value) => ({
  value,
  label: value,
});

export const makeOptionWithLabel = (value, label) => ({
  value,
  label: label || value,
});

export const mapValuesToOptions = (valuesArray, mapper = makeOption) =>
  valuesArray.map(mapper);

export const mapOneOfValuesToOptions = (oneOf) =>
  oneOf.map(({ properties: { value, label } }) => ({
    value: value.const,
    label: label.const || value.const,
  }));

const borderColor = (theme, { hasError, isFocused, menuIsOpen } = {}) => {
  let color;
  if (hasError) {
    color = theme.colors.red;
  } else if (isFocused || menuIsOpen) {
    color = theme.colors.purple;
  } else {
    color = theme.colors['neutral.grayLight'];
  }
  return {
    borderColor: color,
  };
};

const inputBoxInset = 4;
const textInset = (theme) => theme.space[2] - inputBoxInset;

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <reactSelectComponents.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={menuIsOpen ? 'chevron-up' : 'chevron-down'} />
    </reactSelectComponents.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
};

DropdownIndicator.defaultProps = {
  selectProps: {},
};

export const MultiSelect = withTheme(
  ({
    canCreate,
    canCreatePrompt,
    disabled,
    hasError,
    isClearable,
    isMulti,
    theme,
    placeholder,
    options,
    id,
    name,
    formatCreateLabel,
    ...props
  }) => {
    const { formatMessage } = useIntl();
    const { colors } = useTheme();
    const defaultCanCreatePrompt = formatMessage({
      id: 'MULTI_SELECT_DEFAULT_CAN_CREATE',
    });
    const defaultPlaceholder = formatMessage({
      id: 'MULTI_SELECT_DEFAULT_PLACEHOLDER',
    });

    const defaultFormatCreateLabel = (label) =>
      formatMessage(
        {
          id: 'CREATE',
        },
        { label },
      );

    const promptToCreate = {
      label: canCreatePrompt || defaultCanCreatePrompt,
      isDisabled: true,
    };

    const augmentedOptions = canCreate ? [...options, promptToCreate] : options;

    const SelectComponent = canCreate ? ReactSelectCreatable : ReactSelect;

    const reactSelectTheme = {
      borderRadius: 2,
      spacing: {
        controlHeight: 45,
        baseUnit: 4,
        menuGutter: -1,
      },
      colors: {
        primary: theme.colors.purple,
        primary25: theme.colors.purpleLightest,
        primary50: theme.colors.purpleLighter,
        primary75: theme.colors.purpleLight,
        danger: theme.colors.red,
        dangerLight: theme.colors.redLightest,
        neutral0: theme.colors.white,
        neutral5: theme.colors.greyLightest,
        neutral10: theme.colors.greyLighter,
        neutral20: theme.colors.grey,
        neutral30: theme.colors.grey,
        neutral40: theme.colors.greyDark,
        neutral80: theme.colors.navyDark,
      },
    };

    const styles = {
      control: (provided, state) => ({
        ...provided,
        boxShadow: '',
        '&:hover': null,
        ...borderColor(theme, { ...state, hasError }),
        ...(hasError && {
          backgroundColor: theme.colors.redLightest,
        }),
      }),

      valueContainer: (provided) => ({
        ...provided,
        padding: '2px',
      }),

      placeholder: (provided) => ({
        ...provided,
        color: theme.colors.grey,
        paddingLeft: textInset(theme),
      }),

      singleValue: (provided) => ({
        ...provided,
        fontWeight: 'bold',
        overflow: 'visible',
        paddingLeft: textInset(theme),
      }),

      input: (provided) => ({
        ...provided,
        paddingLeft: textInset(theme),
      }),

      multiValue: (provided) => ({
        ...provided,
        backgroundColor: theme.colors.greyLighter,
        borderRadius: theme.radii[2],
      }),

      multiValueLabel: (provided) => ({
        ...provided,
        fontSize: theme.fontSizes[2],
        lineHeight: `${theme.space[3]}px`,
        borderRadius: theme.radii[2],
        padding: 0,
        paddingLeft: textInset(theme),
        paddingRight: theme.space[1] / 2,
      }),

      multiValueRemove: (provided, { isFocused }) => ({
        ...provided,
        borderRadius: theme.radii[2],
        backgroundColor: 'none',
        border: isFocused ? `1px solid ${theme.colors.purple}` : 'none',
        width: theme.space[3],
        justifyContent: 'center',
        ':hover': {
          ...provided[':hover'],
          backgroundColor: 'none',
        },
      }),

      menu: (provided) => ({
        ...provided,
        borderStyle: 'solid',
        borderWidth: '1px',
        ...borderColor(theme, { menuIsOpen: true, hasError }),
        fontSize: `${theme.fontSizes[2]}px`,
        padding: 0,
        boxShadow: 'initial',
      }),

      menuList: (provided) => ({
        ...provided,
        padding: 0,
        marginTop: -1, // this is to cover the top border
        backgroundColor: theme.colors.white,
      }),

      option: (provided, state) => ({
        ...provided,
        ':active': null,
        fontSize: `${theme.fontSizes[2]}px`,
        padding: theme.space[2],
        ...(!state.isDisabled && {
          fontWeight: 500,
        }),
        color: theme.colors.navyDark,
        ...(state.isFocused && {
          backgroundColor: colors.interactiveActionPrimary.value,
          color: colors.onSurfaceTextReversed.value,
        }),
        ...(state.isSelected && {
          backgroundColor: theme.colors.purpleLightest,
        }),
      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        color: theme.colors['neutral.gray'],
        paddingRight: theme.space[2],
        ':hover': null,
      }),
    };

    const components = {
      IndicatorSeparator: () => null,
      DropdownIndicator,
    };

    return (
      <Box {...props}>
        <SelectComponent
          classNamePrefix="ui-kit-multi-select"
          components={components}
          isDisabled={disabled}
          isClearable={isClearable}
          isMulti={isMulti}
          options={augmentedOptions}
          placeholder={placeholder || defaultPlaceholder}
          styles={styles}
          theme={reactSelectTheme}
          inputId={id}
          name={name}
          formatCreateLabel={formatCreateLabel || defaultFormatCreateLabel}
          {...props}
        />
      </Box>
    );
  },
);

MultiSelect.propTypes = {
  canCreate: PropTypes.bool,
  canCreatePrompt: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.node,
  formatCreateLabel: PropTypes.func,
};

MultiSelect.defaultProps = {
  disabled: undefined,
  canCreate: false,
  canCreatePrompt: undefined,
  hasError: false,
  isClearable: false,
  isMulti: true,
  options: undefined,
  placeholder: undefined,
  formatCreateLabel: undefined,
};
