import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Select as ReactResponsiveSelect } from 'react-responsive-select';
import { isUndefined, isNull } from 'lodash';

import { has } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'ui-kit/components/primitives.view';
import { useTheme } from '@leagueplatform/genesis-commons';
import { radiusInPx, colour } from 'ui-kit/theme';
import { DefaultStyle } from './default-style';

const isEmptyString = (value) =>
  typeof value === 'string' && value.replace(/\s/g, '').length === 0;

const isEmptyOrUndefined = (value) =>
  isEmptyString(value) || isNull(value) || isUndefined(value);

const SelectContainer = styled(Box).attrs({ my: 2, w: 1 })`
  .rrs {
    position: relative;

    .rrs__button {
      height: ${({ height }) => height || '45px'};
    }

    .rrs__button,
    .rrs__button + .rrs__options {
      box-shadow: none;
    }

    .rrs__label {
      border: 1px solid
        ${({ borderColor }) => borderColor || colour('neutral.grayLight')};
      border-radius: ${radiusInPx(2)};
      line-height: ${({ lineHeight }) => lineHeight || '43px'};
      ${({ hasError }) =>
        hasError &&
        css`
          border-color: ${({ errorBorderColor }) =>
            errorBorderColor || colour('red')};
          background-color: ${({ errorBackgroundColor }) =>
            errorBackgroundColor || colour('redLightest')};
        `};
      padding: 0 36px 0 16px;
      .svg-inline--fa {
        position: absolute;
        right: 16px;
        top: calc(50% - 7px);
        color: ${colour('neutral.gray')};
        transition: all 200ms ease-out;
      }
    }

    .rrs__button--disabled {
      background-color: ${({ disabledBackgroundColor }) =>
        disabledBackgroundColor || colour('greyLightest')};
      .rrs__label {
        border: 1px solid
          ${({ disabledBorderColor }) =>
            disabledBorderColor || colour('greyLighter')};
      }
    }

    &.rrs--options-visible .rrs__label,
    .rrs__button:focus:not(.rrs__button--disabled) .rrs__label,
    .rrs__button + .rrs__options {
      border-color: ${({ focusBorderColor }) =>
        focusBorderColor || colour('purple')};
    }

    .rrs__button + .rrs__options {
      border-top: none;
      top: 43px;
    }

    &.rrs--options-visible .svg-inline--fa {
      transform: rotate(180deg);
    }

    .rrs__option {
      padding: 18px 16px;
      color: ${({ color }) => color || colour('purpleDarkest')};
      ${({ optionLineHeight }) =>
        css`
          line-height: ${optionLineHeight};
        `};
      &--selected,
      &--selected.rss__option--next-selection {
        background-color: ${({ selectedOptionBackgroundColor }) =>
          selectedOptionBackgroundColor || colour('purpleLightest')};
      }
      &:focus {
        outline: 1px solid
          ${({ optionFocusBorderColor }) =>
            optionFocusBorderColor || colour('greyLightest')};
      }

      &:hover:not(.rrs__option--selected),
      &--next-selection:not(.rrs__option--selected) {
        background-color: ${({ disabledBackgroundColor }) =>
          disabledBackgroundColor || colour('greyLightest')};
      }
    }

    .rrs__label__text {
      flex-grow: 1;
      ${(props) =>
        !has(props, 'selectedValue') || !isEmptyOrUndefined(props.selectedValue)
          ? css`
              color: ${props.color || colour('navyDark')};
            `
          : css`
              color: ${props.placeholderColor || colour('neutral.gray')};
              font-weight: 300;
            `};
    }

    ${({ width }) =>
      width &&
      css`
        width: ${width};

        > .rrs .rrs__button {
          z-index: 3;
        }

        > .rrs .rrs__label {
          padding-right: 35px;
        }

        > .rrs .rrs__label__text {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > .rrs.rrs--options-visible {
          .rrs__label {
            border-bottom: 1px solid transparent;
          }

          .rrs__button + .rrs__options {
            border-top-color: ${({ focusBorderColor }) =>
              focusBorderColor || colour('purple')};
          }
        }

        > .rrs .rrs__button + .rrs__options {
          border-top-width: 1px;
          border-top-style: solid;
        }

        > .rrs .rrs__options {
          width: initial;
          top: initial;
          min-width: 200px;
        }

        > .rrs .rrs__option {
          white-space: nowrap;
          width: 100%;
          position: relative;
        }
      `};
  }
`;

export const Select = ({ asGenesis, ...props }) => {
  const { colors, space } = useTheme();
  const genesisStyleProps = asGenesis
    ? {
        height: '48px',
        lineHeight: '46px',
        optionLineHeight: `${space.one}px`,
        color: colors.text.primary,
        placeholderColor: colors.text.input.placeholder,
        disabledColor: colors.text.input.disabled,
        borderColor: colors.border.input.default,
        focusBorderColor: colors.text.input.defaultOnFocus,
        errorBorderColor: colors.text.input.danger,
        optionFocusBorderColor: colors.border.input.disabled,
        disabledBorderColor: colors.border.input.disabled,
        errorBackgroundColor: colors.lightPalette.white,
        disabledBackgroundColor: colors.background.input.disabled,
        selectedOptionBackgroundColor: colors.background.primaryHighlight,
      }
    : null;

  return (
    <>
      <DefaultStyle />
      <SelectContainer {...genesisStyleProps} {...props}>
        <ReactResponsiveSelect
          {...props}
          caretIcon={<FontAwesomeIcon icon="chevron-down" />}
        />
      </SelectContainer>
    </>
  );
};

Select.propTypes = {
  asGenesis: PropTypes.bool,
};

Select.defaultProps = {
  asGenesis: false,
};
