import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

export const isActiveActivity = (activity: ConfigurableHealthActivityWSCC) =>
  activity.status === 'active';
export const isUpcomingActivity = (activity: ConfigurableHealthActivityWSCC) =>
  activity.status === 'upcoming';
export const isCompletedActivity = (activity: ConfigurableHealthActivityWSCC) =>
  activity.status === 'completed';
export const isMissedActivity = (activity: ConfigurableHealthActivityWSCC) =>
  activity.status === 'expired' || activity.status === 'removed';
