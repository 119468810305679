import React, { forwardRef } from 'react';
import { Text, TextProps } from '../../Typography';

/**
 * @deprecated use `InputLabel` from `genesis-core` instead
 */

export const LabelText: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="span"
      display="block"
      color="onSurface.text.subdued"
      typography="label"
      ref={ref}
      {...props}
    />
  )
);

LabelText.displayName = 'LabelText';
