import * as React from 'react';
import {
  Flex,
  HeadingOne,
  HeadingFour,
  Caption,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Measurement } from '../types/measurement';

type AverageValuePanelProps = {
  measurement: Measurement;
};

export function AverageValuePanel({ measurement }: AverageValuePanelProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingFour color="onSurface.text.subdued">
        {formatMessage({
          id: 'AVERAGE',
        }).toUpperCase()}
      </HeadingFour>
      <Flex alignItems="baseline">
        <HeadingOne textAlign="center" as="span">
          {measurement.value}
        </HeadingOne>
        <Caption as="span" paddingLeft="quarter" color="onSurface.text.subdued">
          {measurement.type}
        </Caption>
      </Flex>
    </>
  );
}
