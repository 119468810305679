import * as React from 'react';
import { Card, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

type ChallengeActivitiesEmptyStateCardProps = {
  title: string;
  message: string;
};

function ChallengeActivitiesEmptyStateCard({
  title,
  message,
}: ChallengeActivitiesEmptyStateCardProps) {
  return (
    <Card.Flat css={{ width: '100%' }}>
      <Card.Section padding="$two">
        <StackLayout
          horizontalAlignment="center"
          verticalAlignment="center"
          spacing="$none"
        >
          <UtilityText emphasis="subtle" css={{ fontWeight: 800 }}>
            {title}
          </UtilityText>
          <UtilityText emphasis="subtle" size="sm">
            {message}
          </UtilityText>
        </StackLayout>
      </Card.Section>
    </Card.Flat>
  );
}

type ChallengeActivitiesPendingStateCardProps = Omit<
  ChallengeActivitiesEmptyStateCardProps,
  'title'
>;

export function ChallengePendingStateCard({
  message,
}: ChallengeActivitiesPendingStateCardProps) {
  const { formatMessage } = useIntl();

  return (
    <ChallengeActivitiesEmptyStateCard
      title={formatMessage({ id: 'CHALLENGE_ACTIVITY_EMPTY_STATE_HEADER' })}
      message={message}
    />
  );
}

export function ChallengeCompletedCard() {
  const { formatMessage } = useIntl();

  return (
    <ChallengeActivitiesEmptyStateCard
      title={formatMessage({ id: 'CHALLENGE_COMPLETED_TITLE' })}
      message={formatMessage({ id: 'CHALLENGE_COMPLETED_BODY' })}
    />
  );
}

export function ChallengeUpNextActivitiesPendingStateCard() {
  const { formatMessage } = useIntl();

  return (
    <ChallengePendingStateCard
      message={formatMessage({
        id: 'CHALLENGE_ACTIVITY_UP_NEXT_EMPTY_STATE',
      })}
    />
  );
}

export function ChallengeCompletedActivitiesPendingStateCard() {
  const { formatMessage } = useIntl();

  return (
    <ChallengePendingStateCard
      message={formatMessage({
        id: 'CHALLENGE_ACTIVITY_COMPLETED_EMPTY_STATE',
      })}
    />
  );
}

export function ChallengeMissedActivitiesPendingStateCard() {
  const { formatMessage } = useIntl();

  return (
    <ChallengePendingStateCard
      message={formatMessage({
        id: 'CHALLENGE_ACTIVITY_MISSED_EMPTY_STATE',
      })}
    />
  );
}
