/* eslint-disable import/no-unresolved */
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { GenesisRouterLink } from '@leagueplatform/web-common-components';
import {
  BodyOne,
  Box,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Image,
  Flex,
  PrimaryButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { ConfirmFinalizeSelectionsModal } from './components/confirm-finalize-selections-modal.component';
import { useFinalizeSelections } from './hooks/use-finalize-selections.hook';

const LastDayLabel = genesisStyled.span`
  font-weight: normal;
`;

const ContentContainer = genesisStyled(Flex)(
  css({
    borderRadius: 'small',
    borderColor: 'onSurface.border.subdued',
  }),
);

const CardInfo = genesisStyled(Flex)(
  css({
    marginLeft: 'three',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
);

const Icon = genesisStyled(Image)<{ size: string }>(({ size }) =>
  css({
    width: size,
    height: size,
    alt: '',
  }),
);

interface WalletEnrollmentProps {
  enrollmentEndDate?: string;
  showPlanSelections: boolean;
  groupId?: string;
  isNewHireEnrollment: boolean;
}

export const WalletEnrollment = ({
  enrollmentEndDate = '',
  showPlanSelections,
  groupId = '',
  isNewHireEnrollment,
}: WalletEnrollmentProps) => {
  const { formatMessage, formatDate } = useIntl();
  const finalizeSelectionsState = useFinalizeSelections(groupId);

  return (
    <Box
      maxWidth="1000px"
      marginX="auto"
      marginTop="two"
      marginBottom="twoAndHalf"
      paddingX="two"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDirection={['column', 'column', 'row']}
      >
        <HeadingOne>{formatMessage({ id: 'WALLET' })}</HeadingOne>
        {enrollmentEndDate && (
          <BodyOne as="p" className="enrollment-end-date">
            <strong>
              {formatMessage(
                {
                  id: 'LAST_DAY_OF_ENROLLMENT',
                },
                {
                  lastDay: (
                    <LastDayLabel>
                      {formatDate(enrollmentEndDate, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </LastDayLabel>
                  ),
                },
              )}
            </strong>
          </BodyOne>
        )}
      </Flex>

      <ContentContainer borderWidth="none" textAlign="center" paddingY="two">
        <Flex
          flexDirection="column"
          align="center"
          width="100%"
          maxWidth="60ch"
          margin="auto"
        >
          <Icon
            src={
              handleStaticAsset(WALLET_ASSET_KEYS.CARD_LEAGUE_ICON) as string
            }
            size="13rem"
            marginY="two"
            marginX="auto"
          />
          <HeadingTwo>{formatMessage({ id: 'WALLET_WELCOME' })}</HeadingTwo>
          <BodyOne marginY="one" textAlign="center">
            {formatMessage(
              {
                id: 'WALLET_DESCRIPTION',
              },
              {
                healthProgramLink: (
                  <GenesisRouterLink to={JOURNEY_ROUTES.programsHome()}>
                    {formatMessage({ id: 'HEALTH_PROGRAM_LINK_COPY' })}
                  </GenesisRouterLink>
                ),
              },
            )}
          </BodyOne>
          {showPlanSelections && isNewHireEnrollment && (
            <Box marginTop="one">
              <HeadingThree>
                {formatMessage({ id: 'FINALIZE_SELECTIONS_HEADER_COPY' })}
              </HeadingThree>
              <PrimaryButton
                marginTop="one"
                onClick={finalizeSelectionsState.onFinalizeSelections}
              >
                {formatMessage({ id: 'FINALIZE_PLAN_SELECTIONS' })}
              </PrimaryButton>
            </Box>
          )}
        </Flex>
      </ContentContainer>
      {showPlanSelections && (
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
        >
          <ContentContainer
            padding="two"
            paddingLeft="three"
            marginRight={['none', 'none', 'one']}
            marginTop={['one', 'one', 'none']}
            borderWidth="thin"
          >
            <Icon
              src={handleStaticAsset(WALLET_ASSET_KEYS.DOCUMENT_ICON) as string}
              size="75px"
            />
            <CardInfo>
              <Box>
                <HeadingThree>
                  {formatMessage({ id: 'TOTAL_REWARDS_LABEL' })}
                </HeadingThree>
                <BodyOne marginY="one">
                  {formatMessage({ id: 'TOTAL_REWARDS_DESCRIPTION' })}
                </BodyOne>
              </Box>
              <Flex align="center">
                <GenesisRouterLink to="/member/coverage-summary">
                  {formatMessage({ id: 'TOTAL_REWARDS_LINK' })}
                  <Icon
                    src={
                      handleStaticAsset(WALLET_ASSET_KEYS.RIGHT_ARROW) as string
                    }
                    size="10px"
                    marginX="half"
                  />
                </GenesisRouterLink>
              </Flex>
            </CardInfo>
          </ContentContainer>

          <ContentContainer
            padding="two"
            paddingLeft="three"
            marginLeft={['none', 'none', 'one']}
            marginTop={['one', 'one', 'none']}
            borderWidth="thin"
          >
            <Image
              src={handleStaticAsset(WALLET_ASSET_KEYS.PENCIL_ICON) as string}
              size="75px"
              alt=""
            />
            <CardInfo>
              <Box>
                <HeadingThree>
                  {formatMessage({ id: 'CHANGE_PLAN_LABEL' })}
                </HeadingThree>
                <BodyOne marginY="one">
                  {formatMessage({ id: 'CHANGE_PLAN_DESCRIPTION' })}
                </BodyOne>
              </Box>
              <Flex align="center">
                <GenesisRouterLink to="/enroll">
                  {formatMessage({ id: 'CHANGE_PLAN_LINK' })}
                  <Icon
                    src={
                      handleStaticAsset(WALLET_ASSET_KEYS.RIGHT_ARROW) as string
                    }
                    size="10px"
                    marginX="half"
                  />
                </GenesisRouterLink>
              </Flex>
            </CardInfo>
          </ContentContainer>
        </Flex>
      )}
      {finalizeSelectionsState.isConfirmingFinalization && (
        <ConfirmFinalizeSelectionsModal
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...finalizeSelectionsState}
        />
      )}
    </Box>
  );
};
