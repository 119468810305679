import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import PropTypes from 'prop-types';

export const HealthProgramCategory = ({ children }) => (
  <Flex
    paddingY="oneAndHalf"
    paddingX="one"
    alignItems="center"
    borderRadius="large"
    borderStyle="solid"
    borderWidth="thin"
    borderColor="onSurface.border.subdued"
    width="100%"
    height="100%"
  >
    {children}
  </Flex>
);

HealthProgramCategory.propTypes = {
  children: PropTypes.node,
};

HealthProgramCategory.defaultProps = {
  children: '',
};
