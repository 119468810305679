import React, { useRef, forwardRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link as LinkDOM } from '@leagueplatform/routing';
import {
  SubtitleOne,
  BodyTwo,
  BodyOneSecondary,
  Overline,
  CoverImage,
  Box,
} from '@leagueplatform/genesis-commons';
import { TextAction } from '@leagueplatform/genesis-core';
import { ConditionalLinkCard } from '@leagueplatform/web-common-components';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  ChallengesHealthProgramsCardData,
  HealthProgramsCardData,
} from 'types/health-programs-response-data.type';
import { useProgramLibraryAnalytics } from '../hooks/use-program-library-analytics.hook';
import {
  getChallengeContents,
  getHealthContents,
  getProgramType,
  HealthProgramsBadgeProps,
  isProgramAChallenge,
} from './health-programs-card.utils';

export interface HealthProgramsCardProps {
  tag?: string | null;
  analyticsContext?: {
    pageContext?: string;
    programCategory: string | null;
    carouselName: string | null;
    carouselIndex: number | null;
    metricType?: string;
  };
  program: ChallengesHealthProgramsCardData | HealthProgramsCardData;
  badgeComponent?: React.FC<HealthProgramsBadgeProps>;
}

export const HealthProgramsCard = forwardRef(
  (
    {
      tag = null,
      analyticsContext = {
        carouselName: null,
        carouselIndex: null,
        programCategory: null,
        pageContext: undefined,
      },
      program,
      badgeComponent: BadgeComponent,
    }: HealthProgramsCardProps,
    ref,
  ) => {
    const { formatDate, formatMessage, formatNumber } = useIntl();
    const programLibraryAnalytics = useProgramLibraryAnalytics();
    const linkRef = useRef(null);
    const { data: userData } = useGetUserProfile({ staleTime: Infinity });
    const { defaultTimezone } = userData || {};
    const { name, program_id: programId, image_url: imageUrl } = program;
    const isChallenge = isProgramAChallenge(program);
    const { description, caption } = isChallenge
      ? getChallengeContents(
          program,
          formatMessage,
          formatDate,
          defaultTimezone,
        )
      : getHealthContents(program, formatMessage, formatNumber);

    const getLinkPath = () =>
      isChallenge
        ? JOURNEY_ROUTES.getChallengeDetails(programId)
        : JOURNEY_ROUTES.getProgramDetails(programId);

    const programType = getProgramType(program);

    return (
      <ConditionalLinkCard
        hasLink
        linkRef={linkRef}
        cardStyle={{
          // adding ts-ignore since we need to override the original boxShadow value for ConditionalLinkCard and ShadowPropsType doesn't include 'none'
          // @ts-ignore
          boxShadow: 'none',
        }}
      >
        <Box
          ref={ref}
          position="relative"
          flexDirection="column"
          justifyContent="center"
          paddingY="half"
          width={{ _: 155, phone: 299, laptop: 384 }}
          maxWidth="100%"
          marginTop="one"
          marginBottom="two"
        >
          <CoverImage
            src={imageUrl}
            alt=""
            width="100%"
            maxWidth="100%"
            height={{ _: 111, phone: 190, laptop: 235 }}
            borderRadius="medium"
            marginBottom="one"
            backgroundColor="surface.background.secondary"
            position="relative"
          />

          {tag && !BadgeComponent && (
            <Overline as="p" color="onSurface.text.subdued">
              {tag}
            </Overline>
          )}

          {BadgeComponent && <BadgeComponent programType={programType} />}

          <SubtitleOne as="h3">
            <TextAction
              ref={linkRef}
              as={LinkDOM}
              to={getLinkPath()}
              css={{
                color: '$onSurfaceTextPrimary',
                fontWeight: 'inherit',
                fontSize: 'inherit',
                '&&:focus': {
                  outline: 'none',
                },
              }}
              onClick={() =>
                programLibraryAnalytics.sendSelectProgram({
                  ...analyticsContext,
                  isChallenge,
                  program,
                })
              }
            >
              {name}
            </TextAction>
          </SubtitleOne>
          <BodyTwo color="onSurface.text.primary" paddingBottom="half">
            {caption}
          </BodyTwo>
          <BodyOneSecondary
            marginTop="quarter"
            display={{ _: 'none', phone: 'block' }}
          >
            {description}
          </BodyOneSecondary>
        </Box>
      </ConditionalLinkCard>
    );
  },
);

export default HealthProgramsCard;
