import * as React from 'react';
import {
  GDSHeadingLevel,
  GDSHeadingSize,
  GDSSpace,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { WidgetType } from '@leagueplatform/masonry-api';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';

enum HeadingSize {
  'xx-large' = 'xxl',
  'x-large' = 'xl',
  'large' = 'lg',
  'medium' = ' md',
  'small' = 'sm',
  'x-small' = 'xs',
  'eyebrow' = 'sm',
}

export interface SectionHeaderProps {
  heading: string;
  headingLevel?: GDSHeadingLevel;
  headingSize?: keyof typeof HeadingSize;
  paddingTop?: GDSSpace;
}

export type SectionHeaderWSNode = MasonryEngineNode<
  WidgetType.SECTION_HEADER,
  SectionHeaderProps
>;

export const SectionHeader = ({
  heading,
  headingLevel = '2',
  paddingTop = '$one',
  headingSize = 'x-large',
}: SectionHeaderProps) => (
  <HeadingText
    size={HeadingSize[headingSize] as GDSHeadingSize}
    level={headingLevel}
    css={{
      paddingTop,
    }}
  >
    {heading}
  </HeadingText>
);
