import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { Icon, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useGetUserProfile } from '@leagueplatform/health-journey-common';
import { REWARDS_PROGRAMS_ASSET_KEYS } from '../../asset-map';

interface ProgramDateProps {
  startDate?: string;
  endDate: string;
}

export function ProgramDate({ startDate, endDate }: ProgramDateProps) {
  const { formatDate, formatMessage } = useIntl();

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  if (!endDate && !startDate) return null;

  let dateString;

  if (startDate) {
    dateString = `${formatMessage(
      { id: 'START_DAY_AT_TIME' },
      {
        day: formatDate(startDate, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          timeZone: defaultTimezone,
        }),
      },
    )}`;

    if (endDate) {
      dateString = dateString.concat(
        ` • ${formatMessage(
          { id: 'END_DAY_AT_TIME' },
          {
            day: formatDate(endDate, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              timeZone: defaultTimezone,
            }),
          },
        )}`,
      );
    }
  } else if (endDate) {
    dateString = formatMessage(
      { id: 'ENDED_DAY_AT_TIME' },
      {
        day: formatDate(endDate, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          timeZone: defaultTimezone,
        }),
      },
    );
  }
  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      spacing="$quarter"
    >
      <Icon
        css={{ filter: `saturate(${startDate ? 1 : 0})` }}
        icon={
          handleStaticAsset(
            REWARDS_PROGRAMS_ASSET_KEYS.REWARDS_PROGRAM_CLOCK,
          ) as string
        }
        size="$one"
      />
      <UtilityText
        css={{
          typography: '$bodyTwo',
          color: startDate ? 'inherit' : '$onSurfaceTextSubdued',
        }}
      >
        {dateString}
      </UtilityText>
    </StackLayout>
  );
}
