import * as React from 'react';
import isToday from 'date-fns/isToday';
import { useIntl } from '@leagueplatform/locales';
import type { Accessors } from 'types/accessor';
import {
  Circle as StyledCircle,
  CircleProps as StyledCircleProps,
} from '../primitives/Circle';
import { Annotation } from '../primitives/Annotation';
import { useChart } from '../providers/chart-data-provider';
import { useTooltip } from '../providers/tooltip-provider';

export type CircleProps<Datum, XResult, YResult> = StyledCircleProps & {
  datum: Datum;
  accessors: Accessors<Datum, XResult, YResult>;
};

// A utility function to retrieve a value for an axis and return the scaled version.
// @ts-ignore
const compose = (scale, accessor) => (d) => scale(accessor(d));

export function Circle<Datum, XResult, YResult>({
  accessors,
  datum,
  ...props
}: CircleProps<Datum, XResult, YResult>) {
  const { formatMessage } = useIntl();
  const { xScale, yScale } = useChart();
  const { showTooltip, hideTooltip } = useTooltip();

  const xPoint = compose(xScale, accessors.xAccessor);
  const yPoint = compose(yScale, accessors.yAccessor);

  const x = xPoint(datum);
  const y = yPoint(datum);

  // TODO: This should be called `interacting` and moved into the `useChart` context.
  const [hasFocus, setFocus] = React.useState(false);

  const handleShowTooltip = () => {
    showTooltip({
      tooltipData: datum,
      tooltipLeft: x,
      tooltipTop: y,
    });
    setFocus(true);
  };

  const handleHideTooltip = () => {
    hideTooltip();
    setFocus(false);
  };

  const shouldShowTodayAnnotation =
    !hasFocus &&
    // This is a bit hacky.  The xAccessor isn't typed to string so typescript isn't aware that it's a date.
    // The snippet below assumes that xAccessor is a date and casts the XResult value.
    // In the future if this isn't a date, the logic below returns `false`.  With the refactor comment below, if this
    // annotation lives somewhere up the tree, we might have better type context and the cast might not be required.
    isToday(new Date(accessors.xAccessor(datum) as string));

  return (
    <>
      <StyledCircle
        cx={x}
        cy={y}
        onFocus={handleShowTooltip}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        onBlur={handleHideTooltip}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {/* TODO: We might want to refactor the annotation outside of the circle component so they  */}
      {/* aren't tightly coupled and reusable in the future */}
      {shouldShowTodayAnnotation && (
        <Annotation
          x={x}
          y={y}
          label={formatMessage({ id: 'TODAY' }).toLocaleUpperCase()}
        />
      )}
    </>
  );
}
