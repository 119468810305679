import React, { useRef, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link as LinkDOM } from '@leagueplatform/routing';
import { ACTION_IDS, TAB_IDS } from '@leagueplatform/wallet-api';
import {
  Link as GenesisLink,
  Image,
  VisuallyHidden,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import {
  TextAction,
  Icon,
  Box,
  Modal,
  HeadingBar,
  Button,
} from '@leagueplatform/genesis-core';
import { formatPhoneNumber } from '@leagueplatform/web-common';
import slugify from 'slugify';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  LEAGUE_MODULE_NAMES,
  useConfigProperty,
  getModulePath,
} from '@leagueplatform/core';
import { GenericContentModal } from 'components/generic-content-modal/generic-content-modal.component';
import { useReactToPrint } from 'react-to-print';
import { OrderNewCardForm } from 'pages/wallet-detail/components/order-new-card/order-new-card.component';
import { useSaveHtmlCard } from './hooks/use-save-html-card.hook';
import {
  GenericActionLinkProps,
  IconProps,
  SaveCardLinkProps,
  PrintCardLinkProps,
} from './types/action-sub-links.types';

const ActionIcon = ({ url = '', alt = '', variant = 'primary' }: IconProps) => {
  const isPrimaryIcon = variant === 'primary';
  const WIDTH = isPrimaryIcon ? 24 : 'auto';
  const MAX_WIDTH = isPrimaryIcon ? WIDTH : 16;
  const MARGIN = isPrimaryIcon ? 'threeQuarters' : 'half';

  if (!url && isPrimaryIcon) {
    return (
      <Icon
        icon="illustrativeDocument"
        css={{ marginRight: '$threeQuarters', flexShrink: '0' }}
        label=""
      />
    );
  }

  return (
    <Image
      alt={alt}
      marginLeft={!isPrimaryIcon ? MARGIN : undefined}
      marginRight={isPrimaryIcon ? MARGIN : undefined}
      minWidth={WIDTH}
      src={url}
      width={WIDTH}
      maxWidth={MAX_WIDTH}
    />
  );
};

export const GenericLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { url, name } = action;
  return (
    <TextAction
      css={{
        display: 'flex',
        justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
      }}
      onClick={onClickCallback}
      href={url}
      className={className}
    >
      {!action.primary && <ActionIcon url={primaryIcon} alt="" />}
      <Box
        as="span"
        css={{
          flexGrow: secondaryIcon ? '1' : undefined,
          textAlign: 'left',
          maxWidth: '100%',
        }}
      >
        {name}
      </Box>
      {secondaryIcon && (
        <ActionIcon
          url={secondaryIcon.url}
          alt={secondaryIcon.alt}
          variant="secondary"
        />
      )}
    </TextAction>
  );
};

// TODO: tech debt, remove this as print card action will handle save card action?
export const SaveCardLink = ({
  action,
  className,
  // most attributes get stripped due to Genesis(?) component nesting,
  // so we need to use data- to make it work
  onClickCallback,
  primaryIcon,
}: SaveCardLinkProps) => {
  const { name, url, primary: isPrimary, cardHtml } = action;
  const { formatMessage } = useIntl();

  const {
    cardName,
    cardImage,
    onSaveCardCallback: onSaveHtmlCardCallback,
  } = useSaveHtmlCard(cardHtml);
  const linkRef = useRef<HTMLAnchorElement>();
  const saveBackImage = () => {
    if (!cardImage?.back?.src || !linkRef?.current) return;
    linkRef.current.click();
  };

  if (cardImage?.front.type !== 'html') {
    return (
      <>
        <TextAction
          className={className}
          href={cardImage?.front.src}
          download={formatMessage(
            { id: 'CARD_FRONT_FILENAME' },
            { benefitName: cardName },
          )}
          onClick={() => {
            onClickCallback();
            saveBackImage();
          }}
        >
          {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}

          <Box as="span" css={{ textAlign: 'left', maxWidth: '100%' }}>
            {name}
          </Box>
        </TextAction>
        {cardImage?.back?.src && (
          <VisuallyHidden>
            <GenesisLink
              ref={linkRef}
              aria-hidden
              href={cardImage?.back?.src}
              download={formatMessage(
                { id: 'CARD_BACK_FILENAME' },
                { benefitName: cardName },
              )}
              tabIndex={-1}
              role="presentation"
              zIndex={-1}
              pointerEvents="none"
            />
          </VisuallyHidden>
        )}
      </>
    );
  }

  return (
    <TextAction
      className={className}
      href={url}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        onSaveHtmlCardCallback();
        onClickCallback();
      }}
    >
      {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}
      <Box as="span" css={{ textAlign: 'left', maxWidth: '100%' }}>
        {name}
      </Box>
    </TextAction>
  );
};

export const DocumentLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { name, url, id } = action;
  const isCardInfo = id === ACTION_IDS.CARD_INFO;
  const slugifySettings = {
    remove: /[$*_+~.()'"!:@%]/g,
    lower: true,
  };

  const classNameUrlPart = url?.split('/').slice(-1)[0] || '';

  const sectionId = isCardInfo
    ? TAB_IDS.CARD_INFO
    : slugify(classNameUrlPart, slugifySettings);

  const contentfulUrl = getContentUrl(sectionId);
  return (
    <TextAction
      className={className}
      css={{
        display: 'flex',
        justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
      }}
      href={contentfulUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClickCallback}
    >
      {!action.primary && <ActionIcon url={primaryIcon} alt="" />}

      <Box
        as="span"
        css={{
          flexGrow: secondaryIcon ? '1' : undefined,
          textAlign: 'left',
          maxWidth: '100%',
        }}
      >
        {name}
      </Box>
      {secondaryIcon && (
        <ActionIcon
          url={secondaryIcon.url}
          alt={secondaryIcon.alt}
          variant="secondary"
        />
      )}
    </TextAction>
  );
};

export const AuthExternalLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { name, url } = action;
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  return (
    <TextAction
      className={className}
      css={{
        display: 'flex',
        justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
      }}
      href={url}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        handleLinkCallback(url || '');
        onClickCallback();
      }}
    >
      {!action.primary && <ActionIcon url={primaryIcon} alt="" />}

      <Box
        as="span"
        css={{
          flexGrow: secondaryIcon ? '1' : undefined,
          textAlign: 'left',
          maxWidth: '100%',
        }}
      >
        {name}
      </Box>
      {secondaryIcon && (
        <ActionIcon
          url={secondaryIcon.url}
          alt={secondaryIcon.alt}
          variant="secondary"
        />
      )}
    </TextAction>
  );
};

export const InternalLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { name, url, primary: isPrimary } = action;
  return (
    <TextAction
      as={LinkDOM}
      css={{
        display: 'flex',
        justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
        width: '100%',
      }}
      onClick={onClickCallback}
      className={className}
      to={url || ''}
    >
      {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}
      <Box
        as="span"
        css={{
          flexGrow: secondaryIcon ? '1' : undefined,
          textAlign: 'left',
          maxWidth: '100%',
        }}
      >
        {name}
      </Box>

      {secondaryIcon && (
        <ActionIcon
          url={secondaryIcon.url}
          alt={secondaryIcon.alt}
          variant="secondary"
        />
      )}
    </TextAction>
  );
};

export const PhoneLink = ({
  action,
  onClickCallback,
  className,
  primaryIcon,
}: GenericActionLinkProps) => {
  const { url, name } = action;
  const phoneNumber = formatPhoneNumber(url?.replace(/\D/g, ''));

  return (
    <Box css={{ display: 'inline-block' }} className={className}>
      <SubtitleOne
        as="p"
        display="flex"
        alignItems="center"
        color="interactive.action.primary"
      >
        {!action.primary && <ActionIcon url={primaryIcon} alt="" />}

        <Box
          as="span"
          css={{
            display: 'inline-block',
            color: '$onSurfaceTextPrimary',
            maxWidth: '100%',
          }}
        >
          {name}

          <TextAction
            css={{ display: 'block' }}
            href={url}
            onClick={() => {
              onClickCallback();
            }}
          >
            {phoneNumber}
          </TextAction>
        </Box>
      </SubtitleOne>
    </Box>
  );
};

export const GenericContentAction = ({
  action,
  onClickCallback,
  className,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const [isShown, setIsShown] = useState(false);
  const { title, body, name } = action;
  const { formatMessage } = useIntl();

  return (
    <Modal.Root
      open={isShown}
      onOpenChange={() => {
        setIsShown((previous) => !previous);
        onClickCallback();
      }}
    >
      <Modal.Trigger>
        <TextAction
          as="button"
          className={className}
          css={{
            display: 'flex',
            justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
            width: '100%',
          }}
        >
          {!action.primary && <ActionIcon url={primaryIcon} alt="" />}
          <Box
            as="span"
            css={{
              flexGrow: secondaryIcon ? '1' : undefined,
              textAlign: 'left',
              maxWidth: '100%',
            }}
          >
            {name}
          </Box>
          {secondaryIcon && (
            <ActionIcon
              url={secondaryIcon.url}
              alt={secondaryIcon.alt}
              variant="secondary"
            />
          )}
        </TextAction>
      </Modal.Trigger>
      <GenericContentModal
        title={title!}
        description={body}
        buttonText={formatMessage({ id: 'CLOSE' })}
      />
    </Modal.Root>
  );
};

export const UnavailableAction = ({
  action,
  onClickCallback,
  className,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { formatMessage } = useIntl();
  const [isShown, setIsShown] = useState(false);
  const {
    unavailable_action: unavailableAction,
    name,
    primary: isPrimary,
  } = action;

  return (
    <Modal.Root
      open={isShown}
      onOpenChange={() => {
        setIsShown((previous) => !previous);
        onClickCallback();
      }}
    >
      <Modal.Trigger>
        <TextAction
          as="button"
          className={className}
          css={{
            display: 'flex',
            justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
            width: '100%',
          }}
        >
          {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}
          <Box
            as="span"
            css={{
              flexGrow: secondaryIcon ? '1' : undefined,
              textAlign: 'left',
              maxWidth: '100%',
            }}
          >
            {name}
          </Box>
          {secondaryIcon && (
            <ActionIcon
              url={secondaryIcon.url}
              alt={secondaryIcon.alt}
              variant="secondary"
            />
          )}
        </TextAction>
      </Modal.Trigger>
      <GenericContentModal
        title={unavailableAction?.title}
        description={unavailableAction?.description}
        imageSrc={unavailableAction?.logo_url}
        buttonText={formatMessage({ id: 'CLOSE' })}
        phoneNumber={unavailableAction?.phone_number}
      />
    </Modal.Root>
  );
};

export const ClaimsLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  let claimsTypeURL = action.url;

  // inspired by use-search-params.hook.ts in claims module
  const urlParams = new URLSearchParams(action.url);

  if (urlParams.has('type')) {
    claimsTypeURL = `${getModulePath(
      LEAGUE_MODULE_NAMES.claims,
    )}?type=${urlParams.get('type')}`;
  }

  return (
    <InternalLink
      action={{ ...action, url: claimsTypeURL }}
      className={className}
      onClickCallback={onClickCallback}
      primaryIcon={primaryIcon}
      secondaryIcon={secondaryIcon}
    />
  );
};

export const PrintCardLink = ({
  action,
  className,
  onClickCallback,
  primaryIcon,
}: PrintCardLinkProps) => {
  const { name, primary: isPrimary, cardRef } = action;

  const pageStyle = `
  @page {
    margin: 1in;
  }

  .wallet-print-card {
    width: 3.375in;
  }

  .wallet-print-card a, .wallet-print-card button {
    display: none;
  }
`;

  const handlePrint = useReactToPrint({
    content: () => cardRef!.current,
    pageStyle,
  });

  return (
    <TextAction
      as="button"
      onClick={() => {
        onClickCallback();
        handlePrint();
      }}
      className={className}
    >
      {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}
      <Box as="span" css={{ textAlign: 'left', maxWidth: '100%' }}>
        {name}
      </Box>
    </TextAction>
  );
};

export const OrderNewCardAction = ({
  action,
  onClickCallback,
  className,
  primaryIcon,
  secondaryIcon,
}: GenericActionLinkProps) => {
  const { formatMessage } = useIntl();
  const [isShown, setIsShown] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [isConfirmExitFlagEnabled, setIsConfirmExitFlagEnabled] =
    useState(true);

  const { name, primary: isPrimary } = action;

  const handleClose = () => {
    if (isConfirmExitFlagEnabled && !isExiting) {
      setIsExiting(true);

      return;
    }

    setIsShown(false);
    setIsExiting(false);
    setIsConfirmExitFlagEnabled(true);
  };
  const handleContinueOrder = () => {
    setIsExiting(false);
  };

  const disableConfirmExitFlag = () => {
    setIsConfirmExitFlagEnabled(false);
  };

  return (
    <Modal.Root
      open={isShown}
      onOpenChange={(isOpen) => {
        // pressing `esc` sets modal state of open to false
        if (isOpen === false) {
          handleClose();
          return;
        }
        setIsShown(true);
        onClickCallback();
      }}
    >
      <Modal.Trigger>
        <TextAction
          as="button"
          className={className}
          css={{
            display: 'flex',
            justifyContent: secondaryIcon ? 'space-between' : 'flex-start',
            width: '100%',
          }}
        >
          {!isPrimary && <ActionIcon url={primaryIcon} alt="" />}
          <Box
            as="span"
            css={{
              flexGrow: secondaryIcon ? '1' : undefined,
              textAlign: 'left',
              maxWidth: '100%',
            }}
          >
            {name}
          </Box>
          {secondaryIcon && (
            <ActionIcon
              url={secondaryIcon.url}
              alt={secondaryIcon.alt}
              variant="secondary"
            />
          )}
        </TextAction>
      </Modal.Trigger>
      <Modal.Content
        forceModalFocus
        layout="fullscreen"
        css={{
          '.GDS-modal': {
            width: '100vw',
            left: '0',
            height: '100vh',
            borderRadius: '$none',
            backgroundColor: '$surfaceBackgroundTertiary',
          },
          '.GDS-modal-close-button-wrapper': {
            display: 'none',
          },
        }}
      >
        <HeadingBar
          css={{ backgroundColor: 'transparent' }}
          rightAction={
            <Button
              css={{ '& > *': { paddingRight: 0 } }}
              priority="secondary"
              quiet
              icon="tinyClose"
              size="medium"
              onClick={handleClose}
            >
              <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            </Button>
          }
        />
        <Modal.Title>
          <VisuallyHidden>
            {formatMessage({ id: 'ORDER_NEW_CARD_HEADING' })}
          </VisuallyHidden>
        </Modal.Title>

        <Modal.Description>
          <OrderNewCardForm
            isExiting={isExiting}
            cancelFunction={handleClose}
            continueFunction={handleContinueOrder}
            disableConfirmExitFlag={disableConfirmExitFlag}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal.Root>
  );
};
