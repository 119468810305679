import React from 'react';
import {
  formatThemeForCommons,
  GenesisThemeProvider,
} from '@leagueplatform/genesis-commons';
import type { PropsWithChildren } from 'react';
import { UIKitThemeProvider } from '@leagueplatform/ui-kit';
import {
  GDSThemeLoaderOptions,
  GDSThemeProvider,
  loadTheme,
} from '@leagueplatform/genesis-core';
import { useConfigProperty } from '@leagueplatform/config';

export const LeagueThemeProvider = ({ children }: PropsWithChildren<{}>) => {
  const { theme, themeOptions } = useConfigProperty('core.ui');
  /**
   * To avoid conflicts with legacy ui-kit CSS, use pixel based font-sizes by default across all apps
   * (unless they explicitly pass `useRems: true` in `GDSThemeLoaderOptions`)
   * so the default is to NOT use rems and INCLUDE legacy theming (i.e. Genesis Commons and UIKit)
   */

  const coreThemeOptions: GDSThemeLoaderOptions = {
    useRems: false,
    ...themeOptions,
  };
  const coreTheme = loadTheme(theme, coreThemeOptions);

  if (themeOptions?.noLegacyTheming === true) {
    /**
     * If the consumer explicitly specifies that they only want to include Genesis Core theme
     * support, return a render tree with only the GDSThemeProvider
     */

    return <GDSThemeProvider theme={coreTheme}>{children}</GDSThemeProvider>;
  }

  /**
   * The default render tree includes legacy theme providers for Genesis Commons and UIKit
   */

  const commonsTheme = formatThemeForCommons(theme);
  return (
    <GDSThemeProvider theme={coreTheme}>
      <GenesisThemeProvider theme={commonsTheme}>
        <UIKitThemeProvider>{children}</UIKitThemeProvider>
      </GenesisThemeProvider>
    </GDSThemeProvider>
  );
};
