import * as React from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import {
  BodyOne,
  Box,
  Flex,
  Image,
  Link,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DEPENDENTS_ASSET_KEYS } from '../../types/dependents.types';

interface LeagueFamilyPendingInviteBannerProps {
  title: string;
  description: string;
  linkText?: string;
  linkUrl?: string;
}

export function LeagueFamilyPendingInviteBanner({
  title,
  description,
  linkText,
  linkUrl,
}: LeagueFamilyPendingInviteBannerProps) {
  const hasLink = linkText && linkUrl;

  return (
    <Box
      as="section"
      width={1}
      marginBottom="oneAndHalf"
      borderRadius="small"
      backgroundColor="highlight.background.subdued"
      padding="one"
      paddingLeft="three"
    >
      <Flex alignItems="center">
        <Image
          src={
            handleStaticAsset(DEPENDENTS_ASSET_KEYS.CIRCLE_INFO_ICON) as string
          }
          alt=""
          size={20}
          marginLeft="minusTwo"
          position="absolute"
        />
        <SubtitleOne as="h2" marginBottom="quarter">
          {title}
        </SubtitleOne>
      </Flex>
      <BodyOne>{description}</BodyOne>

      {hasLink && (
        <Link as={RouterLink} to={linkUrl} fontSize="body2" marginTop="quarter">
          {linkText}
        </Link>
      )}
    </Box>
  );
}
