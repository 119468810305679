import React from 'react';
import { Radio } from '@leagueplatform/genesis-core';
import { QuestionnaireItemAnswerOption } from 'fhir/r4';

import { useIntl } from '@leagueplatform/locales';
import { ControlledFieldset } from '@leagueplatform/web-common';
import { getDisplaySlug } from 'utils/display-slug.util';

export interface RadioGroupProps {
  answerOptions: QuestionnaireItemAnswerOption[];
  id: string;
  name: string;
  legend: React.ReactNode;
  required?: boolean;
}

export const RadioGroup = ({
  answerOptions,
  id,
  name,
  legend,
  required = false,
}: RadioGroupProps) => {
  const { formatMessage } = useIntl();
  const defaultInputRules = {
    required: {
      value: required,
      message: formatMessage({ id: 'PLEASE_MAKE_A_SELECTION' }),
    },
  };

  const inputRules = required ? defaultInputRules : {};

  const defaultValue = answerOptions.find((option) => option.initialSelected)
    ?.valueCoding?.code;

  const getLabel = (label: string | undefined) => label?.split('\n')[0];
  const getHint = (label: string | undefined) => label?.split('\n')[1];

  return (
    <ControlledFieldset
      name={name}
      inputOptions={{
        ...inputRules,
        value: defaultValue,
      }}
      legend={legend}
      id={id}
    >
      {answerOptions.map((option) => {
        const keyAndId = getDisplaySlug({ id, option });
        return (
          <Radio
            key={keyAndId}
            label={
              getLabel(option.valueCoding!.display) ?? option.valueCoding!.code
            }
            hint={getHint(option.valueCoding!.display)}
            name={name}
            value={option.valueCoding!.code}
            id={keyAndId}
            layout="row"
          />
        );
      })}
    </ControlledFieldset>
  );
};
