import React, { forwardRef } from 'react';
import { genesisStyled } from '../utils/genesisStyled';
import { VisuallyHidden } from '../utils/visuallyHidden';
import { Flex } from '../Flex';
import { Box, BoxProps } from '../Box';
import { Circle } from './circle.component';

export interface SpinnerProps extends BoxProps {
  isLoading?: boolean;
  renderLoadingText?: boolean;
}

const StyledCircle = genesisStyled(Circle)`
  fill: transparent;
  height: 24px;
  stroke-width: 2;
  stroke-dasharray: 40;
  stroke-dashoffset: 80;
  animation: stroke 2s linear infinite forwards,
    rotate 1s linear infinite forwards;
  @keyframes stroke {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Span = forwardRef((props: BoxProps, ref) => (
  <Box as="span" ref={ref} {...props} />
));

Span.displayName = 'Span';

const SpinnerPositioner = genesisStyled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`;

SpinnerPositioner.defaultProps = { as: 'span' };

/**
 * @deprecated  use `Spinner` from `genesis-core` instead
 */

export const Spinner: React.FunctionComponent<SpinnerProps> = forwardRef(
  (
    { children, isLoading, renderLoadingText = true, ...props }: SpinnerProps,
    ref
  ) => {
    return (
      <Span display="block" position="relative" ref={ref} {...props}>
        {isLoading && (
          <>
            <SpinnerPositioner aria-hidden="true">
              <StyledCircle />
            </SpinnerPositioner>
            {renderLoadingText && <VisuallyHidden>Loading.</VisuallyHidden>}
          </>
        )}
        <Span opacity={isLoading && '0'}>{children}</Span>
      </Span>
    );
  }
);

Spinner.displayName = 'Spinner';
