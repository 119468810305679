import * as React from 'react';
import { LinePath as VisxLinePath } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { LinePathProps as VisxLinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { useTheme } from '@leagueplatform/genesis-core';

export type LinePathProps<Datum> = VisxLinePathProps<Datum>;

/**
 * Wraps the visx LinePath component and applies genesis styles.
 */
export function LinePath<Datum>(props: LinePathProps<Datum>) {
  const theme = useTheme();

  return (
    <VisxLinePath
      stroke={theme.colors.decorativeBrandPrimaryDark}
      strokeWidth={4}
      curve={curveLinear}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
