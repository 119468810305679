import { useContext } from 'react';
import {
  MasonryConfigContext,
  MasonryConfigContextType,
} from '../context/masonry-config-context';

export const useGetConfig = (): MasonryConfigContextType => {
  const config = useContext(MasonryConfigContext);
  return config;
};
