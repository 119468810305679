import React from 'react';
import styled, { css } from 'styled-components';
import { getColourObject } from '../utils/helpers';

const getColor = ({ disabled, theme }) =>
  disabled ? getColourObject(theme).darkGrey : getColourObject(theme).purple;

const getCursor = (props) => (props.disabled ? 'initial' : 'pointer');

const StyledLink = styled.a`
  color: ${getColor};
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  cursor: ${getCursor};
  &:hover {
    ${({ disabled, theme }) =>
      disabled
        ? css`
            color: ${getColourObject(theme).darkGrey};
          `
        : css`
            text-decoration: underline;
            color: ${getColourObject(theme).purple};
          `};
  }
`;

const Link = ({ ...props }) => {
  return <StyledLink {...props} />;
};

export default Link;
