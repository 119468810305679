import { useQuery } from 'react-query';
import {
  getUserLeaguePointsRewardsBalance,
  GET_USER_LEAGUE_POINTS_REWARDS_BALANCE_MESSAGE_TYPE,
} from '@leagueplatform/rewards-api';
import { captureError } from '@leagueplatform/observability';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

export const useRewardsSidebar = () => {
  const {
    data: isLeagueCreditDisabled,
    isLoading: isLeagueCreditDisabledLoading,
  } = useFeatureFlagQuery('disable_league_credit');
  const { isLoading, isError, data } = useQuery({
    queryKey: [GET_USER_LEAGUE_POINTS_REWARDS_BALANCE_MESSAGE_TYPE],
    queryFn: getUserLeaguePointsRewardsBalance,
    enabled: isLeagueCreditDisabledLoading ? false : !isLeagueCreditDisabled,
    onError: (error) => {
      captureError(
        new Error(`Unable to fetch user's rewards points balance: ${error}`),
      );
    },
  });

  return {
    currentTierRewards: data?.current_tier,
    hasError: isError,
    nextTierRewards: data?.next_tier,
    ready: !isLoading && !isLeagueCreditDisabledLoading,
    userRewardPoints: data?.league_points,
  };
};
