import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme as oldUiKitTheme } from '../theme';
import { useTheme } from '@leagueplatform/genesis-commons';
import { get } from 'lodash';
import { GlobalStyle } from 'assets/css/style';

const themeKeysMap = {
  blue: 'highlight.border.default',
  blueLightest: 'highlight.background.subdued',
  functional: {
    blueLight: 'highlight.background.subdued',
    green: 'success.border.default',
  },
  'highlight.background.subdued': 'highlight.background.subdued',
  'functional.green': 'success.border.default',
  neutral: {
    grayLight: 'onSurface.border.subdued',
    grayLighter: 'interactive.background.disabled',
    grayDark: 'onSurface.text.subdued',
  },
  'neutral.grayLight': 'onSurface.border.subdued',
  'neutral.grayLighter': 'interactive.background.disabled',
  'neutral.grayDark': 'onSurface.text.subdued',
  offWhite: 'surface.background.secondary',
  purple: 'primary.background.default',
  purpleDarkest: 'decorative.brand.primary.darkest',
  purpleLightest: 'interactive.background.hovered',
  white: 'surface.background.primary',
};

export const UIKitThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { colors: genesisColorTheme } = useTheme();

  // To map the Genesis colour tokens from themeKeysMap to their hex values, which will be used by UI-Kit components
  // This way, UI-kit components will map to the colours from a customized Genesis theme
  // There is a recursive call to handle nested object
  const mapGenesisColorTokensToHexValues = (keysMap: any) =>
    Object.entries(keysMap).reduce(
      (accumulator: any, [uiKitKey, genesisKey]: any) => {
        if (typeof genesisKey === 'object') {
          // Recursively iterate over genesisKey object to get the hex values
          accumulator[uiKitKey] = mapGenesisColorTokensToHexValues(genesisKey);
        } else {
          accumulator[uiKitKey] = get(genesisColorTheme, genesisKey);
        }
        return accumulator;
      },
      {},
    );

  const newUIKitThemeColors = mapGenesisColorTokensToHexValues(themeKeysMap);

  const newUIKitTheme = {
    ...oldUiKitTheme,
    colors: {
      ...oldUiKitTheme.colors,
      ...newUIKitThemeColors,
    },
    colour: {
      ...oldUiKitTheme.colour,
      ...newUIKitThemeColors,
    },
  };

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={newUIKitTheme}>{children}</ThemeProvider>
    </>
  );
};
