export enum TEAM_CHOICE {
  JOIN = 'join',
  CREATE = 'create',
}

export enum TEAM_PHOTO_MODAL_PAGES {
  SELECT_PHOTO_PATH = 'SELECT_PHOTO_PATH',
  SELECT_TEAM_AVATAR = 'SELECT_TEAM_AVATAR',
}

export const JOIN_TEAM_CONTAINER_MAX_WIDTH = '1080px';
export const JOIN_TEAM_PROFILE_PICTURE_SIZE = '180px';
export const INPUT_MAX_WIDTH = '483px';
export const CREATE_TEAM_MODAL_MAX_WIDTH = 904;
