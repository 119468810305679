import styled from 'styled-components';
import { leagueFont } from '../utils/font';
import { getColourObject } from '../utils/helpers';

export const LinkButton = styled.button`
  ${leagueFont({ medium: true })};
  background-color: transparent;
  border: none;
  color: ${({ color, theme }) => color || getColourObject(theme).purple};
  cursor: pointer;
  font-size: 1.6rem;
  outline: none;
  padding: 0;
  text-align: left;
`;
