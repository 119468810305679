import React, { FC, forwardRef, isValidElement } from 'react';
import { generateInputHint } from '../../component-generators';
import { CheckboxIndicator } from './checkbox-indicator';
import type { GDSSelectableProps } from '../../types';
import { styled } from '../../../../theme';
import { focusOutlineInner } from '../../../../theme/utils/focus-outline';
import { GenesisCoreInspector } from '../../../../test-utils/genesis-core-inspector';

const LabelText = styled('span', {
  typography: '$bodyOne',
  variants: {
    disabled: {
      true: { color: '$onSurfaceTextSubdued' },
      false: { color: '$onSurfaceTextPrimary' },
    },
    'aria-disabled': {
      true: { color: '$onSurfaceTextSubdued' },
      false: { color: '$onSurfaceTextPrimary' },
    },
  },
});

const labelPadding = '$half';

const CheckboxLabel = styled('label', {
  gap: '$half',
  borderRadius: '$medium',
  padding: labelPadding,
  '&:hover': {
    backgroundColor: '$inputBackgroundHovered',
  },
  '&:active': {
    backgroundColor: '$inputBackgroundPressed',
  },
  variants: {
    layout: {
      inline: {
        display: 'inline-flex',
        '&:focus-within': {
          ...focusOutlineInner,
          input: {
            '&[class*="GDS"]': {
              outline: 'none',
            },
          },
        },
      },
      row: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:focus-within:has(input:focus)': {
          ...focusOutlineInner,
          input: {
            '&[class*="GDS"]': {
              outline: 'none',
            },
          },
        },
      },
    },
    disabled: {
      false: {},
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    'aria-disabled': {
      false: {},
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const Wrapper = styled('div');

export interface GDSCheckboxProps extends GDSSelectableProps {
  indeterminate?: boolean;
}

export const Checkbox: FC<GDSCheckboxProps> = forwardRef(
  (
    {
      label,
      layout = 'inline',
      css,
      id,
      name,
      required,
      hint,
      checked,
      inputStatus,
      disabled,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      value,
      indeterminate,
      onClick,
      onChange,
      onBlur,
      onFocus,
      className,
      loading,
      ...props
    }: GDSCheckboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const hintId = `${id}-hint`;
    return layout === 'row' ? (
      <GenesisCoreInspector displayName="Checkbox">
        <Wrapper className="GDS-checkbox-row" css={{ ...css, width: '100%' }}>
          <CheckboxLabel
            className="GDS-checkbox-label"
            layout={layout}
            htmlFor={id}
            disabled={disabled}
            aria-disabled={ariaDisabled}
          >
            <span>
              <LabelText
                disabled={disabled}
                aria-disabled={ariaDisabled}
                className="GDS-checkbox-label-text"
              >
                {label}
              </LabelText>
              <span className="GDS-checkbox-hint">
                {hint &&
                  generateInputHint({
                    hintId,
                    hint,
                    css: { marginBlockStart: '$quarter' },
                  })}
              </span>
            </span>
            <CheckboxIndicator
              id={id}
              name={name}
              indeterminate={indeterminate}
              inputStatus={inputStatus}
              loading={loading}
              value={value}
              checked={checked}
              disabled={disabled}
              aria-disabled={ariaDisabled}
              aria-describedby={ariaDescribedby}
              onClick={onClick}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              className={className}
              ref={ref}
              css={{
                marginInlineStart: '$half',
              }}
              {...props}
            />
          </CheckboxLabel>
        </Wrapper>
      </GenesisCoreInspector>
    ) : (
      <GenesisCoreInspector displayName="Checkbox">
        <Wrapper className="GDS-checkbox-inline" css={css}>
          <CheckboxLabel
            className="GDS-checkbox-label"
            layout={layout}
            htmlFor={id}
            disabled={disabled}
            aria-disabled={ariaDisabled}
          >
            <CheckboxIndicator
              id={id}
              name={name}
              indeterminate={indeterminate}
              inputStatus={inputStatus}
              loading={loading}
              value={value}
              checked={checked}
              disabled={disabled}
              aria-disabled={ariaDisabled}
              aria-describedby={`${hintId}${
                ariaDescribedby ? ` ${ariaDescribedby}` : ''
              }`}
              onClick={onClick}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              className={className}
              ref={ref}
              {...props}
            />
            {isValidElement(label) ? (
              label
            ) : (
              <LabelText
                disabled={disabled}
                aria-disabled={ariaDisabled}
                className="GDS-checkbox-label-text"
              >
                {label}
              </LabelText>
            )}
          </CheckboxLabel>
          {hint && (
            <Wrapper
              className="GDS-checkbox-hint"
              css={{
                // CheckboxIndicator width + labelPadding + spacing margin CheckboxIndicator and label
                marginInlineStart: `calc($oneAndHalf + ${labelPadding} + $half)`,
              }}
            >
              {generateInputHint({ hint, hintId })}
            </Wrapper>
          )}
        </Wrapper>
      </GenesisCoreInspector>
    );
  },
);

Checkbox.displayName = 'Checkbox';
