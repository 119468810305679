import { useMemo } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { OptionalStatus } from '../types/react-hook-form-input.type';
import { useInputStatus } from './use-input-status.hook';

/**
 *`useInput` is a minimal-config integration of `react-form-hook`'s method of creating inputs in conjunction with it's `FormProvider`
 */
export const useInput = (
  inputName: string,
  inputOptions: RegisterOptions = {},
  optionalStatus?: OptionalStatus,
) => {
  const { register, formState } = useFormContext();
  const inputValidationState = useInputStatus(
    inputName,
    formState,
    optionalStatus,
  );

  const isDirty = formState.dirtyFields[inputName];
  const isTouched = formState.touchedFields[inputName];
  const field = useMemo(
    () => register(inputName, inputOptions),
    [inputName, inputOptions, register],
  );

  return {
    field,
    inputValidationState,
    isDirty,
    isTouched,
  };
};
