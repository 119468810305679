import { ObjectOrArray } from 'styled-system';

export type BorderWidths =
  | 'none'
  | 'thin'
  | 'thick'
  | 'thicker'
  | 'outerFocus'
  | 'innerFocus';

export const borderWidthValues = [0, 1, 2, 4];

export const defaultBorderWidths: ObjectOrArray<number | string> = {
  none: 0,
  thin: 1,
  thick: 2,
  thicker: 4,
  outerFocus: 2,
  innerFocus: 2,
};
