import { useQuery } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import {
  getHealthProfileModule,
  GET_HEALTH_PROFILE_MODULE_MESSAGE_TYPE,
} from '@leagueplatform/assessment-api';

export const useGetHealthProfileModule = (moduleId) => {
  const {
    data: healthProfileModule,
    isLoading,
    isError,
  } = useQuery(
    [GET_HEALTH_PROFILE_MODULE_MESSAGE_TYPE, moduleId],
    () => getHealthProfileModule(moduleId),
    {
      select: (module) => camelcaseKeys(module, { deep: true }),
    },
  );

  return {
    healthProfileModule,
    isLoading,
    isError,
  };
};
