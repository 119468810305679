import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  Box,
  UtilityText,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import type { NotificationCardWidgetProps } from './notification-card-widget.component';

type HeadingLinkProps = PropsWithChildren<
  Pick<NotificationCardWidgetProps, 'onCardClick' | 'url'>
>;

const eyebrowStyles: GDSStyleObject = {
  borderRadius: '$medium',
  backgroundColor: '$surfaceBackgroundTertiary',
  padding: '$quarter $half',
  marginBottom: '$half',
};

const HeadingLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  HeadingLinkProps
>(({ children, url, onCardClick }, ref) => {
  if (url)
    return (
      <Box
        as="a"
        onClick={onCardClick}
        href={url}
        ref={ref as Ref<HTMLAnchorElement>}
        css={{
          '&&': { color: '$onSurfaceTextPrimary', textDecoration: 'none' },
        }}
      >
        {children}
      </Box>
    );
  if (onCardClick)
    return (
      <Box
        as="button"
        ref={ref as Ref<HTMLButtonElement>}
        type="button"
        onClick={onCardClick}
        css={{
          '&&': {
            color: '$onSurfaceTextPrimary',
            border: 'none',
            typography: '$subtitleOne',
            background: 'none',
            padding: 0,
            cursor: 'pointer',
          },
        }}
      >
        {children}
      </Box>
    );
  return <span>{children}</span>;
});

export const NotificationCardContent = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  NotificationCardWidgetProps
>(({ heading, subheading, info, eyebrow, url, onCardClick }, ref) => (
  <StackLayout>
    {eyebrow && (
      <UtilityText css={eyebrowStyles} size="eyebrow" emphasis="emphasized">
        {eyebrow}
      </UtilityText>
    )}

    {heading && (
      <HeadingLink onCardClick={onCardClick} url={url} ref={ref}>
        <HeadingText size="sm" level="2" css={{ cursor: 'inherit' }}>
          {heading}
        </HeadingText>
      </HeadingLink>
    )}

    {subheading && (
      <ParagraphText
        css={{ '&:not(:last-child)': { marginBlockEnd: 0 } }}
        htmlStringOptions={BodyTextHtmlTagOptions}
      >
        {subheading}
      </ParagraphText>
    )}

    {info && (
      <ParagraphText
        emphasis="subtle"
        size="sm"
        htmlStringOptions={BodyTextHtmlTagOptions}
      >
        {info}
      </ParagraphText>
    )}
  </StackLayout>
));
