export const formatFileSize = (fileSize: number) => {
  const fileSizeKilobytes = Math.round(fileSize / 1000);
  const fileSizeMegabytes =
    Math.round((fileSize / 1000 / 1000 + Number.EPSILON) * 10) / 10;
  let fileSizeDisplayed = `${fileSize} B`;
  if (fileSizeMegabytes >= 1) {
    fileSizeDisplayed = `${fileSizeMegabytes} MB`;
  } else if (fileSizeKilobytes >= 1) {
    fileSizeDisplayed = `${fileSizeKilobytes} KB`;
  }
  return fileSizeDisplayed;
};
