import * as React from 'react';
import {
  Box,
  genesisStyled,
  HeadingTwo,
  ThemeProps,
} from '@leagueplatform/genesis-commons';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { HealthMetric } from '@leagueplatform/dashboard-api';
import { InsightsCard } from 'components/insights/insights-card/insights-card';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';

type MetricGroupProps = {
  title: string;
  metrics: HealthMetric[];
  dataTestId?: string;
  embedded?: boolean;
};

const InsightCardContainer = genesisStyled(Box)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: ${({ theme }: ThemeProps) => `${theme?.space.one}px`};
    padding-top: ${({ theme }: ThemeProps) => `${theme?.space.one}px`};
    @media screen and (max-width: ${({ theme }: ThemeProps) =>
      theme?.breakpoints.tablet}) {
      grid-template-columns: 1fr;
    }
`;

export function MetricGroup({
  embedded = true,
  title,
  metrics,
  dataTestId,
}: MetricGroupProps) {
  return (
    <div data-testid={dataTestId}>
      <HeadingTwo marginTop="twoAndHalf" marginBottom="oneAndHalf">
        {title}
      </HeadingTwo>
      <InsightCardContainer>
        {metrics.map(({ type, trend, timestamp, unit, value }) => (
          <InsightsCard
            embedded={embedded}
            key={type}
            type={type}
            trend={trend}
            timestamp={timestamp}
            unit={unit}
            value={value}
            onClick={() => {
              trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                product_area: PRODUCT_AREA.DASHBOARD,
                screen_name: SCREEN_NAMES.ALL_INSIGHTS,
                metric_type: type,
                detail: 'click metric cards',
              });
            }}
          />
        ))}
      </InsightCardContainer>
    </div>
  );
}
