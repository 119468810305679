import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { GetProviderServicesResponse } from 'types/queries/get-provider-services.types';

export const GET_PROVIDER_SERVICES_MESSAGE_TYPE = 'get_provider_services';

export function getProviderServices(
  userId: string | undefined,
  benefitId: string | undefined,
): Promise<GetProviderServicesResponse> {
  return SocketAsFetch.fetch({
    message_type: GET_PROVIDER_SERVICES_MESSAGE_TYPE,
    info: {
      user_id: userId,
      user_benefit_id: benefitId,
    },
  });
}
