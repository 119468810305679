// TODO: switch value type to GDSSpacing when theme shape is merged
export const paddingX = (value: any) => ({
  paddingLeft: value,
  paddingRight: value,
});

export const paddingY = (value: any) => ({
  paddingTop: value,
  paddingBottom: value,
});
