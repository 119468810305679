import React from 'react';
import Masonry from '@leagueplatform/masonry';
import { CarePageWrapper } from '@leagueplatform/care-common';
import { useIntl } from '@leagueplatform/locales';
import {
  usePageViewAnalytics,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { Responsive, StackLayout, Box } from '@leagueplatform/genesis-core';
import { useCareDiscoveryConfig } from '../../care-discovery.config';

export const MasonryGetCareLandingPage = () => {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: SCREEN_NAMES.HOMEPAGE,
    screen_name: SCREEN_NAMES.HOMEPAGE,
  });

  useDocumentTitle(formatMessage({ id: 'GET_CARE' }));

  const defaultMasonryAppId = { left: '', right: '' };

  const { masonryAppId = defaultMasonryAppId } = useCareDiscoveryConfig();

  const masonryConfig = {
    apiVersion: 1,
    analytics: {
      productArea: PRODUCT_AREA.GET_CARE,
      screenName: SCREEN_NAMES.HOMEPAGE,
    },
  };

  const LeftColumn = Masonry.init({
    ...masonryConfig,
    appId: masonryAppId.left,
    apiParams: {
      platform: 'web',
    },
  });

  // Right column is optional, so it needs to be validated
  let RightColumn;
  if (masonryAppId.right) {
    RightColumn = Masonry.init({
      ...masonryConfig,
      appId: masonryAppId.right,
    });
  }

  return (
    <CarePageWrapper title={formatMessage({ id: 'GET_CARE' })}>
      {RightColumn ? (
        <>
          {/* Mobile: RightColumn top + LeftColumn bottom */}
          <Responsive down="tablet">
            <StackLayout spacing="$two">
              <Box css={{ width: '100%' }}>
                <RightColumn.Provider />
              </Box>
              <Box css={{ width: '100%' }}>
                <LeftColumn.Provider />
              </Box>
            </StackLayout>
          </Responsive>
          {/* Tablet and up: LeftColumn left + RightColumn right */}
          <Responsive up="tablet">
            <StackLayout orientation="horizontal" spacing="$two">
              <Box
                css={{
                  width: '70%',
                  '@tablet': { width: '60%' },
                }}
              >
                <LeftColumn.Provider />
              </Box>
              <Box
                css={{
                  width: '30%',
                  '@tablet': { width: '40%' },
                }}
              >
                <RightColumn.Provider />
              </Box>
            </StackLayout>
          </Responsive>
        </>
      ) : (
        <LeftColumn.Provider />
      )}
    </CarePageWrapper>
  );
};
