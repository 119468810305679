import {
  EffectiveTime,
  FieldType,
  HealthMetricInput,
} from '@leagueplatform/dashboard-api';
import { Button, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { IntegerInput } from 'components/inputs/integer-input';
import { RadioInput } from 'components/inputs/radio-input';
import { DateTimeInput } from 'components/inputs/date-time-input';
import { FieldValues, useForm } from 'react-hook-form';
import React from 'react';
import { FormProvider } from '@leagueplatform/web-common';
import { ErrorSaving } from 'components/manage-units-modal/manage-units-modal';
import { formatMetricPatchData } from 'utils/format-metric-patch-data.util';
import { usePatchHealthMetrics } from 'hooks/use-patch-health-metrics.hook';
import { parseAbsoluteToLocal } from '@internationalized/date';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';

interface MetricFormProps {
  metricConfigId: string;
  onSuccessSubmit?: Function;
  metricInput: HealthMetricInput;
  observationId: string;
  value: {
    [key: string]: number;
  };
  timeStamp?: string;
  effectiveDateTime?: EffectiveTime;
  showDateTimePicker?: boolean;
}

export function MetricFormPatch({
  onSuccessSubmit,
  metricInput,
  observationId,
  value,
  timeStamp,
  effectiveDateTime,
  metricConfigId,
  showDateTimePicker,
}: MetricFormProps) {
  const { formatMessage } = useIntl();

  const formMethods = useForm({
    mode: 'onChange',
  });

  const mutation = usePatchHealthMetrics(onSuccessSubmit);

  const handleSubmit = (data: FieldValues) => {
    // return early if mutation in progress
    if (mutation.isLoading) return;

    // return early if mutation is done, and it reports that all inputs were successfully posted
    if (mutation.isSuccess && !mutation.error) return;

    mutation.mutate({
      data: formatMetricPatchData(data, metricInput, metricConfigId),
      observationId,
    });

    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.DASHBOARD,
      screen_name: SCREEN_NAMES.ADD_METRIC_DATA,
      metric_type: metricConfigId,
      detail: 'submit',
    });
  };

  return (
    <FormProvider formMethods={formMethods} onSubmit={handleSubmit}>
      <StackLayout spacing="$one" horizontalAlignment="stretch">
        {showDateTimePicker && (
          <DateTimeInput
            id="effectiveDateTime"
            validationMessage="validationMessage"
            label={formatMessage({ id: 'DATE_AND_TIME' })}
            defaultValue={timeStamp}
            isReadOnly // TO DO styles for read-only field
          />
        )}
        {metricInput.valueFields.map((valueField) => {
          if (valueField.options) {
            return (
              <RadioInput
                id={valueField.fieldKeyName}
                options={valueField.options}
                defaultValue={value[valueField.fieldKeyName]}
                validationMessage={formatMessage({
                  id: 'NO_VALUE_SELECTED_ERROR_MESSAGE',
                })}
                hint={valueField.hint}
              />
            );
          }

          switch (valueField.fieldType) {
            case FieldType.DATETIME:
              // add value to datetime input
              return (
                <DateTimeInput
                  id={valueField.fieldKeyName}
                  label={valueField.fieldLabel}
                  validationMessage={formatMessage({
                    id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD',
                  })}
                  defaultValue={
                    effectiveDateTime?.[
                      valueField.fieldKeyName as keyof EffectiveTime
                    ]
                  }
                  required
                  hint={valueField.hint}
                  validations={{
                    endDateMustBeAfterStart: (
                      fieldValue: string,
                      fieldValues: FieldValues,
                    ) => {
                      const startDate = parseAbsoluteToLocal(fieldValues.start);
                      const endDate = parseAbsoluteToLocal(fieldValues.end);

                      if (fieldValue === fieldValues.start) {
                        return undefined;
                      }

                      const check = startDate.compare(endDate);
                      // 60000 milliseconds = 1 minute, end date must be at least 1 minute after start date
                      if (check >= -60000) {
                        return formatMessage({
                          id: 'START_DATE_MUST_BE_EARLIER_ERROR_MESSAGE',
                        });
                      }
                      return undefined;
                    },
                    notMoreThan24HoursDifference: (
                      fieldValue: string,
                      fieldValues: FieldValues,
                    ) => {
                      const startDate = parseAbsoluteToLocal(fieldValues.start);
                      const endDate = parseAbsoluteToLocal(fieldValues.end);
                      const check = startDate.add({ days: 1 }).compare(endDate);
                      if (fieldValue === fieldValues.start) {
                        return undefined;
                      }
                      if (check < 0) {
                        return formatMessage({
                          id: 'DURATION_EXCEEDS_24_HOURS_ERROR_MESSAGE',
                        });
                      }
                      return undefined;
                    },
                  }}
                />
              );

            case FieldType.QUANTITY: {
              const defaultValue =
                metricInput.valueFields.length === 1
                  ? value[valueField.fieldKeyName]
                  : value[valueField.unit];
              return (
                <IntegerInput
                  validationMessage={formatMessage({
                    id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD',
                  })}
                  id={valueField.fieldKeyName}
                  unit={valueField.unit}
                  placeholder={valueField.placeholderCopy}
                  minValue={valueField.min}
                  maxValue={valueField.max}
                  name={valueField.fieldKeyName}
                  label={valueField.fieldLabel}
                  hint={valueField.hint}
                  defaultValue={defaultValue}
                />
              );
            }
            default:
              return null;
          }
        })}

        {mutation.error && <ErrorSaving />}
      </StackLayout>

      <Button
        loading={mutation.isLoading}
        icon="interfaceCheckmark"
        hideLabel
        type="submit"
        css={{
          position: 'absolute',
          bottom: '$minusFive',
          right: '$none',
          '@mobile': {
            bottom: '$two',
            right: '$two',
          },
        }}
      >
        {formatMessage({ id: 'SAVE' })}
      </Button>
    </FormProvider>
  );
}
