import React, { FC, useMemo } from 'react';
import { Transition } from 'react-transition-group';

// Common Dependencies
import { Box, Flex, css } from '@leagueplatform/genesis-commons';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';

// Hooks

// Common Components

// Local Components

// Constants
import {
  TRANSITIONS,
  TRANSITION_DURATION_MS,
} from '../constants/multi-step-footer-constants';

// Types
interface MultiStepSlideProps {
  gap?: string;
  slideIndex: number;
  activeIndex: number;
}

// Styles
const slideTransition = `transform ${TRANSITIONS.PAGE}, opacity ${TRANSITIONS.PAGE}`;
const reducedMotionSlideTransition = `opacity ${TRANSITIONS.PAGE}`;
const reducedMotionSlideStyles = css({
  left: 0,
  transform: 'none',
  transition: reducedMotionSlideTransition,
});

/**
 * This hook determines each slide's transitionable styles based on the users preference for reduced motion
 * @returns `css()` styles
 */
const useSlideTransitionStyles = ({
  slideIndex,
  gap,
  activeIndex,
}: MultiStepSlideProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const slideStyles = css({
    left: `calc(${slideIndex} * ${gap})`,
    transform: `translateX(calc(-${gap} * ${activeIndex}))`,
    transition: slideTransition,
  });

  return useMemo(
    () => (prefersReducedMotion ? reducedMotionSlideStyles : slideStyles),
    [prefersReducedMotion, slideStyles],
  );
};

export const MultiStepSlide: FC<MultiStepSlideProps> = ({
  children,
  gap = '100%',
  slideIndex,
  activeIndex,
}) => {
  const slideTransitionStyles = useSlideTransitionStyles({
    gap,
    slideIndex,
    activeIndex,
  });

  return (
    <Box
      aria-hidden={activeIndex !== slideIndex}
      css={slideTransitionStyles}
      height="100%"
      opacity={Number(activeIndex === slideIndex)}
      overflowY="auto"
      position="absolute"
      top={0}
      width="100%"
      className="multi-step-slide"
    >
      <Transition
        in={activeIndex === slideIndex}
        timeout={TRANSITION_DURATION_MS.XLONG}
        unmountOnExit
      >
        {children}
      </Transition>
    </Box>
  );
};

export const MultiStepSlider: FC<{}> = ({ children }) => (
  <Flex
    height="100%"
    width="100%"
    position="relative"
    className="multi-step-slider"
  >
    {children}
  </Flex>
);
