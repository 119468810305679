import * as React from 'react';
import { useTheme } from '@leagueplatform/genesis-commons';
import {
  SHORT_WEEKDAY_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { XYChart, Tooltip, AxisBottom, AxisLeft, Grid } from '../xychart';
import { useGetLeftAxisLabelOffset } from '../bar-graph/utils/use-calculate-left-axis-label-offset';
import { LinePath } from '../xychart/components/line-path';
import { Circles } from '../xychart/components/circles';
import { useGetRoundedYMax } from '../bar-graph/utils/get-rounded-y-max';
import { LineGraph as LineGraphType } from './types/line-graph';

export type WeeklyLineGraphProps<Datum> = LineGraphType<Datum>;

const NUM_Y_TICKS = 5;
const tickFormat = (value: number) => Math.round(value).toString();
export function WeeklyLineGraph<Datum>({
  data,
  accessors,
  title,
  description,
  axisLeftLabel,
  ariaDatumLabel,
  axisBottomLabel,
  renderTooltip,
  yMaxFromEnums,
}: WeeklyLineGraphProps<Datum>) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const theme = useTheme();
  const yMin = 0;
  const yMax = useGetRoundedYMax(data, accessors, 100, yMaxFromEnums);

  const labelOffset = useGetLeftAxisLabelOffset(yMax);

  const margin = {
    top: theme.space.three,
    bottom: theme.space.five + theme.space.one,
    left: theme.space.three + labelOffset,
    right: theme.space.four,
  };

  return (
    <XYChart
      title={title}
      desc={description}
      data={data}
      accessors={accessors}
      margin={margin}
      xScale={{
        type: 'point',
        padding: 0.5,
      }}
      yScale={{
        type: 'linear',
        domain: [yMin, yMax],
      }}
    >
      <Grid numTicksRows={NUM_Y_TICKS} centerColumns />
      <AxisLeft
        label={axisLeftLabel}
        labelOffset={labelOffset}
        numTicks={NUM_Y_TICKS}
        hideTicks
        tickFormat={tickFormat}
      />
      <AxisBottom
        label={axisBottomLabel}
        tickFormat={(timestamp: number) =>
          formatDateWithUserProfileLocation(
            new Date(timestamp),
            SHORT_WEEKDAY_FORMAT,
          )
        }
      />
      <LinePath accessors={accessors} data={data} />
      <Circles
        ariaDatumLabel={ariaDatumLabel}
        accessors={accessors}
        data={data}
      />
      <Tooltip<Datum> snapToDatum>{renderTooltip}</Tooltip>
    </XYChart>
  );
}
