import React, { createElement } from 'react';

import {
  GDSStackLayoutProps,
  GDSStyleObject,
  StackLayout,
  GDSHorizontalStackLayoutProps,
} from '@leagueplatform/genesis-core';
import { IconText, IconTextProps } from '../icon-text/icon-text.component';

export type BadgeProps = Omit<GDSStackLayoutProps, 'children'> &
  Record<string, any> &
  (
    | {
        iconTextProps: IconTextProps;
        children?: never;
      }
    | {
        iconTextProps?: never;
        children: React.ReactNode;
      }
  );

const defaultCss: GDSStyleObject = {
  backgroundColor: '$successBackgroundSubdued',
  borderRadius: '$pill',
  color: '$onSurfaceTextPrimary',
  flex: 'initial',
  fontWeight: '$semibold',
  padding: '$quarter $half',
  textDecoration: 'none',
  typography: '$subtitleTwo',
  width: 'fit-content',
};

export const Badge = ({
  iconTextProps,
  children,
  css = {},
  ...other
}: BadgeProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    spacing="$quarter"
    css={{ ...defaultCss, ...css }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...(other as GDSHorizontalStackLayoutProps)}
  >
    {iconTextProps ? createElement(IconText, iconTextProps) : children}
  </StackLayout>
);
