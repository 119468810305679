import React from 'react';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { VerticalLargeImageCard } from 'components/common/vertical-large-image-card/vertical-large-image-card.component';
import { HorizontalLargeImageCard } from 'components/common/horizontal-large-image-card/horizontal-large-image-card.component';
import type { ImageAlignLargeImageCard } from '../horizontal-large-image-card/horizontal-large-image-card.component';

export interface LargeImageCardProps {
  image: string;
  imageAltText?: string;
  imageAlign?: ImageAlignLargeImageCard;
  isChildItem?: boolean;
  children: React.ReactElement;
}

export const LargeImageCard = ({
  image,
  imageAltText = '',
  isChildItem = false,
  imageAlign,
  children,
}: LargeImageCardProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const CardComponent =
    isMobile || isChildItem ? VerticalLargeImageCard : HorizontalLargeImageCard;

  return (
    <CardComponent
      image={image}
      imageAltText={imageAltText}
      imageAlign={imageAlign}
    >
      {children}
    </CardComponent>
  );
};
