import React, { FC, cloneElement, isValidElement } from 'react';
import { FormField, GDSFormFieldProps } from '@leagueplatform/genesis-core';
import { useInput } from '../hooks/use-input.hook';
import {
  ControlledInputCommonProps,
  PrunedGDSFieldProps,
} from '../types/react-hook-form-input.type';

interface ControlledFormFieldProps
  extends ControlledInputCommonProps,
    PrunedGDSFieldProps<GDSFormFieldProps> {}

export const ControlledFormField: FC<ControlledFormFieldProps> = ({
  name,
  inputOptions = {},
  optionalStatus,
  children,
  ...props
}) => {
  const { field, inputValidationState } = useInput(
    name,
    inputOptions,
    optionalStatus,
  );
  const required = Boolean(inputOptions?.required);
  const { inputStatus, statusMessage } = inputValidationState;

  return (
    <FormField
      name={name}
      inputStatus={inputStatus}
      statusMessage={statusMessage}
      required={required}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isValidElement(children) && cloneElement(children, field)}
    </FormField>
  );
};
