import React, {
  cloneElement,
  ReactElement,
  ReactNode,
  isValidElement,
} from 'react';
import type {
  GDSGenerateInputLabelProps,
  GDSGenerateLegendProps,
} from './types';
import { InputLabel } from './form-field-elements/input-label';
import { FieldsetLegend } from './form-field-elements/fieldset-legend';
import { InputHint } from './form-field-elements/input-hint';
import { isReactElement } from './utilities';
import type { GDSStyleObject } from '../../theme';
import { InputStatusMessage } from './form-field-elements/input-status-message';
import type { GDSInputStatusMessageProps } from './form-field-elements/input-status-message';

export const generateInputLabel = ({
  label,
  htmlFor,
  required,
  hideLabel,
  tooltip,
}: GDSGenerateInputLabelProps) => {
  if (isReactElement(label)) {
    return cloneElement(label as ReactElement<any>, {
      htmlFor,
      required,
      hideLabel,
      as: 'label',
    });
  }
  return (
    <InputLabel
      htmlFor={htmlFor}
      required={required}
      hideLabel={hideLabel}
      tooltip={tooltip}
    >
      {label}
    </InputLabel>
  );
};

interface GDSHintGeneratorArguments {
  hint: ReactNode;
  hintId: string;
  css?: GDSStyleObject;
}

export const generateInputHint = ({
  hint,
  hintId,
  css,
}: GDSHintGeneratorArguments) => {
  if (isReactElement(hint)) {
    return cloneElement(hint as ReactElement<any>, {
      id: hintId,
    });
  }
  return (
    <InputHint id={hintId} css={css}>
      {hint}
    </InputHint>
  );
};

export const generateLegend = ({
  legend,
  required,
  hideLegend,
  tooltip,
}: GDSGenerateLegendProps) => {
  if (isReactElement(legend)) {
    return cloneElement(legend as ReactElement<any>, {
      required,
      hideLegend,
      as: 'legend',
    });
  }
  return (
    <FieldsetLegend
      required={required}
      hideLegend={hideLegend}
      tooltip={tooltip}
    >
      {legend}
    </FieldsetLegend>
  );
};

interface GDSStatusMessageGeneratorArguments
  extends GDSInputStatusMessageProps {
  statusMessage: ReactNode;
}

export const generateInputStatusMessage = ({
  css,
  id,
  inputStatus,
  statusIconLabel,
  statusMessage,
}: GDSStatusMessageGeneratorArguments) => {
  if (isValidElement(statusMessage)) {
    if (statusMessage.type === InputStatusMessage) {
      return cloneElement(statusMessage as ReactElement<any>, {
        css,
        id,
        inputStatus,
        statusIconLabel,
      });
    }
    return cloneElement(statusMessage as ReactElement<any>, {
      css,
      id,
    });
  }
  return (
    <InputStatusMessage
      id={id}
      inputStatus={inputStatus}
      statusIconLabel={statusIconLabel}
      css={css}
    >
      {statusMessage}
    </InputStatusMessage>
  );
};
