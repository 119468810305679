import * as React from 'react';
import {
  InsightHighlightCard,
  InsightCardVerticalBarGraph,
} from '@leagueplatform/dashboard';
import { VerticalBarGraph } from '@leagueplatform/dashboard-api';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { ABOUT_ME_MASONRY_API_VERSION } from 'constants/masonry';
import { captureError } from '@leagueplatform/observability';
import { INSIGHT_TYPE } from 'constants/analytics';
import { trackWidgetClickEvent } from './utils';

type VerticalBarGraphInsightResponse = {
  body: VerticalBarGraph;
  callout: {
    description: string;
    image: string;
    image_alt_text: string;
  };
  cta: string;
  heading: string;
};

type VerticalBarGraphInsightWidgetProps = {
  widget: ContainerWidgetItem;
};

export function VerticalBarGraphInsightWidget({
  widget,
}: VerticalBarGraphInsightWidgetProps) {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<VerticalBarGraphInsightResponse>(
    widget,
    ABOUT_ME_MASONRY_API_VERSION,
  );

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error) {
    captureError(
      new Error(
        `Unable to fetch widget data for the vertical bar graph insight widget: ${error}`,
      ),
    );
  }

  if (error || !widgetData || !widgetData?.attributes) {
    return null;
  }

  const { body, callout, cta, heading } = widgetData.attributes;

  return (
    <InsightHighlightCard
      heading={heading}
      seeMoreLink={cta}
      callout={callout}
      onClick={() => {
        trackWidgetClickEvent(body.metric, INSIGHT_TYPE.VERTICAL_BAR_GRAPH);
      }}
    >
      <InsightCardVerticalBarGraph
        title={body.title}
        description={body.description}
        metric={body.metric}
        unit={body.unit}
        items={body.items}
      />
    </InsightHighlightCard>
  );
}
