import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  HEALTH_METRIC,
  HealthMetric,
  HealthMetricValue,
} from './types/health-metrics-data.type';

type Value =
  | {
      type: 'value-quantity';
      [key: string]: string | number;
    }
  | {
      type: 'value-enum';
      [key: string]: string | number;
    }
  | {
      type: 'value-period';
      start: string;
      end: string;
    };

export type HealthMetricPostData = {
  metricConfigId: string;
  metricComponentId: string;
  effectiveDateTime?: string;
  value: Value;
};

export async function createHealthMetrics(
  data: HealthMetricPostData,
): Promise<{ data: HealthMetricValue }> {
  const response = await leagueFetch('v3/health-metrics', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: HEALTH_METRIC,
        attributes: data,
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`PostCreateHealthMetrics error: ${response.status}`);
  }
  const responseJson: HealthMetric = await response.json();

  return deserialise(responseJson);
}
