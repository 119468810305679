import type {
  GDSBreakpoint,
  GDSBreakpointOrNumber,
  GDSBreakpoints,
} from '../types';
import { breakpoints } from './constants';

// Adapted from Material UI's breakpoint helpers code
// https://github.com/mui/material-ui/blob/master/packages/mui-system/src/createTheme/createBreakpoints.js

const sortBreakpointsValues = (values: GDSBreakpoints) => {
  const keys = Object.keys(values) as GDSBreakpoint[];

  const breakpointsAsArray =
    keys.map((key) => ({ key, val: values[key] })) || [];

  // Sort in ascending order
  breakpointsAsArray.sort(
    (breakpoint1, breakpoint2) => breakpoint1.val - breakpoint2.val,
  );

  return breakpointsAsArray.reduce(
    (acc, obj) => ({ ...acc, [obj.key]: obj.val }),
    {},
  );
};

const sortedBreakpointValues = sortBreakpointsValues(breakpoints);
const breakpointKeys = Object.keys(sortedBreakpointValues) as GDSBreakpoint[];

const unit = 'px';

const isBreakpointToken = (token: any): token is GDSBreakpoint =>
  token in breakpoints;

const getValue = (breakpoint: any) => {
  if (isBreakpointToken(breakpoint)) return breakpoints[breakpoint];
  return breakpoint;
};

const up = (breakpoint: GDSBreakpointOrNumber) => {
  const value = getValue(breakpoint);
  return `(min-width:${value}${unit})`;
};

const down = (breakpoint: GDSBreakpointOrNumber) => {
  const value = getValue(breakpoint);
  return `(max-width:${value - 1}${unit})`;
};

const between = (
  rangeValues: [GDSBreakpointOrNumber, GDSBreakpointOrNumber],
) => {
  const startValue = getValue(rangeValues?.[0]);
  const endValue = getValue(rangeValues?.[1]);

  return (
    `(min-width:${startValue}${unit}) and ` +
    `(max-width:${endValue - 1}${unit})`
  );
};

const only = (breakpoint: GDSBreakpoint) => {
  if (breakpointKeys.indexOf(breakpoint) + 1 < breakpointKeys.length) {
    return between([
      breakpoint,
      breakpointKeys[breakpointKeys.indexOf(breakpoint) + 1],
    ]);
  }
  return up(breakpoint);
};

const not = (breakpoint: GDSBreakpoint) => {
  const keyIndex = breakpointKeys.indexOf(breakpoint);

  if (keyIndex === 0) {
    return up(breakpointKeys[1]);
  }
  if (keyIndex === breakpointKeys.length - 1) {
    return down(breakpointKeys[keyIndex]);
  }

  const betweenQuery = between([
    breakpoint,
    breakpointKeys[breakpointKeys.indexOf(breakpoint) + 1],
  ]);

  return betweenQuery && `not all and ${betweenQuery}`;
};

export const queryHelpers = { up, down, between, only, not };
