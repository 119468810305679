type UserData = {
  email?: string;
  userProfile?: {
    preferredFirstName?: string;
    firstName?: string;
  };
};

export const getPreferredFirstName = (userData?: UserData) =>
  userData?.userProfile?.preferredFirstName ??
  userData?.userProfile?.firstName ??
  userData?.email ??
  '';
