import * as React from 'react';
import isToday from 'date-fns/isToday';
import { useIntl } from '@leagueplatform/locales';
import {
  useTheme,
  HeadingOne,
  HeadingTwo,
  Flex,
  Caption,
} from '@leagueplatform/genesis-commons';
import { useFormatDateByUserProfileLocation } from '@leagueplatform/web-common';
import { Measurement } from '../types/measurement';

type LastUpdatedPanelProps = {
  lastUpdated?: string;
  measurement: Measurement;
};

export function TodaysMeasurementPanel({
  lastUpdated,
  measurement,
}: LastUpdatedPanelProps) {
  const { formatMessage, formatList } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const theme = useTheme();

  const wasLastUpdateToday = !!lastUpdated && isToday(new Date(lastUpdated));

  return (
    <>
      <Flex
        alignItems="baseline"
        justifyContent="space-between"
        paddingLeft="oneAndHalf"
        paddingRight="oneAndHalf"
        paddingTop="one"
        marginRight="two"
        paddingBottom="one"
        backgroundColor="surface.background.secondary"
        width={`calc(100% - ${theme.space.two}px)`}
      >
        <HeadingTwo>
          {formatMessage({
            id: 'TODAY',
          })}
        </HeadingTwo>
        <Flex alignItems="baseline">
          {measurement.value !== undefined ? (
            <>
              <HeadingOne as="span">{measurement.value}</HeadingOne>
              <Caption
                as="span"
                paddingLeft="quarter"
                color="onSurface.text.subdued"
              >
                {measurement.type}
              </Caption>
            </>
          ) : (
            <HeadingOne as="span">--</HeadingOne>
          )}
        </Flex>
      </Flex>
      {wasLastUpdateToday ? (
        <Flex
          width={`calc(100% - ${theme.space.two}px)`}
          paddingTop="half"
          paddingBottom="quarter"
          justifyContent="flex-end"
          marginRight="two"
        >
          <Caption
            as="span"
            paddingLeft="quarter"
            color="onSurface.text.subdued"
            fontWeight="bold"
          >
            {formatMessage({
              id: 'LAST_UPDATED',
            })}
            :
          </Caption>
          <Caption
            as="span"
            paddingLeft="quarter"
            color="onSurface.text.subdued"
            minWidth={theme.space.five}
          >
            {formatList(
              [
                formatMessage({
                  id: 'TODAY',
                }),
                formatDateWithUserProfileLocation(lastUpdated, {
                  hour: 'numeric',
                  minute: 'numeric',
                }),
              ],
              { type: 'conjunction', style: 'narrow' },
            )}
          </Caption>
        </Flex>
      ) : null}
    </>
  );
}
