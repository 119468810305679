import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { every, isEmpty } from 'lodash';

export const hasAnswers = (answers) => {
  if (!answers?.currentAnswer) {
    return false;
  }
  return !every(answers, isEmpty);
};

export const containsAllowedDomains = (url) => {
  try {
    const { hostname } = new URL(url);
    return (
      hostname === 'league.com' ||
      hostname.endsWith('.league.com') ||
      hostname.endsWith('.league.dev')
    );
  } catch (_) {
    return false;
  }
};

export const containsForbiddenPaths = (url) =>
  // member is not supposed to go back to the assessment after completing/exiting it
  url.includes('sign-in') ||
  url.includes(getModulePath(LEAGUE_MODULE_NAMES.assessment));

export const canRedirectUsingURL = (url) =>
  url && containsAllowedDomains(url) && !containsForbiddenPaths(url);
