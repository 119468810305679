import React, {
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useLocation } from '@leagueplatform/routing';
import { FormikState } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  InputStatusMessage,
  Box,
  VisuallyHidden,
  UtilityText,
  Button,
  styled,
  focusOutlineOuter,
} from '@leagueplatform/genesis-core';
import {
  MESSAGING_SCREEN_NAMES,
  trackMessagingAnalyticsEvent,
} from 'utils/track-messaging-analytics.util';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { captureError } from '@leagueplatform/observability';
import { MetaConfigurationAttributes } from '@leagueplatform/messaging-api';
import { getConfigOverride } from 'utils/get-config-override.util';
import { formatFileSize } from 'utils/format-file-size.util';
import { useIsImageThumbnail } from 'hooks/use-is-image-thumbnail.hook';
import { useFhirResponseConfig } from 'components/fhir-response-config-context/fhir-response-config-context';
import { useGetComposeMessageFooterConfig } from '../../../hooks/use-get-compose-message-footer-config.hook';
import { AutoResizeTextarea } from './autoresize-textarea.component';
import { FilePreview } from './file-preview.component';
import {
  TEXT_AREA_NAME,
  FILE_UPLOAD_NAME,
} from './compose-message-footer.constants';

const StyledLabel = styled('label', {
  order: '1',
  borderRadius: '$circle',
  color: '$interactiveActionPrimary',
  paddingX: '$quarter',
  paddingY: '$quarter',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$tertiaryBackgroundHovered',
    color: '$tertiaryTextHovered',
  },
  '&:active': {
    backgroundColor: '$tertiaryBackgroundPressed',
    color: '$tertiaryTextHovered',
  },
  '&:focus-within': {
    backgroundColor: '$tertiaryBackgroundHovered',
    color: '$tertiaryTextHovered',
    ...focusOutlineOuter,
  },
});

export interface FormValues {
  [TEXT_AREA_NAME]: string;
  [FILE_UPLOAD_NAME]: File[] | null;
}

interface ComposeMessageFooterProps {
  values: FormValues;
  setFieldValue: (field: typeof FILE_UPLOAD_NAME, value: File[] | null) => void;
  isLoading: boolean;
  isButtonDisabled: boolean;
  resetForm: (nextState?: Partial<FormikState<FormValues>> | undefined) => void;
  textareaError?: string;
  threadConfigOverride?: MetaConfigurationAttributes[];
}

const FILE_UPLOAD_ID = 'uploadedFileId';
const TEXT_AREA_ID = 'textAreaId';

export const ComposeMessageFooter = ({
  values,
  setFieldValue,
  isLoading,
  isButtonDisabled,
  resetForm,
  textareaError,
  threadConfigOverride,
}: ComposeMessageFooterProps) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const [currentLocation, setCurrentLocation] = useState(pathname);
  const [isDragging, setIsDragging] = useState(false);

  const [configValue] = useFhirResponseConfig();

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    // reset the form any time we move to a new route
    if (currentLocation !== pathname) {
      resetForm();
      setCurrentLocation(pathname);
      textAreaRef.current?.focus();
    }
  }, [currentLocation, setCurrentLocation, pathname, resetForm]);

  const {
    allowedFileTypes: defaultAllowedFileTypes,
    maxFileSize: defaultMaxFileSize,
    documentUploadEnabled: defaultDocumentUploadEnabled,
    maxMessageLength: defaultMaxMessageLength,
    maxNumberOfDocs: defaultMaxNumberOfDocs,
  } = useGetComposeMessageFooterConfig();

  const allowedFileTypes = useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultAllowedFileTypes,
        configName: 'DOC_TYPES',
        fhirResponseConfigs: configValue?.data,
        threadConfigs: threadConfigOverride,
        format: 'string',
      }),
    [configValue?.data, defaultAllowedFileTypes, threadConfigOverride],
  );

  const maxFileSize = useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultMaxFileSize,
        configName: 'DOC_MAX_SIZE',
        fhirResponseConfigs: configValue?.data,
        threadConfigs: threadConfigOverride,
        format: 'number',
      }),
    [configValue?.data, defaultMaxFileSize, threadConfigOverride],
  );

  const documentEnabled = useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultDocumentUploadEnabled,
        configName: 'DOC_UPLOAD_ENABLED',
        fhirResponseConfigs: configValue?.data,
        threadConfigs: threadConfigOverride,
        format: 'bool',
      }),
    [configValue?.data, defaultDocumentUploadEnabled, threadConfigOverride],
  );

  const maxMessageLength = useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultMaxMessageLength,
        configName: 'MESSAGE_MAX_SIZE',
        fhirResponseConfigs: configValue?.data,
        threadConfigs: threadConfigOverride,
        format: 'number',
      }),
    [configValue?.data, defaultMaxMessageLength, threadConfigOverride],
  );

  const maxNumberOfDocs = useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultMaxNumberOfDocs,
        configName: 'MAX_NUMBER_OF_DOCS',
        fhirResponseConfigs: configValue?.data,
        threadConfigs: threadConfigOverride,
        format: 'number',
      }),
    [configValue?.data, defaultMaxNumberOfDocs, threadConfigOverride],
  );

  /*
    useEffect refocuses on the text area after we get a response from the
    BE when a user sends a message.
  */
  useEffect(() => {
    if (!isLoading) {
      textAreaRef.current?.focus();
    }
  }, [isLoading]);

  /*
    useEffect to handle expand our dragarea when entering/leaving the window with a dragged item.
  */
  useEffect(() => {
    const handleDragEnter = () => setIsDragging(true);
    window.addEventListener('dragenter', handleDragEnter);
    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
    };
  }, []);

  const filesArray = useMemo(() => values[FILE_UPLOAD_NAME] || [], [values]);

  const isImageThumbnail = useIsImageThumbnail();

  const imageThumbnails = useMemo(
    () => filesArray.filter((file: File) => isImageThumbnail(file)),
    [filesArray, isImageThumbnail],
  );

  const nonImageThumbnails = useMemo(
    () => filesArray.filter((file: File) => !isImageThumbnail(file)),
    [filesArray, isImageThumbnail],
  );

  const validateAndSetFile = (file: File) => {
    const allowedFileTypesArray = allowedFileTypes.split(', ');
    if (filesArray.length >= maxNumberOfDocs) {
      const fileTooLargeError = new Error(
        'Document / surpasses max number of documents allowed',
      );
      captureError(fileTooLargeError);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ATTACHMENT_LIMIT_REACHED',
        shouldAutoClose: false,
        // content is incorrectly typed requiring a key/value pair, but is functional with a string.
        // Eugen and I spoke about this and the web plat team will look into it, for now he said
        // it is ok to ts-ignore
        // @ts-ignore
        content: formatMessage(
          { id: 'MAXIMUM_NUM_OF_ATTACHMENTS_ALLOWED' },
          { maxNumberOfDocs },
        ),
      });
    } else if (file.size > maxFileSize) {
      const fileTooLargeError = new Error(
        'Document / photo file size is too big',
      );
      captureError(fileTooLargeError, {
        context: {
          'Response Info': {
            'File Size': `${file.size} bytes`,
            'Max Size': `${maxFileSize} bytes`,
          },
        },
      });
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'FILE_TOO_LARGE',
        shouldAutoClose: false,
        // content is incorrectly typed requiring a key/value pair, but is functional with a string.
        // Eugen and I spoke about this and the web plat team will look into it, for now he said
        // it is ok to ts-ignore
        // @ts-ignore
        content: formatMessage(
          { id: 'FILE_SHOULD_BE_UNDER' },
          { fileSize: formatFileSize(maxFileSize) },
        ),
      });
    } else if (
      !allowedFileTypesArray.includes(
        `.${file.name.split('.').pop()?.toLowerCase()}`,
      )
    ) {
      const fileTypeNotSupportedError = new Error(
        'Document / photo file type is not supported',
      );
      captureError(fileTypeNotSupportedError, {
        context: {
          'Response Info': {
            'File Type': file.type,
            'File Name': file.name,
          },
        },
      });
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'OOPS_FILE_TYPE_ISNT_SUPPORTED',
        shouldAutoClose: false,
        // See comment on line 135
        // @ts-ignore
        content: formatMessage(
          { id: 'ATTACHMENT_TYPES_ALLOWED' },
          { fileTypes: allowedFileTypes },
        ),
      });
    } else {
      setFieldValue(FILE_UPLOAD_NAME, [...filesArray, file]);
    }
  };
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    // TODO: Add in Toast to explain which files are allowed/not allowed/file limit size />
    // https://everlong.atlassian.net/browse/PCHAT-810
    if (!e?.dataTransfer?.items.length) return;
    const file = e.dataTransfer.items[0].getAsFile();
    if (!file) return;
    validateAndSetFile(file);
  };

  const validateTextarea = (value: string) => {
    let error;
    if (maxMessageLength && value.length > maxMessageLength) {
      error = formatMessage(
        {
          id: 'CHAR_LIMIT_EXCEEDED',
        },
        { maximum: maxMessageLength },
      );
    }
    return error;
  };

  const handleRemoveFile = useCallback(
    (removedFile: File) => {
      const removedFileIndex = filesArray?.findIndex(
        (file) => file === removedFile,
      );
      const filesArrayCopy = [...filesArray];
      filesArrayCopy.splice(removedFileIndex, 1);
      setFieldValue(FILE_UPLOAD_NAME, filesArrayCopy);
    },
    [filesArray, setFieldValue],
  );
  return (
    <Box
      css={{
        paddingX: '$twoAndHalf',
        paddingY: '$two',
        marginBottom: '$quarter',
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        '@mobileLandscape': {
          paddingX: '$half',
        },
        '@mobile': {
          paddingX: '$half',
        },
      }}
    >
      {/* Dropzone */}
      <Box
        onDrop={handleDrop}
        onDragOver={(e: React.DragEvent) => e.preventDefault()}
        onDragLeave={() => setIsDragging(false)}
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: isDragging ? 'auto' : 'none',
          backgroundColor: isDragging
            ? '$interactiveBackgroundDefault'
            : 'transparent',
          opacity: 0.5,
          zIndex: 1,
        }}
      />
      <Box
        css={{
          order: '2',
          flexGrow: '1',
          // a fixed width is required to avoid some unwanted stacking with
          // the upload button and textarea
          // it will not reflect the actual rendered width, as flexGrow will
          // allow the textarea to fill the remaining space
          width: '100px',
          paddingX: '$half',
          position: 'relative',
        }}
      >
        <VisuallyHidden>
          <label htmlFor={TEXT_AREA_ID}>
            {formatMessage({ id: 'WRITE_A_MESSAGE' })}
          </label>
        </VisuallyHidden>
        <Box
          css={{
            backgroundColor: '$surfaceBackgroundSecondary',
            borderRadius: '$extraLarge',
          }}
        >
          <AutoResizeTextarea
            id={TEXT_AREA_ID}
            textareaName={TEXT_AREA_NAME}
            textValue={values[TEXT_AREA_NAME]}
            disabled={isLoading}
            textAreaRef={textAreaRef}
            validateTextarea={validateTextarea}
            ariaDescribedby={
              textareaError ? `${TEXT_AREA_ID}-error` : undefined
            }
          />
          {filesArray.length > 0 && (
            <Box
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                paddingBottom: '$one',
              }}
            >
              {imageThumbnails.length > 0 && (
                <Box
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                    '@mobile': {
                      width: '100%',
                    },
                  }}
                >
                  {imageThumbnails.map((file) => (
                    <FilePreview
                      key={file.lastModified}
                      fileUpload={file}
                      onRemove={handleRemoveFile}
                    />
                  ))}
                </Box>
              )}
              {nonImageThumbnails.length > 0 && (
                <Box
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                    marginTop: imageThumbnails.length > 0 ? '$half' : undefined,
                    '@mobile': {
                      width: '100%',
                    },
                  }}
                >
                  {nonImageThumbnails.map((file) => (
                    <FilePreview
                      key={file.lastModified}
                      fileUpload={file}
                      onRemove={handleRemoveFile}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
        {textareaError && (
          <Box css={{ position: 'absolute' }}>
            <InputStatusMessage
              id={`${TEXT_AREA_ID}-error`}
              inputStatus="error"
              statusIconLabel={formatMessage({ id: 'ERROR' })}
            >
              {textareaError}
            </InputStatusMessage>
          </Box>
        )}
      </Box>
      {documentEnabled && (
        <StyledLabel htmlFor={FILE_UPLOAD_ID}>
          <Box
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '$button',
            }}
            aria-hidden="true"
          >
            {
              handleStaticAsset(
                MESSAGING_ASSET_KEYS.MESSAGING_ADD_DOCUMENT_ICON,
                {
                  isComponent: true,
                },
              ) as JSX.Element
            }
          </Box>
          <VisuallyHidden>
            {formatMessage({ id: 'UPLOAD_MULTIPLE_FILES' })}
            <input
              id={FILE_UPLOAD_ID}
              name={FILE_UPLOAD_NAME}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e.currentTarget.files?.length) {
                  const fileUploadError = new Error(
                    'Document / Photo upload error',
                  );
                  captureError(fileUploadError);
                  return;
                }
                const file = e.currentTarget.files[0];
                validateAndSetFile(file);
              }}
              type="file"
              accept={allowedFileTypes}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                trackMessagingAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  screen_name: MESSAGING_SCREEN_NAMES.MESSAGE_THREAD,
                  detail: 'document',
                });
                // Resetting the native input's value since it is not being updated by the setField event
                // Doesn't impact anything beyond ensuring the onChange event fires since the value is still stored in Formik.
                (e.target as HTMLInputElement).value = '';
              }}
            />
          </VisuallyHidden>
        </StyledLabel>
      )}
      <Button
        type="submit"
        disabled={isButtonDisabled}
        loading={isLoading}
        hideLabel={isLoading}
        css={{
          order: 3,
          paddingX: '$oneAndHalf',
          paddingY: '$half',
          marginBottom: '$quarter',
          marginTop: '$one',
          flexBasis: 'auto',
          justifyContent: 'center',
          '@mobileLandscape': {
            flexBasis: '100%',
            marginTop: '$oneAndHalf',
          },
          '@mobile': {
            flexBasis: '100%',
            marginTop: '$oneAndHalf',
          },
        }}
      >
        <VisuallyHidden>{formatMessage({ id: 'SEND_MESSAGE' })}</VisuallyHidden>
        <UtilityText
          css={{ color: 'inherit', fontWeight: 'inherit' }}
          aria-hidden="true"
        >
          {formatMessage({ id: 'SEND' })}
        </UtilityText>
      </Button>
    </Box>
  );
};
