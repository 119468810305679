import React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { GetCareLandingPage } from './pages/get-care-landing/get-care-landing.page';
import { MasonryGetCareLandingPage } from './pages/masonry-get-care-landing/masonry-get-care-landing.page';
import { QuickActionPage } from './pages/quick-action/quick-action.page';
import { useCareDiscoveryConfig } from './care-discovery.config';

export const CareDiscoveryRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  const { data: isMasonryDisabled, isLoading } = useFeatureFlagQuery(
    'disable_care_masonry_page',
  );

  let LandingPage;

  if (isLoading) {
    LandingPage = LoadingIndicator;
  }

  const hasMasonryId = useCareDiscoveryConfig().masonryAppId?.left;

  LandingPage =
    hasMasonryId && !isMasonryDisabled
      ? MasonryGetCareLandingPage
      : GetCareLandingPage;

  return (
    <Switch>
      <Route
        exact
        path={`${path}/submenu/benefits-and-coverage/:quickActionId`}
        component={QuickActionPage}
      />
      <Route component={LandingPage} />
    </Switch>
  );
};
