import { useGetMessagingConfig } from './use-get-messaging-config.hook';

type ThreadConfig = {
  attributes: {
    description: string;
    name: string;
    value: any;
  };
};

export const useGetThreadNameConfig = () => {
  const { data } = useGetMessagingConfig();

  const getAttributeByName = (attrName: string) => {
    const attrData = data?.data?.find(
      (config: ThreadConfig) => config?.attributes?.name === attrName,
    );

    return attrData?.attributes?.value;
  };

  const maxThreadTitleLength = getAttributeByName('THREAD_TITLE_MAX_SIZE')
    ? Number(getAttributeByName('THREAD_TITLE_MAX_SIZE'))
    : undefined;

  return {
    maxThreadTitleLength,
  };
};
