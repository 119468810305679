import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { Flex } from '@leagueplatform/genesis-commons';
import { CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH } from '@leagueplatform/health-journey-common';
import {
  BackButtonArrow,
  FullPageError,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { ChallengeActivitySection } from 'pages/challenge-landing-page/components/challenge-activities/components/challenge-activity-section';
import {
  HeadingText,
  SkeletonText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  ChallengeCompletedActivitiesPendingStateCard,
  ChallengeMissedActivitiesPendingStateCard,
} from 'pages/challenge-landing-page/components/challenge-activities/components/challenge-activities-empty-state-card';
import { useActivitiesByActivityStatusAndCampaign } from 'hooks/use-activities-by-activity-status-and-campaign.hook';
import { getActivityStatusesArray } from 'utils/get-activity-statuses-array.util';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { sortActivitiesInReverseChronologicalOrder } from 'pages/challenge-landing-page/components/challenge-activities/utils/activity-date';
import { ChallengeActivityList } from '../challenge-landing-page/components/challenge-activities/components/challenge-activity-list';

type ChallengeActivitiesHistoryPageLayoutProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

/**
 * A generic page template that could possibly extracted into a separate
 * component and reused in the future.
 */
function ChallengeActivitiesHistoryPageLayout({
  children,
  title,
}: ChallengeActivitiesHistoryPageLayoutProps) {
  const history = useHistory();

  return (
    <Flex
      width="100%"
      maxWidth={CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH}
      margin="auto"
      paddingX="one"
      paddingTop={{ _: 'two', laptop: 'four' }}
      paddingBottom="one"
    >
      <BackButtonArrow marginRight="one" onClick={() => history.goBack()} />
      <StackLayout spacing="$oneAndHalf" css={{ width: '100%' }}>
        <HeadingText level="1" size="xl" css={{ width: '100%' }}>
          {title}
        </HeadingText>
        <ChallengeActivitySection>{children}</ChallengeActivitySection>
      </StackLayout>
    </Flex>
  );
}

/**
 * The activity challenge details page supports full page history
 * views for missed and completed activities.
 */
type ChallengeActivityHistoryPageView = 'missed' | 'completed';

// Create a mapping between page views and empty states.
const pageViewEmptyStateMap: Record<
  ChallengeActivityHistoryPageView,
  React.ReactElement
> = {
  missed: <ChallengeMissedActivitiesPendingStateCard />,
  completed: <ChallengeCompletedActivitiesPendingStateCard />,
};

type ChallengeActivitiesHistoryPageProps = {
  activityStatus: ChallengeActivityHistoryPageView;
};

export function ChallengeActivitiesHistoryPage({
  activityStatus,
}: ChallengeActivitiesHistoryPageProps) {
  const { $tmap } = useIntl();
  const activityStatuses = getActivityStatusesArray(activityStatus);

  const useGetChallengeByIdDataQuery = useGetChallengeByIdData();
  const challenge = useGetChallengeByIdDataQuery?.data?.data;

  const useActivitiesByActivityStatusQuery =
    useActivitiesByActivityStatusAndCampaign({
      activityStatuses,
      campaignId: challenge?.userChallenge?.data?.id,
    });

  const activities =
    useActivitiesByActivityStatusQuery.data?.userHealthActivities;
  const hasActivities = !!activities?.length;

  const isError =
    useActivitiesByActivityStatusQuery.isError ||
    useGetChallengeByIdDataQuery.isError ||
    // If there is no challenge data and loading is done something went wrong.
    (!challenge && !useGetChallengeByIdDataQuery.isLoading);

  const isLoading =
    useActivitiesByActivityStatusQuery.isLoading ||
    useGetChallengeByIdDataQuery.isLoading;

  if (isError) {
    return <FullPageError />;
  }

  let children: React.ReactNode = null;

  if (isLoading) {
    children = (
      <>
        <SkeletonText width="20%" />
        <SkeletonBox
          backgroundColor="interactive.background.disabled"
          height={72}
        />
      </>
    );
  } else if (hasActivities) {
    children = (
      <ChallengeActivityList
        activities={sortActivitiesInReverseChronologicalOrder(activities)}
        level="3"
      />
    );
  } else {
    children = pageViewEmptyStateMap[activityStatus];
  }

  const headingTextMessage = $tmap({
    missed: 'MISSED_ACTIVITIES',
    completed: 'COMPLETED_ACTIVITIES',
  })(activityStatus);

  return (
    <ChallengeActivitiesHistoryPageLayout
      title={isLoading ? <SkeletonText header width="50%" /> : challenge?.title}
    >
      <HeadingText level="2" size="lg">
        {headingTextMessage}
      </HeadingText>
      {children}
    </ChallengeActivitiesHistoryPageLayout>
  );
}
