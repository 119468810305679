import React, { ReactNode } from 'react';
import {
  Box,
  HeadingText,
  ParagraphText,
  Responsive,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { splitAtNewlines } from '@leagueplatform/web-common';
import { LightQuestionIcon, TooltipModal } from '@leagueplatform/ui-kit';

interface MilestoneSectionProps {
  title: string;
  description: string;
  children: ReactNode;
}

export const MilestoneSection = ({
  title,
  description,
  children,
}: MilestoneSectionProps) => {
  const isAboveTablet = useMediaQuery(queryHelpers.up('tablet'));

  const formattedDescription = splitAtNewlines(description).map(
    (partDescription, index) => (
      <Responsive up="tablet">
        <ParagraphText
          // eslint-disable-next-line react/no-array-index-key
          key={`${description};part:${index}`}
          css={{
            typography: '$bodyOneSecondary',
            marginTop: '$threeQuarters',
            color: '$onSurfaceTextSubdued',
          }}
        >
          {partDescription}
        </ParagraphText>
      </Responsive>
    ),
  );

  return (
    <Box css={{ marginTop: '$four', marginBottom: '$half' }}>
      <StackLayout
        orientation={isAboveTablet ? 'vertical' : 'horizontal'}
        css={{
          marginBottom: '$two',
        }}
      >
        <HeadingText size="xl" level="2" css={{ display: 'inline-block' }}>
          {title}
        </HeadingText>

        {description && (
          <>
            {formattedDescription}
            <Responsive down="tablet">
              <TooltipModal
                marginTop="10px"
                marginLeft="quarter"
                label={<LightQuestionIcon />}
                content={description}
              />
            </Responsive>
          </>
        )}
      </StackLayout>
      <StackLayout orientation="horizontal" css={{ flexWrap: 'wrap' }}>
        {children}
      </StackLayout>
    </Box>
  );
};
