import { ModalContainer } from './modal-container.view';
import { ModalBody } from './modal-body.view';
import { ModalHeader } from './modal-header.view';
import { ModalHeaderTitle } from './modal-header-title.view';
import { ModalFooter } from './modal-footer.view';
import { ModalWrapper } from './modal-wrapper.view';

export const Modal = {
  Container: ModalContainer,
  Header: ModalHeader,
  HeaderTitle: ModalHeaderTitle,
  Body: ModalBody,
  Footer: ModalFooter,
  Wrapper: ModalWrapper,
};
