import React from 'react';
import { useConfigProperty } from '@leagueplatform/core';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  genesisStyled,
  Link,
  Overline,
  SubtleLink,
  ThemeProps,
} from '@leagueplatform/genesis-commons';
import { useHelpCenterLinks } from '@leagueplatform/health-journey-common';
import {
  GoogleAppIcon,
  AppleAppIcon,
} from '@leagueplatform/web-common-components';
import { useIsDashboardEnabled } from 'hooks/use-is-dashboard-enabled';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { ConnectDevicesModal } from './connect-devices-modal.component';

const STORE_IMG_HEIGHT = '50px';

const AppIconContainer = genesisStyled(Flex)`
  gap: ${({ theme }: ThemeProps) => `${theme?.space.half}px`};
`;

export interface UnavailableDeviceConnectModalProps {
  onClose: () => void;
}

export const UnavailableDeviceConnectModal = ({
  onClose,
}: UnavailableDeviceConnectModalProps) => {
  const { formatMessage } = useIntl();
  const { whatDevicesCanConnectLink, gettingStartedWithLeagueLink } =
    useHelpCenterLinks();
  const { isDashboardEnabled } = useIsDashboardEnabled();

  const mobileAppUrls = useConfigProperty('core.mobileAppUrls');

  // Static Assets
  const connectionUnavailable = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.DEVICE_CONNECTION_ERROR_ICON,
  ) as string;

  // Render legacy version of this modal if the feature flag is off.
  if (!isDashboardEnabled) {
    return (
      <ConnectDevicesModal
        img={connectionUnavailable}
        altTxt={formatMessage({ id: 'CONNECTING_UNAVAILABLE' })}
        heading={formatMessage({ id: 'CONNECTING_UNAVAILABLE' })}
        body={formatMessage({ id: 'CONNECTING_UNAVAILABLE_DETAILS' })}
        onClose={onClose}
      >
        <Link
          href={gettingStartedWithLeagueLink}
          marginBottom="half"
          target="_blank"
        >
          {formatMessage({ id: 'DONT_HAVE_THE_APP' })}
        </Link>
        <Link href={whatDevicesCanConnectLink} target="_blank">
          {formatMessage({ id: 'WHAT_DEVICES_CAN_CONNECT' })}
        </Link>
      </ConnectDevicesModal>
    );
  }

  return (
    <ConnectDevicesModal
      img={connectionUnavailable}
      altTxt={formatMessage({ id: 'CONNECTING_UNAVAILABLE' })}
      heading={formatMessage({ id: 'CONNECTING_UNAVAILABLE' })}
      body={
        <>
          {formatMessage({ id: 'CONNECTING_UNAVAILABLE_DETAILS_WITH_LINK' })}{' '}
          <SubtleLink
            href={whatDevicesCanConnectLink}
            target="_blank"
            color="interactive.action.primary"
          >
            {formatMessage({ id: 'APPS_AND_DEVICES_CURRENTLY_SUPPORTED' })}
          </SubtleLink>
        </>
      }
      onClose={onClose}
    >
      <Overline as="p">{formatMessage({ id: 'DONT_HAVE_THE_APP' })}</Overline>
      <AppIconContainer flexWrap="wrap" justifyContent="center" marginTop="one">
        <AppleAppIcon
          imageSize={STORE_IMG_HEIGHT}
          href={mobileAppUrls?.appStoreUrl}
        />
        <GoogleAppIcon
          imageSize={STORE_IMG_HEIGHT}
          href={mobileAppUrls?.playStoreUrl}
        />
      </AppIconContainer>
    </ConnectDevicesModal>
  );
};
