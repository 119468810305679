import React from 'react';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle, useGoBack } from '@leagueplatform/web-common';
import {
  BackButtonArrow,
  LoadingIndicator,
  PageNotFound,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { WalletCard } from 'components/wallet-card/wallet-card.component';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { MemberSelectorProvider } from 'components/member-selector-context/member-selector-context';
import { useMedicalCard } from 'hooks/use-medical-card.hook';
import { WalletPageHeaderBackground } from 'components/page-header-background/pageheaderbackground.component';
import { SidebarLayout } from 'components/sidebar-layout/sidebar-layout.component';
import { DetailHeaderBanner } from 'components/detail-header-banner/detail-header-banner.component';
import { CardDetailSummary } from './components/card-detail-summary/card-detail-summary.component';
import { CardStatus } from './components/card-status/card-status.view';
import { CardDetailQuickActionLinks } from './components/card-detail-quick-action-links/card-detail-quick-action-links.component';
import { FaqLinks } from './components/faq-links/faq-links.component';
import { NavigationTabs } from './components/navigation-tabs/navigation-tabs.component';
import { Feedback } from './components/feedback/feedback.component';
import { PolicyDateRange } from './components/policy-date-range/policy-date-range.component';
import { useWalletDetail } from './hooks/use-wallet-detail.hook';
import { useBenefitTranslationMap } from './hooks/use-benefit-translation-map.util';
import { PrimaryActionLink } from './components/primary-action-link/primary-action-link.component';

interface WalletDetailProps {
  currentDate?: any;
}

const MAX_WIDTH = 1132;

export const WalletDetail = ({
  currentDate = new Date(),
}: WalletDetailProps) => {
  const {
    isLoading,
    benefitCard,
    cardStartDate,
    cardEndDate,
    cardInactiveDate,
    formattedStartDate,
    formattedEndDate,
    showBenefitDetailFeedbackComponent,
    useBalanceEndDate,
    submitClaimsEndDate,
  } = useWalletDetail();

  const analyticsInfo = {
    title: SCREEN_NAMES.BENEFIT_PAGE,
    product_area: PRODUCT_AREA.WALLET,
    benefit_name: benefitCard?.title || benefitCard?.full_name,
    benefit_id: benefitCard?.id,
    vendor_id: benefitCard?.vendor_id,
    benefit_product_type: benefitCard?.product_type,
    benefit_source: benefitCard?.benefit_source,
  };
  const goBack = useGoBack(getModulePath(LEAGUE_MODULE_NAMES.wallet));
  const { isMedicalCardEnabled } = useMedicalCard();

  usePageViewAnalytics(analyticsInfo, [benefitCard]);

  const { formatMessage } = useIntl();

  let title = React.useMemo(
    () => benefitCard?.title || benefitCard?.full_name,
    [benefitCard?.full_name, benefitCard?.title],
  );

  const benefitTranslationIdMap = useBenefitTranslationMap();

  if (benefitCard && !title) {
    title = benefitTranslationIdMap(benefitCard.product_type);
  }

  const WALLET_TITLE = formatMessage({ id: 'WALLET' });
  const pageTitle = React.useMemo(
    () => (title ? `${WALLET_TITLE} - ${title}` : WALLET_TITLE),
    [title, WALLET_TITLE],
  );
  useDocumentTitle(pageTitle);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!benefitCard) {
    return (
      <PageNotFound
        buttonText={formatMessage(
          { id: 'RETURN_TO' },
          { location: WALLET_TITLE },
        )}
        redirectLink={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
      />
    );
  }

  return (
    <MemberSelectorProvider>
      <WalletPageHeaderBackground>
        <Box marginX="auto" width="100%" maxWidth={MAX_WIDTH} paddingX="two">
          <Flex
            position="relative"
            flexDirection={{ _: 'column', tablet: 'row', laptop: 'row' }}
          >
            <BackButtonArrow
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault();
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  ...analyticsInfo,
                  detail: 'go back',
                });
                goBack();
              }}
              as="a"
              href="#"
              position="absolute"
              left={{
                _: 'none',
                tablet: 'minusOneAndHalf',
                desktop: 'minusThree',
              }}
              top={0}
              id="go-back"
            />
            <CardDetailSummary
              title={title!}
              subtitle={benefitCard?.subtitle}
              planName={benefitCard.plan_name}
              productType={benefitCard.product_type}
              icon={benefitCard.benefit_logo?.src || benefitCard.icon_url}
              startDate={formattedStartDate}
              endDate={formattedEndDate}
            />
          </Flex>
        </Box>
      </WalletPageHeaderBackground>
      <Box
        paddingX="two"
        paddingBottom="two"
        maxWidth={MAX_WIDTH}
        marginX="auto"
        width="100%"
      >
        <SidebarLayout
          sidebarWidth="280px"
          isSideBarOnRight
          marginTop="twoAndHalf"
        >
          {/* Left Column */}
          <Box>
            <CardStatus
              status={benefitCard.status}
              currentDate={currentDate}
              startDate={cardStartDate}
              endDate={cardEndDate}
              inactiveDate={cardInactiveDate}
            />
            <StackLayout space="$oneAndHalf">
              {/* Optional Header Banner */}
              <DetailHeaderBanner banners={benefitCard?.banners} />
              <NavigationTabs />
              <PolicyDateRange
                endSubmitDate={submitClaimsEndDate}
                endBalanceDate={useBalanceEndDate}
              />
            </StackLayout>
          </Box>
          {/* Right Column */}
          <StackLayout>
            {!isMedicalCardEnabled && <WalletCard />}
            <PrimaryActionLink />
            <CardDetailQuickActionLinks />
            <FaqLinks benefitProductType={benefitCard.product_type} />
          </StackLayout>
        </SidebarLayout>
        {showBenefitDetailFeedbackComponent && (
          <Box marginTop="two">
            <Feedback />
          </Box>
        )}
      </Box>
    </MemberSelectorProvider>
  );
};
