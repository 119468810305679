import React, { ReactNode } from 'react';
import { Box, Overline } from '@leagueplatform/genesis-commons';

interface ProgressBoxProps {
  title: string;
  children: ReactNode;
}

export const ProgressBox = ({ title, children }: ProgressBoxProps) => (
  <Box
    backgroundColor="surface.card.primary"
    borderRadius="medium"
    padding="half"
    width="48%"
  >
    <Overline as="p" color="onSurface.text.subdued" marginBottom="quarter">
      {title}
    </Overline>
    <Box as="span" display="inline-flex" alignItems="center">
      {children}
    </Box>
  </Box>
);
