import { MasonryConfig } from 'types/config.types';
import { merge } from 'lodash';
import {
  MAXIMUM_API_VERSION,
  MINIMUM_API_VERSION,
} from '../constants/supported-api-version';
import { MasonryConfigSchema } from './masonry-config-schema';
import { validateConfigWithSchema } from './validate-config-with-schema';
import {
  MasonryConfigContextType,
  defaultConfig,
} from '../context/masonry-config-context';

const formatConfigWithDefaultValues = (config: MasonryConfig) =>
  merge({}, defaultConfig, config);

export function validateAndFormatConfig(
  config: MasonryConfig,
): MasonryConfigContextType {
  // Throw error if no config is provided
  if (!config)
    throw new Error(
      'Please provide a configuration object to initialize Masonry',
    );

  const errors = validateConfigWithSchema(config, MasonryConfigSchema);

  // Throw errors with formatting if the errors array has items
  if (errors?.length) throw new Error(`\n${errors.join('\n')}`);

  // Throw errors if apiVersion passed is not a valid apiVersion
  if (
    config.apiVersion < MINIMUM_API_VERSION ||
    config.apiVersion > MAXIMUM_API_VERSION
  ) {
    throw new Error(
      `\nThe apiVersion provided is not supported by masonry module. Please provide a version between ${MINIMUM_API_VERSION} & ${MAXIMUM_API_VERSION} `,
    );
  }

  const formattedConfig = formatConfigWithDefaultValues(config);

  return formattedConfig;
}
