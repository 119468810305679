import {
  Box,
  HeadingText,
  Icon,
  ParagraphText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useFormContext } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import * as React from 'react';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { MemberCard } from './member-selection-card.component';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';

interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postal: string;
  isDefault: string;
}

interface AddressCardProps {
  deliveryAddress: Address;
}

const AddressCard = ({ deliveryAddress }: AddressCardProps) => {
  const { formatMessage } = useIntl();
  const { address1, address2, city, state, postal, isDefault } =
    deliveryAddress;

  const isDefaultAddressBoolean = isDefault === 'true';

  return (
    <StackItem css={{ flexDirection: 'row', marginTop: '$oneAndHalf' }}>
      <Box
        css={{
          backgroundColor: '$surfaceBackgroundSecondary',
          padding: '$threeQuarters',
          borderRadius: '$circle',
          height: 'fit-content',
          margin: 'auto',
          marginRight: '$one',
        }}
      >
        <Icon icon="illustrativeMail" tint="$onSurfaceIconPrimary" />
      </Box>
      <Box>
        <HeadingText level="4" size="md">
          {isDefaultAddressBoolean
            ? formatMessage({ id: 'DEFAULT_ADDRESS' })
            : formatMessage({ id: 'NEW_ADDRESS' })}
        </HeadingText>
        <Box>
          {/* genesis adds marginBlockEnd to all non-last-child p tags, need to override specificity */}
          <ParagraphText
            as="span"
            css={{ marginBlockEnd: '0 !important', display: 'block' }}
          >{`${address1} ${address2 || ''}`}</ParagraphText>
          <ParagraphText
            as="span"
            css={{ display: 'block' }}
          >{`${city}, ${state}, ${postal}`}</ParagraphText>
        </Box>
      </Box>
    </StackItem>
  );
};
const {
  addressLine1: addressLine1Input,
  addressLine2: addressLine2Input,
  city: cityInput,
  state: stateInput,
  postal: postalInput,
  isDefault: isDefaultAddress,
} = orderNewCardFormInputNames;

export const ConfirmationScreen = () => {
  const { formatMessage } = useIntl();
  const { getValues } = useFormContext();

  useWalletDetailScreenLoaded(
    BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_CONFIRMATION_STEP,
  );

  const [address1, address2, city, state, postal, isDefault] = getValues([
    addressLine1Input,
    addressLine2Input,
    cityInput,
    stateInput,
    postalInput,
    isDefaultAddress,
  ]);

  const deliveryAddress = {
    address1,
    address2,
    city,
    state,
    postal,
    isDefault,
  };

  return (
    <StackLayout>
      <HeadingText size="lg" level="3">
        {formatMessage({ id: 'CONFIRM_DETAILS' })}
      </HeadingText>
      <MemberCard headingLevel={4} />
      <AddressCard deliveryAddress={deliveryAddress} />
    </StackLayout>
  );
};
