import { useInfiniteQuery, useQuery } from 'react-query';
import {
  getHealthMetricsV3,
  getHealthMetricsByPaginationLinkV3,
  HealthMetricsQueryParameters,
  healthMetricsV3Path,
} from '@leagueplatform/dashboard-api';

export function useGetHealthMetricsV3(params: HealthMetricsQueryParameters) {
  return useQuery(
    [healthMetricsV3Path, params],
    () => getHealthMetricsV3(params),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );
}

export function useInfiniteHealthMetricsV3(
  params: Omit<HealthMetricsQueryParameters, 'from' | 'interval'>,
) {
  return useInfiniteQuery(
    [healthMetricsV3Path, params],
    ({ pageParam }) => {
      if (pageParam) {
        return getHealthMetricsByPaginationLinkV3(pageParam);
      }
      return getHealthMetricsV3({
        ...params,
        interval: 'all',
      });
    },
    {
      getNextPageParam(lastPage) {
        if (!lastPage?.links?.next) {
          return undefined;
        }

        // TODO: Remove this string manipulation after the merge of
        // https://everlong.atlassian.net/browse/WEBPLAT-2249
        // Which will allow for the passing of a full URL to leagueFetch
        const { next } = lastPage.links;
        return next.slice(next.indexOf('/v3/'));
      },
    },
  );
}
