import React from 'react';
import { BadgeList } from 'common/badge-list/badge-list.component';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  UtilityText,
  Box,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { BadgeItem } from 'common/badge-list/badge-item.type';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export interface MasonryLargeCardContentProps {
  heading?: string;
  body: string;
  eyebrow?: string;
  badges?: BadgeItem[];
  linkRefState?: React.RefObject<HTMLAnchorElement>;
  badgeBackgroundColor?: string;
  onClick?: MasonryDeeplinkAction;
}

export const MasonryLargeCardContentRenderer = ({
  eyebrow,
  linkRefState,
  heading,
  body,
  badges,
  badgeBackgroundColor,
  onClick,
}: MasonryLargeCardContentProps) => {
  const eyebrowId = `lvc-${heading?.replace(/\s+/g, '-')}`;
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout spacing="$half" orientation="vertical">
      {eyebrow && (
        <UtilityText id={eyebrowId} size="eyebrow" as="p">
          {eyebrow}
        </UtilityText>
      )}
      <StackLayout spacing="$quarter" orientation="vertical">
        {heading && (
          <HeadingText level="3" size="sm" aria-describedby={eyebrowId}>
            <ConditionalLinkRenderer
              ref={linkRefState}
              url={onClick?.payload.url}
              disableDefaultOnClick
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>
        )}
        {body && (
          <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
            {body}
          </ParagraphText>
        )}
      </StackLayout>
      {badges && (
        <Box css={{ marginTop: '$threeQuarters' }}>
          <BadgeList badges={badges} backgroundColor={badgeBackgroundColor} />
        </Box>
      )}
    </StackLayout>
  );
};
