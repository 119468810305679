import React from 'react';
import { HeadingTwo, Flex, SubtitleTwo } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  TeamChallengeRank,
  UserChallengeRank,
} from '@leagueplatform/health-journey-api';
import { PodiumMedalist } from './podium-medalist.component';

export interface LeaderboardPodiumProps {
  leaderboardData: (UserChallengeRank | TeamChallengeRank)[];
  headerText: string;
}

export const LeaderboardPodium = ({
  leaderboardData,
  headerText,
}: LeaderboardPodiumProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <HeadingTwo as="h2">{formatMessage({ id: 'TOP_RANKED' })}</HeadingTwo>
      {headerText && <SubtitleTwo as="span">{headerText}</SubtitleTwo>}
      <Flex
        as="ol"
        justifyContent="center"
        alignItems="flex-start"
        marginTop="oneAndHalf"
      >
        {/* Note: the order prop is used for the CSS order property, but does not impact the order in which medalists are rendered in the markup. This ensures that medalists are read by screen readers in the correct rank order */}
        {leaderboardData[0] && (
          <PodiumMedalist user={leaderboardData[0]} order={2} />
        )}
        {leaderboardData[1] && (
          <PodiumMedalist user={leaderboardData[1]} order={1} />
        )}
        {leaderboardData[2] && (
          <PodiumMedalist user={leaderboardData[2]} order={3} />
        )}
      </Flex>
    </>
  );
};
