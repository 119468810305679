import React from 'react';
import { Box, UtilityText, keyframes } from '@leagueplatform/genesis-core';

interface ProgressBarProps {
  isLoading: boolean;
  currentActivity: number;
  totalActivities: number;
  progressTitle: string;
}

export const ProgressBar = ({
  isLoading,
  currentActivity,
  totalActivities,
  progressTitle,
}: ProgressBarProps) => {
  const activityPercent = (currentActivity / totalActivities) * 100;

  const stretch = keyframes({
    '0%': { width: 0 },
    '100%': { width: `${activityPercent}%` },
  });

  return (
    <Box>
      <UtilityText
        css={{
          typography: '$bodyTwo',
          color: '$onSurfaceTextSubdued',
          marginTop: '$half',
          marginBottom: '$quarter',
          display: 'block',
        }}
      >
        {progressTitle}
      </UtilityText>
      <Box
        css={{
          width: '100%',
          height: '$threeQuarters',
          borderRadius: '$medium',
          marginBottom: '$half',
          backgroundColor: '$successBackgroundSubdued',
          overflow: 'hidden',
        }}
      >
        {!isLoading && (
          <Box
            css={{
              height: '$threeQuarters',
              backgroundColor: '$decorativeBrandSecondaryDark',
              borderRadius: '$medium',
              transition: 'width 1.5s ease',
              animation: `${stretch} 1.5s ease-in-out forwards`,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
