{
  "dragDescriptionKeyboard": "按 Enter 开始拖动。",
  "dragDescriptionKeyboardAlt": "按 Alt + Enter 开始拖动。",
  "dragDescriptionLongPress": "长按以开始拖动。",
  "dragDescriptionTouch": "双击开始拖动。",
  "dragDescriptionVirtual": "单击开始拖动。",
  "dragItem": "拖动 {itemText}",
  "dragSelectedItems": "拖动 {count, plural, one {# 选中项目} other {# 选中项目}}",
  "dragSelectedKeyboard": "按 Enter 以拖动 {count, plural, one {# 个选定项} other {# 个选定项}}。",
  "dragSelectedKeyboardAlt": "按 Alt + Enter 以拖动 {count, plural, one {# 个选定项} other {# 个选定项}}。",
  "dragSelectedLongPress": "长按以拖动 {count, plural, one {# 个选定项} other {# 个选定项}}。",
  "dragStartedKeyboard": "已开始拖动。按 Tab 导航到放置目标，然后按 Enter 放置或按 Escape 取消。",
  "dragStartedTouch": "已开始拖动。导航到放置目标，然后双击放置。",
  "dragStartedVirtual": "已开始拖动。导航到放置目标，然后单击或按 Enter 放置。",
  "dropCanceled": "放置已取消。",
  "dropComplete": "放置已完成。",
  "dropDescriptionKeyboard": "按 Enter 放置。按 Escape 取消拖动。",
  "dropDescriptionTouch": "双击放置。",
  "dropDescriptionVirtual": "单击放置。",
  "dropIndicator": "放置标记",
  "dropOnItem": "放置于 {itemText}",
  "dropOnRoot": "放置于",
  "endDragKeyboard": "正在拖动。按 Enter 取消拖动。",
  "endDragTouch": "正在拖动。双击取消拖动。",
  "endDragVirtual": "正在拖动。单击取消拖动。",
  "insertAfter": "插入到 {itemText} 之后",
  "insertBefore": "插入到 {itemText} 之前",
  "insertBetween": "插入到 {beforeItemText} 和 {afterItemText} 之间"
}
