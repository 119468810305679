import {
  trackAnalyticsEvent,
  EVENT_NAME,
  SCREEN_NAMES,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';

export const sendNavigationAnalyticEvent = (
  benefitName?: string,
  benefitId?: string,
  vendorId?: string,
  benefitProductType?: string,
  benefitCategory?: string,
  benefitSource?: string,
) => {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.WALLET,
    screen_name: SCREEN_NAMES.HOME_PAGE,
    benefit_name: benefitName,
    benefit_id: benefitId,
    vendor_id: vendorId,
    benefit_product_type: benefitProductType,
    benefit_category: benefitCategory,
    benefit_source: benefitSource,
    detail: 'select benefit',
  });
};
