import React from 'react';
import {
  Radio,
  Label,
  ChoiceText,
  Flex,
} from '@leagueplatform/genesis-commons';
import { fieldProps } from './field-shape';

export const RadioField = ({
  field: { name, value, ...field },
  form: { setFieldValue },
  inputId,
  required,
  disabled,
  readOnly,
  options,
  hasError,
}) => {
  const isDisabled = Boolean(disabled ?? readOnly);
  const isBooleanField = options.some(
    (option) => typeof option.value === 'boolean',
  );
  return (
    <Flex
      alignItems="center"
      role="radiogroup"
      aria-labelledby={`${inputId}_label`}
      id={inputId}
    >
      {options?.map((option, idx) => (
        <Label
          key={`${name}_${option.value}`}
          marginLeft={idx > 0 ? 'one' : 'none'}
          display="flex"
          paddingTop="one"
        >
          <Radio
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            name={inputId}
            value={option.value}
            required={required}
            disabled={isDisabled}
            checked={option.value === value}
            error={hasError}
            onChange={({ target }) => {
              const newValue = isBooleanField
                ? target.value === 'true'
                : target.value;
              setFieldValue(name, newValue);
            }}
          />
          <ChoiceText marginLeft="threeQuarters">{option.label}</ChoiceText>
        </Label>
      ))}
    </Flex>
  );
};

RadioField.propTypes = fieldProps;
