import React, { forwardRef } from 'react';
import { Box, BoxProps } from '../../Box';

export interface LinkProps
  extends Partial<Omit<HTMLAnchorElement, 'children'>>,
    BoxProps {}

/**
 * @deprecated  use `TextAction` from `genesis-core` instead
 */

export const SubtleLink: React.FunctionComponent<LinkProps> = forwardRef(
  ({ tabIndex = 0, children, ...props }, ref) => {
    return (
      <Box
        as="a"
        tabIndex={tabIndex}
        color="inherit"
        borderRadius="small"
        ref={ref}
        hoverStyle={{
          color: 'interactive.action.hovered',
        }}
        focusStyle={{
          outline: 0,
          boxShadow: 'focusRing',
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

SubtleLink.displayName = 'SubtleLink';
