export function flexBox() {
  return `
    display: flex;
  `;
}

export function verticalBox() {
  return `
    ${flexBox()}
    flex-direction: column;
  `;
}

export function horizontalBox() {
  return `
    ${flexBox()}
    flex-direction: row;
  `;
}

export function flexShrink(value) {
  return `
    flex-shrink: ${value};
  `;
}

export function flexGrow(value) {
  return `
    flex-grow: ${value};
  `;
}

export function flex(value) {
  return `
    flex: ${value};
  `;
}

export function flexAlign(align) {
  return `
    align-items: ${
      align === 'start' || align === 'end' ? `flex-${align}` : align
    };
  `;
}

export function flexPack(pack) {
  return `
    justify-content: ${
      pack === 'start' || pack === 'end' ? `flex-${pack}` : pack
    };
  `;
}

export function flexWrap() {
  return `
    flex-wrap: wrap;
  `;
}

export function flexOrder(value) {
  return `
    order: ${value};
  `;
}
