import React, { FC } from 'react';

// Common Dependencies
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOneSecondary,
  genesisStyled,
  Box,
  css,
} from '@leagueplatform/genesis-commons';

// Hooks

// Common Components
import { VisuallyHidden } from '@leagueplatform/genesis-core';
import { LeftButton, RightButton } from './multi-step-footer-buttons.component';
import { MultiStepPageProgress } from './multi-step-page-progress.component';

// Types
import { MultiStepFooterAreas } from '../../constants/multi-step-footer-constants';
import { OrderNewCardFormValues } from '../../types/order-new-card-form.types';

const { Top, Left, Center, Right, Bottom } = MultiStepFooterAreas;
// Local Types
interface OrderNewCardFooterProps {
  totalPages: number;
  currentPage: number;
  disabled?: boolean;
  onNext(): void;
  onPrev(): void;
  onSubmit: (data: OrderNewCardFormValues) => void;
  isLoading?: boolean;
}

// Styles
const footerPlacement = {
  [Top]: css({ gridArea: Top, justifySelf: 'center' }),
  [Left]: css({ gridArea: Left, justifySelf: 'start' }),
  [Center]: css({ gridArea: Center, justifySelf: 'center' }),
  [Right]: css({ gridArea: Right, justifySelf: 'end' }),
  [Bottom]: css({ gridArea: Bottom, justifySelf: 'center' }),
};

// Local Components
const FooterLayout = genesisStyled(Box)`
  align-items: center;
  display: grid;
  grid-template-areas:
  "top top top"
  "left center right"
  "bottom bottom bottom";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
`;

export const OrderNewCardFooter: FC<OrderNewCardFooterProps> = ({
  totalPages,
  currentPage,
  disabled,
  onNext,
  onPrev,
  isLoading,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const isMultiPage = totalPages > 1;

  const copy = {
    pageProgress: formatMessage(
      { id: 'X_OF_Y' },
      { current: currentPage + 1, total: totalPages },
    ),
  };
  return (
    <FooterLayout marginTop="one">
      {/* Activity Page Progress Bar */}
      {isMultiPage && (
        <MultiStepPageProgress
          css={footerPlacement[Top]}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}

      {/* Left Action */}
      {isMultiPage && (
        <LeftButton
          currentPage={currentPage}
          disabled={disabled}
          onClick={onPrev}
          css={footerPlacement[Left]}
        />
      )}

      {/* Page Location Copy */}
      {isMultiPage && (
        <BodyOneSecondary
          textAlign="center"
          css={footerPlacement[Center]}
          aria-live="polite"
        >
          <VisuallyHidden>{formatMessage({ id: 'STEP' })}</VisuallyHidden>
          {copy.pageProgress}
        </BodyOneSecondary>
      )}

      {/* Right Action */}
      {isLoading ? (
        <RightButton
          css={footerPlacement[Right]}
          currentPage={currentPage}
          totalPages={totalPages}
          disabled={disabled}
          onClick={onNext}
          isLoading={isLoading}
        />
      ) : (
        <RightButton
          css={footerPlacement[Right]}
          currentPage={currentPage}
          totalPages={totalPages}
          disabled={disabled}
          onClick={onNext}
          onSubmit={onSubmit}
        />
      )}
    </FooterLayout>
  );
};
