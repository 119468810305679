import { GDSStyleObject } from '@leagueplatform/genesis-core';

export const GDSVideoTheme = {
  controlBackground: 'rgba(8, 8, 8, 0.8)',
  controlHover: 'rgba(8, 8, 8, 1)',
  remainingProgress: 'rgba(101, 105, 125, 1)',
  bufferProgress: 'rgba(117, 131, 149, 1)',
  progress: 'rgba(255, 255, 255, 1)',
  menuItemHover: 'rgba(255, 255, 255, 0.33)',
};

const EASE = '250ms ease-in-out';
const TRANSITION = `all ${EASE}`;
const VOLUME_PANEL_SELECTOR = `.vjs-volume-panel .vjs-volume-control.vjs-volume-vertical, .vjs-volume-panel.vjs-hover .vjs-mute-control~.vjs-volume-control.vjs-volume-vertical, .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-vertical, .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-vertical:active`;

export const GDSVideoJSStyleOverrides: GDSStyleObject = {
  $$videoIconSize: 'clamp($sizes$one, 3.75vw ,$sizes$oneAndHalf)', // (24px / 640px) * 100vw = 3.75vw. icon max height = 24px at 640px
  $$videoControlsGap: 'clamp($half, 2.5vw, $one)', // (16px / 640px) * 100 = 2.5
  '.video-js': {
    fontSize: '$bodyOne',

    // Idle play button
    '.vjs-big-play-button': {
      fontSize: 'inherit',
      borderRadius: '$circle',
      border: 'none',
      aspectRatio: 1,
      width: 'auto',
      padding: '$two',
      backgroundColor: GDSVideoTheme.controlBackground,
      transition: TRANSITION,
      transform: 'translate(-50%, -50%)',
      margin: 0,

      '.vjs-svg-icon': {
        padding: '$threeQuarters',
        width: 'auto',
        height: '100%',
      },
    },

    '&:hover .vjs-big-play-button, .vjs-big-play-button:focus': {
      backgroundColor: GDSVideoTheme.controlHover,
      transition: TRANSITION,
    },

    '.vjs-volume-panel': {
      position: 'initial',
    },
    [VOLUME_PANEL_SELECTOR]: {
      '&, &:active': {
        position: 'absolute',
        bottom: '100%',
        left: 'auto',
        transition: TRANSITION,
        margin: 0,
        justifyContent: 'center',
      },
      '.vjs-volume-level .vjs-svg-icon': {
        left: '50%',
      },
    },

    // Containers for menu-trigger buttons
    '.vjs-menu-button': {
      display: 'flex',
      justifyContent: 'center',
    },

    // Control bar pop-up menus
    '.vjs-volume-vertical, .vjs-menu .vjs-menu-content': {
      borderRadius: '$small $small 0 0',
      backgroundColor: GDSVideoTheme.controlBackground,
    },

    // Pop-up menus
    '.vjs-menu': {
      left: 'auto',
      transition: TRANSITION,
      display: 'block',
      height: '100%',
      '&:not(:hover)': {
        opacity: 0,
        visibility: 'hidden',
      },
      // Control bar pop-up menu item, unselected + hovered
      '.vjs-menu-item:not([aria-checked="true"]):hover': {
        backgroundColor: GDSVideoTheme.menuItemHover,
      },
    },

    // Hover transitions
    '.vjs-hover': {
      '.vjs-menu': {
        position: 'absolute',
        left: 'inherit',
        opacity: 1,
        visibility: 'visible',
      },
    },

    // Control bar container
    '.vjs-control-bar': {
      border: 'none',
      borderRadius: '$large',
      fontSize: '$caption',
      lineHeight: 'auto',
      inset: '$space$one',
      bottom: '$space$one',
      top: 'auto',
      maxWidth: 'calc(100% - $space$one * 2)',
      margin: '0 auto',
      backgroundColor: GDSVideoTheme.controlBackground,
      justifyContent: 'space-between',
      gap: '1%',
      padding: '$one',
      height: 'auto',
      alignItems: 'center',

      // Control bar elements
      '.vjs-control': {
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        minWidth: 'auto',
      },

      '.vjs-playback-rate-value,.vjs-time-control': {
        lineHeight: 'initial',
      },

      '.vjs-menu-button': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        '.vjs-playback-rate-value,.vjs-time-control': {
          fontSize: '1rem',
          position: 'relative',
        },
      },
    },

    '.vjs-remaining-time': {
      paddingLeft: 0,
      lineHeight: 'auto',
    },

    '.vjs-progress-control': {
      gridColumn: 'progress-bar',
      '.vjs-svg-icon': {
        height: 'initial',
      },
    },

    // Progress slider: background/unloaded
    '.vjs-slider': {
      backgroundColor: GDSVideoTheme.remainingProgress,
    },

    // Progress slider: buffer progress
    '.vjs-load-progress [data-start]': {
      backgroundColor: GDSVideoTheme.bufferProgress,
    },

    // Icons
    '.vjs-svg-icon': {
      fontSize: '$bodyOne',
      transition: 'filter 150ms ease-out',
      height: '$$videoIconSize',
      // width: 'auto',
      aspectRatio: '1 / 1',
      // maxHeight: '$oneAndHalf',
      // minHeight: '$one',
      // width: '100%',
      // height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        height: '100%',
      },
    },

    // Audio-only mode
    '&.vjs-audio-only-mode': {
      '.vjs-control-bar': {
        bottom: 'auto',
      },
    },

    // Error container
    '.vjs-error-display': {
      typography: '$bodyOne',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '.vjs-modal-dialog-content': {
        position: 'relative',
        height: 'auto',
        padding: '0 10%',
        maxWidth: '80ch', // See: https://www.w3.org/WAI/tutorials/page-structure/styling/#line-length
      },
    },
  },
};

export const GDSVideoFrameStyles = {
  borderRadius: '$small',
  clipPath: 'border-box',
  ...GDSVideoJSStyleOverrides,
};
