import React from 'react';
import {
  Flex,
  Image,
  CoverImage,
  Box,
  SpaceProps,
} from '@leagueplatform/genesis-commons';
import { CONTENT_MAX_WIDTH } from 'constants/challenges.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

export interface ModalContentWithImageProps {
  image: {
    src: string;
    alt: string;
  };
  marginTop?: SpaceProps['marginTop'];
  children: JSX.Element | JSX.Element[];
  isSuccessScreen?: boolean;
}

const MODAL_CONTENT_MAXWIDTH = { _: '445px', tablet: CONTENT_MAX_WIDTH };
const MODAL_IMAGE_MAXWIDTH = { _: '150px', tablet: '240px' };
const CONFETTI_WIDTH = { _: '247px', phone: '308px' };
const CONFETTI_HEIGHT = { _: '227px', phone: '284px' };
const PROFILE_PICTURE_SIZE = { _: '160px', phone: '200px' };
const PROFILE_TOP_VALUE = { _: '35px', phone: '45px' };
const PROFILE_LEFT_VALUE = { _: '45px', phone: '60px' };

export const ModalContentWithImage = ({
  image,
  children,
  isSuccessScreen,
  marginTop,
}: ModalContentWithImageProps): JSX.Element => {
  const confetti = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_CONFETTI,
  ) as string;
  return (
    <Flex
      width="100%"
      maxWidth={MODAL_CONTENT_MAXWIDTH}
      margin="none"
      marginLeft="auto"
      marginRight="auto"
      marginTop={marginTop ?? 'none'}
      justifyContent="space-between"
      alignItems={isSuccessScreen ? 'center' : 'flex-start'}
      flexDirection={{ _: 'column', tablet: 'row' }}
    >
      {isSuccessScreen ? (
        <Box
          position="relative"
          marginBottom={{ _: 'one', tablet: 'unset' }}
          marginLeft={{ _: 'auto', tablet: 'unset' }}
          marginRight={{ _: 'auto', tablet: 'four' }}
        >
          <CoverImage
            src={image.src}
            alt=""
            borderRadius="circle"
            left={PROFILE_LEFT_VALUE}
            position="absolute"
            size={PROFILE_PICTURE_SIZE}
            top={PROFILE_TOP_VALUE}
            role="presentation"
          />
          <CoverImage
            src={confetti}
            alt=""
            height={CONFETTI_HEIGHT}
            position="relative"
            width={CONFETTI_WIDTH}
            role="presentation"
          />
        </Box>
      ) : (
        <Image
          src={image.src}
          alt={image.alt}
          maxWidth={MODAL_IMAGE_MAXWIDTH}
          marginRight={{ _: 'auto', tablet: 'four' }}
          marginLeft={{ _: 'auto', tablet: 'unset' }}
          marginBottom={{ _: 'one', tablet: 'unset' }}
        />
      )}

      <Flex flexDirection="column" width="100%">
        {children}
      </Flex>
    </Flex>
  );
};
