export const ACHIEVEMENTS_ENABLED = 'achievements_enabled';
export const ENABLE_CHALLENGES = 'enable_challenges';
export const ENABLE_CHALLENGES_FAQ = 'enable_challenges_faq';
export const ENABLE_ONBOARDING = 'enable_onboarding';
export const VERIFIABLE_AUTOMATIC_ONLY = 'verifiable_automatic_only';
export const ENABLE_NEW_JOURNEY_EXPERIENCE = 'enable_journey_experience';
export const JOURNEY_WELLNESS_AND_REWARDS = 'journey_wellness_and_rewards';
export const ENABLE_V3_ACTIVITY_CARDS = 'enable_v3_health_activity_cards';
export const ENABLE_JOURNEY_HISTORY = 'enable_journey_history';
export const ENABLE_CHALLENGES_WELLNESS_AND_REWARDS =
  'enable_challenges_wellness_and_rewards';
export const ENABLE_NEW_JOURNEY_PROGRESS = 'new_journey_progress_enabled';
export const REWARDS_PROGRAMS_ENABLED = 'rewards_programs_enabled';
export const ENABLE_CHALLENGE_ACTIVITIES_TAB =
  'enable_challenge_activities_tab';
export const ENABLE_JOURNEY_POINTS_COUNTER_BUTTON =
  'enable_journey_points_counter_button';
export const ENABLE_JOURNEY_VIEW_MY_PROGRESS_BUTTON =
  'enable_journey_view_my_progress_button';
export const ENABLE_JOURNEY_VIEW_MY_PROFILE_BUTTON =
  'enable_journey_view_my_profile_button';
