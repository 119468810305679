import React from 'react';
import PropTypes from 'prop-types';
import { Box, QuietButton, Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import BackIcon from 'assets/back.svg';
import { PageHeaderBackground } from '../page-header-background/page-header-background';

export const SwoopHeader = ({ onBackClick, children, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <PageHeaderBackground
      paddingX="twoAndHalf"
      paddingTop="twoAndHalf"
      marginX="auto"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {onBackClick !== null && (
        <Box marginRight="one">
          <QuietButton
            marginTop={2}
            width="two"
            height="two"
            paddingX={0}
            paddingY={0}
            borderRadius="circle"
            onClick={onBackClick}
          >
            <Image
              src={BackIcon}
              position="relative"
              top="quarter"
              left="quarter"
              size="oneAndHalf"
              alt={formatMessage({ id: 'GO_BACK' })}
            />
          </QuietButton>
        </Box>
      )}
      {children}
    </PageHeaderBackground>
  );
};

SwoopHeader.propTypes = {
  children: PropTypes.node.isRequired,
  onBackClick: PropTypes.func,
};

SwoopHeader.defaultProps = {
  onBackClick: null,
};
