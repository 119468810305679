import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import { CHALLENGES_PATH } from './constants/challenge-requests.constants';
import {
  ChallengeTypes,
  MultipleChallengesResponseData,
  MultipleChallengesDeserialisedData,
} from './types/challenge-response-data.type';

export const getAllChallenges = async (
  challengeType?: ChallengeTypes,
  errorContext?: ErrorContext,
): Promise<MultipleChallengesDeserialisedData> => {
  const allChallengesRequestPath = challengeType
    ? `${CHALLENGES_PATH}?filter[challengeType]=${challengeType}`
    : CHALLENGES_PATH;
  const response = await leagueFetch(allChallengesRequestPath, {
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (!response.ok) {
    throw new Error(`getAllChallenges error: ${response.status}`);
  }
  const responseJson: MultipleChallengesResponseData = await response.json();
  return deserialise(responseJson);
};
