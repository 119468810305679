import { useCallback } from 'react';
import { useMessagingConfig } from '../messaging.config';

/**
 * Returns a function which can be used to check if a given file can be used
 * as a thumbnail.
 *
 * We need this in the form of a hook because the list of allowed image file types
 * comes from the Config API, meaning that we support the config values changing dynamically
 * after the initial value is set. Wrapping this function in a hook means that
 * it can remain up-to-date with the current value of the messaging config.
 */
export const useIsImageThumbnail = () => {
  const { allowedImageTypes } = useMessagingConfig();

  return useCallback(
    (file: File) =>
      // tiff and heic files are not well supported on browsers
      // for the purpose of this function we will return false for those two
      allowedImageTypes.includes(file.type) &&
      !file.type.includes('tiff') &&
      !file.type.includes('heic'),
    [allowedImageTypes],
  );
};
