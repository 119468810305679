import {
  optional,
  string,
  boolean,
  number,
  integer,
  record,
  unknown,
  intersection,
  type,
  type Infer,
} from 'superstruct';

/**
 * Note: This schema object has to be maintained and updated
 * to stay strictly aligned with the backend server config schema
 * in order to maintain the appropriate config validation.
 */
export const knownServerConfigSchema = type({
  'config_observability_sentry-dsn': optional(string()),
  'config_observability_sentry-max-breadcrumbs': optional(integer()),
  'config_observability_sentry-errors-sample-rate': optional(number()),
  'config_observability_sentry-traces-sample-rate': optional(number()),
  'config_observability_custom-view-tracing': optional(boolean()),
  config_observability_enabled: optional(boolean()),
});

export type KnownServerConfig = Infer<typeof knownServerConfigSchema>;

export const unknownServerConfigSchema = record(string(), unknown());

export type UnknownServerCOnfig = Infer<typeof unknownServerConfigSchema>;

export const serverConfigSchema = intersection([
  knownServerConfigSchema,
  unknownServerConfigSchema,
]);

export type ServerConfig = Infer<typeof serverConfigSchema>;
