import { getEnvironment } from './util/environment-helpers';

/**
 * @deprecated use {@link getEnvironment `getEnvironment`} instead
 */
export const AppEnvironment = {
  get environment() {
    return getEnvironment();
  },
};
