import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const EXIT_ASSESSMENT_MESSAGE_TYPE = 'exit_assessment';

export const exitAssessment = (moduleId: string) =>
  SocketAsFetch.fetch({
    message_type: EXIT_ASSESSMENT_MESSAGE_TYPE,
    info: {
      module_id: moduleId,
    },
  });
