import React, { memo } from 'react';
import {
  styled,
  Button,
  ParagraphText,
  Box,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { formatFileSize } from 'utils/format-file-size.util';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { useIsImageThumbnail } from 'hooks/use-is-image-thumbnail.hook';

const StyledImage = styled('img', {
  borderColor: '$onSurfaceBorderSubdued',
  borderWidth: '$thin',
  borderStyle: '$solid',
  borderRadius: '$medium',
  width: '8rem',
  height: '8rem',
  objectFit: 'cover',
});

interface CloseButtonProps {
  onClick: () => void;
  top?: string;
  left?: string;
  right?: string;
  filename: string;
  fileSize: string;
}

const CloseButton = ({
  onClick,
  top,
  left,
  right,
  filename,
  fileSize,
}: CloseButtonProps) => {
  const { formatMessage } = useIntl();
  const closeIcon = handleStaticAsset(
    MESSAGING_ASSET_KEYS.MESSAGING_CLOSE_ICON,
  ) as string;
  return (
    <Button
      css={{
        position: 'absolute',
        top,
        right,
        left,
        backgroundColor: '$onSurfaceIconPrimary',
        borderColor: '$interactiveIconDefault',
        padding: '$quarter',
      }}
      icon={closeIcon}
      hideLabel
      onClick={onClick}
      size="small"
    >
      {formatMessage({ id: 'REMOVE_FILE_FILENAME' }, { filename, fileSize })}
    </Button>
  );
};

interface FilePreviewProps {
  fileUpload: File;
  onRemove: (removedFile: File) => void;
}

export const FilePreviewRaw = ({
  fileUpload,
  onRemove: handleRemoveFile,
}: FilePreviewProps) => {
  const { formatMessage } = useIntl();
  const isImageThumbnail = useIsImageThumbnail();
  if (!fileUpload) return null;
  const { name, size } = fileUpload;
  const fileSizeDisplayed = formatFileSize(size);
  const fileTypeDisplayed = name.split('.').pop()?.toUpperCase();

  if (isImageThumbnail(fileUpload)) {
    const imagePreview = URL.createObjectURL(fileUpload);
    return (
      <Box
        css={{
          position: 'relative',
          padding: '$half',
          marginTop: '$quarter',
        }}
      >
        <StyledImage
          src={imagePreview}
          alt={formatMessage({ id: 'PREVIEW_OF_FILE' }, { fileName: name })}
        />
        <ParagraphText css={{ position: 'absolute' }} size="sm">
          {fileSizeDisplayed}
        </ParagraphText>
        <CloseButton
          onClick={() => handleRemoveFile(fileUpload)}
          top="0"
          right="0"
          filename={name}
          fileSize={fileSizeDisplayed}
        />
      </Box>
    );
  }

  return (
    <Box
      css={{
        position: 'relative',
        padding: '$half',
        width: '25.4rem',
        '@mobile': {
          width: '100%',
        },
      }}
    >
      <Box
        css={{
          backgroundColor: '$surfaceBackgroundPrimary',
          padding: '$threeQuarters',
          borderRadius: '$medium',
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            flexShrink: 0,
          },
          // we need to define a max-width for the document icon
          // tenants who do not use a react-svg component for this override
          // will see a extremely large img element
          // this a temp solution until we work on the following todo
          // TODO: remove this as part of: https://everlong.atlassian.net/browse/PCHAT-4214
          // once we can use the same react-svg components for all tenants, we should be able to remove this
          '& > img': {
            maxWidth: '24px',
          },
        }}
      >
        {
          handleStaticAsset(MESSAGING_ASSET_KEYS.MESSAGING_DOCUMENT_ICON, {
            isComponent: true,
          }) as JSX.Element
        }
        {/* 3.2rem offset comes from the width of the document icon and the text left margin */}
        <Box css={{ marginLeft: '$half', maxWidth: 'calc(100% - 3.2rem)' }}>
          <ParagraphText
            size="sm"
            css={{
              '&&': {
                marginBlockEnd: '$none',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </ParagraphText>
          <ParagraphText
            size="sm"
            css={{
              '&&': {
                marginBlockEnd: '$none',
              },
            }}
          >
            {fileTypeDisplayed}
            <UtilityText size="sm" aria-hidden="true">
              &nbsp;•
            </UtilityText>
            <UtilityText size="sm">&nbsp;{fileSizeDisplayed}</UtilityText>
          </ParagraphText>
        </Box>
      </Box>
      <CloseButton
        onClick={() => handleRemoveFile(fileUpload)}
        top="0"
        right="0"
        filename={name}
        fileSize={fileSizeDisplayed}
      />
    </Box>
  );
};

// Memoizing this such that we don't re-render it each time a user types in the chat box
// (prevents flash of the image occurring)
export const FilePreview = memo(FilePreviewRaw);
