import {
  getWalletItemsSectioned,
  GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE,
  WalletItemsSectionedQueryResponse,
} from '@leagueplatform/wallet-api';
import { UseQueryOptions, useQuery } from 'react-query';
import { useWalletParams } from 'hooks/use-wallet-params.hook';

export function useWalletItemsSectionedQuery(
  options?: UseQueryOptions<WalletItemsSectionedQueryResponse>,
) {
  const { userId } = useWalletParams();

  return useQuery<WalletItemsSectionedQueryResponse>(
    [GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE, userId],
    () => getWalletItemsSectioned(userId),
    options,
  );
}
