import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { Backdrop } from 'ui-kit/components/backdrop.view';
import { ModalPortal } from './modal.portal.view';
import { useOnEscape } from '../../utils/use-on-escape';

export const ModalWrapper = ({ onClose, options, children, ...props }) => {
  useOnEscape(onClose);

  return (
    <ModalPortal>
      <FocusTrap focusTrapOptions={options}>
        <Backdrop onClose={onClose} closeOnChildrenClick={false} {...props}>
          {children}
        </Backdrop>
      </FocusTrap>
    </ModalPortal>
  );
};

ModalWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  // See https://github.com/focus-trap/focus-trap#createfocustrapelement-createoptions
  // for available options
  options: PropTypes.shape({}),
};

ModalWrapper.defaultProps = {
  options: {},
};
