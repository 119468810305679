import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_GROUP_MESSAGE_TYPE = 'get_group';

export const getGroup = async (groupId?: string) => {
  return SocketAsFetch.fetch({
    message_type: GET_GROUP_MESSAGE_TYPE,
    info: {
      group_id: groupId,
    },
  });
};
