import React from 'react';
import { OnboardingModuleConfig } from 'types/onboarding-module-config.type';

const OnboardingModuleConfigContext = React.createContext<
  OnboardingModuleConfig | undefined
>(undefined);

export const OnboardingModuleConfigProvider =
  OnboardingModuleConfigContext.Provider;

export function useOnboardingModuleConfig() {
  const context = React.useContext(OnboardingModuleConfigContext);
  if (context === undefined) {
    throw new Error(
      'useOnboardingModuleConfig must be used within a OnboardingModuleConfigProvider',
    );
  }
  return context;
}
