import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  ParagraphText,
  StatusBanner,
  HeadingText,
} from '@leagueplatform/genesis-core';

export const LegalDisclaimer = () => {
  const { formatMessage } = useIntl();
  const disclaimerText = formatMessage({
    id: 'CHALLENGES_LEGAL_DISCLAIMER',
  });
  if (disclaimerText === ' ') {
    return null;
  }
  return (
    <StatusBanner
      title={
        <HeadingText level="2" size="sm">
          {formatMessage({ id: 'DISCLAIMER' })}
        </HeadingText>
      }
      status="neutral"
    >
      <ParagraphText>{disclaimerText}</ParagraphText>
    </StatusBanner>
  );
};
