import * as React from 'react';
import {
  HeadingText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

type ChallengeActivitySectionHeaderProps = {
  heading: string;
  href?: string;
};

export function ChallengeActivitySectionHeader({
  heading,
  href,
}: ChallengeActivitySectionHeaderProps) {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      horizontalAlignment="spaceBetween"
      orientation="horizontal"
      verticalAlignment="center"
      css={{ width: '100%' }}
    >
      <HeadingText level="2" size="lg">
        {heading}
      </HeadingText>
      {href ? (
        <TextAction size="medium" href={href}>
          {formatMessage({ id: 'VIEW_ALL' })}
        </TextAction>
      ) : null}
    </StackLayout>
  );
}
