import * as React from 'react';
import { SubtitleOne, Image, Flex } from '@leagueplatform/genesis-commons';
import { HealthSource } from '@leagueplatform/dashboard-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  useDataSourceConfigMap,
  isSupportedHealthDevice,
} from 'constants/use-data-source-config';

type DataSourceLogoIcon = { src: string };

type DataSourceItemProps = {
  dataSource: HealthSource;
};
const SourceLogoIcon = ({ src }: DataSourceLogoIcon) => (
  <Image alt="" width="36px" src={src} />
);

export const DataSourceItem = ({ dataSource }: DataSourceItemProps) => {
  const dataSourceConfig = useDataSourceConfigMap();

  //  check if unsupported data type is passed in, ignore it
  if (!isSupportedHealthDevice(dataSource.device)) {
    return null;
  }

  const source = dataSourceConfig.source(dataSource.device);
  const icon = dataSourceConfig.icon(dataSource.device);
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      paddingY="one"
      borderBottomWidth="thin"
      borderColor="onSurface.border.subdued"
    >
      <SourceLogoIcon src={handleStaticAsset(icon) as string} />
      <SubtitleOne paddingLeft="one">{source}</SubtitleOne>
    </Flex>
  );
};
