import React from 'react';
import {
  Box,
  GDSColor,
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
  useClickableCard,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  BadgeList,
  BadgeListProps,
} from 'common/badge-list/badge-list.component';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
  MasonryDismissAction,
} from '../../types/masonry-driver-node-actions';

export type MasonrySmallCardNodeProperties = {
  image: string;
  eyebrow?: string;
  heading?: string;
  body?: string;
  imageAltText: string;
  badges?: BadgeListProps['badges'];
  isChildItem?: boolean;
  badgeBackgroundColor?: GDSColor;
};

type MasonrySmallCardNodeActions = {
  onClick?: MasonryDriverAction;
  onDismiss?: MasonryDismissAction;
};

export type MasonrySmallCardNode = MasonryEngineNode<
  'smallCard',
  MasonrySmallCardNodeProperties,
  MasonrySmallCardNodeActions
>;

export type MasonrySmallCardRendererProps =
  MasonryEngineNodeRendererProps<MasonrySmallCardNode>;

export const MasonrySmallCardRenderer = ({
  eyebrow,
  heading,
  body,
  image,
  badges,
  imageAltText,
  isChildItem = false,
  badgeBackgroundColor,
  onClick,
  onDismiss,
}: MasonrySmallCardRendererProps) => {
  const dismissible = Boolean(onDismiss);

  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();
  const overlineId = `svc-${heading?.replace(/\s+/g, '-')}`;

  const onClickDeeplinkAction = onClick as MasonryDeeplinkAction;

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean(onClickDeeplinkAction?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
      }}
      dismissible={dismissible}
      onDismiss={onDismiss}
    >
      <StackLayout
        orientation={{
          '@initial': 'horizontal',
          '@mobile': 'vertical',
          '@mobileLandscape': 'horizontal',
        }}
        verticalAlignment="center"
        horizontalAlignment="spaceBetween"
        spacing="$threeQuarters"
        css={{
          padding: '$oneAndHalf',
        }}
      >
        <StackItem verticalAlignment="center" css={{ flex: '2' }}>
          <StackItem>
            {eyebrow && (
              <UtilityText id={overlineId} size="eyebrow" as="p">
                {eyebrow}
              </UtilityText>
            )}
            <StackLayout css={{ marginTop: '$quarter' }}>
              {heading && (
                <HeadingText level="3" size="sm" aria-describedby={overlineId}>
                  <ConditionalLinkRenderer
                    disableDefaultOnClick
                    ref={primaryActionRef}
                    url={onClickDeeplinkAction?.payload.url}
                    onClick={(event: React.MouseEvent) => {
                      if (onClick) {
                        invokeAction({
                          event,
                          ...onClick,
                        });
                      }
                    }}
                  >
                    {heading}
                  </ConditionalLinkRenderer>
                </HeadingText>
              )}
              {body && (
                <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
                  {body}
                </ParagraphText>
              )}
            </StackLayout>
          </StackItem>
          {badges && (
            <Box css={{ marginTop: '$threeQuarters' }}>
              <BadgeList
                badges={badges}
                backgroundColor={badgeBackgroundColor}
              />
            </Box>
          )}
        </StackItem>
        <StackItem>
          <SquareImageContainer
            src={image}
            alt={imageAltText}
            maxWidth="9.8rem"
            maxWidthDesktop={isChildItem ? '9.8rem' : '11.2rem'}
          />
        </StackItem>
      </StackLayout>
    </ConditionalLinkCardRenderer>
  );
};
