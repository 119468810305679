import React from 'react';
import {
  ColorProps,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';

interface StyledPathProps {
  fillColor?: ColorProps['backgroundColor'] | ColorProps['color'];
  strokeColor?: ColorProps['backgroundColor'] | ColorProps['color'];
}

export const StyledPath = genesisStyled.path(
  ({ fillColor, strokeColor }: StyledPathProps) =>
    css({
      fill: fillColor,
      ...(strokeColor && { stroke: strokeColor }),
    }),
);

interface HeartIconProps {
  fillColor?: ColorProps['backgroundColor'] | ColorProps['color'];
}

export const HeartIcon = ({ fillColor }: HeartIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="heartIcon"
  >
    <StyledPath
      d="M12 22L2.62481 11.8374C1.80161 10.9823 1.25825 9.87967 1.07169 8.68581C0.885136 7.49194 1.06484 6.26735 1.58536 5.18551V5.18551C1.97855 4.36873 2.55289 3.66072 3.26106 3.11981C3.96923 2.5789 4.79095 2.22057 5.65852 2.07436C6.5261 1.92814 7.41469 1.99822 8.25109 2.27882C9.08749 2.55941 9.84776 3.0425 10.4692 3.68827L12 5.27885L13.5308 3.68827C14.1522 3.0425 14.9125 2.55941 15.7489 2.27882C16.5853 1.99822 17.4739 1.92814 18.3415 2.07436C19.2091 2.22057 20.0308 2.5789 20.7389 3.11981C21.4471 3.66072 22.0214 4.36873 22.4146 5.18551V5.18551C22.9352 6.26735 23.1149 7.49194 22.9283 8.68581C22.7417 9.87967 22.1984 10.9823 21.3752 11.8374L12 22Z"
      fillColor={fillColor}
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
