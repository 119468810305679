import { useState } from 'react';
import {
  CARD_STATUS,
  PILL_STATUS,
  WalletItemNavigationSectionGroup,
} from '@leagueplatform/wallet-api';
import { parseBenefitCardStatuses } from 'pages/wallet-landing/utils/parse-benefit-card-statuses.util';

// onSuccess: (data) => {
//   // TODO: rework this so that disabling these rules is not necessary
//   // eslint-disable-next-line @typescript-eslint/no-use-before-define
//   setActiveSection(
//     data.items_sectioned[0]?.sections?.[0]?.section_id || '',
//   );
//   const parsedCardStatuses = parseBenefitCardStatuses(data.items_sectioned);
//   // eslint-disable-next-line @typescript-eslint/no-use-before-define
//   setCardStatuses(parsedCardStatuses);
//   // This needs to be set, in case a user only has one benefit and it is not status active
//   // eslint-disable-next-line @typescript-eslint/no-use-before-define
//   setActiveStatus(
//     parsedCardStatuses?.length === 1
//       ? parsedCardStatuses[0]
//       : CARD_STATUS.ACTIVE,
//   );
// },

export const useWalletItemNavigationSections = (
  itemSections: WalletItemNavigationSectionGroup[],
) => {
  const parsedCardStatuses = parseBenefitCardStatuses(itemSections);

  const [activeSection, setActiveSection] = useState(
    itemSections?.[0]?.sections?.[0]?.section_id || '',
  );
  const [cardStatuses] =
    useState<(PILL_STATUS | CARD_STATUS)[]>(parsedCardStatuses);
  const [activeStatus, setActiveStatus] = useState<PILL_STATUS | CARD_STATUS>(
    parsedCardStatuses?.length === 1
      ? parsedCardStatuses[0]
      : CARD_STATUS.ACTIVE,
  );

  const value = {
    activeSection,
    activeStatus,
    cardStatuses,
    setActiveSection,
    setActiveStatus,
  };

  return value;
};
