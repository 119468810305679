/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DateInput } from '@leagueplatform/ui-kit';
import { fieldProps } from './field-shape';

export const DateField = ({
  field: { name, ...field },
  form: { setFieldValue, setFieldTouched, setFieldError },
  disabled,
  readOnly,
  ...props
}) => (
  <DateInput
    {...props}
    {...field}
    name={name}
    setFieldError={setFieldError}
    onChange={(e, { error }) => {
      setFieldValue(name, error || e.currentTarget.value, !error);
      if (error) setFieldError(name, error);
    }}
    onBlur={(_, { error }) => {
      setFieldTouched(name, true, !error);
      if (error) {
        setFieldError(name, error);
      }
    }}
    disabled={Boolean(disabled ?? readOnly)}
  />
);

DateField.propTypes = fieldProps;
