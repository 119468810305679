import React, { useState } from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { SettingsCog } from 'components/settings-cog/settings-cog.component';
import { SettingsDropdownItem } from 'components/settings-dropdown/settings-dropdown-item.component';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { CLP_DETAIL } from 'constants/analytics.constants';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { LeaveChallengeModal } from '../leave-challenge-modal/leave-challenge-modal.component';

export interface HeadingSettingsCogProps {
  status: string;
  isSoloChallenge: boolean;
}

export const HeadingSettingsCog = ({
  status,
  isSoloChallenge,
}: HeadingSettingsCogProps) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { formatMessage } = useIntl();
  const [displayLeaveChallengeModal, setDisplayLeaveChallengeModal] =
    useState(false);
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);

  const isChallengeLeavable =
    status === CHALLENGE_STATUS.UPCOMING || status === CHALLENGE_STATUS.ACTIVE;
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail,
    });

  return (
    <SettingsCog
      modalIsOpen={displayLeaveChallengeModal}
      isSettingsDropdownOpen={isSettingsDropdownOpen}
      setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
    >
      {!isSoloChallenge && (
        <SettingsDropdownItem
          to={JOURNEY_ROUTES.getChallengeSettings(challengeId)}
          onClick={() => handleAnalyticsClick(CLP_DETAIL.SETTINGS_MENU_PRIVACY)}
        >
          {formatMessage({ id: 'PRIVACY' })}
        </SettingsDropdownItem>
      )}
      {isChallengeLeavable && (
        <>
          <SettingsDropdownItem
            onClick={() => {
              setDisplayLeaveChallengeModal(true);
              handleAnalyticsClick(CLP_DETAIL.SETTINGS_MENU_LEAVE_CHALLENGE);
            }}
          >
            {formatMessage({ id: 'LEAVE_CHALLENGE' })}
          </SettingsDropdownItem>
          <LeaveChallengeModal
            isSettingsDropdownOpen={displayLeaveChallengeModal}
            onDismiss={() => {
              setDisplayLeaveChallengeModal(false);
            }}
          />
        </>
      )}
    </SettingsCog>
  );
};
