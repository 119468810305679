import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { ACTIVITY_PROGRESS_PROPERTIES } from './constants/user-health-activity.constants';

export const SET_ACTIVITY_PROGRESS_MESSAGE_TYPE =
  'set_user_health_activity_verification_progress';

interface ProgressObject {
  [ACTIVITY_PROGRESS_PROPERTIES.COUNT_PROGRESS]?: number;
  [ACTIVITY_PROGRESS_PROPERTIES.HELPFUL_TIPS]?: Array<{
    id: string;
    opened: boolean;
  }>;
}

export const setActivityProgress = (
  userActivityId: string,
  activityProgress: ProgressObject,
) =>
  SocketAsFetch.fetch({
    message_type: SET_ACTIVITY_PROGRESS_MESSAGE_TYPE,
    info: {
      user_health_activity_id: userActivityId,
      activity_verification_progress: activityProgress,
    },
  });
