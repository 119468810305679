import { useState, useEffect } from 'react';
import type { Entries } from 'type-fest';
import { config } from '../theme';
import type { GDSBreakpoint } from '../types';

type Listener = (breakpoint: GDSBreakpoint | null) => void;

const listeners = new Set<Listener>();

let currentBreakpoint: GDSBreakpoint | null;

const setIfBreakpointIsActive = (
  breakpoint: GDSBreakpoint,
  matcher: MediaQueryList | MediaQueryListEvent,
) => {
  if (matcher.matches) {
    currentBreakpoint = breakpoint;
    listeners.forEach((listener) => {
      listener(currentBreakpoint);
    });
  }
};

// Check if window and matchMedia are defined before initializing media queries
if (typeof window !== 'undefined' && window.matchMedia) {
  (Object.entries(config.media) as Entries<typeof config.media>).forEach(
    ([breakpoint, query]) => {
      const mediaQueryList = window.matchMedia(query);

      // Only set the initial breakpoint if window is defined
      setIfBreakpointIsActive(breakpoint, mediaQueryList);

      const listener = (_mediaQueryListEvent: MediaQueryListEvent) => {
        setIfBreakpointIsActive(breakpoint, _mediaQueryListEvent);
      };

      if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener('change', listener);
      }
    },
  );
}

export const useActiveBreakpoint = (): GDSBreakpoint | null => {
  const [activeBreakpoint, setActiveBreakpoint] = useState(currentBreakpoint);

  useEffect(() => {
    listeners.add(setActiveBreakpoint);

    return () => {
      listeners.delete(setActiveBreakpoint);
    };
  }, []);

  return activeBreakpoint;
};
