import React, { useState } from 'react';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { Achievement } from '@leagueplatform/rewards-api';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { useAchievementsPage } from '../../hooks/achievements-page.hook';
import { MilestoneSection } from '../milestone-section/milestone-section.view';
import { ViewProgramLibraryPlaceholder } from '../view-programs-library-placeholder/view-programs-library-placeholder.view';
import { BadgeCard } from '../badge-card/badge-card.view';
import { AchievementsDetailsModal } from '../achievements-details-modal/achievements-details-modal';

type UserAchievementsProps = {
  errorComponent: React.ReactElement;
};

export const UserAchievements = ({ errorComponent }: UserAchievementsProps) => {
  const { userAchievements, error, userAchievementsIsLoading } =
    useAchievementsPage();
  const [showAchievementDetailsModal, setShowAchievementDetailsModal] =
    useState(false);
  const [modalAchievement, setModalAchievement] = useState<Achievement>();

  const badgeAnalytics = (badgeName: string, achievementId: string) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'achievements',
      detail: 'view achievement',
      sub_detail: badgeName,
      achievement_id: achievementId,
    });
  };

  if (error) {
    return errorComponent;
  }

  if (userAchievementsIsLoading) {
    return (
      <SkeletonBox data-testid="loading-skeleton" width="100%" height="250px" />
    );
  }

  return (
    <>
      {userAchievements.map(
        ({ category_title, tooltip, completed, in_progress, empty }) => {
          const allCompletedAndInProgressAchievementsInCategory = [
            ...(completed || []),
            ...(in_progress || []),
          ];
          return (
            <MilestoneSection
              title={category_title}
              description={tooltip.description}
              key={category_title}
            >
              {allCompletedAndInProgressAchievementsInCategory.length > 0 ? (
                <>
                  {allCompletedAndInProgressAchievementsInCategory.map(
                    (achievement) => (
                      <BadgeCard
                        imageUrl={achievement.image?.large}
                        isDisabled={achievement.completions <= 0}
                        title={achievement.name}
                        key={achievement.id}
                        earnedStreak={achievement.completions}
                        onClick={() => {
                          setModalAchievement(achievement);
                          setShowAchievementDetailsModal(true);
                          badgeAnalytics(achievement.name, achievement.id);
                        }}
                      />
                    ),
                  )}
                </>
              ) : (
                <ViewProgramLibraryPlaceholder
                  title={empty?.title}
                  description={empty?.description}
                  cta={empty?.cta}
                />
              )}
            </MilestoneSection>
          );
        },
      )}

      {showAchievementDetailsModal && modalAchievement && (
        <AchievementsDetailsModal
          onModalChange={() =>
            setShowAchievementDetailsModal((previous) => !previous)
          }
          modalAchievement={modalAchievement}
          open={showAchievementDetailsModal}
        />
      )}
    </>
  );
};
