import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

const ENABLE_INSIGHTS_IMPROVEMENTS = 'release_about-me_insights-improvement';

export const useIsInsightsImprovementsEnabled = () => {
  const { isLoading, data: isInsightsImprovementsEnabled } =
    useFeatureFlagQuery(ENABLE_INSIGHTS_IMPROVEMENTS);

  return {
    isLoading,
    isInsightsImprovementsEnabled,
  };
};
