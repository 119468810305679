import {
  getUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
  UserProfileAggregate,
} from '@leagueplatform/user-profile-api';
import { useQuery, UseQueryOptions } from 'react-query';

export function useUserProfileQuery<TSelect = UserProfileAggregate>(
  userId?: string,
  options?: UseQueryOptions<UserProfileAggregate, unknown, TSelect>,
) {
  return useQuery<UserProfileAggregate, unknown, TSelect>(
    [GET_USER_PROFILE_MESSAGE_TYPE, userId],
    () => getUserProfile(userId),
    options,
  );
}
