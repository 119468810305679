import React, { ComponentProps } from 'react';
import { useConfigProperty } from '@leagueplatform/core';
import { genesisStyled, css, Flex } from '@leagueplatform/genesis-commons';

const StyledHeaderContainer = genesisStyled(Flex)(({ pageHeader }) => {
  const paddingBottom =
    pageHeader?.shape === 'line'
      ? 'twoAndHalf'
      : ['four', 'four', 'two', 'four'];

  const borderBottomRadius = pageHeader?.shape === 'line' ? 0 : '80% 60%';

  return css({
    height: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    marginBottom: 'two',
    paddingTop: ['twoAndHalf', 'threeQuarters', 'three'],
    paddingBottom,

    '&::after': {
      content: '""',
      backgroundColor: `${
        pageHeader?.backgroundColor ?? 'surface.background.tertiary'
      }`,
      zIndex: -1,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderBottomLeftRadius: borderBottomRadius,
      borderBottomRightRadius: borderBottomRadius,
      transform: 'scaleX(1.2)',
    },
  });
});

type WalletPageHeaderBackgroundProps = ComponentProps<
  typeof StyledHeaderContainer
>;

export const WalletPageHeaderBackground = (
  props: WalletPageHeaderBackgroundProps,
) => {
  const pageHeaderConfig = useConfigProperty('core.ui.components.pageHeader');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledHeaderContainer pageHeader={pageHeaderConfig} {...props} />
  );
};
