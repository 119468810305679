{
  "dragDescriptionKeyboard": "اضغط Enter لبدء السحب.",
  "dragDescriptionKeyboardAlt": "اضغط على Alt + Enter لبدء السحب.",
  "dragDescriptionLongPress": "اضغط باستمرار لبدء السحب.",
  "dragDescriptionTouch": "اضغط مرتين لبدء السحب.",
  "dragDescriptionVirtual": "انقر لبدء السحب.",
  "dragItem": "اسحب {itemText}",
  "dragSelectedItems": "اسحب {count, plural, one {# عنصر محدد} other {# عناصر محددة}}",
  "dragSelectedKeyboard": "اضغط على Enter للسحب {count, plural, one {عدد العناصر المختارة} other {عدد العناصر المختارة}}.",
  "dragSelectedKeyboardAlt": "اضغط على مفتاحي Alt + Enter للسحب {count, plural, one {عدد العناصر المختارة} other {عدد العناصر المختارة}}.",
  "dragSelectedLongPress": "اضغط باستمرار للسحب {count, plural, one {عدد العناصر المختارة} other {عدد العناصر المختارة}}.",
  "dragStartedKeyboard": "بدأ السحب. اضغط Tab للانتقال إلى موضع الإفلات، ثم اضغط Enter للإفلات، أو اضغط Escape للإلغاء.",
  "dragStartedTouch": "بدأ السحب. انتقل إلى موضع الإفلات، ثم اضغط مرتين للإفلات.",
  "dragStartedVirtual": "بدأ السحب. انتقل إلى مكان الإفلات، ثم انقر أو اضغط Enter للإفلات.",
  "dropCanceled": "تم إلغاء الإفلات.",
  "dropComplete": "اكتمل الإفلات.",
  "dropDescriptionKeyboard": "اضغط Enter للإفلات. اضغط Escape لإلغاء السحب.",
  "dropDescriptionTouch": "اضغط مرتين للإفلات.",
  "dropDescriptionVirtual": "انقر للإفلات.",
  "dropIndicator": "مؤشر الإفلات",
  "dropOnItem": "إفلات {itemText}",
  "dropOnRoot": "الإفلات",
  "endDragKeyboard": "السحب. اضغط Enter لإلغاء السحب.",
  "endDragTouch": "السحب. اضغط مرتين لإلغاء السحب.",
  "endDragVirtual": "السحب. انقر لإلغاء السحب.",
  "insertAfter": "أدخل بعد {itemText}",
  "insertBefore": "أدخل قبل {itemText}",
  "insertBetween": "أدخل بين {beforeItemText} و {afterItemText}"
}
