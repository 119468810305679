import * as React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import {
  HighlightCard,
  CalloutBanner,
} from '@leagueplatform/web-common-components';
import { ParagraphText } from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
} from '@leagueplatform/masonry-engine';
import type {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from '@leagueplatform/masonry-renderers';

type InsightHighlightCardProps = {
  heading: string;
  actionText: string;
  callout: {
    backgroundColor: string;
    body: string;
    image: string;
    imageAltText: string;
  };
  content: React.ReactElement[];
};

type MasonryLargeCardNodeActions = {
  onClick?: MasonryDriverAction;
};

type InsightHighlightCardNode = MasonryEngineNode<
  'insight_card',
  InsightHighlightCardProps,
  MasonryLargeCardNodeActions
>;

type InsightHighlightCardRendererProps =
  MasonryEngineNodeRendererProps<InsightHighlightCardNode>;

export const InsightHighlightCardNodeRenderer = ({
  heading,
  actionText,
  callout,
  onClick,
  content,
}: InsightHighlightCardRendererProps) => {
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <HighlightCard
      title={heading}
      actionLink={(onClick as MasonryDeeplinkAction)?.payload?.url}
      onClick={(event: React.MouseEvent) => {
        if (onClick) {
          invokeAction({
            event,
            ...onClick,
          });
        }
      }}
      actionText={actionText}
    >
      <CalloutBanner icon={callout.image} label={callout.imageAltText}>
        <ParagraphText>{callout.body}</ParagraphText>
      </CalloutBanner>
      <Box mt="two">{content}</Box>
    </HighlightCard>
  );
};
