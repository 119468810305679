import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import { BodyOne, Flex, SubtitleTwo } from '@leagueplatform/genesis-commons';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';

// This makes adjustments to design easy
const imageRatio = `${(200 / 240) * 100}%`;

const TopicImage = styled(Image)`
  padding-top: ${imageRatio};
`;

export const ModuleWelcomeTopic = ({ name, imageUrl }) => {
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <Flex
      as="li"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      marginY={2}
    >
      <TopicImage url={imageUrl} width={isMobile ? '100px' : '200px'} contain />
      {isMobile ? (
        <SubtitleTwo>{name}</SubtitleTwo>
      ) : (
        <BodyOne fontWeight="bold" margin="oneAndHalf">
          {name}
        </BodyOne>
      )}
    </Flex>
  );
};

ModuleWelcomeTopic.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
