import { leagueFetch } from '@leagueplatform/league-fetch';
import { MASONRY_NODE_DISMISS_ENDPOINT } from '../constants/masonry-node-api';

export const postMasonryNodeDismiss = async (nodeId: string) => {
  const response = await leagueFetch(MASONRY_NODE_DISMISS_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        type: 'dismissed',
        id: nodeId,
      },
    }),
  });

  if (response.ok) {
    return true;
  }

  throw new Error(`Masonry Dismiss Action Error:${response.status}`);
};
