import * as React from 'react';
import { Route, Switch, useRouteMatch } from '@leagueplatform/routing';
import { WalletLandingPage } from 'pages/wallet-landing/wallet-landing.page';
import { WalletDetail } from 'pages/wallet-detail/wallet-detail.component';
import { DeepLinkWrapper } from 'components/deep-link-wrapper/deep-link-wrapper.component';
import { useWalletMockData } from 'hooks/use-wallet-mock-data.hook';

export const WalletRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={WalletLandingPage} />
      <Route path={`${path}/:benefitId/:sectionId?`}>
        <DeepLinkWrapper>
          <WalletDetail />
        </DeepLinkWrapper>
      </Route>
    </Switch>
  );
};

export function MockedWalletRoutes() {
  useWalletMockData();
  return <WalletRoutes />;
}
