import React, { useEffect, useState } from 'react';
import { Link, useParams } from '@leagueplatform/routing';
import {
  HeadingOne,
  BodyOne,
  Box,
  Flex,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  CONTENT_MAX_WIDTH,
  noopFunction,
} from 'constants/challenges.constants';
import {
  BackButtonArrow,
  FullPageError,
} from '@leagueplatform/web-common-components';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { isEnrollmentOpen } from 'utils/enrollment-period-status.util';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { TeamsOption } from './components/teams-option/teams-option.component';
import { JoinTeam } from './components/join-team/join-team.component';
import { JoinTeamPageSkeletonLoader } from './components/join-team-page-skeleton-loader/join-team-page-skeleton-loader.component';
import { TEAM_CHOICE } from './constants/join-team-page.constants';
import { CreateTeam } from './components/create-team/create-team.component';

export const JoinTeamPage = (): JSX.Element => {
  const [teamChoice, setTeamChoice] = useState<TEAM_CHOICE | undefined>();
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { isLoading, error, data: challengeData } = useGetChallengeByIdData();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  useEffect(() => {
    if (!teamChoice) {
      trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        screen_name: TLP_SCREEN_NAMES.JOIN_OR_CREATE_TEAM,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeData, teamChoice]);

  if (isLoading) return <JoinTeamPageSkeletonLoader />;

  if (error || !challengeData?.data)
    return <FullPageError route={JOURNEY_ROUTES.programsHome()} />;

  const { endEnroll, maxTeamSize, startEnroll } = challengeData.data;
  const enrollmentOpen = isEnrollmentOpen(
    startEnroll,
    endEnroll,
    defaultTimezone,
  );
  const userIsEnrolled =
    challengeData.data?.userChallenge?.data?.enrollmentStatus === 'enrolled';
  const teamsEnabled = maxTeamSize > 0;
  const userIsOnTeam = !!challengeData.data.challengeTeam?.data.id;
  // TODO: move userCanJoinTeam logic to select method on query (https://everlong.atlassian.net/browse/CHAL-1854)
  const userCanJoinTeam =
    userIsEnrolled && teamsEnabled && !userIsOnTeam && enrollmentOpen;

  // Display error if someone tries to access the /join-team route directly (not via a valid state from CLP) when they shouldn't be able to
  if (!userCanJoinTeam)
    return <FullPageError route={JOURNEY_ROUTES.programsHome()} />;

  if (teamChoice === TEAM_CHOICE.JOIN)
    return <JoinTeam backTo={() => setTeamChoice(undefined)} />;

  if (teamChoice === TEAM_CHOICE.CREATE)
    return <CreateTeam backTo={() => setTeamChoice(undefined)} />;

  const joinTeam = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_JOIN_TEAM,
  ) as string;
  const createTeam = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_JOIN_TEAM,
  ) as string;

  return (
    <Box
      marginX={{ _: 'twoAndHalf', tablet: 'auto' }}
      marginY="five"
      maxWidth={CONTENT_MAX_WIDTH}
    >
      <Flex alignItems="center">
        <BackButtonArrow
          as={Link}
          marginRight={{ _: 'one', phone: 'twoAndHalf' }}
          marginTop="half"
          onClick={noopFunction}
          to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
        />
        <HeadingOne>{formatMessage({ id: 'TEAMS' })}</HeadingOne>
      </Flex>
      <Box marginLeft="four">
        <BodyOne marginTop="one" marginBottom="three">
          {formatMessage({ id: 'CHALLENGE_TEAMS_DESCRIPTION' })}
        </BodyOne>
        <TeamsOption
          image={joinTeam}
          heading={formatMessage({ id: 'JOIN_A_TEAM' })}
          description={formatMessage({ id: 'JOIN_TEAM_DESCRIPTION' })}
          setChoice={() => setTeamChoice(TEAM_CHOICE.JOIN)}
        />
        <TeamsOption
          image={createTeam}
          heading={formatMessage({ id: 'CREATE_A_TEAM' })}
          description={formatMessage({ id: 'CREATE_TEAM_DESCRIPTION' })}
          setChoice={() => setTeamChoice(TEAM_CHOICE.CREATE)}
        />
      </Box>
    </Box>
  );
};
