import { useEffect } from 'react';
import { KEY } from '@leagueplatform/web-common';

/**
 * Handles toggling React Top Navigation Dropdown Menus via other means other than the onClick
 * Allows the user to close the dropdown
 * by clicking outside the dropdown menu or by clicking ESC
 * Additionally handles scenarios where the items within the dropdown contain a modal with its
 * own toggling behavior
 *
 * @param {*} isOpen - useState boolean on whether the dropdown is is open (true) or is closed (false)
 * @param {*} setIsOpen - useState function to trigger isOpen to change
 * @param {*} ref - ref of the dropdown menu to infer a click event outside the dropdown menu
 * @param {*} modalIsOpen - useState boolean on whether a modal is open overtop the dropdown
 */

interface UseHandleDropdownWithModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ref: React.MutableRefObject<HTMLUListElement | null>;
  modalIsOpen: boolean;
}

export const useHandleDropdownWithModal = ({
  isOpen,
  setIsOpen,
  ref,
  modalIsOpen,
}: UseHandleDropdownWithModalProps) => {
  useEffect(() => {
    const handleClickedOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === KEY.ESC) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickedOutside);
      document.addEventListener('keydown', handleEsc);
    }

    if (modalIsOpen) {
      document.removeEventListener('click', handleClickedOutside);
      document.removeEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('click', handleClickedOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, setIsOpen, modalIsOpen, ref]);
};
