import React, { MouseEventHandler } from 'react';
import {
  Card,
  useClickableCard,
  StackLayout,
  Icon,
  Box,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { NotificationCardContent } from './notification-card-content.component';

export type NotificationCardWidgetProps = {
  eyebrow?: string;
  heading: string;
  info?: string;
  onCardClick?: MouseEventHandler;
  subheading: string;
  url?: string;
};

export type NotificationCardWSNode = MasonryEngineNode<
  WidgetType.NOTIFICATION_CARD,
  NotificationCardWidgetProps
>;

const clickableCardStyles: GDSStyleObject = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$one',
  padding: '$one',
  paddingRight: '$quarter',
};

export const NotificationCardWidget = ({
  eyebrow,
  heading,
  info,
  onCardClick,
  subheading,
  url,
}: NotificationCardWidgetProps) => {
  const isClickable = Boolean(url || onCardClick);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <Card.Elevated backgroundColor="secondary">
      <StackLayout orientation="horizontal" verticalAlignment="stretch">
        {isClickable ? (
          <Card.ClickArea
            css={clickableCardStyles}
            onCardMouseDown={onCardMouseDown}
            onCardMouseUp={onCardMouseUp}
            ref={clickAreaRef}
          >
            <NotificationCardContent
              eyebrow={eyebrow}
              heading={heading}
              info={info}
              onCardClick={onCardClick}
              ref={primaryActionRef}
              subheading={subheading}
              url={url}
            />
            {isClickable && <Icon icon="interfaceChevronRight" />}
          </Card.ClickArea>
        ) : (
          <Box css={{ padding: '$one' }}>
            <NotificationCardContent
              eyebrow={eyebrow}
              heading={heading}
              info={info}
              onCardClick={onCardClick}
              ref={primaryActionRef}
              subheading={subheading}
              url={url}
            />
          </Box>
        )}
      </StackLayout>
    </Card.Elevated>
  );
};
