import React from 'react';

export const Checkmark = ({ disabled, size = 14, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill={disabled ? '#949494' : '#FFFFFF'}
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <path d="M5.70508 12C5.1979 12 4.7156 11.8244 4.30798 11.4918C4.22086 11.4209 4.1384 11.3433 4.06217 11.2606L0.342274 7.22566C0.121352 6.98762 0 6.66854 0 6.3309C0 5.99325 0.121352 5.67417 0.340718 5.43613C0.56164 5.1964 0.854129 5.06472 1.16529 5.06472C1.47645 5.06472 1.77049 5.1964 1.98986 5.43613L5.70664 9.46764L11.9002 0.506472C12.1196 0.189083 12.4697 0 12.8337 0C13.0873 0 13.3285 0.0877884 13.5323 0.251548C14.0488 0.671919 14.153 1.46708 13.7672 2.02589L7.57359 10.9871C7.19864 11.5273 6.65256 11.8784 6.03647 11.9747C5.92601 11.9916 5.81554 12 5.70508 12Z" />
  </svg>
);
