import React, { forwardRef, HTMLAttributes } from 'react';
import { styled } from '../../theme';
import type {
  GDSCustomizableComponent,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../../types';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export type GDSBoxProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      as?: React.ElementType;
      children?: React.ReactNode;
    }
  > &
    GDSCustomizableComponent &
    HTMLAttributes<HTMLElement>;

export type GDSBoxComponent = <C extends React.ElementType = 'div'>(
  props: GDSBoxProps<C>,
) => React.ReactElement | null;

const BaseBox = styled('div');

export const Box: GDSBoxComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    { as: asElement, className, css, children, ...props }: GDSBoxProps<C>,
    ref?: PolymorphicRef<C>,
  ) => (
    <GenesisCoreInspector displayName="Box">
      <BaseBox
        as={asElement}
        className={['GDS-box', className].join(' ')}
        css={{
          ...css,
        }}
        ref={ref}
        {...props}
      >
        {children}
      </BaseBox>
    </GenesisCoreInspector>
  ),
);

// @ts-ignore
Box.displayName = 'Box';
