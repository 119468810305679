import React from 'react';

export const HyperLink = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="hyperlinkIcon"
  >
    <path
      d="M12.3428 16.0149C11.6179 17.5227 9.79382 18.1703 8.26012 17.4645V17.4645C6.73059 16.7499 6.0736 14.9517 6.78963 13.4398L7.72269 11.4851C8.44732 9.97736 10.2712 9.32968 11.8047 10.0355V10.0355C12.6771 10.4412 13.3051 11.2279 13.5 12.1593"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5668 7.98525C12.303 6.47733 14.1553 5.82963 15.7128 6.53554V6.53554C17.2657 7.25019 17.9328 9.04798 17.2061 10.5598L16.2586 12.5148C15.5225 14.0227 13.6701 14.6704 12.1126 13.9645V13.9645C11.3551 13.618 10.7737 12.9895 10.5 12.2212"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
