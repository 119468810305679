import { roundAxisValueUp, roundAxisValueUpV2 } from './get-rounded-y-max';

/**
 * Generates n number of ticks after 0 to the `largestNumber`.
 */
export function getAxisTicks(largestNumber: number, numTicks = 4) {
  let tick = largestNumber;

  if (largestNumber < numTicks) {
    tick = numTicks;
  }

  tick = Math.ceil(largestNumber / numTicks);

  if (tick > 10) {
    tick = roundAxisValueUp(tick);
  }

  // Add 1 for the tick 0.
  const ticks = Array(numTicks + 1)
    .fill(0)
    .map((_value, index) => index * tick);

  return ticks;
}

export function getAxisTicksV2(largestNumber: number) {
  let roundedLargestNumber = largestNumber;
  let numTicks = 4;

  // if largest number is > 5 and < 10, round up to the nearest even number
  // this allows for padding/room on the axis
  if (largestNumber > 5 && largestNumber < 10) {
    if (largestNumber % 2 !== 1) {
      roundedLargestNumber = largestNumber + 2;
    } else roundedLargestNumber = largestNumber + 1;
  }

  // if largest number is greater than or equal to 10, add 5% to max data
  // this allows for padding/room on the axis
  if (largestNumber >= 10) {
    roundedLargestNumber = largestNumber * 1.05;
  }

  let tick = roundedLargestNumber;

  if (roundedLargestNumber <= 5) {
    numTicks = roundedLargestNumber + 1;
  }
  if (roundedLargestNumber > 5 && largestNumber < 10) {
    numTicks = roundedLargestNumber / 2;
  }

  tick = Math.ceil(roundedLargestNumber / numTicks);

  if (tick > 10) {
    tick = roundAxisValueUpV2(tick);
  }

  // Add 1 for the tick 0.
  const ticks = Array(numTicks + 1)
    .fill(0)
    .map((_value, index) => index * tick);

  return ticks;
}
