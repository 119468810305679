import React from 'react';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { AchievementCalloutBanner } from '../achievement-callout-banner/achievement-callout-banner.component';

interface ProgramAchievementsProps {
  badgeUrl: string;
  text: string;
  title?: string;
}

export const ProgramAchievements = ({
  badgeUrl,
  title,
  text,
}: ProgramAchievementsProps) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout spacing="$one">
      <HeadingText
        level="3"
        size={{
          '@initial': 'xl',
          '@mobile': 'lg',
        }}
      >
        {title || formatMessage({ id: 'ACHIEVEMENTS' })}
      </HeadingText>
      <AchievementCalloutBanner image={badgeUrl} text={text} badgeSize="56px" />
    </StackLayout>
  );
};
