import React from 'react';

export const QUICK_ACTION_ICONS = {
  question_claims: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
        strokeWidth="1.5"
      >
        <path
          d="M12.5 21.5H5c-.552285 0-1-.447715-1-1V3c0-.552285.447715-1 1-1h11.129c.397547.000085.778802.157982 1.06.439l2.872 2.872c.281018.281198.438915.662453.439 1.06V20.5c0 .552285-.447715 1-1 1h-7z"
          fill="#E5DDF9"
        />
        <path d="M13.799852 6.002344h-2.103546c-.242433-.01721-.481163.060964-.653135.213875-.171971.152912-.25989.365181-.240534.580744 0 .871016 2.997215 1.53727 2.997215 2.408286.004381.211916-.088365.416303-.25692.566175-.168555.149873-.398418.23234-.63675.228444h-2.103545M12.3 6V5m0 6v-1m-5.116667 4.316667H17.05M7.183333 18h6.933334" />
      </g>
    </svg>
  ),
  question_coverage: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#501CD2"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M22.3125 13.375c0-5.695436-4.617064-10.3125-10.3125-10.3125S1.6875 7.679564 1.6875 13.375l.743417-.619667c.890653-.742152 2.042494-1.094662 3.195951-.978092 1.153457.11657 2.211478.692412 2.935632 1.597759.911288-.912424 2.147941-1.425114 3.4375-1.425114 1.289559 0 2.526212.51269 3.4375 1.425114.724154-.905347 1.782175-1.48119 2.935632-1.59776 1.153457-.11657 2.305298.235941 3.195951.978093l.743417.619667z"
          fill="#E5DDF9"
        />
        <path d="M12 11.951417v8.983333c0 .759391-.615609 1.375-1.375 1.375-.759392 0-1.375-.615609-1.375-1.375M12 3.0625v-1.375" />
        <path
          d="M21.526859 16.488034C21.227955 16.176376 20.81359 16 20.380306 16c-.433285 0-.84765.176376-1.146553.488034l-.98276 1.005429-.98276-1.005429C16.96933 16.176376 16.554965 16 16.12168 16c-.433284 0-.84765.176376-1.146553.488034-.633503.659297-.633503 1.696917 0 2.356214l2.926607 3.007174c.091079.094886.21729.148578.34926.148578.131967 0 .25818-.053692.349258-.148578l2.926607-3.011315c.630855-.658749.630855-1.693324 0-2.352073z"
          fill="#E5DDF9"
        />
      </g>
    </svg>
  ),
  question_provider: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
        strokeWidth="1.5"
      >
        <g transform="translate(2 2)">
          <circle fill="#E5DDF9" cx="8.333333" cy="8.333333" r="8.333333" />
          <path d="M20 20l-5.773333-5.773333" />
        </g>
        <path d="M8 10.5h5M10.5 8v5" />
      </g>
    </svg>
  ),
  question_health: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
        strokeWidth="1.5"
      >
        <path
          d="M2.5 13.5V5c0-1.656854 1.343146-3 3-3h13c1.656854 0 3 1.343146 3 3v8.5c0 1.656854-1.343146 3-3 3h-2V22l-6-5.5h-5c-1.656854 0-3-1.343146-3-3z"
          fill="#E5DDF9"
        />
        <path d="M15.276859 6.988034C14.977955 6.676376 14.56359 6.5 14.130306 6.5c-.433285 0-.84765.176376-1.146553.488034l-.98276 1.005428-.98276-1.005428C10.71933 6.676376 10.304965 6.5 9.87168 6.5c-.433284 0-.84765.176376-1.146553.488034-.633503.659297-.633503 1.696917 0 2.356214l2.926607 3.007174c.091079.094886.21729.148578.34926.148578.131967 0 .25818-.053692.349258-.148578l2.926607-3.011315c.630855-.658749.630855-1.693324 0-2.352073z" />
      </g>
    </svg>
  ),
  chat_blurple: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h28v28H0z" />
        <path
          d="M4.5 16.5V8c0-1.656854 1.343146-3 3-3h13c1.656854 0 3 1.343146 3 3v8.5c0 1.656854-1.343146 3-3 3h-2V25l-6-5.5h-5c-1.656854 0-3-1.343146-3-3zM19 10H9m10 4.5H9"
          stroke="#501cd2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ),
};

export const getQuickActionIcon = (iconId) =>
  QUICK_ACTION_ICONS[iconId] ?? QUICK_ACTION_ICONS.question_health;
