import React, { forwardRef } from 'react';
import { string, shape, number } from 'prop-types';
import { Flex, Box } from '@leagueplatform/genesis-commons';
import { HealthProgramsCard } from './health-programs-card.component';
import { programsListPropType } from '../utils/health-programs-page.props';

export const HealthProgramsGallery = forwardRef(
  ({ programs, analyticsContext, firstNewProgramIndex }, ref) => (
    <Flex flexWrap="wrap" as="ul">
      {programs.length > 0 &&
        programs.map((program, index) => {
          const isFirstNewProgram = index === firstNewProgramIndex;

          return (
            <li key={program.program_id}>
              <Box marginRight="oneAndHalf">
                <HealthProgramsCard
                  program={program}
                  analyticsContext={analyticsContext}
                  ref={isFirstNewProgram ? ref : undefined}
                />
              </Box>
            </li>
          );
        })}
    </Flex>
  ),
);

HealthProgramsGallery.propTypes = {
  programs: programsListPropType,
  analyticsContext: shape({ programCategory: string, pageContext: string }),
  firstNewProgramIndex: number.isRequired,
};

HealthProgramsGallery.defaultProps = {
  programs: [],
  analyticsContext: {
    pageContext: undefined,
    programCategory: undefined,
  },
};
