import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { vars } from '../vars';
import { noop } from 'lodash';
import { fullScreen } from '../utils/layout';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { hex2Rgba } from '../utils/helpers';

const BackdropWrapper = genesisStyled('div')((props) =>
  css({
    transition: '0.3s opacity',
    opacity: props.isVisible ? 1 : 0,
    position: 'relative',
    zIndex: props.zIndex,
  }),
);

const BackdropContainer = genesisStyled(Flex)`
  ${fullScreen()};
  position: fixed;
  height: 100vh;
  background-color: ${hex2Rgba(vars.colour.black, 0.7)};
`;

export class Backdrop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.setVisibile(true);
  }

  onClick(e) {
    const { onClose, closeOnChildrenClick } = this.props;

    if (!closeOnChildrenClick && e.target !== e.currentTarget) {
      return;
    }

    onClose(e);
  }

  setVisibile(isVisible) {
    this.setState({
      isVisible,
    });
  }

  render() {
    const { children, className, zIndex } = this.props;
    const { isVisible } = this.state;
    return (
      <BackdropWrapper isVisible={isVisible} zIndex={zIndex}>
        <BackdropContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          onClick={this.onClick}
          className={className}
        >
          {children}
        </BackdropContainer>
      </BackdropWrapper>
    );
  }
}

Backdrop.defaultProps = {
  onClose: noop,
  className: null,
  closeOnChildrenClick: true,
  zIndex: 100,
};

Backdrop.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeOnChildrenClick: PropTypes.bool,
  zIndex: PropTypes.number,
};
