import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  HeadingThree,
  BodyOne,
  QuietButton,
  DangerButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { HealthProgramsProgramDetailsModal } from './health-programs-program-details-modal.component';

import { useProgramDetailsAnalytics } from '../hooks/use-program-details-analytics';

const modalTitleId = 'remove-program-modal-title';

const HeaderLine = genesisStyled(Box)(
  css({
    width: '100%',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
  }),
);

export const RemoveProgramModal = ({
  onClose,
  requestLeaveHealthProgram,
  onProgramRemove,
  programName,
  programId,
}) => {
  const { formatMessage } = useIntl();
  const programDetailsAnalytics = useProgramDetailsAnalytics(
    programName,
    programId,
  );

  useEffect(() => {
    programDetailsAnalytics.viewProgramDetailsRemoveModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HealthProgramsProgramDetailsModal
      onClose={onClose}
      aria-labelledby={modalTitleId}
      bodyStyleOverrides={{ padding: 'none' }}
    >
      <HeaderLine>
        <HeadingThree id={modalTitleId} margin="oneAndHalf">
          {formatMessage({ id: 'REMOVE_PROGRAM' })}
        </HeadingThree>
      </HeaderLine>
      <Box paddingX="two" paddingY="oneAndHalf">
        <BodyOne color="onSurface.text.subdued" marginBottom="half">
          {formatMessage({ id: 'ARE_YOU_SURE_REMOVE_PROGRAM' })}
        </BodyOne>
        <Flex
          flexDirection={{ _: 'column-reverse', phone: 'row' }}
          justifyContent={{ phone: 'flex-end' }}
          alignItems={{ phone: 'flex-end' }}
        >
          <QuietButton
            marginRight={{ phone: 'half' }}
            marginTop={{ _: 'half', phone: 'none' }}
            onClick={onClose}
          >
            {formatMessage({ id: 'CANCEL' })}
          </QuietButton>
          <DangerButton
            onClick={() => {
              requestLeaveHealthProgram();
              onProgramRemove();
            }}
            marginTop="oneAndHalf"
          >
            {formatMessage({ id: 'REMOVE_PROGRAM_BUTTON' })}
          </DangerButton>
        </Flex>
      </Box>
    </HealthProgramsProgramDetailsModal>
  );
};

RemoveProgramModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestLeaveHealthProgram: PropTypes.func,
  onProgramRemove: PropTypes.func,
  programName: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

RemoveProgramModal.defaultProps = {
  requestLeaveHealthProgram: null,
  onProgramRemove: null,
};
