import htmlElementAttributes from 'react-html-attributes';
import propTypes from '@styled-system/prop-types';

const stylePropTypes = {
  /* space */
  ...propTypes.space,
  /* color */
  ...propTypes.color,
  ...propTypes.colourStyle,
  /* typography */
  ...propTypes.typography,
  /* layout */
  ...propTypes.layout,
  /* flexbox */
  ...propTypes.flexbox,
  /* background */
  ...propTypes.background,
  /* border */
  ...propTypes.border,
  /* position */
  ...propTypes.position,
  /* shadow */
  ...propTypes.shadow,
};

const stylePropKeys = Object.keys(stylePropTypes);

/**
 * Based on https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes
 * Minus the experimental types and ones getting removed
 */
const globalHTMLAttrsKeys = [
  'accessKey',
  'autoCapitalize',
  'className',
  'contentEditable',
  'dir',
  'draggable',
  'hidden',
  'id',
  'inputMode',
  'itemProp',
  'itemScope',
  'itemType',
  'itemRef',
  'itemID',
  'lang',
  'part',
  'slot',
  'style',
  'tabIndex',
  'title',
];

const dataAttrRegex = /^data-/;

/**
 * 'react-html-attributes' is missing:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/readonly
 */
const inputPropKeys = [
  ...htmlElementAttributes.input,
  'readOnly',
  'onBlur',
  'onFocus',
].filter((key) => !['width', 'height'].includes(key));

interface Predicate {
  (prop: string): boolean;
}

const pick = (predicate: Predicate) =>
  function pick(props: object): object {
    return Object.keys(props).reduce(
      (result, prop) =>
        predicate(prop) ? { ...result, [prop]: props[prop] } : result,
      {}
    );
  };

const negate = (predicate: Predicate): Predicate =>
  function negate(prop: string): boolean {
    return !predicate(prop);
  };

export const pickStyleProps = pick((prop) => stylePropKeys.includes(prop));
export const omitStyleProps = pick((prop) => !stylePropKeys.includes(prop));

export const pickGlobalHTMLProps = pick(
  (prop) => dataAttrRegex.test(prop) || globalHTMLAttrsKeys.includes(prop)
);

export const pickInputProps = pick((prop) => inputPropKeys.includes(prop));
export const omitInputProps = pick((prop) => !inputPropKeys.includes(prop));

// layout props are props that are external to the borders:
// ✔️ margin
// ✔️ display
// ✔️ position
// ✘ padding
// ✘ border
// ✘ background color
const layoutPropRegex = new RegExp(
  [
    // LAYOUT https://styled-system.com/api/#layout
    /^display$/,
    /^width$/,
    /^height$/,
    /^minWidth$/,
    /^minHeight$/,
    /^maxWidth$/,
    /^maxHeight$/,
    /^size$/,
    /^verticalAlign$/,
    /^overflow$/,
    /^overflowX$/,
    /^overflowY$/,
    // POSITION https://styled-system.com/api/#position
    /^position$/,
    /^top$/,
    /^left$/,
    /^bottom$/,
    /^right$/,
    // MARGINS https://styled-system.com/api/#space
    /^margin/,
    // FLEXBOX https://styled-system.com/api/#flexbox
    /^flex/,
    /^align/,
    /^justify/,
    /^order$/,
  ]
    .map((re) => re.source)
    .join('|')
);

const isLayoutProp = (prop) => layoutPropRegex.test(prop);

export const pickLayoutProps = pick(isLayoutProp);
export const omitLayoutProps = pick(negate(isLayoutProp));
