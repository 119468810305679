{
  "dragDescriptionKeyboard": "Druk op Enter om te slepen.",
  "dragDescriptionKeyboardAlt": "Druk op Alt + Enter om te slepen.",
  "dragDescriptionLongPress": "Houd lang ingedrukt om te slepen.",
  "dragDescriptionTouch": "Dubbeltik om te slepen.",
  "dragDescriptionVirtual": "Klik om met slepen te starten.",
  "dragItem": "{itemText} slepen",
  "dragSelectedItems": "{count, plural, one {# geselecteerd item} other {# geselecteerde items}} slepen",
  "dragSelectedKeyboard": "Druk op Enter om {count, plural, one {# geselecteerd item} other {# geselecteerde items}} te slepen.",
  "dragSelectedKeyboardAlt": "Druk op Alt + Enter om {count, plural, one {# geselecteerd item} other {# geselecteerde items}} te slepen.",
  "dragSelectedLongPress": "Houd lang ingedrukt om {count, plural, one {# geselecteerd item} other {# geselecteerde items}} te slepen.",
  "dragStartedKeyboard": "Begonnen met slepen. Druk op Tab om naar een locatie te gaan. Druk dan op Enter om neer te zetten, of op Esc om te annuleren.",
  "dragStartedTouch": "Begonnen met slepen. Ga naar de gewenste locatie en dubbeltik om neer te zetten.",
  "dragStartedVirtual": "Begonnen met slepen. Ga naar de gewenste locatie en klik of druk op Enter om neer te zetten.",
  "dropCanceled": "Neerzetten geannuleerd.",
  "dropComplete": "Neerzetten voltooid.",
  "dropDescriptionKeyboard": "Druk op Enter om neer te zetten. Druk op Esc om het slepen te annuleren.",
  "dropDescriptionTouch": "Dubbeltik om neer te zetten.",
  "dropDescriptionVirtual": "Klik om neer te zetten.",
  "dropIndicator": "aanwijzer voor neerzetten",
  "dropOnItem": "Neerzetten op {itemText}",
  "dropOnRoot": "Neerzetten op",
  "endDragKeyboard": "Bezig met slepen. Druk op Enter om te annuleren.",
  "endDragTouch": "Bezig met slepen. Dubbeltik om te annuleren.",
  "endDragVirtual": "Bezig met slepen. Klik om te annuleren.",
  "insertAfter": "Plaatsen na {itemText}",
  "insertBefore": "Plaatsen vóór {itemText}",
  "insertBetween": "Plaatsen tussen {beforeItemText} en {afterItemText}"
}
