import React, { forwardRef, useMemo } from 'react';
import { Icon, IconSource } from 'components/icon/icon';
import { VisuallyHidden } from 'components/visually-hidden/visually-hidden';
import { Spinner } from 'components/spinner/spinner';
import {
  mapResponsiveProp,
  useResponsiveProp,
} from '../../hooks/use-responsive-prop';
import type {
  GDSCustomizableComponent,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  GDSResponsiveProp,
  GDSHorizontalAlignmentValues,
} from '../../types';
import { styled } from '../../theme';
import { useThemeStrings } from '../../hooks/use-theme';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export type GDSButtonSize = 'large' | 'medium' | 'small' | 'toolbar';

export type GDSButtonWidth = 'hugContents' | 'fillContainer';

export type GDSButtonPriority = 'primary' | 'secondary' | 'tertiary';

export type GDSButtonTextAlignment = GDSHorizontalAlignmentValues | 'justify';

export type GDSButtonProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      children: React.ReactNode;
      as?: 'button' | 'a' | React.ElementType;
      size?: GDSResponsiveProp<GDSButtonSize>;
      type?: 'button' | 'submit' | 'reset';
      hideLabel?: GDSResponsiveProp<boolean>;
      truncateLabel?: boolean;
      icon?: IconSource;
      priority?: GDSResponsiveProp<GDSButtonPriority>;
      destructive?: boolean;
      quiet?: boolean;
      width?: GDSResponsiveProp<GDSButtonWidth>;
      indicator?: 'newTabInternal' | 'newWindowInternal' | 'externalLink';
      disabled?: boolean;
      loading?: boolean;
      textAlign?: GDSButtonTextAlignment;
    }
  > &
    GDSCustomizableComponent;

export type GDSButtonComponent = <C extends React.ElementType = 'button'>(
  props: GDSButtonProps<C>,
) => React.ReactElement | null;

const ButtonBase = styled('button', {
  display: 'inline-flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  borderRadius: '$button',
  borderWidth: '$thin',
  borderStyle: 'solid',
  maxWidth: '100%',
  cursor: 'pointer',
  variants: {
    width: {
      hugContents: { width: 'unset' },
      fillContainer: { width: '100%', justifyContent: 'center' },
    },

    size: {
      large: {
        typography: '$buttonOne',
        paddingBlockStart: '$threeQuarters',
        paddingBlockEnd: '$threeQuarters',
        paddingInlineStart: '$two',
        paddingInlineEnd: '$two',
      },
      medium: {
        typography: '$buttonTwo',
        paddingBlockStart: '$half',
        paddingBlockEnd: '$half',
        paddingInlineStart: '$one',
        paddingInlineEnd: '$one',
      },
      small: {
        typography: '$label',
        paddingBlockStart: '$quarter',
        paddingBlockEnd: '$quarter',
        paddingInlineStart: '$threeQuarters',
        paddingInlineEnd: '$threeQuarters',
      },
      toolbar: {
        paddingBlockStart: '$half',
        paddingBlockEnd: '$half',
        paddingInlineStart: '$threeQuarters',
        paddingInlineEnd: '$threeQuarters',
        typography: '$label',
      },
    },
    loading: {
      true: { pointerEvents: 'none' },
    },
  },
});

const destructiveLoadingStyle = {
  backgroundColor: '$primaryBackgroundCriticalDefault',
  color: '$primaryTextCriticalDefault',
  borderColor: 'transparent',
};

const destructiveHoverAndActiveStyle = {
  '&:hover': {
    backgroundColor: '$primaryBackgroundCriticalHovered',
    color: '$primaryTextCriticalDefault',
    borderColor: 'transparent',
  },
  '&:active': {
    backgroundColor: '$primaryBackgroundCriticalPressed',
    color: '$primaryTextCriticalDefault',
    borderColor: 'transparent',
  },
};

const disabledStyle = {
  backgroundColor: '$interactiveBackgroundDisabled',
  color: '$onSurfaceTextSubdued',
  borderColor: 'transparent',
  pointerEvents: 'none',
};

const loadingStyle = {
  backgroundColor: '$primaryBackgroundDefault',
  color: '$secondaryTextHovered',
  borderColor: 'transparent',
};

const StandardButton = styled(ButtonBase, {
  variants: {
    priority: {
      primary: {
        backgroundColor: '$primaryBackgroundDefault',
        color: '$primaryTextDefault',
        borderColor: 'transparent', // Transparent border maintains border width
        '&[aria-disabled="true"], &:disabled': disabledStyle,
        '&:hover': {
          backgroundColor: '$primaryBackgroundHovered',
        },
        '&:active': {
          backgroundColor: '$primaryBackgroundPressed',
        },
      },
      secondary: {
        backgroundColor: '$secondaryBackgroundDefault',
        color: '$secondaryTextDefault',
        borderColor: '$secondaryBorderDefault',
        '&[aria-disabled="true"], &:disabled': disabledStyle,
        '&:hover': {
          backgroundColor: '$secondaryBackgroundHovered',
          color: '$secondaryTextHovered',
          borderColor: 'transparent',
        },
        '&:active': {
          backgroundColor: '$secondaryBackgroundPressed',
          color: '$secondaryTextHovered',
          borderColor: 'transparent',
        },
      },
      tertiary: {
        backgroundColor: '$tertiaryBackgroundDefault',
        color: '$tertiaryTextDefault',
        borderColor: '$tertiaryBorderDefault',
        '&[aria-disabled="true"], &:disabled': disabledStyle,
        '&:hover': {
          backgroundColor: '$tertiaryBackgroundHovered',
          color: '$tertiaryTextHovered',
          borderColor: 'transparent',
        },
        '&:active': {
          backgroundColor: '$tertiaryBackgroundPressed',
          color: '$tertiaryTextHovered',
          borderColor: 'transparent',
        },
      },
    },
    destructive: {
      true: destructiveHoverAndActiveStyle,
    },
    quiet: {
      true: { borderColor: 'transparent' },
    },
    // These variants don't have a shared style - they are only used in compound variants, so the default value is an empty object
    iconOnly: {
      true: {},
    },
    reducePadding: {
      true: {},
    },
    size: {
      small: {},
      medium: {},
      large: {},
      toolbar: {},
    },
    loading: {
      true: {},
    },
  },
  compoundVariants: [
    // Icon only
    {
      size: 'large',
      iconOnly: true,
      css: {
        padding: '$threeQuarters',
      },
    },
    {
      size: 'medium',
      iconOnly: true,
      css: {
        padding: '$half',
      },
    },
    {
      size: 'small',
      iconOnly: true,
      css: {
        padding: '$half',
      },
    },
    // Quiet
    {
      priority: 'primary',
      quiet: true,
      css: {
        borderColor: 'transparent',
      },
    },
    {
      priority: 'secondary',
      quiet: true,
      css: {
        borderColor: 'transparent',
      },
    },
    {
      priority: 'tertiary',
      quiet: true,
      css: {
        borderColor: 'transparent',
      },
    },
    // Destructive
    {
      priority: 'primary',
      destructive: true,
      css: {
        backgroundColor: '$primaryBackgroundCriticalDefault',
        color: '$primaryTextCriticalDefault',
      },
    },
    {
      priority: 'secondary',
      destructive: true,
      quiet: false,
      css: {
        color: '$onSurfaceTextCritical',
        borderColor: '$onSurfaceTextCritical',
      },
    },
    {
      priority: 'secondary',
      destructive: true,
      quiet: true,
      css: {
        color: '$onSurfaceTextCritical',
        borderColor: 'transparent',
      },
    },
    // Loading
    {
      loading: true,
      destructive: false,
      css: loadingStyle,
    },
    // Destructive loading
    {
      loading: true,
      destructive: true,
      css: destructiveLoadingStyle,
    },

    {
      size: 'large',
      reducePadding: true,
      css: {
        paddingInlineStart: '$oneAndHalf',
        paddingInlineEnd: '$oneAndHalf',
      },
    },
    {
      size: 'medium',
      reducePadding: true,
      css: {
        paddingInlineStart: '$threeQuarters',
        paddingInlineEnd: '$threeQuarters',
      },
    },
    {
      size: 'small',
      reducePadding: true,
      css: { paddingInlineStart: '$half', paddingInlineEnd: '$half' },
    },
  ],
});

const toolbarQuietStyle = {
  borderColor: 'transparent',
  backgroundColor: 'transparent',
  '&:hover': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
};

const ToolbarButton = styled(ButtonBase, {
  variants: {
    priority: {
      primary: {
        backgroundColor: '$primaryBackgroundDefault',
        color: '$primaryTextDefault',
        borderColor: 'transparent', // Transparent border maintains border width
        '&:hover': {
          backgroundColor: '$primaryBackgroundHovered',
        },
        '&:active': {
          backgroundColor: '$primaryBackgroundPressed',
        },
        '&[aria-disabled="true"], &:disabled': disabledStyle,
      },
      secondary: {
        backgroundColor: '$surfaceBackgroundPrimary',
        color: '$interactiveActionPrimary',
        borderColor: '$interactiveActionPrimary',
        '&:hover': {
          backgroundColor: '$surfaceBackgroundPrimary',
          color: '$interactiveActionHovered',
          borderColor: '$interactiveActionHovered',
        },
        '&:active': {
          backgroundColor: '$surfaceBackgroundPrimary',
          color: '$interactiveActionPressed',
          borderColor: '$interactiveActionPressed',
        },
        '&[aria-disabled="true"], &:disabled': disabledStyle,
      },
      tertiary: {
        backgroundColor: '$surfaceBackgroundPrimary',
        color: '$interactiveActionSubdued',
        borderColor: '$interactiveActionSubdued',
        '&:hover': {
          backgroundColor: '$surfaceBackgroundPrimary',
          color: '$interactiveActionSubdued',
          borderColor: '$interactiveActionSubdued',
        },
        '&:active': {
          backgroundColor: '$surfaceBackgroundPrimary',
          color: '$interactiveActionPrimary',
          borderColor: '$interactiveActionPrimary',
        },
        '&[aria-disabled="true"], &:disabled': disabledStyle,
      },
    },
    destructive: {
      true: destructiveHoverAndActiveStyle,
    },
    loading: {
      true: {},
    },
    iconOnly: {
      true: {
        padding: '$quarter',
      },
    },
    reducePadding: {
      true: { padding: '$half' },
    },
    quiet: { true: toolbarQuietStyle },
  },

  compoundVariants: [
    // Destructive
    {
      priority: 'primary',
      destructive: true,
      quiet: false,
      loading: false,
      css: {
        backgroundColor: '$primaryBackgroundCriticalDefault',
        color: '$primaryTextCriticalDefault',
      },
    },
    {
      priority: 'secondary',
      destructive: true,
      loading: false,
      quiet: false,
      css: {
        color: '$onSurfaceTextCritical',
        borderColor: '$onSurfaceTextCritical',
      },
    },
    {
      priority: 'secondary',
      destructive: true,
      loading: false,
      quiet: true,
      css: {
        ...destructiveHoverAndActiveStyle,
        color: '$onSurfaceTextCritical',
        borderColor: 'transparent',
      },
    },
    {
      destructive: true,
      loading: true,
      css: destructiveLoadingStyle,
    },
    {
      priority: 'tertiary',
      quiet: true,
      css: toolbarQuietStyle,
    },
  ],
});

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    paddingInlineEnd: {
      large: { paddingInlineEnd: '$half' },
      medium: { paddingInlineEnd: '$half' },
      small: { paddingInlineEnd: '$quarter' },
      toolbar: { paddingInlineEnd: '$quarter' },
      none: { paddingInlineEnd: '$none' },
    },
    paddingInlineStart: {
      large: { paddingInlineStart: '$half' },
      medium: { paddingInlineStart: '$half' },
      small: { paddingInlineStart: '$quarter' },
      toolbar: { paddingInlineStart: '$quarter' },
      none: { paddingInlineStart: '$none' },
    },
  },
});

const LabelWrapper = styled('span', {
  variants: {
    truncateLabel: {
      true: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  },
});

const iconSizes = {
  large: 24,
  medium: 20,
  small: 16,
  toolbar: 16,
};

function useIndicatorProps() {
  const stringsMap = useThemeStrings();

  return useMemo(
    () => ({
      size: {
        large: 16,
        medium: 14,
        small: 12,
        toolbar: 16,
      },
      icon: {
        newTabInternal: 'tinyNewWindowInternal',
        newWindowInternal: 'tinyNewWindowInternal',
        externalLink: 'tinyExternalLink',
      },
      label: {
        newTabInternal: stringsMap.newTabInternal,
        newWindowInternal: stringsMap.newWindowInternal,
        externalLink: stringsMap.externalLink,
      },
    }),
    [stringsMap],
  );
}

interface IconProps {
  wrapperSpacing: any;
  indicator: 'newTabInternal' | 'newWindowInternal' | 'externalLink';
  responsiveSize: GDSButtonSize;
}

export const ButtonIndicator = ({
  wrapperSpacing,
  indicator,
  responsiveSize,
}: IconProps) => {
  const indicatorProps = useIndicatorProps();
  return (
    <IconWrapper paddingInlineStart={wrapperSpacing}>
      <Icon
        icon={indicatorProps.icon[indicator]}
        size={indicatorProps.size[responsiveSize]}
        label={indicatorProps.label[indicator]}
      />
    </IconWrapper>
  );
};

export const Button: GDSButtonComponent = forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      as: asElement,
      children,
      icon,
      hideLabel,
      truncateLabel,
      size = 'large',
      priority = 'primary',
      width = 'hugContents',
      destructive,
      quiet = false,
      disabled = false,
      'aria-disabled': ariaDisabled,
      loading = false,
      loadingText,
      indicator,
      type,
      className,
      textAlign = 'start',
      ...props
    }: GDSButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    // Log errors when illegal combinations of props are passed:
    if (!icon && hideLabel)
      console.error(
        `Icon is ${icon} and hideLabel is ${hideLabel}. You can hide the label only if you provide an icon. Otherwise, the button appears to be empty.`,
      );

    if (priority === 'tertiary' && destructive)
      console.error(
        `Priority is ${priority} and destructive is ${destructive}. Only primary and secondary buttons can be destructive.`,
      );

    if (priority === 'primary' && quiet)
      console.error(
        `Priority is ${priority} and quiet is ${quiet}. Only secondary and tertiary buttons can be quiet.`,
      );

    if (indicator && hideLabel)
      console.error(
        `Indicator is ${indicator} and hideLabel is ${hideLabel}. Only buttons with visible text can have the indicator icon.`,
      );

    // responsive and calculated values
    const responsiveSize = useResponsiveProp(size);
    const responsiveHideLabel = useResponsiveProp(hideLabel);
    const spaceBetweenIconAndLabel = responsiveHideLabel
      ? 'none'
      : responsiveSize;
    const responsiveIconSize = mapResponsiveProp(size, iconSizes);
    const responsiveWidth = useResponsiveProp(width);
    const iconOnly = icon && responsiveHideLabel;
    const reducePadding = !icon && responsiveHideLabel;
    const ButtonComponent =
      responsiveSize === 'toolbar' ? ToolbarButton : StandardButton;

    // The component is a button by default, so if `as` is undefined, it's button
    const defaultType = !type && !asElement ? 'button' : undefined;

    return (
      <GenesisCoreInspector displayName="Button">
        <ButtonComponent
          as={asElement}
          className={['GDS-button', className].join(' ')}
          size={responsiveSize}
          width={responsiveWidth}
          priority={priority}
          destructive={destructive}
          quiet={quiet}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          loading={loading}
          ref={ref}
          iconOnly={iconOnly}
          reducePadding={reducePadding}
          type={type || defaultType}
          {...props}
        >
          {loading && (
            <IconWrapper paddingInlineEnd={spaceBetweenIconAndLabel}>
              <Spinner
                loading
                loadingText={loadingText}
                size={`${responsiveIconSize}px`}
              />
            </IconWrapper>
          )}
          {icon && !loading && (
            <IconWrapper paddingInlineEnd={spaceBetweenIconAndLabel}>
              <Icon icon={icon} size={responsiveIconSize} />
            </IconWrapper>
          )}
          {responsiveHideLabel ? (
            <VisuallyHidden>{children}</VisuallyHidden>
          ) : (
            <LabelWrapper truncateLabel={truncateLabel} css={{ textAlign }}>
              {children}
            </LabelWrapper>
          )}
          {indicator && !hideLabel && (
            <ButtonIndicator
              wrapperSpacing={spaceBetweenIconAndLabel}
              indicator={indicator}
              responsiveSize={responsiveSize}
            />
          )}
        </ButtonComponent>
      </GenesisCoreInspector>
    );
  },
);

// @ts-ignore
Button.displayName = 'Button';
