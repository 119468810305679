import { leagueFetch } from '@leagueplatform/league-fetch';

import { UserHealthActivitiesEndpoints } from './constants/user-health-activity.constants';

import {
  TOOLBOX_INTERACTION_HUB_DATA_TYPE,
  PersistInputsMutationArgs,
} from './types/post-question-component.types';

export const postToolboxInteractionHub = async ({
  userActivityId,
  userHealthCampaignId,
  components = [],
  skippedComponents,
  completedActivity = false,
}: PersistInputsMutationArgs) => {
  const response: Response = await leagueFetch(
    UserHealthActivitiesEndpoints.toolboxInteractionHub,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      body: JSON.stringify({
        data: {
          id: userActivityId,
          type: TOOLBOX_INTERACTION_HUB_DATA_TYPE,
          attributes: {
            userHealthCampaignId,
            completedActivity,
            skippedComponents,
            components,
          },
        },
      }),
    },
  );

  if (response.ok) {
    try {
      return await response.json();
    } catch {
      // TODO – ACTV-X – Remove null return when persisting api consistently returns a JSON object
      return null;
    }
  }
  throw new Error('Something went wrong while fetching!');
};
