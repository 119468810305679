import {
  ApiDelayConfig,
  DELAY_DISPLAY_STATES,
} from '../types/api-delay-controller.types';

export const DEFAULT_API_DELAY_CONFIG: ApiDelayConfig = Object.freeze({
  minDuration: 3500,
  maxDuration: 10000,
  delay: DELAY_DISPLAY_STATES.MEDIUM_DELAY,
});
