import React from 'react';
import {
  BodyTwo,
  Flex,
  HeadingTwo,
  Image,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ASSESSMENT_ASSET_KEYS } from '../../assessment.types';
import { PRODUCT_AREA, SCREEN_NAME } from '../../constants/analytics.constants';

export default function FrictionScreen() {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES_FRICTION_SCREEN,
  });

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      aria-live="polite"
    >
      <Image
        src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.HANDS_LOADING_ANIMATION)}
        alt=""
        width={140}
      />
      <HeadingTwo as="h1" marginTop="oneAndHalf">
        {formatMessage({ id: 'OUTCOMES_FRICTION_SCREEN_TITLE' })}
      </HeadingTwo>
      <BodyTwo marginTop="oneAndHalf">
        {formatMessage({ id: 'OUTCOMES_FRICTION_SCREEN_DESCRIPTION' })}
      </BodyTwo>
    </Flex>
  );
}
