import { useMutation, useQueryClient } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  submitBenefitSelection,
  SUBMIT_BENEFIT_SELECTION_MESSAGE_TYPE,
  GET_WALLET_MESSAGE_TYPE,
} from '@leagueplatform/wallet-api';
import { captureError, captureMessage } from '@leagueplatform/observability';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { usePlanIdQuery } from 'hooks/queries/use-plan-id-query.hook';

interface UseSubmitBenefitSelectionMutationProps {
  groupId: string;
  allocateImmediately?: boolean;
}

interface SubmitBenefitSelectionQueryResponse {
  validation_errors?: any[];
}

export const useSubmitBenefitSelectionMutation = ({
  groupId,
  allocateImmediately,
}: UseSubmitBenefitSelectionMutationProps) => {
  const queryClient = useQueryClient();
  const { userId } = useWalletParams();
  const planId = usePlanIdQuery(userId);

  return useMutation<SubmitBenefitSelectionQueryResponse>(
    [SUBMIT_BENEFIT_SELECTION_MESSAGE_TYPE, groupId],
    () => submitBenefitSelection(groupId, allocateImmediately, planId),
    {
      onSuccess: ({ validation_errors: validationErrors }) => {
        // You can receive a success response but the
        // response can be errors
        if (validationErrors && validationErrors.length > 0) {
          toastActions.add({
            type: TOAST_STATUS.ERROR,
            textId: 'ERROR_FINALIZING_SELECTIONS_INVALID_SELECTIONS',
          });
          captureMessage('Invalid selections', {
            severityLevel: 'error',
            context: {
              'validation errors': {
                ...validationErrors,
              },
            },
          });
        } else {
          queryClient.invalidateQueries([GET_WALLET_MESSAGE_TYPE, userId]);
          toastActions.add({
            type: TOAST_STATUS.SUCCESS,
            textId: 'SELECTIONS_FINALIZED_SUCCESS',
          });
        }
      },
      onError: (err) => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_FINALIZING_SELECTIONS',
        });
        captureError(err as Error);
      },
    },
  );
};
