import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { Box, BoxProps } from '../Box';

export interface ImageProps extends BoxProps {
  src: string;
  crossOrigin?: 'anonymous' | 'use-credentials' | '';
  decoding?: 'async' | 'auto' | 'sync';
  loading?: 'eager' | 'lazy';
  referrerPolicy?: 'no-referrer' | 'origin' | 'unsafe-url';
  sizes?: string;
  srcSet?: string;
  useMap?: string;
}

export interface HasAlt {
  alt: React.ReactNode;
}

export interface IsDecorative {
  role: 'presentation';
  alt?: '';
}

type ImageWithAltOrDecorativeProps = (IsDecorative | HasAlt) & ImageProps;

export const Image: React.FunctionComponent<ImageWithAltOrDecorativeProps> =
  forwardRef(
    (
      {
        alt,
        width,
        height,
        size,
        display = 'inline-block',
        role,
        ...props
      }: ImageWithAltOrDecorativeProps,
      ref
    ) => (
      <Box
        loading="lazy"
        as="img"
        alt={role === 'presentation' ? '' : alt}
        display={display}
        css={css({
          width: width || size || (height && 'auto') || '100%',
          height: height || size || 'auto',
        })}
        ref={ref}
        {...props}
      />
    )
  );

Image.displayName = 'Image';
