import React, { createElement } from 'react';

import {
  GDSStackLayoutHorizontalAlignment,
  GDSStyleObject,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { IconTextProps, IconText } from '@leagueplatform/web-common-components';

const ICON_SIZE = 54;

interface ProgramBannerProps {
  basicText: string | React.ReactNode;
  boldedText?: string | IconTextProps;
  icon?: string;
  css?: GDSStyleObject;
  horizontalAlignment?: GDSStackLayoutHorizontalAlignment;
}

export const ProgramBanner = ({
  boldedText,
  basicText,
  icon,
  css,
  horizontalAlignment = 'start',
}: ProgramBannerProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    horizontalAlignment={horizontalAlignment}
    css={css}
  >
    <StackLayout spacing="$half" horizontalAlignment={horizontalAlignment}>
      {boldedText &&
        (typeof boldedText === 'string' ? (
          <UtilityText emphasis="emphasized">{boldedText}</UtilityText>
        ) : (
          createElement(IconText, boldedText)
        ))}
      {typeof basicText === 'string' ? (
        <UtilityText css={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
          {basicText}
        </UtilityText>
      ) : (
        basicText
      )}
    </StackLayout>
    {icon && <Image src={icon} alt="" size={ICON_SIZE} />}
  </StackLayout>
);
