import { Image } from '@leagueplatform/genesis-commons';
import {
  Button,
  StackLayout,
  Box,
  HeadingText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import * as React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import { useIsSelfReportedDataEnabled } from 'hooks/use-is-self-reported-data-enabled.hook';
import { AddEditDataModal } from 'components/add-edit-data/add-edit-data-modal';
import { InputConfigValue } from '@leagueplatform/dashboard-api';
import { AddMetricDataButton } from './add-metric-data-button';

type NoDataProps = {
  metric: string;
  canSelfRecordData: boolean;
  canConnectDevice: boolean;
  inputConfig: InputConfigValue;
  metricConfigId: string;
};

export const NoDataAvailable = ({
  metric,
  canSelfRecordData,
  canConnectDevice,
  inputConfig,
  metricConfigId,
}: NoDataProps) => {
  const { formatMessage } = useIntl();
  const { isSelfReportedDataEnabled } = useIsSelfReportedDataEnabled();
  const { dataType } = useParams<{ dataType: string }>();
  const connectedDeviceImage = handleStaticAsset(
    DASHBOARD_ASSET_MAP.DASHBOARD_NO_HEALTH_METRIC_DATA,
  ) as string;

  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    React.useState(false);

  const showAddDataButton = canSelfRecordData && isSelfReportedDataEnabled;

  return (
    <Box
      css={{
        marginTop: '$twoAndHalf',
        marginBottom: '$five',
      }}
    >
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        spacing="$one"
        css={{
          paddingTop: '$two',
          paddingBottom: '$two',
          backgroundColor: '$surfaceBackgroundTertiary',
          borderColor: '$onSurfaceBorderSubdued',
          borderWidth: '$thin',
          borderRadius: '$small',
          borderStyle: 'solid',
        }}
      >
        <Box>
          <Image width={215} src={connectedDeviceImage} alt="altTxt" />
        </Box>
        <StackLayout verticalAlignment="center" horizontalAlignment="center">
          <HeadingText
            size="md"
            level="2"
            css={{ typography: '$subtitleOne', marginBottom: '$quarter' }}
          >
            {formatMessage({
              id: 'NO_DATA_AVAILABLE',
            })}
          </HeadingText>

          <UtilityText as="p" css={{ marginBottom: '$oneAndHalf' }}>
            {formatMessage(
              { id: 'CURRENTLY_NOT_SHARING_DATA' },
              {
                metric,
              },
            )}
          </UtilityText>
          <StackLayout css={{ maxWidth: 295, width: '100%' }} spacing="$half">
            {showAddDataButton ? (
              <AddEditDataModal
                metricConfigId={metricConfigId}
                inputConfig={inputConfig}
                modalTrigger={<AddMetricDataButton />}
              />
            ) : null}
            {canConnectDevice ? (
              <Button
                width="fillContainer"
                priority="secondary"
                onClick={() => {
                  setDisplayUnavailableOnWebModal(true);
                  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                    product_area: PRODUCT_AREA.DASHBOARD,
                    screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
                    metric_type: dataType,
                    detail: 'connect a device',
                  });
                }}
              >
                {formatMessage({ id: 'CONNECT_A_DEVICE' })}
              </Button>
            ) : null}
          </StackLayout>
        </StackLayout>
      </StackLayout>
      {displayUnavailableOnWebModal ? (
        <UnavailableDeviceConnectModal
          onClose={() => setDisplayUnavailableOnWebModal(false)}
        />
      ) : null}
    </Box>
  );
};
