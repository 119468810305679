import React, { useState } from 'react';
import {
  HeadingText,
  Modal,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Badge } from '@leagueplatform/web-common-components';
import { EffectiveTime, InputConfigValue } from '@leagueplatform/dashboard-api';
import { MetricFormPost } from 'components/metric-form/metric-form-post';
import { MetricFormPatch } from 'components/metric-form/ metric-form-patch';
import { isNil } from 'lodash';
import { InformationBanner } from 'components/information-banner/information-banner';
import { ParseValueForInput } from 'utils/parse-value-for-input.util';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';

type AddEditDataModalProps = {
  modalTrigger: React.ReactNode;
  inputConfig: InputConfigValue;
  metricConfigId?: string;
  observationId?: string;
  value?: number | string | null;
  label?: string;
  timestamp?: string;
  effectiveDateTime?: EffectiveTime;
  onAddData?: (date: Date) => void;
};

const MODAL_WIDTH = '476px';
const MODAL_PROPORTIONAL_HEIGHT = '77vh';

export function AddEditDataModal({
  modalTrigger,
  inputConfig,
  metricConfigId,
  observationId,
  value,
  label,
  timestamp,
  effectiveDateTime,
  onAddData,
}: AddEditDataModalProps) {
  const {
    badge,
    heading,
    description,
    healthMetricInputs,
    showEffectiveDateTimePicker,
    informationBanner,
  } = inputConfig;

  const [modalOpen, setModalOpen] = useState(false);

  const showPatchForm =
    healthMetricInputs && metricConfigId && observationId && !isNil(value);

  React.useEffect(() => {
    if (modalOpen) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.DASHBOARD,
        screen_name: SCREEN_NAMES.ADD_METRIC_DATA,
        metric_type: metricConfigId,
      });
    }
  }, [metricConfigId, modalOpen]);

  return (
    <Modal.Root
      open={modalOpen}
      onOpenChange={() => {
        setModalOpen((previous) => !previous);
      }}
    >
      <Modal.Trigger>{modalTrigger}</Modal.Trigger>
      <Modal.Content
        padding="$twoAndHalf $two"
        width={{ '@initial': MODAL_WIDTH, '@mobile': '100vw' }}
        css={{
          '.GDS-modal': {
            height: MODAL_PROPORTIONAL_HEIGHT,
            overflow: 'visible',
            '@mobile': {
              height: '100vh',
            },
          },
          '.GDS-modal-close-button': {
            color: '$onSurfaceIconPrimary',
            position: 'absolute',
            top: '$none',
            left: 'calc(100% + 15vw)',
            '@mobile': {
              left: 'unset',
              top: '$half',
            },
            '@mobileLandscape': {
              left: 'calc(100% + 5vw)',
            },
            '@tablet': {
              left: 'calc(100% + 10vw)',
            },
          },
          '.GDS-modal-overlay': {
            backgroundColor: '$interactiveBackgroundHovered',
          },
        }}
      >
        <StackLayout
          horizontalAlignment="stretch"
          spacing="$one"
          css={{
            maxHeight: `calc(${MODAL_PROPORTIONAL_HEIGHT} - $five)`,
            overflow: 'auto',
          }}
        >
          <StackLayout
            horizontalAlignment="center"
            css={{ paddingBottom: '$two' }}
            spacing="$one"
          >
            <Badge
              css={{
                backgroundColor: '$surfaceBackgroundSecondary',
                borderRadius: '$small',
                color: '$onSurfaceTextSubdued',
              }}
            >
              {badge}
            </Badge>
            <Modal.Title>
              <HeadingText level="2" size="xl" css={{ textAlign: 'center' }}>
                {heading}
              </HeadingText>
            </Modal.Title>
            {description && (
              <ParagraphText css={{ textAlign: 'center' }}>
                {description}
              </ParagraphText>
            )}
          </StackLayout>
          <StackLayout horizontalAlignment="stretch" spacing="$two">
            {healthMetricInputs && metricConfigId && !observationId && (
              <MetricFormPost
                metricConfigId={metricConfigId}
                onSuccessSubmit={(date: Date) => {
                  setModalOpen(false);
                  if (onAddData) {
                    onAddData(date);
                  }
                }}
                showDateTimePicker={showEffectiveDateTimePicker}
                metricInputs={healthMetricInputs}
              />
            )}
            {showPatchForm && (
              <MetricFormPatch
                metricConfigId={metricConfigId}
                observationId={observationId}
                onSuccessSubmit={() => setModalOpen(false)}
                metricInput={healthMetricInputs[0]}
                value={ParseValueForInput(value, label)}
                timeStamp={timestamp}
                effectiveDateTime={effectiveDateTime}
                showDateTimePicker={showEffectiveDateTimePicker}
              />
            )}
            {informationBanner && !!informationBanner.body && (
              <InformationBanner
                body={informationBanner.body}
                heading={informationBanner.heading}
                iconURL={informationBanner.icon}
              />
            )}
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
}
