import React from 'react';
import {
  Box,
  Button,
  Card,
  HeadingText,
  ParagraphText,
  queryHelpers,
  StackLayout,
  useMediaQuery,
  useThemeStrings,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNodeAction,
  useMasonryEngineActionEmitter,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { InternalOrExternalLink } from '@leagueplatform/web-common-components';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import {
  MasonryDeeplinkAction,
  MasonryDismissAction,
} from 'types/masonry-driver-node-actions';
import { getButtonIconToken, Indicator } from 'utils/icon-mapping';

type MasonryCTACardProperties = {
  body: string;
  buttonLabel: string;
  buttonIndicator?: Indicator;
  eyebrow?: string;
  heading: string;
  image?: string;
  imageAltText?: string;
  isChildItem?: Boolean;
  showMobile?: Boolean;
};

export type MasonryCTACardNodeActions = {
  onButtonClick: MasonryEngineNodeAction;
  onDismiss?: MasonryDismissAction;
};

export type MasonryCTACardNode = MasonryEngineNode<
  'ctaCard',
  MasonryCTACardProperties,
  MasonryCTACardNodeActions
>;

export type MasonryCTACardRendererProps =
  MasonryEngineNodeRendererProps<MasonryCTACardNode>;

export const MasonryCTACardRenderer = ({
  body,
  buttonIndicator,
  buttonLabel,
  eyebrow,
  heading,
  image,
  imageAltText = '',
  isChildItem = false,
  onButtonClick,
  onDismiss,
  showMobile = false,
  rootElementRef,
}: MasonryCTACardRendererProps) => {
  const dismissible = Boolean(onDismiss);
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const showSmallerCard = isMobile || isChildItem || showMobile;

  const strings = useThemeStrings();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Elevated
      css={{
        backgroundColor: '$surfaceBackgroundTertiary',
        padding: '$oneAndHalf',
      }}
      ref={rootElementRef}
    >
      {dismissible && (
        <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            hideLabel
            size="small"
            icon="interfaceClose"
            priority="tertiary"
            quiet
            onClick={(event: React.MouseEvent) => {
              if (onDismiss) {
                invokeAction({
                  event,
                  ...onDismiss,
                });
              }
            }}
          >
            {strings.dismiss}
          </Button>
        </Box>
      )}
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing={{
          '@mobile': '$threeQuarters',
          '@initial': showSmallerCard ? '$threeQuarters' : '$oneAndHalf',
        }}
      >
        <StackLayout orientation="vertical" spacing="$quarter">
          {eyebrow && <UtilityText size="eyebrow">{eyebrow}</UtilityText>}
          <HeadingText level="3" size="sm">
            {heading}
          </HeadingText>
          <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
            {body}
          </ParagraphText>

          {!showSmallerCard && (
            <Button
              indicator={
                buttonIndicator
                  ? getButtonIconToken(buttonIndicator)
                  : undefined
              }
              css={{ padding: '$half $two', marginTop: '$one' }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...((onButtonClick as MasonryDeeplinkAction)?.payload.url && {
                as: InternalOrExternalLink,
                disableDefaultOnClick: true,
              })}
              href={(onButtonClick as MasonryDeeplinkAction)?.payload.url}
              onClick={(event: React.MouseEvent) => {
                if (onButtonClick) {
                  invokeAction({
                    event,
                    ...onButtonClick,
                  });
                }
              }}
            >
              {buttonLabel}
            </Button>
          )}
        </StackLayout>
        {image && (
          <SquareImageContainer
            src={image}
            alt={imageAltText}
            maxWidth="8rem"
            maxWidthDesktop={isChildItem ? '8rem' : '11.2rem'}
          />
        )}
      </StackLayout>
      {showSmallerCard && (
        <Button
          width="fillContainer"
          indicator={
            buttonIndicator ? getButtonIconToken(buttonIndicator) : undefined
          }
          css={{ padding: '$half $two', marginTop: '$one' }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...((onButtonClick as MasonryDeeplinkAction)?.payload.url && {
            as: InternalOrExternalLink,
            disableDefaultOnClick: true,
          })}
          href={(onButtonClick as MasonryDeeplinkAction)?.payload.url}
          onClick={(event: React.MouseEvent) => {
            if (onButtonClick) {
              invokeAction({
                event,
                ...onButtonClick,
              });
            }
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </Card.Elevated>
  );
};
