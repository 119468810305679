import React from 'react';

export const CircularArrow = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="circularArrowIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83279 2.23467C11.9635 2.21338 13.9415 3.33788 15.013 5.17963C16.0846 7.02139 16.0846 9.2967 15.013 11.1385C13.9415 12.9802 11.9635 14.1047 9.83279 14.0834V14.0834L9.82562 14.0834C8.20729 14.0827 6.65711 13.4318 5.52336 12.277C4.73946 11.4785 4.20011 10.4854 3.95151 9.41372L5.00911 10.103C5.35612 10.3292 5.82078 10.2312 6.04696 9.88423C6.27313 9.53722 6.17517 9.07256 5.82815 8.84639L3.4658 7.30668C3.40522 7.26577 3.3383 7.23346 3.26677 7.21154C3.17807 7.18427 3.08627 7.1742 2.99602 7.18038C2.888 7.18756 2.78607 7.21769 2.69517 7.266C2.60423 7.31413 2.52214 7.38157 2.45561 7.46691C2.43981 7.4871 2.42502 7.50812 2.41132 7.5299L0.871722 9.89106C0.64548 10.238 0.743349 10.7027 1.09032 10.929C1.43729 11.1552 1.90197 11.0573 2.12821 10.7104L2.56364 10.0426C2.90013 11.2696 3.54525 12.4032 4.45298 13.3278C5.86778 14.7689 7.80196 15.5815 9.82137 15.5834C12.4901 15.6088 14.9673 14.1998 16.3095 11.8928C17.6524 9.58472 17.6524 6.73336 16.3095 4.42531C14.9667 2.11726 12.4879 0.708061 9.8178 0.734747C9.40361 0.738887 9.0712 1.07801 9.07533 1.49221C9.07947 1.9064 9.4186 2.23881 9.83279 2.23467Z"
      fill="currentColor"
    />
  </svg>
);
