import { useQueryClient, useMutation } from 'react-query';
import { useHistory, useLocation } from '@leagueplatform/routing';
import {
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
  GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
  GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
  startHealthGoalProgram,
} from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  JOURNEY_ROUTES,
  useApiDelayController,
} from '@leagueplatform/health-journey-common';

export const useStartHealthProgram = (
  programId,
  isUserDeviceConnectableProgram,
) => {
  const queryClient = useQueryClient();
  const apiDelayController = useApiDelayController();
  const history = useHistory();
  const location = useLocation();

  const { activityId: redirectActivityId, activityType: redirectActivityType } =
    location?.state ?? {};

  return useMutation(
    (campaignMode) =>
      startHealthGoalProgram(
        programId,
        isUserDeviceConnectableProgram,
        campaignMode,
      ),
    {
      onMutate: () => {
        apiDelayController.init();
      },
      onSuccess: async (data) => {
        await apiDelayController.finish();
        const isMaxProgramLimitReached = data?.content_for_users_state;
        if (!isMaxProgramLimitReached) {
          toastActions.add({
            type: TOAST_STATUS.SUCCESS,
            textId: 'PROGRAM_ADDED',
            contentId: 'THE_PROGRAM_WILL_APPEAR_IN_JOURNEY',
          });
          // check if assigned activity id exists and if it does redirect user
          if (redirectActivityId) {
            history.push(
              JOURNEY_ROUTES.getActivityDetails(
                redirectActivityId,
                redirectActivityType,
              ),
            );
          } else {
            history.push(JOURNEY_ROUTES.journeyHome());
          }
        }
        return data;
      },
      onError: async () => {
        await apiDelayController.finish();
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_STATE_TITLE',
          contentId: 'PROGRAM_ADD_ERROR',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          GET_USER_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
        );
        queryClient.invalidateQueries(GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE);
        queryClient.invalidateQueries(
          GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
        );
      },
    },
  );
};
