import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

export const MESSAGING_LINKS = {
  getStartNewMessage: (searchParam = '') =>
    `${getModulePath(LEAGUE_MODULE_NAMES.messaging)}/thread${searchParam}`,
  getMessagingHome: (searchParam = '') =>
    `${getModulePath(LEAGUE_MODULE_NAMES.messaging)}/threads${searchParam}`,
  getMessagesForThread: (threadId: string, searchParam = '') =>
    `${getModulePath(
      LEAGUE_MODULE_NAMES.messaging,
    )}/threads/${threadId}${searchParam}`,
};

export const HOME_SEARCH_PARAM = '?h=1';
