import {
  HealthMetricValue,
  HealthMetricValueV3,
} from '@leagueplatform/dashboard-api';
import {
  NUMERIC_YEAR_MONTH_DAY,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { compareAsc } from 'date-fns';
import { groupByKey } from './group-by-key';

export type HealthMetricData = ReturnType<typeof useHealthMetricValues>;
export type HealthMetricDataV3 = ReturnType<typeof useHealthMetricValuesV3>;

export function useHealthMetricValues(healthMetrics: HealthMetricValue[]) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  // Group health metrics by date.
  const groupedByDate = groupByKey(
    (item) =>
      formatDateWithUserProfileLocation(item.timestamp, {
        ...NUMERIC_YEAR_MONTH_DAY,
      }),
    healthMetrics,
  );

  const consolidatedHealthMetrics = Object.entries(groupedByDate)
    .sort(([a], [b]) => compareAsc(new Date(a), new Date(b)))
    .map(([, healthMetricsByDate]) => {
      // Group health metrics within each date by metric type.
      const groupedByMetricType = groupByKey(
        (item) => item.config.data.id,
        healthMetricsByDate,
      );

      // Iterate over each health metric within a day.
      const healthMetricsMinMax = Object.entries(groupedByMetricType).map(
        ([type, healthMetricsByType]) => {
          let value: number | [number, number] | null = null;
          let label:
            | string
            | [string | undefined, string | undefined]
            | undefined;
          const values = healthMetricsByType
            .map((m) => m.value)
            .filter((v): v is number => v !== null);

          if (values.length > 1) {
            const min = Math.min(...values);
            const max = Math.max(...values);

            const minMetric = healthMetricsByType.find((m) => m.value === min)!;
            const maxMetric = healthMetricsByType.find((m) => m.value === max)!;

            value = [min, max];
            label = [minMetric.label, maxMetric.label];
          } else if (values.length === 1) {
            const metric = healthMetricsByType.find(
              (m) => m.value === values[0],
            )!;
            // eslint-disable-next-line prefer-destructuring
            value = values[0];
            label = metric.label;
          }

          return {
            type,
            timestamp: healthMetricsByType[0].timestamp,
            value,
            label,
            metric: healthMetricsByType[0].config,
          };
        },
      );
      return healthMetricsMinMax.sort(
        (a, b) => a.metric.data.order - b.metric.data.order,
      );
    })
    .flat();
  return consolidatedHealthMetrics;
}

export function useHealthMetricValuesV3(healthMetrics: HealthMetricValueV3[]) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  // Group health metrics by date.
  const groupedByDate = groupByKey(
    (item) =>
      formatDateWithUserProfileLocation(item.timestamp, {
        ...NUMERIC_YEAR_MONTH_DAY,
      }),
    healthMetrics,
  );

  const consolidatedHealthMetrics = Object.entries(groupedByDate)
    .sort(([a], [b]) => compareAsc(new Date(a), new Date(b)))
    .map(([, healthMetricsByDate]) => {
      // Group health metrics within each date by metric type.
      const groupedByMetricType = groupByKey(
        (item) => item.metricComponent.data.name,
        healthMetricsByDate,
      );

      // Iterate over each health metric within a day.
      const healthMetricsMinMax = Object.entries(groupedByMetricType).map(
        ([type, healthMetricsByType]) => {
          let value: number | [number, number] | null = null;
          let label:
            | string
            | [string | undefined, string | undefined]
            | undefined;
          const values = healthMetricsByType
            .map((m) => m.value)
            .filter((v): v is number => v !== null);

          if (values.length > 1) {
            const min = Math.min(...values);
            const max = Math.max(...values);

            const minMetric = healthMetricsByType.find((m) => m.value === min)!;
            const maxMetric = healthMetricsByType.find((m) => m.value === max)!;

            value = [min, max];
            label = [minMetric.label, maxMetric.label];
          } else if (values.length === 1) {
            const metric = healthMetricsByType.find(
              (m) => m.value === values[0],
            )!;
            // eslint-disable-next-line prefer-destructuring
            value = values[0];
            label = metric.label;
          }

          return {
            type,
            timestamp: healthMetricsByType[0].timestamp,
            value,
            label,
            metric: healthMetricsByType[0].metricComponent,
          };
        },
      );
      return healthMetricsMinMax.sort(
        (a, b) => a.metric.data.order - b.metric.data.order,
      );
    })
    .flat();
  return consolidatedHealthMetrics;
}
