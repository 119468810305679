import { useIntl } from '@leagueplatform/locales';
import { CLAIM_STATUS_TRANSLATIONS } from '@leagueplatform/web-common';

export const useClaimStatusMessageTranslation = () =>
  useIntl().$tmap<keyof typeof CLAIM_STATUS_TRANSLATIONS>({
    started: 'STARTED',
    submitted_for_preapproval: 'SUBMITTED_FOR_PREAPPROVAL',
    preapproval_more_information_needed: 'PREAPPROVAL_MORE_INFORMATION_NEEDED',
    preapproved: 'PREAPPROVED',
    preapproval_rejected: 'PREAPPROVAL_REJECTED',
    more_information_needed: 'MORE_INFORMATION_NEEDED',
    pending_external_review: 'PENDING_EXTERNAL_REVIEW',
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    cancelled: 'CANCELLED',
    paid: 'PAID',
  });
