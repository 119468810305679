import { useMemo, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Dependent,
  UserInsuranceBenefitCardDetails,
} from '@leagueplatform/wallet-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';

const PRIMARY_USER = 'primary';
const DEPENDENT_USER = 'dependent';

const formatDependentOptions = (dependents: Dependent[]) =>
  dependents
    ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
    ?.map((dependent) => ({
      label: `${dependent.first_name} ${dependent.last_name}`,
      value: dependent.dependent_id,
    }));

const formatCardDetailsArray = (
  memberIdLabel: string,
  groupIdLabel: string,
  insurance: UserInsuranceBenefitCardDetails,
) =>
  [
    {
      key: memberIdLabel,
      value: insurance?.member_policy_number,
    },
    {
      key: groupIdLabel,
      value: insurance?.provider_policy_number,
    },
    {
      key: 'RxBIN',
      value: insurance?.rx_bin,
    },
    {
      key: 'RxPCN',
      value: insurance?.rx_pcn,
    },
    {
      key: 'RxGROUP',
      value: insurance?.rx_group,
    },
    {
      key: 'RxID',
      value: insurance?.rx_id,
    },
    {
      key: 'RxCarrier',
      value: insurance?.rx_carrier,
    },
  ].filter((cardDetail) => cardDetail.value);

export const useMemberCard = () => {
  const { userId, benefitId } = useWalletParams();
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  const { data: isDropdownEnabled } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_DEPENDENTS_DROPDOWN_ENABLED,
  );
  const primaryUserId = userId ?? PRIMARY_USER;
  const { formatMessage } = useIntl();
  const [member] = useSelectedMember();
  const selectedUserId = member?.id;
  const { data: userData, isLoading: isUserLoading } = useWalletDetailQuery(
    userId,
    benefitId,
  );

  const {
    data: dependentData,
    isLoading: isDependentLoading,
    isIdle: isDependentIdle,
  } = useWalletDetailQuery(userId, benefitId, selectedUserId, {
    enabled: !!selectedUserId && selectedUserId !== primaryUserId,
  });

  const dependentInsurance = dependentData?.insurance;
  const dependentPolicyInfo = dependentData?.policy_info;

  const {
    insurance,
    dependents,
    member_first_name: memberFirstName,
    member_last_name: memberLastName,
    policy_info: policyInfo,
  } = userData || {};

  let currentInsurance = insurance;

  if (selectedUserId && selectedUserId !== primaryUserId) {
    currentInsurance = dependentInsurance;
  }

  const dependentOptions = useMemo(
    () => formatDependentOptions(dependents || []),
    [dependents],
  );
  const memberOptions = useMemo(
    () => [
      { label: `${memberFirstName} ${memberLastName}`, value: primaryUserId },
      ...dependentOptions,
    ],
    [dependentOptions, memberFirstName, memberLastName, primaryUserId],
  );

  const cardDetails = useMemo(() => {
    if (policyInfo) {
      return dependentPolicyInfo || policyInfo;
    }

    return formatCardDetailsArray(
      formatMessage({ id: 'MEMBER_ID' }),
      formatMessage({ id: 'GROUP_ID' }),
      currentInsurance || {},
    );
  }, [currentInsurance, dependentPolicyInfo, formatMessage, policyInfo]);

  const onMemberChange = useCallback(
    (value: string) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `select ${
          value !== primaryUserId ? DEPENDENT_USER : PRIMARY_USER
        }`,
      });
    },
    [primaryUserId, sendBenefitAnalyticsEvent],
  );
  const showMemberSelect = !!dependents?.length && !!isDropdownEnabled;
  // isIdle only happens if a query is initialized with enabled: false and no initial data is available.
  const isLoading = isDependentIdle ? isUserLoading : isDependentLoading;

  return {
    memberOptions,
    onMemberChange,
    isLoading,
    cardDetails,
    showMemberSelect,
  };
};
