import React, { forwardRef, HTMLAttributes, ReactElement } from 'react';
import { styled } from '../../theme';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../../types';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export type GDSColumnWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type GDSColumnOffset = 0 | GDSColumnWidth;
export type GDSColumnOrder = GDSColumnWidth;

export type GDSColumnProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      width: GDSResponsiveProp<GDSColumnWidth>;
      offset?: GDSResponsiveProp<GDSColumnOffset>;
      order?: GDSResponsiveProp<GDSColumnOrder>;
      index?: number;
      as?: React.ElementType;
      children?: React.ReactNode;
    }
  > &
    GDSCustomizableComponent &
    HTMLAttributes<HTMLElement>;

const ColumnBase = styled('div', {
  alignSelf: 'stretch',
});

export type GDSColumnComponent = <C extends React.ElementType = 'div'>(
  props: GDSColumnProps<C>,
) => React.ReactElement | null;

export const isColumn = (
  column?: ReactElement<any>,
): column is ReactElement<any> => {
  const columnProps = column?.props as unknown as GDSColumnProps<any>;
  return columnProps?.width !== undefined;
};

export const Column: GDSColumnComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      width: responsiveWidth,
      offset: responsiveOffset,
      order: responsiveOrder,
      as: asElement,
      index,
      css,
      className,
      children,
      startColumn,
      ...props
    }: GDSColumnProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const width = useResponsiveProp(responsiveWidth);
    const offset = useResponsiveProp(responsiveOffset);
    const order = useResponsiveProp(responsiveOrder);
    const gridColumnStart = offset && offset > 0 ? startColumn ?? 0 : 'auto';
    const gridColumnEnd = `span ${width}`;

    return (
      <GenesisCoreInspector displayName="Column">
        <ColumnBase
          ref={ref}
          className={['GDS-column', className].join(' ')}
          css={{
            gridColumnStart,
            gridColumnEnd,
            order,
            ...css,
          }}
          {...props}
        >
          {children}
        </ColumnBase>
      </GenesisCoreInspector>
    );
  },
);

// @ts-ignore
Column.displayName = 'Column';
