import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Auth } from '@leagueplatform/auth';
import { ErrorContext } from '@leagueplatform/observability';
import { getObservabilityContext } from 'utils/get-observability-context';
import { getWidgetContainer } from 'utils/get-widget-container';
import { ContainerData } from '../types/container-data';
import { ContainerDataV2 } from '../types/container-data-v2';
import { ApiParams } from '../types/api-params';

export const GET_WIDGET_CONTAINER = 'get_widget_container';

export const useGetWidgetContainer = (
  appId: string,
  version: number,
  apiParams?: ApiParams,
  errorContext: ErrorContext = {}, // Placed error context for capability teams to extend errorContext
) => {
  const queryClient = useQueryClient();

  // Invalidating query when the hook is unmounted
  useEffect(
    () => () => {
      setTimeout(() => {
        /**
         * Before even trying to refetch this container's query on unmount, we wait
         * a bit. This is because one reason this widget may unmount in the first place
         * is that the user's auth session has ended (due to logging out or inactivity),
         * in which case we don't want to try refetching without a valid token.
         *
         * Waiting ensures that the `isAuthorized` function call gives us the right answer
         * free of race conditions.
         */
        Auth.isAuthorized().then((isAuthorized) => {
          if (isAuthorized) {
            queryClient.refetchQueries([GET_WIDGET_CONTAINER, appId, version]);
          }
        });
      }, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // @TODO - remove ContainerData and rename ContainerDataV2 to ContainerData when deprecating v0 and 1
  return useQuery<ContainerData | ContainerDataV2, Error>(
    [GET_WIDGET_CONTAINER, appId, version],
    () =>
      getWidgetContainer(
        appId,
        version,
        apiParams,
        getObservabilityContext({
          ...errorContext,
          errorName: 'Masonry - get widget container api error',
          tags: {
            container: appId,
            apiVersion: version,
            ...(errorContext?.tags || {}),
          },
        }),
      ),
  );
};
