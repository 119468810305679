import { ErrorContext } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const SET_WEARABLES_CONSENT_MESSAGE_TYPE = 'set_wearables_consent';

export const setWearablesConsent = async (
  errorContext: ErrorContext,
): Promise<{ updated: boolean }> =>
  SocketAsFetch.fetch(
    {
      message_type: SET_WEARABLES_CONSENT_MESSAGE_TYPE,
    },
    { errorContext },
  );
