import {
  ServerConfig,
  LeagueConfig,
  KnownLeagueConfig,
} from './league-config-schema';

/**
 * The status of the config store, with respect to the various states its `config`
 * and `serverConfig` properties can be in.
 */
export enum ConfigStoreStatus {
  /**
   * Initial state. No client config has been set yet, and by extension, no server
   * config can be fetched yet.
   */
  Empty,
  /**
   * Client config has been set, but we have not tried to fetch server config yet.
   */
  ClientOnly,
  /**
   * We are currently attempting to fetch server config, and we DO NOT HAVE cache fallback.
   */
  Loading,
  /**
   * We are currently attempting to fetch server config,and we DO HAVE cache fallback.
   */
  LoadingWithCache,
  /**
   * We have successfully fetched fresh server config.
   */
  Success,
  /**
   * We failed to fetch fresh server config, but we DO HAVE cache fallback.
   */
  ErrorWithCache,
  /**
   * We failed to fetch server config, and we DO NOT HAVE cache fallback.
   */
  Error,
}

export type ConfigStoreStatusNonEmpty = Exclude<
  ConfigStoreStatus,
  ConfigStoreStatus.Empty
>;

/**
 * All store statuses that have a server config property
 * set.
 */
export type ConfigStoreStatusSettled =
  | ConfigStoreStatus.Success
  | ConfigStoreStatus.ErrorWithCache
  | ConfigStoreStatus.LoadingWithCache;

/**
 * An object with two properties:
 * - `status` - a `ConfigStoreStatus`.
 * - `config` - a merged config of the specific type expected given the status.
 */
export type MergedConfigWithStatus =
  // The store is empty. we have nothing to give.
  | { status: ConfigStoreStatus.Empty; config: undefined }
  // The store has client config set, but no server config.
  | {
      status:
        | ConfigStoreStatus.ClientOnly
        | ConfigStoreStatus.Error
        | ConfigStoreStatus.Loading;
      config: LeagueConfig;
    } // The store definitely has both server and client config.
  | {
      status: ConfigStoreStatusSettled;
      config: LeagueCombinedConfig;
    };

export type MergedConfigWithStatusFromStatus<Status extends ConfigStoreStatus> =
  Extract<MergedConfigWithStatus, Record<'status', Status>>;

/**
 * The shape of the config store. Includes a `config` property holding
 * a {@link LeagueConfig `LeagueConfig`} object (or `undefined` if never set),
 * a `serverConfig` property holding a {@link ServerConfig `ServerConfig`}
 * object (or `undefined` if never set), and a `status` property holding
 * a {@link ConfigStoreStatus `ConfigStoreStatus`}.
 */
export type ConfigStoreState =
  | {
      config: undefined;
      serverConfig: undefined;
      status: ConfigStoreStatus.Empty;
    }
  | {
      config: LeagueConfig;
      serverConfig: undefined;
      status: ConfigStoreStatus.ClientOnly;
    }
  | {
      config: LeagueConfig;
      serverConfig: undefined;
      status: ConfigStoreStatus.Loading;
    }
  | {
      config: LeagueConfig;
      serverConfig: ServerConfig;
      status: ConfigStoreStatus.LoadingWithCache;
    }
  | {
      config: LeagueConfig;
      serverConfig: ServerConfig;
      status: ConfigStoreStatus.Success;
    }
  | {
      config: LeagueConfig;
      serverConfig: ServerConfig;
      status: ConfigStoreStatus.ErrorWithCache;
    }
  | {
      config: LeagueConfig;
      serverConfig: undefined;
      status: ConfigStoreStatus.Error;
    };

/**
 * Utility type for generating a narrowed {@link ConfigStoreState `ConfigStoreState`} based
 * on the `status` property.
 */
export type ConfigStoreStateFromStatus<Status extends ConfigStoreStatus> =
  Extract<ConfigStoreState, Record<'status', Status>>;

/**
 * Return of the useConfigProperty hook when the requested property
 * is a server config property. Includes the data itself as well as various status-
 * indicating booleans, loosely in the style of `useQuery`.
 */
export type ServerConfigSelectionResult<
  Selection = unknown,
  WithDefaultValue extends boolean = false,
> =
  /**
   * We are trying to fetch. We did not have cache.
   */
  | {
      data: WithDefaultValue extends true ? Selection : undefined;
      isPresent: false;
      isLoading: true;
      isError: false;
      isFresh: false;
    }
  /**
   * We are trying to fetch. We had cache.
   */
  | {
      data: Selection;
      isPresent: true;
      isLoading: true;
      isError: false;
      isFresh: false;
    }

  /**
   * We fetched successfully.
   */
  | {
      data: Selection;
      isPresent: true;
      isLoading: false;
      isError: false;
      isFresh: true;
    }
  /**
   * We failed to fetch, but we had cache.
   */
  | {
      data: Selection;
      isPresent: true;
      isLoading: false;
      isError: false;
      isFresh: false;
    }
  /**
   * We failed to fetch, and we had no cache.
   */
  | {
      data: WithDefaultValue extends true ? Selection : undefined;
      isPresent: false;
      isLoading: false;
      isError: true;
      isFresh: false;
    };

export type ConfigProducerRecipe = (
  draft: LeagueConfig,
) => KnownLeagueConfig | void;

/**
 * The shape of a fully settled config (both client and server).
 */
export type LeagueCombinedConfig = ServerConfig & LeagueConfig;
