import * as React from 'react';
import {
  StackLayout,
  StackItem,
  Responsive,
} from '@leagueplatform/genesis-core';

import { BackButtonArrow } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import {
  PROGRAM_BACK_BUTTON_WIDTH,
  PROGRAM_CONTENT_WIDTH,
  PROGRAM_SIDEBAR_HEIGHT,
  PROGRAM_SIDEBAR_WIDTH,
} from '../../constants/program.constants';

export interface ProgramLayoutProps {
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  content?: React.ReactNode;
}

export const ProgramLayout = ({
  header,
  content,
  sidebar,
}: ProgramLayoutProps) => {
  const history = useHistory();

  return (
    <StackLayout
      css={{
        marginBottom: '$three',
        padding: '$twoAndHalf',
      }}
      orientation={{
        '@initial': 'vertical',
        '@laptop': 'horizontal',
        '@desktop': 'horizontal',
      }}
      horizontalAlignment="stretch"
    >
      <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
        {header && (
          <StackLayout
            orientation="horizontal"
            spacing="$four"
            css={{ width: '100%', justifyContent: 'center' }}
          >
            <StackLayout
              orientation={{
                '@initial': 'vertical',
                '@laptop': 'horizontal',
                '@desktop': 'horizontal',
              }}
              css={{
                maxWidth: PROGRAM_CONTENT_WIDTH,
              }}
            >
              <StackItem
                css={{
                  width: '100%',
                  '@desktop': { width: 'auto' },
                  '@laptop': { width: 'auto' },
                }}
              >
                <BackButtonArrow
                  justifyContent="initial"
                  marginRight="quarter"
                  marginY="half"
                  onClick={history.goBack}
                  size="three"
                />
              </StackItem>
              {header}
            </StackLayout>

            {sidebar && (
              <Responsive up="laptop">
                <StackItem
                  css={{
                    width: PROGRAM_SIDEBAR_WIDTH,
                    maxHeight: PROGRAM_SIDEBAR_HEIGHT,
                    overflowY: 'visible',
                  }}
                >
                  {sidebar}
                </StackItem>
              </Responsive>
            )}
          </StackLayout>
        )}

        <StackLayout
          orientation="horizontal"
          horizontalAlignment="center"
          spacing="$four"
        >
          <StackItem
            css={{
              width: '100%',
              maxWidth: PROGRAM_CONTENT_WIDTH,
              paddingLeft: 0,
              '@laptop': {
                paddingLeft: PROGRAM_BACK_BUTTON_WIDTH,
              },
              '@desktop': {
                paddingLeft: PROGRAM_BACK_BUTTON_WIDTH,
              },
            }}
          >
            {content}
          </StackItem>

          {sidebar && (
            <Responsive up="laptop">
              <StackItem css={{ width: PROGRAM_SIDEBAR_WIDTH }}>
                {!header && sidebar}
              </StackItem>
            </Responsive>
          )}
        </StackLayout>
      </StackLayout>
    </StackLayout>
  );
};
