import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  CSChallengeActivitiesSortType,
  CSHealthActivityStatusType,
} from '@leagueplatform/health-journey-common';
import { UserHealthActivitiesEndpoints } from './constants/user-health-activity.constants';
import {
  HealthActivityResponseData,
  IndividualHealthActivityDeserializedData,
} from './types/health-activity-response-data.type';
import { handleCorruptActivity } from './utils/handleCorruptActivity';

export const getUserHealthActivityById = async (
  userHealthActivityId?: string,
  campaignId?: string,
  contentActivityId?: string,
  userId?: string,
  userHealthCampaignId?: string,
  status?: CSHealthActivityStatusType | CSHealthActivityStatusType[],
  sortBy?: CSChallengeActivitiesSortType[],
): Promise<IndividualHealthActivityDeserializedData> => {
  const endpoint = UserHealthActivitiesEndpoints.userHealthActivity(
    userHealthActivityId,
    campaignId,
    contentActivityId,
    userId,
    userHealthCampaignId,
    status,
    sortBy,
  );

  const response = await leagueFetch(endpoint);
  const responseJson: HealthActivityResponseData = await response.json();
  const deserializedJson = deserialise(responseJson);

  if (!response.ok) return Promise.reject(deserializedJson);

  if (deserializedJson.data.length) {
    handleCorruptActivity(deserializedJson.data);
  }
  return Promise.resolve(deserializedJson);
};
