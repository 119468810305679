// The theme object with all tokens currently used in Genesis, with no values, used as default in Stitches

export const emptyThemeDefinition = {
  colors: {
    surfaceBackgroundPrimary: '',
    surfaceBackgroundSecondary: '',
    surfaceBackgroundTertiary: '',
    surfaceCardPrimary: '',
    surfaceCardSecondary: '',
    surfaceCardDisabled: '',
    surfaceScreenHeaderBackground: '',
    surfaceSkeletonShimmerBackground: '',
    surfaceSkeletonShimmerOverlay: '',
    onSurfaceTextPrimary: '',
    onSurfaceTextSubdued: '',
    onSurfaceTextCritical: '',
    onSurfaceTextSuccess: '',
    onSurfaceTextWarning: '',
    onSurfaceTextReversed: '',
    onSurfaceBorderSubdued: '',
    onSurfaceBorderDefault: '',
    onSurfaceIconPrimary: '',
    interactiveActionPrimary: '',
    interactiveActionHovered: '',
    interactiveActionPressed: '',
    interactiveActionSubdued: '',
    interactiveActionDisabled: '',
    interactiveBackgroundDefault: '',
    interactiveBackgroundDisabled: '',
    interactiveBackgroundHovered: '',
    interactiveBackgroundPressed: '',
    interactiveBorderDefault: '',
    interactiveBorderHovered: '',
    interactiveBorderDisabled: '',
    interactiveBorderCriticalDefault: '',
    interactiveFocusInner: '',
    interactiveFocusOuter: '',
    interactiveIconDefault: '',
    interactiveIconDisabled: '',
    criticalBackgroundSubdued: '',
    criticalBackgroundSecondary: '',
    criticalBorderDefault: '',
    criticalIcon: '',
    warningBackgroundSubdued: '',
    warningBackgroundSecondary: '',
    warningBorderDefault: '',
    warningIcon: '',
    successBackgroundSubdued: '',
    successBackgroundSecondary: '',
    successBorderDefault: '',
    successIcon: '',
    highlightBackgroundSubdued: '',
    highlightBackgroundSecondary: '',
    highlightBorderDefault: '',
    highlightIcon: '',
    decorativeBrandPrimaryDarkest: '',
    decorativeBrandPrimaryDark: '',
    decorativeBrandPrimaryDefault: '',
    decorativeBrandPrimaryBright: '',
    decorativeBrandPrimaryBrightest: '',
    decorativeBrandPrimaryPastel: '',
    decorativeBrandSecondaryDarkest: '',
    decorativeBrandSecondaryDark: '',
    decorativeBrandSecondaryDefault: '',
    decorativeBrandSecondaryBright: '',
    decorativeBrandSecondaryBrightest: '',
    decorativeBrandSecondaryPastel: '',
    decorativeBrandTertiaryDarkest: '',
    decorativeBrandTertiaryDark: '',
    decorativeBrandTertiaryDefault: '',
    decorativeBrandTertiaryBright: '',
    decorativeBrandTertiaryBrightest: '',
    decorativeBrandTertiaryPastel: '',
    primaryBackgroundDefault: '',
    primaryBackgroundHovered: '',
    primaryBackgroundPressed: '',
    primaryTextDefault: '',
    primaryBackgroundCriticalDefault: '',
    primaryBackgroundCriticalHovered: '',
    primaryBackgroundCriticalPressed: '',
    primaryTextCriticalDefault: '',
    secondaryBackgroundDefault: '',
    secondaryBackgroundHovered: '',
    secondaryBackgroundPressed: '',
    secondaryTextDefault: '',
    secondaryTextHovered: '',
    secondaryBorderDefault: '',
    tertiaryBackgroundDefault: '',
    tertiaryBackgroundHovered: '',
    tertiaryBackgroundPressed: '',
    tertiaryTextDefault: '',
    tertiaryTextHovered: '',
    tertiaryBorderDefault: '',
    inputBackgroundDefault: '',
    inputBackgroundDisabled: '',
    inputBackgroundHovered: '',
    inputBackgroundPressed: '',
    inputBorderDefault: '',
    inputBorderHovered: '',
    inputBorderDisabled: '',
    inputBorderCritical: '',
    inputBorderSuccess: '',
    inputBorderWarning: '',
    dataVizInterfaceTrackBorder: '',
    dataVizInterfaceTrackBackground: '',
    dataVizSingleColorBrand: '',
    dataVizSingleColorNeutral: '',
    dataVizStatusCritical: '',
    dataVizStatusWarning: '',
    dataVizStatusSuccess: '',
    dataVizCategoricalOne: '',
    dataVizCategoricalTwo: '',
    dataVizCategoricalThree: '',
    dataVizCategoricalFour: '',
    dataVizCategoricalFive: '',
    dataVizCategoricalsix: '',
  },
  space: {
    none: '',
    quarter: '',
    half: '',
    threeQuarters: '',
    one: '',
    oneAndHalf: '',
    two: '',
    twoAndHalf: '',
    three: '',
    four: '',
    five: '',
  },
  sizes: {
    none: '',
    quarter: '',
    half: '',
    threeQuarters: '',
    one: '',
    oneAndHalf: '',
    two: '',
    twoAndHalf: '',
    three: '',
    four: '',
    five: '',
  },
  fonts: {
    headings: '',
    body: '',
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    subtitleOne: '',
    subtitleTwo: '',
    bodyOne: '',
    bodyTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  fontSizes: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    subtitleOne: '',
    subtitleTwo: '',
    bodyOne: '',
    bodyTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  fontWeights: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    bodyOne: '',
    bodyOneSecondary: '',
    bodyTwo: '',
    bodyTwoSecondary: '',
    subtitleOne: '',
    subtitleTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  lineHeights: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    subtitleOne: '',
    subtitleTwo: '',
    bodyOne: '',
    bodyTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  letterSpacings: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    bodyOne: '',
    bodyOneSecondary: '',
    bodyTwo: '',
    bodyTwoSecondary: '',
    subtitleOne: '',
    subtitleTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  textDecorations: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    bodyOne: '',
    bodyOneSecondary: '',
    bodyTwo: '',
    bodyTwoSecondary: '',
    subtitleOne: '',
    subtitleTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  textTransforms: {
    headingOne: '',
    headingTwo: '',
    headingThree: '',
    headingFour: '',
    bodyOne: '',
    bodyOneSecondary: '',
    bodyTwo: '',
    bodyTwoSecondary: '',
    subtitleOne: '',
    subtitleTwo: '',
    buttonOne: '',
    buttonTwo: '',
    label: '',
    overline: '',
    caption: '',
    tab: '',
  },
  borderWidths: {
    thin: '',
    thick: '',
    card: '',
  },
  zIndices: {
    default: '',
    sticky: '',
    dropdown: '',
    tooltip: '',
    modal: '',
    toast: '',
  },
  transitions: {
    defaultTime: '',
  },
  radii: {
    none: '',
    thin: '',
    thick: '',
    small: '',
    medium: '',
    large: '',
    extraLarge: '',
    huge: '',
    pill: '',
    circle: '',
    button: '',
    inputField: '',
    card: '',
  },
  shadows: {
    card: '',
    dropdown: '',
  },
};
