import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  DeserialisedMessagesForThread,
  MessagesForThreadResponse,
} from 'types/messages-for-thread.types';
import { THREAD_MESSAGES_PATH } from './constants/messaging-requests.constants';

export const getMessagesForThread = async (
  threadId: string,
  requestPath?: string | null,
): Promise<DeserialisedMessagesForThread> => {
  const path =
    requestPath || `${THREAD_MESSAGES_PATH}/${threadId}?page[size]=30`;
  const response = await leagueFetch(path);
  if (!response.ok) {
    throw new Error(`getMessagesForThread error: ${response.status}`);
  }
  const responseJson: MessagesForThreadResponse = await response.json();
  return deserialise(responseJson);
};
