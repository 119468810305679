import {
  ConfigStoreStatusSettled,
  ConfigStoreState,
  ConfigStoreStateFromStatus,
  ConfigStoreStatus,
  MergedConfigWithStatus,
  MergedConfigWithStatusFromStatus,
} from './store.types';
import { knownServerConfigSchema } from './module-config-schemas/server-driven';

const serverConfigPaths = Object.keys(knownServerConfigSchema.schema);

/**
 * Checks if a given path (passed to  `useConfigProperty`) is for a server config
 * property or not.
 */
export function getIsServerConfigPath(path: string) {
  const firstSegment = path.match(/^([^.]+)\.?/)?.[1];
  const is = firstSegment && serverConfigPaths.includes(firstSegment);
  return is;
}

/**
 * Given a `ConfigStoreState`, returns whether that store is in the `empty` status.
 */
export const isStoreEmpty = (
  state: ConfigStoreState,
): state is ConfigStoreStateFromStatus<ConfigStoreStatus.Empty> =>
  state.status === ConfigStoreStatus.Empty;

/**
 * Given a `ConfigStoreStatus`, returns whether it represents a settled store.
 */
export const isStatusSettled = (
  status: ConfigStoreStatus,
): status is ConfigStoreStatusSettled =>
  status === ConfigStoreStatus.Success ||
  status === ConfigStoreStatus.ErrorWithCache ||
  status === ConfigStoreStatus.LoadingWithCache;

/**
 * Given a `MergedConfigWithStatus`, returns whether it is in a settled status.
 */
export const isConfigSettled = (
  configWithStatus: MergedConfigWithStatus,
): configWithStatus is MergedConfigWithStatusFromStatus<ConfigStoreStatusSettled> =>
  isStatusSettled(configWithStatus.status);

/**
 * Given a `ConfigStoreStatus`, returns whether server config fetch settled.
 */
export const isFetchStatusSettled = (
  status: ConfigStoreStatus,
): status is ConfigStoreStatusSettled =>
  status === ConfigStoreStatus.Success ||
  status === ConfigStoreStatus.ErrorWithCache;

/**
 * Given a `ConfigStoreState`, returns whether server config fetch settled.
 */
export const isServerConfigFetchSettled = (
  state: ConfigStoreState,
): state is ConfigStoreStateFromStatus<ConfigStoreStatusSettled> =>
  isFetchStatusSettled(state.status);
