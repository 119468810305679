import React from 'react';
import { CoverImage } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

export interface ChooseProfilePictureProps {
  photoUrl?: string;
  size: string;
}

export const ChooseProfilePicture = ({
  photoUrl,
  size,
}: ChooseProfilePictureProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const camera = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_CAMERA,
  ) as string;

  return (
    <CoverImage
      src={photoUrl?.length ? photoUrl : camera}
      alt={photoUrl?.length ? formatMessage({ id: 'PROFILE_PHOTO' }) : ''}
      size={size}
      alignSelf="center"
      borderRadius="circle"
      role={photoUrl ? 'img' : 'presentation'}
    />
  );
};
