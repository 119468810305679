import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingOne,
  BodyOneSecondary,
  Box,
  Image,
  Flex,
  genesisStyled,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { PRODUCT_AREA, SCREEN_NAME } from '../constants/analytics.constants';
import { HEALTH_ASSESSMENT_TYPE } from '../constants';

const TopicContainer = genesisStyled(Flex)`
  max-width: 750px;
`;

export const TopicOnboarding = ({
  title,
  amtQuestions,
  description,
  imageUrl,
  moduleId,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
    screen_name: SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_CATEGORY_INTRO,
    assessment_type: HEALTH_ASSESSMENT_TYPE,
    assessment_questionnaire_category: title.toLowerCase(),
    assessment_id: moduleId,
  });
  return (
    <TopicContainer flexDirection={isMobile ? 'column' : 'row'} align="center">
      <Box
        marginRight={isMobile ? 'auto' : 'four'}
        marginLeft={isMobile ? 'auto' : 'none'}
      >
        <Image
          role="presentation"
          src={imageUrl}
          height={245}
          borderRadius="medium"
        />
      </Box>
      <Flex justifyContent="center" flexDirection="column" width="100%">
        {isMobile ? (
          <HeadingTwo>{title}</HeadingTwo>
        ) : (
          <HeadingOne>{title}</HeadingOne>
        )}
        <BodyOneSecondary
          fontSize={isMobile ? 'overline' : 'heading3'}
          fontWeight="bold"
          marginBottom="two"
        >
          {formatMessage(
            {
              id: 'AMOUNT_QUESTIONS',
            },
            {
              amount: amtQuestions,
            },
          )}
        </BodyOneSecondary>
        <BodyOneSecondary marginTop="one">{description}</BodyOneSecondary>
      </Flex>
    </TopicContainer>
  );
};

TopicOnboarding.propTypes = {
  title: PropTypes.string.isRequired,
  amtQuestions: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
};
