// Checks that the first 32 characters match a mongoID,
// and can optionally include "-whatever"
// This is for multi-card benefits
const mongoRegex = /^[a-f0-9]{32}(?:-.*)?$/;
const isMongoId = (id: string) => mongoRegex.test(id);

export function isValidDeepLinkId(benefitId: string) {
  if (benefitId === 'league-credit') {
    return false;
  }

  return !isMongoId(benefitId);
}
