import { useQuery } from 'react-query';
import {
  getUserFeatureFlags,
  GET_USER_FEATURE_FLAGS_MESSAGE_TYPE,
} from './get-user-feature-flags';

type FeatureFlags = { user_flags: { [key: string]: boolean } };

export function useFeatureFlagQuery(featureFlag: string) {
  if (!featureFlag) throw new Error('Please provide a feature flag name');

  return useQuery([GET_USER_FEATURE_FLAGS_MESSAGE_TYPE], getUserFeatureFlags, {
    staleTime: Infinity, // Added to mitigate re-fetching when there are nested uses of the hook in a page
    select: (data: FeatureFlags) => {
      if (!data.user_flags[featureFlag]) return false;

      return data.user_flags[featureFlag];
    },
  });
}
