import { useEffect } from 'react';
import { CHALLENGE_HIGHLIGHT_EVENT_TYPES } from '@leagueplatform/health-journey-api';
import { useDebounce } from '@leagueplatform/web-common';
import { DEBOUNCE_DELAY_INTERVAL } from 'constants/challenges.constants';
import { useReactToHighlight } from './use-react-to-highlight.hook';

interface UseDebouncedReactToHighlightMutationProps {
  isLiked: boolean;
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>;
  userLikedHighlight: boolean;
  likeReactionId: string;
  highlightEventType: CHALLENGE_HIGHLIGHT_EVENT_TYPES;
}

/*
  Rather than calling the react to highlight mutation immediately when a user likes/unlikes a highlight, we want to implement debouncing behaviour so that the API is called after a delay of 400ms based on the user's last action (debouncedIsLiked).

  However, we still want to immediately update the UI to reflect the user's action(s), rather than waiting to update the UI when we receive a response from the API. This will be done directly from the like button, calling setIsLiked on click of the button.

  useEffect will call the react to highlight mutation when debouncedIsLiked is updated, but only when it is different from the original userLikedHighlight state. This will prevent us from making an unnecessary call when there is no change to the final liked state (e.g., if a user likes and then unlikes within the debounce interval). It also prevents the mutation from being called on mount of the component when debouncedIsLiked is first being set.
*/
export const useDebouncedReactToHighlightMutation = ({
  isLiked,
  setIsLiked,
  userLikedHighlight,
  likeReactionId,
  highlightEventType,
}: UseDebouncedReactToHighlightMutationProps) => {
  const debouncedIsLiked = useDebounce(isLiked, DEBOUNCE_DELAY_INTERVAL);

  const reactToHighlightMutation = useReactToHighlight(
    likeReactionId,
    highlightEventType,
    userLikedHighlight,
    debouncedIsLiked,
    setIsLiked,
  );

  useEffect(() => {
    if (debouncedIsLiked !== userLikedHighlight) {
      reactToHighlightMutation();
    }
  }, [debouncedIsLiked, reactToHighlightMutation, userLikedHighlight]);
};
