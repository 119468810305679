/**
 * Given an activityStatusCounts and progressPercentages object, return an array for CompoundProgressBar to consume
 * @param {*} progressPercentages - a progressPercentages object containing ints for missed and completed activities
 * @returns
 */
export const programProgressBarSegmentFactory = ({ completed, missed }) => [
  {
    key: 'complete',
    percent: completed,
    color: 'success.icon',
  },
  {
    key: 'missed',
    percent: missed,
    color: 'onSurface.text.subdued',
  },
];
