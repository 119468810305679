import { NestedCampaign } from '@leagueplatform/health-journey-api';
import { useUrlParam } from '@leagueplatform/web-common';
import { PROGRAM_TABS } from 'constants/program-tabs.constants';
import { useMemo } from 'react';

export function useGetCurrentTab(rewardProgram?: NestedCampaign) {
  const { urlParam } = useUrlParam('page');

  return useMemo(() => {
    if (!rewardProgram) return undefined;

    const { userHealthCampaigns, campaign } = rewardProgram;

    const { displayGlobalProgress } = campaign.data;
    if (urlParam) return urlParam;

    if (displayGlobalProgress && rewardProgram.campaignProgress.current > 0) {
      return PROGRAM_TABS.PROGRESS;
    }

    if (!displayGlobalProgress) {
      let hasProgress = false;

      userHealthCampaigns?.data.forEach((childCampaign) => {
        if (childCampaign.campaignProgress.current > 0) hasProgress = true;
      });

      if (hasProgress) return PROGRAM_TABS.PROGRESS;
    }

    return PROGRAM_TABS.OVERVIEW;
  }, [rewardProgram, urlParam]);
}
