import React, { useState } from 'react';
import { UnavailableDeviceConnectModal } from './unavailable-device-connect-modal.component';
import { DeviceTrackingModal } from './device-tracking-modal.component';

interface VerifiableProgramModalProps {
  onClose: () => void;
  programAddHandler: (campaignMode: string) => void;
  userHasSufficientWearablePermissions: boolean;
  programDataPoints: string[];
}

export const VerifiableProgramModal = ({
  onClose,
  programAddHandler,
  userHasSufficientWearablePermissions,
  programDataPoints,
}: VerifiableProgramModalProps) => {
  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    useState(false);

  if (displayUnavailableOnWebModal) {
    return <UnavailableDeviceConnectModal onClose={onClose} />;
  }

  return (
    <DeviceTrackingModal
      onClose={onClose}
      userHasSufficientWearablePermissions={
        userHasSufficientWearablePermissions
      }
      programDataPoints={programDataPoints}
      programAddHandler={programAddHandler}
      setDisplayUnavailableOnWebModal={setDisplayUnavailableOnWebModal}
    />
  );
};
