import React from 'react';
import type { Traits } from '@leagueplatform/masonry-api';
import {
  Box,
  HeadingThree,
  Overline,
  SubtitleOne,
  Text,
} from '@leagueplatform/genesis-commons';
import { BadgeList } from 'common/badge-list/badge-list.component';
import { ConditionalLink } from '@leagueplatform/web-common-components';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';
import { BadgeItem } from 'common/badge-list/badge-item.type';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface LargeVisualCardContentProps {
  title: string;
  description: string;
  overline?: string;
  badges?: BadgeItem[];
  linkRefState?: React.RefObject<HTMLAnchorElement>;
  linkUrl?: string;
  onCardClick?: (e: React.MouseEvent) => void;
  traits?: Traits;
}

export const LargeVisualCardContent = ({
  overline,
  linkRefState,
  linkUrl,
  title,
  description,
  badges,
  traits,
  onCardClick,
}: LargeVisualCardContentProps) => {
  const overlineId = `lvc-${title.replace(/\s+/g, '-')}`;
  // TODO: Traits will be removed in Masonry 2.0
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <StackLayout
      spacing="$half"
      orientation="vertical"
      css={{
        padding: '$oneAndHalf',
      }}
    >
      {overline && (
        <Overline as={Text} id={overlineId} color="onSurface.text.subdued">
          {overline}
        </Overline>
      )}
      <StackLayout spacing="$quarter" orientation="vertical">
        <SubtitleOne
          as={HeadingThree}
          aria-describedby={overlineId}
          fontWeight="bold"
        >
          <ConditionalLink
            ref={linkRefState}
            url={linkUrl}
            onClick={onCardClick}
          >
            {title}
          </ConditionalLink>
        </SubtitleOne>

        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {description}
        </ParagraphText>
      </StackLayout>
      {badges && (
        <Box marginTop="threeQuarters">
          <BadgeList
            badges={badges}
            backgroundColor={traitItems?.badgeBackgroundColor}
          />
        </Box>
      )}
    </StackLayout>
  );
};
