import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const SET_HEALTH_PROFILE_MODULE_ONBOARDING_COMPLETE_MESSAGE_TYPE =
  'set_health_profile_module_onboarding_complete';

export const setHealthProfileModuleOnboardingComplete = async (
  moduleId: string,
) =>
  SocketAsFetch.fetch({
    message_type: SET_HEALTH_PROFILE_MODULE_ONBOARDING_COMPLETE_MESSAGE_TYPE,
    info: {
      module_id: moduleId,
    },
  });
