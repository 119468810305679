import { getActiveGroup } from '@leagueplatform/web-common';
import { UserGroup } from '@leagueplatform/user-profile-api';
import { useUserProfileQuery } from './use-user-profile-query.hook';

export function useUserActiveGroupQuery(userId?: string) {
  return useUserProfileQuery<UserGroup>(userId, {
    // TODO: make this take options & support selectors
    select: (data) => {
      // TODO: remove typecasting once getActiveGroup is properly typed
      const group = getActiveGroup(data.groups) as UserGroup;
      return group;
    },
  });
}
