import {
  boolean,
  object,
  partial,
  optional,
  string,
  any,
  record,
  func,
  Struct,
} from 'superstruct';

export const claimsConfigSchema = object({
  filterableProperties: optional(record(string(), any())),
  featureFlags: optional(
    partial(
      object({
        shouldShowClaimDetails: boolean(),
        shouldShowDownloadClaims: boolean(),
        shouldShowFilterChips: boolean(),
      }),
    ),
  ),
  supportLink: optional(string()),
  showTotalCostOverviewAndAdditionalResources: optional(boolean()),
  showClaimStatusDefinitions: optional(boolean()),
  showClaimsSummaryBackButton: optional(boolean()),
  enableDynamicFilters: optional(boolean()),
  getTotalCostOverviewVisibilityForClaim: optional(
    func() as unknown as Struct<(claim: Object) => boolean>,
  ),
  slots: optional(
    object({
      Banner: optional(func() as unknown as Struct<React.FunctionComponent>),
    }),
  ),
});
