import {
  Button,
  Icon,
  StackLayout,
  StatusBanner,
  TextAction,
  styled,
} from '@leagueplatform/genesis-core';
import {
  CampaignAttributes,
  USER_HEALTH_CAMPAIGN_STATUS,
} from '@leagueplatform/health-journey-api';
import {
  JOURNEY_ROUTES,
  ProgramAchievements,
  ProgramRichText,
  ProgramSponsor,
  ProgramRules,
} from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import React, { useEffect } from 'react';
import { Link } from '@leagueplatform/routing';
import { captureError } from '@leagueplatform/observability';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  ANALYTICS_FIELDS,
  ANALYTICS_VALUES,
} from 'constants/analytics.constants';

const StyledLink = styled(TextAction, {
  fontSize: 'inherit',
  lineHeight: 'inherit',
  marginTop: '$one',
});

interface RewardProgramOverviewProps {
  campaign: CampaignAttributes;
  campaignId: string;
  status: USER_HEALTH_CAMPAIGN_STATUS;
  isVisible?: boolean;
}

export function RewardProgramOverviewTab({
  campaignId,
  campaign,
  status,
  isVisible,
}: RewardProgramOverviewProps) {
  const { formatMessage } = useIntl();

  const { programRules } = campaign.data;

  const sponsoredByAttributes =
    campaign.data.contentProviderAttributes?.componentAttributes;

  const disclaimerAttributes =
    campaign.data.bannerComponentAttributes?.componentAttributes;

  const achievementAttributes =
    campaign.data.achievementsComponentAttributes?.componentAttributes;

  useEffect(() => {
    if (
      !programRules &&
      !sponsoredByAttributes &&
      !disclaimerAttributes &&
      !achievementAttributes
    ) {
      captureError(
        new Error(
          `Reward program(id:${campaignId}) overview data isn't provided`,
        ),
      );
    }
  }, [
    achievementAttributes,
    campaignId,
    disclaimerAttributes,
    programRules,
    sponsoredByAttributes,
  ]);

  useEffect(() => {
    if (isVisible) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        [ANALYTICS_FIELDS.PRODUCT_AREA]:
          ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
        [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
          ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
        [ANALYTICS_FIELDS.SCREEN_NAME]:
          ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_OVERVIEW,
        [ANALYTICS_FIELDS.CAMPAIGN_NAME]: campaign.data.name,
        [ANALYTICS_FIELDS.CAMPAIGN_ID]: campaignId,
        [ANALYTICS_FIELDS.CAMPAIGN_TYPE]: campaign.data.type,
        [ANALYTICS_FIELDS.CAMPAIGN_STATUS]: status,
      });
    }
  }, [campaign.data.name, campaign.data.type, campaignId, isVisible, status]);

  return (
    <StackLayout spacing="$twoAndHalf" css={{ marginTop: '$two' }}>
      <ProgramRichText
        options={{
          a: {
            component: StyledLink,
            props: {
              target: '_blank',
              onClick: (e: any) => {
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  [ANALYTICS_FIELDS.PRODUCT_AREA]:
                    ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
                  [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
                    ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
                  [ANALYTICS_FIELDS.SCREEN_NAME]:
                    ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_OVERVIEW,
                  [ANALYTICS_FIELDS.DETAIL]: e.currentTarget.innerHTML,
                });
              },
            },
          },
        }}
        richText={campaign.data.richTextDescription}
      />
      <Button
        as={Link}
        to={JOURNEY_ROUTES.getRewardProgramProgress(campaignId)}
        onClick={() => {
          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
            [ANALYTICS_FIELDS.PRODUCT_AREA]:
              ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
            [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
              ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
            [ANALYTICS_FIELDS.SCREEN_NAME]:
              ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_OVERVIEW,
            [ANALYTICS_FIELDS.DETAIL]:
              ANALYTICS_VALUES.DETAIL_VIEW_ALL_ACTIVITIES,
          });
        }}
      >
        {formatMessage({ id: 'VIEW_THE_ACTIVITIES' })}
      </Button>
      {programRules && <ProgramRules programRules={programRules} />}
      {sponsoredByAttributes && (
        <ProgramSponsor
          image={sponsoredByAttributes.logotype}
          sponsorName={sponsoredByAttributes.name}
        />
      )}
      {achievementAttributes && (
        <ProgramAchievements
          title={achievementAttributes.title}
          text={achievementAttributes.text}
          badgeUrl={achievementAttributes.iconUrl}
        />
      )}
      {disclaimerAttributes && (
        <StatusBanner
          css={{ width: '100%', backgroundColor: '$surfaceBackgroundTertiary' }}
          status="neutral"
          icon={<Icon size="$one" icon={disclaimerAttributes.iconUrl} />}
          title={disclaimerAttributes.title}
        >
          <ProgramRichText richText={disclaimerAttributes.richText} />
        </StatusBanner>
      )}
    </StackLayout>
  );
}
