import React from 'react';
import PropTypes from 'prop-types';
import { Box, colour } from '@leagueplatform/ui-kit';
import {
  css,
  genesisStyled,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import styled from 'styled-components';
import { uniqueId } from 'lodash';

const DotWrapper = genesisStyled('button')(
  css({
    padding: 'threeQuarters',
    marginRight: 'quarter',
    backgroundColor: 'surface.background.primary',
    border: 'none',
    borderRadius: 'circle',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      boxShadow: 'focusRing',
      outline: 'none',
    },
  }),
);

const MenuWrapper = styled('ul')`
  padding-top: 24px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled(Box)`
  height: ${({ active }) => (active ? '16px' : '12px')};
  width: ${({ active }) => (active ? '16px' : '12px')};
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? colour('purple') : colour('neutral.grayLight')};
`;

export const ProgressIndicator = ({
  progressionItems,
  setActivePageIndex,
  setIsAnimate,
}) => (
  <MenuWrapper>
    {progressionItems.map((item, index) => (
      <li key={uniqueId('progress-dot-')}>
        <DotWrapper
          onClick={(e) => {
            e.preventDefault();
            if (!item.active) {
              setIsAnimate(false);
              setActivePageIndex(index);
            }
          }}
        >
          <VisuallyHidden>{`${item.title} ${index + 1}`}</VisuallyHidden>
          <Dot
            height={item.active ? 'one' : 'threeQuaters'}
            borderRadius="50%"
            active={item.active}
          />
        </DotWrapper>
      </li>
    ))}
  </MenuWrapper>
);

ProgressIndicator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  progressionItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActivePageIndex: PropTypes.func.isRequired,
  setIsAnimate: PropTypes.func.isRequired,
};
