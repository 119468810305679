import { query, deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { UserHealthActivitiesEndpoints } from './constants/user-health-activity.constants';
import {
  UserHealthJourneyHistoryDeserializedData,
  UserHealthJourneyHistoryQueryParameters,
  UserHealthJourneyHistoryResponse,
} from './types/user-health-journey-history.type';

export const getUserHealthJourneyHistory = async (
  params: UserHealthJourneyHistoryQueryParameters = {},
): Promise<UserHealthJourneyHistoryDeserializedData> => {
  const endpoint = UserHealthActivitiesEndpoints.userHealthJourneyHistory;
  const response = await leagueFetch(`${endpoint}?${query(params)}`);

  const responseJson: UserHealthJourneyHistoryResponse = await response.json();
  const deserializedJson = deserialise(responseJson);

  if (!response.ok) return Promise.reject(deserializedJson);
  return Promise.resolve(deserializedJson);
};

export const getUserHealthJourneyHistoryByPaginationLink = async (
  page: string,
): Promise<UserHealthJourneyHistoryDeserializedData> => {
  const response = await leagueFetch(page);

  const responseJson: UserHealthJourneyHistoryResponse = await response.json();
  const deserializedJson = deserialise(responseJson);

  if (!response.ok) return Promise.reject(deserializedJson);
  return Promise.resolve(deserializedJson);
};
