import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { formatISO } from 'date-fns';
import { useGetHealthMetricsV2 } from 'hooks/use-get-health-metrics-v2.hook';

type UseChartDataParams = {
  chartView: CHART_VIEW;
  metric: string;
  from: Date;
};

export function useChartData({ chartView, metric, from }: UseChartDataParams) {
  const useGetHealthMetricsQuery = useGetHealthMetricsV2({
    interval: chartView === CHART_VIEWS.WEEKLY ? 'weekly' : 'monthly',
    filter: { type: metric },
    fields: { type: ['type', 'value', 'unit', 'timestamp'] },
    from: formatISO(from, { representation: 'date' }),
  });

  return useGetHealthMetricsQuery;
}
