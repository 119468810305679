import { QuestionnaireItem } from 'fhir/r4';

export const handleItemTypeUrl = (item: QuestionnaireItem): QuestionnaireItem =>
  item;

export const handleAnswerTypeUrl = (value: string) => [
  {
    valueUri: value,
  },
];
