import {
  getUserStoreCreditBalance,
  GET_USER_STORE_CREDIT_BALANCE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useQuery } from 'react-query';
import { UserStoreCreditBalanceResponse } from '../../types/league-credit.types';

export function useUserStoreCreditBalanceQuery<
  TSelect = UserStoreCreditBalanceResponse,
>(select: (data: UserStoreCreditBalanceResponse) => TSelect) {
  const { userId } = useWalletParams();

  return useQuery<UserStoreCreditBalanceResponse, unknown, TSelect>(
    [GET_USER_STORE_CREDIT_BALANCE_MESSAGE_TYPE, userId],
    () => getUserStoreCreditBalance(userId),
    {
      select,
    },
  );
}
