import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import {
  ProgramBanner,
  ProgramDetailCardFooter,
} from '@leagueplatform/health-journey-common';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { isAfterEnrollmentPeriod } from 'utils/enrollment-period-status.util';

export const ChallengeDetailsCardBanner = () => {
  const { formatMessage, formatDate } = useIntl();
  const { data: challengeData } = useGetChallengeByIdData();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  const { challengeType, endDate, endEnroll, status } = challengeData!.data;
  const isGroupChallenge = challengeType === 'group';
  const isBelowLaptop = useMediaQuery(queryHelpers.down('laptop'));
  const userIsEnrolled =
    challengeData!.data?.userChallenge?.data?.enrollmentStatus === 'enrolled';
  const isNowAfterSignUpPeriod = isAfterEnrollmentPeriod(
    endEnroll,
    defaultTimezone,
  );
  const displaySignUpDatePassedBanner =
    isGroupChallenge &&
    !userIsEnrolled &&
    isNowAfterSignUpPeriod &&
    status !== 'closed';
  const completedIcon = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_COMPLETED_CHALLENGE,
  ) as string;
  const dateFormatOptions = {
    ...SHORT_DATE_FORMAT,
    timeZone: defaultTimezone,
  };
  if (displaySignUpDatePassedBanner) {
    // TODO: Come back and clean this up with more time
    return isBelowLaptop ? (
      <ProgramBanner
        boldedText={formatMessage({ id: 'SIGN_UP_PERIOD_PASSED' })}
        basicText={formatMessage({ id: 'SIGN_UP_PERIOD_PASSED_DETAIL' })}
        css={{
          borderRadius: '$medium',
          backgroundColor: '$warningBackgroundSubdued',
          padding: '$one',
        }}
      />
    ) : (
      <ProgramDetailCardFooter
        boldedText={formatMessage({ id: 'SIGN_UP_PERIOD_PASSED' })}
        basicText={formatMessage({ id: 'SIGN_UP_PERIOD_PASSED_DETAIL' })}
      />
    );
  }
  if (status === 'closed') {
    // TODO: Come back and clean this up with more time
    return isBelowLaptop ? (
      <ProgramBanner
        boldedText={formatMessage({ id: 'THIS_CHALLENGE_HAS_ENDED' })}
        basicText={formatMessage(
          { id: 'COMPLETED_ON_DATE' },
          {
            date: formatDate(endDate, dateFormatOptions),
          },
        )}
        css={{
          borderRadius: '$medium',
          backgroundColor: '$warningBackgroundSubdued',
          padding: '$one',
        }}
        icon={completedIcon}
      />
    ) : (
      <ProgramDetailCardFooter
        boldedText={formatMessage({ id: 'THIS_CHALLENGE_HAS_ENDED' })}
        basicText={formatMessage(
          { id: 'COMPLETED_ON_DATE' },
          {
            date: formatDate(endDate, dateFormatOptions),
          },
        )}
        icon={completedIcon}
      />
    );
  }
  return null;
};
