import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ADMIN_GET_EMPLOYEE_SCHEMA_CONFIG_MESSAGE_TYPE =
  'admin_get_employee_schema_config';

interface EmployeeSchema {
  groupId: string;
}

export const adminGetEmployeeSchemaConfig = ({ groupId }: EmployeeSchema) =>
  SocketAsFetch.fetch({
    message_type: ADMIN_GET_EMPLOYEE_SCHEMA_CONFIG_MESSAGE_TYPE,
    info: {
      group_id: groupId,
    },
  });
