import { MetricType } from '@leagueplatform/dashboard-api';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { useGetHealthMetrics } from 'hooks/use-get-health-metrics.hook';

type UseChartDataParams = {
  chartView: CHART_VIEW;
  metric: MetricType;
  from: Date;
};

export function useChartData({ chartView, metric, from }: UseChartDataParams) {
  const useGetHealthMetricsQuery = useGetHealthMetrics(
    {
      interval: chartView === CHART_VIEWS.WEEKLY ? 'weekly' : 'monthly',
      filter: { type: metric },
      fields: { type: ['type', 'value', 'unit', 'timestamp'] },
      from: from.toISOString(),
    },
    {
      // We only need to backfill legacy charts. Dynamic dashboard will contain all days.
      backfill: true,
    },
  );

  return useGetHealthMetricsQuery;
}
