{
  "dragDescriptionKeyboard": "Натисніть Enter, щоб почати перетягування.",
  "dragDescriptionKeyboardAlt": "Натисніть Alt + Enter, щоб почати перетягування.",
  "dragDescriptionLongPress": "Натисніть і утримуйте, щоб почати перетягування.",
  "dragDescriptionTouch": "Натисніть двічі, щоб почати перетягування.",
  "dragDescriptionVirtual": "Натисніть, щоб почати перетягування.",
  "dragItem": "Перетягнути {itemText}",
  "dragSelectedItems": "Перетягніть {count, plural, one {# вибраний елемент} other {# вибраних елем}}",
  "dragSelectedKeyboard": "Натисніть Enter, щоб перетягнути {count, plural, one {# вибраний елемент} other {# вибраних елементи(-ів)}}.",
  "dragSelectedKeyboardAlt": "Натисніть Alt + Enter, щоб перетягнути {count, plural, one {# вибраний елемент} other {# вибраних елементи(-ів)}}.",
  "dragSelectedLongPress": "Утримуйте, щоб перетягнути {count, plural, one {# вибраний елемент} other {# вибраних елементи(-ів)}}.",
  "dragStartedKeyboard": "Перетягування почалося. Натисніть Tab, щоб перейти до цілі перетягування, потім натисніть Enter, щоб перетягнути, або Escape, щоб скасувати.",
  "dragStartedTouch": "Перетягування почалося. Перейдіть до цілі перетягування, потім натисніть двічі, щоб перетягнути.",
  "dragStartedVirtual": "Перетягування почалося. Перейдіть до цілі перетягування, потім натисніть Enter, щоб перетягнути.",
  "dropCanceled": "Перетягування скасовано.",
  "dropComplete": "Перетягування завершено.",
  "dropDescriptionKeyboard": "Натисніть Enter, щоб перетягнути. Натисніть Escape, щоб скасувати перетягування.",
  "dropDescriptionTouch": "Натисніть двічі, щоб перетягнути.",
  "dropDescriptionVirtual": "Натисніть, щоб перетягнути.",
  "dropIndicator": "індикатор перетягування",
  "dropOnItem": "Перетягнути на {itemText}",
  "dropOnRoot": "Перетягнути на",
  "endDragKeyboard": "Триває перетягування. Натисніть Enter, щоб скасувати перетягування.",
  "endDragTouch": "Триває перетягування. Натисніть двічі, щоб скасувати перетягування.",
  "endDragVirtual": "Триває перетягування. Натисніть, щоб скасувати перетягування.",
  "insertAfter": "Вставити після {itemText}",
  "insertBefore": "Вставити перед {itemText}",
  "insertBetween": "Вставити між {beforeItemText} і {afterItemText}"
}
