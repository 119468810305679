import { CardImage } from 'types/benefit-card';
import { CARD_STATUS } from './card-action.types';

export interface Name {
  text: string;
  language: string;
}

export interface WalletItemsSectionedQueryResponse {
  id?: string;
  items_sectioned: WalletItemNavigationSectionGroup[];
}
// TODO: Create a more in-depth interface for wallet navigation sections

export interface WalletItemNavigationSection {
  section_id: string;
  name: Name;
  wallet_items?: WalletItemNavigationBenefit[];
}

export interface WalletItemNavigationBenefit {
  benefit_id?: string;
  benefit_product_type?: string;
  benefit_source?: string;
  benefit_logo?: CardImage; // alternative to `icon_url` for when we need base64 support
  group_id?: string;
  icon_url?: string;
  id?: string;
  is_new_benefit: boolean;
  name: Name;
  status: CARD_STATUS;
  subtitle?: string;
  title?: string;
  type: string;
  vendor_id: string;
  vendor_name: Name;
  wallet_item_id: string;
}

export interface WalletItemNavigationSectionGroup
  extends WalletItemNavigationSection {
  sections: WalletItemNavigationSection[];
}

export enum NAVIGATION_TYPE {
  OTHER = 'other',
  BENEFIT = 'benefit',
  BENEFITS = 'benefits',
}
