import React, { FC } from 'react';

// Common Dependencies
import { Box, StackLayout, styled } from '@leagueplatform/genesis-core';

// Hooks

// Common Components

// Types

// Constants
import {
  ACTIVITY_CONTENT_BASE_WIDTH,
  TRANSITIONS,
} from '../constants/multi-step-footer-constants';

// Local Types
interface MultiStepLayoutProps {}

// Local Components
const FullPageHeight = styled(Box, {
  height: '100%',
  inset: 0,
  position: 'absolute',
  backgroundColor: '$surfaceBackgroundTertiary',
});

const ContentPosition = styled(StackLayout, {
  padding: '$oneAndHalf 0',
  paddingTop: '$five',
  transition: `all ${TRANSITIONS.PAGE}`,
  overflow: 'hidden',
});
ContentPosition.defaultProps = {
  horizontalAlignment: 'center',
  verticalAlignment: 'center',
};

const ContentWrapper = styled(StackLayout, {
  position: 'relative',
  width: ACTIVITY_CONTENT_BASE_WIDTH,
  maxWidth: '100%',
});
ContentWrapper.defaultProps = {
  orientation: 'vertical',
  horizontalAlignment: 'center',
};

export const MultiStepLayout: FC<MultiStepLayoutProps> = ({ children }) => (
  <FullPageHeight data-testid="multi-step-page">
    <ContentPosition data-testid="multi-step-layout">
      <ContentWrapper data-testid="multi-step-content-wrapper">
        {children}
      </ContentWrapper>
    </ContentPosition>
  </FullPageHeight>
);
