import React, { useRef } from 'react';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { LargeImageCard } from 'components/common/large-image-card/large-image-card.component';
import { ConditionalLinkCard } from '@leagueplatform/web-common-components';
import { LargeVisualCardContent } from './large-visual-card-content.component';
import type { LargeVisualCardContentProps } from './large-visual-card-content.component';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export type LargeVisualCardWidgetProps = LargeVisualCardContentProps & {
  image: string;
  imageAltText?: string;
  isChildItem?: boolean;
};

export type LargeVisualCardWSNode = MasonryEngineNode<
  WidgetType.LARGE_VISUAL_CARD,
  LargeVisualCardWidgetProps
>;

export const LargeVisualCardWidget = ({
  image,
  imageAltText,
  isChildItem,
  linkUrl,
  traits,
  ...props
}: LargeVisualCardWidgetProps) => {
  const linkRefState = useRef<HTMLAnchorElement>(null);
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <ConditionalLinkCard linkRef={linkRefState} hasLink={Boolean(linkUrl)}>
      <LargeImageCard
        image={image}
        imageAlign="right"
        imageAltText={imageAltText}
        isChildItem={isChildItem || traitItems?.isChildItem}
      >
        <LargeVisualCardContent
          linkUrl={linkUrl}
          linkRefState={linkRefState}
          traits={traits}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </LargeImageCard>
    </ConditionalLinkCard>
  );
};
