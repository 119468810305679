import * as React from 'react';
import {
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { HealthMetricDataV3 } from 'components/charts/core/range-graph/utils/use-health-metric-values';
import { DatumLegend } from 'pages/details2/components/datum-legend';
import { shapes } from 'components/charts/core/xychart/primitives/Shape';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { getHealthMetricDisplayValue } from 'utils/get-health-metric-display-value';
import { getHealthMetricUnitDisplayValue } from 'utils/get-health-metric-unit-display-value';
import { ChartTooltip } from '../chart-tooltip';

type MultipleMeasurementsTooltipProps = {
  data: HealthMetricDataV3;
};

export function MultipleMeasurementsTooltipV3({
  data,
}: MultipleMeasurementsTooltipProps) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  return (
    <ChartTooltip>
      <StackLayout spacing="$half">
        {data.map((datum) => {
          const shape = shapes[datum.metric.data.order];
          const value = getHealthMetricDisplayValue(datum);
          const unit = getHealthMetricUnitDisplayValue(
            value,
            datum.metric.data.unit,
          );

          return (
            <StackItem>
              <DatumLegend
                size="small"
                shape={data.length > 1 ? shape : undefined}
                name={datum.metric.data.name.toUpperCase()}
                value={value}
                unit={unit}
              />
            </StackItem>
          );
        })}
        <UtilityText size="eyebrow">
          {formatDateWithUserProfileLocation(data[0].timestamp, DATE_FORMAT)}
        </UtilityText>
      </StackLayout>
    </ChartTooltip>
  );
}
