import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export interface Fields {
  netsuite_id: string;
}

interface GroupFields {
  groupId: string;
  group: Array<Fields>;
}

export const SET_GROUP_MESSAGE_TYPE = 'set_group';

export const setGroup = ({ groupId, group }: GroupFields) =>
  SocketAsFetch.fetch({
    message_type: SET_GROUP_MESSAGE_TYPE,
    info: {
      group_id: groupId,
      group,
    },
  });
