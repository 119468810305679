import React from 'react';
import PropTypes from 'prop-types';
import {
  QuietButton,
  Image,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import BackIcon from 'assets/back.svg';

const BackButton = genesisStyled(QuietButton)(
  css({
    '&:hover, &:active, &:focus': {
      background: 'transparent !important',
      transform: 'translateX(-3px)',
    },
  }),
);

export const BackButtonArrow = ({ onClick, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <BackButton
      onClick={onClick}
      backgroundColor="transparent"
      height="two"
      minWidth="two"
      paddingX="none"
      paddingY="none"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Image
        display="block"
        src={BackIcon}
        width={24}
        height={24}
        alt={formatMessage({ id: 'GO_BACK' })}
      />
    </BackButton>
  );
};

BackButtonArrow.propTypes = {
  onClick: PropTypes.func,
};
// Optional since postToWR is no longer mandatory
BackButtonArrow.defaultProps = {
  onClick: () => {},
};
