import type { JourneyTab } from 'types/health-journey-tabs.types';

export const SCREEN_NAMES = {
  // health journey specific
  ACTIVITY_TIMELINE: 'health journey activities',
  ACTIVITY_DETAILS: 'health campaign activity detail',
  COMPLETE_MODAL: 'health campaign activity complete',
  REMOVE_MODAL: 'health campaign activity remove',
  SAVE_PROGRESS_MODAL: 'health campaign activity goal confirmation',
  EXIT_CONFIRM_MODAL: 'health campaign activity exit confirmation',
  PROGRESS_PAGE: 'health journey progress',
  PROGRAM_LIMIT_MODAL: 'health campaign limit banner',
  SUGGESTED_GROUP: 'health journey activities suggested group',
  HOME_PAGE: 'homepage',
  HISTORY_PAGE: 'health journey history',
  // health programs specific
  PROGRAM_LIBRARY: 'health campaign library',
  CATEGORY_PAGE: 'health campaign category',
  HEALTH_CAMPAIGN_REMOVAL: 'health campaign removal',
  HEALTH_CAMPAIGN_DETAIL: 'health campaign detail',
  HEALTH_ASSESSMENT_REPORT: 'health assessment report',
} as const;

// These tab display names are used for analytics and need to match the order
// of the tabs in the ConfigurableJourneyExperience component
// capabilities/health-journey/src/components/configurable-journey-experience/configurable-journey-experience.component.tsx
export const TAB_DISPLAY_NAMES: JourneyTab[] = [
  'activities',
  'progress',
  'history',
  'explore',
];

export const TAB_PANEL_ANALYTICS_NAMES = [
  SCREEN_NAMES.ACTIVITY_TIMELINE,
  SCREEN_NAMES.PROGRESS_PAGE,
  SCREEN_NAMES.HISTORY_PAGE,
  SCREEN_NAMES.PROGRAM_LIBRARY,
] as const;
