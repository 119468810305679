import { useLocation, useHistory } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ENABLE_NEW_JOURNEY_EXPERIENCE } from '../constants/feature-flag-names.constants';

interface UseBackButtonRoutingArgs {
  backButtonRoute: string;
  onClickFunc: () => void;
}

/*
  Back button routing logic is as follows:
    - If user is coming from:
      - old journey OR,
      - new journey and from outside of the app,
    go back to backButtonRoute
    - If new journey and app history exists, go back programatically via history.goBack()
*/
export const useBackButtonRouting = ({
  backButtonRoute,
  onClickFunc,
}: UseBackButtonRoutingArgs) => {
  const location = useLocation();
  const history = useHistory();
  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );

  // app history stack will be undefined if user came to the page from outside of the app
  const appHistoryStackExists = location.key !== undefined;

  /*
    - Default href to backButtonRoute
    - If new journey and app history exists, href = '.'
  */
  const isUserComingFromAppNewJourney =
    isNewJourneyExperienceEnabled && appHistoryStackExists;
  const backButtonHref = isUserComingFromAppNewJourney ? '.' : backButtonRoute;

  const backButtonOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Existing old journey onClick functionality (e.g., analytics tracking, modal logic etc.)
    onClickFunc();

    if (isUserComingFromAppNewJourney) {
      e.preventDefault();
      history.goBack();
    }
  };

  return {
    backButtonHref,
    backButtonOnClick,
  };
};
