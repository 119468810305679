import { deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  ChallengeResponseData,
  IndividualChallengeDeserialisedData,
} from './types/challenge-response-data.type';
import {
  CHALLENGES_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

const { USER_CHALLENGE, CHALLENGE_TEAM } = CHALLENGE_DATA_TYPES;
export const getChallengeDataById = async (
  challengeId: string,
  errorContext?: ErrorContext,
): Promise<IndividualChallengeDeserialisedData> => {
  const response = await leagueFetch(
    `${CHALLENGES_PATH}/${challengeId}?include=${USER_CHALLENGE},${CHALLENGE_TEAM}`,
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (!response.ok) {
    throw new Error(`getChallengeDataById error: ${response.status}`);
  }
  const responseJson: ChallengeResponseData = await response.json();
  return deserialise(responseJson);
};
