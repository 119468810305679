import type {
  GDSTheme,
  GDSThemeLoaderOptions,
} from '@leagueplatform/genesis-core';
import {
  boolean,
  enums,
  literal,
  object,
  optional,
  string,
  union,
} from 'superstruct';
import type { Struct } from 'superstruct';

export const uiConfiSchema = object({
  theme: object() as unknown as Struct<GDSTheme>,
  themeOptions: optional(object() as unknown as Struct<GDSThemeLoaderOptions>),
  components: optional(
    object({
      pageHeader: optional(
        object({
          shape: optional(enums(['swoop', 'line'])),
          backgroundColor: optional(string()),
        }),
      ),
      loading: optional(
        object({
          element: object() as unknown as Struct<React.ReactElement>,
          text: optional(union([literal(false), string()])),
        }),
      ),
    }),
  ),
  groupLogo: optional(
    object({
      element: optional(object() as unknown as Struct<React.ReactElement>),
    }),
  ),
  features: optional(
    object({
      pointsEnabled: optional(boolean()),
    }),
  ),
});
