import React, { useEffect } from 'react';
import { DateInput, Text } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';

export const ModuleDateInput = ({
  questionId,
  onChangeHandler,
  setShowArrow,
  userSelections,
  isSkippable,
  ariadescribedby,
}) => {
  const userSelection = userSelections[questionId] || {};
  const {
    value = '',
    error: { hasError = false, errorMsg = '' },
  } = userSelection;

  // show arrow if there is a date
  useEffect(() => {
    setShowArrow(!!value || isSkippable);
  }, [value, setShowArrow, isSkippable]);

  /**
   * NOTE: "legacy" in DateInput component is deprecated and should not be used!
   * Remove the `legacy` prop in this component to update it to use Genesis
   *
   * TODO: This component is the only component that is still in legacy.
   * Make sure to delete src/ui-kit/components/date-input/legacy-date-input.view.js file
   * once this component is updated to Genesis.
   */
  return (
    <>
      <DateInput
        aria-describedby={ariadescribedby}
        legacy
        aria-required
        hasError={hasError}
        value={value}
        onChange={(event, { date }) => {
          onChangeHandler({ value: date ? date.toISOString() : '' });
        }}
      />
      {hasError && errorMsg && (
        <Text color="red" fontSize={2} mt={1} mb={0}>
          {errorMsg}
        </Text>
      )}
    </>
  );
};

ModuleDateInput.propTypes = {
  questionId: PropTypes.string.isRequired,
  setShowArrow: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
  isSkippable: PropTypes.bool,
  ariadescribedby: PropTypes.string,
};

ModuleDateInput.defaultProps = {
  isSkippable: false,
  ariadescribedby: '',
};
