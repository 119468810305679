const MasonryLogPreffix = '[Masonry]:';

const getStylingFromString = (msg: string): string[] => {
  const styleLength = msg.match(/%c/g)?.length;
  return [...Array(styleLength)].map(
    (_, i) => `font-weight: ${i % 2 === 0 ? 'bold' : 'medium'}`,
  );
};

export const logError = (msg: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(`${MasonryLogPreffix} ${msg}`, ...getStylingFromString(msg));
  }
};

export const logWarning = (msg: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(`${MasonryLogPreffix} ${msg}`, ...getStylingFromString(msg));
  }
};
