import {
  getConfig,
  MODULE_VERSION,
  subscribeToConfig,
  updateServerConfig,
} from '@leagueplatform/config';
import { captureError } from '@leagueplatform/observability';
import { getBrowserInfo } from './get-browser-info';

const SERVER_CONFIG_API_VERSION = 'v1';

function callbackError() {
  const { config } = getConfig();
  if (config.core.onError) {
    const error = new Error(
      'Failed to fetch League configuration, and no cached configuration was found.',
    );
    error.name = 'Failed to load League configuration';

    config.core.onError(error);
  }
}

export function createServerConfigUpdateSubscription() {
  subscribeToConfig(
    /**
     * Notice that subscribing to only the parameters we need means that the eventual
     * call to `updateServerConfig` will only happen when those parameters change,
     * which is exactly the circumstances under which we want to get (or re-get)
     * server config values. This also prevents an infinite loop of config setting,
     * since the act of setting `serverConfig` will not in itself re-trigger this subscription,
     * because it does not involve modifying any of the client config parameters we
     * are subscribed to.
     */
    ({ config }) => ({
      appEnvironment: config.core.appEnvironment,
      clientId: config.core.clientId,
      appVersion: config.core.appVersion,
      // eslint-disable-next-line no-underscore-dangle
      isNodeTestEnv: config.__internal?.isNodeTestEnv,
    }),
    (selection) => {
      if (!selection || selection.isNodeTestEnv) {
        /**
         * ...and until we have those client config properties, we would not be able
         * to ask for server-driven values.
         */
        return;
      }

      const { name: browserName, version: browserVersion } = getBrowserInfo();

      updateServerConfig({
        clientId: selection.clientId,
        environment: selection.appEnvironment,
        apiVersion: SERVER_CONFIG_API_VERSION,
        browserName,
        browserVersion,
        sdkVersion: MODULE_VERSION,
        appVersion: selection.appVersion,
      })
        .then()
        .catch((error) => {
          callbackError();
          captureError(error, { errorName: 'Get Server Config Error' });
        });
    },
  );
}
