import React from 'react';
import {
  BackgroundColorSystemProp,
  Flex,
  HeadingFour,
  SubtitleOne,
  TextColorSystemProp,
  Box,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import range from 'lodash/range';
import { FeedbackInput } from './components/feedback-input.component';
import { StackLayout } from '../stack-layout/stack-layout.component';

interface FeedbackCardProps {
  backgroundColor?: BackgroundColorSystemProp;
  title: string;
  subtitle?: string;
  min?: number;
  max?: number;
  size?: 'small' | 'large';
  onClick?: (number: number) => void;
  minMaxTextColor?: TextColorSystemProp;
  feedbackUrl: string;
}

export const FeedbackCard = ({
  backgroundColor = 'surface.background.secondary',
  title,
  subtitle,
  min = 1,
  max = 5,
  size = 'small',
  minMaxTextColor = 'onSurface.text.subdued',
  onClick,
  feedbackUrl = '#',
}: FeedbackCardProps) => {
  const { formatMessage } = useIntl();
  const isSmall = size === 'small';
  const hasSubtitle = !!subtitle;

  return (
    <Flex
      flexDirection="column"
      borderRadius="medium"
      backgroundColor={backgroundColor}
      alignItems="center"
      padding="two"
    >
      <StackLayout space="$half" css={{ width: '100%', textAlign: 'center' }}>
        <Box>
          <HeadingFour as="h2">{title}</HeadingFour>
        </Box>
        <Box
          as="fieldset"
          flexDirection="column"
          alignItems="center"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          {hasSubtitle && <SubtitleOne as="legend">{subtitle}</SubtitleOne>}

          <Flex
            alignItems="center"
            maxWidth={isSmall ? '450px' : '85%'}
            width={1}
            color={minMaxTextColor}
            gap="half"
            justifyContent="space-around"
            marginTop="two"
          >
            {range(min, max + 1).map((number, index) => {
              let expandedLabel;
              if (index === 0) {
                expandedLabel = formatMessage({
                  id: 'FEEDBACK_MIN_SCALE',
                });
              } else if (number === max) {
                expandedLabel = formatMessage({
                  id: 'FEEDBACK_MAX_SCALE',
                });
              }
              return (
                <FeedbackInput
                  key={`feedback-input-item-${number}`}
                  value={number}
                  ariaLabel={formatMessage(
                    { id: 'FEEDBACK_LINK_LABEL' },
                    { value: number, maximum: max },
                  )}
                  label={`${number}`}
                  href={`${feedbackUrl}${number}`}
                  onClick={onClick}
                  isFirst={index === 0}
                  isSmall={isSmall}
                  expandedLabel={expandedLabel}
                />
              );
            })}
          </Flex>
        </Box>
      </StackLayout>
    </Flex>
  );
};
