import { ReconnectingSocketController } from '@leagueplatform/league-socket';
import { RewardEvent } from 'constants/types';
import { useState, useEffect } from 'react';
import { useWindowFocus } from './use-window-focus.hook';

export function useDisplayRewardEvent() {
  const [currentRewardEvent, setCurrentRewardEvent] = useState<RewardEvent>();

  const [rewardEvents, setRewardEvents] = useState<RewardEvent[]>([]);

  const { isWindowFocused } = useWindowFocus();

  useEffect(() => {
    const removeEventHandler = ReconnectingSocketController.addEventHandler(
      (newEvent: RewardEvent) => {
        setRewardEvents((prev) => {
          if (
            newEvent.message_type === 'user_rewards_updates' &&
            !prev.some((event) => event.message_id === newEvent.message_id)
          ) {
            prev.push(newEvent);
          }

          return [...prev];
        });
      },
    );

    return () => {
      removeEventHandler();
    };
  }, []);

  useEffect(() => {
    if (isWindowFocused) {
      setCurrentRewardEvent(rewardEvents[0]);
    }
  }, [isWindowFocused, rewardEvents]);

  const rewardEventViewed = () => {
    setRewardEvents((prev) => {
      prev.shift();
      return [...prev];
    });
  };

  return { currentRewardEvent, rewardEventViewed };
}
