import * as React from 'react';
import { SingleMeasurementTooltip } from 'components/charts/core/chart-tooltip';
import { useIntl } from '@leagueplatform/locales';
import {
  LONG_WEEKDAY_DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { HealthMetricChart } from 'components/charts/types/chart';
import { CHART_VIEWS } from 'components/charts/constants/chart-views';
import { MetricBarChart } from '../metric-bar-chart';

type StepsBarChartProps = HealthMetricChart;

export function StepsBarChart({ data, type, view }: StepsBarChartProps) {
  const { formatMessage, $tifelse } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { formatUnit } = useLocaleUnitFormatter();
  const { formatMetricValue } = useLocaleMetricValueFormatter();

  return (
    <MetricBarChart
      yMaxFromEnums={0}
      data={data}
      type={type}
      view={view}
      description={$tifelse(
        view === CHART_VIEWS.WEEKLY,
        'INSIGHT_CHART_STEPS_WEEK_DESCRIPTION',
        'INSIGHT_CHART_STEPS_MONTH_DESCRIPTION',
      )}
      axisLeftLabel={`${formatMessage({
        id: 'INSIGHT_CARD_HEADER_STEPS',
      })}`.toLocaleUpperCase()}
      ariaDatumLabel={(datum) =>
        formatMessage(
          { id: 'INSIGHT_CHART_STEPS_DATUM_LABEL' },
          {
            distance: formatMetricValue(datum.value, type),
            unit: formatUnit(datum.type, datum.unit),
            date: formatDateWithUserProfileLocation(
              new Date(datum.timestamp),
              LONG_WEEKDAY_DATE_FORMAT,
            ),
          },
        )
      }
      renderTooltip={(datum) => (
        <SingleMeasurementTooltip
          title={formatMessage({ id: 'INSIGHT_CARD_HEADER_STEPS' })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datum}
        />
      )}
    />
  );
}
