import { useState } from 'react';

export function useCheckboxConfirm({ onConfirm = () => {} } = {}) {
  const [isChecked, setIsChecked] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  return {
    isChecked,
    setIsChecked,
    isErrored,
    setIsErrored,
    handleChange: () => {
      setIsChecked(!isChecked);
      if (isErrored) {
        setIsErrored(false);
      }
    },
    onConfirm: (e) => {
      // event object is required for formik's handleSubmit
      if (!isChecked) {
        setIsErrored(true);
      } else {
        // Reset state
        setIsChecked(false);
        setIsErrored(false);
        onConfirm(e);
      }
    },
  };
}
