import { Flex, FlexProps } from '@leagueplatform/genesis-commons';
import React from 'react';

export const CONTENT_MAX_WIDTH = '1272px';

interface MainPageContainerProps extends FlexProps {
  children: React.ReactNode;
}

export const MainPageContainer = ({
  children,
  ...props
}: MainPageContainerProps) => (
  <Flex
    maxWidth={CONTENT_MAX_WIDTH}
    marginX="auto"
    padding={{ _: 'oneAndHalf', phone: 'two' }}
    flexDirection="column"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Flex>
);
