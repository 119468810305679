{
  "dragDescriptionKeyboard": "Lohistamise alustamiseks vajutage klahvi Enter.",
  "dragDescriptionKeyboardAlt": "Lohistamise alustamiseks vajutage klahvikombinatsiooni Alt + Enter.",
  "dragDescriptionLongPress": "Vajutage pikalt lohistamise alustamiseks.",
  "dragDescriptionTouch": "Topeltpuudutage lohistamise alustamiseks.",
  "dragDescriptionVirtual": "Klõpsake lohistamise alustamiseks.",
  "dragItem": "Lohista {itemText}",
  "dragSelectedItems": "Lohista {count, plural, one {# valitud üksust} other {# valitud üksust}}",
  "dragSelectedKeyboard": "{count, plural, one {# valitud üksuse} other {# valitud üksuse}} lohistamiseks vajutage sisestusklahvi Enter.",
  "dragSelectedKeyboardAlt": "Lohistamiseks vajutage klahvikombinatsiooni Alt + Enter {count, plural, one {# valitud üksuse} other {# valitud üksuse}} jaoks.",
  "dragSelectedLongPress": "Pikk vajutus {count, plural, one {# valitud üksuse} other {# valitud üksuse}} lohistamiseks.",
  "dragStartedKeyboard": "Alustati lohistamist. Kukutamise sihtmärgi juurde navigeerimiseks vajutage klahvi Tab, seejärel vajutage kukutamiseks klahvi Enter või loobumiseks klahvi Escape.",
  "dragStartedTouch": "Alustati lohistamist. Navigeerige kukutamise sihtmärgi juurde ja topeltpuudutage kukutamiseks.",
  "dragStartedVirtual": "Alustati lohistamist. Navigeerige kukutamise sihtmärgi juurde ja kukutamiseks klõpsake või vajutage klahvi Enter.",
  "dropCanceled": "Lohistamisest loobuti.",
  "dropComplete": "Lohistamine on tehtud.",
  "dropDescriptionKeyboard": "Kukutamiseks vajutage klahvi Enter. Lohistamisest loobumiseks vajutage klahvi Escape.",
  "dropDescriptionTouch": "Kukutamiseks topeltpuudutage.",
  "dropDescriptionVirtual": "Kukutamiseks klõpsake.",
  "dropIndicator": "lohistamise indikaator",
  "dropOnItem": "Kukuta asukohta {itemText}",
  "dropOnRoot": "Kukuta asukohta",
  "endDragKeyboard": "Lohistamine. Lohistamisest loobumiseks vajutage klahvi Enter.",
  "endDragTouch": "Lohistamine. Lohistamisest loobumiseks topeltpuudutage.",
  "endDragVirtual": "Lohistamine. Lohistamisest loobumiseks klõpsake.",
  "insertAfter": "Sisesta {itemText} järele",
  "insertBefore": "Sisesta {itemText} ette",
  "insertBetween": "Sisesta {beforeItemText} ja {afterItemText} vahele"
}
