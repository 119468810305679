import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  HEALTH_METRIC,
  HealthMetric,
  HealthMetricValue,
} from './types/health-metrics-data.type';

type Value =
  | {
      type: 'value-quantity';
      [key: string]: string | number;
    }
  | {
      type: 'value-enum';
      [key: string]: string | number;
    }
  | {
      type: 'value-period';
      start: string;
      end: string;
    };

export type HealthMetricPatchData = {
  // dupe for now with POST, move to shared types file.
  metricConfigId: string;
  metricComponentId: string;
  effectiveDateTime?: string;
  value: Value;
};

export async function updateHealthMetrics({
  data,
  observationId,
}: {
  data: HealthMetricPatchData;
  observationId: string;
}): Promise<{ data: HealthMetricValue }> {
  const response = await leagueFetch(`v3/health-metrics/${observationId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: observationId,
        type: HEALTH_METRIC,
        attributes: data,
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`PostCreateHealthMetrics error: ${response.status}`);
  }
  const responseJson: HealthMetric = await response.json();

  return deserialise(responseJson);
}
