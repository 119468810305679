import { leagueFetch } from '@leagueplatform/league-fetch';
import { IdCardsAttributes, IdCardsRequestBody } from 'types/generated';

export const POST_ID_CARDS_PATH = '/v1/id-cards';

interface PostIdCards {
  data: IdCardsAttributes;
}

export async function postIdCards({
  data: attributes,
}: PostIdCards): Promise<{ error?: Error; status: number }> {
  const data: IdCardsRequestBody = {
    type: 'id-card',
    attributes,
  };

  const response = await leagueFetch(POST_ID_CARDS_PATH, {
    method: 'POST',
    body: JSON.stringify({ data }),
  });

  if (!response.ok) {
    throw new Error(`postIdCards error: ${response.status}`);
  }

  return { status: response.status };
}
