export const currencyChecker = (data) => {
  // "12.55"      true
  // "12."        true
  // "12"         true
  // "12.."       false
  // "$12.555"    false
  const regex = /^$|^\$?[0-9]+\.?[0-9]?[0-9]?$/gm;
  const match = regex.exec(data);
  return match;
};

export const formatCurrency = (data) => {
  const formatted = parseFloat(data).toFixed(2);
  if (Number.isNaN(formatted)) {
    return '';
  }
  return formatted;
};

export const formatPhoneNumber = (input) => {
  if (input) {
    const phone = input.replace(/\D/g, '');
    if (phone) {
      const values = /(\d)?(\d\d\d)(\d\d\d)(\d\d\d\d)/.exec(phone);
      if (values) {
        const [fullNo, country, area, transfer, last4] = values;
        if (fullNo) {
          const lead = country ? `+${country} ` : '';
          return `${lead}(${area}) ${transfer}-${last4}`;
        }
      }

      return phone;
    }
  }

  return null;
};

export const formatPhoneNumberForHref = (input) => {
  if (input) {
    const phone = input.replace(/\D/g, '');
    if (phone) {
      const values = /(\d)?(\d\d\d\d\d\d\d\d\d\d)/.exec(phone);
      if (values) {
        const [fullNo, country, number] = values;
        if (fullNo) {
          const lead = country ? `+${country}` : '';
          return `tel:${lead}${number}`;
        }
      }
      return `tel:${phone}`;
    }
  }

  return null;
};

const unicodePattern = /\\u([\d\w]{4})/gi;
export const unescapeUnicode = (text = '') =>
  text.replace(unicodePattern, (match, grp) =>
    String.fromCharCode(parseInt(grp, 16)),
  );

// Return a string in all lowercase and spaces and underscores are replaced with hyphens
export const lowercaseAndHypen = (text) =>
  text.toLowerCase().replace(/[_\s]/g, '-');

// Return string in all uppercase with all hyphens and spaces replaced with underscores
export const uppercaseAndUnderscore = (text) =>
  text.toUpperCase().replace(/[-\s]/g, '_');

// Split text at newlines and return array to allow parent component to display as needed
export const splitAtNewlines = (text = '') => text.split(/\r\n|\r|\n/g);

// Converts a string in camelCase or PascalCase to snake_case
export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`,
  );

// create tag string for an array of ids with dashes, in upper case, and separated by dots
export const arrayToTag = (arr, retainCase = false) => {
  const taggedArray = arr
    .filter((el) => el != null)
    .join(' ∙ ')
    .split('-')
    .join(' ');

  return retainCase ? taggedArray : taggedArray.toLowerCase();
};
