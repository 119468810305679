import { useQuery } from 'react-query';
import {
  getUserHealthActivities,
  GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import camelcaseKeys from 'camelcase-keys';
import { ConfigurableHealthActivityWSCC } from '../types/activity-details-props.types';

// TODO: Replace this with improved typing when completing:
// https://everlong.atlassian.net/browse/XPNC-2890
export type ActivityStatusOptions =
  | 'active'
  | 'completed'
  | 'expired'
  | 'removed'
  | 'upcoming';
interface UseActivitiesByActivityStatusArgs {
  activityStatuses: ActivityStatusOptions[];
  campaignId?: string;
}

const WS_ENDPOINT_VERSION_NUMBER = 3;

export interface GetUserHealthActivitiesV3Return {
  userHealthActivities: ConfigurableHealthActivityWSCC[];
}

export function useActivitiesByActivityStatusAndCampaign({
  activityStatuses,
  campaignId,
}: UseActivitiesByActivityStatusArgs) {
  return useQuery<GetUserHealthActivitiesV3Return, Error>(
    [GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE, activityStatuses, campaignId],
    () =>
      getUserHealthActivities({
        activityStatuses,
        campaignId,
        version: WS_ENDPOINT_VERSION_NUMBER,
      }),
    {
      enabled: !!activityStatuses.length && !!campaignId,
      select: (data) => camelcaseKeys(data, { deep: true }),
    },
  );
}
