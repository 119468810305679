import React, { useEffect, useState } from 'react';

export enum SWIPE_DIRECTION {
  right = 'RIGHT',
  left = 'LEFT',
  up = 'UP',
  down = 'DOWN',
}

type SwipeDirectionType = SWIPE_DIRECTION | '';

export enum SWIPE_ORIENTATION {
  vertical = 'VERTICAL',
  horizontal = 'HORIZONTAL',
}

/**
 * useSwipeGestures
 * A hook that detects when a swipe gesture (touchstart & touchend event) occurs.
 * @param {React.RefObject<HTMLElement>} ref - a reference to the "swipeable" area where the event listeners will be added / removed.
 * @param {SWIPE_ORIENTATION} [orientation] - the orientation of the swipe gestures that the hook should return. If not provided, both vertical and horizontal guestures would be returned.
 * @returns {SwipeDirectionType} the direction of the most recent swipe gesture.
 */
export const useSwipeGestures = (
  ref: React.RefObject<HTMLElement>,
  orientation?: SWIPE_ORIENTATION,
): SwipeDirectionType => {
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirectionType>('');
  const [touchStartClient, setTouchStartClient] = useState<number[] | null[]>([
    null,
    null,
  ]);

  if (!ref) {
    // eslint-disable-next-line no-console
    console.warn('[useSwipeGesture] Ref element not found: ', ref);
  }

  useEffect(() => {
    const { current } = ref;

    const handleTouchStart = (e: TouchEvent) => {
      setSwipeDirection('');

      const { clientX, clientY } = e.touches[0];
      setTouchStartClient([clientX, clientY]);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const [startClientX, startClientY] = touchStartClient;
      const { clientX: endClientX, clientY: endClientY } = e.changedTouches[0];

      if (
        typeof startClientX === 'number' &&
        typeof startClientY === 'number'
      ) {
        /* Determine the orientation of the swipe based on which axis (X or Y) had more movement. Then determine the direction based on the starting & end points. */
        if (
          orientation !== SWIPE_ORIENTATION.vertical &&
          Math.abs(startClientX - endClientX) >
            Math.abs(startClientY - endClientY)
        ) {
          if (startClientX > endClientX) {
            setSwipeDirection(SWIPE_DIRECTION.right);
          } else if (startClientX < endClientX) {
            setSwipeDirection(SWIPE_DIRECTION.left);
          }
        } else if (
          orientation !== SWIPE_ORIENTATION.horizontal &&
          Math.abs(startClientX - endClientX) <
            Math.abs(startClientY - endClientY)
        ) {
          if (startClientY > endClientY) {
            setSwipeDirection(SWIPE_DIRECTION.down);
          } else if (startClientY < endClientY) {
            setSwipeDirection(SWIPE_DIRECTION.up);
          }
        }
      }
    };

    if (current) {
      current.addEventListener('touchstart', handleTouchStart);
      current.addEventListener('touchend', handleTouchEnd);

      return () => {
        current.removeEventListener('touchstart', handleTouchStart);
        current.removeEventListener('touchend', handleTouchEnd);
      };
    }

    return () => {};
  }, [ref, orientation, touchStartClient]);

  return swipeDirection;
};
