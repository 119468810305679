{
  "dragDescriptionKeyboard": "Aloita vetäminen painamalla Enter-näppäintä.",
  "dragDescriptionKeyboardAlt": "Aloita vetäminen painamalla Alt + Enter -näppäinyhdistelmää.",
  "dragDescriptionLongPress": "Aloita vetäminen pitämällä painettuna.",
  "dragDescriptionTouch": "Aloita vetäminen kaksoisnapauttamalla.",
  "dragDescriptionVirtual": "Aloita vetäminen napsauttamalla.",
  "dragItem": "Vedä kohdetta {itemText}",
  "dragSelectedItems": "Vedä {count, plural, one {# valittua kohdetta} other {# valittua kohdetta}}",
  "dragSelectedKeyboard": "Vedä painamalla Enter {count, plural, one {# valittu kohde} other {# valittua kohdetta}}.",
  "dragSelectedKeyboardAlt": "Vedä painamalla Alt + Enter {count, plural, one {# valittu kohde} other {# valittua kohdetta}}.",
  "dragSelectedLongPress": "Vedä pitämällä painettuna {count, plural, one {# valittu kohde} other {# valittua kohdetta}}.",
  "dragStartedKeyboard": "Vetäminen aloitettu. Siirry pudotuskohteeseen painamalla sarkainnäppäintä ja sitten pudota painamalla Enter-näppäintä tai peruuta painamalla Escape-näppäintä.",
  "dragStartedTouch": "Vetäminen aloitettu. Siirry pudotuskohteeseen ja pudota kaksoisnapauttamalla.",
  "dragStartedVirtual": "Vetäminen aloitettu. Siirry pudotuskohteeseen ja pudota napsauttamalla tai painamalla Enter-näppäintä.",
  "dropCanceled": "Pudotus peruutettu.",
  "dropComplete": "Pudotus suoritettu.",
  "dropDescriptionKeyboard": "Pudota painamalla Enter-näppäintä. Peruuta vetäminen painamalla Escape-näppäintä.",
  "dropDescriptionTouch": "Pudota kaksoisnapauttamalla.",
  "dropDescriptionVirtual": "Pudota napsauttamalla.",
  "dropIndicator": "pudotuksen ilmaisin",
  "dropOnItem": "Pudota kohteeseen {itemText}",
  "dropOnRoot": "Pudota kohteeseen",
  "endDragKeyboard": "Vedetään. Peruuta vetäminen painamalla Enter-näppäintä.",
  "endDragTouch": "Vedetään. Peruuta vetäminen kaksoisnapauttamalla.",
  "endDragVirtual": "Vedetään. Peruuta vetäminen napsauttamalla.",
  "insertAfter": "Lisää kohteen {itemText} jälkeen",
  "insertBefore": "Lisää ennen kohdetta {itemText}",
  "insertBetween": "Lisää kohteiden {beforeItemText} ja {afterItemText} väliin"
}
