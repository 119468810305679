import React, { FC } from 'react';

// Common Dependencies
import { QuietButton, StyleProps } from '@leagueplatform/genesis-commons';
import { Button, Icon } from '@leagueplatform/genesis-core';

// Hooks

// Common Components
import { ViewTransitionController } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { CircleButton } from './circle-button.component';

// Constants
import { TRANSITION_DURATION_MS } from '../../constants/multi-step-footer-constants';
import { OrderNewCardFormValues } from '../../types/order-new-card-form.types';

const { SHORT } = TRANSITION_DURATION_MS;

// Types
enum FooterButtonTypes {
  Check = 'Check',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Loading = 'Spinner',
}

interface ButtonProps {
  currentPage: number;
  totalPages: number;
  disabled?: boolean;
  onClick(): unknown;
  css: StyleProps;
  isLoading?: boolean;
  onSubmit?: (data: OrderNewCardFormValues) => void;
}

interface CircleIconProps {
  css: StyleProps;
  disabled?: boolean;
  icon: string;
  onClick(): unknown;
  type?: string;
  label?: string;
}

interface SubmitButtonProps {
  disabled?: boolean;
  onClick(): unknown;
  buttonText: string;
}

interface TransitionView {
  view: React.FC<any>;
  props?: Record<string, any>;
}

type TransitionViews = Partial<Record<FooterButtonTypes, TransitionView>>;

interface ButtonTransitionProps {
  views: TransitionViews;
  handleActiveView(): FooterButtonTypes | '';
  css: StyleProps;
}

const ButtonTransition: FC<ButtonTransitionProps> = ({
  views,
  handleActiveView,
  css,
}) => (
  <ViewTransitionController
    views={views}
    handleActiveView={handleActiveView}
    css={css}
    duration={SHORT}
    transitionType="fade"
  />
);

const CircleIcon = ({
  icon,
  onClick,
  disabled,
  css,
  type,
  label,
}: CircleIconProps) => (
  <CircleButton
    as={QuietButton}
    onClick={onClick}
    disabled={disabled}
    css={css}
    type={type}
    label={label}
  >
    <Icon icon={icon} css={{ display: 'block' }} />
  </CircleButton>
);

const SubmitButton = ({
  onClick,
  disabled,
  buttonText,
  ...props
}: SubmitButtonProps) => (
  <Button
    priority="primary"
    onClick={onClick}
    disabled={disabled}
    css={{
      paddingY: '$threeQuarters',
      textTransform: 'uppercase',
      textWrap: 'nowrap',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {buttonText}
  </Button>
);

export const LeftButton: FC<
  Pick<ButtonProps, 'currentPage' | 'onClick' | 'css' | 'disabled'>
> = ({ currentPage, onClick, css, disabled }) => {
  const buttons: TransitionViews = {
    [FooterButtonTypes.ArrowLeft]: {
      view: CircleIcon,
      props: {
        icon: 'interfaceArrowLeft',
        disabled,
        onClick,
        label: 'Back',
        type: 'button',
      },
    },
  };

  const handleActiveButton = () => {
    if (currentPage) return FooterButtonTypes.ArrowLeft;
    return '';
  };

  return (
    <ButtonTransition
      views={buttons}
      handleActiveView={handleActiveButton}
      css={css}
    />
  );
};

export const RightButton: FC<ButtonProps> = ({
  currentPage,
  totalPages,
  disabled,
  onClick,
  css,
  isLoading,
  onSubmit,
}) => {
  const commonProps = { onClick, disabled };
  const { formatMessage } = useIntl();

  const buttons: TransitionViews = {
    [FooterButtonTypes.ArrowRight]: {
      view: CircleIcon,
      props: {
        icon: 'interfaceArrowRight',
        label: 'Next',
        type: 'button',
        ...commonProps,
      },
    },
    [FooterButtonTypes.Check]: {
      view: SubmitButton,
      props: {
        type: 'submit',
        onSubmit,
        buttonText: formatMessage({ id: 'ORDER_CARD' }),
        loading: isLoading,
        ...commonProps,
      },
    },
    [FooterButtonTypes.Loading]: {
      view: SubmitButton,
      props: {
        buttonText: formatMessage({ id: 'ORDER_CARD' }),
        ...commonProps,
      },
    },
  };

  const isLastPage = currentPage === totalPages - 1;

  const handleActiveButton = () => {
    if (isLastPage) return FooterButtonTypes.Check;
    if (isLoading) return FooterButtonTypes.Loading;
    return FooterButtonTypes.ArrowRight;
  };

  return (
    <ButtonTransition
      views={buttons}
      handleActiveView={handleActiveButton}
      css={css}
    />
  );
};
