import React, { PropsWithChildren, createContext } from 'react';
import { ThemeContainer } from './theme-container';
import { EnableGenesisCoreInspector } from '../constants';

interface GDSThemeProviderProps {
  theme: any;
  children: React.ReactNode;
}

export const ThemeContext = createContext<any>(undefined);

const getGenesisCoreInspectorStatus = () =>
  window.localStorage.getItem(EnableGenesisCoreInspector) === 'true';

export const GDSThemeProvider: React.FunctionComponent<
  PropsWithChildren<GDSThemeProviderProps>
> = ({ theme, children }) => {
  if (theme === undefined) {
    throw new Error('GDSThemeProvider requires a theme object.');
  }

  const isGenesisCoreInspectorEnabled = getGenesisCoreInspectorStatus();

  if (isGenesisCoreInspectorEnabled) {
    console.warn(
      'Genesis Core Inspector is enabled. You may experience a layout shift in some block level elements that may appear inline due to the added Genesis Core Inspector styles.',
    );
  }

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeContainer theme={theme}>{children}</ThemeContainer>
    </ThemeContext.Provider>
  );
};
