import {
  UserChallengeRank,
  TeamChallengeRank,
} from '@leagueplatform/health-journey-api';
import { formatUserName } from 'utils/format-user-name.util';
import { UserProfileAggregate } from '@leagueplatform/user-profile-api';
import { IntlFormatters } from 'react-intl';
import { isTeamRank } from './is-team-rank.util';

export const getDisplayedRankDetails = (
  leaderboardRank: UserChallengeRank | TeamChallengeRank,
  userData: UserProfileAggregate,
  formatMessage: IntlFormatters['formatMessage'],
) => {
  const { rank, id, avatarUrl, rankingUnit, score } = leaderboardRank;

  let isCurrentUser = false;
  let isCurrentTeam = false;
  let firstName = '';
  let lastName = '';
  let teamName = '';
  let user: UserChallengeRank['user'] | undefined;

  if (isTeamRank(leaderboardRank)) {
    teamName = leaderboardRank.teamName;
    isCurrentTeam = !!leaderboardRank.challengeTeam?.data?.id;
  } else {
    firstName = leaderboardRank.firstName;
    lastName = leaderboardRank.lastName;
    user = leaderboardRank.user;
    isCurrentUser = user?.data?.id === userData?.user_id;
  }

  const formattedName =
    firstName?.length && lastName?.length
      ? formatUserName({ firstName, lastName })
      : teamName;

  const currentUserOrTeamName = isTeamRank(leaderboardRank)
    ? formatMessage({ id: 'LEADERBOARD_CURRENT_TEAM_NAME' }, { team: teamName })
    : formatMessage(
        { id: 'LEADERBOARD_CURRENT_USER_NAME' },
        { user: formattedName },
      );

  const isHighlightedRank = isCurrentUser || isCurrentTeam;

  return {
    avatarUrl,
    currentUserOrTeamName,
    firstName,
    formattedName,
    id,
    isCurrentTeam,
    isCurrentUser,
    isHighlightedRank,
    lastName,
    rank,
    teamName,
    rankingUnit,
    score,
  };
};
