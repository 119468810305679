import * as React from 'react';
import { Image } from '@leagueplatform/genesis-commons';
import {
  Button,
  HeadingText,
  Modal,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

interface GenericActionModalProps {
  title?: string;
  description?: string;
  imageSrc?: string;
  buttonText?: string;
  phoneNumber?: string;
}

export const GenericContentModal = ({
  title,
  description,
  imageSrc,
  buttonText,
  phoneNumber,
}: GenericActionModalProps) => {
  const { formatMessage } = useIntl();

  // mmoore: safety check since the BE /could/ return empty strings for these values
  const modalTitle = title && title?.length > 0 ? title : undefined;
  const modalDescription =
    description && description?.length > 0 ? description : undefined;

  return (
    <Modal.Content
      showCloseButton
      css={{
        '.GDS-modal': {
          maxHeight: '60vh',
          margin: '0 auto',
          textAlign: 'center',
        },
        '.GDS-modal-content * + *': {
          marginTop: '$one',
        },
      }}
    >
      {imageSrc && <Image src={imageSrc} alt="" width="auto" />}
      <Modal.Title>
        {modalTitle && (
          <HeadingText
            size="xl"
            level="2"
            css={{ marginBottom: '$oneAndHalf' }}
          >
            {modalTitle}
          </HeadingText>
        )}
      </Modal.Title>
      <HtmlToReact htmlString={modalDescription} />
      {phoneNumber && (
        <ParagraphText>
          <strong>
            {formatMessage({ id: 'PLEASE_CALL' }, { phoneNumber })}
          </strong>
        </ParagraphText>
      )}
      <Modal.Close>
        <Button
          priority="primary"
          width="fillContainer"
          css={{ marginTop: '$four' }}
        >
          {buttonText || formatMessage({ id: 'CLOSE' })}
        </Button>
      </Modal.Close>
    </Modal.Content>
  );
};
