import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { StyledImg } from '../common';

export type ImageAlignLargeImageCard = 'left' | 'right';

export type HorizontalLargeImageCardProps = {
  image: string;
  imageAltText: string;
  children: React.ReactElement;
  imageAlign?: ImageAlignLargeImageCard;
};

export const HorizontalLargeImageCard = ({
  image,
  imageAltText,
  children,
  imageAlign = 'left',
}: HorizontalLargeImageCardProps) => {
  const isImageAlignLeft = imageAlign === 'left';

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      spacing="$oneAndHalf"
      verticalAlignment="center"
      css={{
        flexFlow: isImageAlignLeft ? 'row-reverse' : 'row',
      }}
    >
      {children}
      <StyledImg
        borderTopLeftRadius={isImageAlignLeft && 'medium'}
        borderBottomLeftRadius={isImageAlignLeft && 'medium'}
        borderTopRightRadius={!isImageAlignLeft && 'medium'}
        borderBottomRightRadius={!isImageAlignLeft && 'medium'}
        borderStyle="solid"
        src={image}
        width="350px"
        minHeight="176px"
        height="100%"
        alt={imageAltText}
      />
    </StackLayout>
  );
};
