import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Image } from '@leagueplatform/genesis-commons';
import CHEVRON from 'assets/chevron-down.svg';

export const LanguageBar = ({ onClick, isOpen, selectedLanguage }) => {
  let shortLocale = selectedLanguage;
  if (selectedLanguage.includes('-')) {
    shortLocale = selectedLanguage.substring(0, selectedLanguage.indexOf('-'));
  }

  return (
    <Flex
      as="button"
      background="none"
      onClick={onClick}
      border="none"
      alignItems="center"
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-controls="language_selector_dropdown_menu"
      focusStyle={{
        outline: 0,
        boxShadow: 'focusRing',
        borderWidth: 'thin',
        borderColor: 'decorative.brand.primary.bright',
      }}
      cursor="pointer"
    >
      <Text
        as="p"
        color="onSurface.text.subdued"
        fontSize="subtitle3"
        display="inline"
        marginRight="half"
        fontWeight="medium"
        hoverStyle={{
          color: 'onSurface.text.primary',
        }}
      >
        {shortLocale.toUpperCase()}
      </Text>
      <Image
        src={CHEVRON}
        alt=""
        height="threeQuarters"
        transition="transform 0.5s ease"
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
        role="presentation"
      />
    </Flex>
  );
};

LanguageBar.defaultProps = { isOpen: false };

LanguageBar.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};
