import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { flexbox, system, compose } from 'styled-system';
import { Box, BoxProps } from '../Box';
import {
  AlignContentProps,
  AlignItemsProps,
  JustifyContentProps,
  JustifyItemsProps,
  FlexDirectionProps,
  FlexWrapProps,
  FlexGenericProps as _FlexProps,
  FlexGrowProps,
  FlexShrinkProps,
  FlexBasisProps,
  JustifySelfProps,
  AlignSelfProps,
  OrderProps,
  SystemPropType,
  GapProps,
} from '../theme/types';

type FlexDisplayType = SystemPropType<
  'flex' | 'inline-flex' | 'inline' | 'block' | 'inline-block'
>;

export interface FlexProps
  extends BoxProps,
    AlignContentProps,
    AlignItemsProps,
    JustifyContentProps,
    JustifyItemsProps,
    FlexDirectionProps,
    FlexWrapProps,
    _FlexProps,
    FlexGrowProps,
    FlexShrinkProps,
    FlexBasisProps,
    JustifySelfProps,
    AlignSelfProps,
    OrderProps,
    GapProps {
  display?: FlexDisplayType;
}

export const FlexBase: React.FunctionComponent<FlexProps> = styled(
  Box
)<FlexProps>`
  ${compose(
    flexbox,
    system({
      display: {
        property: 'display',
        defaultScale: {
          flex: 'flex',
          block: 'flex',
          'inline-flex': 'inline-flex',
          'inline-block': 'inline-flex',
          inline: 'inline-flex',
        },
        transform: (value, scale) => scale?.[value] ?? 'flex',
      },
      gap: {
        property: 'gap',
        scale: 'space',
      },
      columnGap: {
        property: 'columnGap',
        scale: 'space',
      },
      rowGap: {
        property: 'rowGap',
        scale: 'space',
      },
    })
  )}
` as React.FunctionComponent<FlexProps>;

/**
 * @deprecated use `StackLayout` or `Box` from `genesis-core` instead
 */

export const Flex: React.FunctionComponent<FlexProps> = forwardRef(
  ({ display = 'flex' as FlexDisplayType, ...props }, ref) => (
    <FlexBase ref={ref} display={display} {...props} />
  )
);

Flex.displayName = 'Flex';
