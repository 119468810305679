import React from 'react';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';

type TooltipProps = ReturnType<typeof useVisxTooltip>;

export type TooltipContextProps = TooltipProps | undefined;

const TooltipContext = React.createContext<TooltipContextProps | undefined>(
  undefined,
);
TooltipContext.displayName = 'TooltipContext';

export type TooltipProviderProps = {
  children: React.ReactNode;
};

export const TooltipProvider = TooltipContext.Provider;

export function useTooltip() {
  const context = React.useContext(TooltipContext);
  if (context === undefined) {
    throw new Error('useTooltip must be used within a TooltipProvider');
  }
  return context;
}
