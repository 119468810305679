import { parseUrl } from '@leagueplatform/web-common';
import {
  HEALTH_PROGRAM,
  LIFEMARKET,
} from '../constants/health-solution-types.constants';

/**
 *
 * Note: Use query param `c=1` to hide back button if opened in tab.
 *
 * @param {string} link uri for either employer benefit, health program, marketplace offering or health content.
 * Includes experience root path, like `/member` for all link types except health content which represents an external link
 */
export const getHealthSolutionUrl = (
  link: string,
  type: string,
  lifemarketUrl: string = '',
): string => {
  switch (type) {
    case LIFEMARKET: {
      let path = parseUrl(link).pathname;
      if (path.charAt(0) === '/') {
        // remove leading slash
        path = path.substr(1);
      }

      return `${lifemarketUrl}${path}`;
    }
    case HEALTH_PROGRAM: {
      return `/${link}?c=1`;
    }
    default:
      // Check if path already includes a leading slash, and return as-is if true
      if (parseUrl(link).pathname.charAt(0) === '/') {
        return link;
      }

      return `/${link}`;
  }
};
