import { has, get, set, cloneDeep } from 'lodash';
import type { GDSTheme } from 'theme/theme-types';

import { fallbackTokens } from './theme-fallback-values';

/**
 * Returns `true` if called in the context of a running static test (e.g., Jest/unit tests).
 *
 * Note: **will not return `true` in the context of an end-to-end test.**
 */
export const isNodeTestEnv = () => {
  /**
   * Separate the name of the property from the access expression below,
   * so that ESBuild can't replace it with a hard-coded string literal.
   */
  const nodeEnvPropName = 'NODE_ENV';
  return (
    typeof process !== 'undefined' && process.env?.[nodeEnvPropName] === 'test'
  );
};

const diffMessageTemplate = (
  diffList: string[],
  themeName?: string,
) => `Genesis Design System: Following tokens have been added to your ${
  themeName || ''
} theme with fallback values. For all fallback color tokens please ensure they meet WCAG 2.2 color contrast requirements when used in combination with other colors in your theme. You can update your theme if you wish to provide custom values for these tokens.

${diffList.join(`\n`)}
`;

export const getFallbackValues = (
  clientTheme: GDSTheme,
  themeName?: string,
) => {
  const clientThemeClone = cloneDeep(clientTheme);
  const fallbackTokensAdded: string[] = [];

  Object.entries(fallbackTokens).forEach(([key, value]) => {
    if (!has(clientThemeClone, key)) {
      const fallbackValue = get(clientThemeClone, fallbackTokens[key]);
      if (fallbackValue) {
        fallbackTokensAdded.push(`- ${key}: ${fallbackValue}`);
        set(clientThemeClone, key, fallbackValue);
      } else {
        fallbackTokensAdded.push(`- ${key}: ${value}`);
        set(clientThemeClone, key, value);
      }
    }
  });

  const diffMessage = diffMessageTemplate(fallbackTokensAdded, themeName);

  if (process.env.NODE_ENV === 'development' && !isNodeTestEnv()) {
    console.warn(diffMessage);
  }

  return clientThemeClone;
};
