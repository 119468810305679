import DOMPurify from 'dompurify';
import { UserWalletBenefitCard, CardImages } from '@leagueplatform/wallet-api';

const LEAGUE_STATIC_URL_REGEX = /https:\/\/app\.league\.com\/static/g;
/**
 * Cards have their images urls point absolutely to prod, which isn't great
 * Instead, use the absolute url for local and otherwise point use relative URL
 * */

export const updateCardHtmlUrls = (html: string) => {
  if (!html) {
    throw new Error('HTML is required');
  }

  return html.replace(LEAGUE_STATIC_URL_REGEX, '/static');
};

export const selectCardHtml = (
  data: UserWalletBenefitCard,
): CardImages | null => {
  // there's a discrepancy in how get_wallet and get_wallet_dependent_cards
  // return card data. before that's addressed in the BE, we have to check for both
  const dependentCardHtml = data.rendered_template?.front;
  const primaryMemberCardHtml = data.card_images?.front.src;

  const validHtml = primaryMemberCardHtml ?? dependentCardHtml;

  if (!validHtml) {
    return null;
  }

  const htmlFront = updateCardHtmlUrls(validHtml);

  return {
    front: {
      description: '',
      src: DOMPurify.sanitize(htmlFront, {
        FORCE_BODY: true,
        ALLOWED_ATTR: ['class', 'style', 'role'],
      }),
      type: 'html',
    },
  };
};
