import React, { forwardRef } from 'react';
import * as RadixPortal from '@radix-ui/react-portal';
import type { GDSComponent } from '../../types';
import { ThemeContainer } from '../../theme/theme-container';
import { useTheme } from '../../hooks/use-theme';

export interface GDSPortalProps extends GDSComponent {
  children: React.ReactNode;
  container?: RadixPortal.PortalProps['container'];
}

export const Portal = forwardRef<HTMLDivElement, GDSPortalProps>(
  ({ children, ...props }: GDSPortalProps, ref: React.Ref<HTMLDivElement>) => {
    const theme = useTheme();

    return (
      <RadixPortal.Root {...props} className="GDS-portal">
        <ThemeContainer theme={theme} ref={ref}>
          {children}
        </ThemeContainer>
      </RadixPortal.Root>
    );
  },
);
