import * as React from 'react';
import { Label as VisxLabel } from '@visx/annotation';
import { useTheme } from '@leagueplatform/genesis-commons';

export type LabelProps = Omit<
  React.ComponentProps<typeof VisxLabel>,
  'horizontalAnchor' | 'verticalAnchor'
> & {
  title: string;
};

/**
 * Wraps the visx Label component
 */
export function Label({ title, ...props }: LabelProps) {
  const theme = useTheme();

  return (
    <VisxLabel
      title={title}
      showAnchorLine={false}
      horizontalAnchor="middle"
      verticalAnchor="end"
      backgroundProps={{ rx: '4.8', ry: '4.8' }}
      backgroundPadding={{
        top: theme.space.quarter,
        bottom: theme.space.quarter,
      }}
      backgroundFill={theme.colors.decorative.brand.primary.brightest}
      fontColor={theme.colors.secondary.text.default}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
