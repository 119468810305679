import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { SYSTEM_TYPES } from '@leagueplatform/wallet-api';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { useUserSettingsQuery } from 'hooks/queries/use-user-settings-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';

export const useOverview = () => {
  const { userId, benefitId } = useWalletParams();
  const showDependentsMenuItemQuery = useUserSettingsQuery(
    (data) => data.show_dependents_menu_item,
  );

  const { data: hideSpendingAccountBalanceBanner } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_SPENDING_ACCOUNT_BALANCE_BANNER_HIDDEN,
  );

  const canEditDependents = !!showDependentsMenuItemQuery.data;

  const benefitQuery = useWalletDetailQuery(userId, benefitId);
  const dependents = benefitQuery.data?.dependents;
  const spendingAccount = benefitQuery.data?.spending_account;
  const cardType = benefitQuery.data?.type;

  const overviewBody = benefitQuery.data?.content_actions?.find(
    (action) => action.system_type === SYSTEM_TYPES.CoverageOverview,
  )?.body;

  const showSpendingAccountBalanceBanner =
    spendingAccount && !hideSpendingAccountBalanceBanner;

  return {
    canEditDependents,
    cardType,
    dependents,
    spendingAccount,
    showSpendingAccountBalanceBanner,
    body: overviewBody,
  };
};
