import React, { ReactNode } from 'react';
import { Box, BoxProps } from '../Box';
import {
  pickInputProps,
  pickStyleProps,
  pickGlobalHTMLProps,
} from '../utils/propFilters';
import { ErrorMessage } from '../Typography';
import { TextInput, TextInputProps } from '../Input';
import { Label, LabelText } from '../Label';
import { Hint } from './Hint';

export interface TextFieldProps
  extends BoxProps,
    TextInputProps,
    Partial<HTMLLabelElement> {
  name: string;
  label?: ReactNode;
  /**
   * @see - The text input's ID will fallback to
   * the value of its name attribute if an ID is not provided.
   * Please specify an ID for the text input if
   * its name attribute is not a unique value
   */
  id?: string;
  hint?: ReactNode;
}

/**
 * @deprecated use `FormField` from `genesis-core` instead
 */

export const TextField: React.FunctionComponent<TextFieldProps> = (props) => {
  const inputProps = pickInputProps(props);
  const wrapperProps = pickStyleProps(props);
  const globalHTMLProps = pickGlobalHTMLProps(props);
  const { label, hint, error, name, id: inputIdProp } = props;

  const inputId = inputIdProp || `${name}-Input`;
  const hintId = hint ? `${name}-Hint` : undefined;
  const errorMessageId = error ? `${name}-ErrorMessage` : undefined;
  const ariaDescribedby =
    error && hint ? `${errorMessageId} ${hintId}` : hintId || errorMessageId;

  return (
    <Box {...wrapperProps}>
      <Box marginBottom="quarter">
        <Label htmlFor={inputId}>
          <LabelText>{label}</LabelText>
        </Label>
        {hint && <Hint id={hintId}>{hint}</Hint>}
      </Box>
      <TextInput
        name={name}
        error={error}
        aria-describedby={ariaDescribedby}
        {...inputProps}
        {...globalHTMLProps}
        id={inputId}
      />
      {error && (
        <ErrorMessage id={errorMessageId} marginTop="quarter">
          {error}
        </ErrorMessage>
      )}
    </Box>
  );
};
