import React, { useMemo } from 'react';
import * as RadixAccordionPrimitive from '@radix-ui/react-accordion';
import { Box } from '@leagueplatform/genesis-core';
import { AccordionStyleProps } from './accordion-root.component';
import { AccordionItemContext } from './accordion-item.component';

export interface AccordionContentProps
  extends RadixAccordionPrimitive.AccordionContentProps,
    AccordionStyleProps {}

export const Content = React.forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ className, ...props }, forwardedRef) => {
    const { triggerId, contentId } = React.useContext(AccordionItemContext);
    const composedClassName = useMemo(
      () => ['cc-accordion-content', className].join(' '),
      [className],
    );

    return (
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        aria-labelledby={triggerId}
        as={RadixAccordionPrimitive.Content}
        className={composedClassName}
        id={contentId}
        ref={forwardedRef}
      />
    );
  },
);

Content.displayName = 'Accordion.Content';
