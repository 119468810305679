import {
  getGroup,
  GET_GROUP_MESSAGE_TYPE,
  getUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import { useQuery } from 'react-query';

const getGroupId = (userProfileData) =>
  userProfileData?.groups?.find(
    (group) => group.state === 'active' || group.state === 'initial',
  ).group_id;

export function useUserCountryCode() {
  const groupIdQuery = useQuery(
    [GET_USER_PROFILE_MESSAGE_TYPE],
    () => getUserProfile(),
    { select: (data) => getGroupId(data) },
  );

  const groupMessageTypeQuery = useQuery(
    [GET_GROUP_MESSAGE_TYPE],
    () => getGroup(String(groupIdQuery.data)),
    { enabled: !!groupIdQuery.data },
  );

  return groupMessageTypeQuery?.data?.group?.country_code;
}
