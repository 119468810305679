import { cloneDeep } from 'lodash';
import { defaultHTMLTagOptions, HTMLTagOptions } from './html-to-react.config';

/**
 * This function assumes that when we declare new options for a given tag that we want to maintain any default options that aren't being overwritten
 * @param {Object} options new options object to apply over the default options
 * @returns an options object containing with the new options with defaults
 */
export const applyOptionsWithDefaults = (
  newOptions: HTMLTagOptions = {},
  defaultOptions: HTMLTagOptions = cloneDeep(defaultHTMLTagOptions),
) =>
  Object.entries(newOptions).reduce(
    (aggregateOptions, [tagName, { component, props = {} }]) => {
      const tagDefaults = aggregateOptions?.[tagName];

      // Exit when neither a new component is provided, nor a default exists for a given tagName
      if (!component && !tagDefaults) return aggregateOptions;

      // Get default properties in tagDefaults
      const { component: defaultComponent, props: defaultProps = {} } =
        tagDefaults ?? {};

      // Combine the default option with the new option
      const combinedOption = {
        component: component ?? defaultComponent,
        props: { ...defaultProps, ...props },
      };

      // Add or overwrite the object for the tagName specified
      return { ...aggregateOptions, [tagName]: combinedOption };
    },
    defaultOptions,
  );
