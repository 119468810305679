import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingFour,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import { CoverageIcon, ExampleIcon, RestrictionIcon } from './icons.component';
import { NOT_COVERED } from '../constants/coverage.constants';
import { CoverageType } from '../types/coverage-type.type';

interface CategoryProps {
  name: string;
  coverage: CoverageType;
  description?: string;
  examples?: string[];
  restriction?: string;
  onCategorySelect: (id: string) => void;
  id: string;
  embedded: boolean;
}

export const Category = ({
  name,
  coverage,
  description,
  examples,
  restriction,
  onCategorySelect,
  id,
  embedded,
}: CategoryProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      onClick={() => {
        if (coverage !== NOT_COVERED) {
          onCategorySelect(id);
        }
      }}
      embedded={embedded}
      isCovered={coverage !== NOT_COVERED}
      backgroundColor="transparent"
    >
      <Box padding="one">
        <Flex marginBottom="one" alignItems="center">
          <HeadingFour
            as="h3"
            fontSize={['heading4', 'heading4', 'heading3']}
            fontWeight="regular"
          >
            <CoverageIcon coverage={coverage} embedded={embedded} />
            <Box as="span" marginLeft="one">
              {name}
            </Box>
          </HeadingFour>
        </Flex>
        {(description || examples || restriction) && (
          <Box paddingBottom="half" marginLeft={['none', 'none', 'two']}>
            {description && (
              <BodyOne paddingRight={['half', 'one', 'four']}>
                {description}
              </BodyOne>
            )}
            {examples && (
              <Box marginTop="one" paddingRight="two">
                <Flex alignItems="center" marginBottom="quarter">
                  <ExampleIcon role="presentation" />
                  <BodyOne fontWeight="regular" marginLeft="half">
                    {formatMessage({ id: 'EXAMPLES' })}
                  </BodyOne>
                </Flex>
                {examples.map((example) => (
                  <BodyOne key={example}>{example}</BodyOne>
                ))}
              </Box>
            )}
            {restriction && (
              <Box marginTop="one" paddingRight="two">
                <Flex alignItems="center" marginBottom="quarter">
                  <RestrictionIcon role="presentation" />
                  <BodyOne marginLeft="half" fontWeight="regular">
                    {formatMessage({ id: 'RESTRICTIONS' })}
                  </BodyOne>
                </Flex>
                <BodyOne>{restriction}</BodyOne>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};
