import React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { FocusAreaPage } from './pages/focus-area-page/focus-area-page.component';

export const HealthNeedPagesRoutes = ({
  noSolutionsMessage,
}: {
  noSolutionsMessage?: React.ReactNode;
}): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${url}/:category/:topic`}
        render={(routeProps) => (
          <FocusAreaPage
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...routeProps}
            // Temporary Solution to pass in the Chat Banner until Chat is modularized
            noSolutionsMessage={noSolutionsMessage}
          />
        )}
      />
    </Switch>
  );
};
