import React from 'react';

type ExternalRedirectProps = {
  to: string | URL;
};

/**
 * A component that redirects the user to an external link.
 * @param {string | URL} to - the external link the user should be redirected to
 * @returns {null}
 */
export const ExternalRedirect = ({ to }: ExternalRedirectProps): null => {
  React.useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
};
