export const createUserSelections = (questions) => {
  // set initial error state to false
  const initialErrorState = {
    error: {
      hasError: false,
      errorMsg: '',
    },
  };
  return questions.reduce((acc, question) => {
    const { userAnswers, questionType } = question;
    if (userAnswers) {
      switch (questionType) {
        case 'multiple_choice':
          // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
          const { multipleChoiceAnswers } = userAnswers;
          if (multipleChoiceAnswers)
            return {
              ...acc,
              [question.id]: {
                id: multipleChoiceAnswers.map(({ id }) => id),
                ...initialErrorState,
              },
            };
          break;
        case 'multiple_selection':
          // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
          const { multipleSelectionAnswers } = userAnswers;
          if (multipleSelectionAnswers)
            return {
              ...acc,
              [question.id]: {
                id: multipleSelectionAnswers.map(({ id }) => id),
                ...initialErrorState,
              },
            };
          break;
        case 'drop_down':
          // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
          const { dropDownAnswers } = userAnswers;
          if (dropDownAnswers)
            return {
              ...acc,
              [question.id]: {
                id: dropDownAnswers.map(({ id }) => id),
                value: dropDownAnswers[0].value,
                ...initialErrorState,
              },
            };
          break;
        case 'input':
          // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
          const value = userAnswers.inputAnswer?.value;
          // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
          const valueUnitType = userAnswers.inputAnswer?.valueUnitType;

          if (value || value === 0)
            return {
              ...acc,
              [question.id]: {
                value,
                unit: valueUnitType,
                ...initialErrorState,
              },
            };
          break;
        default:
      }
    }
    return { ...acc, [question.id]: { ...initialErrorState } };
  }, {});
};
