import { useQuery } from 'react-query';
import { getGroup, GET_GROUP_MESSAGE_TYPE } from '@leagueplatform/wallet-api';
import { getActiveGroup } from '@leagueplatform/web-common';
import {
  getUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';

export const useCurrentUserActiveGroup = () => {
  const { data: userProfileData } = useQuery(
    [GET_USER_PROFILE_MESSAGE_TYPE],
    getUserProfile,
  );

  const activeGroup = getActiveGroup(userProfileData?.groups) || null;

  return useQuery(
    [GET_GROUP_MESSAGE_TYPE, activeGroup?.group_id],
    () => getGroup(activeGroup?.group_id),
    { enabled: !!activeGroup?.group_id },
  );
};
