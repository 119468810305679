import { useClaimsQuery } from '@leagueplatform/claims-b2b';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useWalletConfig } from 'wallet.config';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { SELECTION_STATUS } from 'common/selection-status';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { useWalletQuery } from 'hooks/queries/use-wallet-query.hook';
import { useUserProfileQuery } from 'hooks/queries/use-user-profile-query.hook';
import { useUserActiveGroupQuery } from 'hooks/queries/use-user-active-group-query.hook';
import { useUserConfigQuery } from './queries/use-user-config-query.hook';
import { useIsNewHireEnrollmentQuery } from './queries/use-is-new-hire-enrollment.hook';
import { useWalletItemsSectionedQuery } from './queries/use-wallet-items-sectioned-query.hook';

export function useWalletLanding() {
  const { userId } = useWalletParams();

  const userConfigQuery = useUserConfigQuery(userId);
  const shouldShowSubmitClaims =
    userConfigQuery.data?.hide_submit_claims === false;
  const walletQuery = useWalletQuery(userId);
  const claimsQuery = useClaimsQuery(userId, {
    enabled: shouldShowSubmitClaims,
  });
  const isNewHireEnrollmentQuery = useIsNewHireEnrollmentQuery(userId);
  const walletItemsSectionedQuery = useWalletItemsSectionedQuery();

  // we need to pass in userId as `undefined` to get the current logged in user's role
  const { data: userRole } = useUserProfileQuery(undefined, {
    select: (data) => data.role,
  });
  const { data: isManageDependentsEnabled } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.MANAGE_DEPENDENTS_V2,
  );
  const { data: isGridLayoutEnabled } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.BENEFITS_LANDING_GRID_LAYOUT,
  );

  const { data: activeGroup } = useUserActiveGroupQuery(userId);
  const groupId = activeGroup?.group_id;

  const showQleAddDependentBanner =
    userConfigQuery.data?.show_add_dependents_with_qle ||
    userConfigQuery.data?.show_add_dependents_without_qle;

  const claims = claimsQuery.data?.claims;
  const showQLEBanner = showQleAddDependentBanner && isManageDependentsEnabled;
  const showClaims = claims && claims.length > 0;

  const isLoading = [
    userConfigQuery,
    walletQuery,
    claimsQuery,
    walletItemsSectionedQuery,
  ].some((query) => query.isLoading);

  const isError = [
    userConfigQuery,
    walletQuery,
    claimsQuery,
    walletItemsSectionedQuery,
  ].some((query) => query.isError);

  const { showLandingPageHeaderBranding, showLandingPageClaimsEntry, slots } =
    useWalletConfig();

  const benefitItemSections = walletItemsSectionedQuery.data?.items_sectioned;
  const isEmpty = !benefitItemSections?.length && !isLoading;
  const isNewHireEnrollment = isNewHireEnrollmentQuery.data || false;

  return {
    enrollmentEndDate: walletQuery.data?.enrollment_end_date,
    groupId,
    isFirstTimeEnrollment: walletQuery.data?.is_first_time_enrollment,
    isGridLayoutEnabled,
    isLoading,
    isError,
    isEmpty,
    isNewHireEnrollment,
    showClaims,
    showLandingPageHeaderBranding,
    showPlanSelections:
      walletQuery.data?.selection_status === SELECTION_STATUS.COMPLETED,
    showQLEBanner,
    userId,
    userRole,
    showLandingPageClaimsEntry,
    slots,
  };
}
