import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  TEAMS_PATH,
  CHALLENGE_PATCH_OPERATIONS,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

export const patchChallengeTeam = async (
  challengeTeamId: string,
  challengeId: string,
  operationType: CHALLENGE_PATCH_OPERATIONS,
  errorContext?: ErrorContext,
) => {
  const response = await leagueFetch(`${TEAMS_PATH}/${challengeTeamId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: challengeTeamId,
        type: CHALLENGE_DATA_TYPES.CHALLENGE_TEAM,
        attributes: {
          operationType,
        },
        relationships: {
          challenge: {
            data: {
              id: challengeId,
              type: CHALLENGE_DATA_TYPES.CHALLENGE,
            },
          },
        },
      },
    }),
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while patching!');
};
