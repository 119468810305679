import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_USER_BENEFIT_CATEGORIES_MESSAGE_TYPE =
  'get_user_benefit_categories';

export const getUserBenefitCategories = (
  userId?: string,
  benefitId?: string,
  asOfDate?: Date | string,
  query?: string | null,
  version: number = 1,
) => {
  const info = {
    user_id: userId,
    as_of_date: asOfDate,
    query,
    version,
    user_benefit_id: benefitId,
  };

  return SocketAsFetch.fetch({
    message_type: GET_USER_BENEFIT_CATEGORIES_MESSAGE_TYPE,
    info,
  });
};
