import { useQuery, UseQueryOptions } from 'react-query';
import {
  getUserBenefitCategories,
  getEmployerBenefitCategories,
  GET_EMPLOYER_BENEFIT_CATEGORIES_MESSAGE_TYPE,
  GET_USER_BENEFIT_CATEGORIES_MESSAGE_TYPE,
} from '@leagueplatform/wallet-api';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { Category } from '../../types/category.type';

interface CoverageQueryParams {
  userId?: string;
  benefitId?: string;
  asOfDate?: Date | string;
  query?: string | null;
  version?: number;
  employer?: boolean;
}
export interface CoverageQueryResponse {
  categories?: Category[];
}

export function useCoverageQuery(
  {
    asOfDate,
    query,
    version = 1,
    employer,
    userId,
    benefitId,
  }: CoverageQueryParams,
  options?: UseQueryOptions<CoverageQueryResponse, unknown, Category[]>,
) {
  const { userId: walletUserId, benefitId: walletBenefitId } =
    useWalletParams();
  const coverageUserId = userId ?? walletUserId;
  const coverageBenefitId = benefitId ?? walletBenefitId;

  // The coverage component is currently used in different places within the League app,
  // and in the Enrollment flow it needs to show `employer` benefit categories instead
  // of user categories.
  const getCoverageCategories = employer
    ? getEmployerBenefitCategories
    : getUserBenefitCategories;
  const messageType = employer
    ? GET_EMPLOYER_BENEFIT_CATEGORIES_MESSAGE_TYPE
    : GET_USER_BENEFIT_CATEGORIES_MESSAGE_TYPE;

  return useQuery<CoverageQueryResponse, unknown, Category[]>(
    [messageType, coverageUserId, benefitId, query, version],
    () =>
      getCoverageCategories(
        coverageUserId,
        coverageBenefitId,
        asOfDate,
        query,
        version,
      ),
    { select: (data) => data.categories ?? [], ...options },
  );
}
