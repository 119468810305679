import React from 'react';
import {
  Box,
  GDSStyleObject,
  styled,
  GDSSpacingValues,
} from '@leagueplatform/genesis-core';

export interface StackLayoutProps {
  space?: GDSSpacingValues;
  css?: GDSStyleObject;
  as?: React.ElementType;
}

const StackLayoutBox = styled(Box, {
  '&& > * + *': {
    marginTop: '$$space',
  },
});

/**
 * @deprecated use `StackLayout` from `genesis-core` instead
 */

export const StackLayout: React.FC<StackLayoutProps> = ({
  space = '$one',
  css,
  children,
  as: asElement,
}) => (
  <StackLayoutBox as={asElement} css={{ ...css, $$space: `$space${space}` }}>
    {children}
  </StackLayoutBox>
);
