/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, type RefCallback } from 'react';
import {
  ObservabilityErrorBoundary,
  captureError,
  captureMessage,
} from '@leagueplatform/observability';
import { useQuery } from 'react-query';
import { useMasonryEngineConfig } from '../masonry-engine-config-context';
import type {
  AnyMasonryEngineNode,
  MasonryEngineNode,
  MasonryEngineNodeAction,
  MasonryEngineNodeRenderer,
  MasonryEngineNodeRendererProps,
} from '../types/masonry-engine-node.types';
import { logError } from '../utils/log';
import { getMasonryEngineObservabilityContext } from '../utils/masonry-engine-observability-content';
import { MasonryEngineNodeAncestryProvider } from '../masonry-engine-node-ancestry-context';
import { useMasonryEngineActionEmitter } from '../masonry-engine-action-controller';
import {
  DEFAULT_NAMESPACE,
  MasonryEngineLevelActionNames,
} from '../constants/constants';
import {
  INITIAL_COMMON_STATE,
  MasonryEngineNodeStateFromNode,
  useStateControllerStore,
} from '../masonry-engine-state-controller';
import {
  MasonryNodeMessageReceiver,
  MasonryNodeReloadMessage,
} from '../types/masonry-engine-message.types';

const QUERY_KEY_GET_NODE_ASYNC_SELF = 'MASONRY_ENGINE_GET_NODE_ASYNC_SELF';

type NodeResolverProps<Node extends MasonryEngineNode> = {
  node: Node;
};

/**
 * Given a {@link MasonryEngineNode `MasonryEngineNode`} and a {@link MasonryEngineRendererMap `MasonryEngineRendererMap`}, executes the node's
 * data query and passes the results to the appropriate renderer when ready.
 */
export const NodeResolver = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>({
  node: originalNode,
}: NodeResolverProps<Node>) => {
  /**
   * Grab controllers from engine context.
   */
  const { nodeRenderers, stateControllerStore, messagingController } =
    useMasonryEngineConfig<Node, Action>();

  /* --------- BEGIN ASYNC NODE LOGIC -------- */

  /**
   * we will store whatever query params object was sent to us in the latest
   * `reload` message, if any.
   *
   * We start with an empty object.
   */
  const queryParamsRef = useRef<Record<string, string>>({});

  const {
    error,
    /**
     * "fresh", as in fetched asynchronously if this is an async node, or simply
     * the original node otherwise (since in that context, original IS "fresh").
     */
    data: freshNode,
    isFetching,
    refetch,
  } = useQuery(
    [QUERY_KEY_GET_NODE_ASYNC_SELF, originalNode.id, queryParamsRef.current],
    async () => {
      /**
       * If this node has no `getAsyncSelf` (meaning, this is a sync node),
       * the query will simply return `originalNode`.
       */
      const newNodeData =
        (await originalNode.getAsyncSelf?.(queryParamsRef.current)) ||
        originalNode;

      const { setInitialNodeState, getNodeState, setNodeState } =
        stateControllerStore.getState();

      if (getNodeState(originalNode.id)) {
        setNodeState(originalNode.id, (current) => ({
          ...current,
          ...newNodeData.properties,
        }));
      } else {
        /**
         * This is the ideal opportunity to initialize/re-initialize
         * this node's state in the state controller, because it is only on the
         * calling of this query that new node property values may arrive.
         */
        setInitialNodeState(newNodeData.id, {
          ...newNodeData.properties,
          ...INITIAL_COMMON_STATE,
        });
      }

      return newNodeData;
    },
    {
      /**
       * we WANT to fetch when this component mounts.
       */
      refetchOnMount: true,
      /**
       * we DO NOT WANT to fetch when the tab/window is re-focused or when
       * an offline client reconnects, because we don't want to overwrite any
       * state changes that may have occured client-side since this component mounted.
       */
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  /**
   * If the query above hasn't run yet, we can just use `originalNode`. Otherwise,
   * we can safely use `freshNode`. From this point onwards, we can just refer
   * to `node`.
   */
  const node: AnyMasonryEngineNode = freshNode || originalNode;

  /* ---------- END ASYNC NODE LOGIC --------- */

  /* --------- BEGIN ENGINE-LEVEL ACTION LOGIC -------- */

  const invokeAction = useMasonryEngineActionEmitter(node.id);

  /**
   * Keep track of whether we have invoked engine-level actions yet.
   */
  const hasInvokedEngineActionsRef = useRef(false);

  useEffect(() => {
    if (!hasInvokedEngineActionsRef.current && node.actions?.onLoad) {
      invokeAction(node.actions.onLoad);
      hasInvokedEngineActionsRef.current = true;
    }
  }, [node.actions?.onLoad, invokeAction]);

  /* ---------- END ENGINE-LEVEL ACTION LOGIC --------- */

  const rootElementRef = useRef<HTMLElement>();

  const rootElementRefCallback: RefCallback<HTMLElement> = (instance) => {
    if (instance) {
      rootElementRef.current = instance;
    }
  };

  /* --------- BEGIN RELOAD LOGIC -------- */

  /**
   * Will be set to `true` right before calling `refetch` in the `reload` message
   * handler below, and set to `false` right after `refetch` resolves.
   */
  const isReloadHandlerInProgressRef = useRef(false);

  /**
   * We consider ourselves "reloading" if `react-query` is currently fetching the query
   * AND that's happening specifically because the `refetch()` method was called
   * by the reload handler and not resolved yet.
   */
  const isReloading = isFetching && isReloadHandlerInProgressRef.current;

  /**
   * This will store the "resolve" callback of a deferred promise awaited by the
   * `reload` message handler below.
   */
  const reloadPromiseResolverRef = useRef<() => void>();

  useEffect(() => {
    if (!isReloading) {
      /**
       * If we just re-rendered and are not in a reloading state,
       * then resolve the deferred promise being awaited by the `reload` message handler
       * (should one exist).
       */
      reloadPromiseResolverRef.current?.();
      reloadPromiseResolverRef.current = undefined;
    }
  }, [isReloading]);

  /**
   * We will use this handler in our message receiver, so that when we receive
   * a `reload` message, we can execute it.
   */
  const reloadHandler = useCallback(
    async (messagePayload: MasonryNodeReloadMessage['payload']) => {
      if (!originalNode.getAsyncSelf) {
        throw new Error('Trying to reload a sync node!');
      }

      if (messagePayload.setQueryParams) {
        /**
         * Before initiating a refetch, update the query params object we stored in the ref
         * so that the resulting query includes its contents in the network request.
         */
        queryParamsRef.current = messagePayload.setQueryParams(
          queryParamsRef.current,
        );
      }

      isReloadHandlerInProgressRef.current = true;

      /**
       * Create a deferred promise, by extracting its resolver and placing it
       * in the ref above, so that an external process (the component re-rendering
       * after `refetch()`) can call it to resolve the promise.
       */
      const reloadPromise = new Promise<void>((resolve) => {
        reloadPromiseResolverRef.current = resolve;
      });
      await refetch();

      isReloadHandlerInProgressRef.current = false;

      /**
       * Now, await the deferred promise we created. Thanks to the `useEffect()` above,
       * when the query finishes AND the component re-renders with new data, this promise
       * will be resolved, marking the true end of the `reload` message handling logic.
       */
      await reloadPromise;
    },
    [originalNode.getAsyncSelf, refetch],
  );

  /* ---------- END RELOAD LOGIC --------- */

  /* --------- START STATE LOGIC --------- */

  const stateFromController = useStateControllerStore((store) =>
    store.getNodeState(node.id),
  );

  /**
   * `resolvedState` is the combination of this node's properties and its state.
   */
  const resolvedState: MasonryEngineNodeStateFromNode<AnyMasonryEngineNode> =
    /**
     * When reloading we always use the properties of `originalNode`, since we want
     * to show loaders during this time.
     */
    isReloading
      ? { ...originalNode.properties, ...INITIAL_COMMON_STATE }
      : /**
         * Otherwise, if we've already set this node's state in the state controller
         * during a previous render, we will simply use that.
         */
        stateFromController || /**
         * But if this is the first render and this node's state has not yet been set
         * in the state controller, we will use its properties and spread our initial
         * common state values (e.g., `isVisible: true`).
         */ { ...node.properties, ...INITIAL_COMMON_STATE };

  /* ---------- END STATE LOGIC ---------- */

  /* ---------- START MESSAGE LOGIC ---------- */

  /**
   * When we mount, we create a message receiver and register it with the
   * messagingController. This receiver will receive messages sent to this
   * node/widget by whoever.
   *
   * To clean up, we remove the receiver from the map.
   */
  useEffect(() => {
    const messageReceiver: MasonryNodeMessageReceiver = async (message) => {
      switch (message.type) {
        case 'reload':
          await reloadHandler(message.payload);
          break;
        case 'focus':
          if (rootElementRef.current) {
            const originalTabIndex = rootElementRef.current.tabIndex;
            rootElementRef.current.tabIndex = -1;
            rootElementRef.current.focus();
            rootElementRef.current.tabIndex = originalTabIndex;
          }
          break;
        case 'serverAction':
          await invokeAction(message.payload.action);
          break;
        default:
          /**
           * This default case is only here so that TypeScript will warn us
           * if there is a message type we did not explicitly handle.
           */
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: never = message;
          captureMessage(`Received unhandled node message ${exhaustiveCheck}`, {
            severityLevel: 'warning',
          });
      }
    };

    messagingController.registerReceiver(node.id, messageReceiver);

    return () => {
      messagingController.unregisterReceiver(node.id);
    };
  }, [invokeAction, node.id, messagingController, reloadHandler]);

  /* ---------- END MESSAGE LOGIC ---------- */

  if (error) {
    logError(
      `Error while making api call for node id ${originalNode.id} ${error}`,
    );
    captureError(
      new Error(
        `MasonryEngine - Error while making api call for node id ${originalNode.id} ${error}`,
      ),
      getMasonryEngineObservabilityContext({}),
    );
    return null;
  }

  /**
   * Hide from view if `state.isVisible` is `false`.
   */
  if (!resolvedState.isVisible) return null;

  /**
   * Assigning 'default' namespace if no namespace is provided for a node
   */
  const nodeNamespace = node.namespace || DEFAULT_NAMESPACE;

  const nodeRendererMapForNamespace = nodeRenderers[nodeNamespace];

  /**
   * When refetching we always use the type of `originalNode`, since we want
   * to show loaders during this time.
   */
  const nodeType = !isReloading ? node.type : originalNode.type;

  const NodeRenderer = nodeRendererMapForNamespace?.[
    nodeType as keyof typeof nodeRendererMapForNamespace
  ] as MasonryEngineNodeRenderer<MasonryEngineNode> | undefined;

  if (!NodeRenderer) {
    logError(
      `Could not find renderer for namespace - ${nodeNamespace} & node type - ${node.type} !`,
    );
    captureError(
      new Error(
        `MasonryEngine - Could not find renderer for namespace - ${nodeNamespace} & node type - ${node.type} !`,
      ),
      getMasonryEngineObservabilityContext({}),
    );
    return null;
  }

  /**
   * Converting each section item of type {@link MasonryEngineNode} into a section of NodeResolver component.
   */
  const resolvedSections = node.sections
    ? Object.fromEntries(
        Object.entries(node.sections).map(([section, subnodes]) => [
          section,
          (subnodes as Array<MasonryEngineNode>).map((subnode) => (
            <NodeResolver key={subnode.id} node={subnode} />
          )),
        ]),
      )
    : {};

  /* Filter out Masonry Engine specific actions and pass rest of
     the actions to the node renderer. */
  const filteredNodeActions = node.actions
    ? Object.fromEntries(
        Object.entries(node.actions).filter(
          ([key]) => !MasonryEngineLevelActionNames.includes(key),
        ),
      )
    : {};

  // Needed to merge properties from original node and properties from api
  // response of node in order to share properties passed by the parent node
  const nodeRendererProps: MasonryEngineNodeRendererProps<MasonryEngineNode> = {
    ...resolvedState,
    ...resolvedSections,
    ...filteredNodeActions,
    rootElementRef: rootElementRefCallback,
    nodeId: node.id,
  };

  return (
    <MasonryEngineNodeAncestryProvider nodeId={node.id || originalNode.id}>
      <ObservabilityErrorBoundary
        errorContext={getMasonryEngineObservabilityContext({
          tags: {
            errorName: `MasonryEngine - Error while rendering Masonry Engine Node id: ${originalNode.id}`,
          },
        })}
        onError={(err: Error) => {
          logError(
            `Error while rendering Masonry Engine Node id: ${originalNode.id} ${err.message}`,
          );
        }}
      >
        <NodeRenderer {...nodeRendererProps} />
      </ObservabilityErrorBoundary>
    </MasonryEngineNodeAncestryProvider>
  );
};
