import { UserWalletBenefitCard } from '@leagueplatform/wallet-api';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';

const selectMemberOptions = (userId: string, data: UserWalletBenefitCard) => {
  const primaryUserOption = {
    label: `${data.member_first_name} ${data.member_last_name}`,
    value: userId,
  };
  const dependentOptions =
    data.dependents
      ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map((dependent) => ({
        label: `${dependent.first_name} ${dependent.last_name}`,
        value: dependent.dependent_id,
      })) ?? [];

  return [primaryUserOption, ...dependentOptions];
};

export const useSelectMembersStep = () => {
  const { benefitId } = useWalletParams();

  const { data: userProfile } = useGetUserProfile();
  const { data: memberOptions = [], isLoading } = useWalletDetailQuery(
    userProfile?.userId,
    benefitId,
    undefined,
    {
      select: (data: UserWalletBenefitCard) =>
        selectMemberOptions(userProfile?.userId || '', data),
    },
  );
  return { memberOptions, isLoading };
};
