import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);
  useEffect(() => {
    const eventListener = (e: MediaQueryListEvent) => setMatches(e.matches);
    const matchedMediaList = window.matchMedia(query);
    matchedMediaList.addEventListener('change', eventListener);
    return () => matchedMediaList.removeEventListener('change', eventListener);
  }, [query]);

  return matches;
};
