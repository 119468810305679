import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import {
  Box,
  Icon,
  StackLayout,
  UtilityText,
  Card,
  useClickableCard,
  TextAction,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ABOUT_ME_ASSET_KEYS } from 'types/about-me-assets.type';

export interface ViewMyHealthDashboardButtonProps {
  handleClickAnalytics: (string: string) => void;
}

export const ViewMyHealthDashboardButton = ({
  handleClickAnalytics,
}: ViewMyHealthDashboardButtonProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const icon = handleStaticAsset(
    ABOUT_ME_ASSET_KEYS.ABOUT_ME_VIEW_MY_HEALTH_DASHBOARD_BUTTON_ICON,
  ) as string;
  return (
    <Card.Elevated imagePosition={{ '@initial': 'start' }} css={{ border: 0 }}>
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        <Card.Section css={{ padding: 0 }}>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="spaceBetween"
            verticalAlignment="center"
          >
            {!isMobile && (
              <Box
                css={{
                  backgroundColor: '$interactiveActionPrimary',
                  padding: '$one',
                }}
              >
                <Icon icon={icon} size="32" css={{ display: 'block' }} />
              </Box>
            )}
            <StackLayout
              spacing="$quarter"
              css={{ padding: '$half $none $half $one' }}
            >
              <TextAction
                as={Link}
                to={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
                ref={primaryActionRef}
                onClick={() => handleClickAnalytics('view my progress')}
              >
                {formatMessage({ id: 'VIEW_MY_HEALTH_DASHBOARD' })}
              </TextAction>
              <UtilityText size="xs" emphasis="subtle">
                {formatMessage({ id: 'LATEST_INSIGHTS_METRICS_AND_MORE' })}
              </UtilityText>
            </StackLayout>
            <Box css={{ padding: '$one' }}>
              <Icon
                icon="interfaceChevronRight"
                size={22}
                tint="$onSurfaceIconPrimary"
              />
            </Box>
          </StackLayout>
        </Card.Section>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
