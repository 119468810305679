import {
  CSChallengeActivitiesSortType,
  CSHealthActivityStatusType,
} from '@leagueplatform/health-journey-common';

export enum ACTIVITY_PROGRESS_PROPERTIES {
  ACTIVITY_PROGRESS = 'activity_verification_progress',
  COUNT_PROGRESS = 'count_progress',
  HELPFUL_TIPS = 'helpful_tips',
}

export const UserHealthActivitiesEndpoints = {
  userHealthActivities: '/v1/user-health-activities',
  questionComponentResponses: '/v1/question-component-responses',
  toolboxInteractionHub: '/v1/toolbox-interaction-hub',
  userHealthJourneyHistory: `/v2/user-health-journey-history`,
  userHealthActivity(
    userActivityId?: string,
    campaignId?: string,
    contentActivityId?: string,
    userId?: string,
    userHealthCampaignId?: string,
    status: CSHealthActivityStatusType | CSHealthActivityStatusType[] = [
      'active',
      'completed',
      'removed',
    ],
    sortBy?: CSChallengeActivitiesSortType[],
  ) {
    if (campaignId && userActivityId && userId) {
      return `${this.userHealthActivities}?userId=${userId}&filter[activityId]=${userActivityId}&filter[campaignId]=${campaignId}`;
    }
    if (userHealthCampaignId && userId) {
      const statuses = Array.isArray(status) ? status.join(',') : status;
      if (sortBy) {
        return `${this.userHealthActivities}?userId=${userId}&sort=${sortBy}&filter[status]=${statuses}&filter[userHealthCampaignId]=${userHealthCampaignId}`;
      }
      return `${this.userHealthActivities}?userId=${userId}&filter[status]=${statuses}&filter[userHealthCampaignId]=${userHealthCampaignId}`;
    }
    if (campaignId && contentActivityId) {
      return `${this.userHealthActivities}?filter[status]=active,completed&filter[campaignId]=${campaignId}&filter[activityId]=${contentActivityId}`;
    }
    if (userActivityId) {
      return `${this.userHealthActivities}/${userActivityId}?include=user-health-campaign`;
    }
    return this.userHealthActivities;
  },
};
