import React, { forwardRef, FunctionComponent } from 'react';
import css from '@styled-system/css';
import { trueOrUndefined } from '../../utils/trueOrUndefined';
import { Box, BoxProps } from '../../Box';
import { InputProps } from '../Input';

export interface TextInputBaseProps extends InputProps, BoxProps {}

export const TextInputBase: FunctionComponent<TextInputBaseProps> = forwardRef(
  ({ error, className, ...props }, ref) => (
    <Box
      className="Genesis-TextInputBase-Container"
      as="div"
      css={css({
        lineHeight: 0,
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          borderRadius: 'small',
          pointerEvents: 'none',
        },
        ':focus-within::before': {
          boxShadow: 'focusOutline',
        },
      })}
    >
      <Box
        className={`Genesis-TextInputBase-Input ${className}`}
        as="input"
        ref={ref}
        color="onSurface.text.primary"
        paddingY="threeQuarters"
        paddingX="one"
        borderRadius="small"
        borderColor={
          error
            ? 'interactive.border.critical.default'
            : 'interactive.border.default'
        }
        borderStyle="solid"
        borderWidth="thin"
        fontSize="body1"
        width="100%"
        aria-invalid={trueOrUndefined(Boolean(error))}
        focusStyle={{
          outline: '0',
        }}
        hoverStyle={{
          borderColor: 'interactive.border.hovered',
        }}
        disabledStyle={{
          backgroundColor: 'interactive.background.disabled',
          borderColor: 'interactive.border.disabled',
          color: 'interactive.action.subdued',
        }}
        css={css({
          '::placeholder': {
            color: 'interactive.action.subdued',
          },
        })}
        {...props}
      />
    </Box>
  )
);

TextInputBase.displayName = 'TextInputBase';
