{
  "dragDescriptionKeyboard": "Sürüklemeyi başlatmak için Enter'a basın.",
  "dragDescriptionKeyboardAlt": "Sürüklemeyi başlatmak için Alt + Enter'a basın.",
  "dragDescriptionLongPress": "Sürüklemeye başlamak için uzun basın.",
  "dragDescriptionTouch": "Sürüklemeyi başlatmak için çift tıklayın.",
  "dragDescriptionVirtual": "Sürüklemeyi başlatmak için tıklayın.",
  "dragItem": "{itemText}’i sürükle",
  "dragSelectedItems": "Sürükle {count, plural, one {# seçili öge} other {# seçili öge}}",
  "dragSelectedKeyboard": "{count, plural, one {# seçilmiş öğe} other {# seçilmiş öğe}} öğesini sürüklemek için Enter'a basın.",
  "dragSelectedKeyboardAlt": "{count, plural, one {# seçilmiş öğe} other {# seçilmiş öğe}} öğesini sürüklemek için Alt + Enter tuşuna basın.",
  "dragSelectedLongPress": "{count, plural, one {# seçilmiş öğe} other {# seçilmiş öğe}} öğesini sürüklemek için uzun basın.",
  "dragStartedKeyboard": "Sürükleme başlatıldı. Bir bırakma hedefine gitmek için Tab’a basın, ardından bırakmak için Enter’a basın veya iptal etmek için Escape’e basın.",
  "dragStartedTouch": "Sürükleme başlatıldı. Bir bırakma hedefine gidin, ardından bırakmak için çift tıklayın.",
  "dragStartedVirtual": "Sürükleme başlatıldı. Bir bırakma hedefine gidin, ardından bırakmak için Enter’a tıklayın veya basın.",
  "dropCanceled": "Bırakma iptal edildi.",
  "dropComplete": "Bırakma tamamlandı.",
  "dropDescriptionKeyboard": "Bırakmak için Enter'a basın. Sürüklemeyi iptal etmek için Escape'e basın.",
  "dropDescriptionTouch": "Bırakmak için çift tıklayın.",
  "dropDescriptionVirtual": "Bırakmak için tıklayın.",
  "dropIndicator": "bırakma göstergesi",
  "dropOnItem": "{itemText} üzerine bırak",
  "dropOnRoot": "Bırakın",
  "endDragKeyboard": "Sürükleme. Sürüklemeyi iptal etmek için Enter'a basın.",
  "endDragTouch": "Sürükleme. Sürüklemeyi iptal etmek için çift tıklayın.",
  "endDragVirtual": "Sürükleme. Sürüklemeyi iptal etmek için tıklayın.",
  "insertAfter": "{itemText}’den sonra gir",
  "insertBefore": "{itemText}’den önce gir",
  "insertBetween": "{beforeItemText} ve {afterItemText} arasına gir"
}
