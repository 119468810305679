import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { breakpoints } from '../../breakpoints';

export const Responsive = ({ breakpoint, children, ...props }) => (
  <MediaQuery {...props} query={breakpoint}>
    {children}
  </MediaQuery>
);

Responsive.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const Phone = (props) => (
  <Responsive {...props} breakpoint={breakpoints.belowTablet}>
    {props.children}
  </Responsive>
);

Phone.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Tablet = (props) => (
  <Responsive {...props} breakpoint={breakpoints.tablet}>
    {props.children}
  </Responsive>
);

Tablet.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TabletAndAbove = (props) => (
  <Responsive {...props} breakpoint={breakpoints.tabletAndAbove}>
    {props.children}
  </Responsive>
);

TabletAndAbove.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LapAndAbove = (props) => (
  <Responsive {...props} breakpoint={breakpoints.lapAndAbove}>
    {props.children}
  </Responsive>
);

LapAndAbove.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Desktop = (props) => (
  <Responsive {...props} breakpoint={breakpoints.desktop}>
    {props.children}
  </Responsive>
);

Desktop.propTypes = {
  children: PropTypes.node.isRequired,
};
