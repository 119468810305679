import React from 'react';
import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';
import { useDocumentTitle } from '@leagueplatform/web-common';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  usePageViewAnalytics,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
  EVENT_NAME,
  SUB_PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { QuickAction } from '@leagueplatform/care-discovery-api';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { CarePageWrapper } from '@leagueplatform/care-common';
import { useIntl } from '@leagueplatform/locales';
import { useQuickAction } from '../../hooks/use-quick-action.hook';
import { QuickActionMenuItem } from '../../components/quick-action-menu-item/quick-action-menu-item.component';

interface QuickActionPageProps {
  match: {
    params: {
      quickActionId: string;
    };
  };
}

const StyledListItem = genesisStyled('li')(
  css({
    marginBottom: 'oneAndHalf',
  }),
);

export const QuickActionPage = ({
  match: {
    params: { quickActionId },
  },
}: QuickActionPageProps) => {
  const { formatMessage } = useIntl();

  const screenName = `${SCREEN_NAMES.QUICK_ACTION_DETAIL} - ${quickActionId}`;

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: screenName,
    screen_name: screenName,
  });

  const { isLoading, isError, submenuItems, parent } =
    useQuickAction(quickActionId);

  useDocumentTitle(
    `${formatMessage({ id: 'GET_CARE' })}${
      parent?.attributes.title ? ` - ${parent?.attributes.title}` : ''
    }`,
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <CarePageWrapper>
        <ErrorState />
      </CarePageWrapper>
    );
  }

  return (
    <CarePageWrapper
      title={parent?.attributes.title}
      backButtonPath={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
    >
      <Box>
        <ul>
          {submenuItems?.map((submenuItem: QuickAction) => {
            const { id } = submenuItem;
            const { title, url } = submenuItem.attributes;

            return (
              <StyledListItem key={id}>
                <QuickActionMenuItem
                  title={title}
                  url={url}
                  analyticsEvent={() => {
                    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                      product_area: PRODUCT_AREA.GET_CARE,
                      sub_product_area: screenName,
                      screen_name: screenName,
                      detail: 'quick action menu',
                      sub_detail: title.toLowerCase(),
                    });
                    if (title.toLowerCase() === 'documents') {
                      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                        product_area: PRODUCT_AREA.GET_CARE,
                        sub_product_area: SUB_PRODUCT_AREA.DOCUMENTS,
                        screen_name: PRODUCT_AREA.GET_CARE,
                      });
                    }
                  }}
                />
              </StyledListItem>
            );
          })}
        </ul>
      </Box>
    </CarePageWrapper>
  );
};
