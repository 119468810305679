export function shouldDisplayDate(
  startDateParent?: string,
  endDateParent?: string,
  startDateChild?: string,
  endDateChild?: string,
) {
  const startDateParentFormatted = new Date(startDateParent || 0).setUTCHours(
    0,
    0,
    0,
    0,
  );
  const startDateChildFormatted = new Date(startDateChild || 0).setUTCHours(
    0,
    0,
    0,
    0,
  );
  const endDateParentFormatted = new Date(endDateParent || 0).setUTCHours(
    0,
    0,
    0,
    0,
  );
  const endDateChildFormatted = new Date(endDateChild || 0).setUTCHours(
    0,
    0,
    0,
    0,
  );

  if (
    startDateChildFormatted !== startDateParentFormatted ||
    endDateChildFormatted !== endDateParentFormatted
  ) {
    return true;
  }
  return false;
}
