import * as React from 'react';
import { StackLayout, Box, UtilityText } from '@leagueplatform/genesis-core';

type InformationBannerProps = {
  heading: string;
  iconURL: string;
  body: string;
};

export function InformationBanner({
  heading,
  iconURL,
  body,
}: InformationBannerProps) {
  return (
    <Box>
      <StackLayout
        horizontalAlignment="start"
        css={{
          backgroundColor: '$surfaceBackgroundSecondary',
          borderRadius: '$medium',
          padding: '$one',
        }}
      >
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          horizontalAlignment="start"
          spacing="$one"
          css={{
            paddingBottom: '$one',
          }}
        >
          <Box
            as="img"
            alt=""
            src={iconURL}
            css={{ width: '$one', height: '$one' }}
          />

          <UtilityText size="eyebrow">{heading}</UtilityText>
        </StackLayout>

        <UtilityText emphasis="subtle" size="xs">
          {body}
        </UtilityText>
      </StackLayout>
    </Box>
  );
}
