import { useCallback } from 'react';
import { IntlFormatters } from 'react-intl';
import { useIntl } from '@leagueplatform/locales';
import { useGetUserProfile } from '../use-get-user-profile/use-get-user-profile.hook';

export const useFormatDateByUserProfileLocation = () => {
  const { formatDate } = useIntl();
  const { data: userProfileData } = useGetUserProfile({ staleTime: Infinity });
  const { defaultTimezone } = userProfileData || {};

  const formatDateWithUserProfileLocation: IntlFormatters['formatDate'] =
    useCallback(
      (value, opts) =>
        formatDate(value, { ...opts, timeZone: defaultTimezone }),
      [defaultTimezone, formatDate],
    );
  return formatDateWithUserProfileLocation;
};
