import { Category } from '../types/category.type';
import { CoverageType } from '../types/coverage-type.type';

export function getDistinctCoverageTypesInTree(categories: Category[] = []) {
  const coverageTypes: Set<CoverageType> = categories.reduce(
    (types, category) => {
      const subcategoryCoverageTypes = getDistinctCoverageTypesInTree(
        category.sub ?? [],
      );

      return new Set([
        ...Array.from(types),
        category.coverage,
        ...subcategoryCoverageTypes,
      ]);
    },
    new Set<CoverageType>(),
  );

  return Array.from(coverageTypes);
}
