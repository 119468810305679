import React from 'react';
import { Box, Overline } from '@leagueplatform/genesis-commons';
import {
  ProgressBar,
  ConditionalLink,
} from '@leagueplatform/web-common-components';
import {
  GDSHeadingLevel,
  GDSHeadingSize,
  HeadingText,
  ParagraphText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';

interface ProgressBarCardWidgetProps {
  eyebrow?: string;
  heading?: string;
  headingLevel?: GDSHeadingLevel;
  currentText: string;
  linkRefState?: React.RefObject<HTMLAnchorElement>;
  progress: number;
  suffixText?: string;
  footerText?: string;
  action?: string;
  emphasizeCurrentValue?: boolean;
  onCardClick?: (e: React.MouseEvent) => void;
  headingSize?: GDSHeadingSize;
}

export const ProgressBarCardContent = ({
  eyebrow,
  heading,
  headingLevel = '3',
  headingSize = 'sm',
  currentText,
  progress,
  suffixText,
  footerText,
  action,
  linkRefState,
  emphasizeCurrentValue = false,
  onCardClick,
}: ProgressBarCardWidgetProps) => (
  <StackLayout
    orientation="vertical"
    spacing="$quarter"
    css={{ margin: '$oneAndHalf', alignItems: 'normal', height: 'auto' }}
  >
    {eyebrow && (
      <Overline as="p" color="onSurface.text.subdued" marginBottom="quarter">
        {eyebrow}
      </Overline>
    )}
    <StackLayout spacing="$threeQuarters" orientation="vertical">
      {heading && (
        <HeadingText level={headingLevel} size={headingSize}>
          {heading}
        </HeadingText>
      )}
      {/* By default p tags get a margin bottom applied we do not want that here. */}
      <ParagraphText size="sm" css={{ marginBlockEnd: '$none !important' }}>
        <ConditionalLink ref={linkRefState} url={action} onClick={onCardClick}>
          <ParagraphText
            as="span"
            size="sm"
            css={{
              fontWeight: emphasizeCurrentValue ? 'bold' : '400',
            }}
          >
            {currentText}
          </ParagraphText>{' '}
          {suffixText}
        </ConditionalLink>
      </ParagraphText>
      <Box paddingBottom="half" width="100%">
        <ProgressBar
          total={100}
          current={progress}
          customBarFillColor="success.border.default"
          customBarBackgroundColor="success.background.subdued"
          screenReaderText={`${currentText} ${suffixText}`}
        />
      </Box>
      <UtilityText
        emphasis="subtle"
        size="xs"
        css={{
          alignSelf: 'flex-end',
        }}
      >
        {footerText}
      </UtilityText>
    </StackLayout>
  </StackLayout>
);
