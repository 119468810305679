import { useMutation, useQueryClient } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  CHALLENGE_HIGHLIGHTS_PATH,
  postUserChallenge,
  CHALLENGES_PATH,
} from '@leagueplatform/health-journey-api';
import { SIGN_UP_MODAL_PAGES } from '../constants/sign-up-modal.constants';

export const useSignUpForChallenge = (
  challengeTrackingMode: string,
  setNextModalNavigationStep: (next: keyof typeof SIGN_UP_MODAL_PAGES) => void,
) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const queryClient = useQueryClient();

  return useMutation(
    () => postUserChallenge(challengeTrackingMode, challengeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHALLENGES_PATH, challengeId]);
        queryClient.invalidateQueries([CHALLENGE_HIGHLIGHTS_PATH]);
        setNextModalNavigationStep(SIGN_UP_MODAL_PAGES.SIGN_UP_SUCCESS);
      },
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'UNABLE_TO_JOIN_CHALLENGE',
        });
      },
    },
  );
};
