import React from 'react';
import { HtmlToReact } from '@leagueplatform/web-common';
import {
  Box,
  StackLayout,
  TextAction,
  styled,
} from '@leagueplatform/genesis-core';

const StyledH3 = styled('h3', {
  fontSize: '$headingTwo',
  marginTop: '$twoAndHalf',
  marginBottom: '$one',
  color: '$onSurfaceTextPrimary',
  fontWeight: 600,
  '@mobile': {
    fontSize: '$headingThree',
  },
});

const StyledH4 = styled('h4', {
  fontSize: '$one',
  marginTop: '$two',
  marginBottom: '$half',
  color: '$onSurfaceTextPrimary',
  fontWeight: 600,
});

const StyledParagraph = styled('p', {
  lineHeight: '24px',
  fontSize: '$one',
  color: '$onSurfaceTextPrimary',
  a: {
    margin: 0,
  },
});

const StyledLink = styled(TextAction, {
  fontSize: 'inherit',
  lineHeight: 'inherit',
  marginTop: '$one',
});

const StyledList = styled(Box, {
  '& > li': {
    fontSize: '$one',
    color: '$onSurfaceTextPrimary',
  },
});

const RichTextOptions = {
  h3: {
    component: StyledH3,
  },
  h4: {
    component: StyledH4,
  },
  p: {
    component: StyledParagraph,
  },
  a: {
    component: StyledLink,
    props: {
      target: '_blank',
    },
  },

  li: {
    component: StyledList,
    props: {
      as: 'li',
    },
  },
};

export const ProgramRichText = ({
  richText,
  options,
}: {
  richText: string;
  options?: any;
}) => (
  <StackLayout spacing="$none" css={{ whiteSpace: 'pre-wrap' }}>
    <HtmlToReact
      htmlString={richText}
      options={{ ...RichTextOptions, ...options }}
    />
  </StackLayout>
);
