import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { MetricType } from '@leagueplatform/dashboard-api';

import {
  PRODUCT_AREA,
  SCREEN_NAME,
  RESOURCE_TYPE,
  INSIGHT_TYPE,
} from 'constants/analytics';

export const trackWidgetClickEvent = (
  metric: MetricType,
  insightType: INSIGHT_TYPE,
) => {
  trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
    product_area: PRODUCT_AREA.ABOUT_ME,
    screen_name: `${SCREEN_NAME.ABOUT_ME} ${SCREEN_NAME.OVERVIEW}`,
    resource_type: RESOURCE_TYPE.INSIGHT_CARD,
    metric_type: metric,
    insight_type: insightType,
  });
};
