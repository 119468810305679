import {
  BodyOneSecondary,
  Box,
  SecondaryButton,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { LeagueFamilyPendingInviteBanner } from '@leagueplatform/dependents';
import { GenesisRouterLink } from '@leagueplatform/web-common-components';
import { GridContainer } from 'components/grid-layout/grid-layout.component';
import { Dependent } from './components/dependent/dependent.component';
import { useDependents } from './hooks/use-dependents.hook';
import { DependentCardViewerModal } from './components/dependent-card-viewer-modal/dependent-card-viewer-modal.component';

const GRID_COLUMN_COUNT = 3;
const MIN_GRID_ITEM_WIDTH = '200px';

export const Dependents = () => {
  const {
    onViewDependentCardClick,
    showDependentsMenuItem,
    currentDependent,
    benefitId,
    onViewDependentCardClose,
    dependents = [],
    banners,
  } = useDependents();
  const { formatMessage, $tifelse } = useIntl();
  const hasDependents = dependents && dependents.length > 0;
  const statusBanner = banners?.status;

  return (
    <>
      {statusBanner && (
        <LeagueFamilyPendingInviteBanner
          title={statusBanner.title}
          description={statusBanner.description}
          linkText={statusBanner.link?.text}
          linkUrl={statusBanner.link?.url}
        />
      )}
      <Box as="section">
        <VisuallyHidden as="h2">
          {formatMessage({ id: 'DEPENDENTS' })}
        </VisuallyHidden>

        <BodyOneSecondary marginBottom="two">
          {$tifelse(
            hasDependents,
            'HAS_DEPENDENTS_DESCRIPTION',
            'HAS_NO_DEPENDENTS_DESCRIPTION',
          )}
        </BodyOneSecondary>
        {showDependentsMenuItem && (
          <SecondaryButton
            as={GenesisRouterLink}
            textDecoration="none"
            to="/member/wallet/dependents"
          >
            {$tifelse(
              hasDependents,
              'MANAGE_DEPENDENTS',
              'ADD_A_DEPENDENT_CAPITAL',
            )}
          </SecondaryButton>
        )}
        {hasDependents ? (
          <GridContainer
            marginTop="two"
            gridColumnCount={GRID_COLUMN_COUNT}
            minGridItemWidth={MIN_GRID_ITEM_WIDTH}
            as="ul"
          >
            {dependents.map((dependent) => (
              <Dependent
                key={dependent.dependent_id}
                viewDependentCard={onViewDependentCardClick}
                dependent={dependent}
              />
            ))}
          </GridContainer>
        ) : null}
        {currentDependent && (
          <DependentCardViewerModal
            benefitId={benefitId}
            dependent={currentDependent}
            onClose={onViewDependentCardClose}
          />
        )}
      </Box>
    </>
  );
};
