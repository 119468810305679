import styled, { css } from 'styled-components';
import { leagueFont } from '../../utils/font';
import { spaceInPx, fontSizeInPx, radiusInPx, colour } from '../../theme';
import ValidIcon from 'assets/valid-icon.svg';
import ErrorIcon from 'assets/error-icon.svg';

export const Input = styled.input`
  ${leagueFont()};
  font-size: ${fontSizeInPx(2)};
  height: 45px;
  padding: 0 ${spaceInPx(2)};
  border: 1px solid ${colour('neutral.grayLight')};
  border-radius: ${radiusInPx(2)};
  background-position: right 1rem top 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  outline: none;
  transition: all 200ms ease-out;
  ${({ fit }) => fit && `width: 100%;`};
  ${({ isValid }) => isValid && `background-image: url(${ValidIcon})`};
  ${({ hasError }) =>
    hasError &&
    css`
      background-image: url(${ErrorIcon});
      border-color: ${colour('red')};
    `};
  &[disabled] {
    color: ${colour('grey')};
    background-color: ${colour('greyLightest')};
  }
  &:focus {
    border-color: ${colour('purple')};
  }
  &::placeholder {
    color: ${colour('neutral.gray')};
  }
`;

Input.displayName = 'Input';
