import React, { forwardRef } from 'react';
import { Text, TextProps } from '../Text';

/**
 * @deprecated use `HeadingText` from `genesis-core` instead
 */

export const SubtitleOne: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="h5"
      color="onSurface.text.primary"
      typography="subtitleOne"
      ref={ref}
      {...props}
    />
  )
);

SubtitleOne.displayName = 'SubtitleOne';
