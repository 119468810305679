import React, { FC, forwardRef } from 'react';
import {
  Button,
  Card,
  GDSCardElevatedProps,
  GDSCardClickAreaProps,
  useThemeStrings,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { MasonryDismissAction } from '../../../types/masonry-driver-node-actions';

type DismissActionProps = {
  onDismiss?: MasonryDismissAction;
  toolbarButton?: boolean;
};
export interface ElevatedCardWithClickAreaProps
  extends GDSCardClickAreaProps,
    DismissActionProps {
  dismissible?: boolean;
}

export interface ElevatedCardProps
  extends GDSCardElevatedProps,
    DismissActionProps {
  dismissible?: boolean;
}

export interface ConditionalLinkCardRendererProps
  extends ElevatedCardWithClickAreaProps {
  hasLink?: boolean;
  toolbarButton?: boolean;
}

const DismissButton = ({ onDismiss, toolbarButton }: DismissActionProps) => {
  const strings = useThemeStrings();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Button
      hideLabel
      size={toolbarButton ? 'toolbar' : 'small'}
      icon="interfaceClose"
      priority="tertiary"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(!toolbarButton && { quiet: true })}
      onClick={(event: React.MouseEvent) => {
        if (onDismiss) {
          invokeAction({
            event,
            ...onDismiss,
          });
        }
      }}
      css={{
        position: 'absolute',
        top: '$half',
        right: '$half',
        zIndex: 2,
      }}
    >
      {strings.dismiss}
    </Button>
  );
};

const ElevatedCardWithClickArea: FC<ElevatedCardWithClickAreaProps> =
  forwardRef(
    (
      {
        css,
        children,
        onCardMouseUp,
        onCardMouseDown,
        imagePosition,
        dismissible,
        onDismiss,
        toolbarButton,
      }: ElevatedCardWithClickAreaProps,
      ref: React.Ref<HTMLDivElement>,
    ) => (
      <Card.Elevated css={css}>
        <Card.ClickArea
          ref={ref}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          imagePosition={imagePosition}
          data-testid="clickable-card"
        >
          {children}
        </Card.ClickArea>
        {dismissible && (
          <DismissButton onDismiss={onDismiss} toolbarButton={toolbarButton} />
        )}
      </Card.Elevated>
    ),
  );

const ElevatedCard: FC<ElevatedCardProps> = forwardRef(
  (
    {
      css,
      imagePosition,
      dismissible,
      onDismiss,
      toolbarButton,
      children,
    }: ElevatedCardProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <Card.Elevated ref={ref} imagePosition={imagePosition} css={css}>
      {children}
      {dismissible && (
        <DismissButton onDismiss={onDismiss} toolbarButton={toolbarButton} />
      )}
    </Card.Elevated>
  ),
);

export const ConditionalLinkCardRenderer: FC<ConditionalLinkCardRendererProps> =
  forwardRef(
    (
      {
        css,
        children,
        hasLink,
        onCardMouseUp,
        onCardMouseDown,
        imagePosition,
        dismissible,
        onDismiss,
        toolbarButton,
      }: ConditionalLinkCardRendererProps,
      ref: React.Ref<HTMLDivElement>,
    ) => {
      const ConditionalLinkCard = hasLink
        ? ElevatedCardWithClickArea
        : ElevatedCard;

      return (
        <ConditionalLinkCard
          ref={ref}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          css={css}
          imagePosition={imagePosition}
          dismissible={dismissible}
          onDismiss={onDismiss}
          toolbarButton={toolbarButton}
        >
          {children}
        </ConditionalLinkCard>
      );
    },
  );
