const MasonryLogPreffix = '[MasonryEngine]:';

export const logError = (msg: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`%c${MasonryLogPreffix} ${msg}`, 'color: red');
  }
};

export const logWarning = (msg: string) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`%c${MasonryLogPreffix} ${msg}`, 'color: yellow');
  }
};
