import {
  LocationDescriptor,
  LocationDescriptorObject,
  Path,
} from '@leagueplatform/routing';

export interface LocationStateWithReferrer extends Record<string, unknown> {
  referrerStack?: Path[];
}

export type LocationParamsWithReferrer = Omit<
  LocationDescriptorObject<LocationStateWithReferrer>,
  'pathname'
>;

export type LocationDescriptorWithReferrer =
  LocationDescriptor<LocationStateWithReferrer>;

/**
 * Create a `location` object containing a `state.referrerStack` array that establishes the current page as the referrer to the next and forwards any existing location state, including any existing `referrerStack`.
 *
 * @summary
 * - The current location is appended to the end of the referrerStack
 * - `referrerStack.length` represents the distance from the current page to the original entrypoint.
 * Ex. `history.go(-referrerStack.length)`
 * - This function will forward any location state, _including any provided referrerStack_
 * - To "reset" the entrypoint while using this function: `state.referrerStack = `undefined | []`
 *
 * **Useful for:**
 * - Breadcrumbs
 * - Multi-page flows that need to conclude on the same page the user started.
 * - Avoiding circular flows between pages using that use `history.push` and `history.goBack/goForward/go`
 */
export const getLocationWithReferrer = ({
  pathname,
  state = {},
  ...locationParams
}: LocationDescriptorObject<LocationStateWithReferrer>): LocationDescriptorWithReferrer => ({
  ...locationParams,
  pathname,
  state: {
    ...state,
    referrerStack: (state?.referrerStack ?? [])
      .concat(window.location.pathname)
      .filter(Boolean),
  },
});
