import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { BaseButton, ButtonProps } from '../BaseButton';

/**
 * @deprecated  use `Button` from `genesis-core` instead
 */

export const SecondaryButton: React.FunctionComponent<ButtonProps> = forwardRef(
  (props, ref) => (
    <BaseButton
      backgroundColor="secondary.background.default"
      color="secondary.text.default"
      borderColor="secondary.border.default"
      borderWidth="thick"
      ref={ref}
      css={css({
        ':hover': {
          color: 'secondary.text.hovered',
          backgroundColor: 'secondary.background.hovered',
        },
        ':active': {
          color: 'secondary.text.hovered',
          backgroundColor: 'secondary.background.pressed',
        },
        ':focus': {
          color: 'secondary.text.hovered',
          backgroundColor: 'secondary.background.hovered',
        },
      })}
      {...props}
    />
  )
);

SecondaryButton.displayName = 'SecondaryButton';
