import styled from 'styled-components';
import { getColourObject } from '../utils/helpers';

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;

  thead {
    background-color: ${({ theme }) => getColourObject(theme).greyLightest};
    color: ${({ theme }) => getColourObject(theme).grey};
    padding: 1rem 0;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 500;
  }

  tr {
    border-bottom: 1px solid
      ${({ theme }) => getColourObject(theme).greyLighter};
  }

  td {
    padding: 0.8rem 0 0.8rem 1rem;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  th {
    padding: 2.4em 0 2.4em 1rem;
    text-align: left;
  }

  th:nth-child(1) {
    border-radius: 0.3rem 0 0 0;
  }

  th:nth-last-child(1) {
    border-radius: 0 0.3rem 0 0;
  }
`;
