import * as React from 'react';
import { VerticalBarGraphItemV2 } from '@leagueplatform/dashboard-api';
import { InsightCardVerticalBarGraphV2 } from '@leagueplatform/dashboard';

type VerticalBarGraphInsightResponse = {
  items: VerticalBarGraphItemV2[];
  description: string;
};

export function VerticalBarGraphInsightNodeRenderer({
  description,
  items,
}: VerticalBarGraphInsightResponse) {
  return (
    <InsightCardVerticalBarGraphV2
      title={description}
      description={description}
      items={items}
    />
  );
}
