import { Box as GenesisBox } from '@leagueplatform/genesis-commons';
import { Box } from '../../components/primitives.view';
import { Tooltip } from '../../components/tooltip/tooltip.view';
import { TooltipContent } from '../../components/tooltip/tooltip-content.view';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const TooltipText = styled(Box).attrs({
  p: 2,
})`
  font-size: 1.4rem;
  line-height: 1.5;
  min-width: ${({ minWidth }) => minWidth};
  white-space: pre-wrap;
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
`;

export const TooltipHeader = ({
  tooltipLink,
  tooltipText,
  inline,
  top,
  verticalPosition,
  fontWeight,
  minWidth,
  pointerPosition,
  center,
  right,
  id,
}) => (
  <Tooltip id={id} inline={inline}>
    <GenesisBox
      as="span"
      fontSize="body2"
      marginBottom={inline ? 'none' : 20}
      marginTop={inline ? 'none' : 14}
      color="interactive.action.primary"
    >
      {tooltipLink}
    </GenesisBox>
    <TooltipContent
      right={right}
      center={center}
      pointerPosition={pointerPosition}
      borderRadius="5px"
      top={top}
      verticalPosition={verticalPosition}
    >
      <TooltipText fontWeight={fontWeight} minWidth={minWidth}>
        {tooltipText}
      </TooltipText>
    </TooltipContent>
  </Tooltip>
);

TooltipHeader.propTypes = {
  id: PropTypes.string,
  tooltipLink: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool,
  top: PropTypes.bool,
  verticalPosition: PropTypes.string,
  fontWeight: PropTypes.string,
  minWidth: PropTypes.string,
  pointerPosition: PropTypes.number,
};

TooltipHeader.defaultProps = {
  inline: false,
  right: true,
  center: false,
  top: false,
  verticalPosition: null,
  fontWeight: null,
  minWidth: '35rem',
  pointerPosition: 30,
};
