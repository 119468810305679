import { css } from 'styled-components';

const px = (value, offset) => `${value + offset}px`;

const min = (value, offset = 0) => `(min-width: ${px(value, offset)})`;

const max = (value, offset = 0) => `(max-width: ${px(value, offset)})`;

export const units = {
  phone: 480,
  tablet: 768,
  lap: 1024,
  desktop: 1280,
};

export const breakpoints = {
  phone: max(units.phone),
  belowTablet: max(units.tablet, -1),
  tabletAndBelow: max(units.tablet),
  tablet: `${min(units.tablet)} and ${max(units.lap, -1)}`,
  tabletAndAbove: min(units.tablet),
  belowLap: max(units.lap, -1),
  lap: `${min(units.lap)} and ${max(units.desktop, -1)}`,
  lapAndAbove: min(units.lap),
  belowDesktop: max(units.desktop, -1),
  desktop: min(units.desktop),
};

export const reduceBreakpoints = (accumulate, key) => ({
  ...accumulate,
  [key]: (...args) => css`
    @media ${breakpoints[key]} {
      ${css(...args)}
    }
  `,
});

// Iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce(reduceBreakpoints, {});
