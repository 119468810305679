import * as React from 'react';

import {
  StackLayout,
  HeadingText,
  UtilityText,
} from '@leagueplatform/genesis-core';

import {
  ShapeIcon,
  ShapeType,
} from 'components/charts/core/xychart/primitives/Shape';

type DatumLegendProps = {
  size?: 'normal' | 'small';
  shape?: ShapeType;
  name: string;
  value: string;
  unit: string;
};

type DatumValueWithUnitProps = {
  size?: 'normal' | 'small';
  value: string;
  unit: string;
};

export function DatumValueWithUnit({
  size = 'normal',
  value,
  unit,
}: DatumValueWithUnitProps) {
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <UtilityText
        css={{ typography: size === 'normal' ? '$headingOne' : '$headingTwo' }}
      >
        {value}{' '}
      </UtilityText>
      <UtilityText emphasis="subtle" css={{ typography: '$bodyOne' }}>
        {unit}
      </UtilityText>
    </div>
  );
}

export function DatumLegend({
  size = 'normal',
  shape,
  name,
  value,
  unit,
}: DatumLegendProps) {
  return (
    <>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing="$quarter"
      >
        {shape && <ShapeIcon shape={shape} />}
        <HeadingText
          level="4"
          size={size === 'normal' ? 'md' : 'xs'}
          emphasis="subtle"
        >
          {name}
        </HeadingText>
      </StackLayout>
      <DatumValueWithUnit size={size} value={value} unit={unit} />
    </>
  );
}
