import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { Button, Responsive, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { CLP_DETAIL } from 'constants/analytics.constants';
import { BUTTON_CONTAINER_MAX_WIDTH } from 'constants/challenges.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import React from 'react';

interface SignUpButtonProps {
  setDisplaySignUpModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignUpButton = ({ setDisplaySignUpModal }: SignUpButtonProps) => {
  const { formatMessage } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail,
      is_critical_event: true,
    });
  return (
    <Button
      onClick={() => {
        setDisplaySignUpModal(true);
        handleAnalyticsClick(CLP_DETAIL.SIGN_UP);
      }}
      width="fillContainer"
      css={{
        maxWidth: BUTTON_CONTAINER_MAX_WIDTH,
      }}
    >
      {formatMessage({ id: 'SIGN_UP_FOR_CHALLENGE' })}
    </Button>
  );
};

export const MobileResponsiveSignUpButton = ({
  setDisplaySignUpModal,
}: SignUpButtonProps) => (
  <Responsive down="laptop">
    <StackLayout
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '$surfaceBackgroundPrimary',
        height: '$five',
        padding: '$one',
        zIndex: '1',
      }}
      horizontalAlignment="center"
      verticalAlignment="center"
    >
      <SignUpButton setDisplaySignUpModal={setDisplaySignUpModal} />
    </StackLayout>
  </Responsive>
);
