import * as React from 'react';
import { Image, SubtitleOne } from '@leagueplatform/genesis-commons';
import { MetricType } from '@leagueplatform/dashboard-api';
import {
  useDataTypeConfigMap,
  isSupportedUnitMetricType,
} from 'constants/use-data-type-config';
import { ListItemLink } from 'components/list-item';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';

type NoDataCardProps = {
  dataType: MetricType;
  to: string;
};

export function NoDataCard({ dataType, to }: NoDataCardProps) {
  const dataTypeConfig = useDataTypeConfigMap();

  if (!isSupportedUnitMetricType(dataType)) return null;
  const metric = dataTypeConfig.metric(dataType);

  return (
    <ListItemLink data-testid="unavailable-metric" to={to}>
      <SubtitleOne>{metric}</SubtitleOne>
      <Image
        role="presentation"
        height="one"
        src={
          handleStaticAsset(
            DASHBOARD_ASSET_MAP.DASHBOARD_CHEVRON_RIGHT,
          ) as string
        }
      />
    </ListItemLink>
  );
}
