import { bool, string, number, shape, arrayOf } from 'prop-types';

export const programsListItemPropType = {
  available_points: number,
  description: string,
  image_url: string,
  name: string,
  program_id: string,
  total_days: number,
  total_activities_count: number,
};

export const programsListPropType = arrayOf(shape(programsListItemPropType));

export const programsBasePropTypes = {
  ready: bool,
  name: string,
  programs: programsListPropType,
};

export const programsPropTypes = shape({
  ...programsBasePropTypes,
  hasError: bool,
  numberOfAvailablePrograms: number,
  programLimitModal: shape({
    title: string,
    description: string,
  }),
});
