import React from 'react';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';

interface ProgressDotsProps {
  length: number;
  currentDot: number;
}

const ProgressDot = genesisStyled(Flex)(({ isActive }) =>
  css({
    height: 'threeQuarters',
    width: 'threeQuarters',
    cursor: 'pointer',
    backgroundColor: isActive
      ? 'interactive.action.primary'
      : 'interactive.action.disabled',
  }),
);

export const ProgressDots = ({ length, currentDot }: ProgressDotsProps) => {
  const progressDots = new Array(length).fill(true);

  return (
    <Flex
      position="absolute"
      flexDirection="row"
      justify="center"
      align="center"
      bottom="0"
      width="100%"
      justifyContent="center"
    >
      {progressDots.map((active, index) => {
        const key = `progress-dot-${index}-${active}`;
        return (
          <ProgressDot
            borderRadius="circle"
            marginX="threeQuarters"
            isActive={currentDot === index}
            key={key}
          />
        );
      })}
    </Flex>
  );
};
