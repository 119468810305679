export enum HEALTH_JOURNEY_COMMON_ASSET_KEYS {
  EMPTY_STATE_CONFIGURABLE_ACTIVITIES = 'EMPTY_STATE_CONFIGURABLE_ACTIVITIES',
  ENABLED_BADGE_PLACEHOLDER = 'ENABLED_BADGE_PLACEHOLDER',
  COMPLETED_PROGRAM = 'COMPLETED_PROGRAM',
  PROGRAM_HEADING_BANNER_ICON = 'PROGRAM_HEADING_BANNER_ICON',
  ACTIVITIES_CYCLING_ANIMATION = 'ACTIVITIES_CYCLING_ANIMATION',
}

export type HealthJourneyCommonAssets = Record<
  HEALTH_JOURNEY_COMMON_ASSET_KEYS,
  unknown
>;
