import React from 'react';
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
} from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { useIsAboutMeEnabled } from 'hooks/use-is-about-me-enabled';

// Pages
import { AchievementsLandingPage } from './pages/achievements-landing-page/achievements-landing-page.page';

export const AchievementsRoutes = () => {
  const { path } = useRouteMatch();
  const { isAboutMeEnabled } = useIsAboutMeEnabled();

  return (
    <Switch>
      {isAboutMeEnabled ? (
        // `/about-me?view=achievements` replaces the `/achievements` page when the about me module is enabled.
        <Redirect
          exact
          from={path}
          to={`${getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}?view=achievements`}
          key="achievements"
        />
      ) : null}
      <Route exact path={`${path}`} component={AchievementsLandingPage} />
    </Switch>
  );
};
