import { Definition, ProviderBalanceGroup } from '@leagueplatform/wallet-api';
import { NetworkProviderService } from '../types/plan-progress.types';

const getBalanceTypes = (networkType: ProviderBalanceGroup[]) => {
  const benefitTypeArray: string[] = [];
  networkType.forEach((network) => {
    network.balances.forEach((balance) => {
      if (!benefitTypeArray.includes(balance.balance_type)) {
        benefitTypeArray.push(balance.balance_type);
      }
    });
  });

  return benefitTypeArray.sort();
};

/**
 * This function takes a provider service array.
 * It removes any network balances that don't match the balance type and filters networks with no balances.
 * Adds the definition that matches the balances balance_type
 * It returns a new array of array of provider services.
 * @arg {ProviderBalanceGroup[]} networkType
 * @arg {Definition[]} definitions
 * @returns {NetworkProviderService[][]} array
 */

export const getNetworkWithBalanceType = (
  networkType: ProviderBalanceGroup[],
  definitions: Definition[],
) => {
  const balanceTypes = getBalanceTypes(networkType);

  return balanceTypes.map((balanceType) => {
    const networksInBalance = networkType.reduce(
      (prev: NetworkProviderService[], current) => {
        const balances = current.balances.filter(
          (b) => b.balance_type === balanceType,
        );

        const definition = definitions.find(
          (_definition) => _definition.balance_type === balanceType,
        );

        return [...prev, { ...current, balances, definition }].filter(
          (network) => network.balances.length > 0,
        );
      },
      [],
    );

    return networksInBalance;
  });
};
