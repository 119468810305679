import {
  CategorizedActions,
  BenefitCardAction,
} from '@leagueplatform/wallet-api';

export const flattenActionList = (
  categorizedActions: CategorizedActions[] = [],
  actions: BenefitCardAction[] | null = [],
) => {
  const flattenedActions =
    categorizedActions?.reduce(
      (actionsList: BenefitCardAction[], currentCategory) => [
        ...actionsList,
        ...currentCategory.actions,
      ],
      [],
    ) || [];

  if (actions?.length) {
    return [...flattenedActions, ...actions];
  }

  return flattenedActions;
};
