export const PROGRAM_BACK_BUTTON_WIDTH = '52px';
export const PROGRAM_CONTENT_WIDTH = '778px';
export const PROGRAM_SIDEBAR_WIDTH = '357px';
export const PROGRAM_SIDEBAR_HEIGHT = '200px';
export const CHALLENGE_TYPE_GROUP = 'group';
export const CHALLENGE_TYPE_SOLO = 'solo';
export const CHALLENGE = 'challenge';
export const CHALLENGE_TYPES = [
  CHALLENGE_TYPE_SOLO,
  CHALLENGE_TYPE_GROUP,
] as const;
