import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE = 'get_user_health_activity';

// Request can be called with userActivityId or activityId + campaignId
export const getUserHealthActivity = (
  userActivityId?: string,
  activityId?: string,
  campaignId?: string,
): Promise<any> =>
  SocketAsFetch.fetch({
    message_type: GET_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
    info: {
      id: userActivityId,
      activity_id: activityId,
      campaign_id: campaignId,
    },
  });
