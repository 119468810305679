import * as React from 'react';
import { useTheme as useCommonsTheme } from '@leagueplatform/genesis-commons';
import {
  NUMERIC_YEAR_MONTH_DAY,
  SHORT_WEEKDAY_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { XYChart, Tooltip, AxisBottom, AxisLeft, Grid } from '../xychart';
import { useGetLeftAxisLabelOffset } from '../bar-graph/utils/use-calculate-left-axis-label-offset';
import { RangeBar, RangeBarProps } from '../xychart/components/range-bar';
import { useGetRoundedYMax } from '../bar-graph/utils/get-rounded-y-max';
import type { RangeGraph } from './types/range-graph';
import { groupByKey } from './utils/group-by-key';
import { TooltipMarker } from './tooltip-marker';

export type WeeklyRangeGraphProps<Datum> = RangeGraph<Datum>;

const NUM_Y_TICKS = 5;

export function WeeklyRangeGraph<Datum>({
  data,
  accessors,
  title,
  description,
  axisLeftLabel,
  ariaDatumLabel,
  axisBottomLabel,
  renderTooltip,
  getShape,
  yMaxFromEnums,
}: WeeklyRangeGraphProps<Datum>) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const commonsTheme = useCommonsTheme();
  const yMin = 0;
  const yMax = useGetRoundedYMax(data, accessors, 100, yMaxFromEnums);
  const labelOffset = useGetLeftAxisLabelOffset(yMax);

  // Group by date.
  const groupedByDate = groupByKey(
    (datum) =>
      formatDateWithUserProfileLocation(accessors.xAccessor(datum), {
        ...NUMERIC_YEAR_MONTH_DAY,
      }),
    data,
  );

  const margin = {
    top: commonsTheme.space.five,
    bottom: commonsTheme.space.five + commonsTheme.space.one,
    left: commonsTheme.space.three + labelOffset,
    right: commonsTheme.space.four,
  };

  return (
    <XYChart
      title={title}
      desc={description}
      data={data}
      accessors={accessors}
      margin={margin}
      xScale={{
        type: 'band',
        paddingInner: 0.9,
        paddingOuter: 0.45,
      }}
      yScale={{
        type: 'linear',
        domain: [yMin, yMax],
      }}
    >
      <Grid numTicksRows={NUM_Y_TICKS} centerColumns />
      <AxisLeft
        label={axisLeftLabel}
        labelOffset={labelOffset}
        numTicks={NUM_Y_TICKS}
        hideTicks
      />
      <AxisBottom
        label={axisBottomLabel}
        tickFormat={(timestamp: number) =>
          formatDateWithUserProfileLocation(
            new Date(timestamp),
            SHORT_WEEKDAY_FORMAT,
          )
        }
      />
      <Tooltip<Datum[]> snapToDatum={false}>{renderTooltip}</Tooltip>
      {Object.values(groupedByDate)
        // Remove days where datums are all null
        .filter(
          (datums) =>
            datums.filter((datum) => accessors.yAccessor(datum) !== null)
              .length > 0,
        )
        .map((datums) => (
          <TooltipMarker data={datums} accessors={accessors} />
        ))}
      {data
        // Only render bars with values.
        .filter((datum) => accessors.yAccessor(datum) !== null)
        .map((datum) => {
          const shape = getShape(datum);

          return (
            <RangeBar
              key={`bar-${accessors.xAccessor(datum)}:${accessors.yAccessor(
                datum,
              )}`}
              datum={datum}
              shape={shape}
              // TODO: Don't use `as`
              accessors={accessors as RangeBarProps<Datum, string>['accessors']}
              ariaLabel={ariaDatumLabel(datum)}
            />
          );
        })}
    </XYChart>
  );
}
