import { isAfter, isBefore, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const getZonedNow = (defaultTimezone?: string) =>
  defaultTimezone ? utcToZonedTime(new Date(), defaultTimezone) : new Date();

const getZonedDatestring = (dateString: string, defaultTimezone?: string) =>
  defaultTimezone
    ? utcToZonedTime(new Date(parseISO(dateString)), defaultTimezone)
    : new Date(parseISO(dateString));

/**
 * @param  {string} startEnroll
 * @param  {string} endEnroll
 * @param  {string | undefined} defaultTimezone
 * @returns boolean
 *
 * Return true if the current moment is between the start enroll and end enroll dates,
 * otherwise return false
 */
export const isEnrollmentOpen = (
  startEnroll: string,
  endEnroll: string,
  defaultTimezone?: string,
): boolean => {
  const now = getZonedNow(defaultTimezone);
  const start = getZonedDatestring(startEnroll, defaultTimezone);
  const end = getZonedDatestring(endEnroll, defaultTimezone);
  return isAfter(now, start) && isBefore(now, end);
};

/**
 * @param  {string} startEnroll
 * @param  {string | undefined} defaultTimezone
 * @returns boolean
 * Return true if the current moment is before the start enroll date,
 * otherwise return false
 */
export const isBeforeEnrollmentPeriod = (
  startEnroll: string,
  defaultTimezone?: string,
): boolean => {
  const now = getZonedNow(defaultTimezone);
  const start = getZonedDatestring(startEnroll, defaultTimezone);
  return isBefore(now, start);
};

/**
 * @param  {string} endEnroll
 * @param  {string | undefined} defaultTimezone
 * @returns boolean
 * Return true if the current moment is after the end enroll date,
 * otherwise return false
 */
export const isAfterEnrollmentPeriod = (
  endEnroll: string,
  defaultTimezone?: string,
): boolean => {
  const now = getZonedNow(defaultTimezone);
  const end = getZonedDatestring(endEnroll, defaultTimezone);
  return isAfter(now, end);
};
