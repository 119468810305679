import React, { forwardRef } from 'react';
import { Link, LinkProps } from '@leagueplatform/routing';
import { Box, QuietButton, ButtonProps } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Modal } from '@leagueplatform/ui-kit';
import { Icon } from '@leagueplatform/genesis-core';
import {
  SkeletonBox,
  SkeletonCards,
} from '@leagueplatform/web-common-components';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { GetQuickActionsVersion } from '@leagueplatform/care-discovery-api';
import { TriageScreen } from '../triage-screen/triage-screen.component';
import { TopicScreen } from '../topic-screen/topic-screen.component';
import { useGetCareTriage } from '../../hooks/use-get-care-triage.hook';

interface GetCareTriageComponentProps {
  version?: GetQuickActionsVersion;
}

const LOADING_HEIGHT_MATCHES_LOADED_CONTENT = '660px';

const CloseButton = forwardRef(({ onClick }: ButtonProps, ref) => {
  const { formatMessage } = useIntl();
  return (
    <Box position="absolute" top="one" right="oneAndHalf">
      <QuietButton
        size="two"
        textDecoration="none"
        fontSize="body1"
        paddingX="none"
        activeStyle={{ color: 'inherit' }}
        onClick={onClick}
        ref={ref}
      >
        <Icon
          icon="tinyClose"
          size="12px"
          label={formatMessage({ id: 'CLOSE' })}
        />
      </QuietButton>
    </Box>
  );
});

const BackButtonLink = forwardRef(
  (
    { to, children, tabIndex, className, onClick }: LinkProps,
    ref: React.Ref<HTMLAnchorElement>,
  ) => (
    <Link
      className={className}
      tabIndex={tabIndex}
      innerRef={ref}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  ),
);

const BackButton = forwardRef(
  ({ to, onClick }: ButtonProps, ref: React.Ref<HTMLAnchorElement>) => {
    const { formatMessage } = useIntl();
    return (
      <Box position="absolute" top="one" left="oneAndHalf">
        <QuietButton
          as={BackButtonLink}
          size="two"
          textDecoration="none"
          fontSize="body1"
          paddingX="none"
          activeStyle={{ color: 'inherit' }}
          to={to}
          onClick={onClick}
          ref={ref}
        >
          <Icon
            icon="tinyBackButton"
            size="12px"
            label={formatMessage({ id: 'GO_BACK' })}
          />
        </QuietButton>
      </Box>
    );
  },
);

export const GetCareTriageComponent = ({
  version,
}: GetCareTriageComponentProps) => {
  const {
    isOpen,
    closeModal,
    isLoading,
    actionItems,
    menuItems,
    modalAriaProps,
    focusRef,
    topic,
    topicId,
    backButtonUrl,
    hasBackButton,
  } = useGetCareTriage(version);
  const { formatMessage } = useIntl();

  if (!isOpen) return null;

  const {
    'aria-label': ariaLabel,
    'aria-modal': ariaModal,
    role,
  } = modalAriaProps;

  // Conditionally set modal content
  let content;
  if (isLoading) {
    content = (
      <>
        <SkeletonBox height="32px" marginBottom="oneAndHalf" />
        <SkeletonCards numCards={3} height="84px" marginBottom="three" />
        <SkeletonBox height="78px" marginTop="one" />
        <SkeletonBox height="78px" marginTop="one" />
        <SkeletonBox height="78px" marginTop="one" />
        <SkeletonBox height="78px" marginTop="one" />
      </>
    );
  } else if (topic) {
    const { title, detailPage } = topic;
    const {
      title: detailPageTitle,
      disclaimer,
      menuItems: detailPageMenuItems,
      footerText,
      footerActionText,
      footerUrl,
    } = detailPage;

    content = (
      <TopicScreen
        topic={title}
        parentTopicId={topicId}
        // detailPage
        title={detailPageTitle}
        disclaimer={disclaimer}
        menuItems={detailPageMenuItems}
        footerText={footerText}
        footerActionText={footerActionText}
        footerUrl={footerUrl}
      />
    );
  } else {
    content = <TriageScreen actionItems={actionItems} menuItems={menuItems} />;
  }

  return (
    <Modal.Wrapper onClose={closeModal} options={{}}>
      <Box
        backgroundColor="surface.background.primary"
        borderRadius="medium"
        height={isLoading ? LOADING_HEIGHT_MATCHES_LOADED_CONTENT : 'auto'}
        maxHeight="70%"
        overflowX="auto"
        position="relative"
        width={480}
        // modalAriaProps
        aria-label={ariaLabel}
        aria-modal={ariaModal}
        role={role}
      >
        {hasBackButton ? (
          <>
            <BackButton
              to={backButtonUrl}
              ref={focusRef}
              onClick={() => {
                sendAnalyticsEvent({
                  category: 'Quick Action Detail',
                  action: 'Back To Quick Action Menu',
                  label: formatMessage({ id: 'GO_BACK' }),
                });
              }}
            />
            <CloseButton
              onClick={() => {
                sendAnalyticsEvent({
                  category: 'Quick Action Detail',
                  action: 'Close',
                  label: formatMessage({ id: 'CLOSE' }),
                });
                closeModal();
              }}
            />
          </>
        ) : (
          <CloseButton
            ref={focusRef}
            onClick={() => {
              sendAnalyticsEvent({
                category: 'Quick Action Menu',
                action: 'Close',
                label: formatMessage({ id: 'CLOSE' }),
              });
              closeModal();
            }}
          />
        )}
        <Box padding="two" paddingTop="four">
          {content}
        </Box>
      </Box>
    </Modal.Wrapper>
  );
};
