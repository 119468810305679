import React from 'react';
import { MasonryConfig } from 'types/config.types';
import { MINIMUM_API_VERSION } from '../constants/supported-api-version';

export type MasonryConfigContextType = Exclude<MasonryConfig, 'uiConfig'> &
  Required<Pick<MasonryConfig, 'uiConfig'>>;

export const defaultConfig: MasonryConfigContextType = {
  appId: '',
  apiVersion: MINIMUM_API_VERSION,
  analytics: {
    screenName: '',
    productArea: '',
  },
  uiConfig: {
    spinnerConfig: {
      thickness: 1.5,
      size: '48px',
      tint: '$interactiveActionPrimary',
      containerHeight: '80vh',
    },
  },
};

export const MasonryConfigContext =
  React.createContext<MasonryConfigContextType>(defaultConfig);
