import React from 'react';
import { Button } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { LOAD_MORE_BUTTON_MAX_WIDTH } from 'constants/challenges.constants';

export interface LoadMoreCTAProps {
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
  onClick?: () => void;
}

export const LoadMoreCTA = ({
  isFetchingNextPage,
  fetchNextPage,
  onClick,
}: LoadMoreCTAProps) => {
  const { formatMessage } = useIntl();
  const handleClick = () => {
    fetchNextPage();
    onClick?.(); // Call onClick if it exists
  };
  return (
    <Button
      loading={isFetchingNextPage}
      onClick={handleClick}
      priority="secondary"
      width="fillContainer"
      css={{
        maxWidth: LOAD_MORE_BUTTON_MAX_WIDTH,
      }}
    >
      {formatMessage({ id: 'VIEW_MORE' })}
    </Button>
  );
};
