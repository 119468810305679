import { parseUrl } from '@leagueplatform/web-common';
import { ActionItem } from '../types/care-discovery.types';

const hasUrl = (actionItem: ActionItem) => Boolean(actionItem.url);

const addIsExternal = ({ url, ...actionItem }: ActionItem) => ({
  ...actionItem,
  url,
  isExternal: window.location.hostname !== parseUrl(url).hostname,
});

export const setIsExternal = (items: ActionItem[]) =>
  items.filter(hasUrl).map(addIsExternal);
