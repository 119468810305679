import React from 'react';
import { Card, useClickableCard } from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { MasonryNotificationBannerContentRenderer } from './masonry-notification-banner-content-renderer';
import {
  MasonryDeeplinkAction,
  MasonryDismissAction,
  MasonryDriverAction,
} from '../../types/masonry-driver-node-actions';
import { ConditionalLinkCardRenderer } from '../common/conditional-link-card-renderer/conditional-link-card-renderer.component';

type MasonryNotificationBannerNodeProperties = {
  heading: string;
  body: string;
  image: string;
  imageAltText: string;
};

type MasonryNotificationBannerNodeActions = {
  onClick?: MasonryDriverAction;
  onDismiss?: MasonryDismissAction;
};

export type MasonryNotificationBannerNode = MasonryEngineNode<
  'notificationBanner',
  MasonryNotificationBannerNodeProperties,
  MasonryNotificationBannerNodeActions
>;

export type MasonryNotificationBannerRendererProps =
  MasonryEngineNodeRendererProps<MasonryNotificationBannerNode>;

export const MasonryNotificationBannerRenderer = ({
  heading,
  image,
  body,
  imageAltText,
  onClick,
  onDismiss,
}: MasonryNotificationBannerRendererProps) => {
  const dismissible = Boolean(onDismiss);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <ConditionalLinkCardRenderer
      hasLink={Boolean((onClick as MasonryDeeplinkAction)?.payload.url)}
      ref={clickAreaRef}
      onCardMouseUp={onCardMouseUp}
      onCardMouseDown={onCardMouseDown}
      dismissible={dismissible}
      onDismiss={onDismiss}
      css={{
        ' &:has(.GDS-card-click-area)': {
          boxShadow: '$card',
        },
      }}
    >
      <Card.Section>
        <MasonryNotificationBannerContentRenderer
          image={image}
          imageAltText={imageAltText}
          heading={heading}
          body={body}
          onClick={onClick}
          ref={primaryActionRef}
        />
      </Card.Section>
    </ConditionalLinkCardRenderer>
  );
};
