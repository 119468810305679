import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ADMIN_SET_EMPLOYEE_MESSAGE_TYPE = 'admin_set_employee';

export const adminSetEmployee = (values: Record<string, any>) =>
  SocketAsFetch.fetch({
    message_type: ADMIN_SET_EMPLOYEE_MESSAGE_TYPE,
    info: {
      ...values,
      version: 2,
    },
  });
