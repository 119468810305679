import * as React from 'react';
import compareDesc from 'date-fns/compareDesc';
import { useIntl } from '@leagueplatform/locales';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import {
  useTheme,
  HeadingOne,
  HeadingTwo,
  Flex,
  Caption,
} from '@leagueplatform/genesis-commons';
import { HealthMetricValueV3 } from '@leagueplatform/dashboard-api';
import { useFormatDateByUserProfileLocation } from '@leagueplatform/web-common';
import { shapes } from 'components/charts/core/xychart/primitives/Shape';
import { getHealthMetricUnitDisplayValue } from 'utils/get-health-metric-unit-display-value';
import { getValueUnitDisplayLabel } from 'utils/get-value-unit-display-label';
import { DatumLegend } from './datum-legend';

type TodaysMeasurementPanelProps = {
  todaysValues: HealthMetricValueV3[];
};

function useCachedTodaysValues(todaysValues: HealthMetricValueV3[]) {
  const [cachedTodaysValues, setCachedTodaysValues] =
    React.useState(todaysValues);

  const memoizedValues = React.useMemo(() => {
    // let newCachedTodaysValues = cachedTodaysValues;

    if (todaysValues.length > 0) {
      setCachedTodaysValues(todaysValues);
      // newCachedTodaysValues = todaysValues;
    }

    const lastUpdated = [...cachedTodaysValues].sort((a, b) =>
      compareDesc(new Date(a.timestamp), new Date(b.timestamp)),
    )[0]?.timestamp;

    // sort todays values by the metricComponent `order` property, in ascending order
    cachedTodaysValues.sort(
      (a, b) => a.metricComponent.data.order - b.metricComponent.data.order,
    );

    return { cachedTodaysValues, lastUpdated };
  }, [todaysValues, cachedTodaysValues]);

  return memoizedValues;
}

function Values({ todaysValues }: TodaysMeasurementPanelProps) {
  if (todaysValues.length === 0) {
    return <HeadingOne as="span">--</HeadingOne>;
  }

  return (
    <>
      {todaysValues.map((todaysValue) => {
        const shape = shapes[todaysValue.metricComponent.data.order];
        const unit = getHealthMetricUnitDisplayValue(
          todaysValue.label,
          todaysValue.metricComponent.data.unit,
        );
        return todaysValues.length > 1 ? (
          <StackLayout key={todaysValue.type} horizontalAlignment="center">
            <DatumLegend
              shape={shape}
              name={todaysValue.metricComponent.data.name.toUpperCase()}
              value={todaysValue.label}
              unit={unit}
            />
          </StackLayout>
        ) : (
          getValueUnitDisplayLabel({
            value: todaysValue.value,
            label: todaysValue.label,
            unitOptions: todaysValue.metricComponent.data.unit,
          }).map(([displayValue, displayUnit], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`${index} + ${todaysValue.type}`}>
              <HeadingOne as="span">{displayValue}</HeadingOne>
              <Caption
                as="span"
                paddingLeft="quarter"
                color="onSurface.text.subdued"
              >
                {displayUnit}
              </Caption>
            </Box>
          ))
        );
      })}
    </>
  );
}

export function TodaysMeasurementPanel({
  todaysValues,
}: TodaysMeasurementPanelProps) {
  const { formatMessage, formatList } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const theme = useTheme();

  const { cachedTodaysValues, lastUpdated } =
    useCachedTodaysValues(todaysValues);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="oneAndHalf"
        paddingRight="oneAndHalf"
        paddingTop="one"
        marginRight="two"
        paddingBottom="one"
        backgroundColor="surface.background.secondary"
        width={`calc(100% - ${theme.space.two}px)`}
      >
        <HeadingTwo>
          {formatMessage({
            id: 'TODAY',
          })}
        </HeadingTwo>
        <Flex alignItems="baseline">
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="center"
            spacing="$half"
          >
            <Values todaysValues={cachedTodaysValues} />
          </StackLayout>
        </Flex>
      </Flex>
      {lastUpdated ? (
        <Flex
          width={`calc(100% - ${theme.space.two}px)`}
          paddingTop="half"
          paddingBottom="quarter"
          justifyContent="flex-end"
          marginRight="two"
        >
          <Caption
            as="span"
            paddingLeft="quarter"
            color="onSurface.text.subdued"
            fontWeight="bold"
          >
            {formatMessage({
              id: 'LAST_UPDATED',
            })}
            :
          </Caption>
          <Caption
            as="span"
            paddingLeft="quarter"
            color="onSurface.text.subdued"
            minWidth={theme.space.five}
          >
            {formatList(
              [
                formatMessage({
                  id: 'TODAY',
                }),
                formatDateWithUserProfileLocation(lastUpdated, {
                  hour: 'numeric',
                  minute: 'numeric',
                }),
              ],
              { type: 'conjunction', style: 'narrow' },
            )}
          </Caption>
        </Flex>
      ) : null}
    </>
  );
}
