export enum WidgetType {
  SMALL_VISUAL_CARD = 'text_with_small_image',
  LARGE_VISUAL_CARD = 'text_with_large_image',
  TEXT_WITH_CTA_CARD = 'text_with_button',
  CAROUSEL = 'carousel',
  HEALTH_JOURNEY = 'health_journey', // DEPRECATED after custom component
  WELCOME_BANNER = 'welcome_banner', // DEPRECATED after v1
  HERO_BANNER = 'heroBanner',
  PRODUCT_CAROUSEL = 'product_carousel',
  NOTIFICATION_BANNER = 'notification_banner',
  NOTIFICATION_CARD = 'notification_card',
  XS_VISUAL_CARD = 'xs_visual_card',
  VERTICAL_STACK_XS = 'vertical_stack_xs',
  IMAGE_FOOTER = 'image_footer',
  CUSTOM_COMPONENT = 'custom_component',
  PROGRESS_BAR = 'progress_bar',
  PROGRESS_RING = 'progress_ring',
  TWO_UP_CARD = '2_up_card',
  SECTION_HEADER = 'section_header',
  STACK = 'stack',
  GENERIC_CAROUSEL = 'genericCarousel',
  METRIC_CARD = 'metricCard',
  HIDDEN = 'hidden',
}
