import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { DASHBOARD_CONFIG_PATH } from './constants';
import {
  DashboardConfigDeserialisedData,
  DashboardConfigResponseData,
  Units,
} from './types';

export async function patchDashboardConfig(
  units: Units[],
  dashboardConfig: DashboardConfigDeserialisedData,
): Promise<DashboardConfigDeserialisedData> {
  const response = await leagueFetch(DASHBOARD_CONFIG_PATH, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: dashboardConfig.data.id,
        type: dashboardConfig.data.type,
        attributes: {
          units,
        },
      },
    }),
  });

  if (response.status !== 200) {
    throw new Error(`patchDashboardConfig error: ${response.status}`);
  }

  const responseJson: DashboardConfigResponseData = await response.json();
  return deserialise(responseJson.data);
}
