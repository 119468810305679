import * as React from 'react';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  PillTabs,
  PillTabList,
  PillTab,
} from '@leagueplatform/web-common-components';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { useParams } from '@leagueplatform/routing';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { CHART_VIEWS } from 'components/charts/constants/chart-views';
import { Box } from '@leagueplatform/genesis-core';

const ViewSwitcherContainer = genesisStyled(Flex)(
  css({
    marginTop: '-30px',
  }),
);

type WeekMonthViewSwitchProps = {
  onChange: (view: string) => void;
};

const handleAnalyticsClick = (metricType: string, detail: string) =>
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
    metric_type: metricType,
    detail,
  });

export function WeekMonthViewSwitch({ onChange }: WeekMonthViewSwitchProps) {
  const { formatMessage } = useIntl();
  const { dataType } = useParams<{ dataType: string }>();

  return (
    <ViewSwitcherContainer>
      <PillTabs onValueChange={onChange} defaultValue={CHART_VIEWS.WEEKLY}>
        <Box css={{ width: 'fit-content' }}>
          <PillTabList>
            <PillTab
              value={CHART_VIEWS.WEEKLY}
              onClick={() => handleAnalyticsClick(dataType, 'last 7 days')}
            >
              {formatMessage({
                id: 'LAST_7_DAYS',
              })}
            </PillTab>
            <PillTab
              value={CHART_VIEWS.MONTHLY}
              onClick={() => handleAnalyticsClick(dataType, 'last 30 days')}
            >
              {formatMessage({
                id: 'LAST_30_DAYS',
              })}
            </PillTab>
          </PillTabList>
        </Box>
      </PillTabs>
    </ViewSwitcherContainer>
  );
}
