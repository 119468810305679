import React from 'react';
import PropTypes from 'prop-types';
import QUESTION_TOOLTIP_ICON from 'assets/questionmark-in-circle.svg';
import { Box, Image } from '@leagueplatform/genesis-commons';

export const LightQuestionIcon = ({ altText }) => (
  <Box as="span" display="block" width="one" height="one">
    <Image src={QUESTION_TOOLTIP_ICON} alt={altText} display="block" />
  </Box>
);

LightQuestionIcon.propTypes = {
  altText: PropTypes.string,
};
LightQuestionIcon.defaultProps = {
  altText: 'Tooltip',
};
