import { useState } from 'react';
import { useQuery } from 'react-query';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  getRecentAchievements,
  GET_RECENT_ACHIEVEMENTS_MESSAGE_TYPE,
  getAchievementsStats,
  GET_ACHIEVEMENTS_STATS_MESSAGE_TYPE,
} from '@leagueplatform/rewards-api';
import { captureError } from '@leagueplatform/observability';
import { ACHIEVEMENTS_ENABLED } from '../constants/feature-flag-names.constants';

export const useAchievementsDetail = () => {
  const [showActivityCompleteModal, toggleActivityCompleteModal] =
    useState(false);

  const { data: userRecentAchievementsData } = useQuery(
    [GET_RECENT_ACHIEVEMENTS_MESSAGE_TYPE],
    getRecentAchievements,
    {
      onError: (error) => {
        captureError(
          new Error(`Unable to fetch user's recent achievements: ${error}`),
        );
      },
    },
  );

  const { data: userAchievementStatsData } = useQuery(
    [GET_ACHIEVEMENTS_STATS_MESSAGE_TYPE],
    getAchievementsStats,
    {
      onError: (error) => {
        captureError(
          new Error(`Unable to fetch user's achievements stats: ${error}`),
        );
      },
    },
  );

  return {
    isFeatureFlagOn: useFeatureFlagQuery(ACHIEVEMENTS_ENABLED),
    showActivityCompleteModal,
    toggleActivityCompleteModal,
    completedAchievementStats:
      userAchievementStatsData?.achievements_stats ?? [],
    recentAchievements: userRecentAchievementsData?.recent_achievements ?? [],
  };
};
