import * as React from 'react';
import { Tabs } from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  useMasonryEngineActionEmitter,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { MasonryDriverAction } from 'types/masonry-driver-node-actions';

export interface MasonryTabListItem {
  title: string;
  id: string;
}
export interface MasonryTabsNodeProps {
  defaultSelectedTabId: string;
  tabList: MasonryTabListItem[];
  tabListName: string;
}

export type MasonryTabsNodeActions = {
  onClick?: MasonryDriverAction;
};

export type MasonryTabsNode = MasonryEngineNode<
  'tabs',
  MasonryTabsNodeProps,
  MasonryTabsNodeActions,
  { items: MasonryEngineNode<string, any>[] }
>;

export type MasonryTabsRendererProps =
  MasonryEngineNodeRendererProps<MasonryTabsNode>;

export const MasonryTabsRenderer = ({
  items,
  defaultSelectedTabId,
  tabList = [],
  tabListName,
  onClick,
}: MasonryTabsRendererProps) => {
  const invokeAction = useMasonryEngineActionEmitter();
  if (!items || !tabList.length) return null;

  const handleOnClick = (tabId: string) => {
    if (!onClick) return;

    if (onClick?.analytics) {
      invokeAction({
        ...onClick,
        analytics: { ...onClick.analytics, tabId },
      });

      return;
    }

    invokeAction(onClick);
  };

  return (
    <Tabs.Root defaultValue={defaultSelectedTabId ?? tabList[0].id}>
      <Tabs.TabList
        aria-label={tabListName ?? undefined}
        divider
        tabDistribution="hugContents"
      >
        {tabList.map((tab) => (
          <Tabs.Tab
            key={`${tab.id}-tab-button`}
            value={tab.id}
            onClick={() => {
              handleOnClick(tab.id);
            }}
          >
            {tab.title}
          </Tabs.Tab>
        ))}
      </Tabs.TabList>
      {React.Children.toArray(items).map((item, index) => {
        // Do not render a tab panel that does not have a matching tab
        if (!tabList[index]?.id) return null;

        return (
          <Tabs.TabPanel
            key={`${tabList[index].id}-tabpanel`}
            value={tabList[index].id}
          >
            {item}
          </Tabs.TabPanel>
        );
      })}
    </Tabs.Root>
  );
};
