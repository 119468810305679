import {
  CA_COUNTRY_CODE,
  DEFAULT_LOCALE,
  US_COUNTRY_CODE,
  getAppSupportedLocale,
} from '@leagueplatform/locales';
import { UserGroup } from '@leagueplatform/user-profile-api';
import { get } from 'lodash';

// Types
enum ProductTypes {
  HEALTH_SAVINGS_ACCOUNT = 'health_savings_account',
  FLEXIBLE_SPENDING_ACCOUNT = 'flexible_spending_account',
  HFSA = 'healthcare_flexible_spending_account',
  DCFSA = 'dependent_care_flexible_spending_account',
  LPFSA = 'limited_purpose_flexible_spending_account',
  CRA = 'cra',
}

export enum HelpCenterDomainKeys {
  CA = CA_COUNTRY_CODE,
  US = US_COUNTRY_CODE,
}

type HelpCenterDomainProperty<TValue extends unknown> = Record<
  HelpCenterDomainKeys | string,
  TValue
>;

export type HelpCenterLink = HelpCenterDomainProperty<string> | string;

type GetHelpCenterLinkArgs = {
  helpCenter: HelpCenterLink;
  userGroup: UserGroup;
  source?: string;
  defaultDomain?: HelpCenterDomainKeys;
};

type GetHelpCenterLink = (args: GetHelpCenterLinkArgs) => string;

// Constants
const { CA, US } = HelpCenterDomainKeys;

/** Base URLs for each help center domain */
export const HelpCenterDomainURLs: HelpCenterDomainProperty<string> = {
  [CA]: 'https://help.league.com',
  [US]: 'https://ushelp.league.com',
};

/** Available locales for each help center domain */
export const HelpCenterDomainLocaleMap: HelpCenterDomainProperty<string[]> = {
  [CA]: ['en', 'fr'],
  [US]: ['en', 'es'],
};

// Utilities
export const getHelpCenterLink: GetHelpCenterLink = ({
  helpCenter,
  userGroup,
  source = 'Enrollment',
  defaultDomain = CA,
}) => {
  // Exit early if there is no user group
  if (!userGroup?.group) return HelpCenterDomainURLs[defaultDomain];

  try {
    const { group } = userGroup;
    const domainKey: string = get(group, 'country_code', defaultDomain);

    // From the userGroup, derive the base URL, supported locales, and which URL path to use
    const helpCenterBaseURL = HelpCenterDomainURLs[domainKey];
    const supportedLocales = HelpCenterDomainLocaleMap[domainKey];

    // Single-domain paths are strings
    const pathForDomain =
      typeof helpCenter === 'string' ? helpCenter : helpCenter[domainKey];

    // Exit early, log error when unable to find a valid helpCenter path
    if (!pathForDomain) {
      throw new Error(
        `No path is provided for the help center link provided in the ${domainKey} domain`,
      );
    }

    // Reconcile the user's locale preference with those supported by the domain
    const language = getAppSupportedLocale(DEFAULT_LOCALE, supportedLocales);

    // Construct the final URL to return
    const helpCenterPath = [language, pathForDomain].filter(Boolean).join('/');

    const url = new URL(helpCenterPath, helpCenterBaseURL);

    if (source) {
      url.searchParams.append('utm_source', source);
    }

    return url.toString();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return '';
  }
};

// Help Center Links
export const BENEFITS_LEARN_MORE_LINK = {
  [CA]: 'faqs/Working-version/CAN/view-and-download-your-benefits-information',
  [US]: 'faqs/Working-version/US/view-and-download-your-benefits-information',
};

export const HEALTH_PROGRAMS_AND_JOURNEY_LEARN_MORE_LINK = {
  [CA]: 'faqs/Working-version/CAN/complete-activities',
  [US]: 'faqs/Working-version/US/complete-activities',
};

export const PRORATION_ARTICLE_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-is-proration',
  [US]: 'faqs/Working-version/US/what-is-proration',
};

export const WHAT_IS_QLE_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-is-a-qualifying-life-event-qle',
  [US]: 'faqs/Working-version/US/what-is-a-qualifying-life-event-qle',
};

export const COORDINATION_OF_BENEFITS_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-is-coordination-of-benefits-cob',
  [US]: 'faqs/',
};

export const BENEFICIARIES_ARTICLE_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-is-a-beneficiary',
  [US]: 'faqs/Working-version/US/what-is-a-beneficiary',
};

export const EOI_ARTICLE_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-is-evidence-of-insurability-eoi',
  [US]: 'faqs/Working-version/US/what-is-evidence-of-insurability-eoi',
};

export const UPLOAD_DOCUMENTS_LINK = {
  [CA]: 'faqs/Working-version/CAN/uploading-documents',
  [US]: 'faqs/Working-version/US/uploading-documents',
};

/*
  The links defined here are by product type, and the url path will be appended
  with the help center base url (helpDeskLink).
  Note: These links are currently specific to US benefits. The format may change
  depending on the condition in the future to show different versions according to
  the language or country code
*/
export const CONTRIBUTION_ARTICLE_LINK_BY_PRODUCT_TYPES = {
  [ProductTypes.HEALTH_SAVINGS_ACCOUNT]:
    'faqs/Working-version/US/what-is-a-health-savings-account-hsa',
  [ProductTypes.FLEXIBLE_SPENDING_ACCOUNT]:
    'faqs/Working-version/US/what-is-a-fsa-and-how-do-i-use-it',
  [ProductTypes.HFSA]:
    'faqs/Working-version/US/what-is-a-fsa-and-how-do-i-use-it',
  [ProductTypes.DCFSA]:
    'faqs/Working-version/US/what-is-a-dependent-care-flexible-spending-account',
  [ProductTypes.LPFSA]:
    'faqs/Working-version/US/what-is-limited-purpose-flexible-spending-account',
  [ProductTypes.CRA]:
    'faqs/Working-version/US/what-is-a-commuter-reimbursement-account-cra',
};

export const GETTING_STARTED_WITH_LEAGUE_LINK = {
  [CA]: 'faqs/Working-version/CAN/getting-started-with-league',
  [US]: 'faqs/Working-version/US/getting-started-with-league',
};

export const WHAT_ARE_CONNECTED_HEALTH_PROGRAMS_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-are-connected-health-programs',
  [US]: 'faqs/Working-version/US/what-are-connected-health-programs',
};

export const WHAT_DEVICES_CAN_CONNECT_LINK = {
  [CA]: 'faqs/Working-version/CAN/what-wearable-apps-and-devices-can-i-connect',
  [US]: 'faqs/Working-version/US/what-wearable-apps-and-devices-can-i-connect',
};

export const HEALTH_PROGRAMS_MANUAL_TRACKING_LINK = {
  [CA]: 'faqs/Working-version/CAN/do-i-have-to-connect-a-wearable-app-or-device',
  [US]: 'faqs/Working-version/US/do-i-have-to-connect-a-wearable-app-or-device',
};
