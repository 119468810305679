import styled, { css } from 'styled-components';

import { isProduction } from 'ui-kit/utils/isProduction';

import {
  compose,
  space,
  width,
  color,
  fontSize,
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  borders,
  boxShadow,
  alignSelf,
  flex,
  order,
  flexDirection,
  alignItems,
  justifyContent,
  flexWrap,
} from 'styled-system-old';

const baseSystems = compose(
  space,
  width,
  color,
  fontSize,
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
  borders,
  boxShadow,
  alignSelf,
  flex,
  order,
  flexDirection,
  alignItems,
  justifyContent,
  flexWrap,
);

/**
 * Box Component
 */
export const Box = styled.div`
  box-sizing: border-box;
  ${baseSystems}
`;

Box.displayName = 'Box';
Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...borders.propTypes,
  ...boxShadow.propTypes,
  ...alignSelf.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
};

/**
 * Flex Component
 * Extends the Box component and sets display flex. It also includes the flex related props.
 */
export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  ${baseSystems}
`;

Flex.displayName = 'Flex';
Flex.propTypes = {
  ...Box.propTypes,
  ...alignSelf.propTypes,
  ...order.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...flexWrap.propTypes,
};

/**
 * Fill Component
 * Extends the Box component and sets flex 1 with min width and height.
 */
export const Fill = styled(Box)`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
`;
Fill.displayName = 'Fill';

/**
 * Fit Component
 * Extends the Box component and sets flex to none.
 */
export const Fit = styled(Box)`
  flex: none;
`;
Fit.displayName = 'Fit';

/**
 * Inline Flex Component
 * Extends the Flex component and sets display to inline-flex.
 */
export const InlineFlex = styled(Flex)`
  display: inline-flex;
`;
InlineFlex.displayName = 'InlineFlex';

/**
 * Text Component
 */
// TODO: Replace weight and size props with appropriate styled-system props
export const Text = styled.p`
  box-sizing: border-box;
  ${baseSystems}

  ${({ weight }) => {
    if (!weight) return null;

    if (!isProduction()) {
      // eslint-disable-next-line
      console.warn(
        `Text component "weight" prop is deprecated, use styled system "fontWeight" instead! Got weight ${weight}.`,
      );
    }

    return css`
      font-weight: ${weight};
    `;
  }};
  ${({ size }) => {
    if (!size) return null;

    if (!isProduction()) {
      // eslint-disable-next-line
      console.warn(
        `Text component "size" prop is deprecated, use styled system "fontSize" instead! Got size ${size}.`,
      );
    }
    return css`
      font-size: ${size};
    `;
  }};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

Text.displayName = 'Text';
Text.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...borders.propTypes,
  ...boxShadow.propTypes,
  ...alignSelf.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
};
