import * as React from 'react';
import {
  GenericCarouselWidget,
  GenericCarouselWidgetProps,
  COMPLEX_WIDGET_CARDS_WIDTH,
} from '@leagueplatform/masonry-widgets';
import { StackItem } from '@leagueplatform/genesis-core';
import { useGetConfig } from 'hooks/use-get-config.hook';
import { WidgetTypeToSimpleComponentMap } from 'utils/custom-module-utils';
import { uniqueId } from 'lodash';
import { DefaultWidget } from 'components/default-widget/default-widget';
import { ComplexWidgetItemData } from 'types/widget-display-data.types';

export type GenericCarouselProps = Omit<
  GenericCarouselWidgetProps,
  'children'
> & {
  items: ComplexWidgetItemData[];
  onItemClick: (data: ComplexWidgetItemData, index: number) => void;
};

/**
 * @param {data} children - Accepts strings, elements, and React components
 */

export const GenericCarousel = ({
  items,
  onItemClick,
  ...props
}: GenericCarouselProps) => {
  const config = useGetConfig();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <GenericCarouselWidget {...props}>
      {items?.map((item, index) => {
        const WidgetComponent = WidgetTypeToSimpleComponentMap[item.type];
        if (!WidgetComponent) return null;

        return (
          <StackItem
            css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH, height: '100%' }}
          >
            <DefaultWidget
              key={uniqueId()}
              data={item}
              component={WidgetComponent}
              config={config}
              onCardClick={() => onItemClick(item, index)}
              isChildItem
            />
          </StackItem>
        );
      })}
    </GenericCarouselWidget>
  );
};
