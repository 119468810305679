import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Subtitle = (props) => {
  const StyledSubtitle = styled.div`
    text-transform: ${props.textTransform};
    font-size: 1.2rem;
    margin: 1.6rem 0;
  `;

  return <StyledSubtitle {...props}>{props.children}</StyledSubtitle>;
};

Subtitle.defaultProps = {
  textTransform: 'none',
  children: '',
};

Subtitle.propTypes = {
  /** [1-3] - the level of emphasis */
  textTransform: PropTypes.string,
  /** The content of header */
  children: PropTypes.node,
};

export default Subtitle;
