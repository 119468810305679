import React from 'react';
import { BadgeImage, ProgressBar } from '@leagueplatform/rewards-achievements';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';

interface BadgeProgressProps {
  imageUrl: string;
  title: string;
  isLoading: boolean;
  currentActivity: number;
  totalActivities: number;
  progressTitle: string;
  completed: boolean;
}

export const BadgeProgress = ({
  imageUrl,
  title,
  isLoading,
  currentActivity,
  totalActivities,
  progressTitle,
  completed,
}: BadgeProgressProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    spacing="$one"
    css={{ width: '100%', padding: '$one' }}
  >
    <BadgeImage imageUrl={imageUrl} isDisabled={!completed} />
    <StackLayout horizontalAlignment="stretch">
      <HeadingText level="2" size="xs">
        {title}
      </HeadingText>
      <ProgressBar
        isLoading={isLoading}
        currentActivity={currentActivity}
        totalActivities={totalActivities}
        progressTitle={progressTitle}
      />
    </StackLayout>
  </StackLayout>
);
