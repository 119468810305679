import { get } from 'lodash';
import { keyframes, css } from 'styled-components';
import { vars } from '../vars';

/**
 * Returns an object containing color hex values
 * This is a workaround to ensure that league-b2b tests pass ever since updating UI-Kit components to access colors from the ThemeProvider
 * @param {string} theme - theme object from the ThemeProvider
 * @returns {object} color object
 */
export const getColourObject = (theme) => {
  return theme?.colour || vars.colour;
};

/**
 * Converts a CSS hex color value to RGBA.
 * @param {string} hex - Expanded hexadecimal CSS color value in the format #rrggbb
 * @param {number} alpha - Alpha as a decimal, 0-1 inclusive
 * @returns {string} RGBA CSS color value.
 */
export function hex2Rgba(hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const slideUp = keyframes`
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const slideDown = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(30px);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const pulseKeyframes = keyframes`
  0% {
    background-color: ${hex2Rgba(vars.colour.lightMediumGrey, 0.45)};
  }

  100% {
    background-color: ${hex2Rgba(vars.colour.lightMediumGrey, 0.9)};
  }
`;

export const pulse = css`
  ${pulseKeyframes} .8s linear infinite alternate;
`;

const shakeKeyframes = keyframes`
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
`;

export const shake = css`
  ${shakeKeyframes} 0.82s cubic-bezier(.36,.07,.19,.97) both;
`;

const { darkBlack, flushOrange, brilliantRose, mint, moneyGreen, purple } =
  vars.colour;

const colorMap = {
  submitted: {
    inner: purple,
    outer: purple,
  },
  more_information_needed: {
    inner: flushOrange,
    outer: flushOrange,
  },
  approved: {
    inner: mint,
    outer: mint,
  },
  paid: {
    inner: moneyGreen,
    outer: moneyGreen,
  },
  rejected: {
    inner: brilliantRose,
    outer: brilliantRose,
  },
  cancelled: {
    inner: darkBlack,
    outer: darkBlack,
  },
  // should never really get to this point, but default
  default: {
    inner: purple,
    outer: purple,
  },
};

/**
 * Gets an object with colours from a map
 * @param {string} status - The status of a claim
 * @returns {object} inner, outer, both HEX as string
 */
export const getStatusColor = (status) =>
  get(colorMap, status, colorMap.default);

/**
 * Sums values of an array of objects, using a specific field
 * @param {array} array - The array containing values to be summed
 * @param {string} field - Lodash shorthand for the path to the field
 * @returns {number} - The sum
 */
export const sumField = (array = [], field) =>
  array.reduce((acc, curr) => acc + get(curr, field), 0);
