// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.2.0.

/*
 * Following tokens have been deprecated in version 1.2.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '../theme-types';

export const archetypeDarkTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#111111FF',
        secondary: '#333333FF',
        tertiary: '#555555FF',
      },
      card: {
        primary: '#3D3D3DFF',
        secondary: '#000000FF',
        disabled: '#585858FF',
      },
      screenHeader: { background: '#DEF3FCFF' },
      skeleton: {
        shimmer: {
          background: '#E7E7E7FF',
          overlay:
            'linear-gradient(90.0deg, #FFFFFF00 0%, #FFFFFF80 50%, #FFFFFFE5 70%, #FFFFFF00 100%)',
        },
      },
    },
    onSurface: {
      text: {
        primary: '#F9F9F9FF',
        subdued: '#CECECEFF',
        reversed: '#000000FF',
        critical: '#EF6782FF',
        success: '#BCF5E2FF',
        warning: '#F9F9F9FF',
      },
      border: { subdued: '#515B66FF', default: '#ADADADFF', card: '#959595FF' },
      icon: { primary: '#CACACAFF' },
    },
    interactive: {
      action: {
        primary: '#72D1FEFF',
        hovered: '#99D3EEFF',
        pressed: '#28BBFFFF',
        subdued: '#D3D3D3FF',
        disabled: '#A8A8A8FF',
      },
      icon: { default: '#171717FF', disabled: '#474747FF' },
      background: {
        default: '#000000FF',
        disabled: '#1A1D22FF',
        hovered: '#070707FF',
        pressed: '#101010FF',
      },
      border: {
        default: '#ADADADFF',
        hovered: '#72D1FEFF',
        disabled: '#474747FF',
        critical: { default: '#EF6782FF' },
      },
      focus: { inner: '#A3BDF3FF', outer: '#9AB5EDFF' },
    },
    critical: {
      background: { subdued: '#5E0407FF', secondary: '#000000FF' },
      border: { default: '#F8D7D9FF' },
      icon: '#F491A5FF',
    },
    warning: {
      background: { subdued: '#443D00FF', secondary: '#000000FF' },
      border: { default: '#FFF0A2FF' },
      icon: '#FFF0A2FF',
    },
    success: {
      background: { subdued: '#0E6932FF', secondary: '#000000FF' },
      border: { default: '#9AEFD3FF' },
      icon: '#9AEFD3FF',
    },
    highlight: {
      background: { subdued: '#00374FFF', secondary: '#000000FF' },
      border: { default: '#94ACEFFF' },
      icon: '#C6EAFAFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#F1FAFFFF',
          dark: '#C0E7FFFF',
          default: '#6BC5EFFF',
          bright: '#1C7EBBFF',
          brightest: '#074E7AFF',
          pastel: '#03253AFF',
        },
        secondary: {
          darkest: '#FFF8EAFF',
          dark: '#FFE4B0FF',
          default: '#F5C464FF',
          bright: '#F2A818FF',
          brightest: '#C2891BFF',
          pastel: '#845A09FF',
        },
        tertiary: {
          darkest: '#CCFF9AFF',
          dark: '#56FE64FF',
          default: '#18F2A4FF',
          bright: '#12B67BFF',
          brightest: '#0C7952FF',
          pastel: '#063D29FF',
        },
      },
    },
    primary: {
      background: {
        default: '#0F80B4FF',
        hovered: '#36A7DCFF',
        pressed: '#06A4EFFF',
        critical: {
          default: '#CB2A32FF',
          hovered: '#E13557FF',
          pressed: '#E75A76FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#333333FF',
        pressed: '#1B1B1BFF',
      },
      text: { default: '#06A4EFFF', hovered: '#FFFFFFFF' },
      border: { default: '#06A4EFFF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#F0F0F0FF',
        pressed: '#E1E1E1FF',
      },
      text: { default: '#C1C1C1FF', hovered: '#1E1E1EFF' },
      border: { default: '#C1C1C1FF' },
    },
    input: {
      border: {
        success: '#B3CE84FF',
        warning: '#FFF0A2FF',
        default: '#ADADADFF',
        hovered: '#72D1FEFF',
        disabled: '#474747FF',
        critical: '#EF6782FF',
      },
      background: {
        default: '#000000FF',
        disabled: '#1A1D22FF',
        hovered: '#070707FF',
        pressed: '#101010FF',
      },
    },
    dataViz: {
      interface: { track: { border: '#959595FF', background: '#F5F5F5FF' } },
      singleColor: { brand: '#008BCCFF', neutral: '#959595FF' },
      status: {
        critical: '#CB2A32FF',
        warning: '#DE6F12FF',
        success: '#159369FF',
      },
      categorical: {
        one: '#008BCCFF',
        two: '#074664FF',
        three: '#00699AFF',
        four: '#C2891BFF',
        five: '#845A09FF',
        six: '#008B82FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#FFFFFF14' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#FFFFFF1A' },
  },
  typography: {
    headingOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none',
    },
    caption: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily:
        "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3, card: 1 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
    card: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings:
      "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },
  transitions: { defaultTime: '200ms' },
};
