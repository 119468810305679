import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  StackLayout,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import {
  ColorProps,
  SizeProps,
  CoverImage,
} from '@leagueplatform/genesis-commons';
import { REWARDS_COMMON_ASSET_KEYS } from '../../asset-map';

export interface ChallengeTokenIconProps {
  circleColor?: ColorProps['backgroundColor'];
  starColor?: ColorProps['backgroundColor'];
  size?: SizeProps['size'];
  altText?: string;
  subdued?: boolean;
  value: number;
}

export const ChallengeTokenIcon = ({
  circleColor = 'warning.icon',
  starColor = 'warning.background.secondary',
  size = 'threeQuarters',
  value,
  altText,
  subdued,
}: ChallengeTokenIconProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { REWARDS_CHALLENGE_TOKEN, REWARDS_CHALLENGE_TOKEN_SUBDUED } =
    REWARDS_COMMON_ASSET_KEYS;
  const componentOptions = {
    isComponent: true,
    props: {
      circleColor,
      starColor,
      size,
    },
  };
  const tokenIcon = subdued ? (
    <CoverImage
      src={handleStaticAsset(REWARDS_CHALLENGE_TOKEN_SUBDUED) as string}
      alt=""
      size={size}
    />
  ) : (
    handleStaticAsset(REWARDS_CHALLENGE_TOKEN, componentOptions)
  );
  return (
    <StackLayout
      orientation="horizontal"
      spacing="$quarter"
      verticalAlignment="center"
    >
      {tokenIcon}

      <VisuallyHidden>
        {altText || formatMessage({ id: 'X_TOKENS' }, { tokenCount: value })}
      </VisuallyHidden>

      <UtilityText
        css={{
          color: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
        }}
        aria-hidden
      >
        {formatNumber(value)}
      </UtilityText>
    </StackLayout>
  );
};
