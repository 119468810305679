import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import PropTypes from 'prop-types';
import {
  Flex,
  SubtitleTwo,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { KEY } from '@leagueplatform/web-common';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';

const Tab = genesisStyled(Flex)(({ isActiveTab, keyboardFocus }) =>
  css({
    boxShadow:
      '8px 0px 8px -8px rgba(0, 0, 0, 0.16), -8px 0px 8px -8px rgba(0, 0, 0, 0.16)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: !isActiveTab && 'surface.background.secondary',
    },
    '&:focus': {
      outline: !keyboardFocus && 'none',
    },
  }),
);

const zIndexBooster = 10;

export const StatusFilterTabs = ({
  cardStatuses,
  setStatusFilter,
  statusFilter,
}) => {
  const { $tmap } = useIntl();
  const [keyboardFocus, setKeyboardFocus] = useState(false);
  const handleClick = (status) => {
    setStatusFilter(status);
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: SCREEN_NAMES.HOME_PAGE,
      product_area: PRODUCT_AREA.WALLET,
      detail: `${status} benefit tab`,
    });
  };

  const statusMessages = $tmap({
    active: 'WALLET_BENEFIT_STATUS_ACTIVE',
    pending: 'WALLET_BENEFIT_STATUS_PENDING',
    past: 'WALLET_BENEFIT_STATUS_PAST',
    created: 'WALLET_BENEFIT_STATUS_CREATED',
    ready: 'WALLET_BENEFIT_STATUS_READY',
    in_waiting_period: 'WALLET_BENEFIT_STATUS_IN_WAITING_PERIOD',
    inactive: 'WALLET_BENEFIT_STATUS_INACTIVE',
    inactive_grace_period: 'WALLET_BENEFIT_STATUS_INACTIVE_GRACE_PERIOD',
    allocated: 'WALLET_BENEFIT_STATUS_ALLOCATED',
    opted_out: 'WALLET_BENEFIT_STATUS_OPTED_OUT',
  });

  return (
    <Flex role="tablist" as="ul">
      {cardStatuses.map((status, index) => {
        const isFirstTab = index === 0;
        const isActiveTab = status === statusFilter;
        // eslint-disable-next-line no-lone-blocks -- FIXME: automatically added for existing issue
        {
          /* TODO: add arrow functionality */
        }
        return (
          <Tab
            as="li"
            role="tab"
            aria-selected={isActiveTab}
            keyboardFocus={keyboardFocus}
            tabIndex="0"
            isActiveTab={isActiveTab}
            backgroundColor={
              isActiveTab
                ? 'surface.background.primary'
                : 'surface.background.secondary'
            }
            flexGrow={1}
            height="three"
            paddingY="one"
            paddingX="threeQuarters"
            borderStyle="solid"
            borderWidth="thin"
            borderColor="onSurface.border.subdued"
            borderLeftStyle={!isFirstTab && 'none'}
            borderBottomStyle={isActiveTab && 'none'}
            borderTopRightRadius="large"
            borderTopLeftRadius="large"
            zIndex={
              cardStatuses.length - index + (isActiveTab ? zIndexBooster : 0)
            }
            onClick={() => {
              handleClick(status);
              setKeyboardFocus(false);
            }}
            onKeyUp={(e) => {
              if (e.key === KEY.ENTER) handleClick(status);
              setKeyboardFocus(true);
            }}
            key={status}
          >
            <SubtitleTwo
              width={1}
              textAlign="center"
              color="onSurface.text.subdued"
              lineHeight="16px"
            >
              {statusMessages(status)}
            </SubtitleTwo>
          </Tab>
        );
      })}
    </Flex>
  );
};

StatusFilterTabs.propTypes = {
  cardStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
};
