{
  "dragDescriptionKeyboard": "Prima Enter para iniciar o arrasto.",
  "dragDescriptionKeyboardAlt": "Prima Alt + Enter para iniciar o arrasto.",
  "dragDescriptionLongPress": "Prima longamente para começar a arrastar.",
  "dragDescriptionTouch": "Faça duplo toque para começar a arrastar.",
  "dragDescriptionVirtual": "Clique para iniciar o arrasto.",
  "dragItem": "Arrastar {itemText}",
  "dragSelectedItems": "Arrastar {count, plural, one {# item selecionado} other {# itens selecionados}}",
  "dragSelectedKeyboard": "Prima Enter para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragSelectedKeyboardAlt": "Prima Alt + Enter para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragSelectedLongPress": "Prima longamente para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragStartedKeyboard": "Arrasto iniciado. Prima a tecla de tabulação para navegar para um destino para largar, e em seguida prima Enter para largar ou prima Escape para cancelar.",
  "dragStartedTouch": "Arrasto iniciado. Navegue para um destino para largar, e em seguida faça duplo toque para largar.",
  "dragStartedVirtual": "Arrasto iniciado. Navegue para um destino para largar, e em seguida clique ou prima Enter para largar.",
  "dropCanceled": "Largar cancelado.",
  "dropComplete": "Largar completo.",
  "dropDescriptionKeyboard": "Prima Enter para largar. Prima Escape para cancelar o arrasto.",
  "dropDescriptionTouch": "Faça duplo toque para largar.",
  "dropDescriptionVirtual": "Clique para largar.",
  "dropIndicator": "Indicador de largar",
  "dropOnItem": "Largar em {itemText}",
  "dropOnRoot": "Largar em",
  "endDragKeyboard": "A arrastar. Prima Enter para cancelar o arrasto.",
  "endDragTouch": "A arrastar. Faça duplo toque para cancelar o arrasto.",
  "endDragVirtual": "A arrastar. Clique para cancelar o arrasto.",
  "insertAfter": "Inserir depois de {itemText}",
  "insertBefore": "Inserir antes de {itemText}",
  "insertBetween": "Inserir entre {beforeItemText} e {afterItemText}"
}
