import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  HeadingOne,
  HeadingTwo,
  genesisStyled,
  Flex,
  Image as GenesisImage,
  Link,
  PrimaryButton,
  SubtitleOne,
  Box,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import {
  Text,
  Image,
  colour,
  bgImageCover,
  flexPack,
  verticalBox,
} from '@leagueplatform/ui-kit';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';

import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { useIntl } from '@leagueplatform/locales';
import { EyebrowEarnPoints } from '@leagueplatform/web-common-components';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics.constants';
import { LearnMoreMobileView } from 'components/learn-more-mobile.view';
import { OnboardingModal } from './onboarding-modal.view';

const OnboardingContainer = genesisStyled(Flex)`
max-width: 790px;
`;

const StyledDescription = styled(Text)`
  color: ${colour('neutral.grayDark')};
  line-height: 24px;
`;

const PageImage = styled(Image)`
  ${verticalBox()};
  ${flexPack('end')};
  ${bgImageCover()};
  flex-shrink: 0;
  position: relative;
`;

export const OnboardingContent = ({
  data,
  completionPoints,
  onComplete,
  numberOfPages,
  healthAssessmentIsEditing,
}) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const { title, description, imageId, additionalInfo } = data;
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <OnboardingContainer
      flexDirection={{ _: 'column', tablet: 'row' }}
      alignItems={{ _: 'center', tablet: 'initial' }}
    >
      <PageImage
        cover
        url={getContentUrl(imageId)}
        width={isMobile ? '160px' : '225px'}
        height={isMobile ? '160px' : '225px'}
      />
      <Flex
        flexDirection="column"
        width="100%"
        marginLeft={{ _: '0', tablet: 'two' }}
      >
        {completionPoints > 0 && (
          <EyebrowEarnPoints completionPoints={completionPoints} />
        )}
        {isMobile ? (
          <HeadingTwo marginTop="36px">{title}</HeadingTwo>
        ) : (
          <HeadingOne>{title}</HeadingOne>
        )}
        <StyledDescription>{description}</StyledDescription>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
        <Link
          as="button"
          background="transparent"
          border="none"
          marginTop={{ _: 'oneAndHalf', tablet: 'three' }}
          textAlign="left"
          onClick={(e) => {
            e.preventDefault();
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
              screen_name: SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO,
              detail: 'view info modal',
              sub_detail: additionalInfo.title,
              has_health_profile: healthAssessmentIsEditing,
              text_detail: title,
            });
            setShowAdditionalInfo(true);
          }}
        >
          <SubtitleOne as="span" color="interactive.action.primary">
            {additionalInfo.title}
          </SubtitleOne>
        </Link>
        {numberOfPages === 1 && (
          <PrimaryButton
            data-testid="forward-button"
            marginRight={{ _: 'none', tablet: 'oneAndHalf' }}
            paddingX="four"
            marginTop="oneAndHalf"
            width={327}
            onClick={onComplete}
          >
            {formatMessage({ id: 'HP_ONBOARDING_SUBMIT' })}
          </PrimaryButton>
        )}
      </Flex>
      {isMobile && showAdditionalInfo && (
        <LearnMoreMobileView
          learnMoreTitle={additionalInfo.title}
          learnMoreContent={additionalInfo.content}
          setIsLearnMoreOpen={setShowAdditionalInfo}
        >
          <Box marginTop="quarter">
            {additionalInfo.content.map(
              ({
                title: contentTitle,
                iconId,
                description: learnMoreDescription,
              }) => (
                <Flex
                  flexDirection="column"
                  key={contentTitle}
                  marginBottom="two"
                >
                  <GenesisImage
                    aria-hidden
                    marginRight="half"
                    size="oneAndHalf"
                    role="presentation"
                    src={getContentUrl(iconId)}
                  />
                  <SubtitleOne as="h3" marginTop="quarter">
                    {contentTitle}
                  </SubtitleOne>
                  <BodyOne color="onSurface.text.subdued" marginTop="half">
                    {learnMoreDescription}
                  </BodyOne>
                </Flex>
              ),
            )}
          </Box>
        </LearnMoreMobileView>
      )}
      {!isMobile && showAdditionalInfo && (
        <OnboardingModal
          data={additionalInfo}
          showModal={setShowAdditionalInfo}
          healthAssessmentIsEditing={healthAssessmentIsEditing}
        />
      )}
    </OnboardingContainer>
  );
};

OnboardingContent.propTypes = {
  data: PropTypes.shape({
    eyebrowHeadline: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    imageId: PropTypes.string,
    additionalInfo: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          iconId: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
    }),
  }),
  completionPoints: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  healthAssessmentIsEditing: PropTypes.bool.isRequired,
};

OnboardingContent.defaultProps = {
  data: {},
  completionPoints: 0,
};
