import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import type { UserProfileAggregate } from 'types/user-profile.type';

export const GET_USER_PROFILE_MESSAGE_TYPE = 'get_user_profile';

export const getUserProfile = (
  userId?: string,
): Promise<UserProfileAggregate> =>
  SocketAsFetch.fetch({
    message_type: GET_USER_PROFILE_MESSAGE_TYPE,
    info: {
      version: 2,
      ...(userId && { user_id: userId }),
    },
  });
