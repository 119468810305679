import React, { ReactNode } from 'react';
import { styled } from '../../../theme';
import { useThemeStrings } from '../../../hooks/use-theme';

const OptionalFlag = styled('span', {
  typography: '$caption',
  color: '$onSurfaceTextSubdued',
});

const StyledSpan = styled('span');

export const LabelText = ({
  children,
  required,
}: {
  children: ReactNode;
  required?: boolean;
}) => {
  const { optional } = useThemeStrings();
  return (
    <>
      <StyledSpan {...(!required && { css: { marginInlineEnd: '$quarter' } })}>
        {children}
      </StyledSpan>
      {!required && <OptionalFlag>{`(${optional})`}</OptionalFlag>}
    </>
  );
};
