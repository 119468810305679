import * as React from 'react';
import { Annotation as VisxAnnotation, LineSubject } from '@visx/annotation';
import { useTheme } from '@leagueplatform/genesis-commons';
import { Label as StyledLabel } from './Label';

type AnnotationProps = { label: string; x: number; y: number };

export function Annotation({ label, x, y }: AnnotationProps) {
  const theme = useTheme();

  return (
    <VisxAnnotation aria-hidden="true" x={x} y={-15}>
      <StyledLabel title={label} />
      <LineSubject
        orientation="vertical"
        stroke={theme.colors.decorative.brand.primary.dark}
        strokeWidth={3}
        strokeDasharray="5,5"
        min={-10}
        max={y}
        x={x}
      />
    </VisxAnnotation>
  );
}
