import * as React from 'react';
import { Grid as VisxGrid } from '@visx/grid';
import { useTheme } from '@leagueplatform/genesis-commons';

export type GridProps = React.ComponentProps<typeof VisxGrid>;

/**
 * Wraps the visx Grid component and applies genesis styles.
 */
export function Grid(props: GridProps) {
  const theme = useTheme();

  return (
    <VisxGrid
      stroke={theme.colors.onSurface.border.subdued}
      strokeWidth={theme.borderWidths.thick}
      strokeDasharray="3 3"
      rowLineStyle={{ strokeDasharray: 'none' }}
      columnLineStyle={{ strokeDasharray: 5 }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
