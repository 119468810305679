import React from 'react';
import {
  BackgroundColorSystemProp,
  BorderColorSystemProp,
  css,
  Flex,
  Box,
  genesisStyled,
  VisuallyHidden,
  TextColorSystemProp,
} from '@leagueplatform/genesis-commons';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import uniqueId from 'lodash/uniqueId';

export interface ProgressBarProps {
  total?: number;
  current: number;
  screenReaderText?: string;
  customBarFillColor?: BackgroundColorSystemProp | BorderColorSystemProp;
  customBarBackgroundColor?:
    | BackgroundColorSystemProp
    | BorderColorSystemProp
    | TextColorSystemProp;
}

const Bar = genesisStyled(Flex)(
  css({
    height: '1rem',
    minHeight: '10px',
    width: '100%',
    borderRadius: '59px',
    position: 'relative',
    overflow: 'hidden',
  }),
);

// Using the genesisStyled function here because the backgroundColor for the ProgressIndicator could need to use a border Genesis token
const ProgressIndicator = genesisStyled(Box)(
  ({ customBackgroundColor, percentage, reducedMotion }) =>
    css({
      backgroundColor: customBackgroundColor,
      width: `${percentage}%`,
      transition: reducedMotion ? 'width 2s linear' : 'none',
      animation: reducedMotion ? 'none' : 'barWidth 1s ease-in-out forwards',
      '@keyframes barWidth': {
        from: {
          width: '0%',
        },
      },
    }),
);

export const ProgressBar = ({
  customBarFillColor = 'primary.background.default',
  customBarBackgroundColor = 'interactive.background.disabled',
  current,
  total = 100, // If no total is provided, assumption is current is a percentages so total would be 100
  screenReaderText = `${total ? Number((current / total) * 100) : current}%`,
}: ProgressBarProps) => {
  const reducedMotion = usePrefersReducedMotion();
  const id = uniqueId('progress-bar');

  // In order to avoid rendering many individual segments of the progress bar, we convert the current and total values to a percentage.
  const currentAsPercentage = total ? Number((current / total) * 100) : current;

  return (
    <div>
      <VisuallyHidden>
        <label htmlFor={id}>{screenReaderText}</label>
        <progress id={id} max={total} value={current} />
      </VisuallyHidden>
      <Bar
        data-testid="progress-bar"
        aria-hidden
        backgroundColor={customBarBackgroundColor}
      >
        <ProgressIndicator
          customBackgroundColor={customBarFillColor}
          height="100%"
          borderBottomRightRadius="pill"
          borderTopRightRadius="pill"
          percentage={currentAsPercentage}
          reducedMotion={reducedMotion}
        />
      </Bar>
    </div>
  );
};
