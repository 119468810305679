import { getContentUrl } from '@leagueplatform/league-content-api';
import { useGetUserProfile } from './use-get-user-profile.hook';

export const useGetUserProfilePhotoUrl = () => {
  let userPhotoUrl: string | undefined;

  const { data: userData } = useGetUserProfile();

  if (userData?.user_profile?.avatar?.image_id) {
    userPhotoUrl = getContentUrl(userData.user_profile.avatar.image_id);
  }
  return userPhotoUrl;
};
