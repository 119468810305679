import React, { ReactNode } from 'react';
import { Link } from '@leagueplatform/routing';
import {
  SubtitleOne,
  QuietButton,
  Box,
  Flex,
  genesisStyled,
  GenesisTheme,
} from '@leagueplatform/genesis-commons';

const DropdownButton = genesisStyled(QuietButton)(
  ({ theme: { colors } }: { theme: GenesisTheme }) => `
  &:hover, &:active, &:focus {
    background-color: ${colors.decorative.brand.primary.pastel}
  }
  `,
);

export interface SettingsDropdownItemProps {
  children: ReactNode;
  onClick?: () => void;
  to?: string;
}

export const SettingsDropdownItem = ({
  onClick,
  children,
  to,
}: SettingsDropdownItemProps) => (
  <Box
    as="li"
    borderBottomColor="interactive.border.disabled"
    borderBottomWidth="thin"
    paddingX="quarter"
    paddingY="half"
    role="none"
  >
    <DropdownButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(to && {
        as: Link,
        textDecoration: 'none',
        to,
      })}
      justifyContent="flex-start"
      onClick={onClick}
      paddingLeft="half"
      role="menuitem"
      width="100%"
    >
      <Flex>
        <SubtitleOne as="span" color="secondary.text.default">
          {children}
        </SubtitleOne>
      </Flex>
    </DropdownButton>
  </Box>
);
