import React, { forwardRef } from 'react';
import * as RadixTabsPrimitive from '@radix-ui/react-tabs';
import { styled } from '../../theme';
import { GDSCustomizableComponent } from '../../types';
import { focusOutlineInner } from '../../theme/utils/focus-outline';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSTabPanelProps
  extends RadixTabsPrimitive.TabsContentProps,
    GDSCustomizableComponent {}

const BaseTabpanel = styled(RadixTabsPrimitive.Content, {
  typography: '$bodyOne',
  color: '$onSurfaceTextPrimary',
  padding: '$threeQuarters $half',
  '&:focus, &[class*="GDS"]:focus': {
    boxShadow: 'none',
    outline: 'none',
  },
  '&[class*="GDS"]:focus-visible, &:focus-visible': {
    ...focusOutlineInner,
  },
});

export const TabPanel = forwardRef<HTMLDivElement, GDSTabPanelProps>(
  ({ children, className, css, value, ...props }, ref) => (
    <GenesisCoreInspector displayName="TabPanel">
      <BaseTabpanel
        className={[`GDS-tab-panel`, className].join(' ')}
        value={value}
        css={css}
        ref={ref}
        {...props}
      >
        {children}
      </BaseTabpanel>
    </GenesisCoreInspector>
  ),
);
