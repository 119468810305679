import { useMutation, useQueryClient } from 'react-query';

import {
  createHealthMetrics,
  healthMetricsV3Path,
} from '@leagueplatform/dashboard-api';

export const usePostHealthMetrics = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createHealthMetrics, {
    onSuccess: () => {
      queryClient.invalidateQueries([healthMetricsV3Path]);
    },
  });

  return mutation;
};
