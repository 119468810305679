import React from 'react';
import {
  BodyOne,
  Caption,
  Box,
  Flex,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Avatar } from '@leagueplatform/web-common-components';
import { Participant } from '@leagueplatform/messaging-api';

interface SidebarMembersListProps {
  participants: Participant[];
}

const StyledList = genesisStyled(Flex)`
  & > li:last-child {
    border-bottom: 0px;
  }
`;

export const SidebarMembersList = ({
  participants,
}: SidebarMembersListProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledList
      as="ol"
      flexDirection="column"
      aria-label={formatMessage({ id: 'MEMBERS' })}
    >
      {participants.map((participant) => {
        const { id, name, roleDisplayName, initials, photoUrl } = participant;
        return (
          <Flex
            as="li"
            alignItems="center"
            borderBottomColor="onSurface.border.subdued"
            borderBottomWidth="thin"
            borderBottomStyle="solid"
            paddingY="threeQuarters"
            key={id}
          >
            <Box marginRight="threeQuarters" minWidth="48px">
              <Avatar
                userInitials={initials}
                imageSrc={photoUrl}
                width="three"
                height="three"
              />
            </Box>
            <Flex flexDirection="column" justifyContent="center">
              <BodyOne>{name}</BodyOne>
              {roleDisplayName && <Caption>{roleDisplayName}</Caption>}
            </Flex>
          </Flex>
        );
      })}
    </StyledList>
  );
};
