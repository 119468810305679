import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { USER_CHALLENGE_PATH } from './constants/challenge-requests.constants';

export const deleteUserChallenge = async (
  userChallengeId: string,
  errorContext?: ErrorContext,
) => {
  const response = await leagueFetch(
    `${USER_CHALLENGE_PATH}/${userChallengeId}`,
    {
      method: 'DELETE',
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (response.ok) {
    return;
  }
  throw new Error('Something went wrong while deleting!');
};
