import React, { useState } from 'react';
import { useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-commons';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { TLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { SettingsCog } from 'components/settings-cog/settings-cog.component';
import { SettingsDropdownItem } from 'components/settings-dropdown/settings-dropdown-item.component';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useJoinOrLeaveTeam } from 'hooks/use-join-or-leave-team.hook';
import { CHALLENGE_PATCH_OPERATIONS } from '@leagueplatform/health-journey-api';
import { LeaveTeamModal } from '../leave-team-modal/leave-team-modal.component';
import { TeamInviteLinkModal } from '../../team-invite-link-modal/team-invite-link-modal.component';

export interface TLPSettingsCogWithContentProps {
  teamName: string;
  enrollmentOpen: boolean;
  userIsOnCurrentTeam: boolean;
  spotsAvailable: number;
  setDisplayJoinTeamSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayCantJoinTeamModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TLPSettingsCogWithContent = ({
  teamName,
  enrollmentOpen,
  userIsOnCurrentTeam,
  spotsAvailable,
  setDisplayJoinTeamSuccessModal,
  setDisplayCantJoinTeamModal,
}: TLPSettingsCogWithContentProps) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { teamId } = useParams<{ teamId: string }>();
  const { formatMessage } = useIntl();
  const [displayLeaveTeamModal, setDisplayLeaveTeamModal] = useState(false);
  const [displayTeamInviteLinkModal, setDisplayTeamInviteLinkModal] =
    useState(false);
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);

  const modalIsOpen = displayTeamInviteLinkModal || displayLeaveTeamModal;
  const canInviteFriends = spotsAvailable > 0 && enrollmentOpen;
  /*
    User can leave team when:
      1. Challenge is NOT Pending or Closed
      2. User is part of team
    Hard coding this value now since it is unclear at this point what the BE
    response will look like and whether team info will come from the get challenge
    endpoint or a new endpoint altogether
  */
  const userCanLeaveTeam = enrollmentOpen && userIsOnCurrentTeam;

  const { mutate: leaveChallengeTeam, isLoading } = useJoinOrLeaveTeam(
    teamName,
    CHALLENGE_PATCH_OPERATIONS.REMOVE,
    setDisplayJoinTeamSuccessModal,
    setDisplayCantJoinTeamModal,
  );
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.TEAM_DETAILS,
      detail,
      team_name: teamName,
      team_id: teamId,
    });
  };

  return (
    <Box position="absolute" top="0" right="0">
      <SettingsCog
        modalIsOpen={modalIsOpen}
        isSettingsDropdownOpen={isSettingsDropdownOpen}
        setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
      >
        {canInviteFriends && (
          <>
            <SettingsDropdownItem
              onClick={() => {
                setDisplayTeamInviteLinkModal(true);
                handleAnalyticsClick(TLP_DETAIL.SETTINGS_MENU_INVITE);
              }}
            >
              {formatMessage({ id: 'INVITE_FRIENDS' })}
            </SettingsDropdownItem>
            <TeamInviteLinkModal
              isOpen={displayTeamInviteLinkModal}
              onDismiss={() => setDisplayTeamInviteLinkModal(false)}
            />
          </>
        )}
        <SettingsDropdownItem
          to={JOURNEY_ROUTES.getChallengeSettings(challengeId)}
          onClick={() => {
            handleAnalyticsClick(TLP_DETAIL.SETTINGS_MENU_PRIVACY);
          }}
        >
          {formatMessage({ id: 'PRIVACY' })}
        </SettingsDropdownItem>
        {userCanLeaveTeam && (
          <>
            <SettingsDropdownItem
              onClick={() => {
                setDisplayLeaveTeamModal(true);
                handleAnalyticsClick(TLP_DETAIL.SETTINGS_MENU_LEAVE_TEAM);
              }}
            >
              {formatMessage({ id: 'LEAVE_TEAM' })}
            </SettingsDropdownItem>
            <LeaveTeamModal
              teamName={teamName}
              isSettingsDropdownOpen={displayLeaveTeamModal}
              setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
              onDismiss={() => {
                setDisplayLeaveTeamModal(false);
                trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  screen_name: TLP_SCREEN_NAMES.LEAVE_TEAM,
                  detail: TLP_DETAIL.EXIT,
                  team_name: teamName,
                  team_id: teamId,
                });
              }}
              leaveChallengeTeam={leaveChallengeTeam}
              isLoading={isLoading}
            />
          </>
        )}
      </SettingsCog>
    </Box>
  );
};
