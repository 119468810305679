import React from 'react';
import { Link } from '@leagueplatform/routing';

import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { CTA } from 'types/achievement.types';
import {
  HeadingText,
  StackLayout,
  UtilityText,
  Button,
} from '@leagueplatform/genesis-core';

interface ViewProgramLibraryPlaceholderProps {
  title: string;
  description: string;
  cta: CTA;
}

export const ViewProgramLibraryPlaceholder = ({
  title,
  description,
  cta,
}: ViewProgramLibraryPlaceholderProps) => {
  const sendViewProgramLibraryAnalytics = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'achievements',
      detail: 'view health campaign library',
      cta_text: `cta: ${cta?.title}`,
    });
  };
  return (
    <StackLayout
      orientation="vertical"
      verticalAlignment="center"
      horizontalAlignment="center"
      css={{
        backgroundColor: '$surfaceBackgroundSecondary',
        borderWidth: '$thin',
        borderRadius: '$large',
        borderColor: '$interactiveBorderDisabled',
        padding: '$oneAndHalf',
      }}
    >
      <HeadingText level="2" size="md" css={{ marginBottom: '$half' }}>
        {title}
      </HeadingText>
      <UtilityText
        css={{
          typography: '$bodyOneSecondary',
          textAlign: 'center',
          marginBottom: '$one',
          color: '$onSurfaceTextSubdued',
        }}
      >
        {description}
      </UtilityText>
      <Button as={Link} to={cta?.url} onClick={sendViewProgramLibraryAnalytics}>
        {cta?.title}
      </Button>
    </StackLayout>
  );
};
