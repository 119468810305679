/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

export const fieldProps = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasError: PropTypes.bool,
};
