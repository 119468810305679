/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Select } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { fieldProps } from './field-shape';

export const SelectField = ({
  field: { name, ...field },
  form: { handleBlur, setFieldValue },
  inputId,
  disabled,
  readOnly,
  placeholder,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      {...props}
      {...field}
      name={name}
      id={inputId}
      placeholder={placeholder ?? formatMessage({ id: 'SELECT_ONE' })}
      disabled={Boolean(disabled ?? readOnly)}
      onBlur={({ value }) => {
        handleBlur({ target: { value, name } });
      }}
      onChange={(evt) => {
        const value = evt ? evt.currentTarget.value : null;
        setFieldValue(name, value);
      }}
      data-testid={`select_field_${name}`}
    />
  );
};

SelectField.propTypes = fieldProps;
