import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import {
  getHighlightFeedByIdPath,
  CHALLENGE_HIGHLIGHT_FEED_TYPES,
} from './constants/challenge-requests.constants';
import {
  MultipleHighlightsDeserialisedData,
  MultipleHighlightsResponseData,
} from './types/highlights-data.type';

export const getHighlightsFeedById = async (
  feedType: CHALLENGE_HIGHLIGHT_FEED_TYPES,
  id: string,
  challengeId: string,
  requestPath?: string | null,
  errorContext?: ErrorContext,
): Promise<MultipleHighlightsDeserialisedData> => {
  const fetchHighlightsByIdPath =
    requestPath ||
    `${getHighlightFeedByIdPath(feedType, challengeId)}${id}&page[size]=10`;

  const response = await leagueFetch(fetchHighlightsByIdPath, {
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (!response.ok) {
    throw new Error(`getHighlightsFeedById error: ${response.status}`);
  }
  const responseJson: MultipleHighlightsResponseData = await response.json();
  return deserialise(responseJson);
};
