import { canRedirectUsingURL } from '../utils';

export const getUrlToRedirectOnExit = (redirectUrl) => {
  const { referrer } = document;
  if (redirectUrl) {
    return redirectUrl;
  }
  if (canRedirectUsingURL(referrer)) {
    return referrer;
  }
  return `${window.location.origin}`;
};
