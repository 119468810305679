import React from 'react';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { truncateWithEllipses } from '@leagueplatform/web-common';
import {
  BodyOne,
  HeadingThree,
  Flex,
  Box,
  Link,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { BENEFIT } from '../../constants/health-solution-types.constants';

interface HealthSolutionProps {
  imageSrc?: string;
  title: string;
  description: string;
  onCardClick: () => void;
  type: string;
}

const HealthSolutionContainer = genesisStyled(Flex)(
  css({
    cursor: 'pointer',
  }),
);

export const HealthSolution = ({
  imageSrc,
  title,
  description,
  onCardClick,
  type,
}: HealthSolutionProps) => {
  const { formatMessage } = useIntl();

  return (
    <HealthSolutionContainer
      data-testid="health-solution"
      tabIndex={0}
      width="252px"
      marginRight="two"
      marginBottom="twoAndHalf"
      flexDirection="column"
      onClick={onCardClick}
    >
      {imageSrc && (
        <Box
          data-testid="health-solution-img"
          minWidth="252px"
          height="142px"
          borderRadius="medium"
          marginBottom="one"
          backgroundImage={`url(${imageSrc})`}
          backgroundColor={
            type === BENEFIT ? 'surface.background.secondary' : undefined
          }
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        />
      )}
      <HeadingThree marginBottom="half">{title}</HeadingThree>

      <BodyOne color="onSurface.text.subdued" marginBottom="one">
        {truncateWithEllipses(description, 89)}
      </BodyOne>

      <Box>
        {/* TODO: Fix a11y issue when page is redone */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link marginRight="one">
          {formatMessage({ id: 'VIEW_MORE_DETAILS_SENTENCE_CASE' })}
        </Link>
        <Icon
          size={16}
          icon={ICONS.RIGHT_CHEVRON}
          colour="interactive.action.primary"
        />
      </Box>
    </HealthSolutionContainer>
  );
};
