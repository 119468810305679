import React from 'react';
import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';

import { Transition } from 'react-transition-group';
import { TransitionStates } from 'types/animation-transition-states.type';
import { TransitionProps } from 'types/transition-props.type';

const Element = genesisStyled(Box)(({ stylesProp }) =>
  css({
    ...stylesProp,
  }),
);

export const SlideInHorizontal = ({
  isActive,
  stylesProp,
  duration,
  distance = '-100%',
  children = null,
}: TransitionProps) => {
  const transitionStyles = {
    entering: { transform: `translateX(0)` },
    entered: { transform: `translateX(0)` },
    exiting: { transform: `translateX(0)` },
    exited: { transform: `translateX(${distance})` },
  };
  return (
    <Transition in={isActive} timeout={duration}>
      {(state: TransitionStates) => (
        <Element
          stylesProp={{
            ...stylesProp,
            transition: `transform ${duration}ms ease-in-out`,
          }}
          style={{ ...transitionStyles[state] }}
        >
          {children && children}
        </Element>
      )}
    </Transition>
  );
};
