import * as React from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';

export const useGetUserDefaultAddress = () => {
  const { data: user } = useGetUserProfile();
  const { address1, address2, city, postal, state } =
    React.useMemo(
      () =>
        user?.userProfile?.locations?.find((location) => location.isDefault),
      [user?.userProfile?.locations],
    ) || {};

  return { address1, address2, city, postal, state };
};
