import React from 'react';
import linkifyHtml from 'linkify-html';
import { HtmlToReact } from '@leagueplatform/web-common';
import { styled } from '@leagueplatform/genesis-core';

const StyledLink = styled('a', {
  color: 'inherit',
  textDecoration: 'underline',
});

const StyledLinkFromCurrentUser = styled('a', {
  color: 'inherit',
  textDecoration: 'underline',
  '&[class*="GDS"]:focus': {
    outlineColor: 'inherit',
  },
});

export interface MessageTextWithInlineLinksProps {
  fromCurrentUser?: boolean;
  text: string;
}

export const MessageTextWithInlineLinks = ({
  fromCurrentUser,
  text,
}: MessageTextWithInlineLinksProps) => {
  const linkifyOptions = {
    defaultProtocol: 'https',
    attributes: {
      rel: 'noopener noreferrer',
    },
  };

  const RichTextOptions = {
    a: {
      component: fromCurrentUser ? StyledLinkFromCurrentUser : StyledLink,
      props: {
        target: '_blank',
      },
    },
  };

  return (
    <HtmlToReact
      htmlString={linkifyHtml(text, linkifyOptions)}
      options={RichTextOptions}
    />
  );
};
