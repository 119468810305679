import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE =
  'get_user_health_activities';

type GetUserHealthActivitiesParams = {
  date?: number;
  activityStatuses: Array<string>;
  campaignId?: string;
  version: number;
  errorContext?: ErrorContext;
};

export const getUserHealthActivities = ({
  date,
  activityStatuses,
  campaignId,
  // defaulting to version 2 to maintain backwards capability
  // new websocket version is version 3
  version = 2,
  errorContext,
}: GetUserHealthActivitiesParams) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_USER_HEALTH_ACTIVITIES_MESSAGE_TYPE,
      info: {
        version,
        date,
        activity_statuses: activityStatuses,
        user_health_campaign_id: campaignId,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
