import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '../primitives.view';
import { getColourObject } from '../../utils/helpers';

const Footer = styled(Flex)`
  background-color: ${({ theme }) => getColourObject(theme).offWhite};
`;

export const ModalFooter = ({ children, className }) => (
  <Footer p={2} justifyContent="flex-end" className={className}>
    {children}
  </Footer>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalFooter.defaultProps = {
  className: null,
};
