import * as React from 'react';
import { BarRounded } from '@visx/shape';
import {
  useTheme,
  useThemeRawValues,
  UtilityText,
  HeadingText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import type { VerticalBarGraphItemV2 } from '@leagueplatform/dashboard-api';

type LegendProps = {
  items: VerticalBarGraphItemV2[];
};

export function LegendV2({ items }: LegendProps) {
  const rawTheme = useThemeRawValues();
  const theme = useTheme();

  return (
    <StackLayout orientation="horizontal" as="ul" spacing="$three" aria-hidden>
      {items.map((datum) => (
        <li key={datum.indexLabel}>
          <StackLayout
            orientation="horizontal"
            verticalAlignment="center"
            spacing="$one"
          >
            <svg width={12} height={rawTheme.sizes.three}>
              <BarRounded
                x={0}
                y={0}
                height={rawTheme.sizes.three}
                width={rawTheme.sizes.threeQuarters}
                radius={rawTheme.radii.extraLarge}
                top // radius top
                bottom // radius bottom
                strokeWidth={theme.borderWidths.thick}
                fill={datum.barColor}
              />
            </svg>
            <StackLayout verticalAlignment="center" spacing="$none">
              <UtilityText emphasis="subtle" size="sm">
                {datum.indexLabel}
              </UtilityText>
              <HeadingText level="3" size="lg">
                {datum.indexValue}
              </HeadingText>
            </StackLayout>
          </StackLayout>
        </li>
      ))}
    </StackLayout>
  );
}
