import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE =
  'complete_user_health_activity';

export const completeUserHealthActivity = (userActivityId: string) =>
  SocketAsFetch.fetch({
    message_type: COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
    info: {
      user_health_activity_id: userActivityId,
    },
  });
