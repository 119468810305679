import React, { ReactNode } from 'react';
import { Box } from '@leagueplatform/genesis-commons';

export interface TLPCardProps {
  children: ReactNode;
}

export const TLPCard = ({ children }: TLPCardProps) => (
  <Box
    backgroundColor="surface.background.primary"
    borderColor="onSurface.border.subdued"
    borderRadius="medium"
    borderWidth="thin"
    padding="oneAndHalf"
  >
    {children}
  </Box>
);
