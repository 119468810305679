import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ActionButton } from './image-viewer-buttons.view';

const Toolbar = styled.div`
  width: 2rem;
  z-index: 1005;
  overflow: hidden;
  text-align: center;
  position: fixed;
  top: 5rem;
  right: 0.5rem;
`;

export const ImageViewerToolbar = ({
  zoomIn,
  zoomOut,
  reset,
  rotateRight,
  rotateLeft,
  next,
  prev,
}) => (
  <Toolbar>
    <ActionButton onClick={zoomIn}>
      <FontAwesomeIcon icon="plus" color="white" />
    </ActionButton>
    <ActionButton onClick={zoomOut}>
      <FontAwesomeIcon icon="minus" color="white" />
    </ActionButton>
    <ActionButton onClick={prev}>
      <FontAwesomeIcon icon="arrow-left" color="white" />
    </ActionButton>
    <ActionButton onClick={reset}>
      <FontAwesomeIcon icon="history" color="white" />
    </ActionButton>
    <ActionButton onClick={next}>
      <FontAwesomeIcon icon="arrow-right" color="white" />
    </ActionButton>
    <ActionButton onClick={rotateLeft}>
      <FontAwesomeIcon icon="undo-alt" color="white" />
    </ActionButton>
    <ActionButton onClick={rotateRight}>
      <FontAwesomeIcon icon="redo-alt" color="white" />
    </ActionButton>
  </Toolbar>
);

ImageViewerToolbar.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  rotateRight: PropTypes.func.isRequired,
  rotateLeft: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
