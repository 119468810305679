import React, { createContext, useState, useContext } from 'react';
import { PostStartAsyncMessagingData } from '@leagueplatform/messaging-api';

export const FhirResponseConfigContext = createContext<
  [
    undefined | PostStartAsyncMessagingData,
    (config: PostStartAsyncMessagingData | undefined) => void,
  ]
>([undefined, () => {}]);

export function FhirResponseConfigContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const config = useState<undefined | PostStartAsyncMessagingData>(undefined);
  return (
    <FhirResponseConfigContext.Provider value={config}>
      {children}
    </FhirResponseConfigContext.Provider>
  );
}

export function useFhirResponseConfig(): [
  PostStartAsyncMessagingData | undefined,
  (config: PostStartAsyncMessagingData | undefined) => void,
] {
  const [value, setValue] = useContext(FhirResponseConfigContext);
  const setConfig = React.useCallback(
    (config: PostStartAsyncMessagingData | undefined) => {
      setValue(config);
    },
    [setValue],
  );

  return [value, setConfig];
}
