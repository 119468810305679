import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

export interface ChallengeIconProps {
  iconType: keyof typeof ICON_TYPES;
}

export enum ICON_TYPES {
  ALERT = 'ALERT',
  ARROW = 'ARROW',
  CALENDAR = 'CALENDAR',
  CHEVRON = 'CHEVRON',
  CIRCULAR_ARROW = 'CIRCULAR_ARROW',
  CLOCK = 'CLOCK',
  CLOSE = 'CLOSE',
  COG = 'COG',
  HYPERLINK = 'HYPERLINK',
  LINKOUT = 'LINKOUT',
  PRIZE = 'PRIZE',
  QUESTION = 'QUESTION',
}

export const ChallengeIcon = ({ iconType }: ChallengeIconProps) => {
  switch (iconType) {
    case ICON_TYPES.ALERT:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_ALERT, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.ARROW:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_ARROW, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.CALENDAR:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_CALENDAR, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.CHEVRON:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_CHEVRON, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.CIRCULAR_ARROW:
      return handleStaticAsset(
        CHALLENGES_ASSET_KEYS.CHALLENGES_CIRCULAR_ARROW,
        {
          isComponent: true,
        },
      ) as JSX.Element;
    case ICON_TYPES.CLOCK:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_CLOCK, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.CLOSE:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_CLOSE, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.COG:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_COG, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.HYPERLINK:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_HYPERLINK, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.LINKOUT:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_LINKOUT, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.PRIZE:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_PRIZE, {
        isComponent: true,
      }) as JSX.Element;
    case ICON_TYPES.QUESTION:
      return handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_QUESTION, {
        isComponent: true,
      }) as JSX.Element;
    default:
      return null;
  }
};
