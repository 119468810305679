import React from 'react';
import {
  HeadingThree,
  Image,
  Flex,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { styled } from '@leagueplatform/genesis-core';
import { isAbsoluteUrl, normalizeUrl } from '@leagueplatform/web-common';
import {
  OpensInANewTabMessage,
  InternalOrExternalLink,
} from '@leagueplatform/web-common-components';
import { useConfigProperty } from '@leagueplatform/core';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CARE_COMMON_ASSET_KEYS } from '@leagueplatform/care-common';

const Link = styled(InternalOrExternalLink, {
  '&': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '$oneAndHalf',
    backgroundColor: '$interactiveBackgroundDefault',
    borderWidth: '$thin',
    borderStyle: 'solid',
    borderRadius: '$large',
    borderColor: '$onSurfaceBorderSubdued',
  },
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: '$interactiveBackgroundHovered',
  },
  '&:focus:before': {
    borderRadius: '$large',
  },
});

interface QuickActionMenuItemProps {
  title: string;
  icon?: string;
  url: string;
  description?: string;
  analyticsEvent: () => void;
}

export const QuickActionMenuItem = ({
  title,
  icon,
  url,
  description,
  analyticsEvent,
}: QuickActionMenuItemProps) => {
  const isExternalUrl = isAbsoluteUrl(url);
  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  return (
    <Link
      href={normalizeUrl(url)}
      isExternalUrl={isExternalUrl}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        analyticsEvent();
        if (isExternalUrl) {
          e.preventDefault();
          handleLinkCallback(url);
        }
      }}
    >
      {icon && <Image src={icon} alt="" maxWidth="56px" marginRight="one" />}
      <Flex width="100%" marginRight="one" flexDirection="column">
        <HeadingThree width="100%" color="onSurface.text.primary">
          {title}
        </HeadingThree>
        {description && (
          <BodyTwoSecondary width="100%" marginTop="quarter">
            {description}
          </BodyTwoSecondary>
        )}
      </Flex>
      {isExternalUrl && <OpensInANewTabMessage />}
      <Image
        src={handleStaticAsset(CARE_COMMON_ASSET_KEYS.CHEVRON_RIGHT) as string}
        alt=""
        maxWidth="10px"
      />
    </Link>
  );
};
