import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  DeserialisedMessagingContacts,
  MessagingContactsResponse,
} from 'types/messaging-contacts.types';
import { GET_MESSAGING_CONTACTS_PATH } from './constants/messaging-requests.constants';

export const getMessagingContacts =
  async (): Promise<DeserialisedMessagingContacts> => {
    const response = await leagueFetch(`${GET_MESSAGING_CONTACTS_PATH}`);
    if (!response.ok) {
      throw new Error(`getMessagingContacts error: ${response.status}`);
    }
    const responseJson: MessagingContactsResponse = await response.json();
    return deserialise(responseJson);
  };
