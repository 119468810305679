import * as React from 'react';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  PillTabs,
  PillTabList,
  PillTab,
} from '@leagueplatform/web-common-components';
import { CHART_VIEWS } from 'components/charts/constants/chart-views';

const ViewSwitcherContainer = genesisStyled(Flex)(
  css({
    marginTop: '-30px',
  }),
);

type WeekMonthViewSwitchProps = {
  onChange: (view: string) => void;
};

export function WeekMonthViewSwitch({ onChange }: WeekMonthViewSwitchProps) {
  const { formatMessage } = useIntl();

  return (
    <ViewSwitcherContainer>
      <PillTabs onValueChange={onChange} defaultValue={CHART_VIEWS.WEEKLY}>
        <Box css={{ width: 'fit-content' }}>
          <PillTabList>
            <PillTab value={CHART_VIEWS.WEEKLY}>
              {formatMessage({
                id: 'LAST_7_DAYS',
              })}
            </PillTab>
            <PillTab value={CHART_VIEWS.MONTHLY}>
              {formatMessage({
                id: 'LAST_30_DAYS',
              })}
            </PillTab>
          </PillTabList>
        </Box>
      </PillTabs>
    </ViewSwitcherContainer>
  );
}
