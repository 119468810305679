import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { genesisStyled } from '@leagueplatform/genesis-commons';
import { Flex } from '../primitives.view';
import { withTheme, css } from 'styled-components';
import { getColourObject } from '../../utils/helpers';

const getPointerPositionProperty = ({ left, right, position }) => {
  if (left) return ` left: ${position}px;`;
  if (right) return `right: ${position}px`;
  return null;
};

const TooltipContentBox = styled(Flex)`
  position: absolute;
  z-index: 99;
  ${({ verticalPosition }) => verticalPosition};
  ${({ horizontalPosition }) => horizontalPosition};
  ${({ center }) => center && 'left: 50%; transform: translateX(-50%)'};
  ${({ right }) => right && 'right: 0'};
  ${({ left }) => left && 'left: 0'};
  ${({ borderRadius }) =>
    borderRadius && `border-radius: ${borderRadius}`} color: ${({ colour }) =>
    colour};
  background-color: ${({ backgroundColour }) => backgroundColour};
  border: 1px solid ${({ borderColour }) => borderColour};
  filter: drop-shadow(
    0 2px 8px ${({ theme }) => getColourObject(theme).lightMediumGrey}
  );
  pointer-events: auto;
  &[aria-hidden='true'] {
    pointer-events: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    ${({ center }) => center && 'left: 50%; transform: translateX(-50%);'};
    width: 0;
    height: 0;
  }

  &:before {
    ${({ center, left, right, pointerPosition }) =>
      !center &&
      getPointerPositionProperty({ left, right, position: pointerPosition })};
    border-top: 8px solid ${({ borderColour }) => borderColour};
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    ${({ top }) =>
      top &&
      `
        top: auto;
        bottom: 100%;
        transform: rotate(180deg);
      `};
  }

  &:after {
    ${({ right, left, pointerPosition }) =>
      (right || left) &&
      getPointerPositionProperty({
        left,
        right,
        position: pointerPosition + 1,
      })};
    border-top: 7px solid ${({ backgroundColour }) => backgroundColour};
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    ${({ top }) =>
      top &&
      `
        top: auto;
        bottom: 100%;
        transform: rotate(180deg);
      `};
  }
`;

const TooltipContentWrapper = genesisStyled('div')`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transform: translateY(32px);
  opacity: 0;
  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translateY(0);
          opacity: 1;
        `
      : ''}
`;

export const TooltipContent = withTheme(
  ({
    isHovered,
    children,
    colour,
    backgroundColour,
    borderColour,
    borderRadius,
    pointerPosition,
    verticalPosition,
    horizontalPosition,
    center,
    left,
    right,
    top,
    theme,
    ...props
  }) => {
    const colourTheme = getColourObject(theme);
    return (
      <TooltipContentWrapper isVisible={isHovered}>
        <TooltipContentBox
          aria-hidden={!isHovered}
          colour={colour || colourTheme.offBlack}
          backgroundColour={backgroundColour || colourTheme.white}
          borderColour={borderColour || colourTheme.greyLighter}
          pointerPosition={pointerPosition}
          horizontalPosition={horizontalPosition}
          verticalPosition={verticalPosition}
          borderRadius={borderRadius}
          center={center}
          left={left}
          right={right}
          top={top}
          {...props}
        >
          {children}
        </TooltipContentBox>
      </TooltipContentWrapper>
    );
  },
);

TooltipContent.propTypes = {
  children: PropTypes.node.isRequired,
  isHovered: PropTypes.bool,
  colour: PropTypes.string,
  backgroundColour: PropTypes.string,
  borderColour: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pointerPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  verticalPosition: PropTypes.string,
  horizontalPosition: PropTypes.string,
  center: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
};

TooltipContent.defaultProps = {
  isHovered: false,
  borderRadius: '0',
  pointerPosition: 'left: 50%',
  horizontalPosition: null,
  verticalPosition: 'bottom: 100%',
  center: false,
  left: false,
  right: false,
  top: false,
};
