/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import type { BarGraph as BarGraphType } from './types/bar-graph';
import { WeeklyBarGraph } from './weekly-bar-graph';
import { MonthlyBarGraph } from './monthly-bar-graph';

export type BarGraphProps<Datum> = BarGraphType<Datum> & {
  chartView: CHART_VIEW;
};

export function BarGraph<Datum>({ chartView, ...props }: BarGraphProps<Datum>) {
  if (chartView === CHART_VIEWS.WEEKLY) {
    return <WeeklyBarGraph {...props} />;
  }
  if (chartView === CHART_VIEWS.MONTHLY) {
    return <MonthlyBarGraph {...props} />;
  }
  return null;
}
