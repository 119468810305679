import * as React from 'react';
import {
  InsightHighlightCard,
  InsightCardHorizontalBarGraph,
} from '@leagueplatform/dashboard';
import { MetricType, MetricUnit } from '@leagueplatform/dashboard-api';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { ABOUT_ME_MASONRY_API_VERSION } from 'constants/masonry';
import { captureError } from '@leagueplatform/observability';
import { INSIGHT_TYPE } from 'constants/analytics';
import { trackWidgetClickEvent } from './utils';

type HorizontalBarGraphInsightResponse = {
  body: {
    description: string;
    items: { timeframelabel: string; value: number }[];
    metric: MetricType;
    title: string;
    unit: MetricUnit;
  };
  callout: {
    description: string;
    image: string;
    image_alt_text: string;
  };
  cta: string;
  heading: string;
};

type HorizontalBarGraphInsightWidgetProps = {
  widget: ContainerWidgetItem;
};

export function HorizontalBarGraphInsightWidget({
  widget,
}: HorizontalBarGraphInsightWidgetProps) {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<HorizontalBarGraphInsightResponse>(
    widget,
    ABOUT_ME_MASONRY_API_VERSION,
  );

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error) {
    captureError(
      new Error(
        `Unable to fetch widget data for the horizontal bar graph insight widget: ${error}`,
      ),
    );
  }

  if (error || !widgetData || !widgetData?.attributes) {
    return null;
  }

  const { body, callout, cta, heading } = widgetData.attributes;

  return (
    <InsightHighlightCard
      heading={heading}
      seeMoreLink={cta}
      callout={callout}
      onClick={() => {
        trackWidgetClickEvent(
          widgetData.attributes.body.metric,
          INSIGHT_TYPE.HORIZONTAL_BAR_GRAPH,
        );
      }}
    >
      <InsightCardHorizontalBarGraph
        title={body.title}
        description={body.description}
        type={body.metric}
        unit={body.unit}
        items={body.items}
      />
    </InsightHighlightCard>
  );
}
