import {
  LeagueSocketAsPromised,
  WebsocketApiURL,
} from '@leagueplatform/league-socket';
import * as uuid from 'uuid';
import {
  captureError,
  finishSpan,
  startSpanOnActiveTransaction,
  combineErrorContexts,
  captureMessage,
} from '@leagueplatform/observability';
import type { ErrorContext } from '@leagueplatform/observability';
import { isLoginRequiredError } from '@leagueplatform/auth';
import { getThrownErrorMessage } from './util/get-thrown-error-message';
import { getUserConfigureConnection } from './util/get-user-configure-connection';

export interface WebsocketMessage {
  message_type: string;
  message_id?: string;
  info?: Record<string, any>;
}
export interface FetchOptions {
  errorContext?: ErrorContext;
  /**
   * If an internal network or auth error occurs during fetching (as opposed to
   * an error RESPONSE to an otherwise successful fetch), this function will be called
   * with the error, and may return an Error to be further processed by the module
   * with its default behavior (which may include adding more information to the error
   * and sending it to Observability) before finally throwing it up to the caller.
   *
   * To prevent the module from perfoming the default actions mentioned above, simply
   * throw from within this function.
   */
  onInternalError?: (error: unknown) => Error | void;
}
/**
 * Match a League WebSocket response message with a few patterns that are known
 * to represent errors.
 */
function isErrorMessage(message: WebsocketMessage) {
  if (!message) return true;
  if (message.message_type === 'fail') return true;
  if (message.message_type === 'server_error') return true;
  if (message?.info?.code === 'invalid_request') return true;
  return false;
}

const NORMAL_CLOSURE_CODE = 1000;

// Number of times we are allowed to attempt to reopen a closed connection
const MAX_RECONNECTION_RETRIES = 3;

/**
 * initial delay before attempting reconnection. Will increase exponentially
 * with each attempt.
 */
const BASE_DELAY_MILIS = 350;

/**
 * Number of miliseconds of a successfully open connection after which the
 * counter above is reset to 0
 */
const RECONNECTION_COUNTER_RESET_MILIS = 30000;

const RECONNECT_REQUESTED_MESSAGE_TYPE = 'reconnect_requested';

type FetchAsPromiseOptions = {
  anonymous?: boolean;
};

type CloseSentryMessageContext = {
  code: number;
  reason: string | undefined;
  wasClean: boolean;
  reconnectionAttemptNum?: number;
};

export class FetchAsPromise extends LeagueSocketAsPromised {
  #options: FetchAsPromiseOptions;

  /**
   * This promise will be created on the first call to `fetch` by setting it to
   * the promise returned by `init`. That call to `fetch`, as well as every subsequent
   * call to it, will then await this promise before proceeding to send messages.
   */

  #initPromise: Promise<void> | undefined;

  #numReconnectionAttempts = 0;

  /**
   * When the connection opens, we count back from RECONNECTION_COUNTER_RESET_MILIS
   * and then reset the reconnection retries counter. But when the connection closes
   * unexpectedly, we want to cancel this reset. So, we store the timeout
   * here.
   */
  #onOpenTimeout: number | undefined;

  constructor(
    url: WebsocketApiURL,
    options: FetchAsPromiseOptions = { anonymous: false },
  ) {
    super(url);

    this.#options = options;

    this.onOpen.addListener(() => {
      if (this.#onOpenTimeout !== undefined) {
        window.clearTimeout(this.#onOpenTimeout);
      }
      this.#onOpenTimeout = window.setTimeout(() => {
        /**
         * It's been RECONNECTION_COUNTER_RESET_MILIS since the connection
         * opened and remained open. Let's reset the "max retry" counter.
         */
        this.#numReconnectionAttempts = 0;
      }, RECONNECTION_COUNTER_RESET_MILIS);
    });

    this.onUnpackedMessage.addListener(async (event) => {
      if (event.message_type === RECONNECT_REQUESTED_MESSAGE_TYPE) {
        await this.close();
        this.#initPromise = this.#init();
      }
    });

    this.onClose.addListener((event) => {
      const { code, reason, wasClean } = event;
      const context = {
        'WS Close Event': {
          code,
          reason,
          wasClean,
        } as CloseSentryMessageContext,
      };

      if (code !== NORMAL_CLOSURE_CODE) {
        /**
         * The connection closed unexpectedly. Clear the "counter reset" timeout.
         */
        window.clearTimeout(this.#onOpenTimeout);

        if (this.#numReconnectionAttempts <= MAX_RECONNECTION_RETRIES) {
          context['WS Close Event'].reconnectionAttemptNum =
            this.#numReconnectionAttempts + 1;
          captureMessage(
            'WebSocket connection closed unexpectedly. Attempting to reconnect',
            {
              severityLevel: 'warning',
              context,
            },
          );

          /**
           * Time to wait before attempting to reconnect. Will increase exponentially
           * with each reconnection attempt.
           */
          const delay = BASE_DELAY_MILIS * 2 ** this.#numReconnectionAttempts;

          /**
           * By assinging a new `init` promise to `this.#initPromise`, we ensure that
           * any calls to `this.fetch()` that come in while we reconnect await our
           * reconnection attempt before proceeding. This is the same logic as when
           * this class is initialized in the first place.
           */
          this.#initPromise = this.#init(delay);

          this.#initPromise.catch((e) => {
            if (!isLoginRequiredError(e)) {
              captureError(
                new Error(
                  `LeagueReconnectingSocket could not authenticate. ${e}`,
                ),
              );
            }
          });

          this.#numReconnectionAttempts += 1;
        } else {
          captureError(
            new Error(
              'WebSocket connection closed unexpectedly, but we are out of retry attempts.',
            ),
            {
              context,
            },
          );
        }
      } else {
        captureMessage('WebSocket connection closed with a normal code.', {
          severityLevel: 'info',
          context,
        });
      }
    });

    this.onError.addListener(() => {
      /**
       * The `error` event is a generic `Event` that contains no useful information,
       * so we will not bother including it in the Sentry error context.
       */
      captureError(new Error('WebSocket connection error'));
    });

    if (
      typeof window !== undefined &&
      typeof window.addEventListener === 'function'
    ) {
      window.addEventListener('unload', this.unload);
    }
  }

  async #init(delay = 0) {
    /**
     * `delay` will allow us to wait before re-init attemps, while ensuring callers to `fetch`
     * await this delay
     */
    await new Promise((res) => {
      setTimeout(res, delay);
    });
    const span = startSpanOnActiveTransaction({
      /**
       * we set the op to the same value that Sentry's
       * built-in instrumentation sets it when it creates
       * a `fetch` span. This will allow us to query for aggregate
       * data about API-related spans that incldues both WS calls and
       * REST calls.
       */
      op: 'http.client',
      description: 'Initializing WebSocket connection',
      tags: {
        isWS: true,
        isAnonymous: !!this.#options.anonymous,
      },
    });

    try {
      await this.open();

      if (!this.#options.anonymous) {
        await this.authenticate();
      }

      await this.configureConnection();
    } catch (error) {
      if (span) finishSpan(span);
      throw error;
    }
    if (span) finishSpan(span);
  }

  /**
   * Defined as a public class field arrow function instead of a public class method
   * so that we get the automatic `this`-binding, since this function is passed
   * directly to `window.addEventListener`
   */
  unload = async () => {
    await this.close(NORMAL_CLOSURE_CODE);

    if (
      typeof window !== undefined &&
      typeof window.addEventListener === 'function'
    ) {
      window.removeEventListener('unload', this.unload);
    }

    this.onClose.removeAllListeners();
    this.onError.removeAllListeners();
    this.onUnpackedMessage.removeAllListeners();
    this.onOpen.removeAllListeners();
  };

  async configureConnection() {
    const configMessage = getUserConfigureConnection();

    await this.sendRequest(configMessage, {
      requestId: uuid.v4(),
    });
  }

  /**
   * Extend superclass with a fetch-like interface that applies a unique message
   * ID and throws errors given in the response. It also handles waiting for
   * the socket to open so that consumers don't have to remember to do that.
   */
  async fetch(
    message: WebsocketMessage,
    fetchOptions: FetchOptions = {},
    observabilityTags = {},
  ) {
    if (!this.#initPromise) {
      /**
       * if we haven't done so before, assign our `initPormise` field to the promise
       * returned by the `init` method.
       *
       * This call, and future calls to `fetch` will then `await` this promise
       * to ensure they only proceed with sending messages after the `init` promise
       * is resolved.
       */

      this.#initPromise = this.#init();
    }
    const span = startSpanOnActiveTransaction({
      op: 'http.client',
      description: message.message_type,
      tags: {
        isWS: true,
        ...observabilityTags,
      },
    });

    const { errorContext: optionalErrorContext = {}, onInternalError } =
      fetchOptions;

    let response;

    try {
      await this.#initPromise;

      if (!this.#options.anonymous) {
        await this.authenticateIfNeeded();
      }

      response = await this.sendRequest(message, {
        requestId: uuid.v4(),
      });
    } catch (error) {
      if (span) finishSpan(span);

      const processedError = onInternalError?.(error) ?? error;

      const errorMessage = getThrownErrorMessage(processedError);
      const fetchError = new Error(
        `{ "info": { "reason": "${errorMessage}" } }`,
      );
      const fetchErrorContext = combineErrorContexts([
        { errorName: 'Socket Fetch Error' },
        optionalErrorContext,
      ]);

      captureError(fetchError, fetchErrorContext);
      throw fetchError;
    }

    if (span) finishSpan(span);

    if (isErrorMessage(response)) {
      const messageType = response.info?.message?.message_type;
      const fetchErrorContext = combineErrorContexts([
        {
          errorName: `Socket Fetch Response Error: ${messageType}`,
          tags: {
            messageType,
            messageId: response.info?.message?.message_id,
          },
        },
        optionalErrorContext,
      ]);

      captureError(Error(response.info?.reason), fetchErrorContext);

      throw Error(JSON.stringify(response));
    }

    return response?.info;
  }
}
