import React from 'react';
import { Route } from '@leagueplatform/routing';

// Pages
import { OnboardingLandingPage } from 'pages/onboarding-landing-page';

export const OnboardingRoutes = () => (
  <Route>
    <OnboardingLandingPage />
  </Route>
);
