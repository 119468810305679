import React from 'react';
import { Box, css, Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import {
  LoadingIndicator,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { useMedicalCard } from 'hooks/use-medical-card.hook';
import { WalletCard } from 'components/wallet-card/wallet-card.component';
import { useMemberCard } from './hooks/use-member-card.hook';
import { CardDetailsTable } from './components/card-details-table.component';
import { MemberDropDown } from '../../../member-dropdown/member-dropdown.component';

const SidebarLayout = genesisStyled(Flex)(
  css({
    gap: 'two',
    flexWrap: 'wrap',

    '& > :first-child': {
      flexBasis: 0,
      flexGrow: 999,
      minInlineSize: '50%',
    },

    '& > :last-child': {
      flexGrow: 1,
    },
  }),
);

export const CardDetails = () => {
  const { cardDetails, showMemberSelect, isLoading } = useMemberCard();

  const { isMedicalCardEnabled } = useMedicalCard();
  const showBenefitCard =
    !isLoading && isMedicalCardEnabled && cardDetails.length !== 0;

  return (
    <SidebarLayout>
      <StackLayout space="$oneAndHalf">
        {showMemberSelect && <MemberDropDown />}

        {isLoading && <LoadingIndicator inline />}

        {!isLoading && (
          <CardDetailsTable
            cardDetails={cardDetails}
            hasDependents={showMemberSelect}
          />
        )}
      </StackLayout>
      {showBenefitCard && (
        <Box maxWidth={320} marginX="auto">
          <WalletCard />
        </Box>
      )}
    </SidebarLayout>
  );
};
