import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColourObject } from '../../utils/helpers';

const Carousel = styled.div`
  z-index: 1005;
  position: fixed;
  top: 0;
  ${(props) => (props.isDragged ? `left: 0;` : `right: 0;`)} margin: 0;
  width: ${(props) => props.width}px;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  box-shadow: -3px 4px 14px 3px
    ${({ theme }) => getColourObject(theme).greyLighter};
`;

function imageTransform(rotation, scale) {
  const deltaPercent = (1 - scale) * 100;
  const offsetX = deltaPercent * 0.5;
  const offsetY = deltaPercent * -0.5;
  return `translate(${offsetX}%, ${offsetY}%) scale(${scale}) rotate(${rotation}deg)`;
}

export const ImageViewerCarousel = ({
  activeIndex,
  images,
  imageWidth,
  carouselRef,
  scale,
  rotation,
  handleMouseOut,
  handleMouseEnter,
  isDragged,
  ...props
}) => (
  <Carousel
    {...props}
    width={imageWidth}
    ref={carouselRef}
    isDragged={isDragged}
  >
    {images
      .map((src, idx) => ({
        id: `${src}-${idx}`,
        src,
      }))
      .map((img) => {
        // PDFs cause duplicates in this array, making the url an insufficient key :(
        return (
          <Image
            key={`carousel-image-${img.id}`}
            style={{
              transform: imageTransform(rotation, scale),
              display: img.src === images[activeIndex] ? 'block' : 'none',
            }}
            onMouseOut={handleMouseOut}
            onMouseEnter={handleMouseEnter}
            src={img.src}
            draggable={false}
          />
        );
      })}
  </Carousel>
);

export const ImageViewerCarouselPropTypes = {
  visible: PropTypes.bool,
  activeIndex: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageWidth: PropTypes.number.isRequired,
};

ImageViewerCarousel.propTypes = {
  scale: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  carouselRef: PropTypes.func.isRequired,
  handleMouseOut: PropTypes.func.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  ...ImageViewerCarouselPropTypes,
};
