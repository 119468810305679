import { useGetAllChallenges } from '@leagueplatform/challenges';
import { ENABLE_CHALLENGES_WELLNESS_AND_REWARDS } from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { formatChallengesForProgramCards } from 'utils/format-challenges-for-programs-cards.utils';

export const useChallengesCarousel = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const { data: isChallengesWellnessAndRewardsEnabled } = useFeatureFlagQuery(
    ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
  );
  const { data: challengeData, isLoading, isError } = useGetAllChallenges();

  const programs = challengeData?.data.length
    ? formatChallengesForProgramCards(challengeData)
    : [];
  const firstThreeChallenges = programs.slice(0, 3);
  return {
    ready: !isLoading,
    hasError: isError,
    carousels: [
      {
        id: null,
        title,
        description,
        programs: isChallengesWellnessAndRewardsEnabled
          ? firstThreeChallenges
          : programs,
      },
    ],
  };
};
