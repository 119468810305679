import * as React from 'react';
import { Flex, Box, genesisStyled } from '@leagueplatform/genesis-commons';
import { Avatar } from '@leagueplatform/web-common-components';

interface PariticipantAvatar {
  id: string;
  initials: string;
  photoUrl?: string;
}
export interface ParticipantsAvatarProps {
  participants: PariticipantAvatar[];
}

const MultipleParticipants = genesisStyled(Flex)`
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 48px;
  div {
    position: absolute;
  }
  div:first-of-type {
    bottom: 0;
    left: 0;
  }
  div:last-of-type {
    top: 0;
    right: 0;
  }
`;

export function ParticipantsAvatar({ participants }: ParticipantsAvatarProps) {
  const maxAvatars = 2;

  return (
    <Box width="three" height="three">
      {participants.length > 1 ? (
        <MultipleParticipants>
          {participants.slice(0, maxAvatars).map((participant) => (
            <Avatar
              key={participant.id}
              userInitials={participant.initials}
              imageSrc={participant?.photoUrl}
              width="two"
              height="two"
              fontSize="caption"
            />
          ))}
        </MultipleParticipants>
      ) : (
        <Avatar
          key={participants[0]?.initials}
          userInitials={participants[0]?.initials}
          imageSrc={participants[0]?.photoUrl}
          width="three"
          height="three"
        />
      )}
    </Box>
  );
}
