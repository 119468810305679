import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  useLottieAnimation,
  LottieComponentProps,
} from '@leagueplatform/lottie';
import { Box, VisuallyHidden } from '@leagueplatform/genesis-core';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';

const animationData = import('../assets/league-cycling-animation.lottie.json');

export interface ActivitiesAnimationComponentProps
  extends LottieComponentProps {
  shouldPlay?: boolean;
  setShouldPlay?: (shouldPlay: boolean) => void;
}

export const CycleAnimation = ({
  lottieConfig: configOverrides,
  shouldPlay,
  setShouldPlay,
}: ActivitiesAnimationComponentProps) => {
  const { formatMessage } = useIntl();
  const prefersReducedMotion = usePrefersReducedMotion();

  const lottieConfig = {
    autoplay: true,
    loop: true,
    ...configOverrides,
  };

  const { animation, animationContainerRef } = useLottieAnimation({
    lottieConfig,
    animationData,
  });

  // Handle reduced motion
  animation?.setSpeed(prefersReducedMotion ? 0.25 : 1);

  const handleAnimationPlayback = () => {
    if (animation?.isPaused) {
      setShouldPlay?.(true);
      animation?.play();
    } else {
      setShouldPlay?.(false);
      animation?.pause();
    }
  };

  return (
    <Box
      ref={animationContainerRef}
      onClick={handleAnimationPlayback}
      css={{ opacity: shouldPlay ? 1 : 0.5 }}
    >
      <VisuallyHidden>
        {formatMessage({ id: 'ANIMATION_ACCESSIBILITY_PLAYBACK' })}
      </VisuallyHidden>
    </Box>
  );
};
