import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';
import {
  ChallengesHealthProgramsCardData,
  HealthProgramsCardData,
} from 'types/health-programs-response-data.type';
import { useActiveCampaignInfo } from './use-active-campaign-limit-info.hook';

const { SCREEN_LOADED, BUTTON_CLICKED } = EVENT_NAME;

export const useProgramLibraryAnalytics = () => {
  const { data: campaignLimitInfo = {} } = useActiveCampaignInfo();
  const {
    number_of_enrolled_programs: activeCampaigns,
    max_programs_limit: campaignLimit,
  } = campaignLimitInfo;

  const meta = {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: SCREEN_NAMES.PROGRAM_LIBRARY,
  };

  const data = {
    num_health_campaign_active: activeCampaigns ?? null,
    num_health_campaign_limit: campaignLimit ?? null,
  };

  return {
    sendViewProgramLibrary() {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        ...data,
      });
    },
    sendSelectProgram({
      carouselName,
      carouselIndex,
      programCategory,
      pageContext,
      program,
      metricType,
      isChallenge,
    }: {
      carouselName: string | null;
      carouselIndex: number | null;
      pageContext?: string;
      programCategory: string | null;
      program: HealthProgramsCardData | ChallengesHealthProgramsCardData;
      metricType?: string;
      isChallenge?: boolean;
    }) {
      const { program_id: programId, name } = program;
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        ...data,
        // metric_type and health_campaign_type are needed selecting a program within
        // the dashboard capability
        ...(metricType && {
          metric_type: metricType,
          health_campaign_type: isChallenge ? 'challenge' : 'opt-in',
        }),
        screen_name: pageContext,
        detail: 'view health campaign',
        health_campaign_id: programId,
        health_campaign_name: name,
        carousel_name: carouselName,
        carousel_index: carouselIndex,
        health_campaign_category: programCategory,
      });
    },
    sendSelectProgramCategory(category: string) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        detail: 'view health campaign category',
        health_campaign_category: category,
      });
    },
    sendViewProgramCategory(category: string) {
      trackAnalyticsEvent(SCREEN_LOADED, {
        ...meta,
        screen_name: SCREEN_NAMES.CATEGORY_PAGE,
        health_campaign_category: category,
      });
    },
    sendLeaveProgramCategory(category: string) {
      trackAnalyticsEvent(BUTTON_CLICKED, {
        ...meta,
        screen_name: SCREEN_NAMES.CATEGORY_PAGE,
        detail: 'close',
        health_campaign_category: category,
      });
    },
  };
};
