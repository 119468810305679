import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { CARD_STATUS } from 'types/card-action.types';
import {
  BenefitCardAction,
  LeagueCreditBenefitCard,
  WalletBenefitCard,
  StoreCreditBalance,
} from 'types/benefit-card';
import { UserWallet } from 'types/user-wallet.type';
import { GET_WALLET_MESSAGE_TYPE } from './get-wallet';

// DISCLAIMER:
// this function will live here temporarily until the BE updates
// the League Credit (personal_store_credit_balance) response to behave like a benefit card.

// Modifying data in the query function is not pattern we should be using often, but in
// this case it's the cleanest solution as the data transformation lives in a single place
// and will eventually be removed.
function getLeagueCreditBenefit(
  data: StoreCreditBalance,
): LeagueCreditBenefitCard {
  const leagueCreditBenefit: LeagueCreditBenefitCard = {
    ...data,
    id: 'league-credit',
    product_type: 'promo_code',
    type: 'personal_store_credit_balance',
    status: CARD_STATUS.ACTIVE,
  };

  const {
    content_actions: contentActions = [],
    primary_action: primaryAction,
  } = data;

  let tempContentActions = [...contentActions];
  if (primaryAction) {
    tempContentActions = [primaryAction, ...tempContentActions];
  }

  const leagueCreditContentActions =
    tempContentActions.map(
      (action) =>
        ({
          ...action,
          type: 'content',

          // League Credit always has the same two tabs with hardcoded URLs, so we can use them to figure out the system_type
          system_type:
            action.url === '/member/wallet/promo_code/purchases'
              ? 'transaction_history'
              : 'league_credit',
        } as BenefitCardAction),
    ) || [];

  return {
    ...leagueCreditBenefit,
    content_actions: leagueCreditContentActions,
    primary_action: undefined,
  };
}

// This calls the same endpoint as getWallet, but with different parameters
// that make it work differently.
export async function getWalletBenefitCard(
  userId?: string,
  benefitId?: string,
  dependentId?: string,
): Promise<WalletBenefitCard> {
  if (!benefitId) {
    throw new Error(`Benefit ID is missing`);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const message_type = GET_WALLET_MESSAGE_TYPE;

  const isLeagueCredit = benefitId === 'league-credit';

  // if benefitId is league-credit we need to pass 'personal_store_credit_balance' as item ID
  const walletItemId = isLeagueCredit
    ? 'personal_store_credit_balance'
    : benefitId;

  const info = {
    user_id: userId,
    wallet_item_id: walletItemId,
    suppress_card_actions_and_templates: false,
    version: 6,
    dependent_id: dependentId,
    options: {
      context: 'web',
    },
  };

  const data: UserWallet = await SocketAsFetch.fetch({
    message_type,
    info,
  });

  if (isLeagueCredit && data.personal_store_credit_balance) {
    return getLeagueCreditBenefit(data.personal_store_credit_balance);
  }

  const benefitCard = data.benefit_cards.find(
    (card) => card.id === walletItemId,
  );

  if (!benefitCard) {
    throw new Error(`Benefit card ${walletItemId} not found`);
  }

  return benefitCard;
}
