import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const QUIT_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE = 'quit_health_goal_program';

export const quitHealthGoalProgram = async (
  userProgramId: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: QUIT_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE,
      info: {
        user_program_id: userProgramId,
        version: 1,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
