import * as React from 'react';
import { merge } from 'lodash';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { Carousel } from '@leagueplatform/web-common-components';
import { ParagraphText, StackItem } from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { COMPLEX_WIDGET_CARDS_WIDTH } from '../../constants';
import {
  MasonryDeeplinkAction,
  MasonryAnalyticsAction,
} from '../../types/masonry-driver-node-actions';
import { SectionHeader } from '../masonry-section-header-renderer/section-header.component';

type MasonryCarouselNodeProperties = {
  heading?: string;
  body?: string;
  headerButtonLabel?: string;
};

export type MasonryCarouselNodeActions = {
  onHeaderButtonClick?: MasonryDeeplinkAction;
  onScrollLeft: MasonryAnalyticsAction;
  onScrollRight: MasonryAnalyticsAction;
};

export type MasonryCarouselNode = MasonryEngineNode<
  'carousel',
  MasonryCarouselNodeProperties,
  MasonryCarouselNodeActions,
  {
    items: MasonryEngineNode<string, any>[];
  }
>;

export type MasonryCarouselRendererProps =
  MasonryEngineNodeRendererProps<MasonryCarouselNode>;

export const MasonryCarouselRenderer = ({
  heading,
  body,
  headerButtonLabel,
  items,
  onHeaderButtonClick,
  onScrollLeft,
  onScrollRight,
  ...props
}: MasonryCarouselRendererProps) => {
  const invokeAction = useMasonryEngineActionEmitter();

  if (!items) return null;

  return (
    <Carousel
      name={heading || 'masonry-carousel'}
      heading={
        heading ? (
          <SectionHeader heading={heading} paddingTop="$none" />
        ) : undefined
      }
      description={
        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {body}
        </ParagraphText>
      }
      spaceBetweenCards="oneAndHalf"
      linkCta={headerButtonLabel}
      linkUrl={onHeaderButtonClick?.payload.url}
      linkOnClick={(event: React.SyntheticEvent) => {
        if (onHeaderButtonClick) {
          invokeAction({
            event,
            ...onHeaderButtonClick,
          });
        }
      }}
      LeftNavButtonAnalyticsFn={() => {
        if (onScrollLeft) {
          invokeAction({
            ...onScrollLeft,
          });
        }
      }}
      RightNavButtonAnalyticsFn={() => {
        if (onScrollRight) {
          invokeAction({
            ...onScrollRight,
          });
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {React.Children?.map(items, (child) => (
        <StackItem
          css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH, height: '100%' }}
        >
          {React.cloneElement(child as unknown as React.ReactElement, {
            node: merge(
              {},
              (child as unknown as React.ReactElement).props.node,
              {
                properties: { isChildItem: true },
              },
            ),
          })}
        </StackItem>
      ))}
    </Carousel>
  );
};
