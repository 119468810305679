import { chain, isUndefined, isNull } from 'lodash';

const isEmptyString = (val) => val === '';

/**
 * @function omitKeysWithEmptyValues omit keys with empty values such as undefined, null or ''
 *
 * @param {object} data an object with a mix of keys with empty and non-empty values
 *
 * @returns {object} an object containing only keys with non-empty values
 */

export const omitKeysWithEmptyValues = (data) =>
  chain(data).omitBy(isUndefined).omitBy(isNull).omitBy(isEmptyString).value();
