import React, { useMemo } from 'react';
import * as RadixAccordionPrimitive from '@radix-ui/react-accordion';
import { Box } from '@leagueplatform/genesis-core';
import { AccordionStyleProps } from './accordion-root.component';

export const AccordionItemContext = React.createContext({
  itemId: '',
  triggerId: '',
  contentId: '',
});

export interface AccordionItemProps
  extends RadixAccordionPrimitive.AccordionItemProps,
    AccordionStyleProps {}

export const Item = React.forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ className, value, ...props }, forwardedRef) => {
    const context = useMemo(
      () => ({
        itemId: value,
        triggerId: `${value}-trigger`,
        contentId: `${value}-content`,
      }),
      [value],
    );
    const composedClassName = useMemo(
      () => ['cc-accordion-item', className].join(' '),
      [className],
    );

    return (
      <AccordionItemContext.Provider value={context}>
        <Box
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          as={RadixAccordionPrimitive.Item}
          className={composedClassName}
          ref={forwardedRef}
          value={value}
        />
      </AccordionItemContext.Provider>
    );
  },
);

Item.displayName = 'Accordion.Item';
