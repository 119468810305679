import jstz from 'jstz';
import { DEFAULT_LOCALE, getCurrentLocale } from '@leagueplatform/locales';

export function getUserConfigureConnection() {
  const locale = getCurrentLocale(DEFAULT_LOCALE);
  return {
    message_type: 'configure_connection',
    info: { tz_name: jstz.determine().name(), client_platform: 'web', locale },
  };
}
