import React, { forwardRef } from 'react';
import { styled } from '../../theme';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSSpacingValues,
} from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { focusOutlineOuter } from '../../theme/utils/focus-outline';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSScrollableProps extends GDSCustomizableComponent {
  children?: React.ReactNode;
  height?: GDSResponsiveProp<GDSSpacingValues>;
  orientation?: GDSResponsiveProp<'vertical' | 'horizontal'>;
  width?: GDSResponsiveProp<GDSSpacingValues>;
}

const ScrollableWrapper = styled('div', {
  overflow: 'hidden',
  '&:focus, &[class*="GDS"]:focus': {
    boxShadow: 'none',
    outline: 'none',
  },
  '&:focus-visible, &[class*="GDS"]:focus-visible': {
    ...focusOutlineOuter,
  },
  variants: {
    orientation: {
      vertical: {
        overflowY: 'auto',
      },
      horizontal: {
        overflowX: 'auto',
      },
    },
  },
});

export const Scrollable = forwardRef<HTMLDivElement, GDSScrollableProps>(
  (
    {
      children,
      orientation = 'vertical',
      css,
      width,
      height,
      className,
      ...props
    },
    ref,
  ) => {
    const responsiveOrientation = useResponsiveProp(orientation);
    const responsiveHeight = useResponsiveProp(height);
    const responsiveWidth = useResponsiveProp(width);
    return (
      <GenesisCoreInspector displayName="Scrollable">
        <ScrollableWrapper
          className={['GDS-scrollable', className].join(' ')}
          ref={ref}
          css={{
            ...css,
            ...(width && { width: `${responsiveWidth}` }),
            ...(height && { height: `${responsiveHeight}` }),
          }}
          orientation={responsiveOrientation}
          tabIndex={0}
          {...props}
        >
          {children}
        </ScrollableWrapper>
      </GenesisCoreInspector>
    );
  },
);

Scrollable.displayName = 'Scrollable';
