import { useGetAllChallenges } from '@leagueplatform/challenges';
import { ChallengeTypes } from '@leagueplatform/health-journey-api';
import { formatChallengesForProgramCards } from 'utils/format-challenges-for-programs-cards.utils';

export const useGetChallengesByType = (challengeType: ChallengeTypes) => {
  const {
    data: challengeData,
    isLoading,
    isError,
  } = useGetAllChallenges(challengeType);

  const challenges = challengeData?.data.length
    ? formatChallengesForProgramCards(challengeData)
    : [];
  return {
    isLoading,
    isError,
    challenges,
  };
};
