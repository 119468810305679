import React, { forwardRef, ElementType } from 'react';
import { TypographyBase } from 'components/typography/typography-base/typography-base';
import {
  HtmlToReact,
  startsWithPOrDivTag,
} from 'components/html-to-react/html-to-react';
import { GDSHTMLTagOptions } from 'components/html-to-react/html-to-react.config';
import type { GDSTypography } from '../typography';
import type {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../../../types';
import type { GDSVariantProps } from '../../../theme';
import { styled } from '../../../theme';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

export type GDSParagraphTextSize = 'base' | 'sm' | 'xs';

export type GDSParagraphTextProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      as?: ElementType;
      size?: GDSVariantProps<typeof StyledParagraphText>['size'];
      htmlStringOptions?: GDSHTMLTagOptions;
    }
  > &
    GDSTypography;

export type GDSParagraphTextComponent = <C extends React.ElementType = 'p'>(
  props: GDSParagraphTextProps<C>,
) => React.ReactElement | null;

const StyledParagraphText = styled(TypographyBase, {
  variants: {
    size: {
      base: {
        typography: '$bodyOne',
        '&:not(:last-child)': {
          marginBlockEnd: '$bodyOne',
        },
      },
      sm: {
        typography: '$bodyTwo',
        '&:not(:last-child)': {
          marginBlockEnd: '$bodyTwo',
        },
      },
      xs: {
        typography: '$caption',
        '&:not(:last-child)': {
          marginBlockEnd: '$quarter',
        },
      },
    },
  },
});

export const ParagraphText: GDSParagraphTextComponent = forwardRef(
  <C extends React.ElementType = 'p'>(
    {
      children,
      className,
      css,
      emphasis = 'base',
      id,
      size = 'base',
      htmlStringOptions = {},
      as,
      ...props
    }: GDSParagraphTextProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const beginsWithPOrDivTag =
      typeof children === 'string' ? startsWithPOrDivTag(children) : undefined;

    return (
      <GenesisCoreInspector displayName="ParagraphText">
        <StyledParagraphText
          // render the ParagraphText as a <div> if the content is a rich text string beginning with a <p> or <div> tags
          as={as || (beginsWithPOrDivTag && 'div') || 'p'}
          className={['GDS-paragraph-text', className].join(' ')}
          css={css}
          emphasis={emphasis}
          id={id}
          ref={ref}
          size={size}
          {...props}
        >
          {typeof children === 'string' ? (
            <HtmlToReact htmlString={children} options={htmlStringOptions} />
          ) : (
            children
          )}
        </StyledParagraphText>
      </GenesisCoreInspector>
    );
  },
);

// @ts-ignore
ParagraphText.displayName = 'ParagraphText';
