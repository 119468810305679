import React, { useEffect } from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import { Activity, Achievement } from 'constants/types';
import { useIntl } from '@leagueplatform/locales';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  Button,
  HeadingText,
  Modal,
  StackLayout,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import {
  PROGRESS_MODAL_ACTIONS,
  REWARDS_EVENT_ANALYTICS,
} from 'constants/analytics.constants';

import { AnalyticsDictFactory } from 'utils/analytics-dict-factory.utils';
import { BadgeProgress } from '../badge-progress/badge-progress.view';

interface ProgressModalProps {
  onClose: () => void;
  activities: Activity[];
  celebrations?: Achievement[];
}

export const ProgressModal = ({
  onClose,
  activities,
  celebrations,
}: ProgressModalProps) => {
  const { formatMessage } = useIntl();

  const handleButtonAction = (action: PROGRESS_MODAL_ACTIONS) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_MODAL,
      detail: action,
    });
    onClose();
  };
  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_MODAL,
      num_of_milestone_rewards: activities.length,
      milestone_rewards_dict: AnalyticsDictFactory(
        activities.map((activity) => ({
          milestone_reward_id: activity.id,
          milestone_reward_name: activity.name,
          completed: activity.progress.is_completed,
        })),
      ),
    });
  }, [activities]);

  return (
    <Modal.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) handleButtonAction(PROGRESS_MODAL_ACTIONS.CLOSE);
      }}
    >
      <Modal.Title>
        <VisuallyHidden>
          {formatMessage({ id: 'ACHIEVEMENT_PROGRESS' })}
        </VisuallyHidden>
      </Modal.Title>

      <Modal.Content
        css={{
          '.GDS-modal': { width: '100%', maxWidth: '618px' },
        }}
      >
        <StackLayout orientation="vertical" horizontalAlignment="center">
          <HeadingText level="1" size="xxl" css={{ marginBottom: '$two' }}>
            {formatMessage({ id: 'KEEP_GOING' })}!
          </HeadingText>
          <StackLayout
            orientation="vertical"
            horizontalAlignment="center"
            css={{ width: '100%', maxWidth: '350px', marginBottom: '$two' }}
          >
            {activities.map((activity) => (
              <BadgeProgress
                imageUrl={activity.image.large}
                isLoading={false}
                title={activity.name}
                totalActivities={activity.progress.threshold}
                currentActivity={activity.progress.current}
                progressTitle={`${activity.progress.current}/${
                  activity.progress.threshold
                } ${formatMessage({ id: 'ACTIVITIES' })}`}
                completed={activity.progress.is_completed}
              />
            ))}
          </StackLayout>
          <StackLayout orientation="horizontal" spacing="$one">
            {celebrations ? (
              <Button
                onClick={() =>
                  handleButtonAction(PROGRESS_MODAL_ACTIONS.CONTINUE)
                }
              >
                {formatMessage({ id: 'CONTINUE' })}
              </Button>
            ) : (
              <>
                <Button
                  priority="tertiary"
                  quiet
                  role="link"
                  as={RouterLink}
                  to={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
                  onClick={() =>
                    handleButtonAction(PROGRESS_MODAL_ACTIONS.VIEW_ALL)
                  }
                  css={{ textDecoration: 'none', marginRight: '$oneAndHalf' }}
                >
                  {formatMessage({ id: 'VIEW_ALL_ACHIEVEMENTS' })}
                </Button>
                <Button
                  onClick={() =>
                    handleButtonAction(PROGRESS_MODAL_ACTIONS.TAKE_ME_BACK)
                  }
                >
                  {formatMessage({ id: 'TAKE_ME_BACK' })}
                </Button>
              </>
            )}
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
