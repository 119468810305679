import { WebsocketApiURL } from 'types/socket.types';
import { getWebsocketAuthMessage } from 'util/get-websocket-auth-message';
import * as uuid from 'uuid';
import WebSocketAsPromised from 'websocket-as-promised';

/**
 * The type declarations of `websocket-as-promised` are incorrect.
 * The `close` method of this class accepts optional code and reason arguments
 * (see https://github.com/vitalets/websocket-as-promised/blob/v2.0.1/src/index.js#L289)
 * but in the declaration file those parameters are missing
 * (see https://github.com/vitalets/websocket-as-promised/blob/v2.0.1/types/index.d.ts#L26).
 */
type CorrectCloseMethod = (
  code?: number,
  reason?: string,
) => ReturnType<WebSocketAsPromised['close']>;

export class LeagueSocketAsPromised extends WebSocketAsPromised {
  #currentAuthState = '';

  constructor(url: WebsocketApiURL) {
    super(url, {
      createWebSocket: (address) => new WebSocket(address),
      packMessage: JSON.stringify,
      unpackMessage: (data) => JSON.parse(data as string),
      attachRequestId: (data, message_id) => ({ ...data, message_id }),
      extractRequestId: (data) => data?.message_id,
    });
  }

  close = super.close as CorrectCloseMethod;

  async authenticate() {
    const authMessage = await getWebsocketAuthMessage();
    await this.sendRequest(authMessage, {
      requestId: uuid.v4(),
    });
    this.#currentAuthState = JSON.stringify(authMessage.info);
  }

  async authenticateIfNeeded() {
    const authMessage = await getWebsocketAuthMessage();
    if (this.#currentAuthState !== JSON.stringify(authMessage.info)) {
      await this.authenticate();
    }
  }
}
