import * as React from 'react';
import { BodyOne, HeadingOne, Overline } from '@leagueplatform/genesis-commons';
import {
  SHORT_DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { HealthMetric } from '@leagueplatform/dashboard-api';
import { useIntl } from '@leagueplatform/locales';
import { isSupportedUnitMetricType } from 'constants/use-data-type-config';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import { ChartTooltip } from './chart-tooltip';

export type SingleMeasurementTooltipProps = HealthMetric & {
  title: string;
};

export function SingleMeasurementTooltip({
  title,
  type,
  value,
  timestamp,
  unit,
}: SingleMeasurementTooltipProps) {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();

  if (!isSupportedUnitMetricType(type)) {
    throw new Error(`Missing '${type}' metric configuration`);
  }

  if (value === null) {
    return null;
  }

  return (
    <ChartTooltip>
      <Overline color="onSurface.text.subdued" paddingBottom="quarter">
        {`${formatMessage({ id: 'TOTAL' })} ${title}`}
      </Overline>
      <HeadingOne as="span">{formatMetricValue(value, type)}</HeadingOne>{' '}
      <BodyOne as="span" color="onSurface.text.subdued">
        {formatUnit(type, unit, value)}
      </BodyOne>
      <Overline paddingTop="quarter" as="p" color="onSurface.text.subdued">
        {formatDateWithUserProfileLocation(
          new Date(timestamp),
          SHORT_DATE_FORMAT,
        )}
      </Overline>
    </ChartTooltip>
  );
}
