import React, { forwardRef, useRef, useMemo } from 'react';
import { NavLink } from '@leagueplatform/routing';
import { tabStyles, TabIconLabelWrapper } from 'components/tabs/tab';
import { useTablistContext } from 'components/tabs/tablist-context';
import { useTabElementIsInView } from 'components/tabs/use-tab-element-is-in-view';
import { Icon } from 'components/icon/icon';
import { styled } from '../../theme';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import { GDSLinkProps, NavItem } from './nav-link';

export interface GDSRouterLinkProps extends GDSLinkProps {
  id: string;
  path: string;
}

const BaseRouterLink = styled(NavLink, {
  ...tabStyles,
});

export const RouterLink = forwardRef<HTMLAnchorElement, GDSRouterLinkProps>(
  (
    { children, className, css, icon, iconLabel, id, path, width, ...props },
    ref,
  ) => {
    const internalRef = useRef<HTMLAnchorElement>(null);
    const composedRefs = useComposedRefs(ref, internalRef);
    const tabWidth = useResponsiveProp(width);
    const tabIcon = useResponsiveProp(icon);
    const { elementIsInView, tabScrollOptions } = useTabElementIsInView();
    const { scrollLeftButton, scrollRightButton } = useTablistContext();

    const scrollElementIfNotInView = useMemo(
      () => () => {
        if (
          !elementIsInView(internalRef) &&
          (scrollLeftButton || scrollRightButton)
        ) {
          internalRef.current!.scrollIntoView(tabScrollOptions);
        }
      },
      [elementIsInView, tabScrollOptions, scrollLeftButton, scrollRightButton],
    );

    return (
      <NavItem
        className={[`GDS-nav-item`, className].join(' ')}
        css={{
          ...css,
          ...(width && {
            width: `${tabWidth}`,
          }),
        }}
      >
        <BaseRouterLink
          className="GDS-nav-router-link"
          id={id}
          to={path}
          ref={composedRefs}
          onFocus={() => scrollElementIfNotInView()}
          {...props}
        >
          <TabIconLabelWrapper>
            {tabIcon && <Icon icon={tabIcon} label={iconLabel} />}
            {children}
          </TabIconLabelWrapper>
        </BaseRouterLink>
      </NavItem>
    );
  },
);
