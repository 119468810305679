import React, { useState, useRef, useContext } from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import { LocaleContext } from '@leagueplatform/locales';
import { useHandleDropdown } from '@leagueplatform/web-common';
import { LanguageBar } from './language-selector-bar.component';
import { Dropdown } from './language-selector-dropdown.component';
import { useUserCountryCode } from './use-user-country-code.hook';

export const LanguageSelector = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const countryCode = useUserCountryCode();

  useHandleDropdown({
    isOpen: isDropdownOpen,
    setIsOpen: setIsDropdownOpen,
    ref: dropdownRef,
  });

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Flex
      justifyContent="center"
      position="relative"
      ref={dropdownRef}
      backgroundColor="surface.background.primary"
    >
      <LanguageBar
        aria-controls="language_dropdown_menu"
        aria-expanded={isDropdownOpen}
        aria-haspopup="true"
        isOpen={isDropdownOpen}
        onClick={toggleDropdown}
        selectedLanguage={locale}
      />
      <Dropdown
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
        selectedLanguage={locale}
        setLanguage={setLocale}
        countryCode={countryCode}
      />
    </Flex>
  );
};
