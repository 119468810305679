import React from 'react';
import type { PropsWithChildren } from 'react';
import { RouteComponentProvider, BaseRoute } from '@leagueplatform/routing';
import { RouteWithObservability } from '@leagueplatform/observability';
import { useConfigProperty } from '@leagueplatform/config';

/**
 * Encapsulates logic for determining whether to pass the Observabiity-aware route component
 * to the routing module's `<RouteComponentProvider>`. Will ensure all descendents use the correct
 * `<Route>` component.
 */
export const LeagueRouteComponentProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const routeComponent = useConfigProperty('core.routing.isUsingLeagueRouting')
    ? RouteWithObservability
    : BaseRoute;

  return (
    <RouteComponentProvider routeComponent={routeComponent}>
      {children}
    </RouteComponentProvider>
  );
};
