import React, { forwardRef } from 'react';
import * as RadixPopoverPrimitive from '@radix-ui/react-popover';
import { Portal } from 'components/portal/portal';
import { Button } from 'components/button/button';
import type {
  GDSAlign,
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSSide,
  GDSSpacingValues,
} from '../../types';
import { styled } from '../../theme';
import { useThemeStrings } from '../../hooks/use-theme';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSPopoverContentProps
  extends Omit<RadixPopoverPrimitive.PopoverContentProps, 'align' | 'side'>,
    GDSCustomizableComponent {
  align?: GDSResponsiveProp<GDSAlign>;
  closeLabel?: string;
  headingBar?: React.ReactNode;
  padding?: GDSResponsiveProp<GDSSpacingValues>;
  showCloseButton?: boolean;
  side?: GDSResponsiveProp<GDSSide>;
}

const BasePopoverContent = styled(RadixPopoverPrimitive.Content, {
  typography: '$bodyOne',
  color: '$onSurfaceTextPrimary',
  borderRadius: '$medium',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$onSurfaceBorderSubdued',
  backgroundColor: '$surfaceBackgroundPrimary',
  boxShadow: '$dropdown',
  zIndex: '$tooltip',
  '&[class*="GDS"]:focus, &[class*="GDS"]:active': {
    boxShadow: '$dropdown',
    outline: 'none',
    position: 'initial',
  },
});

const PopoverHeading = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBlockEnd: '$one',
});

export const PopoverContent = forwardRef<
  HTMLDivElement,
  GDSPopoverContentProps
>(
  (
    {
      align,
      avoidCollisions,
      children,
      closeLabel,
      collisionPadding,
      css,
      headingBar,
      padding = '$one',
      showCloseButton = true,
      side,
      sideOffset = 6,
      className,
      ...props
    },
    ref,
  ) => {
    const contentPadding = useResponsiveProp(padding);
    const responsiveSide = useResponsiveProp(side);
    const responsiveAlign = useResponsiveProp(align);
    const stringsMap = useThemeStrings();

    return (
      <Portal>
        <GenesisCoreInspector displayName="PopoverContent">
          <BasePopoverContent
            className={['GDS-popover-content', className].join(' ')}
            align={responsiveAlign}
            avoidCollisions={avoidCollisions}
            collisionPadding={collisionPadding}
            css={{
              ...css,
              ...(padding && {
                padding: `${contentPadding}`,
              }),
            }}
            ref={ref}
            side={responsiveSide}
            sideOffset={sideOffset}
            {...props}
          >
            {headingBar ||
              (showCloseButton && (
                <PopoverHeading className="GDS-popover-heading-bar">
                  <RadixPopoverPrimitive.Close asChild>
                    <Button
                      type="button"
                      icon="tinyClose"
                      hideLabel
                      quiet
                      priority="secondary"
                      size="small"
                      className="GDS-popover-close"
                      css={{
                        color: '$interactiveActionSubdued',
                        svg: {
                          width: '$threeQuarters',
                          height: '$threeQuarters',
                        },
                      }}
                    >
                      {closeLabel || stringsMap.close}
                    </Button>
                  </RadixPopoverPrimitive.Close>
                </PopoverHeading>
              ))}

            {children}
          </BasePopoverContent>
        </GenesisCoreInspector>
      </Portal>
    );
  },
);
