import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  Flex,
  css,
  genesisStyled,
  Box,
  SpacePropsType,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import {
  useSwipeGestures,
  SWIPE_ORIENTATION,
  SWIPE_DIRECTION,
} from '@leagueplatform/web-common';
import { tween, easing } from 'popmotion';
import { CTALink } from './carousel-cta-link.component';
import { ProgressDots } from './carousel-progress-dots.component';
import { LeftNavButton, RightNavButton } from './carousel-nav-button.component';

type DefaultCarouselProps = {
  name: string;
  children: React.ReactNode;
  spaceBetweenCards?: SpacePropsType;
  heading?: React.ReactNode;
  description?: React.ReactNode;
  sideArrows?: boolean;
  showProgressDots?: boolean;
  LeftNavButtonAnalyticsFn?: () => void;
  RightNavButtonAnalyticsFn?: () => void;
  onIndexChange?: (index: number) => void;
  titleMarginBottom?: SpacePropsType;
};

// If CTA link is going to be used, linkCTA & linkUrl must be included.
// linkOnClick is optional and can be used in additional to linkUrl (ex. to send analytics)
// or to override the default link behaviour (ex. to handle authorization on link click)
// if e.preventDefault() is a part of the function.
type LinkCTAProps =
  // linkCta & linkUrl provided.
  // linkOnClick & linkAriaLabel are optional.
  | {
      linkCta: string;
      linkUrl: string;
      linkOnClick?: (e: React.SyntheticEvent) => void;
      linkAriaLabel?: string;
    }
  // linkCta, linkOnClick & linkUrl provided.
  // linkAriaLabel is optional.
  // linkUrl is required so an href value can be provided to the link for a11y purposes
  | {
      linkCta: string;
      linkUrl: string;
      linkOnClick: (e: React.SyntheticEvent) => void;
      linkAriaLabel?: string;
    }
  // For carousel-widget.component.tsx
  // linkCta & linkUrl provided (they must both be marked as optional due to the API structure for the Carousel Widget).
  // linkOnClick & linkAriaLabel are not provided.
  | {
      linkCta?: string;
      linkUrl?: string;
      linkOnClick?: (e: React.SyntheticEvent) => void;
      linkAriaLabel?: string;
    }
  // linkCta is not provided, the other link props shouldn't be provided as well.
  | {
      linkCta?: never;
      linkUrl?: never;
      linkOnClick?: never;
      linkAriaLabel?: never;
    };

type CarouselProps = DefaultCarouselProps & LinkCTAProps;

interface CarouselRefType extends HTMLUListElement {
  // offsetWidth extended from HTMLUListElement
  children: HTMLCollectionOf<HTMLLIElement>;
}

enum NAV_BUTTON {
  right = 'RIGHT',
  left = 'LEFT',
}

export const shouldShowNavigation = ({
  offsetWidth,
  children,
}: CarouselRefType) => {
  if (!children || children.length === 0) return false;
  return children[0].offsetWidth * children.length > offsetWidth;
};

const CarouselInnerWrapper = genesisStyled(Flex)(({ spaceBetweenCards }) =>
  css({
    '& > *': {
      marginRight: spaceBetweenCards,
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
);

export const Carousel = ({
  name,
  children,
  spaceBetweenCards = 'one',
  heading,
  description,
  linkOnClick,
  linkAriaLabel,
  linkCta,
  linkUrl,
  LeftNavButtonAnalyticsFn = () => null,
  RightNavButtonAnalyticsFn = () => null,
  sideArrows = false,
  showProgressDots = false,
  onIndexChange,
  titleMarginBottom = 'oneAndHalf',
}: CarouselProps) => {
  const carouselRef = useRef<CarouselRefType>(null);
  const childrenRef = useRef<HTMLLIElement[]>([]);

  // Navigation Buttons
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [showNavButtons, setShowNavButtons] = useState(true);
  const swipeDirection = useSwipeGestures(
    carouselRef,
    SWIPE_ORIENTATION.horizontal,
  );

  // Progress Indicator Dots
  const [currentProgressIndex, setCurrentProgressIndex] = useState(0);

  // Carousel Children
  const childrenLength = React.Children.toArray(children).length;
  const [childrenInView, setChildrenInView] = useState<number[]>([]);

  const currentCarouselProperties = () => {
    const { current: currentCarouselRef } = carouselRef;

    if (currentCarouselRef) {
      const { scrollLeft, offsetWidth, scrollWidth } = currentCarouselRef;
      const firstChild = currentCarouselRef.children[0];

      const childrenWidth =
        firstChild.offsetWidth +
        parseInt(getComputedStyle(firstChild).marginRight, 10);

      // Current index is determined by how far it is scrolled, divided by the width of each card.
      const currentIndex = parseInt(
        (scrollLeft / childrenWidth).toString(),
        10,
      );

      // Scroll card amount is determined by the scroll container divided by the card width.
      const amountOfChildrenInView = Math.round(offsetWidth / childrenWidth);

      const userScroll = offsetWidth + scrollLeft;

      return {
        currentCarouselRef,
        scrollLeft,
        offsetWidth,
        scrollWidth,
        userScroll,
        firstChild,
        childrenWidth,
        currentIndex,
        amountOfChildrenInView,
      };
    }

    return null;
  };

  // Determines which children indexes are currently in view and updates state.
  const updateChildrenInView = useCallback(() => {
    const carouselProperties = currentCarouselProperties();

    if (carouselProperties) {
      const { amountOfChildrenInView, currentIndex, userScroll, scrollWidth } =
        carouselProperties;

      // Used to make sure the indexes returned are accurate when the last of the carousel children is reached.
      const lastChildOffset =
        childrenLength > 1 && userScroll > scrollWidth ? 1 : 0;
      const currentChildIndex = currentIndex + lastChildOffset;

      if (amountOfChildrenInView === 1)
        return setChildrenInView([currentChildIndex]);

      const inView: number[] = [];
      for (let x = 0; x < amountOfChildrenInView; x += 1) {
        inView.push(currentChildIndex + x);
      }

      setChildrenInView(inView);
    }

    return null;
  }, [childrenLength]);

  // Updates the tabindex attribute for carousel items based on if the item is currently in view or not (overflow).
  const updateChildrenTabIndex = useCallback(() => {
    childrenRef.current.forEach((elementRef, index) => {
      const elementInView = childrenInView.find((el) => el === index);

      if (elementInView !== undefined) {
        elementRef
          .querySelector('*[tabindex="-1"]')
          ?.setAttribute('tabindex', '0');
      } else {
        elementRef
          .querySelector('*[tabindex="0"]')
          ?.setAttribute('tabindex', '-1');
      }
    });
  }, [childrenInView, childrenRef]);

  // Disable or enable the Navigation buttons when user scrolls.
  const scrollingEvent = useCallback(() => {
    const carouselProperties = currentCarouselProperties();

    if (carouselProperties) {
      const { scrollLeft, scrollWidth, userScroll } = carouselProperties;

      setLeftArrowDisabled(scrollLeft === 0);
      setRightArrowDisabled(userScroll >= scrollWidth);
    }
  }, []);

  useEffect(() => {
    const carouselProperties = currentCarouselProperties();

    if (carouselProperties) {
      const { scrollWidth, offsetWidth, currentCarouselRef } =
        carouselProperties;

      setRightArrowDisabled(scrollWidth <= offsetWidth);
      currentCarouselRef.addEventListener('scroll', scrollingEvent);

      // Show navigation buttons only if width of slides > width of container.
      setShowNavButtons(shouldShowNavigation(currentCarouselRef));

      // Store references to carousel children in an array.
      childrenRef.current = childrenRef.current.slice(0, childrenLength);
      updateChildrenInView();

      return () => {
        currentCarouselRef.removeEventListener('scroll', scrollingEvent);
      };
    }

    return () => null;
  }, [children, childrenLength, scrollingEvent, updateChildrenInView]);

  useEffect(() => {
    updateChildrenTabIndex();
  }, [childrenInView, updateChildrenTabIndex]);

  const onResizeCarousel = useCallback(() => {
    const { current: currentCarouselRef } = carouselRef;

    if (currentCarouselRef) {
      const showNavigation = shouldShowNavigation(currentCarouselRef);

      if (showNavigation !== showNavButtons) {
        setShowNavButtons(showNavigation);

        // Trigger the 'scroll' event that the carousel is listening for and reset left to ensure  the carousel is starting on the first slide.
        currentCarouselRef.dispatchEvent(
          new CustomEvent('scroll', { detail: { scrollLeft: 0 } }),
        );
      }
    }
  }, [showNavButtons, carouselRef]);

  useEffect(() => {
    const { current: currentCarouselRef } = carouselRef;

    if (currentCarouselRef) {
      // Listen for resize event(s) only on the carousel instead of the entire window.
      const carouselResize = new ResizeObserver(onResizeCarousel);
      carouselResize.observe(currentCarouselRef);

      return () => {
        carouselResize.disconnect();
      };
    }

    return () => null;
  }, [carouselRef, onResizeCarousel]);

  // return current index when updated
  useEffect(() => {
    if (onIndexChange) {
      if (currentProgressIndex < 0 || currentProgressIndex >= childrenLength)
        return;
      onIndexChange(currentProgressIndex);
    }
  }, [childrenLength, currentProgressIndex, onIndexChange]);

  const scrollTo = (to = 0, duration = 0) => {
    const { current: currentCarouselRef } = carouselRef;

    if (currentCarouselRef) {
      const from = currentCarouselRef.scrollLeft;
      const scroll = tween({
        from,
        to,
        duration,
        ease: easing.easeOut,
      });
      scroll.start({
        update: (left: number) => {
          currentCarouselRef.scrollLeft = left;
        },
        complete: () => {
          updateChildrenInView();
        },
      });
    }
  };

  const handleNavButtonEvent = (type: NAV_BUTTON) => {
    const carouselProperties = currentCarouselProperties();

    if (carouselProperties) {
      const {
        currentIndex,
        amountOfChildrenInView,
        childrenWidth,
        scrollLeft,
      } = carouselProperties;

      if (type === NAV_BUTTON.right) {
        // Determine how far the view needs to scroll to.
        scrollTo((currentIndex + amountOfChildrenInView) * childrenWidth, 200);
        setCurrentProgressIndex(currentIndex + 1);
      } else if (type === NAV_BUTTON.left) {
        // The user is scrolling left. Scroll to the current index's position.
        // On the chance that the user is on the current index's position and wants to scroll left, the carousel would have to scroll to the previous index's position.
        scrollTo(
          scrollLeft % childrenWidth === 0
            ? (currentIndex - amountOfChildrenInView) * childrenWidth
            : currentIndex * childrenWidth,
          200,
        );
        setCurrentProgressIndex((prevIndex) => prevIndex - 1);
      }
    }
  };

  const LeftNavButtonEvent = () => {
    handleNavButtonEvent(NAV_BUTTON.left);
    LeftNavButtonAnalyticsFn();
  };

  const RightNavButtonEvent = () => {
    handleNavButtonEvent(NAV_BUTTON.right);
    RightNavButtonAnalyticsFn();
  };

  useEffect(() => {
    if (swipeDirection) {
      switch (swipeDirection) {
        case SWIPE_DIRECTION.right:
          RightNavButtonEvent();
          break;
        case SWIPE_DIRECTION.left:
          LeftNavButtonEvent();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection]);

  if (children === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`No children found for carousel: ${name}`);
    return null;
  }

  if (
    (!!linkUrl && linkCta === undefined) ||
    (!!linkCta && linkUrl === undefined)
  ) {
    if (linkCta === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `Link CTA will not render. "linkUrl" prop provided, but "linkCta" prop missing for carousel: ${name}. Please provide both props.`,
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Link CTA will not render. "linkCta" prop provided, but "linkUrl" prop missing for carousel: ${name}. Please provide both props.`,
      );
    }
  }

  let descriptionWrapper = description;
  if (description && typeof description === 'string') {
    descriptionWrapper = (
      <BodyOne color="onSurface.text.subdued">{description}</BodyOne>
    );
  }

  return (
    <Flex flexDirection="column" overflow="hidden" data-testid="carousel">
      <Box marginBottom={titleMarginBottom}>
        <Flex
          alignItems="center"
          justifyContent={heading ? 'space-between' : 'flex-end'}
        >
          {heading}
          <Flex
            marginLeft="threeQuarters"
            alignItems="center"
            flexDirection={{ _: 'column', phone: 'row' }}
            columnGap="oneAndHalf"
            rowGap="threeQuarters"
          >
            {linkCta && linkUrl && (
              <CTALink
                onClick={linkOnClick}
                ariaLabel={linkAriaLabel}
                href={linkUrl}
              >
                {linkCta}
              </CTALink>
            )}
            {showNavButtons && !sideArrows && (
              <Flex paddingY="quarter" columnGap="one" marginRight="4px">
                <LeftNavButton
                  disabled={leftArrowDisabled}
                  onClick={LeftNavButtonEvent}
                />
                <RightNavButton
                  disabled={rightArrowDisabled}
                  onClick={RightNavButtonEvent}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        {descriptionWrapper}
      </Box>
      <Flex
        width="100%"
        justifyContent="space-between"
        minWidth="100%"
        position="relative"
      >
        <CarouselInnerWrapper
          width="100%"
          height="100%"
          overflow="hidden"
          as="ul"
          ref={carouselRef}
          spaceBetweenCards={spaceBetweenCards}
          paddingBottom={showProgressDots ? 'four' : 'quarter'}
          padding="quarter"
        >
          {React.Children.map(children, (child, index) => (
            <li
              ref={(element) => {
                if (element !== null) {
                  childrenRef.current[index] = element;
                }
                return null;
              }}
            >
              {child}
            </li>
          ))}
        </CarouselInnerWrapper>
        {sideArrows && showNavButtons && (
          <Flex
            position="absolute"
            left="one"
            top="50%"
            zIndex={1}
            transform="translateY(-50%)"
          >
            <LeftNavButton
              disabled={leftArrowDisabled}
              onClick={LeftNavButtonEvent}
            />
          </Flex>
        )}
        {sideArrows && showNavButtons && (
          <Flex
            position="absolute"
            right="one"
            top="50%"
            zIndex={1}
            transform="translateY(-50%)"
          >
            <RightNavButton
              disabled={rightArrowDisabled}
              onClick={RightNavButtonEvent}
            />
          </Flex>
        )}
        {/* Only show progress dots if prop is provided and there is more than one child component. */}
        {showProgressDots && childrenLength >= 2 && (
          <ProgressDots
            currentDot={currentProgressIndex}
            length={childrenLength}
          />
        )}
      </Flex>
    </Flex>
  );
};
