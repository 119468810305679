import { Box } from '@leagueplatform/genesis-core';
import * as React from 'react';

const DEFAULT_STYLES = {
  MIN_GRID_ITEM_WIDTH: '250px',
  GRID_COLUMN_COUNT: 2,
};

type GridContainerProps = {
  gridColumnCount?: number;
  minGridItemWidth?: string;
};

export function GridContainer({
  gridColumnCount = DEFAULT_STYLES.GRID_COLUMN_COUNT,
  minGridItemWidth = DEFAULT_STYLES.MIN_GRID_ITEM_WIDTH,
  children,
}: React.PropsWithChildren<GridContainerProps>) {
  const gapCount = gridColumnCount - 1;
  const totalGapWidth = gapCount * 16;
  const maxGridItemWidth = `calc((100% - ${totalGapWidth}px) / ${gridColumnCount})`;

  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(max(${minGridItemWidth}, ${maxGridItemWidth}), 1fr));`,
        gridGap: '$one',
      }}
    >
      {children}
    </Box>
  );
}
