import React from 'react';
import { Carousel } from '@leagueplatform/web-common-components';
import { ParagraphText, Box } from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { SectionHeader } from '../section-header/section-header.component';

export type GenericCarouselWidgetProps = {
  // @TODO handle as part of MSRY-1414
  // onSelectItem: (item: any, index: number) => void;
  body?: string;
  children: React.ReactNode;
  headerButtonAction?: string;
  headerButtonLabel?: string;
  heading?: string;
  onScrollLeft: () => void;
  onScrollRight: () => void;
  onSelectHeader: () => void;
};

export const GenericCarouselWidget = ({
  body,
  children,
  headerButtonAction,
  headerButtonLabel,
  heading,
  onScrollLeft,
  onScrollRight,
  onSelectHeader,
}: GenericCarouselWidgetProps) => (
  <Box
    css={{
      paddingTop: '$one',
    }}
  >
    <Carousel
      name={heading || 'Content Cards'}
      heading={
        heading ? (
          <SectionHeader heading={heading} paddingTop="$none" />
        ) : undefined
      }
      description={
        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {body}
        </ParagraphText>
      }
      LeftNavButtonAnalyticsFn={onScrollLeft}
      linkCta={headerButtonLabel}
      linkOnClick={onSelectHeader}
      linkUrl={headerButtonAction}
      RightNavButtonAnalyticsFn={onScrollRight}
      spaceBetweenCards="oneAndHalf"
    >
      {children}
    </Carousel>
  </Box>
);
