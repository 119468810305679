import React, { useEffect } from 'react';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  Checkbox,
  BodyOne,
  Link,
  HeadingOne,
  PrimaryButton,
  genesisStyled,
  Label,
  ErrorMessage,
  Box,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import type { Formatter } from '@leagueplatform/locales';
import { Formik, Field, Form } from 'formik';
import { PRIVACY_POLICY_MAX_WIDTH } from 'constants/challenges.constants';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { USER_CONNECTED_DEVICE_CAMPAIGN_MODES } from '@leagueplatform/health-journey-api';
import {
  SIGN_UP_MODAL_PAGES,
  Values,
} from '../../constants/sign-up-modal.constants';
import { getPrivacyPolicyUrl } from '../../utils/privacy-url.util'; // replace with modularized version of this function from webplat
import { useSignUpForChallenge } from '../../hooks/use-sign-up-for-challenge.hook';

const { ACCEPT_PRIVACY_POLICY } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { CONTINUE } = SIGN_UP_ANALYTICS_DETAILS;

interface AcceptPrivacyPolicyProps {
  trackingType: Values<typeof USER_CONNECTED_DEVICE_CAMPAIGN_MODES>;
  setNextModalNavigationStep: (next: keyof typeof SIGN_UP_MODAL_PAGES) => void;
}

const StyledUL = genesisStyled.ul`
  list-style: unset;
  padding-left: 40px;
`;

const PrivacyPolicyLink: Formatter = (text) => {
  const linkout = handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_LINKOUT, {
    isComponent: true,
  });
  return (
    <Link
      href={getPrivacyPolicyUrl()}
      target="_blank"
      display="inline-flex"
      justifyContents="center"
      alignItems="center"
    >
      <Box as="span" marginRight="quarter">
        {text}
      </Box>
      {linkout}
    </Link>
  );
};

export const AcceptPrivacyPolicy = ({
  trackingType,
  setNextModalNavigationStep,
}: AcceptPrivacyPolicyProps) => {
  const { formatMessage } = useIntl();
  const { data: challengeData } = useGetChallengeByIdData();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const signUpForChallengeMutation = useSignUpForChallenge(
    trackingType,
    setNextModalNavigationStep,
  );
  useEffect(
    () =>
      trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        screen_name: ACCEPT_PRIVACY_POLICY,
        is_critical_event: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleSubmitAnalytics = (error: boolean) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: ACCEPT_PRIVACY_POLICY,
      detail: CONTINUE,
      is_critical_event: true,
      is_validation_error: error,
    });
  // trust that challengeData exists as we prevent home page from loading without it
  const { challengeType, dataFields } = challengeData!.data;
  const isSoloChallenge = challengeType === 'solo';
  const showWearablesConnection = dataFields && dataFields.length > 0;
  // if user does not have wearable connections, they won't be see wearables connection screen and should be signed up for challenge
  const handleSignUpLogic = () => {
    if (!showWearablesConnection) {
      signUpForChallengeMutation.mutate();
    } else {
      setNextModalNavigationStep(SIGN_UP_MODAL_PAGES.WEARABLES_CONNECTION);
    }
  };
  return (
    <>
      {/* Setting aria-hidden here as this is the same visually hidden heading we use for the entire sign up flow. See heading in Sign Up Modal */}
      <Box maxWidth={PRIVACY_POLICY_MAX_WIDTH} margin="0 auto">
        <HeadingOne as="h3" aria-hidden="true">
          {formatMessage({ id: 'SIGN_UP_FOR_THE_CHALLENGE' })}
        </HeadingOne>
        <BodyOne
          paddingTop={{ _: 'one', tablet: 'oneAndHalf' }}
          paddingBottom={{ _: 'one', tablet: 'oneAndHalf' }}
        >
          {formatMessage({ id: 'CHALLENGE_SENSITIVE_DATA' })}
        </BodyOne>
        <BodyOne paddingBottom="threeQuarters">
          {formatMessage({
            id: 'CHALLENGE_USING_DATA_TO',
          })}
        </BodyOne>
        <StyledUL>
          <BodyOne as="li">
            {formatMessage({
              id: 'COMPLETE_ACTIVITIES_SHORT',
            })}
          </BodyOne>
          {isSoloChallenge ? (
            <BodyOne as="li">
              {formatMessage({
                id: 'SHARE_CHALLENGE_PROGRESS_WITH_EMPLOYER',
              })}
            </BodyOne>
          ) : (
            <>
              <BodyOne as="li">
                {formatMessage({
                  id: 'SHARE_CHALLENGE_PROGRESS',
                })}
              </BodyOne>
              <BodyOne as="li">
                {formatMessage({
                  id: 'RANK_SCORE',
                })}
              </BodyOne>
              <BodyOne as="li">
                {formatMessage({
                  id: 'DETERMINE_CHALLENGE_WINNERS',
                })}
              </BodyOne>
            </>
          )}
        </StyledUL>
        <Formik
          initialValues={{
            confirmedPrivacyPolicy: false,
          }}
          onSubmit={() => {
            handleSubmitAnalytics(false);
            handleSignUpLogic();
          }}
          validate={(values) => {
            const errors: { confirmedPrivacyPolicy?: string } = {};
            if (values.confirmedPrivacyPolicy === false) {
              errors.confirmedPrivacyPolicy = formatMessage({
                id: 'ACCEPT_BEFORE_CONTINUE',
              });
              handleSubmitAnalytics(true);
            }
            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors }) => (
            <Form>
              <Label
                display="flex"
                marginTop={{ _: 'two', tablet: 'two' }}
                marginBottom={{ _: 'two', tablet: 'two' }}
              >
                <Field
                  as={Checkbox}
                  type="checkbox"
                  name="confirmedPrivacyPolicy"
                  marginRight="one"
                  error={errors.confirmedPrivacyPolicy}
                />
                <span>
                  {formatMessage(
                    {
                      id: 'CHECK_TO_CONFIRM_PRIVACY_POLICY',
                    },
                    {
                      a: PrivacyPolicyLink,
                    },
                  )}
                </span>
              </Label>
              {errors.confirmedPrivacyPolicy && (
                <ErrorMessage
                  display="flex"
                  alignItems="center"
                  marginBottom="two"
                >
                  <ChallengeIcon iconType={ICON_TYPES.ALERT} />
                  <ErrorMessage as="span" marginLeft="one">
                    {errors.confirmedPrivacyPolicy}
                  </ErrorMessage>
                </ErrorMessage>
              )}
              <PrimaryButton
                type="submit"
                width="100%"
                isLoading={signUpForChallengeMutation.isLoading}
              >
                {formatMessage({ id: 'CONTINUE' })}
              </PrimaryButton>
            </Form>
          )}
        </Formik>
        <BodyOne as="p" marginTop="oneAndHalf">
          {formatMessage(
            {
              id: 'LEARN_MORE_ABOUT_PRIVACY_POLICY',
            },
            {
              a: PrivacyPolicyLink,
            },
          )}
        </BodyOne>
      </Box>
    </>
  );
};
