import { func, object, optional, string, Struct } from 'superstruct';

export interface User {
  id: string;
  employer_group_id: string;
  tenant_id: string;
}

export interface SendAnalyticsPageViewParams {
  screen_name?: string;
  path?: string;
  [key: string]: string | unknown;
}

export const analyticsConfigSchema = object({
  GAPropertyId: optional(string()),
  segmentKey: optional(string()),
  onIdentify: optional(func() as unknown as Struct<(user: User) => void, null>),
  onTrackEvent: optional(
    func() as unknown as Struct<
      (eventName: string, props: Record<string, any>) => void,
      null
    >,
  ),
  onTrackPageViewEvent: optional(
    func() as unknown as Struct<
      (screen_name: string, props: unknown) => void,
      null
    >,
  ),
});
