import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE =
  'admin_get_employee_schema';

interface EmployeeSchema {
  groupId: string;
  userId?: string;
  benefitClassId?: string;
}

export const adminGetEmployeeSchema = ({
  groupId,
  userId,
  benefitClassId,
}: EmployeeSchema) =>
  SocketAsFetch.fetch({
    message_type: ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE,
    info: {
      benefit_class_id: benefitClassId,
      group_id: groupId,
      user_id: userId,
    },
  });
