import React from 'react';
import {
  Card,
  useClickableCard,
  StackLayout,
  StackItem,
  Button,
  useThemeStrings,
} from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { type WidgetLink, WidgetType } from '@leagueplatform/masonry-api';
import { NotificationBannerContent } from './notification-banner-content';
import { useDismissibleAction } from './use-dismiss-action-hook';

export interface NotificationBannerWidgetProps {
  body: string;
  dismissibleLink?: WidgetLink;
  heading: string;
  image?: string;
  imageAltText?: string;
  onCardClick?: (e: React.MouseEvent) => void;
  onDismiss?: () => void;
  url?: string;
}

export type NotificationBannerWSNode = MasonryEngineNode<
  WidgetType.NOTIFICATION_BANNER,
  NotificationBannerWidgetProps
>;

export const NotificationBannerWidget = ({
  body,
  dismissibleLink,
  heading,
  image,
  imageAltText,
  onCardClick,
  onDismiss: onDismissAnalytics,
  url,
}: NotificationBannerWidgetProps) => {
  const strings = useThemeStrings();

  const isClickable = !!(url || onCardClick);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const { dismissed, onDismiss } = useDismissibleAction(
    dismissibleLink,
    onDismissAnalytics,
  );

  if (dismissed) return null;

  return (
    <Card.Elevated>
      <StackLayout orientation="horizontal" verticalAlignment="stretch">
        {isClickable ? (
          <Card.ClickArea
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: '$one',
              padding: '$threeQuarters',
              paddingRight: '$half',
            }}
            ref={clickAreaRef}
            onCardMouseUp={onCardMouseUp}
            onCardMouseDown={onCardMouseDown}
          >
            <NotificationBannerContent
              body={body}
              heading={heading}
              image={image}
              imageAltText={imageAltText}
              onCardClick={onCardClick}
              ref={primaryActionRef}
              url={url}
            />
          </Card.ClickArea>
        ) : (
          <StackLayout
            orientation="horizontal"
            spacing="$one"
            verticalAlignment="center"
            css={{
              padding: '$threeQuarters',
              paddingRight: '$half',
            }}
          >
            <NotificationBannerContent
              body={body}
              heading={heading}
              image={image}
              imageAltText={imageAltText}
            />
          </StackLayout>
        )}

        {dismissibleLink && (
          <StackItem
            verticalAlignment="top"
            css={{ padding: '$threeQuarters', paddingLeft: '$half' }}
          >
            <Button
              hideLabel
              icon="tinyClose"
              onClick={onDismiss}
              priority="tertiary"
              quiet
              size="small"
            >
              {strings.dismiss}
            </Button>
          </StackItem>
        )}
      </StackLayout>
    </Card.Elevated>
  );
};
