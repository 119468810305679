import React from 'react';
import { Link, useHistory } from '@leagueplatform/routing';
import { Flex } from '@leagueplatform/genesis-commons';
import { ErrorState } from '../error-state/error-state.component';
import { BackButtonArrow } from '../back-button-arrow/back-button-arrow.view';

interface RouteProps {
  route?: string;
}

export const FullPageError = ({ route }: RouteProps) => {
  const history = useHistory();
  return (
    <Flex
      flexDirection={{ _: 'column', phone: 'row' }}
      height="100%"
      justifyContent={{ _: 'flex-start', phone: 'center' }}
      padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
    >
      <BackButtonArrow
        as={route && Link}
        justifyContent="initial"
        marginRight="quarter"
        marginY="half"
        onClick={route ? null : history.goBack}
        to={route}
        size="three"
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        paddingX={{ _: 'none', phone: 'five' }}
      >
        <ErrorState />
      </Flex>
    </Flex>
  );
};
