import React, { forwardRef } from 'react';
import * as CSS from 'csstype';
import css from '@styled-system/css';
import { trueOrUndefined } from '../../utils/trueOrUndefined';
import { Flex, FlexProps } from '../../Flex';
import { useTheme, GenesisTheme, SystemPropType } from '../../theme';
import { Spinner } from '../../Spinner';

export interface ButtonProps
  extends FlexProps,
    Partial<Omit<HTMLButtonElement, 'children'>> {
  /**
   * @deprecated The position prop of Genesis Button components cannot be overridden directly to ensure their focus states render properly
   * @see Genesis Storybook documentation for examples of how to manipulate the position of a Button component
   */
  position?: CSS.PositionProperty;
}

/**
 * @deprecated  use `Button` from `genesis-core` instead
 */

export const BaseButton: React.FunctionComponent<ButtonProps> = forwardRef(
  (
    {
      tabIndex = 0,
      paddingY = 'threeQuarters',
      borderWidth = 'thin',
      isLoading = false,
      children,
      disabled,
      as = 'button',
      ...props
    }: ButtonProps,
    ref
  ) => {
    const theme: GenesisTheme = useTheme();

    const borderWidthValue = theme.borderWidths[borderWidth];
    let paddingYValue: SystemPropType<String | Number> = paddingY;
    // none of this magic works for responsive arrays or object prop values
    if (typeof paddingY === 'string') {
      const py = theme.space[paddingY];
      const bw = typeof borderWidth === 'string' ? borderWidthValue : 0;
      paddingYValue = py - bw;
    }

    const focusOutlineOffset = `-${borderWidthValue + 2}px`;

    return (
      <Flex
        as={as}
        display="inline-flex"
        alignItems="center"
        backgroundColor="tertiary.background.default"
        borderColor="transparent"
        borderRadius="button"
        borderStyle="solid"
        borderWidth={borderWidth}
        fontSize="body1"
        fontWeight="medium"
        justifyContent="center"
        paddingX="two"
        tabIndex={tabIndex}
        ref={ref}
        disabled={trueOrUndefined(disabled || isLoading)}
        css={css({
          paddingY: paddingYValue,
          position: props.position || 'relative',
          '::before': {
            content: '""',
            position: 'absolute',
            top: focusOutlineOffset,
            left: focusOutlineOffset,
            right: focusOutlineOffset,
            bottom: focusOutlineOffset,
            borderRadius: props.borderRadius || 'button',
          },
          ':hover': {
            cursor: 'pointer',
            borderColor: 'transparent',
          },
          ':focus': {
            outline: 0,
          },
          ':focus::before': {
            boxShadow: 'focusOutline',
          },
          ':disabled,:disabled:hover': {
            cursor: 'not-allowed',
            ':active': {
              boxShadow: 'none',
            },
            ...(isLoading
              ? {}
              : {
                  backgroundColor: 'interactive.background.disabled',
                  borderColor: 'interactive.border.disabled',
                  color: 'interactive.action.subdued',
                }),
          },
        })}
        {...props}
      >
        <Spinner isLoading={isLoading}>{children}</Spinner>
      </Flex>
    );
  }
);

BaseButton.displayName = 'BaseButton';
