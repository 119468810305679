import { MultipleChallengesDeserialisedData } from '@leagueplatform/health-journey-api';
import { ChallengesHealthProgramsCardData } from 'types/health-programs-response-data.type';

export const formatChallengesForProgramCards = (
  challengesData: MultipleChallengesDeserialisedData,
): ChallengesHealthProgramsCardData[] => {
  // With the move to REST API the challenge programs come back in the above format.
  // This differs than what will come back from the existing WS connections (for example, New & Noteworthy)
  // So we format the data to make it consistent for use in the cards
  const formattedChallenges = challengesData.data?.map((challenge) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      id: program_id,
      title: name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      headerImageUrl: image_url = '',
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      startDate: start_date,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      endDate: end_date,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      startEnroll: start_enroll,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      endEnroll: end_enroll,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      challengeType: challenge_type,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      totalActivities: total_activities_count,
    } = challenge;

    // This is the same format as how challenges will come back from the WS endpoints
    return {
      program_id,
      name,
      image_url,
      challenge_info: {
        challenge_type,
        start_date,
        end_date,
        start_enroll,
        end_enroll,
      },
      type: challenge.type,
      total_activities_count,
    };
  });

  return formattedChallenges;
};
