/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React, { useState, useMemo, useEffect } from 'react';

// components
import {
  Flex,
  Box,
  HeadingTwo,
  HeadingFour,
  SubtitleTwo,
  BodyOne,
  CoverImage,
  genesisStyled,
  SubtleButton,
  Link,
} from '@leagueplatform/genesis-commons';
import {
  LoadingIndicator,
  HighlightBanner,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  splitAtNewlines,
  truncateWithEllipses,
  useDocumentTitle,
} from '@leagueplatform/web-common';
import { AchievementCalloutBanner } from '@leagueplatform/health-journey-common';

// utils
import { useIntl } from '@leagueplatform/locales';

// hooks
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

// other
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/rewards-achievements';
import {
  HEALTH_PROGRAM_STATUS,
  JOURNEY_MANAGER_ENABLED,
} from 'constants/health-program-status.constants';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useHealthProgramsProgramDetails } from './hooks/use-health-programs-program-details.hook';
import { HealthProgramCategory } from '../main/components/health-program-category/health-programs-category.component';
import { ProgramDetailsSidebar } from './parts/health-programs-program-details-sidebar.component';
import { ProgramDetailsBackButton } from './parts/program-detail-back-button.component';

import { useProgramDetailsAnalytics } from './hooks/use-program-details-analytics';

const ActivityContainer = genesisStyled(Box)(
  ({ theme: { space, breakpoints } }) => `
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: ${space.one}px;
  grid-column-gap: ${space.oneAndHalf}px;
  @media screen and (max-width: ${breakpoints.phone}) {
    grid-template-columns: auto;
  }
`,
);

export const HealthProgramDetails = () => {
  const {
    ready,
    hasError,
    image,
    name,
    description,
    goals,
    status,
    progressPercentages,
    availablePoints,
    programId,
    requestLeaveHealthProgram,
    requestStartHealthProgram,
    startProgramMaxEnrolmentModalContent,
    whatAreConnectedHealthProgramsLink,
    userHasSufficientWearablePermissions,
    programDataPoints,
    isUserDeviceConnectableProgram,
    syncableDevicesString,
    activityStatusCounts,
    achievementImage,
  } = useHealthProgramsProgramDetails();
  const { formatMessage } = useIntl();
  const { data: isAchievementsFeatureFlagOn } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);
  const { data: isJourneyManagerEnabled } = useFeatureFlagQuery(
    JOURNEY_MANAGER_ENABLED,
  );
  useDocumentTitle(formatMessage({ id: 'PROGRAM_DETAILS' }));

  const [isOpen, setIsOpen] = useState(false);

  const programDetailsAnalytics = useProgramDetailsAnalytics(
    name,
    programId,
    status,
  );

  useEffect(() => {
    if (ready) programDetailsAnalytics.viewProgramDetailsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  const visibleGoals = useMemo(() => {
    if (isOpen) return goals;
    return goals?.slice(0, 6);
  }, [goals, isOpen]);

  if (!ready) {
    return <LoadingIndicator />;
  }

  if (hasError) {
    return (
      <Flex
        padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
        flexDirection={{ _: 'column', phone: 'row' }}
        margin="0 auto"
        justifyContent="center"
      >
        <ProgramDetailsBackButton />
        <Box marginTop="three">
          <ErrorState />
        </Box>
      </Flex>
    );
  }

  const isGoalsGreaterThanSix = goals?.length > 6;

  // Static Assets
  const successStatus = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.SUCCESS_STATUS,
  );
  const enabledBadgePlaceholder = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.ENABLED_BADGE_PLACEHOLDER,
  );

  return (
    <Flex
      flexDirection={{ _: 'column', laptop: 'row' }}
      justifyContent="center"
      paddingTop={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
      gap={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
    >
      <Flex
        flexDirection={{ _: 'column', tablet: 'row' }}
        alignItems="flex-start"
        paddingX="two"
      >
        <ProgramDetailsBackButton />
        <Flex
          flexDirection="column"
          maxWidth={{ _: 416, phone: 620, desktop: 778 }}
        >
          {image && (
            <CoverImage
              src={image}
              alt={formatMessage({ id: 'HEALTH_PROGRAMS' })}
              borderRadius="medium"
              height={{ _: 233, phone: 377, desktop: 437 }}
              maxWidth="100%"
              backgroundColor="highlight.background.subdued"
              marginBottom="oneAndHalf"
              data-testid="program-details-cover-image"
            />
          )}
          <HeadingTwo
            as="h1"
            fontSize={{ _: 'initial', phone: 'heading1' }}
            marginBottom="three"
          >
            {name}
          </HeadingTwo>

          {isUserDeviceConnectableProgram && (
            <HighlightBanner
              title={formatMessage({ id: 'HEALTH_PROGRAMS_CONNECTED_PROGRAM' })}
              icon={successStatus}
              marginBottom="twoAndHalf"
            >
              {formatMessage(
                { id: 'HEALTH_PROGRAMS_SYNC_DEVICE' },
                { device: syncableDevicesString },
              )}{' '}
              <Link
                fontSize="subtitle2"
                href={whatAreConnectedHealthProgramsLink}
                target="_blank"
              >
                {formatMessage({ id: 'CONNECT_DEVICES_LEARN_MORE' })}
              </Link>
            </HighlightBanner>
          )}
          <HeadingFour as="h2" marginBottom="oneAndHalf">
            {formatMessage({ id: 'ABOUT_THIS_PROGRAM' })}
          </HeadingFour>
          {splitAtNewlines(description)?.map((partDescription) => (
            <BodyOne key={partDescription} marginBottom="oneAndHalf">
              {partDescription}
            </BodyOne>
          ))}
          {isAchievementsFeatureFlagOn && (
            <AchievementCalloutBanner
              image={achievementImage || enabledBadgePlaceholder}
              text={formatMessage({ id: 'UNLOCK_ACHIEVEMENTS' })}
            />
          )}
          {visibleGoals?.length > 0 &&
            status !== HEALTH_PROGRAM_STATUS.ACTIVE && (
              <>
                <HeadingFour as="h2" marginBottom="oneAndHalf" marginTop="two">
                  {formatMessage(
                    { id: 'ACTIVITIES_PLURALIZATION' },
                    { count: goals.length },
                  )}
                  {isJourneyManagerEnabled && ` (${goals.length})`}
                </HeadingFour>
                <ActivityContainer>
                  {visibleGoals.map(({ name: goalName, icon_url, goal_id }) => (
                    <Box
                      key={goal_id}
                      width={{ phone: '295px', desktop: '377px' }}
                    >
                      <HealthProgramCategory>
                        <CoverImage
                          src={icon_url}
                          role="presentation"
                          maxWidth="37px"
                          height="37px"
                          backgroundColor="highlight.background.subdued"
                          marginRight="half"
                        />
                        <SubtitleTwo as="h3" fontSize={{ phone: 'subtitle1' }}>
                          {truncateWithEllipses(goalName, 55)}
                        </SubtitleTwo>
                      </HealthProgramCategory>
                    </Box>
                  ))}
                </ActivityContainer>
                {isGoalsGreaterThanSix && !isOpen && (
                  <SubtleButton
                    marginTop="twoAndHalf"
                    width="166px"
                    alignSelf="center"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    {formatMessage({ id: 'SHOW_MORE' })}
                  </SubtleButton>
                )}
                {isOpen && (
                  <SubtleButton
                    marginTop="twoAndHalf"
                    width="166px"
                    alignSelf="center"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {formatMessage({ id: 'SHOW_LESS' })}
                  </SubtleButton>
                )}
              </>
            )}
        </Flex>
      </Flex>
      <Box
        width={{ _: 'auto', laptop: '357px' }}
        paddingRight={{ _: 'inherit', laptop: 'twoAndHalf' }}
      >
        <ProgramDetailsSidebar
          status={status}
          activityStatusCounts={activityStatusCounts}
          availablePoints={availablePoints}
          progressPercentages={progressPercentages}
          programName={name}
          programId={programId}
          requestLeaveHealthProgram={requestLeaveHealthProgram}
          requestStartHealthProgram={requestStartHealthProgram}
          startProgramMaxEnrolmentModalContent={
            startProgramMaxEnrolmentModalContent
          }
          isUserDeviceConnectableProgram={isUserDeviceConnectableProgram}
          userHasSufficientWearablePermissions={
            userHasSufficientWearablePermissions
          }
          programDataPoints={programDataPoints}
          hasError={hasError}
          ready={ready}
        />
      </Box>
    </Flex>
  );
};
