import React, { useState } from 'react';
import { Accordion } from '@leagueplatform/web-common-components';
import {
  ProgramBanner,
  ProgramRichText,
} from '@leagueplatform/health-journey-common';
import {
  Box,
  HeadingText,
  StackLayout,
  UtilityText,
  styled,
} from '@leagueplatform/genesis-core';
import { RewardProgramsProgress } from 'components/reward-programs-progress/reward-programs-progress.component';
import {
  NestedCampaign,
  USER_HEALTH_CAMPAIGN_STATUS,
} from '@leagueplatform/health-journey-api';
import { ActivityList } from 'components/activity-list/activity-list.component';
import { ProgramDate } from 'components/program-date/program-date.component';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  ANALYTICS_FIELDS,
  ANALYTICS_VALUES,
  GROUP_ACTIONS,
} from 'constants/analytics.constants';
import { REWARDS_PROGRAMS_ASSET_KEYS } from '../../asset-map';

const StyledAccordionTrigger = styled(Accordion.Trigger, {
  '&, > span': {
    padding: '$oneAndHalf',
    background: '$surfaceBackgroundSecondary',
    borderRadius: '$large',
    borderWidth: '$thin',
    borderStyle: 'solid',
    borderColor: '$onSurfaceBorderSubdued',
    color: '$onSurfaceTextPrimary',
    '> span': {
      flexGrow: 1,
    },
  },
  display: 'flex',
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
  textAlign: 'left',
  '.accordion-chevron': {
    transition: 'transform 0.25s ease',
  },
  '&[aria-expanded="true"] .accordion-chevron': {
    transform: 'rotate(180deg)',
  },
  '&[aria-expanded="true"]': {
    '&, > span': {
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  '&:hover': {
    '&, > span': {
      backgroundColor: '$surfaceBackgroundSecondary',
    },
  },
});

const StyledAccordionContent = styled(Accordion.Content, {
  backgroundColor: '$surfaceBackgroundPrimary',
  borderBottomLeftRadius: '$large',
  borderBottomRightRadius: '$large',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$onSurfaceBorderSubdued',
  borderTop: 'none',
  marginX: 1,
});

interface RewardProgramGroupProps {
  relatedCampaign: NestedCampaign;
  showDate?: boolean;
}

export const RewardProgramGroup = ({
  relatedCampaign,
  showDate,
}: RewardProgramGroupProps) => {
  const [groupOpen, setGroupOpen] = useState<Boolean>(false);

  const campaign = relatedCampaign.campaign.data;

  const { status, completedComponentAttributes, campaignProgress } =
    relatedCampaign;

  const { richTextDescription, name, startDate, endDate } = campaign;

  const showDescription = status !== USER_HEALTH_CAMPAIGN_STATUS.COMPLETED;

  const showCompletionBanner =
    status === USER_HEALTH_CAMPAIGN_STATUS.COMPLETED &&
    completedComponentAttributes;

  // const showEndedBanner =
  //   status === USER_HEALTH_CAMPAIGN_STATUS.ENDED && endedComponentAttributes;

  const showProgramDate =
    status === USER_HEALTH_CAMPAIGN_STATUS.ACTIVE && showDate;

  const handleAccordionChange = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      [ANALYTICS_FIELDS.PRODUCT_AREA]:
        ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
      [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
        ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
      [ANALYTICS_FIELDS.SCREEN_NAME]:
        ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_PROGRESS,
      [ANALYTICS_FIELDS.DETAIL]: !groupOpen
        ? GROUP_ACTIONS.EXPAND
        : GROUP_ACTIONS.COLLAPSE,
      [ANALYTICS_FIELDS.CAMPAIGN_NAME]: campaign.name,
      [ANALYTICS_FIELDS.CAMPAIGN_ID]: relatedCampaign.campaignId,
      [ANALYTICS_FIELDS.CAMPAIGN_TYPE]: campaign.type,
      [ANALYTICS_FIELDS.CAMPAIGN_STATUS]: relatedCampaign.status,
    });
    setGroupOpen((previous) => !previous);
  };

  return (
    <Accordion.Root collapsible type="single" onClick={handleAccordionChange}>
      <Accordion.Item value={name}>
        <Accordion.Header level="3" size="md">
          <StyledAccordionTrigger>
            <StackLayout spacing="$half">
              <HeadingText
                level="display"
                size="md"
                css={{ typography: '$headingThree' }}
              >
                {name}
              </HeadingText>
              {showDescription && (
                <UtilityText css={{ typography: '$bodyTwo', p: { margin: 0 } }}>
                  <ProgramRichText richText={richTextDescription} />
                </UtilityText>
              )}
              {showCompletionBanner && (
                <ProgramBanner
                  boldedText={{
                    iconProps: {
                      icon: handleStaticAsset(
                        REWARDS_PROGRAMS_ASSET_KEYS.REWARDS_PROGRAM_CHECK_MARK,
                      ) as string,
                      tint: '$successIcon',
                      size: 16,
                    },
                    text: (
                      <HeadingText
                        level="3"
                        size="md"
                        css={{
                          color: '$onSurfaceTextSuccess',
                          fontWeight: 600,
                        }}
                      >
                        {completedComponentAttributes.componentAttributes.title}
                      </HeadingText>
                    ),
                  }}
                  basicText={
                    <ProgramRichText
                      richText={
                        completedComponentAttributes.componentAttributes
                          ?.richText
                      }
                    />
                  }
                  css={{
                    width: 'calc(100% + $one)',
                    backgroundColor: '$successBackgroundSecondary',
                    borderRadius: '$medium',
                    padding: '$one',
                    textAlign: 'center',
                    typography: '$caption',
                  }}
                  horizontalAlignment="center"
                />
              )}
              {/* {showEndedBanner && (
              <ProgramBanner
                boldedText={{
                  iconProps: {
                    icon: endedComponentAttributes.componentAttributes?.iconUrl,
                    size: 14,
                    css: { filter: `saturate(0)` },
                  },
                  text: (
                    <HeadingText
                      level="display"
                      size="md"
                      css={{
                        typography: '$headingFour',
                      }}
                    >
                      {endedComponentAttributes.componentAttributes.title}
                    </HeadingText>
                  ),
                }}
                basicText={
                  <ProgramRichText
                    richText={
                      endedComponentAttributes.componentAttributes?.richText
                    }
                  />
                }
                css={{
                  width: '100%',
                  backgroundColor: '$successBackgroundSecondary',
                  borderRadius: '$medium',
                  padding: '$one',
                  textAlign: 'center',
                  typography: '$caption',
                }}
                horizontalAlignment="center"
              />
            )} */}

              <Box css={{ typography: '$bodyTwo', marginTop: '$half' }}>
                <RewardProgramsProgress
                  iconUrl={campaignProgress.iconUrl}
                  currentValue={campaignProgress.current}
                  maxValue={campaignProgress.threshold}
                  showProgress={false}
                />
              </Box>
              {showProgramDate && (
                <ProgramDate startDate={startDate} endDate={endDate} />
              )}
            </StackLayout>
          </StyledAccordionTrigger>
        </Accordion.Header>
        <StyledAccordionContent css={{ marginTop: '$minusQuarter' }}>
          <ActivityList compact relatedCampaign={relatedCampaign} />
        </StyledAccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  );
};
