export enum ManualInputMethodType {
  manual = 'manual',
  slider = 'slider',
  counter = 'counter',
}

export enum BannerTypes {
  subtle = 'subtle',
  primary = 'primary',
  secondary = 'secondary',
}

export enum StringInputMethodType {
  line = 'line',
  box = 'box',
}

export enum DateInputMethodType {
  datePick = 'date-pick',
  monthYear = 'month-year',
}

export enum AdditionalDateOptionUnit {
  day = 'day',
  month = 'month',
  year = 'year',
  week = 'week',
  noAnswer = 'no-answer',
}

export enum StyleHorizontalAlignmentType {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum StyleBorderRadiusType {
  pill = 'pill',
  medium = 'medium',
}

export enum StyleBackgroundColorType {
  decorativeBrandPrimaryPastel = 'decorative-brand-primary-pastel',
  decorativeBrandSecondaryPastel = 'decorative-brand-secondary-pastel',
  decorativeBrandTertiaryPastel = 'decorative-brand-tertiary-pastel',
}

export enum ProgressTrackingInputMethod {
  button = 'button',
  horizontalBar = 'horizontal-bar',
  circularBar = 'circular-bar',
}

export enum BiometricStatusType {
  inRange = 'in-range',
  outOfRange = 'out-of-range',
  inconclusive = 'inconclusive',
  noDataAvailable = 'no-data-available',
}

export enum FreeTextInputType {
  string = 'string',
  integer = 'integer',
}

export enum WeightUnits {
  pounds = 'lbs', // to match what the BE is sending
  kilogram = 'kg',
}

export enum HeightUnits {
  inches = 'inches', // to match what the BE is sending
  centimeter = 'cm',
}

export enum DateTimeInputDisplay {
  full = 'full',
  dateOnly = 'date-only',
  timeOnly = 'time-only',
}
