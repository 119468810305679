import * as React from 'react';
import {
  Grid as StyledGrid,
  GridProps as StyledGridProps,
} from '../primitives/Grid';
import { useChart } from '../providers/chart-data-provider';
import getScaleStep from '../utils/get-scale-step';

export type GridProps = Omit<
  StyledGridProps,
  'width' | 'height' | 'xScale' | 'yScale' | 'strokeDasharray'
> & {
  // Makes the column grid lines appear **between** the plotted datums.
  centerColumns?: boolean;
};

/**
 * Applies apply chart context values to the Grid primitive.
 */
export function Grid({ centerColumns = false, ...props }: GridProps) {
  const { xMax, yMax, xScale, yScale } = useChart();
  const step = getScaleStep(xScale);

  return (
    <StyledGrid
      xScale={xScale}
      yScale={yScale}
      width={xMax}
      height={yMax}
      xOffset={centerColumns ? step / 2 : 0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
