import { boolean, object, optional, string } from 'superstruct';

export const careDiscoveryConfigSchema = object({
  showGetAssistanceNowSubheading: optional(boolean()),
  showProviderAvailability: optional(boolean()),
  masonryAppId: optional(
    object({
      left: string(),
      right: optional(string()),
    }),
  ),
});
