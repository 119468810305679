import React from 'react';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { ProgramDetailCard } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { YourRanking } from 'pages/challenge-landing-page/components/sidebar/components/your-ranking/your-ranking.component';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';

export const ProgressTracker = () => {
  const { formatMessage } = useIntl();
  const { data: challengeData } = useGetChallengeByIdData();
  // Trust that challenge data exists as parent components would not render without it
  const { challengeType, totalActivities, userChallenge } = challengeData!.data;
  const progress = userChallenge?.data.progress;
  // if completed activities aren't included, default to 0
  const completedActivities = progress?.completedActivities || 0;

  // if for some reason we don't have the total number of activities, don't show progress
  if (!totalActivities) {
    return null;
  }
  const isGroupChallenge = challengeType === 'group';

  const completionPercentage = Math.round(
    (completedActivities / totalActivities) * 100,
  );
  return (
    <ProgramDetailCard heading={formatMessage({ id: 'CHALLENGE_PROGRESS' })}>
      <StackLayout
        horizontalAlignment="stretch"
        spacing="$oneAndHalf"
        orientation={{
          '@mobileLandscape': 'horizontal',
          '@tablet': 'horizontal',
          '@initial': 'vertical',
        }}
      >
        <StackLayout spacing="$half" horizontalAlignment="stretch">
          {/* TODO: Extract this into a common component (Rewards Team @JLoganathan1) */}
          <StackLayout orientation="horizontal" spacing="$quarter">
            <UtilityText
              size="sm"
              emphasis="emphasized"
              css={{ color: '$onSurfaceTextSuccess' }}
            >
              {formatMessage(
                { id: 'X_PERCENT_COMPLETE' },
                { number: completionPercentage },
              )}
            </UtilityText>
            <UtilityText size="sm" emphasis="emphasized">
              •
            </UtilityText>
            <UtilityText size="sm" emphasis="subtle">
              {formatMessage(
                { id: 'X_OF_Y_ACTIVITIES' },
                { completed: completedActivities, total: totalActivities },
              )}
            </UtilityText>
          </StackLayout>
          <ProgressBar
            current={completedActivities}
            total={totalActivities}
            customBarFillColor="input.border.success"
          />
        </StackLayout>
        {isGroupChallenge && <YourRanking />}
      </StackLayout>
    </ProgramDetailCard>
  );
};
