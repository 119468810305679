import { useState, useEffect } from 'react';
import { EnableGenesisCoreInspector } from '../constants';

/**
 * A hook that returns the current state of the Genesis Core Inspector.
 *
 * @returns An object containing the `isEnabled` property, which indicates whether the Genesis Core Inspector is enabled.
 *
 * @example
 * ```tsx
 * const { isEnabled } = useGenesisCoreInspector();
 *
 * if (isEnabled) {
 *   // Do something when the Genesis Core Inspector is enabled.
 * }
 * ```
 */
export const useGenesisCoreInspector = () => {
  const [isEnabled, setIsEnabled] = useState(
    localStorage.getItem(EnableGenesisCoreInspector) === 'true',
  );

  useEffect(() => {
    localStorage.setItem(EnableGenesisCoreInspector, isEnabled.toString());
  }, [isEnabled]);

  const toggleInspector = () => {
    setIsEnabled(!isEnabled);
    window.location.reload();
  };

  return { isEnabled, toggleInspector };
};

export default useGenesisCoreInspector;
