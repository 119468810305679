import React, { FC } from 'react';
import { styled } from '../../theme';
import type { GDSCustomizableComponent } from '../../types';

export interface GDSCardImageProps extends GDSCustomizableComponent {
  image: string;
  imageAlt?: string;
}

const BaseImage = styled('img', {
  width: '100%',
  objectFit: 'cover',
});

export const CardImage: FC<GDSCardImageProps> = ({
  className,
  css,
  image,
  imageAlt = '',
  ...props
}: GDSCardImageProps) => (
  <BaseImage
    src={image}
    alt={imageAlt}
    css={css}
    className={['GDS-card-image', className].join(' ')}
    {...props}
  />
);
