import React, { useEffect } from 'react';
import { Input, Text, spaceInPx } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  margin-right: ${spaceInPx(2)};
`;

export const ModuleStringInput = ({
  id,
  placeholderText,
  onChangeHandler,
  setShowArrow,
  questionId,
  userSelections,
  isSkippable,
  ariadescribedby,
}) => {
  const userSelection = userSelections[questionId] || {};
  const {
    value: inputValue = '',
    error: { hasError = false, errorMsg = '' },
  } = userSelection;

  // show arrow if there is a user input
  useEffect(() => {
    setShowArrow(!!inputValue || isSkippable);
  }, [inputValue, setShowArrow, isSkippable]);

  return (
    <>
      <StyledInput
        aria-describedby={ariadescribedby}
        key={id}
        type="text"
        placeholder={placeholderText}
        value={inputValue}
        hasError={hasError}
        onChange={({ target: { value } }) => {
          onChangeHandler({ value });
        }}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
      {hasError && errorMsg && (
        <Text color="red" fontSize={2} mt={1} mb={0}>
          {errorMsg}
        </Text>
      )}
    </>
  );
};

ModuleStringInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  setShowArrow: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
  isSkippable: PropTypes.bool,
  ariadescribedby: PropTypes.string,
};

ModuleStringInput.defaultProps = {
  isSkippable: false,
  ariadescribedby: '',
};
