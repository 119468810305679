import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image, Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import CHECKMARK from 'assets/checkmark.svg';

export const DropdownItem = ({ setLanguage, isSelected, locale }) => {
  const { $tmap } = useIntl();

  const localeName = $tmap({
    en: 'LANGUAGE_SELECTOR_ENGLISH',
    fr: 'LANGUAGE_SELECTOR_FRENCH',
    'es-MX': 'LANGUAGE_SELECTOR_SPANISH',
  })(locale);

  return (
    <Box as="li" role="menuitem">
      <Flex
        as="button"
        href="#"
        lang={locale}
        fontSize="body2"
        lineHeight="body2"
        fontWeight="regular"
        color="onSurface.text.primary"
        padding="half"
        marginBottom="quarter"
        border="none"
        backgroundColor="transparent"
        width="100%"
        textAlign="left"
        hoverStyle={{
          textDecoration: 'none !important',
          backgroundColor: 'highlight.background.subdued',
          cursor: 'pointer',
        }}
        alignItems="center"
        onClick={setLanguage}
      >
        {localeName}
        {isSelected && (
          <Image src={CHECKMARK} alt="" height="9px" paddingLeft="half" />
        )}
      </Flex>
    </Box>
  );
};

DropdownItem.defaultProps = {
  isSelected: false,
};

DropdownItem.propTypes = {
  locale: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};
