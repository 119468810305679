import React, { useEffect } from 'react';
import { Link, useParams } from '@leagueplatform/routing';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  BackButtonArrow,
  FullPageError,
} from '@leagueplatform/web-common-components';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { isEnrollmentOpen } from 'utils/enrollment-period-status.util';
import { noopFunction } from 'constants/challenges.constants';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';
import { TLPHeading } from './components/tlp-heading/tlp-heading.component';
import { TLPSidebar } from './components/tlp-sidebar/tlp-sidebar.component';
import { TLPHighlights } from './components/tlp-highlights/tlp-highlights.component';
import { useGetTeamDataById } from './hooks/use-get-team-data-by-id.hook';
import { TLPSkeletonLoader } from './components/tlp-skeleton-loader/tlp-skeleton-loader.component';
import { TLP_MAX_WIDTH } from './constants/team-landing-page.constants';

export const TeamLandingPage = () => {
  const { isLoading, error, data: teamData } = useGetTeamDataById();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  useEffect(() => {
    if (teamData) {
      trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        screen_name: TLP_SCREEN_NAMES.TEAM_DETAILS,
        team_name: teamData.data?.name,
        team_id: teamData.data?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData]);
  if (isLoading) return <TLPSkeletonLoader />;

  if (error || !teamData?.data)
    return <FullPageError route={JOURNEY_ROUTES.programsHome()} />;

  const {
    id: teamId,
    name,
    avatarUrl,
    maxMembers,
    memberCount,
    challenge,
  } = teamData.data;
  const spotsAvailable = maxMembers - memberCount;

  const { endEnroll, maxTeamSize, startEnroll, status, title } = challenge.data;

  const enrollmentOpen = isEnrollmentOpen(
    startEnroll,
    endEnroll,
    defaultTimezone,
  );

  const userIsEnrolled = !!teamData.data.userChallenge?.data.id;
  const teamsEnabled = maxTeamSize > 0;
  const userIsOnCurrentTeam = !!teamData.data.user?.data.id;
  // TODO: move userCanJoinTeam logic to select method on query (https://everlong.atlassian.net/browse/CHAL-1854)
  const userCanJoinTeam =
    userIsEnrolled &&
    teamsEnabled &&
    // TODO: userIsOnCurrentTeam should be switched to a userIsOnTeam (any team) value
    !userIsOnCurrentTeam &&
    enrollmentOpen &&
    spotsAvailable > 0;
  const displayJoinTeamBtn =
    !userIsOnCurrentTeam && enrollmentOpen && spotsAvailable > 0;

  // Display error if someone tries to access the /team/{teamId} route directly (not via a valid state from CLP) when they shouldn't be able to
  if (!teamsEnabled)
    return <FullPageError route={JOURNEY_ROUTES.programsHome()} />;

  return (
    <Box backgroundColor="surface.background.secondary" height="100%">
      <Box
        as="section"
        marginX="auto"
        maxWidth={TLP_MAX_WIDTH}
        paddingY="twoAndHalf"
        paddingX={{ _: 'one', phone: 'oneAndHalf', tablet: 'twoAndHalf' }}
      >
        <BackButtonArrow
          as={Link}
          justifyContent="initial"
          marginRight="quarter"
          marginY="half"
          onClick={noopFunction}
          to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
          size="three"
        />
        <TLPHeading
          memberTotal={memberCount}
          spotsAvailable={spotsAvailable}
          teamIcon={avatarUrl}
          teamName={name}
          displayJoinTeamBtn={displayJoinTeamBtn}
          userCanJoinTeam={userCanJoinTeam}
          userIsOnCurrentTeam={userIsOnCurrentTeam}
          userIsEnrolled={userIsEnrolled}
          enrollmentOpen={enrollmentOpen}
        />
        <Flex
          flexDirection={{ _: 'column-reverse', tablet: 'row' }}
          justifyContent="space-between"
        >
          <TLPHighlights
            challengeTitle={title}
            teamId={teamId}
            userChallengeId={teamData.data.user?.data.id}
          />
          <TLPSidebar
            challengeName={title}
            teamName={name}
            challengeStatus={status}
            enrollmentOpen={enrollmentOpen}
            spotsAvailable={spotsAvailable}
            userIsOnCurrentTeam={userIsOnCurrentTeam}
          />
        </Flex>
      </Box>
    </Box>
  );
};
