import { useWalletDetail } from 'pages/wallet-detail/hooks/use-wallet-detail.hook';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';

export function useMedicalCard() {
  const { benefitCard } = useWalletDetail();
  const isMedicalBenefit = benefitCard?.product_type === 'medical';

  const { data: isContentPanelBenefitCardEnabled } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_CONTENT_PANEL_BENEFIT_CARD_ENABLED,
  );

  return {
    isMedicalBenefit,
    isMedicalCardEnabled: isMedicalBenefit && isContentPanelBenefitCardEnabled,
  };
}
