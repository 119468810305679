import { useContext } from 'react';
import { ThemeContext } from '../theme/GDSThemeProvider';

export const useTheme = () => {
  const theme = useContext(ThemeContext);

  if (theme === undefined) {
    throw new Error(
      'No theme found in context. Check that your component is nested within a GDSThemeProvider.',
    );
  }

  return theme;
};

export const useThemeRawValues = () => {
  const { rawValues } = useTheme();
  return rawValues;
};

export const useThemeIcons = () => {
  const { icons } = useTheme();
  return icons;
};

export const useThemeStrings = () => {
  const { strings } = useTheme();
  return strings;
};
