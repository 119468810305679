{
  "dragDescriptionKeyboard": "Apăsați pe Enter pentru a începe glisarea.",
  "dragDescriptionKeyboardAlt": "Apăsați pe Alt + Enter pentru a începe glisarea.",
  "dragDescriptionLongPress": "Apăsați lung pentru a începe glisarea.",
  "dragDescriptionTouch": "Atingeți de două ori pentru a începe să glisați.",
  "dragDescriptionVirtual": "Faceți clic pentru a începe glisarea.",
  "dragItem": "Glisați {itemText}",
  "dragSelectedItems": "Glisați {count, plural, one {# element selectat} other {# elemente selectate}}",
  "dragSelectedKeyboard": "Apăsați pe Enter pentru a glisa {count, plural, one {# element selectat} other {# elemente selectate}}.",
  "dragSelectedKeyboardAlt": "Apăsați pe Alt + Enter pentru a glisa {count, plural, one {# element selectat} other {# elemente selectate}}.",
  "dragSelectedLongPress": "Apăsați lung pentru a glisa {count, plural, one {# element selectat} other {# elemente selectate}}.",
  "dragStartedKeyboard": "A început glisarea. Apăsați pe Tab pentru a naviga la o țintă de fixare, apoi apăsați pe Enter pentru a fixa sau apăsați pe Escape pentru a anula glisarea.",
  "dragStartedTouch": "A început glisarea. Navigați la o țintă de fixare, apoi atingeți de două ori pentru a fixa.",
  "dragStartedVirtual": "A început glisarea. Navigați la o țintă de fixare, apoi faceți clic sau apăsați pe Enter pentru a fixa.",
  "dropCanceled": "Fixare anulată.",
  "dropComplete": "Fixare finalizată.",
  "dropDescriptionKeyboard": "Apăsați pe Enter pentru a fixa. Apăsați pe Escape pentru a anula glisarea.",
  "dropDescriptionTouch": "Atingeți de două ori pentru a fixa.",
  "dropDescriptionVirtual": "Faceți clic pentru a fixa.",
  "dropIndicator": "indicator de fixare",
  "dropOnItem": "Fixați pe {itemText}",
  "dropOnRoot": "Fixare pe",
  "endDragKeyboard": "Se glisează. Apăsați pe Enter pentru a anula glisarea.",
  "endDragTouch": "Se glisează. Atingeți de două ori pentru a anula glisarea.",
  "endDragVirtual": "Se glisează. Faceți clic pentru a anula glisarea.",
  "insertAfter": "Inserați după {itemText}",
  "insertBefore": "Inserați înainte de {itemText}",
  "insertBetween": "Inserați între {beforeItemText} și {afterItemText}"
}
