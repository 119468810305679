import { Box } from '@leagueplatform/genesis-commons';
import React from 'react';

export const TestComponent = ({
  content,
}: {
  content: string;
}): JSX.Element => {
  return (
    <Box
      backgroundColor="primary.background.default"
      color="primary.text.default"
      borderRadius="pill"
      display="inline-block"
      paddingLeft="half"
      paddingRight="half"
      paddingTop="quarter"
      paddingBottom="quarter"
    >
      {content}
    </Box>
  );
};
