import * as React from 'react';
import {
  StackLayout,
  StackItem,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { RewardPointsIcon } from '@leagueplatform/rewards-common';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';

export interface RewardProgramsProgressProps {
  currentValue: number;
  maxValue: number;
  showProgress: boolean;
  iconUrl?: string;
}

export const RewardProgramsProgress = ({
  iconUrl,
  currentValue,
  maxValue,
  showProgress,
}: RewardProgramsProgressProps) => {
  const { formatNumber, formatMessage } = useIntl();
  return (
    <StackLayout
      orientation="vertical"
      verticalAlignment="center"
      spacing="$half"
    >
      <StackItem>
        <StackLayout orientation="horizontal" spacing="$quarter">
          <RewardPointsIcon iconUrl={iconUrl} value={currentValue} />
          <UtilityText css={{ fontSize: 'inherit' }}>/</UtilityText>
          <UtilityText css={{ fontSize: 'inherit' }}>
            {formatNumber(maxValue)}
          </UtilityText>
          <UtilityText css={{ fontSize: 'inherit' }}>
            {formatMessage({ id: 'REWARD_POINTS_EARNED' })}
          </UtilityText>
        </StackLayout>
      </StackItem>

      {showProgress && (
        <StackItem css={{ width: '100%' }}>
          <ProgressBar
            customBarFillColor="success.border.default"
            customBarBackgroundColor="interactive.background.disabled"
            current={currentValue}
            total={maxValue}
          />
        </StackItem>
      )}
    </StackLayout>
  );
};
