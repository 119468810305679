import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useParams } from '@leagueplatform/routing';
import { TLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import {
  Box,
  HeadingFour,
  BodyOne,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { TeamInviteLinkModal } from '../../team-invite-link-modal/team-invite-link-modal.component';

interface InviteFriendsCTAProps {
  teamName: string;
}
export const InviteFriendsCTA = ({ teamName }: InviteFriendsCTAProps) => {
  const { formatMessage } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const { teamId } = useParams<{ teamId: string }>();

  const handleAnalyticsClick = () =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.TEAM_DETAILS,
      detail: TLP_DETAIL.INVITE,
      team_name: teamName,
      team_id: teamId,
    });

  const [displayTeamInviteLinkModal, setDisplayTeamInviteLinkModal] =
    useState(false);
  return (
    <>
      <Box
        backgroundColor="decorative.brand.primary.pastel"
        borderColor="onSurface.border.subdued"
        borderRadius="medium"
        borderWidth="thin"
        boxShadow="card"
        padding="oneAndHalf"
      >
        <HeadingFour as="h2">
          {formatMessage({ id: 'TEAM_INVITE_HEADING' })}
        </HeadingFour>
        <BodyOne marginTop="half" marginBottom="oneAndHalf">
          {formatMessage({ id: 'TEAM_INVITE_DESCRIPTION' })}
        </BodyOne>
        <PrimaryButton
          width="100%"
          onClick={() => {
            setDisplayTeamInviteLinkModal(true);
            handleAnalyticsClick();
          }}
        >
          {formatMessage({ id: 'INVITE' })}
        </PrimaryButton>
      </Box>
      <TeamInviteLinkModal
        isOpen={displayTeamInviteLinkModal}
        onDismiss={() => setDisplayTeamInviteLinkModal(false)}
      />
    </>
  );
};
