import { leagueFetch } from '@leagueplatform/league-fetch';
import { CARE_COLLECTIONS_PATH } from '../constants/care-collections.constants';
import { CareCollectionQueryResponse } from '../types/care-collections.types';

export const getCareCollection = async (
  careCollectionId: string,
): Promise<CareCollectionQueryResponse> => {
  const response = await leagueFetch(
    `${CARE_COLLECTIONS_PATH}/${careCollectionId}`,
  );

  return response.json();
};
