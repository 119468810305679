import { query, deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  HealthSourcesDeserialisedData,
  HealthSourcesQueryParameters,
  HealthSourcesResponseData,
} from './types/health-sources-data.type';

export const healthSourcesV1Path = 'v1/health-sources';

export async function getHealthSources(params: HealthSourcesQueryParameters) {
  const response = await leagueFetch(`${healthSourcesV1Path}?${query(params)}`);

  const responseJson: HealthSourcesResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getHealthSources error: ${response.status}`);
  }

  return deserialise(responseJson) as HealthSourcesDeserialisedData;
}
