import {
  Box,
  SkeletonRectangle,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { LoaderType } from '@leagueplatform/masonry-api';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import React from 'react';

export type HeroBannerSkeletonWSNode =
  MasonryEngineNode<LoaderType.HERO_BANNER>;

export const HeroBannerSkeleton = () => (
  <Box
    css={{
      height: '184px',
      '@tablet': {
        height: '232px',
      },
      '@mobileLandscape': {
        height: '232px',
      },
      '@laptop': {
        height: '351px',
      },
      '@desktop': {
        height: '351px',
      },
    }}
  >
    <StackLayout
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 'auto',
        paddingBlock: '$twoAndHalf',
        paddingInline: '$three',
        '@mobile': {
          paddingBlock: '$oneAndHalf',
          paddingInline: '$oneAndHalf',
        },
        backgroundColor: '$decorativeBrandPrimaryPastel',
      }}
    >
      <SkeletonRectangle
        height={{
          '@initial': '136px',
          '@laptop': '255px',
          '@desktop': '255px',
        }}
      />
    </StackLayout>
  </Box>
);
