import React from 'react';
import { useStaticAsset } from '@leagueplatform/asset-config';
import { Icon, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { REWARDS_COMMON_ASSET_KEYS } from '../../asset-map';

export interface PointsIconProps {
  subdued?: boolean;
  size?: string;
  altText?: string;
  value: number;
}

export const PointsIcon = ({
  subdued,
  size = 'threeQuarters',
  altText,
  value,
}: PointsIconProps) => {
  const pointsIcon = useStaticAsset(
    REWARDS_COMMON_ASSET_KEYS.REWARDS_POINTS,
  ) as string;

  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      spacing="$quarter"
      verticalAlignment="center"
    >
      <Icon
        size={size}
        icon={pointsIcon}
        css={{ filter: `saturate(${subdued ? 1 : 0})` }}
        label={
          altText || formatMessage({ id: 'X_LEAGUE_POINTS' }, { points: value })
        }
      />
      <UtilityText
        css={{
          color: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
        }}
        aria-hidden
      >
        {formatMessage({ id: 'X_LEAGUE_POINTS' }, { points: value })}
      </UtilityText>
    </StackLayout>
  );
};
