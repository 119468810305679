import * as React from 'react';
import {
  GDSCustomizableComponent,
  TextAction,
  Icon,
} from '@leagueplatform/genesis-core';

type PaginationLinkProps = {
  isEllipsis?: boolean;
  isIcon?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
} & GDSCustomizableComponent;

export const PaginationLink = ({
  children,
  isSelected,
  isEllipsis = false,
  isIcon,
  css,
  onClick,
  isDisabled,
}: React.PropsWithChildren<PaginationLinkProps>) => (
  <TextAction
    onClick={onClick}
    as={!isEllipsis ? 'button' : 'span'}
    aria-current={isSelected ? 'page' : undefined}
    css={{
      color: isEllipsis || isDisabled ? '$interactiveIconDisabled' : 'inherit',
      minWidth: '$oneAndHalf',
      height: '$oneAndHalf',
      padding: isIcon ? '$none' : '$quarter',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '$thin',
      borderStyle: 'solid',
      borderColor: 'transparent',
      lineHeight: 'normal',
      '&:hover': {
        textDecoration: 'none',
      },
      '&[disabled]': {
        cursor: 'auto',
      },
      ...(isEllipsis && { cursor: 'default' }),
      ...css,
    }}
    {...(!isEllipsis && { disabled: isDisabled || isSelected })}
  >
    {children}
  </TextAction>
);

type PaginationIconLinkProps = {
  icon: 'interfaceChevronLeft' | 'interfaceChevronRight';
} & Omit<PaginationLinkProps, 'isIcon'>;

export const PaginationIconLink = ({
  children,
  icon,
  ...props
}: React.PropsWithChildren<PaginationIconLinkProps>) => (
  <PaginationLink {...props} isEllipsis={false} isIcon>
    <Icon
      css={{
        width: 'calc($oneAndHalf - $borderWidths$thin * 2)',
        height: 'calc($oneAndHalf - $borderWidths$thin * 2)',
        borderWidth: '$thin',
      }}
      icon={icon}
      aria-hidden
    />
    {children}
  </PaginationLink>
);

export const Ellipsis = () => (
  <PaginationLink isEllipsis>&#8230;</PaginationLink>
);
