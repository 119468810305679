export const DetailLargeVisualCardButtonClick = 'text_with_large_image';
export const DetailSmallVisualCardButtonClick = 'text_with_small_image';
export const DetailXSVisualCardButtonClick = 'text_with_xsmall_image';
export const DetailNotificationBannerButtonClick = 'notification_banner';
export const DetailNotificationBannerDismissClick =
  'notification_banner_dismiss_button';
export const DetailNotificationCardButtonClick = 'notification_card';
export const DetailProgressRingButtonClick = 'progress_ring';
export const DetailProgressBarButtonClick = 'progress_bar';
export const DetailHeroBannerButtonClick = 'welcome_banner_primary_button';
export const DetailTextWithCTAButtonClick = 'text_with_button_primary_button';
export const Detail2UpCardWithCTAButtonClick = '2_up_card';
export const DetailMetricCardButtonClick = 'metric_card';
export const DetailCarouselScrollRight = 'scroll carousel right';
export const DetailCarouselScrollLeft = 'scroll carousel left';
export const DetailCarouselHeaderButtonClick = 'carousel_header_button';
export const DetailCarouselHeaderSelectItem = 'select item from carousel';
export const DetailStackSelectItem = 'select item from stack';
