import { useMutation, useQueryClient } from 'react-query';
import {
  CHALLENGE_PATCH_OPERATIONS,
  patchHighlightReaction,
  CHALLENGE_HIGHLIGHT_EVENT_TYPES,
  CHALLENGE_HIGHLIGHTS_PATH,
} from '@leagueplatform/health-journey-api';

export const useReactToHighlight = (
  reactionId: string,
  highlightEventType: CHALLENGE_HIGHLIGHT_EVENT_TYPES,
  originalLikedState: boolean,
  debouncedIsLiked: boolean,
  setIsLiked: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const queryClient = useQueryClient();
  const operationType = debouncedIsLiked
    ? CHALLENGE_PATCH_OPERATIONS.ADD
    : CHALLENGE_PATCH_OPERATIONS.REMOVE;

  const { mutate } = useMutation(
    () => patchHighlightReaction(reactionId, operationType),
    {
      onSuccess: () => {
        // Invalidate highlights feed to refetch latest feed with updated likes data
        queryClient.invalidateQueries([CHALLENGE_HIGHLIGHTS_PATH]);
      },
      onError: () => {
        // If error, set liked state back to the original state
        setIsLiked(originalLikedState);
      },
    },
  );

  return mutate;
};
