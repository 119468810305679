import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

const ENABLE_METRICS_IMPROVEMENTS = 'release_about-me_metrics-improvement';

export const useIsMetricsImprovementsEnabled = () => {
  const { isLoading, data: isMetricsImprovementsEnabled } = useFeatureFlagQuery(
    ENABLE_METRICS_IMPROVEMENTS,
  );

  return {
    isLoading,
    isMetricsImprovementsEnabled,
  };
};
