// Hook taken from here:
// https://brianm.me/posts/react-router-search-params/#use-search-params-custom-hook

import { useLocation } from '@leagueplatform/routing';
import { useMemo } from 'react';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search],
  );
}
