import { useParams } from '@leagueplatform/routing';
import { useGetUserProfile } from '@leagueplatform/health-journey-common';
import { useGetUserHealthCampaignById } from './use-get-user-health-campaign-by-id.hook';

export function useRewardProgramLandingPage() {
  const { userHealthCampaignId } = useParams<{
    userHealthCampaignId: string;
  }>();
  const { isLoading, isFetching, data, isError } = useGetUserHealthCampaignById(
    userHealthCampaignId,
    true,
    true,
  );

  const { isLoading: isLoadingProfile, isError: isProfileError } =
    useGetUserProfile();

  return {
    isError: isError || isProfileError,
    isLoading: isFetching || isLoading || isLoadingProfile,
    rewardProgram: data?.data,
  };
}
