import React, { useRef, useEffect, useState, useCallback } from 'react';

export const useClickableCard = () => {
  const primaryActionRef = useRef<any>(null);
  const clickAreaRef = useRef<HTMLDivElement>(null);
  const [up, setUp] = useState<number>(0);
  const [down, setDown] = useState<number>(0);

  // Inspired by redundant click handling on Heydon Pickering's Inclusive Card Components:
  // https://inclusive-components.design/cards/#theredundantclickevent

  const onCardMouseDown = useCallback(() => setDown(+new Date()), []);
  const onCardMouseUp = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    return setUp(+new Date());
  }, []);

  useEffect(() => {
    const clickDuration = up - down;
    const isWithinClickThreshold = clickDuration > 0 && clickDuration < 200;
    const isPrimaryActionClicked =
      primaryActionRef.current === document.activeElement;

    if (
      primaryActionRef.current &&
      isWithinClickThreshold &&
      !isPrimaryActionClicked
    ) {
      primaryActionRef.current.click();
    }
  }, [up, down]);

  return {
    primaryActionRef,
    clickAreaRef,
    onCardMouseUp,
    onCardMouseDown,
  };
};
