import React from 'react';
import { Image } from '@leagueplatform/genesis-commons';
import { Box, ParagraphText } from '@leagueplatform/genesis-core';
import { ProgramRichText } from '../program-rich-text/program-rich-text.component';

interface AchievementCalloutBannerProps {
  image: string;
  text: string;
  badgeSize?: string;
}

export const AchievementCalloutBanner = ({
  image,
  text,
  badgeSize = '48px',
}: AchievementCalloutBannerProps) => (
  <Box css={{ display: 'flex', alignItems: 'center' }}>
    <Image src={image} height={badgeSize} alt="" />
    <ParagraphText size="sm" css={{ marginLeft: '$one', fontWeight: 500 }}>
      <ProgramRichText richText={text} />
    </ParagraphText>
  </Box>
);
