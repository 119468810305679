import fontawesome from '@fortawesome/fontawesome';

import faBars from '@fortawesome/fontawesome-free-solid/faBars';
import faDownload from '@fortawesome/fontawesome-free-solid/faDownload';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faMinus from '@fortawesome/fontawesome-free-solid/faMinus';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faChevronUp from '@fortawesome/fontawesome-free-solid/faChevronUp';
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';
import faChevronLeft from '@fortawesome/fontawesome-free-solid/faChevronLeft';
import faChevronRight from '@fortawesome/fontawesome-free-solid/faChevronRight';
import faTrashAlt from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import faExclamationTriangle from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faFile from '@fortawesome/fontawesome-free-solid/faFile';
import faTag from '@fortawesome/fontawesome-free-solid/faTag';
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft';
import faArrowRight from '@fortawesome/fontawesome-free-solid/faArrowRight';
import faHistory from '@fortawesome/fontawesome-free-solid/faHistory';
import faUndoAlt from '@fortawesome/fontawesome-free-solid/faUndoAlt';
import faRedoAlt from '@fortawesome/fontawesome-free-solid/faRedoAlt';
import faEdit from '@fortawesome/fontawesome-free-solid/faEdit';
import faFilePdf from '@fortawesome/fontawesome-free-solid/faFilePdf';
import faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import faCircle from '@fortawesome/fontawesome-free-solid/faCircle';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faQuestion from '@fortawesome/fontawesome-free-solid/faQuestion';
import faQuestionCircle from '@fortawesome/fontawesome-free-solid/faQuestionCircle';

/* Include fontawesome icons */
fontawesome.library.add({
  faBars,
  faDownload,
  faFile,
  faFilePdf,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTrashAlt,
  faExclamationTriangle,
  faExclamationCircle,
  faCircle,
  faCheck,
  faTimesCircle,
  faCheckCircle,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faTag,
  faTimes,
  faPlus,
  faMinus,
  faInfoCircle,
  faArrowLeft,
  faArrowRight,
  faHistory,
  faUndoAlt,
  faRedoAlt,
  faEdit,
  faQuestion,
  faQuestionCircle,
});
