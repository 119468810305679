export const ACTIVITY_CONTENT_BASE_WIDTH = 484;

export const EASING = Object.freeze({
  IN_OUT_QUAD: `cubic-bezier(0.45, 0, 0.55, 1)`,
});

const baseDurationMs = 150;

export const TRANSITION_DURATION_MS = Object.freeze({
  SHORT: baseDurationMs,
  MEDIUM: baseDurationMs * 2,
  LONG: baseDurationMs * 3,
  XLONG: baseDurationMs * 4,
});

const { MEDIUM, XLONG } = TRANSITION_DURATION_MS;
const { IN_OUT_QUAD } = EASING;

export const TRANSITIONS = Object.freeze({
  PAGE: `${XLONG}ms ${IN_OUT_QUAD}`,
  HALF_PAGE: `${MEDIUM}ms ${IN_OUT_QUAD}`,
});

export enum MultiStepFooterAreas {
  Top = 'top',
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Bottom = 'bottom',
}
