{
  "dragDescriptionKeyboard": "Nospiediet Enter, lai sāktu vilkšanu.",
  "dragDescriptionKeyboardAlt": "Nospiediet taustiņu kombināciju Alt+Enter, lai sāktu vilkšanu.",
  "dragDescriptionLongPress": "Turiet nospiestu, lai sāktu vilkšanu.",
  "dragDescriptionTouch": "Veiciet dubultskārienu, lai sāktu vilkšanu.",
  "dragDescriptionVirtual": "Noklikšķiniet, lai sāktu vilkšanu.",
  "dragItem": "Velciet {itemText}",
  "dragSelectedItems": "Velciet {count, plural, one {# atlasīto vienumu} other {# atlasītos vienumus}}",
  "dragSelectedKeyboard": "Nospiediet taustiņu Enter, lai vilktu {count, plural, one {# atlasīto vienumu} other {# atlasītos vienumus}}.",
  "dragSelectedKeyboardAlt": "Nospiediet taustiņu kombināciju Alt+Enter, lai vilktu {count, plural, one {# atlasīto vienumu} other {# atlasītos vienumus}}.",
  "dragSelectedLongPress": "Turiet nospiestu, lai vilktu {count, plural, one {# atlasīto vienumu} other {# atlasītos vienumus}}.",
  "dragStartedKeyboard": "Uzsākta vilkšana. Nospiediet taustiņu Tab, lai pārietu uz nomešanas mērķi, pēc tam nospiediet Enter, lai nomestu, vai nospiediet Escape, lai atceltu.",
  "dragStartedTouch": "Uzsākta vilkšana. Pārejiet uz nomešanas mērķi, pēc tam veiciet dubultskārienu, lai nomestu.",
  "dragStartedVirtual": "Uzsākta vilkšana. Pārejiet uz nomešanas mērķi, pēc tam nospiediet Enter, lai nomestu.",
  "dropCanceled": "Nomešana atcelta.",
  "dropComplete": "Nomešana pabeigta.",
  "dropDescriptionKeyboard": "Nospiediet Enter, lai nomestu. Nospiediet Escape, lai atceltu vilkšanu.",
  "dropDescriptionTouch": "Veiciet dubultskārienu, lai nomestu.",
  "dropDescriptionVirtual": "Noklikšķiniet, lai nomestu.",
  "dropIndicator": "nomešanas indikators",
  "dropOnItem": "Nometiet uz {itemText}",
  "dropOnRoot": "Nometiet uz",
  "endDragKeyboard": "Notiek vilkšana. Nospiediet Enter, lai atceltu vilkšanu.",
  "endDragTouch": "Notiek vilkšana. Veiciet dubultskārienu, lai atceltu vilkšanu.",
  "endDragVirtual": "Notiek vilkšana. Noklikšķiniet, lai atceltu vilkšanu.",
  "insertAfter": "Ievietojiet pēc {itemText}",
  "insertBefore": "Ievietojiet pirms {itemText}",
  "insertBetween": "Ievietojiet starp {beforeItemText} un {afterItemText}"
}
