import { useQuery, UseQueryOptions } from 'react-query';
import {
  getProviderServices,
  GetProviderServicesResponse,
  GET_PROVIDER_SERVICES_MESSAGE_TYPE,
} from '@leagueplatform/wallet-api';

export function useProviderServices<TSelect = GetProviderServicesResponse>(
  userId: string | undefined,
  benefitId: string | undefined,
  options?: UseQueryOptions<GetProviderServicesResponse, unknown, TSelect>,
) {
  return useQuery<GetProviderServicesResponse, unknown, TSelect>(
    [GET_PROVIDER_SERVICES_MESSAGE_TYPE, userId, benefitId],
    () => getProviderServices(userId, benefitId),
    options,
  );
}
