import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colour } from '../../theme';
import { Image } from '../image/image.view';
import { Box, Flex } from 'ui-kit/components/primitives.view';

const Card = styled(Flex)`
  background-color: ${colour('white')};
  border-radius: 4px;
  min-width: 420px;
  max-width: 782px;

  .image-border {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

const LeftColumn = styled(Box)`
  min-width: 18%;
`;

const RightColumn = styled(Box)`
  padding: 40px 80px;
  width: 100%;
`;

export const ImageCard = ({ image, children, className }) => {
  return (
    <Card className={className}>
      {image && (
        <LeftColumn>
          <Image url={image} cover fit className="image-border" />
        </LeftColumn>
      )}
      <RightColumn>{children}</RightColumn>
    </Card>
  );
};

ImageCard.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ImageCard.defaultProps = {
  image: null,
  className: '',
};
