import { mapContentfulURLToModules } from './map-contentful-url-to-modules.utils';

export function getSanitizedURL(redirectUrl) {
  if (!redirectUrl) {
    return null;
  }
  const moduleUrl = mapContentfulURLToModules(redirectUrl);

  if (moduleUrl) {
    return moduleUrl;
  }

  return redirectUrl.replace(/^\/app\//, '/');
}
