import { useCallback } from 'react';
import { useActiveBreakpoint } from './use-active-breakpoint';
import type { Prefixed } from '../theme/stitches.config';
import type {
  GDSBreakpoint,
  GDSResponsivePropByBreakpoint,
  GDSResponsiveProp,
} from '../types';

function isResponsiveProp(arg: any): arg is GDSResponsivePropByBreakpoint<any> {
  return arg?.['@initial'] !== undefined;
}

function getResponsivePropValue<T>(
  prop: GDSResponsiveProp<T>,
  activeBreakpoint: GDSBreakpoint,
): T {
  if (!isResponsiveProp(prop)) {
    return prop;
  }

  const initialBreakpointValue = prop['@initial'] as unknown as T;

  return (
    (prop?.[`@${activeBreakpoint}`] as unknown as T) ?? initialBreakpointValue
  );
}

export const useResponsiveProp = <T>(prop: GDSResponsiveProp<T>): T => {
  const activeBreakpoint = useActiveBreakpoint();

  return getResponsivePropValue<T>(prop, activeBreakpoint as GDSBreakpoint);
};

export const useResponsivePropCallback = () => {
  const activeBreakpoint = useActiveBreakpoint();

  return useCallback(
    (prop) =>
      getResponsivePropValue<any>(prop, activeBreakpoint as GDSBreakpoint),
    [activeBreakpoint],
  );
};

// Map object of values to an object of different values
export const mapResponsiveProp = <T>(
  prop: GDSResponsiveProp<T>,
  valueMap: object,
) => {
  if (!valueMap) return prop;

  if (!isResponsiveProp(prop)) {
    return valueMap[prop as keyof typeof valueMap];
  }

  const result: any = {};
  Object.keys(prop).forEach((key) => {
    const originalValue = prop[key as Prefixed<'@', GDSBreakpoint>];
    const newValue = valueMap[originalValue as keyof typeof valueMap];
    result[key] = newValue;
  });
  return result;
};
