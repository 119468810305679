import React from 'react';
import { EVENT_NAME, PRODUCT_AREA } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingTwo,
  HeadingThree,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  Link,
} from '@leagueplatform/genesis-commons';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import { useHelpCenterLinks } from '@leagueplatform/health-journey-common';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import {
  BUTTON_CONTAINER_MAX_WIDTH,
  CHALLENGES_UTM_SOURCE,
} from 'constants/challenges.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { useSetConsentAndCheckValidicUser } from 'hooks/use-set-consent-and-check-validic-user.hook';
import { DeviceModalContentProps } from './wearables-connection.types';

const { WEARABLES_CONNECTION } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { TRACK_AUTOMATICALLY, TRACK_MANUALLY } = SIGN_UP_ANALYTICS_DETAILS;

export const DeviceUnconnectedModalContent = ({
  setDisplayManualTrackingConfirmationModal,
  isLoading: isSignUpMutationLoading,
  onConfirm,
}: DeviceModalContentProps) => {
  const { formatMessage } = useIntl();
  const { whatDevicesCanConnectLink, healthProgramsManualTrackingLink } =
    useHelpCenterLinks(CHALLENGES_UTM_SOURCE);
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.APPS_AND_DEVICES,
      screen_name: WEARABLES_CONNECTION,
      is_data_available: false,
      detail,
    });
  const checkValidicUserOnSuccess = () => {
    onConfirm();
  };
  const { isMutationLoading, mutate: checkValidicUser } =
    useSetConsentAndCheckValidicUser();
  const trackWearables = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_TRACK_WEARABLES,
  ) as string;

  return (
    <ModalContentWithImage
      image={{
        src: trackWearables,
        alt: formatMessage({ id: 'TRACK_WEARABLES_ALT_TEXT' }),
      }}
    >
      <HeadingTwo as="h3" marginBottom="threeQuarters">
        {formatMessage({ id: 'DEVICES_UNCONNECTED_TITLE' })}
      </HeadingTwo>
      <BodyOne marginBottom="three">
        {formatMessage({
          id: 'DEVICES_UNCONNECTED_DESCRIPTION',
        })}
      </BodyOne>
      <HeadingThree marginBottom="one">
        {formatMessage({
          id: 'DEVICES_UNCONNECTED_TRACK_AUTOMATICALLY_SUBTITLE',
        })}
      </HeadingThree>
      <BodyOne marginBottom="oneAndHalf">
        {formatMessage(
          {
            id: 'TRACK_CHALLENGE_AUTOMATICALLY_DESCRIPTION',
          },
          {
            instructionsLink: (
              <Link
                href={whatDevicesCanConnectLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage({
                  id: 'TRACK_CHALLENGE_AUTOMATICALLY_INSTRUCTION',
                })}
              </Link>
            ),
          },
        )}
      </BodyOne>
      <PrimaryButton
        marginBottom="three"
        onClick={() => {
          handleAnalyticsClick(TRACK_AUTOMATICALLY);
          checkValidicUser({ checkValidicUserOnSuccess });
        }}
        maxWidth={BUTTON_CONTAINER_MAX_WIDTH}
        isLoading={isMutationLoading || isSignUpMutationLoading}
      >
        {formatMessage({ id: 'TRACK_CHALLENGE_AUTOMATICALLY' })}
      </PrimaryButton>
      <HeadingThree marginBottom="one">
        {formatMessage({ id: 'DEVICES_UNCONNECTED_TRACK_MANUALLY_SUBTITLE' })}
      </HeadingThree>
      <BodyOne marginBottom="oneAndHalf">
        {formatMessage(
          {
            id: 'TRACK_CHALLENGE_MANUALLY_CHANGE_LATER',
          },
          {
            learnMoreLink: (
              <Link
                href={healthProgramsManualTrackingLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage({ id: 'TRACK_CHALLENGE_MANUALLY_LEARN_MORE' })}
              </Link>
            ),
          },
        )}
      </BodyOne>
      <SecondaryButton
        onClick={() => {
          handleAnalyticsClick(TRACK_MANUALLY);
          setDisplayManualTrackingConfirmationModal();
        }}
        maxWidth={BUTTON_CONTAINER_MAX_WIDTH}
        disabled={isMutationLoading || isSignUpMutationLoading}
      >
        {formatMessage({ id: 'TRACK_CHALLENGE_MANUALLY' })}
      </SecondaryButton>
    </ModalContentWithImage>
  );
};
