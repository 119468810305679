import {
  sendAnalyticsEvent,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';
import { TYPING_DEBOUNCE_INTERVAL } from '@leagueplatform/web-common';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Category } from '../types/category.type';
import { getDistinctCoverageTypesInTree } from '../utils/get-distinct-coverage-types-in-tree.util';
import { useCoverageQuery } from './queries/use-coverage-query.hook';

interface CoverageState {
  query: string;
  displayQuery: string;
  expandedIds: string[];
  categories?: Category[];
  isTree: boolean;
  coverage: string[];
}

type CoverageAction =
  | { type: 'UPDATE_CATEGORIES'; payload: { categories: Category[] } }
  | {
      type: 'SET_SEARCH_QUERY';
      payload: { query: string };
    }
  | { type: 'TOGGLE_EXPAND'; payload: { id: string } }
  | { type: 'EXPAND_ALL' }
  | { type: 'COLLAPSE_ALL' };

const initialState: CoverageState = {
  query: '',
  displayQuery: '',
  expandedIds: [],
  categories: undefined,
  coverage: [],
  isTree: false,
};

function coverageReducer(
  state: CoverageState,
  action: CoverageAction,
): CoverageState {
  switch (action.type) {
    case 'UPDATE_CATEGORIES': {
      const { categories } = action.payload;
      const isTree = Boolean(categories?.some((category) => !!category.sub));
      const hasQuery = Boolean(state.query);
      const expandedIds = hasQuery ? categories?.map((c) => c.unique_id) : [];
      const coverage = getDistinctCoverageTypesInTree(categories);

      return {
        ...state,
        isTree,
        expandedIds,
        categories,
        displayQuery: state.query,
        coverage,
      };
    }
    case 'SET_SEARCH_QUERY': {
      const { query } = action.payload;

      return {
        ...state,
        query,
      };
    }
    case 'EXPAND_ALL': {
      const { categories } = state;
      const categoryIds = categories?.map((c) => c.unique_id) ?? [];

      return { ...state, expandedIds: categoryIds };
    }
    case 'COLLAPSE_ALL': {
      return { ...state, expandedIds: [] };
    }
    case 'TOGGLE_EXPAND': {
      const { expandedIds } = state;
      const { id } = action.payload;

      let newExpandedIds: string[];

      if (expandedIds.includes(id)) {
        newExpandedIds = expandedIds.filter((val) => val !== id);
      } else {
        newExpandedIds = [...expandedIds, id];
      }

      return {
        ...state,
        expandedIds: newExpandedIds,
      };
    }

    default: {
      return state;
    }
  }
}

interface UseCoverageResult extends CoverageState {
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onToggleExpand: (id: string) => void;
  onQueryChange: (query: string) => void;
  isFullyExpanded: boolean;
  isFullyCollapsed: boolean;
}

export function useCoverage(
  employer: boolean,
  benefitType?: string,
  userId?: string,
  benefitId?: string,
): UseCoverageResult {
  useEffect(() => {
    if (benefitType) {
      sendAnalyticsPageView({
        title: `Coverage Summary - ${benefitType}`,
      });
    }
  }, [benefitType]);

  const [coverageState, dispatch] = useReducer(coverageReducer, initialState);

  const onExpandAll = useCallback(() => dispatch({ type: 'EXPAND_ALL' }), []);
  const onCollapseAll = useCallback(
    () => dispatch({ type: 'COLLAPSE_ALL' }),
    [],
  );
  const onToggleExpand = useCallback(
    (id) => {
      dispatch({ type: 'TOGGLE_EXPAND', payload: { id } });

      const categoryName = coverageState.categories!.find(
        (category) => category.unique_id === id,
      )?.category;

      if (!employer) {
        sendAnalyticsEvent({
          category: 'Wallet',
          action: `Coverage Browse - ${benefitType}`,
          label: categoryName,
        });
      }
    },
    [benefitType, coverageState.categories, employer],
  );

  const onQueryChange = useMemo(
    () =>
      debounce((query) => {
        dispatch({
          type: 'SET_SEARCH_QUERY',
          payload: { query },
        });

        if (query && !employer) {
          sendAnalyticsEvent({
            category: 'Wallet',
            action: `Coverage Search - ${benefitType}`,
            label: query,
          });
        }
      }, TYPING_DEBOUNCE_INTERVAL),
    [benefitType, employer],
  );

  const { data: coverageData } = useCoverageQuery({
    userId,
    benefitId,
    query: coverageState.query,
    employer,
  });

  useEffect(() => {
    if (coverageData) {
      dispatch({
        type: 'UPDATE_CATEGORIES',
        payload: { categories: coverageData },
      });
    }
  }, [coverageData]);

  const isFullyExpanded = useMemo(
    () =>
      !!coverageState.categories?.every((c) =>
        coverageState.expandedIds.includes(c.unique_id),
      ),
    [coverageState.categories, coverageState.expandedIds],
  );

  return {
    ...coverageState,
    isFullyExpanded,
    isFullyCollapsed:
      !isFullyExpanded && coverageState.expandedIds.length === 0,
    onExpandAll,
    onCollapseAll,
    onToggleExpand,
    onQueryChange,
  };
}
