import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Overline,
  css,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import Icon from 'ui-kit/components/icon/icon.view';

const StyledIcon = genesisStyled(Icon)((props) =>
  css({
    fill: props.iconColor,
    marginRight: 'quarter',
  }),
);

export const Badge = ({ icon, iconColor, text, ...props }) => (
  <Flex
    display="inline-flex"
    backgroundColor="highlight.background.subdued"
    height="oneAndHalf"
    borderRadius="medium"
    paddingX="quarter"
    alignItems="center"
    {...props}
  >
    {icon && (
      <StyledIcon
        icon={icon}
        size={10}
        iconColor={iconColor}
        aria-hidden="true"
      />
    )}
    <Overline as="span">{text}</Overline>
  </Flex>
);

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ),
  iconColor: PropTypes.string,
};

Badge.defaultProps = {
  icon: null,
  iconColor: 'onSurface.text.primary',
};
