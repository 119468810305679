import { useMutation, useQueryClient } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { TLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import {
  patchChallengeTeam,
  CHALLENGE_PATCH_OPERATIONS,
  CHALLENGE_HIGHLIGHTS_PATH,
  CHALLENGES_PATH,
} from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { TEAM_BY_ID_QUERY_KEY } from 'pages/team-landing-page/hooks/use-get-team-data-by-id.hook';

export const PATCH_JOIN_AND_LEAVE_TEAM_QUERY_KEY = 'patchJoinAndLeaveTeam';

export const useJoinOrLeaveTeam = (
  teamName: string,
  operationType: CHALLENGE_PATCH_OPERATIONS,
  setDisplayJoinTeamSuccessModal: React.Dispatch<React.SetStateAction<boolean>>,
  setDisplayCantJoinTeamModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const queryClient = useQueryClient();
  const { challengeId } = useParams<{ challengeId: string }>();
  const { teamId } = useParams<{ teamId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = () =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.TEAM_DETAILS,
      detail: TLP_DETAIL.JOIN_TEAM,
      team_name: teamName,
      team_id: teamId,
    });
  return useMutation(
    () => patchChallengeTeam(teamId, challengeId, operationType),

    {
      onSuccess: () => {
        if (operationType === CHALLENGE_PATCH_OPERATIONS.ADD) {
          setDisplayJoinTeamSuccessModal(true);
          handleAnalyticsClick();
        } else {
          toastActions.add({
            type: TOAST_STATUS.SUCCESS,
            textId: 'SUCCESSFULLY_LEFT_TEAM',
            shouldAutoClose: true,
            values: {
              teamName,
            },
          });
        }
        // Invalidate highlights feed, team data, challenge landing page
        queryClient.invalidateQueries([CHALLENGE_HIGHLIGHTS_PATH]);
        queryClient.invalidateQueries([TEAM_BY_ID_QUERY_KEY]);
        queryClient.invalidateQueries([CHALLENGES_PATH, challengeId]);
      },
      onError: () => {
        if (operationType === CHALLENGE_PATCH_OPERATIONS.ADD) {
          setDisplayCantJoinTeamModal(true);
        } else {
          toastActions.add({
            type: TOAST_STATUS.ERROR,
            textId: 'LEAVE_TEAM_FAILURE',
            shouldAutoClose: false,
          });
        }
      },
    },
  );
};
