import * as React from 'react';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { isSaveCardAction, isPrintCardAction } from 'utils/action-links.util';

interface UseCardActions {
  cardRef?: React.RefObject<HTMLElement>;
}

export const useCardActions = ({ cardRef }: UseCardActions) => {
  const { userId, benefitId } = useWalletParams();
  const [memberId] = useSelectedMember();
  const { data: cardActions = [] } = useWalletDetailQuery(
    userId,
    benefitId,
    memberId?.id,
    {
      select: (data) => data.card_images?.actions,
    },
  );

  const actions = React.useMemo(
    () =>
      cardActions.map((action) => {
        // using destructuring to remove unnecessary nav_icon (secondaryIcon) from action to preserve spacing
        // eslint-disable-next-line
        const { nav_icon, ...strippedAction } = action;

        if (isSaveCardAction(action)) {
          return { ...strippedAction, cardRef };
        }

        if (isPrintCardAction(action)) {
          return { ...strippedAction, cardRef };
        }

        return strippedAction;
      }),
    [cardActions, cardRef],
  );

  return { actions };
};
