import React from 'react';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';
import { Flex, css, genesisStyled } from '@leagueplatform/genesis-commons';
import {
  TwoUpCardContent,
  TwoUpCardContentProps,
} from '../two-up-card-content/two-up-card-content.component';

export interface TwoUpCardWidgetProps {
  items: TwoUpCardContentProps[];
  onItemClick: (item: TwoUpCardContentProps, index: number) => void;
}

export type TwoUpCardWSNode = MasonryEngineNode<
  typeof WidgetType.TWO_UP_CARD,
  TwoUpCardWidgetProps
>;

const StyledFlex = genesisStyled(Flex)(
  css({
    justifyContent: 'space-between',
    '&& > * + *': {
      marginLeft: 'one',
    },
  }),
);

const StyledLi = genesisStyled('li')(
  css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  }),
);

export const TwoUpCardWidget = ({
  items,
  onItemClick,
}: TwoUpCardWidgetProps) => (
  <StyledFlex as="ul">
    {items.map((card, index) => {
      const { heading, body, image, imageAltText, actionLink } = card;
      return (
        <StyledLi key={heading}>
          <TwoUpCardContent
            heading={heading}
            body={body}
            image={image}
            imageAltText={imageAltText}
            actionLink={actionLink}
            onClick={() => onItemClick(card, index)}
          />
        </StyledLi>
      );
    })}
  </StyledFlex>
);
