import { isAbsoluteUrl } from './is-absolute-url';
import { isExternalUrl } from './is-external-url';

const STAGING_URL = 'https://app.staging.league.dev';

export const normalizeUrl = (url: string) => {
  // Check if URL is empty or absolute
  if (url.trim().length !== 0 && !isAbsoluteUrl(url)) {
    // If the first character is not '/', add a '/'
    // Without '/' url's were stacking and resulting in broken navigation
    if (url.charAt(0) !== '/') {
      return `/${url}`;
    }
  }
  return url;
};

/**
 * Function to format an absolute url which starts with the current window.location.origin.
 * For Example if the url is 'https://app.staging.league.dev/get-care', this function will
 * return '/get-care'. This formatting is used for react router to provide it with relative
 * path.
 * In development env, replace 'https://app.staging.league.dev/get-care' to '/get-care'
 *
 * @param url[string] - url relative or absolute
 * @returns
 */

export const normalizeInternalUrl = (url: string = '') => {
  if (
    process.env.NODE_ENV === 'development' &&
    url?.match(new RegExp(STAGING_URL, 'ig'))
  ) {
    return url.replace(new RegExp(STAGING_URL, 'ig'), '');
  }
  return !isExternalUrl(url) &&
    url?.match(new RegExp(window.location.origin, 'i'))
    ? url?.replace(new RegExp(window.location.origin, 'ig'), '')
    : normalizeUrl(url);
};
