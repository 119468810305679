import * as React from 'react';

export function Circle({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <symbol id="circle" strokeLinecap="round">
        <circle cx="12" cy="12" r="10" stroke="currentcolor" />
      </symbol>
      <use xlinkHref="#circle" />
    </svg>
  );
}
