import { useMemo } from 'react';
import { FormState, FieldValues } from 'react-hook-form';
import {
  InputValidationState,
  OptionalStatus,
} from '../types/react-hook-form-input.type';

/**
 *`useInputStatus` reconciles react-hook-form's validation/error handling with Genesis Core's method of applying an input status and message.
 * If there is no error the optional status' will be applied, if provided.
 */
export const useInputStatus = (
  inputName: string,
  formState: FormState<FieldValues>,
  optionalStatus?: OptionalStatus,
) => {
  const error = formState.errors[inputName];

  const inputValidationState: InputValidationState = useMemo(() => {
    if (error && typeof error?.message === 'string')
      return { inputStatus: 'error', statusMessage: error.message };

    if (optionalStatus) return optionalStatus;

    return { inputStatus: undefined, statusMessage: undefined };
  }, [error, optionalStatus]);

  return inputValidationState;
};
