import React from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Box,
  HeadingTwo,
  SubtitleOne,
  SubtleButton,
  Text,
  Link,
} from '@leagueplatform/genesis-commons';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { useModalParam } from '../../hooks/use-modal-param.hook';
import { getQuickActionIcon } from '../../utils/quick-action-icons.util';
import { MenuItem, ActionItem } from '../../types/care-discovery.types';

interface QuickActionProps {
  children?: React.ReactNode;
  href: string;
  target: '_blank' | '_self';
}

interface TopicLinkProps {
  id: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  url?: string;
}

interface TriageScreenProps {
  actionItems?: ActionItem[];
  menuItems?: MenuItem[];
}

const QuickAction = ({
  children,
  href,
  target = '_self',
}: QuickActionProps) => (
  <Flex
    as="a"
    href={href}
    target={target}
    rel={target === '_blank' ? 'noopener noreferrer' : undefined}
    textDecoration="none"
    borderRadius="medium"
    padding="threeQuarters"
    paddingTop="oneAndHalf"
    paddingRight="one"
    backgroundColor="highlight.background.subdued"
    flexDirection="column"
    justifyContent="flex-end"
    cursor="pointer"
    width="100%"
    focusStyle={{
      outline: 0,
      boxShadow: 'focusRing',
    }}
    onClick={() => {
      sendAnalyticsEvent({
        category: 'Quick Action Menu',
        action: 'Select Quick Action',
        label: children,
      });
    }}
  >
    <SubtitleOne as="span">{children}</SubtitleOne>
  </Flex>
);

const TopicLink = ({ id, children, icon, url }: TopicLinkProps) => {
  const { linkToModalParam } = useModalParam();
  const isExternalLink = isAbsoluteUrl(url);
  const returnInternalUrl = (path: string | undefined) =>
    path || linkToModalParam(`get-care/${id}`);

  return (
    <SubtleButton
      textDecoration="none"
      color="onSurface.text.primary"
      borderColor="interactive.border.disabled"
      paddingY="oneAndHalf"
      boxShadow="card"
      marginTop="one"
      width="100%"
      justifyContent="start"
      as={isExternalLink ? Link : RouterLink}
      href={isExternalLink ? url : undefined}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
      to={!isExternalLink ? returnInternalUrl(url) : undefined}
      onClick={() => {
        sendAnalyticsEvent({
          category: 'Quick Action Menu',
          action: 'Select Question',
          label: children,
        });
      }}
    >
      {icon}
      <Text as="span" marginLeft="one">
        {children}
      </Text>
    </SubtleButton>
  );
};

export const TriageScreen = ({
  actionItems = [],
  menuItems = [],
}: TriageScreenProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingTwo as="h1" marginBottom="oneAndHalf">
        {formatMessage({ id: 'ID_LIKE_TO_DOTDOTDOT' })}
      </HeadingTwo>
      {Boolean(actionItems.length) && (
        <Flex
          as="ul"
          marginRight="minusOne"
          marginBottom="three"
          justifyContent="space-between"
        >
          {actionItems.map(({ id, url, title, isExternal }: ActionItem) => (
            <Flex key={id} as="li" width={1 / 3} paddingRight="one">
              <QuickAction href={url} target={isExternal ? '_blank' : '_self'}>
                {title}
              </QuickAction>
            </Flex>
          ))}
        </Flex>
      )}
      <Box as="ul">
        {menuItems.map(({ id, title, iconId, url }: MenuItem) => (
          <li key={id}>
            <TopicLink id={id} icon={getQuickActionIcon(iconId)} url={url}>
              {title}
            </TopicLink>
          </li>
        ))}
      </Box>
    </>
  );
};
