import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOneSecondary,
  Flex,
  HeadingOne,
  Box,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import {
  StatusBadge,
  STATUS_OPTIONS,
} from '@leagueplatform/web-common-components';
import { useJoinOrLeaveTeam } from 'hooks/use-join-or-leave-team.hook';
import { CHALLENGE_PATCH_OPERATIONS } from '@leagueplatform/health-journey-api';
import { TLPSettingsCogWithContent } from './tlp-settings-cog-with-content/tlp-settings-cog-with-content.component';
import { CantJoinTeamModal } from './cant-join-team-modal/cant-join-team-modal.component';
import { TLPCard } from '../tlp-card/tlp-card.component';
import { JoinTeamSuccessModal } from './join-team-success-modal/join-team-success-modal.component';

const TEAM_HEADING_FIXED_HEIGHT = 164;
const HEADING_BUTTON_MAX_WIDTH = { _: 180, phone: 264 };

export interface TLPHeadingProps {
  teamIcon: string;
  teamName: string;
  memberTotal: number;
  spotsAvailable: number;
  displayJoinTeamBtn: boolean;
  userCanJoinTeam: boolean;
  userIsOnCurrentTeam: boolean;
  userIsEnrolled: boolean;
  enrollmentOpen: boolean;
}

export const TLPHeading = ({
  teamIcon,
  teamName,
  memberTotal,
  spotsAvailable,
  displayJoinTeamBtn,
  userCanJoinTeam,
  userIsOnCurrentTeam,
  userIsEnrolled,
  enrollmentOpen,
}: TLPHeadingProps) => {
  const { formatMessage } = useIntl();
  const [displayCantJoinTeamModal, setDisplayCantJoinTeamModal] =
    useState(false);

  const [displayJoinTeamSuccessModal, setDisplayJoinTeamSuccessModal] =
    useState(false);

  const { mutate: joinChallengeTeam, isLoading } = useJoinOrLeaveTeam(
    teamName,
    CHALLENGE_PATCH_OPERATIONS.ADD,
    setDisplayJoinTeamSuccessModal,
    setDisplayCantJoinTeamModal,
  );

  const onJoinClick = () => {
    if (userCanJoinTeam) {
      joinChallengeTeam();
    }
    if (!userCanJoinTeam) {
      setDisplayCantJoinTeamModal(true);
    }
  };

  return (
    <Box marginBottom="one" width="100%">
      <TLPCard>
        <Flex position="relative">
          <Flex
            alignItems="center"
            flexDirection={{ _: 'column', tablet: 'row' }}
            justifyContent="space-between"
            marginTop={{ _: 'two', tablet: 'none' }}
            width="100%"
          >
            <Flex
              alignItems={{ _: 'center', tablet: 'flex-start' }}
              flexDirection={{ _: 'column', tablet: 'row' }}
              marginRight={{ tablet: 'one' }}
              textAlign={{ _: 'center', tablet: 'left' }}
            >
              <Box
                marginRight={{ _: 'none', tablet: 'twoAndHalf' }}
                minWidth={TEAM_HEADING_FIXED_HEIGHT}
                size={TEAM_HEADING_FIXED_HEIGHT}
              >
                <ChallengeProfilePicture
                  profilePicture={teamIcon}
                  firstName={teamName}
                  lastName=""
                />
              </Box>
              <Box>
                <HeadingOne marginBottom="half" marginTop="one">
                  {teamName}
                </HeadingOne>
                {userIsOnCurrentTeam && (
                  <Box marginBottom="one">
                    <StatusBadge
                      status={STATUS_OPTIONS.SUCCESS}
                      label={formatMessage({ id: 'JOINED' })}
                      displayIcon
                    />
                  </Box>
                )}
                <BodyOneSecondary>
                  {formatMessage({ id: 'X_TEAM_MEMBERS' }, { memberTotal })}
                </BodyOneSecondary>
                <BodyOneSecondary>
                  {formatMessage(
                    { id: 'X_SPOTS_AVAILABLE' },
                    { spotsAvailable },
                  )}
                </BodyOneSecondary>
              </Box>
            </Flex>
            {displayJoinTeamBtn && (
              <>
                <PrimaryButton
                  alignSelf={{ tablet: 'flex-end' }}
                  marginTop={{ _: 'oneAndHalf', tablet: 'none' }}
                  maxWidth={HEADING_BUTTON_MAX_WIDTH}
                  onClick={onJoinClick}
                  width="100%"
                  isLoading={isLoading}
                >
                  {formatMessage({ id: 'JOIN_TEAM' })}
                </PrimaryButton>
                <CantJoinTeamModal
                  userIsEnrolled={userIsEnrolled}
                  teamName={teamName}
                  isSettingsDropdownOpen={displayCantJoinTeamModal}
                  onDismiss={() => setDisplayCantJoinTeamModal(false)}
                />
              </>
            )}
            <JoinTeamSuccessModal
              isOpen={displayJoinTeamSuccessModal}
              onDismiss={() => setDisplayJoinTeamSuccessModal(false)}
            />
          </Flex>
          {userIsOnCurrentTeam && (
            <TLPSettingsCogWithContent
              teamName={teamName}
              enrollmentOpen={enrollmentOpen}
              userIsOnCurrentTeam={userIsOnCurrentTeam}
              spotsAvailable={spotsAvailable}
              setDisplayJoinTeamSuccessModal={setDisplayJoinTeamSuccessModal}
              setDisplayCantJoinTeamModal={setDisplayCantJoinTeamModal}
            />
          )}
        </Flex>
      </TLPCard>
    </Box>
  );
};
