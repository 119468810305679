import * as React from 'react';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { UserWalletBenefitCard } from '@leagueplatform/wallet-api';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { EVENT_NAME } from '@leagueplatform/analytics';

const PRIMARY_USER = 'primary';
const DEPENDENT_USER = 'dependent';

function selectMemberOptions(data: UserWalletBenefitCard) {
  const primaryUserOption = {
    label: `${data.member_first_name} ${data.member_last_name}`,
    value: 'primary',
  };
  const dependentOptions =
    data.dependents
      ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map((dependent) => ({
        label: `${dependent.first_name} ${dependent.last_name}`,
        value: dependent.dependent_id,
      })) ?? [];

  return [primaryUserOption, ...dependentOptions];
}

export function useMemberDropdown() {
  const { userId, benefitId } = useWalletParams();
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  const { data: isDropdownEnabled } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_DEPENDENTS_DROPDOWN_ENABLED,
  );

  const { data: memberOptions = [] } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: selectMemberOptions,
    },
  );

  const hasMemberOptions = memberOptions.length > 1;

  const showMemberSelect = isDropdownEnabled && hasMemberOptions;

  const primaryUserId = userId ?? PRIMARY_USER;

  const onMemberChange = React.useCallback(
    (value: string) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `select ${
          value !== primaryUserId ? DEPENDENT_USER : PRIMARY_USER
        }`,
      });
    },
    [primaryUserId, sendBenefitAnalyticsEvent],
  );

  return {
    showMemberSelect,
    memberOptions,
    onMemberChange,
  };
}
