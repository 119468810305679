import React from 'react';
import { Field } from 'formik';
import { useFocusFirstFormikError } from '@leagueplatform/web-common';
import {
  Label,
  Flex,
  Checkbox,
  Caption,
  css,
  genesisStyled,
  ChoiceText,
} from '@leagueplatform/genesis-commons';
import { Participant } from '@leagueplatform/messaging-api';

const StyledListItem = genesisStyled.li(
  css({
    paddingY: 'one',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
    '&:last-child': {
      borderBottom: 'none',
    },
  }),
);

export const PARTICIPANTS_INPUT_NAME = 'selectedParticipantIds';

interface ParticipantListOptionProps {
  participant: Participant;
}

export const ParticipantListOption = ({
  participant,
}: ParticipantListOptionProps) => {
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const { id, name, roleDisplayName } = participant;
  const inputId = `careTeamInput${id}`;
  useFocusFirstFormikError();
  return (
    <StyledListItem>
      <Label htmlFor={inputId} ref={labelRef}>
        <Flex display="flex" justifyContent="space-between">
          <ChoiceText>{name}</ChoiceText>
          <Field
            as={Checkbox}
            type="checkbox"
            id={inputId}
            name={PARTICIPANTS_INPUT_NAME}
            value={id}
            onFocus={() => {
              labelRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }}
          />
        </Flex>
        {roleDisplayName && (
          <Caption as="span" color="onSurface.text.subdued">
            {roleDisplayName}
          </Caption>
        )}
      </Label>
    </StyledListItem>
  );
};
