import React from 'react';
import {
  ImageMessage,
  GDSImageMessageProps,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';

const ImageAlignMap = {
  left: 'start',
  center: 'center',
  right: 'end',
};

type MasonryImageBannerNodeProperties = {
  image: string;
  imageAltText: string;
  imageHeight?: number;
  imageAlign?: keyof typeof ImageAlignMap;
};

export type MasonryImageBannerNode = MasonryEngineNode<
  'imageBanner',
  MasonryImageBannerNodeProperties
>;

export type MasonryImageBannerRendererProps =
  MasonryEngineNodeRendererProps<MasonryImageBannerNode>;

export const MasonryImageBannerRenderer = ({
  image,
  imageAltText,
  imageHeight = 50,
  imageAlign = 'center',
}: MasonryImageBannerRendererProps) => (
  <ImageMessage
    image={image}
    imageAlt={imageAltText}
    imageHeight={imageHeight}
    imageHorizontalAlignment={
      (
        ImageAlignMap as Record<
          string,
          GDSImageMessageProps['imageHorizontalAlignment']
        >
      )[imageAlign]
    }
  />
);
