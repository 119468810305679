import { useWalletQuery } from 'hooks/queries/use-wallet-query.hook';
import { useUserActiveGroupQuery } from 'hooks/queries/use-user-active-group-query.hook';
import { useGroupQuery } from 'hooks/queries/use-group-query.hook';
import { getIsInNewHireEnrollment } from '../../utils/get-is-in-new-hire-enrollment.util';

export function useIsNewHireEnrollmentQuery(userId?: string) {
  const walletQuery = useWalletQuery(userId);

  const { data: activeGroup } = useUserActiveGroupQuery(userId);

  const groupQuery = useGroupQuery(activeGroup?.group_id);

  if (!walletQuery.data || !groupQuery.data) {
    return {
      data: null,
      isError: walletQuery.isError || groupQuery.isError,
      isLoading: walletQuery.isLoading || groupQuery.isLoading,
    };
  }

  const annualEnrollmentPeriod =
    groupQuery.data.group.employer?.benefits_annual_enrollment_period;
  const planEnrollment = groupQuery.data.plan_enrollment ?? {};

  const isInEnrollment = walletQuery.data.in_enrollment_period;

  const enrollmentEndDate = new Date(walletQuery.data?.enrollment_end_date);

  const isNewHireEnrollment = getIsInNewHireEnrollment(
    isInEnrollment,
    enrollmentEndDate,
    annualEnrollmentPeriod,
    planEnrollment,
  );

  return {
    isError: walletQuery.isError || groupQuery.isError,
    isLoading: walletQuery.isLoading || groupQuery.isLoading,
    data: isNewHireEnrollment,
  };
}
