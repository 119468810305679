import * as React from 'react';
import Masonry, { getMasonryDriver } from '@leagueplatform/masonry';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { useIsMetricsImprovementsEnabled } from 'hooks/use-is-metrics-improvements-enabled';

const MasonryComposer = Masonry.init({
  appId: 'dashboard-metrics',
  apiVersion: 2,
  analytics: {
    productArea: PRODUCT_AREA.DASHBOARD,
    screenName: SCREEN_NAMES.ABOUT_ME_METRICS,
  },
});

const MasonryMetricsDriver = getMasonryDriver({
  rootNodeId: 'aboutMeMetrics$root',
});

export function AllMetricCards() {
  const { isMetricsImprovementsEnabled } = useIsMetricsImprovementsEnabled();

  if (isMetricsImprovementsEnabled) {
    return <MasonryEngine driver={MasonryMetricsDriver} />;
  }
  return <MasonryComposer.Provider />;
}
