import { useQuery, UseQueryOptions } from 'react-query';
import {
  getWalletItemId,
  GET_WALLET_ITEM_ID_MESSAGE_TYPE,
  GetWalletItemIdResponse,
} from '@leagueplatform/wallet-api';
import { captureError } from '@leagueplatform/observability';

export function useGetWalletItemIdQuery<TSelect = GetWalletItemIdResponse>(
  userId: string | undefined,
  deepLinkUrl: string,
  options?: UseQueryOptions<GetWalletItemIdResponse, unknown, TSelect>,
) {
  return useQuery<GetWalletItemIdResponse, unknown, TSelect>(
    [GET_WALLET_ITEM_ID_MESSAGE_TYPE, userId, deepLinkUrl],
    () => getWalletItemId(userId, deepLinkUrl),
    {
      ...options,
      onError: (err) => {
        captureError(err as Error, {
          context: {
            params: {
              deepLinkUrl,
            },
          },
        });

        if (options?.onError) {
          options.onError(err);
        }
      },
    },
  );
}
