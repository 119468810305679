import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  Box,
  HeadingText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { formatUserName } from 'utils/format-user-name.util';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

interface LikeProps {
  firstName: string;
  lastName: string;
  avatar?: string;
}

export interface LikesModalProps {
  displayLikesModal: boolean;
  setDisplayLikesModal: React.Dispatch<React.SetStateAction<boolean>>;
  activityLikes: LikeProps[];
}

const LIKES_MODAL_MAX_WIDTH = 500;
const LIKES_MODAL_LIST_HEIGHT = 220;
const LIKES_MODAL_ARIA_LABEL = 'likesModalHeading';

export const LikesModal = ({
  displayLikesModal,
  setDisplayLikesModal,
  activityLikes,
}: LikesModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal.Root
      open={displayLikesModal}
      onOpenChange={(open) => setDisplayLikesModal(open)}
    >
      <Modal.Content
        css={{
          '.GDS-modal': {
            maxWidth: LIKES_MODAL_MAX_WIDTH,
            paddingTop: 'oneAndHalf',
            paddingLeft: 'two',
            paddingBottom: 'none',
            paddingRight: 'none',
          },
        }}
        aria-labelledby={LIKES_MODAL_ARIA_LABEL}
      >
        <Box css={{ alignItems: 'center', display: 'flex' }}>
          {handleStaticAsset(CHALLENGES_ASSET_KEYS.CHALLENGES_HEART_ICON, {
            isComponent: true,
            props: {
              fillColor: 'onSurface.text.primary',
            },
          })}
          <HeadingText
            size="lg"
            level="2"
            id={LIKES_MODAL_ARIA_LABEL}
            css={{ marginLeft: '$threeQuarters' }}
          >
            {formatMessage(
              { id: 'X_LIKES' },
              { activityLikes: activityLikes.length },
            ).toLowerCase()}
          </HeadingText>
        </Box>
        <Box
          as="ul"
          css={{
            marginTop: '$threeQuarters',
            height: LIKES_MODAL_LIST_HEIGHT,
            overflowY: 'auto',
          }}
        >
          {activityLikes.map((like, index) => {
            const { firstName, lastName, avatar } = like;
            const formattedName = formatUserName({ firstName, lastName });
            return (
              <Box
                as="li"
                // Note: the `likes` don't come with unique ids from the BE so not ideal to use index here
                // but it does the job. We aren't adding/removing these items on the FE so using the index
                // shouldn't cause re-rendering/state issues.
                // eslint-disable-next-line react/no-array-index-key
                key={`${firstName}_${lastName}_${index}`}
                css={{
                  paddingTop: '$threeQuarters',
                  paddingBottom: '$threeQuarters',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  css={{
                    marginRight: '$one',
                    width: '$twoAndHalf',
                    height: '$twoAndHalf',
                  }}
                >
                  <ChallengeProfilePicture
                    profilePicture={avatar}
                    firstName={firstName}
                    lastName={lastName}
                  />
                </Box>
                <UtilityText
                  as="p"
                  size="sm"
                  css={{ typography: '$subtitleTwo' }}
                >
                  {formattedName}
                </UtilityText>
              </Box>
            );
          })}
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
};
