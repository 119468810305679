import * as React from 'react';
import { StackItem, StackLayout } from '@leagueplatform/genesis-core';

type ChallengeActivitySectionProps = {
  children: React.ReactNode;
};

export function ChallengeActivitySection({
  children,
}: ChallengeActivitySectionProps) {
  return (
    <StackItem css={{ width: '100%' }}>
      <StackLayout spacing="$one" css={{ width: '100%' }}>
        {children}
      </StackLayout>
    </StackItem>
  );
}
