{
  "dragDescriptionKeyboard": "Stlačením klávesu Enter začnete presúvanie.",
  "dragDescriptionKeyboardAlt": "Stlačením klávesov Alt + Enter začnete presúvanie.",
  "dragDescriptionLongPress": "Dlhým stlačením začnete presúvanie.",
  "dragDescriptionTouch": "Dvojitým kliknutím začnete presúvanie.",
  "dragDescriptionVirtual": "Kliknutím začnete presúvanie.",
  "dragItem": "Presunúť položku {itemText}",
  "dragSelectedItems": "Presunúť {count, plural, one {# vybratú položku} other {# vybraté položky}}",
  "dragSelectedKeyboard": "Stlačením klávesu Enter presuniete {count, plural, one {# vybratú položku} other {# vybratých položiek}}.",
  "dragSelectedKeyboardAlt": "Stlačením klávesov Alt + Enter presuniete {count, plural, one {# vybratú položku} other {# vybratých položiek}}.",
  "dragSelectedLongPress": "Dlhým stlačením presuniete {count, plural, one {# vybratú položku} other {# vybratých položiek}}.",
  "dragStartedKeyboard": "Presúvanie sa začalo. Do cieľového umiestnenia prejdete stlačením klávesu Tab. Ak chcete položku umiestniť, stlačte kláves Enter alebo stlačte kláves Esc, ak chcete presúvanie zrušiť.",
  "dragStartedTouch": "Presúvanie sa začalo. Prejdite na cieľové umiestnenie a dvojitým kliknutím umiestnite položku.",
  "dragStartedVirtual": "Presúvanie sa začalo. Prejdite na cieľové umiestnenie a kliknutím alebo stlačením klávesu Enter umiestnite položku.",
  "dropCanceled": "Umiestnenie zrušené.",
  "dropComplete": "Umiestnenie dokončené.",
  "dropDescriptionKeyboard": "Stlačením klávesu Enter umiestnite položku. Stlačením klávesu Esc zrušíte presúvanie.",
  "dropDescriptionTouch": "Dvojitým kliknutím umiestnite položku.",
  "dropDescriptionVirtual": "Kliknutím umiestnite položku.",
  "dropIndicator": "indikátor umiestnenia",
  "dropOnItem": "Umiestniť na položku {itemText}",
  "dropOnRoot": "Umiestniť na",
  "endDragKeyboard": "Prebieha presúvanie. Ak ho chcete zrušiť, stlačte kláves Enter.",
  "endDragTouch": "Prebieha presúvanie. Dvojitým kliknutím ho môžete zrušiť.",
  "endDragVirtual": "Prebieha presúvanie.",
  "insertAfter": "Vložiť za položku {itemText}",
  "insertBefore": "Vložiť pred položku {itemText}",
  "insertBetween": "Vložiť medzi položky {beforeItemText} a {afterItemText}"
}
