{
  "dragDescriptionKeyboard": "Pulse Intro para empezar a arrastrar.",
  "dragDescriptionKeyboardAlt": "Pulse Intro para empezar a arrastrar.",
  "dragDescriptionLongPress": "Mantenga pulsado para comenzar a arrastrar.",
  "dragDescriptionTouch": "Pulse dos veces para iniciar el arrastre.",
  "dragDescriptionVirtual": "Haga clic para iniciar el arrastre.",
  "dragItem": "Arrastrar {itemText}",
  "dragSelectedItems": "Arrastrar {count, plural, one {# elemento seleccionado} other {# elementos seleccionados}}",
  "dragSelectedKeyboard": "Pulse Intro para arrastrar {count, plural, one {# elemento seleccionado} other {# elementos seleccionados}}.",
  "dragSelectedKeyboardAlt": "Pulse Alt + Intro para arrastrar {count, plural, one {# elemento seleccionado} other {# elementos seleccionados}}.",
  "dragSelectedLongPress": "Mantenga pulsado para arrastrar {count, plural, one {# elemento seleccionado} other {# elementos seleccionados}}.",
  "dragStartedKeyboard": "Se ha empezado a arrastrar. Pulse el tabulador para ir al público destinatario donde se vaya a colocar y, a continuación, pulse Intro para soltar, o pulse Escape para cancelar.",
  "dragStartedTouch": "Se ha empezado a arrastrar. Vaya al público destinatario donde se vaya a colocar y, a continuación, pulse dos veces para soltar.",
  "dragStartedVirtual": "Se ha empezado a arrastrar. Vaya al público destinatario donde se vaya a colocar y, a continuación, haga clic o pulse Intro para soltar.",
  "dropCanceled": "Se ha cancelado la colocación.",
  "dropComplete": "Colocación finalizada.",
  "dropDescriptionKeyboard": "Pulse Intro para soltar. Pulse Escape para cancelar el arrastre.",
  "dropDescriptionTouch": "Pulse dos veces para soltar.",
  "dropDescriptionVirtual": "Haga clic para soltar.",
  "dropIndicator": "indicador de colocación",
  "dropOnItem": "Soltar en {itemText}",
  "dropOnRoot": "Soltar en",
  "endDragKeyboard": "Arrastrando. Pulse Intro para cancelar el arrastre.",
  "endDragTouch": "Arrastrando. Pulse dos veces para cancelar el arrastre.",
  "endDragVirtual": "Arrastrando. Haga clic para cancelar el arrastre.",
  "insertAfter": "Insertar después de {itemText}",
  "insertBefore": "Insertar antes de {itemText}",
  "insertBetween": "Insertar entre {beforeItemText} y {afterItemText}"
}
