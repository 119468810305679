import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOne,
  HeadingTwo,
  Image,
  Box,
} from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';

interface EmptySearchProps {
  query: string;
}

export const EmptySearch = ({ query }: EmptySearchProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box textAlign="center">
      <Image
        src={handleStaticAsset(WALLET_ASSET_KEYS.ICON_SEARCH_EMPTY) as string}
        role="presentation"
        width={86.36}
        height={100}
        marginTop="two"
      />
      <HeadingTwo color="onSurface.text.subdued" paddingY="oneAndHalf">
        {formatMessage(
          {
            id: 'COVERAGE_SEARCH_QUERY_NOT_FOUND',
            defaultMessage: '{query} could not be found.',
          },
          { query },
        )}
      </HeadingTwo>

      <BodyOne color="onSurface.text.subdued" fontStyle="italic">
        {formatMessage({
          id: 'COVERAGE_SEARCH_TRY_AGAIN',
          defaultMessage: 'Try searching again using a different keyword.',
        })}
      </BodyOne>
    </Box>
  );
};
