import { useQuery } from 'react-query';
import {
  getCareCollection,
  CareCollectionQueryResponse,
} from '@leagueplatform/care-collections-api';

export const useGetCareCollection = (careCollectionId: string) =>
  useQuery<CareCollectionQueryResponse>(
    ['GET_CARE_COLLECTION', careCollectionId],
    () => getCareCollection(careCollectionId),
  );
