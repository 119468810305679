import intersection from 'lodash/intersection';
import {
  CARD_STATUS,
  PILL_STATUS,
  WalletItemNavigationSectionGroup,
} from '@leagueplatform/wallet-api';

export type CardStatuses = PILL_STATUS | CARD_STATUS;

const normalizeStatus = (status: CARD_STATUS) => {
  switch (status) {
    case CARD_STATUS.CREATED:
    case CARD_STATUS.READY:
    case CARD_STATUS.IN_WAITING_PERIOD:
      return PILL_STATUS.PENDING;
    case CARD_STATUS.INACTIVE_GRACE_PERIOD:
    case CARD_STATUS.INACTIVE:
      return PILL_STATUS.PAST;
    default:
      return CARD_STATUS.ACTIVE;
  }
};

export function parseBenefitCardStatuses(
  walletItemsSectioned?: WalletItemNavigationSectionGroup[],
): CardStatuses[] {
  if (!walletItemsSectioned) {
    return [];
  }
  const benefitsSections = walletItemsSectioned?.find(
    (section) => section.section_id === 'benefits',
  )?.sections;
  const statuses = new Set<PILL_STATUS | CARD_STATUS>();
  if (benefitsSections) {
    benefitsSections.forEach((section) => {
      if (section.wallet_items) {
        section.wallet_items.forEach((item) =>
          statuses.add(normalizeStatus(item.status)),
        );
      }
    });
  }
  return (
    statuses &&
    intersection<PILL_STATUS | CARD_STATUS>(
      [PILL_STATUS.PENDING, PILL_STATUS.ACTIVE, PILL_STATUS.PAST],
      Array.from(statuses),
    )
  );
}
