import * as React from 'react';
import {
  Column,
  ColumnLayout,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { merge } from 'lodash';
import {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
// eslint-disable-next-line import/no-cycle
import { MasonryDriverNode } from '../../types/masonry-driver-node';

type MasonryColumnLayoutRendererProperties = {
  heading: string;
  body: string;
};

type MasonryColumnLayoutNodeSections = {
  column_1: MasonryDriverNode[];
  column_2: MasonryDriverNode[];
};

export type MasonryColumnLayoutNode = MasonryEngineNode<
  'column_layout',
  MasonryColumnLayoutRendererProperties,
  any,
  MasonryColumnLayoutNodeSections
>;

export type MasonryColumnLayoutRendererProps =
  MasonryEngineNodeRendererProps<MasonryColumnLayoutNode>;

// @TODO - update the component according to BE definition.
export const MasonryColumnLayoutRenderer = ({
  column_1,
  column_2,
  heading,
  body,
}: MasonryColumnLayoutRendererProps) => (
  <StackLayout>
    <HeadingText level="2" size="xl">
      {heading}
    </HeadingText>
    <ParagraphText>{body}</ParagraphText>
    <ColumnLayout
      css={{
        width: '100%',
        maxWidth: '100%',
        gap: '$none',
        '@desktop': {
          gap: '50px',
        },
        '@laptop': {
          gap: '40px',
        },
      }}
      background="primary"
      spacingStart={{
        '@initial': '$none',
        '@mobile': '$two',
        '@tablet': '$two',
        '@laptop': '$none',
        '@desktop': '$none',
      }}
      spacingEnd={{
        '@initial': '$none',
        '@mobile': '$two',
        '@tablet': '$two',
        '@laptop': '$none',
        '@desktop': '$none',
      }}
    >
      <Column
        offset={0}
        order={{
          '@initial': 12,
          '@mobile': 2,
          '@tablet': 2,
          '@laptop': 1,
          '@desktop': 1,
        }}
        width={{
          '@initial': 12,
          '@mobile': 12,
          '@tablet': 12,
          '@laptop': 8,
          '@desktop': 9,
        }}
      >
        <StackLayout
          orientation="vertical"
          horizontalAlignment="stretch"
          spacing="$two"
        >
          {column_1}
        </StackLayout>
      </Column>
      <Column
        offset={0}
        order={{
          '@initial': 12,
          '@mobile': 1,
          '@tablet': 1,
          '@laptop': 2,
          '@desktop': 2,
        }}
        width={{
          '@initial': 12,
          '@mobile': 12,
          '@tablet': 12,
          '@laptop': 4,
          '@desktop': 3,
        }}
        css={{
          marginTop: '$none',
          '@mobile': {
            marginTop: '$oneAndHalf',
          },
          '@tablet': {
            marginTop: '$oneAndHalf',
          },
        }}
      >
        <StackLayout
          css={{ height: 'auto' }}
          orientation="vertical"
          horizontalAlignment="stretch"
          spacing="$two"
        >
          {React.Children?.map(column_2, (child) =>
            React.cloneElement(child as unknown as React.ReactElement, {
              node: merge(
                {},
                (child as unknown as React.ReactElement).props.node,
                {
                  properties: { isChildItem: true },
                },
              ),
            }),
          )}
        </StackLayout>
      </Column>
    </ColumnLayout>
  </StackLayout>
);
