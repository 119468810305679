import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { formatUserName } from 'utils/format-user-name.util';
import {
  HighlightImage,
  HighlightReaction,
  CHALLENGE_HIGHLIGHT_IMAGE_TYPES,
  CHALLENGE_HIGHLIGHT_REACTION_TYPES,
  UserReactionsData,
  Highlight,
} from '@leagueplatform/health-journey-api';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';

export const useGetHighlightDetails = (highlight: Highlight) => {
  const { formatDate } = useIntl();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  const {
    firstName,
    lastName,
    teamName,
    createdAt,
    images,
    reactions,
    challengeFeedItemReaction,
  } = highlight;
  // Assume that if firstName does not exist or is an empty string, the highlight is for a team
  // May need to be updated when teams BE is complete
  const formattedName = firstName?.length
    ? formatUserName({ firstName, lastName })
    : teamName;
  const formattedDate = formatDate(createdAt, {
    ...SHORT_DATE_FORMAT,
    timeZone: defaultTimezone,
  });

  const userPhoto = images?.filter(
    (image: HighlightImage) =>
      image.type === CHALLENGE_HIGHLIGHT_IMAGE_TYPES.USER,
  )[0]?.imageUrl;
  const teamPhoto = images?.filter(
    (image: HighlightImage) =>
      image.type === CHALLENGE_HIGHLIGHT_IMAGE_TYPES.TEAM,
  )[0]?.imageUrl;
  const activityImage = images?.filter(
    (image: HighlightImage) =>
      image.type === CHALLENGE_HIGHLIGHT_IMAGE_TYPES.HIGHLIGHT,
  )[0]?.imageUrl;

  const numberOfLikes = reactions?.filter(
    (reaction: HighlightReaction) =>
      reaction.type === CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE,
  )[0]?.count;

  const likeReactionId = challengeFeedItemReaction?.data?.filter(
    (data: UserReactionsData) =>
      data.reactionType === CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE,
  )[0]?.id;

  const likesData = challengeFeedItemReaction?.data?.filter(
    (data: UserReactionsData) =>
      data.reactionType === CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE,
  )[0]?.userProfiles;

  const userLikedHighlight = reactions?.filter(
    (reaction: HighlightReaction) =>
      reaction.type === CHALLENGE_HIGHLIGHT_REACTION_TYPES.LIKE,
  )[0]?.reacted;

  return {
    activityImage,
    formattedDate,
    formattedName,
    likesData,
    likeReactionId,
    numberOfLikes,
    teamPhoto,
    userLikedHighlight,
    userPhoto,
  };
};
