import { useQuery, UseQueryOptions } from 'react-query';
import {
  getWallet,
  GET_WALLET_MESSAGE_TYPE,
  UserWallet,
} from '@leagueplatform/wallet-api';

export function useWalletQuery<TSelect = UserWallet>(
  userId?: string,
  options?: UseQueryOptions<UserWallet, unknown, TSelect>,
) {
  return useQuery<UserWallet, unknown, TSelect>(
    [GET_WALLET_MESSAGE_TYPE, userId],
    () => getWallet(userId),
    options,
  );
}
