import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout, HeadingText } from '@leagueplatform/genesis-core';
import {
  ChartType,
  HealthMetricAverageValueV3,
} from '@leagueplatform/dashboard-api';
import { shapes } from 'components/charts/core/xychart/primitives/Shape';
import { getHealthMetricUnitDisplayValue } from 'utils/get-health-metric-unit-display-value';
import { getValueUnitDisplayLabel } from 'utils/get-value-unit-display-label';
import { DatumLegend, DatumValueWithUnit } from './datum-legend';

type AverageValuePanelProps = {
  averages: HealthMetricAverageValueV3[];
  chartType: ChartType;
};

export function AverageValuePanel({
  averages,
  chartType,
}: AverageValuePanelProps) {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="center"
      spacing="$three"
    >
      {averages
        .sort((a, b) =>
          a.metricComponent.data.order < b.metricComponent.data.order ? -1 : 1,
        )
        .map((average) => {
          const shape = shapes[average.metricComponent.data.order];
          const unit = getHealthMetricUnitDisplayValue(
            average.value,
            average.metricComponent.data.unit,
          );
          return (
            <StackLayout horizontalAlignment="center" key={average.id}>
              {chartType === 'range' ? (
                <DatumLegend
                  shape={averages.length > 1 ? shape : undefined}
                  name={average.metricComponent.data.name.toUpperCase()}
                  value={average.value}
                  unit={unit}
                />
              ) : (
                <>
                  <HeadingText level="4" size="md" emphasis="subtle">
                    {formatMessage({
                      id: 'AVERAGE',
                    }).toUpperCase()}
                  </HeadingText>
                  <StackLayout orientation="horizontal" spacing="$half">
                    {getValueUnitDisplayLabel({
                      value: average.value,
                      unitOptions: average.metricComponent.data.unit,
                    }).map(([displayValue, displayUnit]) => (
                      <DatumValueWithUnit
                        value={displayValue}
                        unit={displayUnit}
                      />
                    ))}
                  </StackLayout>
                </>
              )}
            </StackLayout>
          );
        })}
    </StackLayout>
  );
}
