import { merge } from 'lodash';

// Like _.invert(), except it expects the object values to be arrays, with each
// array element used as a key in the resulting object.
type InvertedMap = Record<string, any>;
type InputMap = Record<string, Array<any>>;

export const invertMap = (map: InputMap): InvertedMap =>
  Object.keys(map).reduce((aggr, key) => {
    const values = map[key];
    const inverted = values.reduce(
      (inv, value) => ({ ...inv, [value]: key }),
      {},
    );
    return merge(inverted, aggr);
  }, {});
