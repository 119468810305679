import { ErrorContext } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { ApiParams } from '../types/api-params';

const GET_WIDGET_DATA: string = 'get_widget_data';

export const getWidgetData = async (
  params: ApiParams,
  version: number,
  widgetId: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_WIDGET_DATA,
      info: {
        ...params,
        version,
      },
    },
    {
      errorContext,
    },
    { widgetId },
  );
