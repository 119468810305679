import React from 'react';
import {
  Box,
  QuietButton,
  genesisStyled,
  css,
  VisuallyHidden,
  ThemeProps,
  HeadingThree,
  Flex,
  GenesisTheme,
  Image,
  BodyTwo,
  Spinner,
} from '@leagueplatform/genesis-commons';
import { useQueryClient } from 'react-query';
import { GET_WIDGET_DATA } from '@leagueplatform/masonry-api';
import { Modal, Icon, ICONS } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import {
  HighlightBanner,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { UnitSwitcher } from 'components/unit-switcher';
import { MoreOptionsButton } from '@leagueplatform/health-profile-common';
import { UnitOptions } from '@leagueplatform/dashboard-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  useDataTypeCategoryConfigMap,
  isSupportedUnitMetricType,
  useDataTypeConfigMap,
  isSupportedDataCategory,
  UnitMetricTypes,
} from 'constants/use-data-type-config';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { DataCategory } from 'types/data-type';
import { useGetDashboardConfig } from 'hooks/use-get-dashboard-config.hook';
import { usePatchDashboardConfig } from 'hooks/use-patch-dashboard-config';
import { ErrorPanel } from 'components/error-panel';
import { useIsDashboardEnabled } from 'hooks/use-is-dashboard-enabled';
import { StackLayout } from '@leagueplatform/genesis-core';

const StyledModalContainer = genesisStyled(Modal.Container)(
  css({
    position: 'relative',
    maxWidth: 458,
    borderRadius: 'medium',
  }),
);

const StyledModalBody = genesisStyled(Modal.Body)`
  display: grid;
  grid-template-rows: 1fr;
  gap: ${({ theme }: ThemeProps) => `${theme?.space.oneAndHalf}px`};
  padding: ${({ theme }: ThemeProps) => `${theme?.space.fourAndHalf}px`};
  padding-top: ${({ theme }: ThemeProps) => `${theme?.space.four}px`};
`;

type ManageUnitsSectionProps = {
  children: React.ReactNode;
  title: string;
  icon: string;
};

const UnitGroup = genesisStyled(Flex)<{ theme: GenesisTheme }>`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.oneAndHalf}px;
  margin-bottom: ${({ theme }) => theme.space.half}px;
`;

const CategoryHeader = genesisStyled(Flex)<{ theme: GenesisTheme }>`
  align-items: center;
  gap: ${({ theme }) => theme.space.half}px;
`;

function SavingLabel() {
  const { formatMessage } = useIntl();

  return (
    <>
      <Spinner isLoading paddingX="one" paddingY="threeQuarters" />
      <BodyTwo>
        {formatMessage({ id: 'MANAGE_UNITS_APPLYING_CHANGES' })}
      </BodyTwo>
    </>
  );
}

type SavedLabelProps = {
  lastUpdateDate: Date;
};
function SavedLabel({ lastUpdateDate }: SavedLabelProps) {
  const { formatMessage, formatTime } = useIntl();

  return (
    <>
      <Image
        src={
          handleStaticAsset(
            DASHBOARD_ASSET_MAP.DASHBOARD_CHECK_WITH_CIRCLE,
          ) as string
        }
        width="one"
        marginRight="quarter"
        alt=""
      />
      <BodyTwo>
        {formatMessage(
          {
            id: 'MANAGE_UNITS_CHANGES_SAVED',
          },
          {
            time: formatTime(lastUpdateDate, {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }),
          },
        )}
      </BodyTwo>
    </>
  );
}

export function ErrorSaving() {
  const { formatMessage } = useIntl();

  return (
    <StackLayout orientation="horizontal" verticalAlignment="center">
      <Image
        src={
          handleStaticAsset(
            DASHBOARD_ASSET_MAP.DASHBOARD_X_WITH_CIRCLE,
          ) as string
        }
        width="one"
        marginRight="quarter"
        alt=""
      />
      <BodyTwo>{formatMessage({ id: 'ERROR_SAVING_TRY_AGAIN' })}</BodyTwo>
    </StackLayout>
  );
}

type DashboardConfigDataMapType = {
  [K in DataCategory]: UnitOptions[];
};

function ManageUnitsSection({
  title,
  icon,
  children,
}: ManageUnitsSectionProps) {
  return (
    <>
      <CategoryHeader>
        <Image alt="" width="36px" src={icon} />
        <HeadingThree>{title}</HeadingThree>
      </CategoryHeader>
      <UnitGroup>{children}</UnitGroup>
    </>
  );
}

const unitConfigSections: Record<
  Extract<
    UnitMetricTypes,
    | 'distance'
    | 'energy_burned'
    | 'body_height'
    | 'body_weight'
    | 'waist_circumference'
  >,
  DataCategory
> = {
  distance: 'activity',
  energy_burned: 'activity',
  body_height: 'body',
  body_weight: 'body',
  waist_circumference: 'body',
};

// Temporary array for ordered data categories. In the near future, this will
// be provided by the BE and we shouldn't need to hard code it on the FE anymore
const orderedDataCategorySectionKeys: DataCategory[] = ['activity', 'body'];

type ManageUnitsModalProps = {
  onClose: () => void;
  'aria-labelledby': string;
  modalScreenLoadedEvent?: () => void;
};

const ManageUnitsModalContent = () => {
  const {
    isLoading,
    isError: isDashboardConfigError,
    isRefetching,
    data: dashboardConfigData,
    refetch,
  } = useGetDashboardConfig();
  const dashboardConfigMutation = usePatchDashboardConfig();

  const [lastUpdateDate, setLastUpdateDate] = React.useState<Date>();

  React.useEffect(() => {
    if (dashboardConfigMutation.isSuccess) {
      setLastUpdateDate(new Date());
    }
  }, [dashboardConfigMutation.isSuccess]);

  const catergoryConfig = useDataTypeCategoryConfigMap();
  const metricConfig = useDataTypeConfigMap();

  if (isDashboardConfigError) {
    return (
      <ErrorPanel
        isRefetching={isRefetching}
        marginTop="none"
        onRetry={refetch}
      />
    );
  }

  if (isLoading) {
    return <SkeletonBox data-testid="loading" height="300px" />;
  }

  const dashboardConfigDataMap =
    dashboardConfigData &&
    dashboardConfigData.data.unitOptions.reduce<DashboardConfigDataMapType>(
      (acc, unitOption) => {
        const sectionKey = unitConfigSections[unitOption.unitType];
        return {
          ...acc,
          [sectionKey]: [...(acc[sectionKey] ?? []), unitOption],
        };
      },
      {
        activity: [],
        body: [],
      },
    );

  return (
    <>
      {dashboardConfigDataMap && (
        <>
          {orderedDataCategorySectionKeys.map((sectionKey) => {
            const sectionData = dashboardConfigDataMap[sectionKey];
            // Sanity check if for whatever reason an unsupported data type is passed in during runtime we ignore it.
            if (!isSupportedDataCategory(sectionKey) || !sectionData.length) {
              return null;
            }
            return (
              <ManageUnitsSection
                key={sectionKey}
                icon={
                  handleStaticAsset(catergoryConfig.icon(sectionKey)) as string
                }
                title={catergoryConfig.metric(sectionKey)}
              >
                {sectionData.map(({ options, unitType }) => {
                  const selectedUnit = dashboardConfigData.data.units.find(
                    (u) => u.unitType === unitType,
                  )?.unit;

                  if (!isSupportedUnitMetricType(unitType) || !selectedUnit) {
                    return null;
                  }

                  return (
                    <UnitSwitcher
                      key={unitType}
                      title={metricConfig.metric(unitType)}
                      units={options}
                      selectedUnit={selectedUnit}
                      onChange={(unit) => {
                        dashboardConfigMutation.mutate([
                          {
                            unit,
                            unitType,
                          },
                        ]);
                      }}
                    />
                  );
                })}
              </ManageUnitsSection>
            );
          })}
        </>
      )}

      <Flex justifyContent="flex-end" alignItems="center" height="one">
        {dashboardConfigMutation.isLoading ? <SavingLabel /> : null}
        {dashboardConfigMutation.isSuccess && lastUpdateDate ? (
          <SavedLabel lastUpdateDate={lastUpdateDate} />
        ) : null}
        {dashboardConfigMutation.isError && <ErrorSaving />}
      </Flex>
    </>
  );
};

export function ManageUnitsModal({
  onClose,
  'aria-labelledby': ariaLabelledBy,
  modalScreenLoadedEvent,
}: ManageUnitsModalProps) {
  const { formatMessage } = useIntl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => modalScreenLoadedEvent?.(), []);

  return (
    <Modal.Wrapper onClose={onClose} options={null}>
      <StyledModalContainer aria-labelledby={ariaLabelledBy}>
        <Box position="absolute" right="0">
          <QuietButton
            onClick={onClose}
            margin="one"
            paddingX="none"
            paddingY="none"
            size="two"
          >
            <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
          </QuietButton>
        </Box>

        <StyledModalBody>
          <HighlightBanner
            title={formatMessage({ id: 'UPDATE_UNITS_OF_MEASURE_MODAL_TITLE' })}
            backgroundColor="surface.background.tertiary"
            icon={
              handleStaticAsset(
                DASHBOARD_ASSET_MAP.DASHBOARD_EXCLAMATION_MARK_ICON,
              ) as string
            }
            marginBottom="none"
          >
            {formatMessage({ id: 'UPDATE_UNITS_OF_MEASURE_MODAL_DESCRIPTION' })}
          </HighlightBanner>

          <ManageUnitsModalContent />
        </StyledModalBody>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
}

type ManageUnitsLinkProps = {
  onClick?: () => void;
  modalScreenLoadedEvent?: () => void;
};

export function ManageUnitsLink({
  onClick,
  modalScreenLoadedEvent,
}: ManageUnitsLinkProps) {
  const { formatMessage } = useIntl();
  const [showManageUnitsModal, setShowManageUnitsModal] = React.useState(false);
  const { isDashboardEnabled } = useIsDashboardEnabled();
  const queryClient = useQueryClient();

  if (!isDashboardEnabled) {
    return null;
  }

  return (
    <>
      <MoreOptionsButton
        id="manage-units-link"
        onClick={() => {
          setShowManageUnitsModal(true);
          onClick?.();
        }}
      >
        {formatMessage({ id: 'MANAGE_UNITS' })}
      </MoreOptionsButton>
      {showManageUnitsModal ? (
        <ManageUnitsModal
          aria-labelledby="manage-units-link"
          onClose={() => {
            queryClient.invalidateQueries({
              queryKey: [GET_WIDGET_DATA],
            });
            setShowManageUnitsModal(false);
          }}
          modalScreenLoadedEvent={modalScreenLoadedEvent}
        />
      ) : null}
    </>
  );
}
