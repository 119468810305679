import { Box } from './primitives.view';
import { hex2Rgba } from '../utils/helpers';
import styled from 'styled-components';
import { getColourObject } from '../utils/helpers';

const Message = styled(Box).attrs({
  fontSize: 2,
  p: 1,
  borderWidth: '0.1rem',
  borderStyle: 'solid',
})`
  border-color: ${({ theme }) => hex2Rgba(getColourObject(theme).ocean, 0.5)};
  color: ${({ theme }) => getColourObject(theme).black};
  background-color: ${({ theme }) =>
    hex2Rgba(getColourObject(theme).ocean, 0.15)};
`;
Message.displayName = 'Message';

export default Message;
