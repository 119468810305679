import * as React from 'react';
import { Link } from '@leagueplatform/routing';
import { BackButtonArrow } from '@leagueplatform/web-common-components';
import {
  ENABLE_NEW_JOURNEY_EXPERIENCE,
  JOURNEY_ROUTES,
  useBackButtonRouting,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { useHealthProgramsProgramDetails } from '../hooks/use-health-programs-program-details.hook';
import { useProgramDetailsAnalytics } from '../hooks/use-program-details-analytics';

export const ProgramDetailsBackButton = () => {
  const { name, status, programId, goBackPath } =
    useHealthProgramsProgramDetails();

  const programDetailsAnalytics = useProgramDetailsAnalytics(
    name,
    programId,
    status,
  );

  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );
  const backButtonRoute = isNewJourneyExperienceEnabled
    ? JOURNEY_ROUTES.programsHome()
    : goBackPath;
  const { backButtonHref, backButtonOnClick } = useBackButtonRouting({
    backButtonRoute,
    onClickFunc: programDetailsAnalytics.detailsBackButton,
  });

  return (
    <BackButtonArrow
      as={Link}
      color="onSurface.text.primary"
      data-testid="program-details-back-button"
      fontSize="heading3"
      marginRight="one"
      marginY="half"
      to={backButtonHref}
      onClick={backButtonOnClick}
    />
  );
};
