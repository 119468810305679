import { useMutation, useQueryClient } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  CHALLENGES_PATH,
  deleteUserChallenge,
} from '@leagueplatform/health-journey-api';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';

export const useLeaveChallenge = () => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { data: challengeData } = useGetChallengeByIdData();
  const queryClient = useQueryClient();

  // using non-null assertion operator here. In order for the modal to be displayed,
  // we know that a user challenge ID must exist so don't need to do optional checks
  const { id: userChallengeId } = challengeData!.data.userChallenge!.data;

  return useMutation(() => deleteUserChallenge(userChallengeId), {
    onSuccess: () => {
      queryClient.invalidateQueries([CHALLENGES_PATH, challengeId]);
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'SUCCESSFULLY_LEFT_CHALLENGE',
      });
    },
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'UNABLE_TO_LEAVE_CHALLENGE',
        shouldAutoClose: false,
      });
    },
  });
};
