import { useQuery } from 'react-query';
import {
  GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
  getActiveCampaignLimitInfo,
} from '@leagueplatform/health-journey-api';

export const useActiveCampaignInfo = () =>
  useQuery(
    GET_ACTIVE_CAMPAIGN_LIMIT_INFO_MESSAGE_TYPE,
    getActiveCampaignLimitInfo,
    { staleTime: Infinity },
  );
