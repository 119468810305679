import React, { forwardRef } from 'react';
import { Box, BoxProps } from '../Box';

export interface BackgroundImageProps extends BoxProps {
  src: string;
  alt: string;
}

export const BackgroundImage: React.FunctionComponent<BackgroundImageProps> =
  forwardRef(
    (
      {
        src,
        alt,
        size,
        width,
        height,
        backgroundSize = 'cover',
        backgroundRepeat = 'no-repeat',
        backgroundPosition = '50%',
        ...props
      }: BackgroundImageProps,
      ref
    ) => (
      <Box
        ref={ref}
        backgroundImage={`url(${src})`}
        backgroundSize={backgroundSize}
        backgroundRepeat={backgroundRepeat}
        backgroundPosition={backgroundPosition}
        width={width || size || '100%'}
        height={height || size}
        {...(alt
          ? {
              'aria-label': alt,
              role: alt === '' ? 'decorative' : 'img',
            }
          : null)}
        {...props}
      />
    )
  );

BackgroundImage.displayName = 'BackgroundImage';
