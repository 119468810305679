import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  LocationParamsWithReferrer,
  getLocationWithReferrer,
} from './utils/capability-routing.utils';

export const JOURNEY_ROUTES = {
  memberHome: '/member/home',
  journeyHome: () => getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
  journeyHistoryHome: () =>
    getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
  journeySuggested: () =>
    `${getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}/suggested`,
  programsHome: () => getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
  progressHome: () => getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
  achievementsHome: () =>
    getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements),
  challengesHome: () =>
    `${getModulePath(LEAGUE_MODULE_NAMES.healthJourney)}/challenges`,
  getActivityDetails: (
    activityId: string,
    type: string,
    locationParams?: LocationParamsWithReferrer,
  ) =>
    type === 'healthJourney_toolboxActivity'
      ? JOURNEY_ROUTES.getToolboxActivityDetails(activityId, locationParams)
      : getLocationWithReferrer({
          ...locationParams,
          pathname: `${JOURNEY_ROUTES.journeyHome()}/activity/${activityId}`,
        }),
  getToolboxActivityDetails: (
    activityId: string,
    locationParams?: LocationParamsWithReferrer,
  ) =>
    getLocationWithReferrer({
      ...locationParams,
      pathname: `${JOURNEY_ROUTES.journeyHome()}/activity/${activityId}/toolbox`,
    }),
  getActivitySteps: (
    activityId: string,
    type: string,
    locationParams?: LocationParamsWithReferrer,
  ) =>
    type === 'healthJourney_toolboxActivity'
      ? JOURNEY_ROUTES.getToolboxActivityDetails(activityId, locationParams)
      : getLocationWithReferrer({
          ...locationParams,
          pathname: `${JOURNEY_ROUTES.journeyHome()}/activity/${activityId}/steps`,
        }),
  getSuggestedActivity: (activityId: string) =>
    `${JOURNEY_ROUTES.journeySuggested()}/${activityId}`,
  getChallengeDetails: (challengeId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}`,
  getChallengeSettings: (challengeId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}/settings`,
  getChallengeTeamDetails: (challengeId: string, teamId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}/team/${teamId}`,
  getChallengeJoinTeam: (challengeId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}/join-team`,
  getChallengeCompletedActivities: (challengeId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}/activities/completed`,
  getChallengeMissedActivities: (challengeId: string) =>
    `${JOURNEY_ROUTES.challengesHome()}/${challengeId}/activities/missed`,
  getProgramDetails: (programId: string) =>
    `${JOURNEY_ROUTES.programsHome()}/${programId}`,
  getProgramCategory: (categoryId: string) =>
    `${JOURNEY_ROUTES.programsHome()}/category/${categoryId}`,
  getCompletedActivityHistory: () =>
    `${JOURNEY_ROUTES.progressHome()}/completed-history`,
  getMissedActivityHistory: () =>
    `${JOURNEY_ROUTES.progressHome()}/missed-history`,
  getStandaloneActivities: () => `${JOURNEY_ROUTES.progressHome()}/standalone`,
  getAllChallengeType: () => `${JOURNEY_ROUTES.programsHome()}/all-challenges`,
  getRewardProgramOverview: (userHealthCampaignId: string) =>
    `${getModulePath(
      LEAGUE_MODULE_NAMES.healthJourney,
    )}/reward-program/${userHealthCampaignId}`,
  getRewardProgramProgress: (userHealthCampaignId: string) =>
    `${getModulePath(
      LEAGUE_MODULE_NAMES.healthJourney,
    )}/reward-program/${userHealthCampaignId}?page=progress`,
};
