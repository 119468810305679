import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Modal } from '@leagueplatform/ui-kit';
import {
  BodyOne,
  HeadingTwo,
  Image,
  SubtitleOne,
  QuietButton,
  VisuallyHidden,
  Box,
} from '@leagueplatform/genesis-commons';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ASSESSMENT_ASSET_KEYS } from '../../assessment.types';
import { PRODUCT_AREA } from '../../constants/analytics.constants';

const ModalContainer = styled(Modal.Container)`
  position: relative;
  border: none;
  margin: 0 auto;
`;

export const OnboardingModal = ({
  data: { title, subtitle, content },
  showModal,
  healthAssessmentIsEditing,
}) => {
  const handleClose = () => {
    showModal(false);
  };

  usePageViewAnalytics(
    {
      product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
      screen_name: title?.toLowerCase(),
      has_health_profile: healthAssessmentIsEditing,
    },
    [title, healthAssessmentIsEditing],
  );

  const { formatMessage } = useIntl();
  return (
    <Modal.Wrapper onClose={handleClose}>
      <ModalContainer
        maxWidth="900px"
        maxHeight="100%"
        borderRadius="12px"
        bg="white"
        p={4}
      >
        <QuietButton
          borderColor="transparent"
          borderRadius="circle"
          borderStyle="solid"
          borderWidth="thin"
          onClick={handleClose}
          paddingX="none"
          paddingY="none"
          size="two"
          position="absolute"
          right="two"
          top="two"
        >
          <VisuallyHidden display="block">
            {formatMessage({ id: 'CLOSE' })}
          </VisuallyHidden>
          <Image
            role="presentation"
            size="one"
            display="block"
            src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.CROSS)}
          />
        </QuietButton>
        <HeadingTwo>{title}</HeadingTwo>
        <BodyOne color="onSurface.text.subdued" marginTop="half">
          {subtitle}
        </BodyOne>
        <Box marginTop="quarter">
          {content.map(({ title: contentTitle, iconId, description }) => (
            <Flex flexDirection="column" key={contentTitle}>
              <Image
                aria-hidden
                marginTop="two"
                size="oneAndHalf"
                role="presentation"
                src={getContentUrl(iconId)}
              />
              <SubtitleOne as="h3" marginTop="quarter">
                {contentTitle}
              </SubtitleOne>
              <BodyOne color="onSurface.text.subdued" marginTop="half">
                {description}
              </BodyOne>
            </Flex>
          ))}
        </Box>
      </ModalContainer>
    </Modal.Wrapper>
  );
};

OnboardingModal.propTypes = {
  data: PropTypes.shape({
    eyebrowHeadline: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    subtitle: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    content: PropTypes.array,
  }),
  showModal: PropTypes.func.isRequired,
  healthAssessmentIsEditing: PropTypes.bool.isRequired,
};

OnboardingModal.defaultProps = {
  data: {},
};
