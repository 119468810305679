import React, { ReactNode } from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  PillTabs,
  PillTabList,
  PillTab,
} from '@leagueplatform/web-common-components';

interface GlobalLeaderboardToggleProps {
  teamsEnabled: boolean;
  // leaderboardView is referencing the tab index value from reach that's being used in the leaderboard podium component for the toggle: index 0 is for the individual view and 1 the team view
  leaderboardViews: string[];
  setLeaderboardView: React.Dispatch<React.SetStateAction<number>>;
  isLoadingOrError: boolean;
  children: ReactNode;
}

interface TogglePodiumContainerProps {
  teamsEnabled: boolean;
  isLoadingOrError: boolean;
  children: ReactNode;
}

const TogglePodiumContainer = ({
  teamsEnabled,
  isLoadingOrError,
  children,
}: TogglePodiumContainerProps) => {
  if (teamsEnabled || !isLoadingOrError) {
    return (
      <Box
        backgroundColor="surface.background.secondary"
        borderColor="onSurface.border.subdued"
        borderRadius="small"
        borderWidth="thin"
        marginBottom="two"
        padding={{ _: 'one', tablet: 'two' }}
        textAlign="center"
      >
        {children}
      </Box>
    );
  }
  return <Box>{children}</Box>;
};

export const GlobalLeaderboardToggle = ({
  teamsEnabled,
  leaderboardViews,
  setLeaderboardView,
  isLoadingOrError,
  children,
}: GlobalLeaderboardToggleProps) => {
  const { formatMessage } = useIntl();

  return (
    <TogglePodiumContainer
      teamsEnabled={teamsEnabled}
      isLoadingOrError={isLoadingOrError}
    >
      <PillTabs
        defaultValue={leaderboardViews[0]}
        onValueChange={(value) => {
          setLeaderboardView(leaderboardViews.indexOf(value));
        }}
      >
        {teamsEnabled && (
          <Box width="fit-content" marginX="auto" marginBottom="two">
            <PillTabList>
              <PillTab value={leaderboardViews[0]}>
                {formatMessage({ id: 'INDIVIDUAL_CAP' })}
              </PillTab>
              <PillTab value={leaderboardViews[1]}>
                {formatMessage({ id: 'TEAMS' })}
              </PillTab>
            </PillTabList>
          </Box>
        )}
        {children}
      </PillTabs>
    </TogglePodiumContainer>
  );
};
