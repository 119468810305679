import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useHistory, Link as RouteLink } from '@leagueplatform/routing';
import {
  Box,
  Flex,
  HeadingThree,
  Link,
  Overline,
  BodyTwo,
  Image,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import {
  MORE_INFORMATION_NEEDED,
  SUBMITTED,
  APPROVED,
  REJECTED,
  PAID,
} from '@leagueplatform/web-common';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  StatusBadge,
  STATUS_OPTIONS,
} from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { useClaimStatusMessageTranslation } from '../../hooks/queries/use-claim-status-message-translation';
import { CLAIMS_ASSET_KEYS } from '../../types/claims';
import { useClaimsQuery } from '../../hooks/queries/use-claims-query.hook';

const noDataPlaceholder = '---';

const StyledBodyTwo = genesisStyled(BodyTwo)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClaimsBox = genesisStyled(Box)(
  css({
    overflowX: 'auto',
    '&:focus': {
      boxShadow: 'rgba(0, 0, 0, 0.5)',
      outline: 'interactive.focus.outer.outer',
    },
  }),
);

const CLAIMS_HISTORY_URL = '/member/claims-history';
const CLAIMS_HEADING_ID = 'recent-claims-section';

// TODO: Decide if claim url should be taken as a prop
const getClaimUrl = (claimId: string) =>
  `/member/claims/${claimId}?source=wallet`;
interface RecentClaimsProps {
  userId?: string;
}

const mapClaimStatus = (claimStatus: string) => {
  switch (claimStatus) {
    case MORE_INFORMATION_NEEDED:
      return STATUS_OPTIONS.WARNING;
    case SUBMITTED:
      return STATUS_OPTIONS.HIGHLIGHT;
    case APPROVED:
      return STATUS_OPTIONS.HIGHLIGHT;
    case REJECTED:
      return STATUS_OPTIONS.CRITICAL;
    case PAID:
      return STATUS_OPTIONS.SUCCESS;
    default:
      return STATUS_OPTIONS.DISABLED;
  }
};

export const RecentClaims = ({ userId }: RecentClaimsProps) => {
  const { data: { claims = [] } = {} } = useClaimsQuery(userId);
  const history = useHistory();
  const { formatMessage, $tifelse, formatDate, formatMoney } = useIntl();
  const needMoreInfo = claims.filter(
    (claim) => claim.status === MORE_INFORMATION_NEEDED,
  ).length;

  function onClaimClick(status: string) {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      category: 'Wallet',
      action: 'View Claim Detail',
      label: status,
    });
  }

  const getClaimStatusMessage = useClaimStatusMessageTranslation();

  return (
    <Box>
      <Flex justifyContent="space-between">
        <HeadingThree id={CLAIMS_HEADING_ID} as="h2">
          {formatMessage({ id: 'RECENT_CLAIMS' })}
        </HeadingThree>
        <Link
          as={RouteLink}
          to={CLAIMS_HISTORY_URL}
          onClick={() => {
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              category: 'Wallet',
              action: 'View All Claims',
            });
          }}
        >
          {formatMessage({ id: 'VIEW_ALL_CLAIMS' })}
        </Link>
      </Flex>
      {needMoreInfo > 0 && (
        <Flex
          marginTop="oneAndHalf"
          marginBottom="one"
          paddingY="half"
          paddingX="one"
          backgroundColor="warning.background.subdued"
          borderStyle="solid"
          borderWidth="thin"
          borderColor="warning.border.default"
          borderRadius="medium"
          alignItems="center"
        >
          <Image
            src={
              handleStaticAsset(CLAIMS_ASSET_KEYS.WARNING_ICON_ORANGE) as string
            }
            role="presentation"
            height="oneAndHalf"
            width="oneAndHalf"
            marginRight="one"
          />
          <Box>
            <BodyTwo fontWeight="medium" display="inline">
              {formatMessage({ id: 'PLEASE_NOTE' })}:
            </BodyTwo>
            <BodyTwo marginLeft="quarter" display="inline">
              {$tifelse(
                needMoreInfo > 1,
                'NEED_MORE_INFO_MULTIPLE_CLAIMS',
                'NEED_MORE_INFO_SINGLE_CLAIM',
              )}
            </BodyTwo>
          </Box>
        </Flex>
      )}
      <ClaimsBox role="region" aria-labelledby={CLAIMS_HEADING_ID} tabIndex={0}>
        <Box
          as="table"
          width={1}
          marginTop="one"
          aria-labelledby={CLAIMS_HEADING_ID}
        >
          <thead>
            <Box
              as="tr"
              textAlign="left"
              padding="one"
              paddingTop="none"
              borderBottomStyle="solid"
              borderBottomWidth="thin"
            >
              <Overline
                as="th"
                scope="col"
                color="onSurface.text.subdued"
                padding="one"
                minWidth={136}
              >
                {formatMessage({ id: 'STATUS' })}
              </Overline>
              <Overline
                as="th"
                scope="col"
                color="onSurface.text.subdued"
                padding="one"
                maxWidth={200}
              >
                {formatMessage({ id: 'CLAIM_REF_NUMBER' })}
              </Overline>
              <Overline
                as="th"
                scope="col"
                color="onSurface.text.subdued"
                padding="one"
                maxWidth={200}
              >
                {formatMessage({ id: 'VENDOR' })}
              </Overline>
              <Overline
                as="th"
                scope="col"
                color="onSurface.text.subdued"
                padding="one"
              >
                {formatMessage({ id: 'LAST_UPDATED' })}
              </Overline>
              <Overline
                as="th"
                scope="col"
                color="onSurface.text.subdued"
                padding="one"
                textAlign="right"
              >
                {formatMessage({ id: 'AMOUNT' })}
              </Overline>
              <Box as="th" padding="one" />
            </Box>
          </thead>
          <tbody>
            {claims.map((claim, index) => {
              // Apparently amount_claimed is not always present on a claim
              // If neither is present, the value will fall back to placeholder
              const amount =
                claim.amount_approved || claim.amount_claimed || {};
              const hasAmount = amount && !!amount.value;
              const CLAIM_URL = getClaimUrl(claim.claim_id);
              const badgeStatus = mapClaimStatus(claim.status);
              return (
                <Box
                  as="tr"
                  key={claim.claim_id}
                  borderBottomStyle={
                    index === claims.length - 1 ? 'none' : 'solid'
                  }
                  borderBottomWidth="thin"
                  cursor="pointer"
                  hoverStyle={{
                    backgroundColor: 'surface.background.secondary',
                  }}
                  onClick={() => {
                    history.push(CLAIM_URL);
                    onClaimClick(claim.status);
                  }}
                >
                  <Box
                    as="td"
                    padding="one"
                    verticalAlign="middle"
                    minWidth={136}
                  >
                    <StatusBadge
                      status={badgeStatus}
                      label={getClaimStatusMessage(claim.status)}
                      displayIcon
                      smallText
                    />
                  </Box>
                  <StyledBodyTwo
                    as="td"
                    padding="one"
                    maxWidth={200}
                    verticalAlign="middle"
                  >
                    {claim.reference_id || noDataPlaceholder}
                  </StyledBodyTwo>
                  <StyledBodyTwo
                    as="td"
                    padding="one"
                    maxWidth={200}
                    verticalAlign="middle"
                  >
                    {claim.vendor || noDataPlaceholder}
                  </StyledBodyTwo>
                  <BodyTwo as="td" padding="one" verticalAlign="middle">
                    {formatDate(claim.last_event_timestamp, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </BodyTwo>
                  <BodyTwo
                    as="td"
                    padding="one"
                    verticalAlign="middle"
                    textAlign="right"
                  >
                    {hasAmount
                      ? formatMoney({ F: amount.value, C: amount.currency })
                      : noDataPlaceholder}
                  </BodyTwo>
                  <Box as="td" padding="one" verticalAlign="middle">
                    <Link
                      as={RouteLink}
                      to={CLAIM_URL}
                      onClick={() => {
                        onClaimClick(claim.status);
                      }}
                      aria-label={formatMessage(
                        { id: 'VIEW_CLAIM_LABEL_HIDDEN' },
                        {
                          vendor: claim.vendor,
                          referenceId: claim.reference_id,
                        },
                      )}
                    >
                      <Image
                        height={12}
                        src={
                          handleStaticAsset(
                            CLAIMS_ASSET_KEYS.CHEVRON_RIGHT,
                          ) as string
                        }
                        role="presentation"
                      />
                    </Link>
                  </Box>
                </Box>
              );
            })}
          </tbody>
        </Box>
      </ClaimsBox>
    </Box>
  );
};
