/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { LinkProps, NavLink } from '@leagueplatform/routing';
import {
  Box,
  BoxProps,
  css,
  Flex,
  FlexProps,
  genesisStyled,
  Link,
  TextColorPropsType,
} from '@leagueplatform/genesis-commons';

type GenesisLinkProps = Partial<Omit<HTMLAnchorElement, 'children'>> &
  BoxProps & {
    href?: string;
    disabled?: boolean;
  };

type StyledLinkProps = GenesisLinkProps & {
  isMobile?: boolean;
  decorativeBarColor?: TextColorPropsType;
};

type StyledNavLinkProps = LinkProps & StyledLinkProps;

type AppBarLinkProps = StyledLinkProps & {
  listItemProps?: FlexProps;
};

type AppBarNavLinkProps = AppBarLinkProps & StyledNavLinkProps;

const StyledLink = ({ children, ...props }: GenesisLinkProps) => (
  <Link
    display="flex"
    position="relative"
    alignSelf="stretch"
    alignItems="center"
    color="onSurface.text.subdued"
    fontSize="subtitle2"
    textAlign="center"
    paddingX={{ _: props?.isMobile ? 0 : 'quarter', laptop: 'half' }}
    textDecoration={'none !important' as 'none'}
    hoverStyle={{ color: 'onSurface.text.primary' }}
    {...props}
  >
    <Box as="span">{children}</Box>
  </Link>
);

const StyledNavLink = genesisStyled((props: StyledNavLinkProps) => (
  <StyledLink as={NavLink} {...props} />
))((props: StyledNavLinkProps) =>
  css({
    '&[aria-current="page"]': {
      color: !props.hoverStyle?.color
        ? 'onSurface.text.primary'
        : props.hoverStyle.color,
    },
    '&[aria-current="page"]:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: -1,
      left: 0,
      backgroundColor: props?.isMobile
        ? 'transparent'
        : props?.decorativeBarColor || 'interactive.action.primary',
      height: 'quarter',
      borderRadius: 'large',
      width: '100%',
    },
  }),
);

/**
 * A List item wrapper that can be used inside the AppBarComponent.
 * @param {node} children - child components, any component you want to display as a nav item
 * @param {object} props - any additional properties to pass to the flex "li" component
 */
export const AppBarItem = ({ children, ...props }: FlexProps) => (
  <Flex as="li" flexGrow={1} alignSelf="stretch" {...props}>
    {children}
  </Flex>
);

/**
 * A Link that can be used inside the AppBarComponent.
 * @param {node} children - child components, usually a string to be displayed in the link
 * @param {object} listItemProps - object of properties to be passed exclusively to the li parent of the link
 * @param {object} props - any additional properties to pass to the link component (onClick, to, etc)
 */

export const AppBarLink = ({
  children,
  listItemProps,
  ...props
}: AppBarLinkProps) => (
  <AppBarItem {...listItemProps}>
    <StyledLink {...props}>{children}</StyledLink>
  </AppBarItem>
);

/**
 * A NavLink that can be used inside the AppBarComponent.
 * @param {node} children - child components, usually a string to be displayed in the link
 * @param {object} listItemProps - object of properties to be passed exclusively to the li parent of the link
 * @param {object} props - any additional properties to pass to the link component (onClick, to, etc)
 */
export const AppBarNavLink = ({
  children,
  listItemProps,
  ...props
}: AppBarNavLinkProps) => (
  <AppBarItem {...listItemProps}>
    <StyledNavLink {...props}>{children}</StyledNavLink>
  </AppBarItem>
);
