import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { StyledImg } from '../common';

export type VerticalLargeImageCardProps = {
  image: string;
  imageAltText: string;
  children: React.ReactElement;
};

export const VerticalLargeImageCard = ({
  image,
  imageAltText,
  children,
}: VerticalLargeImageCardProps) => (
  <StackLayout
    orientation="vertical"
    verticalAlignment="spaceBetween"
    horizontalAlignment="stretch"
    spacing="$none"
  >
    <StyledImg
      borderTopRightRadius="medium"
      borderTopLeftRadius="medium"
      borderStyle="solid"
      src={image}
      width="100%"
      height="176px"
      alt={imageAltText}
    />
    {children}
  </StackLayout>
);
