import React from 'react';

export interface GDSStatusBannerEmojiProps {
  label: string;
  children: React.ReactNode;
}

export const StatusBannerEmoji = ({
  label,
  children,
}: GDSStatusBannerEmojiProps) => {
  if (!label) {
    console.error(
      "StatusBannerEmoji is missing a descriptive label. To be accessible, [role='img'] elements must have an alternative text.",
    );
  }

  return (
    <span role="img" aria-label={label}>
      {children}
    </span>
  );
};
