import React, { useRef } from 'react';
import { Box, Image } from '@leagueplatform/genesis-commons';
import { StackLayout } from '@leagueplatform/web-common-components';
import { useWalletCard } from './hooks/use-wallet-card.hook';
import { Card } from './components/card.component';
import { CardActions } from './components/card-actions.component';

export interface WalletCardProps {
  card?: string;
}

export const WalletCard = ({ card: cardHtml }: WalletCardProps) => {
  const { card, isImageCard, cardFaces } = useWalletCard(cardHtml);

  const cardImagesRef = useRef(null);

  if (!card) {
    return null;
  }

  if (isImageCard) {
    return (
      <Box ref={cardImagesRef} className="wallet-print-card">
        <StackLayout
          space="$one"
          css={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {cardFaces.map((cardFace, i) => (
            <Image
              key={`card-face-${i === 0 ? 'front' : 'back'}`}
              maxWidth="100%"
              loading="eager"
              src={cardFace.src}
              alt={cardFace.description}
            />
          ))}
          <CardActions cardRef={cardImagesRef} />
        </StackLayout>
      </Box>
    );
  }

  return (
    <StackLayout
      space="$one"
      css={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 280 }}
    >
      <Card
        minHeight={168}
        width={280}
        dangerouslySetInnerHTML={{
          __html: card.src,
        }}
        className="wallet-print-card"
        ref={cardImagesRef}
      />
      <CardActions cardRef={cardImagesRef} />
    </StackLayout>
  );
};
