import { WebsocketMessage } from './fetch-as-promise';

/**
 * A callback/subscriber for some WebSocket message type.
 */
export type Listener<Data> = (data: Data) => void;

/**
 * A map from a message type string key to a set of `Listener`s to that message type.
 */
const listenerMap = new Map<
  WebsocketMessage['message_type'],
  Set<Listener<unknown>>
>();

/**
 * Given a message type and a listener, will call that listener when a message
 * of that type is received. Returns a callback which, when called, removes/unsubscribes
 * that listener.
 */
export const subscribeToMessage = <Data extends unknown = unknown>(
  messageType: WebsocketMessage['message_type'],
  listener: Listener<Data>,
) => {
  let listenerSetForThisMessageType = listenerMap.get(messageType);

  if (!listenerSetForThisMessageType) {
    listenerSetForThisMessageType = new Set();
    listenerMap.set(messageType, listenerSetForThisMessageType);
  }

  listenerSetForThisMessageType.add(listener as Listener<unknown>);

  return () => {
    listenerSetForThisMessageType!.delete(listener as Listener<unknown>);
  };
};

export const callListenersWithMessage = (message: WebsocketMessage) => {
  const { message_type: messageType, info: data } = message;
  listenerMap.get(messageType)?.forEach((listener) => {
    listener(data);
  });
};
