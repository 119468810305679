import * as React from 'react';
import {
  StackLayout,
  StackItem,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { SectionHeader } from '../masonry-section-header-renderer/section-header.component';

export interface MasonryVerticalLayoutNodeProperties {
  heading?: string;
  body?: string;
}

export type MasonryVerticalLayoutNode = MasonryEngineNode<
  'verticalLayout',
  MasonryVerticalLayoutNodeProperties,
  {},
  {
    items: MasonryEngineNode<string, any>[];
  }
>;

export type MasonryVerticalLayoutRendererProps =
  MasonryEngineNodeRendererProps<MasonryVerticalLayoutNode>;

export const MasonryVerticalLayoutRenderer = ({
  items,
  heading,
  body,
}: MasonryVerticalLayoutRendererProps) => {
  if (!items) return null;

  return (
    <StackLayout
      spacing="$one"
      horizontalAlignment="stretch"
      orientation="vertical"
    >
      {heading && <SectionHeader heading={heading} />}
      {body && <ParagraphText>{body}</ParagraphText>}
      {React.Children.map(items, (item) => (
        <StackItem verticalAlignment="fillContainer">{item}</StackItem>
      ))}
    </StackLayout>
  );
};
