import {
  HealthMetricInput,
  HealthMetricPostData,
} from '@leagueplatform/dashboard-api';

export function formatMetricPostData(
  formData: any,
  metricInputs: HealthMetricInput[],
  metricConfigId?: string,
) {
  return metricInputs.map((metricInput, index) => {
    const metricFormData: any = {
      metricConfigId,
      metricComponentId: metricInput.metricComponentId,
      value: {},
    };

    if (formData.effectiveDateTime) {
      metricFormData.effectiveDateTime = formData.effectiveDateTime;
    }

    metricInput.valueFields.forEach((valueField) => {
      // create an array of HealthMetricPostData and sort value fields based on their index

      if (formData[`${index}-${valueField.fieldKeyName}`] !== undefined) {
        metricFormData.value.type = metricInput.inputType;

        metricFormData.value[valueField.fieldKeyName] =
          formData[`${index}-${valueField.fieldKeyName}`];
      }
    });

    return metricFormData as HealthMetricPostData;
  });
}
