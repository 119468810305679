import React, { forwardRef, Ref } from 'react';
import {
  Card,
  ParagraphText,
  StackLayout,
  StackItem,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import type { MasonryNotificationBannerRendererProps } from './masonry-notification-banner-renderer.component';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import { MasonryDeeplinkAction } from '../../types/masonry-driver-node-actions';

export const MasonryNotificationBannerContentRenderer = forwardRef(
  (
    {
      image,
      imageAltText,
      heading,
      body,
      onClick,
    }: Omit<
      MasonryNotificationBannerRendererProps,
      'isVisible' | 'rootElementRef' | 'nodeId'
    >,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const onClickDeeplinkAction = onClick as MasonryDeeplinkAction;
    const invokeAction = useMasonryEngineActionEmitter();

    return (
      <StackLayout
        orientation="horizontal"
        verticalAlignment="stretch"
        spacing="$one"
      >
        {image && (
          <StackItem verticalAlignment="center">
            <Card.Image
              image={image}
              imageAlt={imageAltText}
              css={{
                width: '$twoAndHalf',
                height: '$twoAndHalf',
              }}
            />
          </StackItem>
        )}
        <StackLayout spacing="$quarter">
          <HeadingText size="sm" level="2">
            <ConditionalLinkRenderer
              ref={ref}
              url={onClickDeeplinkAction?.payload.url}
              disableDefaultOnClick
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
            >
              {heading}
            </ConditionalLinkRenderer>
          </HeadingText>
          <ParagraphText
            emphasis="subtle"
            htmlStringOptions={BodyTextHtmlTagOptions}
          >
            {body}
          </ParagraphText>
        </StackLayout>
      </StackLayout>
    );
  },
);
