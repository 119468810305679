import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { BENEFIT_CARD_ACTION, SYSTEM_TYPES } from '@leagueplatform/wallet-api';
import { BenefitContentAction } from 'types/benefit-card-action.types';

export function useFallbackTabs() {
  const { formatMessage } = useIntl();

  const fallbackTabs = useMemo(() => {
    const coverageOverviewTab: BenefitContentAction = {
      id: 'overview',
      type: BENEFIT_CARD_ACTION.CONTENT,
      system_type: SYSTEM_TYPES.CoverageOverview,
      name: formatMessage({ id: 'OVERVIEW' }),
      banners: {},
      indicator: false,
    };

    return [coverageOverviewTab];
  }, [formatMessage]);

  return fallbackTabs as BenefitContentAction[];
}
