import { keyframes } from '@stitches/react';
import React, { FC } from 'react';
import { VisuallyHidden } from 'components/visually-hidden/visually-hidden';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import type { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import type { GDSColor, GDSSize } from '../../theme';
import { styled } from '../../theme';
import { Circle } from './circle';
import { useThemeStrings } from '../../hooks/use-theme';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSSpinnerProps extends GDSCustomizableComponent {
  loading?: boolean;
  loadingText?: string;
  replaceContent?: boolean;
  renderLoadingText?: boolean;
  size?: GDSResponsiveProp<number | string | GDSSize>;
  thickness?: number;
  tint?: GDSColor;
  children?: React.ReactNode;
}

const rotate = keyframes({
  to: { transform: 'rotate(360deg)' },
});

const StyledCircle = styled(Circle, {
  display: 'inline-block',
  fill: 'transparent',
  borderRadius: '$circle',
  path: {
    strokeWidth: '$$thickness',
  },
  animation: `${rotate} 1s linear infinite forwards`,
});

const Span = styled('span', {
  lineHeight: 0,
  position: 'relative',
});

Span.displayName = 'Span';

const SpinnerPositioner = styled('span', {
  variants: {
    replaceContent: {
      true: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});

export const Spinner: FC<GDSSpinnerProps> = ({
  children,
  loading,
  loadingText,
  replaceContent = false,
  renderLoadingText = true,
  size,
  tint,
  className,
  thickness,
  css,
  ...props
}: GDSSpinnerProps) => {
  const stringsMap = useThemeStrings();
  const spinnerSize = useResponsiveProp(size);

  return (
    <GenesisCoreInspector displayName="Spinner">
      <Span
        className={['GDS-spinner', className].join(' ')}
        css={css}
        {...props}
      >
        {loading && (
          <>
            <SpinnerPositioner replaceContent={replaceContent}>
              <StyledCircle
                css={{
                  width: spinnerSize,
                  height: spinnerSize,
                  color: tint,
                  $$thickness: thickness,
                }}
              />
            </SpinnerPositioner>
            {renderLoadingText && (
              <VisuallyHidden>
                {loadingText || stringsMap.loadingText}
              </VisuallyHidden>
            )}
          </>
        )}
        {children && (
          <Span css={{ ...(loading && { opacity: 0 }) }}>{children}</Span>
        )}
      </Span>
    </GenesisCoreInspector>
  );
};

Spinner.displayName = 'Spinner';
