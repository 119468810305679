import { leagueFetch } from '@leagueplatform/league-fetch';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

declare global {
  interface Window {
    league?: typeof LeagueConsoleAPI;
  }
}

async function wsSend(message_type: string, info?: { [key: string]: any }) {
  try {
    const response = await SocketAsFetch.fetch({ message_type, info });
    console.log(`🚀 "${message_type}": success`);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = JSON.parse(error.message);
      console.error(
        `💔 "${message_type}": we had a problem with this api call: ${errorMessage?.info?.reason}`,
      );
      console.error(error);
      return errorMessage;
    }
    throw error;
  }
}

// Not comprehensive, but I think we don't need any of the weirder ones?
type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

async function RESTRequest(
  path: string,
  method: HTTPMethod = 'GET',
  body?: BodyInit,
) {
  let response;
  try {
    response = await leagueFetch(path, {
      method,
      body,
    });
  } catch (ex) {
    console.error(`window.league.api.rest ${method} failure`, ex);

    throw ex;
  }

  if (!response.ok) {
    console.error(
      `window.league.api.rest ${method} error response`,
      response.status,
      response.statusText,
    );
  }

  return response;
}

// Object that is installed as `window.league`
const LeagueConsoleAPI = {
  api: {
    ws: wsSend,
    send: wsSend, // legacy name for WS handler
    rest: RESTRequest,
  },
};

// Installs a helper function on the window global that can be used to make WR or REST requests.
// Handles authentication details.
//
// Useful for developer debugging via the console.
//
// E.g.
// window.league.api.send("some_message", { payload: 2 });
// window.league.api.rest('/some/endpoint', 'GET');
// window.league.api.rest('/some/post/endpoint', 'POST', { some_data: 2 });
export function setupLeagueAPI() {
  if (typeof window !== 'undefined' && !window.league) {
    window.league = LeagueConsoleAPI;
  }
}
