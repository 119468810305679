import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { Tabs, Box } from '@leagueplatform/genesis-core';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailAnalytics } from '../../hooks/use-wallet-detail-analytics.hook';
import { useWalletDetailTabsQuery } from '../../hooks/queries/use-wallet-detail-tabs-query.hook';
import { useWalletTabsContentQuery } from '../../hooks/queries/use-wallet-tabs-content-query.hook';
import { useBenefitCardActionTabs } from './hooks/use-benefit-card-action-tabs.hook';

export function NavigationTabs() {
  const { userId, benefitId } = useWalletParams();
  const { formatMessage } = useIntl();
  const { data: tabsData, isLoading } = useWalletDetailTabsQuery(
    userId,
    benefitId,
  );
  const contentPanels = useWalletTabsContentQuery(userId, benefitId);
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  const { tabs, activeTab, setActiveTab } = useBenefitCardActionTabs(tabsData);

  const handleClick = React.useCallback(
    (value: string) => {
      const clickedTab = tabs.find((tab) => tab.tabValue === value);

      sendBenefitAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
        detail: clickedTab?.name,
        is_notification_dot: clickedTab?.indicator,
      });

      setActiveTab(value);
    },
    [tabs, sendBenefitAnalyticsEvent, setActiveTab],
  );

  if (isLoading || tabs.length === 0) {
    return null;
  }

  // If only 1 tab show just the one component
  if (tabs.length === 1) {
    const TabComponent = contentPanels[0];
    const { body } = tabs[0];

    return <TabComponent body={body} />;
  }

  return (
    <Tabs.Root onValueChange={handleClick} value={activeTab}>
      <Tabs.TabList divider tabDistribution="hugContents">
        {tabs.map((tab) => (
          <Tabs.Tab key={tab.tabValue} value={tab.tabValue}>
            <Box as="span" css={{ position: 'relative' }}>
              {tab.name}
              {tab.indicator && (
                <Box
                  css={{
                    display: 'block',
                    borderRadius: '$circle',
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    backgroundColor: '$criticalIcon',
                    width: '$half',
                    height: '$half',
                    transform: 'translate(100%, -50%)',
                  }}
                  as="span"
                  id={`${tab.tabValue}-indicator`}
                  aria-label={
                    tab.indicator_description ??
                    formatMessage({
                      id: 'DEPENDENT_NOTIFICATION_MESSAGE',
                    })
                  }
                  aria-hidden
                />
              )}
            </Box>
          </Tabs.Tab>
        ))}
      </Tabs.TabList>

      {tabs.map((tab, index) => {
        const TabComponent = contentPanels[index];

        return (
          <Tabs.TabPanel
            css={{ paddingBlock: '$oneAndHalf', paddingInline: '$none' }}
            key={tab.tabValue}
            value={tab.tabValue}
          >
            <TabComponent body={tab.body} />
          </Tabs.TabPanel>
        );
      })}
    </Tabs.Root>
  );
}
