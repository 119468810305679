import {
  ACTION_TYPES,
  BenefitCardAction,
  TAB_SYSTEM_TYPES,
  TAB_SYSTEM_TYPE,
} from '@leagueplatform/wallet-api';

export function isBenefitTab(action: BenefitCardAction) {
  return (
    action.type === ACTION_TYPES.CONTENT ||
    TAB_SYSTEM_TYPES.includes(action.system_type as TAB_SYSTEM_TYPE)
  );
}

export const isBenefitActionLink = (action: BenefitCardAction) =>
  !isBenefitTab(action);
