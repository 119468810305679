import * as React from 'react';
import {
  Box,
  css,
  genesisStyled,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { SidebarCard } from '@leagueplatform/web-common-components';

// eslint-disable-next-line react/jsx-props-no-spreading
const LinksContainer = genesisStyled((props) => <Box as="ul" {...props} />)(
  css({
    'li:not(:last-child)': {
      borderBottomColor: 'onSurface.border.subdued',
      borderBottomStyle: 'solid',
      borderBottomWidth: 'thin',
    },
  }),
);

type MoreOptionsCardProps = {
  children?: React.ReactNode;
};

export function MoreOptionsCard({ children }: MoreOptionsCardProps) {
  const { formatMessage } = useIntl();

  return (
    <SidebarCard
      as="nav"
      aria-labelledby="more-options-heading"
      paddingTop={{ laptop: 'oneAndHalf' }}
      paddingX={{ laptop: 'oneAndHalf' }}
      paddingBottom={{ laptop: 'half' }}
    >
      <HeadingThree id="more-options-heading" marginBottom="one" as="h2">
        {formatMessage({ id: 'MORE_OPTIONS' })}
      </HeadingThree>
      <LinksContainer>{children}</LinksContainer>
    </SidebarCard>
  );
}
