import React from 'react';
import {
  Box,
  BodyOne,
  Image,
  HeadingTwo,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { Link } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HealthProgramsProgramDetailsModal } from './health-programs-program-details-modal.component';
import { useGetHealthProgram } from '../hooks/use-get-health-program.hook';

interface Props {
  onClose: () => void;
  programId: string;
}

export const LeaveProgramPulseCheckModal = ({ onClose, programId }: Props) => {
  const { formatMessage } = useIntl();
  const { data: programData } = useGetHealthProgram(programId);
  const program = programData?.program || {};

  const { campaign_content_config: contentConfig } = program;
  const pulseCheckUrl = contentConfig?.pulse_checks[0]?.ctaUrl.replace(
    /^\/app\//,
    '/',
  );
  const pulseCheckText = contentConfig?.pulse_checks[0]?.ctaText;

  // Static Assets
  const garbageIcon = handleStaticAsset(
    HEALTH_PROGRAMS_ASSET_KEYS.GARBAGE_ICON,
  ) as string;

  return (
    <HealthProgramsProgramDetailsModal
      onClose={onClose}
      aria-labelledby="program-removed"
    >
      <Box textAlign="center">
        <Image width="120px" src={garbageIcon} alt="" />
        <HeadingTwo id="program-removed" marginTop="half" marginBottom="half">
          {formatMessage({ id: 'PROGRAM_REMOVED' })}
        </HeadingTwo>
        <BodyOne color="onSurface.text.subdued" marginBottom="two">
          {formatMessage({ id: 'YOU_WILL_NO_LONGER_SEE_IN_JOURNEY' })}
        </BodyOne>
        <PrimaryButton
          data-testid="remove-program-pulse-check"
          as={Link}
          to={pulseCheckUrl}
          textDecoration="none"
        >
          {pulseCheckText}
        </PrimaryButton>
      </Box>
    </HealthProgramsProgramDetailsModal>
  );
};
