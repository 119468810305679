import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  SubtitleTwo,
  Overline,
  Image,
} from '@leagueplatform/genesis-commons';

/**
 * HighlightBanner Component
 *
 * Renders a box with a colored background containing a title,
 * optional icon, and body content. Color and background color
 * can be optionally modified as props.
 *
 * @param title - The title of the banner
 * @param icon - optional icon rendered to the left of the title
 * @param backgroundColor - optional background color. Defaults to highlight.background.subdued
 * @param color - optional text color. Defaults to onSurface.text.primary
 * @param marginBottom - optional bottom margin. Defaults to twoAndHalf
 * @param children - body content of the banner
 *
 */
export const HighlightBanner = ({
  title,
  icon,
  backgroundColor,
  color,
  marginBottom,
  children,
}) => (
  <Box
    backgroundColor={backgroundColor}
    borderRadius="medium"
    marginBottom={marginBottom}
    padding="one"
  >
    <Flex alignItems="center">
      {icon && (
        <Image src={icon} alt="" width="oneAndHalf" marginRight="half" />
      )}
      <Overline as="p" color={color}>
        {title}
      </Overline>
    </Flex>
    <SubtitleTwo as="p" fontWeight="regular" marginTop="half" color={color}>
      {children}
    </SubtitleTwo>
  </Box>
);

HighlightBanner.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  marginBottom: PropTypes.string,
  children: PropTypes.node.isRequired,
};
HighlightBanner.defaultProps = {
  icon: null,
  backgroundColor: 'highlight.background.subdued',
  color: 'onSurface.text.primary',
  marginBottom: 'twoAndHalf',
};
