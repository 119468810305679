import * as React from 'react';

import { genesisStyled, css } from '@leagueplatform/genesis-commons';
import { useEffect, useState } from 'react';
import {
  Tabs,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';

const TabsStyled = genesisStyled(Tabs.Root)(() =>
  css({
    width: '100%',
  }),
);

const TabListStyled = genesisStyled(Tabs.TabList)(() =>
  css({
    borderRadius: ['none', 'small'],
    borderColor: 'onSurface.border.subdued',
    borderWidth: ['none', 'thin'],
    borderBottomWidth: 'thin',
    borderStyle: 'solid',
    paddingX: [0, 'oneAndHalf'],
    height: ['36px', '56px'],
    width: '100%',
  }),
);

const TabItemStyled = genesisStyled(Tabs.Tab)(() =>
  css({
    fontSize: 'subtitle1',
    fontWeight: 600,
    fontStyle: 'normal',

    '&::after': {
      height: ['2px', '3px'],
      borderTopLeftRadius: [0, 'small'],
      borderTopRightRadius: [0, 'small'],
    },
  }),
);

type TabsType = {
  tabName: string;
  tabContent: React.ReactElement;
  tabKey?: string;
  onClick?: Function;
};

interface RewardsProgramTabsProps {
  tabs: TabsType[];
  currentTab?: string;
}

export const ProgramTabs = ({ tabs, currentTab }: RewardsProgramTabsProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('laptop'));

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.tabKey === currentTab);

    if (index >= 0) {
      setTabIndex(index);
    }
  }, [currentTab, tabs]);

  const onTabClick = (nextIndex: number) => {
    const { onClick = () => {} } = tabs[nextIndex];

    if (nextIndex !== tabIndex) {
      onClick();
    }
  };

  return (
    <TabsStyled value={tabIndex.toString()}>
      <TabListStyled tabDistribution="hugContents">
        {tabs.map((tab, index) => (
          <TabItemStyled
            onClick={() => onTabClick(index)}
            value={index.toString()}
            css={{ flexGrow: isMobile ? 1 : 0 }}
            key={`${tab.tabName}-tab`}
          >
            {tab.tabName}
          </TabItemStyled>
        ))}
      </TabListStyled>

      {tabs.map((tab, index) => {
        const tabContent = React.cloneElement(tab.tabContent, {
          isVisible: index === tabIndex,
        });
        return (
          <Tabs.TabPanel
            css={{ padding: '$none' }}
            value={index.toString()}
            key={`${tab.tabName}-panel`}
          >
            {tabContent}
          </Tabs.TabPanel>
        );
      })}
    </TabsStyled>
  );
};
