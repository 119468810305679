import React, { useRef, ReactNode } from 'react';
import { Box, genesisStyled } from '@leagueplatform/genesis-commons';
import { useHandleDropdownWithModal } from 'pages/challenge-landing-page/hooks/use-handle-dropdown-with-modal.hook';

const SETTINGS_DROPDOWN_FIXED_WIDTH = '260px';

const StyledDropdown = genesisStyled(Box)`
  & > li:last-child {
    border-bottom: 0px;
  }
`;
export const SETTINGS_BUTTON_LABEL = 'settings_button_label';

export interface SettingsDropdownProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  modalIsOpen: boolean;
}

export const SettingsDropdown = ({
  isOpen,
  setIsOpen,
  children,
  modalIsOpen,
}: SettingsDropdownProps) => {
  const dropdownRef = useRef<HTMLUListElement>(null);

  /*
    When dropdown is open and modal is CLOSED
     - We want to be able to click outside of the dropdown to close it
    When dropdown is open and modal is OPEN
     - We don't want the dropdown to be able to close at all
  */
  useHandleDropdownWithModal({
    isOpen,
    setIsOpen,
    ref: dropdownRef,
    modalIsOpen,
  });
  return (
    <StyledDropdown
      as="ul"
      role="menu"
      aria-labelledby={SETTINGS_BUTTON_LABEL}
      backgroundColor="surface.background.primary"
      borderRadius="medium"
      boxShadow="dropdown"
      paddingX="one"
      paddingY="half"
      position="absolute"
      ref={dropdownRef}
      right="none"
      top="four"
      width={SETTINGS_DROPDOWN_FIXED_WIDTH}
      zIndex="1"
    >
      {children}
    </StyledDropdown>
  );
};
