import { useCallback, useContext, useMemo } from 'react';
import { formatEventName } from './utils/formatEventName';
import { DEFAULT_NAMESPACE } from './constants/constants';
import type {
  MasonryEngineNodeAction,
  AnyMasonryEngineNodeAction,
  MasonryEngineNode,
} from './types/masonry-engine-node.types';
import {
  MasonryEngineActionController,
  MasonryEngineActionHandlerAnonymousParams,
  MasonryEngineActionHandlerMap,
  MasonryEngineActionHandlerParams,
  MasonryEngineEvent,
  MasonryEngineActionHandler,
  MasonryEngineEventListener,
} from './types/masonry-engine-action.types';
import { MasonryEngineNodeAncestryContext } from './masonry-engine-node-ancestry-context';
import { useMasonryEngineConfig } from './masonry-engine-config-context';
import { MasonryEngineStateController } from './masonry-engine-state-controller';
import { NarrowByType } from './types/util.types';

type AnyMasonryNodeActionHandler =
  MasonryEngineActionHandler<AnyMasonryEngineNodeAction>;

type AnyMasonryEngineActionHandlerMap = Partial<
  Record<string, AnyMasonryNodeActionHandler>
>;

type EventListenerKey = `${string}-${MasonryEngineEvent}-${string}`;

export type EventListenerMap = Partial<
  Record<
    EventListenerKey,
    Set<MasonryEngineEventListener<AnyMasonryEngineNodeAction>>
  >
>;

const masonryEngineEventNamesExceptALL = Object.values(
  MasonryEngineEvent,
).filter((type) => type !== MasonryEngineEvent.ALL);

export const createMasonryEngineActionController = <
  /**
   * A union of known `MasonryEngineNodeAction` supported for this controller.
   */
  Action extends MasonryEngineNodeAction,
>(
  defaultHandlers: MasonryEngineActionHandlerMap<Action>,
): MasonryEngineActionController<Action> => {
  const actionRegistry: Partial<
    Record<string, AnyMasonryEngineActionHandlerMap>
  > = {
    default: defaultHandlers as unknown as AnyMasonryEngineActionHandlerMap,
  };

  const eventListenerMap: EventListenerMap = {};

  /**
   * @method registerHandler is used to register an action to the to the Masonry Engine Driver {@link actionRegistry }.
   */
  const registerHandler: MasonryEngineActionController<Action>['registerHandler'] =
    (type, namespace, handler) => {
      actionRegistry[namespace] = actionRegistry[namespace] || {};

      if (actionRegistry[namespace]![type]) {
        throw new Error(
          'Attempting to register a handler for a type at a namespace where a handler was already registered for that type!',
        );
      }

      actionRegistry[namespace]![type] = handler as AnyMasonryNodeActionHandler;
    };

  /**
   * @method overrideHandler: is used to override an action in the Masonry Engine Driver {@link actionRegistry }.
   */
  const overrideHandler: MasonryEngineActionController<Action>['overrideHandler'] =
    (type, namespace, handler) => {
      actionRegistry[namespace] = actionRegistry[namespace] || {};

      actionRegistry[namespace]![type] = handler as AnyMasonryNodeActionHandler;
    };

  const emitAction: MasonryEngineActionController<Action>['emitAction'] =
    async (params, stateControllerStore, messagingController) => {
      /**
       * Using same store for internal actions which are fired corresponding to the action type like analytics and actions emitted
       * by a action handlers.
       */
      const emitActionWithStore = async (internalParams = params) => {
        const handler =
          actionRegistry[internalParams.namespace]?.[internalParams.type];
        if (!handler) {
          throw new Error(
            `Masonry Engine: No action - ${internalParams.type} found for namespace - ${internalParams.namespace}`,
          );
        }

        const { setNodeState, getNodeState } = stateControllerStore.getState();
        const stateController: MasonryEngineStateController = {
          setNodeState,
          getNodeState,
        };

        const startedEventName = formatEventName(
          internalParams.namespace,
          internalParams.type,
          MasonryEngineEvent.STARTED,
        );

        /**
         * Trigger all Masonry event listeners registered for
         * {@link MASONRY_ENGINE_EVENT_TYPE MASONRY_ENGINE_EVENT_TYPE.EVENT_STARTED} event type
         */
        eventListenerMap[startedEventName]?.forEach((listener) => {
          listener(internalParams, undefined);
        });

        // eslint-disable-next-line no-console
        console.log('Masonry Engine Action emitted', internalParams);

        const typedEmitAction = emitActionWithStore as Parameters<
          typeof handler
        >[1];

        const returnValue = await Promise.resolve(
          handler(
            internalParams,
            typedEmitAction,
            stateController,
            messagingController,
          ),
        );

        const endedEventName = formatEventName(
          internalParams.namespace,
          internalParams.type,
          MasonryEngineEvent.ENDED,
        );

        /**
         * Trigger all Masonry event listeners registered for
         * {@link MASONRY_ENGINE_EVENT_TYPE MASONRY_ENGINE_EVENT_TYPE.EVENT_ENDED} event type
         */
        eventListenerMap[endedEventName]?.forEach((listener) => {
          listener(internalParams, returnValue);
        });

        // Trigger Masonry analytics event if action has an analytics object
        const analyticsHandler =
          actionRegistry[internalParams.namespace]?.analytics;

        if (internalParams.analytics && analyticsHandler) {
          const analyticsPayload = {
            type: 'analytics',
            namespace: DEFAULT_NAMESPACE,
            payload: internalParams.analytics,
            ancestry: internalParams.ancestry,
          };
          typedEmitAction(analyticsPayload);
        }

        return returnValue;
      };

      return emitActionWithStore();
    };

  /**
   * @method addEventListener is used for adding a listener for Masonry actions and events to the {@link EventListenerMap}.
   * */
  const addEventListener: MasonryEngineActionController<Action>['addEventListener'] =
    (
      actionType: string,
      namespace: string,
      eventType: MasonryEngineEvent,
      listener: (...args: any) => void,
    ) => {
      // An array of event types we should bind this listener to.
      const resolvedEventTypes =
        eventType === MasonryEngineEvent.ALL
          ? masonryEngineEventNamesExceptALL
          : [eventType];

      // Add listener for each resolved event type
      resolvedEventTypes.forEach((resolvedEventType) => {
        const formattedEventName = formatEventName(
          namespace,
          actionType,
          resolvedEventType,
        );
        eventListenerMap[formattedEventName] =
          eventListenerMap[formattedEventName] || new Set();
        eventListenerMap[formattedEventName]!.add(listener);
      });

      return () => {
        resolvedEventTypes.forEach((resolvedEventType) => {
          const formattedEventName = formatEventName(
            namespace,
            actionType,
            resolvedEventType,
          );

          eventListenerMap[formattedEventName]!.delete(listener);
        });
      };
    };

  /**
   * @method getRegisteredEventListeners: is used for getting an object that lists all the event types that have registered listeners in the Masonry Engine Listener Map {@link EventListenerMap }.
   */
  const getRegisteredEventListeners = () => {
    const record: Record<string, number> = {};

    Object.entries(eventListenerMap).forEach(([eventType, listenerSet]) => {
      if (listenerSet?.size) {
        record[eventType] = listenerSet.size;
      }
    });

    /**
     * This is an object with keys representing event types and values representing
     * how many listeners are currently registered for each.
     */
    return record;
  };

  return {
    registerHandler,
    overrideHandler,
    emitAction,
    addEventListener,
    getRegisteredEventListeners,
  };
};

/**
 * A hook that returns an emitter for {@link MasonryEngineActionHandlerAnonymousParams `MasonryEngineActionHandlerAnonymousParams`}.
 * The parameters will be automatically converted to {@link MasonryEngineActionHandlerParams `MasonryEngineActionHandlerParams`}s
 * (with ancestry information) and sent up to the Driver. Note: actions may be
 * intercepted if the user provided a {@link MasonryEngineNamespacedActionHandlerMap `MasonryEngineNamespacedActionHandlerMap`}
 * in the Config.
 */

// provide, fetch, react query clear cache,
export const useMasonryEngineActionEmitter = <
  Action extends MasonryEngineNodeAction,
>(
  /**
   * For internal use. Prepends one additional node ID
   * to the ancestry sent to the action handler.
   */
  prependToAncestry?: MasonryEngineNode['id'],
) => {
  const externalAncestry = useContext(MasonryEngineNodeAncestryContext);

  const ancestry = useMemo(
    () =>
      prependToAncestry
        ? [prependToAncestry, ...externalAncestry]
        : externalAncestry,
    [prependToAncestry, externalAncestry],
  );
  const {
    actionController: { emitAction },
    /**
     * Pass vanilla zustand store directly instead of passing State controller from React Context, so that
     * users creating this outside of react should be able to use the store directly instead of relying
     * on React specific stuff.
     */
    stateControllerStore,
    messagingController,
  } = useMasonryEngineConfig();

  const invokeAction = useCallback(
    <Type extends string>(
      params: MasonryEngineActionHandlerAnonymousParams<
        | Action
        | (MasonryEngineNodeAction<Type> &
            (Type extends Action['type'] ? NarrowByType<Action, Type> : {}))
      >,
    ) => {
      const actionWithAncestry = {
        ...params,
        ancestry,
      } as MasonryEngineActionHandlerParams<Action>;

      return emitAction(
        actionWithAncestry,
        stateControllerStore,
        messagingController,
      );
    },
    [ancestry, emitAction, stateControllerStore, messagingController],
  );

  return invokeAction;
};
