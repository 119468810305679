import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

interface Order {
  type: string;
  asc: boolean;
}

type Statuses = Array<string>;

interface GetClaimsInfo {
  user_id?: string;
  limit: number;
  order: Order;
  statuses?: Statuses;
}

export const GET_CLAIMS_MESSAGE_TYPE = 'get_claims';

export const getClaims = async (
  userId?: string,
  limit: number = 3,
  order: Order = { type: 'last_updated', asc: false },
  statuses?: Statuses,
) => {
  const info: GetClaimsInfo = {
    user_id: userId,
    limit,
    order,
  };

  if (statuses) {
    info.statuses = statuses;
  }

  const params = {
    message_type: GET_CLAIMS_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
