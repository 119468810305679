import * as React from 'react';
import { merge } from 'lodash';
import { Carousel } from '@leagueplatform/web-common-components';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { SectionHeader } from 'components/section-header/section-header.component';
import { ParagraphText, StackItem } from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
// eslint-disable-next-line import/no-cycle
import { MasonryWSDriverNode } from '../../types/masonry-ws-driver-node';
import { COMPLEX_WIDGET_CARDS_WIDTH } from '../../constants';

export type CarouselWSRendererProps = {
  body?: string;
  headerButtonAction?: string;
  headerButtonLabel?: string;
  heading?: string;
  items: React.ReactElement[];
  onScrollLeft: () => void;
  onScrollRight: () => void;
  onSelectHeader: () => void;
};

export type CarouselWSRendererNode = MasonryEngineNode<
  'genericCarousel',
  Omit<CarouselWSRendererProps, 'items'>,
  any,
  {
    items: Array<MasonryWSDriverNode>;
  }
>;

export const CarouselWSRenderer = ({
  body,
  headerButtonAction,
  headerButtonLabel,
  heading,
  items,
  onScrollLeft,
  onScrollRight,
  onSelectHeader,
  ...props
}: CarouselWSRendererProps) => {
  if (!items) return null;

  return (
    <Carousel
      name={heading || 'masonry-carousel'}
      heading={
        heading ? (
          <SectionHeader heading={heading} paddingTop="$none" />
        ) : undefined
      }
      description={
        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {body}
        </ParagraphText>
      }
      LeftNavButtonAnalyticsFn={onScrollLeft}
      linkCta={headerButtonLabel}
      linkOnClick={onSelectHeader}
      linkUrl={headerButtonAction}
      RightNavButtonAnalyticsFn={onScrollRight}
      spaceBetweenCards="oneAndHalf"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {React.Children?.map(items, (child) => (
        <StackItem
          css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH, height: '100%' }}
        >
          {React.cloneElement(child, {
            node: merge({}, child.props.node, {
              properties: { isChildItem: true },
            }),
          })}
        </StackItem>
      ))}
    </Carousel>
  );
};
