import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-commons';
import { Button } from '@leagueplatform/genesis-core';
import PropTypes from 'prop-types';

export const LearnMoreMobileView = ({ children, setIsLearnMoreOpen }) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 300,
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <Button
        icon="interfaceArrowLeft"
        priority="secondary"
        quiet="true"
        onClick={() => setIsLearnMoreOpen(false)}
      >
        {formatMessage({ id: 'BACK' })}
      </Button>
      <Box margin="two">{children}</Box>
    </Box>
  );
};

LearnMoreMobileView.propTypes = {
  children: PropTypes.node.isRequired,
  setIsLearnMoreOpen: PropTypes.func.isRequired,
};
