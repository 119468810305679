import React, { useEffect } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { splitAtNewlines } from '@leagueplatform/web-common';
import {
  LoadingIndicator,
  FeedbackCard,
  StackLayout,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  Flex,
  BodyOne,
  HeadingTwo,
  genesisStyled,
  css,
  Box,
} from '@leagueplatform/genesis-commons';
import {
  EVENT_NAME,
  GA_EVENT,
  PRODUCT_AREA,
  SCREEN_NAMES,
  sendAnalyticsPageView,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { Solution } from '@leagueplatform/care-collections-api';
import { CarePageWrapper } from '@leagueplatform/care-common';
import { useIntl } from '@leagueplatform/locales';
import { SolutionCard } from '../../components/solution-card/solution-card.component';
import { useCareCollectionPage } from '../../hooks/use-care-collection-page.hook';

interface CareCollectionProps {
  match: {
    params: {
      careCollectionId: string;
    };
  };
}

const SolutionCardListItem = genesisStyled('li')(
  css({
    marginTop: 'oneAndHalf',
  }),
);

export const CareCollection = ({
  match: {
    params: { careCollectionId },
  },
}: CareCollectionProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const {
    isLoading,
    isError,
    data,
    error,
    showFeedbackCard,
    userId,
    tenantId,
  } = useCareCollectionPage(careCollectionId);

  const pageViewAnalytics = {
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: SCREEN_NAMES.CARE_COLLECTIONS,
    screen_name: SCREEN_NAMES.CARE_COLLECTION,
    care_collection_id: careCollectionId,
  };

  useEffect(() => {
    if (isError) {
      // TODO: Send correct error properties once API endpoint is live
      sendAnalyticsPageView(
        { ...pageViewAnalytics, error_detail: error },
        GA_EVENT,
      );
    }

    if (data) {
      sendAnalyticsPageView(
        {
          ...pageViewAnalytics,
          text_detail: data?.data?.attributes?.title.toLowerCase(),
        },
        GA_EVENT,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError || !data?.data) {
    return (
      <CarePageWrapper backButtonOnClick={() => history.goBack()}>
        <ErrorState />
      </CarePageWrapper>
    );
  }

  const {
    attributes: { title, description, solutions, typeformUrl },
  } = data.data;

  const FEEDBACK_URL = `${typeformUrl}?member_id=${userId}&tenant_id=${tenantId}&platform=web&care_collection_name=${title}&prefilled_answer=`;

  return (
    <CarePageWrapper title={title} backButtonOnClick={() => history.goBack()}>
      <Flex flexDirection="column" width="100%" marginBottom="twoAndHalf">
        {splitAtNewlines(description).map((partDescription) => (
          <BodyOne key={description} color="onSurface.text.primary">
            {partDescription}
          </BodyOne>
        ))}
      </Flex>

      <StackLayout space="$oneAndHalf">
        <HeadingTwo>{formatMessage({ id: 'SOLUTIONS' })}</HeadingTwo>
        <ul>
          {solutions?.map((solution: Solution, i: number) => (
            <SolutionCardListItem key={solution.url}>
              <SolutionCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...solution}
                isRecommended={i === 0}
                careCollectionId={careCollectionId}
                topicTitle={title.toLowerCase()}
              />
            </SolutionCardListItem>
          ))}

          {!solutions.length && (
            <BodyOne>{formatMessage({ id: 'NO_RECOMENDATIONS' })}</BodyOne>
          )}
        </ul>
      </StackLayout>

      {showFeedbackCard && (
        <Flex marginTop="five" justifyContent="center">
          <Box width="100%" maxWidth="920px">
            <FeedbackCard
              title={formatMessage({ id: 'FEEDBACK_TITLE' })}
              size="large"
              backgroundColor="surface.background.primary"
              minMaxTextColor="onSurface.text.primary"
              feedbackUrl={FEEDBACK_URL}
              onClick={(number: number) => {
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  product_area: PRODUCT_AREA.GET_CARE,
                  sub_product_area: SCREEN_NAMES.CARE_COLLECTIONS,
                  screen_name: SCREEN_NAMES.CARE_COLLECTION,
                  care_collection_id: careCollectionId,
                  detail: 'select experience rating',
                  sub_detail: number.toString(),
                  text_detail: title.toLowerCase(),
                });
              }}
            />
          </Box>
        </Flex>
      )}
    </CarePageWrapper>
  );
};
