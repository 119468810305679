import React from 'react';
import { ProgressBar } from '@leagueplatform/web-common-components';
import { useMasonryEngineActionEmitter } from '@leagueplatform/masonry-engine';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from '../../types/masonry-driver-node-actions';
import { MasonryProgressBarCardRendererType } from './masonry-progress-bar-card-renderer.type';

interface MasonryProgressBarCardRendererProps
  extends MasonryProgressBarCardRendererType {
  onClick?: MasonryDriverAction;
  linkRefState?: React.RefObject<HTMLAnchorElement>;
}

export const MasonryProgressBarCardContentRenderer = ({
  eyebrow,
  heading,
  currentText,
  progress,
  suffixText,
  footerText,
  onClick,
  linkRefState,
  headingLevel,
  emphasizeCurrentValue = false,
}: MasonryProgressBarCardRendererProps) => {
  const invokeAction = useMasonryEngineActionEmitter();

  const onClickDeeplinkAction = onClick as MasonryDeeplinkAction;
  return (
    <StackLayout
      orientation="vertical"
      spacing="$quarter"
      css={{ margin: '$oneAndHalf', alignItems: 'normal', height: 'auto' }}
    >
      {eyebrow && (
        <UtilityText as="p" size="eyebrow" css={{ marginBottom: '$quarter' }}>
          {eyebrow}
        </UtilityText>
      )}
      <StackLayout spacing="$threeQuarters" orientation="vertical">
        {heading && (
          <HeadingText level={headingLevel || '3'} size="sm">
            {heading}
          </HeadingText>
        )}
        <ParagraphText size="sm">
          <ConditionalLinkRenderer
            disableDefaultOnClick
            ref={linkRefState}
            url={onClickDeeplinkAction?.payload.url}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            <UtilityText
              size="sm"
              emphasis={emphasizeCurrentValue ? 'emphasized' : 'base'}
            >
              {currentText}
            </UtilityText>{' '}
            {suffixText}
          </ConditionalLinkRenderer>
        </ParagraphText>
        <Box css={{ paddingBottom: '$half', width: '100%' }}>
          <ProgressBar
            total={100}
            current={progress}
            customBarFillColor="success.border.default"
            customBarBackgroundColor="success.background.subdued"
            screenReaderText={`${currentText} ${suffixText}`}
          />
        </Box>
        <UtilityText
          emphasis="subtle"
          size="xs"
          css={{
            alignSelf: 'flex-end',
          }}
        >
          {footerText}
        </UtilityText>
      </StackLayout>
    </StackLayout>
  );
};
