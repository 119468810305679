import * as React from 'react';
import { Box } from '@leagueplatform/genesis-core';

type ChartTooltipProps = {
  children: React.ReactNode;
};

export function ChartTooltip({ children }: ChartTooltipProps) {
  return (
    <Box
      css={{
        width: 'fit-content',
        transform: 'translateY(-100%)',
        marginLeft: '-50%',
        padding: '$half',
        borderRadius: '$small',
        borderWidth: '$thick',
        borderStyle: 'solid',
        borderColor: '$decorativeBrandPrimaryDark',
        backgroundColor: '$interactiveBackgroundDefault',
        '&:after': {
          content: '""',
          borderBottom: '2px solid',
          borderRight: '2px solid',
          borderColor: '$decorativeBrandPrimaryDark',
          position: 'absolute',
          left: '50%',
          right: '0',
          bottom: '-9px',
          width: '15px',
          height: '15px',
          transform: 'translateX(-51%) rotate(45deg)',
          backgroundColor: '$interactiveBackgroundDefault',
        },
      }}
    >
      {children}
    </Box>
  );
}
