import * as React from 'react';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { useProviderServices } from 'hooks/queries/use-provider-services-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailScreenLoaded } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { BENEFIT_SCREEN_NAMES } from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { selectCoverageNetworks } from './utils/select-coverage-networks.util';
import {
  NetworksCoverage,
  NoNetworkCoverage,
} from './components/network-coverage.component';

export function MedicalCoverage() {
  const { userId, benefitId } = useWalletParams();
  const { data, isLoading, isError, refetch } = useProviderServices(
    userId,
    benefitId,
    {
      select: selectCoverageNetworks,
    },
  );
  useWalletDetailScreenLoaded(BENEFIT_SCREEN_NAMES.COVERAGE_SUMMARY);

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  if (isError || !data) {
    return (
      <Box
        as="section"
        css={{
          backgroundColor: '$surfaceBackgroundTertiary',
          padding: '$one',
          minHeight: '286px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ErrorState hideImage onButtonClick={refetch} />
      </Box>
    );
  }

  const { metadata, networks, isNoNetworkUser } = data;

  const hasNetworks = networks.length > 0;

  return (
    <StackLayout as="section" spacing="$oneAndHalf" css={{ width: '100%' }}>
      <StackLayout spacing="$one" css={{ width: '100%' }}>
        {metadata.title && (
          <HeadingText as="h2" level="2" size="xl">
            {metadata.title}
          </HeadingText>
        )}
        {metadata.description && (
          <ParagraphText>{metadata.description}</ParagraphText>
        )}
      </StackLayout>
      {!isNoNetworkUser && hasNetworks && (
        <NetworksCoverage networks={networks} />
      )}
      {isNoNetworkUser && hasNetworks && (
        <NoNetworkCoverage services={networks[0]} />
      )}
      {!hasNetworks && (
        <Box css={{ width: '100%' }}>
          <ErrorState hideImage onButtonClick={refetch} />
        </Box>
      )}
    </StackLayout>
  );
}
