import { leagueFetch } from '@leagueplatform/league-fetch';
import { getConfig } from '@leagueplatform/config';
import { MasonryNodeResponse } from '../types/get-masonry-node';

export type GetMasonryNodeOptions = {
  path: string;
  method: Request['method'];
  queryParams?: Record<string, string>;
};

export const getMasonryNode = async ({
  path,
  method,
  queryParams,
}: GetMasonryNodeOptions): Promise<MasonryNodeResponse> => {
  const url = new URL(path, window.location.origin);

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }

  const completePath = url.href.replace(url.origin, '');
  // Un-comment this if using mock data
  // const completePath =  url.href.replace(url.origin, 'http://127.0.0.1:3100');

  const timeZone =
    typeof Intl !== 'undefined'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : 'America/Toronto';

  const response = await leagueFetch(completePath, {
    method,
    allowUnauthenticated: true,
    headers: {
      'X-League-Client-Platform': 'web',
      'X-League-Client-Time-Zone': timeZone,
      'X-League-Client-Version': getConfig().config.core.appVersion || '1.0.0',
      'X-League-Client-View-Size': 'desktop', // @TODO - update this to pass dynamic values in this ticket - MSRY-3458
      // Un-comment this if using mock data
      // Prefer: `code=200, example=${params}`,
    },
  });

  if (!response.ok) {
    throw new Error(`getMasonryNode error: ${response.status}`);
  }

  const responseJson: any = await response.clone().json();
  return responseJson;
};
