/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { MultiSelect } from '@leagueplatform/ui-kit';
import { fieldProps } from './field-shape';

export const MultiSelectField = ({
  field: { name, value, ...field },
  form: { setFieldValue },
  disabled,
  readOnly,
  options,
  required,
  ...props
}) => {
  const optionValues = value?.map((option) => option?.value ?? option) ?? [];

  const selectedValues = optionValues.map(
    (optionValue) =>
      options.find((option) => option.value === optionValue) ?? {
        label: optionValue,
        value: optionValue,
      },
  );

  return (
    <MultiSelect
      {...props}
      {...field}
      required={required}
      name={name}
      options={options}
      disabled={Boolean(disabled ?? readOnly)}
      onChange={(selectedOptions, action) => {
        if (!action) return; // user is typing
        const selectedOptionsArray = selectedOptions || [];
        setFieldValue(
          name,
          selectedOptionsArray.map((opt) => opt.value),
        );
      }}
      value={selectedValues}
      data-testid={`multi_select_field_${name}`}
      canCreate
    />
  );
};

MultiSelectField.propTypes = fieldProps;
