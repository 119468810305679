import { LEAGUE_CREDIT_TYPE } from 'common/league-credit-type';

const LEAGUE_CREDIT_NAME = 'League Credit';

/**
 * Benefits have names, while other cards don't.
 * These functions adds names friendly to consumers of feedback results and analytics.
 */
export const getCardNameFromType = (cardType: string) => {
  if (cardType === LEAGUE_CREDIT_TYPE) return LEAGUE_CREDIT_NAME;
  return cardType;
};
