import React from 'react';
import { ErrorMessage } from '@leagueplatform/genesis-commons';
import {
  ChallengeIcon,
  ICON_TYPES,
} from '../challenge-icon/challenge-icon.component';

export interface ErrorWithIconProps {
  errorMessage: string;
}

export const ErrorWithIcon = ({ errorMessage }: ErrorWithIconProps) => (
  <ErrorMessage
    display="flex"
    alignItems="center"
    justifyContent="center"
    marginTop="two"
    role="alert"
  >
    <ChallengeIcon iconType={ICON_TYPES.ALERT} />
    <ErrorMessage as="span" marginLeft="one">
      {errorMessage}
    </ErrorMessage>
  </ErrorMessage>
);
