import React from 'react';
import { Redirect } from '@leagueplatform/routing';
import { FeaturesHighlightView } from 'components/feature-highlight-view';

import { useGetFeaturesHighlight } from 'hooks/use-get-features-highlight.hook';
import { useSetOnboardingConfig } from 'hooks/use-set-onboarding-config.hook';

import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { screenDetails } from 'constants/anayltics.constants';

import { useOnboardingModuleConfig } from 'components/onboarding-module-config-context';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

export const OnboardingLandingPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'WELCOME' }));
  const {
    contentfulId = '',
    tenantId = 'league',
    onOnboardingComplete,
  } = useOnboardingModuleConfig();
  const { slides, title, isLoading, isError } = useGetFeaturesHighlight(
    contentfulId,
    tenantId,
  );

  const setOnboardingConfigFlag = useSetOnboardingConfig({
    showOnboarding: false,
  });

  const nextHandler = () => {
    setOnboardingConfigFlag.mutate();
    if (onOnboardingComplete) {
      onOnboardingComplete();
    }
  };

  if (isError) {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      ...screenDetails,
      error_text: 'Failed to load feature highlights',
      error_text_detail: 'Call to contentful failed',
    });

    if (onOnboardingComplete) {
      onOnboardingComplete(new Error('Failed to load feature highlights'));
    } else {
      return <Redirect to="/" />;
    }
  }

  if (slides && slides.length < 1) {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      ...screenDetails,
      error_text: 'Failed to load feature highlights',
      error_text_detail: 'Call to contentful returned empty',
    });

    if (onOnboardingComplete) {
      onOnboardingComplete(new Error('Failed to load feature highlights'));
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <FeaturesHighlightView
      heading={title || 'Features Highlight'}
      handleNext={nextHandler}
      slides={slides || []}
      isLoading={isLoading}
    />
  );
};
