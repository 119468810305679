import React, { useRef } from 'react';
import { type Traits, WidgetType } from '@leagueplatform/masonry-api';
import {
  Flex,
  Overline,
  SubtitleOne,
  HeadingThree,
  Text,
  Box,
} from '@leagueplatform/genesis-commons';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import {
  StackLayout,
  ConditionalLinkCard,
  ConditionalLink,
} from '@leagueplatform/web-common-components';
import { ParagraphText } from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  BadgeList,
  BadgeListProps,
} from 'common/badge-list/badge-list.component';
import { SquareImageContainer } from 'common/square-image-container/square-image-container.component';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface SmallVisualCardWidgetProps {
  image: string;
  overline?: string;
  title: string;
  description: string;
  imageAltText: string;
  badges?: BadgeListProps['badges'];
  linkUrl?: string;
  isChildItem?: boolean;
  onCardClick?: (e: React.MouseEvent) => void;
  traits?: Traits;
}

export type SmallVisualCardWSNode = MasonryEngineNode<
  WidgetType.SMALL_VISUAL_CARD,
  SmallVisualCardWidgetProps
>;

export const SmallVisualCardWidget = ({
  overline,
  title,
  description,
  image,
  badges,
  imageAltText,
  linkUrl,
  isChildItem = false,
  onCardClick = () => {},
  traits,
}: SmallVisualCardWidgetProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const overlineId = `svc-${title.replace(/\s+/g, '-')}`;
  // TODO: Traits will be removed in Masonry 2.0
  const traitItems = useGetTraitsForMedia(traits);

  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(linkUrl)}
      cardStyle={{
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 'oneAndHalf',
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={isChildItem ? 'flex-start' : 'center'}
        flex="2"
      >
        <StackLayout space="$half">
          {overline && (
            <Overline id={overlineId} as={Text} color="onSurface.text.subdued">
              {overline}
            </Overline>
          )}
          <StackLayout space="$quarter">
            <SubtitleOne
              as={HeadingThree}
              fontWeight="bold"
              aria-describedby={overlineId}
            >
              <ConditionalLink
                ref={linkRef}
                url={linkUrl}
                onClick={onCardClick}
              >
                {title}
              </ConditionalLink>
            </SubtitleOne>
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {description}
            </ParagraphText>
          </StackLayout>
        </StackLayout>
        {badges && (
          <Box marginTop="threeQuarters">
            <BadgeList
              badges={badges}
              backgroundColor={traitItems?.badgeBackgroundColor}
            />
          </Box>
        )}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        marginLeft={{
          _: 'threeQuarters',
          desktop: 'two',
        }}
      >
        <SquareImageContainer
          src={image}
          alt={imageAltText}
          maxWidth="9.8rem"
          maxWidthDesktop={isChildItem ? '9.8rem' : '11.2rem'}
        />
      </Flex>
    </ConditionalLinkCard>
  );
};
