export const CHALLENGE_CAMPAIGN_TYPE = 'challenge';

export enum TAB_NAME {
  OVERVIEW = 'overview',
  HIGHLIGHTS = 'highlights',
  LEADERBOARD = 'leaderboard',
  ACTIVITIES = 'activities',
}

export enum SIGN_UP_ANALYTICS_SCREEN_NAMES {
  ACCEPT_PRIVACY_POLICY = 'challenges privacy screen',
  QUIT_SIGN_UP = 'quit sign-up confirmation',
  SIGN_UP_SUCCESS = 'sign-up success',
  WEARABLES_CONNECTION = 'connect app or device from health campaign',
  SELECT_TEAM_AVATAR = 'select team avatar',
}

export enum SIGN_UP_ANALYTICS_DETAILS {
  CHANGE_TEAM_AVATAR = 'change team avatar',
  CONFIRM = 'confirm',
  CONTINUE = 'continue',
  COPY = 'copy',
  CREATE_TEAM = 'create team',
  EXIT = 'exit',
  GO_TO_JOURNEY = 'go to journey',
  JOIN_TEAM = 'join or create a team',
  NOT_NOW = 'not now',
  SELECT_TEAM_AVATAR = 'select team avatar',
  TRACK_AUTOMATICALLY = 'track progress automatically',
  TRACK_MANUALLY = 'track manually instead',
  VIEW_DETAILS = 'view challenge details',
  VIEW_TEAM_DETAILS = 'view team details',
}

export enum CLP_SCREEN_NAMES {
  LEAVE_CHALLENGE = 'leave challenge',
  CHALLENGES_PRIVACY_SETTINGS = 'challenges privacy settings',
}

export enum CLP_DETAIL {
  NAVIGATE_TO_HELP_CENTER = 'help center',
  SIGN_UP = 'sign up',
  JOIN_CREATE_TEAM = 'join or create a team',
  LEAVE_CHALLENGE = 'leave challenge',
  VIEW_MORE = 'view more',
  HIDE_ACTIVITY = 'hide activity',
  SHOW_ACTIVITY = 'show activity',
  SETTINGS = 'settings',
  INTERACT_HIGHLIGHTS = 'interact with highlights',
  EXIT = 'exit',
  SETTINGS_MENU_PRIVACY = 'settings menu - privacy',
  SETTINGS_MENU_LEAVE_CHALLENGE = 'settings menu - leave challenge',
}
export enum TLP_SCREEN_NAMES {
  CREATE_TEAM = 'create team',
  CREATE_TEAM_SUCCESS = 'create team success',
  LEAVE_TEAM = 'leave team',
  TEAM_DETAILS = 'team details',
  JOIN_OR_CREATE_TEAM = 'join or create team',
  JOIN_TEAM_SUCCESS = 'join team success',
}
export enum TLP_DETAIL {
  EXIT = 'exit',
  LEAVE_TEAM = 'leave team',
  JOIN_TEAM = 'join team',
  SETTINGS_MENU_INVITE = 'settings menu - invite friends',
  SETTINGS_MENU_PRIVACY = 'settings menu - privacy',
  SETTINGS_MENU_LEAVE_TEAM = 'settings menu - leave team',
  INVITE = 'invite',
  TEAM_DETAILS = 'go to team details',
}
