import {
  completeUserHealthActivity,
  COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';
import { FETCH_AND_SYNC_ACHIEVEMENTS_UPDATES_MESSAGE_TYPE } from '@leagueplatform/rewards-api';
import { useMutation, useQueryClient } from 'react-query';

export const useCompleteActivity = (
  userActivityId: string,
  toggleAchievementUpdates: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const queryClient = useQueryClient();
  return useMutation(() => completeUserHealthActivity(userActivityId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        FETCH_AND_SYNC_ACHIEVEMENTS_UPDATES_MESSAGE_TYPE,
      );
      queryClient.setQueryData(
        [COMPLETE_USER_HEALTH_ACTIVITY_MESSAGE_TYPE],
        data,
      );
      toggleAchievementUpdates(!!data?.completion_screen);
    },
  });
};
