import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import {
  StackLayout,
  UtilityText,
  StackItem,
} from '@leagueplatform/genesis-core';

interface PolicyDateRangeProps {
  endSubmitDate: string | null | undefined;
  endBalanceDate: string | null | undefined;
}

export const PolicyDateRange = ({
  endSubmitDate,
  endBalanceDate,
}: PolicyDateRangeProps) => {
  const { formatMessage, formatDate } = useIntl();

  return (
    <StackLayout spacing="$oneAndHalf">
      {endBalanceDate && (
        <StackItem>
          <UtilityText
            css={{ typography: '$subtitleTwo', marginBottom: '$half' }}
          >
            {formatMessage({ id: 'LAST_DAY_TO_USE_BALANCE' })}
          </UtilityText>
          <UtilityText css={{ typography: '$bodyTwo' }}>
            {formatDate(endBalanceDate, {
              ...SHORT_DATE_FORMAT,
              timeZone: 'UTC',
            })}
          </UtilityText>
        </StackItem>
      )}
      {endSubmitDate && (
        <StackItem>
          <UtilityText
            css={{ typography: '$subtitleTwo', marginBottom: '$half' }}
          >
            {formatMessage({ id: 'LAST_DAY_TO_SUBMIT_CLAIMS' })}
          </UtilityText>
          <UtilityText css={{ typography: '$bodyTwo' }}>
            {formatDate(endSubmitDate, {
              ...SHORT_DATE_FORMAT,
              timeZone: 'UTC',
            })}
          </UtilityText>
        </StackItem>
      )}
    </StackLayout>
  );
};
