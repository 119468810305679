import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';

const icons = {
  accident: WALLET_ASSET_KEYS.ACCIDENT,
  calendar: WALLET_ASSET_KEYS.CALENDAR,
  critical_illness: WALLET_ASSET_KEYS.CRITICAL_ILLNESS,
  dental: WALLET_ASSET_KEYS.DENTAL,
  drug: WALLET_ASSET_KEYS.DRUG,
  home: WALLET_ASSET_KEYS.HOME,
  life: WALLET_ASSET_KEYS.LIFE,
  spouse_optional_life: WALLET_ASSET_KEYS.LIFE,
  ltd: WALLET_ASSET_KEYS.LONG_TERM_DISABILITY,
  medical: WALLET_ASSET_KEYS.MEDICAL,
  pet: WALLET_ASSET_KEYS.PET,
  placeholder: WALLET_ASSET_KEYS.PLACEHOLDER,
  std: WALLET_ASSET_KEYS.SHORT_TERM_DISABILITY,
  spending_accounts: WALLET_ASSET_KEYS.SPENDING_ACCOUNTS,
  lsa: WALLET_ASSET_KEYS.SPENDING_ACCOUNTS,
  psa: WALLET_ASSET_KEYS.SPENDING_ACCOUNTS,
  hsa: WALLET_ASSET_KEYS.SPENDING_ACCOUNTS,
  vision: WALLET_ASSET_KEYS.VISION,
  credit_card: WALLET_ASSET_KEYS.CREDIT_CARD,
  'credit-card': WALLET_ASSET_KEYS.CREDIT_CARD,
  wellness_accounts: WALLET_ASSET_KEYS.WELLNESS_ACCOUNTS,
} as const;

export type ProductIconType = keyof typeof icons;

export function getProductIcon(productType?: ProductIconType): string {
  if (!productType || !icons[productType]) {
    return handleStaticAsset(WALLET_ASSET_KEYS.REWARDS) as string;
  }

  return handleStaticAsset(icons[productType]) as string;
}
