import * as React from 'react';
import { BenefitCardAction } from '@leagueplatform/wallet-api';
import { Button } from '@leagueplatform/genesis-core';
import { useActionLink } from 'hooks/use-action-link.hook';

interface CardActionProps {
  action: BenefitCardAction;
}

export const CardAction = ({ action }: CardActionProps) => {
  const { LinkComponent, handleActionClick } = useActionLink(action);

  if (!LinkComponent) {
    return null;
  }

  const { icon_url: icon, nav_icon: secondaryIcon } = action;

  return (
    // @ts-ignore-next-line
    <Button
      as={LinkComponent}
      action={action}
      onClickCallback={handleActionClick}
      primaryIcon={icon}
      secondaryIcon={secondaryIcon}
      css={{
        '&&': {
          width: '100%',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        },
        // Some styles have the same specificity and so clash
        // These styles make the button look like secondary button
        borderColor: '$secondaryBorderDefault',
        color: '$secondaryTextDefault',
        borderRadius: '$button',
        borderWidth: '$thin',
        borderStyle: 'solid',
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      }}
      priority="secondary"
    />
  );
};
