import React, { useRef } from 'react';
import {
  Flex,
  css,
  genesisStyled,
  FlexProps,
  ColorProps,
} from '@leagueplatform/genesis-commons';

interface CardStyles extends FlexProps {
  backgroundColor?: ColorProps['backgroundColor'] & string;
}

export interface ConditionalLinkCardProps {
  children: React.ReactNode;
  hasLink?: boolean;
  cardStyle?: CardStyles;
  linkRef?: React.RefObject<HTMLAnchorElement>;
  onClick?: () => void;
}

/** Card Created with inclusive components to handle links wrt a11y */
const LinkCardWrapper = genesisStyled(Flex)(
  css({
    '&:hover, &:focus-within': {
      boxShadow: 'focusOutline',
      borderRadius: 'medium',
      cursor: 'pointer',
    },
    a: {
      '&:hover': {
        textDecoration: 'none',
      },
      '&:focus': {
        borderColor: 'none',
        '&::before': {
          boxShadow: 'none',
        },
      },
    },
  }),
);

/**
 * @param {boolean} hasLink - determine if card has a link
 * @param {React.ReactNode} children - Accepts strings, elements, and React components
 * @param {FlexProps} cardStyle - Styling for Flex card container
 * @param {React.RefObject<HTMLAnchorElement>} linkRef - Reference to Link component which is inside children
 */

export const ConditionalLinkCard = ({
  children,
  hasLink,
  cardStyle = {},
  linkRef,
  onClick = () => {},
}: ConditionalLinkCardProps) => {
  const Card = hasLink ? LinkCardWrapper : Flex;
  const cardRef = useRef<HTMLDivElement>(null);

  const handleCardClick = () => {
    linkRef?.current?.click();
    onClick();
  };

  return (
    <Card
      ref={cardRef}
      flex={1}
      boxShadow="card"
      backgroundColor="surface.background.primary"
      borderRadius="large"
      borderStyle="solid"
      onClick={handleCardClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...cardStyle}
    >
      {children}
    </Card>
  );
};
