import { WebsocketApiURL } from 'types/socket.types';
import { LeagueReconnectingSocket } from './league-reconnecting-socket';

class ReconnectingSocketControllerClass {
  private instance?: LeagueReconnectingSocket;

  private API_URL: WebsocketApiURL = '';

  private activeHandlerCount = 0;

  setUrl(url: WebsocketApiURL) {
    this.API_URL = url;

    if (this.instance) {
      // reconnect to new init URL if there are active handlers
      if (this.activeHandlerCount > 0) {
        this.instance.reconnect();
      }
    } else {
      this.instance = new LeagueReconnectingSocket(this.urlProvider);
    }
  }

  private urlProvider = () => this.API_URL;

  addEventHandler(listener: Function): Function {
    if (!this.instance) {
      throw Error(`Reconnecting WebSocket has not been initialized`);
    }

    const eventHandler = (message: any) => {
      const unpackedMessage = JSON.parse(message.data);

      listener(unpackedMessage);
    };

    this.instance.addEventListener('message', eventHandler);

    this.activeHandlerCount += 1;

    this.instance.reconnect();

    return () => this.removeEventHandler(eventHandler);
  }

  private removeEventHandler(eventHandler: any) {
    if (
      // eslint-disable-next-line @typescript-eslint/dot-notation
      !this?.instance?.['_listeners']['message'].find(
        (l: any) => l === eventHandler,
      )
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `Event Handler does not exist or has already been removed from ReconnectingSocketController`,
      );

      return;
    }

    this.instance?.removeEventListener('message', eventHandler);
    this.activeHandlerCount -= 1;

    if (this.activeHandlerCount === 0) {
      this.instance?.close();
    }
  }

  destroy() {
    this.instance?.close();
    this.instance = undefined;
    this.activeHandlerCount = 0;
  }
}

export const ReconnectingSocketController =
  new ReconnectingSocketControllerClass();
