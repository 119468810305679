import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Text } from '@leagueplatform/ui-kit';
import { uniqueId, get } from 'lodash';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { ModuleStringInput, ModuleNumberInput, ModuleDateInput } from '.';

const InputContainer = styled(Box)`
  display: inline-block;
`;

export const AnswerInput = ({
  onChangeHandler,
  description,
  answers,
  ...props
}) => {
  const inputs = (answer) => {
    const { id, type, placeholderText, unitOptions, defaultUnit } = answer;

    switch (type) {
      case 'date':
        return (
          <ModuleDateInput
            ariadescribedby={`caption-${id}`}
            key={id}
            onChangeHandler={onChangeHandler}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        );
      case 'string':
        return (
          <ModuleStringInput
            ariadescribedby={`caption-${id}`}
            key={id}
            id={id}
            placeholderText={placeholderText}
            onChangeHandler={onChangeHandler}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        );
      case 'number': {
        return (
          <div role="group" aria-labelledby={`answer-title caption-${id}`}>
            <ModuleNumberInput
              ariadescribedby={`caption-${id}`}
              id={id}
              key={id}
              unitOptions={unitOptions}
              defaultUnit={defaultUnit}
              placeholderText={placeholderText}
              onChangeHandler={onChangeHandler}
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...props}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };
  return (
    <>
      <InputContainer>
        {answers.map((answer) => inputs(answer))}
        {description && (
          <Text color="greyLight" mt={1} fontSize={1}>
            {description}
          </Text>
        )}
      </InputContainer>
      {answers.map((answer) => {
        const answerCaption = get(answer, 'answerCaption', null);
        return (
          answerCaption && (
            <Text
              aria-hidden="true"
              key={uniqueId('input-answer-caption-')}
              color="greyLight"
              mt={1}
              fontSize={0}
              id={`caption-${answer.id}`}
            >
              {answerCaption}
            </Text>
          )
        );
      })}
    </>
  );
};

const answerPropTypes = {
  description: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  placeholderText: PropTypes.string,
  answerCaption: PropTypes.string,
  defaultUnit: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
  unitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

AnswerInput.propTypes = {
  setShowArrow: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  description: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape(answerPropTypes)),
  userSelections: PropTypes.shape({}).isRequired,
};

AnswerInput.defaultProps = {
  description: '',
  answers: [],
};
