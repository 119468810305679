import React from 'react';
import { ImageMessage } from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { Traits, WidgetType } from '@leagueplatform/masonry-api';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface ImageFooterProps {
  imageAltText: string;
  imageUrl: string;
  traits?: Traits;
}

export type ImageFooterWSNode = MasonryEngineNode<
  WidgetType.IMAGE_FOOTER,
  ImageFooterProps
>;

enum ImageAlignMap {
  left = 'start',
  center = 'center',
  right = 'end',
}

export const ImageFooter = ({
  imageAltText,
  imageUrl,
  traits,
}: ImageFooterProps) => {
  const traitItems = useGetTraitsForMedia(traits);
  return (
    <ImageMessage
      image={imageUrl}
      imageAlt={imageAltText}
      imageHeight={+(traitItems?.imageHeight || '50')}
      imageHorizontalAlignment={
        traitItems?.imageAlign ? ImageAlignMap[traitItems.imageAlign] : 'center'
      }
    />
  );
};
