{
  "dragDescriptionKeyboard": "Tryk på Enter for at starte med at trække.",
  "dragDescriptionKeyboardAlt": "Tryk på Alt + Enter for at starte med at trække.",
  "dragDescriptionLongPress": "Tryk længe for at starte med at trække.",
  "dragDescriptionTouch": "Dobbelttryk for at starte med at trække.",
  "dragDescriptionVirtual": "Klik for at starte med at trække.",
  "dragItem": "Træk {itemText}",
  "dragSelectedItems": "Træk {count, plural, one {# valgt element} other {# valgte elementer}}",
  "dragSelectedKeyboard": "Tryk på Enter for at trække {count, plural, one {# valgte element} other {# valgte elementer}}.",
  "dragSelectedKeyboardAlt": "Tryk på Alt + Enter for at trække {count, plural, one {# valgte element} other {# valgte elementer}}.",
  "dragSelectedLongPress": "Tryk længe for at trække {count, plural, one {# valgte element} other {# valgte elementer}}.",
  "dragStartedKeyboard": "Startet med at trække. Tryk på Tab for at gå til et slip-mål, tryk derefter på Enter for at slippe, eller tryk på Escape for at annullere.",
  "dragStartedTouch": "Startet med at trække. Gå til et slip-mål, og dobbelttryk derefter for at slippe.",
  "dragStartedVirtual": "Startet med at trække. Gå til et slip-mål, og klik eller tryk derefter på enter for at slippe.",
  "dropCanceled": "Slip annulleret.",
  "dropComplete": "Slip fuldført.",
  "dropDescriptionKeyboard": "Tryk på Enter for at slippe. Tryk på Escape for at annullere trækning.",
  "dropDescriptionTouch": "Dobbelttryk for at slippe.",
  "dropDescriptionVirtual": "Klik for at slippe.",
  "dropIndicator": "slip-indikator",
  "dropOnItem": "Slip på {itemText}",
  "dropOnRoot": "Slip på",
  "endDragKeyboard": "Trækning. Tryk på enter for at annullere træk.",
  "endDragTouch": "Trækning. Dobbelttryk for at annullere træk.",
  "endDragVirtual": "Trækning. Klik for at annullere trækning.",
  "insertAfter": "Indsæt efter {itemText}",
  "insertBefore": "Indsæt før {itemText}",
  "insertBetween": "Indsæt mellem {beforeItemText} og {afterItemText}"
}
