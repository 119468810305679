import { deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  USER_HEALTH_CAMPAIGNS_PARAMS,
  USER_HEALTH_CAMPAIGNS_PATH,
} from './constants/user-health-campaign.constants';
import { UserHealthCampaignByIdResponse } from './types/user-health-campaign.types';

export const getUserHealthCampaignById = async (
  userHealthCampaignId: string,
  includeUserHealthActivities?: boolean,
  includeUserHealthCampaigns?: boolean,
): Promise<UserHealthCampaignByIdResponse> => {
  let request: string = `${USER_HEALTH_CAMPAIGNS_PATH}/${userHealthCampaignId}`;

  const includedParams = [];

  if (includeUserHealthActivities) {
    includedParams.push(USER_HEALTH_CAMPAIGNS_PARAMS.USER_HEALTH_ACTIVITY);
  }

  if (includeUserHealthCampaigns) {
    includedParams.push(USER_HEALTH_CAMPAIGNS_PARAMS.USER_HEALTH_CAMPAIGN);
  }

  if (includedParams.length > 0) {
    request = request.concat(`?included=${includedParams.toString()}`);
  }

  const response = await leagueFetch(request);

  if (response.ok) {
    return deserialise(await response.json());
  }
  throw new Error('Something went wrong while fetching!');
};
