import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

const ENABLE_ABOUT_ME_WEB = 'about_me_web';
const ENABLE_ABOUT_ME_BE = 'about_me';

export const useIsAboutMeEnabled = () => {
  const { isLoading: isWebLoading, data: isAboutMeWebEnabled } =
    useFeatureFlagQuery(ENABLE_ABOUT_ME_WEB);

  const { isLoading: isBeLoading, data: isAboutMeBeEnabled } =
    useFeatureFlagQuery(ENABLE_ABOUT_ME_BE);

  const isAboutMeEnabled = isAboutMeWebEnabled && isAboutMeBeEnabled;

  const isLoading = isWebLoading || isBeLoading;

  return {
    isLoading,
    isAboutMeEnabled,
  };
};
