export const getCurrentIndex = ({
  currentQuestionIndex,
  questions,
  moduleNavigation,
}) => {
  if (currentQuestionIndex > 0 && currentQuestionIndex < questions.length) {
    const currentQuestionId = questions[currentQuestionIndex].id;

    const navigationQuestionIndex = moduleNavigation.findIndex(
      (question) => question.id === currentQuestionId,
    );
    return navigationQuestionIndex;
  }
  return 0;
};
