import * as React from 'react';
import {
  ControlledFormField,
  RegisterOptions,
} from '@leagueplatform/web-common';
import { Box, InputHint, TextInput } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export type IntegerInputProps = {
  id: string;
  label?: string;
  maxValue?: number;
  minValue?: number;
  name: string;
  placeholder?: string;
  unit?: string;
  value?: number;
  validationMessage: string;
  hint?: string;
  defaultValue?: number;
};

export function IntegerInput({
  id,
  label,
  maxValue,
  minValue,
  name,
  placeholder,
  unit,
  value,
  validationMessage,
  hint,
  defaultValue,
}: IntegerInputProps) {
  const { formatMessage, formatNumber } = useIntl();

  const inputRules: RegisterOptions = {
    valueAsNumber: true,
    required: {
      value: true,
      message: validationMessage,
    },
    min: {
      value: minValue || 0,
      message: formatMessage(
        { id: 'MIN_VALUE_ERROR_MESSAGE' },
        {
          min: formatNumber(minValue || 0),
        },
      ),
    },
    ...(maxValue && {
      max: {
        value: maxValue,
        message: formatMessage(
          { id: 'MAX_VALUE_ERROR_MESSAGE' },
          {
            max: formatNumber(maxValue),
          },
        ),
      },
    }),
  };

  return (
    <Box>
      <ControlledFormField
        id={id}
        label={label}
        name={name}
        inputOptions={inputRules}
      >
        <TextInput
          type="number"
          step="0.1"
          addOnEnd={unit || null}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
        />
      </ControlledFormField>
      {hint && (
        <InputHint
          css={{
            marginTop: '$half',
          }}
        >
          {hint}
        </InputHint>
      )}
    </Box>
  );
}
