import React, { ReactNode } from 'react';
import { css, genesisStyled, BodyOne } from '@leagueplatform/genesis-commons';
import { StackLayout, HeadingText } from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';

export interface ChallengeDetailsProps {
  heading: string;
  content?: string;
  children?: ReactNode;
}

const StyledList = genesisStyled(BodyOne)(() => {
  const checkCircle = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_CHECK_CIRCLE,
  ) as string;
  return css({
    listStyle: 'none',
    padding: 0,
    '& > li': {
      backgroundImage: `url(${checkCircle})`,
      backgroundSize: 16,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 4px',
      paddingLeft: 'oneAndHalf',
    },
  });
});

const StyledBodyOne = genesisStyled(BodyOne)(
  css({
    overflowWrap: 'anywhere',
  }),
);
export const ChallengeDetailsHtmlToReactOptions = {
  ul: {
    component: StyledList,
  },
  p: {
    component: StyledBodyOne,
  },
};

export const ChallengeDetails = ({
  heading,
  content,
  children,
}: ChallengeDetailsProps): JSX.Element | null => {
  if (!content && !children) return null;
  return (
    <StackLayout spacing="$half">
      <HeadingText size="xl" level="2">
        {heading}
      </HeadingText>
      {children || (
        <HtmlToReact
          htmlString={content}
          options={ChallengeDetailsHtmlToReactOptions}
        />
      )}
    </StackLayout>
  );
};
