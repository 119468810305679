import * as React from 'react';
import { BarRounded } from '@visx/shape';
import {
  useTheme,
  useThemeRawValues,
  UtilityText,
  HeadingText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import type {
  MetricType,
  MetricUnit,
  VerticalBarGraphItem,
} from '@leagueplatform/dashboard-api';

function average(numbers: number[]) {
  return numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
}

type LegendProps = {
  unit: MetricUnit;
  metric: MetricType;
  items: VerticalBarGraphItem[];
};

export function Legend({ unit, metric, items }: LegendProps) {
  const rawTheme = useThemeRawValues();
  const theme = useTheme();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const { formatUnit } = useLocaleUnitFormatter();

  return (
    <StackLayout orientation="horizontal" as="ul" spacing="$three" aria-hidden>
      {items.map((datum, index) => {
        const datumAverage = average(datum.values);

        return (
          <li key={datum.timeframelabel}>
            <StackLayout
              orientation="horizontal"
              verticalAlignment="center"
              spacing="$one"
            >
              <svg width={12} height={rawTheme.sizes.three}>
                <BarRounded
                  x={0}
                  y={0}
                  height={rawTheme.sizes.three}
                  width={rawTheme.sizes.threeQuarters}
                  radius={rawTheme.radii.extraLarge}
                  top // radius top
                  bottom // radius bottom
                  strokeWidth={theme.borderWidths.thick}
                  fill={
                    index === 0
                      ? theme.colors.decorativeBrandPrimaryBright
                      : theme.colors.decorativeBrandPrimaryDark
                  }
                />
              </svg>
              <StackLayout verticalAlignment="center" spacing="$none">
                <UtilityText emphasis="subtle" size="sm">
                  {datum.timeframelabel}
                </UtilityText>
                <HeadingText level="3" size="lg">
                  {`${formatMetricValue(datumAverage, metric)} ${formatUnit(
                    metric,
                    unit,
                    datumAverage,
                  )}`}
                </HeadingText>
              </StackLayout>
            </StackLayout>
          </li>
        );
      })}
    </StackLayout>
  );
}
