// TODO: switch value type to GDSSpacing when theme shape is merged
export const marginX = (value: any) => ({
  marginLeft: value,
  marginRight: value,
});

export const marginY = (value: any) => ({
  marginTop: value,
  marginBottom: value,
});
