import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import { CHALLENGE_PRIVACY_PATH } from './constants/challenge-requests.constants';
import {
  ChallengePrivacyConfigDeserialisedData,
  ChallengePrivacyConfigResponseData,
} from './types/challenge-privacy-config.type';

export const getChallengeConfig = async (
  userChallengeId?: string,
  errorContext?: ErrorContext,
): Promise<ChallengePrivacyConfigDeserialisedData | null> => {
  if (!userChallengeId) {
    return null;
  }
  const response = await leagueFetch(
    `${CHALLENGE_PRIVACY_PATH}/${userChallengeId}`,
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (!response.ok) {
    throw new Error(`getChallengeConfig error: ${response.status}`);
  }
  const responseJson: ChallengePrivacyConfigResponseData =
    await response.json();
  return deserialise(responseJson);
};
