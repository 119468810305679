import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { GetQuickActionsVersion } from '../types/get-quick-actions-version.type';

export const GET_QUICK_ACTIONS_MESSAGE_TYPE = 'get_quick_actions';

export const getQuickActions = (version: GetQuickActionsVersion = 3) =>
  SocketAsFetch.fetch({
    message_type: GET_QUICK_ACTIONS_MESSAGE_TYPE,
    info: { version },
  });
