import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { CoverImage } from '@leagueplatform/genesis-commons';
import {
  Box,
  HeadingText,
  StackLayout,
  UtilityText,
  keyframes,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { LottieAnimationItem } from '@leagueplatform/lottie';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import { Badge } from '@leagueplatform/web-common-components';
import {
  ChallengeTokenIcon,
  PointsIcon,
  RewardPointsIcon,
} from '@leagueplatform/rewards-common';
import {
  Achievement,
  REWARDS_EVENTS_ASSET_MAP,
  UnitTypes,
} from '../../constants/types';

interface CelebrationContentProps {
  achievement?: Achievement;
  isVisible: boolean;
  index: number;
}

const CelebrationLinesContainer = styled(Box, {
  position: 'absolute',
  width: '368px',
  height: '368px',
});

const ScaleAnimation = keyframes({
  '0%': {
    transform: 'scale(0)',
    opacity: 1,
  },
  '75%': {
    transform: 'scale(1.1)',
    opacity: 1,
  },
  '100%': {
    transform: 'scale(1)',
    opacity: 1,
  },
});

const FadeDownAnimation = keyframes({
  from: {
    transform: `translate(0px, -25px)`,
    opacity: 0,
  },
  to: {
    transform: `translate(0px, 0px)`,
    opacity: 1,
  },
});

const FadeUpAnimation = keyframes({
  from: {
    transform: `translate(0px, 25px)`,
    opacity: 0,
  },
  to: {
    transform: `translate(0px, 0px)`,
    opacity: 1,
  },
});

const AnimatedCoverImage = styled(CoverImage, {
  animationTimingFunction: 'ease-in',
  animationDuration: '0.4s',
  animationDelay: '0.5s',
  variants: {
    animationKey: {
      reducedMotion: { opacity: 1, animation: 'none' },
      ScaleAnimation: { opacity: 0, animationName: `${ScaleAnimation}` },
    },
    isVisible: {
      true: { animationPlayState: 'running' },
      false: { animationPlayState: 'paused' },
    },
  },
});

const AnimatedTranslateWrapper = styled(Box, {
  animationTimingFunction: 'ease-out',
  textAlign: 'center',
  variants: {
    isVisible: {
      true: { animationPlayState: 'running' },
      false: { animationPlayState: 'paused' },
    },
    animationKey: {
      reducedMotion: { opacity: 1, animation: 'none' },

      'animated-header': {
        opacity: 0,
        animation: `${FadeDownAnimation}`,
        animationDuration: '1s',
        animationDelay: '1s',
      },
      'animated-subheader': {
        opacity: 0,
        animation: `${FadeUpAnimation}`,
        animationDuration: '1s',
        animationDelay: '1.3s',
      },
      'animated-points': {
        opacity: 0,
        animation: `${FadeUpAnimation}`,
        animationDuration: '1s',
        animationDelay: '1.7s',
      },
    },
  },
});

export const CelebrationContent = ({
  achievement,
  isVisible,
  index,
}: CelebrationContentProps) => {
  const { formatMessage } = useIntl();
  const celebrationBadgeAnimationRef = useRef<LottieAnimationItem>(null);
  const [celebrationViewed, setCelebrationViewed] = useState(false);
  const preferredReducedMotion = usePrefersReducedMotion();
  const [showAnimation, setShowAnimation] = useState(false);

  const { unit, value } = achievement?.reward || {};

  useEffect(() => {
    if (isVisible && !celebrationViewed) {
      setTimeout(() => {
        setShowAnimation(true);
      }, 1300);
      setCelebrationViewed(true);
    }
  }, [isVisible, celebrationViewed]);

  return (
    <StackLayout
      orientation="vertical"
      horizontalAlignment="center"
      css={{ alignItems: 'center', width: '368px' }}
    >
      <AnimatedTranslateWrapper
        animationKey={
          preferredReducedMotion ? 'reducedMotion' : 'animated-header'
        }
        css={{ animationFillMode: 'forwards' }}
        flexDirection="column"
        isVisible={isVisible}
      >
        <HeadingText
          level="1"
          size="xxl"
          css={{
            marginTop: '$one',
            marginBottom: '$half',
            textAlign: 'center',
          }}
        >
          {achievement?.celebration_title}
        </HeadingText>
        <UtilityText
          css={{ textAlign: 'center', typography: '$bodyTwoSecondary' }}
        >
          {achievement?.celebration_subtitle}
        </UtilityText>
      </AnimatedTranslateWrapper>
      <StackLayout
        css={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '$oneAndHalf',
          marginBottom: '$half',
          '@desktop': {
            marginTop: '$four',
            marginBottom: '$half',
          },
        }}
      >
        <CelebrationLinesContainer>
          {
            handleStaticAsset(
              REWARDS_EVENTS_ASSET_MAP.ACHIEVEMENT_BADGE_ANIMATION,
              {
                isComponent: true,
                props: {
                  key: `animation-${index}`,
                  animationRef: celebrationBadgeAnimationRef,
                  shouldPlay: showAnimation,
                },
              },
            ) as ReactNode
          }
        </CelebrationLinesContainer>

        <AnimatedCoverImage
          animationKey={
            preferredReducedMotion ? 'reducedMotion' : 'ScaleAnimation'
          }
          isVisible={isVisible}
          css={{ animationFillMode: 'forwards' }}
          size="184px"
          backgroundSize="contain"
          src={
            achievement?.image?.large ??
            (handleStaticAsset(
              REWARDS_EVENTS_ASSET_MAP.ACHIEVEMENT_BADGE_PLACEHOLDER,
            ) as string)
          }
          alt={
            achievement?.name ??
            formatMessage({ id: 'YOUVE_EARNED_ACHIEVEMENT_BADGE' })
          }
        />
      </StackLayout>
      <AnimatedTranslateWrapper
        isVisible={isVisible}
        css={{ animationFillMode: 'forwards' }}
        animationKey={
          preferredReducedMotion ? 'reducedMotion' : 'animated-subheader'
        }
        flexDirection="column"
        alignItems="center"
      >
        <UtilityText
          css={{ color: '$onSurfaceTextSubdued', typography: '$bodyOne' }}
        >
          {formatMessage({ id: 'YOUVE_COMPLETED' })}
        </UtilityText>
        <HeadingText
          level="2"
          size="xl"
          css={{
            textAlign: 'center',
          }}
        >
          {achievement?.name}
        </HeadingText>
      </AnimatedTranslateWrapper>

      {Boolean(achievement?.reward.value) && (
        <AnimatedTranslateWrapper
          css={{ animationFillMode: 'forwards' }}
          animationKey="animated-points"
          flexDirection="column"
          isVisible={isVisible}
        >
          <StackLayout
            orientation="vertical"
            horizontalAlignment="center"
            css={{ marginTop: '$oneAndHalf' }}
          >
            <UtilityText
              css={{ typography: '$bodyTwoSecondary', marginBottom: '$half' }}
            >
              {formatMessage({ id: 'YOUVE_EARNED_TOTAL' })}
            </UtilityText>

            <Badge css={{ backgroundColor: '$decorativeBrandSecondaryPastel' }}>
              {unit === UnitTypes.LEAGUE_POINTS && (
                <PointsIcon value={value || 0} />
              )}
              {unit === UnitTypes.TOKENS && (
                <ChallengeTokenIcon value={value || 0} />
              )}
              {unit === UnitTypes.REWARD_POINTS && (
                <RewardPointsIcon
                  value={value || 0}
                  pointsText={formatMessage({ id: 'REWARD_POINTS' })}
                />
              )}
            </Badge>
          </StackLayout>
        </AnimatedTranslateWrapper>
      )}
    </StackLayout>
  );
};
