import React, { forwardRef, Children } from 'react';
import type { GDSHorizontalStackLayoutProps } from 'components/stack-layout/stack-layout.types';
import { useResponsiveProp } from '../../../hooks/use-responsive-prop';
import { styled } from '../../../theme';

const BaseStack = styled('div', {
  display: 'flex',
  flex: '1',
  variants: {
    horizontalAlignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
      spaceAround: {
        justifyContent: 'space-around',
      },
      spaceEvenly: {
        justifyContent: 'space-evenly',
      },
      stretch: {
        justifyContent: 'stretch',
        '& > *': {
          flex: '1 1 0',
        },
      },
    },
    verticalAlignment: {
      top: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      bottom: {
        alignItems: 'flex-end',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
  },
});

export const HorizontalStackLayout = forwardRef<
  HTMLDivElement,
  GDSHorizontalStackLayoutProps
>(
  (
    {
      horizontalAlignment = 'start',
      verticalAlignment = 'top',
      spacing,
      divider,
      children,
      className,
      css,
      ...props
    },
    ref,
  ) => {
    const responsiveSpacing = useResponsiveProp(spacing);
    const responsiveDivider = useResponsiveProp(divider);
    const arrayChildren = Children.toArray(children);
    return (
      <BaseStack
        horizontalAlignment={horizontalAlignment}
        verticalAlignment={verticalAlignment}
        ref={ref}
        className={['GDS-horizontal-stack-layout', className].join(' ')}
        css={{
          ...css,
          ...(spacing && { gap: `$space${responsiveSpacing}` }),
        }}
        {...props}
      >
        {React.Children.map(arrayChildren, (child, index) => {
          const isLast = index === arrayChildren.length - 1;

          return (
            <>
              {child}
              {!isLast && responsiveDivider ? responsiveDivider : null}
            </>
          );
        })}
      </BaseStack>
    );
  },
);
