import { getCurrentLocale, DEFAULT_LOCALE } from '@leagueplatform/locales';

interface Ids {
  en: string;
  es: string;
  fr: string;
}

// TODO: improve localization logic to support any number of languages
export const getTypeformUrl = (ids: Ids) => {
  let typeformUrlId = ids.en;

  const currentLocale = getCurrentLocale(DEFAULT_LOCALE);

  if (currentLocale.includes('fr')) {
    typeformUrlId = ids.fr;
  } else if (currentLocale.includes('es')) {
    typeformUrlId = ids.es;
  }

  return {
    url: `https://leagueca.typeform.com/to/${typeformUrlId}`,
    id: typeformUrlId,
  };
};
