import React from 'react';
import { Flex, PrimaryButton } from '@leagueplatform/genesis-commons';
import forwardArrow from 'assets/forward-arrow.svg';
import { Arrow, ArrowButtonProps } from '../arrow-button.component';

export const ForwardArrowButton = ({
  label,
  onClick,
  hidden = false,
}: ArrowButtonProps) => (
  <Flex display="inline-flex" flexDirection="column" alignItems="center">
    <PrimaryButton
      display="block"
      aria-hidden={hidden}
      opacity={hidden ? 0 : 1}
      onClick={onClick}
      width="56px"
      height="56px"
      paddingX="none"
      transition="background-color 200ms ease-in-out, opacity 0.2s ease-in-out"
      borderRadius="circle"
      aria-label={label}
    >
      <Arrow aria-hidden src={forwardArrow} alt={label} />
    </PrimaryButton>
  </Flex>
);
