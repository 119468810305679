import React, { useState } from 'react';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { getObservabilityContext } from '@leagueplatform/masonry-api';
import { logError } from 'utils/log-error';
import { WidgetsWrapper } from 'components/widgets-wrapper/widgets-wrapper.component';
import {
  MasonryConfigContext,
  MasonryConfigContextType,
} from '../../context/masonry-config-context';

export const MasonryProvider = ({
  config,
}: {
  config: MasonryConfigContextType;
}) => {
  const [masonryConfig] = useState<MasonryConfigContextType>(config);
  return (
    <ObservabilityErrorBoundary
      fallback={() => null}
      errorContext={getObservabilityContext({
        tags: {
          errorName: `Masonry - Error while rendering Masonry Container: ${config?.appId}`,
          container: config?.appId,
        },
      })}
      onError={(err: any) => {
        logError(
          `Masonry - Error while rendering Masonry Container: ${err.message}`,
        );
      }}
    >
      <MasonryConfigContext.Provider value={masonryConfig}>
        <WidgetsWrapper />
      </MasonryConfigContext.Provider>
    </ObservabilityErrorBoundary>
  );
};
