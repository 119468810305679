import React, { FC } from 'react';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { styled } from '../../theme';
import type { GDSCustomizableComponent, GDSSpacingValues } from '../../types';

export interface GDSCardSectionProps extends GDSCustomizableComponent {
  padding?: GDSSpacingValues;
  children: React.ReactNode;
}

export const BaseCardPart = styled('div', {
  padding: '$oneAndHalf',
  position: 'relative',
  flex: 1,
});

export const CardSection: FC<GDSCardSectionProps> = ({
  children,
  className,
  css,
  padding,
}: GDSCardSectionProps) => {
  const cardPadding = useResponsiveProp(padding);
  return (
    <BaseCardPart
      className={['GDS-card-section', className].join(' ')}
      css={{
        ...css,
        ...(padding && {
          padding: `${cardPadding}`,
        }),
      }}
    >
      {children}
    </BaseCardPart>
  );
};
