export enum HEALTH_PROGRAMS_ASSET_KEYS {
  STAR_RIBBON = 'STAR_RIBBON',
  SUCCESS_STATUS = 'SUCCESS_STATUS',
  CLOCK = 'CLOCK',
  // TODO - JRNY - Update tokens to reflect the source-of-truth when they become available
  CONNECT_DEVICES = 'CONNECT_DEVICES',
  ENABLED_BADGE_PLACEHOLDER = 'ENABLED_BADGE_PLACEHOLDER',
  GARBAGE_ICON = 'GARBAGE_ICON',
  HELPFUL_TIP_FALL_BACK_IMAGE = 'HELPFUL_TIP_FALL_BACK_IMAGE',
  DEVICE_CONNECTION_ERROR_ICON = 'DEVICE_CONNECTION_ERROR_ICON',
  MAX_PROGRAM_ENROLMENT_IMAGE = 'MAX_PROGRAM_ENROLMENT_IMAGE',
}
