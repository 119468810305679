import React, { useState } from 'react';
import {
  getModulePath,
  LEAGUE_MODULE_NAMES,
  useConfigProperty,
} from '@leagueplatform/core';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { Box, BodyOne } from '@leagueplatform/genesis-commons';
import {
  Carousel,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { useDocumentTitle } from '@leagueplatform/web-common';
import {
  SCREEN_NAMES,
  PRODUCT_AREA,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { CARE_COLLECTIONS_DATA_TYPE } from '@leagueplatform/care-collections-api';
import { CarePageWrapper } from '@leagueplatform/care-common';
import {
  CarouselCard,
  CarouselCardProps,
} from '../../components/carousel-card/carousel-card.component';
import { useGetCareCollections } from '../../hooks/use-care-collections.hook';
import { CarouselHeading } from '../../components/carousel-heading/carousel-heading.component';

interface CardProps extends CarouselCardProps {
  id: string;
}

export const CareCollections = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [careTopicTitles, setCareTopicTitles] = useState(new Map());
  const [careTopicCollections, setCareTopicCollections] = useState(new Map());
  const queryTagForCollectionsTopics = useConfigProperty(
    'careCollections.queryTagForCollectionsTopics',
    '',
  ) as string;

  const careCollectionsPath = getModulePath(
    LEAGUE_MODULE_NAMES.careCollections,
  );

  const pageTitle = formatMessage({ id: 'CARE_COLLECTIONS' });

  useDocumentTitle(pageTitle);

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: SCREEN_NAMES.CARE_COLLECTIONS_MENU,
    screen_name: pageTitle.toLowerCase(),
  });

  const { data, isLoading, isError } = useGetCareCollections(
    CARE_COLLECTIONS_DATA_TYPE.careCollectionsTopic,
    queryTagForCollectionsTopics,
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <CarePageWrapper backButtonOnClick={() => history.goBack()}>
        <ErrorState />
      </CarePageWrapper>
    );
  }

  // parse data to set careTopicTitles & careTopicCollections for rendering
  if (data?.data && careTopicTitles.size === 0) {
    const careTopicTitlesMap = new Map();
    const careTopicCollectionsMap = new Map();

    data.data.forEach((careCollection) => {
      const careCollectionTopic = careCollection.relationships.topics.data.find(
        ({ type }) => type === CARE_COLLECTIONS_DATA_TYPE.careCollectionsTopic,
      );

      if (careCollectionTopic !== undefined) {
        const { id: topicId } = careCollectionTopic;
        const topic = data?.included?.find(({ id }) => id === topicId);

        if (topic !== undefined) {
          careTopicTitlesMap.set(topicId, topic?.attributes);

          const topicCollection: CardProps = {
            title: careCollection.attributes.title,
            url: `${careCollectionsPath}/${careCollection.id}`,
            id: careCollection.id,
          };

          if (careTopicCollectionsMap.get(topicId)) {
            // append current careCollection to the Map
            const careTopicArray = careTopicCollectionsMap.get(topicId);
            careTopicCollectionsMap.set(topicId, [
              ...careTopicArray,
              topicCollection,
            ]);
          } else {
            careTopicCollectionsMap.set(topicId, [topicCollection]);
          }
        }
      }
    });

    setCareTopicTitles(careTopicTitlesMap);
    setCareTopicCollections(careTopicCollectionsMap);
  }

  const hasCareCollections = Boolean(careTopicTitles.size);

  return (
    <CarePageWrapper
      title={pageTitle}
      backButtonOnClick={() => history.goBack()}
    >
      <Box paddingTop="two">
        {hasCareCollections &&
          [...careTopicTitles.keys()].map((topicId) => {
            const topic = careTopicTitles.get(topicId);
            const collections = careTopicCollections.get(topicId);
            return (
              <Box
                key={`Topic-Carousel-${topicId}`}
                marginBottom="four"
                maxWidth={{
                  _: '300px',
                  tablet: '576px',
                  laptop: '852px',
                  desktop: '1128px',
                }}
              >
                <Carousel
                  name={topic?.title}
                  heading={
                    <CarouselHeading
                      title={topic?.title}
                      imageSrc={topic?.icon}
                    />
                  }
                >
                  {collections.map((collection: CardProps) => {
                    const { id, title, url } = collection;
                    return (
                      <CarouselCard
                        key={`Carousel-Card-${id}`}
                        title={title}
                        url={url}
                      />
                    );
                  })}
                </Carousel>
              </Box>
            );
          })}
        {!hasCareCollections && (
          <BodyOne>{formatMessage({ id: 'NO_CARE_COLLECTIONS' })}</BodyOne>
        )}
      </Box>
    </CarePageWrapper>
  );
};
