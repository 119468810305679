import { useState, useEffect } from 'react';
import { useLocation, useHistory } from '@leagueplatform/routing';
import { SHOW_PULSE_CHECK_MODAL } from 'constants/health-program-modal.constants.js';

interface LocationState {
  type?: string;
  programId?: string;
}

export const useLeaveProgramModal = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [showLeaveProgramPulseCheck, setShowLeaveProgramPulseCheck] = useState(
    location?.state?.type === SHOW_PULSE_CHECK_MODAL,
  );

  const [leaveProgramPulseCheckState] = useState(location.state);
  useEffect(() => {
    if (showLeaveProgramPulseCheck) {
      history.replace(location.pathname, { type: '' });
    }
  }, [history, location.pathname, showLeaveProgramPulseCheck]);

  return {
    showLeaveProgramPulseCheck,
    setShowLeaveProgramPulseCheck,
    leaveProgramPulseCheckState,
  } as const;
};
