import * as React from 'react';

export function Circle({ className }: { className?: string }) {
  return (
    <svg
      aria-hidden="true"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity=".1"
        d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1 12C1 5.925 5.925 1 12 1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
