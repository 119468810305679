import { leagueFetch } from '@leagueplatform/league-fetch';
import { GENERATE_QUESTIONNAIRE_PATH } from './constants/messaging-requests.constants';
import { QuestionnaireResponseData } from './types/messaging.types';

interface GenerateQuestionnaireProps {
  questionnaireId: string;
  answers?: Array<any>;
}
export const postGenerateQuestionnaire = async ({
  questionnaireId,
  answers = [],
}: GenerateQuestionnaireProps): Promise<QuestionnaireResponseData> => {
  const response = await leagueFetch(GENERATE_QUESTIONNAIRE_PATH, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'questionnaire',
        attributes: {
          nodeId: questionnaireId,
          answers,
        },
      },
    }),
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error(`postGenerateQuestionnaire error: ${response.status}`);
};
