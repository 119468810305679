import {
  Box,
  Card,
  ParagraphText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import {
  NestedCampaign,
  USER_HEALTH_CAMPAIGN_STATUS,
} from '@leagueplatform/health-journey-api';
import {
  ProgramBanner,
  ProgramDetailCard,
  ProgramHeading,
  ProgramRichText,
} from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { captureError } from '@leagueplatform/observability';
import { HeaderDate } from 'components/header-date/header-date.component';
import { RewardProgramsProgress } from 'components/reward-programs-progress/reward-programs-progress.component';
import React, { useEffect } from 'react';

interface RewardProgramHeaderProps {
  rewardProgram: NestedCampaign;
}

export function RewardProgramHeader({
  rewardProgram,
}: RewardProgramHeaderProps) {
  const { formatMessage } = useIntl();

  const { campaign, campaignProgress } = rewardProgram;

  const isMobile = useMediaQuery(queryHelpers.down('laptop'));

  const completedComponentAttributes =
    rewardProgram.completedComponentAttributes?.componentAttributes;

  const showCompletionBanner =
    campaign.data.displayGlobalProgress &&
    completedComponentAttributes &&
    rewardProgram.status === USER_HEALTH_CAMPAIGN_STATUS.COMPLETED;

  const showCampaignProgress =
    campaignProgress && campaign.data.displayGlobalProgress && isMobile;

  useEffect(() => {
    if (
      !rewardProgram.status &&
      !campaign.data.name &&
      !campaign.data.programImage
    ) {
      captureError(
        new Error(
          `Reward program(id:${rewardProgram.id}) wasn't configured properly`,
        ),
      );
    }
  }, [
    campaign.data.name,
    campaign.data.programImage,
    rewardProgram.id,
    rewardProgram.status,
  ]);

  return (
    <ProgramHeading
      badge={{
        css: { borderRadius: '$medium' },
        iconTextProps: {
          iconProps: {
            tint: '$decorativeBrandSecondaryDefault',
            icon: campaign.data.programHeaderIcon,
            size: '16px',
          },
          text: (
            <ParagraphText css={{ typography: '$overline' }}>
              {campaign.data.programHeader}
            </ParagraphText>
          ),
        },
      }}
      image={campaign.data.programImage}
      title={campaign.data.name}
    >
      {isMobile && (
        <HeaderDate
          startDate={campaign.data.startDate}
          endDate={campaign.data.endDate}
          dateCompleted={rewardProgram.completionDate}
          status={rewardProgram.status}
        />
      )}

      {showCompletionBanner && (
        <Card.Flat css={{ backgroundColor: '$successBackgroundSubdued' }}>
          <Card.Section>
            <ProgramBanner
              icon={completedComponentAttributes.iconUrl}
              basicText={
                <ProgramRichText
                  richText={completedComponentAttributes.richText}
                />
              }
            />
          </Card.Section>
        </Card.Flat>
      )}
      {showCampaignProgress && (
        <ProgramDetailCard
          backgroundColor="$surfaceBackgroundTertiary"
          heading={formatMessage({ id: 'PROGRAM_PROGRESS' })}
        >
          <Box css={{ fontSize: '$bodyOne' }}>
            <RewardProgramsProgress
              iconUrl={campaignProgress.iconUrl}
              currentValue={campaignProgress.current}
              maxValue={campaignProgress.threshold}
              showProgress
            />
          </Box>
        </ProgramDetailCard>
      )}
    </ProgramHeading>
  );
}
