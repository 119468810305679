import React from 'react';
import { ChoiceButton } from '@leagueplatform/genesis-core';

import { useIntl } from '@leagueplatform/locales';
import { ControlledFieldset } from '@leagueplatform/web-common';

export interface BooleanGroupProps {
  id: string;
  name: string;
  defaultValue?: string;
  legend: React.ReactNode;
  required?: boolean;
}

const choiceButtonStyle = {
  width: '50%',
  maxWidth: 248,
  '> label': {
    width: '100%',
  },
  '@mobile': {
    width: '100%',
    maxWidth: 'unset',
  },
};

export const BooleanGroup = ({
  id,
  name,
  defaultValue,
  legend,
  required,
}: BooleanGroupProps) => {
  const { formatMessage } = useIntl();
  const defaultInputRules = {
    required: {
      value: required,
      message: formatMessage({ id: 'PLEASE_MAKE_A_SELECTION' }),
    },
  };
  const inputRules = required ? defaultInputRules : {};

  return (
    <ControlledFieldset
      name={name}
      inputOptions={{
        ...inputRules,
        value: defaultValue,
      }}
      legend={legend}
      id={id}
    >
      <ChoiceButton
        css={{ ...choiceButtonStyle, marginBottom: '$quarter' }}
        inputType="radio"
        label={formatMessage({ id: 'YES' })}
        name={name}
        id={`${id}-yes`}
        value="true"
      />
      <ChoiceButton
        css={choiceButtonStyle}
        inputType="radio"
        label={formatMessage({ id: 'NO' })}
        name={name}
        id={`${id}-no`}
        value="false"
      />
    </ControlledFieldset>
  );
};
