import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { Link } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { CLP_CONTAINER_MAX_WIDTH } from 'pages/challenge-landing-page/constants/challenge-landing-page.constants';
import { noopFunction } from 'constants/challenges.constants';

export const CLPSkeletonLoader = (): JSX.Element => (
  <Flex
    as="section"
    flexDirection={{ _: 'column', laptop: 'row' }}
    alignItems={{ _: 'center', laptop: 'initial' }}
    padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
    justifyContent="center"
  >
    <Flex
      flexDirection={{ _: 'column', laptop: 'row' }}
      paddingRight={{ laptop: 'four' }}
      marginBottom="twoAndHalf"
      width="100%"
      maxWidth={CLP_CONTAINER_MAX_WIDTH}
    >
      <BackButtonArrow
        as={Link}
        justifyContent="initial"
        marginRight="quarter"
        marginY="half"
        onClick={noopFunction}
        to={JOURNEY_ROUTES.programsHome}
        size="three"
      />
      <Flex flexDirection="column" width="100%">
        {/* Image Skeleton Loader */}
        <SkeletonBox
          width="100%"
          maxWidth={CLP_CONTAINER_MAX_WIDTH}
          height="444px"
          backgroundColor="interactive.background.disabled"
          role="presentation"
        />

        {/* Description pre-load */}
        <SkeletonBox
          backgroundColor="interactive.background.disabled"
          marginTop="three"
          role="presentation"
        />
        <SkeletonBox
          backgroundColor="interactive.background.disabled"
          height="222px"
          role="presentation"
        />
      </Flex>
    </Flex>

    {/* Sidebar */}
    <Flex flexDirection="column" width={{ _: '100%', laptop: '30%' }}>
      <SkeletonBox
        width="100%"
        maxWidth={{ CLP_CONTAINER_MAX_WIDTH }}
        height="444px"
        backgroundColor="interactive.background.disabled"
        alignSelf="center"
        marginBottom="oneAndHalf"
        role="presentation"
      />
      <SkeletonBox
        width="100%"
        maxWidth={{ CLP_CONTAINER_MAX_WIDTH }}
        alignSelf="center"
        height="444px"
        backgroundColor="interactive.background.disabled"
        role="presentation"
      />
    </Flex>
  </Flex>
);
