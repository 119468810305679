import React from 'react';

/**
 * This is a workaround for components exported as forwardRefs to work with styled-components(< v4).
 *  We should remove this work-around once we upgrade styled-components to a version above v4.
 */

export const StyledComponentRefWrapper = (Component) => {
  return (props) => <Component {...props} />;
};
