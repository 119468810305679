import { captureError } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_GROUP_MESSAGE_TYPE = 'get_group';

export function getGroup(groupId: string) {
  if (!groupId) {
    const error = new Error('groupId is required');
    captureError(error);
    throw error;
  }

  return SocketAsFetch.fetch({
    message_type: GET_GROUP_MESSAGE_TYPE,
    info: {
      group_id: groupId,
    },
  });
}
