import React, { createContext, useState, useContext } from 'react';

interface PageError {
  error?: unknown;
}

export const PageErrorContext = createContext<
  [undefined | PageError, (config: PageError | undefined) => void]
>([undefined, () => {}]);

export function PageErrorProvider({ children }: React.PropsWithChildren<{}>) {
  const config = useState<undefined | PageError>(undefined);
  return (
    <PageErrorContext.Provider value={config}>
      {children}
    </PageErrorContext.Provider>
  );
}

export function usePageError(): [
  PageError | undefined,
  (config: PageError | undefined) => void,
] {
  const [value, setValue] = useContext(PageErrorContext);
  const setConfig = React.useCallback(
    (config: PageError | undefined) => {
      setValue(config);
    },
    [setValue],
  );

  return [value, setConfig];
}
