import React from 'react';

import {
  HeadingText,
  ParagraphText,
  UtilityText,
  TextAction,
  Button,
  styled,
} from '@leagueplatform/genesis-core/';

export type Tag = {
  component: string | React.ElementType;
  props?: Record<string, string | object>;
};

export type HTMLTagOptions = Record<string, Tag>;

// API format:
// 1. the html tag, eg h1,
// 2. the component to re-render it as,
// 3. and the props to spread on that component
export const defaultHTMLTagOptions: HTMLTagOptions = {
  a: { component: TextAction },
  p: {
    component: ParagraphText,
    props: { css: { '> a': { textDecoration: 'underline' } } },
  },
  h1: { component: HeadingText, props: { level: '1', size: 'xxl' } },
  h2: { component: HeadingText, props: { level: '2', size: 'xl' } },
  h3: { component: HeadingText, props: { level: '3', size: 'lg' } },
  h4: { component: HeadingText, props: { level: '4', size: 'md' } },
  h5: { component: HeadingText, props: { level: '5', size: 'sm' } },
  h6: { component: HeadingText, props: { level: '6', size: 'xs' } },
  img: { component: styled('img', { maxWidth: '100%' }) },
  button: { component: Button },
  ul: {
    component: ParagraphText,
    props: {
      as: 'ul',
      css: {
        listStyle: 'revert',
        paddingLeft: '$oneAndHalf',
        li: { typography: '$bodyOne' },
      },
    },
  },
  ol: {
    component: ParagraphText,
    props: {
      as: 'ol',
      css: {
        listStyle: 'revert',
        paddingLeft: '$oneAndHalf',
        li: { typography: '$bodyOne' },
      },
    },
  },
  b: { component: UtilityText, props: { as: 'b', emphasis: 'emphasized' } },
  strong: {
    component: UtilityText,
    props: { as: 'strong', emphasis: 'emphasized' },
  },
  em: {
    component: UtilityText,
    props: { as: 'em', css: { fontStyle: 'italic' } },
  },
  i: {
    component: UtilityText,
    props: { as: 'i', css: { fontStyle: 'italic' } },
  },
  sup: {
    component: UtilityText,
    props: { as: 'sup', color: '$onSurfaceTextSubdued', size: 'eyebrow' },
  },
};
