import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { NestedCampaign } from '@leagueplatform/health-journey-api';
import {
  ProgramDetailCard,
  ProgramDetailCardFooter,
  ProgramRichText,
} from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { HeaderDate } from 'components/header-date/header-date.component';
import { RewardProgramsProgress } from 'components/reward-programs-progress/reward-programs-progress.component';
import React from 'react';

interface RewardProgramSideBarProps {
  rewardProgram: NestedCampaign;
}

export function RewardProgramSideBar({
  rewardProgram,
}: RewardProgramSideBarProps) {
  const { formatMessage } = useIntl();

  const { campaign, campaignProgress } = rewardProgram;

  const endedComponentAttributes =
    rewardProgram.endedComponentAttributes?.componentAttributes;

  const showVisibilityBanner =
    new Date(rewardProgram?.visibilityEndDate) > new Date() &&
    new Date(rewardProgram?.campaign.data.endDate) < new Date() &&
    endedComponentAttributes;

  return (
    <StackLayout spacing="$oneAndHalf">
      {campaign.data.displayGlobalProgress && (
        <ProgramDetailCard heading={formatMessage({ id: 'PROGRAM_PROGRESS' })}>
          <Box css={{ fontSize: '$bodyOne' }}>
            <RewardProgramsProgress
              iconUrl={campaignProgress?.iconUrl}
              currentValue={campaignProgress?.current}
              maxValue={campaignProgress?.threshold}
              showProgress
            />
          </Box>
        </ProgramDetailCard>
      )}
      <ProgramDetailCard
        heading={formatMessage({ id: 'PROGRAM_DETAILS' })}
        footer={
          showVisibilityBanner &&
          endedComponentAttributes && (
            <ProgramDetailCardFooter
              boldedText={endedComponentAttributes.title}
              basicText={
                <ProgramRichText richText={endedComponentAttributes.richText} />
              }
              icon={endedComponentAttributes.iconUrl}
            />
          )
        }
      >
        <HeaderDate
          startDate={campaign.data.startDate}
          endDate={campaign.data.endDate}
          dateCompleted={rewardProgram.completionDate}
          status={rewardProgram.status}
        />
      </ProgramDetailCard>
    </StackLayout>
  );
}
