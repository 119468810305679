import React, { FC, forwardRef } from 'react';
import type { GDSBaseSelectableProps } from '../../types';
import { styled } from '../../../../theme';
import {
  SelectableLoader,
  SelectableLoaderWrapper,
} from '../selectable-loader';
import { GenesisCoreInspector } from '../../../../test-utils/genesis-core-inspector';

const RadioBase = styled('input', {
  appearance: 'none',
  borderRadius: '$circle',
  borderWidth: '$thin',
  borderColor: '$onSurfaceBorderDefault',
  borderStyle: 'solid',
  backgroundColor: '$inputBackgroundDefault',
  margin: 0,
  width: '$oneAndHalf',
  height: '$oneAndHalf',
  display: 'inline-block',
  'vertical-align': 'text-bottom',
  position: 'relative',
  transition: 'box-shadow $defaultTime ease-in-out',
  '&::before': {
    content: '',
    position: 'absolute',
    margin: 'auto',
    height: 0,
    width: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    top: 0,
    transition: 'opacity $defaultTime ease-in-out',
    opacity: 0,
  },
  '&:checked': {
    borderColor: '$interactiveActionPrimary',
    background: '$interactiveActionPrimary',
  },
  '&:checked::before': {
    border: '4px solid transparent',
    'border-radius': '$circle',
    'outline-offset': '-6px',
    background: '$inputBackgroundDefault',
    opacity: 1,
  },
  '&:checked ~ .GDS-selectable-loader': {
    borderColor: '$interactiveActionPrimary',
    backgroundColor: '$interactiveActionPrimary',
    '& svg': {
      color: '$onSurfaceTextReversed',
    },
  },
  '&:disabled, &[aria-disabled="true"], &[aria-readonly="true"]': {
    backgroundColor: '$inputBackgroundDisabled',
    borderColor: '$inputBorderDisabled',
  },
  '&:disabled:checked::before, &[aria-disabled="true"]:checked::before': {
    background: '$onSurfaceTextSubdued',
  },
  '&[aria-readonly="true"]:checked:before': {
    background: '$onSurfaceTextPrimary',
  },
  variants: {
    inputStatus: {
      success: {
        borderColor: '$inputBorderSuccess',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderSuccess',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderSuccess',
          '& svg': {
            color: '$inputBorderSuccess',
          },
        },
        '&:checked': {
          borderColor: '$inputBorderSuccess',
          background: '$inputBorderSuccess',
        },
        '&:checked ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderSuccess',
          backgroundColor: '$inputBorderSuccess',
          '& svg': {
            color: '$onSurfaceTextReversed',
          },
        },
      },
      warning: {
        borderColor: '$inputBorderWarning',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderWarning',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderWarning',
          '& svg': {
            color: '$inputBorderWarning',
          },
        },
        '&:checked': {
          borderColor: '$inputBorderWarning',
          background: '$inputBorderWarning',
        },
        '&:checked ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderWarning',
          backgroundColor: '$inputBorderWarning',
          '& svg': {
            color: '$onSurfaceTextReversed',
          },
        },
      },
      error: {
        borderColor: '$inputBorderCritical',
        '&:hover:not(:focus)': {
          borderColor: '$inputBorderCritical',
        },
        '& ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderCritical',
          '& svg': {
            color: '$inputBorderCritical',
          },
        },
        '&:checked': {
          borderColor: '$inputBorderCritical',
          background: '$inputBorderCritical',
        },
        '&:checked ~ .GDS-selectable-loader': {
          borderColor: '$inputBorderCritical',
          backgroundColor: '$inputBorderCritical',
          '& svg': {
            color: '$onSurfaceTextReversed',
          },
        },
      },
    },
  },
});

export const RadioIndicator: FC<GDSBaseSelectableProps> = forwardRef(
  (
    {
      id,
      name,
      value,
      required,
      checked,
      inputStatus,
      loading,
      disabled,
      'aria-disabled': ariaDisabled,
      'aria-describedby': ariaDescribedby,
      'aria-readonly': ariaReadonly,
      onClick,
      onChange,
      onBlur,
      onFocus,
      className = '',
      css,
      ...props
    }: GDSBaseSelectableProps,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <GenesisCoreInspector displayName="RadioIndicator">
      <SelectableLoaderWrapper>
        <RadioBase
          type="radio"
          id={id}
          value={value}
          name={name}
          checked={checked}
          className={['GDS-radio', className].join(' ')}
          inputStatus={inputStatus}
          {...(inputStatus === 'error' && { 'aria-invalid': true })}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          aria-describedby={ariaDescribedby}
          aria-readonly={ariaReadonly}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          css={css}
          ref={ref}
          {...props}
        />
        {loading && <SelectableLoader inputType="radio" loading={loading} />}
      </SelectableLoaderWrapper>
    </GenesisCoreInspector>
  ),
);

RadioIndicator.displayName = 'RadioIndicator';
