import React, { useEffect, useState } from 'react';
import { Link } from '@leagueplatform/routing';
import { openInTab, splitAtNewlines } from '@leagueplatform/web-common';
import { useConfigProperty } from '@leagueplatform/core';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { useIntl } from '@leagueplatform/locales';
import {
  PRODUCT_AREA,
  sendAnalyticsEvent,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';

import { Image } from '@leagueplatform/ui-kit';
import {
  LoadingIndicator,
  PageHeaderBackground,
  BackButtonArrow,
  DimensionOfHealthModal,
  ErrorState,
} from '@leagueplatform/web-common-components';

import {
  Flex,
  Box,
  HeadingOne,
  BodyOne,
  HeadingTwo,
  genesisStyled,
  css,
  ImageProps,
} from '@leagueplatform/genesis-commons';

import { Topic } from 'types/health-need-pages.types';
import { useHealthNeed } from '../../hooks/use-health-need.hook';
import { HealthSolutionsList } from '../../components/health-solutions-list/health-solutions-list.component';
import { TopHealthSolution } from '../../components/top-health-solution/top-health-solution.component';
import { getHealthSolutionUrl } from '../../utils/get-health-solution-url';
import { LIFEMARKET } from '../../constants/health-solution-types.constants';

interface FocusAreaPageProps {
  match: {
    params: {
      topic: string;
      category: string;
    };
  };
  noSolutionsMessage?: React.ReactNode;
}
interface SelectedTopic {
  title: string;
  description: string;
  imageId: string;
  url: string;
  icon?: string;
}

const TopicImage = genesisStyled(Image)<ImageProps>(
  css({
    margin: 'half',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
      transition: 'all 0.2s ease-in-out',
      transform: 'translateY(-2px)',
    },
  }),
);

const DefaultNoSolutionsMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <BodyOne color="onSurface.text.subdued">
      {formatMessage({ id: 'NO_SOLUTIONS_AVAILABLE' })}
    </BodyOne>
  );
};

/**
 * This will be renamed to Health Need Page later.
 * Route: `/health-need/<category>/<topic>`
 * - category doesn't matter right now, this is future prep. This features uses the topic
 * from the url and fetches data using `get_health_need`.
 *
 * This page shows details about a health need including option recommendations, called "health solutions".
 * Health solutions currently only have:
 * - employer program
 * - League health program
 * - League Marketplace offerings
 * Each of these solutions open in a new tab.
 */
export const FocusAreaPage = ({
  match: {
    params: { topic },
  },
  noSolutionsMessage = DefaultNoSolutionsMessage(),
}: FocusAreaPageProps) => {
  const { isLoading, isError, data } = useHealthNeed(topic);

  useEffect(() => {
    if (topic) {
      sendAnalyticsPageView({
        screen_name: `Health Needs - ${topic} - Focus Area Detail`,
      });
    }
  }, [topic]);

  const [selectedTopic, setSelectedTopic] = useState<SelectedTopic | null>(
    null,
  );

  const { formatMessage } = useIntl();

  const backButtonPath = '/member/health-profile';

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  if (
    isError ||
    // Show error state if we don't have the correct data to display the health need page
    (!isLoading && !data?.name && !data?.description)
  ) {
    return (
      <Flex
        padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
        flexDirection={{ _: 'column', phone: 'row' }}
        maxWidth={1440}
        justifyContent="center"
      >
        <Box marginRight="twoAndHalf">
          {/* Using Button as Link */}
          {/* TODO: Fix when page is redesigned */}
          {/* @ts-ignore */}
          <BackButtonArrow
            as={Link}
            to={backButtonPath}
            marginY="threeQuarters"
            marginLeft="none"
            marginRight="two"
          />
        </Box>
        <Box marginTop="three">
          <ErrorState />
        </Box>
      </Flex>
    );
  }

  if (data && !isLoading) {
    const {
      name,
      description,
      topHealthSolution,
      healthSolutions,
      topics,
      lifemarketUrl,
    } = data;

    const noSolutions = !topHealthSolution && !healthSolutions?.length;

    return (
      <Flex
        padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
        marginX="auto"
        maxWidth={1440}
      >
        <PageHeaderBackground />
        <Flex flexDirection={{ _: 'column', phone: 'row' }}>
          <Box>
            {/* Using Button as Link */}
            {/* TODO: Fix when page is redesigned */}
            {/* @ts-ignore */}
            <BackButtonArrow
              as={Link}
              to={backButtonPath}
              marginY="threeQuarters"
              marginLeft="none"
              marginRight="two"
            />
          </Box>
          <Box>
            <HeadingOne
              marginBottom="one"
              fontSize={{ _: 'heading2', tablet: 'heading1' }}
            >
              {name}
            </HeadingOne>
            {topics && (
              <Flex>
                {topics.map((dimensionOfHealthTopic: Topic) => (
                  // Using ui-kit Image which requires all bg properties as props, ignoring TS check for now
                  // TODO: Fix when page is redesigned and use genesis Image component
                  // @ts-ignore
                  <TopicImage
                    data-testid="dimension-of-health-topic"
                    key={`${name}-topic-icon-${dimensionOfHealthTopic?.id}`}
                    url={
                      dimensionOfHealthTopic?.imageId
                        ? getContentUrl(dimensionOfHealthTopic?.imageId)
                        : dimensionOfHealthTopic?.placeholderImage
                    }
                    aria-label={name}
                    tabIndex="0"
                    width={50}
                    height={50}
                    onClick={(e: any) => {
                      e.stopPropagation();

                      sendAnalyticsEvent({
                        category: `Health Needs - ${name}`,
                        action: 'View Dimension of Health Details',
                        label: dimensionOfHealthTopic.name,
                      });

                      setSelectedTopic({
                        title: dimensionOfHealthTopic.name,
                        description: dimensionOfHealthTopic.description,
                        imageId: dimensionOfHealthTopic.imageId,
                        url: dimensionOfHealthTopic.url,
                        icon: dimensionOfHealthTopic.icon,
                      });
                    }}
                  />
                ))}
              </Flex>
            )}
            {description && (
              <Box
                maxWidth="850px"
                width="100%"
                marginTop={topics?.length ? 'oneAndHalf' : 'four'}
              >
                {splitAtNewlines(description).map((partDescription) => (
                  <BodyOne key={description} color="onSurface.text.subdued">
                    {partDescription}
                  </BodyOne>
                ))}
              </Box>
            )}
            {topHealthSolution && (
              <Flex flexDirection="column" marginTop="oneAndHalf">
                <HeadingTwo marginBottom="one">
                  {formatMessage({ id: 'TOP_MATCH' })}
                </HeadingTwo>
                <TopHealthSolution
                  heading={topHealthSolution.title}
                  description={topHealthSolution.description}
                  imageSrc={topHealthSolution.imageSrc}
                  type={topHealthSolution.type}
                  overline={topHealthSolution.tagline}
                  onCardClick={() => {
                    sendAnalyticsEvent({
                      category: `Health Needs - ${name}`,
                      action: `Select Recommended Health Solution - ${topHealthSolution.type}`,
                      label: topHealthSolution.title,
                      parameter: {
                        rank: 1,
                        solution_id: topHealthSolution.id,
                        solution_type: topHealthSolution.type,
                      },
                    });
                    const healthSolutionUrl = getHealthSolutionUrl(
                      topHealthSolution.link,
                      topHealthSolution.type,
                    );

                    // TODO: Use query param `c=1` to hide back button if opened in tab.
                    // focusAreaId is added to the url as a query param to ensure the back button of each health program in this list goes back to the focus area page
                    if (topHealthSolution.type === LIFEMARKET) {
                      handleLinkCallback(healthSolutionUrl);
                    } else {
                      openInTab(healthSolutionUrl);
                    }
                  }}
                />
              </Flex>
            )}
            {!!healthSolutions?.length && name && (
              <Flex flexDirection="column" marginTop="two">
                <HeadingTwo marginBottom="oneAndHalf">
                  {formatMessage({ id: 'MORE_GREAT_RESOURCES' })}
                </HeadingTwo>
                <Flex
                  flexDirection={{ _: 'column', phone: 'row' }}
                  maxWidth="860px"
                  flexWrap="wrap"
                >
                  <HealthSolutionsList
                    healthSolutionsData={healthSolutions}
                    lifemarketUrl={lifemarketUrl}
                    healthNeedName={name}
                  />
                </Flex>
              </Flex>
            )}
            {noSolutions && (
              <Flex
                flexDirection="column"
                marginTop="oneAndHalf"
                maxWidth="850px"
              >
                {/* Temp solution until Chat is modularized and we can import the chat banner here */}
                {noSolutionsMessage}
              </Flex>
            )}
          </Box>
        </Flex>

        {selectedTopic && (
          <DimensionOfHealthModal
            title={selectedTopic.title}
            description={selectedTopic.description}
            imageId={selectedTopic.imageId}
            url={selectedTopic.url}
            icon={selectedTopic.icon}
            onClose={() => {
              setSelectedTopic(null);
            }}
            analyticsScreenName={`Health Needs - ${name}`}
            analyticsProductArea={PRODUCT_AREA.HEALTH_ASSESSMENTS}
          />
        )}
      </Flex>
    );
  }

  return <LoadingIndicator />;
};
