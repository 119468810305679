import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { isProduction } from '@leagueplatform/app-environment';

export const useIsChallengesEnabled = () => {
  const ENABLE_CHALLENGES = 'enable_challenges';

  const { isLoading, data: isChallengesFFEnabled } =
    useFeatureFlagQuery(ENABLE_CHALLENGES);

  const isChallengesEnabled = isChallengesFFEnabled || !isProduction();

  return {
    isLoading,
    isChallengesEnabled,
  };
};
