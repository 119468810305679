interface BrowserData {
  name: string;
  regex: RegExp;
}

const userAgentBrowsersRule: BrowserData[] = [
  { name: 'android', regex: /Android\s([0-9.]+)/ },
  { name: 'chrome', regex: /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/ },
  {
    name: 'chromium-webview',
    regex: /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/,
  },
  { name: 'edge', regex: /Edge\/([0-9._]+)/ },
  { name: 'edge-chromium', regex: /EdgA?\/([0-9.]+)/ },
  { name: 'edge-ios', regex: /EdgiOS\/([0-9._]+)/ },
  { name: 'firefox', regex: /Firefox\/([0-9.]+)(?:\s|$)/ },
  { name: 'ios', regex: /Version\/([0-9._]+).*Mobile.*Safari.*/ },
  { name: 'ios-webview', regex: /AppleWebKit\/([0-9.]+).*Mobile/ },
  { name: 'ios-webview', regex: /AppleWebKit\/([0-9.]+).*Gecko\)$/ },
  { name: 'opera', regex: /Opera\/([0-9.]+)(?:\s|$)/ },
  { name: 'opera', regex: /OPR\/([0-9.]+)(:?\s|$)/ },
  { name: 'opera-mini', regex: /Opera Mini.*Version\/([0-9.]+)/ },
  { name: 'safari', regex: /Version\/([0-9._]+).*Safari/ },
  { name: 'samsung', regex: /SamsungBrowser\/([0-9.]+)/ },
];

export interface BrowserInfo {
  name: string | undefined;
  version: string | undefined;
}

export const getBrowserInfo = (): BrowserInfo => {
  if (typeof window === 'undefined')
    return { name: undefined, version: undefined };

  const { userAgent } = navigator;

  return userAgentBrowsersRule.reduce<BrowserInfo>(
    (result, browserData) => {
      if (result.name) return result;
      const match = userAgent.match(browserData.regex);
      if (match) {
        return { name: browserData.name, version: match[1] };
      }
      return result;
    },
    { name: undefined, version: undefined },
  );
};
