import React from 'react';
import { KEY } from '@leagueplatform/web-common';
import { Formik, Form } from 'formik';
import { Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import {
  NewMessageData,
  MetaConfigurationAttributes,
} from '@leagueplatform/messaging-api';
import { usePostNewThread } from 'hooks/use-post-new-thread.hook';
import { useSendMessageInThread } from 'hooks/use-send-message-in-thread.hook';
import { DraftThreadType } from 'types/messaging.types';
import {
  TEXT_AREA_NAME,
  FILE_UPLOAD_NAME,
} from './compose-message-footer.constants';
import {
  ComposeMessageFooter,
  FormValues,
} from './compose-message-footer.component';
import { MessageClosedText } from '../message-closed-text/message-closed-text.component';

const StyledForm = genesisStyled(Form)`
  display: flex;
  flex-direction: column;
`;

export const initialValues: FormValues = {
  [TEXT_AREA_NAME]: '',
  [FILE_UPLOAD_NAME]: null,
};

export interface ComposeFormProps {
  isDraftThread: boolean;
  draftThread: DraftThreadType;
  resetDraftState: () => void;
  messageClosed?: boolean;
  threadConfigOverride?: MetaConfigurationAttributes[];
}

export const ComposeForm = ({
  isDraftThread,
  draftThread,
  resetDraftState,
  messageClosed,
  threadConfigOverride,
}: ComposeFormProps) => {
  const { mutate: createThreadMutation, isLoading: isLoadingCreateThread } =
    usePostNewThread();

  const {
    mutate: createNewMessageMutation,
    isLoading: isLoadingNewMessage,
    isLoadingThreadData,
  } = useSendMessageInThread();

  if (messageClosed) {
    return <MessageClosedText />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        if (isDraftThread) {
          const newThreadData = {
            subject: draftThread.subject,
            participants: draftThread.participants,
            text: values[TEXT_AREA_NAME],
            document: values[FILE_UPLOAD_NAME],
            answers: draftThread.answers,
          };
          createThreadMutation({
            newThreadData,
            resetForm,
            resetDraftState,
          });
          return;
        }
        const newMessageData: NewMessageData = {
          text: values[TEXT_AREA_NAME],
          document: values[FILE_UPLOAD_NAME],
        };

        createNewMessageMutation({
          newMessageData,
          resetForm,
        });
      }}
    >
      {({ values, setFieldValue, resetForm, submitForm, errors }) => {
        const isMessageDataLoading =
          isLoadingCreateThread || isLoadingNewMessage;
        const isFormEmpty =
          !values[TEXT_AREA_NAME].trimEnd().length &&
          (!values[FILE_UPLOAD_NAME] || values[FILE_UPLOAD_NAME].length === 0);
        const isButtonDisabled = isLoadingThreadData || isFormEmpty;
        return (
          <StyledForm
            data-testid="compose-message-form"
            onKeyDown={(e) => {
              const activeElement = e.target as HTMLElement;
              if (
                e.key === KEY.ENTER &&
                !e.shiftKey &&
                // Ensures we're not preventing the default behaviour of elements that should be triggered by the Enter key
                // such as the CloseButton in the FilePreview content,
                // and theSend button in the ComposeMessageFooter to behave as expected for keyboard users
                activeElement.tagName === 'TEXTAREA'
              ) {
                e.preventDefault();
                if (!isFormEmpty) {
                  submitForm();
                }
              }
            }}
          >
            <Flex flexDirection="column">
              <ComposeMessageFooter
                values={values}
                setFieldValue={setFieldValue}
                isLoading={isMessageDataLoading}
                isButtonDisabled={isButtonDisabled}
                resetForm={resetForm}
                textareaError={errors[TEXT_AREA_NAME]}
                threadConfigOverride={threadConfigOverride}
              />
            </Flex>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
