import { useQuery } from 'react-query';

import {
  getHealthGoalProgram,
  GET_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';

export const useGetHealthProgram = (programId) =>
  useQuery([GET_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE, programId], () =>
    getHealthGoalProgram(programId),
  );
