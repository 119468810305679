import { useQuery } from 'react-query';
import { ErrorContext } from '@leagueplatform/observability';
import { getObservabilityContext } from 'utils/get-observability-context';
import { getContainerPDF } from 'utils/get-container-pdf';

export const GET_CONTAINER_PDF = 'get_container_pdf';

export const useGeneratePDFReport = (
  generatePDF: boolean,
  {
    containerId: container_id,
    filename,
    templateId: template_id,
    version,
  }: {
    containerId: string;
    filename: string;
    templateId?: string;
    version: number;
  },
  errorContext: ErrorContext = {}, // Placed error context for capability teams to extend errorContext
) =>
  useQuery<any, Error>(
    [GET_CONTAINER_PDF],
    () =>
      getContainerPDF(
        GET_CONTAINER_PDF,
        {
          container_id,
          filename,
          version,
          ...(template_id && { template_id }),
        },
        getObservabilityContext({
          ...errorContext,
          errorName: 'Masonry - get container pdf widget api error',
          tags: {
            ...(errorContext?.tags || {}),
          },
        }),
      ),
    { enabled: generatePDF },
  );
