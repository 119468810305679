import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  CHALLENGE_HIGHLIGHT_EVENT_TYPES,
  Highlight,
} from '@leagueplatform/health-journey-api';
import { UtilityText } from '@leagueplatform/genesis-core';
import type { Formatter } from '@leagueplatform/locales';
import { CHALLENGE_OR_TEAM_VALUES } from '../constants/highlight-card.constants';

interface HighlightDescriptionProps {
  event: Highlight['event'];
  teamName?: string;
  challengeTitle: string;
}

const strong: Formatter = (msg) => (
  <UtilityText as="strong" emphasis="emphasized">
    {msg}
  </UtilityText>
);

export const useHighlightDescription = ({
  event,
  teamName,
  challengeTitle,
}: HighlightDescriptionProps) => {
  const { formatMessage, $tifelse, formatNumber } = useIntl();
  switch (event.type) {
    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.CHALLENGE:
      return {
        displayDescription: formatMessage(
          { id: 'JOINED_CHALLENGE_HIGHLIGHT' },
          { challengeTitle, strong },
        ),
        analyticsDescription: formatMessage(
          { id: 'JOINED_CHALLENGE_HIGHLIGHT' },
          { challengeTitle },
        ),
      };

    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.TEAM:
      return {
        displayDescription: $tifelse(
          event.value === CHALLENGE_OR_TEAM_VALUES.CREATED,
          'CREATED_TEAM_HIGHLIGHT',
          'JOINED_TEAM_HIGHLIGHT',
          { teamName, strong },
        ),
        analyticsDescription: $tifelse(
          event.value === CHALLENGE_OR_TEAM_VALUES.CREATED,
          'CREATED_TEAM_HIGHLIGHT',
          'JOINED_TEAM_HIGHLIGHT',
          { teamName },
        ),
      };

    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.STEPS:
    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.MINUTES: {
      const eventValue =
        typeof event.value === 'string'
          ? parseInt(event.value, 10)
          : event.value;
      return {
        displayDescription: $tifelse(
          event.type === CHALLENGE_HIGHLIGHT_EVENT_TYPES.STEPS,
          'STEPS_WALKED_HIGHLIGHT',
          'ACTIVE_MINUTES_HIGHLIGHT',
          { total: formatNumber(eventValue), strong },
        ),
        analyticsDescription: $tifelse(
          event.type === CHALLENGE_HIGHLIGHT_EVENT_TYPES.STEPS,
          'STEPS_WALKED_HIGHLIGHT',
          'ACTIVE_MINUTES_HIGHLIGHT',
          { total: formatNumber(eventValue) },
        ),
      };
    }

    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.MINDFUL_MINUTE: {
      const minutes =
        typeof event.value === 'string'
          ? parseInt(event.value, 10)
          : event.value;
      return {
        displayDescription: formatMessage(
          {
            id: 'MINDFUL_MINUTES_HIGHLIGHT',
          },
          { total: formatNumber(minutes), strong },
        ),
        analyticsDescription: formatMessage(
          {
            id: 'MINDFUL_MINUTES_HIGHLIGHT',
          },
          { total: formatNumber(minutes) },
        ),
      };
    }

    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.ACHIEVEMENT:
      return {
        displayDescription: formatMessage(
          { id: 'EARNED_ACHIEVEMENT_HIGHLIGHT' },
          { achievement: event.value, strong },
        ),
        analyticsDescription: formatMessage(
          { id: 'EARNED_ACHIEVEMENT_HIGHLIGHT' },
          { achievement: event.value },
        ),
      };

    case CHALLENGE_HIGHLIGHT_EVENT_TYPES.COMPLETED_ACTIVITY:
      return {
        displayDescription: formatMessage(
          { id: 'COMPLETED_ACTIVITY_HIGHLIGHT' },
          { strong },
        ),
        analyticsDescription: formatMessage({
          id: 'COMPLETED_ACTIVITY_HIGHLIGHT',
        }),
      };
    default:
      return { displayDescription: '', analyticsDescription: '' };
  }
};
