import { useQuery } from 'react-query';
import {
  GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
  getHealthGoalProgramsByCategory,
} from '@leagueplatform/health-journey-api';

export const useGetHealthProgramsCategory = (categoryId) =>
  useQuery([GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE, categoryId], () =>
    getHealthGoalProgramsByCategory(categoryId),
  );
