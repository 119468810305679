import React from 'react';
import { Image, genesisStyled } from '@leagueplatform/genesis-commons';
import { useIntl } from 'react-intl';
import Loading from 'assets/loading.png';

const SpinnerImage = genesisStyled(Image)`
  animation: rotate 1s ease-in-out infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = (props) => {
  const { formatMessage } = useIntl();
  return (
    <SpinnerImage
      src={Loading}
      size="two"
      alt={formatMessage({ id: 'LOADING' })}
      aria-live="polite"
      {...props}
    />
  );
};
