import * as React from 'react';
import { func, string } from 'prop-types';
import {
  Flex,
  HeadingThree,
  BodyOne,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { usePageViewAnalytics, PRODUCT_AREA } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { SCREEN_NAMES } from '../constants/health-journey-analytics.constants';
import { HealthActivityModal } from './health-activity-modal.component';

export const ProgramLimitModal = ({ onClose, title, description }) => {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: SCREEN_NAMES.PROGRAM_LIMIT_MODAL,
  });

  return (
    <HealthActivityModal onClose={onClose} aria-label={title}>
      <HeadingThree marginBottom="oneAndHalf">{title}</HeadingThree>
      <BodyOne color="onSurface.text.subdued">{description}</BodyOne>
      <Flex justifyContent="flex-end">
        <PrimaryButton onClick={() => onClose()} marginTop="oneAndHalf">
          {formatMessage({ id: 'OK' })}
        </PrimaryButton>
      </Flex>
    </HealthActivityModal>
  );
};

ProgramLimitModal.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
  description: string.isRequired,
};
