import React, { useEffect } from 'react';

import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Box, keyframes, styled } from '@leagueplatform/genesis-core';
import { Activity, PointsEarned } from 'constants/types';
import { REWARDS_EVENT_ANALYTICS } from 'constants/analytics.constants';
import { ToastProgressBanner } from '../toast-progress-banner/toast-progress-banner.view';
import { ToastPoints } from '../toast-points/toast-points.view';

interface RewardsToastContentProps {
  activities?: Activity[] | null;
  pointsEarned?: PointsEarned;
  showMoreActivities: React.Dispatch<React.SetStateAction<boolean>>;
  closeToast: Function;
}

const SlideUpAnimation = keyframes({
  '0%': {
    transform: 'translateY(500px)',
    opacity: 0,
  },
  '70%': {
    transform: 'translateY(-20px)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

const ToastContentWrapper = styled(Box, {
  animationName: `${SlideUpAnimation}`,
  animationDuration: '0.65s',
  animationTimingFunction: 'ease-in-out',
});

export const RewardsToastContent = ({
  pointsEarned,
  activities,
  showMoreActivities,

  closeToast,
}: RewardsToastContentProps) => {
  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_TOAST,
      action_rewarded: pointsEarned?.name,
      has_points: Boolean(pointsEarned),
      has_milestone_reward: Boolean(activities),
      num_of_milestone_rewards: activities?.length || 0,
      sub_detail: activities?.[0].name,
      milestone_reward_id: activities?.[0].id,
      has_see_more_button: activities ? activities.length > 1 : false,
    });
  }, [activities, pointsEarned]);

  return (
    <ToastContentWrapper
      flexDirection="column"
      css={{ animationFillMode: 'forwards' }}
    >
      {pointsEarned && (
        <ToastPoints
          onlyPoints={!activities}
          title={pointsEarned.name}
          points={pointsEarned.value}
          unit={pointsEarned.unit}
          activities={activities || []}
          onClose={closeToast}
        />
      )}
      {activities && (
        <ToastProgressBanner
          onClose={closeToast}
          onlyProgress={!pointsEarned}
          activities={activities}
          showMoreActivities={showMoreActivities}
        />
      )}
    </ToastContentWrapper>
  );
};
