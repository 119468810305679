{
  "dragItem": "Drag {itemText}",
  "dragSelectedItems": "Drag {count, plural, one {# selected item} other {# selected items}}",
  "dragDescriptionKeyboard": "Press Enter to start dragging.",
  "dragDescriptionKeyboardAlt": "Press Alt + Enter to start dragging.",
  "dragDescriptionTouch": "Double tap to start dragging.",
  "dragDescriptionVirtual": "Click to start dragging.",
  "dragDescriptionLongPress": "Long press to start dragging.",
  "dragSelectedKeyboard": "Press Enter to drag {count, plural, one {# selected item} other {# selected items}}.",
  "dragSelectedKeyboardAlt": "Press Alt + Enter to drag {count, plural, one {# selected item} other {# selected items}}.",
  "dragSelectedLongPress": "Long press to drag {count, plural, one {# selected item} other {# selected items}}.",
  "dragStartedKeyboard": "Started dragging. Press Tab to navigate to a drop target, then press Enter to drop, or press Escape to cancel.",
  "dragStartedTouch": "Started dragging. Navigate to a drop target, then double tap to drop.",
  "dragStartedVirtual": "Started dragging. Navigate to a drop target, then click or press Enter to drop.",
  "endDragKeyboard": "Dragging. Press Enter to cancel drag.",
  "endDragTouch": "Dragging. Double tap to cancel drag.",
  "endDragVirtual": "Dragging. Click to cancel drag.",
  "dropDescriptionKeyboard": "Press Enter to drop. Press Escape to cancel drag.",
  "dropDescriptionTouch": "Double tap to drop.",
  "dropDescriptionVirtual": "Click to drop.",
  "dropCanceled": "Drop canceled.",
  "dropComplete": "Drop complete.",
  "dropIndicator": "drop indicator",
  "dropOnRoot": "Drop on",
  "dropOnItem": "Drop on {itemText}",
  "insertBefore": "Insert before {itemText}",
  "insertBetween": "Insert between {beforeItemText} and {afterItemText}",
  "insertAfter": "Insert after {itemText}"
}
