import React from 'react';
import type {
  DropdownSection,
  UserDropdownItemProps,
} from './user-dropdown.types';
import { UserDropdownItem } from './user-dropdown.dropdown-item.component';

export const DropdownList: React.FC<{
  dropdownItemsConfig: DropdownSection[];
  onDropdownClick: (action: string) => void;
  isMobile: boolean;
  refsMenuItems: any[];
}> = ({ dropdownItemsConfig, onDropdownClick, isMobile, refsMenuItems }) => {
  const handleDropdownItemClick = (
    event: MouseEvent,
    dropdownItem: UserDropdownItemProps,
  ) => {
    onDropdownClick(dropdownItem.text);
    if (dropdownItem.onClick) {
      dropdownItem.onClick(event);
      event.preventDefault();
    }
  };

  return (
    <>
      {dropdownItemsConfig.map((section, sectionIndex) => {
        const items = section.map(
          (dropdownItem: UserDropdownItemProps, elementIndex) => (
            <UserDropdownItem
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...dropdownItem}
              isMobile={isMobile}
              key={dropdownItem.text}
              onClick={(event: MouseEvent) =>
                handleDropdownItemClick(event, dropdownItem)
              }
              ref={(node) => {
                if (node) {
                  refsMenuItems.push(node);
                }
              }}
              isLastElementInSection={
                sectionIndex < dropdownItemsConfig.length - 1 &&
                elementIndex === section.length - 1
              }
            />
          ),
        );
        return items;
      })}
    </>
  );
};
