import React, { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import type {
  MasonryEngineNode,
  MasonryEngineNodeAncestry,
} from './types/masonry-engine-node.types';

/**
 * A React Context for providing the current node's ID ancestry to any of its resolver's
 * descending components.
 */
export const MasonryEngineNodeAncestryContext =
  createContext<MasonryEngineNodeAncestry>([]);

type MasonryEngineNodeAncestryProviderProps = {
  // the ID of the current layer's node.
  nodeId: MasonryEngineNode['id'];
  children: ReactNode;
};

/**
 * A provider of {@link MasonryEngineNodeAncestryContext `MasonryEngineNodeAncestryContext`},
 * which is re-provided by each layer in the node tree, to recursively construct a
 * {@link MasonryEngineNodeAncestryContext `MasonryEngineNodeAncestryContext`} object
 * uniquely identifying all members in the hierarchy.
 */
export const MasonryEngineNodeAncestryProvider = ({
  children,
  nodeId,
}: MasonryEngineNodeAncestryProviderProps) => {
  const upperAncestry = useContext(MasonryEngineNodeAncestryContext);

  const newAncestry = useMemo(
    () => [nodeId, ...upperAncestry],
    [nodeId, upperAncestry],
  );

  return (
    <MasonryEngineNodeAncestryContext.Provider value={newAncestry}>
      {children}
    </MasonryEngineNodeAncestryContext.Provider>
  );
};
