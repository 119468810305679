import React from 'react';
import { Backdrop } from './backdrop.view';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColourObject } from '../utils/helpers';

const Title = styled.div`
  font-weight: 600;
  color: ${({ theme }) => getColourObject(theme).purple};
`;

const AlertWrapper = styled.div`
  background-color: ${({ theme }) => getColourObject(theme).white};
  min-width: 40rem;
  max-width: 60rem;
  padding: 2rem;
  line-height: 1.4;
  box-shadow: 0.3rem 0.3rem 0 ${({ theme }) => getColourObject(theme).orangeRed};

  ${Title} {
    margin-bottom: 1.5rem;
    color: ${({ theme }) => getColourObject(theme).orangeRed};
  }
`;

const Alert = ({ ...props }) => {
  const { title, children, onClose } = props;

  return (
    <Backdrop onClose={onClose}>
      <AlertWrapper>
        {title && <Title>{title}</Title>}
        <div>{children}</div>
      </AlertWrapper>
    </Backdrop>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  title: null,
  children: null,
  onClose: () => {},
};

export default Alert;
