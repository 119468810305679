import React from 'react';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { SkeletonBox } from '@leagueplatform/web-common-components';

export const LeaderboardSkeletonLoader = () => {
  // TODO: think of a better solution for this
  const leaderboardSkeleton = Array(5).fill(0);

  return (
    <Box>
      {leaderboardSkeleton.map(({ id }) => (
        <SkeletonBox
          width="100%"
          marginBottom="quarter"
          key={id}
          role="presentation"
        >
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="left"
            paddingTop="oneAndHalf"
            paddingBottom="oneAndHalf"
          >
            <SkeletonBox
              width="twoAndHalf"
              height="twoAndHalf"
              marginLeft="four"
              borderRadius="circle"
              // Waiting to hear back from the design team on a new colour / token
              backgroundColor="interactive.background.disabled"
            />
            <SkeletonBox
              marginLeft="one"
              width="40%"
              height="oneAndHalf"
              backgroundColor="interactive.background.disabled"
            />
          </Flex>
        </SkeletonBox>
      ))}
    </Box>
  );
};
