import capabilityClaims from 'assets/base-icons/capabilityClaims.svg';
import capabilityClaimsFilled from 'assets/base-icons/capabilityClaimsFilled.svg';
import capabilityGroupChallenges from 'assets/base-icons/capabilityGroupChallenges.svg';
import capabilityJourney from 'assets/base-icons/capabilityJourney.svg';
import capabilityJourneyFilled from 'assets/base-icons/capabilityJourneyFilled.svg';
import capabilityMarketplace from 'assets/base-icons/capabilityMarketplace.svg';
import capabilityWallet from 'assets/base-icons/capabilityWallet.svg';
import capabilityWalletFilled from 'assets/base-icons/capabilityWalletFilled.svg';
import illustrativeAlarmClock from 'assets/base-icons/illustrativeAlarmClock.svg';
import illustrativeAmbulance from 'assets/base-icons/illustrativeAmbulance.svg';
import illustrativeBuildings from 'assets/base-icons/illustrativeBuildings.svg';
import illustrativeCalendar from 'assets/base-icons/illustrativeCalendar.svg';
import illustrativeChatHistory from 'assets/base-icons/illustrativeChatHistory.svg';
import illustrativeCheckBadge from 'assets/base-icons/illustrativeCheckBadge.svg';
import illustrativeClock from 'assets/base-icons/illustrativeClock.svg';
import illustrativeCompose from 'assets/base-icons/illustrativeCompose.svg';
import illustrativeCreditCardPayment from 'assets/base-icons/illustrativeCreditCardPayment.svg';
import illustrativeDocument from 'assets/base-icons/illustrativeDocument.svg';
import illustrativeFaceID from 'assets/base-icons/illustrativeFaceID.svg';
import illustrativeFilter from 'assets/base-icons/illustrativeFilter.svg';
import illustrativeGraphTrendingUp from 'assets/base-icons/illustrativeGraphTrendingUp.svg';
import illustrativeHealthClipboard from 'assets/base-icons/illustrativeHealthClipboard.svg';
import illustrativeHeart from 'assets/base-icons/illustrativeHeart.svg';
import illustrativeHeartFilled from 'assets/base-icons/illustrativeHeartFilled.svg';
import illustrativeHeartRateUp from 'assets/base-icons/illustrativeHeartRateUp.svg';
import illustrativeHome from 'assets/base-icons/illustrativeHome.svg';
import illustrativeHome2 from 'assets/base-icons/illustrativeHome2.svg';
import illustrativeHomeFilled from 'assets/base-icons/illustrativeHomeFilled.svg';
import illustrativeInformationDesk from 'assets/base-icons/illustrativeInformationDesk.svg';
import illustrativeInsurance from 'assets/base-icons/illustrativeInsurance.svg';
import illustrativeLockPerson from 'assets/base-icons/illustrativeLockPerson.svg';
import illustrativeLockShield from 'assets/base-icons/illustrativeLockShield.svg';
import illustrativeLockTree from 'assets/base-icons/illustrativeLockTree.svg';
import illustrativeMail from 'assets/base-icons/illustrativeMail.svg';
import illustrativeMobileAlertUser from 'assets/base-icons/illustrativeMobileAlertUser.svg';
import illustrativeMoneyBill from 'assets/base-icons/illustrativeMoneyBill.svg';
import illustrativeNotifications from 'assets/base-icons/illustrativeNotifications.svg';
import illustrativeParking from 'assets/base-icons/illustrativeParking.svg';
import illustrativePaymentCard from 'assets/base-icons/illustrativePaymentCard.svg';
import illustrativePeople from 'assets/base-icons/illustrativePeople.svg';
import illustrativePerson from 'assets/base-icons/illustrativePerson.svg';
import illustrativePersonalInfo from 'assets/base-icons/illustrativePersonalInfo.svg';
import illustrativePersonFilled from 'assets/base-icons/illustrativePersonFilled.svg';
import illustrativePhone from 'assets/base-icons/illustrativePhone.svg';
import illustrativePill from 'assets/base-icons/illustrativePill.svg';
import illustrativePillFilled from 'assets/base-icons/illustrativePillFilled.svg';
import illustrativeQuestionBox from 'assets/base-icons/illustrativeQuestionBox.svg';
import illustrativeSecurityLock from 'assets/base-icons/illustrativeSecurityLock.svg';
import illustrativeSettings from 'assets/base-icons/illustrativeSettings.svg';
import illustrativeShop from 'assets/base-icons/illustrativeShop.svg';
import illustrativeShopFilled from 'assets/base-icons/illustrativeShopFilled.svg';
import illustrativeStarRibbon from 'assets/base-icons/illustrativeStarRibbon.svg';
import illustrativeStudentRecertification from 'assets/base-icons/illustrativeStudentRecertification.svg';
import illustrativeSupportQuestionChat from 'assets/base-icons/illustrativeSupportQuestionChat.svg';
import illustrativeTouchID from 'assets/base-icons/illustrativeTouchID.svg';
import illustrativeTransitBus from 'assets/base-icons/illustrativeTransitBus.svg';
import illustrativeUmbrella from 'assets/base-icons/illustrativeUmbrella.svg';
import illustrativeWebsite from 'assets/base-icons/illustrativeWebsite.svg';
import illustrativeWheelchairAccessibility from 'assets/base-icons/illustrativeWheelchairAccessibility.svg';
import interfaceArrowLeft from 'assets/base-icons/interfaceArrowLeft.svg';
import interfaceArrowRight from 'assets/base-icons/interfaceArrowRight.svg';
import interfaceCheckmark from 'assets/base-icons/interfaceCheckmark.svg';
import interfaceChevronDown from 'assets/base-icons/interfaceChevronDown.svg';
import interfaceChevronLeft from 'assets/base-icons/interfaceChevronLeft.svg';
import interfaceChevronRight from 'assets/base-icons/interfaceChevronRight.svg';
import interfaceChevronUp from 'assets/base-icons/interfaceChevronUp.svg';
import interfaceClose from 'assets/base-icons/interfaceClose.svg';
import interfaceCopy from 'assets/base-icons/interfaceCopy.svg';
import interfaceChange from 'assets/base-icons/interfaceChange.svg';
import interfaceDelete from 'assets/base-icons/interfaceDelete.svg';
import interfaceDownload from 'assets/base-icons/interfaceDownload.svg';
import interfaceEdit from 'assets/base-icons/interfaceEdit.svg';
import interfaceEndKey from 'assets/base-icons/interfaceEndKey.svg';
import interfaceExternalLink from 'assets/base-icons/interfaceExternalLink.svg';
import interfaceExternalShare from 'assets/base-icons/interfaceExternalShare.svg';
import interfaceHidden from 'assets/base-icons/interfaceHidden.svg';
import interfaceHomeKey from 'assets/base-icons/interfaceHomeKey.svg';
import interfaceMeatballs from 'assets/base-icons/interfaceMeatballs.svg';
import interfaceMenu from 'assets/base-icons/interfaceMenu.svg';
import interfaceMinus from 'assets/base-icons/interfaceMinus.svg';
import interfacePlus from 'assets/base-icons/interfacePlus.svg';
import interfaceQuestionCircle from 'assets/base-icons/interfaceQuestionCircle.svg';
import interfaceQuestionCircleFilled from 'assets/base-icons/interfaceQuestionCircleFilled.svg';
import interfaceRefresh from 'assets/base-icons/interfaceRefresh.svg';
import interfaceSearch from 'assets/base-icons/interfaceSearch.svg';
import interfaceSignOut from 'assets/base-icons/interfaceSignOut.svg';
import interfaceStar from 'assets/base-icons/interfaceStar.svg';
import interfaceURL from 'assets/base-icons/interfaceURL.svg';
import interfaceVisible from 'assets/base-icons/interfaceVisible.svg';
import statusAlert from 'assets/base-icons/statusAlert.svg';
import statusAlertFill from 'assets/base-icons/statusAlertFill.svg';
import statusErrorStatus from 'assets/base-icons/statusErrorStatus.svg';
import statusErrorStatusFilled from 'assets/base-icons/statusErrorStatusFilled.svg';
import statusInformationCircle from 'assets/base-icons/statusInformationCircle.svg';
import statusInformationCircle2 from 'assets/base-icons/statusInformationCircle2.svg';
import statusInformationCircle2Filled from 'assets/base-icons/statusInformationCircle2Filled.svg';
import statusInformationCircleFill from 'assets/base-icons/statusInformationCircleFill.svg';
import statusSuccessStatus from 'assets/base-icons/statusSuccessStatus.svg';
import statusSuccessStatusFilled from 'assets/base-icons/statusSuccessStatusFilled.svg';
import tinyAlert from 'assets/base-icons/tinyAlert.svg';
import tinyBackButton from 'assets/base-icons/tinyBackButton.svg';
import tinyCircleStar from 'assets/base-icons/tinyCircleStar.svg';
import tinyClear from 'assets/base-icons/tinyClear.svg';
import tinyClose from 'assets/base-icons/tinyClose.svg';
import tinyDisclosureIndicator from 'assets/base-icons/tinyDisclosureIndicator.svg';
import tinyExternalLink from 'assets/base-icons/tinyExternalLink.svg';
import tinyHelper from 'assets/base-icons/tinyHelper.svg';
import tinyHelperFilled from 'assets/base-icons/tinyHelperFilled.svg';
import tinyNewWindowInternal from 'assets/base-icons/tinyNewWindowInternal.svg';
import tinyStar from 'assets/base-icons/tinyStar.svg';
import tinyValidationCheck from 'assets/base-icons/tinyValidationCheck.svg';

const CAPABILITY_ICONS = {
  capabilityGroupChallenges,
  capabilityJourney,
  capabilityJourneyFilled,
  capabilityMarketplace,
  capabilityWallet,
  capabilityWalletFilled,
  capabilityClaims,
  capabilityClaimsFilled,
};

const ILLUSTRATIVE_ICONS = {
  illustrativeAlarmClock,
  illustrativeAmbulance,
  illustrativeBuildings,
  illustrativeCalendar,
  illustrativeChatHistory,
  illustrativeCheckBadge,
  illustrativeClock,
  illustrativeCompose,
  illustrativeCreditCardPayment,
  illustrativeDocument,
  illustrativeFaceID,
  illustrativeFilter,
  illustrativeGraphTrendingUp,
  illustrativeHealthClipboard,
  illustrativeHeart,
  illustrativeHeartFilled,
  illustrativeHeartRateUp,
  illustrativeHome,
  illustrativeHome2,
  illustrativeHomeFilled,
  illustrativeInformationDesk,
  illustrativeInsurance,
  illustrativeLockPerson,
  illustrativeLockShield,
  illustrativeLockTree,
  illustrativeMail,
  illustrativeMobileAlertUser,
  illustrativeMoneyBill,
  illustrativeNotifications,
  illustrativeParking,
  illustrativePaymentCard,
  illustrativePeople,
  illustrativePerson,
  illustrativePersonFilled,
  illustrativePersonalInfo,
  illustrativePhone,
  illustrativePill,
  illustrativePillFilled,
  illustrativeQuestionBox,
  illustrativeSecurityLock,
  illustrativeSettings,
  illustrativeShop,
  illustrativeShopFilled,
  illustrativeStarRibbon,
  illustrativeSupportQuestionChat,
  illustrativeTouchID,
  illustrativeTransitBus,
  illustrativeUmbrella,
  illustrativeWebsite,
  illustrativeWheelchairAccessibility,
  illustrativeStudentRecertification,
};

const INTERFACE_ICONS = {
  interfaceArrowLeft,
  interfaceArrowRight,
  interfaceCheckmark,
  interfaceChevronDown,
  interfaceChevronLeft,
  interfaceChevronRight,
  interfaceChevronUp,
  interfaceClose,
  interfaceCopy,
  interfaceChange,
  interfaceDelete,
  interfaceDownload,
  interfaceEdit,
  interfaceEndKey,
  interfaceExternalLink,
  interfaceExternalShare,
  interfaceHidden,
  interfaceHomeKey,
  interfaceMeatballs,
  interfaceMenu,
  interfaceMinus,
  interfacePlus,
  interfaceQuestionCircle,
  interfaceQuestionCircleFilled,
  interfaceRefresh,
  interfaceSearch,
  interfaceSignOut,
  interfaceStar,
  interfaceURL,
  interfaceVisible,
};

const STATUS_ICONS = {
  statusAlert,
  statusAlertFill,
  statusErrorStatus,
  statusErrorStatusFilled,
  statusInformationCircle,
  statusInformationCircle2,
  statusInformationCircle2Filled,
  statusInformationCircleFill,
  statusSuccessStatus,
  statusSuccessStatusFilled,
};

const TINY_ICONS = {
  tinyAlert,
  tinyBackButton,
  tinyCircleStar,
  tinyClear,
  tinyClose,
  tinyDisclosureIndicator,
  tinyExternalLink,
  tinyHelper,
  tinyHelperFilled,
  tinyNewWindowInternal,
  tinyStar,
  tinyValidationCheck,
};

// Original Genesis Core icon set
export const BASE_ICON_MAP = {
  ...CAPABILITY_ICONS,
  ...ILLUSTRATIVE_ICONS,
  ...INTERFACE_ICONS,
  ...STATUS_ICONS,
  ...TINY_ICONS,
};
