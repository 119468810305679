import { formatThemeForStitches } from './formatters/format-theme-for-stitches';
import type { GDSTheme, GDSThemeLoaderOptions } from './theme-types';
import type { GDSStitchesTheme } from './stitches.config';
import { createTheme } from './stitches.config';
import type { GDSIcons } from './icons/get-theme-icons';
import { getThemeIcons } from './icons/get-theme-icons';
import { getThemeStrings } from './global-strings';
import { getFallbackValues } from './utils/get-fallback-values';

export type GDSCoreTheme = GDSStitchesTheme & {
  icons?: GDSIcons;
  strings?: object;
  rawValues?: GDSCoreRawTheme;
};

const scalesWithNumberValues = [
  'radii',
  'sizes',
  'space',
  'borderWidths',
] as const;

type GDSCoreNumberTokensSubset = Pick<
  GDSCoreTheme,
  (typeof scalesWithNumberValues)[number]
>;

export type GDSCoreRawTheme = {
  [Scale in keyof GDSCoreNumberTokensSubset]: {
    [Token in keyof GDSCoreNumberTokensSubset[Scale]]: number;
  };
};

const extractRawValue = (tokenScale: any) =>
  Object.entries(tokenScale).reduce(
    (newTokenScale: any, [token, tokenValue]: [string, any]) => {
      const rawValue = parseInt(tokenValue.value, 10);
      return Number.isFinite(rawValue)
        ? {
            ...newTokenScale,
            [token]: rawValue,
          }
        : newTokenScale;
    },
    {},
  );

const extractRawPxValue = (tokenScale: any) =>
  Object.entries(tokenScale).reduce(
    (newTokenScale: any, [token, tokenValue]: [string, any]) => {
      const rawValue = parseInt(tokenValue, 10);
      return Number.isFinite(rawValue)
        ? {
            ...newTokenScale,
            [token]: rawValue,
          }
        : newTokenScale;
    },
    {},
  );

export const extractRawValues = (
  theme: GDSStitchesTheme,
  px: any,
): GDSCoreRawTheme => {
  const extractedValues = Object.entries(theme)
    .filter(([tokenScale]: [string, string]) =>
      (scalesWithNumberValues as Readonly<string[]>).includes(tokenScale),
    )

    .reduce<Partial<GDSCoreRawTheme>>(
      (rawTokenValues, [themeKey, tokenScale]: [string, any]) => ({
        ...rawTokenValues,
        [themeKey]: ['space', 'sizes'].includes(themeKey)
          ? extractRawPxValue(px[themeKey])
          : extractRawValue(tokenScale),
      }),
      {},
    );
  return extractedValues as GDSCoreRawTheme;
};

export const loadTheme = (
  theme: GDSTheme,
  {
    themeName,
    customIcons,
    customStrings,
    useRems = true,
  }: GDSThemeLoaderOptions = {
    useRems: true,
  },
): GDSCoreTheme => {
  const resolvedTheme = getFallbackValues(theme, themeName);

  const { rem, px, ...formatted } = formatThemeForStitches(resolvedTheme);
  const relativeOrStaticProperties = useRems ? rem : px;
  const formattedTheme = { ...relativeOrStaticProperties, ...formatted };
  const stitchesTheme: GDSStitchesTheme = themeName
    ? (createTheme(themeName, formattedTheme) as GDSStitchesTheme)
    : (createTheme(formattedTheme) as GDSStitchesTheme);

  const rawValues = extractRawValues(stitchesTheme, px);

  return {
    ...(stitchesTheme as {}),
    icons: getThemeIcons(customIcons),
    strings: getThemeStrings(customStrings),
    rawValues,
  } as GDSCoreTheme;
};
