import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { ONBOARDING_CONFIG_PATH } from 'constants/onboarding-config.constants';
import { OnboardingResponseData } from 'types/onboarding-requests.type';

export const getOnboardingConfig = async (): Promise<any> => {
  try {
    const response = await leagueFetch(ONBOARDING_CONFIG_PATH);
    if (response.status === 200) {
      const responseJson: OnboardingResponseData = await response.json();
      return deserialise(responseJson.data);
    }
    return false;
  } catch (err) {
    return false;
  }
};
