{
  "dragDescriptionKeyboard": "Premi Invio per iniziare a trascinare.",
  "dragDescriptionKeyboardAlt": "Premi Alt + Invio per iniziare a trascinare.",
  "dragDescriptionLongPress": "Premi a lungo per iniziare a trascinare.",
  "dragDescriptionTouch": "Tocca due volte per iniziare a trascinare.",
  "dragDescriptionVirtual": "Fai clic per iniziare a trascinare.",
  "dragItem": "Trascina {itemText}",
  "dragSelectedItems": "Trascina {count, plural, one {# altro elemento selezionato} other {# altri elementi selezionati}}",
  "dragSelectedKeyboard": "Premi Invio per trascinare {count, plural, one {# elemento selezionato} other {# elementi selezionati}}.",
  "dragSelectedKeyboardAlt": "Premi Alt + Invio per trascinare {count, plural, one {# elemento selezionato} other {# elementi selezionati}}.",
  "dragSelectedLongPress": "Premi a lungo per trascinare {count, plural, one {# elemento selezionato} other {# elementi selezionati}}.",
  "dragStartedKeyboard": "Hai iniziato a trascinare. Premi Tab per arrivare sull’area di destinazione, quindi premi Invio per rilasciare o Esc per annullare.",
  "dragStartedTouch": "Hai iniziato a trascinare. Arriva sull’area di destinazione, quindi tocca due volte per rilasciare.",
  "dragStartedVirtual": "Hai iniziato a trascinare. Arriva sull’area di destinazione, quindi fai clic o premi Invio per rilasciare.",
  "dropCanceled": "Rilascio annullato.",
  "dropComplete": "Rilascio completato.",
  "dropDescriptionKeyboard": "Premi Invio per rilasciare. Premi Esc per annullare.",
  "dropDescriptionTouch": "Tocca due volte per rilasciare.",
  "dropDescriptionVirtual": "Fai clic per rilasciare.",
  "dropIndicator": "indicatore di rilascio",
  "dropOnItem": "Rilascia su {itemText}",
  "dropOnRoot": "Rilascia su",
  "endDragKeyboard": "Trascinamento. Premi Invio per annullare.",
  "endDragTouch": "Trascinamento. Tocca due volte per annullare.",
  "endDragVirtual": "Trascinamento. Fai clic per annullare.",
  "insertAfter": "Inserisci dopo {itemText}",
  "insertBefore": "Inserisci prima di {itemText}",
  "insertBetween": "Inserisci tra {beforeItemText} e {afterItemText}"
}
