import { CARD_STATUS } from 'types/card-action.types';
import {
  WalletItemsSectionedQueryResponse,
  WalletItemNavigationBenefit,
} from 'types/wallet-item-navigation-section.types';

const setMockItemStatus = (
  item: WalletItemNavigationBenefit,
  itemStatus: CARD_STATUS,
) => ({ ...item, status: itemStatus });

export const mockHealthSpendingAccountItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '2e76a9572fc058160b018a543525d92b',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'hsa',
  name: {
    text: 'Health Spending Account',
    language: 'en-CA',
  },
  vendor_id: 'league',
  vendor_name: {
    text: 'League',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockVisionItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_vision',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'vision',
  name: {
    text: 'Vision',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockParamedicalServicesItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_paramedical',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'paramedical',
  name: {
    text: 'Paramedical Services',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockMedicalServicesSuppliesItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id:
    '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_medical-services-and-supplies',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'medical_services_and_supplies',
  name: {
    text: 'Medical Services \u0026 Supplies',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockMentalHealthItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id:
    '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_mental-health',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'mental_health',
  name: {
    text: 'Mental Health',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockDrugItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '34013d8bbb9c9b6b3cf3cc6c0ef47e26',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'drug',
  name: {
    text: 'Drug',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockAccreditationItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '5e3b54b58ff40e0408f642314f369bad',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'internal_program',
  name: {
    text: 'Accreditation',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockAdoptionAssistanceItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '6af4316baee4c67d6d291eaa749322fc',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'internal_program',
  name: {
    text: 'Adoption/Surrogacy Assistance',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockDentalItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.CREATED,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'd4d372b3368cb7413366250b63e9225a',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'dental',
  name: {
    text: 'Dental',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockLeagueItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  id: 'personal_store_credit_balance',
  name: {
    text: 'League Credit',
    language: 'en-CA',
  },
  vendor_id: 'league',
  vendor_name: {
    text: 'League',
    language: 'en-CA',
  },
  type: 'other',
  is_new_benefit: false,
  wallet_item_id: 'personal_store_credit_balance',
};

export const mockLifestyleSpendingAccountItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'b499f4fe176692d61d982030a3165a45',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'lsa',
  name: {
    text: 'Lifestyle Spending Account',
    language: 'en-CA',
  },
  vendor_id: 'league',
  vendor_name: {
    text: 'League',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockRRSPItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'fb33ef25793caad4de45666309cfaed2',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'rrsp',
  name: {
    text: 'RRSP',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockTFSAItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '9915b6c61a8c7506fd60855cc8abaa0d',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'tfsa',
  name: {
    text: 'TFSA',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockPensionItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'cc2e065bddc264c33988e63270be355e',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'pension',
  name: {
    text: 'Pension',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockEmployeeAssistanceProgram: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '73f414db320f0b7b67cf81b8b80cf763',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'eap',
  name: {
    text: 'Employee Assistance Program',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockParentalLeaveItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'be1e6814f63ba441eddbecdd3363398a',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'internal_program',
  name: {
    text: 'Parental Leave',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockSabbaticalLeaveItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '4744165df35e2e731b1f33dff48ca2f6',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'internal_program',
  name: {
    text: 'Sabbatical Leave',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockEmergencyTravelAssistanceItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_travel',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'travel',
  name: {
    text: 'Emergency Travel Assistance',
    language: 'en-CA',
  },
  vendor_id: 'sunlife',
  vendor_name: {
    text: 'Sun Life',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockOptionalLifeItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.ACTIVE,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'd5f562e42a69e181addf46c681cb6010',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'optional_life',
  name: {
    text: 'Optional Life',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockBusinessTravelItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'e562e18ec241c7e24fc53f69cbc5dcb6',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'travel',
  name: {
    text: 'Business Travel',
    language: 'en-CA',
  },
  vendor_id: 'sutton',
  vendor_name: {
    text: 'Sutton Special Risk',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockWorkLifeFlexibilityItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '29ed0d5e46fc75de6e9ae41698df0e83',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'internal_program',
  name: {
    text: 'Work Life Flexibility',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockMISPShortTermDisabilityItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '3143624ceaf01d079c7b1486161d75a4',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'std',
  name: {
    text: 'MISP Short-Term Disability',
    language: 'en-CA',
  },
  vendor_id: 'kpmg',
  vendor_name: {
    text: 'KPMG',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockLongTermDisabilityItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '25cfbcf96847392ade2affc9da812150',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'ltd',
  name: {
    text: 'Long-Term Disability',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockOptionalAccidentItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.READY,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'dff2056d03d8779aef54da93ec1d833a',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'optional_accident',
  name: {
    text: 'Optional Accident',
    language: 'en-CA',
  },
  vendor_id: 'sutton',
  vendor_name: {
    text: 'Sutton Special Risk',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockLifeItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.CREATED,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'db57a2c7cc25638624e749a2da8bef6c',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'life',
  name: {
    text: 'Life',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockSpouseOptionalLifeItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.CREATED,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: 'b3b5a9bfe35d8ab44dbd999c54270e0b',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'spouse_optional_life',
  name: {
    text: 'Spouse Optional Life',
    language: 'en-CA',
  },
  vendor_id: 'vendor',
  vendor_name: {
    text: 'Vendor',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const mockSpouseAccidentItem: WalletItemNavigationBenefit = {
  status: CARD_STATUS.CREATED,
  group_id: 'c6965889c1924abee5a8a7aec49de92c',
  benefit_id: '1b2882ee684a33f691c66a2063c2b741',
  id: '',
  wallet_item_id: '',
  benefit_product_type: 'spouse_accident',
  name: {
    text: 'Spouse Accident',
    language: 'en-CA',
  },
  vendor_id: 'sutton',
  vendor_name: {
    text: 'Sutton Special Risk',
    language: 'en-CA',
  },
  type: 'benefit',
  is_new_benefit: false,
  benefit_source: 'benefit-source',
};

export const walletItemNavigationSectionsMock: WalletItemsSectionedQueryResponse =
  {
    id: 'wallet_item_navigation_sections',
    items_sectioned: [
      {
        section_id: 'benefits',
        name: {
          text: 'Benefits',
          language: 'en-CA',
        },
        sections: [
          {
            section_id: 'health-and-wellness',
            name: {
              text: 'Mental Health \u0026 Wellbeing',
              language: 'en-CA',
            },
            wallet_items: [
              mockHealthSpendingAccountItem,
              mockVisionItem,
              mockParamedicalServicesItem,
              mockMedicalServicesSuppliesItem,
              mockMentalHealthItem,
              mockDrugItem,
              mockAccreditationItem,
              mockAdoptionAssistanceItem,
              setMockItemStatus(mockDentalItem, CARD_STATUS.CREATED),
              setMockItemStatus(
                mockMedicalServicesSuppliesItem,
                CARD_STATUS.INACTIVE_GRACE_PERIOD,
              ),
              setMockItemStatus(
                mockMentalHealthItem,
                CARD_STATUS.INACTIVE_GRACE_PERIOD,
              ),
            ],
          },
          {
            section_id: 'financial',
            name: {
              text: 'Financial',
              language: 'en-CA',
            },
            wallet_items: [
              mockLifestyleSpendingAccountItem,
              setMockItemStatus(mockRRSPItem, CARD_STATUS.READY),
              setMockItemStatus(mockTFSAItem, CARD_STATUS.READY),
              setMockItemStatus(mockPensionItem, CARD_STATUS.READY),
            ],
          },
          {
            section_id: 'work-and-life',
            name: {
              text: 'Work \u0026 Life',
              language: 'en-CA',
            },
            wallet_items: [
              setMockItemStatus(
                mockEmployeeAssistanceProgram,
                CARD_STATUS.READY,
              ),
              setMockItemStatus(mockParentalLeaveItem, CARD_STATUS.READY),
              setMockItemStatus(mockSabbaticalLeaveItem, CARD_STATUS.READY),
              setMockItemStatus(mockWorkLifeFlexibilityItem, CARD_STATUS.READY),
            ],
          },
          {
            section_id: 'insurance-and-protection',
            name: {
              text: 'Insurance \u0026 Protection',
              language: 'en-CA',
            },
            wallet_items: [
              mockEmergencyTravelAssistanceItem,
              mockOptionalLifeItem,
              setMockItemStatus(mockBusinessTravelItem, CARD_STATUS.READY),
              setMockItemStatus(
                mockMISPShortTermDisabilityItem,
                CARD_STATUS.READY,
              ),
              setMockItemStatus(mockLongTermDisabilityItem, CARD_STATUS.READY),
              setMockItemStatus(mockOptionalAccidentItem, CARD_STATUS.READY),
              setMockItemStatus(mockLifeItem, CARD_STATUS.CREATED),
              setMockItemStatus(
                mockSpouseOptionalLifeItem,
                CARD_STATUS.CREATED,
              ),
              setMockItemStatus(mockSpouseAccidentItem, CARD_STATUS.CREATED),
              setMockItemStatus(
                mockEmergencyTravelAssistanceItem,
                CARD_STATUS.INACTIVE_GRACE_PERIOD,
              ),
            ],
          },
        ],
      },
      {
        section_id: 'other',
        name: {
          text: 'Other',
          language: 'en-CA',
        },
        sections: [],
        wallet_items: [
          {
            status: CARD_STATUS.ACTIVE,
            id: 'personal_store_credit_balance',
            name: {
              text: 'League Credit',
              language: 'en-CA',
            },
            vendor_id: 'league',
            vendor_name: {
              text: 'League',
              language: 'en-CA',
            },
            type: 'other',
            is_new_benefit: false,
            wallet_item_id: 'personal_store_credit_balance',
          },
        ],
      },
    ],
  };

export const walletItemNavigationSectionsSingleStatusMock: WalletItemsSectionedQueryResponse =
  {
    id: 'wallet_item_navigation_sections',
    items_sectioned: [
      {
        section_id: 'benefits',
        name: {
          text: 'Benefits',
          language: 'en-CA',
        },
        wallet_items: undefined,
        sections: [
          {
            section_id: 'health-and-wellness',
            name: {
              text: 'Health \u0026 Well-Being',
              language: 'en-CA',
            },
            wallet_items: [mockHealthSpendingAccountItem, mockVisionItem],
          },
          {
            section_id: 'financial',
            name: {
              text: 'Financial',
              language: 'en-CA',
            },
            wallet_items: [mockLifestyleSpendingAccountItem],
          },
        ],
      },
    ],
  };
