import { ObjectOrArray } from 'styled-system';

export type Durations = 'none' | 'fast' | 'base' | 'slow';

export const durations: ObjectOrArray<number | string> = {
  none: 0,
  fast: '100ms',
  base: '200ms',
  slow: '300ms',
};
