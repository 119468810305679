import { Box, SkeletonRectangle } from '@leagueplatform/genesis-core';
import { LoaderType } from '@leagueplatform/masonry-api';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import React from 'react';

export type SmallSkeletonWSNode = MasonryEngineNode<LoaderType.SMALL>;

export const SmallSkeleton = () => (
  <Box aria-busy aria-live="polite">
    <SkeletonRectangle
      height={{
        '@initial': '160px',
        '@mobile': '180px',
      }}
    />
  </Box>
);
