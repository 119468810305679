import { ComponentStorageSources } from './health-activity-storage-sources.types';
import { ToolboxTransformedComponent } from './transformed-health-activity-component.types';

export const TOOLBOX_INTERACTION_HUB_DATA_TYPE =
  'toolbox-interaction-hub-request';

export interface PersistInputsMutationArgs {
  userActivityId?: string;
  userHealthCampaignId?: string;
  components?: ToolboxTransformedComponent[];
  skippedComponents?: (ComponentStorageSources | undefined)[];
  completedActivity?: boolean;
}
