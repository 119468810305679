import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../components/modal/index';
import styled from 'styled-components';

const ModalWrapper = styled(Modal.Container)`
  overflow: visible;
`;

export const ConfirmationModal = ({ children, headerText, footerContent }) => (
  <ModalWrapper>
    <Modal.Header>
      <Modal.HeaderTitle>{headerText}</Modal.HeaderTitle>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>{footerContent}</Modal.Footer>
  </ModalWrapper>
);

ConfirmationModal.propTypes = {
  children: PropTypes.node.isRequired,
  headerText: PropTypes.node.isRequired,
  footerContent: PropTypes.node.isRequired,
};
