/* eslint-disable react/jsx-props-no-spreading */
import type { ReactNode } from 'react';
import React, { isValidElement } from 'react';
import { styled } from '@leagueplatform/genesis-core';
import {
  SwitchIndicator,
  SwitchIndicatorProps,
} from './switch-indicator.component';

export type SwitchProps = SwitchIndicatorProps & {
  layout?: 'inline' | 'row';
  label: ReactNode;
};

const LabelText = styled('span', {
  typography: '$bodyOne',
  variants: {
    disabled: {
      true: { color: '$onSurfaceTextSubdued' },
      false: { color: '$onSurfaceTextPrimary' },
    },
    'aria-disabled': {
      true: { color: '$onSurfaceTextSubdued' },
      false: { color: '$onSurfaceTextPrimary' },
    },
  },
});

const SwitchLabel = styled('label', {
  gap: '$half',
  borderRadius: '$medium',
  padding: '$half',
  variants: {
    layout: {
      row: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:focus-within:has(button:focus)': {
          boxShadow:
            'inset 0 0 0 $borderWidths$innerFocus $colors$interactiveFocusInner',
          // Visible in Windows high-contrast themes
          outline: '$borderWidths$innerFocus  solid transparent',
          button: {
            '&[class*="cc-"]': {
              outline: 'none',
            },
          },
        },
      },
      inline: {
        display: 'inline-flex',
        '&:focus-within': {
          '&:focus-within:has(button:focus)': {
            boxShadow:
              'inset 0 0 0 $borderWidths$innerFocus $colors$interactiveFocusInner',
            // Visible in Windows high-contrast themes
            outline: '$borderWidths$innerFocus  solid transparent',
            button: {
              '&[class*="cc-"]': {
                outline: 'none',
              },
            },
          },
        },
      },
    },
    disabled: {
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    'aria-disabled': {
      true: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const Wrapper = styled('div');

export const Switch = ({
  label,
  layout = 'inline',
  css,
  id,
  checked,
  disabled,
  ariaDisabled,
  onClick,
  onChange,
  onBlur,
  onFocus,
  ...props
}: SwitchProps) => {
  const labelId = `${id}-label`;
  return layout === 'row' ? (
    <Wrapper className="cc-switch-row" css={{ ...css, width: '100%' }}>
      <SwitchLabel
        className="cc-switch-label"
        id={labelId}
        htmlFor={id}
        layout={layout}
        disabled={disabled}
        aria-disabled={ariaDisabled}
      >
        <LabelText
          className="cc-switch-label-text"
          disabled={disabled}
          aria-disabled={ariaDisabled}
        >
          {label}
        </LabelText>
        <SwitchIndicator
          id={id}
          checked={checked}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          ariaLabelledBy={labelId}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          css={{
            marginLeft: '$half',
          }}
          {...props}
        />
      </SwitchLabel>
    </Wrapper>
  ) : (
    <Wrapper className="cc-switch-inline" css={{ ...css }}>
      <SwitchLabel
        id={labelId}
        className="cc-switch-label"
        htmlFor={id}
        layout={layout}
        disabled={disabled}
        aria-disabled={ariaDisabled}
      >
        <SwitchIndicator
          id={id}
          checked={checked}
          disabled={disabled}
          aria-disabled={ariaDisabled}
          ariaLabelledBy={labelId}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        />
        {isValidElement(label) ? (
          label
        ) : (
          <LabelText
            className="cc-switch-label-text"
            disabled={disabled}
            aria-disabled={ariaDisabled}
          >
            {label}
          </LabelText>
        )}
      </SwitchLabel>
    </Wrapper>
  );
};
