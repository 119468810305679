import {
  ExtraSmallSkeleton,
  HeroBannerSkeleton,
  LargeSkeleton,
  SmallSkeleton,
} from '@leagueplatform/masonry-widgets';

export const SkeletonTypeToComponentMap = {
  loadingSmall: SmallSkeleton,
  loadingLarge: LargeSkeleton,
  loadingExtraSmall: ExtraSmallSkeleton,
  loadingHeroBanner: HeroBannerSkeleton,
};
