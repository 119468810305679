import React from 'react';
import { StackLayout, Accordion } from '@leagueplatform/web-common-components';
import { Caption } from '@leagueplatform/genesis-commons';
import { Box } from '@leagueplatform/genesis-core';
import { LiveBalanceItem } from '../live-balance-item/live-balance-item.component';
import { NetworkProviderService } from '../../types/plan-progress.types';

interface NetworkAccordionProps {
  providerService: NetworkProviderService[];
  networkName: string;
  lastUpdated: string;
  onToggle?: (
    accordionName: string,
    state: 'expand' | 'collapse',
    balanceType?: string,
  ) => void;
}

const formatAccordionStringName = (name: string = '') =>
  name.replaceAll(' ', '-').toLocaleLowerCase();

export const NetworkAccordion = ({
  providerService: _providerService,
  networkName,
  lastUpdated,
  onToggle,
}: NetworkAccordionProps) => {
  const providerService = React.useMemo(
    () =>
      _providerService.map((service) => ({
        accordionId: service.provider
          ? `accordion-${formatAccordionStringName(
              service.provider_id,
            )}-${formatAccordionStringName(service.provider)}`
          : undefined,
        ...service,
      })),
    [_providerService],
  );

  const defaultPanel = React.useMemo(() => {
    const panel = providerService.find((service) => service.accordionId);

    return panel?.accordionId ? [panel.accordionId] : [];
  }, [providerService]);
  const [activePanels, setActivePanels] = React.useState(defaultPanel);

  const toggleItem = React.useCallback(
    (currentPanels: string[]) => {
      if (currentPanels.length > activePanels.length) {
        // Accordion component always adds the newest clicked trigger to the end of currentPanels array
        const clickedPanel = providerService.find(
          (service) => service.accordionId === currentPanels[0],
        );
        const provider = clickedPanel?.provider!;
        const balanceType = clickedPanel?.balances[0]?.balance_type;

        if (onToggle) {
          onToggle(provider, 'expand', balanceType);
        }
      } else {
        // Get the panel that does not exist in currentPanels
        const [clickedActivePanel] = activePanels.filter(
          (x) => !currentPanels.includes(x),
        );
        // Accordion component always adds the newest clicked trigger to the end of currentPanels array
        const clickedPanel = providerService.find(
          (service) => service.accordionId === clickedActivePanel,
        );
        const provider = clickedPanel?.provider!;
        const balanceType = clickedPanel?.balances[0]?.balance_type;

        if (onToggle) {
          onToggle(provider, 'collapse', balanceType);
        }
      }

      setActivePanels(currentPanels);
    },
    [activePanels, onToggle, providerService],
  );

  return (
    <Box>
      {providerService.map(({ accordionId, balances, provider }) => {
        // Workaround while Total out of pocket maximum is returned in providerServices
        if (!provider) {
          return (
            <StackLayout space="$oneAndHalf">
              {balances.map(
                ({ title, limit, paid_amount, remaining_amount, category }) => (
                  <LiveBalanceItem
                    category={category}
                    key={`balance-item-${category}-${title}`}
                    balanceName={title}
                    networkName={networkName}
                    limit={limit}
                    paid_amount={paid_amount}
                    remaining_amount={remaining_amount}
                  />
                ),
              )}
              <Caption>{lastUpdated}</Caption>
            </StackLayout>
          );
        }

        return (
          <Accordion.Root
            type="multiple"
            value={activePanels}
            onValueChange={toggleItem}
          >
            <Accordion.Item
              value={accordionId || 'accordion-item'}
              key={accordionId}
            >
              <Accordion.Header level="4" size="md">
                <Accordion.Trigger>{provider}</Accordion.Trigger>
              </Accordion.Header>
              <StackLayout space="$oneAndHalf" as={Accordion.Content}>
                {balances.map(
                  ({
                    title,
                    limit,
                    paid_amount,
                    remaining_amount,
                    category,
                  }) => (
                    <LiveBalanceItem
                      category={category}
                      key={`accordion-balance-item-${category}-${title}`}
                      balanceName={title}
                      networkName={networkName}
                      limit={limit}
                      paid_amount={paid_amount}
                      remaining_amount={remaining_amount}
                      headingLevel="5"
                    />
                  ),
                )}
                <Caption>{lastUpdated}</Caption>
              </StackLayout>
            </Accordion.Item>
          </Accordion.Root>
        );
      })}
    </Box>
  );
};
