import { useQuery, UseQueryOptions } from 'react-query';
import {
  getClaims,
  GET_CLAIMS_MESSAGE_TYPE,
} from '@leagueplatform/claims-b2b-api';
import { Claim } from 'types/claims';

interface ClaimsQueryResponse {
  claims: Claim[];
  total_claims: number;
}

export function useClaimsQuery(
  userId?: string,
  options?: UseQueryOptions<ClaimsQueryResponse>,
) {
  return useQuery<ClaimsQueryResponse>(
    [GET_CLAIMS_MESSAGE_TYPE, userId],
    () => getClaims(userId),
    options,
  );
}
