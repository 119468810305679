import React, { forwardRef } from 'react';
import { Text, TextProps } from '../../Typography';

/**
 * @deprecated use `InputHint` from `genesis-core` instead
 */

export const Hint: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      color="onSurface.text.subdued"
      fontSize="body2"
      fontWeight="regular"
      ref={ref}
      {...props}
    />
  )
);

Hint.displayName = 'Hint';
