import * as React from 'react';
import type {
  DatePickerProps as AriaDatePickerProps,
  DateValue,
  ValidationResult,
} from 'react-aria-components';
import {
  DateInput,
  DatePicker as AriaDatePicker,
  DateSegment,
  Group,
  FieldError,
} from 'react-aria-components';
import {
  Popover,
  Box,
  TextInput,
  InputLabel,
  Icon,
  Button,
} from '@leagueplatform/genesis-core';
import { CalendarView } from 'components/calendar/calendar';

interface DatePickerProps<T extends DateValue> extends AriaDatePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  showErrorMessage?: boolean;
}

export function DatePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  ...props
}: DatePickerProps<T>) {
  return (
    <Box
      css={{
        '.GDS-base-input-wrapper': {
          display: 'none',
        },
        '.GDS-text-input-wrapper': {
          flex: 1,
        },
        button: {
          cursor: 'pointer',
        },
        '.react-aria-DateInput': {
          display: 'flex',
          '.react-aria-DateSegment:nth-child(6)': {
            marginRight: '$half',
          },
          '.react-aria-DateSegment:nth-child(11)': {
            marginRight: '$quarter',
          },
        },
        '.GDS-affix-leading-content': {
          width: '250px',
        },
        '@layer popover': {
          left: '-143px',
          button: {
            cursor: 'pointer',
          },
          '.react-aria-Popover': {
            maxWidth: 'unset',
            padding: '1.25rem',
          },
        },
      }}
    >
      {label && (
        <InputLabel htmlFor="date-picker" required>
          {label}
        </InputLabel>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AriaDatePicker {...props}>
        <TextInput
          id="date-picker"
          css={{
            '.react-aria-Group': {
              width: '100%',
            },
          }}
          leadingContent={
            <Group>
              {/* Todo: update the types */}
              <DateInput>
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
            </Group>
          }
          addOnEnd={
            <Popover.Root>
              <Popover.Trigger>
                <Button
                  aria-label="Open calendar pop out"
                  priority="primary"
                  size="medium"
                  css={{
                    borderRadius: '0 $radii$medium $radii$medium 0',
                    flexShrink: 0,
                    '&:disabled': {
                      borderColor: '$interactiveBorderDefault',
                      borderLeft: 'none',
                    },
                  }}
                  disabled={props.isReadOnly}
                >
                  <Icon icon="illustrativeCalendar" />
                </Button>
              </Popover.Trigger>
              <Popover.Content
                headingBar={false}
                showCloseButton={false}
                align="end"
                css={{
                  position: 'relative',
                  zIndex: '999999999',
                }}
              >
                <CalendarView
                  css={{
                    '.calendar': {
                      width: '300px',
                    },
                    '.calendar-grid-cell': {
                      width: '22px',
                      height: '22px',
                    },
                    '.react-aria-CalendarHeaderCell': {
                      padding: '$half',
                    },
                    '.calendar .calendar-grid-cell': {
                      padding: '$one',
                      margin: '$half auto',
                      border: '1px solid transparent',
                      borderRadius: '100%',

                      '&:hover': {
                        backgroundColor: '$interactiveActionHovered',
                        color: '$onSurfaceTextReversed',
                      },
                      '&:focus-visible': {
                        border: '1px solid $interactiveFocusInner',
                      },
                      '&:active': {
                        backgroundColor: '$interactiveActionPressed',
                        color: '$onSurfaceTextReversed',
                      },
                      '&.calendar-grid-cell[data-selected]': {
                        backgroundColor: '$interactiveActionPrimary',
                        color: '$onSurfaceTextReversed',
                        outline: '1px solid $interactiveActionPrimary',
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Popover.Content>
            </Popover.Root>
          }
          readOnly={props.isReadOnly}
        />
        <FieldError>{errorMessage}</FieldError>
      </AriaDatePicker>
    </Box>
  );
}
