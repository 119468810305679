import * as React from 'react';
import PropTypes from 'prop-types';
import {
  QuietButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { Modal, Icon, ICONS, visuallyHidden } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

const CloseButton = genesisStyled(QuietButton)(
  css({
    position: 'absolute',
    right: 0,
    margin: 'one',
    padding: 'half',
    zIndex: 'modal',
  }),
);

const CloseButtonScreenReaderText = genesisStyled.span(css(visuallyHidden));

const StyledModalContainer = genesisStyled(Modal.Container)(
  ({ isNarrow, styleOverrides }) => [
    css({
      backgroundColor: 'surface.background.primary',
      position: 'relative',
      maxWidth: isNarrow ? 500 : 620,
      borderRadius: 'medium',
      overflow: 'scroll',
    }),
    styleOverrides,
  ],
);

const StyledModalBody = genesisStyled(Modal.Body)(
  ({ isNarrow, styleOverrides }) => [
    css({
      paddingY: isNarrow ? 'oneAndHalf' : 'twoAndHalf',
      paddingX: isNarrow ? 'two' : 'twoAndHalf',
    }),
    styleOverrides,
  ],
);

export const HealthActivityModal = ({
  onClose,
  children,
  isNarrow = false,
  styleOverrides = {},
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Wrapper onClose={onClose}>
      <StyledModalContainer
        isNarrow={isNarrow}
        styleOverrides={styleOverrides.container}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <CloseButton onClick={onClose}>
          <CloseButtonScreenReaderText>
            {formatMessage({ id: 'CLOSE' })}
          </CloseButtonScreenReaderText>
          <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
        </CloseButton>
        <StyledModalBody
          styleOverrides={styleOverrides.body}
          isNarrow={isNarrow}
        >
          {children}
        </StyledModalBody>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
};

HealthActivityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isNarrow: PropTypes.bool,
  styleOverrides: PropTypes.shape({
    container: PropTypes.func,
    body: PropTypes.func,
  }),
};

HealthActivityModal.defaultProps = {
  isNarrow: false,
  styleOverrides: {
    container: () => undefined,
    body: () => undefined,
  },
};
