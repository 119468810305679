import { useMutation } from 'react-query';
import { exitAssessment } from '@leagueplatform/assessment-api';

export const useExitAssessment = (
  moduleId: string,
  redirectMemberFromExitScreen: () => void,
) =>
  useMutation(() => exitAssessment(moduleId), {
    onSettled: redirectMemberFromExitScreen,
  });
