import { useQuery } from 'react-query';
import { ErrorContext } from '@leagueplatform/observability';
import { getObservabilityContext } from 'utils/get-observability-context';
import { postWidgetDismiss } from 'utils/post-widget-dismiss';
import { normalizeParams } from 'utils/normalize-paramters';
import { WidgetLink } from '../types/container-data';

export const usePostDismissWidget = (
  dismissed: boolean,
  link?: WidgetLink,
  errorContext: ErrorContext = {}, // Placed error context for capability teams to extend errorContext
) => {
  const { href = '', params } = link || {};
  // @TODO - remove ContainerData and rename ContainerDataV2 to ContainerData when deprecating v0 and 1
  useQuery<any, Error>(
    [href],
    () =>
      postWidgetDismiss(
        href,
        normalizeParams({ params }),
        getObservabilityContext({
          ...errorContext,
          errorName: 'Masonry - post dismiss widget api error',
          tags: {
            ...(errorContext?.tags || {}),
          },
        }),
      ),
    { enabled: dismissed },
  );
};
