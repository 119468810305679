import * as React from 'react';
import isToday from 'date-fns/isToday';
import { Line } from '@visx/shape';
import { useIntl } from '@leagueplatform/locales';
import { useChart } from '../xychart/providers/chart-data-provider';
import { useTooltip } from '../xychart/providers/tooltip-provider';
import getScaleBandwidth from '../xychart/utils/get-scale-bandwidth';
import { Annotation } from '../xychart/primitives/Annotation';

// A utility function to retrieve a value for an axis and return the scaled version.
// @ts-ignore
const compose = (scale, accessor) => (d) => scale(accessor(d));

type TooltipMarkerProps<Datum> = {
  data: Datum[];
  accessors: {
    xAccessor: (d: Datum) => string;
    yAccessor: (d: Datum) => [min: number, max: number] | number | null;
  };
};

export function TooltipMarker<Datum>({
  data,
  accessors,
}: TooltipMarkerProps<Datum>) {
  const { formatMessage } = useIntl();
  const { xScale, yMax } = useChart();
  const tooltip = useTooltip();

  const xPoint = compose(xScale, accessors.xAccessor);

  const barX = xPoint(data[0]);
  const barWidth = getScaleBandwidth(xScale);
  const barCenterPoint = barX + barWidth / 2;

  // TODO: This should be called `interacting` and moved into the `useChart` context.
  const [hasFocus, setFocus] = React.useState(false);

  const showTooltip = () => {
    tooltip.showTooltip({
      tooltipData: data,
      tooltipLeft: barCenterPoint,
      tooltipTop: yMax,
    });
    setFocus(true);
  };

  const hideTooltip = () => {
    tooltip.hideTooltip();
    setFocus(false);
  };

  const shouldShowTodayAnnotation =
    !hasFocus &&
    // In the future if this isn't a date, the logic below returns `false`.  With the refactor comment below, if this
    // annotation lives somewhere up the tree, we might have better type context and the cast might not be required.
    isToday(new Date(accessors.xAccessor(data[0])));

  return (
    <>
      <Line
        tabIndex={0}
        x1={barCenterPoint}
        y1={0}
        x2={barCenterPoint}
        y2={yMax}
        stroke="transparent"
        // Makes the mouse hover area larger.
        strokeWidth={30}
        onFocus={showTooltip}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onBlur={hideTooltip}
        style={{
          outline: 'none',
        }}
      />
      {/* TODO: We might want to refactor the annotation outside of the circle component so they  */}
      {/* aren't tightly coupled and reusable in the future */}
      {shouldShowTodayAnnotation && (
        <Annotation
          x={barCenterPoint}
          y={yMax}
          label={formatMessage({ id: 'TODAY' }).toLocaleUpperCase()}
        />
      )}
    </>
  );
}
