import * as React from 'react';
import {
  FormField,
  InputHint,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { DatePicker } from '@leagueplatform/web-common-components';
import { parseAbsoluteToLocal, ZonedDateTime } from '@internationalized/date';
import { Controller, Field, RegisterOptions } from 'react-hook-form';
import { useIntl } from '@leagueplatform/locales';

type DateTimeInputProps = {
  required?: boolean;
  label?: string;
  id: string;
  validationMessage: string;
  defaultValue?: string;
  isReadOnly?: boolean;
  triggerValidation?: () => void;
  validations?: {
    [key: string]: (value: string, values: Field) => string | undefined;
  };
  hint?: string;
};

export function DateTimeInput({
  id,
  defaultValue,
  label,
  validationMessage,
  isReadOnly,
  required = false,
  triggerValidation,
  validations,
  hint,
}: DateTimeInputProps) {
  const { formatMessage } = useIntl();
  const inputOptions: RegisterOptions = {
    required: {
      value: required,
      message: validationMessage,
    },
    validate: {
      noFutureDate: (date) => {
        const inputDate = parseAbsoluteToLocal(date);
        const currentDate = parseAbsoluteToLocal(new Date().toISOString());
        const check = inputDate.compare(currentDate);

        if (check > 0) {
          return formatMessage({
            id: 'NO_FUTURE_DATES',
          });
        }
        return undefined;
      },
      ...validations,
    },
  };

  return (
    <Controller
      name={id}
      rules={inputOptions}
      defaultValue={defaultValue || new Date().toISOString()}
      render={({ field, fieldState }) => (
        <StackLayout horizontalAlignment="stretch">
          <FormField
            name={id}
            id={id}
            label={label}
            statusMessage={fieldState.error?.message}
            inputStatus={fieldState.error ? 'error' : undefined}
          >
            <DatePicker
              isReadOnly={isReadOnly}
              granularity="minute"
              value={field.value && parseAbsoluteToLocal(field.value)}
              hideTimeZone
              onChange={(value) => {
                field.onChange(
                  new Date((value as ZonedDateTime).toDate()).toISOString(),
                );
                if (triggerValidation) triggerValidation();
              }}
              maxValue={parseAbsoluteToLocal(new Date().toISOString())}
              errorMessage="" // we are using the FormField error handling and hiding the default DatePicker message.
            />
            {hint && (
              <InputHint
                css={{
                  marginTop: '$half',
                }}
              >
                {hint}
              </InputHint>
            )}
          </FormField>
        </StackLayout>
      )}
    />
  );
}
