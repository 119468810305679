import * as React from 'react';

export function useLastTruthyValue<T>(value: T, initialValue: T) {
  const lastTruthyRef = React.useRef(initialValue);

  React.useEffect(() => {
    if (value) {
      lastTruthyRef.current = value;
    }
  }, [value]);

  return lastTruthyRef;
}
