import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { FaqSection } from '../types/faq.types';
import { useFaqLinksQuery } from './queries/use-faq-links-query.hook';

export interface UseFaqLinksReturn {
  onLinkClick: (sectionHeading: string, label: string) => void;
  sections: FaqSection[];
}

export const useFaqLinks = ({
  benefitProductType,
}: {
  benefitProductType: string;
}): UseFaqLinksReturn => {
  const onLinkClick = (sectionHeading: string, label: string) => {
    sendAnalyticsEvent({
      category: 'Wallet',
      action: `FAQ: ${sectionHeading}`,
      label,
      params: {},
    });
  };

  const faqLinksQuery = useFaqLinksQuery(benefitProductType);

  return { onLinkClick, sections: faqLinksQuery.data ?? [] };
};
