import React, { FC, PropsWithChildren } from 'react';
import { Icon } from 'components/icon/icon';
import type { GDSCustomizableComponent, GDSInputStatus } from '../../../types';
import type { GDSColor } from '../../../theme';
import { styled } from '../../../theme';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

const StatusMessageWrapper = styled('div', {
  marginBlockStart: '$quarter',
  display: 'flex',
  alignItems: 'center',
});

const StatusMessageBase = styled('p', {
  margin: 0,
  typography: '$caption',
  variants: {
    status: {
      success: { color: '$onSurfaceTextSuccess' },
      warning: { color: '$onSurfaceTextWarning' },
      error: { color: '$onSurfaceTextCritical' },
    },
  },
});

const icons = {
  success: 'tinyValidationCheck',
  warning: 'tinyAlert',
  error: 'tinyClear',
};

const tints = {
  success: '$inputBorderSuccess',
  warning: '$inputBorderWarning',
  error: '$inputBorderCritical',
};
export interface GDSInputStatusMessageProps extends GDSCustomizableComponent {
  id?: string;
  inputStatus?: GDSInputStatus;
  statusIconLabel?: string;
  hideIcon?: boolean;
}

export const InputStatusMessage: FC<
  PropsWithChildren<GDSInputStatusMessageProps>
> = ({
  children,
  className,
  css,
  hideIcon = false,
  inputStatus,
  statusIconLabel,
  ...props
}) => (
  <GenesisCoreInspector displayName="InputStatusMessage">
    <StatusMessageWrapper
      className={['GDS-input-status-message-wrapper', className].join(' ')}
      css={css}
      {...props}
    >
      {!hideIcon && inputStatus && (
        <Icon
          icon={icons[inputStatus]}
          tint={tints[inputStatus] as GDSColor}
          size={10}
          label={statusIconLabel}
          css={{ marginInlineEnd: '$quarter' }}
        />
      )}
      <StatusMessageBase
        className={['GDS-input-status-message', className].join(' ')}
        status={inputStatus}
      >
        {children}
      </StatusMessageBase>
    </StatusMessageWrapper>
  </GenesisCoreInspector>
);

InputStatusMessage.displayName = 'InputStatusMessage';
