import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { getProductIcon, ProductIconType } from 'utils/get-product-icon.util';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';

interface CardDetailSummaryProps {
  title: string;
  subtitle?: string;
  planName?: string | null;
  productType: ProductIconType | string;
  startDate?: string | null;
  endDate?: string | null;
  icon?: string;
}

export function CardDetailSummary({
  title,
  subtitle,
  planName = null,
  productType,
  startDate = null,
  endDate = null,
  icon,
}: CardDetailSummaryProps) {
  const { userId, benefitId } = useWalletParams();
  const { formatMessage } = useIntl();
  const { data: benefitCard } = useWalletDetailQuery(userId, benefitId);

  const hasStartOrEndDate = Boolean(startDate || endDate);
  const shouldDisplayDates =
    hasStartOrEndDate &&
    benefitCard?.content_configuration?.show_policy_dates !== false;

  const policyDates = [startDate, endDate].filter((date) => !!date).join(' - ');

  return (
    <Box
      css={{
        width: '100%',
        '@mobile': {
          paddingTop: '$three',
        },
      }}
    >
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        spacing={{
          '@initial': '$two',
          '@mobile': '$threeQuarters',
          '@mobileLandscape': '$threeQuarters',
          '@tablet': '$threeQuarters',
          '@desktop': '$two',
        }}
        orientation="horizontal"
      >
        <StackItem
          css={{
            width: '$five',
            height: '$five',
            backgroundColor: '$surfaceBackgroundPrimary',
            borderRadius: '$circle',
            '@mobile': {
              order: '2',
            },
          }}
          horizontalAlignment="center"
          verticalAlignment="center"
        >
          <Image
            width="48px"
            height="48px"
            alignSelf="center"
            src={icon || getProductIcon(productType as ProductIconType)}
            alt=""
          />
        </StackItem>
        <StackLayout spacing="$quarter" horizontalAlignment="start">
          <StackLayout
            orientation={{
              '@initial': 'horizontal',
              '@mobile': 'vertical',
              '@mobileLandscape': 'vertical',
              '@tablet': 'vertical',
              '@desktop': 'horizontal',
            }}
          >
            {planName && (
              <UtilityText
                size="eyebrow"
                emphasis="emphasized"
                css={{ marginBottom: '$half' }}
              >
                {planName}
              </UtilityText>
            )}
            <HeadingText level="1" size="xxl">
              {title}
            </HeadingText>
          </StackLayout>
          {subtitle && (
            <HeadingText as="p" level="display" size="lg">
              {subtitle}
            </HeadingText>
          )}
          {shouldDisplayDates && (
            <StackLayout
              spacing="$threeQuarters"
              orientation="horizontal"
              horizontalAlignment="start"
              css={{
                marginTop: '$quarter',
              }}
            >
              <Image
                width="24px"
                height="24px"
                src={handleStaticAsset(WALLET_ASSET_KEYS.CALENDAR) as string}
                alt=""
              />
              <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
                {`${formatMessage({ id: 'POLICY_ACTIVE' })}: ${policyDates}`}
              </ParagraphText>
            </StackLayout>
          )}
        </StackLayout>
      </StackLayout>
    </Box>
  );
}
