import React, { useEffect } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_AND_TIME_FORMAT } from '@leagueplatform/web-common';
import { Tooltip } from '@leagueplatform/genesis-core';

import {
  ColorProps,
  Box,
  BodyOne,
  VisuallyHidden,
  genesisStyled,
  Flex,
  css,
} from '@leagueplatform/genesis-commons';
import { Message, Participant, Document } from '@leagueplatform/messaging-api';
import { MessageDocument } from './message-document.component';
import { MessageTextWithInlineLinks } from './message-text-with-inline-links.component';

const StyledContainer = genesisStyled(Flex)(({ displayFocusBorder }) =>
  css({
    borderColor: 'transparent',
    borderRadius: 'small',
    borderStyle: 'solid',
    borderWidth: 'thin',
    paddingY: 'quarter',
    '&:hover': {
      backgroundColor: 'highlight.background.subdued',
    },
    '&:focus-within': {
      backgroundColor: 'highlight.background.subdued',
      borderColor: displayFocusBorder
        ? 'interactive.focus.outer'
        : 'transparent',
    },
  }),
);

const StyledTextWrapper = genesisStyled(BodyOne)`
  word-break: break-word;
`;

export interface MessageBubbleProps {
  backgroundColor: ColorProps['backgroundColor'];
  color: ColorProps['color'];
  fromCurrentUser?: boolean;
  hasFocus: boolean;
  messageDetails: Message;
  sender?: Participant;
  gridRef: React.RefObject<HTMLElement>;
}

export const MessageBubble = ({
  backgroundColor,
  color,
  fromCurrentUser,
  hasFocus,
  messageDetails,
  sender,
  gridRef,
}: MessageBubbleProps) => {
  const { formatDate, formatMessage } = useIntl();
  const [displayFocusBorder, setDisplayFocusBorder] = React.useState(true);
  const cellRef = React.useRef<HTMLElement>(null);

  const { text, sentAt, documents } = messageDetails;
  useEffect(() => {
    const isFocusWithinGrid = gridRef.current?.contains(document.activeElement);
    if (hasFocus && isFocusWithinGrid) {
      cellRef.current?.focus();
    }
  }, [hasFocus, gridRef]);
  const messageDocuments = documents?.data;
  const user = fromCurrentUser
    ? formatMessage({ id: 'YOU' })
    : sender?.firstName;
  const formattedDate = formatDate(sentAt, SHORT_DATE_AND_TIME_FORMAT);
  // if cell is focused, include border on styled container
  // otherwise, only focus on the element inside
  return (
    <StyledContainer width="100%" displayFocusBorder={displayFocusBorder}>
      <Tooltip align="end" side="bottom" content={formattedDate}>
        <Flex
          role="gridcell"
          justifyContent={fromCurrentUser ? 'flex-end' : 'flex-start'}
          width="100%"
        >
          <Box
            backgroundColor={backgroundColor}
            borderRadius="extraLarge"
            padding="threeQuarters"
            width="fit-content"
            maxWidth={{ _: '75%', phone: '50%' }}
            tabIndex={hasFocus ? 0 : -1}
            ref={cellRef}
            // hide default styles as the container has focus within to indicate focus of the whole row
            focusStyle={{ outline: 'none', boxShadow: 'none' }}
          >
            <StyledTextWrapper as="span" color={color}>
              <VisuallyHidden>
                {formatMessage({ id: 'USER_COLON' }, { user })}
              </VisuallyHidden>
              {text && (
                <MessageTextWithInlineLinks
                  text={text}
                  fromCurrentUser={fromCurrentUser}
                />
              )}
              {messageDocuments?.map((document: Document) => (
                <React.Fragment key={document.downloadUrl}>
                  <VisuallyHidden>
                    {formatMessage({ id: 'WITH_DOCUMENT' })}
                  </VisuallyHidden>
                  <Box marginTop="quarter">
                    <MessageDocument
                      document={document}
                      hasFocus={hasFocus}
                      color={color}
                      fromCurrentUser={fromCurrentUser}
                      onFocus={() => setDisplayFocusBorder(false)}
                      onBlur={() => setDisplayFocusBorder(true)}
                    />
                  </Box>
                </React.Fragment>
              ))}
              {/* hidden formatted date read by screen readers as they do not read the date from the tooltip */}
              <VisuallyHidden>{formattedDate}</VisuallyHidden>
            </StyledTextWrapper>
          </Box>
        </Flex>
      </Tooltip>
    </StyledContainer>
  );
};
