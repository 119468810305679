import { useCallback, useMemo } from 'react';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { NETWORK_ANALYTICS_ID } from 'pages/wallet-detail/types/network-analytics-ids';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { NetworkPlan, NETWORK_PLAN_ID } from '../types/plan-progress.types';
import { getNetworkWithBalanceType } from '../utils/get-network-with-balance-type.util';
import { useGetUserBenefitLiveBalancesQueryV1 } from './queries/use-get-user-benefit-live-balances-query-v1.hook';

export const usePlanProgressV1 = (
  userId: string | undefined,
  benefitId: string,
) => {
  const { data: benefitName = '' } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: (data) => data.full_name,
    },
  );

  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();

  const [member] = useSelectedMember();

  const { data, isError, isLoading, refetch } =
    useGetUserBenefitLiveBalancesQueryV1(
      userId,
      benefitId,
      benefitName,
      member?.id,
    );
  const {
    in_network: inNetwork = [],
    out_of_network: outOfNetwork = [],
    accumulation_period: accumulationPeriod,
    last_updated: lastUpdated,
    carrier_name: carrierName,
    definitions = [],
  } = data || {};

  // InNetwork balance arrays
  const inNetworkProviders = useMemo(
    () => getNetworkWithBalanceType(inNetwork ?? [], definitions),
    [inNetwork, definitions],
  );

  const outOfNetworkProviders = useMemo(
    () => getNetworkWithBalanceType(outOfNetwork ?? [], definitions),
    [outOfNetwork, definitions],
  );

  const plans: NetworkPlan[] = [
    {
      id: NETWORK_PLAN_ID.IN_NETWORK,
      analyticsId: NETWORK_ANALYTICS_ID.IN_NETWORK,
      network: inNetworkProviders,
    },
    {
      id: NETWORK_PLAN_ID.OUT_OF_NETWORK,
      analyticsId: NETWORK_ANALYTICS_ID.OUT_OF_NETWORK,
      network: outOfNetworkProviders,
    },
  ];

  const onTabClickEvent = useCallback(
    (tabName: NETWORK_ANALYTICS_ID) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
        detail: tabName,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  const onAccordionToggleEvent = useCallback(
    (
      accordionName: string,
      state: 'expand' | 'collapse',
      balanceType?: string,
    ) => {
      sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        detail: `toggle ${accordionName} accordion`,
        sub_detail: state,
        text_detail: balanceType,
      });
    },
    [sendBenefitAnalyticsEvent],
  );

  return {
    plans,
    accumulationPeriod,
    lastUpdated,
    carrierName,
    onTabClickEvent,
    onAccordionToggleEvent,
    isLoading,
    isError,
    refetch,
  };
};
