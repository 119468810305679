import React from 'react';
import { useIntl } from 'react-intl';
import { Box, VisuallyHidden } from '@leagueplatform/genesis-core';
import { ContainerWidgetItemV2 } from '@leagueplatform/masonry-api';
import { SkeletonTypeToComponentMap } from 'utils/skeleton-type-to-component-map';

export const MasonrySkeleton = ({
  loading,
}: {
  loading: ContainerWidgetItemV2['loading'];
}) => {
  const { formatMessage } = useIntl();
  if (loading?.type) {
    const LoadingComponent = SkeletonTypeToComponentMap[loading?.type];
    if (LoadingComponent) {
      return (
        <Box aria-busy aria-live="polite">
          <VisuallyHidden>{formatMessage({ id: 'LOADING' })}</VisuallyHidden>
          <LoadingComponent />
        </Box>
      );
    }
  }
  return null;
};
