import { ObjectOrArray } from 'styled-system';
import * as CSS from 'csstype';

export type ZIndices =
  | 'default'
  | 'sticky'
  | 'dropdown'
  | 'tooltip'
  | 'modal'
  | 'toast';

export const zIndices: ObjectOrArray<CSS.ZIndexProperty> = {
  default: 1,
  sticky: 100,
  dropdown: 4000,
  tooltip: 5000,
  modal: 8000,
  toast: 9000,
};
