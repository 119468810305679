import { QuestionnaireItemAnswerOption } from 'fhir/r4';

export const getDisplaySlug = ({
  id,
  option,
}: {
  id: string;
  option: QuestionnaireItemAnswerOption;
}): string => {
  const { valueCoding } = option;
  const slug = valueCoding?.display
    ? `-${valueCoding?.display.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`
    : '';
  return `${id}-${valueCoding?.code}${slug}`;
};
