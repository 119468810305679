import * as React from 'react';
import { PaginationPage, PaginationProps } from '../types/pagination.types';

interface UsePagination
  extends Pick<PaginationProps, 'defaultPage' | 'onPageChange' | 'page'> {}

export const usePagination = ({
  defaultPage = 1,
  page: pageProp,
  onPageChange,
}: UsePagination): [number, (page: PaginationPage) => void] => {
  const [currentPage, setCurrentPage] = React.useState(defaultPage);
  const handlePageChange = React.useCallback(
    (page: PaginationPage) => {
      setCurrentPage(page.pageNumber);

      if (onPageChange) {
        onPageChange(page);
      }
    },
    [onPageChange],
  );

  if (pageProp && onPageChange) {
    return [pageProp, onPageChange];
  }

  return [currentPage, handlePageChange];
};
