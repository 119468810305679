import { BenefitContentAction } from 'types/benefit-card-action.types';
import { useFallbackTabs } from '../use-fallback-tabs.hook';
import { useSelectBenefitTabs } from '../use-select-benefit-tabs.hook';
import { useWalletDetailQuery } from './use-wallet-detail-query.hook';

const EMPTY_TABS: BenefitContentAction[] = [];

export function useWalletDetailTabsQuery(
  userId: string | undefined,
  benefitId?: string,
) {
  const fallbackTabs = useFallbackTabs();
  const selectBenefitTabs = useSelectBenefitTabs();

  const query = useWalletDetailQuery<BenefitContentAction[]>(
    userId,
    benefitId,
    undefined,
    {
      select: selectBenefitTabs,
    },
  );

  let tabs = query.data ?? EMPTY_TABS;

  if (tabs.length === 0 && !query.isLoading) {
    tabs = fallbackTabs;
  }

  return { ...query, data: tabs };
}
