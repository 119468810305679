import * as React from 'react';
import type { Accessors } from 'types/accessor';
import {
  LinePath as StyledLinePath,
  LinePathProps as StyledLinePathProps,
} from '../primitives/LinePath';
import { useChart } from '../providers/chart-data-provider';

export type LinePathProps<Datum, XResult, YResult> = Omit<
  StyledLinePathProps<Datum>,
  'top' | 'scale'
> & {
  accessors: Accessors<Datum, XResult, YResult>;
};

// A utility function to retrieve a value for an axis and return the scaled version.
// @ts-ignore
const compose = (scale, accessor) => (d) => scale(accessor(d));

export function LinePath<Datum, XResult, YResult>({
  accessors,
  data,
  ...props
}: LinePathProps<Datum, XResult, YResult>) {
  const { xScale, yScale } = useChart();

  const xPoint = compose(xScale, accessors.xAccessor);
  const yPoint = compose(yScale, accessors.yAccessor);

  return (
    <StyledLinePath
      x={(d) => xPoint(d)}
      y={(d) => yPoint(d)}
      // Only render lines with values.
      data={data?.filter((d) => yPoint(d) !== undefined)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
