import React, { useState } from 'react';
import { useOnEscape } from '../../../utils/use-on-escape';
import {
  TooltipModalPresenter,
  DottedTooltipModalPresenter,
  IconTooltipModalPresenter,
} from './tooltip-modal.view';

export const useTooltipModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useOnEscape(() => closeModal);

  const onLabelKeyDown = (e) => {
    e.key === 'Enter' && openModal();
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    onLabelKeyDown,
    buttonTabIndex: isModalOpen ? -1 : 0,
  };
};

export const TooltipModal = (props) => {
  const tooltipProps = useTooltipModal();
  return <TooltipModalPresenter {...props} {...tooltipProps} />;
};

export const DottedTooltipModal = (props) => {
  const tooltipProps = useTooltipModal();
  return <DottedTooltipModalPresenter {...props} {...tooltipProps} />;
};

export const IconTooltipModal = (props) => {
  const tooltipProps = useTooltipModal();
  return <IconTooltipModalPresenter {...props} {...tooltipProps} />;
};
