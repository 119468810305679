import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';

export const AppBarNavList = ({ children }: { children: React.ReactNode }) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      as="ul"
      orientation="horizontal"
      aria-label={formatMessage({ id: 'MAIN_NAVIGATION_LIST_LABEL' })}
      horizontalAlignment="spaceBetween"
      css={{ alignItems: 'stretch' }}
    >
      {children}
    </StackLayout>
  );
};
