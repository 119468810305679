import { createStore } from 'zustand/vanilla';
import { shallow } from 'zustand/vanilla/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useStore } from 'zustand';
import { produce } from 'immer';
import type { Paths, Get, Primitive, LiteralToPrimitive } from 'type-fest';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import { LeagueConfigError } from './league-config-error';
import {
  type LeagueConfig,
  type ServerConfig,
  leagueConfigSchema,
  KnownLeagueConfig,
} from './league-config-schema';
import { KnownServerConfig } from './module-config-schemas/server-driven';
import { assertConfigSchema } from './assert-config-schema';
import {
  getIsServerConfigPath,
  isServerConfigFetchSettled,
  isStatusSettled,
  isStoreEmpty,
} from './store.utils';
import {
  ConfigProducerRecipe,
  ConfigStoreState,
  ServerConfigSelectionResult,
  ConfigStoreStatus,
  LeagueCombinedConfig,
  ConfigStoreStatusNonEmpty,
  MergedConfigWithStatusFromStatus,
} from './store.types';
import {
  FAILED_TO_FETCH_SERVER_CONFIG_ERROR_MESSAGE,
  GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE,
  SET_CONFIG_UPDATER_WITHOUT_CONFIG_ERROR_MESSAGE,
} from './store.constants';

/**
 * A store for League config values.
 * NOTE: this function SHOULD NOT be exposed as an export of this module. It is
 * only to be used by the server-driven config logic.
 */
export const configStore = createStore<ConfigStoreState>(() => ({
  config: undefined,
  serverConfig: undefined,
  status: ConfigStoreStatus.Empty,
}));

/**
 * Updates the {@link LeagueConfig `LeagueConfig`} store. Can be given either a
 * new `LeagueConfig` object, or a callback which will be passed a draft of
 * the current config and can mutate it. Note that the latter can only be used
 * after the config was set at least once, and will throw an error otherwise.
 *
 * Throws a {@link LeagueConfigError `LeagueConfigError`} if the input does not
 * meet validation against the known `LeagueConfig` schema.
 */
export function setConfig(config: LeagueConfig): void;
export function setConfig(recipe: ConfigProducerRecipe): void;
export function setConfig(configOrRecipe: LeagueConfig | ConfigProducerRecipe) {
  configStore.setState((currentState) => {
    let newConfig: unknown;

    const isEmpty = isStoreEmpty(currentState);

    if (typeof configOrRecipe === 'function') {
      if (isEmpty) {
        throw new LeagueConfigError(
          SET_CONFIG_UPDATER_WITHOUT_CONFIG_ERROR_MESSAGE,
        );
      }
      newConfig = produce(currentState.config, configOrRecipe);
    } else {
      newConfig = configOrRecipe;
    }

    assertConfigSchema(newConfig, leagueConfigSchema);

    if (isEmpty) {
      /**
       * If the store was empty when this function was called, then we need
       * to update its status to `ClientOnly`
       */
      return {
        config: newConfig,
        status: ConfigStoreStatus.ClientOnly,
      };
    }

    /**
     * Otherwise, the status will have been correctly set by some previous state
     * mutation, so we leave it as-is.
     */
    return {
      config: newConfig,
    };
  });
}

/**
 * Given a complete `ConfigStoreState` will merge its `config` and `serverConfig`
 * objects and return the result, or `undefined` if the story was in the `empty`
 * status.
 */
function getMergedConfigFromState<State extends ConfigStoreState>(
  state: State,
): MergedConfigWithStatusFromStatus<State['status']>['config'] {
  return (
    isStoreEmpty(state)
      ? undefined
      : {
          ...state.serverConfig,
          ...state.config,
        }
  ) as MergedConfigWithStatusFromStatus<State['status']>['config'];
}

/**
 * Returns the current {@link MergedConfigWithStatus `MergedConfigWithStatus`}. By default, throws
 * an error if called when the store is in the `empty` status. Takes a boolean `assertConfigSet`
 * parameter which, if set to false, will prevent an error from being thrown if the
 * config is not set and will instead return `undefined`.
 */
export function getConfig(): MergedConfigWithStatusFromStatus<ConfigStoreStatusNonEmpty>;
export function getConfig(
  assertLeagueConfigSet: true,
): MergedConfigWithStatusFromStatus<ConfigStoreStatusNonEmpty>;
export function getConfig(
  assertLeagueConfigSet: false,
): MergedConfigWithStatusFromStatus<ConfigStoreStatus>;
export function getConfig(assertLeagueConfigSet = true) {
  const state = configStore.getState();
  if (!isStoreEmpty(state)) {
    return {
      status: state.status,
      config: getMergedConfigFromState(state),
    };
  }
  if (assertLeagueConfigSet) {
    throw new LeagueConfigError(GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE);
  }

  return {
    status: state.status,
    config: undefined,
  };
}

/**
 * A function that takes {@link MergedConfigWithStatus `MergedConfigWithStatus`} and returns some
 * selection against it.
 */
type Selector<Selection, Status extends ConfigStoreStatus> = (
  configWithStatus: MergedConfigWithStatusFromStatus<Status>,
) => Selection;

/**
 * Provided a {@link Selector `Selector`} and a listener, will call the listener
 * with the selection of the {@link MergedConfigWithStatus `MergedConfigWithStatus`} returned
 * by the selector whenever its value changes, as well as once immediately
 * after subscribing, or `undefined` if the config has not yet been set.
 */
export function subscribeToConfig<Selection>(
  selector: Selector<Selection, ConfigStoreStatusNonEmpty>,
  listener: (
    selection: Selection | undefined,
    prevSelection: Selection | undefined,
  ) => void,
) {
  const initialState = configStore.getState();

  let firstSelection: Selection | undefined;

  // call the listener immediately
  const firstConfig = getMergedConfigFromState(initialState);
  if (firstConfig) {
    const configWithStatus = {
      status: initialState.status,
      config: firstConfig,
    } as MergedConfigWithStatusFromStatus<ConfigStoreStatusNonEmpty>;

    firstSelection = selector(configWithStatus);
  } else {
    firstSelection = undefined;
  }

  listener(firstSelection, undefined);

  /**
   * Keep a record of the previous selection result, so that we can compare it
   * to the new one when the store updates and refrain from calling the listener
   * if the selection hasn't changed (zustand vanilla stores` `subscribe()` method
   * doesn't support selectors natively).
   */
  let prevSelection = firstSelection;

  const unsubscribe = configStore.subscribe((state) => {
    const config = getMergedConfigFromState(state);

    let selection: Selection | undefined;

    if (config) {
      const configWithStatus = {
        status: state.status,
        config,
      } as MergedConfigWithStatusFromStatus<ConfigStoreStatusNonEmpty>;

      selection = selector(configWithStatus);
    } else {
      selection = undefined;
    }

    if (!shallow(prevSelection, selection)) {
      /**
       * Make sure prevSelection is updated before we invoke the listener.
       * This prevents a potential infinite loop where the listener logic
       * is updating the config, triggering the subscription before updating prevSelection.
       */
      const originalPrevSelection = prevSelection;
      prevSelection = selection;

      listener(selection, originalPrevSelection);
    }
  });

  return unsubscribe;
}

/**
 * Returns a promise which resolves with a `LeagueCombinedConfig` when the
 * server config fetch is settled.
 */
export const getSettledConfig = async () =>
  new Promise<LeagueCombinedConfig>((resolve, reject) => {
    const initialState = configStore.getState();
    if (isServerConfigFetchSettled(initialState)) {
      /**
       * First, if server config is already settled when this function is called,
       * we can immediately resolve the promise with the config.
       */
      resolve(getMergedConfigFromState(initialState));
    } else if (initialState.status === ConfigStoreStatus.Error)
      reject(new Error(FAILED_TO_FETCH_SERVER_CONFIG_ERROR_MESSAGE));
    else {
      /**
       * Otherwise, we will create a subscription directly with the store. As soon
       * as the server config is settled, we will unsubscribe and resolve the promise
       * with the config.
       */
      const unsubscribe = configStore.subscribe((currentState) => {
        if (isServerConfigFetchSettled(currentState)) {
          unsubscribe();
          resolve(getMergedConfigFromState(currentState));
        } else if (currentState.status === ConfigStoreStatus.Error) {
          unsubscribe();
          reject(new Error(FAILED_TO_FETCH_SERVER_CONFIG_ERROR_MESSAGE));
        }
      });
    }
  });

/**
 * A reusable function that returns an object with state booleans
 * reflecting the state of the server configuration fetch request.
 */
function getServerConfigSelectionStatus(
  status: ConfigStoreState['status'],
): Omit<ServerConfigSelectionResult, 'data'> {
  return {
    isPresent: isStatusSettled(status),
    isLoading:
      status === ConfigStoreStatus.LoadingWithCache ||
      status === ConfigStoreStatus.Loading,
    isError: status === ConfigStoreStatus.Error,
    isFresh: status === ConfigStoreStatus.Success,
  };
}

/**
 * React hook for subscribing to a selection over {@link MergedConfigWithStatus `MergedConfigWithStatus`}.
 * Takes a {@link Selector `Selector`} callback, which receives a `MergedConfigWithStatus`
 * and returns a selection over it, such that changes to the value of that selection will
 * cause a re-render to the calling component.
 *
 * Optionally, takes a default value as a second argument, which will be merged
 * with the current value of the config selection and returned.
 *
 * @example useConfigSelection((config) => config.core.clientId, 'defaultclientId')
 *
 * Throws an error if called when the config is not yet set.
 */
export function useConfigSelection<Selection>(
  selector: Selector<Selection, ConfigStoreStatusNonEmpty>,
): Selection;
export function useConfigSelection<Selection, DefaultValue extends Selection>(
  selector: Selector<Selection, ConfigStoreStatusNonEmpty>,
  defaultValue: DefaultValue,
): DefaultValue extends Primitive
  ? LiteralToPrimitive<DefaultValue>
  : DefaultValue & Selection;
export function useConfigSelection<Selection, DefaultValue extends Selection>(
  selector: Selector<Selection, ConfigStoreStatusNonEmpty>,
  defaultValue?: DefaultValue,
) {
  return useStoreWithEqualityFn(
    configStore,
    (state) => {
      if (state.status === ConfigStoreStatus.Empty) {
        throw new LeagueConfigError(GETTERS_WITHOUT_CONFIG_ERROR_MESSAGE);
      }
      const config = getMergedConfigFromState(state);

      const configWithStatus = {
        status: state.status,
        config,
      } as MergedConfigWithStatusFromStatus<ConfigStoreStatusNonEmpty>;

      const selection = selector(configWithStatus);

      if (typeof defaultValue === 'object') {
        /**
         * we have been given a `defaultValue`, and it is a non-primitive value.
         * That means we can expect the config property we're getting to be, itself,
         * a non-primitive value, and thus we must merge it with `defaultValue`
         * so that partial config values still take precedence over ones from `defaultValue`.
         *
         * Note: we allow array values to OVERRIDE default values instead of being
         * merged with them.
         */
        return mergeWith({}, defaultValue, selection, (_, b) =>
          Array.isArray(b) ? b : undefined,
        );
      }

      /**
       * Either `defaultValue` was not provided, or it was provided but it and the
       * config property we're getting are primitive. Either way, we will return
       * the config property's value, or if it is nullish, the `defaultValue`.
       */
      return selection ?? defaultValue;
    },
    shallow,
  );
}

/**
 * dot.separated.paths to properties in the KnownLeagueConfig,
 * KnownServerConfig, and UnknownLeagueConfig objects
 *
 * @example 'core.api.wsUrl'
 */
type LeagueConfigPaths = Paths<KnownLeagueConfig>;
type ServerConfigPaths = Paths<KnownServerConfig>;

/**
 * React hook for subscribing to a specific deep property of the config object,
 * by a string path to that property.
 *
 * Optionally, takes a default value as a second argument, which will be merged
 * with the current value of the config property and returned.
 *
 * Throws an error if called when the config is not yet set.
 *
 * @example useConfigProperty('core.api.url', 'default-api-url');
 */

// Server Path Overloads
export function useConfigProperty<
  /**
   * ensure the `path` argument is a valid dot.separated.path to a property in
   * the `ServerConfig` object.
   */
  Path extends ServerConfigPaths,
>(path: Path): ServerConfigSelectionResult<Get<ServerConfig, Path>>;

export function useConfigProperty<
  Path extends ServerConfigPaths,
  DefaultValue extends Get<ServerConfig, Path>,
>(
  path: Path,
  defaultValue: DefaultValue,
): DefaultValue extends Primitive
  ? /**
     * If the property we're providing a default value for is a primitive,
     * then when we take in a default value we still assert that the return type
     * is that primitive and not *the literal type* of the default value.
     */
    ServerConfigSelectionResult<LiteralToPrimitive<DefaultValue>, true>
  : ServerConfigSelectionResult<Get<ServerConfig, Path>, true>;

// Client Path Overloads
export function useConfigProperty<
  /**
   * ensure the `path` argument is a valid dot.separated.path to a property in
   * the `LeagueConfig` object.
   */
  Path extends LeagueConfigPaths,
>(path: Path): Get<LeagueConfig, Path>;

export function useConfigProperty<
  Path extends LeagueConfigPaths,
  /**
   * Accept a default value which must extend the type of the config property
   * at the path
   */
  DefaultValue extends Get<LeagueConfig, Path>,
>(
  path: Path,
  defaultValue: DefaultValue,
): DefaultValue extends Primitive
  ? LiteralToPrimitive<DefaultValue>
  : DefaultValue & Get<LeagueConfig, Path>;

export function useConfigProperty<
  Path extends ServerConfigPaths | LeagueConfigPaths,
  DefaultValue extends Get<LeagueCombinedConfig, Path>,
>(path: Path, defaultValue?: DefaultValue) {
  const isServerConfigPath = getIsServerConfigPath(path);
  const serverConfigStatus = useStore(configStore, ({ status }) =>
    isServerConfigPath ? status : ConfigStoreStatus.Success,
  );
  const configSelection = useConfigSelection(
    ({ config }) => get(config, path),
    defaultValue as DefaultValue,
  );

  /**
   * Based on whether the path is a server config, or client config property path
   * we determine the return type of the hook.
   *
   * Client config returns the selection
   */
  if (!isServerConfigPath) return configSelection;

  /**
   * Server config returns an object with selection as data
   * and server config fetch request statuses
   */
  return {
    data: configSelection,
    ...getServerConfigSelectionStatus(serverConfigStatus),
  };
}

/**
 * React hook for subscribing to an unknown property of the config object,
 * by a string path to that property.
 *
 * Optionally, takes a default value as a second argument, which will be merged
 * with the current value of the config property and returned.
 *
 * Throws an error if called when the config is not yet set.
 *
 * @example useUnknownConfigProperty('any-unknown-property-key-name', 'default-api-url');
 */
export function useUnknownConfigProperty(path: string): unknown;
export function useUnknownConfigProperty<DefaultValue = unknown>(
  path: string,
  defaultValue: DefaultValue,
): DefaultValue extends Primitive
  ? LiteralToPrimitive<DefaultValue>
  : DefaultValue;
export function useUnknownConfigProperty<DefaultValue>(
  path: string,
  defaultValue?: DefaultValue,
) {
  const configSelection = useConfigSelection(
    ({ config }) => get(config, path),
    defaultValue,
  );
  return configSelection;
}
