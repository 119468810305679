import { genesisStyled, Box, css } from '@leagueplatform/genesis-commons';

// For reference: https://a11yproject.com/posts/how-to-hide-content/
// And: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
export const visuallyHidden = Object.freeze({
  ':not(:focus):not(:active)': {
    position: 'absolute',
    height: 1,
    width: 1,
    overflow: 'hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
});

export const VisuallyHidden = genesisStyled(Box)(visuallyHidden);
