import { useQuery } from 'react-query';
import camelcaseKeys from 'camelcase-keys';
import {
  getQuickActions,
  GET_QUICK_ACTIONS_MESSAGE_TYPE,
  GetQuickActionsVersion,
} from '@leagueplatform/care-discovery-api';
import { setIsExternal } from '../utils/set-is-external';

export const useQuickActions = (version?: GetQuickActionsVersion) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [GET_QUICK_ACTIONS_MESSAGE_TYPE, version],
    () => getQuickActions(version),
  );
  const quickActionsData = camelcaseKeys(data?.quick_actions_main_page, {
    deep: true,
  });

  return {
    actionItems: quickActionsData?.actionItems
      ? setIsExternal(quickActionsData.actionItems)
      : [],
    menuItems: quickActionsData?.menuItems || [],
    isLoading,
    isError,
    isSuccess,
  };
};
