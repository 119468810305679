/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DOMPurify from 'dompurify';
import parse, {
  domToReact,
  attributesToProps,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { applyOptionsWithDefaults } from './html-to-react.utils';
import { HTMLTagOptions } from './html-to-react.config';

/**
https://www.npmjs.com/package/html-react-parser
* @function curateOptions  curateOptions hook takes in an options object that after being appended to the defaultHTMLTagOptions, is returned in a way that html-react-parser can understand. In the replace function, it checks if the name (html tag) is part of the tag the user would like to re-render, then returns the component for that tag and spread the props as well
* @param options  a specified object (similar to defaultHTMLTagOptions) that contains the component to rerender the html tag, as well as props to spread to that component
* @return  the options param in the format html-react-parser requires
*/

const curateOptions = (options: HTMLTagOptions) => {
  const allOptions: HTMLTagOptions = applyOptionsWithDefaults(options);
  return {
    trim: true,
    replace({
      name,
      attribs,
      children,
    }: {
      name: string;
      attribs: Record<string, string>;
      children: DOMNode[];
    }) {
      if (!attribs) return undefined;

      const attributeProps = attributesToProps(attribs);

      if (name in allOptions) {
        const { component: Component, props } = allOptions[name];
        return (
          <Component {...props} {...attributeProps}>
            {children && children.length // Self-closing tags will error if children are passed to it, like <img />
              ? domToReact(children, this as HTMLReactParserOptions)
              : undefined}
          </Component>
        );
      }

      return undefined;
    },
  };
};

/**
https://www.npmjs.com/package/html-react-parser
* @function HtmlToReact Uses html-react-parser to parse html tags and return specified react components detailed in the options param object
* @param  htmlString  a string containing html
* @param  options the optional options object is passed into the parse function of html-react-parser. Making this
*                 param optional gives the power to the creator as to what should be displayed. Without this param, the html will render with default styles.
* @return      the react element
*/

interface HtmlToReactProps {
  htmlString?: string;
  options?: HTMLTagOptions;
}

export const HtmlToReact = ({
  htmlString,
  options,
}: HtmlToReactProps): JSX.Element | null => {
  if (!htmlString) return null;

  const sanitizedHtml = DOMPurify.sanitize(htmlString, {
    FORCE_BODY: true,
  });

  const derivedOptions = options ? curateOptions(options) : curateOptions({});

  const reactElement = parse(
    sanitizedHtml,
    derivedOptions as HTMLReactParserOptions,
  ) as JSX.Element;
  // wrapping return in fragment suppresses error discussed here: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{reactElement}</>;
};
