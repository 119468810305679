import {
  Icon,
  Responsive,
  StackLayout,
  StatusBadge,
  UtilityText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { USER_HEALTH_CAMPAIGN_STATUS } from '@leagueplatform/health-journey-api';
import { useGetUserProfile } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import React from 'react';
import { useProgramStatusStyles } from '../../hooks/use-program-status-styles';

interface HeaderDateProps {
  startDate: string;
  endDate: string;
  status: USER_HEALTH_CAMPAIGN_STATUS;
  dateCompleted?: string;
}

export const HeaderDate = ({
  startDate,
  endDate,
  status,
  dateCompleted,
}: HeaderDateProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('laptop'));

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  const { text: badgeText, badgeStatus } = useProgramStatusStyles(
    status,
    dateCompleted,
    defaultTimezone,
  );

  const { formatMessage, formatDate } = useIntl();

  return (
    <StackLayout orientation="horizontal" spacing="$one">
      <Responsive up="laptop">
        <Icon icon="illustrativeCalendar" size="$oneAndHalf" />
      </Responsive>

      <StackLayout
        css={{ flexWrap: 'wrap' }}
        horizontalAlignment={isMobile ? 'center' : 'start'}
        orientation={isMobile ? 'horizontal' : 'vertical'}
        verticalAlignment="center"
        spacing="$quarter"
      >
        <UtilityText
          css={{
            typography: isMobile ? '$bodyTwo' : '$bodyOne',
          }}
        >
          {formatMessage(
            { id: 'START_DAY_AT_TIME' },
            {
              day: formatDate(startDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: defaultTimezone,
              }),
            },
          )}
        </UtilityText>
        {endDate && isMobile && (
          <UtilityText
            css={{
              typography: isMobile ? '$bodyTwo' : '$bodyOne',
            }}
          >
            •
          </UtilityText>
        )}
        {endDate && (
          <UtilityText
            css={{
              typography: isMobile ? '$bodyTwo' : '$bodyOne',
            }}
          >
            {formatMessage(
              { id: 'END_DAY_AT_TIME' },
              {
                day: formatDate(endDate, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: defaultTimezone,
                }),
              },
            )}
          </UtilityText>
        )}

        <StatusBadge
          css={{
            marginTop: isMobile ? 'none' : 'threeQuarters',
            marginLeft: isMobile ? 'one' : 'none',
          }}
          label={badgeText}
          showLight
          status={badgeStatus}
        />
      </StackLayout>
    </StackLayout>
  );
};
