import { notBrowser } from '../utils/utils';

let currentGAPropertyID = null;

const getGtag = () => window.gtag || (() => {});

export const initGoogleAnalytics = (GAPropertyID) => {
  if (notBrowser) return false;

  currentGAPropertyID = GAPropertyID;
  return getGtag()('config', GAPropertyID, { send_page_view: false });
};

export const sendGoogleAnalytics = (params) => {
  const data = {
    event_action: params.action,
    event_category: params.category,
    event_label: params.label,
    value: params.value,
  };

  getGtag()('event', params.action, data);

  return data;
};

export const sendGoogleAnalyticsPageView = (params) => {
  const data = {
    page_title: params.screen_name,
    page_path: params.path,
    ...params,
  };

  getGtag()('event', 'page_view', data);

  return data;
};

export const setDimension = (name, value) => {
  if (!currentGAPropertyID) return;

  getGtag()('config', currentGAPropertyID, {
    custom_map: { [name]: value },
  });
};
