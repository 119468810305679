export enum SOLUTION_TYPE {
  benefit = 'benefit',
  healthProgram = 'health_program',
  provider = 'provider',
  marketplace = 'marketplace',
}

export enum CARE_COLLECTIONS_DATA_TYPE {
  careCollection = 'care-collection',
  careCollectionsTopic = 'care-collections-topic',
}

export interface CareCollectionQueryResponse {
  data: CareCollection;
  included: Omit<CareTopic, 'relationships'>[];
}

export interface CareCollection {
  id: string;
  type: CARE_COLLECTIONS_DATA_TYPE.careCollection;
  attributes: {
    title: string;
    image: string;
    description: string;
    topic: string;
    typeformUrl?: string;
    sections: Section[];
    solutions: Solution[];
  };
  relationships: {
    topics: {
      data: Relationship[];
    };
  };
}

export interface Section {
  title: string;
  richTextDescription: string;
}

interface DefaultSolution {
  title: string;
  description: string;
  url: string;
  image: string;
}

export type Solution =
  | (DefaultSolution & {
      type: SOLUTION_TYPE;
      phoneNumber?: never;
    })
  | (DefaultSolution & {
      type: SOLUTION_TYPE.benefit;
      phoneNumber?: string;
    });

export interface Relationship {
  id: string;
  type: CARE_COLLECTIONS_DATA_TYPE;
}

export interface CareTopic {
  id: string;
  type: CARE_COLLECTIONS_DATA_TYPE.careCollectionsTopic;
  attributes: {
    title: string;
    icon: string;
  };
  relationships: {
    collections: {
      data: Relationship[];
    };
  };
}

export interface CareTopicsQueryResponse {
  data: CareTopic[];
  included: Omit<CareCollection, 'relationships'>[];
}

export interface CareCollectionsQueryResponse {
  data: CareCollection[];
  included?: Omit<CareTopic, 'relationships'>[];
}
