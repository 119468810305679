import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import flatten from 'flat';

const INPUT_ELEMENT_TAG_NAMES = ['input', 'select', 'textarea'];

const isFocusableInputElement = (element: HTMLElement) =>
  INPUT_ELEMENT_TAG_NAMES.includes(element.tagName.toLowerCase());

const getClosestFocusableInputElement = (element: HTMLElement) =>
  element.querySelector<HTMLElement>(INPUT_ELEMENT_TAG_NAMES.join(','));

/**
 * When called in a descendent of a `<Formik>`, this hook will focus the first
 * invalid field's input element in the DOM after form submission.
 *
 * @param focusDelay number of miliseconds to wait before focusing the first
 * invalid field after form submission
 */
export const useFocusFirstFormikError = (focusDelay = 250) => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      /**
       * Flatten the errors object into a lodash-style dot.path, so we can relate
       * error keys to field names even in forms with nested fields.
       */
      const invalidFieldNames = Object.keys(flatten(errors));

      if (!invalidFieldNames.length) {
        return;
      }

      /**
       * Create a CSS selector that will match any of the invalid input elements
       * by `name`.
       */
      const selector = invalidFieldNames
        .map((fieldName) => `[name="${fieldName}"]`)
        .join(',');

      /**
       * use `querySelector` to get the first element that matches the selector.
       */
      const firstInvalidField = document.querySelector<HTMLElement>(selector);
      if (!firstInvalidField) {
        return;
      }
      const focusableElement = isFocusableInputElement(firstInvalidField)
        ? firstInvalidField
        : getClosestFocusableInputElement(firstInvalidField);

      if (focusableElement) {
        setTimeout(() => {
          focusableElement.focus();
        }, focusDelay);
      }
    }
  }, [isSubmitting, isValidating, errors, focusDelay]);
};
