import isSameDay from 'date-fns/isSameDay';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {
  SHORT_MONTH_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';

export const RENDER_EVERY_N_DAY = 6;

export function useMonthlyTicks(data: string[]) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();

  const mostRecentDatum = data[data.length - 1];

  function formatTick(
    timestamp: number,
    index: number,
    dates: { value: string | number | Date }[],
  ) {
    const shouldShowMonth =
      index === 0 ||
      differenceInCalendarMonths(
        new Date(timestamp),
        new Date(dates[index - 1].value),
      ) > 0;

    const tick = formatDateWithUserProfileLocation(
      new Date(timestamp),
      shouldShowMonth ? SHORT_MONTH_FORMAT : { day: 'numeric' },
    );

    return tick;
  }

  // Only display ticks every n days.
  const ticks = data
    .filter((lastUpdate) => {
      const dayDifference = differenceInCalendarDays(
        new Date(mostRecentDatum),
        new Date(lastUpdate),
      );
      return dayDifference !== 0 && dayDifference % RENDER_EVERY_N_DAY === 0;
    })
    // Remove duplicate dates when there is a series of metrics within a day.
    .filter(
      (date, index, dates) =>
        !dates
          .slice(index + 1)
          .find((d) => isSameDay(new Date(d), new Date(date))),
    );

  return [formatTick, ticks] as const;
}
