import React, { ReactNode } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingThree,
  SubtitleOne,
  Spinner,
} from '@leagueplatform/genesis-commons';
import { ErrorState } from '@leagueplatform/web-common-components';
import { useGetIndividualTeamRank } from 'pages/team-landing-page/hooks/use-get-individual-team-rank.hook';
import { ProgressBox } from './progress-box.component';
import { TLPCard } from '../../tlp-card/tlp-card.component';

const TLPProgressContainer = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();
  return (
    <Box marginBottom="one">
      <TLPCard>
        <HeadingThree as="h2" marginBottom="oneAndHalf">
          {formatMessage({ id: 'PROGRESS' })}
        </HeadingThree>
        {children}
      </TLPCard>
    </Box>
  );
};

export const TLPProgress = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { isLoading, error, data: teamRankData } = useGetIndividualTeamRank();

  if (isLoading)
    return (
      <TLPProgressContainer>
        <Flex height="three" justifyContent="center">
          <Spinner isLoading />
        </Flex>
      </TLPProgressContainer>
    );

  if (error || !teamRankData?.data || teamRankData?.data?.length === 0)
    return (
      <TLPProgressContainer>
        <ErrorState />
      </TLPProgressContainer>
    );

  const { rank, score, rankingUnit } = teamRankData.data[0];
  return (
    <Box marginBottom="one">
      <TLPCard>
        <HeadingThree as="h2" marginBottom="oneAndHalf">
          {formatMessage({ id: 'PROGRESS' })}
        </HeadingThree>
        <Flex justifyContent="space-between" width="100%" textAlign="center">
          <ProgressBox title={formatMessage({ id: 'CURRENT_RANK' })}>
            <SubtitleOne as="p">
              {formatMessage({ id: 'XTH_PLACE' }, { currentRank: rank })}
            </SubtitleOne>
          </ProgressBox>
          <ProgressBox title={formatMessage({ id: 'TEAM_AVERAGE' })}>
            <SubtitleOne as="p">
              {formatNumber(score)} {rankingUnit}
            </SubtitleOne>
          </ProgressBox>
        </Flex>
      </TLPCard>
    </Box>
  );
};
