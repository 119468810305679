import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '../primitives.view';
import { hex2Rgba } from '../../utils/helpers';
import { getColourObject } from '../../utils/helpers';

const baseRules = ({ theme }) => css`
  color: ${getColourObject(theme).purple};
  background-color: ${hex2Rgba(getColourObject(theme).purple, 0.1)};
  border: 2px solid ${getColourObject(theme).purple};
  border-radius: 0.5rem;
  line-height: 1.4375;
  a {
    color: ${getColourObject(theme).purple};
    font-weight: 500;
    text-decoration: none;
    border-bottom: 2px dotted ${getColourObject(theme).purple};
  }
`;

const adminRules = css`
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  line-height: 1.75;
  margin-top: 1rem;
`;

const CalloutContainer = styled(Box)`
  ${baseRules} ${({ admin }) => admin && adminRules};
`;

export const Callout = ({ children, ...props }) => (
  <CalloutContainer {...props} p={3}>
    {children}
  </CalloutContainer>
);

Callout.propTypes = {
  children: PropTypes.node.isRequired,
};
