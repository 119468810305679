import { ActivityStatusOptions } from 'hooks/use-activities-by-activity-status-and-campaign.hook';

// TODO: Replace statusType with improved typing when completing:
// https://everlong.atlassian.net/browse/XPNC-2890
export const getActivityStatusesArray = (
  statusType: string,
): ActivityStatusOptions[] => {
  switch (statusType) {
    case 'active':
      return ['active'];
    case 'completed':
      return ['completed'];
    case 'missed':
      return ['expired', 'removed'];
    default:
      return [];
  }
};
