import React from 'react';
import { SubtleButton } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { ICONS } from '@leagueplatform/ui-kit';

interface NavButtonProps {
  disabled: boolean;
  onClick: () => void;
  label: string;
  icon: string | JSX.Element;
}

interface LeftAndRightNavButtonProps
  extends Omit<NavButtonProps, 'label' | 'icon'> {}

const NavButton = ({ disabled, onClick, label, icon }: NavButtonProps) => (
  <SubtleButton
    borderRadius="circle"
    width="two"
    height="two"
    paddingX="none"
    paddingY="none"
    paddingTop="one"
    paddingBottom="threeQuarters"
    transition="visibility 300ms, opacity 300ms"
    disabled={disabled}
    onClick={onClick}
    aria-label={label}
  >
    {icon}
  </SubtleButton>
);

export const LeftNavButton = ({
  disabled,
  onClick,
}: LeftAndRightNavButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <NavButton
      disabled={disabled}
      onClick={onClick}
      label={formatMessage({ id: 'SCROLL_CAROUSEL_LEFT' })}
      icon={ICONS.LEFT_CHEVRON[0]}
    />
  );
};

export const RightNavButton = ({
  disabled,
  onClick,
}: LeftAndRightNavButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <NavButton
      disabled={disabled}
      onClick={onClick}
      label={formatMessage({ id: 'SCROLL_CAROUSEL_RIGHT' })}
      icon={ICONS.RIGHT_CHEVRON[0]}
    />
  );
};
