import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { captureError } from '@leagueplatform/observability';
import { useMutation } from 'react-query';

interface RedeemPromoCodes {
  code: string;
}

const REDEEM_PROMO_CODE_MESSAGE_TYPE = 'redeem_promo_code';
function redeemPromoCode({ code }: RedeemPromoCodes) {
  return SocketAsFetch.fetch({
    message_type: REDEEM_PROMO_CODE_MESSAGE_TYPE,
    info: {
      code,
    },
  });
}

export function useRedeemPromoCodeMutation() {
  return useMutation(redeemPromoCode, {
    onError: (err, variables) => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROMO_CODE_NOT_FOUND',
      });

      captureError(err as Error, {
        context: {
          params: {
            promoCode: variables.code,
          },
        },
      });
    },
    onSuccess: () => {
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'PROMO_CODE_SUCCESS',
      });
    },
  });
}
