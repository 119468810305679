import {
  BenefitCardAction,
  CARD_STATUS,
  SYSTEM_TYPES,
} from '@leagueplatform/wallet-api';
import { Coverage } from 'components/coverage/coverage.component';
import { CardBody } from '../components/card-body/card-body.component';
import { AboutBenefit } from '../components/content-panel/components/about-benefit/about-benefit.component';
import { CardDetails } from '../components/content-panel/components/card-details/card-details.component';
import { LeagueCredit } from '../components/content-panel/components/league-credit/league-credit.component';
import { MedicalCoverage } from '../components/medical-coverage/medical-coverage.component';
import { Overview } from '../components/content-panel/components/overview/overview.component';
import { Transactions } from '../components/content-panel/components/transactions/transactions.component';
import { Dependents } from '../components/dependents/dependents.component';
import { PlanProgressAndCoverage } from '../components/plan-progress-and-coverage/plan-progress-and-coverage.component';
import { PlanProgressSwitcher } from '../components/plan-progress/plan-progress-switcher.component';

export function getContentComponent(
  action: BenefitCardAction,
  status?: CARD_STATUS,
  isNonActivePolicyHiddenFlag?: boolean,
) {
  switch (action.system_type) {
    case SYSTEM_TYPES.CoverageOverview:
      return Overview;
    case SYSTEM_TYPES.CoverageCategories:
      return Coverage;
    case SYSTEM_TYPES.UsageOverview:
      return PlanProgressSwitcher;
    case SYSTEM_TYPES.PlanProgressCoverageOverview:
      return PlanProgressAndCoverage;
    case SYSTEM_TYPES.ViewDependent:
      return Dependents;
    case SYSTEM_TYPES.TransactionHistory:
      return Transactions;
    case SYSTEM_TYPES.LeagueCredit:
      return LeagueCredit;
    case SYSTEM_TYPES.CardInfo:
      if (status !== CARD_STATUS.ACTIVE && isNonActivePolicyHiddenFlag) {
        return CardBody;
      }

      return CardDetails;
    case SYSTEM_TYPES.About:
      return AboutBenefit;
    case SYSTEM_TYPES.ViewProviderServices: {
      return MedicalCoverage;
    }
    default:
      return CardBody;
  }
}
