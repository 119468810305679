import { deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  DashboardConfigResponseData,
  DashboardConfigDeserialisedData,
} from './types';
import { DASHBOARD_CONFIG_PATH } from './constants';

export async function getDashboardConfig(): Promise<DashboardConfigDeserialisedData> {
  const response = await leagueFetch(`${DASHBOARD_CONFIG_PATH}`);

  const responseJson: DashboardConfigResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getDashboardConfig error: ${response.status}`);
  }
  return deserialise(responseJson);
}
