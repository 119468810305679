export const formatUserName = ({
  firstName,
  lastName,
  isInitials,
}: {
  firstName: string;
  lastName: string;
  isInitials?: boolean;
}) => {
  if (isInitials) {
    return `${firstName.charAt(0)} ${lastName.charAt(0)}`;
  }
  return `${firstName} ${lastName.charAt(0)}.`;
};
