import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSizeInPx, colour } from '../../theme';

const StyledLabel = styled.label`
  margin-top: 2rem;
  font-size: ${fontSizeInPx(2)};
  font-weight: 500;
  color: ${colour('purpleText')};
  input {
    display: ${(props) => (props.inline ? 'inline-block' : 'block')};
    margin-left: ${(props) => (props.inline ? '0.5rem' : '0')};
  }
`;

export const Label = ({ children, inline, htmlFor, ...props }) => (
  <StyledLabel {...props} inline={inline} htmlFor={htmlFor}>
    {children}
  </StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
};

Label.defaultProps = {
  inline: false,
};
