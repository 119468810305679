import React from 'react';
import {
  Card,
  HeadingText,
  StackLayout,
  TextAction,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { BadgeImage } from '../badge-image/badge-image.view';
import { EarnedTag } from '../earned-tag/earned-tag.view';

interface BadgeCardProps {
  imageUrl: string;
  isDisabled: boolean;
  title: string;
  earnedStreak: number;
  onClick: () => void;
}

export const BadgeCard = ({
  imageUrl,
  isDisabled,
  title,
  earnedStreak,
  onClick,
}: BadgeCardProps) => {
  const earnedBadgeRequirement = 2;

  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <Card.Elevated
      css={{
        position: 'relative',
        margin: '$threeQuarters',
        height: '136px',
        width: '384px',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        css={{
          backgroundColor: '$surfaceBackgroundPrimary',
          padding: '$threeQuarters',
        }}
      >
        <StackLayout orientation="horizontal" verticalAlignment="center">
          <BadgeImage
            imageUrl={imageUrl}
            isDisabled={isDisabled}
            css={{
              margin: '$oneAndHalf',
            }}
          />
          <HeadingText
            level="4"
            size="md"
            css={{
              color: isDisabled
                ? '$onSurfaceTextSubdued'
                : '$onSurfaceTextPrimary',
            }}
          >
            <TextAction
              as="button"
              ref={primaryActionRef}
              css={{
                all: 'inherit',
              }}
              onClick={onClick}
            >
              {title}
            </TextAction>
          </HeadingText>
          {earnedStreak >= earnedBadgeRequirement && (
            <EarnedTag completionAmount={earnedStreak} isBadge />
          )}
        </StackLayout>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
