import { useParams } from '@leagueplatform/routing';
import { useMutation } from 'react-query';
import { postCreateTeam } from '@leagueplatform/health-journey-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  TLP_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';

interface NewTeam {
  teamName: string;
  teamPhoto: string;
}

export const useCreateTeam = (
  setTeamId: React.Dispatch<React.SetStateAction<string>>,
  setDisplayCreateTeamSuccess: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  return useMutation(
    ({ teamName, teamPhoto }: NewTeam) =>
      postCreateTeam({ challengeId, teamName, teamPhoto }),
    {
      onSuccess: (response) => {
        trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          screen_name: TLP_SCREEN_NAMES.CREATE_TEAM,
          detail: SIGN_UP_ANALYTICS_DETAILS.CREATE_TEAM,
        });
        setTeamId(response.data.id);
        setDisplayCreateTeamSuccess(true);
      },
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'UNABLE_TO_CREATE_TEAM',
          shouldAutoClose: false,
        });
      },
    },
  );
};
