import * as React from 'react';
import {
  BodyOne,
  Box,
  Flex,
  HeadingTwo,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { GenesisRouterLink } from '../genesis-router-link/genesis-router-link';
import { StackLayout } from '../stack-layout/stack-layout.component';

interface PageNotFoundProps {
  redirectLink?: string;
  buttonText?: string;
}

export function PageNotFound({
  redirectLink = '/',
  buttonText,
}: PageNotFoundProps): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      backgroundColor="surface.background.secondary"
      textAlign="center"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
    >
      <Box
        borderRadius="small"
        paddingY="two"
        paddingX="four"
        width="45rem"
        backgroundColor="surface.background.primary"
        boxShadow="card"
      >
        <StackLayout space="$two">
          <HeadingTwo>
            {formatMessage({ id: 'PAGE_NOT_FOUND_HEADER' })}
          </HeadingTwo>
          <Box>
            <BodyOne>{formatMessage({ id: 'PAGE_NOT_FOUND_CONTENT' })}</BodyOne>
            <BodyOne>
              {formatMessage({ id: 'PAGE_NOT_FOUND_CONTENT_SECONDARY' })}
            </BodyOne>
          </Box>

          <PrimaryButton as={GenesisRouterLink} to={redirectLink}>
            {buttonText ?? formatMessage({ id: 'PAGE_NOT_FOUND_BUTTON' })}
          </PrimaryButton>
        </StackLayout>
      </Box>
    </Flex>
  );
}
