import { FieldType, InputType } from 'constants/health-metrics.constants';
import { DeserialisedData, ResponseWithData } from 'types/json-api.types';

export const HEALTH_METRIC = 'health-metric';
type HealthMetricType = typeof HEALTH_METRIC;

type RiskIndicator = {
  style: 'success' | 'warning' | 'error';
  label: string;
};

type HealthMetricSource =
  | 'self_reported'
  | 'device'
  | 'health_plan'
  | 'self_reported_no_input';

export type HealthMetricUnit = {
  symbol: string | null;
  name: string;
  namePlural?: string;
};

type HealthMetricComponent = {
  id: string;
  type: 'metric-component';
  attributes: {
    name: string;
    unit: HealthMetricUnit;
    order: number;
    sourceType: HealthMetricSource;
    referenceRange?: string;
  };
};

export type HealthMetric = {
  id: string;
  type: HealthMetricType;
  attributes: {
    value: number | null;
    timestamp: string;
    sourceDisplayName: string;
    label: string;
    imageUrl?: string;
    imageAltText?: string;
    effectiveTime?: EffectiveTime;
    riskIndicator?: RiskIndicator;
  };
  relationships: {
    metricComponent: {
      data: { id: string; type: 'metric-component' };
    };
    editInputConfig: {
      data: { id: string; type: 'input-config' };
    };
  };
};

export type EffectiveTime = {
  type: 'period';
  start: string;
  end: string;
};

type HealthMetricAverage = {
  id: string;
  type: 'health-metric-average';
  attributes: {
    value: string;
  };
  relationships: {
    config: {
      data: { id: string; type: 'metric-component' }[];
    };
  };
};

export type ChartType = 'bar' | 'line' | 'range';

export type HealthMetricsMetadata = {
  isAnyDataAvailable?: boolean;
};

export type AddEditInput = {
  metricId: string;
  type: 'integer' | 'string';
  max: number;
  min: number;
  unit: string;
};

type ValueFields = {
  fieldType: FieldType;
  fieldKeyName: string;
  unit: string;
  placeholderCopy?: string;
  min?: number;
  max?: number;
  fieldLabel?: string;
  hint?: string;
  options?: Array<{
    label: string;
    value: number;
  }>;
};

export type InputConfig = {
  id: string;
  type: 'input-config';
  attributes: {
    heading: string;
    badge: string;
    description?: string;
    showEffectiveDateTimePicker: boolean;
    informationBanner?: {
      heading: string;
      icon: string;
      body: string;
    };
    healthMetricInputs: HealthMetricInput[];
  };
};

export type HealthMetricInput = {
  metricComponentId: string;
  inputType: InputType;
  valueFields: ValueFields[];
};

type HealthMetricsIncluded =
  | HealthMetricComponent
  | HealthMetric
  | HealthMetricAverage
  | InputConfig;

export type HealthMetricsData = {
  id: string;
  type: 'metric-config';
  attributes: {
    chartType: ChartType;
    screenReaderTitle: string;
    screenReaderDescription: string;
    metricDisplayName: string;
    axisLeftLabel: string;
    showHealthLiteracyOnAllRecordedDataScreen?: boolean;
  };
  relationships: {
    metricComponents: {
      data: { id: string; type: 'metric-component' }[];
    };
    values: {
      data: { id: string; type: HealthMetricType }[];
    };
    averages: {
      data: { id: string; type: 'health-metric-average' }[];
    };
    todaysValues: {
      data: { id: string; type: HealthMetricType }[];
    };
    addInputConfig?: {
      data: {
        id: string;
        type: 'input-config';
      };
    };
  };
};

export type HealthMetricsResponseData = ResponseWithData<
  HealthMetricsData,
  HealthMetricsMetadata,
  HealthMetricsIncluded
>;

export type HealthMetricValue = DeserialisedData<HealthMetric> & {
  metricComponent: { data: DeserialisedData<HealthMetricComponent> };
  editInputConfig: { data: DeserialisedData<InputConfig> };
};

export type HealthMetricAverageValue = DeserialisedData<HealthMetricAverage> & {
  metricComponent: { data: DeserialisedData<HealthMetricComponent> };
};

export type HealthMetricConfigValue = DeserialisedData<HealthMetricComponent>;

export type InputConfigValue = DeserialisedData<InputConfig>;

export type HealthMetricDeserialisedData = {
  data: Array<
    DeserialisedData<HealthMetricsData> & {
      metricComponents: { data: HealthMetricConfigValue[] };
      values: { data: HealthMetricValue[] };
      averages: { data: HealthMetricAverageValue[] };
      todaysValues: { data: HealthMetricValue[] };
      addInputConfig: { data: InputConfigValue };
    }
  >;
  links?: {
    self?: string;
    next?: string;
    prev?: string;
  };
  meta?: HealthMetricsMetadata;
};
