import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE = 'get_health_goal_programs';

export const getHealthGoalPrograms = async (errorContext?: ErrorContext) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
      info: {
        version: 1,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
