import React, {
  FC,
  forwardRef,
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  Ref,
  isValidElement,
} from 'react';
import { styled } from '../../../theme';
import {
  generateInputLabel,
  generateInputHint,
  generateInputStatusMessage,
} from '../component-generators';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSInputStatus,
} from '../../../types';
import { isReactElement } from '../utilities';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';
import type { GDSHTMLAutocomplete } from '../types';

export interface GDSFormFieldProps extends GDSCustomizableComponent {
  children: ReactNode;
  hideLabel?: GDSResponsiveProp<boolean>;
  hint?: ReactNode;
  id: string;
  inputStatus?: GDSInputStatus;
  label: ReactNode;
  name?: string;
  required?: boolean;
  statusIconLabel?: string;
  statusMessage?: ReactNode;
  tooltip?: ReactNode;
  autoComplete?: GDSHTMLAutocomplete;
}

const FormFieldBase = styled('div', { width: '100%' });

export const FormField: FC<GDSFormFieldProps> = forwardRef(
  (
    {
      children,
      className,
      css,
      hideLabel = false,
      hint,
      id,
      inputStatus,
      label,
      name,
      required = true,
      statusIconLabel,
      statusMessage,
      tooltip,
      autoComplete,
      ...props
    }: GDSFormFieldProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const statusMessageId = `${id}-status-message`;
    const hintId = `${id}-hint`;

    const ariaDescribedBy: string[] = [];
    if (statusMessage) ariaDescribedBy.push(statusMessageId);
    if (hint) ariaDescribedBy.push(hintId);

    // Show warning if more than one child is passed
    if (Children.count(children) > 1)
      console.warn(
        'More than one child detected. FormField is a wrapper for a single input. For multiple inputs, use Fieldset.',
      );

    return (
      <GenesisCoreInspector displayName="FormField">
        <FormFieldBase
          className={['GDS-form-field', className].join(' ')}
          css={css}
          ref={ref}
          {...props}
        >
          {label &&
            generateInputLabel({
              label,
              htmlFor: id,
              required,
              hideLabel,
              tooltip,
            })}

          {hint &&
            generateInputHint({
              hint,
              hintId,
              css: { marginBlockEnd: '$quarter' },
            })}

          <div className="GDS-input-wrapper">
            {Children.map(children, (child) => {
              // Clone attributes for React and HTML elements
              if (isValidElement(child)) {
                if (child?.props?.['aria-describedby'])
                  ariaDescribedBy.push(child.props['aria-describedby']);

                return cloneElement(child as ReactElement<any>, {
                  id,
                  name,
                  ...(ariaDescribedBy.length > 0 && {
                    'aria-describedby': ariaDescribedBy.join(' '),
                  }),
                  // Pass inputStatus only to React components, not to raw HTML elements like <input type="text"/>
                  ...(isReactElement(child) && { inputStatus, autoComplete }),
                });
              }

              return child;
            })}
          </div>

          {inputStatus &&
            statusMessage &&
            generateInputStatusMessage({
              id: statusMessageId,
              inputStatus,
              statusIconLabel,
              statusMessage,
            })}
        </FormFieldBase>
      </GenesisCoreInspector>
    );
  },
);

FormField.displayName = 'FormField';
