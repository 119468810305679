import * as React from 'react';
import { merge } from 'lodash';
import {
  StackLayout,
  StackItem,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { SectionHeader } from '../masonry-section-header-renderer/section-header.component';
import { COMPLEX_WIDGET_CARDS_WIDTH } from '../../constants';

export interface MasonryHorizontalLayoutNodeProperties {
  heading?: string;
  body?: string;
}

export type MasonryHorizontalLayoutNode = MasonryEngineNode<
  'horizontalLayout',
  MasonryHorizontalLayoutNodeProperties,
  {},
  {
    items: MasonryEngineNode<string, any>[];
  }
>;

export type MasonryHorizontalLayoutRendererProps =
  MasonryEngineNodeRendererProps<MasonryHorizontalLayoutNode>;

export const MasonryHorizontalLayoutRenderer = ({
  items,
  heading,
  body,
}: MasonryHorizontalLayoutRendererProps) => {
  if (!items) return null;

  return (
    <StackLayout
      spacing="$one"
      horizontalAlignment="stretch"
      orientation="vertical"
    >
      {heading && <SectionHeader heading={heading} />}
      {body && <ParagraphText>{body}</ParagraphText>}
      <StackLayout
        spacing="$one"
        horizontalAlignment="stretch"
        verticalAlignment="stretch"
        orientation="horizontal"
      >
        {React.Children.map(items, (item) => (
          <StackItem
            verticalAlignment="fillContainer"
            css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH }}
          >
            {React.cloneElement(item, {
              node: merge({}, item.props.node, {
                properties: { isChildItem: true },
              }),
            })}
          </StackItem>
        ))}
      </StackLayout>
    </StackLayout>
  );
};
