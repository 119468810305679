import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import {
  BodyOneSecondary,
  BodyOne,
  Box,
  HeadingThree,
  Flex,
  CoverImage,
  BodyTwoSecondary,
  css,
  genesisStyled,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { withTheme } from 'styled-components';
import { TooltipContent } from 'ui-kit/components/tooltip/tooltip-content.v2.view';
import { Tooltip } from 'ui-kit/components/tooltip/tooltip.view';
import resumeStar from 'assets/resume-star.svg';
import { getColourObject } from '../../utils/helpers';

const Container = genesisStyled((props) => <Box {...props} />)(({ active }) =>
  css({
    flexGrow: active ? 4.75 : 1,
    flexBasis: 0,
    transition: active && 'flex-grow 1s ease',
    /* The tooltip generates a button with a width of its content which is
    0 for the below progress bar. The progress bar depends on its
    parent being 100% to function properly. */
    button: {
      width: '100%',
    },
  }),
);

const ProgressBarContainer = (props) => (
  <Box
    height="half"
    backgroundColor="interactive.action.disabled"
    cursor="pointer"
    borderRadius="large"
    {...props}
  />
);

const ProgressBar = (props) => (
  <Box
    height="half"
    borderRadius="large"
    transition="width 2s ease"
    {...props}
  />
);

const ProgressBarText = (props) => (
  <BodyOneSecondary minHeight="two" padding="quarter" {...props} />
);

export const SingleProgressBar = withTheme(
  ({
    name,
    color,
    active,
    isResuming,
    totalSteps,
    completedSteps,
    description,
    toolTipRight,
    completionPoints,
    showContentTooltip,
    theme,
    isMobile,
  }) => {
    const { formatMessage, $tifelse } = useIntl();
    // calculate percent completion
    const progress = (completedSteps / totalSteps) * 100 || 0;
    const activeResume = active && isResuming;
    const [showResumeTooltip, setShowResumeTooltip] = useState(activeResume);
    const [timedOut, setTimedOut] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimedOut(true);
      }, 3000);
      return () => clearTimeout(timer);
    }, [setTimedOut]);

    const topicContent = (
      <Box>
        <HeadingThree marginBottom="quarter">{name}</HeadingThree>
        <BodyOneSecondary marginTop="one">{description}</BodyOneSecondary>
      </Box>
    );

    const questionsAwayMessage = $tifelse(
      completionPoints > 0,
      'YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NUMBER',
      'YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NOR_POINTS',
      { points: completionPoints },
    );

    const resumeContent = (
      <Flex flexDirection="column" alignItems="center">
        <Flex alignItems="center" justifyContent="center" marginBottom="half">
          <CoverImage
            src={resumeStar}
            alt="resume-star"
            size="42px"
            backgroundSize="contain"
          />
          <BodyOne fontWeight="bold" marginLeft="one">
            {formatMessage({ id: 'WEVE_PICKED_UP' })}
          </BodyOne>
        </Flex>
        <BodyTwoSecondary>{questionsAwayMessage}</BodyTwoSecondary>
      </Flex>
    );

    const progressDescribeById = uniqueId('single-progress-bar');

    return (
      <Container
        marginX="quarter"
        active={active}
        className={`league-progress-bar-${active ? 'active' : 'inactive'}`}
      >
        {!isMobile && <ProgressBarText>{active && name}</ProgressBarText>}
        {/* if we hover over the progress bar, we want to nuke (no transition) the other tooltip */}
        <Tooltip
          describedBy={progressDescribeById}
          onHover={() => setShowResumeTooltip(false)}
        >
          <ProgressBarContainer className={`league-progress-bar`}>
            <VisuallyHidden>
              {`${name}. ${formatMessage(
                { id: 'X_PERCENT_COMPLETE' },
                { number: Math.round(progress) },
              )}`}
            </VisuallyHidden>
            <ProgressBar backgroundColor={color} width={`${progress}%`} />
          </ProgressBarContainer>
          {showContentTooltip && (
            <TooltipContent
              describeId={progressDescribeById}
              positionShift={{
                x: 0,
                y: 30,
              }}
              top
              right={!active && toolTipRight}
              arrowSize={12}
              borderRadius={4}
              borderColour={getColourObject(theme).neutral.grayLight}
              padding="20px"
            >
              {topicContent}
            </TooltipContent>
          )}
        </Tooltip>
        {/* Setting isHovered explicitly to show Resume tooltip immediately for user
      showResumeTooltip is a local state to nuke the tooltip if they hover over
      the progress bar, so the transition between the two tooltips are better. */}
        {showResumeTooltip && (
          // active resume takes care of if they change their selections, since isResuming -> false
          // if selections change
          // timedOut is a local state variable that handles if it times out.
          <Tooltip isHovered={activeResume && !timedOut}>
            <Box height={0} />
            <TooltipContent
              positionShift={{
                x: 0,
                y: 30,
              }}
              top
              right={!active && toolTipRight}
              arrowSize={12}
              borderRadius={4}
              borderColour={getColourObject(theme).neutral.grayLight}
              padding="20px"
            >
              {resumeContent}
            </TooltipContent>
          </Tooltip>
        )}
      </Container>
    );
  },
);

SingleProgressBar.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  active: PropTypes.bool,
  totalSteps: PropTypes.number.isRequired,
  completedSteps: PropTypes.number.isRequired,
  description: PropTypes.string,
  toolTipRight: PropTypes.bool,
  isResuming: PropTypes.bool,
  completionPoints: PropTypes.number,
  showContentTooltip: PropTypes.bool,
  isMobile: PropTypes.bool,
};

SingleProgressBar.defaultProps = {
  active: false,
  description: '',
  toolTipRight: false,
  isResuming: false,
  completionPoints: 0,
  showContentTooltip: true,
  isMobile: false,
};
