import * as React from 'react';
import useMeasure from 'react-use-measure';
import { createScale } from '@visx/scale';
import { Text } from '@visx/text';
import { Group } from '@visx/group';
import { BarRounded } from '@visx/shape';
import { AnyScaleBand } from '@visx/shape/lib/types';
import { AxisBottom, AxisScale } from '@visx/axis';
import { useTheme, useThemeRawValues } from '@leagueplatform/genesis-core';
import { getAxisTicks } from '../core/bar-graph/utils/get-axis-ticks';

type HorizontalInsightBarProps = {
  timeframe: string;
  value: number;
  xScale: AxisScale;
  yScale: AnyScaleBand;
  displayValue: string;
  barColor: string;
  label: string;
};

function HorizontalInsightBarV2({
  timeframe,
  value,
  displayValue,
  barColor,
  label,
  xScale,
  yScale,
}: HorizontalInsightBarProps) {
  const rawTheme = useThemeRawValues();
  const theme = useTheme();

  return (
    <Group top={yScale(timeframe)?.valueOf()}>
      <Text
        verticalAnchor="start"
        x={1}
        y={0}
        style={{
          fontWeight: theme.fontWeights.bodyTwo,
          fontSize: theme.fontSizes.bodyTwo,
          fill: theme.colors.onSurfaceTextSubdued,
        }}
      >
        {label}
      </Text>
      <Text
        verticalAnchor="start"
        x={1}
        y={24}
        style={{
          fontWeight: theme.fontWeights.headingThree,
          fontSize: theme.fontSizes.headingThree,
          fill: theme.colors.onSurfaceTextPrimary,
        }}
      >
        {displayValue}
      </Text>
      {value === 0 ? null : (
        <BarRounded
          width={xScale(value)?.valueOf() ?? 0}
          x={0}
          y={50}
          height={rawTheme.sizes.threeQuarters}
          radius={rawTheme.radii.extraLarge}
          top // radius top
          bottom // radius bottom
          strokeWidth={theme.borderWidths.thick}
          fill={barColor}
          style={{
            // Adds radius to the outline when focused.
            borderTopLeftRadius: theme.borderWidths.thick,
            borderTopRightRadius: theme.borderWidths.thick,
            borderBottomLeftRadius: theme.borderWidths.thick,
            borderBottomRightRadius: theme.borderWidths.thick,
          }}
        />
      )}
    </Group>
  );
}

export type HorizontalBarGraphItem = {
  barColor: string;
  value: number;
  label: string;
  displayValue: string;
};

export type InsightCardHorizontalBarGraphProps = {
  title: string;
  description: string;
  items: HorizontalBarGraphItem[];
};

// The height needs to be constrained instead of being responsive.  These constants define the
// expected height for each chart part.
const insightBarHeight = 90;
const axisBottomHeight = 20;

// Adds margin between the svg elements and svg container.
const margin = {
  right: 50,
  bottom: 20,
};

export function InsightCardHorizontalBarGraphV2({
  title,
  description,
  items,
}: InsightCardHorizontalBarGraphProps) {
  const theme = useTheme();
  const largestValue = Math.max(...items.map(({ value }) => value));
  const ticks = getAxisTicks(largestValue);
  const shouldAbbreviateTicks = !ticks.every((t) => t < 1000);
  const totalChartHeight = insightBarHeight * items.length + axisBottomHeight;

  // Make the chart dynamically fit its container.
  const [ref, bounds] = useMeasure();
  const { width, height } = bounds;

  const chartWidth = width - margin.right;
  const chartHeight = height - margin.bottom;
  const xScale = createScale({
    type: 'linear',
    domain: [0, ticks[ticks.length - 1]],
    range: [0, chartWidth],
  });

  const yScale = createScale({
    type: 'band',
    domain: items.map((f) => f.label),
    range: [0, chartHeight],
  });

  return (
    <svg
      ref={ref}
      width="100%"
      height={totalChartHeight}
      viewBox={`0 0 ${width} ${height}`}
    >
      <title>{title}</title>
      <desc>{description}</desc>
      {items.map(({ label, value, displayValue, barColor }) => (
        <HorizontalInsightBarV2
          key={label}
          timeframe={label}
          value={value}
          xScale={xScale}
          yScale={yScale}
          displayValue={displayValue}
          barColor={barColor}
          label={label}
        />
      ))}
      <AxisBottom
        scale={xScale}
        top={chartHeight}
        tickFormat={(value) => {
          if (shouldAbbreviateTicks && value !== 0) {
            return `${value.valueOf() / 1000}k`;
          }
          return `${value.valueOf()}`;
        }}
        tickValues={ticks}
        hideAxisLine
        hideTicks
        tickLabelProps={(_value, index) => ({
          fontSize: theme.fontSizes.bodyTwo,
          fill: theme.colors.onSurfaceTextPrimary,
          textAnchor: index === 0 ? 'start' : 'middle',
        })}
        labelProps={{
          fill: theme.colors.onSurfaceTextPrimary,
          fontSize: theme.fontSizes.headingThree,
          textAnchor: 'middle',
          fontWeight: 'bold',
        }}
      />
    </svg>
  );
}
