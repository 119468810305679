import React from 'react';
import { Activity } from 'constants/types';
import { useIntl } from '@leagueplatform/locales';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  REWARDS_EVENT_ANALYTICS,
  TOAST_ACTIONS,
} from 'constants/analytics.constants';
import { CloseButton } from 'components/close-button/close-button.view';
import { Box, styled, TextAction } from '@leagueplatform/genesis-core';
import { BadgeProgress } from '../badge-progress/badge-progress.view';

interface ProgressBannerProps {
  activities: Activity[];
  onlyProgress: boolean;
  showMoreActivities: Function;
  onClose: Function;
}

const ToastButton = styled(TextAction, {
  display: 'inline-block',
  borderTopWidth: '$thin',
  borderTopStyle: 'solid',
  borderTopColor: '$interactiveBorderDisabled',
  width: '100%',
  padding: '$half',
  textAlign: 'center',
  '&:hover, &:active, &:focus': {
    backgroundColor: '$interactiveBackgroundHovered',
    color: '$interactiveActionHovered',
    textDecoration: 'none',
  },
});

const AbsoluteWrapper = styled(Box, {
  position: 'absolute',
  right: '$quarter',
  top: '$quarter',
});

const BannerContainer = styled(Box, {
  position: 'relative',
  backgroundColor: '$warningBackgroundSecondary',
  width: '330px',
  boxShadow: '$card',
});

export const ToastProgressBanner = ({
  onlyProgress,
  activities,
  showMoreActivities,
  onClose,
}: ProgressBannerProps) => {
  const { formatMessage } = useIntl();

  const handleSeeMoreAchievements = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
      screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_TOAST,
      detail: TOAST_ACTIONS.SEE_MORE,
      num_of_milestone_rewards: activities.length,
    });
    showMoreActivities();
  };

  return (
    <BannerContainer
      css={{
        borderRadius: onlyProgress ? '$large' : '$none $none $large $large',
      }}
    >
      <BadgeProgress
        imageUrl={activities[0]?.image.large}
        completed={activities[0]?.progress.is_completed}
        title={activities[0]?.name}
        isLoading={false}
        currentActivity={activities[0]?.progress.current}
        totalActivities={activities[0]?.progress.threshold}
        progressTitle={`${activities[0]?.progress.current}/${
          activities[0]?.progress.threshold
        } ${formatMessage({ id: 'ACTIVITIES' })}`}
      />
      {activities.length > 1 && (
        <ToastButton onClick={handleSeeMoreAchievements}>
          {formatMessage({ id: 'SEE_ALL_ACHIEVEMENTS' })}
        </ToastButton>
      )}
      {onlyProgress && (
        <AbsoluteWrapper>
          <CloseButton onClose={onClose} activitiesLength={activities.length} />
        </AbsoluteWrapper>
      )}
    </BannerContainer>
  );
};
