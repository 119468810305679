import { useQuery } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import {
  DeserialisedUserChallengeRanksResponseData,
  getIndividualUserRank,
} from '@leagueplatform/health-journey-api';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';

export const INDIVIDUAL_USER_RANK_QUERY_KEY = 'getIndividualUserRankData';

export const useGetIndividualUserRank = () => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { data: userData } = useGetUserProfile();
  return useQuery<DeserialisedUserChallengeRanksResponseData, Error>(
    [INDIVIDUAL_USER_RANK_QUERY_KEY, challengeId],
    () => getIndividualUserRank(challengeId, userData?.user_id),
    { enabled: !!userData?.user_id },
  );
};
