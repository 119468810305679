// MASONRY CONSTANTS
// APP IDS & MODULE KEY
export const HEALTH_JOURNEY_MASONRY_VERSION = 0;
export const HEALTH_JOURNEY_MODULE_KEY = 'health_journey';
// TODO: update const name of first tab app id once we have an official name
export const JOURNEY_TO_DO_TAB_APP_ID = 'journey_to_do_tab';
export const JOURNEY_PROGRESS_TAB_APP_ID = 'journey_up_next_tab';
export const HEALTH_ASSESSMENT_REPORT = 'health-assessment-report';

// CUSTOM WIDGET TYPES
export const ACHIEVEMENTS_WIDGET = 'recent_user_achievements';
export const CHALLENGES_LIST_WIDGET =
  'challenge_campaign_progress_activity_stack';
export const COMPLETED_ACTIVITIES_CAROUSEL_WIDGET =
  'get_completed_user_health_activities';
export const MISSED_ACTIVITIES_CAROUSEL_WIDGET =
  'get_missed_user_health_activities';
export const PRIORITY_ACTIVITIES_LIST_WIDGET =
  'get_active_user_health_activities_by_priority';
export const PRIORITY_ACTIVITIES_ACTIVITY_STACK_WIDGET = 'activity_stack';
export const PROGRAMS_LIST_WIDGET = 'optin_campaign_progress_activity_stack';
export const STANDALONE_ACTIVITIES_WIDGET =
  'get_active_standalone_activities_by_priority';
export const CHALLENGES_AND_PROGRAMS_LIST_WIDGET =
  'programs_and_challenges_progress_stack';
export const REWARD_PROGRAM_CAROUSEL = 'reward_programs_carousel';

export const CONFIGURABLE_JOURNEY_PAGE_MAX_WIDTH = '984px';
