import {
  Box,
  css,
  genesisStyled,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import * as React from 'react';

type ArrowButtonProps = {
  direction: 'left' | 'right';
  onClick: () => void;
  disabled?: boolean;
  ariaLabel: string;
};

const StyledArrowButton = genesisStyled(SubtleButton)(
  css({
    borderRadius: 'circle',
    height: 'two',
    paddingLeft: '0',
    paddingRight: '0',
    width: 'two',
  }),
);

const ArrowContainer = genesisStyled(Box)(
  css({
    as: 'span',
    display: 'block',
    marginTop: '-0.225em',
  }),
);

export const ArrowButton = ({
  ariaLabel,
  direction,
  onClick,
  disabled,
}: ArrowButtonProps) => {
  const arrowIcon =
    direction === 'left' ? ICONS.LEFT_CHEVRON : ICONS.RIGHT_CHEVRON;

  return (
    <Box>
      <StyledArrowButton
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={onClick}
      >
        <ArrowContainer>
          <Icon size={16} icon={arrowIcon} />
        </ArrowContainer>
      </StyledArrowButton>
    </Box>
  );
};
