import { getStringWidth } from '@visx/text';
import { useIntl } from '@leagueplatform/locales';

// Calculates the pixel width of the formatted yMax value and determine it's offset.
export function useGetLeftAxisLabelOffset(yMax: number) {
  const { formatNumber } = useIntl();

  // 36 is the default offset for left axis in visx.
  return (getStringWidth(formatNumber(yMax)) ?? 0) + 36;
}
