import * as React from 'react';
import {
  StackLayout,
  GDSStackLayoutProps,
  GDSHeadingLevel,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Traits } from '@leagueplatform/masonry-api';
import { SectionHeader } from 'components/section-header/section-header.component';
import { GridContainer } from '../../common/grid-container/grid-container';

export interface StackWidgetWrapperProps {
  gridColumnCount?: number;
  heading?: string;
  headingLevel?: GDSHeadingLevel;
  body?: string;
  orientation: GDSStackLayoutProps['orientation'] | 'grid';
  traits?: Traits;
}

type StackOrGridProps = {
  orientation: GDSStackLayoutProps['orientation'] | 'grid';
  gridColumnCount?: number;
};

function StackOrGrid({
  gridColumnCount,
  orientation,
  children,
}: React.PropsWithChildren<StackOrGridProps>) {
  if (orientation === 'grid') {
    return (
      <GridContainer gridColumnCount={gridColumnCount}>
        {children}
      </GridContainer>
    );
  }
  return (
    <StackLayout
      spacing="$one"
      horizontalAlignment="stretch"
      verticalAlignment="stretch"
      orientation={orientation}
    >
      {children}
    </StackLayout>
  );
}

export const StackWidgetWrapper = ({
  children,
  gridColumnCount,
  heading,
  headingLevel,
  body,
  orientation,
  traits,
}: React.PropsWithChildren<StackWidgetWrapperProps>) => {
  if (!children) return null;

  return (
    <StackLayout
      spacing="$one"
      orientation="vertical"
      horizontalAlignment="stretch"
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingLevel={headingLevel}
          traits={traits}
        />
      )}
      {body && <ParagraphText>{body}</ParagraphText>}
      <StackOrGrid orientation={orientation} gridColumnCount={gridColumnCount}>
        {children}
      </StackOrGrid>
    </StackLayout>
  );
};
