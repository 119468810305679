import { useMemo } from 'react';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { getContentComponent } from 'pages/wallet-detail/utils/get-content-component.util';
import { useWalletDetailQuery } from './use-wallet-detail-query.hook';
import { useWalletDetailTabsQuery } from './use-wallet-detail-tabs-query.hook';

export function useWalletTabsContentQuery(
  userId: string | undefined,
  benefitId?: string,
) {
  const { data: benefitCard } = useWalletDetailQuery(userId, benefitId);
  const { data: tabs } = useWalletDetailTabsQuery(userId, benefitId);
  // TODO: Remove this when policy information is confirmed to not need a flag
  const { data: isNonActivePolicyInformationTabHidden } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_NON_ACTIVE_POLICY_INFORMATION_HIDDEN,
  );

  const contentPanels = useMemo(
    () =>
      tabs.map((data) =>
        getContentComponent(
          data,
          benefitCard?.status,
          isNonActivePolicyInformationTabHidden,
        ),
      ),
    [tabs, benefitCard?.status, isNonActivePolicyInformationTabHidden],
  );

  return contentPanels;
}
