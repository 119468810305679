import React, { FC, forwardRef } from 'react';
import { styled } from '../../theme';
import type {
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSImagePosition,
} from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { focusOutlineOuter } from '../../theme/utils/focus-outline';

export const IMAGE_MAX_WIDTH = '350px';

export interface GDSCardClickAreaProps extends GDSCustomizableComponent {
  onCardMouseUp: (event: React.SyntheticEvent) => void;
  onCardMouseDown: (event: React.SyntheticEvent) => void;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  imagePosition?: GDSResponsiveProp<GDSImagePosition>;
}

export const ImagePositionWrapper = styled('div', {
  borderRadius: '$card',
  display: 'flex',
  overflow: 'hidden',

  variants: {
    imagePosition: {
      top: {
        flexDirection: 'column',
        alignItems: 'stretch',
      },
      start: {
        flexDirection: 'row',
        '& img': {
          maxWidth: IMAGE_MAX_WIDTH,
        },
      },
      end: {
        flexDirection: 'row-reverse',
        '& img': {
          maxWidth: IMAGE_MAX_WIDTH,
        },
      },
    },
  },
});

const BaseClickArea = styled(ImagePositionWrapper, {
  border: '$borderWidths$card solid $onSurfaceBorderCard',
  boxShadow: '$card',
  borderRadius: '$card',
  overflow: 'hidden',
  flex: '1',
  '&:hover': {
    cursor: 'pointer',
    border: '$borderWidths$card solid $colors$interactiveFocusOuter',
    'a:hover, button:hover': {
      textDecoration: 'none',
    },
  },
  '& [class*="GDS"]:focus': {
    outline: 'none',
  },
  '& [class*="GDS"]:focus-visible': {
    ...focusOutlineOuter,
  },
});

export const CardClickArea: FC<GDSCardClickAreaProps> = forwardRef(
  (
    {
      children,
      className,
      css,
      imagePosition = 'top',
      onCardMouseDown,
      onCardMouseUp,
      ...props
    }: GDSCardClickAreaProps,
    ref,
  ) => {
    const cardImagePosition = useResponsiveProp(imagePosition);

    return (
      <BaseClickArea
        ref={ref}
        className={['GDS-card-click-area', className].join(' ')}
        onMouseDown={onCardMouseDown}
        onMouseUp={onCardMouseUp}
        imagePosition={cardImagePosition}
        css={css}
        {...props}
      >
        {children}
      </BaseClickArea>
    );
  },
);
