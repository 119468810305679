import { useQuery } from 'react-query';
import {
  getAvatars,
  CHALLENGE_TYPES,
  AvatarDeserialisedData,
} from '@leagueplatform/health-journey-api';

export const GET_AVATARS_QUERY_KEY = 'getAvatarsData';

export const useGetAvatars = (avatarType: CHALLENGE_TYPES) =>
  useQuery<AvatarDeserialisedData, Error>(
    [GET_AVATARS_QUERY_KEY, avatarType],
    () => getAvatars(avatarType),
  );
