{
  "dragDescriptionKeyboard": "Tryck på enter för att börja dra.",
  "dragDescriptionKeyboardAlt": "Tryck på Alt + Retur för att börja dra.",
  "dragDescriptionLongPress": "Tryck länge för att börja dra.",
  "dragDescriptionTouch": "Dubbeltryck för att börja dra.",
  "dragDescriptionVirtual": "Klicka för att börja dra.",
  "dragItem": "Dra {itemText}",
  "dragSelectedItems": "Dra {count, plural, one {# valt objekt} other {# valda objekt}}",
  "dragSelectedKeyboard": "Tryck på Retur för att dra {count, plural, one {# markerat objekt} other {# markerade objekt}}.",
  "dragSelectedKeyboardAlt": "Tryck på Alt + Retur för att dra {count, plural, one {# markerat objekt} other {# markerade objekt}}.",
  "dragSelectedLongPress": "Tryck länge för att dra {count, plural, one {# markerat objekt} other {# markerade objekt}}.",
  "dragStartedKeyboard": "Börja dra. Tryck på tabb för att navigera till målet, tryck på enter för att släppa eller på escape för att avbryta.",
  "dragStartedTouch": "Börja dra. Navigera till ett mål och dubbeltryck för att släppa.",
  "dragStartedVirtual": "Börja dra. Navigera till ett mål och klicka eller tryck på enter för att släppa.",
  "dropCanceled": "Släppåtgärd avbröts.",
  "dropComplete": "Släppåtgärd klar.",
  "dropDescriptionKeyboard": "Tryck på enter för att släppa. Tryck på escape för att avbryta dragåtgärd.",
  "dropDescriptionTouch": "Dubbeltryck för att släppa.",
  "dropDescriptionVirtual": "Klicka för att släppa.",
  "dropIndicator": "släppindikator",
  "dropOnItem": "Släpp på {itemText}",
  "dropOnRoot": "Släpp på",
  "endDragKeyboard": "Drar. Tryck på enter för att avbryta dragåtgärd.",
  "endDragTouch": "Drar. Dubbeltryck för att avbryta dragåtgärd.",
  "endDragVirtual": "Drar. Klicka för att avbryta dragåtgärd.",
  "insertAfter": "Infoga efter {itemText}",
  "insertBefore": "Infoga före {itemText}",
  "insertBetween": "Infoga mellan {beforeItemText} och {afterItemText}"
}
