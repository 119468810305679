import type {
  StringTokens,
  NumberTokens,
  StringOrNumberTokens,
} from '../theme-types';

export const lowercaseFirstLetter = (string: string) =>
  string.slice(0, 1).toLowerCase() + string.slice(1);
export const uppercaseFirstLetter = (string: string) =>
  string.slice(0, 1).toUpperCase() + string.slice(1);

export const toUnit = (input: StringOrNumberTokens, unit: string) => {
  const converted: StringOrNumberTokens = {};
  Object.keys(input).forEach((key) => {
    if (typeof input[key] === 'string') {
      const valueAsString = String(input[key]);
      converted[key] =
        valueAsString.indexOf('$') === 0
          ? valueAsString
          : `${input[key]}${unit}`;
    } else {
      const valueAsNumber = Number(input[key]);
      converted[key] =
        valueAsNumber === 0 ? valueAsNumber : `${input[key]}${unit}`;
    }
  });
  return converted;
};

export const toPx = (input: StringOrNumberTokens): StringTokens =>
  toUnit(input, 'px') as StringTokens;
export const toRem = (input: StringOrNumberTokens): StringTokens =>
  toUnit(input, 'rem') as StringTokens;

/**
 * Converts nested tokens as defined in GDSTheme into a single key camelCase, ex:
 *
 *  {
 *     onSurface: {
 *        text: {
 *          primary: "#212121",
 *        }
 *     }
 *  }
 *
 *  becomes:
 *
 *  onSurfaceTextPrimary: "#212121"
 *
 */

export const flattenNestedTokens = (input: { [key: string]: any } = {}) => {
  const result: StringTokens = {};
  function getTokenPath(haystack: { [key: string]: any }, path: string[] = []) {
    Object.keys(haystack).forEach((key: string) => {
      const value: any = haystack[key];

      if (typeof value === 'string' || Number.isFinite(value)) {
        const nestedKey = [...path, key]
          .map((part: string, index: number) =>
            index === 0
              ? lowercaseFirstLetter(part)
              : uppercaseFirstLetter(part),
          )
          .join('');
        result[nestedKey] = value;
      } else {
        getTokenPath(value, [...path, key]);
      }
    });
  }

  getTokenPath(input);
  return result;
};

export const getNegativeSpacing = (spacing: NumberTokens) => {
  const negativeSpacing: NumberTokens = {};
  Object.keys(spacing).forEach((spacingKey: string) => {
    const spacingValue = spacing[spacingKey];
    if (spacingValue !== 0 && !`${spacingValue}`.includes('$')) {
      negativeSpacing[`minus${uppercaseFirstLetter(spacingKey)}`] =
        spacingValue * -1;
    }
  });

  return negativeSpacing;
};

/* Defined using https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */
export type FontWeightToken = keyof typeof fontWeightsByName;
const fontWeightsByName: { [key: string]: number } = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
  extrablack: 950,
};

const fontWeightNameAliases: {
  [key: string]: keyof typeof fontWeightsByName;
} = {
  normal: 'regular',
  heavy: 'black',
  ultraheavy: 'extrablack',
};

/* This converts the figma font value to a css font-weight number value based on the above table */
export const getFontWeightNumberByName = (name: string): number => {
  const formattedName = name.replace(/[^a-zA-Z]*/gi, '').toLowerCase();
  // check if the formattedName is a key in fontWeightsByName else fallback to checking aliases.
  const fontWeightToken = (
    formattedName in fontWeightsByName
      ? formattedName
      : fontWeightNameAliases[formattedName]
  ) as keyof typeof fontWeightsByName;
  return fontWeightsByName[fontWeightToken];
};

export const getFontWeightTokenByNumber = (
  fontWeight: number,
): FontWeightToken => {
  let fontWeightToken: FontWeightToken = 'regular'; // if all else fails, return this as default
  Object.entries(fontWeightsByName).forEach(
    ([fontWeightKey, fontWeightValue]: [string, number]) => {
      if (fontWeightValue === fontWeight) {
        fontWeightToken = fontWeightKey;
      }
    },
  );
  return fontWeightToken;
};
