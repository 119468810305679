import React, { forwardRef } from 'react';
import { TextAction } from '@leagueplatform/genesis-core';
import { normalizeInternalUrl } from '@leagueplatform/web-common';
import { InternalOrExternalLinkButton } from '../internal-or-external-link-button/internal-or-external-link-button.component';

export interface ConditionalLinkProps {
  url?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}

export const ConditionalLink = forwardRef<
  HTMLAnchorElement | null,
  ConditionalLinkProps
>(({ url, children, onClick = () => {}, ...linkProps }, ref) => {
  if (url) {
    const href = normalizeInternalUrl(url);
    return (
      <InternalOrExternalLinkButton
        baseComponent={TextAction}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
        ref={ref}
        href={href}
        css={{
          '&&': {
            all: 'inherit',
          },
          '&:focus': {
            outline: 'unset !important',
          },
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...linkProps}
      >
        {children}
      </InternalOrExternalLinkButton>
    );
  }
  return children as React.ReactElement;
});
