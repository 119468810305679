/**
 * A useful hook for writing custom modals. The props returned by this hook will
 * give context to screen reader users that a particular element is a modal.
 * Example usage:
 * ```js
 * const props = useModalA11yProps('Useful label');
 * return (
 *   <div {...a11yProps}>
 *     <h1>Not helpful label</h1>
 *   </div>
 * );
 * ```
 *
 * Please note that if the Modal has a proper label, we should use
 * `aria-labelledby` instead. For example:
 *
 * ```js
 * const props = useModalA11yProps();
 * return (
 *   <div {...a11yProps} aria-labelledby="label-id">
 *     <h1 id="label-id">Helpful label</h1>
 *   </div>
 * );
 * ```
 *
 * @param {String} [label] - Sets the `aria-label` prop to this value
 * @returns {Object} - Props that should be spread on an element to turn it into an accessible dialog
 */
export const useModalA11yProps = (label) => {
  const modalAriaProps = {
    role: 'dialog',
    'aria-modal': 'true',
  };

  if (label) {
    modalAriaProps['aria-label'] = label;
  }

  return modalAriaProps;
};
