// Strings to be used in Genesis Core Components
export const GDS_STRINGS = {
  clear: 'Clear',
  close: 'Close',
  disclosure: '',
  dismiss: 'Dismiss',
  externalLink: 'opens an external link',
  loadingText: 'Loading',
  newTabInternal: 'opens in a new tab',
  newWindowInternal: 'opens in new window',
  next: 'Next',
  optional: 'optional',
  previous: 'Previous',
};

// Strings to be used Genesis Core Alpha Components
export const GDS_CORE_ALPHA_STRINGS = {
  paginationPage: 'Page',
  paginationLabel: 'Pagination',
  paginationLabelNextPage: 'Next Page',
  paginationLabelPrevPage: 'Previous Page',
  paginationLabelFirstPage: '(First Page)',
  paginationLabelLastPage: '(Last page)',
};

export type GDSStrings = typeof GDS_STRINGS;

export const getThemeStrings = (customStrings?: object): GDSStrings => ({
  ...GDS_STRINGS,
  ...GDS_CORE_ALPHA_STRINGS,
  ...customStrings,
});
