import * as React from 'react';
import {
  HeadingText,
  Icon,
  ParagraphText,
  StackLayout,
  styled,
  UtilityText,
} from '@leagueplatform/genesis-core';

import { HtmlToReact } from '@leagueplatform/web-common';
import {
  ConditionalLink,
  ConditionalLinkCard,
} from '@leagueplatform/web-common-components';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { WidgetType } from '@leagueplatform/masonry-api';

type Measurement = {
  unit?: string;
  value: string;
};

export type MetricCardWidgetProps = {
  action?: string;
  body?: string;
  heading: string;
  image?: string;
  imageAltText?: string;
  measurements?: Measurement[];
  onCardClick?: (e: React.MouseEvent) => void;
  timestamp?: string;
};

export type MetricCardWSNode = MasonryEngineNode<
  WidgetType.METRIC_CARD,
  MetricCardWidgetProps
>;

const StyledImage = styled('img', {
  contentFit: 'cover',
  contentPosition: 'center',
  height: '$three',
  width: '$three',
});

export function MetricCardWidget({
  action,
  body,
  heading,
  image,
  imageAltText,
  measurements,
  onCardClick,
  timestamp,
}: MetricCardWidgetProps) {
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  // using double ! operator to get explicit booleans, rather than just truthy/falsy values
  const displayMeasurements = !!measurements?.length;
  // display body if: no `measurements` exist AND `body` exists
  const displayBody = !displayMeasurements && !!body;
  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(action)}
      cardStyle={{
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 'oneAndHalf',
      }}
    >
      <StackLayout
        orientation="horizontal"
        spacing="$threeQuarters"
        verticalAlignment="center"
      >
        {image && <StyledImage src={image} alt={imageAltText} />}
        <StackLayout
          css={{
            width: '100%',
          }}
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
        >
          <StackLayout
            css={{
              width: '100%',
            }}
            spacing="$quarter"
            verticalAlignment="spaceBetween"
          >
            <HeadingText size="sm" level="3" color="$onSurfaceTextPrimary">
              <ConditionalLink ref={linkRef} url={action} onClick={onCardClick}>
                {heading}
              </ConditionalLink>
            </HeadingText>
            {displayMeasurements ? (
              <StackLayout orientation="horizontal">
                <ParagraphText
                  data-testid="measurement"
                  css={{
                    marginRight: '$threeQuarters',
                    width: '100%',
                    '&&': {
                      marginBlockEnd: '$none',
                    },
                  }}
                >
                  {measurements.map(({ value, unit }) => (
                    <React.Fragment key={`${value}${unit}`}>
                      <UtilityText
                        css={{
                          paddingRight: '$quarter',
                          typography: '$headingThree',
                        }}
                      >
                        {value}
                      </UtilityText>
                      {unit && (
                        <UtilityText
                          css={{
                            typography: '$label',
                            color: '$onSurfaceTextSubdued',
                            paddingRight: '$quarter',
                          }}
                        >
                          {unit}{' '}
                        </UtilityText>
                      )}
                    </React.Fragment>
                  ))}
                </ParagraphText>
              </StackLayout>
            ) : null}
            {displayBody ? (
              <UtilityText
                css={{
                  typography: '$caption',
                  color: '$onSurfaceTextSubdued',
                }}
              >
                <HtmlToReact htmlString={body} />
              </UtilityText>
            ) : null}
          </StackLayout>

          {timestamp && (
            <ParagraphText
              css={{
                typography: '$label',
                color: '$onSurfaceTextSubdued',
              }}
            >
              {timestamp}
            </ParagraphText>
          )}
        </StackLayout>
        {action ? (
          <Icon
            size={24}
            icon="interfaceChevronRight"
            tint="$onSurfaceIconPrimary"
          />
        ) : null}
      </StackLayout>
    </ConditionalLinkCard>
  );
}
