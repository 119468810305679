import React from 'react';
import DOMPurify from 'dompurify';
import { transformAnchorTag } from '@leagueplatform/web-common';
import { CardBodyBox } from './card-body-box.component';

export interface CardBodyProps {
  body?: string;
}

export const CardBody = ({ body = '' }: CardBodyProps) => {
  if (!body) return null;
  DOMPurify.addHook('afterSanitizeAttributes', transformAnchorTag);

  const cleanHTML = DOMPurify.sanitize(body, {
    FORCE_BODY: true,
    ALLOWED_ATTR: ['target', 'class', 'href', 'role'],
  });

  DOMPurify.removeHook('afterSanitizeAttributes');

  return (
    <CardBodyBox
      dangerouslySetInnerHTML={{
        __html: cleanHTML,
      }}
    />
  );
};
