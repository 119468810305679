import { useQuery } from 'react-query';
import { captureError } from '@leagueplatform/observability';
import {
  getHealthSources,
  HealthSourcesQueryParameters,
} from '@leagueplatform/dashboard-api';

export function useHealthSources(params: HealthSourcesQueryParameters) {
  return useQuery(['health-sources', params], () => getHealthSources(params), {
    onError(error) {
      captureError(new Error(`Unable to fetch health sources: ${error}`));
    },
  });
}
