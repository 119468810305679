import { deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  HealthLiteracyResponseData,
  HealthLiteracyDeserialisedData,
} from './types';

const HEALTH_LITERACY_PATH = 'v1/health-literacy';

export async function getHealthLiteracy(dataType: string) {
  const response = await leagueFetch(`${HEALTH_LITERACY_PATH}/${dataType}`);

  const responseJson: HealthLiteracyResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getHealthLiteracy error: ${response.status}`);
  }
  return deserialise(responseJson) as HealthLiteracyDeserialisedData;
}
