import { captureError } from '@leagueplatform/observability';
import { logError } from './utils/log';
import {
  MasonryNodeMessageReceiver,
  MasornyNodeMessages,
} from './types/masonry-engine-message.types';
import { MasonryEngineNode } from './types/masonry-engine-node.types';
import { getMasonryEngineObservabilityContext } from './utils/masonry-engine-observability-content';

/**
 * An object that allows nodes to register their `MasonryNodeMessageReceiver`,
 * and allows actions to send messages to those receivers.
 */
export type MasonryEngineMessagingController = {
  registerReceiver: (
    nodeId: MasonryEngineNode['id'],
    receiver: MasonryNodeMessageReceiver,
  ) => void;
  unregisterReceiver: (nodeId: MasonryEngineNode['id']) => void;
  sendMessage: <Message extends MasornyNodeMessages = MasornyNodeMessages>(
    nodeId: MasonryEngineNode['id'],
    message: Message,
  ) => Promise<void>;
};

export const createMessagingController =
  (): MasonryEngineMessagingController => {
    const nodeMessageReceiverMap = new Map<
      MasonryEngineNode['id'],
      MasonryNodeMessageReceiver
    >();

    return {
      registerReceiver: (nodeId, receiver) => {
        nodeMessageReceiverMap.set(nodeId, receiver);
      },
      unregisterReceiver: (nodeId) => {
        nodeMessageReceiverMap.delete(nodeId);
      },
      sendMessage: (nodeId, message) => {
        const receiver = nodeMessageReceiverMap.get(nodeId);

        if (receiver) {
          return receiver(message);
        }
        logError(`Could not find message receiver for node ${nodeId}`);
        captureError(
          new Error(
            `MasonryEngine - Could not find message receiver for node ${nodeId}`,
          ),
          getMasonryEngineObservabilityContext({}),
        );

        return Promise.resolve();
      },
    };
  };
