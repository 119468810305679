import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useGetIndividualUserRank } from 'hooks/use-get-invidual-user-rank.hook';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';

export const YourRanking = () => {
  const { formatMessage } = useIntl();
  const { data: userRankData } = useGetIndividualUserRank();
  const rank = userRankData?.data[0]?.rank;

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      css={{
        backgroundColor: '$surfaceBackgroundSecondary',
        borderRadius: '$medium',
        padding: '$threeQuarters $one',
      }}
    >
      <UtilityText>{formatMessage({ id: 'YOUR_RANKING' })}</UtilityText>
      <UtilityText emphasis="emphasized">
        {rank ? formatMessage({ id: 'XTH_PLACE' }, { currentRank: rank }) : '-'}
      </UtilityText>
    </StackLayout>
  );
};
