/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BodyOne,
  Flex,
  FlexProps,
  genesisStyled,
  CoverImage,
} from '@leagueplatform/genesis-commons';

interface AvatarProps extends FlexProps {
  imageSrc?: string;
  userInitials?: string;
  altText?: string;
}

const UserInitials = genesisStyled(BodyOne)`
  text-transform: uppercase;
`;

export const Avatar = ({
  imageSrc,
  userInitials,
  altText = '',
  fontSize = 'body1',
  ...props
}: AvatarProps) => (
  <Flex
    overflow="hidden"
    borderRadius="circle"
    width="four"
    height="four"
    alignItems="center"
    justifyContent="center"
    borderColor="interactive.border.disabled"
    borderWidth={imageSrc ? 'none' : 'thin'}
    borderStyle="solid"
    backgroundColor="surface.card.primary"
    {...props}
  >
    {imageSrc ? (
      <CoverImage
        src={imageSrc}
        alt={altText}
        size="100%"
        role="presentation"
      />
    ) : (
      userInitials && (
        <UserInitials aria-hidden="true" fontSize={fontSize}>
          {userInitials}
        </UserInitials>
      )
    )}
  </Flex>
);
