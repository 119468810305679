import * as React from 'react';
import { BarRounded as VisxBarRounded } from '@visx/shape';
import { useTheme } from '@leagueplatform/genesis-commons';

export type BarProps = Omit<
  React.ComponentProps<typeof VisxBarRounded>,
  'radius' | 'stroke' | 'strokeDasharray' | 'strokeWidth'
> & {
  highlight?: boolean;
  radius: number;
};

/**
 * Wraps the visx Bar component and applies genesis styles.
 */
export function Bar({ highlight, radius, ...props }: BarProps) {
  const theme = useTheme();
  return (
    <VisxBarRounded
      top // radius top
      radius={radius}
      strokeDasharray="3 3"
      strokeWidth={theme.borderWidths.thick}
      fill={theme.colors.decorative.brand.primary.dark}
      style={{
        // Adds radius to the outline when focused.
        borderTopLeftRadius: radius,
        borderTopRightRadius: radius,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
