import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, BodyTwoSecondary } from '@leagueplatform/genesis-commons';
import { Team } from '@leagueplatform/health-journey-api';
import { TeamListItem } from '../teams-list-item/teams-list-item.component';

export interface TeamsListProps {
  teamsList: Team[];
}

export const TeamsList = ({ teamsList }: TeamsListProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box aria-live="polite" width="100%">
      <BodyTwoSecondary marginBottom="half">
        {formatMessage({ id: 'RESULT_COUNT' }, { count: teamsList.length })}
      </BodyTwoSecondary>
      <Box as="ul">
        {
          // Returning a new array based on a search term match
          teamsList.map((team: Team) => {
            const { id, name, avatarUrl, maxMembers, memberCount } = team;
            return (
              <TeamListItem
                teamId={id}
                teamName={name}
                avatar={avatarUrl}
                maxMembers={maxMembers}
                memberCount={memberCount}
                key={id}
              />
            );
          })
        }
      </Box>
    </Box>
  );
};
