import { AnnualPeriod, PlanEnrollment } from '@leagueplatform/user-profile-api';

export function getIsInNewHireEnrollment(
  isInEnrollment: boolean,
  enrollmentEndDate: Date,
  annualEnrollmentPeriod: AnnualPeriod,
  planEnrollment: PlanEnrollment,
) {
  const newHireEnrollment = Object.values(planEnrollment).some(
    ({ enrollmentType: type }) => type === 'new_hire',
  );

  if (Object.values(planEnrollment).length > 0) {
    return newHireEnrollment;
  }

  if (!isInEnrollment || !enrollmentEndDate) {
    return false;
  }
  if (isInEnrollment && enrollmentEndDate && !annualEnrollmentPeriod) {
    return true;
  }

  const annualEnrollmentStartDate = new Date(annualEnrollmentPeriod.start_date);
  const annualEnrollmentEndDate = new Date(annualEnrollmentPeriod.end_date);
  const now = new Date();

  if (now > annualEnrollmentStartDate && now < annualEnrollmentEndDate) {
    return false;
  }

  return (
    isInEnrollment &&
    (enrollmentEndDate.getUTCFullYear() !==
      annualEnrollmentEndDate.getUTCFullYear() ||
      enrollmentEndDate.getUTCMonth() !==
        annualEnrollmentEndDate.getUTCMonth() ||
      enrollmentEndDate.getUTCDay() !== annualEnrollmentEndDate.getUTCDay())
  );
}
