import * as React from 'react';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  MoreOptionsLink,
} from '@leagueplatform/health-profile-common';
import {
  Caption,
  Flex,
  HeadingOne,
  SecondaryButton,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  LoadingIndicator,
  Sidebar,
} from '@leagueplatform/web-common-components';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { MetricSource, MetricType } from '@leagueplatform/dashboard-api';
import {
  useDataTypeConfigMap,
  isSupportedUnitMetricType,
} from 'constants/use-data-type-config';
import { useLocaleUnitFormatter } from 'hooks/use-locale-unit-formatter';
import { useInfiniteHealthMetrics } from 'hooks/use-get-health-metrics.hook';
import { useLocaleMetricValueFormatter } from 'hooks/use-metric-value-formatter';
import { ListItem } from 'components/list-item';
import { ErrorPanel } from 'components/error-panel';

type AllRecordedDataProps = {
  match: {
    params: {
      dataType: MetricType;
    };
  };
};

export const AllRecordedData = ({
  match: {
    params: { dataType },
  },
}: AllRecordedDataProps) => {
  const { formatMessage, $tmap } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { formatUnit } = useLocaleUnitFormatter();
  const { formatMetricValue } = useLocaleMetricValueFormatter();
  const metric = useDataTypeConfigMap().metric(dataType);

  const {
    isLoading: isLoadingHealthMetrics,
    isError: isErrorHealthMetrics,
    data: healthMetrics,
    isRefetching: isRefetchingHealthMetrics,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteHealthMetrics({
    filter: {
      type: dataType,
    },
    fields: { type: ['type', 'value', 'source', 'unit', 'timestamp'] },
  });

  const getMetricSourceMessage = $tmap<MetricSource>({
    apple_health: 'APPLE_HEALTH',
    bluetooth: 'BLUETOOTH',
    fitbit: 'FITBIT',
    garmin: 'GARMIN',
    google_fit_sdk: 'GOOGLE_FIT_SDK',
    ihealth: 'IHEALTH',
    omron: 'OMRON',
    polar: 'POLAR',
    samsung_health: 'SAMSUNG_HEALTH',
    strava: 'STRAVA',
    vital_snap: 'VITAL_SNAP',
    withings: 'WITHINGS',
    health_plan: 'HEALTH_PLAN',
    no_source_data: 'NO_SOURCE_DATA',
    unavailable: 'UNAVAILABLE',
    backfilled: 'BACKFILLED',
  });

  if (isLoadingHealthMetrics && !healthMetrics) {
    return <LoadingIndicator />;
  }

  // Sanity check if for whatever reason an unsupported data type is passed in during runtime we ignore it.
  if (!isSupportedUnitMetricType(dataType)) {
    return null;
  }

  return (
    <HealthProfilePageWrapper
      title={formatMessage({
        id: 'ALL_RECORDED_DATA',
      })}
      sidebar={
        <Sidebar>
          <MoreOptionsCard>
            <MoreOptionsLink to="data-sources">
              {formatMessage({ id: 'VIEW_DATA_SOURCES' })}
            </MoreOptionsLink>
          </MoreOptionsCard>
        </Sidebar>
      }
    >
      <HeadingOne as="h2" paddingBottom="twoAndHalf" paddingTop="oneAndHalf">
        {metric}
      </HeadingOne>
      {isErrorHealthMetrics ? (
        <ErrorPanel
          isRefetching={isRefetchingHealthMetrics}
          onRetry={refetch}
        />
      ) : (
        <>
          {healthMetrics?.pages.map((page) =>
            page.data.map((dataMetric) => {
              const { unit, value, source, timestamp, type } = dataMetric;
              const date = formatDateWithUserProfileLocation(
                timestamp,
                DATE_FORMAT,
              );

              if (value === null) {
                return null;
              }

              return (
                <ListItem key={timestamp}>
                  <Flex flexDirection="column">
                    <SubtitleOne>
                      {formatMetricValue(value, type)}{' '}
                      {formatUnit(type, unit, value)}
                    </SubtitleOne>
                    <Caption color="onSurface.text.subdued">
                      {getMetricSourceMessage(source)}
                    </Caption>
                  </Flex>
                  <SubtitleOne
                    paddingTop="half"
                    paddingBottom="half"
                    color="onSurface.text.subdued"
                    fontWeight="regular"
                  >
                    {date}
                  </SubtitleOne>
                </ListItem>
              );
            }),
          )}
          <Flex justifyContent="center" mt="twoAndHalf">
            <SecondaryButton
              disabled={isFetchingNextPage}
              onClick={() => {
                fetchNextPage();
              }}
            >
              {formatMessage({ id: 'LOAD_MORE' }).toLocaleUpperCase()}
            </SecondaryButton>
          </Flex>
        </>
      )}
    </HealthProfilePageWrapper>
  );
};
