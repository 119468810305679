import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  StackLayout,
  HeadingText,
  useMediaQuery,
  queryHelpers,
  StatusBanner,
  Icon,
} from '@leagueplatform/genesis-core';
import { CoverImage } from '@leagueplatform/genesis-commons';
import { Badge, BadgeProps } from '@leagueplatform/web-common-components';
import { HEALTH_JOURNEY_COMMON_ASSET_KEYS } from '../../types/health-journey-common-asset-keys.types';

const PROGRAM_HEADING_FIXED_HEIGHT = { _: 233, phone: 377, tablet: 437 };

export interface ProgramHeadingProps {
  bannerText?: string;
  image?: string;
  imageAltText?: string;
  title: string;
  badge?: BadgeProps;
  settingsComponent?: React.ReactNode;
  children?: React.ReactNode;
}

export const ProgramHeading = ({
  bannerText,
  image,
  imageAltText,
  title,
  badge,
  settingsComponent,
  children,
}: ProgramHeadingProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const centerAlignHeadingItems = isMobile && !settingsComponent;
  const programHeadingBannerIcon = handleStaticAsset(
    HEALTH_JOURNEY_COMMON_ASSET_KEYS.PROGRAM_HEADING_BANNER_ICON,
  ) as string;

  return (
    <StackLayout
      as="header"
      spacing="$one"
      horizontalAlignment="stretch"
      css={{ width: '100%' }}
    >
      {image && (
        <StackLayout spacing="$none" horizontalAlignment="stretch">
          {bannerText && (
            <StatusBanner
              status="success"
              title={bannerText}
              icon={<Icon icon={programHeadingBannerIcon} />}
              css={{
                justifyContent: 'center',
                borderRadius: '$small $small $none $none',
                '> div': {
                  flexGrow: '0',
                },
              }}
            />
          )}
          <CoverImage
            src={image}
            alt={imageAltText ?? ''}
            height={PROGRAM_HEADING_FIXED_HEIGHT}
            borderRadius="none"
            maxWidth="100%"
            backgroundColor="highlight.background.subdued"
            marginBottom="twoAndHalf"
            verticalAlignment="center"
          />
        </StackLayout>
      )}
      <StackLayout
        spacing="$oneAndHalf"
        horizontalAlignment={centerAlignHeadingItems ? 'center' : 'start'}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {badge && <Badge {...badge} />}

        <StackLayout
          css={{
            width: settingsComponent ? '100%' : 'initial',
            textAlign: centerAlignHeadingItems ? 'center' : 'left',
          }}
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          verticalAlignment="center"
        >
          <HeadingText level="1" size={{ '@initial': 'xxl', '@mobile': 'xl' }}>
            {title}
          </HeadingText>
          {settingsComponent}
        </StackLayout>
        {children}
      </StackLayout>
    </StackLayout>
  );
};
