import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const START_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE =
  'start_health_goal_program';

export const startHealthGoalProgram = async (
  programId: string,
  isUserDeviceConnectableProgram?: boolean,
  campaignMode?: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: START_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE,
      info: {
        version: 2,
        program_id: programId,
        ...(isUserDeviceConnectableProgram && {
          campaign_custom_fields: {
            campaign_mode: campaignMode,
          },
        }),
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
