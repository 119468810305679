import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_HEALTH_PROGRAM_CATEGORIES_MESSAGE_TYPE =
  'get_health_program_categories';

export const getHealthProgramCategories = async (errorContext?: ErrorContext) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_HEALTH_PROGRAM_CATEGORIES_MESSAGE_TYPE,
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
