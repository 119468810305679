/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import type { LineGraph as LineGraphType } from './types/line-graph';
import { WeeklyLineGraph } from './weekly-line-graph';
import { MonthlyLineGraph } from './monthly-line-graph';

export type LineGraphProps<Datum> = LineGraphType<Datum> & {
  chartView: CHART_VIEW;
};

export function LineGraph<Datum>({
  chartView,
  ...props
}: LineGraphProps<Datum>) {
  if (chartView === CHART_VIEWS.WEEKLY) {
    return <WeeklyLineGraph {...props} />;
  }
  if (chartView === CHART_VIEWS.MONTHLY) {
    return <MonthlyLineGraph {...props} />;
  }
  return null;
}
