import { HealthMetricUnit } from '@leagueplatform/dashboard-api';

export function getHealthMetricUnitDisplayValue(
  value: string | number | [number, number] | null,
  unit: HealthMetricUnit,
) {
  const { symbol, namePlural, name } = unit;
  if (symbol) {
    return symbol;
  }
  // here we are using a single equal comparison as we want to make sure
  // that value is not equal to either 1 or the string '1'. Generally
  // we want to do double equals, but this is one of the few scenarios
  // where single equals make sense!
  // eslint-disable-next-line eqeqeq
  if (namePlural && value != 1) {
    return namePlural;
  }
  return name;
}
