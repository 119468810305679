import { useParams } from '@leagueplatform/routing';
import { useQuery } from 'react-query';
import { getTeamDataById } from '@leagueplatform/health-journey-api';

export const TEAM_BY_ID_QUERY_KEY = 'teamById';

export const useGetTeamDataById = () => {
  const { teamId } = useParams<{ teamId: string }>();

  return useQuery(
    [TEAM_BY_ID_QUERY_KEY, teamId],
    () => getTeamDataById(teamId),
    {
      enabled: !!teamId,
    },
  );
};
