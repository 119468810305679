import React from 'react';
import {
  Flex,
  Image,
  HeadingThree,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';

interface CarouselHeadingProps {
  imageSrc?: string;
  title: string;
}

const StyledImage = genesisStyled(Image)(
  css({
    objectFit: 'contain',
    aspectRatio: '1 / 1',
    maxWidth: '32px',
    marginRight: 'half',
  }),
);

export const CarouselHeading = ({ imageSrc, title }: CarouselHeadingProps) => (
  <Flex alignItems="center">
    {imageSrc && <StyledImage src={imageSrc} alt="" />}
    <HeadingThree>{title}</HeadingThree>
  </Flex>
);
