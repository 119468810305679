import React from 'react';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';
import { Caption, Flex, Box } from '@leagueplatform/genesis-commons';
import { HtmlToReact } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import {
  NetworkProviders,
  NetworkProviderService,
} from '../../types/plan-progress.types';
import { NetworkAccordion } from '../network-accordion/network-accordion.component';
import { LiveBalanceItem } from '../live-balance-item/live-balance-item.component';
import { networkHasTiers } from './utils/network-has-tiers.util';

interface NetworkPlanProps {
  networkProviders: NetworkProviders;
  networkName: string;
  lastUpdated: string;
  onProviderToggle?: (
    accordionName: string,
    state: 'expand' | 'collapse',
    balanceType?: string,
  ) => void;
}

interface BalanceGroupProps
  extends Pick<
    NetworkPlanProps,
    'lastUpdated' | 'networkName' | 'onProviderToggle'
  > {
  providerServices: NetworkProviderService[];
}

const getBalanceType = (providerServices: NetworkProviderService[]) =>
  providerServices
    .find(
      (provider) =>
        provider.balances.find((balance) => balance.balance_type)?.balance_type,
    )
    ?.balances.find((balance) => balance.balance_type)?.balance_type;

const getDefinition = (providerServices: NetworkProviderService[]) =>
  providerServices.find((provider) => provider.definition)?.definition;

const BalanceGroup = ({
  providerServices,
  lastUpdated,
  networkName,
  onProviderToggle,
}: BalanceGroupProps) => {
  const hasTiers = networkHasTiers(providerServices);
  const balanceType = getBalanceType(providerServices);
  const definition = getDefinition(providerServices);

  return (
    <StackLayout as="section" space="$oneAndHalf">
      {definition && (
        <Box>
          {definition.title && (
            <HeadingText
              level="2"
              size="xl"
              as="h3"
              css={{ marginBottom: '$one' }}
            >
              {definition.title}
            </HeadingText>
          )}
          <StackLayout as="section" space="$half">
            <HtmlToReact htmlString={definition.description} />
          </StackLayout>
        </Box>
      )}

      {hasTiers && (
        <NetworkAccordion
          networkName={networkName}
          providerService={providerServices}
          lastUpdated={lastUpdated}
          onToggle={onProviderToggle}
        />
      )}
      {!hasTiers && (
        <>
          {providerServices.map(({ balances }) =>
            balances.map(
              ({ title, limit, paid_amount, remaining_amount, category }) => (
                <LiveBalanceItem
                  // mmoore: genesis-commons (legacy) headingLevel 3 === genesis-core headingLevel 4
                  headingLevel="4"
                  key={`balance-group-item-${title}-${balanceType}`}
                  balanceName={title}
                  networkName={networkName}
                  limit={limit}
                  paid_amount={paid_amount}
                  remaining_amount={remaining_amount}
                  category={category}
                />
              ),
            ),
          )}

          <Caption>{lastUpdated}</Caption>
        </>
      )}
    </StackLayout>
  );
};

export const NetworkPlan = ({
  networkProviders,
  networkName,
  lastUpdated,
  onProviderToggle,
}: NetworkPlanProps) => {
  const { formatMessage } = useIntl();

  if (networkProviders.length === 0) {
    return (
      <Flex
        as="section"
        backgroundColor="surface.background.tertiary"
        padding="one"
        minHeight={286}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <ParagraphText>
          {formatMessage(
            { id: 'NETWORK_MISSING_COVERAGE' },
            { network: networkName },
          )}
        </ParagraphText>
      </Flex>
    );
  }

  return (
    <StackLayout space="$two">
      {networkProviders.map((providers, i) => (
        <BalanceGroup
          // eslint-disable-next-line react/no-array-index-key
          key={`balance-group-${networkName}-${i}`}
          providerServices={providers}
          lastUpdated={lastUpdated}
          networkName={networkName}
          onProviderToggle={onProviderToggle}
        />
      ))}
    </StackLayout>
  );
};
