import { useQuery } from 'react-query';
import { getFeaturesHighlight } from 'queries/get-features-highlight';

export const useGetFeaturesHighlight = (
  contentfulId: string,
  tenantId: string,
) => {
  const { data, isLoading, isError } = useQuery(
    ['get_features_highlight'],
    () => getFeaturesHighlight(contentfulId, tenantId),
  );
  return {
    isError,
    isLoading,
    slides: data?.data?.highlights,
    title: data?.data?.title,
  };
};
