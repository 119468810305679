import { CustomWidgetData } from 'types/custom-widget-data.types';
import { DefaultWidgetData } from 'types/default-widget-data.types';
import { mapWidgetDataFromAPI } from './widget-data-mapping';

/**
 * func mergeDefaultAndCustomWidgetData
 * @param {DefaultWidgetData} defaultData  : Coming from get_widget_data API call
 * @param {CustomWidgetData} customWidgetData : Coming from the custom widget provider
 * @returns Deep Merge of defaultData & customWidgetData
 */
export const mergeDefaultAndCustomWidgetData = (
  defaultData: DefaultWidgetData,
  customWidgetData?: CustomWidgetData,
) => ({
  ...(customWidgetData?.displayData || {}),
  ...(customWidgetData?.widgetSettings || {}),
  ...(mapWidgetDataFromAPI(defaultData?.attributes || {}, defaultData.type) ||
    {}),
  traits: defaultData?.traits,
});
