import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import {
  getUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
  UserProfileAggregate,
} from '@leagueplatform/user-profile-api';
import {
  deepCamelCaseKeys,
  DeepCamelCaseKeys,
} from '../../utilities/deep-camelcase-keys';

export type CamelCasedUserProfileAggregate =
  DeepCamelCaseKeys<UserProfileAggregate>;

export type UserProfileOptions = Omit<
  UseQueryOptions<
    UserProfileAggregate,
    unknown,
    CamelCasedUserProfileAggregate,
    string[]
  >,
  'queryKey' | 'queryFn'
>;

export const useGetUserProfile = (
  options?: UserProfileOptions,
  userId?: string,
) =>
  useQuery(
    [GET_USER_PROFILE_MESSAGE_TYPE, userId || ''],
    () => getUserProfile(userId),
    {
      select: (data) => deepCamelCaseKeys(data),
      ...options,
    },
  );
