import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Tabs } from '@leagueplatform/genesis-core';
import { CHALLENGE_TYPES } from '@leagueplatform/health-journey-common';

export const AllChallengesByTypeTabs = () => {
  const { $tmap } = useIntl();

  const getTabMessage = $tmap({
    solo: 'CHALLENGE_TYPE_SOLO_TAG',
    group: 'CHALLENGE_TYPE_GROUP_TAG',
  });

  return (
    <Tabs.TabList divider tabDistribution="hugContents">
      {CHALLENGE_TYPES.map((challengeType) => (
        <Tabs.Tab
          key={`${challengeType}_tab`}
          value={challengeType}
          css={{ padding: '$threeQuarters $oneAndHalf' }}
        >
          {getTabMessage(challengeType)}
        </Tabs.Tab>
      ))}
    </Tabs.TabList>
  );
};
