import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { genesisStyled, Flex } from '@leagueplatform/genesis-commons';
import { withTheme } from 'styled-components';
import { getColourObject } from '../../utils/helpers';

const getPointerPositionProperty = (position, right) => {
  // if no right/left is specified, assume left
  // if no position is given, assume 10
  return right
    ? css`
        right: ${position}px;
      `
    : css`
        left: ${position}px;
      `;
};

const getPosition = ({ positionShift, center, right }) => {
  const { x, y } = positionShift;
  // default is left bottom position, translated by (x, y)
  let rightPosition = 'auto';
  let left = 0;
  let transform = `translate(${x}px, ${y}px)`;
  // if opting into center, you lose ability to position horizontally
  if (center) {
    left = '50%';
    transform = `translate(-50%, ${y}px)`;
  } else if (right) {
    // Going to reverse the x-axis on transform to give it useful default
    left = 'auto';
    rightPosition = '0';
    transform = `translate(${-x}px, ${y}px)`;
  }
  return css`
    right: ${rightPosition};
    left: ${left};
    transform: ${transform};
  `;
};

const TooltipContentBox = genesisStyled(Flex)`
  /* Appearance */
  min-width: 250px;
  max-width: 350px;
  outline: none;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      max-width: ${width};
    `};
  ${({ padding }) =>
    css`
      padding: ${padding || '12px'};
    `};
  position: absolute;
  z-index: 99;
  box-shadow: 0 2px 8px ${({ theme }) => theme.lightMediumGrey};
  border: 1px solid ${({ borderColour }) => borderColour};
  pointer-events: auto;
  &[aria-hidden='true'] {
    pointer-events: none;
  }
  ${({ borderRadius }) =>
    css`
      border-radius: ${borderRadius}px;
    `}
  ${({ colour }) =>
    css`
      color: ${colour};
    `}
  ${({ backgroundColour }) =>
    css`
      background: ${backgroundColour || '#fff'};
    `}
  /* Positioning */
  left: 0;
  bottom: 0;
  top: auto;
  ${({ top }) => top && 'bottom: auto; top: 0;'};
  ${({ right }) => right && 'left: initial; right: 0;'};
  ${({ center }) => center && 'left: 50%;'};
  ${({ positionShift, center, right }) =>
    getPosition({ positionShift, center, right })}

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    ${({ center }) => center && 'left: 50%; transform: translateX(-50%);'};
    width: 0;
    height: 0;
  }

  &::before {
    ${({ center, right, pointerPosition }) =>
      !center && getPointerPositionProperty(pointerPosition, right)};

    ${({ borderColour, arrowSize, top }) => css`
      border-right: ${arrowSize}px solid transparent;
      border-left: ${arrowSize}px solid transparent;
      border-top: ${arrowSize + 1}px solid ${borderColour};
      ${top &&
      css`
        top: auto;
        bottom: 100%;
        border-bottom: ${arrowSize + 1}px solid ${borderColour};
        border-top: none;
      `};
    `}
  }

  &::after {
    ${({ right, center, pointerPosition }) =>
      !center && getPointerPositionProperty(pointerPosition + 1, right)};

    ${({ backgroundColour, arrowSize, top }) => css`
      border-right: ${arrowSize - 1}px solid transparent;
      border-left: ${arrowSize - 1}px solid transparent;
      border-top: ${arrowSize}px solid ${backgroundColour};
      ${top &&
      css`
        top: auto;
        bottom: 100%;
        border-bottom: ${arrowSize}px solid ${backgroundColour};
        border-top: none;
      `};
    `}
  }
`;

const TooltipContentWrapper = genesisStyled('span')`
  transition-property: transform, opacity;
  transition-duration: 300ms;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -7px;
  display: block;
  transform: translateY(${({ isTop }) => (isTop ? '8px' : '-8px')});
  opacity: 0;
  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translateY(0);
          opacity: 1;
        `
      : ''}
`;

export const TooltipContent = withTheme(
  ({
    isHovered,
    children,
    colour,
    backgroundColour,
    borderColour,
    borderRadius,
    pointerPosition,
    positionShift,
    arrowSize,
    center,
    left,
    right,
    top,
    padding,
    width,
    describeId,
    theme,
    trackAnalytics,
    role,
  }) => {
    const colourTheme = getColourObject(theme);
    const containerRef = useRef();
    if (isHovered && trackAnalytics) {
      trackAnalytics();
    }

    useEffect(() => {
      if (isHovered) {
        containerRef?.current.focus();
      }
    }, [isHovered, containerRef]);

    return (
      <TooltipContentWrapper isVisible={isHovered} top={top}>
        <TooltipContentBox
          aria-hidden={!isHovered}
          ref={containerRef}
          tabIndex="-1"
          colour={colour || colourTheme.navyDark}
          backgroundColour={backgroundColour || colourTheme.white}
          borderColour={borderColour || colourTheme.infoGrey}
          pointerPosition={pointerPosition}
          borderRadius={borderRadius}
          arrowSize={arrowSize}
          positionShift={positionShift}
          center={center}
          left={left}
          right={right}
          top={top}
          padding={padding}
          width={width}
          role={role}
          {...(describeId && { id: describeId })}
        >
          {children}
        </TooltipContentBox>
      </TooltipContentWrapper>
    );
  },
);

TooltipContent.propTypes = {
  children: PropTypes.node.isRequired,
  isHovered: PropTypes.bool,
  colour: PropTypes.string,
  backgroundColour: PropTypes.string,
  borderColour: PropTypes.string,
  borderRadius: PropTypes.number,
  pointerPosition: PropTypes.number,
  positionShift: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  center: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  arrowSize: PropTypes.number,
  padding: PropTypes.string,
  width: PropTypes.string,
  describeId: PropTypes.string,
  role: PropTypes.string,
  trackAnalytics: PropTypes.func,
};

TooltipContent.defaultProps = {
  isHovered: false,
  borderRadius: 3,
  pointerPosition: 10,
  padding: '12px',
  center: false,
  left: false,
  right: false,
  top: false,
  arrowSize: 7,
  positionShift: {
    x: -10,
    y: -32,
  },
  width: '',
  role: 'tooltip',
};
