import { deserialise } from 'kitsu-core';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  UserChallengeRanksResponseData,
  DeserialisedUserChallengeRanksResponseData,
} from 'challenges/types/user-challenge-ranks-data.type';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { USER_CHALLENGES_RANKINGS_PATH } from './constants/challenge-requests.constants';

export const getIndividualUserRank = async (
  challengeId: string,
  userId?: string | undefined,
  errorContext?: ErrorContext,
): Promise<DeserialisedUserChallengeRanksResponseData> => {
  const response = await leagueFetch(
    `${USER_CHALLENGES_RANKINGS_PATH}&filter[user]=${userId}&challengeId=${challengeId}`,
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (!response.ok) {
    throw new Error(`getIndividualUserRank error: ${response.status}`);
  }
  const responseJson: UserChallengeRanksResponseData = await response.json();
  return deserialise(responseJson);
};
