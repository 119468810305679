import React from 'react';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { ChallengesHealthProgramsCardData } from 'types/health-programs-response-data.type';
import { HealthProgramsCard } from './health-programs-card.component';

interface AllChallengesListProps {
  programs: ChallengesHealthProgramsCardData[];
}

export const AllChallengesList = ({ programs }: AllChallengesListProps) => (
  <StackLayout
    css={{
      flexWrap: 'wrap',
      width: '100%',
    }}
    as="ul"
    orientation="horizontal"
    horizontalAlignment="start"
    spacing="$oneAndHalf"
  >
    {programs.map((program) => (
      <Box
        as="li"
        key={program.program_id}
        css={{
          '@mobile': { width: '100%' },
          '@mobileLandscape': { width: 'calc(50% - 13px)' },
          '@tablet': { width: 'calc(50% - 13px)' },
          '@laptop': { width: 'calc(33% - 13px)' },
          '@desktop': { width: 'calc(33% - 13px)' },
          '&>div': { width: '100%' },
        }}
      >
        <HealthProgramsCard
          tag={program.challenge_info.challenge_type}
          program={program}
        />
      </Box>
    ))}
  </StackLayout>
);
