import { CardStatuses } from 'pages/wallet-landing/utils/parse-benefit-card-statuses.util';
import {
  WalletItemNavigationSection,
  WalletItemNavigationSectionGroup,
} from '@leagueplatform/wallet-api';
import { filterCardStatus } from './filter-card-status.util';

type SortedBenefitSections = {
  [key in CardStatuses]?: WalletItemNavigationSectionGroup[];
};

export const sortBenefitSectionsByStatus = (
  statusArray: CardStatuses[] = [],
  itemSections: WalletItemNavigationSectionGroup[] = [],
) => {
  const sortedBenefitSections: SortedBenefitSections = statusArray.reduce(
    (previousValue, currentValue) => {
      const mappedItemSections = itemSections.map((item) => {
        // Handle league credit response shape
        if (item.wallet_items) {
          const filteredSections = [
            {
              ...item,
              wallet_items: filterCardStatus(item?.wallet_items, currentValue),
            },
          ].filter((sections) => sections?.wallet_items?.length);

          return {
            ...item,
            sections: filteredSections,
          };
        }

        // Handle default benefit response shape
        const filteredSections = item.sections.reduce(
          (prevSection, currentSection) => {
            const walletItems = filterCardStatus(
              currentSection?.wallet_items || [],
              currentValue,
            );

            return [
              ...prevSection,
              { ...currentSection, wallet_items: walletItems },
            ].filter((sections) => sections?.wallet_items?.length);
          },
          [] as WalletItemNavigationSection[],
        );

        return {
          ...item,
          sections: filteredSections,
        };
      });

      return { ...previousValue, [currentValue]: mappedItemSections };
    },
    {},
  );

  return sortedBenefitSections;
};
