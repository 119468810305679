import {
  CARD_STATUS,
  PILL_STATUS,
  WalletItemNavigationBenefit,
} from '@leagueplatform/wallet-api';

const { PENDING, PAST } = PILL_STATUS;
const {
  CREATED,
  READY,
  IN_WAITING_PERIOD,
  ACTIVE,
  INACTIVE_GRACE_PERIOD,
  INACTIVE,
} = CARD_STATUS;

export const filterCardStatus = (
  cards: WalletItemNavigationBenefit[],
  status: CARD_STATUS | PILL_STATUS,
) => {
  switch (status) {
    case PENDING:
      return cards.filter((card) =>
        [CREATED, READY, IN_WAITING_PERIOD].includes(card.status),
      );
    case PAST:
      return cards.filter((card) =>
        [INACTIVE_GRACE_PERIOD, INACTIVE].includes(card.status),
      );
    default:
      return cards.filter((card) =>
        card.status ? card.status === ACTIVE : card,
      );
  }
};
