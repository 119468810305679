import {
  type MasonryEngineNodeRenderer,
  DEFAULT_NAMESPACE,
  type MasonryEngineNamespacedNodeRendererMap,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import type { MasonryWSDriverNode } from '@leagueplatform/masonry-widgets';
import { merge } from 'lodash';
import { MasonryWSDriverDefaultNodeRendererMap } from './masonry-WS-default-node-renderers-map';

type ArbitraryMasonryEngineNode = MasonryEngineNode<string, any>;

const masonryWSDriverNodeRendererMap: MasonryEngineNamespacedNodeRendererMap<ArbitraryMasonryEngineNode> =
  {
    [DEFAULT_NAMESPACE]: MasonryWSDriverDefaultNodeRendererMap,
  };

const masonryWSDriverNodeRendererOverrideMap: Partial<
  MasonryEngineNamespacedNodeRendererMap<ArbitraryMasonryEngineNode>
> = {};

export const MasonryWSDriverNodeRenderersRegistry = {
  /**
   *
   * @method registerRenderer use to register renderers per namespace
   * along with the namespace name to be used for {@link getMasonryWSDriver}.
   * This function expects namespace as first argument and Node type to
   * Renderer map as second argument.
   */
  registerRenderer<Type extends string>(
    namespace: string,
    type: Type,
    renderer: MasonryEngineNodeRenderer<
      Type extends MasonryWSDriverNode['type']
        ? Extract<MasonryWSDriverNode, Record<'type', Type>>
        : MasonryEngineNode<string, any>
    >,
  ) {
    masonryWSDriverNodeRendererMap[namespace] =
      masonryWSDriverNodeRendererMap[namespace] || {};
    if (masonryWSDriverNodeRendererMap[namespace]![type]) {
      throw new Error(
        'Attempting to register a renderer for a type at a namespace where a renderer was already registered for that type!',
      );
    }
    masonryWSDriverNodeRendererMap[namespace]![type] = renderer;
  },

  /**
   * @method overrideRenderer use by app developers to override single renderer for an App
   * for {@link getMasonryWSDriver}.
   * To override default renderers for a driver, please use namespace "default"
   * Note: All renderers map are driver specific.
   * */
  overrideRenderer<Type extends string>(
    namespace: string,
    type: Type,
    renderer: MasonryEngineNodeRenderer<
      Type extends MasonryWSDriverNode['type']
        ? Extract<MasonryWSDriverNode, Record<'type', Type>>
        : MasonryEngineNode<string, any>
    >,
  ) {
    masonryWSDriverNodeRendererOverrideMap[namespace] =
      masonryWSDriverNodeRendererOverrideMap[namespace] || {};
    masonryWSDriverNodeRendererOverrideMap[namespace]![type] = renderer;
  },

  getNodeRenderersMap() {
    const resolvedRendererMap = merge(
      {},
      masonryWSDriverNodeRendererMap,
      masonryWSDriverNodeRendererOverrideMap,
    );
    // eslint-disable-next-line no-console
    console.log('[Masonry WS Driver Renderer Map] : ', resolvedRendererMap);
    return resolvedRendererMap;
  },
};
