import React, { ElementType, useMemo } from 'react';
import * as RadixAccordionPrimitive from '@radix-ui/react-accordion';
import {
  Box,
  Button,
  GDSButtonProps,
  Icon,
} from '@leagueplatform/genesis-core';
import { AccordionStyleProps } from './accordion-root.component';
import { AccordionItemContext } from './accordion-item.component';

export interface AccordionTriggerProps
  extends RadixAccordionPrimitive.AccordionTriggerProps,
    GDSButtonProps<ElementType>,
    AccordionStyleProps {
  children: React.ReactNode;
}

export const Trigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, className, ...props }, forwardedRef) => {
  const { triggerId, contentId } = React.useContext(AccordionItemContext);
  const composedClassName = useMemo(
    () => ['cc-accordion-trigger', className].join(' '),
    [className],
  );

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      aria-controls={contentId}
      as={RadixAccordionPrimitive.Trigger}
      className={composedClassName}
      id={triggerId}
      ref={forwardedRef}
    >
      <Box as="span">{children}</Box>
      <Icon icon="interfaceChevronDown" className="cc-accordion-trigger-icon" />
    </Button>
  );
});

Trigger.displayName = 'Accordion.Trigger';
