import React from 'react';
import {
  HeadingThree,
  Box,
  PrimaryButton,
  SecondaryButton,
  BodyOneSecondary,
  BodyOne,
  Checkbox,
  Label,
  LabelText,
  ErrorMessage,
  Flex,
} from '@leagueplatform/genesis-commons';
import { Modal } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

interface ConfirmFinalizeSelectionsModalProps {
  onCancelFinalizingSelections: () => void;
  onConfirmFinalizingSelections: () => void;
  toggleConfirmationCheckbox: () => void;
  isConfirmationChecked: boolean;
  hasUncheckedError: boolean;
}

export const ConfirmFinalizeSelectionsModal = ({
  onConfirmFinalizingSelections,
  onCancelFinalizingSelections,
  toggleConfirmationCheckbox,
  isConfirmationChecked,
  hasUncheckedError,
}: ConfirmFinalizeSelectionsModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal.Wrapper onClose={onCancelFinalizingSelections} options={null}>
      <Box
        role="dialog"
        aria-labelledby="modal-title"
        backgroundColor="surface.background.primary"
        borderRadius="medium"
        width={600}
        maxWidth="100%"
      >
        <Box padding="two">
          <HeadingThree id="modal-title" as="h2" marginY="one">
            {formatMessage({ id: 'PLEASE_NOTE' })}
          </HeadingThree>
          <BodyOneSecondary marginTop="one">
            {formatMessage({ id: 'FINALIZE_SELECTIONS_COPY_1' })}
          </BodyOneSecondary>

          <BodyOne marginTop="one">
            <strong>
              {formatMessage({ id: 'FINALIZE_SELECTIONS_COPY_2' })}
            </strong>
          </BodyOne>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onConfirmFinalizingSelections();
            }}
          >
            <Label
              htmlFor="finalize-checkbox-id"
              display="flex"
              marginTop="one"
            >
              <Checkbox
                id="finalize-checkbox-id"
                name="finalize-checkbox"
                checked={isConfirmationChecked}
                onChange={toggleConfirmationCheckbox}
                marginRight="half"
                error={hasUncheckedError}
                aria-describedby={
                  hasUncheckedError ? 'finalize-checkbox-error' : ''
                }
              />
              <LabelText color="onSurface.text.primary">
                {formatMessage({ id: 'FINALIZE_SELECTIONS_ACKNOWLEDGEMENT' })}
              </LabelText>
            </Label>

            {hasUncheckedError && (
              <ErrorMessage
                id="finalize-checkbox-error"
                align="center"
                display="flex"
                marginTop="half"
              >
                {formatMessage({ id: 'THIS_BOX_MUST_BE_CHECKED_TO_CONTINUE' })}
              </ErrorMessage>
            )}
            <Flex justifyContent="flex-end" marginTop="oneAndHalf">
              <SecondaryButton
                role="button"
                onClick={onCancelFinalizingSelections}
                marginRight="oneAndHalf"
              >
                {formatMessage({ id: 'CANCEL' })}
              </SecondaryButton>
              <PrimaryButton>{formatMessage({ id: 'SUBMIT' })}</PrimaryButton>
            </Flex>
          </form>
        </Box>
      </Box>
    </Modal.Wrapper>
  );
};
