import { array, string, optional, object, enums, boolean } from 'superstruct';

const JourneyTabName = Object.freeze({
  Activities: 'activities',
  Progress: 'progress',
  History: 'history',
  Explore: 'explore',
});

const JourneyTabs = Object.values(JourneyTabName);

export const healthJourneyConfigSchema = object({
  tabs: optional(array(enums(JourneyTabs))),
  headerConfig: optional(
    object({
      backgroundImageSrc: optional(string()),
      enableHeaderSubTitle: optional(boolean()),
    }),
  ),
});
