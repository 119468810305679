import { useMutation, useQueryClient } from 'react-query';

import {
  HealthMetricPatchData,
  updateHealthMetrics,
  healthMetricsV3Path,
} from '@leagueplatform/dashboard-api';
import { PATCH_HEALTH_METRIC_MESSAGE_TYPE } from 'constants/request.constants';

type UsePatchHealthMetricParams = {
  data: HealthMetricPatchData;
  observationId: string;
};

export const usePatchHealthMetrics = (closeModal?: Function) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async ({ data, observationId }: UsePatchHealthMetricParams) =>
      updateHealthMetrics({ data, observationId }),
    {
      mutationKey: [PATCH_HEALTH_METRIC_MESSAGE_TYPE],
      onSuccess: () => {
        queryClient.invalidateQueries([healthMetricsV3Path]);

        if (closeModal) {
          closeModal();
        }
      },
    },
  );

  return mutation;
};
