import React, { forwardRef, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Portal } from 'components/portal/portal';
import { Button } from 'components/button/button';
import { StackLayout } from 'components/stack-layout/stack-layout';
import { useThemeStrings } from '../../hooks/use-theme';
import { useComposedRefs } from '../../hooks/use-composed-ref';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import type { GDSCustomizableComponent, GDSResponsiveProp } from '../../types';
import { styled, GDSSize, GDSStyleObject } from '../../theme';
import { animate, KeyframeAnimationType } from './animations';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';
import { getComponentDisplayName } from '../../utilities';

export type GDSModalLayout = 'standard' | 'left' | 'right' | 'fullscreen';

export interface GDSModalContentProps
  extends Dialog.DialogContentProps,
    Dialog.DialogPortalProps,
    GDSCustomizableComponent {
  children?: React.ReactNode;
  layout?: GDSResponsiveProp<GDSModalLayout>;
  padding?: GDSResponsiveProp<number | string | GDSSize>;
  width?: GDSResponsiveProp<number | string | GDSSize>;
  showCloseButton?: boolean;
  closeOnOverlay?: boolean;
  forceModalFocus?: boolean;
}

type ApplyModalAnimationStates = Record<
  'open' | 'close',
  KeyframeAnimationType
>;

const applyModalAnimation = ({
  open,
  close,
}: ApplyModalAnimationStates): GDSStyleObject => ({
  animation: animate(open),
  '@media (prefers-reduced-motion)': { animation: 'none' },
  '&[data-state="closed"]': {
    animation: animate(close),
    '@media (prefers-reduced-motion)': { animation: 'none' },
  },
});

const DialogOverlay = styled(Dialog.Overlay, {
  backgroundColor: 'rgba(136, 136, 136, 0.7)',
  position: 'fixed',
  inset: 0,
  zIndex: '$modal',
  ...applyModalAnimation({ open: 'overlayShow', close: 'overlayClose' }),
});

const distanceFromEdge = 40;

const DialogContainer = styled(Dialog.Content, {
  background: '$surfaceBackgroundPrimary',
  boxShadow: '$dropdown',
  position: 'fixed',
  overflow: 'auto',
  zIndex: '$modal',
  width: '33vw',
  '@tablet': { width: '66vw' },
  '@mobileLandscape': { width: '66vw' },
  '&:focus': { outline: 'none !important' },

  variants: {
    layout: {
      standard: {
        maxHeight: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '$large',
        ...applyModalAnimation({ open: 'contentShow', close: 'contentClose' }),
        '@mobile': {
          transform: 'unset',
          width: '100vw',
          margin: '0 auto',
          bottom: 0,
          top: 'unset',
          left: 0,
          right: 0,
          maxHeight: `calc(100% - ${distanceFromEdge}px)`,
          borderRadius: '$large $large 0 0',
          ...applyModalAnimation({ open: 'bottomOpen', close: 'bottomClose' }),
        },
      },

      fullscreen: {
        bottom: 0,
        left: `${distanceFromEdge}px`,
        right: `${distanceFromEdge}px`,
        width: `calc(100vw - ${distanceFromEdge * 2}px)`,
        height: `calc(100% - ${distanceFromEdge}px)`,
        borderRadius: '$large $large 0 0',
        ...applyModalAnimation({ open: 'bottomOpen', close: 'bottomClose' }),
        '@mobile': {
          width: '100vw',
          left: 0,
          right: 0,
          borderRadius: '$large $large 0 0',
        },
      },

      left: {
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: '0 $large $large 0',
        ...applyModalAnimation({ open: 'leftOpen', close: 'leftClose' }),
        '@mobile': {
          width: `calc(100vw - ${distanceFromEdge}px)`,
        },
      },

      right: {
        height: '100%',
        top: 0,
        right: 0,
        left: 'unset',
        borderRadius: '$large 0 0 $large',
        ...applyModalAnimation({ open: 'rightOpen', close: 'rightClose' }),
        '@mobile': {
          width: `calc(100vw - ${distanceFromEdge}px)`,
        },
      },
    },
  },
});
const DialogContent = styled('div');
const ModalWrapper = styled('div');

export const ModalContent = forwardRef<HTMLDivElement, GDSModalContentProps>(
  (
    {
      layout = 'standard',
      padding = '$oneAndHalf',
      showCloseButton = true,
      forceModalFocus = true,
      onInteractOutside,
      width,
      className,
      children,
      css,
      container,
      ...props
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const responsivePadding = useResponsiveProp(padding);
    const responsiveWidth = useResponsiveProp(width);
    const responsiveLayout = useResponsiveProp(layout);

    const strings = useThemeStrings();

    // Use the internal ref to force focus on the modal body
    // Combine internal and passed refs to pass down to the component
    const internalRef = useRef<HTMLDivElement>(null);
    const composedRefs = useComposedRefs(ref, internalRef);

    // Check if the Modal has Modal.Description element
    const hasDescription = React.Children.toArray(children).some((child) => {
      if (React.isValidElement(child) && typeof child.type !== 'string') {
        const componentName = getComponentDisplayName(child);

        if (componentName === 'Modal.Description') {
          return true;
        }
      }
      return false;
    });

    return (
      <Portal container={container}>
        <ModalWrapper css={css}>
          <DialogOverlay className="GDS-modal-overlay" data-testid="overlay" />
          <GenesisCoreInspector displayName="Modal">
            <DialogContainer
              className={['GDS-modal', className].join(' ')}
              {...(forceModalFocus && {
                onOpenAutoFocus: (e) => {
                  e.preventDefault();
                  if (internalRef?.current) internalRef.current.focus();
                },
              })}
              {...(width && {
                css: { width: responsiveWidth },
              })}
              {...(!hasDescription && {
                'aria-describedby': undefined,
              })}
              onInteractOutside={onInteractOutside}
              layout={responsiveLayout}
              ref={composedRefs}
              {...props}
            >
              {showCloseButton && (
                <StackLayout
                  orientation="horizontal"
                  horizontalAlignment="end"
                  css={{ padding: '$half $half $none $half' }}
                  className="GDS-modal-close-button-wrapper"
                >
                  <Dialog.Close asChild>
                    <Button
                      priority="tertiary"
                      quiet
                      icon="tinyClose"
                      hideLabel
                      size="small"
                      className="GDS-modal-close-button"
                    >
                      {strings.close}
                    </Button>
                  </Dialog.Close>
                </StackLayout>
              )}
              <DialogContent
                className="GDS-modal-content"
                css={{ padding: responsivePadding }}
              >
                {children}
              </DialogContent>
            </DialogContainer>
          </GenesisCoreInspector>
        </ModalWrapper>
      </Portal>
    );
  },
);
