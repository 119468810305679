import * as React from 'react';
import { useRouteMatch } from '@leagueplatform/routing';
import {
  genesisStyled,
  HeadingTwo,
  ThemeProps,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  supportedMetrics,
  MetricType,
  HealthMetric,
} from '@leagueplatform/dashboard-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { NoDataCard } from 'components/no-data-card';
import isToday from 'date-fns/isToday';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useGetHealthMetrics } from 'hooks/use-get-health-metrics.hook';
import { DYNAMIC_DASHBOARD_ENABLED } from 'constants/feature-flags.constants';
import { ErrorPanel } from 'components/error-panel';
import { MetricGroup } from './metric-group';
import { AllMetricCards } from '../all-metric-cards';

const CardContainer = genesisStyled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  gap: ${({ theme }: ThemeProps) => `0 ${theme?.space.oneAndHalf}px`};
`;

const daysDifference = (timestamp: string) =>
  differenceInCalendarDays(new Date(), new Date(timestamp));

const getUnavailableMetrics = (healthMetricsData: HealthMetric[]) =>
  supportedMetrics.filter(
    (type: MetricType) =>
      !healthMetricsData.map((metric) => metric.type).includes(type),
  );

type AllInsightsProps = {
  // This component can either be rendered within the About Me metrics tab OR the All Insights page.
  // When `embedded=true` that means we are rendering it within the tab.
  embedded?: boolean;
};

function AllInsightsLegacy({ embedded }: AllInsightsProps) {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();
  const {
    isLoading,
    isError,
    isRefetching,
    data: healthMetrics,
    refetch,
  } = useGetHealthMetrics({
    interval: 'instant',
    fields: {
      type: ['type', 'source', 'value', 'unit', 'trend', 'timestamp'],
    },
  });

  if (isError) {
    return <ErrorPanel isRefetching={isRefetching} onRetry={() => refetch()} />;
  }

  if (isLoading) {
    return <SkeletonBox data-testid="loading" width="100%" height="250px" />;
  }

  const unavailableMetrics = healthMetrics?.data
    ? getUnavailableMetrics(healthMetrics.data)
    : supportedMetrics;

  const metricsFromToday = healthMetrics?.data.filter(({ timestamp }) =>
    isToday(new Date(timestamp)),
  );
  const metricsFromPast7Days = healthMetrics?.data.filter(
    ({ timestamp }) =>
      daysDifference(timestamp) > 0 && daysDifference(timestamp) <= 6,
  );

  const metricsFromPast30Days = healthMetrics?.data.filter(
    ({ timestamp }) =>
      daysDifference(timestamp) > 6 && daysDifference(timestamp) <= 30,
  );

  const metricsFromOlderThan30Days = healthMetrics?.data.filter(
    ({ timestamp }) => daysDifference(timestamp) > 30,
  );

  return (
    <>
      {metricsFromToday?.length ? (
        <MetricGroup
          embedded={embedded}
          title={formatMessage({
            id: 'TODAY',
          })}
          metrics={metricsFromToday}
          dataTestId="today"
        />
      ) : null}
      {metricsFromPast7Days?.length ? (
        <MetricGroup
          embedded={embedded}
          title={formatMessage({
            id: 'LAST_7_DAYS',
          })}
          metrics={metricsFromPast7Days}
          dataTestId="past7Days"
        />
      ) : null}
      {metricsFromPast30Days?.length ? (
        <MetricGroup
          embedded={embedded}
          title={formatMessage({
            id: 'LAST_30_DAYS',
          })}
          metrics={metricsFromPast30Days}
          dataTestId="past30Days"
        />
      ) : null}
      {metricsFromOlderThan30Days?.length ? (
        <MetricGroup
          embedded={embedded}
          title={formatMessage({
            id: 'OLDER',
          })}
          metrics={metricsFromOlderThan30Days}
          dataTestId="older"
        />
      ) : null}

      {unavailableMetrics.length > 0 && (
        <>
          <HeadingTwo marginTop="four" marginBottom="oneAndHalf">
            {formatMessage({
              id: 'NO_DATA_AVAILABLE',
            })}
          </HeadingTwo>
          <CardContainer data-testid="unavailable-metrics-container">
            {unavailableMetrics.map((type: MetricType) => (
              <NoDataCard
                key={type}
                dataType={type}
                to={
                  embedded
                    ? `${path}/insights/${type}/details`
                    : `${path}/${type}/details`
                }
              />
            ))}
          </CardContainer>
        </>
      )}
    </>
  );
}

export function AllInsights({ embedded = true }: AllInsightsProps) {
  const { data: isDynamicDashboardEnabled } = useFeatureFlagQuery(
    DYNAMIC_DASHBOARD_ENABLED,
  );

  return isDynamicDashboardEnabled ? (
    <AllMetricCards />
  ) : (
    <AllInsightsLegacy embedded={embedded} />
  );
}
