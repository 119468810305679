import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { Link, useParams } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  noopFunction,
  CONTENT_MAX_WIDTH,
} from 'constants/challenges.constants';

export const JoinTeamPageSkeletonLoader = (): JSX.Element => {
  const { challengeId } = useParams<{ challengeId: string }>();

  return (
    <Flex
      as="section"
      flexDirection={{ _: 'column', laptop: 'row' }}
      alignItems={{ _: 'center', laptop: 'initial' }}
      padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
      marginTop="two"
      justifyContent="center"
    >
      <Flex marginBottom="twoAndHalf" width="100%" maxWidth={CONTENT_MAX_WIDTH}>
        <BackButtonArrow
          as={Link}
          justifyContent="initial"
          marginRight={{ _: 'one', phone: 'twoAndHalf' }}
          marginY="half"
          onClick={noopFunction}
          to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
          size="three"
          marginTop="half"
        />

        <Flex flexDirection="column" width="100%">
          {/* Heading Skeleton Loader */}
          <SkeletonBox
            width="100%"
            maxWidth={CONTENT_MAX_WIDTH}
            height="100px"
            backgroundColor="interactive.background.disabled"
            role="presentation"
            marginTop="half"
          />

          {/* Join a team option pre-load */}
          <SkeletonBox
            backgroundColor="interactive.background.disabled"
            marginTop="two"
            height="160px"
            role="presentation"
          />

          {/* Create a team option pre-load */}
          <SkeletonBox
            backgroundColor="interactive.background.disabled"
            marginTop="one"
            height="160px"
            role="presentation"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
