import * as React from 'react';
import { Flex, HeadingOne, Box } from '@leagueplatform/genesis-commons';
import { ArrowButton } from 'components/arrow-button/ArrowButton';

type PaginationArrow = {
  label: string;
  enabled: boolean;
  onClick: () => void;
};

type PaginationControlsProps = {
  previous: PaginationArrow;
  next: PaginationArrow;
  title: string;
};

export function PaginationControls({
  title,
  previous,
  next,
}: PaginationControlsProps) {
  return (
    <Flex paddingBottom="twoAndHalf" paddingTop="five" alignItems="flex-end">
      <Box marginBottom="quarter">
        <ArrowButton
          ariaLabel={previous.label}
          disabled={previous.enabled}
          onClick={previous.onClick}
          direction="left"
        />
      </Box>
      <HeadingOne
        textAlign="center"
        as="h2"
        paddingLeft="oneAndHalf"
        paddingRight="oneAndHalf"
      >
        {title}
      </HeadingOne>
      <Box marginBottom="quarter">
        <ArrowButton
          ariaLabel={next.label}
          disabled={next.enabled}
          onClick={next.onClick}
          direction="right"
        />
      </Box>
    </Flex>
  );
}
