import * as React from 'react';
import { Box, Flex, HeadingOne } from '@leagueplatform/genesis-commons';
import { Link } from '@leagueplatform/routing';
import {
  BackButtonArrow,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';

export type CarePageWrapperProps = {
  children: React.ReactNode;
  backButtonPath?: string;
  backButtonOnClick?: () => void;
  title?: string;
};

export function CarePageWrapper({
  backButtonPath,
  backButtonOnClick,
  children,
  title,
}: CarePageWrapperProps) {
  return (
    <Flex
      maxWidth="1272px"
      width="100%"
      padding={{ _: 'oneAndHalf', phone: 'two' }}
      marginX="auto"
      justifyContent="center"
    >
      {title && <PageHeaderBackground />}
      <Flex
        flexDirection={{ _: 'column', phone: 'row' }}
        width="100%"
        alignItems="flex-start"
      >
        {(backButtonPath || backButtonOnClick) && (
          <BackButtonArrow
            as={Link}
            to={backButtonPath}
            marginTop="threeQuarters"
            marginLeft="none"
            marginRight="two"
            onClick={backButtonOnClick}
          />
        )}
        <Box width="100%">
          {title && (
            <HeadingOne
              marginTop="half"
              marginBottom="five"
              fontSize={{ _: 'heading2', phone: 'heading1' }}
            >
              {title}
            </HeadingOne>
          )}
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}
