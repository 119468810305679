import React from 'react';

const icons = {
  CLOCK: [
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g
        strokeWidth="1.5"
        stroke="inherit"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="10" cy="10" r="9" />
        <path d="M10 10V6.528M10 10l4.34 4.34" />
      </g>
    </svg>,
    'fill',
  ],
  RIGHT_CHEVRON: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14L12 8L6 2"
        stroke="currentcolor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
    'stroke',
  ],
  LEFT_CHEVRON: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2L4 8L10 14"
        stroke="currentcolor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
    'stroke',
  ],
  STAR_FILLED: [
    <svg viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 9.12L2.1 11l.65-3.98L0 4.202l3.8-.58L5.5 0l1.7 3.62 3.8.582L8.25 7.02 8.9 11"
        fill="#64CCC9"
        fillRule="evenodd"
      />
    </svg>,
    'fill',
  ],
  INFORMATION: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        fill="#FFF"
        d="M56.67 76.56H43.33v-33h13.34v33zM50 38.05a7.31 7.31 0 1 1 7.31-7.31A7.31 7.31 0 0 1 50 38.05z"
      />
      <path
        fill="#FFF"
        d="M85.36 14.64a50 50 0 1 0 0 70.71 49.84 49.84 0 0 0 0-70.71zm-5.53 65.19a42.19 42.19 0 0 1-59.66-59.66 42.19 42.19 0 0 1 59.67 0 42.19 42.19 0 0 1-.01 59.66z"
      />
      <circle fill="#FFF" cx="50" cy="30.74" r="7.31" />
      <path fill="#FFF" d="M43.33 43.57h13.35v32.99H43.33z" />
    </svg>,
    'fill',
  ],
  SEARCH: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
    </svg>,
    'fill',
  ],
  WARNING: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
    </svg>,
    'fill',
  ],
  X: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z" />
    </svg>,
    'fill',
  ],
  CHECK: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
    </svg>,
    'fill',
  ],
  CHEVRON_RIGHT: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
    </svg>,
    'fill',
  ],
  CHEVRON_DOWN: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
    </svg>,
    'fill',
  ],
  ASTERISK: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M478.21 334.093L336 256l142.21-78.093c11.795-6.477 15.961-21.384 9.232-33.037l-19.48-33.741c-6.728-11.653-21.72-15.499-33.227-8.523L296 186.718l3.475-162.204C299.763 11.061 288.937 0 275.48 0h-38.96c-13.456 0-24.283 11.061-23.994 24.514L216 186.718 77.265 102.607c-11.506-6.976-26.499-3.13-33.227 8.523l-19.48 33.741c-6.728 11.653-2.562 26.56 9.233 33.037L176 256 33.79 334.093c-11.795 6.477-15.961 21.384-9.232 33.037l19.48 33.741c6.728 11.653 21.721 15.499 33.227 8.523L216 325.282l-3.475 162.204C212.237 500.939 223.064 512 236.52 512h38.961c13.456 0 24.283-11.061 23.995-24.514L296 325.282l138.735 84.111c11.506 6.976 26.499 3.13 33.227-8.523l19.48-33.741c6.728-11.653 2.563-26.559-9.232-33.036z" />
    </svg>,
    'fill',
  ],
  LIGHT_BULB: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M272 428v28c0 10.449-6.68 19.334-16 22.629V488c0 13.255-10.745 24-24 24h-80c-13.255 0-24-10.745-24-24v-9.371c-9.32-3.295-16-12.18-16-22.629v-28c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12zm-143.107-44c-9.907 0-18.826-6.078-22.376-15.327C67.697 267.541 16 277.731 16 176 16 78.803 94.805 0 192 0s176 78.803 176 176c0 101.731-51.697 91.541-90.516 192.673-3.55 9.249-12.47 15.327-22.376 15.327H128.893zM112 176c0-44.112 35.888-80 80-80 8.837 0 16-7.164 16-16s-7.163-16-16-16c-61.757 0-112 50.243-112 112 0 8.836 7.164 16 16 16s16-7.164 16-16z" />
    </svg>,
    'fill',
  ],
  DOCUMENT_PIN: [
    <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.06207394,14.1516394 C2.85323954,15.57965 1.64503617,17.0078709 0.435570752,18.4356711 C0.115851198,18.8128139 0.107647867,19.2469594 0.418322723,19.5530068 C0.726894162,19.8569507 1.14379163,19.8521128 1.5184104,19.5391242 C2.55455417,18.6756711 3.58985657,17.8120076 4.62578999,16.9483441 C5.09779702,16.5541636 5.5710661,16.1604037 6.06242457,15.7508682 C5.45642981,15.1398252 4.86242453,14.5399304 4.25685045,13.9295184 C4.18196877,14.0151276 4.12096964,14.0824369 4.06207394,14.1516394 Z M12.5628279,19.748204 C13.4288052,18.7770558 13.3886299,17.1010523 12.6120479,15.2315343 C12.6280339,15.2025071 12.6461233,15.1730592 12.6690505,15.1425597 C14.4258254,12.7892555 16.1720831,10.42901 17.9214959,8.07044726 L18.1619166,8.31128863 C18.5529421,8.70210372 19.1864916,8.70189338 19.576886,8.31107829 L19.7068772,8.18150773 C20.0972716,7.79090299 20.0976923,7.15756379 19.7070876,6.76653836 L13.2331873,0.292848409 C12.8421619,-0.097756336 12.209033,-0.0975459942 11.8180076,0.293058751 L11.688437,0.422418966 C11.2978323,0.813023711 11.2978323,1.44699393 11.688437,1.83759868 L12.0211978,2.17099045 C9.63024239,3.91956194 7.24012838,5.66897479 4.84433514,7.41081533 C4.84202138,7.41291875 4.83844556,7.4141808 4.83550078,7.4156532 C2.91865585,6.60352346 1.16251206,6.52317289 0.219970395,7.46550421 C0.111013336,7.57446126 -0.18914443,7.91942184 0.174746905,8.28352351 L11.6979024,19.8064687 C12.101338,20.2099043 12.433047,19.8777745 12.5628279,19.748204 Z M8.10820904,9.4399828 C7.61895398,9.04012301 7.12296799,8.68191091 6.62845439,8.3674499 C8.75038259,6.63486439 10.8714694,4.9018582 13.0081216,3.15707287 L14.1260883,4.2750396 C12.1072276,6.00783545 10.1068769,7.72443498 8.10820904,9.4399828 Z" />
    </svg>,
  ],
  CARET: [
    <svg
      className="caret-icon"
      x="0px"
      y="0px"
      width="12px"
      height="6px"
      viewBox="351.584 2118.292 12 6"
    >
      <g>
        <path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z" />
      </g>
    </svg>,
  ],
  CLOSE: [
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path
          stroke="currentcolor"
          fill="currentcolor"
          d="M19.5,20.25c-0.2,0-0.389-0.078-0.53-0.22L12,13.061l-6.97,6.97c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22
          c-0.292-0.292-0.292-0.768,0-1.061l6.97-6.97L3.97,5.03C3.828,4.889,3.75,4.7,3.75,4.5s0.078-0.389,0.22-0.53
          C4.111,3.828,4.3,3.75,4.5,3.75s0.389,0.078,0.53,0.22l6.97,6.97l6.97-6.97c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22
          c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53L13.061,12l6.97,6.97c0.292,0.292,0.292,0.768,0,1.061
          C19.889,20.172,19.7,20.25,19.5,20.25z"
        />
      </g>
    </svg>,
  ],
  EDIT: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentcolor"
      >
        <path d="M18.151 1.848a2.911 2.911 0 0 0-4.135.028l-11.6 11.6L1 19l5.524-1.417 11.6-11.6a2.91 2.91 0 0 0 .027-4.135zM13 3l4 4M3 13l4 4" />
      </g>
    </svg>,
  ],
  CANCEL: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentcolor"
      >
        <path d="M13.652 8.348l-5.304 5.303M8.348 8.348l5.304 5.303M20.375 11c0 2.589-.915 4.799-2.746 6.63-1.83 1.83-4.04 2.745-6.629 2.745-2.589 0-4.799-.915-6.63-2.746C2.54 15.8 1.626 13.59 1.626 11c0-2.589.915-4.799 2.746-6.63C6.2 2.54 8.41 1.626 11 1.626c2.589 0 4.799.915 6.63 2.746C19.46 6.2 20.374 8.41 20.374 11z" />
      </g>
    </svg>,
  ],
  CHAT: [
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentcolor"
      >
        <path d="M3.977 6.114c-1.668 2.998-1.595 5.953.217 8.865l-2.569 5.396 5.395-2.57c3.511 2.184 7.535 1.347 10.102-.61 2.567-1.955 3.863-5.697 2.77-9.733-1.092-4.036-4.214-5.837-8.267-5.837-3.43-.005-5.98 1.491-7.648 4.489z" />
        <path d="M11.625 13.5c-.208 0-.313.104-.313.312 0 .209.105.313.313.313.208 0 .312-.104.312-.313 0-.208-.104-.312-.312-.312M9.125 9.125c0-1.114.77-1.92 1.543-2.31.774-.39 1.937-.245 2.725.542.787.788.787 2.087.542 2.725-.246.637-.732 1.543-2.31 1.543" />
      </g>
    </svg>,
  ],
  CARET_DOWN: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
      <path
        d="M6.387 7.76A.87.87 0 0 0 7 8a.87.87 0 0 0 .613-.24l6.221-6.694a.578.578 0 0 0 .087-.695c-.13-.227-.402-.37-.7-.371H.78C.48 0 .209.144.078.371a.578.578 0 0 0 .088.695l6.22 6.694z"
        fillRule="nonzero"
        fill="#000"
      />
    </svg>,
  ],
  FILE_DOWNLOAD: [
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        d="M15.375 20.375C18.1364 20.375 20.375 18.1364 20.375 15.375C20.375 12.6136 18.1364 10.375 15.375 10.375C12.6136 10.375 10.375 12.6136 10.375 15.375C10.375 18.1364 12.6136 20.375 15.375 20.375Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      />
      <path
        fill="none"
        d="M15.375 12.875V17.875"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      />
      <path
        fill="none"
        d="M15.375 17.875L17.25 16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      />
      <path
        fill="none"
        d="M15.375 17.875L13.5 16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      />
      <path
        fill="none"
        d="M7.875 17.875H2.875C2.18464 17.875 1.625 17.3154 1.625 16.625V2.875C1.625 2.18464 2.18464 1.625 2.875 1.625H11.7325C12.0638 1.62507 12.3815 1.75665 12.6158 1.99083L15.0092 4.38417C15.2433 4.6185 15.3749 4.93621 15.375 5.2675V7.875"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      />
    </svg>,
  ],
  WHAT: [
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#501CD2"
      >
        <path
          d="M12.5 21.5H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h11.129a1.5 1.5 0 0 1 1.06.439l2.872 2.872a1.5 1.5 0 0 1 .439 1.06V20.5a1 1 0 0 1-1 1h-7z"
          strokeWidth="1.5"
          fill="#E5DDF9"
        />
        <path
          d="M7.183 14.317h9.867M7.183 18h6.934M10 7a2 2 0 1 1 2 2"
          strokeWidth="1.5"
        />
        <path d="M11.984 10.843a.513.513 0 1 1 0 1.025.513.513 0 0 1 0-1.025" />
      </g>
    </svg>,
  ],
  DEPENDENTS: [
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <ellipse fill="#E5DDF9" cx="12" cy="15.5" rx="8" ry="5.5" />
        <circle fill="#E5DDF9" cx="11.97" cy="5.5" r="4.5" />
        <circle
          stroke="#501CD2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="11.97"
          cy="5.5"
          r="4.5"
        />
        <path
          d="M20.776 15.528c-1.663-3.075-4.935-5-8.5-5-1.3 0-4.806.472-6.802 2.472M10.9 22.069c-.977-1.23-2.902-2-5-2-2.097 0-4.022.77-5 2"
          stroke="#501CD2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 23a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
          stroke="#501CD2"
          strokeWidth="1.5"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g
          stroke="#501CD2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M18.972 17.234H15M16.986 15v4.469" />
        </g>
        <circle
          stroke="#501CD2"
          strokeWidth="1.5"
          fill="#E5DDF9"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="5.901"
          cy="15.5"
          r="3.5"
        />
      </g>
    </svg>,
  ],
  COVERAGE: [
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#501CD2"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M22.313 13.375C22.313 7.68 17.694 3.062 12 3.062S1.687 7.68 1.687 13.376l.744-.62a4.315 4.315 0 0 1 6.131.62 4.858 4.858 0 0 1 6.876 0 4.315 4.315 0 0 1 6.131-.62l.744.62z"
          fill="#E5DDF9"
        />
        <path d="M12 11.951v8.984a1.375 1.375 0 0 1-2.75 0M12 3.063V1.687" />
        <path
          d="M21.527 16.488a1.589 1.589 0 0 0-2.293 0l-.983 1.005-.983-1.005a1.589 1.589 0 0 0-2.293 0 1.698 1.698 0 0 0 0 2.356l2.927 3.007a.484.484 0 0 0 .698 0l2.927-3.01c.63-.66.63-1.694 0-2.353z"
          fill="#E5DDF9"
        />
      </g>
    </svg>,
  ],
  FAMILY: [
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#E5DDF9" cx="13.875" cy="6.96" r="5.5" />
        <path
          d="M22.588 20.768c-1.224-3.068-5.772-6.765-9.818-4.917C8.724 17.7 1.07 17.596.885 21.467c-.185 3.87 23.585.365 21.703-.7z"
          fill="#E5DDF9"
        />
        <circle
          stroke="#501CD2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="13.875"
          cy="6.96"
          r="5.5"
        />
        <circle
          stroke="#501CD2"
          strokeWidth="1.5"
          fill="#E5DDF9"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="7.387"
          cy="11"
          r="4"
        />
        <path
          d="M22.375 20.46c-1.662-3.076-4.936-5-8.5-5-2.31 0-4.291.54-6.877 2.5"
          stroke="#501CD2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.387 21c-1.076-1.845-3.193-3-5.5-3s-4.424 1.155-5.5 3"
          stroke="#501CD2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>,
  ],
  TWO_PEOPLE: [
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        fill="none"
        clipRule="evenodd"
        d="M12.5817 10.5434C14.9575 10.5434 16.8836 8.63087 16.8836 6.27168C16.8836 3.9125 14.9575 2 12.5817 2C10.2058 2 8.27979 3.9125 8.27979 6.27168C8.27979 8.63087 10.2058 10.5434 12.5817 10.5434Z"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 15.7914C19.4109 12.8722 16.2827 11.045 12.8742 11.045C11.9094 11.045 9.67502 11.3146 7.83093 12.3227"
        stroke="#66686B"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5597 22C11.625 20.8324 9.78485 20.1015 7.77987 20.1015C5.77489 20.1015 3.93477 20.8324 3 22"
        stroke="#66686B"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77987 19.0867C9.62776 19.0867 11.1258 17.5992 11.1258 15.7643C11.1258 13.9294 9.62776 12.4419 7.77987 12.4419C5.93197 12.4419 4.43396 13.9294 4.43396 15.7643C4.43396 17.5992 5.93197 19.0867 7.77987 19.0867Z"
        stroke="#66686B"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  PURPLE_ADD: [
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.833 10.833V9.167A.84.84 0 0 0 15 8.333h-3.333V5a.84.84 0 0 0-.834-.833H9.167A.84.84 0 0 0 8.333 5v3.333H5a.84.84 0 0 0-.833.834v1.666a.84.84 0 0 0 .833.834h3.333V15a.84.84 0 0 0 .834.833h1.666a.84.84 0 0 0 .834-.833v-3.333H15a.84.84 0 0 0 .833-.834zM20 10c0 5.52-4.48 10-10 10S0 15.52 0 10 4.48 0 10 0s10 4.48 10 10z"
        fill="#501CD2"
      />
    </svg>,
  ],
  UMBRELLA_HEART: [
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.25 13.5C23.25 7.2868 18.2132 2.25 12 2.25C5.7868 2.25 0.75 7.2868 0.75 13.5L1.561 12.824C2.53262 12.0144 3.78918 11.6298 5.04749 11.757C6.30581 11.8842 7.46001 12.5123 8.25 13.5C9.24413 12.5046 10.5932 11.9453 12 11.9453C13.4068 11.9453 14.7559 12.5046 15.75 13.5C16.54 12.5123 17.6942 11.8842 18.9525 11.757C20.2108 11.6298 21.4674 12.0144 22.439 12.824L23.25 13.5Z"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11.947V21.747C12 22.5754 11.3284 23.247 10.5 23.247C9.67157 23.247 9 22.5754 9 21.747"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.25V0.75"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.68 16.6C22.3214 16.2237 21.8243 16.0107 21.3045 16.0107C20.7847 16.0107 20.2876 16.2237 19.929 16.6L18.75 17.814L17.571 16.6C17.2124 16.2237 16.7153 16.0107 16.1955 16.0107C15.6757 16.0107 15.1786 16.2237 14.82 16.6C14.06 17.3961 14.06 18.649 14.82 19.445L18.331 23.076C18.4403 23.1906 18.5917 23.2554 18.75 23.2554C18.9083 23.2554 19.0597 23.1906 19.169 23.076L22.68 19.44C23.4368 18.6446 23.4368 17.3954 22.68 16.6Z"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  CIGARETTE: [
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 22.75H3.75V16.75H21.75C22.5784 16.75 23.25 17.4216 23.25 18.25V21.25C23.25 22.0784 22.5784 22.75 21.75 22.75Z"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 16.75V22.75"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 16.75V22.75"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 3.25C8.25 5.73528 6.23528 7.75 3.75 7.75C2.09315 7.75 0.75 9.09315 0.75 10.75"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 3.25C12.75 8.22056 8.72056 12.25 3.75 12.25"
        stroke="#767676"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  QUESTION_CIRCLE: [
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
        stroke="#767676"
      />
      <path
        d="M7.236 10.306V8.41H7.65C7.99 8.41 8.292 8.376 8.556 8.308C8.82 8.24 9.044 8.142 9.228 8.014C9.412 7.882 9.552 7.724 9.648 7.54C9.744 7.352 9.792 7.138 9.792 6.898C9.792 6.678 9.744 6.48 9.648 6.304C9.552 6.128 9.412 5.978 9.228 5.854C9.048 5.73 8.828 5.636 8.568 5.572C8.308 5.508 8.016 5.476 7.692 5.476C7.536 5.476 7.382 5.482 7.23 5.494C7.082 5.502 6.95 5.512 6.834 5.524L6.75 4.516C6.886 4.496 7.046 4.48 7.23 4.468C7.414 4.452 7.584 4.444 7.74 4.444C8.228 4.444 8.666 4.502 9.054 4.618C9.442 4.73 9.772 4.892 10.044 5.104C10.316 5.312 10.524 5.566 10.668 5.866C10.816 6.162 10.89 6.492 10.89 6.856C10.89 7.204 10.83 7.52 10.71 7.804C10.59 8.088 10.414 8.334 10.182 8.542C9.95 8.75 9.666 8.92 9.33 9.052C8.994 9.184 8.608 9.274 8.172 9.322L8.124 10.306H7.236ZM7.68 11.476C7.788 11.476 7.892 11.498 7.992 11.542C8.092 11.586 8.178 11.646 8.25 11.722C8.326 11.794 8.386 11.88 8.43 11.98C8.474 12.08 8.496 12.184 8.496 12.292C8.496 12.404 8.474 12.51 8.43 12.61C8.386 12.71 8.326 12.798 8.25 12.874C8.178 12.95 8.092 13.01 7.992 13.054C7.892 13.098 7.788 13.12 7.68 13.12C7.572 13.12 7.468 13.098 7.368 13.054C7.268 13.01 7.18 12.95 7.104 12.874C7.028 12.798 6.968 12.71 6.924 12.61C6.88 12.51 6.858 12.404 6.858 12.292C6.858 12.184 6.88 12.08 6.924 11.98C6.968 11.88 7.028 11.794 7.104 11.722C7.18 11.646 7.268 11.586 7.368 11.542C7.468 11.498 7.572 11.476 7.68 11.476Z"
        fill="#767676"
      />
    </svg>,
  ],
  CADUCEUS: [
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0331 6.118C13.5155 6.118 14.7171 4.91633 14.7171 3.434C14.7171 1.95167 13.5155 0.75 12.0331 0.75C10.5508 0.75 9.34912 1.95167 9.34912 3.434C9.34912 4.91633 10.5508 6.118 12.0331 6.118Z"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.23 12.625C15.8492 12.615 16.434 12.9095 16.7946 13.413C17.1552 13.9165 17.2458 14.5649 17.037 15.148"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.033 6.11792V23.2499"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4941 4.5H22.3831C25.0601 4.5 21.1831 10.953 15.6111 8.8"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.56905 4.5H1.68205C-0.993946 4.5 2.88205 10.953 8.45405 8.8"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83502 12.6251C7.78797 12.6145 6.92974 13.4531 6.91602 14.5001C6.92152 15.5651 7.77044 16.4339 8.83502 16.4641H13.951C15.0108 16.4641 15.87 17.3233 15.87 18.3831C15.87 19.4429 15.0108 20.3021 13.951 20.3021H10.434C9.52967 20.3228 8.81334 21.0727 8.83402 21.9771C8.83146 22.4482 9.02361 22.8995 9.36502 23.2241"
        stroke="#66686B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  PENCIL: [
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6123 1.75402C14.1754 1.26683 13.5829 0.995385 12.9664 1.00006C12.35 1.00473 11.7608 1.28514 11.3298 1.7789L2.12378 12.0899L1 17L5.38399 15.7406L14.5901 5.42969C15.031 4.94728 15.2815 4.28743 15.2857 3.59704C15.2898 2.90666 15.0474 2.24307 14.6123 1.75402Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.5234 2.77783L13.698 6.33339"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.58691 11.6667L5.76152 15.2223"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  CLAIMS: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17143 15H3C2.44771 15 2 14.5523 2 14V2C2 1.44772 2.44772 1 3 1H11.0034C11.276 1.00006 11.5375 1.11234 11.7303 1.31218L13.6983 3.35449C13.891 3.55445 13.9999 3.82557 14 4.10827V14C14 14.5523 13.5523 15 13 15H8.17143Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6.19173 9.96202C6.60452 10.505 7.25652 10.812 7.938 10.7844C9.00851 10.7844 9.87601 10.1331 9.87601 9.33043C9.87601 8.52779 9.00851 7.8774 7.938 7.8774C6.8675 7.8774 6 7.22607 6 6.42249C6 5.6189 6.8675 4.96851 7.938 4.96851C8.61949 4.94089 9.27149 5.24794 9.68427 5.7909"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.938 10.7852V11.7542"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.938 4V4.969"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>,
  ],
  EMPLOYER: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53936 6.26953C1.53936 5.4411 2.21093 4.76953 3.03936 4.76953H12.9606C13.7891 4.76953 14.4606 5.4411 14.4606 6.26953V12.499C14.4606 13.3274 13.7891 13.999 12.9606 13.999H3.03936C2.21093 13.999 1.53936 13.3274 1.53936 12.499V6.26953Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0563 2.63068C9.93056 2.25397 9.57791 1.99995 9.18076 2H6.81924C6.42209 1.99995 6.06944 2.25397 5.94367 2.63068L5.23116 4.76884H10.7688L10.0563 2.63068Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>,
  ],
  FINANCE: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30169 11.3563C5.94929 12.2081 6.97218 12.6898 8.04133 12.6465C9.72079 12.6465 11.0818 11.6246 11.0818 10.3654C11.0818 9.10618 9.72079 8.08582 8.04133 8.08582C6.36186 8.08582 5.00089 7.06399 5.00089 5.80329C5.00089 4.54258 6.36186 3.52222 8.04133 3.52222C9.11047 3.47889 10.1334 3.96061 10.781 4.81242"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.04133 12.6445V14.1647"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.04054 2V3.52022"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>,
  ],
  TOOLKIT: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.07542 6.26953C1.07542 5.4411 1.747 4.76953 2.57542 4.76953H13.4246C14.253 4.76953 14.9246 5.4411 14.9246 6.26953V12.5023C14.9246 13.3307 14.253 14.0023 13.4246 14.0023H2.57542C1.747 14.0023 1.07542 13.3307 1.07542 12.5023V9.38592V6.26953Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.7698 4.76983C10.7698 3.2401 9.52974 2 8 2C6.47027 2 5.23017 3.2401 5.23017 4.76983"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1.07542 8.46289H6"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10 8.46289H14.9246"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10C6.89543 10 6 9.05228 6 8.5C6 7.94772 6.89543 7 8 7C9.10457 7 10 7.94772 10 8.5C10 9.05228 9.10457 10 8 10Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>,
  ],
  SIGN_OUT: [
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 8.00195H5"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.5 10.502L5 8.00195L7.5 5.50195"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11V14C11.5238 14.5268 11.1173 14.9737 10.5907 15H2.40867C1.88226 14.9734 1.47622 14.5265 1.5 14V2C1.47585 1.47332 1.88209 1.02625 2.40867 1H10.5907C11.1173 1.02625 11.5238 1.47321 11.5 2V5"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>,
  ],
  OFFLINE: [
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.75666 5.69832C9.65437 5.67264 9.55207 5.69832 9.46256 5.76253C9.13009 6.04503 8.74647 6.27616 8.32449 6.43025C7.92809 6.58434 7.49332 6.66139 7.03298 6.66139C5.99722 6.66139 5.05096 6.23764 4.37324 5.55708C3.69551 4.87651 3.27353 3.92629 3.27353 2.88619C3.27353 2.4496 3.35026 2.02585 3.47813 1.64063C3.61879 1.22972 3.82338 0.857335 4.09192 0.536315C4.207 0.395066 4.18143 0.189613 4.04077 0.0740454C3.95126 0.0098413 3.84896 -0.0158403 3.74666 0.00984129C2.65975 0.30518 1.71349 0.960062 1.03577 1.83324C0.383617 2.69357 0 3.75936 0 4.91503C0 6.31468 0.562639 7.58593 1.48332 8.51046C2.404 9.435 3.65715 10 5.06375 10C6.24017 10 7.32709 9.58909 8.19662 8.90853C9.07894 8.21513 9.7183 7.22638 9.98683 6.09639C10.038 5.91662 9.93569 5.73685 9.75666 5.69832Z" />
    </svg>,
  ],
  FILE_ARROW_UP: [
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6667 21.0002C18.6122 21.0002 21 18.6123 21 15.6668C21 12.7213 18.6122 10.3335 15.6667 10.3335C12.7212 10.3335 10.3334 12.7213 10.3334 15.6668C10.3334 18.6123 12.7212 21.0002 15.6667 21.0002Z"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6666 18.3335L15.6666 13.0002"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6667 13L17.6667 15"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6666 13L13.6666 15"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66663 6.3335H13"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66663 10.3335H8.99996"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66663 14.3335H6.99996"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M7 18.3333H2.33333C1.59695 18.3333 1 17.7364 1 17V2.33333C1 1.59695 1.59695 1 2.33333 1H11.7813C12.1347 1.00008 12.4736 1.14043 12.7236 1.39022L15.2764 3.94311C15.5262 4.19307 15.6666 4.53196 15.6667 4.88533V7"
        stroke="#501CD2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
  PROFILE: [
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M437.02 330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521 243.251 404 198.548 404 148 404 66.393 337.607 0 256 0S108 66.393 108 148c0 50.548 25.479 95.251 64.262 121.962-36.21 12.495-69.398 33.136-97.281 61.018C26.629 379.333 0 443.62 0 512h40c0-119.103 96.897-216 216-216s216 96.897 216 216h40c0-68.38-26.629-132.667-74.98-181.02zM256 256c-59.551 0-108-48.448-108-108S196.449 40 256 40s108 48.448 108 108-48.449 108-108 108z" />
    </svg>,
  ],
  NEW_TAB: [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.922 283.922"
      aria-hidden="true"
      key="newTab"
    >
      <path d="M266.422 0h-97.625c-9.65 0-17.5 7.851-17.5 17.5 0 9.649 7.85 17.5 17.5 17.5h55.377l-92.375 92.374a17.378 17.378 0 00-5.127 12.375c0 4.676 1.819 9.069 5.125 12.371a17.374 17.374 0 0012.375 5.13c4.674 0 9.069-1.82 12.376-5.127l92.374-92.375v55.377c0 9.649 7.851 17.5 17.5 17.5 9.649 0 17.5-7.851 17.5-17.5V17.5c0-9.649-7.851-17.5-17.5-17.5z" />
      <path d="M201.137 253.922H30V82.785h128.711l30-30H15c-8.284 0-15 6.716-15 15v201.137c0 8.284 6.716 15 15 15h201.137c8.284 0 15-6.716 15-15V95.211l-30 30v128.711z" />
    </svg>,
  ],
  DOT_MENU: [
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="4" r="2" fill="currentcolor" />
      <circle cx="12" cy="12" r="2" fill="currentcolor" />
      <circle cx="12" cy="20" r="2" fill="currentcolor" />
    </svg>,
  ],
};

export default icons;
