import React from 'react';
import { Checkbox } from '@leagueplatform/genesis-core';
import { QuestionnaireItemAnswerOption } from 'fhir/r4';

import { useIntl } from '@leagueplatform/locales';
import { ControlledFieldset } from '@leagueplatform/web-common';
import { getDisplaySlug } from 'utils/display-slug.util';

export interface CheckboxGroupProps {
  answerOptions: QuestionnaireItemAnswerOption[];
  id: string;
  name: string;
  legend: React.ReactNode;
  required?: boolean;
}

export const CheckboxGroup = ({
  answerOptions,
  id,
  name,
  legend,
  required = false,
}: CheckboxGroupProps) => {
  const { formatMessage } = useIntl();
  const defaultInputRules = {
    required: {
      value: required,
      message: formatMessage({ id: 'PLEASE_MAKE_A_SELECTION' }),
    },
  };

  const defaultValues = answerOptions
    .filter((option) => option.initialSelected)
    .map((filterdOption) => filterdOption?.valueCoding?.code);

  const inputRules = required ? defaultInputRules : {};
  return (
    <ControlledFieldset
      name={name}
      inputOptions={{
        ...inputRules,
        value: defaultValues,
      }}
      legend={legend}
      id={id}
    >
      {answerOptions.map((option) => {
        const keyAndId = getDisplaySlug({ id, option });
        return (
          <Checkbox
            key={keyAndId}
            label={option.valueCoding!.display || option.valueCoding!.code}
            name={name}
            value={option.valueCoding!.code}
            id={keyAndId}
            layout="row"
          />
        );
      })}
    </ControlledFieldset>
  );
};
