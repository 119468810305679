import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import {
  TEAMS_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';
import {
  MultipleTeamsResponseData,
  MultipleTeamsDeserialisedData,
} from './types/teams-data.type';

const { CHALLENGE } = CHALLENGE_DATA_TYPES;
export const getAllTeams = async (
  challengeId: string,
  errorContext?: ErrorContext,
): Promise<MultipleTeamsDeserialisedData> => {
  const response = await leagueFetch(
    `${TEAMS_PATH}?filter[challenge]=${challengeId}&include=${CHALLENGE}`,
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (!response.ok) {
    throw new Error(`getAllTeams error: ${response.status}`);
  }
  const responseJson: MultipleTeamsResponseData = await response.json();
  return deserialise(responseJson);
};
