import React from 'react';
// import {
//   Flex,
//   QuietButton,
//   genesisStyled,
//   css,
//   visuallyHidden,
// } from '@leagueplatform/genesis-commons';
import { StackLayout, Modal } from '@leagueplatform/genesis-core';
// import { Icon, ICONS, visuallyHidden } from '@leagueplatform/ui-kit';
// import { useIntl } from '@leagueplatform/locales';

// const StyledModalBody = genesisStyled(Modal.Body)(
//   css({
//     padding: 'twoAndHalf',
//     height: '100%',
//   }),
// );

// const CloseButton = genesisStyled(QuietButton)(
//   css({
//     position: 'absolute',
//     right: 0,
//     margin: 'one',
//     padding: 'half',
//   }),
// );

// const CloseButtonScreenReaderText = genesisStyled.span(css(visuallyHidden));

interface AchievementFullscreenModalProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  // 'aria-labelledby'?: string;
}

// const StyledModalContainer = genesisStyled(Modal.Container)(
//   css({
//     position: 'relative',
//     borderRadius: 'medium',
//     overflow: 'scroll',
//     minWidth: '100%',
//     minHeight: '100%',
//   }),
// );

export const AchievementFullscreenModal = ({
  open,
  onClose,
  children,
}: // 'aria-labelledby': ariaLabelledBy,
AchievementFullscreenModalProps) => (
  // const { formatMessage } = useIntl();

  <Modal.Root open={open} onOpenChange={onClose}>
    <Modal.Content
      css={{
        '.GDS-modal': { minWidth: 345, minHeight: 345 },
        '.GDS-modal-content': { padding: '$twoAndHalf', paddingTop: '$none' },
        '@mobile': {
          '.GDS-modal': { minWidth: '100%', minHeight: '100%' },
        },
        '@laptop': {
          '.GDS-modal': { minWidth: '768px', minHeight: '768px' },
        },
        '@desktop': {
          '.GDS-modal': { minWidth: '100%', minHeight: '100%' },
        },
      }}
    >
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        {children}
        {/* <StyledModalContainer aria-labelledby={ariaLabelledBy}>
            <CloseButton onClick={onClose}>
              <CloseButtonScreenReaderText>
                {formatMessage({ id: 'CLOSE' })}
              </CloseButtonScreenReaderText>
              <Icon icon={ICONS.CLOSE} size={16} aria-hidden="true" />
            </CloseButton>
            <StyledModalBody>{children}</StyledModalBody>
          </StyledModalContainer> */}
      </StackLayout>
    </Modal.Content>
  </Modal.Root>
);
