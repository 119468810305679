import React from 'react';
import { InternalOrExternalLinkButton } from 'components/internal-or-external-link-button/internal-or-external-link-button.component';
import { TextAction } from '@leagueplatform/genesis-core';

interface CTALinkProps {
  children: React.ReactNode;
  href: string;
  ariaLabel?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

export const CTALink = ({
  children,
  ariaLabel,
  href,
  onClick,
}: CTALinkProps) => (
  <InternalOrExternalLinkButton
    baseComponent={TextAction}
    href={href}
    onClick={onClick}
    aria-label={ariaLabel}
  >
    {children}
  </InternalOrExternalLinkButton>
);
