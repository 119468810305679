import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export const useDocumentTitle = (title) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    document.title = formatMessage(
      {
        id: 'DOCUMENT_TITLE',
      },
      {
        title,
      },
    );
  }, [formatMessage, title]);
};
