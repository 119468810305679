import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';
import { ActivityStatusOptions } from 'hooks/use-activities-by-activity-status-and-campaign.hook';
import { ConfigurableHealthActivityWSCC } from 'types/activity-details-props.types';

/*
 * Create an exhausted list of activity statuses, and map a selector function
 * to retrieve the date from correct field.
 */
const activityDateSelectorByStatusMap: Record<
  ActivityStatusOptions,
  (activity: ConfigurableHealthActivityWSCC) => string
> = {
  active: (activity) => activity.startDate,
  upcoming: (activity) => activity.startDate,
  removed: (activity) => activity.dateRemoved!,
  expired: (activity) => activity.endDate,
  completed: (activity) => activity.completedDate!,
};

export function getActivityDate(activity: ConfigurableHealthActivityWSCC) {
  const dateSelector =
    activityDateSelectorByStatusMap[activity.status as ActivityStatusOptions];
  return dateSelector(activity);
}

export function sortActivitiesInChronologicalOrder(
  activities: ConfigurableHealthActivityWSCC[],
) {
  const sortedActivities = activities.sort((activityA, activityB) =>
    compareAsc(
      parseISO(getActivityDate(activityA)),
      parseISO(getActivityDate(activityB)),
    ),
  );

  return sortedActivities;
}

export function sortActivitiesInReverseChronologicalOrder(
  activities: ConfigurableHealthActivityWSCC[],
) {
  const sortedActivities = activities.sort((activityA, activityB) =>
    compareDesc(
      parseISO(getActivityDate(activityA)),
      parseISO(getActivityDate(activityB)),
    ),
  );

  return sortedActivities;
}
