import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { BaseButton, ButtonProps } from '../BaseButton';

/**
 * @deprecated  use `Button` from `genesis-core` instead
 */

export const QuietButton: React.FunctionComponent<ButtonProps> = forwardRef(
  (props, ref) => (
    <BaseButton
      color="tertiary.text.default"
      ref={ref}
      css={css({
        ':hover': {
          backgroundColor: 'tertiary.background.hovered',
          color: 'tertiary.text.hovered',
        },
        ':active': {
          backgroundColor: 'tertiary.background.pressed',
          color: 'tertiary.text.hovered',
        },
        ':focus': {
          backgroundColor: 'tertiary.background.hovered',
          color: 'tertiary.text.hovered',
        },
      })}
      {...props}
    />
  )
);

QuietButton.displayName = 'QuietButton';
