import React from 'react';
import { Link, Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import appStoreEN from 'assets/download-appstore-badge-en.svg';
import playStoreEN from 'assets/download-google-play-badge-en.svg';
import appStoreFR from 'assets/download-appstore-badge-fr.svg';
import playStoreFR from 'assets/download-google-play-badge-fr.svg';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';

const LEAGUE_APP_GOOGLE_STORE_URL =
  'https://play.google.com/store/apps/details?id=life.league';

const LEAGUE_APP_APPLE_STORE_URL =
  'https://apps.apple.com/ca/app/league-health-wellness-professionals/id952832544';

type AppType = 'APPLE' | 'GOOGLE';

const APP_ICON_SRC: { [app in AppType]: { [locale: string]: string } } = {
  APPLE: {
    fr: appStoreFR,
    en: appStoreEN,
  },
  GOOGLE: {
    fr: playStoreFR,
    en: playStoreEN,
  },
};

function getAppIcon(type: AppType, locale: string) {
  const appIcon = APP_ICON_SRC[type];

  if (locale.includes('fr')) {
    return appIcon.fr;
  }

  if (locale.includes('en')) {
    return appIcon.en;
  }

  /**
   * Returning an empty string of the locale isn't "fr" or "en".  Currently with Spanish it will return a blank string and in turn shows the alt text.
   * Keeping the behaviour the same for now since this entire function should be refactored away and replaced with some sort of branding config.
   */
  return '';
}

type AppIconProps = {
  imageSize?: string;
  href?: string;
};

export const AppleAppIcon = ({
  imageSize,
  href = LEAGUE_APP_APPLE_STORE_URL,
}: AppIconProps) => {
  const { formatMessage, locale } = useIntl();

  return (
    <Link
      href={href}
      onClick={() => {
        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          screen_name: 'Member Download App',
          detail: 'Download Apple Store App',
          sub_detail: 'Download on the App Store',
        });
      }}
    >
      <Image
        alt={formatMessage({ id: 'DOWNLOAD_APP_STORE' })}
        src={getAppIcon('APPLE', locale)}
        height={imageSize}
        width="auto"
        display="block"
      />
    </Link>
  );
};

export const GoogleAppIcon = ({
  imageSize,
  href = LEAGUE_APP_GOOGLE_STORE_URL,
}: AppIconProps) => {
  const { formatMessage, locale } = useIntl();

  return (
    <Link
      href={href}
      onClick={() => {
        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          screen_name: 'Member Download App',
          detail: 'Download Google Play App',
          sub_detail: 'Get it on Google Play',
        });
      }}
    >
      <Image
        alt={formatMessage({ id: 'DOWNLOAD_PLAY_STORE' })}
        src={getAppIcon('GOOGLE', locale)}
        height={imageSize}
        width="auto"
        display="block"
      />
    </Link>
  );
};
