import React, { forwardRef } from 'react';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  UtilityText,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
} from '@leagueplatform/masonry-engine';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ConditionalLinkRenderer } from '../common/conditional-link-renderer/conditional-link-renderer.component';
import {
  MasonryDeeplinkAction,
  MasonryDriverAction,
} from '../../types/masonry-driver-node-actions';

const eyebrowStyles: GDSStyleObject = {
  borderRadius: '$medium',
  backgroundColor: '$surfaceBackgroundTertiary',
  padding: '$quarter $half',
  marginBottom: '$half',
};

export type MasonryNotificationCardNodeProperties = {
  heading: string;
  body: string;
  info?: string;
  eyebrow?: string;
};

type MasonryNotificationCardNodeActions = {
  onClick?: MasonryDriverAction;
};

export type MasonryNotificationCardNode = MasonryEngineNode<
  'notificationCard',
  MasonryNotificationCardNodeProperties,
  MasonryNotificationCardNodeActions
>;

export type MasonryNotificationCardRendererProps =
  MasonryEngineNodeRendererProps<MasonryNotificationCardNode>;

interface MasonryNotificationCardContentProps
  extends Omit<
    MasonryNotificationCardRendererProps,
    'isVisible' | 'rootElementRef' | 'nodeId'
  > {
  onClick?: MasonryDriverAction;
}

export const MasonryNotificationCardContentRenderer = forwardRef<
  HTMLAnchorElement,
  MasonryNotificationCardContentProps
>(({ heading, body, info, eyebrow, onClick }, ref) => {
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout>
      {eyebrow && (
        <UtilityText css={eyebrowStyles} size="eyebrow" emphasis="emphasized">
          {eyebrow}
        </UtilityText>
      )}

      {heading && (
        <HeadingText size="sm" level="2" css={{ cursor: 'inherit' }}>
          <ConditionalLinkRenderer
            disableDefaultOnClick
            ref={ref}
            url={(onClick as MasonryDeeplinkAction)?.payload.url}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            {heading}
          </ConditionalLinkRenderer>
        </HeadingText>
      )}

      {body && (
        <ParagraphText
          css={{ '&:not(:last-child)': { marginBlockEnd: 0 } }}
          htmlStringOptions={BodyTextHtmlTagOptions}
        >
          {body}
        </ParagraphText>
      )}

      {info && (
        <ParagraphText
          emphasis="subtle"
          size="sm"
          htmlStringOptions={BodyTextHtmlTagOptions}
        >
          {info}
        </ParagraphText>
      )}
    </StackLayout>
  );
});
