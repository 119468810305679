import {
  CarousalWidgetData,
  ProgressBarCardWidgetData,
  ProgressRingCardWidgetData,
  TextWithCTACardWidgetData,
  TwoUpCardWidgetData,
  XSmallVisualCardWidgetData,
  NotificationBannerWidgetData,
} from 'types/widget-display-data.types';

type CommonDataProps = {
  overline?: string;
  title: string;
  description?: string;
  link_url?: string;
  image: string;
  image_alt_text: string;
};

type CommonProgressProps = {
  eyebrow: string;
  current_label: string;
  percentage: number;
  max_label: string;
  action_link?: string;
};

const convertToCamelCase = (str: string): string =>
  str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const createObjectMapping = (obj: { [x: string]: any }) =>
  Object.keys(obj).reduce((finalObj: { [x: string]: any }, key: string) => {
    // eslint-disable-next-line no-param-reassign
    finalObj[convertToCamelCase(key)] = obj[key];
    return finalObj;
  }, {});

const mapXSVisualCardData = (params: {
  action_link?: string;
  body?: string;
  heading: string;
  image?: string;
  image_alt_text?: string;
}): XSmallVisualCardWidgetData =>
  createObjectMapping(params) as XSmallVisualCardWidgetData;

const mapNotificationBannerData = (params: {
  body: string;
  heading: string;
  url?: string;
  image?: string;
  image_alt_text?: string;
  dismissible?: boolean;
}): NotificationBannerWidgetData =>
  createObjectMapping(params) as NotificationBannerWidgetData;

const mapLargeAndSmallVisualCardData = (params: CommonDataProps) =>
  createObjectMapping(params);

const mapTextWithCTACardData = (
  params: Required<Exclude<CommonDataProps, 'image' | 'image_alt_text'>> & {
    button_text: string;
  },
): TextWithCTACardWidgetData =>
  createObjectMapping(params) as TextWithCTACardWidgetData;

const mapCarousalData = (params: { [x: string]: any }): CarousalWidgetData =>
  ({
    ...createObjectMapping(params),
    items: params.items.map((cardItem: any) => createObjectMapping(cardItem)),
  } as CarousalWidgetData);

const mapTwoUpCardData = (params: { [x: string]: any }): TwoUpCardWidgetData =>
  ({
    ...createObjectMapping(params),
    items: params.items.map((cardItem: any) => createObjectMapping(cardItem)),
  } as TwoUpCardWidgetData);

const mapProgressBarCardData = (
  params: CommonProgressProps & {
    remaining_label: string;
  },
): ProgressBarCardWidgetData =>
  createObjectMapping(params) as ProgressBarCardWidgetData;

const mapProgressRingCardData = (
  params: CommonProgressProps & {
    image: string;
  },
): ProgressRingCardWidgetData =>
  createObjectMapping(params) as ProgressRingCardWidgetData;

// eslint-disable-next-line consistent-return
export const mapWidgetDataFromAPI = (data: any, widgetType: string) => {
  switch (widgetType) {
    case 'text_with_button':
      return mapTextWithCTACardData(data);
    case 'text_with_large_image':
      return mapLargeAndSmallVisualCardData(data);
    case 'text_with_small_image':
      return mapLargeAndSmallVisualCardData(data);
    case 'carousel':
      return mapCarousalData(data);
    case 'notification_banner':
      return mapNotificationBannerData(data);
    case 'xs_visual_card':
      return mapXSVisualCardData(data);
    case '2_up_card':
      return mapTwoUpCardData(data);
    case 'progress_bar':
      return mapProgressBarCardData(data);
    case 'progress_ring':
      return mapProgressRingCardData(data);
    default:
      return createObjectMapping(data);
  }
};
