import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { REWARDS_ACHIEVEMENTS_ASSET_MAP } from 'types/achievement.types';
import { GDSStyleObject, Icon, styled } from '@leagueplatform/genesis-core';

const StyledBadgeImage = styled(Icon);

interface BadgeImageProps {
  imageUrl: string;
  isDisabled: boolean;
  css?: GDSStyleObject;
  label?: string;
}

export const BadgeImage = ({
  imageUrl,
  isDisabled = true,
  css,
  label,
}: BadgeImageProps) => {
  const disabledBadgePlaceholder = handleStaticAsset(
    REWARDS_ACHIEVEMENTS_ASSET_MAP.ACHIEVEMENT_BADGE_PLACEHOLDER,
  ) as string;

  return imageUrl ? (
    <StyledBadgeImage
      icon={imageUrl}
      label={label}
      size="$four"
      css={{
        opacity: isDisabled ? '20%' : '100%',
        filter: `saturate(${isDisabled ? 0 : 1})`,
        ...css,
      }}
    />
  ) : (
    <StyledBadgeImage
      icon={disabledBadgePlaceholder}
      size="$four"
      css={{
        ...css,
      }}
    />
  );
};
