import React from 'react';
import { ParagraphText, HeadingText } from '@leagueplatform/genesis-core';
import { Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { CardBody } from '../../../card-body/card-body.component';
import { SpendingAccountBalance } from './components/spending-balance.component';
import { useOverview } from './hooks/use-overview.hook';

export const Overview = () => {
  const { formatMessage } = useIntl();

  const {
    canEditDependents,
    cardType,
    dependents,
    spendingAccount,
    showSpendingAccountBalanceBanner,
    body,
  } = useOverview();

  // if no body is provided, show fallback content
  if (!body) {
    return (
      <>
        <HeadingText size="xl" level="2" css={{ marginBottom: '$one' }}>
          {formatMessage({ id: 'ABOUT_THIS_BENEFIT' })}
        </HeadingText>
        <ParagraphText emphasis="subtle">
          {formatMessage({ id: 'ABOUT_THIS_BENEFIT_DESCRIPTION' })}
        </ParagraphText>
      </>
    );
  }

  return (
    <Box>
      {showSpendingAccountBalanceBanner && (
        <SpendingAccountBalance
          spendingAccount={spendingAccount}
          cardType={cardType}
          dependents={dependents}
          canEditDependents={canEditDependents}
        />
      )}
      <CardBody body={body} />
    </Box>
  );
};
