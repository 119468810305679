import { useEffect } from 'react';
import { KEY } from '../../constants/keyboard.constants';

/**
 * Handles toggling React Top Navigation Dropdown Menus via other means other than the onClick
 * Allows the user to close the dropdown
 * by clicking outside the dropdown menu or by clicking ESC
 *
 * @param {*} isOpen - useState boolean on whether the dropdown is is open (true) or is closed (false)
 * @param {*} setIsOpen - useState function to trigger isOpen to change
 * @param {*} ref - ref of the dropdown menu to infer a click event outside the dropdown menu
 */

export const useHandleDropdown = ({ isOpen, setIsOpen, ref }) => {
  useEffect(() => {
    const handleClickedOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEsc = (event) => {
      if (event.key === KEY.ESC) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickedOutside);
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('click', handleClickedOutside);
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, setIsOpen, ref]);
};
