import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { BaseButton, ButtonProps } from '../BaseButton';

/**
 * @deprecated  use `Button` from `genesis-core` instead
 */

export const DangerButton: React.FunctionComponent<ButtonProps> = forwardRef(
  (props, ref) => (
    <BaseButton
      backgroundColor="primary.background.critical.default"
      color="primary.text.critical.default"
      ref={ref}
      css={css({
        ':hover': {
          backgroundColor: 'primary.background.critical.hovered',
        },
        ':active': {
          backgroundColor: 'primary.background.critical.pressed',
        },
        ':focus': {
          backgroundColor: 'primary.background.critical.hovered',
        },
      })}
      {...props}
    />
  )
);

DangerButton.displayName = 'DangerButton';
