import * as React from 'react';
import { useHistory, Link } from '@leagueplatform/routing';
import {
  Box,
  Flex,
  genesisStyled,
  HeadingOne,
} from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  PageHeaderBackground,
} from '@leagueplatform/web-common-components';

export type HealthProfilePageWrapperProps = {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  title: string;
  hideBackButton?: boolean;
  backButtonRoute?: string;
};

const PageContainer = genesisStyled(Flex)`
  max-width: 1272px;
`;

export function HealthProfilePageWrapper({
  children,
  sidebar,
  title,
  hideBackButton = false,
  backButtonRoute,
}: HealthProfilePageWrapperProps) {
  const history = useHistory();
  const backButtonDestination = backButtonRoute || '#';
  return (
    <PageContainer
      flexWrap={{ laptop: 'noWrap', tablet: 'wrap', phone: 'wrap' }}
      margin="0 auto"
      paddingX="one"
    >
      <Flex
        marginLeft={{ _: 'none', laptop: 'two' }}
        marginRight={{ _: 'none', laptop: 'two' }}
        flexGrow={1}
        flexDirection={{ _: 'column' }}
        width="100%"
      >
        <PageHeaderBackground>
          <Flex alignItems="center" paddingBottom="one" position="relative">
            {!hideBackButton && (
              <BackButtonArrow
                as={Link}
                to={backButtonDestination}
                fontSize="heading1"
                marginRight="one"
                marginY="threeQuarters"
                justifyContent="initial"
                onClick={(event: React.MouseEvent) => {
                  if (backButtonRoute) {
                    return;
                  }
                  event.preventDefault();
                  history.goBack();
                }}
                position="absolute"
                left={{ _: '0px', laptop: '-35px' }}
                top={{ _: '0px', laptop: 'unset' }}
              />
            )}
            <HeadingOne marginTop={{ _: 'oneAndHalf', laptop: 'none' }}>
              {title}
            </HeadingOne>
          </Flex>
        </PageHeaderBackground>
        <Flex justifyContent="space-between">
          <Box
            flexGrow={1}
            flexShrink={1}
            width="100%"
            maxWidth="890px"
            marginBottom={{ _: '180px', phone: '110px' }}
          >
            {children}
          </Box>
        </Flex>
      </Flex>
      {sidebar && (
        <Box
          padding="none"
          height="100%"
          width={{ laptop: 'initial', tablet: '100%', phone: '100%' }}
          marginTop="one"
        >
          {sidebar}
        </Box>
      )}
    </PageContainer>
  );
}
