import { useLastTruthyValue } from 'hooks/use-last-truthy-value';
import { Accessors } from 'types/accessor';

export function roundAxisValueUp(value: number) {
  let roundBy = 10;
  if (value > 900) {
    roundBy = 1000;
  } else if (value > 90) {
    roundBy = 100;
  }

  const roundedValue = Math.ceil(value / roundBy) * roundBy;

  return roundedValue;
}
export function roundAxisValueUpV2(value: number) {
  let roundBy = 10;
  if (value > 5 && value <= 10) {
    roundBy = 2;
  }
  if (value > 900) {
    roundBy = 500;
  } else if (value > 90) {
    roundBy = 100;
  }

  const roundedValue = Math.ceil(value / roundBy) * roundBy;

  return roundedValue;
}

// Get the highest rounded y value
export function getRoundedYMax<Datum>(
  data: Datum[],
  accessors: Accessors<Datum, string, [number, number] | number | null>,
) {
  const maxValue = Math.max(
    ...data
      // Grab all y values
      .map(accessors.yAccessor)
      // Filter out all null values
      .filter((datum): datum is number => datum !== null)
      .flat(),
  );

  return roundAxisValueUp(maxValue);
}

// Use the last non-zero y-max value, either current or from a previous data set.
export function useGetRoundedYMax<Datum>(
  data: Datum[],
  accessors: Accessors<Datum, string, [number, number] | number | null>,
  initialValue: number,
  yMaxFromEnums: number,
) {
  let yMax = 0;

  if (data.length > 0) {
    yMax = getRoundedYMax(data, accessors);
  }

  const prevYMaxRef = useLastTruthyValue(yMax, initialValue);
  if (!yMax) {
    yMax = prevYMaxRef.current;
  }

  if (yMaxFromEnums > 0) {
    return yMaxFromEnums;
  }

  return yMax;
}
