// React
import React, { useState, useCallback, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';

// Assets
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ONBOARDING_ASSET_KEYS } from 'types/onboarding-assets.types';

// Theme
import { useIntl } from '@leagueplatform/locales';
import {
  css,
  genesisStyled,
  Box,
  Flex,
  Image,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import {
  Button,
  StackItem,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { SkeletonBox, FadeIn } from '@leagueplatform/web-common-components';
import { screenDetails } from 'constants/anayltics.constants';

// Custom Components
import { BackArrowButton } from 'components/arrow-button/back-arrow-button';
import { DotNavigation } from 'components/ui/dot-navigation';
import { FeaturesHighlightProps } from 'types/features-highlight.type';
import { FeatureSlide } from 'components/feature-slide';
import { ForwardArrowButton } from 'components/arrow-button/forward-arrow-button';
import { FeatureSlideMobile } from '../feature-slide-mobile/feature-slide-mobile.component';

// Setting these as variables so they can easily be updated.
const minWidth = '500px';
const maxWidth = '1440px';
const imageWidth = '256px';
const imagePadding = '32px';
const buttonWidth = '60px';

const BackgroundIllustration = genesisStyled(Image)(
  css({
    position: 'absolute',
    top: '0',
    right: '0',
    height: '100%',
    width: '100%',
  }),
);

const Carousel = genesisStyled(TransitionGroup)(
  css({
    position: 'relative',
    height: '100%',
    width: '100%',
  }),
);

const CarouselContainer = genesisStyled(Flex)(
  css({
    minWidth,
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }),
);

const Container = genesisStyled(Flex)(
  css({
    maxWidth,
    flex: '0 1 auto',
    width: '90%',
    marginX: 'auto',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
);

const DecorativeBackground = genesisStyled(Box)(
  css({
    minHeight: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: -1,
  }),
);

const DotNavigationContainer = genesisStyled(Box)(
  css({
    width: '100%',
    minWidth: `calc(${minWidth} + ( 2 * ${buttonWidth}) + ${imagePadding} )`,
  }),
);

const Screen = genesisStyled(Flex)(
  css({
    minWidth,
    minHeight: '100vh',
    position: 'relative',
    width: '100%',
  }),
);

const SlideContainer = genesisStyled(Box)(
  css({
    width: '100%',
  }),
);

export const FeaturesHighlightView = ({
  currentSlide = 0,
  slides,
  handleNext,
  heading,
  isLoading = false,
}: FeaturesHighlightProps) => {
  const { formatMessage } = useIntl();

  const [current, setCurrent] = useState(currentSlide);

  const forwardAnalytics = useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      ...screenDetails,
      detail: 'move to next screen clicked',
      screen_text_detail: slides[current]?.title,
      screen_number: Number(current + 1),
    });
  }, [current, slides]);

  useEffect(() => {
    if (!isLoading) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        ...screenDetails,
        screen_text_detail: slides[current]?.title,
        screen_number: Number(current + 1),
      });
    }
  }, [current, isLoading, slides]);

  const handleForwardButton = useCallback(() => {
    forwardAnalytics();
    if (current + 1 < slides.length) {
      setCurrent(current + 1);
      return;
    }
    handleNext();
  }, [current, setCurrent, forwardAnalytics, handleNext, slides]);

  const handleBackButton = useCallback(() => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  }, [current, setCurrent]);

  const handleSkip = useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      ...screenDetails,
      detail: 'feature hightlight screens skipped',
      screen_text_detail: slides[current]?.title,
      screen_number: Number(current + 1),
    });
    handleNext();
  }, [current, handleNext, slides]);

  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  if (isMobile) {
    return (
      <StackLayout
        css={{
          overflowX: 'hidden',
          width: '100vw',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        {!isLoading && (
          <Button
            onClick={handleSkip}
            quiet
            priority="tertiary"
            size="medium"
            aria-describedby="onboarding-fh-title"
            css={{
              zIndex: 99,
              position: 'absolute',
              top: '$one',
              right: '$one',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
          >
            {formatMessage({ id: 'SKIP' })}
          </Button>
        )}
        <TransitionGroup>
          <StackLayout
            css={{
              width: '100vw',
            }}
          >
            {slides.map((slide, index) => {
              const { title, description, imageUrl, imageDescription } = slide;
              const active = Number(current) === Number(index);
              return (
                <FadeIn
                  isActive={active}
                  duration={700}
                  key={title}
                  stylesProp={{
                    zIndex: active ? '1' : '-1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: active ? 'relative' : 'absolute',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                  }}
                >
                  <StackLayout
                    horizontalAlignment="center"
                    role="group"
                    aria-hidden={!active}
                    aria-labelledby={`feature-highlight-slide-${index + 1}`}
                    css={{
                      width: '100%',
                    }}
                  >
                    <VisuallyHidden id={`feature-highlight-slide-${index + 1}`}>
                      {`${formatMessage(
                        { id: 'SLIDE_OF_TOTAL' },
                        { key: index + 1, total: slides.length },
                      )}`}
                    </VisuallyHidden>
                    <FeatureSlideMobile
                      isLoading={isLoading}
                      heading={title}
                      description={description}
                      image={imageUrl}
                      alt={imageDescription ?? ''}
                      isActive={active}
                    />
                  </StackLayout>
                </FadeIn>
              );
            })}
          </StackLayout>
        </TransitionGroup>
        <StackLayout
          verticalAlignment="bottom"
          horizontalAlignment="spaceBetween"
          css={{
            width: '100%',
            zIndex: 1,
          }}
        >
          <StackItem>
            {!isLoading && (
              <DotNavigation
                total={slides.length}
                current={current}
                handleClick={setCurrent}
              />
            )}
          </StackItem>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="spaceBetween"
            css={{
              margin: '$oneAndHalf',
              flex: '0',
            }}
          >
            <BackArrowButton
              label={formatMessage({ id: 'PREVIOUS' })}
              onClick={handleBackButton}
              hidden={current === 0}
              backgroundColor="surface.background.primary"
              hoverColor="surface.background.secondary"
            />
            {isLoading ? (
              <SkeletonBox height="60px" width="60px" borderRadius="circle" />
            ) : (
              <ForwardArrowButton
                label={formatMessage({ id: 'NEXT' })}
                onClick={handleForwardButton}
              />
            )}
          </StackLayout>
        </StackLayout>
      </StackLayout>
    );
  }

  return (
    <Screen>
      {!isLoading && (
        <Button
          onClick={handleSkip}
          quiet
          priority="tertiary"
          size="large"
          aria-describedby="onboarding-fh-title"
          css={{
            zIndex: 99,
            position: 'absolute',
            top: '$one',
            right: '$one',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          }}
        >
          {formatMessage({ id: 'SKIP' })}
        </Button>
      )}
      <DecorativeBackground
        width={{
          _: 'calc(((100% / 12) * 5))',
          desktop: `calc(((100% - ${maxWidth}) / 2) + ((${maxWidth} / 12) * 5))`,
        }}
        minWidth={{
          _: `calc(8% + ${buttonWidth} + ${imageWidth} + ${imagePadding})`,
          desktop: `calc(${imageWidth} + ${buttonWidth} + (2 * ${imagePadding}))`,
        }}
      >
        <BackgroundIllustration
          src={
            handleStaticAsset(
              ONBOARDING_ASSET_KEYS.FEATURE_HIGHLIGHT_BACKGROUND,
            ) as string
          }
          alt=""
        />
      </DecorativeBackground>
      <Container>
        <VisuallyHidden>
          <h1 id="onboarding-fh-title">{heading}</h1>
        </VisuallyHidden>
        <Flex alignItems="center" width="100%">
          <BackArrowButton
            label={formatMessage({ id: 'PREVIOUS' })}
            onClick={handleBackButton}
            hidden={current === 0}
            backgroundColor="surface.background.primary"
            hoverColor="surface.background.secondary"
          />
          <CarouselContainer>
            <Box flex="1 0 100%" aria-live="polite">
              <Carousel
                component="section"
                aria-label={formatMessage({
                  id: 'FEATURES_HIGHLIGHT_CAROUSEL_LABEL',
                })}
              >
                {slides.map((slide, index) => {
                  const { title, description, imageUrl, imageDescription } =
                    slide;
                  const active = Number(current) === Number(index);
                  return (
                    <FadeIn
                      isActive={active}
                      duration={700}
                      key={title}
                      stylesProp={{
                        zIndex: active ? '1' : '-1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: active ? 'relative' : 'absolute',
                        top: '0',
                        right: '0',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                      }}
                    >
                      <SlideContainer
                        role="group"
                        aria-hidden={!active}
                        aria-labelledby={`feature-highlight-slide-${index + 1}`}
                      >
                        <VisuallyHidden
                          id={`feature-highlight-slide-${index + 1}`}
                        >
                          {`${formatMessage(
                            { id: 'SLIDE_OF_TOTAL' },
                            { key: index + 1, total: slides.length },
                          )}`}
                        </VisuallyHidden>
                        <FeatureSlide
                          isLoading={isLoading}
                          heading={title}
                          description={description}
                          image={imageUrl}
                          alt={imageDescription ?? ''}
                          isActive={active}
                        />
                      </SlideContainer>
                    </FadeIn>
                  );
                })}
              </Carousel>
            </Box>
          </CarouselContainer>
          {isLoading ? (
            <SkeletonBox height="60px" width="60px" borderRadius="circle" />
          ) : (
            <ForwardArrowButton
              label={formatMessage({ id: 'NEXT' })}
              onClick={handleForwardButton}
            />
          )}
        </Flex>
        <DotNavigationContainer>
          <Box
            width={{
              _: 'calc((100% / 12) * 5)',
              laptop: 'calc((100% / 12) * 6)',
            }}
            marginLeft="auto"
            paddingX="one"
            transform="translateY(-100%)"
            position="relative"
            zIndex="2"
          >
            <Box width="calc((100% / 7) * 5)" paddingRight="five">
              {!isLoading && (
                <DotNavigation
                  total={slides.length}
                  current={current}
                  handleClick={setCurrent}
                />
              )}
            </Box>
          </Box>
        </DotNavigationContainer>
      </Container>
    </Screen>
  );
};
