import { GROUP_TYPES } from '../constants/constants';

/**
 * Given a list of groups, try to find one that's active
 * @param {array} groups - An array of groups.
 * @return {object} The active group, or first group in the list if no active
 * groups are found.
 */
export const getActiveGroup = (groups = []) =>
  groups.find(({ suspended }) => !suspended) || groups[0];

/**
 * This function was created to check for active member with 'employer' group_type and having no fallback (see getActiveGroup for comparison). There are other files that use getActiveGroup and so we've decided not to update it for now.
 * Given a list of groups, try to find one that's active with employer group_type.
 * There are cases when a user can belong to more than one group_type.  For example, employer and user_group (community)
 * @param {array} groups - An array of groups.
 * @return {object} The active group with 'employer' group_type.
 */
export const getActiveEmployerGroup = (groups = []) =>
  groups.find(
    ({ suspended, group_type: groupType }) =>
      !suspended && groupType === GROUP_TYPES.EMPLOYER,
  );

/**
 * Lifted from Widget Runner
 * Why just the first group? I don't know...I asked on team-engineering and didn't get a clear cut answer
 * @param {array} groups - An array of groups.
 * @return {object} The active group with 'business_profile' group_type, or first group in the list if no active
 * groups are found.
 */
export const getActiveBusinessProfileGroup = (groups = []) =>
  groups.find(
    ({ suspended, groupType }) =>
      !suspended && groupType === GROUP_TYPES.BUSINESS_PROFILE,
  );
