import React from 'react';
import { Link, useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { PrimaryButton } from '@leagueplatform/genesis-commons';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { ConfirmationModal } from 'components/confirmation-modal/confirmation-modal.component';

export interface CantJoinTeamModalProps {
  userIsEnrolled: boolean;
  teamName: string;
  isSettingsDropdownOpen: boolean;
  onDismiss: () => void;
}

export const CantJoinTeamModal = ({
  userIsEnrolled,
  teamName,
  isSettingsDropdownOpen,
  onDismiss,
}: CantJoinTeamModalProps) => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  return (
    <ConfirmationModal
      heading={formatMessage({ id: 'CANT_JOIN_TEAM_TITLE' }, { teamName })}
      description={
        userIsEnrolled
          ? formatMessage({ id: 'CANT_JOIN_TEAM_DETAIL' }, { teamName })
          : formatMessage(
              { id: 'CANT_JOIN_TEAM_JOIN_CHALLENGE_DETAIL' },
              { teamName },
            )
      }
      isSettingsDropdownOpen={isSettingsDropdownOpen}
      onDismiss={onDismiss}
    >
      {!userIsEnrolled && (
        <PrimaryButton
          as={Link}
          to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
          textAlign="center"
          textDecoration="none"
        >
          {formatMessage({ id: 'VIEW_CHALLENGE_DETAILS' })}
        </PrimaryButton>
      )}
    </ConfirmationModal>
  );
};
