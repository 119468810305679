import React from 'react';
import PropTypes from 'prop-types';
import {
  HeadingOne,
  Flex,
  BodyOneSecondary,
  genesisStyled,
  css,
  Box,
} from '@leagueplatform/genesis-commons';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

const LogoContainer = genesisStyled(Box)(
  css({
    width: 55,
    height: 55,
    position: 'relative',
    marginBottom: 'four',
    '> div': {
      minHeight: 0,
    },
  }),
);

export const FrictionScreen = ({ title, description }) => (
  <Flex flexDirection="column" alignItems="center" marginTop={150}>
    <LogoContainer>
      <LoadingIndicator />
    </LogoContainer>
    <HeadingOne marginBottom="one">{title}</HeadingOne>
    <BodyOneSecondary maxWidth={400} textAlign="center">
      {description}
    </BodyOneSecondary>
  </Flex>
);

FrictionScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
