// to be converted to TS

import {
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';

export const onClickBannerActions = (setShowProgramLimitModel) => {
  setShowProgramLimitModel(true);
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.JOURNEY,
    screen_name: 'health campaign library',
    detail: 'view health campaign limit banner',
  });
};
