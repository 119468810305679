import {
  HealthMetricInput,
  HealthMetricPatchData,
} from '@leagueplatform/dashboard-api';

export function formatMetricPatchData(
  formData: any,
  metricInput: HealthMetricInput,
  metricConfigId?: string,
) {
  const metricFormData: any = {
    metricConfigId,
    metricComponentId: metricInput.metricComponentId,
    value: {},
  };

  if (formData.effectiveDateTime) {
    metricFormData.effectiveDateTime = formData.effectiveDateTime;
  }

  metricInput.valueFields.forEach((valueField) => {
    // create an array of HealthMetricPostData and sort value fields based on their index

    if (formData[valueField.fieldKeyName] !== undefined) {
      metricFormData.value.type = metricInput.inputType;

      metricFormData.value[valueField.fieldKeyName] =
        formData[valueField.fieldKeyName];
    }
  });

  return metricFormData as HealthMetricPatchData;
}
