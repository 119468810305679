import * as React from 'react';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import isFuture from 'date-fns/isFuture';
import isToday from 'date-fns/isToday';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  MoreOptionsLink,
} from '@leagueplatform/health-profile-common';
import { useIntl } from '@leagueplatform/locales';
import { Sidebar, SkeletonBox } from '@leagueplatform/web-common-components';
import { useDataTypeConfigMap } from 'constants/use-data-type-config';
import { MetricType } from '@leagueplatform/dashboard-api';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { HealthLiteracyContent } from 'components/health-literacy/health-literacy-content';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { ErrorPanel } from 'components/error-panel';
import { useChartData } from './hooks/use-chart-data';
import { HealthPrograms } from './components/health-programs';
import { HealthMetricsChart } from './components/health-metrics-chart';
import { NoDataAvailable } from './no-data-available';

type QueryStateHandlerProps = {
  children: React.ReactElement;
  query: ReturnType<typeof useChartData>;
  metric: MetricType;
};

// Helper component to render different loading and error statues.
export function QueryStateHandler({
  children,
  query,
  metric,
}: QueryStateHandlerProps): React.ReactElement {
  if (query.status === 'loading') {
    return (
      <SkeletonBox height="450px" marginTop="twoAndHalf" marginBottom="two" />
    );
  }

  if (query.status === 'error' || !query.data) {
    return (
      <ErrorPanel
        isRefetching={query.isRefetching}
        onRetry={query.refetch}
        marginBottom="two"
      />
    );
  }

  const isAnyDataAvailable = !!query.data?.meta?.isAnyDataAvailable;

  if (!isAnyDataAvailable) {
    return (
      <NoDataAvailable
        marginTop="twoAndHalf"
        marginBottom="five"
        metric={metric}
      />
    );
  }

  return children;
}

type DetailsProps = {
  match: {
    params: {
      dataType: MetricType;
    };
  };
};

export const Details = ({
  match: {
    params: { dataType },
  },
}: DetailsProps) => {
  const { formatMessage } = useIntl();
  const [chartView, setChartView] = React.useState<CHART_VIEW>(
    CHART_VIEWS.WEEKLY,
  );
  const [metricsFrom, setMetricsFrom] = React.useState(new Date());
  const chartDataQuery = useChartData({
    chartView,
    metric: dataType,
    from: metricsFrom,
  });

  const metric = useDataTypeConfigMap().metric(dataType);
  const { data: healthMetrics } = chartDataQuery;
  const isAnyDataAvailable = !!healthMetrics?.meta?.isAnyDataAvailable;
  // Unit is guaranteed to be present since we are request data for a specific metric type.
  const unit = healthMetrics?.meta?.health_metric_unit!;
  const hasPreviousPage = chartDataQuery.isPreviousData;
  const hasNextPage =
    chartDataQuery.isPreviousData ||
    isToday(metricsFrom) ||
    isFuture(metricsFrom);

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
    metric_type: dataType,
  });

  return (
    <HealthProfilePageWrapper
      title={metric}
      sidebar={
        <Sidebar>
          {isAnyDataAvailable ? (
            <MoreOptionsCard>
              <MoreOptionsLink to="all-recorded-data">
                {formatMessage(
                  { id: 'VIEW_ALL_METRIC_DATA' },
                  {
                    metric,
                  },
                )}
              </MoreOptionsLink>
              <MoreOptionsLink to="data-sources">
                {formatMessage({ id: 'VIEW_DATA_SOURCES' })}
              </MoreOptionsLink>
            </MoreOptionsCard>
          ) : null}
        </Sidebar>
      }
    >
      <QueryStateHandler query={chartDataQuery} metric={dataType}>
        <HealthMetricsChart
          healthMetrics={healthMetrics?.data ?? []}
          chartView={chartView}
          metricsFrom={metricsFrom}
          metric={dataType}
          unit={unit}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          onPreviousPage={() => {
            setMetricsFrom(
              sub(metricsFrom, {
                days: chartView === CHART_VIEWS.WEEKLY ? 7 : 30,
              }),
            );
          }}
          onNextPage={() => {
            let newMetricsFrom = add(metricsFrom, {
              days: chartView === CHART_VIEWS.WEEKLY ? 7 : 30,
            });

            if (isFuture(newMetricsFrom)) {
              newMetricsFrom = new Date();
            }

            setMetricsFrom(newMetricsFrom);
          }}
          onViewChange={(view) => {
            setChartView(view);
            // Reset the pagination to the current day when we change views.
            setMetricsFrom(new Date());
          }}
        />
      </QueryStateHandler>
      <HealthPrograms metric={dataType} />
      <HealthLiteracyContent metric={dataType} />
    </HealthProfilePageWrapper>
  );
};
