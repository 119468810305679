import { useParams } from '@leagueplatform/routing';

export interface WalletParams {
  userId?: string;
  benefitId?: string;
}

export function useWalletParams() {
  /**
   * In the admin context the member user id passed in the URL via react-router.
   * In a member context the current userId is inferred in the API calls.
   */

  return useParams<WalletParams>();
}
