import { useTheme as useContextTheme } from 'emotion-theming';
import { GenesisTheme } from './types';

export const useTheme = (): GenesisTheme => {
  const contextTheme = useContextTheme<GenesisTheme>();
  if (!contextTheme) {
    throw new Error(
      'Could not find a theme; did you forget to wrap your code in a GenesisThemeProvider and pass in a theme?'
    );
  }
  return contextTheme;
};
