import { useQuery, UseQueryOptions } from 'react-query';
import { EVENT_NAME } from '@leagueplatform/analytics';
import {
  GetUserLiveBalancesResponseV1,
  getUserBenefitLiveBalances,
  GET_USER_BENEFIT_LIVE_BALANCES_MESSAGE_TYPE,
} from '@leagueplatform/wallet-api';

import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import {
  SUCCESS,
  FAILURE,
  CATEGORY,
  ACTION_NAME,
} from '../../constants/analytics.constants';

export function useGetUserBenefitLiveBalancesQueryV1<
  TData = GetUserLiveBalancesResponseV1,
>(
  userId: string | undefined,
  benefitId: string,
  benefitName: string,
  dependentId?: string,
  options?: UseQueryOptions<GetUserLiveBalancesResponseV1, unknown, TData>,
) {
  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();
  const analyticsInfo = { category: CATEGORY, label: benefitName };
  // parse error
  // TODO: create custom error type and return whole error object
  return useQuery<GetUserLiveBalancesResponseV1, unknown, TData>(
    [
      GET_USER_BENEFIT_LIVE_BALANCES_MESSAGE_TYPE,
      userId,
      benefitId,
      dependentId,
    ],
    () => getUserBenefitLiveBalances(userId, benefitId, dependentId),
    {
      ...options,
      onSuccess: (data) => {
        // Need to send analytics here to track how often
        // we hit this endpoint as it has a query limit
        sendBenefitAnalyticsEvent(EVENT_NAME.QUERY_RETURNED, {
          ...analyticsInfo,
          action: ACTION_NAME + SUCCESS,
        });
        options?.onSuccess?.(data);
      },
      onError: (data) => {
        sendBenefitAnalyticsEvent(EVENT_NAME.QUERY_RETURNED, {
          ...analyticsInfo,
          action: ACTION_NAME + FAILURE,
        });
        options?.onError?.(data);
      },
    },
  );
}
