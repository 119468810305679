import React, { forwardRef } from 'react';
import * as RadixTooltipPrimitive from '@radix-ui/react-tooltip';
import { styled } from './stitches.config';
import { focusOutlineOuter } from './utils/focus-outline';

interface GDSThemeContainerProps {
  theme: any;
  children: React.ReactNode;
}

export const ThemeContainerBase = styled('div', {
  '[class*="GDS"]:focus': {
    ...focusOutlineOuter,
  },
  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },
});

export const ThemeContainer = forwardRef(
  (
    { theme, children, ...props }: GDSThemeContainerProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <ThemeContainerBase ref={ref} className={theme} {...props}>
      <RadixTooltipPrimitive.Provider>
        {children}
      </RadixTooltipPrimitive.Provider>
    </ThemeContainerBase>
  ),
);
