import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { SCREEN_NAMES } from '@leagueplatform/health-journey-common';

import { HEALTH_PROGRAM_STATUS } from 'constants/health-program-status.constants';

import { useActiveCampaignInfo } from '../../../hooks/use-active-campaign-limit-info.hook';

export const useProgramDetailsAnalytics = (programName, programId, status) => {
  const { data: campaignLimitInfo = {} } = useActiveCampaignInfo();
  const {
    number_of_enrolled_programs: activeCampaigns,
    max_programs_limit: campaignLimit,
  } = campaignLimitInfo;

  const baseAnalytics = {
    product_area: PRODUCT_AREA.JOURNEY,
    health_campaign_name: programName,
    health_campaign_id: programId,
    screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
  };

  const numOfCampaignData = {
    num_health_campaign_active: activeCampaigns,
    num_health_campaign_limit: campaignLimit,
  };
  return {
    viewProgramDetailsRemoveModal: () =>
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        ...baseAnalytics,
        screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_REMOVAL,
      }),
    viewProgramDetailsPage: () =>
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        ...baseAnalytics,
        is_active_health_campaign: status === HEALTH_PROGRAM_STATUS.ACTIVE,
      }),
    detailsBackButton: () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseAnalytics,
        detail: 'close',
        is_active_health_campaign: status === HEALTH_PROGRAM_STATUS.ACTIVE,
      }),
    leaveProgram: () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseAnalytics,
        ...numOfCampaignData,
        detail: 'remove health campaign',
      }),
    leaveConfirm: () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseAnalytics,
        screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_REMOVAL,
        detail: 'confirm health campaign removal',
      }),
    leaveCancel: () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseAnalytics,
        screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_REMOVAL,
        detail: 'cancel health campaign removal',
      }),
    addProgram: () =>
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        ...baseAnalytics,
        ...numOfCampaignData,
        detail: 'enroll in health campaign',
      }),
  };
};
