import { leagueFetch } from '@leagueplatform/league-fetch';
import { QUICK_ACTIONS_PATH } from '../constants/care-discovery.constants';
import { QuickActionsData } from '../types/care-discovery.types';

export const getQuickAction = async (
  quickActionId: string,
): Promise<QuickActionsData> => {
  const response = await leagueFetch(
    `${QUICK_ACTIONS_PATH}/${quickActionId}?include=quick-actions-parent`,
  );

  return response.json();
};
