import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { Flex, Box } from '../primitives.view';
import { Input } from '../input/input.view';
import { Select } from '../select/select.view';

export const LegacyUIKitDateInputPresenter = ({
  day,
  onChangeDay,
  month,
  onChangeMonth,
  year,
  onChangeYear,
  onBlur,
  disabled,
  hasError,
  id,
  name,
  dayInputRef,
  monthInputRef,
  yearInputRef,
  autoComplete,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const months = useMemo(
    () =>
      Array(12)
        .fill()
        .map((_, m) => ({
          text: formatDate(new Date(0, m, 10), {
            month: 'long',
          }),
          value: String(m),
        })),
    [formatDate],
  );

  const monthLabel = formatMessage({ id: 'MONTH' });
  const dayLabel = formatMessage({ id: 'DAY' });
  const yearLabel = formatMessage({ id: 'YEAR' });

  return (
    <Flex onBlur={onBlur} id={id} name={name}>
      <Select
        id={`${name || id}_month`}
        options={months}
        onChange={onChangeMonth}
        onSubmit={() => {}}
        width={5 / 10}
        disabled={disabled}
        aria-required="true"
        aria-label={monthLabel}
        hasError={hasError}
        selectedValue={month}
        noSelectionLabel={monthLabel}
        my={0}
        ref={monthInputRef}
        autoComplete={autoComplete}
      />
      <Box ml={1} width={2 / 10}>
        <Input
          fit
          id={`${name || id}_day`}
          type="text"
          placeholder={dayLabel}
          value={day}
          onChange={onChangeDay}
          disabled={disabled}
          aria-required="true"
          aria-label={dayLabel}
          ref={dayInputRef}
          hasError={hasError}
          autoComplete={autoComplete}
        />
      </Box>
      <Box ml={1} width={3 / 10}>
        <Input
          fit
          id={`${name || id}_year`}
          type="text"
          placeholder={yearLabel}
          value={year}
          onChange={onChangeYear}
          disabled={disabled}
          aria-required="true"
          aria-label={yearLabel}
          ref={yearInputRef}
          hasError={hasError}
          autoComplete={autoComplete}
        />
      </Box>
    </Flex>
  );
};

const refPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current:
      typeof Element !== 'undefined'
        ? PropTypes.instanceOf(Element)
        : PropTypes.any,
  }),
]);

LegacyUIKitDateInputPresenter.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  onChangeDay: PropTypes.func,
  onChangeMonth: PropTypes.func,
  onChangeYear: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  dayInputRef: refPropType,
  monthInputRef: refPropType,
  yearInputRef: refPropType,
  autoComplete: PropTypes.string,
};
LegacyUIKitDateInputPresenter.defaultProps = {
  day: '',
  month: '',
  year: '',
  autoComplete: 'off',
  onChangeDay: () => {},
  onChangeMonth: () => {},
  onChangeYear: () => {},
  onBlur: () => {},
  disabled: false,
  hasError: false,
  id: null,
  name: null,
  dayInputRef: null,
  monthInputRef: null,
  yearInputRef: null,
};
