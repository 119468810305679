/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import type { RangeGraph as RangeGraphType } from './types/range-graph';
import { WeeklyRangeGraph } from './weekly-range-graph';
import { MonthlyRangeGraph } from './monthly-range-graph';

export type RangeGraphProps<Datum> = RangeGraphType<Datum> & {
  chartView: CHART_VIEW;
};

export function RangeGraph<Datum>({
  chartView,
  ...props
}: RangeGraphProps<Datum>) {
  if (chartView === CHART_VIEWS.WEEKLY) {
    return <WeeklyRangeGraph {...props} />;
  }
  if (chartView === CHART_VIEWS.MONTHLY) {
    return <MonthlyRangeGraph {...props} />;
  }
  return null;
}
