import { leagueFetch } from '@leagueplatform/league-fetch';
import { captureError } from '@leagueplatform/observability';
import { deserialise } from 'kitsu-core';
import { MESSAGING_CONFIG_PATH } from './constants/messaging-requests.constants';
import { MessagingConfigData } from './types/messaging.types';

export const getMessagingConfig = async (): Promise<MessagingConfigData> => {
  const response = await leagueFetch(`${MESSAGING_CONFIG_PATH}`);

  if (!response.ok) {
    throw new Error(`get messaging config error: ${response.status}`);
  }

  if (response.ok) {
    const clonedResponse = await response.clone().json();
    // Capture error if API returns empty configuration or bad data
    if (!clonedResponse.data?.length) {
      captureError(
        new Error(
          'API get messaging config error: missing configuration or bad data',
        ),
        {
          tags: { requestUrl: response.url },
          context: {
            'Response Info': {
              response: JSON.stringify(clonedResponse),
            },
          },
        },
      );
    }
  }

  return deserialise(response.json());
};
