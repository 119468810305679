import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import _shouldForwardProp from '@styled-system/should-forward-prop';
import {
  space,
  color,
  border,
  shadow,
  position,
  background,
  layout,
  flexbox,
  typography,
  system,
  compose,
} from 'styled-system';
import { StyleProps, PseudoStateStyleProps, ThemeProps } from '../theme/types';

export interface BoxProps extends StyleProps {
  className?: string;
  ref?: any;
}

interface BaseProps extends ThemeProps {
  visitedStyle?: PseudoStateStyleProps;
  focusStyle?: PseudoStateStyleProps;
  hoverStyle?: PseudoStateStyleProps;
  activeStyle?: PseudoStateStyleProps;
  disabledStyle?: PseudoStateStyleProps;
}

const outlineSystemProps = {
  outline: true,
  outlineColor: {
    property: 'outlineColor',
    scale: 'colors',
  },
  outlineStyle: {
    property: 'outlineStyle',
    scale: 'borderStyles',
  },
  outlineWidth: {
    property: 'outlineWidth',
    scale: 'borderWidths',
  },
};

const customSystemProps = system({
  ...outlineSystemProps,
  typography: {
    property: 'typography',
    scale: 'typography',
  },
  transitionDuration: {
    property: 'transitionDuration',
    scale: 'durations',
  },
  cursor: true,
  textDecoration: true,
  textTransform: true,
  transition: true,
  transform: true,
});

const styleFunctions = [
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography,
  customSystemProps,
];

const styleProps = compose(...styleFunctions);

const pseudoStateStyleProps = compose(
  ...styleFunctions.filter((prop) => ![typography].includes(prop))
);

const shouldForwardProp = (prop) => {
  switch (prop) {
    case 'textDecoration':
      return false;
    default:
      return _shouldForwardProp(prop);
  }
};

export const Base: React.FunctionComponent<BoxProps> = styled('div', {
  shouldForwardProp,
})({ boxSizing: 'border-box' }, (props: BaseProps) => ({
  ...styleProps(props),
  '&:visited': pseudoStateStyleProps({
    theme: props.theme,
    ...props.visitedStyle,
  }),
  '&:focus': pseudoStateStyleProps({
    theme: props.theme,
    ...props.focusStyle,
  }),
  '&:focus-visible': pseudoStateStyleProps({
    theme: props.theme,
    ...props.focusStyle,
  }),
  '&:hover': pseudoStateStyleProps({
    theme: props.theme,
    ...props.hoverStyle,
  }),
  '&:active': pseudoStateStyleProps({
    theme: props.theme,
    ...props.activeStyle,
  }),
  '&:disabled': pseudoStateStyleProps({
    theme: props.theme,
    ...props.disabledStyle,
  }),
})) as React.FunctionComponent<BoxProps>;

function hasBorderWidth(props) {
  return Object.keys(props).find((prop) => prop.match(/border.*Width/));
}

/**
 * Facilitates default border functionality where setting borderWidth is all
 * that is required to set default border color and style as well.
 * @param {object} props  the component props
 * @param {string} prop  the border prop to check
 * @param {number|string}  defaultValue default value to enable
 */
export function borderValue(
  props: BoxProps,
  prop: string,
  defaultValue: string | number
) {
  const value = props[prop];
  if (value) {
    return value;
  }
  return hasBorderWidth(props) ? defaultValue : undefined;
}

/**
 * @deprecated use `Box` from `genesis-core` instead
 * @param className: the className prop is not supported by `styled-system` so we have to include it here
 */
export const Box: React.FunctionComponent<BoxProps> = forwardRef(
  (
    {
      fontSize,
      lineHeight = fontSize,
      className,
      border,
      borderX,
      borderY,
      borderTop,
      borderBottom,
      borderRight,
      borderLeft,
      ...props
    },
    ref
  ) => {
    // these props are pulled out to ensure we can spread them before any
    // specific border props (or defaults)
    const decreaseBorderShorthandSpecificity = {
      border,
      borderX,
      borderY,
      borderTop,
      borderBottom,
      borderRight,
      borderLeft,
    };

    return (
      <Base
        ref={ref}
        margin="none"
        padding="none"
        fontFamily="body"
        fontSize={fontSize}
        lineHeight={lineHeight}
        {...decreaseBorderShorthandSpecificity}
        borderColor={borderValue(
          props,
          'borderColor',
          'onSurface.border.subdued'
        )}
        borderStyle={borderValue(props, 'borderStyle', 'solid')}
        borderWidth={borderValue(props, 'borderWidth', 0)}
        className={['genesis', className].filter(Boolean).join(' ')}
        {...props}
      />
    );
  }
);

Box.displayName = 'Box';
