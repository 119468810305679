import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { Traits } from '@leagueplatform/masonry-api';

export const useGetTraitsForMedia = (traits: Traits | undefined) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  // Covering mobile landscape and tablet media queries in isTablet
  const isTablet = useMediaQuery(
    queryHelpers.between(['mobileLandscape', 'laptop']),
  );
  const isLaptop = useMediaQuery(queryHelpers.only('laptop'));
  const isDesktop = useMediaQuery(queryHelpers.up('desktop'));

  if (isMobile) return traits?.mobile;
  if (isTablet) return traits?.tablet;
  if (isLaptop) return traits?.laptop;
  if (isDesktop) return traits?.desktop;
  return {};
};
