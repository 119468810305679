import styled, { css } from 'styled-components';
import { Box } from './primitives.view';
import { colour } from '../theme';

export const Divider = styled(Box)`
  ${({ topBorder }) =>
    topBorder &&
    css`
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(
          to right,
          ${colour('white')},
          ${colour('navyLightest')} 20%,
          ${colour('navyLightest')} 80%,
          ${colour('white')}
        );
      }
    `};

  ${({ bottomBorder }) =>
    bottomBorder &&
    css`
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(
          to right,
          ${colour('white')},
          ${colour('navyLightest')} 20%,
          ${colour('navyLightest')} 80%,
          ${colour('white')}
        );
      }
    `};
`;
