import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE =
  'get_wallet_items_sectioned';

export const getWalletItemsSectioned = async (userId?: string) => {
  const info = {
    id: 'wallet_item_navigation_sections',
    user_id: userId,
  };

  const params = {
    message_type: GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
