import React from 'react';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import {
  BackButtonArrow,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import { Link, useParams } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { noopFunction } from 'constants/challenges.constants';
import { TLP_MAX_WIDTH } from 'pages/team-landing-page/constants/team-landing-page.constants';

export const TLPSkeletonLoader = (): JSX.Element => {
  const { challengeId } = useParams<{ challengeId: string }>();

  return (
    <Box
      padding={{ _: 'oneAndHalf', phone: 'twoAndHalf' }}
      marginX="auto"
      maxWidth={TLP_MAX_WIDTH}
    >
      <BackButtonArrow
        as={Link}
        justifyContent="initial"
        marginRight="quarter"
        marginY="half"
        onClick={noopFunction}
        to={JOURNEY_ROUTES.getChallengeDetails(challengeId)}
        size="three"
      />
      {/* Full-width banner Skeleton Loader */}
      <SkeletonBox
        backgroundColor="interactive.background.disabled"
        height={{ _: '400px', laptop: '200px' }}
        role="presentation"
        marginBottom="one"
      />
      <Flex
        flexDirection={{ _: 'column-reverse', laptop: 'row' }}
        alignItems={{ _: 'center', laptop: 'initial' }}
      >
        <Box
          paddingRight={{ laptop: 'two' }}
          marginBottom="twoAndHalf"
          width="100%"
        >
          {/* Highlights Skeleton Loader */}
          <SkeletonBox
            width="100%"
            height="632px"
            backgroundColor="interactive.background.disabled"
            role="presentation"
          />
        </Box>

        {/* Challenges, Progress & Leaderboard Skeleton Loader */}
        <Box flexDirection="column" width={{ _: '100%', laptop: '60%' }}>
          <SkeletonBox
            height="100px"
            backgroundColor="interactive.background.disabled"
            marginBottom="one"
            role="presentation"
          />
          <SkeletonBox
            marginBottom="one"
            height="200px"
            backgroundColor="interactive.background.disabled"
            role="presentation"
          />
          <SkeletonBox
            height="300px"
            backgroundColor="interactive.background.disabled"
            role="presentation"
            marginBottom="two"
          />
        </Box>
      </Flex>
    </Box>
  );
};
