import { HealthMetricData } from 'components/charts/core/range-graph/utils/use-health-metric-values';
import {
  HealthMetricAverageValue,
  HealthMetricUnit,
  HealthMetricValue,
} from '@leagueplatform/dashboard-api';
import { getHealthMetricDisplayValue } from './get-health-metric-display-value';
import { getHealthMetricUnitDisplayValue } from './get-health-metric-unit-display-value';

type GetValueUnitDisplayLabelProps = {
  unitOptions: HealthMetricUnit;
  value:
    | HealthMetricData[number]['value']
    | HealthMetricValue['value']
    | HealthMetricAverageValue['value'];
  label?: HealthMetricData[number]['label'] | HealthMetricValue['label'];
};

export function getValueUnitDisplayLabel({
  unitOptions,
  value,
  label,
}: GetValueUnitDisplayLabelProps) {
  const displayValue = getHealthMetricDisplayValue({ value, label });

  const unit = getHealthMetricUnitDisplayValue(displayValue, unitOptions);

  if (displayValue.includes(';')) {
    const arrayOfSubUnits = displayValue
      ?.split(';')
      .map((str) => str.split('|'));
    return arrayOfSubUnits;
  }
  return [[displayValue, unit]];
}
