import { useQuery, UseQueryOptions } from 'react-query';
import {
  GET_WALLET_MESSAGE_TYPE,
  getWalletBenefitCard,
  UserWalletBenefitCard,
} from '@leagueplatform/wallet-api';

// setting a staleTime of 5 minutes to avoid unnecessary refetches
const STALE_TIME = 1000 * 60 * 5;

export function useWalletDetailQuery<TSelect = UserWalletBenefitCard>(
  userId: string | undefined,
  // TODO: benefitId should never be undefined for this query, but
  // removing `undefined` makes it clash with `useWalletParams`
  benefitId: string | undefined,
  dependentId?: string,
  options?: UseQueryOptions<UserWalletBenefitCard, unknown, TSelect>,
) {
  return useQuery<UserWalletBenefitCard, unknown, TSelect>(
    [GET_WALLET_MESSAGE_TYPE, userId, benefitId, dependentId],
    () => getWalletBenefitCard(userId, benefitId, dependentId),
    {
      staleTime: STALE_TIME,
      ...options,
    },
  );
}
