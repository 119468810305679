import { BenefitCardAction, SYSTEM_TYPES } from '@leagueplatform/wallet-api';
import { CLAIM_URL } from 'utils/utils';

export const isSubmitClaimAction = (action: BenefitCardAction) =>
  action.url?.includes(CLAIM_URL);
export const isSaveCardAction = (action: BenefitCardAction) =>
  action.system_type === 'save_card';
export const isBenefitDocumentAction = (action: BenefitCardAction) =>
  action.system_type === 'benefit_document' && action.type === 'open-url';
export const isPhoneNumberAction = (action: BenefitCardAction) =>
  action.system_type === SYSTEM_TYPES.CallPhone;
export const isExternalLinkAction = (action: BenefitCardAction) =>
  action?.url?.toLowerCase().startsWith('http');
export const isEmailLinkAction = (action: BenefitCardAction) =>
  action?.url?.toLowerCase().includes('mailto:');
export const isUnavailableAction = (action: BenefitCardAction) =>
  action.unavailable_action !== undefined;
export const isGenericContentModal = (action: BenefitCardAction) =>
  action.system_type === 'modal_content';
export const isClaimsAction = (action: BenefitCardAction) =>
  action.url?.toLocaleLowerCase().includes('claims-authorization');
export const isPrintCardAction = (action: BenefitCardAction) =>
  action.system_type === 'print_card';
export const isOrderNewCardAction = (action: BenefitCardAction) =>
  action.system_type === 'order_card';
