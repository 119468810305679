/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import {
  styled,
  Icon,
  GDSStyleObject,
  Spinner,
  StackLayout,
} from '@leagueplatform/genesis-core';

export type SwitchIndicatorProps = {
  css?: GDSStyleObject;
  className?: string;
  id: string;
  checked: boolean;
  hideIcon?: boolean;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  ariaDisabled?: boolean;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  value?: string;
  required?: boolean;
  onClick?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const SwitchRoot = styled(RadixSwitch.Root, {
  $$borderThickness: '2px',
  $$thumbSize: '20px',
  all: 'unset',
  width: '$three',
  minWidth: '$three',
  height: 'calc($$thumbSize + $$borderThickness*2)',
  backgroundColor: '$interactiveActionSubdued',
  borderRadius: '$pill',
  position: 'relative',
  '.GDS-icon': {
    transition: 'opacity $transitions$defaultTime',
  },
  '&:focus': {
    boxShadow: '0 0 0 $$borderThickness $interactiveActionPrimary',
  },
  '&[data-state="checked"]': {
    backgroundColor: '$interactiveActionPrimary',
    '.GDS-icon': {
      opacity: '1',
    },
  },
  '&[data-state="unchecked"]': {
    '.GDS-icon': {
      opacity: '0',
    },
  },
  '&[disabled]': {
    opacity: 0.5,
  },
});

const SwitchThumb = styled(RadixSwitch.Thumb, {
  display: 'block',
  width: '$$thumbSize',
  height: '$$thumbSize',
  backgroundColor: '$interactiveBackgroundDefault',
  borderRadius: '$circle',
  transition: 'transform $transitions$defaultTime',
  transform: 'translateX($$borderThickness)',
  willChange: 'transform',
  '&[data-state="checked"]': {
    transform: 'translateX(calc($space$oneAndHalf + $$borderThickness))',
  },
});

const Wrapper = styled('span', {
  variants: {
    loading: {
      true: {
        '.cc-switch-root': {
          backgroundColor: '$none',
        },
        '.cc-switch-thumb, .cc-switch-icon': {
          display: 'none',
        },
      },
    },
  },
});

export const SwitchIndicator = ({
  css,
  className = '',
  id,
  checked,
  hideIcon,
  ariaLabelledBy,
  ariaLabel,
  ariaDisabled,
  loading,
  onChange,
  ...props
}: SwitchIndicatorProps) => (
  <Wrapper
    loading={loading}
    className={['cc-switch-root-wrapper', className].join(' ')}
  >
    <SwitchRoot
      id={id}
      onCheckedChange={onChange}
      checked={checked}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
      aria-disabled={ariaDisabled}
      className="cc-switch-root"
      {...props}
    >
      {!hideIcon && (
        <Icon
          // without a label, aria-hidden is set to true on this Icon by default.
          icon="interfaceCheckmark"
          className="cc-switch-icon"
          data-testid="switch-icon"
          tint="$interactiveIconDefault"
          css={{
            position: 'absolute',
            top: '$none',
            left: '$$borderThickness',
          }}
        />
      )}
      {loading && (
        <StackLayout
          css={{ width: '$three', flex: 'unset' }}
          horizontalAlignment="spaceAround"
          orientation="horizontal"
        >
          <Spinner loading={loading} size="20px" data-testid="switch-spinner" />
        </StackLayout>
      )}
      <SwitchThumb className="cc-switch-thumb" />
    </SwitchRoot>
  </Wrapper>
);
