import { MasonryEngineNodeRendererMap } from '@leagueplatform/masonry-engine';
import {
  MasonryCarouselRenderer,
  MasonryColumnLayoutRenderer,
  MasonryCTACardRenderer,
  MasonryDriverNode,
  MasonryHeroBannerRenderer,
  MasonryImageBannerRenderer,
  MasonryLargeCardRenderer,
  MasonryMetricCardRenderer,
  MasonryNotificationBannerRenderer,
  MasonryNotificationCardRenderer,
  MasonryProgressBarCardRenderer,
  MasonryProgressRingCardRenderer,
  MasonrySectionHeaderRenderer,
  MasonrySmallCardRenderer,
  MasonryMicroCardRenderer,
  MasonryExtraSmallCardRenderer,
  MasonryVerticalLayoutRenderer,
  MasonryHorizontalLayoutRenderer,
  MasonryGridLayoutRenderer,
  MasonryLoaderRenderer,
  MasonryTabsRenderer,
} from '@leagueplatform/masonry-renderers';
import {
  MasonrySpinner,
  MasonrySpinnerNode,
} from 'components/spinner/spinner.component';

export const MasonryDriverDefaultNodeRendererMap: MasonryEngineNodeRendererMap<
  MasonryDriverNode | MasonrySpinnerNode
> = {
  largeCard: MasonryLargeCardRenderer,
  smallCard: MasonrySmallCardRenderer,
  extraSmallCard: MasonryExtraSmallCardRenderer,
  ctaCard: MasonryCTACardRenderer,
  microCard: MasonryMicroCardRenderer,
  metricCard: MasonryMetricCardRenderer,
  carousel: MasonryCarouselRenderer,
  verticalLayout: MasonryVerticalLayoutRenderer,
  horizontalLayout: MasonryHorizontalLayoutRenderer,
  gridLayout: MasonryGridLayoutRenderer,
  heroBanner: MasonryHeroBannerRenderer,
  notificationBanner: MasonryNotificationBannerRenderer,
  notificationCard: MasonryNotificationCardRenderer,
  sectionHeader: MasonrySectionHeaderRenderer,
  progressRing: MasonryProgressRingCardRenderer,
  progressBar: MasonryProgressBarCardRenderer,
  imageBanner: MasonryImageBannerRenderer,
  hidden: () => null,
  // @TODO fix naming of column layout type
  column_layout: MasonryColumnLayoutRenderer,
  loader: MasonryLoaderRenderer,
  containerSpinner: MasonrySpinner,
  tabs: MasonryTabsRenderer,
};

export type MasonryDriverDefaultNodeType =
  keyof typeof MasonryDriverDefaultNodeRendererMap;
