import React, { ElementType, useMemo } from 'react';
import * as RadixAccordionPrimitive from '@radix-ui/react-accordion';
import { GDSHeadingTextProps, HeadingText } from '@leagueplatform/genesis-core';
import { AccordionStyleProps } from './accordion-root.component';

export interface AccordionHeaderProps
  extends GDSHeadingTextProps<ElementType>,
    AccordionStyleProps {
  children: React.ReactNode;
}

export const Header = React.forwardRef<
  HTMLHeadingElement,
  AccordionHeaderProps
>(({ className, ...props }, forwardedRef) => {
  const composedClassName = useMemo(
    () => ['cc-accordion-header', className].join(' '),
    [className],
  );

  return (
    <RadixAccordionPrimitive.Header asChild>
      <HeadingText
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={composedClassName}
        ref={forwardedRef}
      />
    </RadixAccordionPrimitive.Header>
  );
});

Header.displayName = 'Accordion.Header';
