import React from 'react';
import {
  css,
  Flex,
  genesisStyled,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import {
  usePageViewAnalytics,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { CarePageWrapper } from '@leagueplatform/care-common';
import { QuickAction } from '@leagueplatform/care-discovery-api';
import { useGetCareLanding } from '../../hooks/use-get-care-landing.hook';
import { QuickActionMenuItem } from '../../components/quick-action-menu-item/quick-action-menu-item.component';
import { GetAssistanceNow } from '../../components/get-assistance-now/get-assistance-now.component';
import { CareTeamCard } from '../../components/care-team-card/care-team-card.component';

const StyledList = genesisStyled('ul')(
  css({
    '> li:not(:last-child)': {
      marginBottom: 'oneAndHalf',
    },
  }),
);

export const GetCareLandingPage = () => {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: SCREEN_NAMES.HOMEPAGE,
    screen_name: SCREEN_NAMES.HOMEPAGE,
  });

  useDocumentTitle(formatMessage({ id: 'GET_CARE' }));

  const { isLoading, isError, data } = useGetCareLanding();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <CarePageWrapper>
        <ErrorState />
      </CarePageWrapper>
    );
  }

  const { quickActions, careProviders, careTeam } = data;

  return (
    <CarePageWrapper title={formatMessage({ id: 'GET_CARE' })}>
      <Flex
        flexDirection={{ _: 'column-reverse', phone: 'row' }}
        marginBottom={{ _: 'twoAndHalf', phone: 'five' }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <HeadingTwo marginBottom="oneAndHalf">
            {formatMessage({ id: 'HOW_CAN_WE_HELP' })}
          </HeadingTwo>
          <StyledList>
            {quickActions.map((quickAction: QuickAction) => {
              const { id } = quickAction;
              const { title, url, icon, description } = quickAction.attributes;

              return (
                <li key={id}>
                  <QuickActionMenuItem
                    title={title}
                    url={url}
                    icon={icon}
                    description={description}
                    analyticsEvent={() =>
                      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                        product_area: PRODUCT_AREA.GET_CARE,
                        sub_product_area: SCREEN_NAMES.HOMEPAGE,
                        screen_name: SCREEN_NAMES.HOMEPAGE,
                        detail: 'care menu',
                        sub_detail: title.toLowerCase(),
                      })
                    }
                  />
                </li>
              );
            })}
          </StyledList>
        </Flex>
        {careTeam && (
          <Flex
            marginLeft={{ _: 'none', phone: 'twoAndHalf' }}
            marginBottom={{ _: 'twoAndHalf', phone: 'none' }}
            flexDirection="column"
            width={{ _: 'auto', phone: 327 }}
          >
            <HeadingTwo marginBottom="oneAndHalf">
              {formatMessage({ id: 'YOUR_CARE_ADVISOR' })}
            </HeadingTwo>
            <CareTeamCard teamInfo={careTeam?.attributes?.participant} />
          </Flex>
        )}
      </Flex>
      {careProviders.length > 0 && (
        <GetAssistanceNow careProviders={careProviders} />
      )}
    </CarePageWrapper>
  );
};
