import { WearablesConsentForDataPointsResponse } from '../types/wearables-consent-for-data-points-response.type';

/**
 * Returns a consolidated list of all granted device permissions.
 */
export function findGrantedWearablePermissions(
  devices: WearablesConsentForDataPointsResponse['receiving_data_v2'],
) {
  const grantedPermissions = Object.values(devices).reduce<string[]>(
    (accum, devicePermissions) => {
      const grantedDevicePermissions = Object.entries(devicePermissions)
        .filter(([, granted]) => granted)
        .map(([permission]) => permission);

      return [...accum, ...grantedDevicePermissions];
    },
    [],
  );

  const removedDuplicatedPermissions = [...new Set(grantedPermissions)];

  return removedDuplicatedPermissions;
}
