import * as React from 'react';
import Masonry, { getMasonryDriver } from '@leagueplatform/masonry';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics';
import {
  ABOUT_ME_APP_ID,
  ABOUT_ME_MASONRY_API_VERSION,
} from 'constants/masonry';
import { HorizontalBarGraphInsightWidget } from 'widgets/horizontal-bar-graph-insight-widget';
import { VerticalBarGraphInsightWidget } from 'widgets/vertical-bar-graph-insight-widget';
import { useIsInsightsImprovementsEnabled } from 'hooks/use-is-insights-improvements-enabled';
import { StackLayout } from '@leagueplatform/genesis-core';

const MasonryComposer = Masonry.init({
  appId: ABOUT_ME_APP_ID,
  apiVersion: ABOUT_ME_MASONRY_API_VERSION,
  analytics: {
    productArea: PRODUCT_AREA.ABOUT_ME,
    screenName: `${SCREEN_NAME.ABOUT_ME} ${SCREEN_NAME.OVERVIEW}`,
  },
  customWidgetModules: [
    {
      moduleKey: 'dashboard',
      customWidgetDataHooks: {},
      customWidgetsUI: [
        {
          type: 'insight_card_horizontal_bar_graph',
          widget: ({ widget }) => (
            <HorizontalBarGraphInsightWidget widget={widget} />
          ),
        },
        {
          type: 'insight_card_vertical_bar_graph',
          widget: ({ widget }) => (
            <VerticalBarGraphInsightWidget widget={widget} />
          ),
        },
      ],
    },
  ],
});

const MasonryOverviewDriver = getMasonryDriver({
  rootNodeId: 'aboutMeOverview$root',
});

export function AboutMeOverview() {
  const { isInsightsImprovementsEnabled } = useIsInsightsImprovementsEnabled();

  if (isInsightsImprovementsEnabled) {
    return (
      <StackLayout
        orientation="vertical"
        verticalAlignment="center"
        horizontalAlignment="stretch"
        spacing="$two"
      >
        <MasonryEngine driver={MasonryOverviewDriver} />
      </StackLayout>
    );
  }
  return <MasonryComposer.Provider />;
}
