import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { useIntl } from 'react-intl';
import {
  Box,
  Flex,
  PrimaryButton,
  SubtleButton,
  Link,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { Modal } from 'ui-kit/components/modal';

const MODAL_DESCRIPTION = 'uikit-modal-description';

const ContentBox = genesisStyled(Box)`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const TooltipModalLabel = (props) => (
  <Flex justifyContent="center" {...props} />
);

const TooltipContent = (props) => (
  <Box
    backgroundColor="surface.background.primary"
    borderRadius="small"
    borderStyle="solid"
    borderColor="onSurface.border.subdued"
    padding="oneAndHalf"
    margin="auto"
    aria-labelledby={MODAL_DESCRIPTION}
    {...props}
  />
);

const ModalContentProps = {
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export const FullscreenModalContent = ({ closeModal, content }) => {
  const { formatMessage } = useIntl();
  const focusRef = (node) => node?.focus?.();

  return (
    <Flex
      flexDirection="column"
      backgroundColor="surface.background.primary"
      position="absolute"
      zIndex="modal"
      height="100vh"
      left={0}
      right={0}
      top={0}
      paddingTop={66}
    >
      <TooltipContent flexGrow={1} overflow="auto">
        <ContentBox>
          <Box
            fontSize="body1"
            color="onSurface.text.primary"
            id={MODAL_DESCRIPTION}
          >
            {content}
          </Box>
        </ContentBox>
      </TooltipContent>
      <Box
        padding="one"
        width={1}
        backgroundColor="surface.background.primary"
        borderTopWidth="thin"
        borderTopStyle="solid"
        borderTopColor="onSurface.border.subdued"
      >
        <PrimaryButton
          marginTop="none"
          width={1}
          onClick={closeModal}
          ref={focusRef}
        >
          {formatMessage({ id: 'GOT_IT' })}
        </PrimaryButton>
      </Box>
    </Flex>
  );
};

FullscreenModalContent.propTypes = ModalContentProps;

export const ModalContent = ({ closeModal, content }) => {
  const { formatMessage } = useIntl();
  const focusRef = (node) => node?.focus?.();

  return (
    <>
      <TooltipContent maxWidth="90%" width={350}>
        <Box
          fontSize="body1"
          color="onSurface.text.primary"
          id={MODAL_DESCRIPTION}
        >
          {content}
        </Box>
        <PrimaryButton
          marginTop="one"
          width={1}
          onClick={closeModal}
          ref={focusRef}
        >
          {formatMessage({ id: 'GOT_IT' })}
        </PrimaryButton>
      </TooltipContent>
    </>
  );
};

ModalContent.propTypes = ModalContentProps;

export const TooltipModalContent = ({ isFullScreen, closeModal, ...props }) => {
  return (
    <Modal.Wrapper onClose={closeModal} {...props}>
      <FocusTrap>
        {isFullScreen ? (
          <FullscreenModalContent closeModal={closeModal} {...props} />
        ) : (
          <ModalContent closeModal={closeModal} {...props} />
        )}
      </FocusTrap>
    </Modal.Wrapper>
  );
};

TooltipModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool,
  content: PropTypes.node.isRequired,
};

TooltipModalContent.defaultProps = {
  isFullScreen: false,
};

const LinkButton = genesisStyled(Link)(
  css({
    backgroundColor: 'transparent',
    border: 'none',
  }),
);

export const TooltipModalPresenter = ({
  isModalOpen,
  openModal,
  closeModal,
  label,
  buttonTabIndex,
  onLabelKeyDown,
  ...props
}) => {
  return (
    // This component must be enveloped in actual DOM (not a fragment)
    // to prevent the label from shifting when the modal is open.
    <span>
      {label && (
        <TooltipModalLabel {...props}>
          <LinkButton
            as="button"
            onClick={openModal}
            tabIndex={buttonTabIndex}
            onKeyDown={onLabelKeyDown}
          >
            {label}
          </LinkButton>
        </TooltipModalLabel>
      )}

      {isModalOpen ? (
        <TooltipModalContent {...props} closeModal={closeModal} />
      ) : null}
    </span>
  );
};

const tooltipModalProps = {
  isModalOpen: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onLabelKeyDown: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
  isFullScreen: PropTypes.bool,
  inline: PropTypes.bool,
  buttonTabIndex: PropTypes.number,
};

TooltipModalPresenter.propTypes = {
  ...tooltipModalProps,
};

const tooltipModalDefaultProps = {
  label: undefined,
  isModalOpen: false,
  textAlign: null,
  isFullScreen: false,
  inline: false,
  buttonTabIndex: 0,
};

TooltipModalPresenter.defaultProps = tooltipModalDefaultProps;

export const DottedButton = genesisStyled(LinkButton)(
  css({
    textDecoration: 'none',
    borderBottomStyle: 'dotted',
    borderBottomWidth: 'thick',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '> span': {
      whiteSpace: 'nowrap',
    },
  }),
);

export const DottedTooltipModalPresenter = ({
  isModalOpen,
  openModal,
  closeModal,
  label,
  buttonTabIndex,
  onLabelKeyDown,
  inline,
  ...props
}) => {
  return (
    // This component must be enveloped in actual DOM (not a fragment)
    // to prevent the label from shifting when the modal is open.
    <span>
      <TooltipModalLabel {...props} inline={inline}>
        <DottedButton
          as="button"
          tabIndex={buttonTabIndex}
          onClick={openModal}
          marginTop="one"
          marginBottom={inline ? 'none' : 'twoAndHalf'}
          onKeyDown={onLabelKeyDown}
          fontSize={{
            _: 'body2',
            tablet: 'body1',
          }}
          type="button"
        >
          {label}
        </DottedButton>
      </TooltipModalLabel>

      {isModalOpen ? (
        <TooltipModalContent {...props} closeModal={closeModal} />
      ) : null}
    </span>
  );
};

DottedTooltipModalPresenter.propTypes = {
  ...tooltipModalProps,
};

TooltipModalPresenter.defaultProps = tooltipModalDefaultProps;

const IconTooltipButton = genesisStyled(SubtleButton)(
  css({
    padding: 'none',
  }),
);

export const IconTooltipModalPresenter = ({
  isModalOpen,
  openModal,
  closeModal,
  label,
  iconColour,
  buttonTabIndex,
  onLabelKeyDown,
  ...props
}) => {
  return (
    // This component must be enveloped in actual DOM (not a fragment)
    // to prevent the label from shifting when the modal is open.
    <span>
      <TooltipModalLabel {...props}>
        <IconTooltipButton
          borderRadius="circle"
          size="one"
          fontWeight="medium"
          onClick={openModal}
          tabIndex={buttonTabIndex}
          iconColour={iconColour}
          onKeyDown={onLabelKeyDown}
          aria-label={label}
        >
          ?
        </IconTooltipButton>
      </TooltipModalLabel>

      {isModalOpen ? (
        <TooltipModalContent {...props} closeModal={closeModal} />
      ) : null}
    </span>
  );
};

IconTooltipModalPresenter.propTypes = {
  ...tooltipModalProps,
  iconColour: PropTypes.string,
};

IconTooltipModalPresenter.defaultProps = {
  ...tooltipModalDefaultProps,
  iconColour: null,
};
