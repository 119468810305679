import { useQuery } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import { getTeamChallengeRanks } from '@leagueplatform/health-journey-api';

const INDIVIDUAL_TEAM_RANK_QUERY_KEY = 'getIndividualTeamRank';

export const useGetIndividualTeamRank = () => {
  const { challengeId, teamId } = useParams<{
    challengeId: string;
    teamId: string;
  }>();

  return useQuery([INDIVIDUAL_TEAM_RANK_QUERY_KEY, challengeId, teamId], () =>
    getTeamChallengeRanks(challengeId, teamId),
  );
};
