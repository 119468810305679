{
  "dragDescriptionKeyboard": "Нажмите клавишу Enter для начала перетаскивания.",
  "dragDescriptionKeyboardAlt": "Нажмите Alt + Enter, чтобы начать перетаскивать.",
  "dragDescriptionLongPress": "Нажмите и удерживайте, чтобы начать перетаскивать.",
  "dragDescriptionTouch": "Дважды нажмите для начала перетаскивания.",
  "dragDescriptionVirtual": "Щелкните для начала перетаскивания.",
  "dragItem": "Перетащить {itemText}",
  "dragSelectedItems": "Перетащить {count, plural, one {# выбранный элемент} other {# выбранных элем}}",
  "dragSelectedKeyboard": "Нажмите Enter для перетаскивания {count, plural, one {# выбранного элемента} other {# выбранных элементов}}.",
  "dragSelectedKeyboardAlt": "Нажмите Alt + Enter для перетаскивания {count, plural, one {# выбранного элемента} other {# выбранных элементов}}.",
  "dragSelectedLongPress": "Нажмите и удерживайте для перетаскивания {count, plural, one {# выбранного элемента} other {# выбранных элементов}}.",
  "dragStartedKeyboard": "Начато перетаскивание. Нажмите клавишу Tab для выбора цели, затем нажмите клавишу Enter, чтобы применить перетаскивание, или клавишу Escape для отмены действия.",
  "dragStartedTouch": "Начато перетаскивание. Выберите цель, затем дважды нажмите, чтобы применить перетаскивание.",
  "dragStartedVirtual": "Начато перетаскивание. Нажмите клавишу Tab для выбора цели, затем нажмите клавишу Enter, чтобы применить перетаскивание.",
  "dropCanceled": "Перетаскивание отменено.",
  "dropComplete": "Перетаскивание завершено.",
  "dropDescriptionKeyboard": "Нажмите клавишу Enter, чтобы применить перетаскивание. Нажмите клавишу Escape для отмены.",
  "dropDescriptionTouch": "Дважды нажмите, чтобы применить перетаскивание.",
  "dropDescriptionVirtual": "Щелкните, чтобы применить перетаскивание.",
  "dropIndicator": "индикатор перетаскивания",
  "dropOnItem": "Перетащить на {itemText}",
  "dropOnRoot": "Перетащить на",
  "endDragKeyboard": "Перетаскивание. Нажмите клавишу Enter для отмены.",
  "endDragTouch": "Перетаскивание. Дважды нажмите для отмены.",
  "endDragVirtual": "Перетаскивание. Щелкните для отмены.",
  "insertAfter": "Вставить после {itemText}",
  "insertBefore": "Вставить перед {itemText}",
  "insertBetween": "Вставить между {beforeItemText} и {afterItemText}"
}
