import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  styled,
  HeadingText,
  UtilityText,
  StackLayout,
  Icon,
} from '@leagueplatform/genesis-core';
import { SidebarCard } from '@leagueplatform/web-common-components';
import { AchievementStatsProps } from 'types/achievement.types';

// We are selecting the star icon and applying some offsetting.
// This is being done b/c web is sharing the same icon with our mobile platforms but that was not the design
// and backend cannot provide us our own icon.
// If we move the star icon we will need to adjust this selector/styles to match.

const StyledListContainer = styled(Box, {
  'ul > li:first-of-type': {
    img: {
      marginBottom: 'minusQuarter',
    },
  },
});

export const AchievementStats = ({
  completedAchievements,
}: AchievementStatsProps) => {
  const { formatMessage } = useIntl();

  if (completedAchievements.length === 0) {
    return null;
  }

  return (
    <SidebarCard
      padding="oneAndHalf"
      paddingBottom="one"
      data-testid="achievement-stats"
    >
      <HeadingText level="2" size="md" css={{ color: '$onSurfaceTextSubdued' }}>
        {formatMessage({ id: 'ACTIVITY_PROGRESS' })}
      </HeadingText>
      <StyledListContainer>
        <StackLayout as="ul" orientation="vertical">
          {completedAchievements.map((achievement) => (
            <StackLayout as="li" key={achievement.category_group}>
              <StackLayout orientation="horizontal" verticalAlignment="center">
                <Icon
                  icon={achievement.image.large}
                  size="$twoAndHalf"
                  css={{ marginLeft: '$minusOne' }}
                />
                <UtilityText as="span" emphasis="emphasized">
                  {achievement.cumulative_count_stat} {achievement.unit}{' '}
                  {achievement.subtitle}
                </UtilityText>
              </StackLayout>
            </StackLayout>
          ))}
        </StackLayout>
      </StyledListContainer>
    </SidebarCard>
  );
};
