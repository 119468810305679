/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  UtilityText,
  StackLayout,
  Icon,
  GDSIconProps,
} from '@leagueplatform/genesis-core';

export interface IconTextProps {
  iconProps: GDSIconProps;
  text: string | React.ReactNode;
}

export const IconText = ({ iconProps, text }: IconTextProps) => (
  <StackLayout
    orientation="horizontal"
    spacing="$quarter"
    verticalAlignment="center"
  >
    <Icon size="$one" {...iconProps} />
    {typeof text === 'string' ? <UtilityText>{text}</UtilityText> : text}
  </StackLayout>
);
