import { validate } from 'superstruct';
import { MasonryConfig } from '../types/config.types';
import { MasonryConfigSchema } from './masonry-config-schema';

export function validateConfigWithSchema(
  config: MasonryConfig,
  schema: typeof MasonryConfigSchema,
) {
  const messageSuffix = ' is required when initializing Masonry';
  const [err] = validate(config, schema);

  if (!err) {
    return [];
  }
  return err.failures().map((failure) => failure.key + messageSuffix);
}
