import React from 'react';
import { HeadingText } from '@leagueplatform/genesis-core';
import { Link, Box } from '@leagueplatform/genesis-commons';
import { StackLayout } from '@leagueplatform/web-common-components';
import { useFaqLinks } from './hooks/use-faq-links.hook';

interface FaqLinksProps {
  benefitProductType?: string;
}

export const FaqLinks = ({ benefitProductType = '' }: FaqLinksProps) => {
  const { onLinkClick: handleLinkClick, sections } = useFaqLinks({
    benefitProductType,
  });

  if (!sections) {
    return null;
  }

  return (
    <>
      {sections.map((section) => (
        <StackLayout
          key={section.heading}
          space="$oneAndHalf"
          css={{
            borderWidth: '$thin',
            borderColor: '$onSurfaceBorderSubdued',
            borderStyle: 'solid',
            borderRadius: '$medium',
            padding: '$oneAndHalf',
          }}
        >
          <HeadingText level="3" size="lg">
            {section.heading}
          </HeadingText>
          <StackLayout space="$oneAndHalf" as="ul">
            {section.questions.map(({ text, url }) => (
              <Box as="li" key={url}>
                <Link
                  maxWidth="80ch"
                  fontWeight="medium"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handleLinkClick(section.heading, text);
                  }}
                >
                  {text}
                </Link>
              </Box>
            ))}
            {section.linkText && (
              <Box
                as="li"
                paddingTop="oneAndHalf"
                borderTopWidth="thin"
                borderColor="onSurface.border.subdued"
                borderStyle="solid"
              >
                <Link
                  href={section.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  maxWidth="80ch"
                  onClick={() => {
                    handleLinkClick(section.heading, section.linkText || '');
                  }}
                >
                  {section.linkText}
                </Link>
              </Box>
            )}
          </StackLayout>
        </StackLayout>
      ))}
    </>
  );
};
