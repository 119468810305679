import { USER_HEALTH_CAMPAIGN_STATUS } from '@leagueplatform/health-journey-api';
import { GDSStatus } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const useProgramStatusStyles = (
  status: USER_HEALTH_CAMPAIGN_STATUS,
  dateCompleted?: string,
  timeZone?: string,
) => {
  const { formatMessage, formatDate } = useIntl();
  switch (status) {
    case USER_HEALTH_CAMPAIGN_STATUS.ENDED:
      return {
        badgeStatus: 'error' as GDSStatus,
        text: formatMessage({ id: 'ENDED' }),
      };

    case USER_HEALTH_CAMPAIGN_STATUS.COMPLETED:
      return {
        badgeStatus: 'neutral' as GDSStatus,
        text: formatMessage(
          {
            id: 'COMPLETED_ON_DATE',
          },
          {
            date: formatDate(dateCompleted, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              timeZone,
            }),
          },
        ),
      };

    case USER_HEALTH_CAMPAIGN_STATUS.ACTIVE:
      return {
        badgeStatus: 'success' as GDSStatus,
        text: formatMessage({ id: 'ACTIVE' }),
      };

    default:
      return {
        badgeStatus: 'success' as GDSStatus,
        text: formatMessage({ id: 'ACTIVE' }),
      };
  }
};
