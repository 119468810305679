import {
  GetUserLiveBalancesResponseV1,
  Balance,
  ProviderBalanceGroup,
  Definition,
  BalanceV2,
  DefinitionV2,
  CategoriesV2,
  ProviderServiceV2,
  GetUserLiveBalancesResponseV2,
} from 'types/queries/get-user-benefit-live-balances.types';

const familyDeductibleBalance: Balance = {
  title: 'Deductible',
  balance_type: 'deductible',
  category: 'family',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const familyOutOfPocketBalance: Balance = {
  title: 'Out-of-Pocket Maximum',
  balance_type: 'out_of_pocket_max',
  category: 'family',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const individualDeductibleBalance: Balance = {
  title: 'Deductible',
  balance_type: 'deductible',
  category: 'individual',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const individualOutOfPocketBalance: Balance = {
  title: 'Out-of-Pocket Maximum',
  balance_type: 'out_of_pocket_max',
  category: 'individual',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const deductibleDefinitionMock: Definition = {
  balance_type: 'deductible',
  title: 'Deductible',
  description:
    'A <strong>deductible</strong> is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
};

const outOfPocketMock: Definition = {
  balance_type: 'out_of_pocket_max',
  title: 'Out-of-Pocket Maximum',
  description:
    'An <strong>out-of-pocket (OOP) maximum</strong> is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.',
};

export const definitionsMock = [deductibleDefinitionMock, outOfPocketMock];

export const enhancedDeductibleProviderMock: ProviderBalanceGroup = {
  provider: 'Enhanced Provider',
  provider_id: 'enhanced_provider',
  balances: [familyDeductibleBalance, individualDeductibleBalance],
};

export const enhancedOutOfPocketMock: ProviderBalanceGroup = {
  ...enhancedDeductibleProviderMock,
  balances: [familyOutOfPocketBalance, individualOutOfPocketBalance],
};

export const standardProviderMock: ProviderBalanceGroup = {
  provider: '',
  provider_id: '',
  balances: [
    familyDeductibleBalance,
    individualDeductibleBalance,
    familyOutOfPocketBalance,
    individualOutOfPocketBalance,
  ],
};

export const getUserBenefitLiveBalancesWithTiersV2Mock: GetUserLiveBalancesResponseV1 =
  {
    last_updated: '2022-07-01T01:00:00Z',
    carrier_name: 'Carrier',
    accumulation_period: {
      start: '2022-01-01T01:00:00Z',
      end: '2022-12-31T01:00:00Z',
    },
    definitions: definitionsMock,
    in_network: [enhancedDeductibleProviderMock, standardProviderMock],
    out_of_network: [enhancedDeductibleProviderMock, standardProviderMock],
  };

export const getUserBenefitLiveBalancesWithoutTiersV2Mock: GetUserLiveBalancesResponseV1 =
  {
    last_updated: '2022-07-01T01:00:00Z',
    carrier_name: 'Carrier',
    accumulation_period: {
      start: '2022-01-01T01:00:00Z',
      end: '2022-12-31T01:00:00Z',
    },
    definitions: definitionsMock,
    in_network: [standardProviderMock],
    out_of_network: [standardProviderMock],
  };

// GetUserLiveBalancesResponseV2 Mocks:
const enhancedBalanceV2: BalanceV2 = {
  title: 'Enhanced Level',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const standardBalanceV2: BalanceV2 = {
  title: 'Standard Level',
  paid_amount: {
    M: 75000,
    F: 750,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 250,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 1000,
    C: 'USD',
  },
};

const outOfNetworkStandardBalanceV2: BalanceV2 = {
  title: 'Standard Level',
  paid_amount: {
    M: 75000,
    F: 950,
    C: 'USD',
  },
  remaining_amount: {
    M: 25000,
    F: 750,
    C: 'USD',
  },
  limit: {
    M: 100000,
    F: 6000,
    C: 'USD',
  },
};

const deductibleDefinitionMockV2: DefinitionV2 = {
  title: 'Deductible',
  description:
    'A <strong>deductible</strong> is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
};

const outOfPocketDefinitionMockV2: DefinitionV2 = {
  title: 'Out-of-Pocket Maximum',
  description:
    'An <strong>out-of-pocket (OOP) maximum</strong> is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.',
};

const singleTierCategoryMockV2: CategoriesV2 = {
  type: 'family',
  title: 'Family',
  definition: deductibleDefinitionMockV2,
  balances: [standardBalanceV2],
};

const multiTierFamilyCategoryMockV2: CategoriesV2 = {
  type: 'family',
  title: 'Family',
  definition: outOfPocketDefinitionMockV2,
  balances: [standardBalanceV2, enhancedBalanceV2],
};

const multiTierIndividualCategoryMockV2: CategoriesV2 = {
  type: 'individual',
  title: 'Individual',
  definition: outOfPocketDefinitionMockV2,
  balances: [standardBalanceV2, enhancedBalanceV2],
};

const outOfNetworkMultiTierCategoryMockV2: CategoriesV2 = {
  type: 'family',
  title: 'Family',
  definition: outOfPocketDefinitionMockV2,
  balances: [outOfNetworkStandardBalanceV2, enhancedBalanceV2],
};

export const outOfPocketSingleTierProviderMockV2: ProviderServiceV2 = {
  title: 'Out of Pocket',
  description:
    'An <strong>out-of-pocket (OOP) maximum</strong> is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.',
  categories: [singleTierCategoryMockV2],
};

export const deductibleSingleTierProviderMockV2: ProviderServiceV2 = {
  title: 'Deductible',
  description:
    'A <strong>deductible</strong> is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
  categories: [singleTierCategoryMockV2],
};

export const outOfPocketMultiTierProviderMockV2: ProviderServiceV2 = {
  title: 'Out of Pocket',
  description:
    'An <strong>out-of-pocket (OOP) maximum</strong> is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.',
  categories: [
    multiTierFamilyCategoryMockV2,
    multiTierIndividualCategoryMockV2,
  ],
};

export const deductibleMultiTierProviderMockV2: ProviderServiceV2 = {
  title: 'Deductible',
  description:
    'A <strong>deductible</strong> is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
  categories: [
    multiTierFamilyCategoryMockV2,
    multiTierIndividualCategoryMockV2,
  ],
};

export const outOfNetworkDeductibleMultiTierProviderMockV2: ProviderServiceV2 =
  {
    title: 'Deductible',
    description:
      'A <strong>deductible</strong> is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing. The deductible applies to all or most items and services covered by your plan.',
    categories: [outOfNetworkMultiTierCategoryMockV2],
  };

export const getUserBenefitLiveBalancesWithTiersMockV2: GetUserLiveBalancesResponseV2 =
  {
    last_updated: '2022-07-01T01:00:00Z',
    carrier_name: 'Carrier',
    accumulation_period: {
      start: '2022-01-01T01:00:00Z',
      end: '2022-12-31T01:00:00Z',
    },
    in_network: [
      outOfPocketMultiTierProviderMockV2,
      deductibleMultiTierProviderMockV2,
    ],
    out_of_network: [
      outOfPocketMultiTierProviderMockV2,
      outOfNetworkDeductibleMultiTierProviderMockV2,
    ],
  };

export const getUserBenefitLiveBalancesWithoutTiersMockV2: GetUserLiveBalancesResponseV2 =
  {
    last_updated: '2022-07-01T01:00:00Z',
    carrier_name: 'Carrier',
    accumulation_period: {
      start: '2022-01-01T01:00:00Z',
      end: '2022-12-31T01:00:00Z',
    },
    in_network: [
      outOfPocketSingleTierProviderMockV2,
      deductibleSingleTierProviderMockV2,
    ],
    out_of_network: [
      outOfPocketSingleTierProviderMockV2,
      deductibleSingleTierProviderMockV2,
    ],
  };

export const getUserBenefitLiveBalancesWithoutTOutOfNetworkMockV2: GetUserLiveBalancesResponseV2 =
  {
    last_updated: '2022-07-01T01:00:00Z',
    carrier_name: 'Carrier',
    accumulation_period: {
      start: '2022-01-01T01:00:00Z',
      end: '2022-12-31T01:00:00Z',
    },
    in_network: [
      outOfPocketMultiTierProviderMockV2,
      deductibleMultiTierProviderMockV2,
    ],
    out_of_network: null,
  };
