import * as React from 'react';
import PropTypes from 'prop-types';
import {
  SubtleButton,
  PrimaryButton,
  SubtitleTwo,
  Box,
  Caption,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { HEALTH_PROGRAM_STATUS } from '../../../constants/health-program-status.constants';

export const ProgramDetailsSidebarCtaAction = ({
  isLoading,
  programStatus,
  onSelectAction,
}) => {
  const { formatMessage } = useIntl();
  const { ACTIVE, AVAILABLE, UNAVAILABLE } = HEALTH_PROGRAM_STATUS;

  switch (programStatus) {
    case ACTIVE:
      return (
        <SubtleButton
          color="onSurface.text.subdued"
          onClick={onSelectAction}
          isLoading={isLoading}
          data-testid="program-details-remove-from-journey"
        >
          {formatMessage({ id: 'REMOVE_FROM_JOURNEY' })}
        </SubtleButton>
      );
    case AVAILABLE:
      return (
        <PrimaryButton
          height="three"
          onClick={onSelectAction}
          isLoading={isLoading}
          data-testid="program-details-add-to-journey"
        >
          {formatMessage({ id: 'ADD_TO_JOURNEY' })}
        </PrimaryButton>
      );
    case UNAVAILABLE:
      return (
        <Box
          backgroundColor="surface.background.secondary"
          padding="one"
          textAlign="center"
          data-testid="program-details-program-limit"
        >
          <SubtitleTwo marginBottom="half">
            {formatMessage({ id: 'YOUVE_REACHED_PROGRAM_LIMIT' })}
          </SubtitleTwo>
          <Caption>
            {formatMessage({ id: 'COMPLETE_ONE_OF_ACTIVE_PROGRAMS' })}
          </Caption>
        </Box>
      );
    default:
      return null;
  }
};

ProgramDetailsSidebarCtaAction.propTypes = {
  isLoading: PropTypes.bool,
  onSelectAction: PropTypes.func,
  programStatus: PropTypes.string.isRequired,
};

ProgramDetailsSidebarCtaAction.defaultProps = {
  isLoading: false,
  onSelectAction: () => {},
};
