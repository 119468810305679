import React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import { Carousel } from '@leagueplatform/web-common-components';
import { ParagraphText } from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import {
  CarouselContentCardWidgetProps,
  CarouselContentCardWidget,
} from '../carousel-content-card-widget/carousel-content-card-widget.component';
import { SectionHeader } from '../section-header/section-header.component';

export type CarouselWidgetProps = {
  title?: string;
  description?: string;
  headerLinkHint?: string;
  headerLinkUrl?: string;
  items: CarouselContentCardWidgetProps[];
  onScrollLeft: () => void;
  onScrollRight: () => void;
  onSelectItem: (item: CarouselContentCardWidgetProps, index: number) => void;
  onSelectHeader: () => void;
};

export const CarouselWidget = ({
  title,
  description,
  headerLinkHint,
  headerLinkUrl,
  items,
  onScrollLeft,
  onScrollRight,
  onSelectItem,
  onSelectHeader,
}: CarouselWidgetProps) => (
  <Box paddingTop="one" width="100%">
    <Carousel
      name={title || 'Content Cards'}
      heading={
        title ? <SectionHeader heading={title} paddingTop="$none" /> : undefined
      }
      description={
        <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
          {description}
        </ParagraphText>
      }
      spaceBetweenCards="oneAndHalf"
      linkCta={headerLinkHint}
      linkUrl={headerLinkUrl}
      linkOnClick={onSelectHeader}
      LeftNavButtonAnalyticsFn={onScrollLeft}
      RightNavButtonAnalyticsFn={onScrollRight}
    >
      {items.map((item, index) => (
        <CarouselContentCardWidget
          key={item.title}
          title={item.title}
          image={item.image}
          imageAltText={item.imageAltText}
          linkUrl={item.linkUrl}
          overline={item.overline}
          description={item.description}
          badge={item.badge}
          badgeImage={item.badgeImage}
          onClick={() => {
            onSelectItem(item, index);
          }}
        />
      ))}
    </Carousel>
  </Box>
);
