import { Auth } from '@leagueplatform/auth';
import {
  getLegacyAuthValues,
  hasLegacyAuthValues,
} from '@leagueplatform/auth-legacy';

function getLegacyAuthAuthenticateMessage() {
  const { sessionId, deviceToken } = getLegacyAuthValues();

  return {
    message_type: 'authenticate',
    info: { session_id: sessionId, device_token: deviceToken },
  };
}

async function getAuthMessage() {
  const token = await Auth.getToken();

  return {
    message_type: 'authenticate_jwt',
    info: { jwt: token, api_version: 1 },
  };
}

export async function getWebsocketAuthMessage() {
  if (Auth.initialized) {
    return getAuthMessage();
  }

  if (hasLegacyAuthValues()) {
    return getLegacyAuthAuthenticateMessage();
  }

  throw new Error(
    'unable to retrieve auth state for websocket; make sure you call StandaloneAuth.initialize',
  );
}
