{
  "dragDescriptionKeyboard": "Paspauskite „Enter“, kad pradėtumėte vilkti.",
  "dragDescriptionKeyboardAlt": "Paspauskite „Alt + Enter“, kad pradėtumėte vilkti.",
  "dragDescriptionLongPress": "Palaikykite nuspaudę, kad pradėtumėte vilkti.",
  "dragDescriptionTouch": "Palieskite dukart, kad pradėtumėte vilkti.",
  "dragDescriptionVirtual": "Spustelėkite, kad pradėtumėte vilkti.",
  "dragItem": "Vilkti {itemText}",
  "dragSelectedItems": "Vilkti {count, plural, one {# pasirinktą elementą} other {# pasirinktus elementus}}",
  "dragSelectedKeyboard": "Paspauskite „Enter“, jei norite nuvilkti {count, plural, one {# pasirinktą elementą} other {# pasirinktus elementus}}.",
  "dragSelectedKeyboardAlt": "Paspauskite „Alt + Enter“, kad nuvilktumėte {count, plural, one {# pasirinktą elementą} other {# pasirinktus elementus}}.",
  "dragSelectedLongPress": "Nuspaudę palaikykite, kad nuvilktumėte {count, plural, one {# pasirinktą elementą} other {# pasirinktus elementus}}.",
  "dragStartedKeyboard": "Pradėta vilkti. Paspauskite „Tab“, kad pereitumėte į tiesioginę paskirties vietą, tada paspauskite „Enter“, kad numestumėte, arba „Escape“, kad atšauktumėte.",
  "dragStartedTouch": "Pradėta vilkti. Eikite į tiesioginę paskirties vietą, tada palieskite dukart, kad numestumėte.",
  "dragStartedVirtual": "Pradėta vilkti. Eikite į tiesioginę paskirties vietą ir spustelėkite arba paspauskite „Enter“, kad numestumėte.",
  "dropCanceled": "Numetimas atšauktas.",
  "dropComplete": "Numesta.",
  "dropDescriptionKeyboard": "Paspauskite „Enter“, kad numestumėte. Paspauskite „Escape“, kad atšauktumėte vilkimą.",
  "dropDescriptionTouch": "Palieskite dukart, kad numestumėte.",
  "dropDescriptionVirtual": "Spustelėkite, kad numestumėte.",
  "dropIndicator": "numetimo indikatorius",
  "dropOnItem": "Numesti ant {itemText}",
  "dropOnRoot": "Numesti ant",
  "endDragKeyboard": "Velkama. Paspauskite „Enter“, kad atšauktumėte vilkimą.",
  "endDragTouch": "Velkama. Spustelėkite dukart, kad atšauktumėte vilkimą.",
  "endDragVirtual": "Velkama. Spustelėkite, kad atšauktumėte vilkimą.",
  "insertAfter": "Įterpti po {itemText}",
  "insertBefore": "Įterpti prieš {itemText}",
  "insertBetween": "Įterpti tarp {beforeItemText} ir {afterItemText}"
}
