import React, { useState, useEffect } from 'react';
import { Link } from '@leagueplatform/routing';

// UI
import {
  PageHeaderBackground,
  BackButtonArrow,
} from '@leagueplatform/web-common-components';
import { Flex, Box, HeadingOne } from '@leagueplatform/genesis-commons';

// utils
import { useIntl } from '@leagueplatform/locales';
import {
  useDocumentTitle,
  useScrollTopOnLocationChange,
} from '@leagueplatform/web-common';
import {
  ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
  ENABLE_NEW_JOURNEY_EXPERIENCE,
  JOURNEY_ROUTES,
  ProgramLimitModal,
} from '@leagueplatform/health-journey-common';
import { useIsChallengesEnabled } from '@leagueplatform/challenges';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { CURATED_CAROUSEL_TYPES } from '../../utils/health-programs-curated-carousel-types.const';
import { useChallengesCarousel } from '../program-details/hooks/use-challenges-carousel';

// hooks
import { useHealthGoalPrograms } from './hooks/use-health-programs.hook';
import { useHealthProgramCategories } from './hooks/use-health-program-categories.hook';
import { useHealthProgramCuratedCarousels } from '../../hooks/use-health-program-curated-carousels.hook';
import { useHealthProgramSuggestedCarousels } from '../../hooks/use-health-program-suggested-carousels.hook';
import { useProgramLibraryAnalytics } from '../../hooks/use-program-library-analytics.hook';

// components
import { HealthProgramErrorState } from './components/health-program-error-state/health-program-error-state.component';
import { HealthProgramCategories } from './components/health-program-categories/health-program-categories.component';
import { HealthGoalPrograms } from './components/health-goal-programs/health-goal-programs.component';
import { HealthProgramCarousels } from '../../components/health-program-carousels.component';
import { ProgramLimitReached } from './components/program-limit-reached-component/program-limit-reached.component';

export const HealthProgramsPage = () => {
  // force scroll to top on mount
  useScrollTopOnLocationChange();

  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'HEALTH_PROGRAMS' }));

  const { isError: healthProgramsError, data: healthProgramsData } =
    useHealthGoalPrograms();

  const {
    data: healthProgramCategoriesData,
    isError: healthProgramCategoriesError,
  } = useHealthProgramCategories();

  const {
    data: healthProgramCuratedCarouselsData,
    isError: healthProgramCuratedCarouselsError,
    isLoading: isHealthProgramCuratedCarouselsLoading,
  } = useHealthProgramCuratedCarousels(CURATED_CAROUSEL_TYPES.PROGRAMLIBRARY);

  const {
    data: healthProgramSuggestedCarouselsData,
    isError: healthProgramSuggestedCarouselsError,
    isLoading: isHealthProgramSuggestedCarouselsLoading,
  } = useHealthProgramSuggestedCarousels();

  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );
  const { data: isChallengesWellnessAndRewardsEnabled } = useFeatureFlagQuery(
    ENABLE_CHALLENGES_WELLNESS_AND_REWARDS,
  );
  const programLibraryAnalytics = useProgramLibraryAnalytics();

  useEffect(() => {
    programLibraryAnalytics.sendViewProgramLibrary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showProgramLimitModel, setShowProgramLimitModel] = useState(false);

  const { isChallengesEnabled } = useIsChallengesEnabled();

  const {
    ready: isChallengeHealthProgramReady,
    carousels: challengeHealthProgramCarousels,
    hasError: isChallengeHealthProgramError,
  } = useChallengesCarousel({
    title: formatMessage({ id: 'CHALLENGES' }),
    description: formatMessage({
      id: 'CHALLENGE_PROGRAM_LIBRARY_DESCRIPTION',
    }),
  });

  if (
    healthProgramsError &&
    healthProgramCategoriesError &&
    isChallengeHealthProgramError &&
    healthProgramCuratedCarouselsError &&
    healthProgramSuggestedCarouselsError
  ) {
    return <HealthProgramErrorState />;
  }

  const isProgramLimitReached =
    healthProgramsData?.number_of_available_programs === 0;

  return (
    <Flex
      padding={
        !isNewJourneyExperienceEnabled && {
          _: 'oneAndHalf',
          phone: 'twoAndHalf',
        }
      }
      maxWidth={1350}
      minWidth="70%"
      marginX="auto"
    >
      {!isNewJourneyExperienceEnabled && <PageHeaderBackground />}
      <Flex flexDirection={{ _: 'column', tablet: 'row' }} width="100%">
        {!isNewJourneyExperienceEnabled && (
          <BackButtonArrow
            as={Link}
            color="onSurface.text.primary"
            fontSize="heading3"
            marginRight="one"
            marginY="quarter"
            to={JOURNEY_ROUTES.progressHome}
          />
        )}
        <Box width="100%">
          <HeadingOne
            marginBottom={isNewJourneyExperienceEnabled ? 'two' : 'five'}
            as={isNewJourneyExperienceEnabled ? 'h2' : 'h1'}
          >
            {formatMessage({ id: 'PROGRAM_LIBRARY' })}
          </HeadingOne>
          {isProgramLimitReached && (
            <ProgramLimitReached
              setShowProgramLimitModel={setShowProgramLimitModel}
            />
          )}
          <HealthProgramCarousels
            isLoading={isHealthProgramSuggestedCarouselsLoading}
            carousels={healthProgramSuggestedCarouselsData?.carousels}
            carouselsCategory="health campaign library"
          />
          {healthProgramCategoriesData?.categories?.length > 0 && (
            <HealthProgramCategories />
          )}
          {isChallengesEnabled && !isChallengeHealthProgramError && (
            <HealthProgramCarousels
              ready={isChallengeHealthProgramReady}
              carousels={challengeHealthProgramCarousels}
              carouselsCategory="health campaign library"
              linkCta={
                isChallengesWellnessAndRewardsEnabled &&
                formatMessage({ id: 'VIEW_ALL' })
              }
              linkUrl={
                isChallengesWellnessAndRewardsEnabled &&
                JOURNEY_ROUTES.getAllChallengeType()
              }
            />
          )}
          <HealthProgramCarousels
            isLoading={isHealthProgramCuratedCarouselsLoading}
            carousels={healthProgramCuratedCarouselsData?.carousels}
            carouselsCategory="health campaign library"
          />
          <HealthGoalPrograms />
        </Box>
      </Flex>
      {showProgramLimitModel && (
        <ProgramLimitModal
          title={healthProgramsData?.program_limit_modal?.title}
          description={healthProgramsData?.program_limit_modal?.description}
          onClose={() => {
            setShowProgramLimitModel(false);
          }}
        />
      )}
    </Flex>
  );
};
