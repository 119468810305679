import { Struct, object, union, optional, func, number } from 'superstruct';
import type {
  Auth0ClientOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';

const authStandaloneConfigSchema = object({
  clientOptions: object() as unknown as Struct<Auth0ClientOptions>,
  idleSessionLifetimeHours: number(),
  redirectLoginOptions: optional(
    object() as unknown as Struct<RedirectLoginOptions>,
  ),
});

const authEmbeddedConfigSchema = object({
  getToken: func() as unknown as Struct<() => Promise<string>, null>,
  getUserIdFromMobileHandoff: optional(
    func() as unknown as Struct<() => Promise<string>, null>,
  ),
});

export const authConfigSchema = union([
  authStandaloneConfigSchema,
  authEmbeddedConfigSchema,
]);
