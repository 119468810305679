import * as React from 'react';
import { Line as VisxLine } from '@visx/shape';
import { useTheme } from '@leagueplatform/genesis-commons';
import { useTooltip } from '../providers/tooltip-provider';
import { useChart } from '../providers/chart-data-provider';

type TooltipLineProps = {
  snapToDatum: boolean;
};

export function TooltipLine({ snapToDatum }: TooltipLineProps) {
  const { margin } = useChart();
  const theme = useTheme();
  const { tooltipLeft, tooltipTop } = useTooltip();

  return (
    <g>
      <VisxLine
        from={{ x: tooltipLeft, y: -margin.top * 1.2 }}
        to={{ x: tooltipLeft, y: tooltipTop }}
        stroke={theme.colors.decorative.brand.primary.dark}
        strokeWidth={2}
        pointerEvents="none"
        strokeDasharray="0"
      />
      {snapToDatum ? (
        <>
          <circle
            cx={tooltipLeft}
            cy={tooltipTop}
            r={12}
            fill="white"
            stroke={theme.colors.decorative.brand.primary.brightest}
            strokeWidth={2}
            pointerEvents="none"
          />
          <circle
            cx={tooltipLeft}
            cy={tooltipTop}
            r={10}
            fill="white"
            stroke={theme.colors.decorative.brand.primary.dark}
            strokeWidth={2}
            pointerEvents="none"
          />
        </>
      ) : null}
    </g>
  );
}
