import React from 'react';
import { CoverImage, BodyOne } from '@leagueplatform/genesis-commons';
import { formatUserName } from 'utils/format-user-name.util';
import { GDSColor, StackLayout } from '@leagueplatform/genesis-core';

export interface ChallengeProfilePictureProps {
  profilePicture?: string;
  firstName: string;
  lastName: string;
  isOnPodium?: boolean;
  backgroundColor?: GDSColor;
}

export const ChallengeProfilePicture = ({
  profilePicture,
  firstName,
  lastName,
  isOnPodium,
  backgroundColor = '$decorativeBrandPrimaryDefault',
}: ChallengeProfilePictureProps) => {
  if (profilePicture) {
    return (
      <CoverImage
        // not adding alt text so screen readers don't read "profile picture" or the user's name over and over again for each line (redundant)
        alt=""
        borderRadius="circle"
        size="100%"
        src={profilePicture}
        role="presentation"
      />
    );
  }

  /*
    Default to "generated" profile picture with initials if the user doesn't have one
    Based on apps/league-b2b/src/apps/member-experience/pages/UserProfile/components/profile-photo.view.js
    This will be modularized by webplat team in future and can be replaced at that point
  */
  return (
    <StackLayout
      horizontalAlignment="center"
      css={{
        backgroundColor,
        borderRadius: '$circle',
        justifyContent: 'center',
        size: '100%',
      }}
    >
      <BodyOne
        color="onSurface.text.reversed"
        fontWeight="bold"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isOnPodium && { fontSize: 'heading2' })}
      >
        {formatUserName({
          firstName,
          lastName,
          isInitials: true,
        })}
      </BodyOne>
    </StackLayout>
  );
};
