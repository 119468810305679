import * as React from 'react';
import { useTooltipInPortal } from '@visx/tooltip';
import { GDSThemeProvider, useTheme } from '@leagueplatform/genesis-core';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { useParams } from '@leagueplatform/routing';
import { useTooltip } from '../providers/tooltip-provider';
import { useChart } from '../providers/chart-data-provider';
import { TooltipLine } from './tooltip-line';

type TooltipData<Datum> = {
  children: (datum: Datum) => React.ReactNode;
  snapToDatum: boolean;
};

export function Tooltip<Datum>({ children, snapToDatum }: TooltipData<Datum>) {
  const { dataType } = useParams<{ dataType: string }>();
  const { margin } = useChart();
  const theme = useTheme();
  const { tooltipData, tooltipLeft } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    scroll: false,
  });

  // To correctly position itself in a Portal, the tooltip must know its container bounds
  // this is done by rendering an invisible node whose ref can be used to find its parentElement
  const setContainerRef = React.useCallback(
    (ownRef: HTMLElement | SVGElement | null) => {
      containerRef(ownRef?.parentElement ?? null);
    },
    [containerRef],
  );
  React.useEffect(() => {
    if (tooltipData) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.DASHBOARD,
        screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
        metric_type: dataType,
        detail: 'graph value click',
      });
    }
  }, [tooltipData, dataType]);

  return (
    <>
      <svg ref={setContainerRef} />
      {tooltipData ? (
        <>
          <TooltipLine snapToDatum={snapToDatum} />
          <TooltipInPortal
            left={tooltipLeft}
            applyPositionStyle
            // TODO: No idea where the extra 21px is coming from.
            offsetLeft={margin.left - 21}
            style={{
              pointerEvents: 'none',
            }}
          >
            <GDSThemeProvider theme={theme}>
              {/* Casting isn't great, but was unable to specify the
              generic type on the underlying visx useTooltip function */}
              {children(tooltipData as Datum)}
            </GDSThemeProvider>
          </TooltipInPortal>
        </>
      ) : null}
    </>
  );
}
