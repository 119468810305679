import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from '@leagueplatform/ui-kit';
import {
  Fieldset,
  queryHelpers,
  StackLayout,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { AnswerPill } from './answer-pill.view';

export const MultiSelectAnswers = ({
  questionId,
  answers,
  setShowArrow,
  onChangeHandler,
  userSelections,
}) => {
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  const userSelection = userSelections[questionId] || {};
  // mutating checkedAnswers as we only want to send ids of checked answers to the store, not the component state
  let { id: checkedAnswers = [] } = userSelection;
  const {
    error: { hasError = false, errorMsg = '' },
  } = userSelection;

  const clearOtherOptionAnswers = answers
    .filter(
      (answer) =>
        answer.properties && answer.properties.includes('clear_other_options'),
    )
    .map((answer) => answer.id);

  // Show next arrow if there are checked answers
  useEffect(() => {
    setShowArrow(userSelection.id && userSelection.id.length > 0);
  }, [userSelection, setShowArrow]);

  const calculateCheckedAnswers = (id) => {
    // clear all checkedAnswers if selected answer has clear_other_options property
    // or when user click away from clear_other_options answer
    if (checkedAnswers.includes(id)) {
      // de-selected answer that has already been selected
      checkedAnswers = checkedAnswers.filter(
        (checkedAnswer) => checkedAnswer !== id,
      );
    } else if (
      clearOtherOptionAnswers.includes(id) ||
      checkedAnswers.find((answer) => clearOtherOptionAnswers.includes(answer))
    ) {
      checkedAnswers = [id];
    } else {
      checkedAnswers.push(id);
    }
    return { id: checkedAnswers };
  };

  return (
    <>
      <Box mb={2}>
        {hasError && errorMsg && (
          <Text color="red" fontSize={2} mt={0}>
            {errorMsg}
          </Text>
        )}
      </Box>
      <Fieldset>
        <StackLayout
          spacing={isMobile ? 'none' : '$one'}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          css={{ flexWrap: isMobile ? '' : 'wrap' }}
        >
          {answers.map(({ id, description, text }) => (
            <AnswerPill
              id={id}
              key={id}
              name="multi-select-answer"
              label={text}
              type="checkbox"
              checked={checkedAnswers.includes(id)}
              onChangeHandler={() => {
                onChangeHandler(calculateCheckedAnswers(id));
              }}
              description={description}
            />
          ))}
        </StackLayout>
      </Fieldset>
    </>
  );
};

MultiSelectAnswers.propTypes = {
  questionId: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      text: PropTypes.string,
      id: PropTypes.string,
      properties: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  setShowArrow: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
};
