import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const ANIMATION_DELAY_INCREMENT = 0.3;

function gridTemplatesForSquare(squareSize) {
  const pxValue = `${squareSize}px`;

  return `${pxValue} ${pxValue} ${pxValue}`;
}

const foldTheCube = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Indicator = styled.div`
  font-size: 10px;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  display: grid;
  grid-template-columns: ${(props) => gridTemplatesForSquare(props.squareSize)};
  grid-template-rows: ${(props) => gridTemplatesForSquare(props.squareSize)};
  .square {
    position: relative;
    width: ${(props) => `${props.squareSize}px`};
    height: ${(props) => `${props.squareSize}px`};
  }
  .square:not(:first-child):not(:last-child) {
    grid-row: 2;
  }
  .square:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 102%; /* Fix misalignment issues caused by CSS transforms on Safari and Firefox */
    height: 102%; /* Fix misalignment issues caused by CSS transforms on Safari and Firefox */
    background-color: #501cd2;
    animation: ${foldTheCube} 2.4s infinite linear both;
    transform-origin: 50% 100%;
  }
  .square--accent:before {
    background: #98f9c7;
  }
  .s1 {
    grid-column: 2;
  }
  .s2 {
    left: ${(props) => `-${props.accentSquareMargin}px`};
    transform: rotateZ(-90deg) translateX(-0.5px);
  }
  .s4 {
    transform: rotateZ(90deg);
  }
  .s5 {
    grid-column: 2;
    grid-row: 3;
    top: ${(props) => `${props.accentSquareMargin}px`};
    transform: rotateZ(-180deg);
  }
  .s1:before {
    animation-delay: ${`${ANIMATION_DELAY_INCREMENT}s`};
  }
  .s2:before {
    animation-delay: ${`${ANIMATION_DELAY_INCREMENT * 4}s`};
  }
  .s3:before {
    animation: ${fadeIn} ${`${ANIMATION_DELAY_INCREMENT}s`} linear 0s 1
      alternate;
  }
  .s4:before {
    animation-delay: ${`${ANIMATION_DELAY_INCREMENT * 2}s`};
  }
  .s5:before {
    animation-delay: ${`${ANIMATION_DELAY_INCREMENT * 3}s`};
  }
`;

const AnimatedLeagueLogo = (props) => {
  const { size } = props;

  // Round values to ensure consistency across browsers
  const squareDimension = Math.round(size / 3);
  const accentSquareMargin = Math.round(squareDimension / 6);

  // Calculate a new `size` prop based on rounded dimensions for squares
  const adjustedIndicatorSize = squareDimension * 3;

  return (
    <Indicator
      size={adjustedIndicatorSize}
      squareSize={squareDimension}
      accentSquareMargin={accentSquareMargin}
    >
      <div className="square s1" />
      <div className="square square--accent s2" />
      <div className="square s3" />
      <div className="square s4" />
      <div className="square square--accent s5" />
    </Indicator>
  );
};

AnimatedLeagueLogo.propTypes = {
  /** Pixel value used for width and height */
  size: PropTypes.number.isRequired,
};

export default AnimatedLeagueLogo;
