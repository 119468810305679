import { GDSButtonProps, GDSIconProps } from '@leagueplatform/genesis-core';

export type Indicator =
  | 'NEW_TAB_INTERNAL'
  | 'NEW_WINDOW_INTERNAL'
  | 'EXTERNAL_LINK';

interface IconTokenByStringProps {
  [key: string]: string;
}

const ButtonIconTokenByString: IconTokenByStringProps = {
  NEW_TAB_INTERNAL: 'newTabInternal',
  NEW_WINDOW_INTERNAL: 'newWindowInternal',
  EXTERNAL_LINK: 'externalLink',
};

const IconTokenByString: IconTokenByStringProps = {
  NEW_WINDOW_INTERNAL: 'interfaceChevronRight',
  EXTERNAL_LINK: 'interfaceExternalLink',
};

const labelByIndicator = {
  NEW_TAB_INTERNAL: 'opens in a new tab',
  NEW_WINDOW_INTERNAL: 'opens in a new window',
  EXTERNAL_LINK: 'opens an external link',
};

export const getButtonIconToken = (indicatorString: Indicator) =>
  ButtonIconTokenByString[
    indicatorString!
  ] as GDSButtonProps<'button'>['indicator'];

export const getIconToken = (indicatorString: Indicator) =>
  IconTokenByString[indicatorString!] as GDSIconProps['icon'];

export const getLabelByIndicator = (indicator: Indicator): string =>
  labelByIndicator[indicator];
