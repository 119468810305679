import {
  object,
  optional,
  number,
  string,
  array,
  func,
  union,
  record,
  boolean,
} from 'superstruct';

const CommonRecordValuesApiParams = union([string(), number(), boolean()]);

const ApiParamsSchema = record(string(), CommonRecordValuesApiParams);

export const MasonryConfigSchema = object({
  appId: string(),
  apiVersion: number(),
  apiParams: optional(
    record(
      string(),
      union([
        CommonRecordValuesApiParams,
        array(CommonRecordValuesApiParams),
        ApiParamsSchema,
      ]),
    ),
  ),
  customWidgetModules: optional(
    array(
      object({
        moduleKey: string(),
        customWidgetDataHooks: optional(
          object({
            useTextWithCTACardWidgetData: optional(func()),
            useLargeVisualCardWidgetData: optional(func()),
            useSmallVisualCardWidgetData: optional(func()),
            useCarouselWidgetData: optional(func()),
            useXSVisualCardWidgetData: optional(func()),
            useWelcomeBannerWidgetData: optional(func()), // Deprecate - v2
            useHeroBannerWidgetData: optional(func()),
            useProductCarouselWidgetData: optional(func()),
            useProgressRingCardWidgetData: optional(func()),
            useProgressBarCardWidgetData: optional(func()),
            useTwoUpCardWidgetData: optional(func()),
            useGenericCarouselWidgetData: optional(func()),
            useStackWidgetData: optional(func()),
            useImageFooterWidgetData: optional(func()),
          }),
        ),
        customWidgetsUI: optional(
          array(
            object({
              type: string(),
              widget: func(),
            }),
          ),
        ),
      }),
    ),
  ),
  analytics: object({
    productArea: string(),
    screenName: string(),
  }),
  uiConfig: optional(
    object({
      CustomLoader: optional(func()),
      spinnerConfig: optional(
        object({
          tint: optional(string()),
          size: optional(union([string(), number()])),
          thickness: optional(number()),
          containerHeight: optional(string()),
        }),
      ),
    }),
  ),
});
