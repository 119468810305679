export const OTHER = 'other';
export const IDENTIFICATION = 'identification';
export const BENEFICIARY_FORM = 'beneficiary_form';
export const EVIDENCE_OF_INSURABILITY = 'evidence_of_insurability';
export const DEPENDENT_VERIFICATION = 'dependent_verification';

export const DOCUMENT_TYPES = [
  {
    id: 'BENEFICIARY_FORM',
    value: 'beneficiary_form',
  },
  { id: 'IDENTIFICATION', value: 'identification' },
  { id: 'EVIDENCE_OF_INSURABILITY', value: EVIDENCE_OF_INSURABILITY },
  { id: 'DEPENDENT_VERIFICATION', value: DEPENDENT_VERIFICATION },
  {
    id: 'PROOF_OF_BUSINESS',
    value: 'proof_of_business',
  },
  { id: 'GYM_MEMBERSHIP', value: 'gym_membership' },
  { id: 'PRESCRIPTION', value: 'prescription' },
  // Removed but not forgotten
  // {
  //   id: 'FOREIGN_CONTRACT',
  //   value: 'foreign_contract_worker_questionnaire',
  // },
  { id: 'OTHER', value: 'other' },
];

export const getOptionId = (value) => {
  const option = DOCUMENT_TYPES.find((item) => item.value === value);
  return option ? option.id : false;
};
