import React, { useState, useEffect, useCallback } from 'react';
import { useDisplayRewardEvent } from 'hooks/use-display-reward-event.hook';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Box } from '@leagueplatform/genesis-core';
import {
  REWARDS_EVENT_ANALYTICS,
  TOAST_ACTIONS,
} from 'constants/analytics.constants';
import { CelebrationModal } from '../celebration-modal/celebration-modal.view';
import { ProgressModal } from '../progress-modal/progress-modal.view';
import { RewardsToastContent } from '../rewards-toast-content/rewards-toast-content.view';

export const RewardsEventController = () => {
  const { currentRewardEvent, rewardEventViewed } = useDisplayRewardEvent();

  const {
    latest_reward: latestRewards,
    updated: inProgressMilestones,
    completed: completedMileStones,
  } = currentRewardEvent?.info.data || {};

  const [showToast, setShowToast] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showCelebrationModal, setShowCelebrationModal] = useState(false);

  const [toastTimeout, setToastTimeout] = useState<NodeJS.Timeout>();

  const closeToast = useCallback(
    (openProgressModal: boolean) => {
      if (toastTimeout) {
        clearTimeout(toastTimeout);
      }

      if (openProgressModal) {
        setShowToast(false);
        setShowProgressModal(true);
      } else if (completedMileStones) {
        setShowToast(false);
        setShowCelebrationModal(true);
      } else {
        setShowToast(false);
        rewardEventViewed();
      }
    },
    [completedMileStones, rewardEventViewed, toastTimeout],
  );

  const closeProgressModal = () => {
    setShowProgressModal(false);
    if (completedMileStones) {
      setShowCelebrationModal(true);
    } else {
      rewardEventViewed();
    }
  };

  const closeCelebrationModal = () => {
    setShowCelebrationModal(false);
    rewardEventViewed();
  };

  useEffect(() => {
    if (currentRewardEvent) {
      if (inProgressMilestones || latestRewards) {
        setShowToast(true);

        setToastTimeout(
          setTimeout(() => {
            closeToast(false);
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: REWARDS_EVENT_ANALYTICS.PRODUCT_AREA_REWARDS,
              screen_name: REWARDS_EVENT_ANALYTICS.SCREEN_NAME_PROGRESS_TOAST,
              detail: TOAST_ACTIONS.AUTO_DISMISSED,
              num_of_milestone_rewards: inProgressMilestones?.length || 0,
            });
          }, 10450),
        );
      } else if (completedMileStones) {
        setShowCelebrationModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentRewardEvent,
    completedMileStones,
    inProgressMilestones,
    latestRewards,
  ]);

  return (
    <>
      <Box
        aria-live="polite"
        css={{
          position: 'fixed',
          bottom: '$twoAndHalf',
          right: '$four',
          zIndex: 10000,
        }}
      >
        {showToast && (
          <RewardsToastContent
            pointsEarned={latestRewards}
            activities={inProgressMilestones}
            showMoreActivities={() => {
              closeToast(true);
            }}
            closeToast={() => closeToast(false)}
          />
        )}
      </Box>

      {showProgressModal && inProgressMilestones && (
        <ProgressModal
          celebrations={completedMileStones}
          activities={inProgressMilestones}
          onClose={closeProgressModal}
        />
      )}
      {showCelebrationModal && completedMileStones && (
        <CelebrationModal
          celebrations={completedMileStones}
          onClose={closeCelebrationModal}
        />
      )}
    </>
  );
};
