/*
  Hook will check if there is an existing location.key before redirecting
  If the key is not present, we assume that the user had opened a new tab and hook will return history.push (redirects to the home page)
  If the key is present, we assume they came from page within our app and hook will return history.goBack (redirects to the previous page)

  React Router depends on the History library, and the value of
  "history.location.key" will depend on its version:

  v5 - history.location.key is undefined
    React Router: https://v5.reactrouter.com/web/api/history
    History: https://github.com/remix-run/history/blob/v4/docs/GettingStarted.md#listening

  v6 - history.location.key is "default"
    React Router: https://reactrouter.com/en/main/utils/location
    History: https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
*/

import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';

export const useGoBack = (fallbackLocation?: string) => {
  const history = useHistory();

  const goBack = React.useCallback(() => {
    if (!history.location.key || history.location.key === 'default') {
      return history.push(fallbackLocation ?? '/');
    }

    return history.goBack();
  }, [fallbackLocation, history]);

  return goBack;
};
