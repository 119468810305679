import { useEffect, RefObject } from 'react';

type OutsideClickRef = RefObject<HTMLElement> | undefined;

export const useOutsideClick = (
  refs: OutsideClickRef | Array<OutsideClickRef>,
  handler?: () => void,
) => {
  useEffect(() => {
    const refsArray = Array.isArray(refs) ? refs : [refs];

    function handleClickOutside(event: any) {
      if (!handler) return;

      // Clicked browser's scrollbar
      if (
        event.target === document.getElementsByTagName('html')[0] &&
        event.clientX >= document.documentElement.offsetWidth
      )
        return;

      const containedToAnyRefs = refsArray.some(
        (rf) => !!rf?.current?.contains?.(event.target),
      );

      // Not contained to any given refs
      if (!containedToAnyRefs) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, handler]);
};
