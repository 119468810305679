import { useUserActiveGroupQuery } from 'hooks/queries/use-user-active-group-query.hook';
import { useGroupQuery } from 'hooks/queries/use-group-query.hook';
import { isKPMG as getIsKPMG } from '@leagueplatform/web-common';
import { getLearnMoreUrl } from '../utils/get-learn-more-url.util';

export function useLearnMoreUrlQuery(userId: string | undefined) {
  const { data: activeGroup } = useUserActiveGroupQuery(userId);

  return useGroupQuery(activeGroup?.group_id, {
    select: (data) => {
      const isKPMG = getIsKPMG(data.group.name);

      return getLearnMoreUrl(data.group.country_code, isKPMG);
    },
  });
}
