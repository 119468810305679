import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  HeadingOne,
  HeadingTwo,
  Flex,
  Box,
  Image,
  PrimaryButton,
  QuietButton,
} from '@leagueplatform/genesis-commons';
import { Link as RouterLink } from '@leagueplatform/routing';

export const CTAMediaObject = ({
  title,
  description,
  ctaLink,
  ctaText,
  secondaryLink,
  secondaryText,
  image,
  ctaHandler,
  secondaryHandler,
  svg,
  ctaAnalytics,
  secondaryAnalytics,
  eyebrow,
  isMobile,
}) => {
  const showSecondaryLink = Boolean(
    secondaryText && (secondaryLink || secondaryHandler),
  );

  return (
    <>
      <Flex
        flexDirection={{ _: 'column', tablet: 'initial' }}
        padding={isMobile ? '0 16px' : 'initial'}
        marginTop={{ _: '-100px', tablet: '0' }}
      >
        <Box
          flex={isMobile ? 'none' : '0 0 277px'}
          display={{ _: 'flex', tablet: 'initial' }}
          justifyContent={{ _: 'center', tablet: 'initial' }}
        >
          {svg || (
            <Image
              src={image}
              role="presentation"
              height={isMobile ? '180px' : '280px'}
            />
          )}
        </Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          marginLeft={{ _: '0', tablet: 'two' }}
          textAlign={{ _: 'center', tablet: 'initial' }}
          width="100%"
        >
          {eyebrow && eyebrow}
          {isMobile ? (
            <HeadingTwo marginBottom="one" marginTop="50px">
              {title}
            </HeadingTwo>
          ) : (
            <HeadingOne marginBottom="one">{title}</HeadingOne>
          )}
          <BodyOne>{description}</BodyOne>
        </Flex>
      </Flex>
      <Flex
        marginTop="four"
        justifyContent="center"
        width={isMobile ? '100%' : 'initial'}
        padding={isMobile ? '0 16px' : 'initial'}
      >
        <Flex
          alignItems="center"
          width={isMobile ? '100%' : 'initial'}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          {showSecondaryLink && (
            <Box
              marginRight={{ _: 'none', tablet: 'two' }}
              width={{ _: '100%', tablet: 'auto' }}
            >
              <QuietButton
                as={secondaryLink ? RouterLink : undefined}
                to={secondaryLink}
                width={{ _: '100%', tablet: 'auto' }}
                textDecoration="none"
                onClick={(e) => {
                  if (secondaryHandler) {
                    e.preventDefault();
                    secondaryHandler();
                  }
                  secondaryAnalytics();
                }}
              >
                {secondaryText}
              </QuietButton>
            </Box>
          )}
          <PrimaryButton
            as={ctaLink ? RouterLink : undefined}
            to={ctaLink}
            textDecoration="none"
            width={isMobile ? '100%' : 'initial'}
            // to align button to heading/ description on desktop
            marginLeft={{
              _: 'none',
              tablet: !showSecondaryLink ? '80px' : 'none',
            }}
            onClick={(e) => {
              if (ctaHandler) {
                e.preventDefault();
                ctaHandler();
              }
              ctaAnalytics();
            }}
          >
            {ctaText}
          </PrimaryButton>
        </Flex>
      </Flex>
    </>
  );
};

CTAMediaObject.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
  secondaryLink: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  image: PropTypes.string,
  ctaHandler: PropTypes.func,
  secondaryHandler: PropTypes.func,
  svg: PropTypes.element,
  ctaAnalytics: PropTypes.func,
  secondaryAnalytics: PropTypes.func,
  eyebrow: PropTypes.string,
  isMobile: PropTypes.bool,
};

CTAMediaObject.defaultProps = {
  secondaryLink: null,
  secondaryText: '',
  secondaryHandler: null,
  ctaHandler: null,
  ctaLink: null,
  image: '',
  eyebrow: null,
  svg: null,
  ctaAnalytics: () => {},
  secondaryAnalytics: () => {},
  isMobile: false,
};
