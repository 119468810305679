import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { captureError } from '@leagueplatform/observability';
import { isLoginRequiredError } from '@leagueplatform/auth';
import { getEnvironmentName } from '../utils/utils';

export const sendAnalyticsToLeague = async (params: any, name: string) => {
  const payload = {
    name,
    params: {
      ...params,
      platform: 'web',
      environment: getEnvironmentName(),
      userAgent: window.navigator.userAgent,
    },
    echo: true,
  };

  SocketAsFetch.fetch(
    {
      message_type: 'set_analytics_event',
      info: payload,
    },
    {
      onInternalError: (error) => {
        /**
         * If the internal error thrown within the `fetch` call was a "Login required"
         * error from the Standalone Auth client, we don't want the `SocketAsFetch`
         * module to send it to Observability (becasue in virtually all cases
         * it's the result of the session being over and thus does not represent
         * a user-affecting event).
         *
         * So, we use the `onInternalError` hook to throw it ourselves, before the
         * module can grab it and sendit to O11y.
         */
        if (isLoginRequiredError(error)) {
          throw error;
        }
      },
    },
  ).catch((error) => {
    if (!isLoginRequiredError(error))
      /**
       * But also, if the error that was just thrown by the `SocketAsFetch` module
       * was NOT a "Login required" error, then we'll capture it and send it to
       * o11y ourselvs.
       */
      captureError(
        new Error(
          `Could not send analytics to League due to an internal WS fetch error. ${error}`,
        ),
      );
  });
};
