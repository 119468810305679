/* eslint-disable import/no-mutable-exports */
import React, { createContext, useContext } from 'react';
import type { PropsWithChildren } from 'react';
import { Route as BaseRoute } from 'react-router-dom';
// eslint-disable-next-line @leagueplatform/prefer-react-router-dom
import type { RouteProps, OmitNative } from 'react-router';

type RouteComponentType = typeof BaseRoute;

const RouteComponentContext = createContext<RouteComponentType>(BaseRoute);

type RouteComponentProviderProps = PropsWithChildren<{
  routeComponent: RouteComponentType;
}>;

/**
 * Provides a component to be used as `<Route>`. When rendered, can be used to REPLACE
 * the `Route` component that will be mounted across the codebase.
 */
export const RouteComponentProvider = ({
  children,
  routeComponent,
}: RouteComponentProviderProps) => (
  <RouteComponentContext.Provider value={routeComponent}>
    {children}
  </RouteComponentContext.Provider>
);

/**
 * The component to use for rendering `<Route>`s. Internally consumes the Context
 * above so as to wrap a replaceable version of the base compoennt from `react-router-dom`.
 */
export const Route = <T extends {} = {}, Path extends string = string>(
  props: RouteProps<Path> & OmitNative<T, keyof RouteProps>,
) => {
  const RouteComponent = useContext(RouteComponentContext);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RouteComponent {...props} />;
};

export { BaseRoute };
