import React, { useState, ChangeEvent } from 'react';
import { useParams } from '@leagueplatform/routing';
import {
  BackButtonArrow,
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';

import {
  Box,
  Flex,
  HeadingThree,
  TextField,
  VisuallyHidden,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { CONTENT_MAX_WIDTH } from 'constants/challenges.constants';
import { useGetAllTeams } from 'pages/join-team-page/hooks/use-get-all-teams.hook';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { Team } from '@leagueplatform/health-journey-api';
import { TeamsList } from '../teams-list/teams-list.component';

export interface JoinTeamProps {
  backTo: () => void;
}

export const JoinTeam = ({ backTo }: JoinTeamProps) => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, error, data: allTeamsData } = useGetAllTeams();

  // TODO: replace LoadingIndicator with join page skeleton loader (https://everlong.atlassian.net/browse/CHAL-1852)
  if (isLoading) return <LoadingIndicator />;

  if (error || !allTeamsData?.data)
    return (
      <FullPageError route={JOURNEY_ROUTES.getChallengeJoinTeam(challengeId)} />
    );

  const teamsList = allTeamsData?.data?.filter((team: Team) =>
    team.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Box
      maxWidth={CONTENT_MAX_WIDTH}
      marginX={{ _: 'twoAndHalf', tablet: 'auto' }}
      marginY={{ _: 'twoAndHalf', tablet: 'five' }}
    >
      <Flex alignItems="center" width="100%">
        <BackButtonArrow
          onClick={backTo}
          marginTop="quarter" // added to visually center the arrow
          marginLeft={{ _: 'none', laptop: 'minusTwo' }}
        />
        <HeadingThree as="h1" marginLeft="half">
          {formatMessage({ id: 'SEARCH_FOR_TEAM' })}
        </HeadingThree>
      </Flex>
      <Box width="100%">
        <form onSubmit={(e) => e.preventDefault()} role="search">
          <Label htmlFor="team_search">
            <VisuallyHidden>
              <LabelText>{formatMessage({ id: 'SEARCH_FOR_TEAM' })}</LabelText>
            </VisuallyHidden>
          </Label>
          <TextField
            name="team_search"
            id="team_search"
            type="search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.currentTarget.value);
            }}
            placeholder={formatMessage({ id: 'SEARCH_FOR_TEAM' })}
            height="three"
            width="100%"
            marginTop="two"
            marginBottom={{ _: 'one', laptop: 'two' }}
          />
        </form>
      </Box>
      <TeamsList teamsList={teamsList} />
    </Box>
  );
};
