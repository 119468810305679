import { useEffect } from 'react';

export const useOnEscape = (onEscape) => {
  useEffect(() => {
    const escKeyListener = (evt) => {
      if (evt.key === 'Escape' || evt.key === 'Esc') {
        onEscape();
      }
    };
    window.addEventListener('keyup', escKeyListener);
    return () => window.removeEventListener('keyup', escKeyListener);
  }, [onEscape]);
};
