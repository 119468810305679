import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  css,
  genesisStyled,
  Box,
  Flex,
  QuietButton,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { DotNavigationProps } from 'types/features-highlight.type';

const DotWrapper = genesisStyled(QuietButton)(({ active }) =>
  css({
    padding: 'quarter',
    borderRadius: 'circle',
    '&:hover': {
      backgroundColor: active
        ? 'onSurface.text.primary'
        : 'onSurface.border.default',
    },
    '&:focus': {
      backgroundColor: active
        ? 'onSurface.text.primary'
        : 'onSurface.border.default',
    },
    '&::before': {
      borderRadius: 'circle',
    },
  }),
);

const Dot = genesisStyled(Box)(({ active }) =>
  css({
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: active ? 'onSurface.text.primary' : 'onSurface.border.default',
    backgroundColor: active
      ? 'onSurface.text.primary'
      : 'surface.background.primary',
    height: 'half',
    width: 'half',
    borderRadius: 'circle',
    transition: 'background-color 0.3s ease',
  }),
);

export const DotNavigation = ({
  current,
  handleClick,
  total,
}: DotNavigationProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex as="ul" justifyContent="center">
      {[...Array(total)].map((el, index) => {
        const key = Number(index + 1);
        const active = index === current;
        const label = formatMessage(
          {
            id: 'SLIDE_OF_TOTAL',
          },
          {
            key,
            total,
          },
        );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`dot-navigation-${index}`}>
            <DotWrapper
              aria-current={active}
              active={active}
              onClick={() => handleClick(index)}
            >
              <Dot active={active}>
                <VisuallyHidden>{label}</VisuallyHidden>
              </Dot>
            </DotWrapper>
          </li>
        );
      })}
    </Flex>
  );
};
