import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { ONBOARDING_CONFIG_PATH } from 'constants/onboarding-config.constants';
import { OnboardingConfig } from 'types/onboarding-requests.type';
import { getOnboardingConfig } from './get-onboarding-config';

export const setOnboardingConfig = async (
  attributes: OnboardingConfig,
): Promise<any> => {
  try {
    const onboardingConfig = await getOnboardingConfig();
    if (!onboardingConfig.id) {
      return new Error('Unable to find user id');
    }
    const response = await leagueFetch(ONBOARDING_CONFIG_PATH, {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          id: onboardingConfig.id,
          type: 'onboarding-config',
          attributes,
        },
      }),
    });

    if (response.status === 200) {
      const responseJson: any = await response.json();
      return deserialise(responseJson.data);
    }
    throw new Error('Something went wrong with set onboarding config');
  } catch (err) {
    throw new Error('Something went wrong with set onboarding config');
  }
};
