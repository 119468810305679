import { useQuery } from 'react-query';
import {
  getQuickActionRest,
  QuickAction,
  QuickActionsData,
  QUICK_ACTIONS_TYPE,
} from '@leagueplatform/care-discovery-api';

export const useQuickAction = (quickActionId: string) => {
  const { data, isLoading, isError, isSuccess } = useQuery<
    QuickActionsData,
    Error
  >(['GET_QUICK_ACTIONS', quickActionId], () =>
    getQuickActionRest(quickActionId),
  );

  const quickActionParent = data?.included?.find(
    (quickAction: QuickAction) =>
      quickAction.type === QUICK_ACTIONS_TYPE.quickActionsParent,
  );

  return {
    submenuItems: data?.data,
    parent: quickActionParent,
    isLoading,
    isError,
    isSuccess,
  };
};
