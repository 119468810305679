import * as React from 'react';
import { useRouteMatch, Link } from '@leagueplatform/routing';
import {
  trackAnalyticsEvent,
  SCREEN_NAMES as GLOBAL_SCREEN_NAMES,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  genesisStyled,
  Flex,
  Box,
  HeadingTwo,
  SubtleButton,
  BodyOneSecondary,
  ThemeProps,
  HeadingThree,
  BodyTwoSecondary,
  Image,
  PrimaryButton,
  Overline,
} from '@leagueplatform/genesis-commons';
import {
  SkeletonBox,
  GenesisRouterLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ErrorPanel } from 'components/error-panel';
import { PRODUCT_AREA } from 'constants/analytics';
import { DASHBOARD_ASSET_MAP } from 'types/dashboard-assets.type';
import { useGetHealthMetrics } from 'hooks/use-get-health-metrics.hook';
import { InsightsCard } from '../insights-card/insights-card';

const InsightCardContainer = genesisStyled(Box)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: ${({ theme }: ThemeProps) => `${theme?.space.one}px`};
    @media screen and (max-width: ${({ theme }: ThemeProps) =>
      `${theme?.breakpoints.tablet}`}) {
      grid-template-columns: 1fr;
    }
`;

function StartTrackingInsights() {
  const { formatMessage } = useIntl();
  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    React.useState(false);
  const { path } = useRouteMatch();

  return (
    <>
      <Flex flexDirection="column" marginY="five">
        <HeadingTwo>{formatMessage({ id: 'YOUR_METRICS' })}</HeadingTwo>
        <Flex
          paddingLeft={{ laptop: 'twoAndHalf' }}
          paddingTop={{ tablet: 'twoAndHalf', phone: 'one' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display={{ tablet: 'initial', phone: 'none' }}>
            <Image
              src={
                handleStaticAsset(
                  DASHBOARD_ASSET_MAP.DASHBOARD_START_TRACKING_INSIGHTS,
                ) as string
              }
              alt=""
              width="100%"
              height="100%"
              maxWidth="405px"
            />
          </Box>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            maxWidth="375px"
            marginLeft={{ tablet: 'one' }}
          >
            <Box>
              <HeadingThree marginBottom="half">
                {formatMessage({ id: 'START_TRACKING_HEALTH_HEADER' })}
              </HeadingThree>
              <BodyTwoSecondary
                color="onSurface.text.primary"
                marginBottom="one"
              >
                {formatMessage({ id: 'START_TRACKING_HEALTH_BODY' })}
              </BodyTwoSecondary>
            </Box>
            <Flex
              alignItems={{ tablet: 'center', phone: 'flex-start' }}
              flexDirection="column"
              maxWidth={300}
            >
              <PrimaryButton
                onClick={() => {
                  setDisplayUnavailableOnWebModal(true);
                }}
              >
                {formatMessage({ id: 'CONNECT_APPS_DEVICES' })}
              </PrimaryButton>
              <GenesisRouterLink to={`${path}/insights`} marginTop="one">
                <Flex alignItems="center">
                  <Box display="inline" marginRight="half">
                    {formatMessage({ id: 'BROWSE_SUPPORTED_DATA' })}
                  </Box>
                  <Icon size={12} icon={ICONS.RIGHT_CHEVRON} />
                </Flex>
              </GenesisRouterLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {displayUnavailableOnWebModal ? (
        <UnavailableDeviceConnectModal
          onClose={() => setDisplayUnavailableOnWebModal(false)}
        />
      ) : null}
    </>
  );
}

export const YourInsights = () => {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();

  const {
    isLoading,
    isError,
    isRefetching,
    data: healthMetrics,
    refetch,
  } = useGetHealthMetrics({
    interval: 'instant',
    fields: { type: ['type', 'value', 'unit', 'trend', 'timestamp'] },
  });

  if (isLoading) {
    return <SkeletonBox height="450px" marginTop="twoAndHalf" />;
  }

  if (isError) {
    return <ErrorPanel isRefetching={isRefetching} onRetry={() => refetch()} />;
  }

  if (!healthMetrics?.data?.length) {
    return <StartTrackingInsights />;
  }

  return (
    <Flex flexDirection="column" marginTop="twoAndHalf">
      <HeadingTwo marginBottom="oneAndHalf">
        {formatMessage({ id: 'YOUR_METRICS' })}
      </HeadingTwo>
      <Overline as="h3" marginBottom="threeQuarters">
        {formatMessage({ id: 'APPS_AND_DEVICES' })}
      </Overline>
      <BodyOneSecondary marginBottom="oneAndHalf">
        {formatMessage({ id: 'YOUR_METRICS_SUBTITLE' })}
      </BodyOneSecondary>
      <InsightCardContainer>
        {healthMetrics.data.map(({ type, unit, value, trend, timestamp }) => (
          <InsightsCard
            key={type}
            type={type}
            trend={trend}
            timestamp={timestamp}
            onClick={() => {
              trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                product_area: PRODUCT_AREA.DASHBOARD,
                screen_name: GLOBAL_SCREEN_NAMES.HEALTH_PROFILE,
                metric_type: type,
                detail: 'click metric cards',
              });
            }}
            unit={unit}
            value={value}
          />
        ))}
      </InsightCardContainer>
      <SubtleButton
        alignSelf="center"
        marginTop="three"
        marginBottom="two"
        as={Link}
        to={`${path}/insights`}
        textDecoration="none"
        onClick={() => {
          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
            product_area: PRODUCT_AREA.DASHBOARD,
            screen_name: GLOBAL_SCREEN_NAMES.HEALTH_PROFILE,
            detail: 'click all insights',
          });
        }}
      >
        {formatMessage({ id: 'VIEW_ALL_METRICS' })}
      </SubtleButton>
    </Flex>
  );
};
