import {
  GetProviderServicesResponse,
  ProviderService,
} from '@leagueplatform/wallet-api';
import { NETWORK_ANALYTICS_ID } from 'pages/wallet-detail/types/network-analytics-ids';
import { getWalletConfig } from '../../../../../wallet.config';

const NO_NETWORK_LABEL = 'No-Network';
const IN_NETWORK_LABEL = 'In-Network';
const OUT_OF_NETWORK_LABEL = 'Out-of-Network';

interface ProviderServiceNetworkRaw {
  analyticsId: NETWORK_ANALYTICS_ID;
  label: string;
  services: ProviderService[] | null | undefined;
}
// TODO: Unify Cost & Coverage and Plan Progress typings
interface ProviderServiceNetwork
  extends Omit<ProviderServiceNetworkRaw, 'services'> {
  services: ProviderService[];
}

const handleNetworkFormat = (networksArray: ProviderServiceNetworkRaw[]) => {
  const { enableNoNetworkLiveBalances } = getWalletConfig();

  // If flag is not enabled use the current display logic
  // TODO: Remove this logic when feature goes to production
  if (!enableNoNetworkLiveBalances) {
    return networksArray.map((network) => ({
      ...network,
      services: network.services ?? [],
    }));
  }

  return networksArray
    .filter((network) => network.services !== null)
    .map((network) => ({ ...network, services: network.services ?? [] }));
};

export function selectCoverageNetworks({
  metadata,
  provider_services: providerServices,
}: GetProviderServicesResponse) {
  const networks: ProviderServiceNetwork[] = handleNetworkFormat([
    {
      analyticsId: NETWORK_ANALYTICS_ID.IN_NETWORK,
      label: IN_NETWORK_LABEL,
      services: providerServices?.in_network,
    },
    {
      analyticsId: NETWORK_ANALYTICS_ID.OUT_OF_NETWORK,
      label: OUT_OF_NETWORK_LABEL,
      services: providerServices?.out_of_network,
    },
  ]);

  const noNetwork: ProviderServiceNetwork[] = [
    {
      analyticsId: NETWORK_ANALYTICS_ID.NO_NETWORK,
      label: NO_NETWORK_LABEL,
      services: providerServices?.no_network ?? [],
    },
  ];

  const isNoNetworkUser = !!providerServices.no_network;

  return {
    metadata,
    networks: isNoNetworkUser ? noNetwork : networks,
    isNoNetworkUser,
  };
}
