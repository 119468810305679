import videojs from 'video.js';
import { kebabCase } from 'lodash';

const BaseComponent = videojs.getComponent('Component');
const GDS_VIDEO_JS_PREFIX = 'GDS-video';

/**
 * @extends BaseComponent.prototype.buildCSSClass
 * @description The Video.js base "`Component`" class has `buildCSSClass` method that would normally return an empty string.
 * @summary `appendGDSClass` overwrites the base video.js Component's `buildCSSClass` such that it appends Genesis class to all base-elements.
 */
function appendGDSClass(this: typeof BaseComponent.prototype) {
  const elementName = kebabCase(this?.name() || this.constructor.name || '');
  return [GDS_VIDEO_JS_PREFIX, elementName].filter(Boolean).join('-');
}

BaseComponent.prototype.buildCSSClass = appendGDSClass;
