import { Extension, QuestionnaireItem } from 'fhir/r4';
import { ParsedQuestionnaireItem } from 'types/questionnaire.types';

export const handleItemTypeChoice = (
  item: QuestionnaireItem,
): ParsedQuestionnaireItem => {
  let inputType;
  item.extension?.map((extension: Extension) => {
    if (
      /StructureDefinition\/questionnaire-itemControl\/?/gi.test(extension.url)
    ) {
      inputType = extension?.valueCodeableConcept?.coding?.[0].code;
    }
    return extension;
  });
  return { ...item, inputType };
};

export const handleAnswerTypeChoice = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value.map((individualAnswer: string) => ({
      valueCoding: {
        code: individualAnswer,
      },
    }));
  }
  return [
    {
      valueCoding: {
        code: value,
      },
    },
  ];
};
