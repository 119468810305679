import React, { FC } from 'react';

// Common Dependencies
import {
  Flex,
  Box,
  genesisStyled,
  StyleProps,
} from '@leagueplatform/genesis-commons';

// Constants
import { TRANSITIONS } from '../../constants/multi-step-footer-constants';

// Types
interface MultiStepPageProgressProps {
  css: StyleProps;
  totalPages: number;
  currentPage: number;
}

// Local Components
const PageIndicator = genesisStyled(Box)`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }: any) =>
    theme.colors.interactive.action.disabled};
  border-radius: ${({ theme }: any) => theme.radii.medium}px;
  border: none;
  height: ${({ theme }: any) => theme.sizes.quarter}px;
  width: 100%;
  &:not(&:first-of-type){
    margin-left: ${({ theme }: any) => theme.space.quarter}px;
  }
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    transform: scaleX(${({ isActive }: any) => Number(isActive)});
    transform-origin: left;
    background-color: ${({ theme }: any) =>
      theme.colors.interactive.action.primary};
    transition: transform ${TRANSITIONS.PAGE};
  }
`;

export const MultiStepPageProgress: FC<MultiStepPageProgressProps> = ({
  css,
  totalPages,
  currentPage,
}) => {
  const pageIndices = [...Array(totalPages).keys()];

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      padding=" 0 25%"
      marginBottom="oneAndHalf"
      css={css}
    >
      {pageIndices.map((pageIndex) => (
        <PageIndicator
          key={`page-progress-${pageIndex}`}
          isActive={currentPage >= pageIndex}
          currentPage={currentPage}
          pageIndex={pageIndex}
        />
      ))}
    </Flex>
  );
};
