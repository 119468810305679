import {
  LargeVisualCardWidget,
  XSVisualCardWidget,
  TextAndCTACardWidget,
  SmallVisualCardWidget,
  NotificationBannerWidget,
  NotificationCardWidget,
  WelcomeBanner,
  SectionHeader,
  CarouselWidget,
  ProgressRingCardWidget,
  ProgressBarCardWidget,
  TwoUpCardWidget,
  HeroBanner,
  ImageFooter,
  MetricCardWidget,
} from '@leagueplatform/masonry-widgets';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import {
  WidgetType,
  getObservabilityContext,
} from '@leagueplatform/masonry-api';
import { ErrorContext, captureError } from '@leagueplatform/observability';
import {
  CustomWidgetFeatureModule,
  MasonryConfig,
} from '../types/config.types';
import { CustomWidgetDataHooks } from '../types/custom-widget-data-hooks.types';
import { logError } from './log-error';
import { WidgetDataForDefaultWidgets } from '../types/default-widget-data.types';

type CustomModuleUtilFn = (
  moduleKey: ContainerWidgetItem['module'],
  customWidgetModules: MasonryConfig['customWidgetModules'],
) => any;

export const captureMasonryError = (
  errorMessage: string,
  context: ErrorContext = {},
  errorName?: string,
) =>
  captureError(
    new Error(errorMessage),
    getObservabilityContext({
      ...context,
      errorName: errorName || 'Masonry - Error',
    }),
  );

export const hasCustomModuleKey: CustomModuleUtilFn = (
  moduleKey,
  customWidgetModules = [],
): Boolean =>
  customWidgetModules.some((module) => module.moduleKey === moduleKey);

export const getCustomModuleByKey: CustomModuleUtilFn = (
  moduleKey,
  customWidgetModules = [],
): CustomWidgetFeatureModule | undefined =>
  customWidgetModules.find((module) => module.moduleKey === moduleKey);

export const getCustomWidgetModule = (
  moduleKey: string,
  customWidgetModules: any,
  appId: string,
) => {
  if (moduleKey === 'default') return null;
  if (
    !customWidgetModules ||
    !hasCustomModuleKey(moduleKey, customWidgetModules)
  ) {
    logError(
      `No custom module with %c moduleKey '${moduleKey}' %cprovided to Masonry config. Please check your config!!`,
    );
    captureMasonryError(
      `No customWidgetModule with moduleKey ${moduleKey} provided to Masonry config.`,
      {
        tags: {
          container: appId,
          moduleKey,
        },
      },
    );
    return null;
  }

  const widgetModule = getCustomModuleByKey(moduleKey, customWidgetModules);

  return widgetModule;
};

export type WidgetTypeToComponentMapType = Record<string, React.ElementType>;

/**
 * Simple components are standalone component wihout any dependecy on another widget type
 * Read more about them here - https://everlong.atlassian.net/wiki/spaces/MAS/pages/3585081365/Single+components
 * */
export const WidgetTypeToSimpleComponentMap: WidgetTypeToComponentMapType = {
  [WidgetType.TEXT_WITH_CTA_CARD]: TextAndCTACardWidget,
  [WidgetType.LARGE_VISUAL_CARD]: LargeVisualCardWidget,
  [WidgetType.SMALL_VISUAL_CARD]: SmallVisualCardWidget,
  [WidgetType.CAROUSEL]: CarouselWidget,
  [WidgetType.WELCOME_BANNER]: WelcomeBanner,
  [WidgetType.HERO_BANNER]: HeroBanner,
  [WidgetType.XS_VISUAL_CARD]: XSVisualCardWidget,
  [WidgetType.NOTIFICATION_BANNER]: NotificationBannerWidget,
  [WidgetType.NOTIFICATION_CARD]: NotificationCardWidget,
  [WidgetType.SECTION_HEADER]: SectionHeader,
  [WidgetType.PROGRESS_RING]: ProgressRingCardWidget,
  [WidgetType.PROGRESS_BAR]: ProgressBarCardWidget,
  [WidgetType.TWO_UP_CARD]: TwoUpCardWidget,
  [WidgetType.IMAGE_FOOTER]: ImageFooter,
  [WidgetType.METRIC_CARD]: MetricCardWidget,
  [WidgetType.HIDDEN]: () => null,
};

const ProviderNameByWidgetType: Record<string, keyof CustomWidgetDataHooks> = {
  [WidgetType.TEXT_WITH_CTA_CARD]: 'useTextWithCTACardWidgetData',
  [WidgetType.LARGE_VISUAL_CARD]: 'useLargeVisualCardWidgetData',
  [WidgetType.SMALL_VISUAL_CARD]: 'useSmallVisualCardWidgetData',
  [WidgetType.CAROUSEL]: 'useCarouselWidgetData',
  [WidgetType.HERO_BANNER]: 'useHeroBannerWidgetData',
  [WidgetType.WELCOME_BANNER]: 'useWelcomeBannerWidgetData', // Deprecate - v2
  [WidgetType.XS_VISUAL_CARD]: 'useXSVisualCardWidgetData',
  [WidgetType.NOTIFICATION_BANNER]: 'useNotificationBannerWidgetData',
  [WidgetType.PROGRESS_RING]: 'useProgressRingCardWidgetData',
  [WidgetType.PROGRESS_BAR]: 'useProgressBarCardWidgetData',
  [WidgetType.TWO_UP_CARD]: 'useTwoUpCardWidgetData',
  [WidgetType.IMAGE_FOOTER]: 'useImageFooterWidgetData',
} as const;

export const getProviderFromWidgetType = (
  widgetData: WidgetDataForDefaultWidgets,
  customWidgetModule: CustomWidgetFeatureModule,
  widgetModule: ContainerWidgetItem['module'],
  appId: string,
) => {
  const providerName = ProviderNameByWidgetType[widgetData.type];
  const provider = customWidgetModule?.customWidgetDataHooks?.[providerName];

  if (!provider) {
    logError(
      `Provider %c${providerName}%c for widget type - ${widgetData.type} is not provided to customWidgetModule with %c moduleKey - ${widgetModule}%c. Please check your config!!`,
    );
    captureMasonryError(
      `Provider ${providerName} is not provided to customWidgetModule.`,
      {
        tags: {
          container: appId,
          widgetType: widgetData.type,
          moduleKey: widgetModule,
          widgetId: widgetData.id,
        },
      },
    );
    return null;
  }

  return provider;
};

export const getCustomWidgetComponent = (
  customWidgetModule: CustomWidgetFeatureModule,
  widgetId: ContainerWidgetItem['id'],
  appId: string,
  customType?: string,
): React.ElementType | null => {
  if (!customType) {
    logError(
      `No %c'custom_type'%c key found for widget id ${widgetId} in widget data coming from %c'get_widget_container'%c API. Please check your Masonry container in BE.`,
    );
    captureMasonryError(
      `No 'custom_type' key found for widget id ${widgetId} in widget data coming from 'get_widget_container' API`,
      {
        tags: {
          widgetId,
          container: appId,
        },
      },
    );
    return null;
  }

  if (!customWidgetModule?.customWidgetsUI?.length) {
    logError(
      `No 'customWidgetsUI[]' is provided in Masonry config. Please check your config!`,
    );
    captureMasonryError(
      `No 'customWidgetsUI[]' is provided for moduleKey: ${customWidgetModule.moduleKey} in Masonry config`,
      {
        tags: {
          widgetId,
          container: appId,
          moduleKey: customWidgetModule.moduleKey,
        },
      },
    );
    return null;
  }

  const customWidget = customWidgetModule.customWidgetsUI.find(
    ({ type: componentType }: { type: string }) => componentType === customType,
  );
  if (!customWidget) {
    logError(
      `No custom widget with type %c${customType} %cis provided inside %c'customWidgetsUI[]'%c for moduleKey ${customWidgetModule.moduleKey}. Please check your config!`,
    );
    captureMasonryError(
      `No custom widget with type ${customType} found in Masonry Config.`,
      {
        tags: {
          widgetId,
          container: appId,
          moduleKey: customWidgetModule.moduleKey,
        },
      },
    );
    return null;
  }

  return customWidget.widget;
};
