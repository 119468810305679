import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_HEALTH_PROGRAM_CURATED_CAROUSELS_MESSAGE_TYPE =
  'get_health_program_curated_carousels';

export const getHealthProgramCuratedCarousels = async (
  type: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_HEALTH_PROGRAM_CURATED_CAROUSELS_MESSAGE_TYPE,
      info: { type },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
