import { useState } from 'react';
import { KEY } from '../../constants/keyboard.constants';

export const useTabFocus = (tabArray: any[], defaultTabIndex: number = 0) => {
  const [focusedTabIndex, setFocusedTabIndex] = useState(defaultTabIndex);

  const indexUp = () => {
    if (focusedTabIndex !== tabArray.length - 1) {
      setFocusedTabIndex(focusedTabIndex + 1);
    } else {
      setFocusedTabIndex(0);
    }
  };

  const indexDown = () => {
    if (focusedTabIndex !== 0) {
      setFocusedTabIndex(focusedTabIndex - 1);
    } else {
      setFocusedTabIndex(tabArray.length - 1);
    }
  };

  const handleKeyUp = ({ key }: { key: string }) => {
    switch (key) {
      case KEY.ARROW_UP:
      case KEY.ARROW_LEFT:
        indexDown();
        break;
      case KEY.ARROW_DOWN:
      case KEY.ARROW_RIGHT:
        indexUp();
        break;
      default:
        break;
    }
  };

  return { handleKeyUp, focusedTabIndex };
};
