import { useQuery, UseQueryOptions } from 'react-query';
import {
  GET_WALLET_DEPENDENT_CARDS_MESSAGE_TYPE,
  getWalletDependentCards,
} from '@leagueplatform/wallet-api';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { BenefitCard } from '../types/dependent-card.types';

interface GetWalletDependentCardsQueryResponse {
  benefit_cards: BenefitCard[];
}

export const useGetWalletDependentCardsQuery = (
  options?: UseQueryOptions<GetWalletDependentCardsQueryResponse>,
) => {
  const { userId } = useWalletParams();

  return useQuery<GetWalletDependentCardsQueryResponse>(
    [GET_WALLET_DEPENDENT_CARDS_MESSAGE_TYPE, userId],
    () => getWalletDependentCards(userId),
    // This query response is large, we are stopping it from re-fetching automatically
    { staleTime: Infinity, ...options },
  );
};
