import {
  UseControllerProps,
  useFormContext,
  useController,
} from 'react-hook-form';
import { OptionalStatus } from '../types/react-hook-form-input.type';
import { useInputStatus } from './use-input-status.hook';

/**
 *`useControlledInput` abstracts `react-form-hook`'s method of creating controlled components by combining the requisite `useFormContext` and `useController` hooks without compromising configurability
 */
export const useControlledInput = (
  inputOptions: UseControllerProps,
  optionalStatus?: OptionalStatus,
) => {
  const { control } = useFormContext();
  const controlledInput = useController({
    control,
    ...inputOptions,
  });
  const inputValidationState = useInputStatus(
    inputOptions?.name,
    controlledInput?.formState,
    optionalStatus,
  );

  return { ...controlledInput, inputValidationState };
};
