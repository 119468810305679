{
  "dragDescriptionKeyboard": "Nyomja le az Enter billentyűt a húzás megkezdéséhez.",
  "dragDescriptionKeyboardAlt": "Nyomja le az Alt + Enter billentyűket a húzás megkezdéséhez.",
  "dragDescriptionLongPress": "Hosszan nyomja meg a húzás elindításához.",
  "dragDescriptionTouch": "Koppintson duplán a húzás megkezdéséhez.",
  "dragDescriptionVirtual": "Kattintson a húzás megkezdéséhez.",
  "dragItem": "{itemText} húzása",
  "dragSelectedItems": "{count, plural, one {# kijelölt elem} other {# kijelölt elem}} húzása",
  "dragSelectedKeyboard": "Nyomja meg az Entert {count, plural, one {# kijelölt elem} other {# kijelölt elem}} húzásához.",
  "dragSelectedKeyboardAlt": "Nyomja meg az Alt + Enter billentyűket {count, plural, one {# kijelölt elem} other {# kijelölt elem}} húzásához.",
  "dragSelectedLongPress": "Tartsa lenyomva hosszan {count, plural, one {# kijelölt elem} other {# kijelölt elem}} húzásához.",
  "dragStartedKeyboard": "Húzás megkezdve. Nyomja le a Tab billentyűt az elengedési célhoz navigálásához, majd nyomja le az Enter billentyűt az elengedéshez, vagy nyomja le az Escape billentyűt a megszakításhoz.",
  "dragStartedTouch": "Húzás megkezdve. Navigáljon egy elengedési célhoz, majd koppintson duplán az elengedéshez.",
  "dragStartedVirtual": "Húzás megkezdve. Navigáljon egy elengedési célhoz, majd kattintson vagy nyomja le az Enter billentyűt az elengedéshez.",
  "dropCanceled": "Elengedés megszakítva.",
  "dropComplete": "Elengedés teljesítve.",
  "dropDescriptionKeyboard": "Nyomja le az Enter billentyűt az elengedéshez. Nyomja le az Escape billentyűt a húzás megszakításához.",
  "dropDescriptionTouch": "Koppintson duplán az elengedéshez.",
  "dropDescriptionVirtual": "Kattintson az elengedéshez.",
  "dropIndicator": "elengedésjelző",
  "dropOnItem": "Elengedés erre: {itemText}",
  "dropOnRoot": "Elengedés erre:",
  "endDragKeyboard": "Húzás folyamatban. Nyomja le az Enter billentyűt a húzás megszakításához.",
  "endDragTouch": "Húzás folyamatban. Koppintson duplán a húzás megszakításához.",
  "endDragVirtual": "Húzás folyamatban. Kattintson a húzás megszakításához.",
  "insertAfter": "Beszúrás {itemText} után",
  "insertBefore": "Beszúrás {itemText} elé",
  "insertBetween": "Beszúrás {beforeItemText} és {afterItemText} közé"
}
