import camelcaseKeys from 'camelcase-keys';
import { CamelCase } from 'type-fest';

// The same type as of the `input` parameter of `camelcaseKeys`:
type Camelizable = Parameters<typeof camelcaseKeys>[0];

/**
 * For information about why we need to manually create this type definition
 * rather than using the one from camelcase-keys, see [this PR](https://github.com/EverlongProject/league-web/pull/10461).
 */

/**
Convert keys of an object to camelcase strings.
*/
export type DeepCamelCaseKeys<T extends Camelizable> = T extends readonly any[]
  ? // Handle arrays or tuples.
    {
      // @ts-expect-error
      [P in keyof T]: DeepCamelCaseKeys<T[P]>;
    }
  : T extends Record<string, any>
  ? // Handle objects.
    {
      [P in keyof T as CamelCase<P>]: DeepCamelCaseKeys<T[P]>;
    }
  : // Return anything else as-is.
    T;

/**
 * Convert object keys to camel case, recursively.
 */
export const deepCamelCaseKeys = <T extends Camelizable>(input: T) =>
  camelcaseKeys(input, { deep: true }) as unknown as DeepCamelCaseKeys<T>;
