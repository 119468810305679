import { useQuery } from 'react-query';
import { useParams } from '@leagueplatform/routing';
import {
  IndividualChallengeDeserialisedData,
  getChallengeDataById,
  CHALLENGES_PATH,
} from '@leagueplatform/health-journey-api';

export const useGetChallengeByIdData = () => {
  const { challengeId } = useParams<{ challengeId: string }>();
  return useQuery<IndividualChallengeDeserialisedData, Error>(
    [CHALLENGES_PATH, challengeId],
    () => getChallengeDataById(challengeId),
    // setting a stale time of 5 minutes such that we can access data
    // without re-triggering the request (helpful for analytics)
    // we invalidate the cache when a user signs up and/or leaves the challenge
    // so the staleTime shouldn't cause the UI to be out of date
    { staleTime: 300000 },
  );
};
