import { SOLUTION_TYPE } from '@leagueplatform/care-collections-api';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { isAbsoluteUrl, normalizeUrl } from '@leagueplatform/web-common';

export const parseSolutionUrl = (url: string, type: string): string => {
  switch (type) {
    case SOLUTION_TYPE.healthProgram: {
      if (!isAbsoluteUrl(url)) {
        const id = url.replace(/(\/?)member\/health-programs\//, '');
        return `${getModulePath(LEAGUE_MODULE_NAMES.healthPrograms)}/${id}`;
      }
      return url;
    }
    // TODO: Add remaining cases
    default:
      return normalizeUrl(url);
  }
};
