import React, { useRef } from 'react';
import {
  HeadingThree,
  Image,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import {
  ConditionalLink,
  ConditionalLinkCard,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { ParagraphText } from '@leagueplatform/genesis-core';

export interface TwoUpCardContentProps {
  heading: string;
  body?: string;
  imageAltText: string;
  image: string;
  actionLink?: string;
}

export interface TwoUpCardComponentProps extends TwoUpCardContentProps {
  onClick?: (e: React.MouseEvent) => void;
}

export const TwoUpCardContent = ({
  heading,
  body,
  imageAltText,
  image,
  actionLink,
  onClick = () => {},
}: TwoUpCardComponentProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  return (
    <ConditionalLinkCard
      linkRef={linkRef}
      hasLink={Boolean(actionLink)}
      cardStyle={{
        padding: 'oneAndHalf',
        width: '100%',
      }}
    >
      <StackLayout space="$half">
        <Image
          borderRadius="medium"
          src={image}
          alt={imageAltText}
          height="4.2rem"
          width="4.2rem"
        />
        <StackLayout space="$quarter">
          <SubtitleOne as={HeadingThree} fontWeight="bold">
            <ConditionalLink onClick={onClick} ref={linkRef} url={actionLink}>
              {heading}
            </ConditionalLink>
          </SubtitleOne>
          {body && (
            <ParagraphText htmlStringOptions={BodyTextHtmlTagOptions}>
              {body}
            </ParagraphText>
          )}
        </StackLayout>
      </StackLayout>
    </ConditionalLinkCard>
  );
};
