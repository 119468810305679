import { CANADA, UNITED_STATES } from '../constants/constants';

export function getPostalCodeTranslationId(countryCode) {
  if (countryCode === UNITED_STATES) {
    return 'ZIP_CODE';
  }
  return 'POSTAL_CODE';
}

export function getSentenceCasePostalCodeTranslationId(countryCode) {
  if (countryCode === UNITED_STATES) {
    return 'ZIP_CODE_SENTENCE_CASE';
  }
  return 'POSTAL_CODE_SENTENCE_CASE';
}

export function getCityTranslationId(countryCode) {
  switch (countryCode) {
    case CANADA:
    case UNITED_STATES:
      return 'CITY';
    default:
      return 'CITY_TOWN';
  }
}

export function getProvinceTranslationId(countryCode) {
  switch (countryCode) {
    case CANADA:
      return 'PROVINCE';
    case UNITED_STATES:
      return 'STATE';
    default:
      return 'PROVINCE_COUNTY';
  }
}
