import React, { useEffect } from 'react';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { Divider, HeadingText } from '@leagueplatform/genesis-core';
import { ProgramLayout } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { useGetChallengePrivacySetting } from 'hooks/use-get-challenge-privacy-setting.hooks';
import { SettingsToggle } from 'components/privacy-settings-toggle/privacy-settings-toggle.component';
import { CLP_SCREEN_NAMES } from 'constants/analytics.constants';

const { CHALLENGES_PRIVACY_SETTINGS } = CLP_SCREEN_NAMES;
export const SettingsPage = (): JSX.Element => {
  const { data: challengeData, isLoading, error } = useGetChallengeByIdData();
  const {
    data: challengeConfig,
    isLoading: challengeConfigLoading,
    error: challengeConfigError,
  } = useGetChallengePrivacySetting();

  const { formatMessage } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  useEffect(() => {
    trackChallengesAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      screen_name: CHALLENGES_PRIVACY_SETTINGS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading || challengeConfigLoading) return <LoadingIndicator />;

  if (
    error ||
    challengeConfigError ||
    !challengeData?.data ||
    !challengeConfig?.data
  )
    return <FullPageError />;

  const originalShowFeedItems = challengeConfig?.data.showFeedItems;
  return (
    <ProgramLayout
      header={
        <HeadingText
          level="1"
          size="xxl"
          css={{
            marginY: '$threeQuarters',
          }}
        >
          {formatMessage({ id: 'PRIVACY' })}
        </HeadingText>
      }
      content={
        <>
          <Divider css={{ marginBottom: '$three' }} />
          <SettingsToggle originalShowFeedItems={originalShowFeedItems} />
        </>
      }
    />
  );
};
