import React from 'react';
import styled from 'styled-components';
import { vars } from '../../vars';
import PropTypes from 'prop-types';
import { noop, identity } from 'lodash';
import { Box, Flex } from '../primitives.view';
import { Image } from '../image/image.view';
import PDF_ICON from 'assets/pdf-icon.svg';
import FILE_ICON from 'assets/file-icon.svg';
import PLUS_ICON from 'assets/plus-icon.svg';
import { FormattedMessage, useIntl } from 'react-intl';

const IDENTIFICATION = 'identification';

export const isImageContentType = (contentType) =>
  contentType.startsWith('image/');

const toDataUrl = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });

const InputWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
`;

/* we're hiding the input way off screen */

const inputDefaultStyle = {
  width: '0.1vw',
  height: '01.vw',
  border: 'none',
  opacity: 0,
  position: 'fixed',
  left: '-200%',
};

const PDFPlaceholder = styled(Box)`
  height: 162px;
  background: url(${PDF_ICON}) no-repeat center/35px ${vars.colour.offWhite};
`;

const ImageRemovalLink = styled(Flex)`
  background: #fff;
  color: ${vars.colour.red};
  padding: 10px;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
`;

const PreviewContainer = styled(Box)`
  position: relative;
  border: 1px solid ${vars.colour.greyLight};
  margin-bottom: 10px;
  cursor: pointer;
  background: url(${FILE_ICON}) no-repeat center/35px ${vars.colour.offWhite};
`;

const Images = styled(Flex)`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
`;

const CrossSymbol = styled(Flex)`
  font-size: 30px;
  margin-right: 5px;
  padding-bottom: 5px;
`;

const FileInput = styled(Flex)`
  flex-direction: column;
  width: 100%;
  min-height: 162px;
  padding: 26px;
  margin-bottom: 10px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border: 1px dashed ${vars.colour.purple};
  &:focus {
    outline: none;
  }
  ${({ hasError }) =>
    hasError &&
    `border-color: ${vars.colour.red};
    background-color: ${vars.colour.redLightest}`};
`;

const UploadTitle = styled(Box)`
  color: ${vars.colour.purple};
  font-size: 14px;
  padding: 10px;
`;

const WarningBanner = styled(Box)`
  background: ${vars.colour.flushOrangeLight};
  color: ${vars.colour.black};
  padding: 6px 12px;
  font-size: 12px;
  line-height: 24px;
  border-radius: 3px;
  margin-bottom: 16px;
`;

const UploadIcon = () => {
  const { formatMessage } = useIntl();
  return (
    <img
      height="30"
      src={PLUS_ICON}
      alt={formatMessage({
        id: 'UPLOAD_DOCUMENT',
      })}
    />
  );
};

export class MultipleImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputs = [];
    this.focusInput = this.focusInput.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.updateBoxArray = this.updateBoxArray.bind(this);
    this.addBox = this.addBox.bind(this);
    this.removeBox = this.removeBox.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.setImageUrl = this.setImageUrl.bind(this);
    /* eslint-disable react/no-unused-state,  react/destructuring-assignment */
    this.state = {
      error: false,
      boxes: [],
      maxBoxCount: this.props.documentType === IDENTIFICATION ? 2 : 10,
    };

    const numInputs = this.props.contentUrls.length;
    // set up input and state depending on how many inputs there are
    for (let i = 0; i < numInputs; i += 1) {
      this.fileInputs.push(React.createRef());
      this.state.boxes.push({
        imageUrl: this.props.isEditing ? this.props.contentUrls[i] : null,
        isImage: !!this.props.isEditing,
        id: i,
        inputFocused: false,
        hasFile: !!this.props.isEditing,
        file: null,
        contentId: this.props.isEditing ? this.props.contentIds[i] : null,
      });
    }
  }

  componentDidMount() {
    // add a box so there is one more at the end
    this.addBox();
    this.updateBoxArray();
  }

  // this is necessary or else the componenet will keep updating in any
  // form that it is in, resulting in massive input delay
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.boxes !== this.state.boxes ||
      nextProps.documentType !== this.props.documentType ||
      nextState.maxBoxCount !== this.state.maxBoxCount
    ) {
      return true;
    }
    return false;
  }

  // disabling linter so can use setState in componentDidUpdate
  // it's a safe usage bc setState is in a condition everytime
  /* eslint-disable react/no-did-update-set-state, react/no-access-state-in-setstate */
  componentDidUpdate() {
    // if switching to id and had more than 2 images, remove the extras
    if (
      this.props.documentType === IDENTIFICATION &&
      this.state.boxes.length > 2
    ) {
      const updateBox = this.state.boxes.slice(0, 2);
      this.setState({
        boxes: updateBox,
        maxBoxCount: 2,
      });
    } else if (
      // switched from ID and had a completely filled out set of boxes
      this.props.documentType !== IDENTIFICATION &&
      this.state.boxes.length > 0 &&
      this.state.boxes[this.state.boxes.length - 1].hasFile
    ) {
      this.setState({ maxBoxCount: 10 }, this.addBox());
    }
  }

  onFileChange(i) {
    const file = this.fileInputs[i].current.files[0];
    const { previewOnChange, onFilePreviewError, setFieldError } = this.props;
    // doing this to remove potential red border on input
    // can always call since there's no case in which we want to preserve red
    // border if file has changed
    setFieldError('file', null);
    if (file) {
      if (previewOnChange) {
        toDataUrl(file)
          .then((dataUrl) => {
            const updateBox = this.state.boxes.map((b) => {
              if (b.id === i) {
                return {
                  ...b,
                  imageUrl: dataUrl,
                  isImage: isImageContentType(file.type),
                  hasFile: true,
                  file,
                };
              }
              return b;
            });
            this.setState(
              {
                boxes: updateBox,
              },
              () => {
                this.updateBoxArray();
                this.addBox();
              },
            );
          })
          .catch((error) => {
            onFilePreviewError({ error });
            this.setState({
              error: true,
            });
          });
      }
    }
  }

  onInputFocus(i) {
    const updateBox = this.state.boxes.map((b) => {
      if (b.id === i) {
        return {
          ...b,
          inputFocused: true,
        };
      }
      return b;
    });
    this.setState({
      boxes: updateBox,
    });
  }

  onInputBlur(i) {
    const updateBox = this.state.boxes.map((b) => {
      if (b.id === i) {
        return {
          ...b,
          inputFocused: false,
        };
      }
      return b;
    });
    this.setState({
      boxes: updateBox,
    });
  }

  setImageUrl(imageUrl, i) {
    const updateBox = this.state.boxes.map((b) => {
      if (b.id === i) {
        return {
          ...b,
          imageUrl,
        };
      }
      return b;
    });
    this.setState({
      boxes: updateBox,
    });
  }

  setError(error) {
    this.setState({
      error,
    });
  }

  // This function should be called everytime the state of the
  // boxes change since it sets the field value of the form
  // Otherwise, when user clicks submit, may not be submitting
  // the images in the boxes
  updateBoxArray() {
    const { onFilePreviewSuccess, documentType } = this.props;
    const { boxes } = this.state;
    const multInputData = boxes
      .filter((box) => box.imageUrl)
      .map((box) => {
        return { file: box.file, contentId: box.contentId };
      });
    // catch for identification type with more than 2 images
    if (documentType === IDENTIFICATION && boxes.length > 3) {
      onFilePreviewSuccess(multInputData.slice(0, 2));
    } else {
      onFilePreviewSuccess(multInputData);
    }
  }

  addBox() {
    const { boxes, maxBoxCount } = this.state;
    if (boxes.length < maxBoxCount) {
      this.fileInputs.push(React.createRef());
      this.setState({
        boxes: [
          ...this.state.boxes,
          {
            imageUrl: null,
            isImage: false,
            id: this.state.boxes.length,
            inputFocused: false,
            hasFile: false,
            file: null,
            contentId: null,
          },
        ],
      });
    }
  }

  removeBox(index) {
    const updateBox = this.state.boxes.filter((box) => box.id !== index);
    updateBox.map((box) => {
      const b = box;
      if (box.id > index) {
        b.id -= 1;
      }
      return b;
    });
    this.fileInputs = this.fileInputs.filter(
      (input, inputIndex) => inputIndex !== index,
    );
    this.setState(
      {
        boxes: updateBox,
      },
      () => {
        // case if removing from a completely filled set of boxes
        if (updateBox[updateBox.length - 1].hasFile) {
          this.addBox();
        }
        this.updateBoxArray();
      },
    );
  }

  focusInput(i) {
    this.fileInputs[i].current.click();
  }

  render() {
    const { boxes } = this.state;
    const {
      inputStyle,
      width,
      height,
      handleBlur,
      touched,
      errors,
      viewFile,
      documentType,
    } = this.props;
    return (
      <Box>
        {documentType === IDENTIFICATION && (
          <WarningBanner>
            <FormattedMessage id="IDENTIFICATION_TYPE_TWO_FILES" />
          </WarningBanner>
        )}
        <Images>
          {boxes.map((box) => {
            return (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width={width}
                key={box.id}
              >
                <InputWrapper
                  className={box.inputFocused ? 'input-focused ' : ''}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    tabIndex="0"
                    name="file-input"
                    ref={this.fileInputs[box.id]}
                    style={inputStyle}
                    onChange={() => {
                      this.onFileChange(box.id);
                    }}
                  />
                  {box.imageUrl && (
                    <PreviewContainer
                      onClick={() => {
                        viewFile?.(box.imageUrl);
                      }}
                    >
                      {box.isImage ? (
                        <Image
                          onClick={() => {
                            this.focusInput(box.id);
                          }}
                          url={box.imageUrl}
                          backgroundSize="cover"
                          backgroundPosition="top"
                          width={width}
                          height={height}
                        />
                      ) : (
                        <PDFPlaceholder width={width} />
                      )}
                      <ImageRemovalLink
                        onClick={(e) => {
                          e.stopPropagation();
                          this.removeBox(box.id);
                        }}
                      >
                        <CrossSymbol>&times;</CrossSymbol>
                        <FormattedMessage id="REMOVE" />
                      </ImageRemovalLink>
                    </PreviewContainer>
                  )}
                  {!box.imageUrl && (
                    <Box
                      width="100%"
                      onClick={() => {
                        this.focusInput(box.id);
                      }}
                    >
                      <FileInput
                        onBlur={handleBlur}
                        hasError={touched.file && errors.file}
                        tabIndex={box.id}
                      >
                        <UploadIcon />
                        <UploadTitle>
                          {box.id === 0 ? (
                            <FormattedMessage id="DRAG_OR_UPLOAD_PHOTO" />
                          ) : (
                            <FormattedMessage id="ADD_IMAGE" />
                          )}
                        </UploadTitle>
                      </FileInput>
                    </Box>
                  )}
                </InputWrapper>
              </Flex>
            );
          })}
        </Images>
      </Box>
    );
  }
}

MultipleImageInput.propTypes = {
  onFilePreviewSuccess: PropTypes.func,
  onFilePreviewError: PropTypes.func,
  inputStyle: PropTypes.shape({
    height: PropTypes.string,
    widgth: PropTypes.string,
  }),
  height: PropTypes.string,
  width: PropTypes.string,
  previewOnChange: PropTypes.bool,
  contentIds: PropTypes.arrayOf(PropTypes.string),
  contentUrls: PropTypes.arrayOf(PropTypes.string),
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    documentType: PropTypes.bool,
    name: PropTypes.bool,
    documentTypeOtherDescription: PropTypes.bool,
    file: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    documentType: PropTypes.bool,
    name: PropTypes.bool,
    documentTypeOtherDescription: PropTypes.bool,
    file: PropTypes.bool,
  }),
  viewFile: PropTypes.func,
  isEditing: PropTypes.bool.isRequired,
  setFieldError: PropTypes.func,
  documentType: PropTypes.string.isRequired,
};

MultipleImageInput.defaultProps = {
  inputStyle: inputDefaultStyle,
  onFilePreviewSuccess: noop,
  onFilePreviewError: noop,
  previewOnChange: false,
  contentIds: [],
  contentUrls: [],
  width: '100%',
  height: '100%',
  touched: {},
  errors: {},
  viewFile: identity,
  setFieldError: noop,
};
