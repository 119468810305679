export const getDependentCard = (
  dependentId: string,
  benefitId: string,
  benefitCards?: any[],
) => {
  if (benefitCards && dependentId) {
    const currentBenefitCard = benefitCards.find(
      (benefitCard) =>
        benefitCard.dependent_id === dependentId &&
        benefitCard.id === benefitId,
    );

    return currentBenefitCard;
  }

  return null;
};
