import React from 'react';
import { CareProvider } from '@leagueplatform/care-discovery-api';
import {
  BodyOne,
  css,
  genesisStyled,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import { ProviderCard } from '../provider-card/provider-card.component';
import { useCareDiscoveryConfig } from '../../care-discovery.config';

interface GetAssistanceNowProps {
  careProviders: CareProvider[];
}

const StyledListItem = genesisStyled('li')(
  css({
    marginBottom: 'oneAndHalf',
  }),
);

export const GetAssistanceNow = ({ careProviders }: GetAssistanceNowProps) => {
  const { formatMessage } = useIntl();
  const { showGetAssistanceNowSubheading } = useCareDiscoveryConfig();

  return (
    <StackLayout space="$oneAndHalf">
      <HeadingTwo>{formatMessage({ id: 'GET_ASSISTANCE_NOW' })}</HeadingTwo>
      {showGetAssistanceNowSubheading && (
        <BodyOne color="onSurface.text.subdued">
          {formatMessage({ id: 'GET_ASSISTANCE_NOW_SUBHEADING' })}
        </BodyOne>
      )}
      <ul>
        {careProviders.map((careProvider: CareProvider) => {
          const {
            title,
            description,
            url,
            iconUrl,
            phoneNumber,
            availabilityText,
          } = careProvider.attributes;

          return (
            <StyledListItem key={careProvider.id}>
              <ProviderCard
                title={title}
                description={description}
                url={url}
                image={iconUrl}
                phoneNumber={phoneNumber}
                availabilityText={availabilityText}
              />
            </StyledListItem>
          );
        })}
      </ul>
    </StackLayout>
  );
};
