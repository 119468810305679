import React from 'react';
import {
  BodyOne,
  Box,
  Flex,
  GenesisTheme,
  HeadingThree,
  PrimaryButton,
  SubtitleOne,
  useTheme,
} from '@leagueplatform/genesis-commons';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { CardBanner, StackLayout } from '@leagueplatform/web-common-components';
import { formatPhoneNumber, normalizeUrl } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { useUnknownConfigProperty } from '@leagueplatform/core';
import { useCareDiscoveryConfig } from '../../care-discovery.config';

interface ProviderCardProps {
  url: string;
  title: string;
  description: string;
  image: string;
  phoneNumber?: string;
  availabilityText?: string;
}

interface AvailabilityBannerProps {
  text: string;
}

const getCardBannerStyles = ({ breakpoints }: GenesisTheme) => ({
  imageContainer: {
    minHeight: '56px',
    width: '56px',
    borderRadius: 'medium',
    alignSelf: 'center',
    flexShrink: '0',
    margin: 'one',
    marginRight: 'none',
    '&&': {
      [`@media screen and (min-width: ${breakpoints.phone})`]: {
        margin: 'two',
        height: '136px',
        maxWidth: '136px',
        borderLeftStyle: 'none',
      },
    },
  },
  wrapper: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
});

const trackAnalytics = (title: string) => {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.GET_CARE,
    sub_product_area: SCREEN_NAMES.HOMEPAGE,
    screen_name: SCREEN_NAMES.HOMEPAGE,
    detail: 'connect with care team',
    sub_detail: title.toLowerCase(),
  });
};

const AvailabilityBanner = ({ text }: AvailabilityBannerProps) => (
  <Flex
    width="fit-content"
    justifyContent="center"
    alignItems="center"
    paddingY="quarter"
    paddingX="half"
    borderRadius="pill"
    backgroundColor="highlight.background.subdued"
  >
    <Box
      width="10px"
      height="10px"
      backgroundColor="highlight.icon"
      borderRadius="circle"
      marginRight="quarter"
    />
    <BodyOne fontSize="overline">{text}</BodyOne>
  </Flex>
);

export const ProviderCard = ({
  url,
  title,
  description,
  image,
  phoneNumber,
  availabilityText,
}: ProviderCardProps) => {
  const { formatMessage } = useIntl();
  const { showProviderAvailability } = useCareDiscoveryConfig();
  const theme = useTheme();
  const adjustForCardColorThemeDiscrepancy = useUnknownConfigProperty(
    '__care__adjustForCardColorThemeDiscrepancy',
  );

  // TODO: Since web is currently only supporting desktop, we want to disable links for phone numbers. When we begin supporting mobile web browsers, we'll add back support for phone number links.
  return (
    <CardBanner
      url={phoneNumber ? undefined : normalizeUrl(url)}
      image={image}
      styles={getCardBannerStyles(theme)}
      // TODO [CDSV-1428] Remove once color tokens are fixed.
      backgroundColor={
        adjustForCardColorThemeDiscrepancy
          ? 'surface.card.primary'
          : 'surface.card.secondary'
      }
      onClick={() => trackAnalytics(title)}
    >
      <StackLayout space="$half">
        {showProviderAvailability && availabilityText && (
          <AvailabilityBanner text={availabilityText} />
        )}
        <HeadingThree>{title}</HeadingThree>
        <BodyOne color="onSurface.text.subdued">{description}</BodyOne>
        {phoneNumber ? (
          <SubtitleOne as="h4">{formatPhoneNumber(phoneNumber)}</SubtitleOne>
        ) : (
          <PrimaryButton>
            {formatMessage({ id: 'START_A_VISIT' })}
          </PrimaryButton>
        )}
      </StackLayout>
    </CardBanner>
  );
};
