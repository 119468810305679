import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  ParagraphText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import {
  GenesisRouterLink,
  StackLayout,
} from '@leagueplatform/web-common-components';
import { Dependent, SpendingAccount } from '@leagueplatform/wallet-api';
import { isTypeHsaOrLsa } from '../utils/is-type-hsa-or-lsa.util';

const splitAfterCommaRegEx = /\s/;

const ShowDependentsContainer = genesisStyled(Flex)(
  css({
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  }),
);

const StyledUtilityText = genesisStyled(UtilityText)(
  css({
    whiteSpace: 'nowrap',
    color: 'onSurface.text.subdued',
  }),
);

interface SpendingAccountBalanceProps {
  canEditDependents: boolean;
  cardType?: string;
  dependents?: Dependent[];
  spendingAccount?: SpendingAccount;
}

const DEPENDENTS_HEADING_ID = 'dependent-list-heading';
const EDIT_DEPENDENTS_LINK_ID = 'dependent-edit-id';

export const SpendingAccountBalance = ({
  spendingAccount,
  cardType,
  dependents,
  canEditDependents,
}: SpendingAccountBalanceProps) => {
  const { formatMoney, formatMessage } = useIntl();
  // There are many different spending accounts, but we want to show the Dependents part only for HSA and LSA
  const isLsaOrHsa = isTypeHsaOrLsa(cardType);
  const showDependents = isLsaOrHsa && dependents;
  const showSpendingAccount = !!spendingAccount;

  const dependentsList = dependents
    ?.map((d) => d.first_name)
    .join(', ')
    .split(splitAfterCommaRegEx);

  return (
    <Flex
      backgroundColor="surface.background.secondary"
      borderRadius="medium"
      paddingY="one"
      paddingX={{ tablet: 'none', _: 'two' }}
      marginBottom="two"
      alignContent="center"
      flexDirection={{ tablet: 'row', _: 'column' }}
      justifyContent="center"
    >
      {showDependents && (
        <ShowDependentsContainer
          paddingX={{ tablet: 'two', _: 'none' }}
          paddingY={{ tablet: 'none', _: 'one' }}
          borderStyle={!showSpendingAccount && 'none'}
          borderColor="onSurface.border.subdued"
          borderWidth="thin"
          borderRightStyle={{ tablet: showSpendingAccount ? 'solid' : 'none' }}
          borderBottomStyle={{
            tablet: 'none',
            _: 'solid',
          }}
        >
          <StackLayout space="$half">
            <StyledUtilityText
              as="h3"
              emphasis="emphasized"
              size="eyebrow"
              id={DEPENDENTS_HEADING_ID}
            >
              {formatMessage({ id: 'DEPENDENTS' })}
            </StyledUtilityText>
            <Flex as="ul" flexWrap="wrap" role="list">
              {dependentsList?.map((dependentFirstName) => (
                <ParagraphText as="li">
                  {dependentFirstName}&nbsp;
                </ParagraphText>
              ))}
            </Flex>
          </StackLayout>
          {canEditDependents && (
            <Flex
              alignItems="center"
              aria-labelledby={`${EDIT_DEPENDENTS_LINK_ID} ${DEPENDENTS_HEADING_ID}`}
              as={GenesisRouterLink}
              display="flex"
              fontSize="subtitle2"
              gap="half"
              id={EDIT_DEPENDENTS_LINK_ID}
              padding="threeQuarters"
              to="/member/wallet/dependents"
            >
              {formatMessage({ id: 'EDIT' })}
            </Flex>
          )}
        </ShowDependentsContainer>
      )}
      {showSpendingAccount && (
        <Flex
          justifyContent={{
            tablet: 'center',
            _: showDependents ? 'left' : 'center',
          }}
          alignItems="center"
          paddingX={{ tablet: 'oneAndHalf', _: 'none' }}
          paddingTop={{ tablet: 'half' }}
          paddingY={{ tablet: 'none', _: 'one' }}
        >
          <StackLayout
            space="$half"
            css={{ textAlign: showDependents ? 'left' : 'center' }}
          >
            <StyledUtilityText emphasis="emphasized" size="eyebrow">
              {formatMessage({ id: 'REMAINING_BALANCE' })}
            </StyledUtilityText>
            <HeadingText
              size="lg"
              level="3"
              as="span"
              css={{ marginTop: '$none !important' }}
            >
              {formatMoney(spendingAccount.balance)}
            </HeadingText>
          </StackLayout>
        </Flex>
      )}
    </Flex>
  );
};
