import React, { forwardRef } from 'react';
import { Text, TextProps } from '../Text';

/**
 * @deprecated use `HeadingText` from `genesis-core` instead
 */

export const HeadingFour: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="h4"
      color="onSurface.text.primary"
      typography="headingFour"
      ref={ref}
      {...props}
    />
  )
);

HeadingFour.displayName = 'HeadingFour';
