import { AddOn } from './add-on';
import { AffixContent } from './affix-content';

export const InputElements = {
  AddOn,
  AffixContent,
};

InputElements.AffixContent.displayName = 'InputElements.AffixContent';
InputElements.AddOn.displayName = 'InputElements.AddOn';
