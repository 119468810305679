import * as React from 'react';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  MoreOptionsLink,
} from '@leagueplatform/health-profile-common';
import {
  StackLayout,
  Button,
  HeadingText,
  UtilityText,
  Box,
  StatusBadge,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  LoadingIndicator,
  Sidebar,
} from '@leagueplatform/web-common-components';
import {
  DATE_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { useInfiniteHealthMetricsV3 } from 'hooks/use-get-health-metrics-v3.hook';
import { ListItem } from 'components/list-item';
import { ErrorPanel } from 'components/error-panel';
import { getValueUnitDisplayLabel } from 'utils/get-value-unit-display-label';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { HealthPrograms } from 'pages/details2/components/health-programs';
import { HealthLiteracyContent } from 'components/health-literacy/health-literacy-content';
import { useIsSelfReportedDataEnabled } from 'hooks/use-is-self-reported-data-enabled.hook';
import { AddMetricDataButton } from 'pages/details2/components/add-metric-data-button';
import { AddEditDataModal } from 'components/add-edit-data/add-edit-data-modal';
import { NoDataAvailable } from 'pages/details3/components/no-data-available';
import { useDashboardConfig } from '../../dashboard.config';

type AllRecordedDataProps = {
  match: {
    params: {
      dataType: string;
    };
  };
};

const handleAnalytics = (
  eventName: EVENT_NAME,
  metricType?: string,
  detail?: string,
) =>
  trackAnalyticsEvent(eventName, {
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.ALL_RECORDED_DATA,
    metric_type: metricType,
    ...(detail && { detail }),
  });

export const AllRecordedData = ({
  match: {
    params: { dataType },
  },
}: AllRecordedDataProps) => {
  const { formatMessage } = useIntl();
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const { isSelfReportedDataEnabled } = useIsSelfReportedDataEnabled();
  const {
    isLoading: isLoadingHealthMetrics,
    isError: isErrorHealthMetrics,
    isRefetching: isRefetchingHealthMetrics,
    data: healthMetrics,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteHealthMetricsV3({
    filter: {
      type: dataType,
    },
    fields: { type: ['type', 'value', 'source', 'unit', 'timestamp'] },
  });
  const { showFullTimeStamp } = useDashboardConfig();
  const metricDisplayName = healthMetrics?.pages[0].data[0].metricDisplayName;

  React.useEffect(() => {
    handleAnalytics(EVENT_NAME.SCREEN_LOADED, dataType);
  }, [dataType]);

  if (isLoadingHealthMetrics && !healthMetrics) {
    return <LoadingIndicator />;
  }
  const allMetricConfigs =
    healthMetrics?.pages[0].data[0].metricComponents.data;
  const firstConfigName = allMetricConfigs?.[0].name;
  // display the config name with the entry if there are multiple metric configs
  // and if the `name` field of the metric configs are different
  const displayConfigName =
    Boolean(allMetricConfigs?.length) &&
    !allMetricConfigs?.every(
      (metricConfig) => metricConfig.name === firstConfigName,
    );
  // TODO: implement handleEditClick functionality after completion of
  // edit data screens in https://everlong.atlassian.net/browse/ABTM-4654
  // const handleEditClick = () => {
  //   handleAnalytics(EVENT_NAME.BUTTON_CLICKED, dataType, 'edit reading');
  // };

  const showHealthLiteracyOnAllRecordedDataScreen =
    healthMetrics?.pages[0].data[0].showHealthLiteracyOnAllRecordedDataScreen;
  const showAddDataButton =
    healthMetrics?.pages[0].data[0].metricComponents.data.some(
      (metricConfig) =>
        metricConfig.sourceType ===
        ('self_reported' || 'self_reported_no_input'),
    ) && isSelfReportedDataEnabled;

  const canConnectDevice =
    healthMetrics?.pages[0].data[0].metricComponents.data.some(
      (config) => config.sourceType === 'device',
    );
  const canSelfReport =
    healthMetrics?.pages[0].data[0].metricComponents.data.some(
      (config) => config.sourceType === 'self_reported',
    );

  // the user has no data for this metric
  const showEmptyState =
    healthMetrics?.pages[0].data[0].values.data.length === 0;

  return (
    <HealthProfilePageWrapper
      title={formatMessage({
        id: 'ALL_RECORDED_DATA',
      })}
      sidebar={
        <Sidebar>
          <MoreOptionsCard>
            <MoreOptionsLink
              to="data-sources"
              onClick={() =>
                handleAnalytics(
                  EVENT_NAME.BUTTON_CLICKED,
                  dataType,
                  'view data sources',
                )
              }
            >
              {formatMessage({ id: 'VIEW_DATA_SOURCES' })}
            </MoreOptionsLink>
          </MoreOptionsCard>
          {showAddDataButton && healthMetrics ? (
            <AddEditDataModal
              metricConfigId={healthMetrics?.pages[0].data[0].id}
              inputConfig={healthMetrics?.pages[0].data[0].addInputConfig.data}
              modalTrigger={
                <AddMetricDataButton
                  onClick={() =>
                    handleAnalytics(
                      EVENT_NAME.BUTTON_CLICKED,
                      dataType,
                      'add data',
                    )
                  }
                />
              }
            />
          ) : null}
        </Sidebar>
      }
    >
      <HeadingText
        size="xxl"
        level="2"
        css={{ paddingTop: '$oneAndHalf', paddingBottom: '$twoAndHalf' }}
      >
        {metricDisplayName}
      </HeadingText>
      <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
        {isErrorHealthMetrics ? (
          <ErrorPanel
            isRefetching={isRefetchingHealthMetrics}
            onRetry={refetch}
          />
        ) : null}

        {/* empty state */}
        {showEmptyState ? (
          <NoDataAvailable
            metric={healthMetrics.pages[0].data[0].metricDisplayName}
            canSelfRecordData={canSelfReport!}
            canConnectDevice={canConnectDevice!}
            metricConfigId={healthMetrics?.pages[0].data[0].id}
            inputConfig={healthMetrics?.pages[0].data[0].addInputConfig.data}
          />
        ) : null}
        {!isErrorHealthMetrics && !showEmptyState ? (
          <Box as="ul" css={{ width: '100%' }}>
            {healthMetrics?.pages.map((page) =>
              page.data[0].values.data.map((dataMetric) => {
                const {
                  id,
                  sourceDisplayName,
                  timestamp,
                  metricComponent,
                  riskIndicator,
                } = dataMetric;
                const isSelfReported =
                  metricComponent.data.sourceType === 'self_reported';
                const date = formatDateWithUserProfileLocation(
                  timestamp,
                  DATE_FORMAT,
                );
                const displayTimestamp = formatDateWithUserProfileLocation(
                  timestamp,
                  { hour: 'numeric', minute: 'numeric' },
                );
                const { referenceRange } = dataMetric.metricComponent.data;
                return (
                  <ListItem as="li" key={id}>
                    <StackLayout>
                      {riskIndicator && (
                        <StatusBadge
                          status={riskIndicator?.style}
                          label={riskIndicator?.label}
                          showLight={false}
                        />
                      )}
                      <UtilityText as="p" emphasis="emphasized">
                        {getValueUnitDisplayLabel({
                          value: dataMetric.value,
                          label: dataMetric.label,
                          unitOptions: dataMetric.metricComponent.data.unit,
                        }).map(([displayValue, displayUnit], index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={`${index} + ${dataMetric.type}`}>
                            {displayValue} {displayUnit}{' '}
                            {displayConfigName
                              ? ` (${metricComponent.data.name.toLocaleLowerCase()})`
                              : null}
                          </span>
                        ))}
                      </UtilityText>
                      {referenceRange && (
                        <UtilityText
                          emphasis="subtle"
                          css={{ typography: '$caption' }}
                        >
                          {referenceRange}
                        </UtilityText>
                      )}
                      <UtilityText
                        emphasis="subtle"
                        css={{ typography: '$caption' }}
                      >
                        {sourceDisplayName}
                      </UtilityText>
                    </StackLayout>
                    <StackLayout
                      orientation="horizontal"
                      horizontalAlignment="end"
                      verticalAlignment="center"
                      spacing="$half"
                    >
                      <StackLayout
                        horizontalAlignment="end"
                        verticalAlignment="center"
                      >
                        <UtilityText
                          css={{
                            paddingTop: '$half',
                          }}
                        >
                          {date}
                        </UtilityText>
                        {showFullTimeStamp && (
                          <UtilityText
                            as="p"
                            emphasis="subtle"
                            css={{ typography: '$caption' }}
                          >
                            {displayTimestamp}
                          </UtilityText>
                        )}
                      </StackLayout>
                      {isSelfReported && isSelfReportedDataEnabled && (
                        <AddEditDataModal
                          metricConfigId={page.data[0].id}
                          observationId={dataMetric.id}
                          inputConfig={dataMetric.editInputConfig.data}
                          label={dataMetric.label}
                          value={dataMetric.value}
                          timestamp={dataMetric.timestamp}
                          effectiveDateTime={dataMetric.effectiveTime}
                          modalTrigger={
                            <Button
                              priority="secondary"
                              quiet
                              icon="illustrativeCompose"
                              hideLabel
                            >
                              {formatMessage(
                                { id: 'EDIT_METRIC_DATA' },
                                {
                                  metric: metricDisplayName,
                                },
                              )}
                            </Button>
                          }
                        />
                      )}
                    </StackLayout>
                  </ListItem>
                );
              }),
            )}
            {}

            {hasNextPage && (
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '$twoAndHalf',
                }}
              >
                <Button
                  priority="secondary"
                  disabled={isFetchingNextPage}
                  onClick={() => {
                    fetchNextPage();
                  }}
                >
                  {formatMessage({ id: 'LOAD_MORE' }).toLocaleUpperCase()}
                </Button>
              </Box>
            )}
          </Box>
        ) : null}

        {showHealthLiteracyOnAllRecordedDataScreen ? (
          <>
            <HealthPrograms metric={dataType} />
            <HealthLiteracyContent metric={dataType} />
          </>
        ) : null}
      </StackLayout>
    </HealthProfilePageWrapper>
  );
};
