import * as React from 'react';
import { Box, BoxProps } from '@leagueplatform/genesis-commons';

type SidebarCardProps = BoxProps;

export function SidebarCard(props: SidebarCardProps) {
  return (
    <Box
      borderStyle={{ laptop: 'solid' }}
      borderWidth="thin"
      borderColor="interactive.border.disabled"
      borderRadius="medium"
      backgroundColor="surface.background.primary"
      boxShadow={{ laptop: 'card' }}
      padding={{ laptop: 'oneAndHalf' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
