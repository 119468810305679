import * as React from 'react';
import {
  HealthProgramCarousels,
  useHealthProgramsCategory,
} from '@leagueplatform/health-programs';
import { SCREEN_NAMES } from 'constants/analytics';
import { Box } from '@leagueplatform/genesis-core';

type HealthProgramsProps = {
  metric: string;
};

export function HealthPrograms({ metric }: HealthProgramsProps) {
  const healthProgramsCategory = useHealthProgramsCategory({
    categoryId: metric,
  });
  return (
    <Box css={{ width: '100%' }}>
      <HealthProgramCarousels
        hideLoadingSkeleton
        isLoading={healthProgramsCategory.isLoading}
        carousels={[
          {
            title: healthProgramsCategory.name,
            programs: healthProgramsCategory.programs,
            analyticsProps: { metricType: metric },
          },
        ]}
        carouselsCategory={SCREEN_NAMES.DASHBOARD_METRIC_SCREEN}
      />
    </Box>
  );
}
