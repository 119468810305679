import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_WALLET_DEPENDENT_CARDS_MESSAGE_TYPE =
  'get_wallet_dependent_cards';

export const getWalletDependentCards = async (userId?: string) => {
  const info = {
    user_id: userId,
  };
  const params = {
    message_type: GET_WALLET_DEPENDENT_CARDS_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
