import { Auth } from '@leagueplatform/auth';
import { captureError } from '@leagueplatform/observability';

export const getAuthenticatedDocument = async (
  downloadURL: string,
): Promise<{ error: any; data: any }> => {
  try {
    const token = await Auth.getToken();
    const response = await fetch(downloadURL, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });
    if (!response.ok) {
      const body = await response.clone().json();
      captureError(new Error('API getAuthenticatedDocument: call failed'), {
        errorName: 'Network Fetch Error',
        tags: { requestUrl: response.url },
        context: {
          Errors: {
            ...body.errors,
          },
          Response: {
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            type: response.type,
            url: response.url,
            method: 'GET',
            body: JSON.stringify(body),
          },
        },
      });
      return { error: response, data: null };
    }
    return { error: null, data: response };
  } catch (error) {
    return { error, data: null };
  }
};
