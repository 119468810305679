import { useIntl } from '@leagueplatform/locales';
import * as React from 'react';
import { useFormContext } from '@leagueplatform/web-common';
import { formatAddress } from '../utils/format-address.util';
import { useGetUserDefaultAddress } from './use-get-user-default-address.hook';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';
import { checkIsDefaultAddress } from '../utils/check-is-default-address';

interface AddressOption {
  label: string;
  hint: string;
  isDefault: boolean;
}

const {
  addressLine1,
  addressLine2,
  city: cityInput,
  state: stateInput,
  postal: postalInput,
  isDefault,
} = orderNewCardFormInputNames;

export const useSelectAddressStep = () => {
  const { formatMessage } = useIntl();
  const { getValues, setValue } = useFormContext();
  const { address1, address2, city, postal, state } =
    useGetUserDefaultAddress();

  const isDefaultAddress = checkIsDefaultAddress(getValues(isDefault));
  // if a user selects their default address, preload those inputs with the default address
  React.useEffect(() => {
    if (isDefaultAddress === null || undefined) return;
    if (isDefaultAddress) {
      setValue(addressLine1, address1);
      setValue(addressLine2, address2);
      setValue(cityInput, city);
      setValue(stateInput, state);
      setValue(postalInput, postal);
    } else {
      setValue(addressLine1, undefined);
      setValue(addressLine2, undefined);
      setValue(cityInput, undefined);
      setValue(stateInput, undefined);
      setValue(postalInput, undefined);
    }
  }, [address1, address2, city, isDefaultAddress, postal, setValue, state]);

  const options: AddressOption[] = React.useMemo(
    () => [
      {
        label: formatMessage({ id: 'DEFAULT_ADDRESS' }),
        hint: formatAddress(address1!, city!, postal!, state!, address2),
        isDefault: true,
      },
      {
        label: formatMessage({ id: 'CHOOSE_NEW_ADDRESS' }),
        hint: formatMessage({ id: 'CHOOSE_NEW_ADDRESS_HINT' }),
        isDefault: false,
      },
    ],
    [address1, address2, city, formatMessage, postal, state],
  );

  return { options };
};
