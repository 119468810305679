import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletCardQuery } from 'pages/wallet-detail/hooks/use-wallet-card-query.hook';

export function useWalletCard(cardHtml?: string) {
  const { userId, benefitId } = useWalletParams();
  const [memberId] = useSelectedMember();

  // if the cardHtml prop is present, we'll render that;
  // if not, we get the HTML from the query cache.
  const { data: walletCard, isLoading } = useWalletCardQuery(
    userId,
    benefitId,
    memberId?.id,
    {
      enabled: !cardHtml,
    },
  );

  let card = walletCard?.front;

  // if cardHtml is provided, use that instead
  if (cardHtml) {
    card = { src: cardHtml, type: 'html', description: '' };
  }

  const isImageCard =
    walletCard?.front.type === 'url' || walletCard?.front.type === 'base64';

  const cardFaces = [walletCard?.front!, walletCard?.back!].filter(
    (face) => !!face,
  );

  return {
    card,
    isLoading,
    isImageCard,
    cardFaces,
  };
}
