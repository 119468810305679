import React, { useEffect } from 'react';
import { Input, Select, Flex, Text, spaceInPx } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  queryHelpers,
  useMediaQuery,
  Select as SelectGenesis,
} from '@leagueplatform/genesis-core';

const StyledInput = styled(Input)`
  margin-right: ${spaceInPx(2)};
  ${({ isMobile }) => isMobile && 'width: 100px;'};
`;

const FeetText = styled(Text)`
  &:last-of-type {
    margin-bottom: ${spaceInPx(1)};
  }
`;

const getFeet = (value) => Math.floor(value / 12);

const getInches = (value) => value % 12;

// remove non-digit characters and parse to int
const convertToNum = (value) => {
  const cleanVal = value.replace(/\D/g, '');
  return cleanVal ? parseInt(cleanVal, 10) : null;
};

const convertFeetToInches = (feet) => feet && feet * 12;

export const ModuleNumberInput = ({
  unitOptions,
  defaultUnit,
  id,
  placeholderText,
  onChangeHandler,
  setShowArrow,
  questionId,
  userSelections,
  isSkippable,
  ariadescribedby,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  const { type: defaultUnitType } = defaultUnit;
  const userAnswer = userSelections[questionId] || {};
  const {
    unit: unitValue = defaultUnitType,
    value: inputValue = null,
    feet = unitValue === 'in' && inputValue !== null
      ? getFeet(inputValue)
      : null,
    inches = unitValue === 'in' && inputValue !== null
      ? getInches(inputValue)
      : null,
    error: { hasError = false, errorMsg = '' },
  } = userAnswer;

  const unitsFullNames = {
    cm: formatMessage({ id: 'CENTIMETERS' }),
    ft: formatMessage({ id: 'FEET' }),
    in: formatMessage({ id: 'INCHES' }),
    kg: formatMessage({ id: 'KILOGRAMS' }),
    lbs: formatMessage({ id: 'POUNDS' }),
  };

  // For inches, need to check for 2 values because there are two inputs.
  // otherwise, check if a value is filled
  useEffect(() => {
    setShowArrow(
      isSkippable || unitValue === 'in'
        ? feet !== null && inches !== null
        : inputValue !== null,
    );
  }, [unitValue, feet, inches, inputValue, setShowArrow, isSkippable]);

  // Need to do this because Input (StyledInput) needs it in this form
  const normalizedUnitOptions = unitOptions.map(({ type, name }) => ({
    label: name,
    text: name,
    value: type,
  }));

  // Create the selector component
  const mobileUnitSelect = (
    <SelectGenesis
      name="unitOptions"
      id="unitOptions"
      placeholder={
        unitValue && unitValue.length > 0 ? defaultUnitType : unitValue
      }
      options={normalizedUnitOptions}
      value={unitValue}
      noSelectionLabel={
        unitValue && unitValue.length > 0 ? defaultUnitType : unitValue
      }
      onChange={(selected) => {
        onChangeHandler({
          unit: selected.currentTarget.value,
          value: null,
          feet: null,
          inches: null,
        });
      }}
    />
  );

  const unitSelect = (
    <Select
      name="unitOptions"
      id="unitOptions"
      options={normalizedUnitOptions}
      selectedValue={unitValue}
      noSelectionLabel={
        unitValue && unitValue.length > 0 ? defaultUnitType : unitValue
      }
      onChange={({ altered, value }) => {
        if (altered) {
          onChangeHandler({
            unit: value,
            value: null,
            feet: null,
            inches: null,
          });
        }
      }}
    />
  );

  const error = (
    <Text color="red" fontSize={2} mt={0} mb={0}>
      {errorMsg}
    </Text>
  );

  // If the unit is inches, then we show two inputs
  // Every other unit is one input
  if (unitValue === 'in') {
    return (
      <>
        <Flex alignItems="center" key={id}>
          <VisuallyHidden as="label" htmlFor="feet-input">
            {unitsFullNames.ft}
          </VisuallyHidden>
          <StyledInput
            aria-describedby={ariadescribedby}
            name="ft"
            id="feet-input"
            type="text"
            placeholder={placeholderText}
            hasError={hasError}
            onChange={({ target: { value } }) => {
              const numVal = convertToNum(value);
              onChangeHandler({
                inches,
                feet: numVal,
                value: inches + convertFeetToInches(numVal),
                unit: unitValue,
              });
            }}
            isMobile={isMobile}
            value={feet === null ? '' : feet}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          <FeetText mr={2} aria-hidden="true">
            {formatMessage({ id: 'FT' })}
          </FeetText>
          <VisuallyHidden as="label" htmlFor="inches-input">
            {unitsFullNames.in}
          </VisuallyHidden>
          <StyledInput
            name="in"
            id="inches-input"
            type="text"
            placeholder={placeholderText}
            hasError={hasError}
            onChange={({ target: { value } }) => {
              const numVal = convertToNum(value);
              onChangeHandler({
                feet,
                inches: numVal,
                value: numVal + convertFeetToInches(feet),
                unit: unitValue,
              });
            }}
            isMobile={isMobile}
            value={inches === null ? '' : inches}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          {isMobile ? mobileUnitSelect : unitSelect}
        </Flex>
        {hasError && errorMsg && error}
      </>
    );
  }
  return (
    <>
      <Flex alignItems="center" key={id}>
        <VisuallyHidden as="label" htmlFor={id}>
          {unitsFullNames[unitValue] ??
            formatMessage({ id: 'DRINKS_PER_WEEK' })}
        </VisuallyHidden>
        <StyledInput
          aria-describedby={ariadescribedby}
          type="text"
          name={unitValue}
          id={id}
          placeholder={placeholderText}
          hasError={hasError}
          onChange={({ target: { value } }) => {
            const numVal = convertToNum(value);
            onChangeHandler({
              unit: unitValue,
              value: numVal,
            });
          }}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={inputValue === null ? '' : inputValue}
        />
        {unitOptions &&
          unitOptions.length > 0 &&
          (isMobile ? mobileUnitSelect : unitSelect)}
      </Flex>
      {hasError && errorMsg && error}
    </>
  );
};

ModuleNumberInput.propTypes = {
  unitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  defaultUnit: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  setShowArrow: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userSelections: PropTypes.shape({}).isRequired,
  isSkippable: PropTypes.bool,
  ariadescribedby: PropTypes.string,
};

ModuleNumberInput.defaultProps = {
  placeholderText: '',
  defaultUnit: {},
  unitOptions: [],
  isSkippable: false,
  ariadescribedby: '',
};
