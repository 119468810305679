import React, { FunctionComponent } from 'react';
import { ThemeProvider as EmoThemeProvider } from 'emotion-theming';
import { GenesisTheme } from './types';
import { themeWithGlobalTokens } from '../utils/themeWithGlobalTokens';

export interface ThemeProviderProps<ThemeType> {
  theme?: Partial<ThemeType> | ((outerTheme: ThemeType) => ThemeType);
  children: React.ReactChild; // theme provider only allows one react child
}

export const GenesisThemeProvider: FunctionComponent<
  ThemeProviderProps<GenesisTheme>
> = ({ theme, ...props }) => {
  const hostAppTheme: GenesisTheme = theme as GenesisTheme;

  const completeTheme = themeWithGlobalTokens(hostAppTheme);

  return <EmoThemeProvider theme={completeTheme} {...props} />;
};
