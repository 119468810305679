import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_USER_CAMPAIGNS_MESSAGE_TYPE = 'get_user_campaigns';

export const getUserCampaigns = async (
  campaignTypes: Array<string>,
  campaignStatuses: Array<string>,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_USER_CAMPAIGNS_MESSAGE_TYPE,
      info: {
        campaign_types: campaignTypes, // currently only used for challenge. Other options : [challenge|opt-in|suggested]
        campaign_statuses: campaignStatuses,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
