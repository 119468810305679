import React from 'react';
import { COMPLEX_WIDGET_CARDS_WIDTH } from '@leagueplatform/masonry-widgets';
import { StackItem } from '@leagueplatform/genesis-core';
import { DefaultWidget } from 'components/default-widget/default-widget';
import { useGetConfig } from 'hooks/use-get-config.hook';
import { WidgetTypeToSimpleComponentMap } from 'utils/custom-module-utils';
import { ComplexWidgetItemData } from 'types/widget-display-data.types';
import { Orientation } from '@leagueplatform/masonry-api';

export type StackItemsListProps = {
  items: ComplexWidgetItemData[];
  orientation: Orientation;
  onItemClick: (data: ComplexWidgetItemData, index: number) => void;
};

export const StackItemsList = ({
  items,
  orientation,
  onItemClick,
}: StackItemsListProps) => {
  const config = useGetConfig();

  return (
    <>
      {items.map((item, index) => {
        const WidgetComponent = WidgetTypeToSimpleComponentMap[item.type];
        if (!WidgetComponent) return null;

        return (
          <StackItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.type}${index}`}
            css={{ minWidth: COMPLEX_WIDGET_CARDS_WIDTH }}
          >
            <DefaultWidget
              data={item}
              component={WidgetComponent}
              config={config}
              /* isChildItem is used to render small cards, usually with mobile UI.
Here showing small cards only when the cards are placed horizontally. */
              isChildItem={orientation === Orientation.HORIZONTAL}
              onCardClick={() => onItemClick(item, index)}
            />
          </StackItem>
        );
      })}
    </>
  );
};
