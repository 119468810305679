import { FieldType, HealthMetricInput } from '@leagueplatform/dashboard-api';
import { Button, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { IntegerInput } from 'components/inputs/integer-input';
import { RadioInput } from 'components/inputs/radio-input';
import { DateTimeInput } from 'components/inputs/date-time-input';
import { FieldValues, useForm } from 'react-hook-form';
import React from 'react';
import { FormProvider } from '@leagueplatform/web-common';
import { usePostHealthMetrics } from 'hooks/use-post-health-metrics.hook';
import { formatMetricPostData } from 'utils/format-metric-post-data.util';
import { ErrorSaving } from 'components/manage-units-modal/manage-units-modal';
import { parseAbsoluteToLocal } from '@internationalized/date';
import { parseISO } from 'date-fns';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';

interface MetricFormProps {
  onSuccessSubmit?: (date: Date) => void;
  showDateTimePicker?: boolean;
  metricInputs: HealthMetricInput[];
  metricConfigId: string;
}

export function MetricFormPost({
  onSuccessSubmit,
  showDateTimePicker = false,
  metricInputs,
  metricConfigId,
}: MetricFormProps) {
  const { formatMessage } = useIntl();

  const [failedComponentIds, setFailedComponentIds] = React.useState<string[]>(
    [],
  );
  const formMethods = useForm({ mode: 'onChange' });

  const mutation = usePostHealthMetrics();

  const handleSubmit = async (formData: FieldValues) => {
    const metricsToPost = formatMetricPostData(
      formData,
      metricInputs,
      metricConfigId,
    );

    const mutationPromises = metricsToPost
      .filter(
        (m) =>
          failedComponentIds.length === 0 ||
          failedComponentIds.includes(m.metricComponentId),
      )
      .map((metric) => mutation.mutateAsync(metric));

    const result = await Promise.allSettled(mutationPromises);
    const failedComponents: string[] = [];
    let successComponentTimestamp: string | undefined;

    result.forEach((r, index) => {
      if (r.status === 'rejected') {
        failedComponents.push(metricsToPost[index].metricComponentId);
      } else if (
        r.status === 'fulfilled' &&
        successComponentTimestamp === undefined
      ) {
        successComponentTimestamp = r.value.data.timestamp;
      }
    });
    setFailedComponentIds(failedComponents);

    if (onSuccessSubmit && successComponentTimestamp) {
      onSuccessSubmit(new Date(parseISO(successComponentTimestamp)));
    }
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.DASHBOARD,
      screen_name: SCREEN_NAMES.ADD_METRIC_DATA,
      metric_type: metricConfigId,
      detail: 'submit',
    });
  };

  return (
    <FormProvider formMethods={formMethods} onSubmit={handleSubmit}>
      <StackLayout spacing="$one" horizontalAlignment="stretch">
        {showDateTimePicker && (
          <DateTimeInput
            id="effectiveDateTime"
            validationMessage="validationMessage"
            label={formatMessage({ id: 'DATE_AND_TIME' })}
          />
        )}
        {metricInputs.map((healthMetricInputs, index) =>
          healthMetricInputs.valueFields.map((valueField) => {
            if (valueField.options) {
              return (
                <RadioInput
                  id={`${index}-${valueField.fieldKeyName}`}
                  options={valueField.options}
                  validationMessage={formatMessage({
                    id: 'NO_VALUE_SELECTED_ERROR_MESSAGE',
                  })}
                  hint={valueField.hint}
                />
              );
            }

            switch (valueField.fieldType) {
              case FieldType.DATETIME:
                return (
                  <DateTimeInput
                    id={`${index}-${valueField.fieldKeyName}`}
                    label={valueField.fieldLabel}
                    validationMessage={formatMessage({
                      id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD',
                    })}
                    triggerValidation={() => formMethods.trigger()}
                    hint={valueField.hint}
                    validations={{
                      endDateMustBeAfterStart: (
                        fieldValue: string,
                        fieldValues: FieldValues,
                      ) => {
                        const startDate = parseAbsoluteToLocal(
                          fieldValues[`${index}-start`],
                        );
                        const endDate = parseAbsoluteToLocal(
                          fieldValues[`${index}-end`],
                        );

                        if (fieldValue === fieldValues[`${index}-start`]) {
                          return undefined;
                        }

                        const check = startDate.compare(endDate);

                        // 60000 milliseconds = 1 minute, end date must be at least 1 minute after start date
                        if (check >= -60000) {
                          return formatMessage({
                            id: 'START_DATE_MUST_BE_EARLIER_ERROR_MESSAGE',
                          });
                        }
                        return undefined;
                      },
                      notMoreThan24HoursDifference: (
                        fieldValue: string,
                        fieldValues: FieldValues,
                      ) => {
                        const startDate = parseAbsoluteToLocal(
                          fieldValues[`${index}-start`],
                        );
                        const endDate = parseAbsoluteToLocal(
                          fieldValues[`${index}-end`],
                        );
                        const check = startDate
                          .add({ days: 1 })
                          .compare(endDate);
                        if (fieldValue === fieldValues[`${index}-start`]) {
                          return undefined;
                        }
                        if (check < 0) {
                          return formatMessage({
                            id: 'DURATION_EXCEEDS_24_HOURS_ERROR_MESSAGE',
                          });
                        }
                        return undefined;
                      },
                    }}
                  />
                );

              case FieldType.QUANTITY:
                return (
                  <IntegerInput
                    validationMessage={formatMessage({
                      id: 'TOOLBOX_VALIDATION_REQUIRED_FIELD',
                    })}
                    id={`input-${valueField.fieldKeyName}`}
                    unit={valueField.unit}
                    placeholder={valueField.placeholderCopy}
                    minValue={valueField.min}
                    maxValue={valueField.max}
                    name={`${index}-${valueField.fieldKeyName}`}
                    label={valueField.fieldLabel}
                    hint={valueField.hint}
                  />
                );

              default:
                return null;
            }
          }),
        )}
        {failedComponentIds.length > 0 ? <ErrorSaving /> : null}
      </StackLayout>

      <Button
        loading={mutation.isLoading}
        icon="interfaceCheckmark"
        hideLabel
        type="submit"
        css={{
          position: 'absolute',
          bottom: '$minusFive',
          right: '$none',
          '@mobile': {
            bottom: '$two',
            right: '$two',
          },
        }}
      >
        {formatMessage({ id: 'SAVE' })}
      </Button>
    </FormProvider>
  );
}
