import React from 'react';
import { InsightsPage } from 'pages/insights-page';
import { useRouteMatch, Switch, Route } from '@leagueplatform/routing';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { DYNAMIC_DASHBOARD_ENABLED } from 'constants/feature-flags.constants';
import { AllRecordedData } from 'pages/all-recorded-data/all-recorded-data';
import { AllRecordedData as AllRecordedData2 } from 'pages/all-recorded-data2/all-recorded-data';
import { AllRecordedData as AllRecordedData3 } from 'pages/all-recorded-data3/all-recorded-data';
import { DataSources } from 'pages/data-sources/data-sources';
import { DataSourcesV3 } from 'pages/data-sources3/data-sources';
import { Details } from 'pages/details/details';
import { Details as Details2 } from 'pages/details2/details';
import { Details as Details3 } from 'pages/details3/details';
import { useIsSelfReportedDataEnabled } from 'hooks/use-is-self-reported-data-enabled.hook';

export const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  const { data: isDynamicDashboardEnabled } = useFeatureFlagQuery(
    DYNAMIC_DASHBOARD_ENABLED,
  );

  const { isSelfReportedDataEnabled } = useIsSelfReportedDataEnabled();

  const showV1 = !isDynamicDashboardEnabled && !isSelfReportedDataEnabled;
  const showV2 = isDynamicDashboardEnabled && !isSelfReportedDataEnabled;
  const showV3 = isDynamicDashboardEnabled && isSelfReportedDataEnabled;

  return (
    <Switch>
      {showV3 && (
        <Route
          exact
          path={`${path}/:dataType/all-recorded-data`}
          component={AllRecordedData3}
        />
      )}
      {showV2 && (
        <Route
          exact
          path={`${path}/:dataType/all-recorded-data`}
          component={AllRecordedData2}
        />
      )}
      {showV1 && (
        <Route
          exact
          path={`${path}/:dataType/all-recorded-data`}
          component={AllRecordedData}
        />
      )}
      {/* small changes between V1 and V2 for the DataSources page, so both render the same component.  */}
      {(showV1 || showV2) && (
        <Route
          exact
          path={`${path}/:dataType/data-sources`}
          component={DataSources}
        />
      )}
      {showV3 && (
        <Route
          exact
          path={`${path}/:dataType/data-sources`}
          component={DataSourcesV3}
        />
      )}
      {showV3 && (
        <Route exact path={`${path}/:dataType/details`} component={Details3} />
      )}
      {showV2 && (
        <Route exact path={`${path}/:dataType/details`} component={Details2} />
      )}
      {showV1 && (
        <Route exact path={`${path}/:dataType/details`} component={Details} />
      )}
      <Route component={InsightsPage} />
    </Switch>
  );
};
