import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../primitives.view';

const ClickEater = (props) => {
  const { children, ...rest } = props;

  return (
    <Box {...rest} onClick={(evt) => evt.stopPropagation()}>
      {children}
    </Box>
  );
};

ClickEater.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClickEater;
