import React from 'react';
import {
  HeadingText,
  ParagraphText,
  Box,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface CardDetails {
  key?: string;
  value?: string;
}

interface CardDetailsTableProps {
  cardDetails: CardDetails[];
  hasDependents: boolean;
}
export const CardDetailsTable = ({
  cardDetails,
  hasDependents,
}: CardDetailsTableProps) => {
  const { formatMessage, $tifelse } = useIntl();

  if (cardDetails.length === 0) {
    return (
      <Box as="section">
        <ParagraphText>
          {$tifelse(
            hasDependents,
            'POLICY_INFORMATION_UNAVAILABLE_DEPENDENTS',
            'POLICY_INFORMATION_UNAVAILABLE',
          )}
        </ParagraphText>
      </Box>
    );
  }
  const validCardDetails = cardDetails.filter((card) => !!card.value);
  return (
    <StackLayout as="table" spacing="$one" css={{ borderCollapse: 'separate' }}>
      <Box as="caption">
        <HeadingText
          size="xl"
          level="2"
          css={{
            textAlign: 'left',
          }}
        >
          {formatMessage({ id: 'FOR_PROVIDERS_AND_PHARMACISTS' })}
        </HeadingText>
      </Box>
      <Box as="tbody">
        {validCardDetails.map((card) => (
          <Box as="tr" key={card.key}>
            <ParagraphText
              as="th"
              emphasis="subtle"
              css={{
                paddingTop: '$half',
                paddingBottom: '$half',
                textAlign: 'left',
                minWidth: '150px',
                paddingRight: '$half',
              }}
            >
              {card.key}:
            </ParagraphText>
            <ParagraphText
              as="td"
              css={{
                fontWeight: 'bold',
                paddingTop: '$half',
                paddingBottom: '$half',
              }}
            >
              {card.value}
            </ParagraphText>
          </Box>
        ))}
      </Box>
    </StackLayout>
  );
};
