import { ColorProps } from '@leagueplatform/genesis-commons';
import { GDSColor } from '@leagueplatform/genesis-core';

export const getRankColors = (
  rank: number,
): {
  backgroundColor: ColorProps['background'];
  backgroundColorCore: GDSColor;
} => {
  switch (rank) {
    case 1:
      return {
        backgroundColor: 'decorative.brand.secondary.darkest',
        backgroundColorCore: '$decorativeBrandSecondaryDarkest',
      };
    case 2:
      return {
        backgroundColor: 'decorative.brand.primary.default',
        backgroundColorCore: '$decorativeBrandPrimaryDefault',
      };
    case 3:
      return {
        backgroundColor: 'decorative.brand.primary.darkest',
        backgroundColorCore: '$decorativeBrandPrimaryDarkest',
      };
    default:
      return {
        backgroundColor: 'primary.background.default',
        backgroundColorCore: '$primaryBackgroundDefault',
      };
  }
};
