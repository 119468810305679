import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ModalContent } from './modal-content';

// Exporting Radix Popover Primitive for custom Popovers
export const GDSModalPrimitive = Dialog;

export const ModalTrigger: FunctionComponent<
  PropsWithChildren<
    Dialog.DialogTriggerProps & React.RefAttributes<HTMLButtonElement>
  >
> = ({ children }) => <Dialog.Trigger asChild>{children}</Dialog.Trigger>;
export const ModalClose: FunctionComponent<
  PropsWithChildren<
    Dialog.DialogCloseProps & React.RefAttributes<HTMLButtonElement>
  >
> = ({ children }) => <Dialog.Close asChild>{children}</Dialog.Close>;
export const ModalTitle: FunctionComponent<
  PropsWithChildren<
    Dialog.DialogTitleProps & React.RefAttributes<HTMLHeadingElement>
  >
> = ({ children }) => <Dialog.Title asChild>{children}</Dialog.Title>;
export const ModalDescription: FunctionComponent<
  PropsWithChildren<
    Dialog.DialogDescriptionProps & React.RefAttributes<HTMLParagraphElement>
  >
> = ({ children }) => (
  <Dialog.Description asChild>{children}</Dialog.Description>
);

export interface GDSModalRootProps extends Dialog.DialogProps {}

// Composed GDS Modal
export const Modal = {
  Root: Dialog.Root,
  Trigger: ModalTrigger,
  Content: ModalContent,
  Close: ModalClose,
  Title: ModalTitle,
  Description: ModalDescription,
};

Modal.Root.displayName = 'Modal.Root';
Modal.Content.displayName = 'Modal.Content';
Modal.Trigger.displayName = 'Modal.Trigger';
Modal.Close.displayName = 'Modal.Close';
Modal.Title.displayName = 'Modal.Title';
Modal.Description.displayName = 'Modal.Description';
