import * as React from 'react';
import {
  StackWidgetWrapper,
  useGetTraitsForMedia,
  DefaultOrientationMap,
  useOrientationFromMediaQuery,
} from '@leagueplatform/masonry-widgets';
import { WidgetType } from '@leagueplatform/masonry-api';
import type { Traits } from '@leagueplatform/masonry-api';
import { StackItemsList } from './stack-widget-items-list.component';
import type { StackItemsListProps } from './stack-widget-items-list.component';

export interface StackWidgetProps extends StackItemsListProps {
  heading?: string;
  traits?: Traits;
  onItemClick: () => void;
}

export const StackWidget = ({
  heading,
  items,
  traits,
  onItemClick,
}: StackWidgetProps) => {
  const orientation = useOrientationFromMediaQuery({
    traits,
    defaultOrientation: DefaultOrientationMap[WidgetType.STACK],
  });

  const traitItems = useGetTraitsForMedia(traits);

  return (
    <StackWidgetWrapper
      orientation={orientation}
      heading={heading}
      gridColumnCount={traitItems?.gridColumnCount}
    >
      <StackItemsList
        onItemClick={onItemClick}
        items={items}
        orientation={orientation}
      />
    </StackWidgetWrapper>
  );
};
