import React from 'react';
import {
  Card,
  GDSColor,
  HeadingText,
  StackLayout,
} from '@leagueplatform/genesis-core';

export interface ProgramDetailCardProps {
  backgroundColor?: GDSColor;
  heading: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const ProgramDetailCard = ({
  backgroundColor = '$surfaceBackgroundPrimary',
  heading,
  children,
  footer,
}: ProgramDetailCardProps) => (
  <Card.Elevated
    css={{
      backgroundColor,
      width: '100%',
    }}
  >
    <Card.Section>
      <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
        <HeadingText level="2" size="md">
          {heading}
        </HeadingText>
        {children}
      </StackLayout>
    </Card.Section>
    {footer}
  </Card.Elevated>
);
