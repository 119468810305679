import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  CHALLENGE_PRIVACY_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

export const patchChallengesPrivacySetting = async (
  userChallengeId: string,
  showFeedItems: boolean,
  errorContext?: ErrorContext,
) => {
  const response = await leagueFetch(
    `${CHALLENGE_PRIVACY_PATH}/${userChallengeId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          attributes: {
            showFeedItems,
          },
          id: userChallengeId,
          type: CHALLENGE_DATA_TYPES.CHALLENGE_CONFIG,
        },
      }),
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while patching!');
};
