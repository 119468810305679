const genesisColors = {
  'neutral.gray': '#767676',
  'neutral.grayLight': '#D6D6D6',
  'neutral.grayLighter': '#F0F0F0',
  'neutral.grayDark': '#66686b',
  'functional.blueLight': '#E0E7FA',
  'functional.green': '#00A876',
  neutral: {
    gray: '#767676',
    grayLight: '#D6D6D6',
    grayLighter: '#F0F0F0',
    grayDark: '#66686b',
  },
  functional: {
    blueLight: '#EAEEFB',
    green: '#00A876',
  },
};

export const vars = {
  colour: {
    // League Design Language -- Primary
    purpleLightest: '#EDE8FA',
    purpleLighter: '#D7CAFA',
    purpleLight: '#9677E4',
    purple: '#501cd2',
    purpleDark: '#3F16A5',
    purpleDarkest: '#250D53',
    mintLightest: '#E5F8F7',
    mintLighter: '#99E5E1',
    mintLight: '#66D9D1',
    mint: '#00bfb3',
    mintDark: '#05A99F',

    // League Design Language -- Neutrals
    greyLightest: '#F6F7F9',
    greyLighter: '#DDE0E6',
    greyLight: '#98A1B4',
    grey: '#536382',
    greyDark: '#414F68',
    navyLightest: '#EAE9EF',
    navyLighter: '#AEA9BF',
    navyLight: '#857DA0',
    navy: '#43327B',
    navyDark: '#342760',
    creme: '#F9F7F6',
    // League Design Language -- Secondary
    redLightest: '#fbf2f3',
    red: '#D92045',
    blue: '#2E5CDF',
    blueLightest: '#EAEEFB',

    // Non-Standard Colours
    lightenedPurple: 'rgba(237, 232, 250, 0.4)',
    purpleGrey: '#424f6f',
    backdrop: 'hsla(223, 25%, 35%, 0.9)',
    purpleText: '#271A4D',
    sea: '#8cffd7',
    ocean: '#1BB0AC',
    oceanDarkened: '#008c80',
    lightGreen: '#D9FFF2',
    green: '#548203',
    moneyGreen: '#15d90b',
    opal: '#00BFB3',
    darkGreen: '#00856C',
    yellow: '#F7CC0C',
    lightYellow: '#FFFAE6',
    darkYellow: '#FFD51A',
    white: '#FFFFFF',
    offWhite: '#F8F8F8',
    paleGrey: '#f2f2f5',
    lightMediumGrey: '#e2e5e8',
    infoGrey: '#BFC5CE',
    mediumGrey: '#afb5ba',
    darkGrey: '#727272',
    offBlack: '#4A4A4A',
    darkBlack: '#0a0b0c',
    black: '#000000',
    lightRose: '#FBE4E8',
    darkRose: '#F1A1B1',
    lightOrange: '#FFF3E6',
    orange: '#FFB466',
    flushOrange: '#ff8200',
    flushOrangeLight: '#ffedd9',
    orangeRed: '#D94647',
    darkRed: '#C71337',
    feta: '#DEFADC',
    brightGreen: '#15D90B',
    remy: '#FDE5F1',
    brilliantRose: '#F04E98',
    tenne: '#A44701',
    bone: '#E5D4C2',
    ...genesisColors,
  },
  button: {
    borderRadius: '0.3rem',
    height: '4.5rem',
  },
  card: {
    bottomShadow: '0 5px 10px #e2e5e8',
    fullShadow: '0 0 14px 7px rgba(0, 0, 0, 0.05)',
  },
  fixedWidth: 1000,
  smallFixedWidth: 835,
  text: {
    bottomShadow: '1px 1px 2px #000',
    fontFamily: `'Helvetica Neue', Arial, Helvetica, sans-serif`,
  },
  fontWeight: {
    regular: 300,
    medium: 700,
    bold: 800,
  },
};
