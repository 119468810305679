import React from 'react';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  getObservabilityContext,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import { CustomWidget } from 'components/custom-widget/custom-widget.component';
import { WidgetProviderFromWidgetType } from 'components/widget-provider-from-type/widget-provider-from-widget-type.component';
import { logError } from 'utils/log-error';
import { MasonryConfig } from '../../types/config.types';
import { AllWidgetsDisplayData } from '../../types/widget-display-data.types';
import { useGetConfig } from '../../hooks/use-get-config.hook';

type WidgetWrapperProps = {
  widget: ContainerWidgetItem;
};

export const MasonrySupportedWidget = ({ widget }: WidgetWrapperProps) => {
  const { apiVersion } = useGetConfig();

  // Default widget data is fetched from get_widget_data API call
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<AllWidgetsDisplayData>(widget, apiVersion);

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error || !widgetData) {
    return null;
  }

  return <WidgetProviderFromWidgetType data={widgetData} widget={widget} />;
};

export const WidgetWrapper = ({
  widget,
  appId,
}: WidgetWrapperProps & { appId: MasonryConfig['appId'] }) => (
  // Returning null as fallback component because masonry should not display anything if a widget fails to render
  <ObservabilityErrorBoundary
    fallback={() => null}
    errorContext={getObservabilityContext({
      tags: {
        errorName: `Masonry - Error while rendering widget: ${widget.id}`,
        container: appId,
        widgetId: widget.id,
        widgetType: widget.type,
      },
    })}
    onError={(err: Error) => {
      logError(
        `Masonry - Error while rendering widget: ${widget.id} ${err.message}`,
      );
    }}
  >
    {widget.type === 'custom_component' ? (
      <CustomWidget widget={widget} />
    ) : (
      <MasonrySupportedWidget widget={widget} />
    )}
  </ObservabilityErrorBoundary>
);
