import type {
  Auth0Client,
  Auth0ClientOptions,
  LogoutOptions,
  RedirectLoginOptions,
  GetUserOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import { sessionTimeoutActions } from '@leagueplatform/session-timeout';

export interface AuthClientParams {
  clientOptions: Auth0ClientOptions;
  /**
   * How long, in hours, a session can be idle (no backend interaction)
   * before the Authorization Server invalidates the session.
   */
  idleSessionLifetimeHours: number;
  redirectLoginOptions?: RedirectLoginOptions;
}
export class AuthClient {
  #auth0ClientPromise: Promise<Auth0Client>;

  #redirectLoginOptions: RedirectLoginOptions;

  #timeoutInterval: number;

  constructor(params: AuthClientParams) {
    if (!params?.clientOptions) {
      throw new Error('clientOptions cannot be null');
    }
    this.#auth0ClientPromise = createAuth0Client(params.clientOptions);

    this.#redirectLoginOptions = params.redirectLoginOptions || {};

    // Convert idleSessionLifetimeHours into milliseconds when setting #timeoutInterval
    this.#timeoutInterval = params.idleSessionLifetimeHours * 3.6e6;
  }

  private async getAuth0Client() {
    return this.#auth0ClientPromise;
  }

  async isAuthenticated() {
    const client = await this.getAuth0Client();
    return client.isAuthenticated();
  }

  async getTokenSilently(options?: GetTokenSilentlyOptions) {
    const client = await this.getAuth0Client();
    sessionTimeoutActions.start(this.#timeoutInterval);
    return client.getTokenSilently(options);
  }

  async getUser(options?: GetUserOptions) {
    const client = await this.getAuth0Client();
    return client.getUser(options);
  }

  async loginWithRedirect(options: RedirectLoginOptions = {}) {
    const client = await this.getAuth0Client();
    return client.loginWithRedirect({
      ...this.#redirectLoginOptions,
      ...options,
    });
  }

  async logout(logoutOptions?: LogoutOptions) {
    const client = await this.getAuth0Client();
    return client.logout(logoutOptions);
  }

  async handleRedirectCallback() {
    const client = await this.getAuth0Client();
    return client.handleRedirectCallback();
  }

  async getIdTokenClaims() {
    const client = await this.getAuth0Client();
    return client.getIdTokenClaims();
  }
}
