import type { GDSIconProps } from '../icon/icon';
import type { GDSColor } from '../../theme';
import type { GDSStatus } from '../../types';

export const backgroundColorByStatus: Record<GDSStatus, GDSColor> = {
  success: '$successBackgroundSubdued',
  error: '$criticalBackgroundSubdued',
  info: '$highlightBackgroundSubdued',
  warning: '$warningBackgroundSubdued',
  neutral: '$inputBackgroundDisabled',
  brand: '$decorativeBrandPrimaryPastel',
};

export const iconTintByStatus: Record<GDSStatus, GDSIconProps['tint']> = {
  success: '$successIcon',
  error: '$criticalIcon',
  info: '$highlightIcon',
  warning: '$warningIcon',
  neutral: '$onSurfaceIconPrimary',
  brand: '$decorativeBrandPrimaryDefault',
};
