import { QuestionnaireItem, Extension } from 'fhir/r4';
import { ParsedQuestionnaireItem } from 'types/questionnaire.types';
import { regexExtensionHandler } from './item-extension-handlers/fhir-core-extension-handlers.util';
import {
  handleItemTypeBoolean,
  handleAnswerTypeBoolean,
} from './item-type-handlers/boolean-item-type-handler.util';
import {
  handleItemTypeChoice,
  handleAnswerTypeChoice,
} from './item-type-handlers/choice-item-type-handler.util';
import {
  handleItemTypeInteger,
  handleAnswerTypeInteger,
} from './item-type-handlers/integer-item-type-handler.util';
import {
  handleItemTypeUrl,
  handleAnswerTypeUrl,
} from './item-type-handlers/url-item-type-handler.util';
import {
  handleItemTypeString,
  handleAnswerTypeString,
} from './item-type-handlers/string-item-type-handler.util';

export const handleAnswerByType = (answerType: string, value: any) => {
  switch (answerType) {
    case 'boolean':
      return handleAnswerTypeBoolean(value);
    case 'choice':
      return handleAnswerTypeChoice(value);
    case 'integer':
      return handleAnswerTypeInteger(value);
    case 'string':
      return handleAnswerTypeString(value);
    case 'url':
      return handleAnswerTypeUrl(value);
    default:
      return [{ valueString: value }];
  }
};

const handleExtensions = (
  extensions: Extension[],
): { [key: string]: any; url: string }[] =>
  extensions.map((extension: Extension) => {
    if (/StructureDefinition\/regex\/?/gi.test(extension.url)) {
      return regexExtensionHandler(extension);
    }
    return extension;
  });

export const handleQuestionByType = (
  item: QuestionnaireItem,
): ParsedQuestionnaireItem => {
  const parsedItem = item;
  if (parsedItem?.extension) {
    parsedItem.extension = handleExtensions(parsedItem.extension);
  }
  switch (parsedItem.type) {
    case 'boolean':
      return handleItemTypeBoolean(parsedItem);
    case 'choice':
      return handleItemTypeChoice(parsedItem);
    case 'integer':
      return handleItemTypeInteger(parsedItem);
    case 'url':
      return handleItemTypeUrl(parsedItem);
    case 'string':
      return handleItemTypeString(parsedItem);
    default:
      return parsedItem;
  }
};
