import { useMemo } from 'react';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import { getCustomWidgetModule } from 'utils/custom-module-utils';
import { useGetConfig } from './use-get-config.hook';

export const useGetCustomWidgetModuleFromConfig = ({
  moduleKey,
}: {
  moduleKey: ContainerWidgetItem['module'];
}) => {
  const config = useGetConfig();
  const moduleProvider = useMemo(
    () =>
      getCustomWidgetModule(
        moduleKey,
        config?.customWidgetModules,
        config?.appId,
      ),
    [moduleKey, config?.customWidgetModules, config?.appId],
  );
  return moduleProvider;
};
