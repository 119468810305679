import * as React from 'react';
import { bool } from 'prop-types';
import { genesisStyled, QuietButton } from '@leagueplatform/genesis-commons';

const getTextColor = ({ theme }) => theme?.colors?.onSurface?.text?.primary;

const getBackgroundColor = ({ theme, darkenBackground }) =>
  darkenBackground
    ? theme?.colors?.surface?.background?.secondary
    : theme?.colors?.surface?.background?.primary;

const getTextDecoration = ({ hideUnderline }) =>
  hideUnderline ? 'inherit' : 'underline';

const StyledQuietButton = genesisStyled(QuietButton)`
  &:hover, &:focus {
    text-decoration: ${getTextDecoration};
    background-color: ${getBackgroundColor};
    color: ${getTextColor};
  }
`;

export const VeryQuietButton = ({
  darkenBackground = false,
  hideUnderline = false,
  ...props
}) => (
  <StyledQuietButton
    darkenBackground={darkenBackground}
    hideUnderline={hideUnderline}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

VeryQuietButton.propTypes = {
  hideUnderline: bool,
  darkenBackground: bool,
};

VeryQuietButton.defaultProps = {
  hideUnderline: false,
  darkenBackground: false,
};
