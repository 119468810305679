import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import {
  MESSAGING_THREADS_PATH,
  MESSAGING_THREADS_PATH_V2,
} from './constants/messaging-requests.constants';
import {
  ThreadsDeserialisedData,
  ThreadsDeserialisedDataV2,
  ThreadsResponseData,
} from './types/threads.types';

const parseV2toV1 = (
  response: ThreadsDeserialisedDataV2,
): ThreadsDeserialisedData => {
  const threads = response.data.map((thread) => {
    const { lastMessageSentAt, lastMessageText, ...rest } = thread;
    const parsedThread = {
      ...rest,
      type: 'thread',
      latestMessage: {
        data: {
          id: `ID-${lastMessageSentAt}`,
          sentAt: lastMessageSentAt,
          text: lastMessageText,
          type: 'message',
          /*
            Adding a generic object as "sender" to respect TS types, but it won't
            be used in the threads. The attr "sender" is required when the message type
            is "document", which won't be the case here.
          */
          sender: {
            data: {
              id: `SENDER-${lastMessageSentAt}`,
              type: 'participant',
            },
          },
        },
      },
    } as const;

    return parsedThread;
  });

  return {
    ...response,
    data: threads,
  };
};
interface GetThreadsProps {
  numberOfThreads: number;
  pageParam?: string | null;
  enableV2?: boolean;
}

export const getThreads = async ({
  numberOfThreads,
  pageParam,
  enableV2 = false,
}: GetThreadsProps): Promise<ThreadsDeserialisedData> => {
  const requestPath = enableV2
    ? MESSAGING_THREADS_PATH_V2
    : MESSAGING_THREADS_PATH;

  const path = pageParam || `${requestPath}?page[size]=${numberOfThreads}`;
  const response = await leagueFetch(path);
  if (!response.ok) {
    throw new Error(`getThreads error: ${response.status}`);
  }

  const responseJson: ThreadsResponseData = await response.json();

  const deserializedJson = deserialise(responseJson);

  if (enableV2) {
    return parseV2toV1(deserializedJson);
  }

  return deserializedJson;
};
