import React, { FC, HTMLAttributes } from 'react';
import { styled } from '../../../theme';
import type { GDSCustomizableComponent } from '../../../types';

export interface AffixContentProps
  extends GDSCustomizableComponent,
    HTMLAttributes<HTMLDivElement> {
  position: 'leading' | 'trailing';
  children: React.ReactNode;
}

const AffixWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  typography: '$bodyTwo',
  marginBlockEnd: '0',
  color: '$onSurfaceTextPrimary',
  maxHeight: '19px',
});

export const AffixContent: FC<AffixContentProps> = ({
  position,
  children,
  className,
  css,
  ...props
}) => (
  <AffixWrapper
    className={[
      'GDS-affix-wrapper',
      `GDS-affix-${position}-content`,
      className,
    ].join(' ')}
    css={{
      ...(position === 'leading'
        ? { paddingInlineEnd: '$half' }
        : { paddingInlineStart: '$half' }),
      ...css,
    }}
    {...props}
  >
    {typeof children === 'string' ? <span>{children}</span> : children}
  </AffixWrapper>
);
