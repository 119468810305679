import * as React from 'react';
import {
  AxisBottom as StyledAxisBottom,
  AxisBottomProps as StyledAxisBottomProps,
} from '../primitives/AxisBottom';
import { useChart } from '../providers/chart-data-provider';

export type AxisBottomProps = Omit<StyledAxisBottomProps, 'top' | 'scale'>;

export function AxisBottom(props: AxisBottomProps) {
  const { xScale, yMax } = useChart();

  return (
    <StyledAxisBottom
      top={yMax} // Positions the bottom axis at the bottom of the chart.
      scale={xScale}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
