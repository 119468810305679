import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { GET_HEALTH_NEED_MESSAGE } from '../constants/get-health-need.constants';

export const getHealthNeed = (urlSlug: string) =>
  SocketAsFetch.fetch({
    message_type: GET_HEALTH_NEED_MESSAGE,
    info: {
      url_slug: urlSlug,
    },
  });
