import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { keys, pick } from 'lodash';
import { Flex } from '../primitives.view';

export const RadioContainer = styled(Flex)`
  cursor: ${({ disabled }) => (disabled ? `auto` : 'pointer')};

  && {
    label {
      cursor: ${({ disabled }) => (disabled ? `auto` : 'pointer')};
      margin: 0 0 0 1.6rem;
    }
  }
`;

const RADIO_BUTTON = 'radio-button';
const DISABLED = 'disabled';
const FOCUSED = 'focused';
const ERROR = 'error';
const getClassNames = (props) =>
  keys(props)
    .reduce(
      (classNames, key) => {
        switch (key) {
          case ERROR:
          case DISABLED:
            return props[key] === true ? [...classNames, key] : classNames;
          case FOCUSED:
            return props[key] === true
              ? [...classNames, `${RADIO_BUTTON}--${FOCUSED}`]
              : classNames;
          default:
            return classNames;
        }
      },
      [RADIO_BUTTON],
    )
    .join(' ');

export class Radio extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.state = {
      checked: props.checked,
      focused: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      checked: newProps.checked,
    });
  }

  onInputFocus() {
    this.setState({
      focused: true,
    });
  }

  onInputBlur() {
    this.setState({
      focused: false,
    });
  }

  handleClick() {
    this.input.current.click();
  }

  render() {
    const {
      id,
      name,
      onChange,
      children,
      disabled,
      value,
      flexDirection,
      className,
      // below props are destructured to omit passing to `otherProps`
      justify, // eslint-disable-line
      error, // eslint-disable-line
      ...otherProps
    } = this.props;
    const { checked } = this.state;

    return (
      <RadioContainer
        onClick={this.handleClick}
        flexDirection={flexDirection}
        alignItems="center"
        className={`${className} ${getClassNames(
          pick({ ...this.props, ...this.state }, [DISABLED, FOCUSED, ERROR]),
        )}`}
        disabled={disabled}
      >
        <input
          {...otherProps}
          type="radio"
          id={id}
          name={name}
          value={value || name}
          checked={checked}
          onChange={onChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          ref={this.input}
          disabled={disabled}
        />
        {children}
      </RadioContainer>
    );
  }
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  flexDirection: PropTypes.string,
  justify: PropTypes.string,
  className: PropTypes.string,
};

Radio.defaultProps = {
  children: null,
  checked: false,
  disabled: false,
  value: null,
  flexDirection: 'row',
  justify: 'flex-start',
  className: '',
  error: false,
};
