import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { Icon, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  useGetUserProfile,
  UserHealthActivityStatus,
} from '@leagueplatform/health-journey-common';
import { GetActivityGroup } from 'utils/get-activity-group';
import { REWARDS_PROGRAMS_ASSET_KEYS } from '../../asset-map';

interface ActivityDateProps {
  startDate: string;
  endDate: string;
  status?: UserHealthActivityStatus;
  showDate?: boolean;
}

export function ActivityDate({
  startDate,
  endDate,
  status,
  showDate,
}: ActivityDateProps) {
  const { formatDate, formatMessage } = useIntl();

  const { data: userProfileData } = useGetUserProfile();

  const { defaultTimezone } = userProfileData || {};

  const { isCompleted, isActive, isMissed } = GetActivityGroup(status);

  /*

  do not show date when activity is completed
  do not show date if start and end dates are missing


  show date when showDate is true ( activity dates vary from parent campaign dates )
  show date when showDate is false ( activity dates are identical to the parent campaign dates )  AND activity isMissed

  */

  if (isCompleted || (!startDate && !endDate) || (!showDate && !isMissed))
    return null;

  return (
    <StackLayout orientation="horizontal" spacing="$quarter">
      <Icon
        css={{ filter: `saturate(${isMissed ? 0 : 1})` }}
        icon={
          handleStaticAsset(
            REWARDS_PROGRAMS_ASSET_KEYS.REWARDS_PROGRAM_CLOCK,
          ) as string
        }
        size="$one"
      />
      <UtilityText
        css={{
          typography: '$bodyTwo',
          color: isMissed ? '$onSurfaceTextSubdued' : 'inherit',
          lineHeight: 1.2,
        }}
      >
        {isActive &&
          `${formatMessage(
            { id: 'START_DAY_AT_TIME' },
            {
              day: formatDate(startDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: defaultTimezone,
              }),
            },
          )} • ${formatMessage(
            { id: 'END_DAY_AT_TIME' },
            {
              day: formatDate(endDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: defaultTimezone,
              }),
            },
          )}`}
        {isMissed &&
          formatMessage(
            { id: 'ENDED_DAY_AT_TIME' },
            {
              day: formatDate(endDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: defaultTimezone,
              }),
            },
          )}
      </UtilityText>
    </StackLayout>
  );
}
