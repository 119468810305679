import React from 'react';
import {
  Box,
  HeadingText,
  StackLayout,
  UtilityText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { RewardProgramActivityCard } from 'components/reward-program-activity-card/reward-program-activity-card.component';
import {
  HealthActivityDeserializedData,
  NestedCampaign,
} from '@leagueplatform/health-journey-api';
import { shouldDisplayDate } from 'utils/should-display-date.util';

interface ActivityListSectionProps {
  sectionTitle: string;
  compact?: boolean;
  relatedCampaign: NestedCampaign;
  activities: HealthActivityDeserializedData[];
}

export const ActivityListSection = ({
  sectionTitle,
  compact,
  relatedCampaign,
  activities,
}: ActivityListSectionProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('laptop'));

  const { campaign } = relatedCampaign;

  return (
    <StackLayout spacing="$one" css={{ width: '100%' }}>
      {compact ? (
        <Box
          css={{
            padding: '$threeQuarters',
            width: '100%',
            backgroundColor: '$surfaceBackgroundTertiary',
          }}
        >
          <UtilityText css={{ typography: '$overline' }}>
            {sectionTitle}
          </UtilityText>
        </Box>
      ) : (
        <HeadingText level="3" size="lg">
          {sectionTitle}
        </HeadingText>
      )}
      <StackLayout
        divider={
          compact && isMobile ? (
            <Box
              css={{
                width: '100%',
                height: '1px',
                backgroundColor: '$onSurfaceBorderSubdued',
              }}
            />
          ) : undefined
        }
        spacing={compact && isMobile ? 'none' : '$oneAndHalf'}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        css={{
          width: '100%',
          flexWrap: 'wrap',
          paddingLeft: compact && isMobile ? 'none' : '$threeQuarters',
          paddingBottom: compact && isMobile ? 'none' : '$oneAndHalf',
        }}
      >
        {activities.map((activity) => (
          <RewardProgramActivityCard
            key={activity.id}
            relatedCampaign={relatedCampaign}
            activity={activity}
            compact={compact}
            showDate={shouldDisplayDate(
              campaign.data.startDate,
              campaign.data.endDate,
              activity.startDate,
              activity.endDate,
            )}
          />
        ))}
      </StackLayout>
    </StackLayout>
  );
};
