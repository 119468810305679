import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  CONTENTFUL_REDIRECT_URL_HEALTH_JOURNEY,
  CONTENTFUL_REDIRECT_URL_HEALTH_PROFILE,
} from '../constants';

export function mapContentfulURLToModules(contentfulUrl) {
  if (contentfulUrl.includes(CONTENTFUL_REDIRECT_URL_HEALTH_JOURNEY)) {
    return contentfulUrl.replace(
      CONTENTFUL_REDIRECT_URL_HEALTH_JOURNEY,
      getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
    );
  }

  if (contentfulUrl.includes(CONTENTFUL_REDIRECT_URL_HEALTH_PROFILE)) {
    return contentfulUrl.replace(
      CONTENTFUL_REDIRECT_URL_HEALTH_PROFILE,
      getModulePath(LEAGUE_MODULE_NAMES.healthProfile),
    );
  }

  return null;
}
