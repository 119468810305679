import {
  Box,
  HeadingText,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  useForm,
  ReactHookFormProvider as FormProvider,
} from '@leagueplatform/web-common';
import * as React from 'react';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import { EVENT_NAME, PRODUCT_AREA } from '@leagueplatform/analytics';
import {
  BENEFIT_ANALYTICS_DETAIL_NAMES,
  BENEFIT_SCREEN_NAMES,
} from 'pages/wallet-detail/constants/analytics-benefit-property-names';
import { SelectMembersStep } from './components/select-members-step.component';
import { NewAddressStep } from './components/new-address-step.component';
import { OrderNewCardFormValues } from './types/order-new-card-form.types';
import { SelectAddressStep } from './components/select-address-step.component';
import { ConfirmationScreen } from './components/confirmation-screen.component';
import { useSubmitOrderNewCardMutation } from './hooks/queries/use-submit-order-new-card-mutation.hook';
import { OrderNewCardFooter } from './components/multi-step-footer/order-new-card-footer.component';
import { MultiStepLayout } from './components/multi-step-layout.component';
import {
  MultiStepSlide,
  MultiStepSlider,
} from './components/multi-step-slider.component';
import { orderNewCardFormInputNames } from './constants/form-inputs.constants';
import { checkIsDefaultAddress } from './utils/check-is-default-address';
import { EndScreen } from './components/end-screen.component';
import {
  ExitIntentScreen,
  ExitIntentScreenProps,
} from './components/exit-intent-screen.component';
import { useSelectMembersStep } from './hooks/use-select-members-step.hook';

const HEADING_ID = 'order-new-card-form';

const CardContainer = styled(Box, {
  display: 'flex',
  backgroundColor: '$surfaceBackgroundPrimary',
  borderRadius: '$large',
  boxShadow: '$dropdown',
  flexDirection: 'column',
  height: '100%',
  minWidth: '100%',
  position: 'relative',
  width: '100%',
  gap: '$one',
  padding: '$twoAndHalf',
});

export interface OrderNewCardFormProps extends ExitIntentScreenProps {
  isExiting: boolean;
  disableConfirmExitFlag: () => void;
}

export const OrderNewCardForm = ({
  isExiting,
  cancelFunction,
  continueFunction,
  disableConfirmExitFlag,
}: OrderNewCardFormProps) => {
  const { formatMessage } = useIntl();
  const methods = useForm<OrderNewCardFormValues>();
  // Check if members are loading before allowing user to proceed.
  const { isLoading: isMembersLoading } = useSelectMembersStep();
  const {
    mutate: submitIdCard,
    isLoading: isSubmitLoading,
    status,
  } = useSubmitOrderNewCardMutation();
  const newAddressSteps = React.useMemo(
    () => [
      SelectMembersStep,
      SelectAddressStep,
      NewAddressStep,
      ConfirmationScreen,
      EndScreen,
    ],
    [],
  );
  const defaultAddressSteps = React.useMemo(
    () => [SelectMembersStep, SelectAddressStep, ConfirmationScreen, EndScreen],
    [],
  );

  const { isDefault } = orderNewCardFormInputNames;
  const isDefaultAddress = methods.watch(isDefault);

  const [steps, setSteps] = React.useState(defaultAddressSteps);
  const [currentStep, setCurrentStep] = React.useState(0);
  const isFooterActive = currentStep !== steps.length - 1;
  const isConfirmationScreen = currentStep === steps.length - 2;

  const modalRef = React.useRef<HTMLDivElement>(null);

  const handleFocus = () => {
    modalRef.current?.focus();
  };

  const sendBenefitAnalyticsEvent = useWalletDetailAnalytics();

  React.useEffect(() => {
    handleFocus();
  }, [currentStep]);

  // This is checking if a user selects the default address,
  // and skips the new address step if true
  React.useEffect(() => {
    const isDefaultAddressSelected = checkIsDefaultAddress(isDefaultAddress);
    if (isDefaultAddressSelected === false) {
      setSteps(newAddressSteps);
    } else {
      setSteps(defaultAddressSteps);
    }
  }, [currentStep, defaultAddressSteps, isDefaultAddress, newAddressSteps]);

  React.useEffect(() => {
    if (
      (status === 'success' && isConfirmationScreen) ||
      (status === 'error' && isConfirmationScreen)
    ) {
      setCurrentStep(currentStep + 1);
      // When user is on Success/Error we no longer want to show the confirm exit screen
      disableConfirmExitFlag();
    }
  }, [
    currentStep,
    disableConfirmExitFlag,
    isConfirmationScreen,
    status,
    steps.length,
  ]);

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateForm = async (): Promise<boolean> => {
    const {
      setFocus,
      trigger,
      formState: { errors, isValid },
    } = methods;
    await trigger();

    if (isValid) {
      return true;
    }

    const firstInstanceOfError = Object.keys(errors)[0];
    setFocus(firstInstanceOfError);
    return false;
  };

  const sendOrderNewCardAnalyticsEvent = () => {
    const { memberList } = orderNewCardFormInputNames;
    const numberOfSelectedUsers = methods.getValues(memberList).length;

    switch (steps[currentStep].name) {
      case 'SelectMembersStep':
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.WALLET,
          screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SELECT_MEMBER_STEP,
          detail: BENEFIT_ANALYTICS_DETAIL_NAMES.SELECT_USER,
          sub_detail: `${numberOfSelectedUsers}`,
        });
        break;
      case 'SelectAddressStep':
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.WALLET,
          screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SELECT_MEMBER_STEP,
          detail: BENEFIT_ANALYTICS_DETAIL_NAMES.SELECT_DELIVERY_ADDRESS,
          sub_detail: BENEFIT_ANALYTICS_DETAIL_NAMES.CONTINUE,
        });
        break;

      case 'NewAddressStep':
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.WALLET,
          screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_NEW_ADDRESS_STEP,
          detail: BENEFIT_ANALYTICS_DETAIL_NAMES.CONFIRM_DETAILS,
        });
        break;
      case 'ConfirmationScreen':
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.WALLET,
          screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_CONFIRMATION_STEP,
          detail: BENEFIT_ANALYTICS_DETAIL_NAMES.SELECT_ORDER_CARD,
        });
        break;

      default:
        sendBenefitAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          product_area: PRODUCT_AREA.WALLET,
          screen_name: BENEFIT_SCREEN_NAMES.ORDER_NEW_CARD_SELECT_MEMBER_STEP,
        });
        break;
    }
  };

  const onNext = async () => {
    sendOrderNewCardAnalyticsEvent();
    await validateForm();
    // onSubmit will setCurrentStep to last screen
    if (!isConfirmationScreen && methods.formState.isValid) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onSubmit = React.useCallback(
    (data: OrderNewCardFormValues) => {
      const isErrorScreen =
        currentStep === steps.length - 1 && status === 'error';
      if (currentStep === steps.length - 2 || isErrorScreen) {
        submitIdCard(data);
      }
    },
    [currentStep, status, steps.length, submitIdCard],
  );

  const handleRetrySubmit = React.useCallback(() => {
    const formValues = methods.getValues();
    onSubmit(formValues);
  }, [methods, onSubmit]);

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (
      // prevent onNext from firing on the end screen
      e.key === 'Enter' &&
      currentStep !== steps.length - 1
    ) {
      await onNext();
    }
  };

  return (
    <MultiStepLayout>
      {isExiting && (
        <ExitIntentScreen
          cancelFunction={cancelFunction}
          continueFunction={continueFunction}
        />
      )}
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <StackLayout
          tabIndex={-1}
          aria-labelledby={HEADING_ID}
          ref={modalRef}
          aria-live="polite"
          spacing="$oneAndHalf"
          verticalAlignment="center"
          css={{
            width: '100%',
            height: '100%',
            position: 'static',
            display: isExiting ? 'none' : '',
            // override GDS outline in favour of our own on the correct element
            '&:focus, &:focus-visible': {
              outline: 'none !important',
            },
            '&:focus-visible .card-container': {
              outline: '$borderWidths$outerFocus solid $interactiveFocusOuter',
            },
          }}
          as="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={handleKeyPress}
        >
          <CardContainer className="card-container">
            <HeadingText
              id={HEADING_ID}
              size="xl"
              level="2"
              css={{ textAlign: 'center', width: '100%' }}
            >
              {formatMessage({ id: 'ORDER_NEW_CARD_HEADING' })}
            </HeadingText>

            <MultiStepSlider>
              {/* Step Slider */}
              {steps.map((ComponentStep, i) => (
                <MultiStepSlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={`step-${i}`}
                  activeIndex={currentStep}
                  slideIndex={i}
                >
                  <ComponentStep
                    status={status}
                    onClick={
                      status === 'success' ? cancelFunction : handleRetrySubmit
                    }
                  />
                </MultiStepSlide>
              ))}
            </MultiStepSlider>
          </CardContainer>

          {/* Footer */}
          {isFooterActive && (
            <OrderNewCardFooter
              totalPages={steps.length - 1}
              currentPage={currentStep}
              onNext={onNext}
              onPrev={goBack}
              onSubmit={onSubmit}
              isLoading={isSubmitLoading}
              disabled={isMembersLoading || isSubmitLoading}
            />
          )}
        </StackLayout>
      </FormProvider>
    </MultiStepLayout>
  );
};
