import { useQuery } from 'react-query';
import { ENABLE_CHALLENGES } from '@leagueplatform/health-journey-common';
import {
  CHALLENGES_PATH,
  ChallengeTypes,
  getAllChallenges,
  MultipleChallengesDeserialisedData,
} from '@leagueplatform/health-journey-api';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

export const useGetAllChallenges = (challengeType?: ChallengeTypes) => {
  const { data: isChallengesEnabled } = useFeatureFlagQuery(ENABLE_CHALLENGES);
  return useQuery<MultipleChallengesDeserialisedData, Error>(
    [CHALLENGES_PATH, challengeType],
    () => getAllChallenges(challengeType),
    {
      enabled: !!isChallengesEnabled,
    },
  );
};
