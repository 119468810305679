import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon, ICONS } from '@leagueplatform/ui-kit';
import {
  BodyOne,
  Flex,
  HeadingOne,
  HeadingTwo,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics.constants';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { ModuleWelcomeTopic } from './module-welcome-topic.view';
import { HEALTH_ASSESSMENT_TYPE } from '../../constants';

export const ModuleWelcome = ({
  title,
  description,
  topics,
  setShowBackArrow,
  timeToCompleteText,
  moduleId,
}) => {
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  useEffect(() => {
    setShowBackArrow(false);
  }, [setShowBackArrow]);

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
    screen_name: SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_INTRO,
    assessment_id: moduleId,
    assessment_type: HEALTH_ASSESSMENT_TYPE,
  });
  return (
    <Flex flexDirection="column" width="100%" marginY="four" maxWidth="790">
      {isMobile ? (
        <HeadingTwo>{title}</HeadingTwo>
      ) : (
        <HeadingOne>{title}</HeadingOne>
      )}
      <BodyOne marginY="one">{description}</BodyOne>
      {timeToCompleteText && (
        <Flex alignItems="center">
          <Icon size={16} icon={ICONS.CLOCK} stroke="black" />
          <SubtitleOne as="p" marginLeft="quarter">
            {timeToCompleteText}
          </SubtitleOne>
        </Flex>
      )}

      <Flex
        as="ul"
        flexWrap={isMobile ? 'nowrap' : 'wrap'}
        justifyContent="space-evenly"
        marginTop="two"
      >
        {topics.map(({ color, name, imageId }) => (
          <ModuleWelcomeTopic
            key={imageId}
            color={color}
            imageUrl={getContentUrl(imageId)}
            name={name}
          />
        ))}
      </Flex>
    </Flex>
  );
};

ModuleWelcome.propTypes = {
  setShowBackArrow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      imageId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  timeToCompleteText: PropTypes.string,
  moduleId: PropTypes.string.isRequired,
};

ModuleWelcome.defaultProps = {
  topics: [],
  timeToCompleteText: null,
};
