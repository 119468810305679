import { useParams } from '@leagueplatform/routing';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  EVENT_NAME,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import {
  CHALLENGE_CAMPAIGN_TYPE,
  TAB_NAME,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
  CLP_SCREEN_NAMES,
  TLP_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';

interface TrackChallengesAnalyticsProps {
  screen_name?:
    | SCREEN_NAMES
    | SIGN_UP_ANALYTICS_SCREEN_NAMES
    | CLP_SCREEN_NAMES
    | TLP_SCREEN_NAMES;
  tab_name?: TAB_NAME;
  is_validation_error?: boolean;
  detail?: string;
  is_data_available?: boolean;
  is_critical_event?: boolean;
  product_area?: PRODUCT_AREA;
  highlight_type?: string;
  highlight_name?: string;
  team_name?: string;
  team_id?: string;
  challenge_leaderboard_type?: string;
  sub_detail?: string;
}

export const useChallengesAnalytics = () => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const { data: challengeData } = useGetChallengeByIdData();

  const baseAnalyticsObj = {
    product_area: PRODUCT_AREA.JOURNEY,
    sub_product_area: SUB_PRODUCT_AREA.CHALLENGES,
    health_campaign_name: challengeData?.data.title,
    health_campaign_id: challengeId,
    challenge_status: challengeData?.data.status,
    health_campaign_type: CHALLENGE_CAMPAIGN_TYPE,
  };

  const trackChallengesAnalyticsEvent = (
    eventType: EVENT_NAME,
    analyticsProps: TrackChallengesAnalyticsProps,
  ) => {
    if (challengeData?.data) {
      trackAnalyticsEvent(eventType, {
        ...baseAnalyticsObj,
        ...analyticsProps,
      });
    }
  };

  return {
    trackChallengesAnalyticsEvent,
  };
};
