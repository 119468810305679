import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Button,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import { CONFIRMATION_MODAL_MIN_HEIGHT } from 'pages/challenge-landing-page/constants/challenge-landing-page.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { MODAL_CTA_MAX_WIDTH } from 'constants/challenges.constants';

const MANUAL_TRACKING_CONFIRMATION_MODAL_LABEL_ID =
  'manualTrackingConfirmationModalLabel';

interface ManualTrackingConfirmationModalProps {
  isLoading: boolean;
  onConfirm: () => void;
}

export const ManualTrackingConfirmationModal = ({
  isLoading: signUpForChallengeMutationisLoading,
  onConfirm,
}: ManualTrackingConfirmationModalProps) => {
  const { formatMessage } = useIntl();
  const trackHealthData = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_TRACK_HEALTH_DATA,
  ) as string;

  return (
    <Box
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: CONFIRMATION_MODAL_MIN_HEIGHT,
      }}
    >
      <ModalContentWithImage
        image={{
          src: trackHealthData,
          alt: formatMessage({ id: 'TRACK_HEALTH_DATA_ALT' }),
        }}
      >
        <HeadingText
          id={MANUAL_TRACKING_CONFIRMATION_MODAL_LABEL_ID}
          level="3"
          size="lg"
          css={{ marginBottom: '$threeQuarters' }}
        >
          {formatMessage({ id: 'TRACK_MANUALLY_CONFIRM_TITLE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({
            id: 'TRACK_MANUALLY_CONFIRM_CONNECT_LATER_DESCRIPTION',
          })}
        </ParagraphText>
        <Box css={{ marginTop: '$four', flexWrap: 'wrap' }}>
          <Button
            onClick={() => {
              onConfirm();
            }}
            loading={signUpForChallengeMutationisLoading}
            width="fillContainer"
            css={{
              '@laptop': { maxWidth: MODAL_CTA_MAX_WIDTH },
              '@mobile': { maxWidth: 'none' },
            }}
          >
            {formatMessage({ id: 'CONFIRM' })}
          </Button>
        </Box>
      </ModalContentWithImage>
    </Box>
  );
};
