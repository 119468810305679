import { Select, FormField } from '@leagueplatform/genesis-core';
import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useSelectedMember } from 'components/member-selector-context/member-selector-context';
import { useMemberDropdown } from 'pages/wallet-detail/components/plan-progress/hooks/use-member-dropdown.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';

const MEMBER_SELECT_ID = 'member-select';

export const MemberDropDown = () => {
  const { userId } = useWalletParams();
  const { formatMessage } = useIntl();
  const { memberOptions: options, onMemberChange } = useMemberDropdown();

  const [member, setMember] = useSelectedMember();

  const primaryUserId = options[0].value === 'primary' ? userId : '';

  React.useEffect(() => {
    setMember({ id: primaryUserId, name: options[0].label });
  }, [options, primaryUserId, setMember]);

  const handleChange = ({ value, label }: { value: string; label: string }) => {
    if (value === 'primary') {
      setMember({ id: primaryUserId, name: label });
      return;
    }
    setMember({ id: value, name: label });
  };

  return (
    <FormField
      id="member-select"
      label={formatMessage({ id: 'MEMBER_LABEL' })}
      name={MEMBER_SELECT_ID}
    >
      <Select
        id={MEMBER_SELECT_ID}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
          evt.preventDefault();
          const { currentTarget } = evt;
          const { value } = currentTarget;
          const label = options.find((option) => option.value === value)?.label;
          handleChange({ value, label: label ?? '' });
          onMemberChange(value);
        }}
        value={member?.id ?? options[0].value}
        options={options!}
      />
    </FormField>
  );
};
