import React, { forwardRef } from 'react';
import { Text, TextProps } from '../Text';

/**
 * @deprecated use `UtilityText` from `genesis-core` instead
 */

export const Overline: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="h6"
      color="onSurface.text.primary"
      typography="overline"
      ref={ref}
      {...props}
    />
  )
);

Overline.displayName = 'Overline';
