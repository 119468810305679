import * as React from 'react';
import {
  GDSHeadingLevel,
  GDSSpace,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { Traits, WidgetType } from '@leagueplatform/masonry-api';
import { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { mapHeadingSizeToGenesisTokens } from 'utils/map-heading-size-to-genesis-tokens.util';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface SectionHeaderProps {
  heading: string;
  headingLevel?: GDSHeadingLevel;
  traits?: Traits;
  paddingTop?: GDSSpace;
}

export type SectionHeaderWSNode = MasonryEngineNode<
  WidgetType.SECTION_HEADER,
  SectionHeaderProps
>;

export const SectionHeader = ({
  heading,
  headingLevel = '2',
  paddingTop = '$one',
  traits,
}: SectionHeaderProps) => {
  const traitItems = useGetTraitsForMedia(traits);
  const headingSize =
    mapHeadingSizeToGenesisTokens(traitItems?.headingSize) ?? 'xl';

  return (
    <HeadingText
      size={headingSize}
      level={headingLevel}
      css={{
        paddingTop,
      }}
    >
      {heading}
    </HeadingText>
  );
};
