import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, HeadingThree } from '@leagueplatform/genesis-commons';
import { HighlightFeed } from 'components/highlight-feed/highlight-feed.component';
import { CHALLENGE_HIGHLIGHT_FEED_TYPES } from '@leagueplatform/health-journey-api';
import { TLPCard } from '../tlp-card/tlp-card.component';

export interface TLPHighlightsProps {
  challengeTitle: string;
  teamId: string;
  userChallengeId?: string;
}

export const TLPHighlights = ({
  challengeTitle,
  teamId,
  userChallengeId,
}: TLPHighlightsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      width={{ _: '100%', tablet: '66%' }}
      marginTop={{ _: 'one', tablet: 'none' }}
      marginRight="oneAndHalf"
    >
      <TLPCard>
        <HeadingThree as="h2" marginBottom="oneAndHalf">
          {formatMessage({ id: 'HIGHLIGHTS' })}
        </HeadingThree>
        <HighlightFeed
          feedType={CHALLENGE_HIGHLIGHT_FEED_TYPES.TEAM}
          challengeOrTeamId={teamId}
          challengeTitle={challengeTitle}
          userChallengeId={userChallengeId}
        />
      </TLPCard>
    </Box>
  );
};
