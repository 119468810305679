import React from 'react';
import { Link } from '@leagueplatform/routing';
import {
  HeadingFour,
  Overline,
  Flex,
  Box,
} from '@leagueplatform/genesis-commons';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';

export interface ChallengeLinkProps {
  to: string;
  title: string;
  description: string;
}

export const ChallengeLink = ({
  to,
  title,
  description,
}: ChallengeLinkProps) => (
  <Flex
    as={Link}
    alignItems="center"
    backgroundColor="surface.background.primary"
    borderColor="onSurface.border.subdued"
    borderRadius="medium"
    borderWidth="thin"
    justifyContent="space-between"
    marginBottom="one"
    padding="oneAndHalf"
    textDecoration="none"
    to={to}
  >
    <Box>
      <Overline as="p">{title}</Overline>
      <HeadingFour as="p">{description}</HeadingFour>
    </Box>
    <Flex
      as="span"
      alignItems="center"
      color="onSurface.text.subdued"
      transform="rotate(180deg)"
    >
      <ChallengeIcon iconType={ICON_TYPES.CHEVRON} />
    </Flex>
  </Flex>
);
