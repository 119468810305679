import { ErrorContext } from '@leagueplatform/observability';

export const getMasonryEngineObservabilityContext = (
  context: ErrorContext,
): ErrorContext => ({
  ...context,
  tags: {
    dependentModule: 'masonry-engine',
    supportTeam: 'masonry-web',
    ...context?.tags,
  },
});
