import { useQuery } from 'react-query';
import { captureError } from '@leagueplatform/observability';
import {
  getDashboardConfig,
  DASHBOARD_CONFIG_PATH,
} from '@leagueplatform/dashboard-api';

export function useGetDashboardConfig() {
  return useQuery([DASHBOARD_CONFIG_PATH], () => getDashboardConfig(), {
    onError(error) {
      captureError(
        new Error(`Unable to fetch the dashboard user preferences: ${error}`),
      );
    },
  });
}
