type InputValue = {
  [key: string]: number;
};

export function ParseValueForInput(value: number | string, label?: string) {
  let valueOrLabel = value;

  if (label?.includes(';')) {
    valueOrLabel = label;
  }

  const valueObject: InputValue = {};

  if (typeof valueOrLabel === 'string') {
    valueOrLabel.split(';').forEach((unitValuePair) => {
      const unitValuePairArray = unitValuePair.split('|');

      const [formattedValue, formattedUnit] = unitValuePairArray;

      if (formattedValue !== undefined && formattedUnit !== undefined) {
        valueObject[formattedUnit as keyof InputValue] = parseInt(
          formattedValue,
          10,
        );
      }
    });
  } else {
    valueObject.value = valueOrLabel;
  }

  return valueObject;
}
