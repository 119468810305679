import { Root } from './accordion-root.component';
import { Item } from './accordion-item.component';
import { Header } from './accordion-header.component';
import { Trigger } from './accordion-trigger.component';
import { Content } from './accordion-content.component';

export const Accordion = {
  Root,
  Item,
  Header,
  Trigger,
  Content,
};
