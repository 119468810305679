import * as React from 'react';
import { GDSHeadingLevel, GDSSpace } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { SectionHeader } from './section-header.component';

enum HeadingSize {
  'xx-large' = 'xxl',
  'x-large' = 'xl',
  'large' = 'lg',
  'medium' = ' md',
  'small' = 'sm',
  'x-small' = 'xs',
  'eyebrow' = 'sm',
}

export interface MasonrySectionHeaderNodeProperties {
  heading: string;
  headingLevel?: GDSHeadingLevel;
  headingSize?: keyof typeof HeadingSize;
  paddingTop?: GDSSpace;
}

export type MasonrySectionHeaderNode = MasonryEngineNode<
  'sectionHeader',
  MasonrySectionHeaderNodeProperties
>;

export type MasonrySectionHeaderRendererProps =
  MasonryEngineNodeRendererProps<MasonrySectionHeaderNode>;

export const MasonrySectionHeaderRenderer = (
  props: MasonrySectionHeaderRendererProps,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <SectionHeader {...props} />;
