import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { intersection } from 'lodash';

import { SingleProgressBar } from '@leagueplatform/ui-kit';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { hasAnswers } from '../utils';

export const ProgressBar = ({
  topics,
  moduleNavigation,
  currentIndex,
  isResuming,
  completionPoints,
}) => {
  const { formatMessage } = useIntl();
  const navigationQuestions = moduleNavigation.filter(
    ({ type }) => type === 'question',
  );
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const submitted = navigationQuestions
    .filter((question) => hasAnswers(question.userAnswers))
    .map((question) => question.id);

  const { topicId: currentTopicId, type } = moduleNavigation[currentIndex];

  if (type === 'topic' || type === 'welcome') {
    return null;
  }

  return (
    <Flex
      width="100%"
      maxWidth="586px"
      paddingTop={isMobile ? 'none' : 'two'}
      paddingBottom={isMobile ? 'none' : 'one'}
      marginX={{ _: 'one', tablet: 'none' }}
    >
      {topics.map(
        ({ id, name, description, numOfQuestions, questionIds }, index) => {
          // change tooltip position to the right for later topics
          const toolTipRight = index > topics.length / 2 - 1;

          // get number of questions answered in this topic
          const numCompleted = intersection(submitted, questionIds).length;

          return (
            <SingleProgressBar
              key={id}
              name={`${name} - ${formatMessage(
                { id: 'X_OF_Y' },
                { current: numCompleted, total: numOfQuestions },
              )}`}
              color="interactive.action.primary"
              active={id === currentTopicId}
              isResuming={isResuming}
              totalSteps={numOfQuestions}
              completedSteps={numCompleted}
              description={description}
              toolTipRight={toolTipRight}
              completionPoints={completionPoints}
              isMobile={isMobile}
            />
          );
        },
      )}
    </Flex>
  );
};

ProgressBar.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  moduleNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      topicId: PropTypes.string,
    }),
  ).isRequired,
  currentIndex: PropTypes.number.isRequired,
  isResuming: PropTypes.bool,
  completionPoints: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  isResuming: false,
};
