import React from 'react';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

export const OpensInANewTabMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <VisuallyHidden>
      {formatMessage({ id: 'OPENS_IN_A_NEW_TAB' })}
    </VisuallyHidden>
  );
};
