import React, { forwardRef } from 'react';
import { Button } from 'components/button/button';
import { styled } from '../../theme';
import { useThemeStrings } from '../../hooks/use-theme';

export const ScrollableWrapper = styled('div', {
  position: 'relative',
  maxWidth: '100%',
});

export const ScrollableItemsWrapper = styled('div');

export const ButtonWrapper = styled('div', {
  backgroundColor: '$surfaceBackgroundPrimary',
  position: 'absolute',
  top: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export interface GDSScrollButtonProps {
  onClick?: (event: React.SyntheticEvent) => void;
}

export const ScrollLeft = forwardRef<HTMLButtonElement, GDSScrollButtonProps>(
  ({ onClick }, ref) => {
    const strings = useThemeStrings();
    return (
      <ButtonWrapper css={{ left: 0 }}>
        <Button
          className="GDS-scroll-button GDS-scroll-left-button"
          size="small"
          icon="interfaceChevronLeft"
          priority="tertiary"
          hideLabel
          onClick={onClick}
          ref={ref}
        >
          {strings.previous}
        </Button>
      </ButtonWrapper>
    );
  },
);

export const ScrollRight = forwardRef<HTMLButtonElement, GDSScrollButtonProps>(
  ({ onClick }, ref) => {
    const strings = useThemeStrings();
    return (
      <ButtonWrapper css={{ right: 0 }}>
        <Button
          className="GDS-scroll-button GDS-scroll-right-button"
          size="small"
          icon="interfaceChevronRight"
          priority="tertiary"
          hideLabel
          onClick={onClick}
          ref={ref}
        >
          {strings.next}
        </Button>
      </ButtonWrapper>
    );
  },
);
