import {
  Icon,
  StackLayout,
  queryHelpers,
  useMediaQuery,
  Card,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import {
  HealthActivityDeserializedData,
  NestedCampaign,
} from '@leagueplatform/health-journey-api';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { ActivityCardContent } from 'components/activity-card-content/activity-card-content.component';
import React from 'react';
import { useHistory, useParams } from '@leagueplatform/routing';
import { captureError } from '@leagueplatform/observability';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  ANALYTICS_FIELDS,
  ANALYTICS_VALUES,
} from 'constants/analytics.constants';

interface RewardProgramActivityCardProps {
  activity: HealthActivityDeserializedData;
  compact?: boolean;
  showDate: boolean;
  relatedCampaign: NestedCampaign;
}

export function RewardProgramActivityCard({
  activity,
  compact,
  showDate,
  relatedCampaign,
}: RewardProgramActivityCardProps) {
  const isMobile = useMediaQuery(queryHelpers.down('laptop'));
  const history = useHistory();

  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const { userHealthCampaignId } = useParams<{
    userHealthCampaignId: string;
  }>();

  const openActivityDetails = () => {
    if (!activity.id) {
      captureError(
        new Error(
          `Reward program(campaign id: ${userHealthCampaignId}) activity id not provided`,
        ),
      );
    }

    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      [ANALYTICS_FIELDS.PRODUCT_AREA]:
        ANALYTICS_VALUES.PRODUCT_AREA_HEALTH_JOURNEY,
      [ANALYTICS_FIELDS.SUB_PRODUCT_AREA]:
        ANALYTICS_VALUES.SUB_PRODUCT_AREA_REWARD_PROGRAMS,
      [ANALYTICS_FIELDS.SCREEN_NAME]:
        ANALYTICS_VALUES.SCREEN_NAME_HEALTH_CAMPAIGN_DETAIL_PROGRESS,
      [ANALYTICS_FIELDS.DETAIL]:
        ANALYTICS_VALUES.DETAIL_VIEW_HEALTH_CAMPAIGN_ACTIVITY,
      [ANALYTICS_FIELDS.ACTIVITY_ID]: activity.activityId,
      [ANALYTICS_FIELDS.ACTIVITY_NAME]: activity.name,
      [ANALYTICS_FIELDS.ACTIVITY_TYPE]: activity.type,
      [ANALYTICS_FIELDS.ACTIVITY_STATUS]: activity.status,
      [ANALYTICS_FIELDS.ACTIVITY_REQUIRED]: activity.required,
    });
    history.push(JOURNEY_ROUTES.getToolboxActivityDetails(activity.id));
  };

  if (compact && isMobile) {
    return (
      <Card.Flat
        css={{ width: '100%', paddingTop: '$half', paddingBottom: '$half' }}
      >
        <Card.ClickArea
          ref={clickAreaRef}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
        >
          <StackLayout orientation="horizontal" verticalAlignment="center">
            <ActivityCardContent
              activity={activity}
              showDate={showDate}
              relatedCampaign={relatedCampaign}
              ref={primaryActionRef}
              clickAction={openActivityDetails}
            />
            <Icon icon="interfaceChevronRight" />
          </StackLayout>
        </Card.ClickArea>
      </Card.Flat>
    );
  }

  return (
    <Card.Elevated
      css={{
        width: '100%',
        maxWidth: isMobile ? '100%' : 'calc(50% - $oneAndHalf)',
      }}
    >
      <Card.ClickArea
        css={{ padding: '$oneAndHalf' }}
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        <ActivityCardContent
          activity={activity}
          showDate={showDate}
          relatedCampaign={relatedCampaign}
          ref={primaryActionRef}
          clickAction={openActivityDetails}
        />
      </Card.ClickArea>
    </Card.Elevated>
  );
}
