import React, { forwardRef, ElementType } from 'react';
import { TypographyBase } from 'components/typography/typography-base/typography-base';
import type {
  GDSTypographyResponsiveEmphasis,
  GDSTypography,
} from '../typography';
import type {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  GDSResponsiveProp,
} from '../../../types';
import { styled } from '../../../theme';
import {
  mapResponsiveProp,
  useResponsiveProp,
} from '../../../hooks/use-responsive-prop';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

type GDSUtilityTextEmphasis = GDSTypographyResponsiveEmphasis | 'emphasized';

export type GDSUtilityTextSize = 'base' | 'sm' | 'xs' | 'eyebrow';

type GDSUtilityTextResponsiveSize = GDSResponsiveProp<GDSUtilityTextSize>;

export type GDSUtilityTextProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      as?: ElementType;
      /**
       * Specify size for the utility text.
       */
      size?: GDSUtilityTextResponsiveSize;
      emphasis?: GDSUtilityTextEmphasis;
    }
  > &
    Omit<GDSTypography, 'emphasis'>;

export type GDSUtilityTextComponent = <C extends React.ElementType = 'span'>(
  props: GDSUtilityTextProps<C>,
) => React.ReactElement | null;

const StyledUtilityText = styled(TypographyBase, {
  color: '$onSurfaceTextPrimary',
  variants: {
    size: {
      base: {
        typography: '$bodyOne',
      },
      sm: {
        typography: '$bodyTwo',
      },
      xs: {
        typography: '$caption',
      },
      eyebrow: {
        typography: '$overline',
      },
    },
    // Even though the emphasis variant exists on TypographyBase, TS fails without repeating it here
    emphasis: {
      base: {
        color: '$onSurfaceTextPrimary',
      },
      subtle: {
        color: '$onSurfaceTextSubdued',
      },
      emphasized: {
        color: '$onSurfaceTextPrimary',
      },
    },
  },

  compoundVariants: [
    {
      size: 'base',
      emphasis: 'emphasized',
      css: { typography: '$subtitleOne' },
    },
    {
      size: 'base',
      emphasis: 'subtle',
      css: { typography: '$bodyOneSecondary' },
    },
    {
      size: 'sm',
      emphasis: 'emphasized',
      css: { typography: '$subtitleTwo' },
    },
    {
      size: 'sm',
      emphasis: 'subtle',
      css: { typography: '$bodyTwoSecondary' },
    },
    {
      size: 'xs',
      emphasis: 'base',
      css: { color: '$onSurfaceTextPrimary' },
    },
    {
      size: 'eyebrow',
      emphasis: 'base',
      css: { color: '$onSurfaceTextSubdued' },
    },
  ],
});

const defaultEmphasisBySize: GDSUtilityTextEmphasis = {
  base: 'base',
  sm: 'base',
  xs: 'subtle',
  eyebrow: 'base',
};

export const UtilityText: GDSUtilityTextComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    {
      as,
      children,
      className,
      css,
      emphasis,
      id,
      size = 'base',
      ...props
    }: GDSUtilityTextProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const emphasisBySize = mapResponsiveProp<GDSUtilityTextResponsiveSize>(
      size,
      defaultEmphasisBySize,
    );

    const responsiveSize = useResponsiveProp(size);

    if (size === 'xs' && emphasis === 'emphasized')
      console.error(
        `Size is ${size} and emphasis is ${emphasis}. An extra small emphasized variant does not exist.`,
      );

    if (size === 'eyebrow' && emphasis === 'subtle')
      console.error(
        `Size is ${size} and emphasis is ${emphasis}. An eyebrow subtle variant does not exist.`,
      );

    return (
      <GenesisCoreInspector displayName="UtilityText">
        <StyledUtilityText
          as={as}
          className={['GDS-utility-text', className].join(' ')}
          css={css}
          emphasis={emphasis ?? emphasisBySize}
          id={id}
          ref={ref}
          size={responsiveSize}
          {...props}
        >
          {children}
        </StyledUtilityText>
      </GenesisCoreInspector>
    );
  },
);

// @ts-ignore
UtilityText.displayName = 'UtilityText';
