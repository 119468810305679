import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useMutation, useQueryClient } from 'react-query';
import { uploadFile } from '@leagueplatform/league-content-api';
import {
  setUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import type { UserProfile } from '@leagueplatform/user-profile-api';

/**
 *
 * @name useUploadUserProfilePhoto
 * @desc React hook to upload a user profile photo.
 * @returns Function that is used to upload a file, and returns user profile photo reference ID
 */
export const useUploadUserProfilePhoto = () => {
  const uploadProfilePhoto = useMutation((file: File) => uploadFile(file));

  // eslint-disable-next-line consistent-return
  return async (file: File) => {
    try {
      const response = await uploadProfilePhoto.mutateAsync(file);
      const referenceId = response?.reference_id;
      if (!referenceId) throw new Error();
      return referenceId;
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROFILE_UPDATE_FAILED',
      });
    }
  };
};

/**
 * @name useUpdateUserProfilePhoto
 * @desc React hook to update the user profile with a new avatar image id
 * @returns Function that updates user profile avatar image id
 */
export const useUpdateUserProfilePhoto = () => {
  const queryClient = useQueryClient();
  const updateUserProfileMutation = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]),
    },
  );

  return async (imageId: string) => {
    if (!imageId)
      throw new Error('No imageId was provided for user profile update.');
    const userProfile = {
      avatar: { image_id: imageId },
    };

    return updateUserProfileMutation.mutateAsync(userProfile);
  };
};
