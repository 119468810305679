import React, { forwardRef } from 'react';
import { Text, TextProps } from '../Text';

/**
 * @deprecated use `ParagraphText` or `UtilityText` from `genesis-core` instead
 */

export const BodyTwo: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="p"
      color="onSurface.text.primary"
      typography="bodyTwo"
      ref={ref}
      {...props}
    />
  )
);

BodyTwo.displayName = 'BodyTwo';
