import { useEffect } from 'react';
import { useDebounce } from '@leagueplatform/web-common';
import { DEBOUNCE_DELAY_INTERVAL } from 'constants/challenges.constants';
import { usePatchPrivacyConfig } from './use-patch-privacy-config.hook';

interface UseDebouncedPatchPrivacyConfigMutationProps {
  showFeedItems: boolean;
  setShowFeedItems: React.Dispatch<React.SetStateAction<boolean>>;
  originalShowFeedItems: boolean;
}

/*
  Rather than calling the patch privacy config mutation immediately when a user enables/disables privacy setting, we want to implement debouncing behaviour so that the API is called after a delay of 400ms based on the user's last action (debouncedShowFeedItems).
  However, we still want to immediately update the UI to reflect the user's action(s), rather than waiting to update the UI when we receive a response from the API. This will be done directly from the toggle button, calling setShowFeedItems on click of the toggle.
  useEffect will call the patch privacy config mutation when debouncedShowActivity is updated, but only when it is different from the originalShowFeedItems state. This will prevent us from making an unnecessary call when there is no change to the final showFeedItems state (e.g., if a user enables and then disables privacy setting within the debounce interval). It also prevents the mutation from being called on mount of the component when debouncedShowFeedItems is first being set.
*/
export const useDebouncedPatchPrivacyConfigMutation = ({
  showFeedItems,
  setShowFeedItems,
  originalShowFeedItems,
}: UseDebouncedPatchPrivacyConfigMutationProps) => {
  const debouncedShowFeedItems = useDebounce(
    showFeedItems,
    DEBOUNCE_DELAY_INTERVAL,
  );

  const patchPrivacyConfigMutation = usePatchPrivacyConfig(
    showFeedItems,
    originalShowFeedItems,
    setShowFeedItems,
  );

  useEffect(() => {
    if (debouncedShowFeedItems !== originalShowFeedItems) {
      patchPrivacyConfigMutation();
    }
  }, [
    debouncedShowFeedItems,
    patchPrivacyConfigMutation,
    originalShowFeedItems,
  ]);
};
