import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from '@leagueplatform/routing';
import { Transition } from 'react-transition-group';
import { Flex, genesisStyled, Overline } from '@leagueplatform/genesis-commons';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import {
  FullPageError,
  CTAMediaObject,
} from '@leagueplatform/web-common-components';
import {
  sendAnalyticsPageView,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { useConfigProperty } from '@leagueplatform/core';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { getSanitizedURL } from 'utils/get-sanitized-redirect-url.utils';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics.constants';
import { FrictionScreen } from './friction-screen.view';
import { HOME_URL } from '../constants';

const Page = genesisStyled(Flex)`
  width: 100%;
  height: 100vh;
`;

const ContentWrapper = genesisStyled(Flex)`
  max-width: 790px;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
`;

const Fade = genesisStyled.div`
  width: 100%;
  transition: opacity 400ms ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

export const SuccessPage = () => {
  const { moduleId } = useParams();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const [showFrictionScreen, setShowFrictionScreen] = useState(null);
  // Getting points from location state
  // set in useCompleteHealthProfileQuestion hook
  const { state: locationState } = location;
  const activeModuleId = locationState?.activeModule ?? null;
  const points = locationState?.points[activeModuleId] ?? 0;
  const assessmentType = locationState?.assessmentType ?? null;
  const successScreen = locationState?.successScreen ?? null;
  const pointsEnabled = useConfigProperty('core.ui.features.pointsEnabled');
  const completionPointsToDisplay = pointsEnabled ? points : 0;

  useEffect(() => {
    // need to explicitly check for false to ensure we don't track when showFrictionScreen is null
    if (showFrictionScreen === false) {
      sendAnalyticsPageView({
        product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
        screen_name: SCREEN_NAME.ASSESSMENT_COMPLETED,
        assessment_id: moduleId,
        assessment_type: assessmentType,
      });
    }
  }, [showFrictionScreen, moduleId, assessmentType]);

  useEffect(() => {
    const shouldDisplayFrictionScreen = !!successScreen?.frictionScreen;
    setShowFrictionScreen(shouldDisplayFrictionScreen);
    if (shouldDisplayFrictionScreen) {
      const frictionScreenTimer = setTimeout(() => {
        setShowFrictionScreen(false);
      }, 2000);
      return () => {
        clearTimeout(frictionScreenTimer);
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!successScreen) {
    // TODO: look into providing more dynamic route based on assessment entry point
    return <FullPageError route={HOME_URL} />;
  }

  const {
    title = '',
    description = '',
    imageId,
    buttonText = '',
    buttonUrl,
    frictionScreen,
  } = successScreen;

  const ctaLink = getSanitizedURL(buttonUrl);

  return (
    <Page>
      <Transition timeout={250} in appear unmountOnExit>
        {(state) => (
          <Fade state={state}>
            {showFrictionScreen ? (
              <ContentWrapper>
                <FrictionScreen
                  title={frictionScreen.title}
                  description={frictionScreen.description}
                />
              </ContentWrapper>
            ) : (
              <ContentWrapper justifyContent="center">
                <CTAMediaObject
                  title={title}
                  description={description}
                  eyebrow={
                    completionPointsToDisplay > 0 && (
                      <Flex alignItems="center">
                        <Icon icon={ICONS.STAR_FILLED} aria-hidden />
                        <Overline as="p" marginLeft="half">
                          {formatMessage(
                            { id: 'HEALTH_PROFILE_COMPLETION_POINTS' },
                            { points: completionPointsToDisplay },
                          )}
                        </Overline>
                      </Flex>
                    )
                  }
                  ctaLink={ctaLink}
                  ctaAnalytics={() =>
                    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                      product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
                      screen_name: SCREEN_NAME.ASSESSMENT_COMPLETED,
                      detail: 'view results',
                      assessment_id: moduleId,
                      assessment_type: assessmentType,
                    })
                  }
                  ctaText={buttonText}
                  image={imageId && getContentUrl(imageId)}
                />
              </ContentWrapper>
            )}
          </Fade>
        )}
      </Transition>
    </Page>
  );
};
