import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';
import { MESSAGING_THREADS_PATH } from './constants/messaging-requests.constants';
import {
  ThreadDetailsResponseData,
  ThreadDetailsDeserialisedData,
} from './types/threads.types';

export const getThreadDetails = async (
  threadId: string,
): Promise<ThreadDetailsDeserialisedData> => {
  const response = await leagueFetch(`${MESSAGING_THREADS_PATH}/${threadId}`);
  if (!response.ok) {
    throw new Error(`getThreadDetails error: ${response.status}`);
  }
  const responseJson: ThreadDetailsResponseData = await response.json();
  return deserialise(responseJson);
};
