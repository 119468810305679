import React, { useCallback } from 'react';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { Icon } from '@leagueplatform/genesis-core';
import { Category } from './category.component';
import { CoverageIcon } from './icons.component';
import { Category as CategoryType } from '../types/category.type';
import { CoverageType } from '../types/coverage-type.type';

const ListItem = genesisStyled(Box)(
  css({
    backgroundColor: 'surface.background.primary',
    borderBottomWidth: 'thin',
    borderColor: 'onSurface.border.subdued',
    borderLeftWidth: 'thin',
    borderStyle: 'solid',

    '&:first-of-type': { borderTop: 'none' },
    '&:last-of-type': { borderBottom: 'none' },
  }),
);

const ExpandButton = genesisStyled(Flex)(
  css({
    backgroundColor: 'inherit',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'normal',
    justifyContent: 'spaceBetween',
    padding: ['one', 'one', 'two'],
    paddingRight: 'one',
    textAlign: 'inherit',
    width: '100%',
    '&[aria-expanded="true"]': {
      borderBottom: 'solid',
      borderBottomWidth: 'thin',
      borderColor: 'onSurface.border.subdued',
    },
  }),
);

interface CategoryGroupProps {
  id: string;
  name: string;
  coverage: CoverageType;
  categories: CategoryType[];
  isExpanded: boolean;
  onToggleExpand: (id: string) => void;
  onCategorySelect: (id: string) => void;
  embedded?: boolean;
}

export const CategoryGroup = ({
  id,
  name: groupName,
  coverage: groupCoverage,
  categories,
  isExpanded,
  onToggleExpand,
  onCategorySelect,
  embedded = false,
}: CategoryGroupProps) => {
  const toggleExpandCallback = useCallback(
    () => onToggleExpand(id),
    [id, onToggleExpand],
  );
  const HEADING_ID = `${id}-heading`;

  return (
    <>
      <HeadingThree
        as="h2"
        alignContent="center"
        display="flex"
        fontSize={['heading4', 'heading4', 'heading3']}
        id={HEADING_ID}
        justifyContent="space-between"
      >
        <ExpandButton
          aria-expanded={isExpanded}
          aria-controls={id}
          as="button"
          onClick={toggleExpandCallback}
        >
          <Flex flexGrow={1} alignItems="center" as="span">
            <Box
              display={['none', 'none', 'block']}
              paddingRight="one"
              as="span"
            >
              <CoverageIcon coverage={groupCoverage} embedded={embedded} />
            </Box>
            <Box as="span">{groupName}</Box>
          </Flex>
          <Box flexGrow="0" as="span">
            <Icon
              icon={
                isExpanded ? 'interfaceChevronDown' : 'interfaceChevronRight'
              }
            />
          </Box>
        </ExpandButton>
      </HeadingThree>
      <Box
        aria-labelledby={HEADING_ID}
        backgroundColor="surface.background.secondary"
        display={isExpanded ? 'block' : 'none'}
        id={id}
        paddingLeft={['one', 'one', 'four']}
        role="region"
      >
        <Box as="ul">
          {categories?.map((category) => (
            <ListItem as="li" key={category.unique_id}>
              <Category
                id={category.unique_id}
                name={category.category}
                coverage={category.coverage}
                description={category.description}
                examples={category.examples}
                restriction={category.restriction}
                onCategorySelect={onCategorySelect}
                embedded={embedded}
              />
            </ListItem>
          ))}
        </Box>
      </Box>
    </>
  );
};
