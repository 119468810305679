export const createNavigation = (questions, hideTopics, introScreen) =>
  questions.reduce((acc, question, index) => {
    const topicType = {
      type: 'topic',
      topicId: question.topicId,
    };
    const questionType = {
      type: 'question',
      ...question,
    };

    const middleScreens = [];

    // Add welcome screen to beginning of module navigation
    if (index === 0) {
      if (introScreen) {
        const welcomeType = { type: 'welcome', topicId: question.topicId };
        middleScreens.push(welcomeType);
      }
      // The topic screen is still needed
      if (!hideTopics) {
        middleScreens.push(topicType);
      }
    }

    // This block adds the topic screens between questions
    if (
      !hideTopics &&
      questions[index - 1] &&
      question.topicId !== questions[index - 1].topicId
    ) {
      middleScreens.push(topicType);
    }

    return [...acc, ...middleScreens, questionType];
  }, []);
