import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colour } from '../../theme';
import { horizontalBox, flexAlign } from '../../utils/flexible';
import checkmarkIcon from 'assets/checkmark.svg';

const Label = styled.label`
  display: inline-block;
  ${({ disabled }) => (disabled ? `cursor: not-allowed;` : `cursor: pointer;`)};
`;

const LabelText = styled.span`
  ${({ showLabel }) =>
    showLabel
      ? `opacity: 1; width: default; height: default;`
      : `opacity: 0; max-width: 0; height: 0;`} ${horizontalBox()};
  ${flexAlign('center')};
`;

const Switch = styled.span`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 8px 6px ${colour('greyLight')};
  transition: 0.2s;
  border-radius: 19px;

  &:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: ${colour('white')};
    transition: 0.2s;
    border-radius: 50%;
  }
`;

const InvisibleCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &[disabled] + ${Slider} {
    opacity: 0.5;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 0 2px ${colour('purpleLighter')};
  }

  &:checked + ${Slider} {
    background: url(${checkmarkIcon}) no-repeat 8px 6px/12px 12px
      ${colour('purpleDark')};
  }

  &:checked + ${Slider}:before {
    transform: translateX(24px);
  }
`;

export const Toggle = ({
  checked,
  onChange,
  disabled,
  name,
  children,
  showLabel,
}) => {
  const id = name || Date.now();
  return (
    <Label htmlFor={id} disabled={disabled}>
      <LabelText showLabel={showLabel}>{children}</LabelText>
      <Switch>
        <InvisibleCheckbox
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          id={id}
        />
        <Slider />
      </Switch>
    </Label>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Toggle.defaultProps = {
  checked: false,
  disabled: false,
  showLabel: false,
  name: null,
};
