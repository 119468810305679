import { useMutation } from 'react-query';
import {
  getValidicUser,
  setWearablesConsent,
} from '@leagueplatform/health-journey-api';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { captureError } from '@leagueplatform/observability';

interface MutateVariables {
  checkValidicUserOnSuccess: () => void;
}

/**
 * useGetValidicUser makes a call to the getValidicUser endpoint to either get an existing validic user
 * or create a new one. If for some reason that new user cannot be created (and there is no returned
 * validic user id), we throw an error.
 */
const useGetValidicUser = () =>
  useMutation(
    () =>
      getValidicUser({
        context: {
          AdditionalNotes: {
            reason:
              'User unable to sign up to automatically track Challenge data because no validic user could be found or created. As a result, data cannot be tracked automatically.',
          },
        },
        ownershipTeam: JOURNEY_EXPERIENCE_TEAM,
      }),
    {
      onSuccess: (data, { checkValidicUserOnSuccess }: MutateVariables) => {
        if (!data?.validic_user_id) {
          const validicError = new Error(
            'User unable to sign up to automatically track Challenge data because no validic user id was returned from the Validic API. As a result, we cannot confirm a validic user was created and data cannot be tracked automatically.',
          );
          captureError(validicError, {
            ownershipTeam: JOURNEY_EXPERIENCE_TEAM,
          });
          throw validicError;
        }
        checkValidicUserOnSuccess();
      },
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG',
        });
      },
    },
  );

/**
 * useSetConsentAndCheckValidicUser first makes a call to the `set_wearables_consent` endpoint to
 * set wearables consent to true and enable the creation of a validic user if one has not already been created.
 * Upon a successful response, we then make a call to our `get_validic_user` endpoint to ensure a validic user
 * exists that is associated with the current league user.
 */
export const useSetConsentAndCheckValidicUser = () => {
  const { isLoading: isMutationLoading, mutate: validicUserMutation } =
    useGetValidicUser();
  const checkValidicUser = useMutation(
    () =>
      setWearablesConsent({
        context: {
          AdditionalNotes: {
            reason:
              'User unable to sign up to automatically track Challenge data because wearables consent could not be set. As a result, data cannot be tracked automatically.',
          },
        },
        ownershipTeam: JOURNEY_EXPERIENCE_TEAM,
      }),
    {
      // underscore indicates to the compiler that _data is an unused variable that we
      // are ok with ignoring. Needed here for accessing the second args to onSuccess, the mutate variables
      onSuccess: (_data, { checkValidicUserOnSuccess }: MutateVariables) => {
        validicUserMutation({ checkValidicUserOnSuccess });
      },
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG',
        });
      },
    },
  );
  return {
    isMutationLoading,
    ...checkValidicUser,
  };
};
