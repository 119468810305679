import defaultsDeep from 'lodash/defaultsDeep';
import {
  LeagueCombinedConfig,
  getConfig,
  useConfigProperty,
} from '@leagueplatform/core';

const defaultConfig = {
  showBenefitDetailFeedbackComponent: false,
  showLandingPageHeaderBranding: false,
  // TODO: Benefit Status Banner flags should be BE feature flags instead and will be updated soon
  showBenefitStatusBannerCreated: false,
  showBenefitStatusBannerPending: false,
  showBenefitStatusBannerExpired: false,
  showLandingPageClaimsEntry: false,
  enableNoNetworkLiveBalances: false,
} satisfies LeagueCombinedConfig['wallet'];

export const useWalletConfig = () => useConfigProperty('wallet', defaultConfig);
export const getWalletConfig = () =>
  defaultsDeep({}, getConfig().config.wallet, defaultConfig) as NonNullable<
    LeagueCombinedConfig['wallet']
  >;
