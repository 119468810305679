/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["storeDraft"]  }] */
import { StoreApi, createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { produce } from 'immer';
import {
  MasonryEngineFormState,
  MasonryEngineNodeState,
} from './types/masonry-engine-state.types';
import { useMasonryEngineConfig } from './masonry-engine-config-context';
import {
  AnyMasonryEngineNode,
  MasonryEngineNode,
} from './types/masonry-engine-node.types';

export type MasonryEngineNodeStateFromNode<
  Node extends MasonryEngineNode = MasonryEngineNode,
> = Node['properties'] & MasonryEngineNodeState;

export type MasonryEngineStateControllerStoreState = {
  nodeStateMap: Partial<
    Record<string, MasonryEngineNodeStateFromNode<AnyMasonryEngineNode>>
  >;
  // @TODO extend this to work with Form states in future form integration project
  formState: MasonryEngineFormState | undefined;
  /**
   * Set any node's state with a callback, which receives the current state and
   * returns what should be the next state.
   */
  setNodeState: <Node extends MasonryEngineNode>(
    nodeId: string,
    producer: (
      state: MasonryEngineNodeStateFromNode<Node>,
    ) => MasonryEngineNodeStateFromNode<Node>,
  ) => void;
  /**
   * Override any node's state.
   */
  setInitialNodeState: <Node extends MasonryEngineNode>(
    nodeId: string,
    state: MasonryEngineNodeStateFromNode<Node>,
  ) => void;
  /**
   * Getter function to get the node state using node id
   * */
  getNodeState: <Node extends MasonryEngineNode>(
    nodeId: string,
  ) => MasonryEngineNodeStateFromNode<Node> | undefined;
};

/**
 * Function createStateControllerStore is used to create a new zustand store. It returns store instance which
 * can be used to get or set any state of the MasonryEngineStateController.
 */
export const createStateControllerStore = () => {
  /**
   * A store for All Masonry Engine Node's state.
   */
  const stateControllerStore =
    createStore<MasonryEngineStateControllerStoreState>((set, get) => ({
      nodeStateMap: {},
      formState: undefined,
      setNodeState: (
        nodeId,
        producer, // adding A function which can act and prev current state setter like rea
      ) =>
        set((store) =>
          produce(store, (storeDraft) => {
            const currentNodeState = storeDraft.nodeStateMap[nodeId];
            if (!currentNodeState) {
              throw new Error(
                'Trying to set the state of a node whose state was not yet initialized!',
              );
            }

            storeDraft.nodeStateMap[nodeId] = producer(currentNodeState);
          }),
        ),
      setInitialNodeState: (nodeId, newNodeState) =>
        set((store) =>
          produce(store, (storeDraft) => {
            storeDraft.nodeStateMap[nodeId] = newNodeState;
          }),
        ),
      getNodeState: (nodeId) => {
        const store = get();
        return store.nodeStateMap[nodeId];
      },
    }));

  return stateControllerStore;
};

export type MasonryEngineStateControllerStore =
  StoreApi<MasonryEngineStateControllerStoreState>;

/**
 * React hook which converts zustand vanilla store into a zustand react store
 * using useStore hook from zustand
 */
export const useStateControllerStore = <T>(
  selector: (state: MasonryEngineStateControllerStoreState) => T,
): T => {
  const { stateControllerStore } = useMasonryEngineConfig();
  return useStore(stateControllerStore, selector);
};

/**
 * The State Controller is simply the getter and the setter from the state controller
 * store.
 */
export type MasonryEngineStateController = Pick<
  MasonryEngineStateControllerStoreState,
  'getNodeState' | 'setNodeState'
>;

export const INITIAL_COMMON_STATE: MasonryEngineNodeState = {
  isVisible: true,
};
