import React from 'react';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { UserAchievements } from 'components/user-achievements/user-achievements.component';
import { AchievementBreadcrumb } from 'components/achievement-breadcrumbs/achievement-breadcrumbs.view';
import { ErrorState } from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';

export const AchievementsLandingPage = () => {
  trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
    product_area: 'rewards',
    sub_product_area: 'achievements',
    screen_name: 'achievements',
  });

  return (
    <StackLayout
      orientation="vertical"
      css={{
        maxWidth: '1400px',
        marginX: 'auto',
        paddingX: '$two',
        paddingY: '$three',
      }}
    >
      <AchievementBreadcrumb />
      <UserAchievements errorComponent={<ErrorState hideImage />} />
    </StackLayout>
  );
};
