import React from 'react';
import { Link } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  usePageViewAnalytics,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { Achievement } from '@leagueplatform/rewards-api';
import {
  Button,
  Box,
  StackLayout,
  HeadingText,
  ParagraphText,
  Modal,
} from '@leagueplatform/genesis-core';
import { BadgeImage } from '../badge-image/badge-image.view';
import { EarnedTag } from '../earned-tag/earned-tag.view';
import { ProgressBar } from '../progress-bar/progress-bar.view';

interface AchievementsDetailsModalProps {
  onModalChange: () => void;
  modalAchievement: Achievement;
  open: boolean;
}

export const AchievementsDetailsModal = ({
  open,
  onModalChange,
  modalAchievement,
}: AchievementsDetailsModalProps) => {
  const { formatMessage, formatDate } = useIntl();

  usePageViewAnalytics({
    product_area: 'rewards',
    sub_product_area: 'achievements',
    screen_name: 'achievement detail',
    sub_detail: modalAchievement.name,
    achievement_id: modalAchievement.id,
  });

  const DATE_FORMAT = {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
  } as const; // e.g Jan 12 2022

  const sendSelectOptionAnalytics = (selectedOption: string) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'achievement detail',
      detail: selectedOption,
      sub_detail: modalAchievement.name,
      achievement_id: modalAchievement.id,
      is_earned: modalAchievement.progress.criteria.is_completed
        ? modalAchievement.progress.criteria.is_completed
        : false,
    });
  };

  return (
    <Modal.Root open={open} onOpenChange={onModalChange}>
      <Modal.Content
        css={{
          '.GDS-modal': { maxWidth: 500 },
          '.GDS-modal-content': { padding: '$twoAndHalf', paddingTop: '$none' },
        }}
      >
        <StackLayout
          data-testid="achievement-modal"
          orientation="vertical"
          horizontalAlignment="center"
          css={{ textAlign: 'center' }}
        >
          <BadgeImage
            imageUrl={modalAchievement?.image?.large}
            isDisabled={modalAchievement.completions < 1}
            css={{ margin: '$oneAndHalf' }}
          />
          <HeadingText level="3" size="lg">
            {modalAchievement.name}
          </HeadingText>
          {modalAchievement?.progress?.criteria?.is_completed && (
            <HeadingText
              level="4"
              size="sm"
              css={{ paddingTop: '$half', color: '$onSurfaceTextSubdued' }}
            >
              {formatMessage({ id: 'EARNED' })}{' '}
              {formatDate(modalAchievement.last_completed_at, DATE_FORMAT)}
            </HeadingText>
          )}
          <ParagraphText
            css={{
              paddingTop: '$oneAndHalf',
              color: '$onSurfaceTextSubdued',
            }}
          >
            {modalAchievement?.progress?.criteria?.is_completed
              ? modalAchievement.description_earned
              : modalAchievement.description}
          </ParagraphText>
          {modalAchievement.completions > 1 && (
            <Box css={{ paddingTop: '$threeQuarters' }}>
              <EarnedTag completionAmount={modalAchievement.completions} />
            </Box>
          )}
          {modalAchievement.display_progress && (
            <Box
              css={{
                width: '100%',
                paddingTop: '$quarter',
                textAlign: 'left',
              }}
            >
              <ProgressBar
                progressTitle={modalAchievement?.progress?.title}
                isLoading={false}
                currentActivity={modalAchievement?.progress?.criteria?.current}
                totalActivities={
                  modalAchievement?.progress?.criteria?.threshold
                }
              />
            </Box>
          )}

          {!modalAchievement?.progress?.criteria?.is_completed && (
            <Button
              to={JOURNEY_ROUTES.journeyHome()}
              as={Link}
              css={{
                textDecoration: 'none',
                marginTop: '$two',
              }}
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
              }}
              onClick={() => sendSelectOptionAnalytics('back to journey')}
            >
              {formatMessage({ id: 'GO_TO_JOURNEY' })}
            </Button>
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
