import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { UnavailableDeviceConnectModal } from '@leagueplatform/health-programs';
import { MoreOptionsButton } from './more-options-button';

type AddAppsDevicesLinkProps = { onClick?: () => void };

export function AddAppsDevicesLink({ onClick }: AddAppsDevicesLinkProps) {
  const { formatMessage } = useIntl();
  const [displayUnavailableOnWebModal, setDisplayUnavailableOnWebModal] =
    React.useState(false);

  return (
    <>
      <MoreOptionsButton
        onClick={() => {
          setDisplayUnavailableOnWebModal(true);
          onClick?.();
        }}
      >
        {formatMessage({ id: 'ADD_APPS_DEVICES' })}
      </MoreOptionsButton>

      {displayUnavailableOnWebModal ? (
        <UnavailableDeviceConnectModal
          onClose={() => setDisplayUnavailableOnWebModal(false)}
        />
      ) : null}
    </>
  );
}
