import React from 'react';
import { AxisScale } from '@visx/axis';
import type { Dimensions } from 'types/dimensions';

export type ChartState = {
  dimensions: Dimensions;
  xMax: number;
  yMax: number;
  xScale: AxisScale;
  yScale: AxisScale;
  margin: Dimensions['margin'];
};

const ChartContext = React.createContext<ChartState | undefined>(undefined);
ChartContext.displayName = 'ChartContext';

export type ChartProviderProps = {
  children: React.ReactNode;
};

export const ChartProvider = ChartContext.Provider;

export function useChart() {
  const context = React.useContext(ChartContext);
  if (context === undefined) {
    throw new Error('useChart must be used within a ChartProvider');
  }
  return context;
}
