import React from 'react';
import { useConfigProperty } from '@leagueplatform/config';
import { StackItem, useTheme } from '@leagueplatform/genesis-core';
import { get } from 'lodash';

const DEFAULT_STYLES = {
  height: '140px',
  width: '20024px',
  smallScreenWidth: '5000px',
  backgroundColor: '$decorativeBrandPrimaryPastel',
};

type PageHeaderBackgroundProps = {
  height?: string;
  pageHeaderBackgroundHeight?: string;
  pageHeaderBackgroundColor?: string;
  backgroundImage?: string;
  curved?: boolean;
  imageAltText?: string;
  imageHeight?: string;
  children: React.ReactNode;
};

export const PageHeaderBackground = ({
  height = DEFAULT_STYLES.height,
  pageHeaderBackgroundHeight = DEFAULT_STYLES.height,
  pageHeaderBackgroundColor,
  backgroundImage,
  curved,
  imageAltText,
  imageHeight,
  ...rest
}: PageHeaderBackgroundProps) => {
  const theme = useTheme();
  const pageHeaderConfig = useConfigProperty('core.ui.components.pageHeader');

  // @TODO remove this when the PBLs start passing colors in genesis-core tokens
  const pageHeaderBackgroundColorCoreToken =
    pageHeaderConfig?.backgroundColor &&
    get(theme, pageHeaderConfig?.backgroundColor);
  const isCurved =
    curved !== undefined ? curved : pageHeaderConfig?.shape !== 'line';
  // @TODO Explore a more systematic addition of classnames to select Masonry components
  return (
    <StackItem
      className="masonry-page-header-background"
      css={{
        height,
        backgroundColor: 'transparent',
        marginTop: '$oneAndHalf',
        marginBottom: '$twoAndHalf',
        '@laptop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
        '@desktop': {
          marginTop: '56px',
          marginBottom: '56px',
        },
        '&:before': {
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : `unset`,
          backgroundSize: imageHeight ? `auto ${imageHeight}px` : 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundColor: `${
            pageHeaderBackgroundColor ??
            pageHeaderBackgroundColorCoreToken ??
            DEFAULT_STYLES.backgroundColor
          }`,
          content: '""',
          zIndex: '0',
          position: 'absolute',
          backgroundPosition: `bottom calc(0px - 5px) left calc(100% - ${DEFAULT_STYLES.width}/2 + 50vw)`,
          width: `${DEFAULT_STYLES.width}`,
          height: `${DEFAULT_STYLES.width}`,
          left: `calc(50% - (${DEFAULT_STYLES.width} / 2))`,
          top: `calc(${pageHeaderBackgroundHeight} - ${DEFAULT_STYLES.width})`,
          borderRadius: `${isCurved ? DEFAULT_STYLES.width : 0}`,
          '@mobile': {
            backgroundPosition: `bottom calc(0px - 5px) left calc(100% - ${DEFAULT_STYLES.smallScreenWidth}/2 + 50vw)`,
            top: `calc(${pageHeaderBackgroundHeight} - ${DEFAULT_STYLES.smallScreenWidth})`,
            left: `calc(50% - (${DEFAULT_STYLES.smallScreenWidth} / 2))`,
            width: `${DEFAULT_STYLES.smallScreenWidth}`,
            height: `${DEFAULT_STYLES.smallScreenWidth}`,
            borderRadius: `${isCurved ? DEFAULT_STYLES.smallScreenWidth : 0}`,
          },
          '@mobileLandscape': {
            backgroundPosition: `bottom calc(0px - 5px) left calc(100% - ${DEFAULT_STYLES.smallScreenWidth}/2 + 50vw)`,
            top: `calc(${pageHeaderBackgroundHeight} - ${DEFAULT_STYLES.smallScreenWidth})`,
            left: `calc(50% - (${DEFAULT_STYLES.smallScreenWidth} / 2))`,
            width: `${DEFAULT_STYLES.smallScreenWidth}`,
            height: `${DEFAULT_STYLES.smallScreenWidth}`,
            borderRadius: `${isCurved ? DEFAULT_STYLES.smallScreenWidth : 0}`,
          },
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};
