import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { ErrorPage, ERROR_TYPES } from '@leagueplatform/masonry-widgets';
import {
  useGetWidgetContainer,
  ContainerWidgetItemV2,
} from '@leagueplatform/masonry-api';
import { MasonrySpinner } from 'components/spinner/spinner.component';
import type { ContainerWidgetItem } from '@leagueplatform/masonry-api';
import { WidgetWrapper } from 'components/widget-wrapper/widget-wrapper.component';
import { WidgetWrapperV2 } from 'components/widget-wrapper/widget-wrapper-v2.component';
import { useGetConfig } from '../../hooks/use-get-config.hook';

export const WidgetsWrapper = () => {
  const { appId, apiVersion, apiParams } = useGetConfig();
  const { data, isLoading, error } = useGetWidgetContainer(
    appId,
    apiVersion,
    apiParams,
  );

  const { widgets } = data || {};

  if (error) {
    return <ErrorPage errorType={ERROR_TYPES.API_ERROR} />;
  }

  if (isLoading) {
    return <MasonrySpinner />;
  }

  if (apiVersion < 2)
    return (
      <StackLayout spacing="$two" horizontalAlignment="stretch">
        {(widgets as ContainerWidgetItem[])?.map(
          (widget: ContainerWidgetItem) => (
            <WidgetWrapper widget={widget} key={widget?.id} appId={appId} />
          ),
        )}
      </StackLayout>
    );

  return (
    <StackLayout spacing="$two" horizontalAlignment="stretch">
      {(widgets as ContainerWidgetItemV2[])?.map(
        (widget: ContainerWidgetItemV2) => (
          <WidgetWrapperV2 widget={widget} key={widget?.id} appId={appId} />
        ),
      )}
    </StackLayout>
  );
};
