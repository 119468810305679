import { StackLayout } from '@leagueplatform/genesis-core';
import { NestedCampaign } from '@leagueplatform/health-journey-api';
import { useIntl } from '@leagueplatform/locales';
import { ActivityListSection } from 'components/activity-list-section/activity-list-section.component';
import { useSortActivitiesByStatus } from 'hooks/use-sort-activities-by-status.hook';
import React from 'react';

interface ActivityListProps {
  compact?: boolean;
  relatedCampaign: NestedCampaign;
}

export function ActivityList({ compact, relatedCampaign }: ActivityListProps) {
  const { activeActivities, completedActivities, missedActivities } =
    useSortActivitiesByStatus(relatedCampaign.userHealthActivities?.data || []);

  const { formatMessage } = useIntl();

  return (
    <StackLayout orientation="vertical" spacing="$two">
      {activeActivities.length > 0 && (
        <ActivityListSection
          compact={compact}
          activities={activeActivities}
          relatedCampaign={relatedCampaign}
          sectionTitle={formatMessage({ id: 'ACTIVITIES' })}
        />
      )}
      {completedActivities.length > 0 && (
        <ActivityListSection
          compact={compact}
          activities={completedActivities}
          relatedCampaign={relatedCampaign}
          sectionTitle={formatMessage({ id: 'COMPLETED_ACTIVITIES' })}
        />
      )}
      {missedActivities.length > 0 && (
        <ActivityListSection
          compact={compact}
          activities={missedActivities}
          relatedCampaign={relatedCampaign}
          sectionTitle={formatMessage({ id: 'MISSED_ACTIVITIES' })}
        />
      )}
    </StackLayout>
  );
}
