import { createContext, useContext } from 'react';
import type { Context } from 'react';
import type {
  MasonryEngineNode,
  MasonryEngineNodeAction,
} from './types/masonry-engine-node.types';
import type { MasonryEngineConfig } from './types/masonry-engine-config.types';

/**
 * A React Context for storing all node renderers coming from app and capabilities
 * to underlying MasonryEngine node resolver components.
 */
export const MasonryEngineConfigContext = createContext<
  MasonryEngineConfig | undefined
>(undefined);

export const getMasonryEngineConfigContext = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>() =>
  MasonryEngineConfigContext as Context<
    MasonryEngineConfig<Node, Action> | undefined
  >;

/**
 * A hook that returns all node renderers via Context.
 */
export const useMasonryEngineConfig = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>() => {
  const config = useContext(getMasonryEngineConfigContext<Node, Action>());

  if (!config) {
    throw new Error(
      'MasonryEngine component must be rendered within the context of a Masonry Engine flow!',
    );
  }

  return config;
};
