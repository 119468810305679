import { deserialise } from 'kitsu-core';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  UserChallengeRanksResponseData,
  DeserialisedUserChallengeRanksResponseData,
} from 'challenges/types/user-challenge-ranks-data.type';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { USER_CHALLENGES_RANKINGS_PATH } from './constants/challenge-requests.constants';

export const getUserChallengeRanks = async (
  challengeId: string,
  requestPath?: string | null,
  teamId?: string,
  errorContext?: ErrorContext,
): Promise<DeserialisedUserChallengeRanksResponseData> => {
  const baseFetchPath =
    requestPath ||
    `${USER_CHALLENGES_RANKINGS_PATH}&page[size]=10&challengeId=${challengeId}`;
  const userChallengeRanksPath = teamId?.length
    ? `${baseFetchPath}&filter[team]=${teamId}`
    : baseFetchPath;

  const response = await leagueFetch(userChallengeRanksPath, {
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (!response.ok) {
    throw new Error(`getUserChallengeRanks error: ${response.status}`);
  }
  const responseJson: UserChallengeRanksResponseData = await response.json();
  return deserialise(responseJson);
};
