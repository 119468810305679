import { styled } from '../../../theme';

export const TypographyBase = styled('span', {
  margin: 0,
  '& a, a&': {
    color: '$interactiveActionPrimary',
    fontWeight: '$buttonOne',
  },
  variants: {
    emphasis: {
      base: {
        color: '$onSurfaceTextPrimary',
      },
      subtle: {
        color: '$onSurfaceTextSubdued',
      },
    },
  },
});
