import { useQuery } from 'react-query';
import { captureError } from '@leagueplatform/observability';
import { getHealthLiteracy } from '@leagueplatform/dashboard-api';

export const HEALTH_LITERACY_QUERY_KEY = 'health-literacy';

export function useGetHealthLiteracy(dataType: string) {
  return useQuery(
    [HEALTH_LITERACY_QUERY_KEY, dataType],
    () => getHealthLiteracy(dataType),
    {
      onError(error) {
        captureError(new Error(`Unable to fetch health literacy: ${error}`));
      },
    },
  );
}
