import { useQuery } from 'react-query';

import {
  GET_HEALTH_PROGRAM_CATEGORIES_MESSAGE_TYPE,
  getHealthProgramCategories,
} from '@leagueplatform/health-journey-api';
import { alphaSortObjectsByStringValue } from 'utils/filter-and-sort-objects-by-key.utils';

export const useHealthProgramCategories = () =>
  useQuery(
    [GET_HEALTH_PROGRAM_CATEGORIES_MESSAGE_TYPE],
    getHealthProgramCategories,
    {
      select(data) {
        const { title, categories } = data;
        const sortedCategories = alphaSortObjectsByStringValue(
          categories,
          'name',
        );
        return { title, categories: sortedCategories };
      },
    },
  );
