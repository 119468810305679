export const WALLET_API_FEATURE_FLAGS = {
  BENEFITS_LANDING_GRID_LAYOUT: 'benefits_landing_page_grid',
  IS_CLINICAL_PROGRAMS_ENABLED: 'enable_wallet_clinical_program',
  IS_CONTENT_PANEL_BENEFIT_CARD_ENABLED: 'enable_content_panel_benefit_card',
  IS_DEPENDENTS_DROPDOWN_ENABLED: 'enable_dependents_dropdown',
  IS_MASONRY_LANDING_ENABLED: 'enable_masonry_wallet_landing_screen',
  IS_NON_ACTIVE_POLICY_INFORMATION_HIDDEN:
    'wallet_hide_non_active_policy_information',
  IS_SPENDING_ACCOUNT_BALANCE_BANNER_HIDDEN:
    'hide_spending_account_balance_banner',
  MANAGE_DEPENDENTS_V2: 'manage_dependent_v2',
  WALLET_LIVE_BALANCE_V2: 'wallet_live_balance_v2',
} as const;

export const WALLET_LOCAL_FEATURE_FLAGS = {
  // add local feature flags here
} as const;
