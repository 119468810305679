import React from 'react';
import {
  HeadingText,
  ParagraphText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useHelpCenterLinks } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { CHALLENGES_UTM_SOURCE } from 'constants/challenges.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME, SCREEN_NAMES } from '@leagueplatform/analytics';
import { CLP_DETAIL } from 'constants/analytics.constants';

export const ChallengeFAQ = () => {
  const { formatMessage } = useIntl();
  const { healthProgramsAndJourneyLearnMoreLink } = useHelpCenterLinks(
    CHALLENGES_UTM_SOURCE,
  );
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: SCREEN_NAMES.HEALTH_CAMPAIGN_DETAIL,
      detail,
    });

  return (
    <StackLayout spacing="$half">
      <HeadingText level="2" size="sm">
        {formatMessage({ id: 'CHALLENGE_FAQ_HEADING' })}
      </HeadingText>
      <ParagraphText>
        {formatMessage(
          { id: 'CHALLENGE_FAQ_CONTENT' },
          {
            helpCenterLink: (
              <TextAction
                href={healthProgramsAndJourneyLearnMoreLink}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() =>
                  handleAnalyticsClick(CLP_DETAIL.NAVIGATE_TO_HELP_CENTER)
                }
              >
                {formatMessage({ id: 'HELP_CENTER' })}
              </TextAction>
            ),
          },
        )}
      </ParagraphText>
    </StackLayout>
  );
};
