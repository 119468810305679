import { Dependent } from '@leagueplatform/wallet-api';
import { selectCardHtml } from 'pages/wallet-detail/utils/select-card-html.util';
import { useGetWalletDependentCardsQuery } from './use-get-wallet-dependent-cards-query.hook';
import { getDependentCard } from '../utils/get-dependent-card.util';

export interface UseDependentCardViewerModalResult {
  cardImage?: string;
  cardType: string;
  cardName?: string;
  isLoading: boolean;
}

interface UseDependentCardViewerModalProps {
  benefitId: string;
  dependent: Dependent;
}

export const useDependentCardViewerModal = ({
  benefitId,
  dependent,
}: UseDependentCardViewerModalProps): UseDependentCardViewerModalResult => {
  const { data, isLoading } = useGetWalletDependentCardsQuery();
  const dependentCard = getDependentCard(
    dependent?.dependent_id,
    benefitId,
    data?.benefit_cards,
  );

  let cardImage;

  if (dependentCard) {
    const cardHtml = selectCardHtml(dependentCard);
    cardImage = cardHtml?.front.src;
  }

  return {
    cardName: dependentCard?.full_name || '',
    cardImage,
    cardType: dependentCard?.type || '',
    isLoading,
  };
};
