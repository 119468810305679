import { GDSColor } from '@leagueplatform/genesis-core';
import { GenesisFontSizeTokens } from './genesis-api-token.types';
import { WidgetLink } from './container-data';
import { WidgetType } from './widget-types';

export enum Orientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  GRID = 'grid',
}

export enum SupportedMedia {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
  DESKTOP = 'desktop',
}

export type TraitsItem = {
  backgroundColor?: string;
  badgeBackgroundColor?: GDSColor;
  emphasizeCurrentValue?: boolean;
  gridColumnCount?: number;
  headingSize?: GenesisFontSizeTokens;
  orientation?: Orientation;
  imageHeight?: string;
  imageAlign?: 'left' | 'right' | 'center';
  isChildItem?: boolean;
  curved?: boolean;
  includeSafeArea?: boolean;
};

export type Traits = {
  [key in SupportedMedia]: TraitsItem;
};

export interface WidgetData<WidgetDataAttributes> {
  id: string;
  type: WidgetType;
  attributes: WidgetDataAttributes;
  product_identifier: string;
  traits?: Traits;
  links: Array<WidgetLink>;
}
