import {
  leagueRoutesMap,
  LEAGUE_MODULE_NAMES,
  RoutesMap,
} from './league-routes';

const checkModuleMapExists = (moduleName: LEAGUE_MODULE_NAMES) => {
  if (!Object.keys(leagueRoutesMap).includes(moduleName))
    throw Error('Passed in module name has not been mapped to a path.');
};

export const getModulePath = (moduleName: LEAGUE_MODULE_NAMES) => {
  checkModuleMapExists(moduleName);
  return leagueRoutesMap[moduleName];
};

export const setModulePath = (
  moduleName: LEAGUE_MODULE_NAMES,
  path: string,
) => {
  checkModuleMapExists(moduleName);
  leagueRoutesMap[moduleName] = path;
};

export const overwriteRoutePaths = (
  overrideRouteMap: Partial<RoutesMap>,
): void => {
  Object.keys(overrideRouteMap).forEach((key) => {
    const moduleName = key as LEAGUE_MODULE_NAMES;
    const value = overrideRouteMap[moduleName];
    if (value) {
      setModulePath(moduleName, value);
    }
  });
};
