import React from 'react';
import { Link } from '@leagueplatform/routing';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { useIntl } from '@leagueplatform/locales';
import { BackButtonArrow } from '@leagueplatform/web-common-components';
import { StackLayout, HeadingText } from '@leagueplatform/genesis-core';

export const AchievementBreadcrumb = () => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      orientation={{
        '@initial': 'vertical',
        '@laptop': 'horizontal',
        '@desktop': 'horizontal',
      }}
    >
      <BackButtonArrow
        as={Link}
        to={JOURNEY_ROUTES.journeyHome()}
        fontSize="heading3"
        color="onSurface.text.primary"
        marginRight="one"
        marginY="quarter"
        onClick={null}
      />
      <HeadingText level="1" size="xxl">
        {formatMessage({ id: 'ACHIEVEMENTS' })}
      </HeadingText>
    </StackLayout>
  );
};
