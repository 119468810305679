import styled from 'styled-components';
import { colour, getThemeValue } from '../theme';

export const Code = styled.code`
  font-size: ${getThemeValue('fontSizes.2')}px;
  background-color: ${colour('purpleLightest')};
  color: ${colour('purple')};
  padding-left: 0.5ch;
  padding-right: 0.5ch;
  font-family: monospace;
`;
