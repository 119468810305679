import React from 'react';
import { Select } from '@leagueplatform/genesis-core';
import { QuestionnaireItemAnswerOption } from 'fhir/r4';

import { useIntl } from '@leagueplatform/locales';
import { ControlledFieldset } from '@leagueplatform/web-common';
import { getDisplaySlug } from 'utils/display-slug.util';

export interface SelectInputProps {
  answerOptions: QuestionnaireItemAnswerOption[];
  id: string;
  name: string;
  legend: React.ReactNode;
  required?: boolean;
}

export const SelectInput = ({
  answerOptions,
  id,
  name,
  legend,
  required,
}: SelectInputProps) => {
  const { formatMessage } = useIntl();
  const defaultInputRules = {
    required: {
      value: required,
      message: formatMessage({ id: 'PLEASE_MAKE_A_SELECTION' }),
    },
  };
  const defaultValue = answerOptions.find((option) => option.initialSelected)
    ?.valueCoding?.code;

  const inputRules = required ? defaultInputRules : {};
  return (
    <ControlledFieldset
      name={name}
      inputOptions={{
        ...inputRules,
      }}
      legend={legend}
      id={id}
    >
      <Select
        id={id}
        aria-label={name}
        css={{ width: '350px' }}
        placeholder="select an option"
        defaultValue={defaultValue}
      >
        {answerOptions.map((option) => (
          <option
            key={getDisplaySlug({ id, option })}
            value={option.valueCoding!.code}
          >
            {option.valueCoding!.display ?? option.valueCoding!.code}
          </option>
        ))}
      </Select>
    </ControlledFieldset>
  );
};
