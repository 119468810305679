import { useLocation, useHistory, matchPath } from '@leagueplatform/routing';

/**
 * Provides a constant and functions that update a given query parameter within the URL.
 * @param {string} name Name of the URL parameter.
 * @example useURLParam('modal')
 */
export const useUrlParam = (name: string) => {
  const location = useLocation();
  const history = useHistory();

  const getParams = (): URLSearchParams => new URLSearchParams(location.search);

  const getParam = (): string | null => getParams().get(name);

  const matchParamPath = (path: string) => {
    const param = getParam();

    if (typeof param === 'string') {
      return matchPath(param, path);
    }
    return null;
  };

  const makeLocation = (params: URLSearchParams) => ({
    ...location,
    search: params.toString(),
  });

  const push = (params: URLSearchParams) => {
    const prevParams = getParams().toString();
    if (prevParams !== params.toString()) {
      history.push(makeLocation(params));
    }
  };

  const linkToParam = (path: string) => {
    const params = getParams();
    params.set(name, path);
    return makeLocation(params);
  };

  const setParam = (path: string) => {
    if (!path) return;
    const params = getParams();
    params.set(name, path);
    push(params);
  };

  const removeParam = () => {
    const params = getParams();
    params.delete(name);
    push(params);
  };

  return {
    urlParam: getParam(),
    matchParamPath,
    setParam,
    removeParam,
    linkToParam,
  };
};
