import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  HealthNeedQueryResponse,
  HealthSolution,
  HealthNeed,
} from '../types/health-need-pages.types';

interface GetImageSrcProps {
  contentId?: string;
  url?: string;
}

export const getImageSrc = ({ contentId, url }: GetImageSrcProps) => {
  if (url) return url;

  if (contentId) return getContentUrl(contentId);

  return undefined;
};

export const normalizeHealthNeed = (
  healthNeed: HealthNeedQueryResponse,
): HealthNeed => {
  const [topHealthSolution, ...healthSolutions] =
    healthNeed?.recommendations || [];

  return {
    ...healthNeed,
    ...(topHealthSolution &&
      !!Object.keys(topHealthSolution).length && {
        topHealthSolution: {
          ...topHealthSolution,
          imageSrc: getImageSrc(topHealthSolution?.image),
        },
      }),
    healthSolutions: healthSolutions.map((healthSolution: HealthSolution) => ({
      ...healthSolution,
      imageSrc: getImageSrc(healthSolution?.image),
    })),
  };
};
