import React, { useState } from 'react';
import { useParams, useHistory } from '@leagueplatform/routing';
import styled from 'styled-components';
import { Flex } from '@leagueplatform/ui-kit';
import { Transition } from 'react-transition-group';
import {
  FullPageError,
  LoadingIndicator,
  AssessmentExitModal,
} from '@leagueplatform/web-common-components';
import {
  Button,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  sendAnalyticsPageView,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { ModuleNavigator } from 'components/module-navigator.view';
import { ProgressBar } from 'components/progress-bar.view';
import { Onboarding } from 'components/onboarding/onboarding.view';
import { HealthAssessmentBackground } from 'components/health-assessment-background.view';
import { FrictionScreen } from 'components/friction-screen.view';
import { PRODUCT_AREA, SCREEN_NAME } from 'constants/analytics.constants';
import { useHealthAssessment } from 'hooks/queries/use-health-assessment.hook';
import { useExitAssessment } from 'hooks/queries/use-exit-assessment.hook';
import { useCompleteOnboarding } from 'hooks/queries/use-complete-onboarding.hook';
import { getSanitizedURL } from 'utils/get-sanitized-redirect-url.utils';
import {
  HEALTH_ASSESSMENT_TYPE,
  PULSE_CHECK_TYPE,
  HOME_URL,
} from './constants';

const TopNav = styled(Flex)`
  flex: 0 0 auto;
  justify-content: center;
`;

const ContentContainer = styled(Flex)`
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
`;

const Fade = styled.div`
  transition: opacity 400ms ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

const getAnalyticsScreenName = (hasOnboarding, screenType) => {
  if (hasOnboarding) {
    return SCREEN_NAME.ASSESSMENT_ONBOARDING_INTRO;
  }

  switch (screenType) {
    case 'welcome':
      return SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_INTRO;
    case 'topic':
      return SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE_CATEGORY_INTRO;
    case 'question':
      return SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE;
    default:
      return SCREEN_NAME.ASSESSMENT_QUESTIONNAIRE;
  }
};

export const HealthAssessment = () => {
  const {
    isLoading,
    isError,
    isResuming,
    moduleNavigation,
    currentIndex,
    moduleQuestionsLength,
    currentModuleQuestionNumber,
    currentTopic,
    userSelections,
    topics,
    onboardingPages,
    completionPoints,
    type,
    exitScreen,
    healthAssessmentIsEditing,
    introScreen,
    urlToRedirectOnExit,
    setCurrentIndex,
    setIsResuming,
    completeQuestion,
    isLoadingCompleteQuestion,
    setCurrentUserSelections,
    pointsEnabled,
  } = useHealthAssessment();
  const { moduleId } = useParams();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [showExit, setShowExit] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [showFrictionScreen, setShowFrictionScreen] = useState(false);
  const [activeOnboardingPageIndex, setActiveOnboardingPageIndex] = useState(0);
  const [hasAnsweredAtLeastOneQuestion, setHasAnsweredAtLeastOneQuestion] =
    useState(false);
  const completeOnboarding = useCompleteOnboarding(moduleId);
  const { frictionScreen } = exitScreen || {};

  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const formattedUrlToRedirectOnExit = getSanitizedURL(urlToRedirectOnExit);
  const redirectMemberFromExitScreen = () => {
    // "Assessment Lite" is the original Health Assessment,
    // also known as Health Profile Builder
    const isAssessmentLiteAndMemberAnsweredQuestion =
      type === HEALTH_ASSESSMENT_TYPE && hasAnsweredAtLeastOneQuestion;

    const isNotAssessmentLite = type !== HEALTH_ASSESSMENT_TYPE;
    if (
      frictionScreen &&
      (isAssessmentLiteAndMemberAnsweredQuestion || isNotAssessmentLite)
    ) {
      setShowExit(false);
      setShowFrictionScreen(true);
      setTimeout(() => {
        history.push(formattedUrlToRedirectOnExit);
      }, 2000);
    } else {
      history.push(formattedUrlToRedirectOnExit);
    }
  };

  const exitAssessment = useExitAssessment(
    moduleId,
    redirectMemberFromExitScreen,
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    // TODO: look into providing more dynamic route based on assessment entry point
    return <FullPageError route={HOME_URL} />;
  }

  const hasOnboarding = onboardingPages.length > 0 && !onboardingCompleted;

  const shouldDisplayPoints = pointsEnabled && !healthAssessmentIsEditing;
  const completionPointsToDisplay = shouldDisplayPoints ? completionPoints : 0;

  const showNavigator = !hasOnboarding;
  const showTopBar = !hasOnboarding && !showExit;

  const handleCloseButton = () => {
    const {
      id: questionId,
      text,
      type: screenType,
    } = moduleNavigation[currentIndex ?? 0];
    const { name: topicName } = currentTopic;

    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
      screen_name: getAnalyticsScreenName(hasOnboarding, screenType),
      detail: 'exit',
      assessment_id: moduleId,
      assessment_type: type,
      current_step:
        screenType === 'question' ? currentModuleQuestionNumber : null,
      total_steps: screenType === 'question' ? moduleQuestionsLength : null,
      questionnaire_id: questionId ?? null,
      questionnaire_title: text ?? null,
      assessment_questionnaire_category:
        screenType === 'welcome' || type === PULSE_CHECK_TYPE
          ? null
          : topicName?.toLowerCase(),
    });

    if (exitScreen) {
      setShowExit(true);
    } else {
      history.push(formattedUrlToRedirectOnExit);
    }
  };

  const onConfirmExit = () => {
    // non-blocking call to a specific service
    if (type === HEALTH_ASSESSMENT_TYPE) {
      exitAssessment.mutate();
      return;
    }
    redirectMemberFromExitScreen();
  };

  return !isLoading && moduleNavigation.length > 0 ? (
    <HealthAssessmentBackground width={1} flexDirection="column" bg="white">
      {!showFrictionScreen && !showExit && (
        <>
          <Button
            onClick={handleCloseButton}
            priority="secondary"
            quiet
            hideLabel
            icon="tinyClose"
            size="small"
            css={{
              alignSelf: 'end',
              margin: isMobile ? '$threeQuarters' : '$two',
            }}
          >
            {formatMessage({ id: 'CLOSE' })}
          </Button>
          {showTopBar && (
            <TopNav alignItems="center" justifyContent="space-around">
              <ProgressBar
                topics={topics}
                currentIndex={currentIndex}
                isResuming={isResuming}
                completionPoints={completionPointsToDisplay}
                moduleNavigation={moduleNavigation}
              />
            </TopNav>
          )}
          <ContentContainer flexDirection="column" width="100%">
            {hasOnboarding && (
              <Onboarding
                type={type}
                pages={onboardingPages}
                moduleId={moduleId}
                completeOnboarding={() => {
                  setOnboardingCompleted(true);
                  completeOnboarding.mutate();
                }}
                activePageIndex={activeOnboardingPageIndex}
                setActivePageIndex={setActiveOnboardingPageIndex}
                healthAssessmentIsEditing={healthAssessmentIsEditing}
                completionPoints={completionPointsToDisplay}
              />
            )}
            {showNavigator && (
              <ModuleNavigator
                moduleNavigation={moduleNavigation}
                currentTopic={currentTopic}
                currentIndex={currentIndex}
                moduleQuestionsLength={moduleQuestionsLength}
                currentModuleQuestionNumber={currentModuleQuestionNumber}
                setCurrentIndex={setCurrentIndex}
                moduleId={moduleId}
                completeQuestion={completeQuestion}
                userSelections={userSelections}
                topics={topics}
                moduleType={type}
                setHasAnsweredAtLeastOneQuestion={
                  setHasAnsweredAtLeastOneQuestion
                }
                healthAssessmentIsEditing={healthAssessmentIsEditing}
                introScreen={introScreen}
                setCurrentUserSelections={setCurrentUserSelections}
                setIsResuming={setIsResuming}
                isLoadingCompleteQuestion={isLoadingCompleteQuestion}
              />
            )}
          </ContentContainer>
        </>
      )}
      {showExit && (
        <AssessmentExitModal
          exitScreen={exitScreen}
          setShowExit={setShowExit}
          completionPoints={completionPointsToDisplay}
          ctaHandler={onConfirmExit}
          ctaAnalytics={() =>
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
              screen_name: SCREEN_NAME.ASSESSMENT_EXIT_CONFIRMATION,
              detail: 'save progress and exit',
              assessment_id: moduleId,
              assessment_type: type,
            })
          }
          secondaryAnalytics={() =>
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
              screen_name: SCREEN_NAME.ASSESSMENT_EXIT_CONFIRMATION,
              detail: 'keep going',
              assessment_id: moduleId,
              assessment_type: type,
            })
          }
          sendPageAnalytics={() => {
            sendAnalyticsPageView({
              product_area: PRODUCT_AREA.HEALTH_ASSESSMENTS,
              screen_name: SCREEN_NAME.ASSESSMENT_EXIT_CONFIRMATION,
              assessment_id: moduleId,
              assessment_type: type,
            });
          }}
        />
      )}
      {showFrictionScreen && (
        <Transition timeout={250} in appear unmountOnExit>
          {(state) => (
            <Fade state={state}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
              <FrictionScreen marginTop={150} {...frictionScreen} />
            </Fade>
          )}
        </Transition>
      )}
    </HealthAssessmentBackground>
  ) : (
    <LoadingIndicator />
  );
};
