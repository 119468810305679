import { leagueFetch } from '@leagueplatform/league-fetch';
import { MASONRY_NODE_DISMISS_ENDPOINT } from '../constants/masonry-node-api';

export const deleteMasonryNodeDismiss = async (nodeIds: string[]) => {
  const response = await leagueFetch(MASONRY_NODE_DISMISS_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        type: 'un-dismissed',
        attributes: {
          nodeIds,
        },
      },
    }),
  });

  if (response.ok) {
    return true;
  }

  throw new Error(`Masonry Undismiss Action Error:${response.status}`);
};
