import { GDSHeadingSize } from '@leagueplatform/genesis-core';
import { GenesisFontSizeTokens } from '@leagueplatform/masonry-api';

type HeadingSizeMap = {
  [key in GenesisFontSizeTokens]: GDSHeadingSize;
};

export const headingSizeMap: HeadingSizeMap = {
  'xx-large': 'xxl',
  'x-large': 'xl',
  large: 'lg',
  medium: 'md',
  small: 'sm',
  'x-small': 'xs',
};

export const mapHeadingSizeToGenesisTokens = (
  headingSize?: GenesisFontSizeTokens | null,
) => {
  if (!headingSize || !(headingSize in headingSizeMap)) {
    return undefined;
  }

  return headingSizeMap[headingSize as GenesisFontSizeTokens];
};
