import { leagueFetch } from '@leagueplatform/league-fetch';
import { THREAD_MESSAGES_PATH } from './constants/messaging-requests.constants';
import { NewMessageData } from './types/messages-for-thread.types';
import { MessageResponse } from './types/messaging.types';

const getMessageFormDataValue = (newMessageData: NewMessageData) => {
  const messageFormData = new FormData();
  const { text = '', document } = newMessageData;

  const formattedMessageData = JSON.stringify({
    data: {
      type: 'message',
      attributes: {
        text,
      },
    },
  });

  messageFormData.append('data', formattedMessageData);
  if (document && document.length) {
    document.forEach((file: File) => messageFormData.append('document', file));
  }
  return messageFormData;
};

export const postCreateMessageInThread = async (
  newMessageData: NewMessageData,
  threadId: string,
): Promise<MessageResponse> => {
  const messageFormData = getMessageFormDataValue(newMessageData);
  const response = await leagueFetch(`${THREAD_MESSAGES_PATH}/${threadId}`, {
    method: 'POST',
    body: messageFormData,
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error(
    'Something went wrong while posting a new message to thread!',
  );
};
