import { useQuery } from 'react-query';

import {
  getHealthProgramSuggestedCarousels,
  GET_HEALTH_PROGRAM_SUGGESTED_CAROUSELS_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';

export const useHealthProgramSuggestedCarousels = () =>
  useQuery(
    [GET_HEALTH_PROGRAM_SUGGESTED_CAROUSELS_MESSAGE_TYPE],
    getHealthProgramSuggestedCarousels,
  );
