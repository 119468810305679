export const sanitizeQuestions = (questions) =>
  questions.map((question) => {
    // Default type is input
    let questionType = 'input';
    if (question.multipleChoiceAnswers) {
      // If there are multiple choice answers, it's a MC question
      questionType = 'multiple_choice';
    } else if (question.multipleSelectionAnswers) {
      // If there are multiple selection answers, it's a MS question
      questionType = 'multiple_selection';
    } else if (question.dropDownAnswers) {
      // If there are drop-down answers, it's a drop-down question
      questionType = 'drop_down';
    }

    // Create one answers array to loop over instead of having 2
    // arrays and one object (input answer)
    const formattedAnswers = [
      question.multipleChoiceAnswers,
      question.multipleSelectionAnswers,
      question.dropDownAnswers,
      question.inputAnswer,
    ]
      .filter((questionArr) => !!questionArr)
      .flat();

    return {
      ...question,
      answers: formattedAnswers,
      questionType,
    };
  });
