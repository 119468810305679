import React from 'react';
import {
  Flex,
  BodyOne,
  Box,
  Image,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { HealthProgramsProgramDetailsModal } from './health-programs-program-details-modal.component';

interface ConnectDevicesModalProps {
  img: string;
  altTxt: string;
  heading: string;
  body: React.ReactNode;
  onClose: () => void;
  children: React.ReactNode;
}

const IMAGE_SIZE = '120px';

export const ConnectDevicesModal = ({
  img,
  altTxt,
  heading,
  body,
  children,
  onClose,
}: ConnectDevicesModalProps) => (
  <HealthProgramsProgramDetailsModal
    onClose={onClose}
    aria-labelledby="modal-label"
  >
    <Box textAlign="center">
      <Box margin="two">
        <Image width={IMAGE_SIZE} src={img} alt={altTxt} />
      </Box>
      <HeadingThree
        as="h2"
        id="modal-label"
        marginTop="half"
        marginBottom="threeQuarters"
      >
        {heading}
      </HeadingThree>
      <BodyOne color="onSurface.text.subdued" marginBottom="two">
        {body}
      </BodyOne>
      <Flex flexDirection="column">{children}</Flex>
    </Box>
  </HealthProgramsProgramDetailsModal>
);
