import { UserHealthActivityStatus } from '@leagueplatform/health-journey-common';
import { ErrorContext, captureError } from '@leagueplatform/observability';
import { HealthActivityDeserializedData } from '../types/health-activity-response-data.type';

export const errorTitle = 'Corrupt Activity';

export const errorMessages = {
  status: `Activity status must be one of ${Object.values(
    UserHealthActivityStatus,
  ).join(', ')}`,
};

export const handleCorruptActivity = (
  activity: HealthActivityDeserializedData,
) => {
  const activityDataObj = Array.isArray(activity) ? activity?.[0] : activity;
  const {
    status,
    id: userActivityId,
    activityId,
    type: activityType,
    userHealthCampaign,
  } = activityDataObj;
  const context: ErrorContext = {
    tags: { ownershipTeam: 'activities' },
    context: {
      activity: {
        userActivityId,
        activityId,
        activityType,
        userHealthCampaign: userHealthCampaign?.data,
      },
    },
  };

  let error = null;
  if (!status) {
    error = new Error(errorMessages.status);
  }

  if (error) {
    error.name = errorTitle;
    captureError(error, context);
    throw error;
  }
};
