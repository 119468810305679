import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Image = ({
  backgroundPosition,
  backgroundSize,
  className,
  cover,
  fit,
  height,
  url,
  width,
  ...rest
}) => {
  const imageWidth = typeof width === 'number' ? `${width}px` : width || null;
  const imageHeight =
    typeof height === 'number' ? `${height}px` : height || null;

  const StyledImage = styled.div`
    background-repeat: no-repeat;
    background-size: ${cover ? 'cover' : backgroundSize};
    background-image: ${url ? `url(${url})` : 'none'};
    background-position: ${backgroundPosition};
    ${fit ? 'width: 100%; height: 100%;' : ''} ${imageWidth !== null
      ? `width: ${imageWidth};`
      : ''} ${imageHeight !== null ? `height: ${imageHeight};` : ''};
  `;

  return <StyledImage className={`image ${className}`} {...rest} />;
};

Image.defaultProps = {
  url: null,
  fit: false,
  width: null,
  height: null,
  cover: false,
  className: '',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  children: null,
};

Image.propTypes = {
  /** The url for the image */
  url: PropTypes.string,
  fit: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cover: PropTypes.bool,
  className: PropTypes.string,
  /** The background-position property for the image */
  backgroundPosition: PropTypes.string,
  /** The background-size property for the image */
  backgroundSize: PropTypes.string,
  /** Inner content of the image */
  children: PropTypes.node,
};
