import { useQuery } from 'react-query';
import {
  getAssessmentOutcome,
  GET_ASSESSMENT_OUTCOME_MESSAGE_TYPE,
} from '@leagueplatform/assessment-api';
import { AssessmentOutcome } from '../../types/assessment-outcome';

export function useAssessmentOutcomeQuery(id: string) {
  return useQuery<AssessmentOutcome, Error>(
    [GET_ASSESSMENT_OUTCOME_MESSAGE_TYPE, id],
    () => getAssessmentOutcome(id),
  );
}
