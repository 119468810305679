import React, { useMemo } from 'react';
import type { WidgetLink, WidgetType } from '@leagueplatform/masonry-api';
import { useGetTraitsForMedia } from '@leagueplatform/masonry-widgets';
import { AllWidgetProps } from 'types/widget-props.types';
import { mergeDefaultAndCustomWidgetData } from 'utils/merge-default-and-custom-widget-data';
import { widgetAnalyticsEvents } from 'utils/widget-analytics-events';
import { DefaultWidgetData } from '../../types/default-widget-data.types';
import { MasonryConfig } from '../../types/config.types';

export const DefaultWidget = ({
  data,
  config,
  component: WidgetComponent,
  isChildItem = false,
  onCardClick,
}: {
  data: DefaultWidgetData;
  config: MasonryConfig;
  component: React.ElementType;
  isChildItem?: boolean;
  /* onCardClick is only received if this widget is a children of a complex widget like stack, carousel. */
  onCardClick?: () => void;
}) => {
  const { type: widgetType, product_identifier: productIdentifier } = data;
  const traits = useGetTraitsForMedia(data.traits);
  const widgetAttributesAndTraits = mergeDefaultAndCustomWidgetData(data);

  /* Do not call widgetAnalyticsEvents if we receive onCardClick from the parent */
  const widgetAnalyticsProps = useMemo(() => {
    if (!onCardClick) {
      return widgetAnalyticsEvents(
        config,
        widgetType as WidgetType,
        widgetAttributesAndTraits as AllWidgetProps,
        productIdentifier,
      );
    }
    return {};
  }, [
    onCardClick,
    config,
    widgetType,
    widgetAttributesAndTraits,
    productIdentifier,
  ]);

  // Check for dismiss link inside links array to show and hide dismiss button in the widget
  const dismissibleLink = useMemo(
    () => data.links?.find((link: WidgetLink) => link.rel === 'dismiss'),
    [data.links],
  );

  return (
    <WidgetComponent
      onCardClick={onCardClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...widgetAttributesAndTraits}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...traits}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...widgetAnalyticsProps}
      dismissibleLink={dismissibleLink}
      isChildItem={isChildItem}
      product_identifier={productIdentifier}
    />
  );
};
