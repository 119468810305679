/**
 * `event.key` key names for React Synthetic events / w3c standard keyboard events
 *
 * For w3c key names see https://www.w3.org/TR/uievents-key/#named-key-attribute-values
 * Or event.key: https://keycode.info/
 *
 * Please use `event.key` as `event.keyCode` and `event.charCode` are deprecated by the w3c! https://www.w3.org/TR/uievents/#idl-keyboardeventinit
 *
 * React synthetic events paper over browser inconsistencies, so don't worry about IE vs other browser key names
 * https://github.com/facebook/react/blob/cae635054e17a6f107a39d328649137b83f25972/packages/react-dom/src/events/SyntheticEvent.js#L302-L305
 */
export const KEY = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  END: 'End',
  ENTER: 'Enter',
  ESC: 'Escape',
  HOME: 'Home',
  SPACE: ' ',
  TAB: 'Tab',
};
