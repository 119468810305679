import * as React from 'react';
import { AxisLeft as VisxAxisLeft } from '@visx/axis';
import { useTheme } from '@leagueplatform/genesis-commons';

export type AxisLeftProps = Omit<
  React.ComponentProps<typeof VisxAxisLeft>,
  'strokeDasharray' | 'stroke' | 'strokeWidth' | 'tickLabelProps'
>;

/**
 * Wraps the visx AxisLeft component and applies genesis styles.
 */
export function AxisLeft(props: AxisLeftProps) {
  const theme = useTheme();

  return (
    <VisxAxisLeft
      tickStroke={theme.colors.onSurface.border.subdued}
      stroke={theme.colors.onSurface.border.default}
      strokeWidth={theme.borderWidths.thick}
      labelProps={{
        fill: theme.colors.onSurface.text.primary,
        fontSize: theme.fontSizes.heading3,
        textAnchor: 'middle',
        fontWeight: 'bold',
      }}
      tickLabelProps={() => ({
        fontSize: theme.fontSizes.heading2,
        fill: theme.colors.onSurface.text.subdued,
        textAnchor: 'end',
        dy: 6,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
