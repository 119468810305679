import * as React from 'react';
import {
  InsightCardHorizontalBarGraphV2,
  HorizontalBarGraphItem,
} from '@leagueplatform/dashboard';

type HorizontalBarGraphInsightResponse = {
  description: string;
  items: HorizontalBarGraphItem[];
};
export function HorizontalBarGraphNodeRenderer({
  description,
  items,
}: HorizontalBarGraphInsightResponse) {
  return (
    <InsightCardHorizontalBarGraphV2
      title={description}
      description={description}
      items={items}
    />
  );
}
