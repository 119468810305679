const breakpointsMap = {
  phone: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
};

export type BreakPoints = keyof typeof breakpointsMap | '_';

const px = (breakpoint) => `${breakpoint}px`;

export type ThemeBreakpoints = string[] & typeof breakpointsMap;

export const breakpoints = <ThemeBreakpoints>Object.values(breakpointsMap)
  .sort((a, b) => a - b)
  .map(px);

Object.keys(breakpointsMap).forEach((key) => {
  breakpoints[key] = px(breakpointsMap[key]);
});
