import React, { forwardRef } from 'react';
import { VerticalStackLayout } from './vertical-stack-layout/vertical-stack-layout';
import { HorizontalStackLayout } from './horizontal-stack-layout/horizontal-stack-layout';
import type {
  GDSStackLayoutProps,
  GDSVerticalStackLayoutProps,
  GDSHorizontalStackLayoutProps,
} from './stack-layout.types';
import type { GDSOrientationValues } from '../../types';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export const StackLayout = forwardRef<HTMLDivElement, GDSStackLayoutProps>(
  ({ orientation = 'vertical', ...props }, ref) => {
    const responsiveOrientation =
      useResponsiveProp<GDSOrientationValues>(orientation);

    return responsiveOrientation === 'horizontal' ? (
      <GenesisCoreInspector displayName="Horizontal StackLayout">
        <HorizontalStackLayout
          ref={ref}
          {...(props as GDSHorizontalStackLayoutProps)}
        />
      </GenesisCoreInspector>
    ) : (
      <GenesisCoreInspector displayName="Vertical StackLayout">
        <VerticalStackLayout
          ref={ref}
          {...(props as GDSVerticalStackLayoutProps)}
        />
      </GenesisCoreInspector>
    );
  },
);

StackLayout.displayName = 'StackLayout';
