import {
  genesisStyled,
  Box,
  StyleProps,
} from '@leagueplatform/genesis-commons';

/* We have to override a ton of styles that are returned inline in the card body section content as those styles are intended for mobile webview */

export const CardBodyBox = genesisStyled(Box)<StyleProps>`
  background-color: ${({ theme }) => theme.colors.surface.background.primary};
  color: ${({ theme }) => theme.colors.onSurface.text.primary};
  .benefit-coverage-container {
    color: ${({ theme }) => theme.colors.onSurface.text.primary};
    background-color: ${({ theme }) => theme.colors.surface.background.primary};
    font-family: ${({ theme }) => theme.fonts.body};
    h1,
    h2,
    h3,
    h4 {
      font-family: ${({ theme }) => theme.fonts.heading};
      font-weight: ${({ theme }) => theme.fontWeights.medium}
    }
    article,
    section {
      box-shadow: none;
    }

    article {
      margin: ${({ theme }) => theme.space.half}px 0;
      padding: 0;
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSizes.heading2}px;
      margin-top: ${({ theme }) => theme.space.oneAndHalf}px;
    }

    h3 {
      font-size: ${({ theme }) => theme.fontSizes.heading3}px;
    }

    p {
      border-bottom: none;
      line-height: ${({ theme }) => theme.lineHeights.body1}px;
    }

    h3 + p {
      padding-top: 0;
    }

    h6 {
      font-size: ${({ theme }) => theme.fontSizes.heading2}px;
      margin-top: ${({ theme }) => theme.space.oneAndHalf}px;
      &:first-of-type {
        margin-top: ${({ theme }) => theme.space.oneAndHalf}px;
      }
    }

    dd,
    a {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }

    dt {
      border-bottom: none;
      margin-bottom: ${({ theme }) => theme.space.one}px;
    }

    pre {
      line-height: 1.4em;
      padding-left: ${({ theme }) => theme.space.oneAndHalf}px;
    }

    strong a {
      font-weight:  ${({ theme }) => theme.fontWeights.semibold};
    }

    .note {
      font-weight: normal;
      padding-top: ${({ theme }) => theme.space.half}px;
    }

    sup {
      font-size: ${({ theme }) => theme.fontSizes.caption}px;
    }

    table {
      border-collapse: collapse;
      margin: ${({ theme }) => theme.space.one}px 0;
      width: 100%;
    }

    table,
    td,
    th {
      border: ${({ theme }) => theme.borderWidths.thin}px solid ${({ theme }) =>
  theme.colors.onSurface.border.subdued};
    }

    th {
      background-color: ${({ theme }) => theme.colors.onSurface.border.subdued};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }

    th,
    td {
      padding: ${({ theme }) => theme.space.half}px;
      text-align: center;
    }

    td.subheading {
      text-align: left;
    }

    td.instructions {
      text-align: left;
    }

    table.usage {
      line-height: 1.5em;
    }

    .tabular {
      border-bottom: none;
      margin-left: 0;
      display: flex;
      position: relative;
      &:before {
        order: 1;
        display: block;
        content: '';
        flex-grow: 1;
        height: 20px;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.25) 25%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 9px 2px;
        background-repeat: repeat-x;
      }

      span {
        &.desc {
          flex-grow: 0;
          flex-basis: auto;
          flex-shrink: 1;
          flex-wrap: wrap;
          order: 0;
          padding-right: 0.25em;
          max-width: 60%;
        }
        &.value {
          text-align: left;
          order: 2;
          flex-basis: auto;
          flex-grow: 0;
          padding-left: 0.25em;
          max-width: 60%;
        }
      }
    }

    .account-overview {
      & > div {
        margin: ${({ theme }) => theme.space.three}px 0 ${({ theme }) =>
  theme.space.two}px;
      }

      h3 {
        font-size: ${({ theme }) => theme.fontSizes.heading3}px;
        margin-bottom: ${({ theme }) => theme.space.one}px;
      }

      .tooltip {
        position: relative;
        &:before {
          width: ${({ theme }) => theme.space.one}px;
          height:${({ theme }) => theme.space.one}px;
          font-weight: ${({ theme }) => theme.fontWeights.regular};
          font-size: ${({ theme }) => theme.fontSizes.body2}px;
          line-height: ${({ theme }) => theme.lineHeights.overline}px;
        }
        .tooltip-content {
          background-color: ${({ theme }) =>
            theme.colors.surface.background.primary};
          color: ${({ theme }) => theme.colors.onSurface.text.primary};
          border: ${({ theme }) => theme.borderWidths.thin}px solid ${({
  theme,
}) => theme.colors.onSurface.border.default};
          left: -41px;
          width: 400px;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: ${({ theme }) => theme.space.three}px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
          }

          &:before {
            border-top: ${({ theme }) => theme.space.one}px solid ${({
  theme,
}) => theme.colors.onSurface.border.default};
          }

          &:after {
            content: '';
            border-top: ${({ theme }) => theme.space.one - 1}px solid ${({
  theme,
}) => theme.colors.surface.background.primary};
            border-right: ${({ theme }) =>
              theme.space.one - 2}px solid transparent;
            border-left: ${({ theme }) =>
              theme.space.one - 2}px solid transparent;
          }
        }

        &:hover {
          .tooltip-content {
            transform: translateY(-20px);
          }
        }
      }

      .tabular {
        border-top: ${({ theme }) => theme.borderWidths.thin}px solid ${({
  theme,
}) => theme.colors.onSurface.border.subdued};
        align-items: center;
        padding: ${({ theme }) => theme.space.oneAndHalf}px ${({ theme }) =>
  theme.space.one}px;
        height: 8rem;

        &.total-funds {
          background-color: ${({ theme }) =>
            theme.colors.surface.background.secondary};
        }

        &.total-available-balance {
          border-top-color: ${({ theme }) =>
            theme.colors.decorative.brand.primary.bright};
          border-bottom: ${({ theme }) => theme.borderWidths.thin}px solid ${({
  theme,
}) => theme.colors.decorative.brand.primary.bright};
          background-color: ${({ theme }) =>
            theme.colors.decorative.brand.primary.brightest};
        }

        .desc {
          flex-basis: 70%;
          font-size: ${({ theme }) => theme.fontSizes.body1}px;
        }
        .label {
          font-weight: ${({ theme }) => theme.fontWeights.medium};
        }
        .value {
          .amount {
            font-size: ${({ theme }) => theme.fontSizes.heading4}px;
          }
          .info {
            margin-top: ${({ theme }) => theme.space.quarter}px;
            font-size: ${({ theme }) => theme.fontSizes.body2}px;
          }
        }
        p {
          padding: 0;
          line-height: 1.6;
          font-size: 100%;
        }
      }
    }
  }
`;
