import { useCallback } from 'react';
import {
  getHelpCenterLink,
  GETTING_STARTED_WITH_LEAGUE_LINK,
  WHAT_ARE_CONNECTED_HEALTH_PROGRAMS_LINK,
  WHAT_DEVICES_CAN_CONNECT_LINK,
  HEALTH_PROGRAMS_MANUAL_TRACKING_LINK,
  HEALTH_PROGRAMS_AND_JOURNEY_LEARN_MORE_LINK,
  HelpCenterLink,
} from '@leagueplatform/web-common';
import { useCurrentUserActiveGroup } from './use-current-user-active-group.hook';

const WEARABLES_CAMPAIGN_UTM_SOURCE = 'wearables-campaign';

export const useHelpCenterLinks = (source = WEARABLES_CAMPAIGN_UTM_SOURCE) => {
  const { data: userGroup } = useCurrentUserActiveGroup();

  const generateHelpCenterLink = useCallback(
    (helpCenter: HelpCenterLink) =>
      getHelpCenterLink({ helpCenter, userGroup, source }),
    [source, userGroup],
  );

  return {
    whatDevicesCanConnectLink: generateHelpCenterLink(
      WHAT_DEVICES_CAN_CONNECT_LINK,
    ),
    whatAreConnectedHealthProgramsLink: generateHelpCenterLink(
      WHAT_ARE_CONNECTED_HEALTH_PROGRAMS_LINK,
    ),
    gettingStartedWithLeagueLink: generateHelpCenterLink(
      GETTING_STARTED_WITH_LEAGUE_LINK,
    ),
    healthProgramsManualTrackingLink: generateHelpCenterLink(
      HEALTH_PROGRAMS_MANUAL_TRACKING_LINK,
    ),
    healthProgramsAndJourneyLearnMoreLink: generateHelpCenterLink(
      HEALTH_PROGRAMS_AND_JOURNEY_LEARN_MORE_LINK,
    ),
  };
};
