import React from 'react';
import PropTypes from 'prop-types';
import {
  ChoiceButton,
  styled,
  useMediaQuery,
  queryHelpers,
} from '@leagueplatform/genesis-core';
import { Text, Box } from '@leagueplatform/genesis-commons';

const ChoiceButtonStyle = styled(ChoiceButton, {
  '~ .GDS-choicebutton-label svg': { display: 'none' },
});

export const AnswerPill = ({
  id,
  label,
  checked,
  onChangeHandler,
  name,
  description,
  type,
}) => {
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <Box
      marginBottom={isMobile ? 'oneAndHalf' : 'none'}
      width={isMobile ? '100%' : 'auto'}
    >
      <ChoiceButtonStyle
        id={id}
        name={name}
        inputType={type}
        value={id}
        label={label}
        checked={checked}
        onChange={() => onChangeHandler(id)}
      />
      {description && type === 'radio' && (
        <Text
          id={`${id}-description`}
          aria-hidden
          color={checked ? 'onSurface.text.primary' : 'onSurface.text.subdued'}
          marginLeft={isMobile ? 'half' : 'oneAndHalf'}
          marginTop="half"
          typography={isMobile ? 'caption' : 'bodyOne'}
        >
          {description}
        </Text>
      )}
    </Box>
  );
};

AnswerPill.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
};

AnswerPill.defaultProps = {
  description: '',
  type: 'radio',
};
