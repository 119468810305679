import * as React from 'react';
import {
  Flex,
  genesisStyled,
  css,
  Box,
  Overline,
  Label,
  VisuallyHidden,
  Radio,
} from '@leagueplatform/genesis-commons';
import { Unit } from '@leagueplatform/dashboard-api';
import { Icon } from '@leagueplatform/genesis-core';

const RadioContainer = genesisStyled(Flex)`
  width: 100%;
  border: none;
  label:last-child {
    border-radius: 0 100px 100px 0;
  }
`;

const RadioLabel = genesisStyled(Label)(({ isSelected }) =>
  css({
    backgroundColor: isSelected
      ? 'decorative.brand.primary.pastel'
      : 'surface.background.primary',
    color: isSelected ? 'onSurface.text.primary' : 'onSurface.text.subdued',
    fontWeight: '600',
    borderRadius: '100px 0 0 100px',
    paddingLeft: 'one',
    paddingRight: 'oneAndHalf',
    paddingY: 'half',
    borderColor: isSelected
      ? 'interactive.action.primary'
      : 'onSurface.border.subdued',
    borderStyle: 'solid',
    borderWidth: 'thin',
    width: '100%',
    minHeight: '42px',
    '& > span': {
      position: 'relative',
      paddingX: 'oneAndHalf',
      lineHeight: 'button2',
      fontSize: 'button2',
    },
    '&:hover': {
      backgroundColor: 'decorative.brand.primary.pastel',
      boxShadow: 'focusOutline',
      zIndex: '9',
    },
    '&:focus-within': {
      backgroundColor: 'decorative.brand.primary.pastel',
      boxShadow: 'focusOutline',
      zIndex: '9',
    },
  }),
);

const SelectedIcon = genesisStyled(Icon)(
  css({
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
);

export interface UnitSwitcherProps {
  title: string;
  units: Array<Unit>;
  selectedUnit: string;
  onChange: (value: Unit) => void;
}

export function UnitSwitcher({
  selectedUnit,
  onChange,
  units,
  title,
}: UnitSwitcherProps) {
  return (
    <Box as="fieldset">
      <Overline as="legend" paddingBottom="half" textTransform="uppercase">
        {title}
      </Overline>
      <RadioContainer>
        {units.map((unit) => {
          const isSelected = unit === selectedUnit;
          const radioId = `${title}-${unit}-radio`
            .replace(/\s+/g, '') // this replaces any empty whitespace as the title can have whitespace inside
            .toLowerCase();
          return (
            <RadioLabel
              isSelected={isSelected}
              key={unit}
              htmlFor={radioId}
              display="flex"
              justifyContent="center"
            >
              <span>
                {unit}
                {isSelected && (
                  <SelectedIcon
                    icon="interfaceCheckmark"
                    tint="$interactiveActionPrimary"
                    size="$one"
                  />
                )}
              </span>
              <VisuallyHidden>
                <Radio
                  value={unit}
                  id={radioId}
                  name={`${title}-radio`}
                  checked={isSelected}
                  onChange={() => onChange(unit)}
                />
              </VisuallyHidden>
            </RadioLabel>
          );
        })}
      </RadioContainer>
    </Box>
  );
}
