import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useWalletConfig } from 'wallet.config';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from './queries/use-wallet-detail-query.hook';

export function useWalletDetail() {
  const { userId, benefitId } = useWalletParams();
  const { data: benefitCard, isLoading } = useWalletDetailQuery(
    userId,
    benefitId,
  );

  const derivedData = useMemo(() => {
    if (!benefitCard) {
      return {};
    }

    return {
      cardStartDate: benefitCard.start_date
        ? parseISO(benefitCard.start_date)
        : null,
      cardEndDate: benefitCard.end_date ? parseISO(benefitCard.end_date) : null,
      cardInactiveDate: benefitCard.inactive_date
        ? parseISO(benefitCard.inactive_date)
        : null,
      formattedStartDate: benefitCard.formatted_start_date
        ? benefitCard.formatted_start_date
        : null,
      formattedEndDate: benefitCard.formatted_end_date
        ? benefitCard.formatted_end_date
        : null,
      submitClaimsEndDate: benefitCard.submit_claims_end_date
        ? benefitCard.submit_claims_end_date
        : null,
      useBalanceEndDate: benefitCard.use_balance_end_date
        ? benefitCard.use_balance_end_date
        : null,
    };
  }, [benefitCard]);

  const {
    cardStartDate,
    cardEndDate,
    cardInactiveDate,
    formattedStartDate,
    formattedEndDate,
    submitClaimsEndDate,
    useBalanceEndDate,
  } = derivedData;

  const { showBenefitDetailFeedbackComponent } = useWalletConfig();

  return {
    isLoading,
    benefitId,
    benefitCard,
    cardStartDate,
    cardEndDate,
    cardInactiveDate,
    formattedStartDate,
    formattedEndDate,
    submitClaimsEndDate,
    useBalanceEndDate,
    showBenefitDetailFeedbackComponent,
  };
}
