import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useUrlParam } from '@leagueplatform/web-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  BENEFIT_CARD_ACTION,
  WalletBenefitCard,
  SYSTEM_TYPES,
} from '@leagueplatform/wallet-api';
import { WALLET_API_FEATURE_FLAGS } from 'common/feature-flags';
import { BenefitContentAction } from 'types/benefit-card-action.types';
import { isBenefitTab } from '../utils/is-benefit-tab.util';

// orders tabs based on the WALLET-4954 business requirements
function getTabOrder(tab: BenefitContentAction) {
  switch (tab.system_type) {
    case SYSTEM_TYPES.About: {
      return 0;
    }

    case SYSTEM_TYPES.CardInfo: {
      return 1;
    }

    default: {
      return 2;
    }
  }
}

function compareTabs(a: BenefitContentAction, b: BenefitContentAction) {
  return getTabOrder(a) - getTabOrder(b);
}
// Currently this is the only deeplink we will be supporting
// The BE does not send an action for this, this was the agreed upon string value.
const PLAN_PROGRESS_DEEPLINK_TAB_STRING = 'plan_progress';

export function useSelectBenefitTabs() {
  const { formatMessage } = useIntl();
  const { urlParam: deeplinkTab } = useUrlParam('tab');
  const isPlanProgressDeeplink =
    deeplinkTab === PLAN_PROGRESS_DEEPLINK_TAB_STRING;

  const { data: includeAboutTab } = useFeatureFlagQuery(
    WALLET_API_FEATURE_FLAGS.IS_CLINICAL_PROGRAMS_ENABLED,
  );

  const planProgressTab: BenefitContentAction = useMemo(
    () => ({
      banners: {},
      id: 'PLAN_PROGRESS',
      indicator: false,
      isDefaultSelected: isPlanProgressDeeplink,
      name: formatMessage({ id: 'PLAN_PROGRESS' }),
      system_type: SYSTEM_TYPES.UsageOverview,
      type: BENEFIT_CARD_ACTION.CONTENT,
    }),
    [formatMessage, isPlanProgressDeeplink],
  );

  const planProgressAndCoverageTab: BenefitContentAction = useMemo(
    () => ({
      banners: {},
      id: 'PLAN_PROGRESS_COST_COVERAGE',
      indicator: false,
      isDefaultSelected: isPlanProgressDeeplink,
      name: formatMessage({ id: 'PLAN_PROGRESS_COST_COVERAGE' }),
      system_type: SYSTEM_TYPES.PlanProgressCoverageOverview,
      type: BENEFIT_CARD_ACTION.CONTENT,
    }),
    [formatMessage, isPlanProgressDeeplink],
  );

  const aboutTab: BenefitContentAction = useMemo(
    () => ({
      id: 'ABOUT',
      type: BENEFIT_CARD_ACTION.CONTENT,
      system_type: SYSTEM_TYPES.About,
      name: formatMessage({ id: 'ABOUT' }),
      banners: {},
      indicator: false,
    }),
    [formatMessage],
  );

  return (benefitCard: WalletBenefitCard): BenefitContentAction[] => {
    const hasUserLiveBalances = benefitCard.show_user_benefit_live_balances;
    const isCombinedPlanProgressandCoverage =
      benefitCard.content_configuration?.show_cost_and_coverage;

    let tabs = benefitCard.content_actions?.filter(isBenefitTab) || [];

    if (hasUserLiveBalances && !isCombinedPlanProgressandCoverage) {
      tabs = [planProgressTab, ...tabs];
    }

    if (hasUserLiveBalances && isCombinedPlanProgressandCoverage) {
      tabs = [planProgressAndCoverageTab, ...tabs];
    }

    if (benefitCard.description && includeAboutTab) {
      tabs = [aboutTab, ...tabs];
    }

    tabs.sort(compareTabs);

    return tabs;
  };
}
