import { isFrench, isSpanish, DEFAULT_LOCALE } from '@leagueplatform/locales';

/*
This is bad! Bad!

Replace this in future when webplat has come up with a modularized version of:
apps/league-b2b/src/apps/terms/privacy-url.js

*/
const PRIVACY_POLICY_URL = 'https://league.com/ca/privacy-policy';
const PRIVACY_POLICY_FR_URL = 'https://league.com/ca/privacy-policy-fr';
const PRIVACY_POLICY_ES_URL = 'https://league.com/privacy-policy-es/';

export const getPrivacyPolicyUrl = () => {
  if (isFrench(DEFAULT_LOCALE)) return PRIVACY_POLICY_FR_URL;
  if (isSpanish(DEFAULT_LOCALE)) return PRIVACY_POLICY_ES_URL;
  return PRIVACY_POLICY_URL;
};
