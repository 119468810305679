import { useIntl } from '@leagueplatform/locales';
import { CategorizedActions } from '@leagueplatform/wallet-api';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { filterSaveCardAction } from '../utils/filter-save-card-action.util';

export const useCardDetailQuickActionLinks = () => {
  const { userId, benefitId } = useWalletParams();
  const { formatMessage } = useIntl();

  const { data: sortedActionCategories, isLoading } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: (data) => {
        const nonPrimaryCategoryActions: CategorizedActions[] =
          data.categorized_actions?.map((categoryAction) => ({
            ...categoryAction,
            actions: filterSaveCardAction(categoryAction.actions),
          })) || [];

        const fallbackActions: CategorizedActions[] =
          data.actions !== null
            ? [
                {
                  actions: filterSaveCardAction(data.actions),
                  title: formatMessage({ id: 'ACTIONS' }),
                },
              ]
            : [];

        // If there are no actions return an empty array
        if (
          nonPrimaryCategoryActions.length === 0 &&
          fallbackActions.length === 0
        ) {
          return [];
        }

        // If no category actions return un-categorized actions array
        if (nonPrimaryCategoryActions.length === 0) {
          return fallbackActions;
        }

        return nonPrimaryCategoryActions;
      },
    },
  );

  // Remove any empty categories
  const filteredCategoryActions = sortedActionCategories?.filter(
    (actionCategory) => actionCategory.actions.length,
  );

  return { actionGroups: filteredCategoryActions, isLoading };
};
