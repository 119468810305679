import React from 'react';
import {
  Flex,
  Box,
  Image,
  HeadingFour,
  BodyTwo,
} from '@leagueplatform/genesis-commons';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { EVENT_NAME, PRODUCT_AREA } from '@leagueplatform/analytics';
import { TLP_SCREEN_NAMES } from 'constants/analytics.constants';

export interface TeamsOptionProps {
  image: string;
  heading: string;
  description?: string;
  setChoice(): void;
}

export const TeamsOption = ({
  image,
  heading,
  description,
  setChoice,
}: TeamsOptionProps): JSX.Element => {
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.JOURNEY,
      screen_name: TLP_SCREEN_NAMES.JOIN_OR_CREATE_TEAM,
      detail,
    });
  const handleClick = () => {
    handleAnalyticsClick(heading.toLowerCase());
    setChoice();
  };
  return (
    <Flex
      as="button"
      paddingY="two"
      paddingRight="two"
      paddingLeft="oneAndHalf"
      marginTop="half"
      marginBottom="one"
      borderWidth="thin"
      borderRadius="medium"
      borderColor="onSurface.border.subdued"
      backgroundColor="tertiary.background.default"
      alignItems="center"
      width="100%"
      onClick={handleClick}
    >
      <Image
        src={image}
        alt=""
        size="four"
        aria-hidden
        display={{ _: 'none', phone: 'block' }}
      />
      <Box
        textAlign="left"
        marginLeft={{ phone: 'oneAndHalf' }}
        marginRight={{ _: 'two', phone: 'four', tablet: 'three' }}
      >
        <HeadingFour as="p">{heading}</HeadingFour>
        {description && <BodyTwo marginTop="half">{description}</BodyTwo>}
      </Box>
      <Box transform="rotate(180deg)" marginLeft="auto">
        <ChallengeIcon iconType={ICON_TYPES.CHEVRON} />
      </Box>
    </Flex>
  );
};
