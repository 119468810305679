import * as React from 'react';
import { Flex, FlexProps } from '@leagueplatform/genesis-commons';

type ListItemProps = FlexProps;

export function ListItem(props: ListItemProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth="thin"
      paddingTop="one"
      paddingBottom="one"
      paddingLeft="quarter"
      paddingRight="quarter"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
