import { useMutation, useQueryClient } from 'react-query';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import {
  CHALLENGE_HIGHLIGHTS_PATH,
  patchChallengesPrivacySetting,
} from '@leagueplatform/health-journey-api';
import { CHALLENGE_PRIVACY_SETTING_QUERY_KEY } from './use-get-challenge-privacy-setting.hooks';

export const usePatchPrivacyConfig = (
  showFeedItems: boolean,
  originalShowFeedItems: boolean,
  setShowFeedItems: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { data: challengeData } = useGetChallengeByIdData();

  // using non-null assertion operator here. In order for the privacy settings page to be displayed,
  // we know that a user challenge ID must exist so don't need to do optional checks
  const { id: userChallengeId } = challengeData!.data.userChallenge!.data;
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    () => patchChallengesPrivacySetting(userChallengeId, showFeedItems),

    {
      onSuccess: () => {
        // Invalidate highlights feed to refetch latest feed with updated showFeedItems data
        queryClient.invalidateQueries([CHALLENGE_HIGHLIGHTS_PATH]);
        queryClient.invalidateQueries([CHALLENGE_PRIVACY_SETTING_QUERY_KEY]);
      },
      onError: () => {
        // If error, set liked state back to the original state
        setShowFeedItems(originalShowFeedItems);
      },
    },
  );

  return mutate;
};
