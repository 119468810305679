/* eslint-disable react/jsx-props-no-spreading */
import {
  Router,
  BrowserRouter as ReactRouterDomBrowserRouter,
  MemoryRouter,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import React from 'react';
import type { ComponentType, ComponentProps } from 'react';

/**
 * The same object returned by the `createBrowserHistory` function from the `history` package.
 */
export const history =
  typeof window !== 'undefined' && typeof document !== 'undefined'
    ? createBrowserHistory()
    : undefined;

type BrowserRouterComponent = ComponentType<
  ComponentProps<typeof ReactRouterDomBrowserRouter>
>;

/**
 * A component identical to `BrowserRouter` from `react-router-dom`, but which
 * uses the specific `history` instance exported by this module rather than internally creating one
 * of its own.
 */
export const BrowserRouter: BrowserRouterComponent = (props) =>
  history ? (
    <Router history={history} {...props} />
  ) : (
    <MemoryRouter {...props} />
  );
