import { isExternalUrl } from './is-external-url';

export const transformAnchorTag = (node: Element) => {
  if (node.nodeName !== 'A' || !node) {
    return;
  }

  const isExternalLink = isExternalUrl(node.getAttribute('href'));
  const isMailLink = node.getAttribute('href')?.includes('mailto:') ?? false;
  const isPhoneLink = node.getAttribute('href')?.includes('tel:') ?? false;
  const isInternalLink = !isExternalLink && !isMailLink && !isPhoneLink;

  if (isInternalLink) {
    const url = node.getAttribute('href')?.replace(/^.*\/\/[^/]+/, '') || '';
    node.setAttribute('href', url);
  } else if (isExternalLink) {
    node.setAttribute('rel', 'noopener noreferrer');
  }
};
