const paymentTypeMap = {
  psa_total: 'LSA',
  hsa_total: 'HSA',
  amount_owing: 'CREDIT_CARD',
  personal_promo_code_total: 'LEAGUE_CREDIT',
  charity: 'CGA',
  psa: 'LSA',
  lsa: 'LSA',
  hsa: 'HSA',
  hra: 'HRA',
};

const accountTotalTypes = [
  'psa_total',
  'hsa_total',
  'charity',
  'psa',
  'hsa',
  'personal_promo_code_total',
  'amount_owing',
];

export const getPaymentDataFromPricingDetails = (details, accountType) => {
  /* old benefits are stored ex: details.psaTotal: 29.99, details.hsaTotal: 0 and legacy data may only be returned in this format */
  const typesUsed = Object.keys(details).filter(
    (type) => accountTotalTypes.includes(type) && details[type] > 0,
  );
  /* New transactions will always specify benefits used in the benefits array */
  const benefitsUsed = (details.benefits || [])
    .filter((benefit) => accountTotalTypes.includes(benefit.payment_type))
    .map((benefit) => benefit.payment_type);

  /* create an array of unique payment types and map to translation ID */
  const accountsUsed = new Set(
    [...typesUsed, ...benefitsUsed].map((account) => paymentTypeMap[account]),
  );

  /* check benefits array first as if pricing details are there, we're good. Otherwise access via predefined properties. Only get the amount associated with the current account */
  // When a transaction was covered by a benefit (e.g. LSA) + credit card or league credit, check for account type and more to the switch statement for non-benefit accounts
  const isBenefitAccount =
    accountType !== 'promo_code' && accountType !== 'credit_card';
  let amount;
  if (
    Array.isArray(details.benefits) &&
    details.benefits.length > 0 &&
    isBenefitAccount
  ) {
    const benefitAccount = details.benefits.find(
      (benefit) => benefit.payment_type === accountType,
    );
    amount = benefitAccount ? benefitAccount.amount : null;
  } else {
    switch (accountType) {
      case 'psa':
        amount = details.psa_total;
        break;
      case 'hsa':
        amount = details.hsa_total;
        break;
      case 'league_credit':
      case 'promo_code':
        amount = details.personal_promo_code_total;
        break;
      case 'credit_card':
        amount = details.amount_owing;
        break;
      default:
        break;
    }
  }

  return {
    amount,
    accountsUsed: accountsUsed.size > 1 ? [...accountsUsed] : null,
  };
};
