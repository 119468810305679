{
  "dragDescriptionKeyboard": "Pressione Enter para começar a arrastar.",
  "dragDescriptionKeyboardAlt": "Pressione Alt + Enter para começar a arrastar.",
  "dragDescriptionLongPress": "Pressione e segure para começar a arrastar.",
  "dragDescriptionTouch": "Toque duas vezes para começar a arrastar.",
  "dragDescriptionVirtual": "Clique para começar a arrastar.",
  "dragItem": "Arrastar {itemText}",
  "dragSelectedItems": "Arrastar {count, plural, one {# item selecionado} other {# itens selecionados}}",
  "dragSelectedKeyboard": "Pressione Enter para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragSelectedKeyboardAlt": "Pressione Alt + Enter para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragSelectedLongPress": "Pressione e segure para arrastar {count, plural, one {# o item selecionado} other {# os itens selecionados}}.",
  "dragStartedKeyboard": "Comece a arrastar. Pressione Tab para navegar até um alvo e, em seguida, pressione Enter para soltar ou pressione Escape para cancelar.",
  "dragStartedTouch": "Comece a arrastar. Navegue até um alvo e toque duas vezes para soltar.",
  "dragStartedVirtual": "Comece a arrastar. Navegue até um alvo e clique ou pressione Enter para soltar.",
  "dropCanceled": "Liberação cancelada.",
  "dropComplete": "Liberação concluída.",
  "dropDescriptionKeyboard": "Pressione Enter para soltar. Pressione Escape para cancelar.",
  "dropDescriptionTouch": "Toque duas vezes para soltar.",
  "dropDescriptionVirtual": "Clique para soltar.",
  "dropIndicator": "indicador de liberação",
  "dropOnItem": "Soltar em {itemText}",
  "dropOnRoot": "Soltar",
  "endDragKeyboard": "Arrastando. Pressione Enter para cancelar.",
  "endDragTouch": "Arrastando. Toque duas vezes para cancelar.",
  "endDragVirtual": "Arrastando. Clique para cancelar.",
  "insertAfter": "Inserir após {itemText}",
  "insertBefore": "Inserir antes de {itemText}",
  "insertBetween": "Inserir entre {beforeItemText} e {afterItemText}"
}
