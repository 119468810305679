/* eslint-disable react/self-closing-comp */
import React, { SVGProps } from 'react';
import {
  BackgroundColorSystemProp,
  BorderColorSystemProp,
  css,
  Flex,
  genesisStyled,
  VisuallyHidden,
  Image,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import uniqueId from 'lodash/uniqueId';

export interface ProgressRingProps {
  total: number;
  current: number;
  screenReaderText?: string;
  customRingFillColor?: BackgroundColorSystemProp | BorderColorSystemProp;
  customRingBackgroundColor?: BackgroundColorSystemProp | BorderColorSystemProp;
  image?: string;
  imageAltText?: string;
  size?: number;
  strokeWidth?: number;
}

const ProgressRingWrapper = genesisStyled('svg')(({ width, height }) =>
  css({
    width,
    height,
    transform: 'rotate(-90deg)',
  }),
);

interface ProgressProps extends SVGProps<SVGElement> {
  reducedMotion: boolean;
  dash: number;
  circumference: number;
}

const Progress = genesisStyled('circle')<ProgressProps>(
  ({ stroke, reducedMotion, dash, circumference }) =>
    css({
      fill: 'transparent',
      stroke,
      strokeLinecap: 'round',
      transition: reducedMotion ? 'stroke-dasharray 2s linear' : 'none',
      strokeDasharray: `${dash}, ${circumference - dash}`,
      animation: reducedMotion ? 'none' : 'dashArray 1s ease-in-out forwards',
      '@keyframes dashArray': {
        from: {
          strokeDasharray: `0, ${circumference}`,
        },
      },
    }),
);

const Track = genesisStyled('circle')(({ stroke }) =>
  css({
    fill: 'transparent',
    stroke,
    strokeDasharray: 0,
  }),
);

const CenteredImageWrapper = genesisStyled(Flex)(
  css({
    borderRadius: 'circle',
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const ProgressRing = ({
  customRingFillColor = 'primary.background.default',
  customRingBackgroundColor = 'interactive.background.disabled',
  current,
  total,
  screenReaderText = `${Number((current / total) * 100)}%`,
  image,
  size = 82,
  strokeWidth = 12,
  imageAltText,
}: ProgressRingProps) => {
  const { space } = useTheme();
  const reducedMotion = usePrefersReducedMotion();

  const currentAsPercentage = Number((current / total) * 100);

  const radius = (size - strokeWidth) / 2;
  const circumference = Math.PI * radius * 2;
  const dash = (currentAsPercentage * circumference) / 100;

  const id = uniqueId('progress-ring');

  return (
    <div>
      <VisuallyHidden>
        <label htmlFor={id}>{screenReaderText}</label>
        <progress id={id} max={total} value={current} />
      </VisuallyHidden>
      <Flex alignItems="center" justifyContent="center" position="relative">
        <ProgressRingWrapper width={size} height={size}>
          <Track
            stroke={customRingBackgroundColor}
            strokeWidth={`${strokeWidth}px`}
            cx={size / 2}
            cy={size / 2}
            r={radius}
          ></Track>

          <Progress
            stroke={customRingFillColor}
            strokeWidth={`${strokeWidth}px`}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            dash={dash}
            circumference={circumference}
            reducedMotion={reducedMotion}
          ></Progress>
        </ProgressRingWrapper>

        {image && (
          <CenteredImageWrapper
            // We want the centered image to have "half" margin around it
            width={size - (strokeWidth + space.half) * 2}
            height={size - (strokeWidth + space.half) * 2}
          >
            <Image src={image} alt={imageAltText} />
          </CenteredImageWrapper>
        )}
      </Flex>
    </div>
  );
};
