import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import type { WidgetDataV2 } from 'types/widget-data-v2';
import { getWidgetContainer } from 'utils/get-widget-container';
import { getWidgetData } from 'utils/get-widget-data';
import { normalizeParams } from 'utils/normalize-paramters';
import { getAnalyticsEventsForMasonryWSDriver } from 'utils/analytics-events-for-masonry-WS-driver';
import { formatObjectKeysToCamelCase } from 'utils/format-object-keys-to-camel-case';
import { Orientation } from 'types/widget-data';
import { WidgetLink } from 'types/container-data';
import { MasonryWSDriverConfig } from 'types/masonry-ws-driver-config.types';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { LoaderType } from 'types/loader-types';

/**
 *
 * Function {@link formatCarouselOrStackResponse} to format response of a
 * "stack" or "genericCarousel" into individual {@link MasonryEngineNode}
 */
export const formatCarouselOrStackResponse = (
  widget: WidgetDataV2<any>,
  analyticsProperties: any,
): MasonryEngineNode => {
  const { id, type, attributes, traits } = widget;
  const { onItemClick } = analyticsProperties;
  return {
    id,
    type,
    properties: {
      ...omit(attributes, 'items'),
      traits,
      ...analyticsProperties,
    },
    sections: {
      items: attributes?.items?.map(
        (slot: WidgetDataV2<any>, index: number) => ({
          id: uuidv4(),
          type: slot.type,
          namespace: 'default',
          properties: {
            ...formatObjectKeysToCamelCase(slot.attributes),
            traits: slot.traits,
            // Each child of generic carousel/stack expects onCardClick instead of onItemClick callback event name
            onCardClick: () => onItemClick(widget, index),
          },
        }),
      ),
    },
  };
};

const getDismissibleLink = (links: WidgetDataV2<any>['links']) =>
  links?.find((link: WidgetLink) => link.rel === 'dismiss');

const MASONRY_WS_API_VERSION = 2;

export const getMasonryWSRootNode = ({
  appId,
  productArea,
  screenName,
  apiParams,
}: MasonryWSDriverConfig): MasonryEngineNode => ({
  id: appId,
  type: 'containerSpinner',
  properties: {},
  sections: {},
  namespace: 'default',
  getAsyncSelf: async (): Promise<MasonryEngineNode> => {
    /** Passing apiVersion 2 always for MasonryWSDriver because this driver
     * works with response returned by this apiVersion.
     */
    const containerData = await getWidgetContainer(
      appId,
      MASONRY_WS_API_VERSION,
      apiParams,
    );

    /**
     * As Masonry WS apis have no concept of Node, so convert container response
     * into {@link MasonryEngineNode}
     */
    return {
      id: appId,
      /**
       * As all widgets in a container are shown vertically, thus setting
       * node type of Masonry WS container to a "stack" with orientation trait
       * set to "vertical".
       */
      type: 'stack',
      properties: {
        traits: {
          mobile: {
            orientation: Orientation.VERTICAL,
          },
          tablet: {
            orientation: Orientation.VERTICAL,
          },
          laptop: {
            orientation: Orientation.VERTICAL,
          },
          desktop: {
            orientation: Orientation.VERTICAL,
          },
        },
      },
      namespace: 'default',
      sections: {
        items: containerData.widgets.map((widget: WidgetDataV2<any>) => ({
          id: widget.id,
          type: widget.type || LoaderType.SMALL,
          properties: {},
          sections: {},
          getAsyncSelf: async (): Promise<MasonryEngineNode> => {
            /**
             * This Node Async function is making api call - get_widget_data to get
             * data for individual widget in a container.
             */
            const linkDetails = widget.links.find(({ rel }) => rel === 'data');
            const widgetResponse = await getWidgetData(
              normalizeParams({ params: linkDetails?.params }),
              MASONRY_WS_API_VERSION,
              widget.id,
            );

            /**
             * Since Masonry WS apis have no concept of actions, function - {@link getAnalyticsEventsForMasonryWSDriver}
             * add action handler to each widget type, by reading widget.type for each widget.
             */
            const analyticsProperties = getAnalyticsEventsForMasonryWSDriver(
              widgetResponse.type,
              widgetResponse.attributes,
              appId,
              productArea,
              screenName,
              widgetResponse.product_identifier,
            );

            if (
              widgetResponse.type === 'genericCarousel' ||
              widgetResponse.type === 'stack'
            ) {
              return formatCarouselOrStackResponse(
                widgetResponse,
                analyticsProperties,
              ) as any;
            }

            /**
             * While returning a node, format each widget response into {@link MasonryEngineNode}
             */
            return {
              id: widget.id,
              sections: {},
              namespace: widgetResponse.module,
              type: widgetResponse.type,
              properties: {
                ...(widgetResponse.attributes
                  ? formatObjectKeysToCamelCase(widgetResponse.attributes)
                  : {}),
                traits: widgetResponse.traits,
                dismissibleLink: getDismissibleLink(widgetResponse.links),
                ...analyticsProperties,
              },
            };
          },
        })),
      },
    };
  },
});
