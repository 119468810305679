{
  "dragDescriptionKeyboard": "Pritisnite Enter da biste započeli prevlačenje.",
  "dragDescriptionKeyboardAlt": "Pritisnite Alt + Enter da biste započeli prevlačenje.",
  "dragDescriptionLongPress": "Pritisnite dugo da biste započeli prevlačenje.",
  "dragDescriptionTouch": "Dvaput dodirnite da biste započeli prevlačenje.",
  "dragDescriptionVirtual": "Kliknite da biste započeli prevlačenje.",
  "dragItem": "Prevucite {itemText}",
  "dragSelectedItems": "Prevucite {count, plural, one {# izabranu stavku} other {# izabrane stavke}}",
  "dragSelectedKeyboard": "Pritisnite Enter da biste prevukli {count, plural, one {# izabranu stavku} other {# izabranih stavki}}.",
  "dragSelectedKeyboardAlt": "Pritisnite Alt + Enter da biste prevukli {count, plural, one {# izabranu stavku} other {# izabranih stavki}}.",
  "dragSelectedLongPress": "Pritisnite dugo da biste prevukli {count, plural, one {# izabranu stavku} other {# izabranih stavki}}.",
  "dragStartedKeyboard": "Prevlačenje je započeto. Pritisnite Tab da biste otišli do cilja za otpuštanje, zatim pritisnite Enter za ispuštanje ili pritisnite Escape za otkazivanje.",
  "dragStartedTouch": "Prevlačenje je započeto. Idite do cilja za otpuštanje, a zatim dvaput dodirnite za otpuštanje.",
  "dragStartedVirtual": "Prevlačenje je započeto. Idite do cilja za otpuštanje, a zatim kliknite ili pritinite Enter za otpuštanje.",
  "dropCanceled": "Otpuštanje je otkazano.",
  "dropComplete": "Prevlačenje je završeno.",
  "dropDescriptionKeyboard": "Pritisnite Enter da biste otpustili. Pritisnite Escape da biste otkazali prevlačenje.",
  "dropDescriptionTouch": "Dvaput dodirnite za otpuštanje.",
  "dropDescriptionVirtual": "Kliknite za otpuštanje.",
  "dropIndicator": "Indikator otpuštanja",
  "dropOnItem": "Otpusti na {itemText}",
  "dropOnRoot": "Otpusti na",
  "endDragKeyboard": "Prevlačenje u toku. Pritisnite Enter da biste otkazali prevlačenje.",
  "endDragTouch": "Prevlačenje u toku. Dvaput dodirnite da biste otkazali prevlačenje.",
  "endDragVirtual": "Prevlačenje u toku. Kliknite da biste otkazali prevlačenje.",
  "insertAfter": "Umetnite posle {itemText}",
  "insertBefore": "Umetnite ispred {itemText}",
  "insertBetween": "Umetnite između {beforeItemText} i {afterItemText}"
}
