import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { trueOrUndefined } from '../../utils/trueOrUndefined';
import {
  pickInputProps,
  pickGlobalHTMLProps,
  pickLayoutProps,
  omitLayoutProps,
  pickStyleProps,
} from '../../utils/propFilters';
import { Box, BoxProps } from '../../Box';
import { Flex } from '../../Flex';
import { TextColorSystemProp } from '../../theme';
import { Checkmark } from './Checkmark';
import { InputProps, visuallyHiddenInputStyles } from '../Input';

export interface CheckboxProps extends InputProps, BoxProps {
  checked?: boolean;
  ref?: any;
}

const CheckboxIcon: React.FunctionComponent = (props) => (
  <Flex
    data-testid="genesis-checkbox-input-icon"
    aria-hidden="true"
    justifyContent="center"
    alignItems="center"
    backgroundColor="interactive.icon.default"
    borderColor="currentcolor"
    borderStyle="solid"
    borderWidth="thin"
    borderRadius="small"
    position="relative"
    color={'interactive.border.default' as TextColorSystemProp}
    size="oneAndHalf"
    transitionProperty="all"
    transitionDuration="base"
    css={css({
      'input ~ &, input ~ &:after': {
        transitionDuration: 'base',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(.17, .67, .83, .67)',
      },
      'input ~ &::before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        right: '-2px',
        bottom: '-2px',
        borderRadius: 'small',
      },
      'input ~ & > svg': {
        display: 'none',
      },
      'input ~ &:after': {
        backgroundColor: 'interactive.action.primary',
      },
      'input:checked ~ &, input:not(checked) ~ &:hover': {
        color: 'interactive.action.primary',
      },
      'input:focus ~ &, input:focus:not(checked) ~ &': {
        borderColor: 'currentcolor',
      },
      'input:focus ~ &::before, input:focus:not(checked) ~ &::before': {
        boxShadow: 'focusOutline',
      },
      'input:checked ~ &': {
        backgroundColor: 'currentcolor',
      },
      'input:checked ~ & > svg': {
        display: 'block',
      },
      'input:disabled ~ &, input:checked:disabled ~ &, input:checked:disabled ~ &:after':
        {
          backgroundColor: 'interactive.background.disabled',
          color: 'interactive.border.disabled',
          pointerEvents: 'none',
        },
    })}
    {...props}
  />
);

/**
 * @deprecated  use `Checkbox` from `genesis-core` instead
 */

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    { checked, disabled, defaultChecked, error, ...props },
    ref
  ) {
    const propsForCheckboxInput = {
      ...pickInputProps(props),
      ...pickGlobalHTMLProps(props),
    } as CheckboxProps;

    const propsForInputContainer = {
      ...pickLayoutProps(props),
      // an onClick handler is passed to the Checkbox component in apps/league-b2b/src/apps/employer-experience/pages/EmployerDetails/Payroll/rate-sheet-extract/rate-sheet-extract.view.js
      onClick: props.onContainerClick,
    };

    const styleProps = pickStyleProps(props);
    const propsForCheckboxIcon = omitLayoutProps(styleProps);

    return (
      <Box {...propsForInputContainer}>
        <Box
          data-testid="genesis-checkbox-input"
          as="input"
          ref={ref}
          type="checkbox"
          role="checkbox"
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
          aria-checked={checked}
          aria-invalid={trueOrUndefined(Boolean(error))}
          css={css(visuallyHiddenInputStyles)}
          {...propsForCheckboxInput}
        />
        <CheckboxIcon
          {...(!!error && {
            color: 'interactive.border.critical.default',
          })}
          {...propsForCheckboxIcon}
        >
          <Checkmark disabled={disabled} />
        </CheckboxIcon>
      </Box>
    );
  }
) as React.FunctionComponent<CheckboxProps>;

Checkbox.displayName = 'Checkbox';
