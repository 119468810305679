import React from 'react';
import { Redirect } from '@leagueplatform/routing';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { isValidDeepLinkId as getIsValidDeepLinkId } from 'pages/wallet-detail/utils/is-valid-deep-link-id.util';
import { useGetWalletItemIdQuery } from '../../pages/wallet-detail/hooks/queries/use-get-wallet-item-id.query.hook';

export const DeepLinkWrapper = ({ children }: { children: JSX.Element }) => {
  const { userId, benefitId = '' } = useWalletParams();
  const isValidDeepLinkId = getIsValidDeepLinkId(benefitId);
  const {
    data: deepLinkId,
    isLoading,
    isError,
  } = useGetWalletItemIdQuery(userId, benefitId, {
    select: (deepLinkResponse) => deepLinkResponse.wallet_item_id,
    enabled: isValidDeepLinkId,
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      shouldAutoClose: true,
      contentId: 'UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG',
    });
    return <Redirect to="./" />;
  }

  if (deepLinkId && isValidDeepLinkId) {
    return <Redirect to={`./${deepLinkId}`} />;
  }

  return children;
};
