import * as React from 'react';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import isFuture from 'date-fns/isFuture';
import isToday from 'date-fns/isToday';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  MoreOptionsLink,
} from '@leagueplatform/health-profile-common';
import { useIntl } from '@leagueplatform/locales';
import { Sidebar, SkeletonBox } from '@leagueplatform/web-common-components';
import { trackAnalyticsEvent, EVENT_NAME } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { HealthLiteracyContent } from 'components/health-literacy/health-literacy-content';
import {
  CHART_VIEW,
  CHART_VIEWS,
} from 'components/charts/constants/chart-views';
import { ErrorPanel } from 'components/error-panel';
import { AddEditDataModal } from 'components/add-edit-data/add-edit-data-modal';
import { HealthMetricDeserialisedDataV3 } from '@leagueplatform/dashboard-api';
import { useIsSelfReportedDataEnabled } from 'hooks/use-is-self-reported-data-enabled.hook';
import { getChartViewDate } from 'components/charts/core/utils/get-chart-view-date';
import { useChartData } from './hooks/use-chart-data';
import { AddMetricDataButton } from './components/add-metric-data-button';
import { HealthPrograms } from './components/health-programs';
import { HealthMetricsChart } from './components/health-metrics-chart';
import { NoDataAvailable } from './components/no-data-available';

type QueryStateHandlerProps = {
  children: (data: HealthMetricDeserialisedDataV3) => React.ReactElement;
  query: ReturnType<typeof useChartData>;
};

// Helper component to render different loading and error statues.
export function QueryStateHandler({
  children,
  query,
}: QueryStateHandlerProps): React.ReactElement {
  if (query.status === 'loading') {
    return (
      <SkeletonBox height="450px" marginTop="twoAndHalf" marginBottom="two" />
    );
  }

  if (query.status === 'error' || !query.data) {
    return (
      <ErrorPanel
        isRefetching={query.isRefetching}
        onRetry={query.refetch}
        marginBottom="two"
      />
    );
  }

  const [chartData] = query.data.data;

  const isAnyDataAvailable = !!query.data.meta?.isAnyDataAvailable;

  const canConnectDevice = chartData.metricComponents.data.some(
    (config) => config.sourceType === 'device',
  );
  const canSelfReport = chartData.metricComponents.data.some(
    (config) => config.sourceType === 'self_reported',
  );

  if (!isAnyDataAvailable) {
    return (
      <NoDataAvailable
        metric={chartData.metricDisplayName}
        canSelfRecordData={canSelfReport}
        canConnectDevice={canConnectDevice}
        metricConfigId={chartData.id}
        inputConfig={chartData.addInputConfig.data}
      />
    );
  }

  return children(query.data);
}

const handleAnalytics = ({
  eventName,
  metricType,
  isEmptyState,
  detail,
}: {
  eventName: EVENT_NAME;
  metricType?: string;
  isEmptyState?: boolean;
  detail?: string;
}) =>
  trackAnalyticsEvent(eventName, {
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.DASHBOARD_METRIC_SCREEN,
    metric_type: metricType,
    ...(isEmptyState !== undefined && { is_empty_state: isEmptyState }),
    ...(detail && { detail }),
  });

type DetailsProps = {
  match: {
    params: {
      dataType: string;
    };
  };
};

export const Details = ({
  match: {
    params: { dataType },
  },
}: DetailsProps) => {
  const { isSelfReportedDataEnabled } = useIsSelfReportedDataEnabled();
  const pageViewAnalyticsRef = React.useRef(false);
  const { formatMessage } = useIntl();

  const [chartViewTab, setChartViewTab] = React.useState<CHART_VIEW>(
    CHART_VIEWS.WEEKLY,
  );
  const [chartView, setChartView] = React.useState<CHART_VIEW>(chartViewTab);
  const [metricsFrom, setMetricsFrom] = React.useState(new Date());
  const chartDataQuery = useChartData({
    chartView: chartViewTab,
    metric: dataType,
    from: metricsFrom,
  });

  const chartData = chartDataQuery?.data?.data[0];
  const meta = chartDataQuery?.data?.meta;
  const isAnyDataAvailable = !!meta?.isAnyDataAvailable;
  const metricDisplayName = chartData?.metricDisplayName;
  const hasPreviousPage = chartDataQuery.isPreviousData;
  const hasNextPage =
    chartDataQuery.isPreviousData ||
    isToday(metricsFrom) ||
    isFuture(metricsFrom);

  React.useEffect(() => {
    if (metricDisplayName && !pageViewAnalyticsRef.current) {
      handleAnalytics({
        eventName: EVENT_NAME.SCREEN_LOADED,
        metricType: dataType,
        isEmptyState: !isAnyDataAvailable,
      });
      pageViewAnalyticsRef.current = true;
    }
  }, [dataType, metricDisplayName, isAnyDataAvailable]);

  const showAddDataButton =
    chartData &&
    chartData.metricComponents.data.some(
      (config) => config.sourceType === 'self_reported',
    ) &&
    isSelfReportedDataEnabled &&
    chartData.addInputConfig;
  // When switching views we don't wont to show the skeleton boxes.  We hold on to the previous data until
  // the new set of data arrives. We also want to prevent the chart view from switching
  // until that data arrives to avoid mapping weekly data onto the monthly graph.
  // To solve this we update the view when the data has finished replacing the previous data.
  React.useEffect(() => {
    if (!chartDataQuery.isPreviousData) {
      setChartView(chartViewTab);
    }
  }, [chartDataQuery.isPreviousData, chartViewTab]);

  return (
    <HealthProfilePageWrapper
      title={metricDisplayName || formatMessage({ id: 'METRIC_DETAILS' })}
      sidebar={
        <Sidebar>
          {isAnyDataAvailable ? (
            <>
              <MoreOptionsCard>
                <MoreOptionsLink
                  to="all-recorded-data"
                  onClick={() =>
                    handleAnalytics({
                      eventName: EVENT_NAME.BUTTON_CLICKED,
                      metricType: dataType,
                      detail: 'view all data',
                    })
                  }
                >
                  {formatMessage(
                    { id: 'VIEW_ALL_METRIC_DATA' },
                    {
                      metric: metricDisplayName,
                    },
                  )}
                </MoreOptionsLink>
                <MoreOptionsLink
                  to="data-sources"
                  onClick={() =>
                    handleAnalytics({
                      eventName: EVENT_NAME.BUTTON_CLICKED,
                      metricType: dataType,
                      detail: 'view data sources',
                    })
                  }
                >
                  {formatMessage({ id: 'VIEW_DATA_SOURCES' })}
                </MoreOptionsLink>
              </MoreOptionsCard>
              {showAddDataButton ? (
                <AddEditDataModal
                  metricConfigId={chartData.id}
                  inputConfig={chartData.addInputConfig.data}
                  onAddData={(date) => {
                    const chartDateToSet = getChartViewDate({
                      date,
                      chartView,
                    });
                    if (chartDateToSet === date) {
                      return null;
                    }
                    return setMetricsFrom(chartDateToSet);
                  }}
                  modalTrigger={
                    <AddMetricDataButton
                      onClick={() =>
                        handleAnalytics({
                          eventName: EVENT_NAME.BUTTON_CLICKED,
                          metricType: dataType,
                          detail: 'add data',
                          isEmptyState: !isAnyDataAvailable,
                        })
                      }
                    />
                  }
                />
              ) : null}
            </>
          ) : null}
        </Sidebar>
      }
    >
      <QueryStateHandler query={chartDataQuery}>
        {({ data: [definedChartData] }) => (
          <HealthMetricsChart
            chartData={definedChartData}
            chartView={chartView}
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
            onPreviousPage={() => {
              setMetricsFrom(
                sub(metricsFrom, {
                  days: chartView === CHART_VIEWS.WEEKLY ? 7 : 30,
                }),
              );
              handleAnalytics({
                eventName: EVENT_NAME.BUTTON_CLICKED,
                metricType: dataType,
                detail: 'backward date navigation',
              });
            }}
            onNextPage={() => {
              let newMetricsFrom = add(metricsFrom, {
                days: chartView === CHART_VIEWS.WEEKLY ? 7 : 30,
              });

              if (isFuture(newMetricsFrom)) {
                newMetricsFrom = new Date();
              }

              handleAnalytics({
                eventName: EVENT_NAME.BUTTON_CLICKED,
                metricType: dataType,
                detail: 'forward date navigation',
              });
              setMetricsFrom(newMetricsFrom);
            }}
            onViewChange={(view) => {
              setChartViewTab(view);
              // Reset the pagination to the current day when we change views.
              setMetricsFrom(new Date());
            }}
          />
        )}
      </QueryStateHandler>
      <HealthPrograms metric={dataType} />
      <HealthLiteracyContent metric={dataType} />
    </HealthProfilePageWrapper>
  );
};
