import {
  object,
  optional,
  string,
  boolean,
  enums,
  func,
  intersection,
  record,
  type,
  deprecated,
} from 'superstruct';
import type { Struct, Infer } from 'superstruct';
import { authConfigSchema } from './auth';
import { uiConfiSchema } from './ui';
import { analyticsConfigSchema } from './analytics';

export const coreConfigSchema = object({
  clientId: string(),
  /**
   * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
   */
  tenantId: optional(string()),
  appVersion: optional(string()),
  contentUrl: string(),
  analytics: optional(analyticsConfigSchema),
  api: object({
    url: string(),
    wsUrl: string(),
    legacyRestApi: string(),
  }),
  // TODO: make required once no app (i.e., B2B) ever needs to initialize witohut auth
  auth: optional(authConfigSchema) as Struct<Infer<typeof authConfigSchema>>,
  appEnvironment: enums(['production', 'staging', 'development']),
  customMethods: object({
    handleLink: func() as unknown as Struct<
      (url: string, target?: string) => Promise<void>
    >,
  }),
  routing: optional(
    object({
      isUsingLeagueRouting: optional(boolean()),
    }),
  ),
  mobileAppUrls: optional(
    object({
      appStoreUrl: optional(string()),
      playStoreUrl: optional(string()),
    }),
  ),
  i18n: optional(
    object({
      strings: optional(
        intersection([
          type({
            default: record(string(), string()),
          }),
          record(string(), record(string(), string())),
        ]),
      ),
      stringOverrides: deprecated(
        optional(
          intersection([
            type({
              default: record(string(), string()),
            }),
            record(string(), record(string(), string())),
          ]),
        ),
        (value, ctx) =>
          console.warn(
            `${ctx.path} is deprecated, but value was '${value}'. Please use 'strings' instead.`,
          ),
      ),
    }),
  ),
  ui: uiConfiSchema,
  onError: optional(func() as unknown as Struct<(error: unknown) => void>),
});
