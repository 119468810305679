import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-commons';
import { ChallengeTokenIcon } from '@leagueplatform/rewards-common';

export interface ChallengeActivityTokenCaptionProps {
  tokens: string | number;
  activityDesc: string;
}

export const ChallengeActivityTokenCaption = ({
  tokens,
  activityDesc,
}: ChallengeActivityTokenCaptionProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const tokenCount = typeof tokens === 'string' ? parseInt(tokens, 10) : tokens;

  return (
    <Box as="span" display="inline-flex" alignItems="center">
      {activityDesc}
      <Box
        as="span"
        marginLeft="quarter"
        display="inline-flex"
        alignItems="center"
      >
        {formatMessage(
          { id: 'WORD_WITH_EXCLAMATION_POINT' },
          {
            word: <ChallengeTokenIcon value={tokenCount} />,
          },
        )}
      </Box>
    </Box>
  );
};
