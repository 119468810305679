import {
  Box,
  Icon,
  ParagraphText,
  HeadingText,
  StackItem,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import * as React from 'react';
import { useFormContext } from '@leagueplatform/web-common';
import { useSelectMembersStep } from '../hooks/use-select-members-step.hook';
import { orderNewCardFormInputNames } from '../constants/form-inputs.constants';

export interface MemberCardProps {
  headingLevel: 3 | 4;
}

const { memberList } = orderNewCardFormInputNames;

export const MemberCard = ({ headingLevel }: MemberCardProps) => {
  const { formatMessage } = useIntl();
  const { getValues } = useFormContext();
  const selectedMembers = getValues(memberList);
  const members = useSelectMembersStep().memberOptions.filter((member) =>
    selectedMembers.includes(member.value),
  );

  return (
    <StackItem
      css={{
        flexDirection: 'row',
        margin: '$oneAndHalf 0',
      }}
    >
      <Box
        css={{
          backgroundColor: '$surfaceBackgroundSecondary',
          padding: '$threeQuarters',
          borderRadius: '$circle',
          height: 'fit-content',
          marginRight: '$one',
          alignSelf: 'center',
          flexGrow: '0',
        }}
      >
        <Icon icon="illustrativePaymentCard" tint="$onSurfaceIconPrimary" />
      </Box>
      <Box>
        <HeadingText level={`${headingLevel}`} size="md">
          {formatMessage({ id: 'MEDICAL_ID_CARD' })}
        </HeadingText>
        <ul>
          {members.map(({ label }, i) => (
            <li key={`member-${label.replace(' ', '-')}`}>
              <ParagraphText>
                {i === members.length - 1 ? `${label}` : `${label},`}
              </ParagraphText>
            </li>
          ))}
        </ul>
      </Box>
    </StackItem>
  );
};
