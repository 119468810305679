import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { getColourObject } from '../../utils/helpers';
import { QuestionIcon } from '../question-icon.view';

const CloseIconButton = styled.button`
  border-radius: 50%;
  width: 29px;
  height: 29px;
  padding: 6px 8px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => getColourObject(theme).greyLighter};
`;

const CloseIcon = styled((props) => <FontAwesomeIcon {...props} />)`
  font-size: 15px;
`;

/*
  Aria-label required for accessibility
  https://humanwhocodes.com/blog/2013/04/01/making-accessible-icon-buttons/
*/
export const CloseButton = () => {
  const { formatMessage } = useIntl();
  return (
    <CloseIconButton
      aria-label={formatMessage({ id: 'ARIA_CIRCLED_CHECKMARK' })}
    >
      <CloseIcon icon="times" />
      <QuestionIcon />
    </CloseIconButton>
  );
};
