import {
  genesisStyled,
  Flex,
  css,
  SpacingValues,
} from '@leagueplatform/genesis-commons';

interface SidebarLayoutProps {
  space?: SpacingValues;
  isSideBarOnRight?: boolean;
  minInlineSize?: string;
  sidebarWidth?: string;
}

export const SidebarLayout = genesisStyled(Flex)(
  ({
    space = 'two',
    minInlineSize = '50%',
    sidebarWidth,
    isSideBarOnRight,
  }: SidebarLayoutProps) =>
    css({
      gap: space,
      flexWrap: 'wrap',

      '& > :first-child': isSideBarOnRight
        ? {
            flexBasis: 0,
            flexGrow: 999,
            minInlineSize,
          }
        : {
            flexGrow: 1,
            flexBasis: sidebarWidth,
          },

      '& > :last-child': isSideBarOnRight
        ? {
            flexGrow: 1,
            flexBasis: sidebarWidth,
          }
        : {
            flexBasis: 0,
            flexGrow: 999,
            minInlineSize,
          },
    }),
);
