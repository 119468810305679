import {
  EVENT_NAME,
  trackAnalyticsEvent,
  SCREEN_NAMES,
  PRODUCT_AREA,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from './queries/use-wallet-detail-query.hook';
import { BENEFIT_SCREEN_NAMES } from '../constants/analytics-benefit-property-names';

const useWalletBenefitAnalyticsData = () => {
  const { userId, benefitId } = useWalletParams();
  const { data: benefitData } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: ({
        benefit_source: benefitSource,
        full_name: fullName,
        id,
        product_type: productType,
        vendor_id: vendorId,
      }) => ({
        benefit_id: id,
        benefit_name: fullName,
        benefit_product_type: productType,
        benefit_source: benefitSource,
        product_area: PRODUCT_AREA.WALLET,
        screen_name: SCREEN_NAMES.BENEFIT_PAGE,
        vendor_id: vendorId,
      }),
    },
  );
  return benefitData;
};

type EventProps = Parameters<typeof trackAnalyticsEvent>[1];

export const useWalletDetailAnalytics = (partialParams?: EventProps) => {
  const benefitData = useWalletBenefitAnalyticsData();

  return (eventName: EVENT_NAME, props?: {}) => {
    trackAnalyticsEvent(eventName, {
      ...benefitData,
      ...partialParams,
      ...props,
    });
  };
};

export const useWalletDetailScreenLoaded = (
  screenName: BENEFIT_SCREEN_NAMES,
) => {
  const benefitData = useWalletBenefitAnalyticsData();

  usePageViewAnalytics({ ...benefitData, screen_name: screenName });
};
