/*
  When you are lucky, match in react router will give you the query string as a object
  But sometimes...the match is null:
  https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/match.md#null-matches
  Or match exists, but the params object is empty, even if there is a query string
  In this case we use the location.search value, which is a string
  This utility below will allow us to parse the string and return an object

  DISCLAIMER:
  If queryString comes from the iframe containing React, it always includes the query string `?lang=<language_code>`
  at the end. This function removes it, but if not removed, the last key/value pair will have `?lang`
  included in it!!!

  example: parseQuery(`?foo=bar?lang=en`) => { foo: 'bar?lang=en' }
*/
export function parseQuery(queryString) {
  const query = {};

  if (queryString) {
    // Remove leading '?' in query string and remove React iframe's ever present trailing `?lang`
    const noQuestionMarkQueryString = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).replace(/(\?lang)=(.*)/gm, '');
    // Cut out '?' from query string if present
    const pairs = noQuestionMarkQueryString.split('&');
    // build pairs
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }

  return query;
}
