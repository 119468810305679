{
  "dragDescriptionKeyboard": "드래그를 시작하려면 Enter를 누르세요.",
  "dragDescriptionKeyboardAlt": "드래그를 시작하려면 Alt + Enter를 누르십시오.",
  "dragDescriptionLongPress": "드래그를 시작하려면 길게 누르십시오.",
  "dragDescriptionTouch": "드래그를 시작하려면 더블 탭하세요.",
  "dragDescriptionVirtual": "드래그를 시작하려면 클릭하세요.",
  "dragItem": "{itemText} 드래그",
  "dragSelectedItems": "{count, plural, one {#개 선택 항목} other {#개 선택 항목}} 드래그",
  "dragSelectedKeyboard": "{count, plural, one {#개 선택 항목} other {#개 선택 항목}}을 드래그하려면 Enter를 누르십시오.",
  "dragSelectedKeyboardAlt": "{count, plural, one {#개 선택 항목} other {#개 선택 항목}}을 드래그하려면 Alt + Enter를 누르십시오.",
  "dragSelectedLongPress": "{count, plural, one {#개 선택 항목} other {#개 선택 항목}}을 드래그하려면 길게 누르십시오.",
  "dragStartedKeyboard": "드래그가 시작되었습니다. Tab을 눌러 드롭 대상으로 이동한 다음 Enter를 눌러 드롭하거나 Esc를 눌러 취소하세요.",
  "dragStartedTouch": "드래그가 시작되었습니다. 드롭 대상으로 이동한 다음 더블 탭하여 드롭하세요.",
  "dragStartedVirtual": "드래그가 시작되었습니다. 드롭 대상으로 이동한 다음 클릭하거나 Enter를 눌러 드롭하세요.",
  "dropCanceled": "드롭이 취소되었습니다.",
  "dropComplete": "드롭이 완료되었습니다.",
  "dropDescriptionKeyboard": "드롭하려면 Enter를 누르세요. 드래그를 취소하려면 Esc를 누르세요.",
  "dropDescriptionTouch": "더블 탭하여 드롭하세요.",
  "dropDescriptionVirtual": "드롭하려면 클릭하세요.",
  "dropIndicator": "드롭 표시기",
  "dropOnItem": "{itemText}에 드롭",
  "dropOnRoot": "드롭 대상",
  "endDragKeyboard": "드래그 중입니다. 드래그를 취소하려면 Enter를 누르세요.",
  "endDragTouch": "드래그 중입니다. 드래그를 취소하려면 더블 탭하세요.",
  "endDragVirtual": "드래그 중입니다. 드래그를 취소하려면 클릭하세요.",
  "insertAfter": "{itemText} 이후에 삽입",
  "insertBefore": "{itemText} 이전에 삽입",
  "insertBetween": "{beforeItemText} 및 {afterItemText} 사이에 삽입"
}
