import * as React from 'react';
import { useTheme } from '@leagueplatform/genesis-commons';
import {
  SHORT_WEEKDAY_FORMAT,
  useFormatDateByUserProfileLocation,
} from '@leagueplatform/web-common';
import { XYChart, Tooltip, AxisBottom, AxisLeft, Bar, Grid } from '../xychart';
import { BarGraph as BarGraphType } from './types/bar-graph';
import { useGetRoundedYMax } from './utils/get-rounded-y-max';
import { useGetLeftAxisLabelOffset } from './utils/use-calculate-left-axis-label-offset';

const NUM_X_TICKS = 2;

type WeeklyBarGraphProps<Datum> = BarGraphType<Datum>;

export function WeeklyBarGraph<Datum>({
  data,
  accessors,
  title,
  description,
  axisLeftLabel,
  axisBottomLabel,
  ariaDatumLabel,
  renderTooltip,
  yMaxFromEnums,
}: WeeklyBarGraphProps<Datum>) {
  const formatDateWithUserProfileLocation =
    useFormatDateByUserProfileLocation();
  const theme = useTheme();
  const yMax = useGetRoundedYMax(data, accessors, 100, yMaxFromEnums);
  const labelOffset = useGetLeftAxisLabelOffset(yMax);

  const margin = {
    top: theme.space.three,
    bottom: theme.space.five,
    left: theme.space.three + labelOffset,
    right: theme.space.four,
  };

  return (
    <XYChart
      title={title}
      desc={description}
      data={data}
      accessors={accessors}
      margin={margin}
      xScale={{ type: 'band', paddingInner: 0.3, paddingOuter: 0.15 }}
      yScale={{
        type: 'linear',
        domain: [0, yMax],
      }}
    >
      <Grid rowTickValues={[0, yMax / NUM_X_TICKS, yMax]} centerColumns />
      <AxisLeft
        label={axisLeftLabel}
        labelOffset={labelOffset}
        tickValues={[0, yMax / NUM_X_TICKS, yMax]}
      />
      <AxisBottom
        label={axisBottomLabel}
        tickFormat={(timestamp: number) =>
          formatDateWithUserProfileLocation(
            new Date(timestamp),
            SHORT_WEEKDAY_FORMAT,
          )
        }
      />
      {data.map((datum, i) => {
        const isLastBar = data.length - 1 === i;

        return (
          <Bar
            key={`bar-${accessors.xAccessor(datum)}:${accessors.yAccessor(
              datum,
            )}`}
            datum={datum}
            accessors={accessors}
            highlight={isLastBar}
            aria-label={ariaDatumLabel(datum)}
            radius={theme.radii.large}
          />
        );
      })}
      <Tooltip<Datum> snapToDatum>{renderTooltip}</Tooltip>
    </XYChart>
  );
}
