import React from 'react';

export const Question = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="questionIcon"
    aria-hidden
  >
    <path
      d="M8.5 15.5C12.6421 15.5 16 12.1421 16 8C16 3.85786 12.6421 0.5 8.5 0.5C4.35786 0.5 1 3.85786 1 8C1 12.1421 4.35786 15.5 8.5 15.5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M8.5 11.5C8.45055 11.5 8.40222 11.5147 8.36111 11.5421C8.32 11.5696 8.28795 11.6086 8.26903 11.6543C8.25011 11.7 8.24516 11.7503 8.2548 11.7988C8.26445 11.8473 8.28826 11.8918 8.32322 11.9268C8.35819 11.9617 8.40273 11.9855 8.45123 11.9952C8.49972 12.0048 8.54999 11.9999 8.59567 11.981C8.64135 11.962 8.6804 11.93 8.70787 11.8889C8.73534 11.8478 8.75 11.7994 8.75 11.75C8.75 11.6837 8.72366 11.6201 8.67678 11.5732C8.62989 11.5263 8.5663 11.5 8.5 11.5Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 5.99999C6.50007 5.63334 6.60093 5.27376 6.79155 4.96056C6.98217 4.64736 7.25522 4.39259 7.58085 4.22409C7.90649 4.05559 8.27218 3.97984 8.63795 4.00513C9.00372 4.03042 9.35551 4.15577 9.65486 4.36748C9.9542 4.5792 10.1896 4.86912 10.3353 5.20558C10.481 5.54203 10.5314 5.91206 10.481 6.27523C10.4306 6.6384 10.2814 6.98073 10.0496 7.26481C9.81778 7.54888 9.51235 7.76377 9.16667 7.88599C8.97161 7.95495 8.80275 8.08271 8.68335 8.25166C8.56395 8.42061 8.49989 8.62243 8.5 8.82932V9.49999"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
