import { FormatDateOptions } from 'react-intl';

export const DATEPICKER_DATE_FORMAT = 'DD-MMM-YYYY';

export const UNICODE_DATE_FORMAT = 'dd-MM-yyyy';

// en-US: August 2, 2020
// en-FR: 2 août 2020
// en-CA: August 2, 2020
export const DATE_FORMAT: FormatDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

// en-US: Aug 2, 2020
// en-FR: 2 août 2020
// en-CA: Aug. 2, 2020
export const SHORT_DATE_FORMAT: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

// en-US: Aug 2, 2020, 8:00 PM EDT
// en-FR: 2 août 2020, 20 h 00 HAE
// en-CA: Aug. 2, 2020, 8:00 p.m. EDT
export const SHORT_DATE_AND_TIME_FORMAT: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
};

// en-US: Aug 2, 2020, 8:00 PM
// en-FR: 2 août 2020, 20 h 00
// en-CA: Aug. 2, 2020, 8:00 p.m.
export const SHORT_DATE_AND_TIME_FORMAT_NO_TIMEZONE: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

// en-US: Aug 2
// fr-CA: 2 août
// en-CA: Aug. 2
export const SHORT_MONTH_FORMAT: FormatDateOptions = {
  month: 'short',
  day: 'numeric',
};

// en-US: August 2
// fr-CA: 2 août
// en-CA: August. 2
export const LONG_MONTH_FORMAT: FormatDateOptions = {
  month: 'long',
  day: 'numeric',
};

// en-US: Sunday, August 2, 2020
// en-FR: dimanche 2 août 2020
// en-CA: Sunday, August 2, 2020
export const LONG_WEEKDAY_DATE_FORMAT: FormatDateOptions = {
  weekday: 'long',
  ...DATE_FORMAT,
};

// en-US: Mon, August 2, 2020
// fr-CA: dim. 2 août 2020
// en-CA: Mon., August 2, 2020
export const SHORT_WEEKDAY_DATE_FORMAT: FormatDateOptions = {
  weekday: 'short',
  ...DATE_FORMAT,
};

// en-US: Mon
// fr-CA: dim
// en-CA: Mon
export const SHORT_WEEKDAY_FORMAT: FormatDateOptions = {
  weekday: 'short',
};

// en-US: 8/02/2020
// fr-CA: 2020-08-02
// en-CA: 2020-08-02
export const NUMERIC_YEAR_MONTH_DAY: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

// en-US: Saturday
// fr-CA: samedi
// en-CA: Saturday
export const LONG_WEEKDAY: FormatDateOptions = {
  weekday: 'long',
};

// en-US: Jun 2021
// fr-CA: juin 2021
// en-CA: Jun 2021
export const SHORT_MONTH_YEAR: FormatDateOptions = {
  month: 'short',
  year: 'numeric',
};

// en-US: August 2020
// fr-CA: août 2020
// en-CA: August 2020
export const MONTH_YEAR: FormatDateOptions = {
  month: 'long',
  year: 'numeric',
};
