import { useParams } from '@leagueplatform/routing';
import { useQuery } from 'react-query';
import {
  getAllTeams,
  MultipleTeamsDeserialisedData,
} from '@leagueplatform/health-journey-api';

const ALL_TEAMS_QUERY_KEY = 'allTeams';

export const useGetAllTeams = () => {
  const { challengeId } = useParams<{ challengeId: string }>();

  return useQuery<MultipleTeamsDeserialisedData, Error>(
    [ALL_TEAMS_QUERY_KEY, challengeId],
    () => getAllTeams(challengeId),
    {
      enabled: !!challengeId,
    },
  );
};
