import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_USER_STANDALONE_ACTIVITIES_MESSAGE_TYPE =
  'get_fotl_suggested_user_health_activities';

export const getUserStandaloneActivities = (
  limit?: number,
  errorContext?: ErrorContext,
): Promise<any> =>
  SocketAsFetch.fetch(
    {
      message_type: GET_USER_STANDALONE_ACTIVITIES_MESSAGE_TYPE,
      info: {
        limit,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
