import React, { useState } from 'react';
import { EVENT_NAME, PRODUCT_AREA } from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  Box,
} from '@leagueplatform/genesis-commons';
import { ModalContentWithImage } from 'components/modal-content-with-image/modal-content-with-image.component';
import {
  SIGN_UP_ANALYTICS_DETAILS,
  SIGN_UP_ANALYTICS_SCREEN_NAMES,
} from 'constants/analytics.constants';
import { BUTTON_CONTAINER_MAX_WIDTH } from 'constants/challenges.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { DeviceModalContentProps } from './wearables-connection.types';

const { WEARABLES_CONNECTION } = SIGN_UP_ANALYTICS_SCREEN_NAMES;
const { TRACK_AUTOMATICALLY, TRACK_MANUALLY } = SIGN_UP_ANALYTICS_DETAILS;

// Hard coding dataPoint for now, but will replace this with dataPoint coming from getChallenges endpoint when available
const dataPoints = ['steps'] as const;

export const DeviceConnectedModalContent = ({
  setDisplayManualTrackingConfirmationModal,
  onConfirm,
  isLoading,
}: DeviceModalContentProps) => {
  const { formatMessage, $tmap, formatList } = useIntl();
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const [isPrimaryButtonPressed, setPrimaryButtonPressed] = useState(false);
  const [isSecondaryButtonPressed, setSecondaryButtonPressed] = useState(false);
  const handleAnalyticsClick = (detail: string) =>
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.APPS_AND_DEVICES,
      screen_name: WEARABLES_CONNECTION,
      is_data_available: true,
      detail,
    });
  const trackWearables = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_TRACK_WEARABLES,
  ) as string;

  const getDataPointMessageForDataPoint = $tmap({
    steps: 'STEPS',
  });

  return (
    <ModalContentWithImage
      image={{
        src: trackWearables,
        alt: formatMessage({ id: 'TRACK_WEARABLES_ALT_TEXT' }),
      }}
    >
      <HeadingTwo as="h3" marginBottom="threeQuarters">
        {formatMessage({ id: 'DEVICES_CONNECTED_TITLE' })}
      </HeadingTwo>
      <BodyOne marginBottom="two">
        {formatMessage(
          {
            id: 'DEVICES_CONNECTED_DESCRIPTION',
          },
          {
            dataPoint: formatList(
              dataPoints.map((dataPoint) =>
                getDataPointMessageForDataPoint(dataPoint),
              ),
            ),
          },
        )}
      </BodyOne>
      <Box maxWidth={BUTTON_CONTAINER_MAX_WIDTH}>
        <PrimaryButton
          marginBottom="oneAndHalf"
          onClick={() => {
            handleAnalyticsClick(TRACK_AUTOMATICALLY);
            onConfirm();
            setPrimaryButtonPressed(true);
          }}
          isLoading={isLoading && !isSecondaryButtonPressed}
          width="100%"
          disabled={isLoading || isSecondaryButtonPressed}
        >
          {formatMessage({ id: 'TRACK_CHALLENGE_AUTOMATICALLY' })}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => {
            handleAnalyticsClick(TRACK_MANUALLY);
            setDisplayManualTrackingConfirmationModal();
            setSecondaryButtonPressed(true);
          }}
          width="100%"
          isLoading={isLoading && isSecondaryButtonPressed}
          disabled={isLoading || isPrimaryButtonPressed}
        >
          {formatMessage({ id: 'TRACK_CHALLENGE_MANUALLY' })}
        </SecondaryButton>
      </Box>
    </ModalContentWithImage>
  );
};
