import * as React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import { func, shape, string, number } from 'prop-types';
import { Transition, SwitchTransition } from 'react-transition-group';

export const TransitionTypes = Object.freeze({
  FADE: 'fade',
});

const { FADE } = TransitionTypes;

const getTransitionStyles = (type = FADE, duration = 350) =>
  ({
    [FADE]: {
      defaultStyles: {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        'will-change': 'opacity',
      },
      transitionStyles: {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
      },
    },
  }[type]);

const getViewWithProps = (viewObject) => {
  const component = { View: React.Fragment, props: {} };

  // If there is no view object return a fragment with no props
  if (!viewObject) return component;

  // If there is view, determine if it using the object syntax to declare a view with props
  component.View = viewObject?.view ?? viewObject;
  component.props = viewObject?.props ?? {};

  return component;
};

export const ViewTransitionController = ({
  handleActiveView,
  views,
  duration,
  transitionType: type,
  ...rest
}) => {
  const { defaultStyles, transitionStyles } = getTransitionStyles(
    type,
    duration,
  );
  const activeView = handleActiveView();
  const { View, props } = getViewWithProps(views?.[activeView]);
  return (
    <SwitchTransition>
      <Transition timeout={duration} key={activeView}>
        {(state) => (
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          <Box {...defaultStyles} {...transitionStyles[state]} {...rest}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
            <View {...props} />
          </Box>
        )}
      </Transition>
    </SwitchTransition>
  );
};

ViewTransitionController.propTypes = {
  transitionType: string,
  duration: number,
  handleActiveView: func.isRequired,
  views: shape({}).isRequired,
};

ViewTransitionController.defaultProps = {
  transitionType: FADE,
  duration: 350,
};
