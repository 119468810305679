import * as React from 'react';
import { Box, HeadingText } from '@leagueplatform/genesis-core';
import { StackLayout } from '@leagueplatform/web-common-components';
import { ActionLink } from './components/action-link/action-link.component';
import { useCardDetailQuickActionLinks } from './hooks/use-card-detail-quick-action-links.hook';

export const CardDetailQuickActionLinks = () => {
  const { actionGroups, isLoading } = useCardDetailQuickActionLinks();

  if (!actionGroups || actionGroups.length === 0 || isLoading) {
    return null;
  }

  return (
    <Box
      css={{
        backgroundColor: '$surfaceBackgroundPrimary',
        borderWidth: '$thin',
        borderColor: '$onSurfaceBorderSubdued',
        borderStyle: 'solid',
        borderRadius: '$medium',
        padding: '$oneAndHalf',
      }}
    >
      <StackLayout space="$oneAndHalf">
        {actionGroups.map((actionGroup) => (
          <React.Fragment key={`${actionGroup.title.replace(' ', '-')}-group`}>
            <HeadingText level="3" size="lg">
              {actionGroup.title}
            </HeadingText>
            <StackLayout as="ul" space="$oneAndHalf">
              {actionGroup.actions.map((action) => (
                <Box
                  as="li"
                  role="listitem"
                  key={`${action.name.replace(' ', '-')}-action`}
                  css={{ listStyle: 'none', maxWidth: '80ch' }}
                >
                  <ActionLink key={action.url} action={action} />
                </Box>
              ))}
            </StackLayout>
          </React.Fragment>
        ))}
      </StackLayout>
    </Box>
  );
};
