import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Icon,
  Responsive,
  StackLayout,
  UtilityText,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
// TODO: Move this component and hook up to a common folder
import { EnrollmentStatusInformation } from 'pages/challenge-landing-page/components/sidebar/components/enrollment-status-information/enrollment-status-information.component';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';

export const ChallengeDetailsContents = () => {
  const { formatMessage, formatDate } = useIntl();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const { data: challengeData } = useGetChallengeByIdData();
  const { challengeType, endDate, status, startDate, userChallenge } =
    challengeData!.data; // trust that we have challengeData already as we fetch at top level
  const isSoloChallenge = challengeType === 'solo';
  const userIsEnrolled = userChallenge?.data?.enrollmentStatus === 'enrolled';
  const displayClockIcon =
    !isSoloChallenge && !userIsEnrolled && status !== CHALLENGE_STATUS.CLOSED;
  const dateFormatOptions = {
    ...SHORT_DATE_FORMAT,
    timeZone: defaultTimezone,
  };
  return (
    <StackLayout
      spacing="$one"
      horizontalAlignment={isMobile && !userIsEnrolled ? 'center' : 'stretch'}
    >
      <StackLayout spacing="$one" orientation="horizontal">
        <Icon icon="illustrativeCalendar" css={{ minWidth: '$oneAndHalf' }} />
        {isSoloChallenge ? (
          <UtilityText>
            {formatMessage({ id: 'THIS_CHALLENGE_RUNS_YEAR_ROUND' })}
          </UtilityText>
        ) : (
          <>
            <Responsive up="laptop">
              <StackLayout spacing="$half">
                <UtilityText>
                  {formatMessage(
                    { id: 'START_DAY_AT_TIME' },
                    {
                      day: formatDate(startDate, dateFormatOptions),
                      endDate: formatDate(endDate, dateFormatOptions),
                    },
                  )}
                </UtilityText>
                <UtilityText>
                  {formatMessage(
                    { id: 'END_DAY_AT_TIME' },
                    {
                      day: formatDate(endDate, dateFormatOptions),
                    },
                  )}
                </UtilityText>
              </StackLayout>
            </Responsive>
            <Responsive down="laptop">
              <UtilityText>
                {formatMessage(
                  { id: 'START_AND_END_DATES' },
                  {
                    startDate: formatDate(startDate, dateFormatOptions),
                    endDate: formatDate(endDate, dateFormatOptions),
                  },
                )}
              </UtilityText>
            </Responsive>
          </>
        )}
      </StackLayout>
      <StackLayout spacing="$one" orientation="horizontal">
        {/* TODO: Update styling for this clock icon such that it doesn't squish when going down to smaller screen sizes. Similarly, add a block that takes up the same space in the case where the icon should not be displayed */}
        {displayClockIcon ? (
          <Icon icon="illustrativeClock" css={{ minWidth: '$oneAndHalf' }} />
        ) : (
          <Responsive up="laptop">
            <Box css={{ width: '$oneAndHalf' }} />
          </Responsive>
        )}
        <EnrollmentStatusInformation />
      </StackLayout>
    </StackLayout>
  );
};
