import { useQuery } from 'react-query';

import {
  getHealthProgramCuratedCarousels,
  GET_HEALTH_PROGRAM_CURATED_CAROUSELS_MESSAGE_TYPE,
} from '@leagueplatform/health-journey-api';

export const useHealthProgramCuratedCarousels = (type) =>
  useQuery([GET_HEALTH_PROGRAM_CURATED_CAROUSELS_MESSAGE_TYPE, type], () =>
    getHealthProgramCuratedCarousels(type),
  );
