import React from 'react';
import { Switch, Route, useRouteMatch } from '@leagueplatform/routing';
import { CareCollection } from './pages/care-collection/care-collection.page';
import { CareCollections } from './pages/care-collections/care-collections.page';

export const CareCollectionsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/:careCollectionId`}
        component={CareCollection}
      />
      <Route component={CareCollections} />
    </Switch>
  );
};
