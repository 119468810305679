import * as React from 'react';
import {
  Box,
  TextAction,
  Icon,
  ParagraphText,
} from '@leagueplatform/genesis-core';

type MoreOptionsButtonProps = {
  children: React.ReactNode;
  id?: string;
  onClick: () => void;
};

export function MoreOptionsButton({
  id,
  onClick,
  children,
}: MoreOptionsButtonProps) {
  return (
    <Box as="li">
      <TextAction
        onClick={onClick}
        as="button"
        id={id}
        size="medium"
        css={{
          alignItems: 'center',
          borderRadius: '$medium',
          justifyContent: 'space-between',
          marginY: '$half',
          paddingBottom: '$threeQuarters',
          paddingLeft: '$quarter',
          paddingTop: '$threeQuarters',
          textAlign: 'left',
          textDecoration: 'none',
          width: '100%',
          '&:hover': {
            backgroundColor: '$interactiveBackgroundDisabled',
            textDecoration: 'none',
          },
        }}
      >
        <ParagraphText css={{ fontWeight: '$headingThree' }}>
          {children}
        </ParagraphText>
        <Icon icon="interfaceChevronRight" tint="$primaryBackgroundHovered" />
      </TextAction>
    </Box>
  );
}
