import React from 'react';
import {
  Box,
  QuietButton,
  genesisStyled,
  css,
  VisuallyHidden,
  Image,
} from '@leagueplatform/genesis-commons';
import { Modal } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import CLOSE_ICON from 'assets/close.svg';

const StyledModalContainer = genesisStyled(Modal.Container)(
  css({
    position: 'relative',
    maxWidth: 620,
    borderRadius: 'medium',
    overflow: 'auto',
  }),
);

const StyledModalBody = genesisStyled(Modal.Body)(
  css({
    padding: 'twoAndHalf',
  }),
);

export const ProfileModal = ({
  onClose,
  children,
  'aria-labelledby': ariaLabelledBy,
}: any) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Wrapper options={{}} onClose={onClose}>
      <StyledModalContainer aria-labelledby={ariaLabelledBy}>
        <Box position="absolute" right="0">
          <QuietButton margin="one" padding="half" onClick={onClose}>
            <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
            <Image
              src={CLOSE_ICON}
              role="presentation"
              size="threeQuarters"
              aria-hidden="true"
            />
          </QuietButton>
        </Box>
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModalContainer>
    </Modal.Wrapper>
  );
};
