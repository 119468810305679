import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import snakecaseKeys from 'snakecase-keys';

export const COMPLETE_HEALTH_PROFILE_QUESTION_MESSAGE_TYPE =
  'complete_health_profile_question';

interface Unit {
  type: string;
  name: string;
}

interface MultipleChoiceAnswer {
  description: string;
  id: string;
  text: string;
  value: number;
}

interface MultipleSelectionAnswer extends MultipleChoiceAnswer {
  isUserPreference: boolean;
  preferenceTags?: string[];
  properties?: string[];
}

interface InputAnswer {
  id: string;
  value: number | string | Date | null;
  type: string;
  answerCaption: string;
  defaultUnit: Unit | null;
  defaultValue: number | string | Date | null;
  unitOptions: Unit[] | null;
  placeholderText: string;
  valueUnitType: string | null;
}

interface DropdownAnswer {
  id: string;
  text: string;
  value: string;
}

type QuestionType =
  | 'multiple_choice_answers'
  | 'multiple_selection_answers'
  | 'input_answer'
  | 'drop_down_answers';

type Answers = {
  [key in QuestionType]:
    | MultipleChoiceAnswer[]
    | MultipleSelectionAnswer[]
    | InputAnswer
    | DropdownAnswer[];
};

interface CompletedQuestion {
  moduleId: string;
  questionId: string;
  answers: Answers;
  campaignId?: string;
  activityId?: string;
}

export const completeHealthProfileQuestion = ({
  moduleId,
  questionId,
  answers,
  campaignId,
  activityId,
}: CompletedQuestion) => {
  const decamelizedAnswers = snakecaseKeys(answers);

  return SocketAsFetch.fetch({
    message_type: COMPLETE_HEALTH_PROFILE_QUESTION_MESSAGE_TYPE,
    info: {
      module_id: moduleId,
      question_id: questionId,
      answers: decamelizedAnswers,
      campaign_id: campaignId,
      activity_id: activityId,
    },
  });
};
