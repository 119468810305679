/**
 * A helper util to parse a given URL
 * @param {string} url URL to parse
 * @returns {object} An object of URL parts - protocol, host, hostname, port, pathname, hash, search, href, and origin.
 */

const whiteSpaceRe = /^\s*$/;

export function parseUrl(url) {
  if (!url || whiteSpaceRe.test(url)) {
    throw new Error(`Error in parseUrl: could not parse ${url} as url`);
  }

  const a = document.createElement('a');
  a.href = url;
  const {
    protocol, // "http:"
    host, // "example.com:3000"
    hostname, // "example.com"
    port, // "3000"
    pathname, // "/pathname/"
    hash, // "#hash"
    search, // "?search=test"
    href, // http://example.com
    origin, // http://example.com:3000
  } = a;

  return {
    protocol,
    host,
    hostname,
    port,
    pathname,
    hash,
    search,
    href,
    origin,
  };
}
