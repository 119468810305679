export function groupByKey<Item>(
  getKey: (getKey: Item) => string,
  items: Item[],
) {
  const groupedItems = items?.reduce((acc, item) => {
    const key = getKey(item);

    return {
      ...acc,
      // Append the item to the key group, if doesn't already exist create a new array.
      [key]: [...(acc[key] ?? []), item],
    };
  }, {} as { [key: string]: Item[] });

  return groupedItems;
}
