export function bgImageContain() {
  return `
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  `;
}

export function bgImageCover() {
  return `
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  `;
}
