import { query, deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { HealthMetricsQueryParameters } from 'health-metrics/v1/types/health-metrics-data.type';
import {
  HealthMetricDeserialisedData,
  HealthMetricsResponseData,
} from 'health-metrics/v3/types/health-metrics-data.type';

export const healthMetricsV3Path = 'v3/health-metrics';

export async function getHealthMetrics(params: HealthMetricsQueryParameters) {
  const response = await leagueFetch(`${healthMetricsV3Path}?${query(params)}`);

  const responseJson: HealthMetricsResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getHealthMetrics error: ${response.status}`);
  }

  const deseralizedData = deserialise(
    responseJson,
  ) as HealthMetricDeserialisedData;

  return deseralizedData;
}

export async function getHealthMetricsByPaginationLink(page: string) {
  const response = await leagueFetch(page);

  const responseJson: HealthMetricsResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getHealthMetrics error: ${response.status}`);
  }

  const deseralizedData = deserialise(
    responseJson,
  ) as HealthMetricDeserialisedData;

  return deseralizedData;
}
