{
  "dragDescriptionKeyboard": "Stisknutím klávesy Enter začnete s přetahováním.",
  "dragDescriptionKeyboardAlt": "Stisknutím Alt + Enter zahájíte přetahování.",
  "dragDescriptionLongPress": "Dlouhým stisknutím zahájíte přetahování.",
  "dragDescriptionTouch": "Poklepáním začnete s přetahováním.",
  "dragDescriptionVirtual": "Kliknutím začnete s přetahováním.",
  "dragItem": "Přetáhnout {itemText}",
  "dragSelectedItems": "Přetáhnout {count, plural, one {# vybranou položku} few {# vybrané položky} other {# vybraných položek}}",
  "dragSelectedKeyboard": "Stisknutím klávesy Enter přetáhněte {count, plural, one {# vybranou položku} other {# vybrané položky}}.",
  "dragSelectedKeyboardAlt": "Stisknutím Alt + Enter přetáhněte {count, plural, one {# vybranou položku} other {# vybrané položky}}.",
  "dragSelectedLongPress": "Dlouhým stisknutím přetáhnete {count, plural, one {# vybranou položku} other {# vybrané položky}}.",
  "dragStartedKeyboard": "Začněte s přetahováním. Po stisknutí klávesy Tab najděte požadovaný cíl a stisknutím klávesy Enter přetažení dokončete nebo stisknutím klávesy Esc akci zrušte.",
  "dragStartedTouch": "Začněte s přetahováním. Najděte požadovaný cíl a poklepáním přetažení dokončete.",
  "dragStartedVirtual": "Začněte s přetahováním. Najděte požadovaný cíl a kliknutím nebo stisknutím klávesy Enter přetažení dokončete.",
  "dropCanceled": "Přetažení bylo zrušeno.",
  "dropComplete": "Přetažení bylo dokončeno.",
  "dropDescriptionKeyboard": "Stisknutím klávesy Enter přetažení dokončete nebo stisknutím klávesy Esc akci zrušte.",
  "dropDescriptionTouch": "Poklepáním přetažení dokončete.",
  "dropDescriptionVirtual": "Kliknutím objekt přetáhněte.",
  "dropIndicator": "indikátor přetažení",
  "dropOnItem": "Přetáhnout na {itemText}",
  "dropOnRoot": "Přetáhnout na",
  "endDragKeyboard": "Probíhá přetahování. Stisknutím klávesy Enter přetažení zrušíte.",
  "endDragTouch": "Probíhá přetahování. Poklepáním přetažení zrušíte.",
  "endDragVirtual": "Probíhá přetahování. Kliknutím přetažení zrušíte.",
  "insertAfter": "Vložit za {itemText}",
  "insertBefore": "Vložit před {itemText}",
  "insertBetween": "Vložit mezi {beforeItemText} a {afterItemText}"
}
