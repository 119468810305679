import { query, deserialise } from 'kitsu-core';
import { leagueFetch } from '@leagueplatform/league-fetch';
import {
  HealthMetricDeserialisedData,
  HealthMetricsQueryParameters,
  HealthMetricsResponseData,
} from './types/health-metrics-data.type';

export const healthMetricsV1Path = 'v1/health-metrics';

export async function getHealthMetrics(params: HealthMetricsQueryParameters) {
  const response = await leagueFetch(`${healthMetricsV1Path}?${query(params)}`);

  const responseJson: HealthMetricsResponseData = await response.json();

  if (!response.ok) {
    throw new Error(`getHealthMetrics error: ${response.status}`);
  }

  return deserialise(responseJson) as HealthMetricDeserialisedData;
}
