import { leagueFetch } from '@leagueplatform/league-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import {
  USER_CHALLENGE_PATH,
  CHALLENGE_DATA_TYPES,
} from './constants/challenge-requests.constants';

export const postUserChallenge = async (
  challengeTrackingMode: string,
  challengeId: string,
  errorContext?: ErrorContext,
) => {
  const response = await leagueFetch(USER_CHALLENGE_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
    body: JSON.stringify({
      data: {
        type: CHALLENGE_DATA_TYPES.USER_CHALLENGE,
        attributes: {
          enrollmentStatus: 'enrolled',
          termsAndConditionsAccepted: true,
          challengeMode: challengeTrackingMode,
        },
        relationships: {
          challenge: {
            data: {
              id: challengeId,
              type: CHALLENGE_DATA_TYPES.CHALLENGE,
            },
          },
        },
      },
    }),
    errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
  });
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong while fetching!');
};
