export const createModuleTopics = ({ topics, questions }) =>
  topics.map((topic) => {
    const numOfQuestions = questions.filter(
      (question) => question.topicId === topic.id,
    ).length;

    // This array is used on the progress bar and on the "Question # of #" tagline
    const questionIds = questions
      .filter((question) => question.topicId === topic.id)
      .map((question) => question.id);

    return {
      ...topic,
      numOfQuestions,
      questionIds,
    };
  });
