import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { Text, TextProps } from '../../Typography';

const disabledStyle = { color: 'onSurface.text.subdued' } as const;

const disabled = ({ disabled: isDisabled }: TextProps) =>
  isDisabled ? disabledStyle : {};

/**
 * @deprecated use `LabelText` from `genesis-core` instead
 */

export const ChoiceText: React.FunctionComponent<TextProps> = forwardRef(
  (props, ref) => (
    <Text
      as="span"
      color="onSurface.text.primary"
      fontSize="body1"
      ref={ref}
      css={css({
        'input:disabled ~ &, & ~ input:disabled': disabledStyle,
      })}
      {...disabled(props)}
      {...props}
    />
  )
);

ChoiceText.displayName = 'ChoiceText';
