import React from 'react';
import { Link as RouterLink, LinkProps } from '@leagueplatform/routing';
import {
  useTheme,
  Box,
  HeadingTwo,
  SubtitleOne,
  BodyTwo,
  PrimaryButton,
  SubtleButton,
  Link,
  ButtonProps,
} from '@leagueplatform/genesis-commons';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { useModalParam } from '../../hooks/use-modal-param.hook';
import { MenuItem } from '../../types/care-discovery.types';

interface TopicScreenProps {
  topic: string;
  parentTopicId?: string;
  title: string;
  disclaimer: string;
  menuItems?: MenuItem[];
  footerText: string;
  footerActionText: string;
  footerUrl: string;
}

interface TopicItemLinkProps extends ButtonProps {
  isExternalLink: boolean;
  url: LinkProps['to'] | undefined;
  onClick?: () => void;
  itemTitle: string;
}

const TopicItemLink = ({
  isExternalLink,
  url,
  onClick,
  itemTitle,
}: TopicItemLinkProps) => {
  const { space } = useTheme();

  return (
    <SubtleButton
      color="onSurface.text.primary"
      textDecoration="none"
      borderWidth="none"
      borderColor="interactive.border.disabled"
      borderBottomWidth="thin"
      borderRadius="none"
      paddingX="none"
      width="100%"
      justifyContent="space-between"
      as={isExternalLink ? Link : RouterLink}
      href={isExternalLink ? url : undefined} // for Genesis Link
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
      to={!isExternalLink ? url : undefined} // for Router Link
      onClick={onClick}
    >
      <span>{itemTitle}</span>
      <Icon icon={ICONS.RIGHT_CHEVRON} size={space.one} stroke="currentColor" />
    </SubtleButton>
  );
};

export const TopicScreen = ({
  topic,
  parentTopicId,
  title,
  disclaimer,
  menuItems = [],
  footerText,
  footerActionText,
  footerUrl,
}: TopicScreenProps) => {
  const { linkToModalParam } = useModalParam();

  const getLinkToModalParam = (id: string) => {
    if (parentTopicId) {
      return linkToModalParam(`get-care/${parentTopicId}/${id}`);
    }
    return linkToModalParam(`get-care/${id}`);
  };

  const dispatchTopicItemAnalytics = (itemTitle: string) => {
    sendAnalyticsEvent({
      category: 'Quick Action Detail',
      action: 'Select Question',
      label: `${topic} - ${itemTitle}`,
    });
  };
  return (
    <>
      <HeadingTwo as="h1" marginBottom="oneAndHalf">
        {title}
      </HeadingTwo>
      <BodyTwo marginBottom="oneAndHalf">{disclaimer}</BodyTwo>
      <Box as="ul" marginBottom="oneAndHalf">
        {menuItems.map(({ id, title: itemTitle, url, detailPage }) => {
          const isExternalLink = isAbsoluteUrl(url);

          return (
            <li key={id}>
              <TopicItemLink
                isExternalLink={isExternalLink}
                url={
                  !isExternalLink && detailPage ? getLinkToModalParam(id) : url
                }
                onClick={() => {
                  dispatchTopicItemAnalytics(itemTitle);
                }}
                itemTitle={itemTitle}
              />
            </li>
          );
        })}
      </Box>
      <SubtitleOne as="h2" marginBottom="oneAndHalf">
        {footerText}
      </SubtitleOne>
      <PrimaryButton
        as="a"
        textDecoration="none"
        href={footerUrl}
        onClick={() => {
          sendAnalyticsEvent({
            category: 'Quick Action Detail',
            action: 'Footer Action Button',
            label: footerActionText,
          });
        }}
      >
        {footerActionText}
      </PrimaryButton>
    </>
  );
};
