/* eslint-disable no-console */
import { backOff } from 'exponential-backoff';
import { ConfigStoreStatus } from './store.types';
import { configStore } from './store';
import type { ServerConfig, LeagueConfig } from './league-config-schema';
import { assertConfigSchema } from './assert-config-schema';
import { serverConfigSchema } from './module-config-schemas/server-driven';

/**
 * Client config properties that represent a segmentation
 * of server config.
 */
type SegmentationParameters = {
  clientId: LeagueConfig['core']['clientId'];
  environment: LeagueConfig['core']['appEnvironment'];
  apiVersion: string;
  sdkVersion?: string;
  appVersion?: string;
  browserVersion?: string;
  browserName?: string;
};

function getCacheKey({
  clientId,
  environment,
  apiVersion,
}: SegmentationParameters) {
  return `server-config-${apiVersion}-${clientId}-${environment}`;
}

function getServerConfigURL({ apiVersion }: SegmentationParameters) {
  const apiUrl = configStore.getState().config?.core.api.url;
  if (!apiUrl) throw new Error('No API URL provided in League configuration');
  return `${apiUrl}/${apiVersion}/sdk-configs`;
}

function getSegmentationQueryParams(
  segmentationParams: SegmentationParameters,
) {
  const queryKeysToExtract: Array<keyof SegmentationParameters> = [
    'sdkVersion',
    'appVersion',
    'browserVersion',
    'browserName',
  ];
  const queryParams: string[] = [];

  queryKeysToExtract.forEach((key) => {
    const value = segmentationParams[key];
    if (value) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    }
  });

  return queryParams.join('&');
}

function cacheServerConfig(
  segmentationParameters: SegmentationParameters,
  config: ServerConfig,
) {
  localStorage.setItem(
    getCacheKey(segmentationParameters),
    JSON.stringify(config),
  );
}

function getCachedServerConfig(segmentationParameters: SegmentationParameters) {
  const serverConfigString = localStorage.getItem(
    getCacheKey(segmentationParameters),
  );

  return serverConfigString
    ? (JSON.parse(serverConfigString) as ServerConfig)
    : undefined;
}

async function fetchServerConfig(
  url: string,
  { clientId }: SegmentationParameters,
) {
  let response: Response;
  try {
    response = await fetch(url, {
      headers: { 'X-League-Client-Id': clientId },
    });
  } catch (fetchError) {
    throw Error(`Fetch server driven config failure: ${fetchError}`);
  }

  // Check response status
  if (!response.ok) {
    console.error(
      `Fetch server driven config error response`,
      response.status,
      response.statusText,
    );
    throw Error(
      `Fetch server driven config error response: ${response.status} ${response.statusText}`,
    );
  }

  // Parse JSON response payload
  let serverConfigResponse: ServerConfig;
  try {
    const responseJson = await response.json();
    serverConfigResponse = responseJson.data.attributes;
  } catch (parseError) {
    throw Error(`Error parsing server response as JSON: ${parseError}`);
  }

  assertConfigSchema(serverConfigResponse, serverConfigSchema);

  return serverConfigResponse;
}

/**
 * Retrieves application config from the server, caches it, and stores it in the
 * config store, making it available for SDKs to access
 */
export async function updateServerConfig(
  segmentationParameters: SegmentationParameters,
) {
  const serverConfigUrl = getServerConfigURL(segmentationParameters);
  const queryParams = getSegmentationQueryParams(segmentationParameters);
  const serverConfigUrlWithQueryParams = queryParams
    ? `${serverConfigUrl}?${queryParams}`
    : serverConfigUrl;

  /**
   * Attempt to fetch cached config, if it exists, and set it in config store.
   * Also return the cached config (or undefined) and set the cachedServerConfig
   * const in order to check if cache exists.
   */
  const cachedServerConfig = getCachedServerConfig(segmentationParameters);

  // Fetch server driven config
  if (cachedServerConfig) {
    configStore.setState({
      serverConfig: cachedServerConfig,
      status: ConfigStoreStatus.LoadingWithCache,
    });
  } else {
    configStore.setState({
      serverConfig: undefined,
      status: ConfigStoreStatus.Loading,
    });
  }

  try {
    const freshServerConfig = await backOff(
      () =>
        fetchServerConfig(
          serverConfigUrlWithQueryParams,
          segmentationParameters,
        ),
      {
        startingDelay: 200,
        numOfAttempts: 6,
      },
    );
    configStore.setState({
      serverConfig: freshServerConfig,
      status: ConfigStoreStatus.Success,
    });

    cacheServerConfig(segmentationParameters, freshServerConfig);
  } catch (e) {
    console.error(e);
    if (cachedServerConfig) {
      configStore.setState({ status: ConfigStoreStatus.ErrorWithCache });
    } else {
      configStore.setState({ status: ConfigStoreStatus.Error });
    }
    throw e;
  }
}
