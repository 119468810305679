import { NetworkPlanV2, NetworkPlanV2Raw } from '../types/plan-progress.types';
import { getWalletConfig } from '../../../../../wallet.config';

export const formatPlansArray = (
  plans: NetworkPlanV2Raw[],
): NetworkPlanV2[] => {
  const { enableNoNetworkLiveBalances } = getWalletConfig();

  // TODO: Util functionality is being controlled by a config flag.
  if (!enableNoNetworkLiveBalances) {
    return plans.map((plan) => ({ ...plan, network: plan.network ?? [] }));
  }

  return (
    plans
      // Remove plans that contain null networks
      .filter((plan) => plan.network !== null)
      // if network is undefined make it an empty array
      .map((plan) => ({ ...plan, network: plan.network ?? [] }))
  );
};
