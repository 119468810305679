import {
  getUserSettings,
  GET_USER_SETTINGS_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import { useQuery } from 'react-query';
import { useWalletParams } from '../use-wallet-params.hook';

interface UserSettingsResponse {
  show_dependents_menu_item: boolean;
  marketplace_url: string;
}

export function useUserSettingsQuery<TSelect = UserSettingsResponse>(
  select?: (data: UserSettingsResponse) => TSelect,
) {
  const { userId } = useWalletParams();

  return useQuery(
    [GET_USER_SETTINGS_MESSAGE_TYPE, userId],
    () => getUserSettings(userId),
    {
      select,
    },
  );
}
