import { getMasonryWSRootNode } from '@leagueplatform/masonry-api';
import type { MasonryWSDriverConfig } from '@leagueplatform/masonry-api';
import {
  createMasonryEngineActionController,
  type MasonryEngineDriver,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { MasonryWSDriverNodeRenderersRegistry } from './masonry-WS-driver-node-renderers-registry';

type ArbitraryMasonryEngineNode = MasonryEngineNode<string, any>;

// Passing an empty action handler map for Masonry WS driver, as this driver
// works independent of action controller.
export const MasonryWSDriverActionController =
  createMasonryEngineActionController({});

export const getMasonryWSDriver =
  (
    config: MasonryWSDriverConfig,
  ): MasonryEngineDriver<ArbitraryMasonryEngineNode, any> =>
  ({ children }) =>
    children(
      getMasonryWSRootNode(config),
      MasonryWSDriverNodeRenderersRegistry.getNodeRenderersMap(),
      MasonryWSDriverActionController,
    );
