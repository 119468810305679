import { useUserSettingsQuery } from 'hooks/queries/use-user-settings-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { TransactionGroup } from '../types/transactions.types';
import { useTransactionsQuery } from './queries/use-transactions-query.hook';

export interface UseTransactionsReturn {
  transactions: TransactionGroup[];
  hasNextPage?: boolean;
  lifemarketUrl?: string;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
}

export const useTransactions = (): UseTransactionsReturn => {
  const { userId, benefitId } = useWalletParams();
  const productTypeQuery = useWalletDetailQuery(userId, benefitId, undefined, {
    select: (data) => data.product_type,
  });
  const productType = productTypeQuery.data as string;

  // TODO: replace with user profile module when possible
  const { data: lifemarketUrl = '' } = useUserSettingsQuery(
    (data) => data.marketplace_url,
  );

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useTransactionsQuery({
      productType,
    });

  return {
    isLoading,
    isFetchingNextPage,
    lifemarketUrl,
    fetchNextPage,
    hasNextPage,
    // TODO: fix these types
    transactions: data as TransactionGroup[],
  };
};
