import React from 'react';
import {
  Flex,
  Box,
  HeadingFour,
  BodyOneSecondary,
  Link,
  Image,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { DEPENDENTS_ASSET_KEYS } from '../../types/dependents.types';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';

function learnMoreClick(url: string) {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.WALLET,
    screen_name: 'dependents settings',
    detail: 'League family - learn more',
    link: url,
    text_detail: 'League family education banner',
    text_sub_detail: 'League family help centre article',
  });
}

interface LeagueFamilyEducationBannerProps {
  url: string;
}

export const LeagueFamilyEducationBanner = ({
  url,
}: LeagueFamilyEducationBannerProps) => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      as="section"
      width={1}
      marginBottom="oneAndHalf"
      borderStyle="solid"
      borderWidth="thin"
      borderRadius="medium"
      justifyContent="space-between"
      padding="two"
    >
      <Box width="70%">
        <HeadingFour marginBottom="half" as="h2">
          {formatMessage({
            id: 'LEAGUE_FAMILY_EDUCATION_BANNER_HEADER',
          })}
        </HeadingFour>
        <BodyOneSecondary marginBottom="oneAndHalf">
          {formatMessage({
            id: 'LEAGUE_FAMILY_EDUCATION_BANNER_SUBHEADER',
          })}
        </BodyOneSecondary>
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => learnMoreClick(url)}
        >
          {formatMessage({ id: 'LEARN_MORE' })}
          <VisuallyHidden>
            {formatMessage({ id: 'LEARN_MORE_LEAGUE_FAMILY' })}
          </VisuallyHidden>
        </Link>
      </Box>
      <Image
        src={
          handleStaticAsset(
            DEPENDENTS_ASSET_KEYS.LEAGUE_FAMILY_EDUCATION_BANNER,
          ) as string
        }
        width={128}
        height={128}
        alt=""
      />
    </Flex>
  );
};
