import { useState, useEffect } from 'react';

// This implmentation is taken and modified from, see article for how to use:
// https://www.joshwcomeau.com/react/prefers-reduced-motion/

const QUERY = '(prefers-reduced-motion: no-preference)';

const getInitialState = () => !window.matchMedia(QUERY).matches;

/**
 * Checks the users browser/OS motion preference
 * Will return True if user prefers reduced motion
 * [MDN Documentation](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion)
 */
export function usePrefersReducedMotion() {
  const [prefersReducedMotion, setPrefersReducedMotion] =
    useState(getInitialState);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);

    const listener = (event: MediaQueryListEvent) => {
      setPrefersReducedMotion(!event.matches);
    };
    mediaQueryList.addEventListener('change', listener);
    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, []);

  return prefersReducedMotion;
}
