import {
  ColorProps,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';

interface StyledPathProps {
  fillColor?: ColorProps['backgroundColor'] | ColorProps['color'];
  strokeColor?: ColorProps['backgroundColor'] | ColorProps['color'];
}

export const StyledPath = genesisStyled.path(
  ({ fillColor, strokeColor }: StyledPathProps) =>
    css({
      fill: fillColor,
      ...(strokeColor && { stroke: strokeColor }),
    }),
);
