import { useConfigProperty } from '@leagueplatform/core';
import {
  GDSStatus,
  ParagraphText,
  StatusBanner,
  TextAction,
} from '@leagueplatform/genesis-core';
import { BenefitDetailBanner } from '@leagueplatform/wallet-api';
import React from 'react';

const DetailBanner = ({ banner }: { banner: BenefitDetailBanner }) => {
  const {
    link: { url: linkUrl, text: linkText } = {},
    description,
    title,
    banner_type: bannerType,
    icon: { url: iconUrl } = {},
  } = banner;

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const handleLinkClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (!linkUrl) {
        return;
      }

      event.preventDefault();
      handleLinkCallback(linkUrl);
    },
    [handleLinkCallback, linkUrl],
  );

  return (
    <StatusBanner
      /* Icon is optional BE value as StatusBanner can automatically apply icons based on `status` (and app theme) */
      icon={iconUrl}
      showIcon
      status={bannerType as GDSStatus}
      title={title}
    >
      <ParagraphText>{description}</ParagraphText>
      {linkUrl && (
        <TextAction
          css={{ maxWidth: 'fit-content' }}
          href={linkUrl}
          indicator="externalLink"
          onClick={handleLinkClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkText}
        </TextAction>
      )}
    </StatusBanner>
  );
};

/* The detail banner is currently only used for pre-effectuated/pre-effective scenarios on the medical benefit details page */
/* Pre-effectuated means the client hasn't paid for coverage yet; Pre-effective means the coverage start date is in the future */
/* For current use cases, we render the first banner passed from the BE */
export const DetailHeaderBanner = ({
  banners = [],
}: {
  banners?: BenefitDetailBanner[];
}) => {
  if (!banners.length) {
    return null;
  }

  return <DetailBanner banner={banners[0]} />;
};
