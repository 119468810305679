{
  "dragDescriptionKeyboard": "Πατήστε Enter για έναρξη της μεταφοράς.",
  "dragDescriptionKeyboardAlt": "Πατήστε Alt + Enter για έναρξη της μεταφοράς.",
  "dragDescriptionLongPress": "Πατήστε παρατεταμένα για να ξεκινήσετε τη μεταφορά.",
  "dragDescriptionTouch": "Πατήστε δύο φορές για έναρξη της μεταφοράς.",
  "dragDescriptionVirtual": "Κάντε κλικ για να ξεκινήσετε τη μεταφορά.",
  "dragItem": "Μεταφορά {itemText}",
  "dragSelectedItems": "Μεταφορά σε {count, plural, one {# επιλεγμένο στοιχείο} other {# επιλεγμένα στοιχεία}}",
  "dragSelectedKeyboard": "Πατήστε Enter για να σύρετε {count, plural, one {# επιλεγμένο στοιχείο} other {# επιλεγμένα στοιχεία}}.",
  "dragSelectedKeyboardAlt": "Πατήστε Alt + Enter για να σύρετε {count, plural, one {# επιλεγμένο στοιχείο} other {# επιλεγμένα στοιχεία}}.",
  "dragSelectedLongPress": "Πατήστε παρατεταμένα για να σύρετε {count, plural, one {# επιλεγμένο στοιχείο} other {# επιλεγμένα στοιχεία}}.",
  "dragStartedKeyboard": "Η μεταφορά ξεκίνησε. Πατήστε το πλήκτρο Tab για να μεταβείτε σε έναν προορισμό απόθεσης και, στη συνέχεια, πατήστε Enter για απόθεση ή πατήστε Escape για ακύρωση.",
  "dragStartedTouch": "Η μεταφορά ξεκίνησε. Μεταβείτε σε έναν προορισμό απόθεσης και, στη συνέχεια, πατήστε δύο φορές για απόθεση.",
  "dragStartedVirtual": "Η μεταφορά ξεκίνησε. Μεταβείτε σε έναν προορισμό απόθεσης και, στη συνέχεια, κάντε κλικ ή πατήστε Enter για απόθεση.",
  "dropCanceled": "Η απόθεση ακυρώθηκε.",
  "dropComplete": "Η απόθεση ολοκληρώθηκε.",
  "dropDescriptionKeyboard": "Πατήστε Enter για απόθεση. Πατήστε Escape για ακύρωση της μεταφοράς.",
  "dropDescriptionTouch": "Πατήστε δύο φορές για απόθεση.",
  "dropDescriptionVirtual": "Κάντε κλικ για απόθεση.",
  "dropIndicator": "δείκτης απόθεσης",
  "dropOnItem": "Απόθεση σε {itemText}",
  "dropOnRoot": "Απόθεση σε",
  "endDragKeyboard": "Μεταφορά σε εξέλιξη. Πατήστε Enter για ακύρωση της μεταφοράς.",
  "endDragTouch": "Μεταφορά σε εξέλιξη. Πατήστε δύο φορές για ακύρωση της μεταφοράς.",
  "endDragVirtual": "Μεταφορά σε εξέλιξη. Κάντε κλικ για ακύρωση της μεταφοράς.",
  "insertAfter": "Εισαγωγή μετά από {itemText}",
  "insertBefore": "Εισαγωγή πριν από {itemText}",
  "insertBetween": "Εισαγωγή μεταξύ {beforeItemText} και {afterItemText}"
}
