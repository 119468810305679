import { useEffect } from 'react';
import { GA_EVENT } from './constants';
import { sendAnalyticsPageView } from './analytics';
import type { SendAnalyticsPageViewParams } from './analytics.types';

/**
 * React hook to capture a page view analytics event on React component mount
 * @param {string} name - Analytics event name
 * @param {Object} params - Analytics event payload
 */
export const usePageViewAnalytics = (
  params: SendAnalyticsPageViewParams,
  dependencies: Array<unknown> = [],
  name: string = GA_EVENT,
) => {
  if (!Array.isArray(dependencies))
    throw new Error('Provided hook dependencies must be an array');

  useEffect(() => {
    sendAnalyticsPageView(params, name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
