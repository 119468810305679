export const focusOutlineOuter = {
  outline: '$borderWidths$outerFocus solid $colors$interactiveFocusOuter',
  outlineOffset: '$borderWidths$outerFocus',
};

export const focusOutlineInner = {
  boxShadow:
    'inset 0 0 0 $borderWidths$innerFocus $colors$interactiveFocusInner',
  // Visible in Windows high-contrast themes
  outline: '$borderWidths$innerFocus  solid transparent',
};
