import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import {
  Modal,
  StackLayout,
  ImageMessage,
  Button,
  HeadingText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { TLP_DETAIL, TLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { MODAL_IMAGE_HEIGHT } from 'constants/challenges.constants';

export interface JoinTeamSuccessModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const JoinTeamSuccessModal = ({
  isOpen,
  onDismiss,
}: JoinTeamSuccessModalProps) => {
  const { formatMessage } = useIntl();
  const highFive = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_HIGH_FIVE,
  ) as string;
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();
  const handleOnClick = () => {
    onDismiss();
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: TLP_SCREEN_NAMES.JOIN_TEAM_SUCCESS,
      detail: TLP_DETAIL.TEAM_DETAILS,
    });
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={onDismiss}>
      <Modal.Content
        css={{
          '.GDS-modal': {
            '@laptop': { width: '50vw' },
            '@desktop': { width: '50vw' },
          },
        }}
      >
        <StackLayout
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
            '@mobileLandscape': 'vertical',
          }}
          verticalAlignment="center"
          horizontalAlignment="center"
          spacing="$oneAndHalf"
        >
          <ImageMessage
            image={highFive}
            imageGap="$oneAndHalf"
            imageHeight={MODAL_IMAGE_HEIGHT}
          />
          <StackLayout
            orientation="vertical"
            horizontalAlignment={{
              '@initial': 'start',
              '@mobile': 'center',
              '@mobileLandscape': 'center',
            }}
            css={{
              flex: 'unset',
              '@mobile': {
                textAlign: 'center',
              },
            }}
          >
            <Modal.Title>
              <HeadingText size="lg" emphasis="base" level="2">
                {formatMessage({ id: 'JOIN_TEAM_SUCCESS_TITLE' })}
              </HeadingText>
            </Modal.Title>
            <Modal.Description>
              <UtilityText css={{ marginBottom: '$threeQuarters' }}>
                {formatMessage({ id: 'JOIN_TEAM_SUCCESS_DETAIL' })}
              </UtilityText>
            </Modal.Description>
            <Button onClick={handleOnClick} width="fillContainer">
              {formatMessage({ id: 'JOIN_TEAM_SUCCESS_BUTTON' })}
            </Button>
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
