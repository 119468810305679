import React from 'react';
import {
  Box,
  BoxProps,
  Link as GenesisLink,
} from '@leagueplatform/genesis-commons';
import { Link as RouterLink } from '@leagueplatform/routing';
import { genesisFocus } from '@leagueplatform/ui-kit';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { getSanitizedURL } from 'utils/get-sanitized-redirect-url.utils';
import { Suggestion } from '../../types/suggestion';
import SuggestionCardContent from './suggestion-card-content.component';

interface SuggestionCardProps extends BoxProps {
  suggestion: Suggestion;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  width?: number | string;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  imageHeight?: number;
  onCardClick: () => void;
}

export default function SuggestionCard({
  suggestion,
  width = '100%',
  imageHeight = 231,
  onCardClick,
  ...wrapperProps
}: SuggestionCardProps) {
  const actionUrl = getSanitizedURL(suggestion.action_url);
  const isExternalLink = isAbsoluteUrl(actionUrl);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Box width={width} marginTop="oneAndHalf" {...wrapperProps}>
      {isExternalLink ? (
        <Box
          as={GenesisLink}
          href={actionUrl}
          onClick={onCardClick}
          display="block"
          textDecoration="none"
          hoverStyle={{
            textDecoration: 'none',
          }}
        >
          <SuggestionCardContent
            suggestion={suggestion}
            imageHeight={imageHeight}
          />
        </Box>
      ) : (
        <Box
          as={RouterLink}
          to={actionUrl}
          onClick={onCardClick}
          display="block"
          focusStyle={genesisFocus}
          textDecoration="none"
          hoverStyle={{
            textDecoration: 'none',
          }}
        >
          <SuggestionCardContent
            suggestion={suggestion}
            imageHeight={imageHeight}
          />
        </Box>
      )}
    </Box>
  );
}
