import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';
import { GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE } from './get-health-goal-programs';

export const getHealthGoalProgramsByCategory = async (
  categoryId: string,
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_HEALTH_GOAL_PROGRAMS_MESSAGE_TYPE,
      info: {
        category_id: categoryId,
        version: 1,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
