import React, { forwardRef } from 'react';
import { Icon, IconSource } from 'components/icon/icon';
import { Spinner } from 'components/spinner/spinner';
import {
  mapResponsiveProp,
  useResponsiveProp,
} from '../../hooks/use-responsive-prop';
import type {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  GDSCustomizableComponent,
  GDSTypographyIntrinsicEmphasis,
  GDSResponsiveProp,
} from '../../types';
import { styled } from '../../theme';
import { useThemeStrings } from '../../hooks/use-theme';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

type GDSTextActionIndicator = 'disclosure' | 'newTabInternal' | 'externalLink';

type GDSTextActionSize = 'medium' | 'large';

export type GDSTextActionProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      as?: 'a' | 'button' | React.ElementType;
      type?: 'button' | 'submit' | 'reset';
      children: React.ReactNode;
      size?: GDSResponsiveProp<GDSTextActionSize>;
      icon?: IconSource;
      iconLabel?: string;
      destructive?: boolean;
      loading?: boolean;
      loadingText?: string;
      indicator?: GDSTextActionIndicator;
      emphasis?: GDSTypographyIntrinsicEmphasis;
    }
  > &
    GDSCustomizableComponent;

export type GDSTextActionComponent = <C extends React.ElementType = 'a'>(
  props: GDSTextActionProps<C>,
) => React.ReactElement | null;

const BaseTextAction = styled('a', {
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none',
  position: 'relative',
  outline: 'none',
  background: 'none',
  border: 'none',
  borderRadius: '$small',
  cursor: 'pointer',
  padding: 0,
  '&:hover': {
    textDecoration: 'underline',
  },
  variants: {
    size: {
      medium: {
        typography: '$buttonTwo',
      },
      large: {
        typography: '$buttonOne',
      },
    },
    emphasis: {
      base: {
        color: '$interactiveActionPrimary',
      },
      subtle: {
        color: '$interactiveActionSubdued',
      },
    },
    destructive: {
      true: {
        color: '$onSurfaceTextCritical',
      },
    },
    loading: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
});

const iconBySize = {
  medium: '20px',
  large: '24px',
};

const indicatorByString = {
  disclosure: 'tinyDisclosureIndicator',
  newTabInternal: 'tinyNewWindowInternal',
  externalLink: 'tinyExternalLink',
};

const getIconByIndicator = (indicator: GDSTextActionIndicator) =>
  indicatorByString[indicator];

export const TextAction: GDSTextActionComponent = forwardRef(
  <C extends React.ElementType = 'a'>(
    {
      as: asElement,
      children,
      className,
      css,
      destructive,
      emphasis = 'base',
      icon,
      iconLabel,
      indicator,
      loading,
      loadingText,
      size = 'large',
      type,
      ...props
    }: GDSTextActionProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const iconSize = mapResponsiveProp<GDSTextActionSize>(size, iconBySize);

    const stringsMap = useThemeStrings();

    const labelByIndicator = {
      disclosure: stringsMap.disclosure,
      newTabInternal: stringsMap.newTabInternal,
      externalLink: stringsMap.externalLink,
    };

    const getLabelByIndicator = (indicatorIcon: GDSTextActionIndicator) =>
      labelByIndicator[indicatorIcon];

    if (emphasis === 'subtle' && destructive) {
      console.error(
        `Destructive is ${destructive} and emphasis is ${emphasis}. TextAction can only be either subtle or destructive.`,
      );
    }

    const defaultType = !type && asElement === 'button' ? 'button' : undefined;

    return (
      <GenesisCoreInspector displayName="TextAction">
        <BaseTextAction
          as={asElement}
          className={['GDS-text-action', className].join(' ')}
          css={css}
          destructive={destructive}
          emphasis={emphasis}
          loading={loading}
          ref={ref}
          size={useResponsiveProp(size)}
          type={type || defaultType}
          {...props}
        >
          {loading && (
            <Spinner
              loading={loading}
              loadingText={loadingText}
              size={iconSize}
              css={{ marginInlineEnd: '$quarter' }}
            />
          )}
          {icon && !loading && (
            <Icon
              icon={icon}
              size={iconSize}
              label={iconLabel}
              css={{ marginInlineEnd: '$quarter' }}
            />
          )}
          {children}
          {indicator && (
            <Icon
              icon={getIconByIndicator(indicator)}
              size="$one"
              css={{ marginInlineStart: '$quarter' }}
              label={getLabelByIndicator(indicator)}
            />
          )}
        </BaseTextAction>
      </GenesisCoreInspector>
    );
  },
);

// @ts-ignore
TextAction.displayName = 'TextAction';
