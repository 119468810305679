import React, { forwardRef } from 'react';
import css from '@styled-system/css';
import { Box, BoxProps } from '../Box';
import { Flex, FlexProps } from '../Flex';

interface LabelProps {
  disabled?: boolean;
}
interface LabelBox extends BoxProps {
  display?: 'block' | 'inline' | 'inline-block';
}
interface LabelFlex extends FlexProps {
  display: 'flex';
}

type LabelWithBoxOrFlexProps = (LabelBox | LabelFlex) & LabelProps;

const labelProps = ({ disabled }: LabelProps) =>
  ({
    as: 'label',
    fontSize: 'body1',
    css: css({ cursor: disabled ? 'default' : 'pointer' }),
  } as const);

/**
 * @deprecated use `LabelText` from `genesis-core` instead
 */

export const Label: React.FunctionComponent<LabelWithBoxOrFlexProps> =
  forwardRef(
    ({ children, display = 'block', ...props }: LabelWithBoxOrFlexProps, ref) =>
      display === 'flex' ? (
        <Flex alignItems="center" ref={ref} {...labelProps(props)} {...props}>
          {children}
        </Flex>
      ) : (
        <Box display={display} ref={ref} {...labelProps(props)} {...props}>
          {children}
        </Box>
      )
  );

Label.displayName = 'Label';
