import React from 'react';
import { Link as RouterLink } from '@leagueplatform/routing';
import {
  HeadingText,
  StackLayout,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

export const NoRecentAchievements = () => {
  const { formatMessage } = useIntl();
  const sendViewAchievementsPageAnalytics = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: 'rewards',
      sub_product_area: 'achievements',
      screen_name: 'health journey activities',
      detail: 'view all achievements',
    });
  };
  return (
    <StackLayout
      orientation="vertical"
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{
        backgroundColor: '$surfaceBackgroundSecondary',
        padding: '$oneAndHalf',
      }}
      data-testid="no-recent-achievements-container"
    >
      <HeadingText
        level="3"
        size="xs"
        css={{
          marginBottom: '$half',
          letterSpacing: '-0.2px',
        }}
      >
        {formatMessage({ id: 'NO_PROGRAMS' })}
      </HeadingText>
      <UtilityText
        css={{
          typography: '$bodyTwoSecondary',
          textAlign: 'center',
          marginBottom: '$one',
          color: '$onSurfaceTextSubdued',
        }}
      >
        {formatMessage({ id: 'NO_ACHIEVEMENTS_DESCRIPTION' })}
      </UtilityText>
      <TextAction
        as={RouterLink}
        to={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
        onClick={sendViewAchievementsPageAnalytics}
        size="medium"
      >
        {formatMessage({ id: 'VIEW_ALL_ACHIEVEMENTS' })}
      </TextAction>
    </StackLayout>
  );
};
