import React from 'react';
import {
  InternalOrExternalLink,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { HtmlToReact, HTMLTagOptions } from '@leagueplatform/web-common';
import { Image } from '@leagueplatform/genesis-commons';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useWalletDetailQuery } from 'pages/wallet-detail/hooks/queries/use-wallet-detail-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';

const htmlToReactOptionsOverride: HTMLTagOptions = {
  a: {
    component: InternalOrExternalLink,
  },
};

export const AboutBenefit = () => {
  const { userId, benefitId } = useWalletParams();
  const { data: aboutData, isLoading } = useWalletDetailQuery(
    userId,
    benefitId,
    undefined,
    {
      select: (data) => ({
        description: data.description,
        featuredImage: data.featured_image,
      }),
    },
  );

  if (isLoading) {
    return <LoadingIndicator inline />;
  }

  return (
    <StackLayout spacing="$one">
      {aboutData?.featuredImage && (
        <Image
          src={aboutData?.featuredImage?.src}
          alt={aboutData?.featuredImage?.description || ''}
          marginBottom="two"
          loading="eager"
          width="auto"
          maxWidth="100%"
        />
      )}
      <HtmlToReact
        htmlString={aboutData?.description}
        options={htmlToReactOptionsOverride}
      />
    </StackLayout>
  );
};
