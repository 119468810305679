import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from '@leagueplatform/routing';
import {
  Flex,
  Spinner,
  genesisStyled,
  css,
  StyleProps,
} from '@leagueplatform/genesis-commons';
import { Box } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { ErrorState } from '@leagueplatform/web-common-components';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import {
  MESSAGING_LINKS,
  HOME_SEARCH_PARAM,
} from 'constants/messaging-links.constants';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { useMessagesPage } from 'hooks/use-messages-page.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useGetDraftThread } from 'hooks/use-get-draft-thread.hook';
import { useDeleteDraftThread } from 'hooks/use-delete-draft-thread.hook';
import { useNewMessageEvent } from 'hooks/use-new-message-event.hook';
import { usePostStartAsyncMessaging } from 'hooks/use-post-start-async-messaging.hook';
import { getFlattenedThreadData } from 'pages/utils/get-flattened-thread-data.util';
import { DISABLE_MESSAGING_QUESTIONNAIRE } from 'constants/feature-flags.constants';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { usePageError } from 'components/page-error-context/page-error-context';
import { useIntl } from '@leagueplatform/locales';
import { MessageHeader } from './components/message-header/message-header.component';
import { MessageBodyWrapper } from './components/message-body-wrapper/message-body-wrapper.component';
import { DetailsSidebar } from './components/details-sidebar/details-sidebar.component';
import { ThreadsSidebar } from './components/threads-sidebar/threads-sidebar.component';
import { useFhirResponseConfig } from '../components/fhir-response-config-context/fhir-response-config-context';

const sidebarStyles = {
  borderRightColor: 'onSurface.border.subdued',
  borderRightWidth: 'thin',
  borderLeftColor: 'onSurface.border.subdued',
  borderLeftWidth: 'thin',
  borderStyle: 'solid',
  maxWidth: 452,
};

const StyledLaptopSidebar = genesisStyled(Flex)<StyleProps>(
  ({ theme, isThreadListEmpty }) => {
    const mobileStyles = isThreadListEmpty
      ? {
          flexGrow: 1,
          maxWidth: '100%',
        }
      : { display: 'none' };
    return css({
      ...sidebarStyles,
      paddingTop: 'two',
      [`@media screen and (max-width: ${theme.breakpoints.laptop})`]: {
        ...mobileStyles,
      },
    });
  },
);

const StyledMobileSidebar = genesisStyled(Flex)<StyleProps>(
  ({ theme, showMobileMessageList }) =>
    css({
      width: '100%',
      display: 'none',
      ...sidebarStyles,
      paddingTop: 'one',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: showMobileMessageList ? 0 : -452,
      backgroundColor: 'interactive.background.default',
      zIndex: 99,
      transition: 'left 0.4s ease',
      visibility: showMobileMessageList ? 'visible' : 'collapse',
      [`@media screen and (max-width: ${theme.breakpoints.laptop})`]: {
        display: 'flex',
      },
    }),
);

const ERROR_MAX_WIDTH = '400px';

export const MessagesPage = () => {
  // States
  const [showModal, setShowModal] = useState(false);
  const [showMobileMessageList, setShowMobileMessageList] = useState(false);
  const [, setConfig] = useFhirResponseConfig();
  const { threadId } = useParams<{ threadId: string }>();

  const draftThread = useGetDraftThread();
  const deleteDraftThread = useDeleteDraftThread();
  const { formatMessage } = useIntl();
  useDocumentTitle(
    formatMessage({ id: 'MESSAGING_MESSAGES_LANDING_PAGE_TITLE' }),
  );

  const {
    mutate,
    isLoading: isLoadingPostStartAsyncMessagingError,
    isError: hasPostStartAsyncMessagingError,
  } = usePostStartAsyncMessaging();
  useEffect(() => {
    if (draftThread.answers.length && !threadId) {
      mutate(draftThread.answers, {
        onSuccess: (data) => {
          setConfig(data);
        },
      });
    } else {
      setConfig(undefined);
    }
  }, [draftThread.answers, mutate, setConfig, threadId]);

  const [pageError] = usePageError();

  // Hooks
  const history = useHistory();
  const searchParams = useSearchParams();
  const { threadsData, isLoading, isError } = useMessagesPage({
    numberOfThreads: 1,
  });

  useNewMessageEvent();

  const flattenedThreadsData = getFlattenedThreadData(threadsData);

  const { data: isQuestionnaireDisabled } = useFeatureFlagQuery(
    DISABLE_MESSAGING_QUESTIONNAIRE,
  );

  // TODO: REMOVE THIS VARIABLE
  const isDraftThread = isQuestionnaireDisabled
    ? !threadId && draftThread.participants.length > 0
    : !threadId && draftThread.answers.length > 0;

  const searchParam = searchParams.get('h') ? HOME_SEARCH_PARAM : '';

  useEffect(() => {
    // Close mobile menu when threadID changes
    setShowMobileMessageList(false);
  }, [threadId]);

  useEffect(() => {
    if (!isDraftThread && !threadId && flattenedThreadsData?.length) {
      const autoselectedRoute = MESSAGING_LINKS.getMessagesForThread(
        flattenedThreadsData[0].id,
        searchParam,
      );
      history.replace(autoselectedRoute);
    }
  }, [flattenedThreadsData, history, threadId, isDraftThread, searchParam]);

  if (isLoading || isLoadingPostStartAsyncMessagingError) {
    return (
      <Box
        css={{
          display: 'flex',
          position: 'absolute',
          inset: 0,
          justifyContent: 'center',
          color: '$decorativeBrandPrimaryDefault',
        }}
      >
        <Spinner isLoading />
      </Box>
    );
  }

  if (isError || hasPostStartAsyncMessagingError || pageError) {
    const errorImage = handleStaticAsset(
      MESSAGING_ASSET_KEYS.MESSAGING_ERROR,
    ) as string;
    return (
      <Box
        css={{
          display: 'flex',
          position: 'absolute',
          inset: 0,
          justifyContent: 'center',
          alignItems: 'center',
          color: '$decorativeBrandPrimaryDefault',
        }}
      >
        <Box css={{ maxWidth: ERROR_MAX_WIDTH }}>
          <ErrorState
            imageSrc={errorImage}
            hideButton
            headingString={formatMessage({ id: 'GENERIC_MESSAGING_ERROR' })}
          />
        </Box>
      </Box>
    );
  }

  const isThreadListEmpty: boolean =
    !flattenedThreadsData?.length && !isDraftThread;
  const displayMessageThreadDetails =
    (!isThreadListEmpty && threadId) || isDraftThread;

  return (
    <Box
      css={{
        display: 'flex',
        width: '100%',
        // maxiWidth is 1400 + 16. The 16 is added to account for an extra 6px margin on either side of the layout.
        // Previously focus states for the messageList items where being cut off.
        maxWidth: '1456px',
        marginX: 'auto',
        marginY: 0,
        overflowY: 'hidden',
        position: 'absolute',
        inset: 0,
      }}
    >
      {/* Sidebar shown with/or without threads present for laptop and above */}
      <StyledLaptopSidebar isThreadListEmpty={isThreadListEmpty}>
        <ThreadsSidebar
          setShowMobileMessageList={setShowMobileMessageList}
          isThreadListEmpty={isThreadListEmpty}
        />
      </StyledLaptopSidebar>
      {displayMessageThreadDetails && (
        <Flex
          flexGrow={1}
          flexBasis="65%"
          flexDirection="column"
          borderRightColor="onSurface.border.subdued"
          borderRightWidth="thin"
          borderStyle="solid"
        >
          <MessageHeader
            setIsDetailsOpen={setShowModal}
            draftSubject={draftThread.subject}
            draftParticipants={draftThread.participants}
            toggleMessageList={setShowMobileMessageList}
            showMobileMessageList={showMobileMessageList}
          />
          <Flex flexDirection="column" flex={1} position="relative">
            {/* Sidebar shown when threads are present for tablet and below */}
            <StyledMobileSidebar showMobileMessageList={showMobileMessageList}>
              <ThreadsSidebar
                setShowMobileMessageList={setShowMobileMessageList}
              />
            </StyledMobileSidebar>
            <MessageBodyWrapper
              showMobileMessageList={showMobileMessageList}
              isDraftThread={isDraftThread}
              draftThread={draftThread}
              resetDraftState={deleteDraftThread}
            />
          </Flex>
        </Flex>
      )}
      {showModal && <DetailsSidebar onClose={setShowModal} />}
    </Box>
  );
};
