import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { styled } from '../../theme';
import { GDSCustomizableComponent } from '../../types';
import { GenesisCoreInspector } from '../../test-utils/genesis-core-inspector';

export interface GDSImageProps
  extends ComponentPropsWithRef<'img'>,
    GDSCustomizableComponent {}

const StyledImage = styled('img', {});

export const Image = forwardRef<HTMLImageElement, GDSImageProps>(
  ({ className, alt = '', ...props }, ref) => (
    <GenesisCoreInspector displayName="Image">
      <StyledImage
        alt={alt}
        className={['GDS-image', className].join(' ')}
        ref={ref}
        {...props}
      />
    </GenesisCoreInspector>
  ),
);
