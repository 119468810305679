import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';
import {
  Box,
  Flex,
  Image,
  genesisStyled,
  css,
  Link,
} from '@leagueplatform/genesis-commons';
import { CARD_STATUS } from '@leagueplatform/wallet-api';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { useWalletConfig } from 'wallet.config';

const HeaderIcon = genesisStyled(Image)(
  css({
    marginRight: 'half',
    width: 16,
    height: 16,
    position: 'relative',
    top: 1,
  }),
);

const Body = genesisStyled(Box)(
  css({
    padding: 'one',
    textAlign: 'center',
    borderBottomLeftRadius: 'small',
    borderBottomRightRadius: 'small',
  }),
);

const dateFormat = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

function dayDistance(a, b) {
  return Math.abs(Math.round((a - b) / 1000 / 60 / 60 / 24));
}

function boldText(msg) {
  return <strong>{msg}</strong>;
}
export function Pending({ currentDate, startDate, endDate }) {
  const { formatMessage, formatDate } = useIntl();
  return (
    <Box marginBottom="two">
      <HeadingText
        level="4"
        size="md"
        css={{
          borderTopLeftRadius: '$small',
          borderTopRightRadius: '$small',
          textTransform: 'uppercase',
          textAlign: 'center',
          padding: 'half',
          verticalAlign: 'baseline',
          backgroundColor: '$highlightIcon',
          color: '$onSurfaceTextReversed',
        }}
      >
        <HeaderIcon
          alt=""
          src={handleStaticAsset(WALLET_ASSET_KEYS.CLOCK_ICON_WHITE)}
        />
        {formatMessage(
          { id: 'BECOMES_ACTIVE_ON_DATE' },
          { date: formatDate(startDate, dateFormat) },
        )}
      </HeadingText>
      <Body backgroundColor="highlight.background.subdued">
        {startDate > currentDate && (
          <HeadingText level="4" size="md" css={{ marginBottom: '$half' }}>
            {formatMessage(
              { id: 'BECOMES_ACTIVE_IN_X_DAYS' },
              { days: dayDistance(currentDate, startDate) },
            )}
          </HeadingText>
        )}
        <ParagraphText size="sm">
          {formatMessage(
            { id: 'YOU_CAN_ONLY_SUBMIT_CLAIMS_IN_DATE_RANGE' },
            {
              strong: boldText,
              dateRange: ` ${formatDate(startDate, dateFormat)} -
                  ${formatDate(endDate, dateFormat)}`,
            },
          )}
        </ParagraphText>
      </Body>
    </Box>
  );
}

Pending.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export function ActivationPending() {
  const { formatMessage } = useIntl();
  return (
    <Box marginBottom="two">
      <HeadingText
        level="4"
        size="md"
        css={{
          borderTopLeftRadius: 'small',
          borderTopRightRadius: 'small',
          textTransform: 'uppercase',
          textAlign: 'center',
          padding: 'half',
          verticalAlign: 'baseline',
          backgroundColor: '$highlightIcon',
          color: '$onSurfaceTextReversed',
        }}
      >
        <HeaderIcon
          alt=""
          src={handleStaticAsset(WALLET_ASSET_KEYS.CLOCK_ICON_WHITE)}
        />
        {formatMessage({ id: 'ACTIVATION_PENDING' })}
      </HeadingText>
      <Body backgroundColor="highlight.background.subdued">
        <HeadingText level="4" size="md" css={{ marginBottom: '$half' }}>
          {formatMessage({
            id: 'YOUR_ACCOUNT_IS_ACTIVE_AND_IN_THE_PROCESS_OF_BEING_SET_UP',
          })}
        </HeadingText>
        <ParagraphText size="sm">
          {formatMessage(
            {
              id: 'IF_YOU_HAVE_AN_URGENT_CLAIM_PLEASE_CONTACT_CUSTOMER_SUPPORT',
            },
            {
              // eslint-disable-next-line react/no-unstable-nested-components
              a: (text) => (
                <Link
                  fontSize="body2"
                  href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
                >
                  {text}
                </Link>
              ),
            },
          )}
        </ParagraphText>
      </Body>
    </Box>
  );
}

// Once ExpiringSoon is used again, use the condition ACTIVE && (inactiveDate && dayDistance(currentDate, endDate) < 30)
export function ExpiringSoon({ currentDate, endDate, inactiveDate }) {
  const { formatMessage, formatDate } = useIntl();
  return (
    <Box marginBottom="two">
      <HeadingText
        level="4"
        size="md"
        css={{
          borderTopLeftRadius: 'small',
          borderTopRightRadius: 'small',
          textTransform: 'uppercase',
          textAlign: 'center',
          padding: 'half',
          verticalAlign: 'baseline',
          backgroundColor: '$onSurfaceTextPrimary',
          color: '$onSurfaceTextReversed',
        }}
      >
        <HeaderIcon
          alt=""
          src={handleStaticAsset(WALLET_ASSET_KEYS.CLOCK_ICON_WHITE)}
        />
        {formatMessage(
          { id: 'EXPIRES_ON_DATE' },
          { date: formatDate(endDate, dateFormat) },
        )}
      </HeadingText>
      <Body backgroundColor="highlight.background.subdued">
        <HeadingText level="4" size="md" css={{ marginBottom: '$half' }}>
          {formatMessage(
            { id: 'YOU_HAVE_X_DAYS_BEFORE_EXPIRY' },
            { days: dayDistance(currentDate, endDate) },
          )}
        </HeadingText>
        <ParagraphText size="sm">
          {formatMessage(
            { id: 'AFTER_EXPIRY_YOU_HAVE_UNTIL_DATE_TO_SUBMIT' },
            {
              strong: boldText,
              inactiveDate: formatDate(inactiveDate, dateFormat),
              endDate: formatDate(endDate, dateFormat),
            },
          )}
        </ParagraphText>
      </Body>
    </Box>
  );
}

ExpiringSoon.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  inactiveDate: PropTypes.instanceOf(Date).isRequired,
};

export function Expired({ currentDate, endDate, inactiveDate }) {
  const { formatMessage, formatDate } = useIntl();
  const daysUntilInactive = dayDistance(currentDate, inactiveDate);
  return (
    <Box marginBottom="two">
      <HeadingText
        level="4"
        size="md"
        css={{
          borderTopLeftRadius: 'small',
          borderTopRightRadius: 'small',
          textTransform: 'uppercase',
          textAlign: 'center',
          padding: 'half',
          verticalAlign: 'baseline',
          backgroundColor: '$warningBorderDefault',
          color: '$onSurfaceTextPrimary',
        }}
      >
        <HeaderIcon
          alt=""
          src={handleStaticAsset(WALLET_ASSET_KEYS.CLOCK_ICON_BLURPLE)}
        />
        {formatMessage(
          { id: 'SUBMIT_PAST_CLAIMS_BY_DATE' },
          { date: formatDate(inactiveDate, dateFormat) },
        )}
      </HeadingText>
      <Body backgroundColor="warning.background.subdued">
        <HeadingText level="4" size="md" css={{ marginBottom: '$half' }}>
          {daysUntilInactive > 1 &&
            formatMessage(
              { id: 'THIS_POLICY_HAS_EXPIRED' },
              { days: daysUntilInactive },
            )}
          {daysUntilInactive <= 1 &&
            formatMessage({ id: 'THIS_POLICY_EXPIRES_TOMORROW' })}
        </HeadingText>
        <ParagraphText size="sm">
          {formatMessage(
            { id: 'YOU_HAVE_UNTIL_DATE_TO_SUBMIT_PAST_CLAIMS' },
            {
              strong: boldText,
              inactiveDate: formatDate(inactiveDate, dateFormat),
              endDate: formatDate(endDate, dateFormat),
            },
          )}
        </ParagraphText>
      </Body>
    </Box>
  );
}

Expired.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  inactiveDate: PropTypes.instanceOf(Date).isRequired,
};

const { CREATED, READY, IN_WAITING_PERIOD, INACTIVE_GRACE_PERIOD, INACTIVE } =
  CARD_STATUS;

function Switch({ status, currentDate, startDate, endDate, inactiveDate }) {
  const {
    showBenefitStatusBannerCreated,
    showBenefitStatusBannerPending,
    showBenefitStatusBannerExpired,
  } = useWalletConfig();

  switch (status) {
    case CREATED:
      return (
        showBenefitStatusBannerCreated && (
          <ActivationPending
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
          />
        )
      );
    case READY:
    case IN_WAITING_PERIOD:
      return (
        showBenefitStatusBannerPending && (
          <Pending
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
          />
        )
      );
    case INACTIVE_GRACE_PERIOD:
    case INACTIVE:
      return (
        showBenefitStatusBannerExpired && (
          <Expired
            currentDate={currentDate}
            endDate={endDate}
            inactiveDate={inactiveDate}
          />
        )
      );
    default:
      return null;
  }
}

export function CardStatus({
  status,
  currentDate,
  startDate,
  endDate,
  inactiveDate,
}) {
  return (
    <Flex flexDirection="column">
      <Switch
        status={status}
        currentDate={currentDate}
        startDate={startDate}
        endDate={endDate}
        inactiveDate={inactiveDate}
      />
    </Flex>
  );
}

CardStatus.propTypes = {
  status: PropTypes.string,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  inactiveDate: PropTypes.instanceOf(Date),
};

CardStatus.defaultProps = {
  startDate: null,
  endDate: null,
  inactiveDate: null,
  status: null,
};

Switch.propTypes = CardStatus.propTypes;
