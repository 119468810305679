/* eslint-disable no-useless-escape */

/**
 * Return true iff value only has numbers.
 * @param {string} value: value to be tested
 */
export const isNumeric = (value) => /^\d+$/.test(value);

/**
 * Return true iff value contains at least one letter and number.
 *
 * In action (with tests!): https://regex101.com/r/RVYvTk/1
 *
 * @param {string} value: value to be tested
 */
export const isAlphanumeric = (value) =>
  /^(?=.*?\d)(?=.*?[a-z])[a-z\d]+$/i.test(value);

export const isPhoneNumber = (value) => {
  if (!value) return false;
  const number = value.replace(/[-\s\(\)]/g, '');
  return /^(\+)?\d{5,15}$/.test(number);
};

const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value) => VALID_EMAIL_REGEX.test(value);
