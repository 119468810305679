import { BASE_ICON_MAP } from './base-icon-map';

export type GDSIconToken = keyof typeof BASE_ICON_MAP;
export type GDSIconName = GDSIconToken | string;
export type GDSIcons = Record<GDSIconName, any>;

export const getThemeIcons = (customIcons?: GDSIcons): GDSIcons => ({
  ...BASE_ICON_MAP,
  ...customIcons,
});
