import React from 'react';
import {
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import {
  SkeletonBox,
  SlideInHorizontal,
} from '@leagueplatform/web-common-components';
import { usePrefersReducedMotion } from '@leagueplatform/web-common';
import { Image } from '@leagueplatform/genesis-commons';
import { FeatureSlideProps } from 'types/features-highlight.type';

const HEADER_BACKGROUND_WIDTH = `calc(100vw*5)`;

const HEADER_HEIGHT = '50vh';

const SlideHeaderBackground = styled(StackLayout, {
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
  backgroundColor: 'transparent',
  '&:before': {
    content: '""',
    zIndex: '-1',
    position: 'absolute',
    top: `calc(${HEADER_HEIGHT} - ${HEADER_BACKGROUND_WIDTH})`,
    left: `calc(50% - (${HEADER_BACKGROUND_WIDTH} / 2))`,
    width: `${HEADER_BACKGROUND_WIDTH}`,
    height: `${HEADER_BACKGROUND_WIDTH}`,
    maxHeight: `${HEADER_BACKGROUND_WIDTH}`,
    borderRadius: HEADER_BACKGROUND_WIDTH,
  },
});

export const FeatureSlideMobile = ({
  description,
  heading,
  image,
  alt,
  isActive = true,
  isLoading = false,
}: FeatureSlideProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  return (
    <>
      <SlideHeaderBackground
        css={{
          '&:before': {
            backgroundColor: '$decorativeBrandSecondaryBrightest',
          },
        }}
      >
        <StackLayout
          horizontalAlignment="center"
          verticalAlignment="center"
          css={{ width: '100%', paddingTop: '$four' }}
        >
          <StackItem>
            {isLoading && <SkeletonBox width="256px" height="256px" />}
            {!isLoading && prefersReducedMotion ? (
              <Image src={image} alt={alt ?? ''} height="256px" width="256px" />
            ) : (
              <SlideInHorizontal
                isActive={isActive}
                duration={500}
                distance="10%"
              >
                <Image
                  src={image}
                  alt={alt ?? ''}
                  height="256px"
                  width="256px"
                />
              </SlideInHorizontal>
            )}
          </StackItem>
        </StackLayout>
      </SlideHeaderBackground>
      <StackLayout
        horizontalAlignment="center"
        spacing="$one"
        css={{
          padding: '$one $two',
          paddingTop: '$three',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {isLoading ? (
          <>
            <SkeletonBox height="40px" />
            <SkeletonBox height="86px" marginTop="one" />
          </>
        ) : (
          <>
            <HeadingText level="2" size="lg">
              {heading}
            </HeadingText>
            <ParagraphText>{description}</ParagraphText>
          </>
        )}
      </StackLayout>
    </>
  );
};
