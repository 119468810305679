import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { leagueFont } from '../utils/font';
import { fontSizeInPx, spaceInPx } from '../theme';

const HeadingBase = (props) => {
  const Tag = `h${props.level}`;
  return <Tag {...props} />;
};

const getFontSize = ({ level }) => {
  switch (level) {
    case 1:
      return fontSizeInPx(5);
    case 2:
      return fontSizeInPx(4);
    case 3:
      return fontSizeInPx(3);
    default:
      return fontSizeInPx(2);
  }
};

// text align below shouldn't be here, we don't know where this is affecting
const Heading = styled(HeadingBase)`
  margin-bottom: ${spaceInPx(2)};
  font-size: ${getFontSize};
  ${leagueFont({ medium: true })};
  line-height: 1.5;
`;

Heading.defaultProps = {
  level: 1,
  children: '',
};

Heading.propTypes = {
  /** [1-4] - the level of emphasis */
  level: PropTypes.number,
  /** The content of header */
  children: PropTypes.node,
};

HeadingBase.propTypes = Heading.PropTypes;

Heading.displayName = 'Heading';

export default Heading;
