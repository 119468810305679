import { QuestionnaireItem } from 'fhir/r4';

export const handleItemTypeInteger = (
  item: QuestionnaireItem,
): QuestionnaireItem => item;

export const handleAnswerTypeInteger = (value: string | number) => [
  {
    // Ensuring the returned value is an integer.
    // Required to satisfy TS since the value could `technically` be a string
    valueInteger: Math.trunc(Number(value)),
  },
];
