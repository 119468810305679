import React from 'react';
import { Box, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface EarnedTagProps {
  completionAmount: number;
  isBadge?: boolean;
}

export const EarnedTag = ({ completionAmount, isBadge }: EarnedTagProps) => {
  const { formatMessage } = useIntl();

  if (completionAmount > 1) {
    return isBadge ? (
      <Box
        css={{
          position: 'absolute',
          top: 0,
          right: 0,
          maxHeight: '$two',
        }}
      >
        <UtilityText
          as="span"
          css={{
            typography: '$subtitleTwo',
            color: '$onSurfaceTextSubdued',
            letterSpacing: '0.6px',
            textTransform: 'uppercase',
            backgroundColor: '$surfaceBackgroundSecondary',
            borderRadius: '$large',
            borderColor: '$interactiveBorderDisabled',
            paddingX: '$one',
            paddingY: '$half',
          }}
        >
          {formatMessage(
            { id: 'EARNED_X_TIMES' },
            { amount: completionAmount },
          )}
        </UtilityText>
      </Box>
    ) : (
      <UtilityText
        as="span"
        css={{
          backgroundColor: '$surfaceBackgroundSecondary',
          borderRadius: '$large',
          borderColor: '$interactiveBorderDisabled',
          color: '$onSurfaceTextSubdued',
          paddingX: '$one',
          paddingY: '$half',
          letterSpacing: '-0.2px',
        }}
      >
        {' '}
        {formatMessage(
          { id: 'COMPLETED_X_TIMES' },
          { amount: completionAmount },
        )}
      </UtilityText>
    );
  }
  return null;
};
