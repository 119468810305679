import { CHART_VIEW } from 'components/charts/constants/chart-views';
import { addDays, differenceInCalendarDays } from 'date-fns';

type GetChartViewDateProps = {
  date: Date;
  chartView: CHART_VIEW;
};

export function getChartViewDate({ date, chartView }: GetChartViewDateProps) {
  const differenceInDays = differenceInCalendarDays(new Date(), date);
  const numberOfDaysInView = chartView === 'weekly' ? 7 : 30;
  if (differenceInDays < numberOfDaysInView) {
    return date;
  }
  const numberOfDaysToMove = differenceInDays % numberOfDaysInView;
  const chartDateToSet = addDays(date, numberOfDaysToMove);

  return chartDateToSet;
}
