import { useGetUserChallengeRanks } from 'hooks/use-get-user-challenge-ranks.hook';
import { useGetTeamChallengeRanks } from 'pages/challenge-landing-page/hooks/use-get-team-challenge-ranks.hook';

export const useGetLeaderboardData = (
  leaderboardView: number,
  teamsEnabled: boolean,
) => {
  const individualLeaderboardData = useGetUserChallengeRanks();
  const teamLeaderboardData = useGetTeamChallengeRanks(teamsEnabled);

  if (leaderboardView === 0) {
    return individualLeaderboardData;
  }
  return teamLeaderboardData;
};
