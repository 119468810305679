import React, { forwardRef, ReactNode, Ref } from 'react';
import {
  Card,
  ParagraphText,
  StackLayout,
  StackItem,
  HeadingText,
  Box,
} from '@leagueplatform/genesis-core';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import type { NotificationBannerWidgetProps } from './notification-banner-widget.component';

type HeadingElementProps = {
  children: ReactNode;
  url?: string;
  onCardClick?: (e: React.MouseEvent) => void;
};

const HeadingElement = forwardRef(
  (
    { children, url, onCardClick }: HeadingElementProps,
    ref: Ref<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (url)
      return (
        <Box
          as="a"
          onClick={onCardClick}
          href={url}
          ref={ref as Ref<HTMLAnchorElement>}
          css={{
            '&&': { color: '$onSurfaceTextPrimary', textDecoration: 'none' },
          }}
        >
          {children}
        </Box>
      );
    if (onCardClick)
      return (
        <Box
          as="button"
          ref={ref as Ref<HTMLButtonElement>}
          type="button"
          onClick={onCardClick}
          css={{
            '&&': {
              color: '$onSurfaceTextPrimary',
              border: 'none',
              typography: '$subtitleOne',
              background: 'none',
              padding: 0,
            },
          }}
        >
          {children}
        </Box>
      );
    return <span>{children}</span>;
  },
);

export const NotificationBannerContent = forwardRef(
  (
    {
      image,
      imageAltText,
      heading,
      body,
      url,
      onCardClick,
    }: NotificationBannerWidgetProps,
    ref: Ref<HTMLAnchorElement | HTMLButtonElement>,
  ) => (
    <>
      {image && (
        <StackItem verticalAlignment="center">
          <Card.Image
            image={image}
            imageAlt={imageAltText}
            css={{
              width: '$twoAndHalf',
              height: '$twoAndHalf',
            }}
          />
        </StackItem>
      )}
      <StackLayout spacing="$quarter">
        <HeadingText size="sm" level="2">
          <HeadingElement url={url} onCardClick={onCardClick} ref={ref}>
            {heading}
          </HeadingElement>
        </HeadingText>
        <ParagraphText
          emphasis="subtle"
          htmlStringOptions={BodyTextHtmlTagOptions}
        >
          {body}
        </ParagraphText>
      </StackLayout>
    </>
  ),
);
