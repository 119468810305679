{
  "dragDescriptionKeyboard": "按 Enter 鍵以開始拖曳。",
  "dragDescriptionKeyboardAlt": "按 Alt+Enter 鍵以開始拖曳。",
  "dragDescriptionLongPress": "長按以開始拖曳。",
  "dragDescriptionTouch": "輕點兩下以開始拖曳。",
  "dragDescriptionVirtual": "按一下滑鼠以開始拖曳。",
  "dragItem": "拖曳「{itemText}」",
  "dragSelectedItems": "拖曳 {count, plural, one {# 個選定項目} other {# 個選定項目}}",
  "dragSelectedKeyboard": "按 Enter 鍵以拖曳 {count, plural, one {# 個選定項目} other {# 個選定項目}}。",
  "dragSelectedKeyboardAlt": "按 Alt+Enter 鍵以拖曳 {count, plural, one {# 個選定項目} other {# 個選定項目}}。",
  "dragSelectedLongPress": "長按以拖曳 {count, plural, one {# 個選定項目} other {# 個選定項目}}。",
  "dragStartedKeyboard": "已開始拖曳。按 Tab 鍵以瀏覽至放置目標，然後按 Enter 鍵以放置，或按 Escape 鍵以取消。",
  "dragStartedTouch": "已開始拖曳。瀏覽至放置目標，然後輕點兩下以放置。",
  "dragStartedVirtual": "已開始拖曳。瀏覽至放置目標，然後按一下滑鼠或按 Enter 鍵以放置。",
  "dropCanceled": "放置已取消。",
  "dropComplete": "放置已完成。",
  "dropDescriptionKeyboard": "按 Enter 鍵以放置。按 Escape 鍵以取消拖曳。",
  "dropDescriptionTouch": "輕點兩下以放置。",
  "dropDescriptionVirtual": "按一下滑鼠以放置。",
  "dropIndicator": "放置指示器",
  "dropOnItem": "放置在「{itemText}」上",
  "dropOnRoot": "放置在",
  "endDragKeyboard": "拖曳中。按 Enter 鍵以取消拖曳。",
  "endDragTouch": "拖曳中。輕點兩下以取消拖曳。",
  "endDragVirtual": "拖曳中。按一下滑鼠以取消拖曳。",
  "insertAfter": "插入至「{itemText}」之後",
  "insertBefore": "插入至「{itemText}」之前",
  "insertBetween": "插入至「{beforeItemText}」和「{afterItemText}」之間"
}
