import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import {
  Box,
  BoxProps,
  BodyTwo,
  Image,
  Link,
  genesisStyled,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useConfigProperty } from '@leagueplatform/core';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { WALLET_ASSET_KEYS } from 'types/wallet.types';
import { TransactionGroup } from '../types/transactions.types';

const TableCell = (props: BoxProps) => (
  <BodyTwo
    as="td"
    paddingY="one"
    paddingX="quarter"
    verticalAlign="middle"
    borderBottomColor="onSurface.border.subdued"
    borderBottomStyle="solid"
    borderBottomWidth="thin"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const StyledTableCell = genesisStyled(TableCell)`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

interface TransactionProps {
  transaction: TransactionGroup;
  lifemarketUrl?: string;
}

export const Transaction = ({
  transaction: {
    date,
    title,
    amount,
    amountType,
    currency,
    invoiceId,
    claimId,
    referenceId,
    paymentId,
    type,
    accountsList,
    isLifemarketTransaction,
  },
  lifemarketUrl,
}: TransactionProps) => {
  const { formatMessage, $tmap, formatDate, formatNumber } = useIntl();
  const history = useHistory();

  const typeMessage = $tmap({
    CLAIM: 'CLAIM',
    LIFEMARKET: 'LIFEMARKET',
    ALLOCATION: 'ALLOCATION',
    TOP_UP: 'TOP_UP',
    REFUND: 'REFUND',
    ADJUSTMENT: 'ADJUSTMENT',
    ROLLED_OVER: 'ROLLED_OVER',
  });

  const accountMessageMap = $tmap({
    LSA: 'LSA',
    HSA: 'HSA',
    CREDIT_CARD: 'CREDIT_CARD',
    LEAGUE_CREDIT: 'LEAGUE_CREDIT',
    CGA: 'CGA',
    HRA: 'HRA',
  });

  const getLinkDescriptionText = () => {
    if (isLifemarketTransaction) {
      return formatMessage({ id: 'GO_TO_LIFEMARKET' }, { invoiceId });
    }
    if (claimId) {
      return formatMessage({ id: 'GO_TO_CLAIM' }, { title });
    }
    if (paymentId) {
      return formatMessage({ id: 'GO_TO_RECEIPT' }, { title });
    }
    return '';
  };

  const isCredit = amountType === 'credit';
  const isLink = isLifemarketTransaction || claimId || paymentId;

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const handleLinkClick = () => {
    if (isLifemarketTransaction) {
      handleLinkCallback(`${lifemarketUrl}account/orders`);
    } else if (claimId) {
      history.push(`/member/claims/${claimId}?source=wallet`);
    } else if (paymentId) {
      history.push(`/member/receipt/${paymentId}`);
    }
  };

  return (
    <Box as="tr">
      <TableCell minWidth={100}>
        {formatDate(date, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </TableCell>
      <StyledTableCell maxWidth={120} title={referenceId || invoiceId}>
        {referenceId || invoiceId}
      </StyledTableCell>
      <TableCell fontWeight="bold">{title}</TableCell>
      <TableCell>
        {typeMessage(type as any)}
        {accountsList && (
          <BodyTwo>
            (
            {accountsList
              .map((account) => accountMessageMap(account as any))
              .join(', ')}
            )
          </BodyTwo>
        )}
      </TableCell>
      <TableCell
        minWidth={100}
        textAlign="right"
        fontWeight="bold"
        color={
          isCredit ? 'decorative.brand.primary.dark' : 'onSurface.text.primary'
        }
      >
        {amount && (
          <>
            {formatNumber(isCredit ? amount : -amount, {
              style: 'currency',
              currencyDisplay: 'symbol',
              currency,
              minimumFractionDigits: 2,
            })}
          </>
        )}
      </TableCell>
      <TableCell minWidth={30}>
        {isLink && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: use button instead?
          <Link
            href="#"
            display="block"
            textAlign="right"
            onClick={handleLinkClick}
          >
            <VisuallyHidden>{getLinkDescriptionText()}</VisuallyHidden>
            <Image
              role="presentation"
              height={12}
              src={handleStaticAsset(WALLET_ASSET_KEYS.CHEVRON_RIGHT) as string}
            />
          </Link>
        )}
      </TableCell>
    </Box>
  );
};
