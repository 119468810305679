import React from 'react';
import { ErrorState } from '@leagueplatform/web-common-components';
import { LeaderboardTable } from 'components/leaderboard/leaderboard-table.component';
import { useGetTeamUserChallengeRanks } from 'pages/team-landing-page/hooks/use-get-team-user-challenge-ranks.hook';
import { LeaderboardSkeletonLoader } from 'components/leaderboard/leaderboard-skeleton-loader.component';
import { UserChallengeRank } from '@leagueplatform/health-journey-api';

export const TeamLeaderboard = () => {
  const {
    isLoading: isTeamLeaderboardLoading,
    error: teamLeaderboardError,
    data: teamLeaderboardData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetTeamUserChallengeRanks();

  let flattenedTeamLeaderboardData;
  if (teamLeaderboardData?.pages) {
    // this reduce method flattens the multiple pages of leaderboard data to be a flat array of pages
    flattenedTeamLeaderboardData = teamLeaderboardData.pages.reduce(
      (acc: UserChallengeRank[], page) => acc.concat(page.data),
      [],
    );
  }
  if (isTeamLeaderboardLoading) {
    return <LeaderboardSkeletonLoader />;
  }

  if (teamLeaderboardError || !flattenedTeamLeaderboardData) {
    return <ErrorState />;
  }

  return (
    <LeaderboardTable
      leaderboardData={flattenedTeamLeaderboardData}
      displayViewMore={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
