import {
  css,
  Box,
  genesisStyled,
  GenesisTheme,
} from '@leagueplatform/genesis-commons';

const DEFAULT_STYLES = {
  MIN_GRID_ITEM_WIDTH: '250px',
  GRID_COLUMN_COUNT: 3,
};

// Auto-flowing grid layout based on:
// https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/

export const GridContainer = genesisStyled(Box)<{
  theme: GenesisTheme;
  gridColumnCount?: number;
  minGridItemWidth?: string;
}>(
  ({
    theme,
    gridColumnCount = DEFAULT_STYLES.GRID_COLUMN_COUNT,
    minGridItemWidth = DEFAULT_STYLES.MIN_GRID_ITEM_WIDTH,
  }) => {
    const gapCount = gridColumnCount - 1;
    const totalGapWidth = gapCount * 16;
    const maxGridItemWidth = `calc((100% - ${totalGapWidth}px) / ${gridColumnCount})`;

    return css({
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(max(${minGridItemWidth}, ${maxGridItemWidth}), 1fr));`,
      gridGap: `${theme.space.one}px`,
    });
  },
);
