import { throttle } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from '@leagueplatform/routing';
import styled from 'styled-components';
import { Box } from '../primitives.view';

export const Container = styled(Box)`
  height: 100%;
  width: 100%;
`;

function FormStepper({
  routes,
  values,
  touched,
  errors,
  dirty,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleReset,
  setFieldTouched,
  setFieldError,
}) {
  const { path } = useRouteMatch();
  const [upcomingRoute, setUpcomingRoute] = useState(routes[0].path);
  const [backButtonPressed, setBackButtonPressed] = useState(false);

  useEffect(() => {
    const navigationListener = () => {
      throttle(
        250,
        () => {
          // todo: the route seems to remount / re-render before
          // this state is set, so back button does not work
          // on the first try
          setBackButtonPressed(true);
          setUpcomingRoute(null);
        },
        { leading: true },
      );
    };
    window.addEventListener('popstate', navigationListener);
    return () => {
      window.removeEventListener('popstate', navigationListener);
    };
  });

  const changeRoute = (event, next) => {
    event.preventDefault();
    if (!backButtonPressed) {
      setUpcomingRoute(next);
    } else {
      setBackButtonPressed(false);
    }
  };

  function renderRoutes() {
    const routePaths = routes.map((route) => route.path);
    return routes.map((currentRoute, index) => {
      const limit = routes.length - 1;
      const back = index - 1 >= 0 ? index - 1 : 0;
      const next = index + 1 <= limit ? index + 1 : index;
      const Component = currentRoute.component;

      return (
        <Route
          exact
          path={`${path}${currentRoute.path}`}
          key={currentRoute.path}
          render={(props) => (
            <Component
              {...props}
              {...currentRoute.props}
              routes={routes}
              upcomingRoute={upcomingRoute}
              isBrowserBackButtonPressed={backButtonPressed}
              back={(event) => changeRoute(event, `${path}${routePaths[back]}`)}
              advance={(event) =>
                changeRoute(event, `${path}${routePaths[next]}`)
              }
              values={values}
              touched={touched}
              errors={errors}
              dirty={dirty}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          )}
        />
      );
    });
  }

  return (
    <Container>
      <Switch>{renderRoutes(routes)}</Switch>
    </Container>
  );
}

FormStepper.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.shape,
      component: PropTypes.node,
    }),
  ),
  values: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
};

FormStepper.defaultProps = {
  routes: [],
};

export default FormStepper;
