import {
  isFrench as isFrenchFn,
  DEFAULT_LOCALE,
} from '@leagueplatform/locales';
import { UNITED_STATES } from '@leagueplatform/web-common';

// TODO: genericize this
export const learnMoreUrls = {
  canadaFrench:
    'https://help.league.com/fr/faqs/Working-version/CAN/what-is-league-credit',
  canadaEnglish:
    'https://help.league.com/en/faqs/Working-version/CAN/what-is-league-credit',
  usEnglish:
    'https://ushelp.league.com/en/faqs/Working-version/US/what-is-league-credit',
  kpmgFrench:
    'https://kpmghelp.league.com/s/topic/0TO1T0000002gjaWAA/league-credit?language=fr',
  kpmgEnglish:
    'https://kpmghelp.league.com/s/topic/0TO1T0000002gjaWAA/league-credit?language=en_US',
};

export const getLearnMoreUrl = (countryCode: string, isKPMG: boolean) => {
  const isFrench = isFrenchFn(DEFAULT_LOCALE);
  const isAmerican = countryCode === UNITED_STATES;

  if (isAmerican) {
    return learnMoreUrls.usEnglish;
  }

  if (isKPMG) {
    return isFrench ? learnMoreUrls.kpmgFrench : learnMoreUrls.kpmgEnglish;
  }

  return isFrench ? learnMoreUrls.canadaFrench : learnMoreUrls.canadaEnglish;
};
