import { cloneDeep } from 'lodash';
import {
  defaultHTMLTagOptions,
  GDSHTMLTagOptions,
} from './html-to-react.config';

/**
 * This function assumes that when we declare new options for a given tag that we want to maintain any default options that aren't being overwritten
 * @param {Object} options new options object to apply over the default options
 * @returns an options object containing with the new options with defaults
 */

export const applyOptionsWithDefaults = (
  newOptions: GDSHTMLTagOptions,
  defaultOptions: GDSHTMLTagOptions = cloneDeep(defaultHTMLTagOptions),
) =>
  newOptions && typeof newOptions === 'object'
    ? Object.entries(newOptions).reduce(
        (acc, [tag, { component, props = {} }]) => {
          // Handle tags not included as a default

          if (!acc[tag]) {
            // Exit if new tag is added without component
            if (!component) return acc;

            acc[tag] = {
              component,
              props: { ...(props as object) },
            };

            return acc;
          }

          // If a new component is defined for an existing tag
          if (component) {
            acc[tag].component = component;
          }

          // Apply new props
          acc[tag].props = {
            ...(acc[tag]?.props as object),
            ...(props as object),
          };

          return acc;
        },
        defaultOptions as GDSHTMLTagOptions,
      )
    : defaultOptions;
