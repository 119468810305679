import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  SubtitleOne,
  SubtitleTwo,
  CoverImage,
} from '@leagueplatform/genesis-commons';
import { CompoundProgressBar } from '@leagueplatform/ui-kit';
import { arrayToTag } from '@leagueplatform/web-common';
import {
  useApiDelayOverlay,
  ViewTransitionController,
  ApiDelayOverlay,
} from '@leagueplatform/health-journey-common';
import { programProgressBarSegmentFactory } from 'utils/health-programs-progress-bar-factory';
import { HEALTH_PROGRAM_STATUS } from 'constants/health-program-status.constants';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { HEALTH_PROGRAMS_ASSET_KEYS } from 'types/health-programs-asset-map.types';
import { useProgramDetailsAnalytics } from '../hooks/use-program-details-analytics';
import { RemoveProgramModal } from './health-programs-program-details-remove-program-modal.component';
import { MaxEnrolmentModal } from './health-programs-program-details-max-enrolment-modal.component';
import { VerifiableProgramModal } from './health-programs-program-details-verifiable-activity-modal.component';
import { ProgramDetailsSidebarCtaAction } from './program-details-sidebar-cta-action.component';
import { PROGRAM_DETAIL_MODALS } from '../types/program-details-modal.types';

const {
  DELAY_ACTIVE,
  DEVICE_CONNECTED_PROGRAM,
  MAX_ENROLLMENT,
  REMOVE_PROGRAM,
  NONE,
} = PROGRAM_DETAIL_MODALS;
const { ACTIVE, AVAILABLE } = HEALTH_PROGRAM_STATUS;

export const ProgramDetailsSidebar = ({
  status,
  progressPercentages,
  availablePoints,
  requestLeaveHealthProgram,
  requestStartHealthProgram,
  startProgramMaxEnrolmentModalContent,
  programName,
  programId,
  isUserDeviceConnectableProgram,
  userHasSufficientWearablePermissions,
  programDataPoints,
  activityStatusCounts,
  hasError,
  ready,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const programDetailsAnalytics = useProgramDetailsAnalytics(
    programName,
    programId,
    status,
  );
  const { delayOverlayActive } = useApiDelayOverlay();
  const [activeModal, setActiveModal] = useState(NONE);
  const [isActionAwaitingResponse, setIsActionAwaitingResponse] =
    useState(false);

  const onProgramModalClose = () => {
    programDetailsAnalytics.leaveCancel();
    setActiveModal(NONE);
  };

  const onProgramRemove = () => {
    programDetailsAnalytics.leaveConfirm();
    setActiveModal(NONE);
  };

  const userContent = startProgramMaxEnrolmentModalContent;
  const isContentReady = Boolean(userContent);

  useEffect(() => {
    if (isContentReady) {
      setActiveModal(MAX_ENROLLMENT);
    }
  }, [isContentReady]);

  const isProgramActive = status === ACTIVE;
  const isAvailablePointsGreaterThanZero = availablePoints > 0;
  const { total, completed, removed, expired } = activityStatusCounts;
  const missed = removed + expired;

  const progressSegments =
    programProgressBarSegmentFactory(progressPercentages);

  const progressCaption = arrayToTag([
    formatMessage({ id: 'X_OF_Y_COMPLETE' }, { completed, total }),
    missed ? formatMessage({ id: 'X_MISSED' }, { missed }) : null,
  ]);

  const progressCaptionId = `progress-caption-${programId}`;

  const programAddHandler = (campaignMode) => {
    requestStartHealthProgram(campaignMode, {
      onSettled() {
        setIsActionAwaitingResponse(false);
      },
    });
    programDetailsAnalytics.addProgram();
  };

  const onDismissModal = (callback = () => {}) => {
    setIsActionAwaitingResponse(false);
    callback();
  };

  const triggerModalOnAction = () => {
    let isModalActive = false;
    if (status === ACTIVE) {
      setActiveModal(REMOVE_PROGRAM);
      isModalActive = true;
    } else if (status === AVAILABLE && isUserDeviceConnectableProgram) {
      setActiveModal(DEVICE_CONNECTED_PROGRAM);
      isModalActive = true;
    } else {
      setActiveModal(NONE);
    }

    return isModalActive;
  };

  const triggerModalOnApiResponse = useCallback(() => {
    if (status === AVAILABLE) {
      if (delayOverlayActive) {
        setActiveModal(DELAY_ACTIVE);
      } else if (isContentReady) {
        setActiveModal(MAX_ENROLLMENT);
      } else {
        setActiveModal(NONE);
      }
    }
  }, [delayOverlayActive, isContentReady, status]);

  const onSelectAction = () => {
    setIsActionAwaitingResponse(true);
    const isModalActive = triggerModalOnAction();

    if (status === ACTIVE) {
      programDetailsAnalytics.leaveProgram();
    }

    if (status === AVAILABLE && !isModalActive) {
      programAddHandler();
    }
  };

  useEffect(() => {
    if (ready && hasError) {
      setIsActionAwaitingResponse(false);
    }

    // Reset the loading state before redirecting (unloading) the page
    return () => setIsActionAwaitingResponse(false);
  }, [ready, hasError]);

  useEffect(() => {
    triggerModalOnApiResponse();
  }, [triggerModalOnApiResponse]);

  const programDetailsModals = {
    [DELAY_ACTIVE]: {
      view: ApiDelayOverlay,
      props: {
        onClose: () => setActiveModal(NONE),
      },
    },
    [DEVICE_CONNECTED_PROGRAM]: {
      view: VerifiableProgramModal,
      props: {
        onClose: () => onDismissModal(() => setActiveModal(NONE)),
        programAddHandler: (campaignMode) => {
          programAddHandler(campaignMode);
          setActiveModal(NONE);
        },
        userHasSufficientWearablePermissions,
        programDataPoints,
      },
    },
    [MAX_ENROLLMENT]: {
      view: MaxEnrolmentModal,
      props: {
        onClose: () => onDismissModal(() => setActiveModal(NONE)),
        image: userContent?.imageUrl,
        title: userContent?.title,
        description: userContent?.description,
      },
    },
    [REMOVE_PROGRAM]: {
      view: RemoveProgramModal,
      props: {
        requestLeaveHealthProgram,
        programName,
        programId,
        onProgramRemove: () => onDismissModal(onProgramRemove),
        onClose: () => onDismissModal(onProgramModalClose),
      },
    },
  };

  // Static Assets
  const clock = handleStaticAsset(HEALTH_PROGRAMS_ASSET_KEYS.CLOCK);
  const starRibbon = handleStaticAsset(HEALTH_PROGRAMS_ASSET_KEYS.STAR_RIBBON);

  return (
    <React.Suspense fallback={null}>
      <Flex
        flexDirection={{ _: 'column', phone: 'row', laptop: 'column' }}
        justifyContent="space-between"
        alignItems={{ phone: 'center', laptop: 'initial' }}
        maxWidth={{ laptop: '357px' }}
        width="100%"
        padding={{ _: 'two', laptop: 'none' }}
        borderStyle={{ _: 'solid', laptop: 'none' }}
        borderWidth="thin"
        borderColor="onSurface.border.subdued"
        backgroundColor="surface.background.primary"
      >
        <Flex
          flexDirection="column"
          flexGrow="1"
          paddingX={{ laptop: 'one' }}
          paddingY={{ laptop: 'oneAndHalf' }}
          marginBottom={{ _: 'two', phone: 'initial', laptop: 'two' }}
          marginRight={{ phone: 'oneAndHalf', laptop: 'initial' }}
          borderStyle={{ laptop: 'solid' }}
          borderWidth="thin"
          borderColor="onSurface.border.subdued"
          borderRadius="medium"
        >
          {isProgramActive ? (
            <>
              <SubtitleOne
                as="h2"
                marginBottom="oneAndHalf"
                display={{ _: 'none', laptop: 'block' }}
              >
                {formatMessage({ id: 'PROGRESS' })}
              </SubtitleOne>
              <CompoundProgressBar
                aria-describedby={progressCaptionId}
                segments={progressSegments}
                marginBottom="half"
              />
              <SubtitleTwo id={progressCaptionId} as="h3">
                {progressCaption}
              </SubtitleTwo>
            </>
          ) : (
            <>
              <SubtitleOne
                as="h3"
                marginBottom="oneAndHalf"
                display={{ _: 'none', laptop: 'block' }}
              >
                {formatMessage({ id: 'PROGRAM_DETAILS' })}
              </SubtitleOne>
              <Flex
                flexDirection={{ _: 'row', phone: 'column' }}
                justifyContent={!isAvailablePointsGreaterThanZero && 'center'}
              >
                <Flex
                  marginRight={{ _: 'one', phone: 'initial' }}
                  marginBottom={
                    isAvailablePointsGreaterThanZero && {
                      _: 'initial',
                      phone: 'oneAndHalf',
                    }
                  }
                  alignItems="center"
                >
                  <CoverImage
                    src={clock}
                    alt=""
                    marginRight="threeQuarters"
                    width={22}
                    height={21}
                  />
                  <SubtitleTwo as="h4">
                    {`${total} ${formatMessage(
                      {
                        id: 'ACTIVITIES_PLURALIZATION',
                      },
                      { count: total },
                    ).toLowerCase()}`}
                  </SubtitleTwo>
                </Flex>
                {isAvailablePointsGreaterThanZero && (
                  <Flex alignItems="center">
                    <CoverImage
                      src={starRibbon}
                      alt=""
                      marginRight="threeQuarters"
                      size={21}
                    />
                    <SubtitleTwo as="h4">
                      {formatMessage(
                        { id: 'EARN_UP_TO_X_POINTS' },
                        {
                          points: formatNumber(availablePoints, {
                            style: 'decimal',
                          }),
                        },
                      )}
                    </SubtitleTwo>
                  </Flex>
                )}
              </Flex>
            </>
          )}
        </Flex>
        <ProgramDetailsSidebarCtaAction
          isLoading={isActionAwaitingResponse}
          onSelectAction={onSelectAction}
          programStatus={status}
        />
        <ViewTransitionController
          views={programDetailsModals}
          handleActiveView={() => activeModal}
        />
      </Flex>
    </React.Suspense>
  );
};

ProgramDetailsSidebar.propTypes = {
  status: PropTypes.string,
  progressPercentages: PropTypes.shape({
    missed: PropTypes.number,
    completed: PropTypes.number,
  }),
  availablePoints: PropTypes.number,
  requestLeaveHealthProgram: PropTypes.func,
  requestStartHealthProgram: PropTypes.func,
  startProgramMaxEnrolmentModalContent: PropTypes.shape({
    contentForUsersState: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
    }),
    imageUrl: PropTypes.string,
  }),
  isUserDeviceConnectableProgram: PropTypes.bool,
  userHasSufficientWearablePermissions: PropTypes.bool,
  programDataPoints: PropTypes.arrayOf(PropTypes.string),
  programName: PropTypes.string,
  programId: PropTypes.string,
  activityStatusCounts: PropTypes.shape({
    total: PropTypes.number,
    active: PropTypes.number,
    upcoming: PropTypes.number,
    completed: PropTypes.number,
    expired: PropTypes.number,
    removed: PropTypes.number,
  }),
  hasError: PropTypes.bool,
  ready: PropTypes.bool,
};

ProgramDetailsSidebar.defaultProps = {
  status: '',
  progressPercentages: {
    completed: 0,
    missed: 0,
  },
  availablePoints: 0,
  requestLeaveHealthProgram: null,
  requestStartHealthProgram: null,
  startProgramMaxEnrolmentModalContent: null,
  isUserDeviceConnectableProgram: false,
  userHasSufficientWearablePermissions: null,
  programDataPoints: null,
  programName: '',
  programId: '',
  activityStatusCounts: {
    total: 0,
    active: 0,
    upcoming: 0,
    completed: 0,
    expired: 0,
    removed: 0,
  },
  hasError: false,
  ready: false,
};
