import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from '@leagueplatform/routing';
import { useIntl } from 'react-intl';
import {
  Box,
  css,
  Flex,
  genesisStyled,
  BodyOne,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { Thread } from '@leagueplatform/messaging-api';
import { ParticipantsAvatar } from 'components/participants-avatar/participants-avatar';
import { TimeStamp } from 'components/timestamp/timestamp';
import {
  MESSAGING_LINKS,
  HOME_SEARCH_PARAM,
} from 'constants/messaging-links.constants';
import {
  MESSAGING_SCREEN_NAMES,
  trackMessagingAnalyticsEvent,
} from 'utils/track-messaging-analytics.util';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { getParticipantFirstNames } from 'pages/utils/get-participant-firstnames.util';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useRemoveCurrentUser } from 'hooks/use-remove-current-user.hook';
import { useMessageThreadDisplayText } from './use-message-thread-display-text.hook';
import { DRAFT_PLACEHOLDER_ID } from '../generate-draft-thread-display-data.util';

export interface ThreadPreviewProps {
  thread: Thread;
  hasFocus: boolean;
  isEntryPoint?: boolean;
}

const StyledGridRow = genesisStyled(Flex)(({ isSelected }) =>
  css({
    alignItems: 'center',
    borderBottomWidth: 'thin',
    borderBottomColor: 'onSurface.border.subdued',
    borderBottomStyle: 'solid',
    transition: 'background-color .2s',
    ...(isSelected && { backgroundColor: 'interactive.background.pressed' }),
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'interactive.background.hovered',
    },
    '&:active': {
      backgroundColor: 'interactive.background.pressed',
    },
  }),
);
const OverflowText = genesisStyled(BodyOne)`
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ThreadPreview = ({
  thread,
  hasFocus,
  isEntryPoint,
}: ThreadPreviewProps) => {
  const { threadId } = useParams<{ threadId: string }>();
  const history = useHistory();
  const searchParams = useSearchParams();
  const displayText = useMessageThreadDisplayText(thread);
  const cellRef = React.useRef<HTMLElement>(null);
  const { formatMessage } = useIntl();
  const firstRenderRef = React.useRef(true);
  useEffect(() => {
    /*
      TODO: https://everlong.atlassian.net/browse/PCHAT-737
      This useEffect runs on every render. However, to prevent focusing a preview on mount,
      we check if we are on our first render and return early if that's the case.
      This feels a bit hacky and I would like to review in future to come up with a better
      solution. That might mean reworking the the grid navigation handler!
    */
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    if (hasFocus) {
      cellRef.current?.focus();
    }
  }, [hasFocus]);

  const {
    id,
    subject,
    participants: { data: participantsData },
    isRead,
    latestMessage: {
      data: { sentAt },
    },
    threadAvatars,
  } = thread;
  const userNames = getParticipantFirstNames(participantsData);
  const filteredParticipants = useRemoveCurrentUser(participantsData);
  let participantsAvatar;
  if (threadAvatars) {
    participantsAvatar = threadAvatars.map((avatar) => ({
      id: avatar.initials, // TODO: PCHAT-2715 use avatar id here
      initials: avatar.initials,
      photoUrl: avatar.url,
    }));
  } else {
    participantsAvatar = filteredParticipants.map((avatar) => ({
      id: avatar.id,
      initials: avatar.initials,
      photoUrl: avatar.photoUrl,
    }));
  }

  const isDraft = id === DRAFT_PLACEHOLDER_ID;
  const isSelected = threadId ? id === threadId : isDraft;
  const includeHomeParam = searchParams.get('h') || isEntryPoint;
  const searchParam = includeHomeParam ? HOME_SEARCH_PARAM : '';
  const toLink = isDraft
    ? MESSAGING_LINKS.getMessagesForThread('', searchParam)
    : MESSAGING_LINKS.getMessagesForThread(id, searchParam);

  return (
    <StyledGridRow key={id} isSelected={isSelected} role="row">
      <Box role="gridcell" width="100%" padding={2}>
        <Box
          as={Link}
          backgroundColor="transparent"
          border="none"
          display="inline-block"
          paddingX={{ _: 'half', phone: 'oneAndHalf' }}
          paddingY="one"
          textAlign="left"
          textDecoration="none"
          height="100%"
          width="100%"
          tabIndex={hasFocus ? 0 : -1}
          ref={cellRef}
          css={css({
            position: 'relative',
            '::before': {
              content: '""',
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
              borderRadius: 'small',
            },
            ':hover': {
              cursor: 'pointer',
            },
            ':focus': {
              outline: 0,
              borderWidth: 'thin',
              borderColor: 'interactive.focus.outer',
            },
            ':focus::before': {
              boxShadow: 'focusOutline',
            },
          })}
          onClick={() => {
            history.replace(toLink);
            trackMessagingAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              screen_name: MESSAGING_SCREEN_NAMES.MESSAGES,
              detail: 'thread',
            });
          }}
          aria-current={isSelected ? 'page' : 'false'}
        >
          <Flex>
            <Box marginRight="threeQuarters" minWidth="three">
              <ParticipantsAvatar participants={participantsAvatar} />
            </Box>
            <Box width="calc(100% - 60px)">
              <Flex alignItems="center" justifyContent="space-between">
                <OverflowText fontSize="subtitle1" fontWeight="bold">
                  {subject || userNames.join(', ')}
                </OverflowText>
                {!isRead && (
                  <>
                    <VisuallyHidden>
                      {formatMessage({ id: 'UNREAD' })}
                    </VisuallyHidden>
                    <Box
                      backgroundColor="highlight.icon"
                      borderRadius="circle"
                      marginLeft="half"
                      minWidth="threeQuarters"
                      size="threeQuarters"
                    />
                  </>
                )}
              </Flex>
              <Flex alignItems="flex-start" justifyContent="space-between">
                <OverflowText
                  fontSize="body2"
                  fontWeight={isRead ? 'regular' : 'bold'}
                  fontStyle={isDraft ? 'italic' : 'regular'}
                >
                  {displayText}
                </OverflowText>
                {sentAt && <TimeStamp lastActive={sentAt} />}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </StyledGridRow>
  );
};
