import { useEffect, useRef } from 'react';
import { KEY } from '../../constants/keyboard.constants';

const getCurrentRef = (ref) => ref.current ?? ref;

/**
 * Handles UP and DOWN arrow keys managing the focus on a dropdown
 * Also automatically sets focus on the first item in the list
 * if the dropdown is isOpened
 *
 * Example usage:
 * const ListContainer = ({isisOpen}) => {
 *  const refsMenuItems = []
 *  const refContainer = useRef()
 *  useListItemsFocusHook(isisOpen, refsMenuItems)
 *
 *  return (
 *    <ul ref={refContainer} role="menu">
 *    <li ref={node => {
 *      if (node) { // do not set to a null node
 *        refsMenuItems.push(node)
 *      }
 *     role="menuitem"
 *    }}>Some list</li>
 *    </ul>
 *  )
 * }
 *
 * Dropdown component:
 * const Dropdown = () => {
 * const [isisOpen, setIsisOpen] = useState(false)
 *
 * return (
 *  <nav>
 *    <button
 *      onClick={() => setIsisOpen(!isisOpen)}
 *      aria-expanded={isOpen}
 *      aria-haspopup="true"
 *      aria-controls="some_menu"
 *       >toggle dropdown</button>
 *    <ListContainer isisOpen={isisOpen} id="some_menu" />
 *  </nav>
 *  )
 * }
 *
 * @param {*} isOpen - whether the dropdown is isOpen or closed
 * @param {*} refsMenuItems - the refs of all the menu items in the dropdown
 */
export const useListItemsFocusHook = (isOpen, refsMenuItems, refContainer) => {
  const currentFocus = useRef(0);
  useEffect(() => {
    const copyRefContainer = refContainer?.current || null;

    const handleKeyDown = (event) => {
      switch (event.key) {
        case KEY.ARROW_DOWN:
          event.preventDefault();
          if (currentFocus.current < refsMenuItems.length - 1) {
            currentFocus.current += 1;
            const currentRef = getCurrentRef(
              refsMenuItems[currentFocus.current],
            );
            currentRef.focus();
          }
          break;

        case KEY.ARROW_UP:
          event.preventDefault();
          if (currentFocus.current > 0) {
            currentFocus.current -= 1;
            const currentRef = getCurrentRef(
              refsMenuItems[currentFocus.current],
            );
            currentRef.focus();
          }
          break;

        default:
          break;
      }
    };
    if (isOpen && refsMenuItems.length && copyRefContainer) {
      if (refsMenuItems[0].current) {
        refsMenuItems[0].current.focus();
      } else {
        refsMenuItems[0].focus();
      }
      copyRefContainer.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (copyRefContainer) {
        copyRefContainer.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isOpen, refsMenuItems, refContainer]);
};
