import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  genesisStyled,
  ThemeProps,
} from '@leagueplatform/genesis-commons';

interface SkeletonBoxProps extends ThemeProps, BoxProps {}

interface SkeletonCardsProps extends SkeletonBoxProps {
  numCards?: number;
}

export const SkeletonBox: React.FunctionComponent<SkeletonBoxProps> = genesisStyled(
  Box,
)`
    width: ${(props) => props.width || '100%'};
    background-color: ${(props) =>
      props.backgroundColor || props.theme.colors.surface.background.secondary};
    height: ${(props) => props.height};
    border-radius: ${(props) => props.borderRadius || '6px'};
    margin-bottom: ${(props) => props.marginBottom || '0px'};
    margin-top: ${(props) => props.marginTop || '0px'};
    padding-top: ${(props) => props.paddingTop || '0px'};
    padding-bottom: ${(props) => props.paddingBottom || '0px'};
    z-index: 0;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      top: 0;
      transform: translateX(100%);
      width: 100%;
      height: ${(props) => props.height};
      position: absolute;
      z-index: 1;
      animation: slide 2s infinite;
      background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.4) 50%,
          rgba(128, 186, 232, 0) 99%,
          rgba(125, 185, 232, 0) 100%
      );
    }
    @keyframes slide {
      0% {
          transform: translateX(-100%);
      }
      100% {
          transform: translateX(100%);
      }
    }
`;

const SkeletonCardFlex = genesisStyled(Flex)`
  & > *:last-child {
      margin-right: 0em !important;
    }
  }
`;

export const SkeletonCards = ({
  numCards = 1,
  height = '100px',
  ...props
}: SkeletonCardsProps) => {
  const cards = [];

  for (let i = 0; i < numCards; i += 1)
    cards.push(
      <SkeletonBox
        key={i}
        marginRight="one"
        height={height}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />,
    );

  return (
    <SkeletonCardFlex justifyContent="space-between">{cards}</SkeletonCardFlex>
  );
};
