import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { SwitchIndicator } from '@leagueplatform/web-common-components';
import { useChallengesAnalytics } from 'hooks/use-challenges-analytics.hook';
import { CLP_DETAIL, CLP_SCREEN_NAMES } from 'constants/analytics.constants';
import { useDebouncedPatchPrivacyConfigMutation } from 'hooks/use-debounced-patch-privacy-config.hook';

const { CHALLENGES_PRIVACY_SETTINGS } = CLP_SCREEN_NAMES;
const { HIDE_ACTIVITY, SHOW_ACTIVITY } = CLP_DETAIL;
export interface SettingsToggleProps {
  originalShowFeedItems: boolean;
}
export const SettingsToggle = ({
  originalShowFeedItems,
}: SettingsToggleProps) => {
  const { formatMessage } = useIntl();

  const [showFeedItems, setShowFeedItems] = useState(originalShowFeedItems);
  const { trackChallengesAnalyticsEvent } = useChallengesAnalytics();

  useDebouncedPatchPrivacyConfigMutation({
    showFeedItems,
    setShowFeedItems,
    originalShowFeedItems,
  });

  const handleAnalyticsClick = (detail: string) => {
    trackChallengesAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: CHALLENGES_PRIVACY_SETTINGS,
      detail,
    });
  };

  const updateActivitySetting = () => {
    handleAnalyticsClick(!showFeedItems ? SHOW_ACTIVITY : HIDE_ACTIVITY);
    setShowFeedItems(!showFeedItems);
  };

  return (
    <StackLayout
      orientation={{
        '@initial': 'vertical',
        '@laptop': 'horizontal',
        '@desktop': 'horizontal',
      }}
      horizontalAlignment="spaceBetween"
      verticalAlignment="center"
      spacing="$oneAndHalf"
    >
      <UtilityText>
        {formatMessage({ id: 'CHALLENGE_ACTIVITY_DETAIL' })}
      </UtilityText>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        spacing="$oneAndHalf"
        horizontalAlignment={{
          '@initial': 'start',
          '@laptop': 'end',
          '@desktop': 'end',
        }}
      >
        <UtilityText
          as="label"
          emphasis="emphasized"
          htmlFor="show-activity-switch"
          css={{
            color: '$interactiveActionPrimary',
          }}
        >
          {formatMessage({ id: 'SHOW_MY_ACTIVITY' })}
        </UtilityText>
        <SwitchIndicator
          id="show-activity-switch"
          onClick={updateActivitySetting}
          checked={showFeedItems}
        />
      </StackLayout>
    </StackLayout>
  );
};
