import type {
  GDSTheme,
  GDSRem,
  GDSTypographyStyle,
  GDSShadow,
  StringTokens,
  NumberTokens,
  StringOrNumberTokens,
  GDSSpacing,
} from '../theme-types';
import {
  flattenNestedTokens,
  toPx,
  toRem,
  getNegativeSpacing,
  getFontWeightNumberByName,
  getFontWeightTokenByNumber,
} from './utils';
/*
https://stitches.dev/docs/tokens#defining-tokens

"You can define your tokens as part of the createStitches configuration. There are 14 token types available."

  colors,
  space,
  fontSizes,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  sizes,
  borderWidths,
  borderStyles,
  radii,
  shadows,
  zIndices,
  transitions

The following functions format the Figma JSON to the 14 token types above. They also add
two custom types:

  textDecorations
  textTransforms

*/

// Helper functions

const getColors = (themeInput: GDSTheme) => {
  const result: StringTokens = flattenNestedTokens(themeInput?.colors);
  return result;
};

const getRemSpacing = (
  rawSpacing: GDSTheme['spacing'],
  baseSize: number,
): GDSTheme['spacing'] =>
  Object.entries(rawSpacing).reduce(
    (
      remSpacing: Partial<GDSSpacing>,
      [spacingKey, spacingValue]: [keyof GDSSpacing, number],
    ) => ({
      ...remSpacing,
      [spacingKey]: spacingValue / baseSize,
    }),
    {},
  ) as GDSSpacing;

// space, sizes <- theme.spacing
const getSpacing = (
  { spacing: rawSpacing, typography, rem: { baseSize } }: GDSTheme,
  useRems: boolean,
) => {
  const spacing: StringOrNumberTokens = useRems
    ? getRemSpacing(rawSpacing, baseSize)
    : { ...rawSpacing };
  // Our `typography` util needs to alias all of its tokens (ex: `$headingOne) to an existing
  // space token so we define those aliases here
  if (typography) {
    // store a cache of the spacing before we add aliases
    const themeSpace = { ...spacing };
    Object.entries(typography).forEach(
      ([typographyKey, { paragraphSpacing }]: [string, GDSTypographyStyle]) => {
        Object.keys(themeSpace).forEach((spacingKey: string) => {
          // find the spacing token that matches the value of the typography.paragraphSpacing
          // and add an alias to the spacing token

          if (themeSpace[spacingKey] === paragraphSpacing) {
            // set an alias to an existing font token, ex: `$heading`
            spacing[typographyKey] = `$${spacingKey}`;
          }
        });
      },
    );
  }

  return spacing;
};

// fonts <- theme.fonts
const getFonts = (themeInput: GDSTheme) => {
  const fonts: StringTokens = {};
  if (themeInput?.fonts) {
    Object.entries(themeInput.fonts).forEach(
      ([font, value]: [string, string]) => {
        fonts[font] = value;
      },
    );
  }

  if (themeInput?.typography) {
    // store a cache of the fonts before we add the aliases
    const themeFonts = { ...fonts };
    Object.entries(themeInput.typography).forEach(
      ([typographyKey, { fontFamily }]: [string, GDSTypographyStyle]) => {
        Object.keys(themeFonts).forEach((fontKey: string) => {
          // The fontFamily value may be a single font instead of a stack because of the
          // way that the theme is specified for mobile so we search the stack fonts for
          // matching string
          if (themeFonts[fontKey] === fontFamily) {
            // set an alias to an existing font token, ex: `$heading`
            fonts[typographyKey] = `$${fontKey}`;
          }
        });
      },
    );
  }
  return fonts;
};

const getRemForProperty = (
  themeInput: GDSTheme,
  property: keyof GDSRem,
): StringTokens => {
  const remValues: StringTokens = {};
  if (themeInput?.rem?.[property]) {
    Object.entries(themeInput.rem[property]).forEach(
      ([propertySize, value]: string[]) => {
        remValues[propertySize] = value;
      },
    );
  }
  // Handle special cases of `bodyOneSecondary` and `bodyTwoSecondary` with no
  // assigned value in `fontSizes`
  Object.keys(themeInput.typography).forEach((typographyKey: string) => {
    if (!remValues[typographyKey]) {
      Object.keys(remValues).forEach((propertySizeKey: string) => {
        if (typographyKey.indexOf(propertySizeKey) > -1) {
          remValues[typographyKey] = `$${propertySizeKey}`;
        }
      });
    }
  });
  return toRem(remValues);
};

const getValuesForProperty = (
  { typography }: GDSTheme,
  property: keyof GDSTypographyStyle,
) => {
  const tokenValues: StringOrNumberTokens = {};
  if (typography) {
    Object.entries(typography).forEach(
      ([typographyKey, typographyToken]: [string, GDSTypographyStyle]) => {
        const tokenValue = typographyToken[property];
        // alias bodyOneSecondary/bodyTwoSecondary to bodyOne/bodyTwo
        const aliasKey = Object.keys(tokenValues).find(
          (key) =>
            typographyKey.indexOf(key) > -1 &&
            typographyKey.length > key.length,
        );
        tokenValues[typographyKey] = aliasKey ? `$${aliasKey}` : tokenValue;
      },
    );
  }
  return tokenValues;
};

const getPxValuesForProperty = (
  themeInput: GDSTheme,
  property: keyof GDSTypographyStyle,
) => toPx(getValuesForProperty(themeInput, property));

// fontSizes <- theme.rem.fontSizes
const getRemFontSizes = (themeInput: GDSTheme) =>
  getRemForProperty(themeInput, 'fontSizes');

const getPxFontSizes = (themeInput: GDSTheme) =>
  getPxValuesForProperty(themeInput, 'fontSize');

// lineHeights <- theme.rem.lineHeights
const getRemLineHeights = (themeInput: GDSTheme) =>
  getRemForProperty(themeInput, 'lineHeights');

const getPxLineHeights = (themeInput: GDSTheme) =>
  getPxValuesForProperty(themeInput, 'lineHeight');

// letterSpacings <- theme.typography
const getLetterSpacings = (themeInput: GDSTheme) =>
  getPxValuesForProperty(themeInput, 'letterSpacing');

// fontWeights <- theme.typography
const getFontWeights = (themeInput: GDSTheme) => {
  const fontWeights: StringOrNumberTokens = {};
  if (themeInput?.fontWeights) {
    Object.entries(themeInput.fontWeights).forEach(
      ([font, value]: [string, number]) => {
        fontWeights[font] = value;
      },
    );
  }

  if (themeInput?.typography) {
    Object.entries(themeInput.typography).forEach(
      ([typographyKey, { fontWeight }]: [string, GDSTypographyStyle]) => {
        // Sometimes the figma theme defines fontWeight as a simple value
        // not mapped to one of the tokens so we do some fun wrangling to produce
        // the token
        if (typeof fontWeight === 'string') {
          const fontWeightValue = getFontWeightNumberByName(
            fontWeight as string,
          );
          const fontWeightToken = getFontWeightTokenByNumber(fontWeightValue);
          // In the case where the theme has no `fontWeights` property, we wanna
          // add the token/value so we can alias the typography tokens to them
          // ex: { bodyOne: '$regular' }
          if (!fontWeights[fontWeightToken]) {
            fontWeights[fontWeightToken] = fontWeightValue;
          }
          fontWeights[typographyKey] = `$${fontWeightToken}`;
        } else {
          fontWeights[typographyKey] = fontWeight;
        }
      },
    );
  }

  return fontWeights;
};

// textTransforms <- theme.typography
const getTextTransforms = (themeInput: GDSTheme) =>
  getValuesForProperty(themeInput, 'textCase');

// textDecorations <- theme.typography
const getTextDecorations = (themeInput: GDSTheme) =>
  getValuesForProperty(themeInput, 'textDecoration');

// borderWidths <- theme.borderWidths
const getBorderWidths = (themeInput: GDSTheme) => {
  const borderWidths: NumberTokens = {};
  if (themeInput?.borderWidths) {
    Object.entries(themeInput.borderWidths).forEach(
      ([borderWidth, value]: [string, number | undefined]) => {
        if (borderWidth) {
          borderWidths[borderWidth] = value || 0;
        }
      },
    );
  }
  return toPx(borderWidths);
};

// radii <- theme.borderRadii
const getBorderRadii = (themeInput: GDSTheme) => {
  const borderRadii: NumberTokens = {};
  if (themeInput?.borderRadii) {
    Object.entries(themeInput.borderRadii).forEach(
      ([borderRadius, value]: [string, number | undefined]) => {
        if (borderRadius) {
          borderRadii[borderRadius] = value || 0;
        }
      },
    );
  }
  return toPx(borderRadii);
};

// shadows <- theme.shadows
const getShadows = (themeInput: GDSTheme) => {
  const shadows: StringTokens = {};
  if (themeInput?.shadows) {
    Object.entries(themeInput.shadows).forEach(
      ([shadowKey, { x, y, blur, spread, color }]: [string, GDSShadow]) => {
        shadows[shadowKey] = `${x}px ${y}px ${blur}px ${spread}px ${color}`;
      },
    );
  }
  return shadows;
};

// zIndices <- theme.zIndices
const getZIndices = (themeInput: GDSTheme) => {
  const zIndices: StringOrNumberTokens = {};
  if (themeInput?.zIndices) {
    Object.entries(themeInput.zIndices).forEach(
      ([zIndex, value]: [string, number]) => {
        zIndices[zIndex] = value;
      },
    );
  }
  return zIndices;
};

// transitions <- theme.transitions
const getTransitions = (themeInput: GDSTheme) => {
  const transitions: StringOrNumberTokens = {};
  if (themeInput?.transitions) {
    Object.entries(themeInput.transitions).forEach(
      ([transition, value]: [string, number]) => {
        transitions[transition] = value;
      },
    );
  }
  return transitions;
};

export const formatThemeForStitches = (theme: GDSTheme) => {
  const remSpacing = getSpacing(theme, true);
  const pxSpacing = getSpacing(theme, false);
  const rem = {
    space: toRem({
      ...remSpacing,
      ...getNegativeSpacing(remSpacing as NumberTokens),
    }),
    sizes: toRem(remSpacing),
    fontSizes: getRemFontSizes(theme),
    lineHeights: getRemLineHeights(theme),
  };
  const px = {
    space: toPx({
      ...pxSpacing,
      ...getNegativeSpacing(pxSpacing as NumberTokens),
    }),
    sizes: toPx(pxSpacing),
    fontSizes: getPxFontSizes(theme),
    lineHeights: getPxLineHeights(theme),
  };

  return {
    rem,
    px,
    colors: getColors(theme),
    fonts: getFonts(theme),
    fontWeights: getFontWeights(theme),
    letterSpacings: getLetterSpacings(theme),
    textTransforms: getTextTransforms(theme),
    textDecorations: getTextDecorations(theme),
    borderWidths: getBorderWidths(theme),
    radii: getBorderRadii(theme),
    shadows: getShadows(theme),
    zIndices: getZIndices(theme),
    transitions: getTransitions(theme),
  };
};
