import * as React from 'react';
import {
  AxisLeft as StyledAxisLeft,
  AxisLeftProps as StyledAxisLeftProps,
} from '../primitives/AxisLeft';
import { useChart } from '../providers/chart-data-provider';

export type AxisLeftProps = Omit<StyledAxisLeftProps, 'left' | 'scale'>;

/**
 * Applies apply chart context values to the AxisLeft primitive.
 */
export function AxisLeft(props: AxisLeftProps) {
  const { yScale } = useChart();
  return (
    <StyledAxisLeft
      scale={yScale}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
