import React from 'react';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { Icon, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { REWARDS_COMMON_ASSET_KEYS } from '../../asset-map';

export interface RewardPointsIconProps {
  subdued?: boolean;
  altText?: string;
  value: number;
  iconUrl?: string;
  pointsText?: string;
}

export const RewardPointsIcon = ({
  iconUrl,
  subdued,
  altText,
  value,
  pointsText,
}: RewardPointsIconProps) => {
  const pointsIcon = handleStaticAsset(
    REWARDS_COMMON_ASSET_KEYS.REWARDS_PROGRAM_POINTS,
  ) as string;

  const { formatNumber } = useIntl();

  let valueColor = '$onSurfaceTextSuccess';
  if (subdued) {
    valueColor = '$onSurfaceTextSubdued';
  } else if (pointsText) {
    valueColor = '$onSurfaceTextPrimary';
  }

  return (
    <StackLayout
      orientation="horizontal"
      spacing="$quarter"
      verticalAlignment="center"
    >
      <Icon
        css={{ filter: `saturate(${subdued ? 0 : 1})` }}
        size="$one"
        icon={iconUrl || pointsIcon}
        label={altText}
      />

      <UtilityText
        css={{
          color: valueColor,
          fontWeight: subdued ? 'normal' : 'bold',
          fontSize: 'inherit',
          whiteSpace: 'nowrap',
        }}
      >
        {formatNumber(value)}
      </UtilityText>
      <UtilityText
        css={{
          color: '$onSurfaceTextPrimary',
          fontWeight: 'bold',
          fontSize: 'inherit',
        }}
      >
        {pointsText}
      </UtilityText>
    </StackLayout>
  );
};
