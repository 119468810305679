import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { JOURNEY_EXPERIENCE_TEAM } from '@leagueplatform/health-journey-common';
import { ErrorContext } from '@leagueplatform/observability';

export const GET_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE = 'get_health_goal_program';

export const getHealthGoalProgram = async (
  programId: string,
  errorContext?: ErrorContext,
  userProgramId?: string,
  userId?: string,
) =>
  SocketAsFetch.fetch(
    {
      message_type: GET_HEALTH_GOAL_PROGRAM_MESSAGE_TYPE,
      info: {
        program_id: programId,
        user_program_id: userProgramId,
        user_id: userId,
        version: 1,
      },
    },
    {
      errorContext: { ownershipTeam: JOURNEY_EXPERIENCE_TEAM, ...errorContext },
    },
  );
