import * as React from 'react';
import { BenefitContentAction } from 'types/benefit-card-action.types';

/**
 The genesis core tabs require a unique `value` for each tab. Unfortunately, not all wallet tabs have a unique
 identifier (id) or even a guarantee that two tabs won't have the same name. So we concatenate name and tab index
 to create a unique tab identifier.

 We also need to replace spaces from the name with `-` since the value is used as an aria identifier which does not
 allow spaces to be present

*/

type BenefitCardActionTab = BenefitContentAction & {
  tabValue: string;
};

interface UseBenefitCardActionTabs {
  tabs: BenefitCardActionTab[];
  activeTab?: string;
  setActiveTab: (tabValue: string) => void;
}

export function useBenefitCardActionTabs(
  cardActions: BenefitContentAction[],
): UseBenefitCardActionTabs {
  const tabs = cardActions.map((tab, index) => ({
    ...tab,
    tabValue: `${tab.name.replaceAll(' ', '-')}-${index}`,
  }));

  const defaultTabValue =
    tabs.find((tab) => tab.isDefaultSelected)?.tabValue ?? tabs[0]?.tabValue;

  const [activeTab, setActiveTab] = React.useState<string>(defaultTabValue);

  return {
    activeTab: activeTab ?? defaultTabValue,
    setActiveTab,
    tabs,
  };
}
