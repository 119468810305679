import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { throttle } from 'lodash/fp';
import { CloseButton } from './image-viewer-buttons.view';
import { ImageViewerToolbar } from './image-viewer-toolbar.view';
import {
  ImageViewerCarousel,
  ImageViewerCarouselPropTypes,
} from './image-viewer-carousel.view';

export class ImageViewer extends React.Component {
  state = {
    scale: 1,
    rotation: 0,
    isDragged: false,
    initDistanceTop: 0,
    initDistanceLeft: 0,
    positionLeft: null,
    positionTop: 0,
  };

  componentDidMount() {
    this.carouselRef.addEventListener('mousedown', this.handleStartDrag);
    document.addEventListener('mouseup', this.handleDrop);
    document.addEventListener('mousemove', this.handleDrag);
    window.addEventListener('resize', this.handleResize);
  }

  onZoom = (direction) => {
    this.setState((state) => ({
      scale: Math.max(0.125, state.scale + direction * 0.025),
    }));
  };

  onRotate(direction = 1) {
    this.setState((state) => ({
      rotation: state.rotation + 90 * direction,
    }));
  }

  onReset = () => {
    this.setState({ scale: 1, rotation: 0, isDragged: false });
  };

  getStyle = (left) =>
    left === null
      ? { top: '0px', right: '0px' }
      : {
          top: `${this.state.positionTop}px`,
          left: `${this.state.positionLeft}px`,
        };

  handleStartDrag = (e) => {
    this.dragging = true;
    const bound = this.carouselRef.getBoundingClientRect();

    this.setState(() => ({
      initDistanceTop: e.clientY - bound.top,
      initDistanceLeft: e.clientX - bound.left,
    }));
  };

  handleDrag = throttle(50, (e) => {
    if (this.dragging) {
      this.setState(() => ({
        positionTop: e.clientY - this.state.initDistanceTop,
        positionLeft: e.clientX - this.state.initDistanceLeft,
        isDragged: true,
      }));
    }
  });

  handleResize = () => {
    if (
      this.carouselRef &&
      window.innerWidth <= this.carouselRef.getBoundingClientRect().right
    ) {
      this.setState(() => ({
        positionLeft: window.innerWidth - this.carouselRef.offsetWidth,
      }));
    }
  };

  handleDrop = () => {
    this.dragging = false;
  };

  handleMouseEnter = () => {
    this.carouselRef.addEventListener('wheel', this.handleMouseScroll);
    document.body.style.overflow = 'hidden';
  };

  handleMouseOut = () => {
    this.carouselRef.removeEventListener('wheel', this.handleMouseScroll);
    document.body.style.overflow = 'auto';
  };

  handleMouseScroll = throttle(50, (e) => {
    let direction = 0;

    if (e.wheelDelta) {
      direction = e.wheelDelta > 0 ? 1 : -1;
    } else if (e.detail) {
      direction = e.detail > 0 ? 1 : -1;
    }

    if (direction !== 0) {
      this.onZoom(direction);
    }
  });

  handleChange = (direction) => {
    // math to increment activeimgindex cyclically across the doc's images
    const { activeIndex, images } = this.props;
    const index = (activeIndex + images.length + direction) % images.length;

    if (index !== activeIndex) {
      this.props.onChange(index);
      this.onReset();
    }
  };

  render() {
    const { onClose, onChange, activeIndex, ...carouselProps } = this.props;
    const { scale, rotation, isDragged, positionLeft } = this.state;

    return (
      <div>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon="times" color="white" />
        </CloseButton>
        <ImageViewerCarousel
          carouselRef={(el) => {
            this.carouselRef = el;
          }}
          handleMouseOut={this.handleMouseOut}
          handleMouseEnter={this.handleMouseEnter}
          scale={scale}
          rotation={rotation}
          activeIndex={activeIndex}
          {...carouselProps}
          style={this.getStyle(positionLeft)}
          isDragged={isDragged}
        />
        <ImageViewerToolbar
          zoomIn={() => this.onZoom(2)}
          zoomOut={() => this.onZoom(-2)}
          reset={this.onReset}
          rotateRight={() => this.onRotate(1)}
          rotateLeft={() => this.onRotate(-1)}
          next={() => this.handleChange(1)}
          prev={() => this.handleChange(-1)}
        />
      </div>
    );
  }
}

ImageViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ...ImageViewerCarouselPropTypes,
};
