import React from 'react';
import { Box, Flex, Image, SubtitleOne } from '@leagueplatform/genesis-commons';
import {
  TeamChallengeRank,
  UserChallengeRank,
} from '@leagueplatform/health-journey-api';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import { formatUserName } from 'utils/format-user-name.util';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { isTeamRank } from 'components/leaderboard/utils/is-team-rank.util';
import { ChallengeUnitIcon } from 'components/leaderboard/challenge-unit-icon.component';
import { Badge } from '@leagueplatform/web-common-components';
import { getRankColors } from '../utils/get-rank-colors.util';

const FIRST_PLACE_SIZE = { _: 90, phone: 120 };
const NON_FIRST_PLACE_SIZE = { _: 75, phone: 95 };

export interface PodiumMedalistProps {
  user: UserChallengeRank | TeamChallengeRank;
  order: number;
}

export const PodiumMedalist = ({ user, order }: PodiumMedalistProps) => {
  let teamName = '';
  let firstName = '';
  let lastName = '';
  const { rank, avatarUrl, score, rankingUnit } = user;

  if (isTeamRank(user)) {
    teamName = user.teamName;
  } else {
    firstName = user.firstName;
    lastName = user.lastName;
  }

  const { backgroundColor, backgroundColorCore } = getRankColors(rank);
  const formattedName = firstName?.length
    ? formatUserName({ firstName, lastName })
    : teamName;

  const isFirst = rank === 1;
  const crown = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_CROWN,
  ) as string;

  return (
    <Flex
      as="li"
      width="33%"
      marginTop={rank > 1 ? '53px' : 'none'}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      marginX={{ _: 'quarter', phone: 'half', tablet: 'oneAndHalf' }}
      order={order}
    >
      {isFirst && (
        <Image src={crown} alt="" marginBottom="quarter" width="three" />
      )}
      <Box
        borderRadius="circle"
        position="relative"
        size={isFirst ? FIRST_PLACE_SIZE : NON_FIRST_PLACE_SIZE}
        marginBottom={{ _: 'one', phone: 'oneAndHalf' }}
      >
        <ChallengeProfilePicture
          profilePicture={avatarUrl}
          firstName={firstName?.length ? firstName : teamName}
          lastName={lastName?.length ? lastName : ''}
          isOnPodium
          backgroundColor={backgroundColorCore}
        />
        <SubtitleOne
          as="span"
          alignItems="center"
          backgroundColor={backgroundColor}
          borderColor="onSurface.border.subdued"
          borderWidth="thick"
          borderRadius="circle"
          bottom="minusOne"
          color="onSurface.text.reversed"
          display="flex"
          fontSize={{ _: 'subtitle2', phone: 'subtitle1' }}
          justifyContent="center"
          left="50%"
          position="absolute"
          size={{ _: 'oneAndHalf', phone: 'two' }}
          transform="translateX(-50%)"
        >
          {rank}
        </SubtitleOne>
      </Box>
      <Badge
        css={{
          backgroundColor: backgroundColorCore,
          color: '$onSurfaceTextReversed',
          fontWeight: '$regular',
          fontSize: '$subtitleOne',
          '@mobile': { fontSize: '$overline' },
        }}
      >
        <ChallengeUnitIcon score={score} rankingUnit={rankingUnit} isOnPodium />
      </Badge>
      <SubtitleOne
        as="p"
        fontSize={{ _: 'overline', phone: 'subtitle1' }}
        marginTop={{ _: 'half', phone: 'threeQuarters' }}
      >
        {formattedName}
      </SubtitleOne>
    </Flex>
  );
};
