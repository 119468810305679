import React from 'react';
import { HeadingText, ParagraphText } from '@leagueplatform/genesis-core';
import { Flex, Image } from '@leagueplatform/genesis-commons';

interface NavigationItemProps {
  iconUrl?: string;
  title: string;
  subtitle?: string;
}

export function NavigationItem({
  iconUrl,
  title,
  subtitle,
}: NavigationItemProps) {
  return (
    <Flex alignItems="center" padding="one">
      {iconUrl && (
        <Image width={48} minWidth={48} height={48} src={iconUrl} alt="" />
      )}
      <Flex flexDirection="column" marginLeft="one" width={1}>
        <HeadingText level="display" size="xs" as="span">
          {title}
        </HeadingText>
        {subtitle && (
          <ParagraphText as="span" emphasis="subtle" size="sm">
            {subtitle}
          </ParagraphText>
        )}
      </Flex>
    </Flex>
  );
}
