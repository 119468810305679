import React from 'react';
import {
  QuietButton,
  Flex,
  VisuallyHidden,
  Image,
  genesisStyled,
  css,
  StyleProps,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { handleStaticAsset } from '@leagueplatform/asset-config';

export interface MobileMenuProps {
  toggleMessageList: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileMessageList: boolean;
}

const StyledMenuButton = genesisStyled(QuietButton)<StyleProps>(({ theme }) =>
  css({
    display: 'none',
    '&:focus': {
      backgroundColor: 'surface.background.primary',
    },
    '&:hover': {
      backgroundColor: 'surface.background.primary',
    },
    [`@media screen and (max-width: ${theme.breakpoints.laptop})`]: {
      display: 'block',
    },
  }),
);

export function MobileMenu({
  toggleMessageList,
  showMobileMessageList,
}: MobileMenuProps) {
  const { formatMessage } = useIntl();
  const mobileMenuIcon = handleStaticAsset(
    MESSAGING_ASSET_KEYS.MESSAGING_MOBILE_MENU_ICON,
  ) as string;

  return (
    <StyledMenuButton
      onClick={() => toggleMessageList(!showMobileMessageList)}
      paddingX="half"
      paddingY="half"
      type="button"
      aria-expanded={showMobileMessageList}
    >
      <Flex justifyContent="center" alignItems="center">
        <Image
          src={mobileMenuIcon}
          alt=""
          size="oneAndHalf"
          aria-hidden="true"
        />
      </Flex>
      <VisuallyHidden>{formatMessage({ id: 'TOGGLE_MENU' })}</VisuallyHidden>
    </StyledMenuButton>
  );
}
