import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import {
  Flex,
  CoverImage,
  HeadingThree,
  SecondaryButton,
  QuietButton,
  Image,
  Box,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import { useHistory } from '@leagueplatform/routing';
// eslint-disable-next-line @leagueplatform/prevent-import-from-dist
import errorStateImage from '@leagueplatform/web-common-components/dist/assets/icon-pivot.png';
import {
  EVENT_NAME,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { ASSESSMENT_ASSET_KEYS } from '../../assessment.types';
import { PRODUCT_AREA, SCREEN_NAME } from '../../constants/analytics.constants';

function trackCloseEvent() {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES_ERROR_SCREEN,
    detail: 'close',
  });
}

function trackReloadEvent() {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES_ERROR_SCREEN,
    detail: 'reload',
  });
}

export default function ErrorScreen({ onReloadSuggestions }) {
  const history = useHistory();
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.PULSE_CHECK_OUTCOMES,
    screen_name: SCREEN_NAME.PULSE_CHECK_OUTCOMES_ERROR_SCREEN,
  });

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      marginX="auto"
      marginTop="three"
      maxWidth={500}
    >
      <Box style={{ alignSelf: 'start' }}>
        <QuietButton
          onClick={() => {
            trackCloseEvent();
            return history.push(JOURNEY_ROUTES.journeyHome());
          }}
        >
          <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
          <Image
            src={handleStaticAsset(ASSESSMENT_ASSET_KEYS.CROSS)}
            alt=""
            width={16}
            height={16}
          />
        </QuietButton>
      </Box>
      <CoverImage
        src={errorStateImage}
        alt=""
        width={149}
        height={167}
        marginTop="three"
        marginBottom="oneAndHalf"
      />
      <HeadingThree
        as="h1"
        marginBottom="oneAndHalf"
        textAlign="center"
        marginTop="two"
      >
        {formatMessage({ id: 'THERE_SEEMS_T0_HAVE_BEEN_A_PROBLEM' })}
      </HeadingThree>
      <SecondaryButton
        onClick={() => {
          trackReloadEvent();
          return onReloadSuggestions();
        }}
      >
        {formatMessage({ id: 'RELOAD_MY_SUGGESTIONS' })}
      </SecondaryButton>
    </Flex>
  );
}

ErrorScreen.propTypes = {
  onReloadSuggestions: PropTypes.func.isRequired,
};
