import React from 'react';
import { MasonryProvider } from '../components/masonry-provider/masonry-provider.component';
import { MasonryConfig } from '../types/config.types';
import { validateAndFormatConfig } from './validate-config';
import { MasonryConfigContextType } from '../context/masonry-config-context';

export class Masonry {
  config: MasonryConfigContextType;

  constructor(clientConfig: MasonryConfig) {
    this.checkConfigForNullOrUndefined(clientConfig);
    this.config = validateAndFormatConfig(clientConfig);
  }

  private checkConfigForNullOrUndefined(config?: MasonryConfig) {
    if (!config && !this.config) {
      throw new Error(
        'No config provided for Masonry Module. Please use Masonry.init() to initialize Masonry.',
      );
    }
  }

  getConfig() {
    this.checkConfigForNullOrUndefined();
    return this.config;
  }

  validateConfig() {
    this.checkConfigForNullOrUndefined();
    validateAndFormatConfig(this.config);
  }

  setConfig(clientConfig: MasonryConfig) {
    this.checkConfigForNullOrUndefined();
    this.config = validateAndFormatConfig(clientConfig);
  }

  Provider = () => <MasonryProvider config={this.config} />;
}

/**
 * Function to initialize Masonry by passing a config as param
 * @param {MasonryConfig} config - Config to intialize Masonry section
 * @returns {Masonry}
 */
export const initMasonry = (config: MasonryConfig): Masonry =>
  new Masonry(config);
