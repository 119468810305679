import * as React from 'react';
import { AxisBottom as VisxAxisBottom } from '@visx/axis';
import { useTheme } from '@leagueplatform/genesis-commons';

export type AxisBottomProps = Omit<
  React.ComponentProps<typeof VisxAxisBottom>,
  'hideTicks' | 'labelOffset' | 'stroke' | 'strokeWidth' | 'tickLabelProps'
>;

/**
 * Wraps the visx AxisBottom component and applies genesis styles.
 */
export function AxisBottom(props: AxisBottomProps) {
  const theme = useTheme();

  return (
    <VisxAxisBottom
      tickStroke={theme.colors.onSurface.border.subdued}
      labelOffset={theme.space.oneAndHalf}
      labelProps={{
        fill: theme.colors.onSurface.text.primary,
        fontSize: theme.fontSizes.heading3,
        textAnchor: 'middle',
        fontWeight: 'bold',
      }}
      stroke={theme.colors.onSurface.border.default}
      strokeWidth={theme.borderWidths.thick}
      tickLabelProps={() => ({
        fontSize: theme.fontSizes.heading2,
        fill: theme.colors.onSurface.text.subdued,
        textAnchor: 'middle',
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
