import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  ImageMessage,
  Modal,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { CopyLink } from 'components/copy-link/copy-link.component';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { CHALLENGES_ASSET_KEYS } from 'types/challenges.types';
import { MODAL_IMAGE_HEIGHT } from 'constants/challenges.constants';

export interface TeamInviteLinkModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const TeamInviteLinkModal = ({
  isOpen,
  onDismiss,
}: TeamInviteLinkModalProps) => {
  const { formatMessage } = useIntl();
  const teamLink = handleStaticAsset(
    CHALLENGES_ASSET_KEYS.CHALLENGES_TEAM_LINK,
  ) as string;

  return (
    <Modal.Root open={isOpen} onOpenChange={onDismiss}>
      <Modal.Content
        css={{
          '.GDS-modal': {
            '@initial': { width: '66vw' },
            '@laptop': { width: '50vw' },
            '@desktop': { width: '45vw' },
          },
        }}
      >
        <StackLayout
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
            '@mobileLandscape': 'vertical',
          }}
          verticalAlignment="center"
          horizontalAlignment="center"
          spacing="$one"
        >
          <ImageMessage image={teamLink} imageHeight={MODAL_IMAGE_HEIGHT} />
          <StackLayout orientation="vertical" spacing="$oneAndHalf">
            <Modal.Title>
              <HeadingText size="xl" level="2">
                {formatMessage({ id: 'TEAM_INVITE_HEADING' })}
              </HeadingText>
            </Modal.Title>
            <Modal.Description>
              <UtilityText>
                {formatMessage({
                  id: 'TEAM_INVITE_LINK_DESCRIPTION',
                })}
              </UtilityText>
            </Modal.Description>
            <Box css={{ width: '100%' }}>
              <CopyLink />
            </Box>
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
