import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE,
  GET_WALLET_MESSAGE_TYPE,
  walletItemNavigationSectionsMock,
} from '@leagueplatform/wallet-api';
import { mockWalletDetailQuery } from 'pages/wallet-detail/__mocks__/mock-queries';

const futureDate = new Date();
futureDate.setHours(futureDate.getHours() + 24);
const queryOptions = { updatedAt: futureDate.getTime() };

export function useWalletMockData() {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.setQueryData(
      [GET_WALLET_ITEMS_SECTIONED_MESSAGE_TYPE, null],
      walletItemNavigationSectionsMock,
      queryOptions,
    );

    queryClient.setQueryData(
      [
        GET_WALLET_MESSAGE_TYPE,
        null,
        '6d20e84f6667cad0c8c4366eeeaa72dd-sunlife_insurance_vision',
      ],
      mockWalletDetailQuery,
      queryOptions,
    );
  }, [queryClient]);
}
