import styled from 'styled-components';
import { leagueFont } from '../utils/font';
import { space } from 'styled-system-old';
import { spaceInPx, colour, radiusInPx } from 'ui-kit/theme';

export const Textarea = styled.textarea`
  ${leagueFont()};
  min-height: 4.5rem;
  padding: ${spaceInPx(1)};
  ${({ fit }) => fit && `width: 100%`};
  border: 1px solid
    ${(props) => (props.error ? colour('red') : colour('greyLight'))};
  border-radius: ${radiusInPx(2)};
  ${space}
`;

Textarea.displayName = 'Textarea';
