import {
  BrowserClient,
  defaultIntegrations,
  defaultStackParser,
  Hub,
  makeFetchTransport,
} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { reactRouterV5Instrumentation } from '@sentry/react';
import type {
  Context,
  Hub as HubType,
  Scope as ScopeType,
  TransactionContext,
  Breadcrumb,
} from '@sentry/types';
import { history } from '@leagueplatform/routing';

import type { ObservabilityMetadata } from '../observability';

import type { MessageContext, SentryConfig } from './sentry.types';
import { TARGET_HUB } from './sentry.types';
import { isObservabilityEnabled, getDomain } from '../observability.utils';
import { setSentryContext, EVENT_IGNORE_CRITERIA } from './sentry.utils';

interface CaptureEventOptions {
  context?: MessageContext;
  targetHub?: TARGET_HUB;
}

const DEFAULT_SAMPLE_RATE = 1;

let leagueSDKSentryHub: HubType;

const isInitialized = (targetHub: HubType = leagueSDKSentryHub) => {
  if (targetHub) return true;

  if (isObservabilityEnabled()) {
    const targetHubType =
      targetHub === leagueSDKSentryHub ? 'League SDK' : 'APP';
    console.warn(
      `Observability - ${targetHubType} instance of Sentry has not been initialized.`,
    );
  }
  return false;
};

const startCustomTransaction = (transactionContext: TransactionContext) => {
  if (!isInitialized()) return null;

  return leagueSDKSentryHub.startTransaction(transactionContext);
};

const getActiveTransaction = () =>
  Sentry.getCurrentHub()?.getScope()?.getTransaction();

const initHub = (metadata: ObservabilityMetadata, config: SentryConfig) => {
  const client = new BrowserClient({
    dsn: config.dsn,
    environment: config.environment,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
      ...defaultIntegrations.filter(
        (integration: any) => integration.name !== 'Dedupe',
      ),
      new Sentry.Integrations.GlobalHandlers({
        onerror: false,
        onunhandledrejection: false,
      }),
    ],
    sampleRate: config.errorsSampleRate || DEFAULT_SAMPLE_RATE,
    tracesSampleRate: config.tracesSampleRate,
    ignoreErrors: EVENT_IGNORE_CRITERIA,
    beforeSend(event) {
      // eslint-disable-next-line no-param-reassign
      event.tags = { ...event.tags, domain: getDomain() };

      return event;
    },
  });

  leagueSDKSentryHub = new Hub(client);
  leagueSDKSentryHub.setTags({ ...metadata, originHub: 'SDK' });
};

const initSDK = (config: SentryConfig) => {
  Sentry.init({
    dsn: config.dsn,
    integrations: [
      new Sentry.BrowserTracing(
        config.integrateWithLeagueRouting && history
          ? {
              /**
               * this `history` instance is imported from `@leagueplatform/routing`,
               * which means it will be the same instance used by the `<BrowserRouter>` component
               * exported by that module. Participating apps will therefore mount a Router
               * that sentry will share a `history` instance with.
               */
              routingInstrumentation: reactRouterV5Instrumentation(history),
            }
          : undefined,
      ),
    ],
    environment: config.environment,
    tracesSampleRate: config.tracesSampleRate,
    sampleRate: config.errorsSampleRate || DEFAULT_SAMPLE_RATE,
    ignoreErrors: EVENT_IGNORE_CRITERIA,
    // Called for transaction events

    beforeSendTransaction(event) {
      const allowedTransactionTypes = ['url', 'route'];
      if (
        event.transaction &&
        event.transaction_info &&
        allowedTransactionTypes.includes(event.transaction_info.source)
      ) {
        // eslint-disable-next-line no-param-reassign
        event.tags = { ...event.tags, domain: getDomain(event.transaction) };
      }

      return event;
    },

    beforeSend(event) {
      // eslint-disable-next-line no-param-reassign
      event.tags = { ...event.tags, domain: getDomain() };

      return event;
    },
  });
  Sentry.setTags({ originHub: 'APP' });
};

const captureError = (error: Error, options?: CaptureEventOptions) => {
  const capturedError = error;

  // Set error name from context if exists
  if (options?.context?.errorName)
    capturedError.name = options?.context?.errorName;

  // Prepend module name to error name from context if exists
  if (options?.context?.tags?.moduleName)
    capturedError.name = `${String(options?.context?.tags?.moduleName)} - ${
      capturedError.name
    }`;

  const targetHub: HubType =
    options?.targetHub === TARGET_HUB.APP
      ? Sentry.getCurrentHub()
      : leagueSDKSentryHub;

  if (isInitialized(targetHub)) {
    /**
     * Retrieve breadcrumbs from the global scope, if available.
     * Note: No documented way to access breadcrumbs array on the scope
     * exists, thus the reason for using escape hatch to access the protected
     * "_breadcrumbs" array property
     */
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const globalBreadcrumbs = Sentry.getCurrentScope()['_breadcrumbs'];

    targetHub.run((currentHub: HubType) => {
      currentHub.withScope((scope: ScopeType) => {
        if (globalBreadcrumbs?.length)
          globalBreadcrumbs.forEach((breadcrumb) =>
            scope.addBreadcrumb(breadcrumb),
          );

        if (options?.context) setSentryContext(options?.context, scope);
        currentHub.captureException(capturedError);
      });
    });
  }
};

const captureMessage = (message: string, options?: CaptureEventOptions) => {
  const targetHub: HubType =
    options?.targetHub === TARGET_HUB.APP
      ? Sentry.getCurrentHub()
      : leagueSDKSentryHub;

  if (isInitialized(targetHub))
    targetHub.run((currentHub: HubType) => {
      currentHub.withScope((scope: ScopeType) => {
        if (options?.context) setSentryContext(options?.context, scope);
        currentHub.captureMessage(message, options?.context?.severityLevel);
      });
    });
};

export const setUserId = (userId: string | undefined) => {
  if (isInitialized()) {
    leagueSDKSentryHub.setUser({ id: userId ?? '' });
    Sentry.getCurrentHub().setUser({ id: userId ?? '' });
  }
};

export const setGlobalContext = (key: string, context: Context) => {
  if (isInitialized()) {
    leagueSDKSentryHub.setContext(key, context);
    Sentry.getCurrentHub().setContext(key, context);
  }
};

export function addBreadcrumb(breadcrumb: Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

export default {
  initHub,
  initSDK,
  captureError,
  captureMessage,
  startCustomTransaction,
  getActiveTransaction,
  setUserId,
};
