import { ErrorContext } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { ValidicUser } from './types/validic-user.type';

export const GET_VALIDIC_USER_MESSAGE_TYPE = 'get_validic_user';

/**
 * getValidicUser makes access the validic user associated with a league user id
 *
 * If the validic user associated with the current league user exists, the validic user object will be returned. If there is not a validic user associated with the current league user, a new one will be created.
 *
 * NOTE: In order for a new validic user to be created, the `set_wearables_consent` endpoint must be reached prior to the
 * validic user endpoint in order to set the wearables consent to `true` for the league user.
 *
 */
export const getValidicUser = async (
  errorContext: ErrorContext,
): Promise<ValidicUser> =>
  SocketAsFetch.fetch(
    {
      message_type: GET_VALIDIC_USER_MESSAGE_TYPE,
    },
    { errorContext },
  );
