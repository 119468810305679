import React from 'react';

import { Card, GDSColor } from '@leagueplatform/genesis-core';

import { ProgramBanner } from '../program-banner/program-banner.component';

interface ProgramDetailCardFooterProps {
  boldedText: string;
  basicText: string | React.ReactNode;
  icon?: string;
  backgroundColor?: GDSColor;
}

export const ProgramDetailCardFooter = ({
  boldedText,
  basicText,
  icon,
  backgroundColor = '$warningBackgroundSubdued',
}: ProgramDetailCardFooterProps) => (
  <Card.Section css={{ backgroundColor }}>
    <ProgramBanner basicText={basicText} boldedText={boldedText} icon={icon} />
  </Card.Section>
);
