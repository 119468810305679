import React from 'react';
import { useParams } from '@leagueplatform/routing';
import {
  Flex,
  Spinner,
  CoverImage,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { ParticipantRelationship } from '@leagueplatform/messaging-api';
import { useMessagingContacts } from 'hooks/use-messaging-contacts.hook';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { MESSAGING_ASSET_KEYS } from 'types/messaging-assets.types';
import { useMessageThreadDetails } from 'hooks/use-message-thread-details.hook';
import { useGetThreadInfoConfig } from 'hooks/use-get-thread-info-config.hook';
import { useGetMessagingStyle } from 'hooks/use-get-messaging-style.hook';
import { usePageError } from 'components/page-error-context/page-error-context';
import { getConfigOverride } from 'utils/get-config-override.util';
import { useFhirResponseConfig } from 'components/fhir-response-config-context/fhir-response-config-context';
import { MessageHeaderContents } from './message-header-contents.component';

export interface ThreadMessageHeaderProps {
  setIsDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMessageList: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileMessageList: boolean;
  threadInfoEnabled: boolean;
}

const ThreadMessageHeader = ({
  setIsDetailsOpen,
  toggleMessageList,
  showMobileMessageList,
  threadInfoEnabled: defaultThreadInfoEnabled,
}: ThreadMessageHeaderProps) => {
  const { formatMessage } = useIntl();
  const { threadId } = useParams<{ threadId: string }>();
  const {
    data: threadData,
    isLoading,
    error,
  } = useMessageThreadDetails(threadId);
  const [, setPageError] = usePageError();
  const threadConfigs = threadData?.meta?.overrideConfigurations;
  const [configFromQuestionnaire] = useFhirResponseConfig();
  const { messagingStyle: defaultMessagingStyle } = useGetMessagingStyle();

  const threadInfoEnabled = React.useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultThreadInfoEnabled,
        configName: 'THREAD_INFO_ENABLED',
        fhirResponseConfigs: configFromQuestionnaire?.data,
        threadConfigs,
        format: 'bool',
      }),
    [configFromQuestionnaire?.data, defaultThreadInfoEnabled, threadConfigs],
  );

  const messagingStyle = React.useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultMessagingStyle,
        configName: 'MESSAGING_THREAD_UI_STYLE',
        fhirResponseConfigs: configFromQuestionnaire?.data,
        threadConfigs,
        format: 'string',
      }),
    [configFromQuestionnaire?.data, defaultMessagingStyle, threadConfigs],
  );

  if (isLoading) {
    return (
      <Flex
        backgroundColor="surface.background.primary"
        color="decorative.brand.primary.default"
        justifyContent="center"
        padding="three"
        borderBottomColor="onSurface.border.subdued"
        borderBottomWidth="thin"
        borderStyle="solid"
      >
        <Spinner isLoading />
      </Flex>
    );
  }

  const isThreadDetailsError = error || !threadData?.data;
  if (isThreadDetailsError) {
    setPageError(isThreadDetailsError);
    // NOTE: we do not expect users to see this inline error,
    // instead the page that renders this component will render a full page error.
    // If this is not handled, users will see this inline error as a fallback:
    const errorImage = handleStaticAsset(
      MESSAGING_ASSET_KEYS.MESSAGING_ERROR,
    ) as string;
    return (
      <Flex
        backgroundColor="surface.background.primary"
        paddingX="three"
        paddingY="two"
        alignItems="center"
        borderBottomColor="onSurface.border.subdued"
        borderBottomWidth="thin"
        borderStyle="solid"
      >
        <CoverImage
          alt=""
          marginRight="oneAndHalf"
          src={errorImage}
          size="three"
        />
        <BodyOne>{formatMessage({ id: 'GENERIC_MESSAGING_ERROR' })}</BodyOne>
      </Flex>
    );
  }

  return (
    <MessageHeaderContents
      setIsDetailsOpen={setIsDetailsOpen}
      threadSubject={threadData.data.subject}
      threadDescription={threadData.data.description}
      threadParticipants={threadData.data.participants.data}
      toggleMessageList={toggleMessageList}
      showMobileMessageList={showMobileMessageList}
      threadInfoEnabled={threadInfoEnabled}
      messagingStyle={messagingStyle}
    />
  );
};

export interface MessageHeaderProps {
  setIsDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  draftSubject?: string;
  draftParticipants: ParticipantRelationship[];
  toggleMessageList: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileMessageList: boolean;
}

export const MessageHeader = ({
  setIsDetailsOpen,
  draftSubject,
  draftParticipants,
  toggleMessageList,
  showMobileMessageList,
}: MessageHeaderProps) => {
  const { formatMessage } = useIntl();
  const { threadId } = useParams<{ threadId: string }>();
  const { data: messagingContactsData } = useMessagingContacts();
  const { threadInfoEnabled: defaultThreadInfoEnabled } =
    useGetThreadInfoConfig();
  const { messagingStyle: defaultMessagingStyle } = useGetMessagingStyle();
  const [configFromQuestionnaire] = useFhirResponseConfig();

  // since these configs are for draft threads
  // we do not have access to threadInfoConfigs, nor do we need to pass them to getConfigOverride()
  const draftThreadInfoEnabled = React.useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultThreadInfoEnabled,
        configName: 'THREAD_INFO_ENABLED',
        fhirResponseConfigs: configFromQuestionnaire?.data,
        format: 'bool',
      }),
    [configFromQuestionnaire?.data, defaultThreadInfoEnabled],
  );

  const draftMessagingStyle = React.useMemo(
    () =>
      getConfigOverride({
        defaultConfigValue: defaultMessagingStyle,
        configName: 'MESSAGING_THREAD_UI_STYLE',
        fhirResponseConfigs: configFromQuestionnaire?.data,
        format: 'string',
      }),
    [configFromQuestionnaire?.data, defaultMessagingStyle],
  );

  // If the thread ID exists, we want to fetch the thread data and display it in the header
  if (threadId) {
    return (
      <ThreadMessageHeader
        setIsDetailsOpen={setIsDetailsOpen}
        toggleMessageList={toggleMessageList}
        showMobileMessageList={showMobileMessageList}
        threadInfoEnabled={defaultThreadInfoEnabled}
      />
    );
  }
  // we can assume the draft participants data exists here as we must be able to fetch it
  // to load the messages page at the root level
  const draftParticipantsList = draftParticipants.map(
    ({ id }) => messagingContactsData!.byId[id],
  );
  // otherwise, we display the draft contents
  return (
    <MessageHeaderContents
      setIsDetailsOpen={setIsDetailsOpen}
      threadSubject={draftSubject}
      threadDescription={formatMessage({ id: 'NEW_MESSAGE_DEFAULT' })}
      threadParticipants={draftParticipantsList}
      isDraft
      toggleMessageList={toggleMessageList}
      showMobileMessageList={showMobileMessageList}
      threadInfoEnabled={draftThreadInfoEnabled}
      messagingStyle={draftMessagingStyle}
    />
  );
};
