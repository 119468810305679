import * as RadixTabsPrimitive from '@radix-ui/react-tabs';
import { TabsRoot } from './tabs-root';
import { TabList } from './tab-list';
import { Tab } from './tab';
import { TabPanel } from './tab-panel';

// Exporting Radix Tabs Primitive for custom Tabs
export const GDSTabsPrimitive = RadixTabsPrimitive;

export const Tabs = {
  Root: TabsRoot,
  Tab,
  TabList,
  TabPanel,
};

Tabs.Root.displayName = 'Tabs.Root';
Tabs.Tab.displayName = 'Tabs.Tab';
Tabs.TabList.displayName = 'Tabs.TabList';
Tabs.TabPanel.displayName = 'Tabs.TabPanel';
