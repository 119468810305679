import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { BORDER_WIDTH } from './language-selector-dropdown.constants';

export const DropdownTooltip = genesisStyled(Box)(({ isOpen }) =>
  css({
    transitionProperty: 'opacity',
    transitionDuration: '250ms',
    animationTimingFunction: 'ease',
    opacity: isOpen ? '1' : '0',
    '&:after': {
      content: '""',
      backgroundColor: 'transparent',
      display: 'block',
      position: 'absolute',
      left: `calc(80% - ${BORDER_WIDTH}px)`,
      bottom: '100%',
      width: 0,
      height: 0,
      borderWidth: `${BORDER_WIDTH}px`,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderBottomColor: 'surface.background.primary',
      borderTop: 'none',
    },
  }),
);
