import React from 'react';
import {
  Card,
  useClickableCard,
  StackLayout,
  Icon,
  Box,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import {
  MasonryNotificationCardContentRenderer,
  MasonryNotificationCardRendererProps,
} from './masonry-notification-card-content-renderer.component';

const clickableCardStyles: GDSStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  gap: '$one',
  padding: '$one',
  paddingRight: '$quarter',
  alignItems: 'center',
};

export const MasonryNotificationCardRenderer = ({
  heading,
  body,
  info,
  eyebrow,
  onClick,
}: MasonryNotificationCardRendererProps) => {
  const isClickable = Boolean(onClick);
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return (
    <Card.Elevated backgroundColor="secondary">
      <StackLayout orientation="horizontal" verticalAlignment="stretch">
        {isClickable ? (
          <Card.ClickArea
            css={clickableCardStyles}
            ref={clickAreaRef}
            onCardMouseUp={onCardMouseUp}
            onCardMouseDown={onCardMouseDown}
          >
            <MasonryNotificationCardContentRenderer
              heading={heading}
              body={body}
              info={info}
              eyebrow={eyebrow}
              onClick={onClick}
              ref={primaryActionRef}
            />
            {isClickable && <Icon icon="interfaceChevronRight" />}
          </Card.ClickArea>
        ) : (
          <Box css={{ padding: '$one' }}>
            <MasonryNotificationCardContentRenderer
              heading={heading}
              body={body}
              info={info}
              eyebrow={eyebrow}
              onClick={onClick}
              ref={primaryActionRef}
            />
          </Box>
        )}
      </StackLayout>
    </Card.Elevated>
  );
};
