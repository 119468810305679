import React from 'react';
import { HighlightCard } from 'components/highlight-card/highlight-card.component';
import { Spinner, StackLayout } from '@leagueplatform/genesis-core';
import { ErrorState } from '@leagueplatform/web-common-components';
import { EmptyActivityState } from 'components/empty-activity-state/empty-activity-state.component';
import {
  CHALLENGE_HIGHLIGHT_FEED_TYPES,
  Highlight,
} from '@leagueplatform/health-journey-api';
import { useGetHighlightsFeedById } from 'hooks/use-get-highlights-feed-by-id';
import { LoadMoreCTA } from 'components/leaderboard/load-more-cta/load-more-cta.component';
import { useIntl } from '@leagueplatform/locales';

interface HighlightFeedProps {
  feedType: CHALLENGE_HIGHLIGHT_FEED_TYPES;
  challengeOrTeamId: string;
  challengeTitle: string;
  userChallengeId?: string;
}

export const HighlightFeed = ({
  feedType,
  challengeOrTeamId,
  challengeTitle,
  userChallengeId,
}: HighlightFeedProps): JSX.Element => {
  const {
    data: highlights,
    fetchNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
  } = useGetHighlightsFeedById(feedType, challengeOrTeamId);
  let flattenedHighlights: Highlight[] | undefined;
  if (highlights?.pages) {
    // this reduce method flattens the multiple pages of highlights to be a flat array of highlights
    // which can be mapped over to render our highlight cards
    flattenedHighlights = highlights.pages.reduce<Highlight[]>(
      (acc, { data: highlightsData }) => acc.concat(highlightsData),
      [],
    );
  }

  const { $t } = useIntl();
  if (isLoading) {
    return (
      <StackLayout
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{ height: '$five', margin: '$five auto' }}
      >
        <Spinner loading tint="$decorativeBrandPrimaryDefault" size="$three" />
      </StackLayout>
    );
  }
  if (isError || !flattenedHighlights) {
    return <ErrorState />;
  }
  if (!flattenedHighlights?.length) {
    return (
      <EmptyActivityState
        activityDescription={$t({ id: 'NO_HIGHLIGHTS_ACTIVITY_DESCRIPTION' })}
      />
    );
  }
  return (
    <StackLayout spacing="$one" horizontalAlignment="center">
      {flattenedHighlights.map((highlight) => (
        <HighlightCard
          highlight={highlight}
          challengeTitle={challengeTitle}
          userChallengeId={userChallengeId}
          key={highlight.id}
        />
      ))}
      {hasNextPage && (
        <LoadMoreCTA
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
        />
      )}
    </StackLayout>
  );
};
