import React from 'react';
import { Box, Spinner } from '@leagueplatform/genesis-core';
import {
  doesUserHaveSufficientWearablePermissions,
  findGrantedWearablePermissions,
  useGetWearablesConsentForDataPoints,
  USER_CONNECTED_DEVICE_CAMPAIGN_MODES,
} from '@leagueplatform/health-journey-api';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import { ManualTrackingConfirmationModal } from './manual-tracking-confirmation-modal/manual-tracking-confirmation-modal.component';
import { DeviceConnectedModalContent } from './device-connected-modal-content.component';
import { DeviceUnconnectedModalContent } from './device-unconnected-modal-content.component';
import { WearablesConnectionProps } from './wearables-connection.types';
import { useSignUpForChallenge } from '../../hooks/use-sign-up-for-challenge.hook';
import { SIGN_UP_MODAL_PAGES } from '../../constants/sign-up-modal.constants';

export const WearablesConnection = ({
  trackingType,
  setTrackingType,
  setNextModalNavigationStep,
  currentStep,
}: WearablesConnectionProps) => {
  const { data: challengeData } = useGetChallengeByIdData();
  // reassignment to a const rather than destructuring in order to avoid
  // unsafe optional chaining.
  const dataFields = challengeData?.data?.dataFields;
  const {
    data: wearablesData,
    isLoading,
    // If fetching wearables data fails, do not display an error toast
    // Instead, treat user as if they do not have a device connected
  } = useGetWearablesConsentForDataPoints(dataFields);
  const signUpForChallengeMutation = useSignUpForChallenge(
    trackingType,
    setNextModalNavigationStep,
  );
  let deviceConnected = false;

  if (isLoading) {
    return (
      <Box css={{ color: '$decorativeBrandPrimaryDefault' }}>
        <Spinner loading />
      </Box>
    );
  }

  if (dataFields && wearablesData) {
    const { receiving_data_v2: devicesObj } = wearablesData;
    const grantedPermissions = findGrantedWearablePermissions(devicesObj);
    deviceConnected = doesUserHaveSufficientWearablePermissions(
      dataFields,
      grantedPermissions,
    );
  }

  if (currentStep === SIGN_UP_MODAL_PAGES.WEARABLES_MANUAL_TRACK_CONFIRMATION) {
    return (
      <ManualTrackingConfirmationModal
        isLoading={signUpForChallengeMutation.isLoading}
        onConfirm={() => {
          setTrackingType(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.MANUAL);
          signUpForChallengeMutation.mutate();
        }}
      />
    );
  }

  return deviceConnected ? (
    <DeviceConnectedModalContent
      setDisplayManualTrackingConfirmationModal={() => {
        setNextModalNavigationStep(
          SIGN_UP_MODAL_PAGES.WEARABLES_MANUAL_TRACK_CONFIRMATION,
        );
      }}
      isLoading={signUpForChallengeMutation.isLoading}
      onConfirm={() => {
        setTrackingType(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.AUTOMATIC);
        signUpForChallengeMutation.mutate();
      }}
    />
  ) : (
    <DeviceUnconnectedModalContent
      setDisplayManualTrackingConfirmationModal={() => {
        setNextModalNavigationStep(
          SIGN_UP_MODAL_PAGES.WEARABLES_MANUAL_TRACK_CONFIRMATION,
        );
      }}
      isLoading={signUpForChallengeMutation.isLoading}
      onConfirm={() => {
        setTrackingType(USER_CONNECTED_DEVICE_CAMPAIGN_MODES.AUTOMATIC);
        signUpForChallengeMutation.mutate();
      }}
    />
  );
};
