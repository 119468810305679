import React from 'react';
import {
  GenesisRouterLink,
  SkeletonBox,
} from '@leagueplatform/web-common-components';
import {
  Box,
  HeadingTwo,
  HeadingThree,
  CoverImage,
  useTheme,
} from '@leagueplatform/genesis-commons';
import {
  JOURNEY_ROUTES,
  ENABLE_NEW_JOURNEY_EXPERIENCE,
} from '@leagueplatform/health-journey-common';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { ActivityContainer } from '../activity-container/activity-container.component';
import { HealthProgramCategory } from '../health-program-category/health-programs-category.component';
import { useHealthProgramCategories } from '../../hooks/use-health-program-categories.hook';
import { useProgramLibraryAnalytics } from '../../../../hooks/use-program-library-analytics.hook';

export const HealthProgramCategories = () => {
  const { data: isNewJourneyExperienceEnabled } = useFeatureFlagQuery(
    ENABLE_NEW_JOURNEY_EXPERIENCE,
  );
  const { data, isLoading } = useHealthProgramCategories();
  const programLibraryAnalytics = useProgramLibraryAnalytics();
  const theme = useTheme();

  if (isLoading)
    return (
      <SkeletonBox
        height="200px"
        width="100%"
        marginBottom={theme.space.twoAndHalf}
      />
    );

  return (
    data?.categories && (
      <Box paddingBottom="four">
        <HeadingTwo
          marginBottom="oneAndHalf"
          as={isNewJourneyExperienceEnabled ? 'h3' : 'h2'}
        >
          {data?.title}
        </HeadingTwo>
        <ActivityContainer as="ul">
          {data?.categories?.map(({ name, icon_url: iconUrl, id }) => (
            <li key={id}>
              <GenesisRouterLink
                width="auto"
                height="100%"
                display="block"
                hoverStyle={{
                  backgroundColor: 'surface.background.secondary',
                  textDecoration: 'none !important',
                }}
                focusStyle={{
                  backgroundColor: 'interactive.action.disabled',
                }}
                onClick={() =>
                  programLibraryAnalytics.sendSelectProgramCategory(name)
                }
                to={JOURNEY_ROUTES.getProgramCategory(id)}
              >
                <HealthProgramCategory>
                  <CoverImage
                    src={iconUrl}
                    alt=""
                    backgroundSize="contain"
                    size="two"
                    minWidth="two"
                    marginRight="half"
                  />
                  <HeadingThree fontSize={{ phone: 'heading3' }}>
                    {name}
                  </HeadingThree>
                </HealthProgramCategory>
              </GenesisRouterLink>
            </li>
          ))}
        </ActivityContainer>
      </Box>
    )
  );
};
