import React from 'react';
import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';

import { Transition } from 'react-transition-group';
import { TransitionStates } from 'types/animation-transition-states.type';
import { TransitionProps } from 'types/transition-props.type';

const Element = genesisStyled(Box)(({ stylesProp }) =>
  css({
    ...stylesProp,
    opacity: 0,
  }),
);

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const FadeIn = ({
  isActive,
  stylesProp,
  duration,
  children = null,
}: TransitionProps) => (
  <Transition in={isActive} timeout={duration}>
    {(state: TransitionStates) => (
      <Element
        stylesProp={{
          ...stylesProp,
          transition: `opacity ${duration}ms ease-in-out`,
        }}
        style={{ ...transitionStyles[state] }}
      >
        {children && children}
      </Element>
    )}
  </Transition>
);
