import React from 'react';
import { UtilityText, GDSColor } from '@leagueplatform/genesis-core';

interface ActivityCardHeadlineProps {
  backgroundColor: GDSColor;
  color: GDSColor;
  children: React.ReactNode;
}

export const ActivityCardHeadline = ({
  backgroundColor,
  color,
  children,
}: ActivityCardHeadlineProps) => (
  <UtilityText
    size="eyebrow"
    emphasis="emphasized"
    css={{
      backgroundColor,
      color,
      borderRadius: '$small $small 0 0',
      paddingTop: '$threeQuarters',
      paddingBottom: '$threeQuarters',
      paddingLeft: '$oneAndHalf',
      paddingRight: 'one',
      minHeight: '40px',
    }}
  >
    {children}
  </UtilityText>
);
