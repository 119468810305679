import React from 'react';
import {
  Box,
  Flex,
  Overline,
  HeadingOne,
  BodyOne,
  SubtitleOne,
  genesisStyled,
  QuietButton as CloseButton,
  VisuallyHidden,
  Image,
} from '@leagueplatform/genesis-commons';
import { Modal, Icon, ICONS, useModalA11yProps } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { openInTab } from '@leagueplatform/web-common';
import { getContentUrl } from '@leagueplatform/league-content-api';
import IconHealthArticle from 'assets/health-article-icon.svg';
import {
  sendAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';

interface DimensionOfHealthModalProps {
  title: string;
  description: string;
  imageId?: string;
  placeholderImage?: string;
  url?: string;
  onClose: () => void;
  analyticsProductArea: string;
  analyticsScreenName: string;
  icon?: string;
}

const DIMENSION_HEALTH_MODAL_MAX_WIDTH = '574px';
const DIMENSION_HEALTH_MODAL_LABEL = 'dimension-of-health-modal';

export const getDimensionOfHealthScreenName = (type: string) =>
  `dimensions of health ${type.toLowerCase()}`;

const InteractiveBox = genesisStyled(Box)`
  cursor: pointer;
`;

/**
 * @param {string} imageId is a single image containing illustration with the clicked topic icon overlapping it
 * @param {string} image used to show default image in story
 * @param {string} url is a full url to be opened in a new tab
 */
export const DimensionOfHealthModal = ({
  title,
  description,
  imageId,
  icon,
  placeholderImage,
  url,
  onClose,
  analyticsScreenName, // Used only for analytics
  analyticsProductArea,
}: DimensionOfHealthModalProps) => {
  const modalAriaProps = useModalA11yProps();

  usePageViewAnalytics(
    {
      product_area: analyticsProductArea,
      screen_name: getDimensionOfHealthScreenName(title),
      has_health_profile: true,
    },
    [title],
  );
  const { formatMessage } = useIntl();
  const healthNeedsImageSrc = imageId
    ? getContentUrl(imageId)
    : placeholderImage;
  const imageSrc = icon || healthNeedsImageSrc;

  return (
    <Modal.Wrapper onClose={onClose} options={{}}>
      <Box
        padding="four"
        borderRadius="medium"
        position="relative"
        overflowY="scroll"
        backgroundColor="surface.background.primary"
        maxWidth={DIMENSION_HEALTH_MODAL_MAX_WIDTH}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalAriaProps}
        aria-labelledby={DIMENSION_HEALTH_MODAL_LABEL}
      >
        <CloseButton
          onClick={onClose}
          paddingX="half"
          paddingY="half"
          position="absolute"
          top="one"
          right="oneAndHalf"
        >
          <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
          <Icon icon={ICONS.CLOSE} size={20} />
        </CloseButton>
        {imageSrc && (
          <Flex
            justifyContent="center"
            flexDirection="column"
            height={277}
            width={277}
            margin="auto"
          >
            <Image data-testid="dimension-image" src={imageSrc} alt="" />
          </Flex>
        )}

        <Overline
          as="h1"
          color="onSurface.text.subdued"
          marginY="half"
          id={DIMENSION_HEALTH_MODAL_LABEL}
        >
          {formatMessage({ id: 'DIMENSION_OF_HEALTH' })}
        </Overline>
        <HeadingOne marginBottom="one" as="h2">
          {title}
        </HeadingOne>
        <BodyOne marginBottom="twoAndHalf" color="onSurface.text.subdued">
          {description}
        </BodyOne>
        {url && (
          <Flex
            padding="oneAndHalf"
            borderColor="interactive.border.disabled"
            borderStyle="solid"
            borderWidth="thin"
            borderRadius="medium"
          >
            <Flex justifyContent="center" width={100} height={72}>
              <Image src={IconHealthArticle} alt="" />
            </Flex>
            <Box marginLeft="oneAndHalf" width="100%">
              <BodyOne color="onSurface.text.subdued" marginBottom="half">
                {formatMessage({ id: 'READING_MORE_ABOUT_DIMENSIONS' })}
              </BodyOne>
              <InteractiveBox
                onClick={() => {
                  sendAnalyticsEvent({
                    category: analyticsScreenName,
                    action: 'Read Dimension of Health Article',
                    label: title,
                  });

                  openInTab(url);
                }}
              >
                <SubtitleOne color="onSurface.text.primary">
                  {formatMessage({ id: 'READ_OUR_ARTICLE' })}
                </SubtitleOne>
              </InteractiveBox>
            </Box>
          </Flex>
        )}
      </Box>
    </Modal.Wrapper>
  );
};
