import React, { forwardRef } from 'react';
import {
  Box,
  HeadingText,
  ParagraphText,
  GDSCustomizableComponent,
} from '@leagueplatform/genesis-core';

export type SampleComponentProps = {
  heading: string;
  body: string;
} & GDSCustomizableComponent;

export const SampleComponent = forwardRef<HTMLDivElement, SampleComponentProps>(
  ({ heading, body, css }, ref) => (
    <Box css={css} className="GDS-sample-component" ref={ref}>
      <HeadingText size="sm" level="2">
        {heading}
      </HeadingText>
      <ParagraphText>{body}</ParagraphText>
    </Box>
  ),
);
