import React, { useMemo } from 'react';
import * as RadixAccordionPrimitive from '@radix-ui/react-accordion';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';

const accordionRootStyles = {
  '> * + *': {
    marginTop: '$two',
  },
  '> * > * + *': {
    marginTop: '$one',
  },
  '.cc-accordion-trigger': {
    background: 'none',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    padding: '$quarter $none',
    width: '100%',
    '&:hover, &:focus': {
      background: 'none',
      color: 'inherit',
    },
    '> span': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '.cc-accordion-trigger-icon': {
      transition: 'transform 200ms',
      marginRight: '$minusHalf',
    },
    '&[aria-expanded=true] .cc-accordion-trigger-icon': {
      transform: 'rotate(180deg)',
    },
  },
};

export interface AccordionStyleProps {
  className?: string;
  css?: GDSStyleObject;
}

export interface AccordionRootMultipleProps
  extends RadixAccordionPrimitive.AccordionMultipleProps,
    AccordionStyleProps {}

export interface AccordionRootSingleProps
  extends RadixAccordionPrimitive.AccordionSingleProps,
    AccordionStyleProps {}

export const Root = React.forwardRef<
  HTMLDivElement,
  AccordionRootMultipleProps | AccordionRootSingleProps
>(({ className, css, ...props }, forwardedRef) => {
  const composedClassName = useMemo(
    () => ['cc-accordion', 'cc-accordion-root', className].join(' '),
    [className],
  );
  const composedCss = useMemo(
    () => ({ ...accordionRootStyles, ...css }),
    [css],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      as={RadixAccordionPrimitive.Root}
      className={composedClassName}
      css={composedCss}
      ref={forwardedRef}
    />
  );
});

Root.displayName = 'Accordion.Root';
