{
  "dragDescriptionKeyboard": "Trykk på Enter for å begynne å dra.",
  "dragDescriptionKeyboardAlt": "Trykk på Alt + Enter for å begynne å dra.",
  "dragDescriptionLongPress": "Trykk lenge for å begynne å dra.",
  "dragDescriptionTouch": "Dobbelttrykk for å begynne å dra.",
  "dragDescriptionVirtual": "Klikk for å begynne å dra.",
  "dragItem": "Dra {itemText}",
  "dragSelectedItems": "Dra {count, plural, one {# merket element} other {# merkede elementer}}",
  "dragSelectedKeyboard": "Trykk Enter for å dra {count, plural, one {# valgt element} other {# valgte elementer}}.",
  "dragSelectedKeyboardAlt": "Trykk på Alt + Enter for å dra {count, plural, one {# valgt element} other {# valgte elementer}}.",
  "dragSelectedLongPress": "Trykk lenge for å dra {count, plural, one {# valgt element} other {# valgte elementer}}.",
  "dragStartedKeyboard": "Begynte å dra. Trykk på Tab for å navigere til et mål, og trykk deretter på Enter for å slippe eller på Esc for å avbryte.",
  "dragStartedTouch": "Begynte å dra. Naviger til et mål, og dobbelttrykk for å slippe.",
  "dragStartedVirtual": "Begynte å dra. Naviger til et mål, og klikk eller trykk på Enter for å slippe.",
  "dropCanceled": "Avbrøt slipping.",
  "dropComplete": "Slippingen er fullført.",
  "dropDescriptionKeyboard": "Trykk på Enter for å slippe. Trykk på Esc hvis du vil avbryte draingen.",
  "dropDescriptionTouch": "Dobbelttrykk for å slippe.",
  "dropDescriptionVirtual": "Klikk for å slippe.",
  "dropIndicator": "slippeindikator",
  "dropOnItem": "Slipp på {itemText}",
  "dropOnRoot": "Slipp på",
  "endDragKeyboard": "Drar. Trykk på Enter hvis du vil avbryte.",
  "endDragTouch": "Drar. Dobbelttrykk hvis du vil avbryte.",
  "endDragVirtual": "Drar. Klikk hvis du vil avbryte.",
  "insertAfter": "Sett inn etter {itemText}",
  "insertBefore": "Sett inn før {itemText}",
  "insertBetween": "Sett inn mellom {beforeItemText} og {afterItemText}"
}
