import { useUserSettingsQuery } from 'hooks/queries/use-user-settings-query.hook';
import { useWalletParams } from 'hooks/use-wallet-params.hook';
import { useCallback } from 'react';
import { useUserStoreCreditBalanceQuery } from './queries/use-user-store-credit-balance-query.hook';
import {
  LeagueCreditBalance,
  UserPromoCode,
  UserStoreCreditBalanceResponse,
} from '../types/league-credit.types';
import { useRedeemPromoCodeMutation } from './queries/use-redeem-promo-code-mutation.hook';
import { useLearnMoreUrlQuery } from './use-learn-more-url-query.hook';

export interface UseLeagueCreditReturn {
  balance?: LeagueCreditBalance;
  redeemedCodes: UserPromoCode[];
  lifemarketUrl?: string;
  learnMoreUrl?: string;
  onSubmit: (promoCode: string) => void;
}

interface TransformedUserStoreCreditBalance {
  balance: LeagueCreditBalance;
  redeemedCodes: UserPromoCode[];
}

// TODO: move to utils and add tests
function selectUserStoreCreditBalanceAndRedeemedCodes(
  data: UserStoreCreditBalanceResponse,
): TransformedUserStoreCreditBalance {
  const redeemedCodes = (data.details || []).filter(
    (code) => code.type === 'code' && code.balance > 0,
  );

  return {
    balance: {
      F: data.amount ?? 0,
      C: data.currency ?? 'CAD',
    },
    redeemedCodes,
  };
}

export const useLeagueCredit = (): UseLeagueCreditReturn => {
  const { userId } = useWalletParams();
  const { data: learnMoreUrl } = useLearnMoreUrlQuery(userId);

  const {
    data: { balance, redeemedCodes } = {} as TransformedUserStoreCreditBalance,
  } = useUserStoreCreditBalanceQuery(
    selectUserStoreCreditBalanceAndRedeemedCodes,
  );

  const { data: lifemarketUrl } = useUserSettingsQuery(
    (data) => data.marketplace_url,
  );

  const { mutate } = useRedeemPromoCodeMutation();

  const onSubmit = useCallback(
    (promoCode: string) => mutate({ code: promoCode }),
    [mutate],
  );

  return {
    balance,
    redeemedCodes,
    lifemarketUrl,
    learnMoreUrl,
    onSubmit,
  };
};
