import React, { useEffect, useRef } from 'react';
import {
  VisuallyHidden,
  TextInput,
  PrimaryButton,
  Flex,
  Box,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';

export interface CopyLinkProps {
  joinTeamLink?: string;
  onClick?: () => void;
}

export const CopyLink = ({
  joinTeamLink = window.location.href,
  onClick,
}: CopyLinkProps) => {
  const { formatMessage } = useIntl();
  const TEAM_INVITE_LINK_INPUT = 'teamInviteLinkInput';
  const inputRef = useRef<HTMLInputElement>(null);

  // Set selection position of the readonly text input for the TLP link to the beginning so that the user will see the beginning of the url rather than the end
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = 0;
    }
  }, [inputRef]);

  const copyLinkOnClick = async () => {
    try {
      await navigator.clipboard.writeText(joinTeamLink);
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'INVITE_LINK_COPIED_TO_CLIPBOARD',
      });
    } catch {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'UNABLE_TO_COPY_TO_CLIPBOARD',
      });
    }
  };
  const handleCopyLinkClick = () => {
    copyLinkOnClick();
    onClick?.(); // Call onClick if it exists
  };
  return (
    <>
      <VisuallyHidden>
        <label htmlFor={TEAM_INVITE_LINK_INPUT}>
          {formatMessage({
            id: 'TEAM_INVITE_LINK',
          })}
        </label>
      </VisuallyHidden>
      <TextInput
        id={TEAM_INVITE_LINK_INPUT}
        name={TEAM_INVITE_LINK_INPUT}
        readOnly
        value={joinTeamLink}
        width="100%"
        ref={inputRef}
      />
      <PrimaryButton marginTop="two" onClick={handleCopyLinkClick} width="100%">
        <Flex justifyContent="center" alignItems="center">
          <ChallengeIcon iconType={ICON_TYPES.HYPERLINK} />
          <Box as="span" marginLeft="half">
            {formatMessage({ id: 'COPY' })}
          </Box>
        </Flex>
      </PrimaryButton>
    </>
  );
};
