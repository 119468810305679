import React, { FC, isValidElement } from 'react';
import { useResponsiveProp } from 'hooks/use-responsive-prop';
import { VisuallyHidden } from 'components/visually-hidden/visually-hidden';
import type { GDSLegendProps } from '../types';
import { styled } from '../../../theme';
import { labelLegendStyles } from '../utilities';
import { LabelText } from './label-text';
import { useThemeStrings } from '../../../hooks/use-theme';
import { GenesisCoreInspector } from '../../../test-utils/genesis-core-inspector';

const LegendBase = styled('legend', labelLegendStyles);

export const FieldsetLegend: FC<GDSLegendProps> = ({
  hideLegend,
  children,
  required,
  tooltip,
  className,
  css,
  ...props
}: GDSLegendProps) => {
  const responsiveHideLegend = useResponsiveProp(hideLegend);
  const { optional } = useThemeStrings();

  if (responsiveHideLegend)
    return (
      <VisuallyHidden as="legend" {...props}>
        {children}
        {!required && `(${optional})`}
      </VisuallyHidden>
    );

  return (
    <GenesisCoreInspector displayName="FieldsetLegend">
      <LegendBase
        className={['GDS-fieldset-legend', className].join(' ')}
        css={css}
        {...props}
      >
        <LabelText required={required}>{children}</LabelText>
        {isValidElement(tooltip) ? tooltip : null}
      </LegendBase>
    </GenesisCoreInspector>
  );
};

FieldsetLegend.displayName = 'FieldsetLegend';
