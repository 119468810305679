import React from 'react';
import {
  Flex,
  Box,
  SubtitleTwo,
  Caption,
} from '@leagueplatform/genesis-commons';
import { ChallengeProfilePicture } from 'components/challenge-profile-picture/challenge-profile-picture.component';
import { JOURNEY_ROUTES } from '@leagueplatform/health-journey-common';
import { Link, useParams } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  ChallengeIcon,
  ICON_TYPES,
} from 'components/challenge-icon/challenge-icon.component';

export interface TeamListItemProps {
  teamId: string;
  teamName: string;
  avatar: string;
  maxMembers: number;
  memberCount: number;
}

export const TeamListItem = ({
  teamId,
  teamName,
  avatar,
  maxMembers,
  memberCount,
}: TeamListItemProps) => {
  const { formatMessage } = useIntl();
  const { challengeId } = useParams<{ challengeId: string }>();
  const spotsAvailable = maxMembers - memberCount;

  return (
    <li key={teamId}>
      <Flex
        as={Link}
        to={JOURNEY_ROUTES.getChallengeTeamDetails(challengeId, `${teamId}`)}
        padding="one"
        textDecoration="none"
        borderTop="1px solid"
        borderColor="onSurface.border.subdued"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex>
          <Box size="twoAndHalf">
            <ChallengeProfilePicture
              profilePicture={avatar}
              firstName={teamName}
              lastName=""
            />
          </Box>
          <Box marginLeft="one">
            <SubtitleTwo as="p">{teamName}</SubtitleTwo>
            <Caption>
              {formatMessage(
                { id: 'X_TEAM_MEMBERS' },
                { memberTotal: memberCount },
              )}{' '}
              • {formatMessage({ id: 'X_SPOTS_AVAILABLE' }, { spotsAvailable })}
            </Caption>
          </Box>
        </Flex>
        <Box transform="rotate(180deg)">
          <ChallengeIcon iconType={ICON_TYPES.CHEVRON} />
        </Box>
      </Flex>
    </li>
  );
};
