import {
  HealthMetricAverageValue,
  HealthMetricValue,
} from '@leagueplatform/dashboard-api';
import { HealthMetricData } from 'components/charts/core/range-graph/utils/use-health-metric-values';

type GetHealthMetricDisplayValueProps = {
  value:
    | HealthMetricData[number]['value']
    | HealthMetricValue['value']
    | HealthMetricAverageValue['value'];
  label?: HealthMetricData[number]['label'] | HealthMetricValue['label'];
};

export function getHealthMetricDisplayValue({
  value,
  label,
}: GetHealthMetricDisplayValueProps) {
  const values = label ?? value;
  if (Array.isArray(values)) {
    return values.join('-');
  }

  if (values === null) {
    return '-';
  }

  return values.toString();
}
