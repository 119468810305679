import React from 'react';

export const Arrow = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="arrowIcon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75258 1.65852C9.11626 1.24288 9.07414 0.611119 8.6585 0.247437C8.24287 -0.116245 7.61111 -0.0741276 7.24742 0.341509L0.250558 8.33793C0.0946349 8.51429 0 8.7461 0 9.00001C0 9.25401 0.094699 9.4859 0.250716 9.66228L7.24742 17.6585C7.61111 18.0742 8.24287 18.1163 8.6585 17.7526C9.07414 17.3889 9.11626 16.7571 8.75258 16.3415L3.20377 10H19C19.5523 10 20 9.5523 20 9.00001C20 8.44773 19.5523 8.00001 19 8.00001H3.20377L8.75258 1.65852Z"
      fill="currentColor"
    />
  </svg>
);
