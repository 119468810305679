import * as React from 'react';
import { useGetUserProfileData } from '@leagueplatform/health-profile-common';
import { HeadingTwo, Flex } from '@leagueplatform/genesis-commons';
import { ABOUT_ME_ASSET_KEYS } from 'types/about-me-assets.type';
import { handleStaticAsset } from '@leagueplatform/asset-config';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { Avatar, Badge } from '@leagueplatform/web-common-components';
import { useRewardsSidebar } from '@leagueplatform/rewards-achievements';
import { StackLayout } from '@leagueplatform/genesis-core';
import { PointsIcon } from '@leagueplatform/rewards-common';
import { Link } from '@leagueplatform/routing';

export function AccountBanner() {
  const { avatar, preferredFirstName, lastName } = useGetUserProfileData();
  const { userRewardPoints } = useRewardsSidebar();

  const showPointsBadge = userRewardPoints != null;

  const userName = `${preferredFirstName} ${lastName || ''}`;

  return (
    <Flex alignItems="center" marginTop="four" gap="threeQuarters">
      <Avatar
        data-testid="avatar"
        imageSrc={
          avatar?.imageId
            ? getContentUrl(avatar?.imageId)
            : (handleStaticAsset(
                ABOUT_ME_ASSET_KEYS.ABOUT_ME_PROFILE_AVATAR,
              ) as string)
        }
      />
      <StackLayout spacing="$none">
        <HeadingTwo marginBottom="half" color="onSurface.text.primary">
          {userName}
        </HeadingTwo>
        {showPointsBadge && (
          <Badge
            as={Link}
            to="./get-rewarded"
            css={{
              '&:hover': { textDecoration: 'underline' },
              backgroundColor: '$surfaceBackgroundSecondary',
            }}
          >
            <PointsIcon value={userRewardPoints} />
          </Badge>
        )}
      </StackLayout>
    </Flex>
  );
}
