import { useMemo, useCallback } from 'react';
import { BenefitCardAction } from '@leagueplatform/wallet-api';
import { EVENT_NAME } from '@leagueplatform/analytics';
import { useWalletDetailAnalytics } from 'pages/wallet-detail/hooks/use-wallet-detail-analytics.hook';
import {
  isSaveCardAction,
  isPhoneNumberAction,
  isBenefitDocumentAction,
  isEmailLinkAction,
  isExternalLinkAction,
  isSubmitClaimAction,
  isUnavailableAction,
  isGenericContentModal,
  isClaimsAction,
  isPrintCardAction,
  isOrderNewCardAction,
} from 'utils/action-links.util';
import {
  PhoneLink,
  DocumentLink,
  GenericLink,
  InternalLink,
  ClaimsLink,
  AuthExternalLink,
  UnavailableAction,
  GenericContentAction,
  PrintCardLink,
  OrderNewCardAction,
} from 'components/action-sub-links/action-sub-links.component';

export const useActionLink = (action?: BenefitCardAction) => {
  const { name, url, primary, system_type: systemType } = action || {};
  const sendBenefitActionAnalyticsEvent = useWalletDetailAnalytics();

  const LinkComponent = useMemo(() => {
    if (!action) {
      return undefined;
    }

    if (isOrderNewCardAction(action)) {
      return OrderNewCardAction;
    }

    if (isPrintCardAction(action)) {
      return PrintCardLink;
    }

    if (isSaveCardAction(action)) {
      return PrintCardLink;
    }

    if (isPhoneNumberAction(action)) {
      return PhoneLink;
    }

    // mmoore: check for unavailable action before benefit document since unavailable action satisfies the benefit doc check
    if (isUnavailableAction(action)) {
      return UnavailableAction;
    }

    if (isGenericContentModal(action)) {
      return GenericContentAction;
    }

    if (isBenefitDocumentAction(action)) {
      return DocumentLink;
    }

    if (isEmailLinkAction(action)) {
      return GenericLink;
    }

    if (!isExternalLinkAction(action) || isSubmitClaimAction(action)) {
      return InternalLink;
    }

    if (isClaimsAction(action)) {
      return ClaimsLink;
    }

    return AuthExternalLink;
  }, [action]);

  const handleActionClick = useCallback(() => {
    sendBenefitActionAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
      detail: primary ? 'primary call to action' : 'list action',
      sub_detail: name,
      resource_type: systemType,
      resource_id: url || null,
    });
  }, [sendBenefitActionAnalyticsEvent, primary, name, systemType, url]);

  return { LinkComponent, handleActionClick };
};
