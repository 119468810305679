import * as React from 'react';
import type { Accessors } from 'types/accessor';
import { Circle, CircleProps } from './circle';
import { useChart } from '../providers/chart-data-provider';

export type CirclesProps<Datum, XResult, YResult> = Omit<
  CircleProps<Datum, XResult, YResult>,
  'datum'
> & {
  data: Datum[];
  accessors: Accessors<Datum, XResult, YResult>;
  ariaDatumLabel: (datum: Datum) => string;
};

// A utility function to retrieve a value for an axis and return the scaled version.
// @ts-ignore
const compose = (scale, accessor) => (d) => scale(accessor(d));

export function Circles<Datum, XResult, YResult>({
  accessors,
  data,
  ariaDatumLabel,
}: CirclesProps<Datum, XResult, YResult>) {
  const { xScale, yScale } = useChart();

  const xPoint = compose(xScale, accessors.xAccessor);
  const yPoint = compose(yScale, accessors.yAccessor);

  return (
    <>
      {data
        // Only render circles with values.
        .filter((datum) => yPoint(datum) !== undefined)
        .map((datum) => (
          <Circle
            key={`circle-${xPoint(datum)}:${yPoint(datum)}`}
            datum={datum}
            accessors={accessors}
            aria-label={ariaDatumLabel(datum)}
          />
        ))}
    </>
  );
}
