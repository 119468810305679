import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  HealthProfilePageWrapper,
  MoreOptionsCard,
  AddAppsDevicesLink,
} from '@leagueplatform/health-profile-common';
import { Sidebar } from '@leagueplatform/web-common-components';
import { RewardsSidebarCard } from '@leagueplatform/rewards-achievements';
import { ManageUnitsLink } from 'components/manage-units-modal';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { AllInsights } from 'components/insights/all-insights/all-insights';

export function InsightsPage() {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.ALL_INSIGHTS,
  });

  return (
    <HealthProfilePageWrapper
      title={formatMessage({
        id: 'APP_AND_DEVICE_METRICS',
      })}
      sidebar={
        <Sidebar>
          <RewardsSidebarCard />
          <MoreOptionsCard>
            <AddAppsDevicesLink />
            <ManageUnitsLink />
          </MoreOptionsCard>
        </Sidebar>
      }
    >
      <AllInsights embedded={false} />
    </HealthProfilePageWrapper>
  );
}
