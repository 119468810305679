import {
  genesisStyled,
  Flex,
  css,
  BodyOne,
  GenesisTheme,
  StyleProps,
} from '@leagueplatform/genesis-commons';
import * as React from 'react';

interface FeedbackInputProps {
  label: string;
  value: number;
  expandedLabel?: string;
  isSmall?: boolean;
  isFirst?: boolean;
  onClick?: (value: number) => void;
  href: string;
  ariaLabel: string;
}

const CIRCLE_SIZE_LARGE = '56px';
const CIRCLE_SIZE_SMALL = '34px';

const CircleLabel = genesisStyled(Flex)<StyleProps>(({ isSmall, theme }) =>
  css({
    textDecoration: 'none',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'decorative.brand.primary.default',
    transition: 'all 250ms ease-in-out',
    borderRadius: 'circle',
    fontWeight: 'bold',
    color: 'decorative.brand.primary.default',
    height: isSmall ? CIRCLE_SIZE_SMALL : CIRCLE_SIZE_LARGE,
    width: isSmall ? CIRCLE_SIZE_SMALL : CIRCLE_SIZE_LARGE,
    fontSize: isSmall ? 'subtitle1' : 'heading2',

    '&:hover': {
      backgroundColor: 'decorative.brand.primary.default',
      color: 'onSurface.text.reversed',
    },

    '&:focus': {
      backgroundColor: 'decorative.brand.primary.default',
      color: 'onSurface.text.reversed',
      outlineWidth: 'thick',
      outlineColor: 'secondary.background.hovered',
      outlineStyle: 'solid',
    },

    [`@media screen and (max-width: ${theme.breakpoints.tablet})`]: {
      height: CIRCLE_SIZE_SMALL,
      width: CIRCLE_SIZE_SMALL,
      fontSize: 'subtitle1',
    },
  }),
);

const ExpandedLabel = genesisStyled(BodyOne)<{
  theme: GenesisTheme;
  isSmall?: boolean;
}>(({ isSmall, theme }) =>
  css({
    color: 'inherit',
    whiteSpace: 'nowrap',
    fontSize: isSmall ? 'subtitle2' : 'inherit',
    position: ['absolute', 'initial'],
    top: `calc(100% - ${theme.space.half}px)`,
    left: '50%',
    transform: ['translateX(-50%)', 'none'],

    [`@media screen and (max-width: ${theme.breakpoints.tablet})`]: {
      fontSize: 'subtitle1',
    },
  }),
);

export const FeedbackInput = ({
  label,
  ariaLabel,
  value,
  expandedLabel,
  isSmall = true,
  href,
  isFirst,
  onClick,
}: FeedbackInputProps) => {
  const EXPANDED_LABEL_ID = `feedback-expanded-label-${
    isFirst ? 'first' : 'last'
  }`;

  return (
    <Flex
      display={['inline-block', 'flex']}
      alignItems="center"
      gap={['none', 'one']}
      justifyContent="center"
      position="relative"
      paddingBottom={['half', 'none', 'none']}
    >
      {isFirst && expandedLabel && (
        <ExpandedLabel id={EXPANDED_LABEL_ID} isSmall={isSmall}>
          {expandedLabel}
        </ExpandedLabel>
      )}
      <CircleLabel
        as="a"
        href={href}
        isSmall={isSmall}
        data-testid="feedback-rating-item"
        aria-label={ariaLabel}
        aria-describedby={expandedLabel ? EXPANDED_LABEL_ID : undefined}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (onClick) {
            onClick(value);
          }
        }}
      >
        {label}
      </CircleLabel>
      {!isFirst && expandedLabel && (
        <ExpandedLabel id={EXPANDED_LABEL_ID} isSmall={isSmall}>
          {expandedLabel}
        </ExpandedLabel>
      )}
    </Flex>
  );
};
