import { vars } from '../vars';
import { Box } from './primitives.view';
import { hex2Rgba } from '../utils/helpers';
import styled from 'styled-components';

const ErrorMessage = styled(Box).attrs({
  fontSize: 2,
  p: 1,
  borderWidth: '0.1rem',
  borderColor: hex2Rgba(vars.colour.orangeRed, 0.5),
  borderStyle: 'solid',
})`
  color: ${vars.colour.black};
  background-color: ${hex2Rgba(vars.colour.orangeRed, 0.15)};
`;
ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
