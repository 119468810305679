import { useEffect, useState } from 'react';
import type { LottiePlayer } from 'lottie-web/build/player/lottie_light';

export const useLottie = (): LottiePlayer | undefined => {
  const [lottie, setLottie] = useState<LottiePlayer | undefined>(undefined);

  useEffect(() => {
    if (!lottie) {
      import('lottie-web/build/player/lottie_light').then((module) =>
        setLottie(module.default),
      );
    }
  }, [lottie]);

  return lottie;
};
