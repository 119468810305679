import * as React from 'react';
import useMeasure from 'react-use-measure';
import { useTooltip } from '@visx/tooltip';
import { Group } from '@visx/group';
import { createScale, ScaleConfig, ScaleInput } from '@visx/scale';
import { AxisScale, AxisScaleOutput } from '@visx/axis';
import type { Dimensions } from 'types/dimensions';
import type { Accessors } from 'types/accessor';
import { ChartProvider } from './providers/chart-data-provider';
import { TooltipProvider } from './providers/tooltip-provider';
import { GridColumnLine } from './components/grid-column-line';

export type XYChartProps<Datum> = {
  children: React.ReactNode;
  title: string;
  desc: string;
  data: Datum[];
  accessors: Accessors<Datum, string, number | [number, number] | null>;
  margin: Dimensions['margin'];
  xScale: ScaleConfig<AxisScaleOutput>;
  yScale: ScaleConfig<AxisScaleOutput>;
};

export function XYChart<Datum>({
  children,
  data,
  accessors,
  margin,
  title,
  desc,
  xScale: xScaleConfig,
  yScale: yScaleConfig,
}: XYChartProps<Datum>) {
  const tooltip = useTooltip<unknown>();

  // Make the chart dynamically fit its container.
  const [ref, bounds] = useMeasure();
  const { width, height } = bounds;

  const dimensions = { width, height, margin };

  // Calculate bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = createScale({
    range: [0, xMax],
    domain: data.map(accessors.xAccessor) as [
      ScaleInput<AxisScale>,
      ScaleInput<AxisScale>,
    ],
    ...xScaleConfig,
  });

  const yScale = createScale({
    range: [yMax, 0],
    ...yScaleConfig,
  });

  return (
    <TooltipProvider value={tooltip}>
      <ChartProvider
        value={{
          dimensions,
          xMax,
          yMax,
          xScale,
          yScale,
          margin,
        }}
      >
        <svg
          ref={ref}
          // This will automatically scale the chart to fit its container
          width="100%"
          height="100%"
          viewBox={`0 0 ${width} ${height}`}
        >
          <title>{title}</title>
          <desc>{desc}</desc>
          {/* The svg elements are drawn from the left-top position within the viewport.
            When we have margin we need to shift all children over. This gives space for items
            that break out of the grid like axis labels and steps. */}
          <Group left={margin.left} top={margin.top}>
            <GridColumnLine />
            {children}
          </Group>
        </svg>
      </ChartProvider>
    </TooltipProvider>
  );
}
