import React, { FC } from 'react';
import { Spinner, GDSSpinnerProps } from 'components/spinner/spinner';
import { styled } from '../../../theme';
import type { GDSColor } from '../../../theme';

interface GDSSelectableLoaderProps extends GDSSpinnerProps {
  inputType: 'radio' | 'checkbox';
  color?: GDSColor;
}

const BaseSelectableLoader = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$onSurfaceBorderDefault',
  backgroundColor: '$inputBackgroundDefault',
  width: '$oneAndHalf',
  height: '$oneAndHalf',
  position: 'absolute',
  top: '0',
  bottom: '0',
  right: '0',
  pointerEvents: 'none',
});

export const SelectableLoaderWrapper = styled('span', {
  display: 'flex',
  position: 'relative',
});

export const SelectableLoader: FC<GDSSelectableLoaderProps> = ({
  inputType,
  loading,
  css,
  ...props
}: GDSSelectableLoaderProps) => (
  <BaseSelectableLoader
    className="GDS-selectable-loader"
    css={{
      borderRadius: inputType === 'radio' ? '$circle' : '$medium',
    }}
    {...props}
  >
    <Spinner loading={loading} size="$one" />
  </BaseSelectableLoader>
);
