export const HEALTH_ASSESSMENT_TYPE = 'health_assessment_lite';
export const PULSE_CHECK_TYPE = 'pulse_check';
export const HOME_URL = '/';

// This is the URLs that are coming from Contentful. They are used by
// all frontend platforms and are currently "hardcoded" in there.
// Don't worry about it having a "member" word, because we are mapping
// these strings to the correct module URLs in
// map-contentful-url-to-modules.utils.js
export const CONTENTFUL_REDIRECT_URL_HEALTH_JOURNEY =
  '/app/member/health-journey';
export const CONTENTFUL_REDIRECT_URL_HEALTH_PROFILE =
  '/app/member/health-profile';
