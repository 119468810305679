import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { type Traits, WidgetType } from '@leagueplatform/masonry-api';
import {
  HeadingText,
  ParagraphText,
  queryHelpers,
  StackItem,
  StackLayout,
  styled,
  useMediaQuery,
  UtilityText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import type { MasonryEngineNode } from '@leagueplatform/masonry-engine';
import { useConfigProperty } from '@leagueplatform/config';
import { normalizeInternalUrl } from '@leagueplatform/web-common';
import { PageHeaderBackground } from 'components/common/page-header-background/page-header-background';
import { InternalOrExternalLinkButton } from '@leagueplatform/web-common-components';
import { BodyTextHtmlTagOptions } from 'utils/body-text-html-tag-options';
import { useGetTraitsForMedia } from '../../hooks/use-get-traits-for-media.hook';

export interface HeroBannerProps {
  body?: string;
  buttonAction?: string;
  buttonLabel?: string;
  eyebrow?: string;
  heading: string;
  icon?: string;
  image?: string;
  imageAltText?: string;
  onCardClick: () => void;
  traits?: Traits;
}

export type HeroBannerWSNode = MasonryEngineNode<
  WidgetType.HERO_BANNER,
  HeroBannerProps
>;

const DecorativeIcon = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const HeroBanner = ({
  body,
  buttonAction = '',
  buttonLabel,
  eyebrow,
  heading,
  icon,
  image,
  imageAltText = '',
  onCardClick: onButtonClick,
  traits,
}: HeroBannerProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(150);
  const isLaptopDown = useMediaQuery(queryHelpers.down('laptop'));
  const pageHeaderBackgroundHeightPadding = isLaptopDown ? 60 : 112;
  const appLevelHeaderShape = useConfigProperty(
    'core.ui.components.pageHeader.shape',
  );
  const traitItems = useGetTraitsForMedia(traits);
  const isCurved =
    typeof traitItems?.curved === 'boolean'
      ? traitItems?.curved
      : appLevelHeaderShape === 'swoop';

  useLayoutEffect(() => {
    const reportWindowSize = () => {
      setContentHeight(contentRef?.current?.clientHeight ?? contentHeight);
    };
    if (contentHeight !== undefined)
      window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [contentHeight]);

  useEffect(() => {
    if (contentRef?.current?.clientHeight)
      setContentHeight(contentRef.current.clientHeight);
  }, []);

  return (
    <PageHeaderBackground
      height={`${contentHeight + pageHeaderBackgroundHeightPadding / 2}px`}
      pageHeaderBackgroundHeight={`${
        contentHeight + pageHeaderBackgroundHeightPadding
      }px`}
      imageHeight={traitItems?.imageHeight}
      pageHeaderBackgroundColor={traitItems?.backgroundColor}
      backgroundImage={image}
      curved={isCurved}
      imageAltText={imageAltText}
    >
      <StackLayout
        css={{ position: 'relative' }}
        horizontalAlignment="start"
        orientation="horizontal"
        spacing="$one"
        verticalAlignment="top"
      >
        <StackItem
          horizontalAlignment="start"
          css={{
            marginRight: traitItems?.includeSafeArea ? '160px' : 'none',
          }}
          verticalAlignment="top"
          ref={contentRef}
        >
          <StackLayout
            horizontalAlignment="start"
            orientation="horizontal"
            spacing="$oneAndHalf"
            verticalAlignment="top"
          >
            {icon && (
              <StackItem
                css={{
                  flex: '1 1 100%',
                  overflow: 'hidden',
                  aspectRatio: '1 / 1',
                  maxWidth: '64px',
                  '@mobile': {
                    order: 2,
                  },
                  '@laptop': {
                    maxWidth: '80px',
                  },
                  '@desktop': {
                    maxWidth: '96px',
                  },
                }}
              >
                <DecorativeIcon src={icon} alt="" data-testid="hero-icon" />
              </StackItem>
            )}
            {/* @TODO Explore a more systematic addition of classnames to select Masonry components */}
            <StackItem
              horizontalAlignment="start"
              verticalAlignment="top"
              className="masonry-hero-banner-heading-wrapper"
            >
              {eyebrow && <UtilityText size="eyebrow">{eyebrow}</UtilityText>}
              <HeadingText
                className="masonry-hero-banner-heading"
                level="1"
                size={{
                  '@initial': 'xl',
                  '@laptop': 'xxl',
                  '@desktop': 'xxl',
                }}
                css={{
                  marginTop: '$quarter',
                }}
              >
                {heading}
              </HeadingText>
              {body && (
                <ParagraphText
                  htmlStringOptions={BodyTextHtmlTagOptions}
                  css={{
                    typography: '$bodyTwo',
                    marginTop: '$threeQuarters',
                    color: '$onSurfaceTextPrimary',
                    '@laptop': {
                      typography: '$bodyOne',
                    },
                    '@desktop': {
                      typography: '$bodyOne',
                    },
                  }}
                >
                  {body}
                </ParagraphText>
              )}
              {buttonLabel && (
                <InternalOrExternalLinkButton
                  href={normalizeInternalUrl(buttonAction)}
                  onClick={onButtonClick}
                  priority="primary"
                  size="medium"
                  type="button"
                  css={{
                    marginTop: '$one',
                  }}
                >
                  {buttonLabel}
                </InternalOrExternalLinkButton>
              )}
              {image && (
                <VisuallyHidden
                  data-testid="hero-img"
                  role="img"
                  aria-label={imageAltText}
                />
              )}
            </StackItem>
          </StackLayout>
        </StackItem>
      </StackLayout>
    </PageHeaderBackground>
  );
};
