import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_USER_CONFIG_MESSAGE_TYPE = 'get_user_config';

export const getUserConfig = async (userId?: string) => {
  const info = {
    user_id: userId,
  };

  const params = {
    message_type: GET_USER_CONFIG_MESSAGE_TYPE,
    info,
  };

  return SocketAsFetch.fetch(params);
};
