import React from 'react';
import PropTypes from 'prop-types';
import {
  CoverImage,
  Box,
  HeadingThree,
  BodyTwo,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { HealthProgramsProgramDetailsModal } from './health-programs-program-details-modal.component';

const modalTitleId = 'max-enrolment-modal-title';

export const MaxEnrolmentModal = ({ onClose, image, title, description }) => {
  const { formatMessage } = useIntl();
  return (
    <HealthProgramsProgramDetailsModal
      onClose={onClose}
      aria-labelledby={modalTitleId}
    >
      <Box textAlign="center">
        <CoverImage src={image} size={140} marginY="oneAndHalf" />
        <HeadingThree marginBottom="half">{title}</HeadingThree>
        <BodyTwo marginBottom="two">{description}</BodyTwo>
        <PrimaryButton width="123px" onClick={() => onClose()}>
          {formatMessage({ id: 'OK' })}
        </PrimaryButton>
      </Box>
    </HealthProgramsProgramDetailsModal>
  );
};

MaxEnrolmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

MaxEnrolmentModal.defaultProps = {
  image: '',
  title: '',
  description: '',
};
