import { useMutation, useQueryClient } from 'react-query';
import { clamp, last } from 'lodash';
import { useHistory, useRouteMatch } from '@leagueplatform/routing';
import {
  completeHealthProfileQuestion,
  COMPLETE_HEALTH_PROFILE_QUESTION_MESSAGE_TYPE,
} from '@leagueplatform/assessment-api';
import { getSanitizedURL } from 'utils/get-sanitized-redirect-url.utils';
import { canRedirectUsingURL } from '../../utils';
import { HOME_URL } from '../../constants';

export const useCompleteHealthProfileQuestion = ({
  setCurrentIndex,
  currentIndex,
  moduleNavigation,
  moduleId,
  points,
  type,
  successScreen,
  redirectUrl,
  outcomesEnabled,
  onSuccess,
  onError,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { url } = useRouteMatch();
  return useMutation(
    ({
      moduleId: payloadModuleId,
      questionId,
      answers,
      campaignId,
      activityId,
    }) =>
      completeHealthProfileQuestion({
        moduleId: payloadModuleId,
        questionId,
        answers,
        campaignId,
        activityId,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          COMPLETE_HEALTH_PROFILE_QUESTION_MESSAGE_TYPE,
        ]);

        const {
          user_question: { id },
        } = data;
        const { isFromHome } = variables;

        onSuccess(data);

        setCurrentIndex(
          clamp(currentIndex + 1, 0, moduleNavigation.length - 1),
        );

        if (last(moduleNavigation).id === id) {
          if (outcomesEnabled) {
            const suffix = isFromHome ? '?b=1' : '';
            history.push(`${url}/outcomes${suffix}`);
          } else if (successScreen) {
            history.push({
              pathname: `${url}/success`,
              state: {
                points: {
                  [moduleId]: points,
                },
                activeModule: moduleId,
                assessmentType: type,
                successScreen,
              },
            });
          } else if (redirectUrl) {
            const formattedRedirectUrl = getSanitizedURL(redirectUrl);
            history.push(formattedRedirectUrl);
          } else if (canRedirectUsingURL(document.referrer)) {
            history.push(document.referrer);
          } else {
            history.push(HOME_URL);
          }
        }
      },
      onError: (error) => {
        const errorObj = JSON.parse(error.message);
        onError(errorObj);
      },
    },
  );
};
