import React, { ComponentProps } from 'react';
import { Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { useConfigProperty } from '@leagueplatform/core';

const DEFAULT_STYLES = {
  height: '140px',
  width: '20024px',
  backgroundColor: 'surface.background.tertiary',
};

const StyledPageHeaderBackground = genesisStyled(Flex)(
  ({
    pageHeader,
    height = DEFAULT_STYLES.height,
    pageHeaderBackgroundHeight = DEFAULT_STYLES.height,
  }) =>
    css({
      height,
      backgroundColor: 'transparent',
      '&:before': css({
        backgroundColor: `${
          pageHeader?.backgroundColor ?? DEFAULT_STYLES.backgroundColor
        }`,
        content: '""',
        zIndex: '-1',
        position: 'absolute',
        top: `calc(${pageHeaderBackgroundHeight} - ${DEFAULT_STYLES.width})`,
        left: `calc(50% - (${DEFAULT_STYLES.width} / 2))`,
        width: `${DEFAULT_STYLES.width}`,
        height: `${DEFAULT_STYLES.width}`,
        borderRadius: `${
          pageHeader?.shape === 'line' ? 0 : DEFAULT_STYLES.width
        }`,
      }),
    }),
);

type PageHeaderBackgroundProps = ComponentProps<
  typeof StyledPageHeaderBackground
> & {
  height?: string;
  pageHeaderBackgroundHeight?: string;
};

export const PageHeaderBackground = ({
  height,
  pageHeaderBackgroundHeight,
  ...rest
}: PageHeaderBackgroundProps) => {
  const pageHeaderConfig = useConfigProperty('core.ui.components.pageHeader');

  return (
    <StyledPageHeaderBackground
      height={height}
      pageHeaderBackgroundHeight={pageHeaderBackgroundHeight}
      pageHeader={pageHeaderConfig}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};
