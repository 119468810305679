import * as React from 'react';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import type { Accessors } from 'types/accessor';
import { useChart } from '../providers/chart-data-provider';
import getScaleBandwidth from '../utils/get-scale-bandwidth';
import { Shape, ShapeType, useShapeTheme } from '../primitives/Shape';

export type RangeBarProps<Datum, XResult> = {
  datum: Datum;
  shape: ShapeType;
  accessors: Accessors<Datum, XResult, [min: number, max: number] | number>;
  ariaLabel: string;
};

export function RangeBar<Datum, XResult>({
  accessors,
  datum,
  shape,
  ariaLabel,
}: RangeBarProps<Datum, XResult>) {
  const { xScale, yScale, yMax } = useChart();
  const shapeTheme = useShapeTheme({ shape });

  // Get datum x,y values
  const xValue = accessors.xAccessor(datum) as number;
  const yValue = accessors.yAccessor(datum);

  // Calculate x coordinates from values
  const xPoint = xScale(xValue) as number;
  const barX = xPoint;
  const barWidth = getScaleBandwidth(xScale);
  const barCenterPoint = barX + barWidth / 2;

  const radius = barWidth / 2;

  // Range value, [min, max]
  if (Array.isArray(yValue)) {
    const yPointMin = yScale(yValue[0]) as number;
    const yPointMax = yScale(yValue[1]) as number;

    // Calculate y positions
    const barTop = yMax - yPointMax;
    const barBottom = yMax - yPointMin;
    const barHeight = barTop - barBottom;
    const barY = yMax - barTop;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Group aria-label={ariaLabel} style={{ pointerEvents: 'none' }}>
        <Bar
          x={barX ?? 0}
          y={barY + radius}
          width={barWidth}
          height={barHeight - radius * 2}
          fill={shapeTheme.light}
        />
        <Shape
          shape={shape}
          x={barCenterPoint}
          y={barY + radius}
          radius={radius}
          fill={shapeTheme.dark}
        />
        <Shape
          shape={shape}
          x={barCenterPoint}
          y={barY + barHeight - radius}
          radius={radius}
          fill={shapeTheme.dark}
        />
      </Group>
    );
  }

  // Single value
  const yPoint = yScale(yValue) as number;

  return (
    <Shape
      shape={shape}
      x={barCenterPoint}
      y={yPoint}
      radius={radius}
      fill={shapeTheme.dark}
      ariaLabel={ariaLabel}
    />
  );
}
