import React, { forwardRef } from 'react';
import * as RadixTooltipPrimitive from '@radix-ui/react-tooltip';
import { Portal } from 'components/portal/portal';
import type {
  GDSAlign,
  GDSCustomizableComponent,
  GDSResponsiveProp,
  GDSSide,
  GDSSpacingValues,
} from '../../types';
import { styled } from '../../theme';
import { useResponsiveProp } from '../../hooks/use-responsive-prop';

export interface GDSTooltipContentProps
  extends Omit<RadixTooltipPrimitive.TooltipContentProps, 'align' | 'side'>,
    GDSCustomizableComponent {
  align?: GDSResponsiveProp<GDSAlign>;
  padding?: GDSResponsiveProp<GDSSpacingValues>;
  side?: GDSResponsiveProp<GDSSide>;
}

const BaseTooltipContent = styled(RadixTooltipPrimitive.Content, {
  typography: '$bodyTwo',
  color: '$onSurfaceTextPrimary',
  borderRadius: '$medium',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$onSurfaceBorderSubdued',
  backgroundColor: '$surfaceBackgroundPrimary',
  boxShadow: '$dropdown',
  zIndex: '$tooltip',
  '&[class*="GDS"]:focus, &[class*="GDS"]:active': {
    boxShadow: '$dropdown',
    outline: 'none',
    position: 'initial',
  },
});

export const TooltipContent = forwardRef<
  HTMLDivElement,
  GDSTooltipContentProps
>(
  (
    {
      align,
      avoidCollisions = true,
      children,
      className,
      collisionPadding,
      css,
      padding = '$half',
      side,
      sideOffset = 6,
      ...props
    },
    ref,
  ) => {
    const contentPadding = useResponsiveProp(padding);
    const responsiveSide = useResponsiveProp(side);
    const responsiveAlign = useResponsiveProp(align);

    return (
      <Portal>
        <BaseTooltipContent
          align={responsiveAlign}
          avoidCollisions={avoidCollisions}
          className={['GDS-tooltip-content', className].join(' ')}
          collisionPadding={collisionPadding}
          css={{
            ...css,
            ...(padding && {
              padding: `${contentPadding}`,
            }),
          }}
          ref={ref}
          side={responsiveSide}
          sideOffset={sideOffset}
          {...props}
        >
          {children}
        </BaseTooltipContent>
      </Portal>
    );
  },
);
