{
  "dragDescriptionKeyboard": "Pritisnite Enter da biste počeli povlačiti.",
  "dragDescriptionKeyboardAlt": "Pritisnite Alt + Enter za početak povlačenja.",
  "dragDescriptionLongPress": "Dugo pritisnite za početak povlačenja.",
  "dragDescriptionTouch": "Dvaput dodirnite da biste počeli povlačiti.",
  "dragDescriptionVirtual": "Kliknite da biste počeli povlačiti.",
  "dragItem": "Povucite stavku {itemText}",
  "dragSelectedItems": "Povucite {count, plural, one {# odabranu stavku} other {ovoliko odabranih stavki: #}}",
  "dragSelectedKeyboard": "Pritisnite Enter za povlačenje {count, plural, one {# odabrana stavka} other {# odabrane stavke}}.",
  "dragSelectedKeyboardAlt": "Pritisnite Alt + Enter za povlačenje {count, plural, one {# odabrana stavka} other {# odabrane stavke}}.",
  "dragSelectedLongPress": "Dugo pritisnite za povlačenje {count, plural, one {# odabrana stavka} other {# odabrane stavke}}.",
  "dragStartedKeyboard": "Počeli ste povlačiti. Pritisnite tipku tabulatora da biste došli do cilja ispuštanja, a zatim Enter da biste ispustili stavku ili Escape da biste prekinuli povlačenje.",
  "dragStartedTouch": "Počeli ste povlačiti. Dođite do cilja ispuštanja, a zatim dvaput dodirnite da biste ispustili stavku.",
  "dragStartedVirtual": "Počeli ste povlačiti. Dođite do cilja ispuštanja, a zatim kliknite ili pritisnite Enter da biste ispustili stavku.",
  "dropCanceled": "Povlačenje je prekinuto.",
  "dropComplete": "Ispuštanje je dovršeno.",
  "dropDescriptionKeyboard": "Pritisnite Enter da biste ispustili stavku. Pritisnite Escape da biste prekinuli povlačenje.",
  "dropDescriptionTouch": "Dvaput dodirnite da biste ispustili stavku.",
  "dropDescriptionVirtual": "Kliknite da biste ispustili stavku.",
  "dropIndicator": "pokazatelj ispuštanja",
  "dropOnItem": "Ispustite na stavku {itemText}",
  "dropOnRoot": "Ispustite na",
  "endDragKeyboard": "Povlačenje. Pritisnite Enter da biste prekinuli povlačenje.",
  "endDragTouch": "Povlačenje. Dvaput dodirnite da biste prekinuli povlačenje.",
  "endDragVirtual": "Povlačenje. Kliknite da biste prekinuli povlačenje.",
  "insertAfter": "Umetnite iza stavke {itemText}",
  "insertBefore": "Ispustite ispred stavke {itemText}",
  "insertBetween": "Umetnite između stavki {beforeItemText} i {afterItemText}"
}
