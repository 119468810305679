import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { subscribeToMessage } from '@leagueplatform/socket-as-fetch';
import { NodeResolver } from './components/node-resolver.component';
import { getMasonryEngineConfigContext } from './masonry-engine-config-context';
import type {
  AnyMasonryEngineNodeAction,
  MasonryEngineNode,
  MasonryEngineNodeAction,
} from './types/masonry-engine-node.types';
import { getMasonryEngineObservabilityContext } from './utils/masonry-engine-observability-content';
import { logError } from './utils/log';
import {
  createStateControllerStore,
  type MasonryEngineStateControllerStore,
} from './masonry-engine-state-controller';
import type { MasonryEngineDriver } from './types/masonry-engine-driver.types';
import {
  createMessagingController,
  type MasonryEngineMessagingController,
} from './masonry-engine-messaging-controller';

type MasonryServerActionWSMessage = {
  invoker: MasonryEngineNode['id'];
  action: AnyMasonryEngineNodeAction;
};

type MasonryEngineProps<
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
> = {
  driver: MasonryEngineDriver<Node, Action>;
};

/**
 * The entry point to rendering a MasonryEngine flow. takes a {@link MasonryEngineDriver `MasonryEngineDriver`} and communicates
 * with the driver to render the correct screen.
 */
export const MasonryEngine = <
  Node extends MasonryEngineNode,
  Action extends MasonryEngineNodeAction,
>({
  driver: Driver,
}: MasonryEngineProps<Node, Action>) => {
  const stateControllerStoreRef =
    useRef() as MutableRefObject<MasonryEngineStateControllerStore>;
  // See https://react.dev/reference/react/useRef#avoiding-recreating-the-ref-contents
  if (!stateControllerStoreRef.current) {
    stateControllerStoreRef.current = createStateControllerStore();
  }

  const messagingControllerRef =
    useRef() as MutableRefObject<MasonryEngineMessagingController>;
  // See https://react.dev/reference/react/useRef#avoiding-recreating-the-ref-contents
  if (!messagingControllerRef.current) {
    messagingControllerRef.current = createMessagingController();
  }

  useEffect(() => {
    const unsubscribe = subscribeToMessage<MasonryServerActionWSMessage>(
      'masonry_action',
      ({ invoker, action }) => {
        messagingControllerRef.current.sendMessage(invoker, {
          type: 'serverAction',
          payload: {
            action,
          },
        });
      },
    );

    return unsubscribe;
  }, []);

  const memoized = useMemo(() => {
    const MasonryEngineConfigProvider = getMasonryEngineConfigContext<
      Node,
      Action
    >().Provider;

    return (
      <ObservabilityErrorBoundary
        errorContext={getMasonryEngineObservabilityContext({
          tags: {
            errorName: `MasonryEngine - Error from Masonry Engine`,
          },
        })}
        onError={(err: Error) => {
          logError(`Error from Masonry Engine: ${err.message}`);
        }}
      >
        <Driver>
          {(node, nodeRenderers, actionController) => (
            <MasonryEngineConfigProvider
              value={{
                nodeRenderers,
                actionController,
                stateControllerStore: stateControllerStoreRef.current,
                messagingController: messagingControllerRef.current,
              }}
            >
              <NodeResolver node={node} />
            </MasonryEngineConfigProvider>
          )}
        </Driver>
      </ObservabilityErrorBoundary>
    );
  }, [Driver]);
  return memoized;
};
