export const getCurrentTopic = ({
  moduleNavigation = [],
  topics,
  currentIndex,
}) => {
  const currentTopicId = moduleNavigation[currentIndex]?.topicId;
  const currentTopicIndex = topics.findIndex(
    (topic) => topic.id === currentTopicId,
  );
  return topics[currentTopicIndex];
};
