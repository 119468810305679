import { leagueFetch } from '@leagueplatform/league-fetch';
import { NEXT_QUESTIONNAIRE_PATH } from './constants/messaging-requests.constants';
import { PostQuestionnaireResponseData } from './types/messaging.types';

export const postQuestionnaireNextNode = async (
  nextNode: string,
): Promise<PostQuestionnaireResponseData> => {
  const response = await leagueFetch(`${NEXT_QUESTIONNAIRE_PATH}`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'next-request',
        attributes: {
          nodeId: nextNode,
        },
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`postQuestionnaireNextNode error: ${response.status}`);
  }
  return response.json();
};
