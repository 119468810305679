import { lowerCase } from 'lodash';

export const getCurrencyForCountry = (countryCode) => {
  switch (lowerCase(countryCode)) {
    case 'us':
      return 'USD';
    case 'ie':
      return 'EUR';
    case 'ca':
    default:
      return 'CAD';
  }
};

export const getSymbolForCurrency = (currency) =>
  currency === 'EUR' ? '€' : '$';

const GST = 'GST';
const HST = 'HST';
const PST = 'PST';
const QST = 'QST';
const RST = 'RST';

export const TAX_TYPES = [GST, HST, PST, QST, RST];

export const TAXES_BY_PROVINCE = {
  AB: [GST],
  BC: [GST, PST],
  MB: [GST, RST],
  NB: [HST],
  NL: [HST],
  NS: [HST],
  NT: [GST],
  NU: [GST],
  ON: [HST],
  PE: [HST],
  QC: [GST, QST],
  SK: [GST, PST],
  YT: [GST],
};
