import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StatusBadge, UtilityText } from '@leagueplatform/genesis-core';
import {
  SHORT_DATE_AND_TIME_FORMAT,
  SHORT_DATE_FORMAT,
} from '@leagueplatform/web-common';
import { useGetChallengeByIdData } from 'pages/challenge-landing-page/hooks/use-get-challenge-by-id-data.hook';
import {
  isEnrollmentOpen,
  isBeforeEnrollmentPeriod,
} from 'utils/enrollment-period-status.util';
import { CHALLENGE_STATUS } from 'constants/challenges.constants';
import { useGetUserProfile } from 'hooks/use-get-user-profile.hook';

export const EnrollmentStatusInformation = () => {
  const { formatMessage, formatDate } = useIntl();
  const { data: challengeData } = useGetChallengeByIdData();
  const { data: userData } = useGetUserProfile();
  const { default_timezone: defaultTimezone } = userData || {};

  const {
    challengeType,
    endEnroll,
    startEnroll,
    status,
    endDate,
    totalActivities,
    userChallenge,
  } = challengeData!.data;
  const { UPCOMING, ACTIVE, CLOSED } = CHALLENGE_STATUS;

  // Display booleans used for different badges/statuses:
  const isSoloChallenge = challengeType === 'solo';
  const isNowBeforeStartEnrollDate = isBeforeEnrollmentPeriod(
    startEnroll,
    defaultTimezone,
  );
  const enrollmentOpen = isEnrollmentOpen(
    startEnroll,
    endEnroll,
    defaultTimezone,
  );
  const userIsEnrolled = userChallenge?.data?.enrollmentStatus === 'enrolled';
  const allActivitiesCompleted =
    userChallenge?.data?.progress?.completedActivities === totalActivities;
  const challengeIsClosed = status === CLOSED;
  const dateFormatOptions = {
    ...SHORT_DATE_FORMAT,
    timeZone: defaultTimezone,
  };
  const dateAndTimeFormatOptions = {
    ...SHORT_DATE_AND_TIME_FORMAT,
    timeZone: defaultTimezone,
  };
  switch (true) {
    // states for when a user is enrolled:
    case isSoloChallenge && userIsEnrolled && !challengeIsClosed:
    case userIsEnrolled && status === UPCOMING:
      return (
        <StatusBadge
          label={formatMessage({ id: 'JOINED' })}
          status="success"
          showLight={false}
        />
      );
    case userIsEnrolled && status === ACTIVE:
      return (
        <StatusBadge label={formatMessage({ id: 'ACTIVE' })} status="success" />
      );
    case userIsEnrolled && challengeIsClosed && allActivitiesCompleted:
      return (
        <StatusBadge
          label={formatMessage(
            { id: 'COMPLETED_ON_DATE' },
            {
              date: formatDate(endDate, dateFormatOptions),
            },
          )}
          status="neutral"
        />
      );
    case userIsEnrolled && challengeIsClosed:
      return (
        <StatusBadge
          label={formatMessage(
            { id: 'ENDED_ON_DATE' },
            {
              date: formatDate(endDate, dateFormatOptions),
            },
          )}
          status="error"
        />
      );

    // states for when a user is not enrolled
    case isSoloChallenge:
      return (
        <StatusBadge
          label={formatMessage({ id: 'AVAILABLE' })}
          status="neutral"
          showLight={false}
        />
      );
    case isNowBeforeStartEnrollDate:
      return (
        <UtilityText>
          {formatMessage(
            { id: 'SIGN_UP_BEGINS_DATE' },
            {
              date: formatDate(startEnroll, dateAndTimeFormatOptions),
            },
          )}
        </UtilityText>
      );
    case enrollmentOpen:
      return (
        <UtilityText>
          {formatMessage(
            { id: 'SIGN_UP_BY_DATE' },
            {
              date: formatDate(endEnroll, dateAndTimeFormatOptions),
            },
          )}
        </UtilityText>
      );
    case !challengeIsClosed:
      return (
        <UtilityText>
          {formatMessage({
            id: 'SIGN_UP_PERIOD_PASSED',
          })}
        </UtilityText>
      );
    case challengeIsClosed:
    default:
      return (
        <StatusBadge
          label={formatMessage(
            { id: 'ENDED_ON_DATE' },
            {
              date: formatDate(endDate, dateFormatOptions),
            },
          )}
          status="error"
        />
      );
  }
};
