import { useMutation, UseMutationOptions } from 'react-query';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
/**
 * @name useLeagueWSMutation
 * @desc A wrapper around React Queries useMutation
 * @param { string } message_type the Websocket message_type of the request being made
 * @param { object } options all options available in React Query useMutation
 */

export function useLeagueWSMutation<TData>(
  message_type: string,
  options?: UseMutationOptions<TData, unknown, TData>,
) {
  return useMutation<TData, unknown, TData>(
    async (info) => {
      if (info) {
        return SocketAsFetch.fetch({
          message_type,
          info,
        });
      }
      throw new Error('Please provide your Websocket message information');
    },
    // consumers can leverage any of RQ options in their mutation
    options,
  );
}
