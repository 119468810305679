import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { truncateWithEllipses } from '@leagueplatform/web-common';
import {
  Flex,
  Box,
  Overline,
  HeadingThree,
  BodyOne,
  genesisStyled,
  Link,
} from '@leagueplatform/genesis-commons';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { BENEFIT } from '../../constants/health-solution-types.constants';

interface TopHealthSolutionProps {
  placeholderImage?: string;
  imageSrc?: string;
  overline?: string;
  heading?: string;
  description?: string;
  onCardClick: () => void;
  type: string;
}

const InteractiveBox = genesisStyled(Box)`
  cursor: pointer;
`;

const SolutionOverline = genesisStyled(Overline)`
  letter-spacing: 0.6px;
`;

/**
 * This component can be passed in different types of content, thus
 * should handle each redirection as follows:
 * 1) Employer solution -> go to employer benefit card in wallet
 * 2) Marketplace offering -> go to marketplace details page for that offering
 * 3) Health program -> go to health program details page
 * 4) Content -> a generic type that can link anywhere; internally or externally
 *
 * There are different content types, this component only renders
 * sections if it has data populating it.
 *
 * Also this component gradually shrink in banner layout until it changes to stacked
 * layout as the screen gets smaller.
 */
export const TopHealthSolution = ({
  placeholderImage, // Only for testing/storybook
  imageSrc,
  overline,
  heading,
  description,
  onCardClick,
  type,
}: TopHealthSolutionProps) => {
  const hasImage = imageSrc || placeholderImage;
  const shouldShowOverline = type === BENEFIT && overline;
  const { formatMessage } = useIntl();

  return (
    <InteractiveBox
      data-testid="top-health-solution"
      tabIndex={0}
      role="button"
      onClick={onCardClick}
      maxWidth="1000px"
      minWidth={{ _: '100%', tablet: '665px' }}
    >
      <Flex
        flexDirection={{ _: 'column', tablet: 'row' }}
        height={{ _: 'auto', tablet: '222px' }}
      >
        {hasImage && (
          <Box
            data-testid="top-health-solution-img"
            backgroundImage={`url(${imageSrc || placeholderImage})`}
            backgroundSize="cover"
            backgroundColor={
              type === BENEFIT ? 'surface.background.secondary' : undefined
            }
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            height={{ _: '184px', tablet: '222px' }}
            flex={{ _: '0 0 auto', tablet: '0 0 394px' }}
            width={{ _: '327px', tablet: '394px' }}
            borderRadius="medium"
          />
        )}
        <Box
          paddingX={{ _: 'none', tablet: 'two' }}
          paddingTop={{ _: 'one', tablet: 'none' }}
          paddingBottom={{ _: 'one', tablet: 'two' }}
          height="100%"
          width={{ _: '327px', tablet: '100%' }}
        >
          {shouldShowOverline && (
            <SolutionOverline
              display="inline-block"
              backgroundColor="decorative.brand.primary.pastel"
              color="decorative.brand.primary.darkest"
              paddingX="half"
              paddingY="quarter"
              marginBottom={{ _: 'quarter', tablet: 'half' }}
              borderRadius="small"
            >
              {overline}
            </SolutionOverline>
          )}
          {heading && (
            <HeadingThree marginBottom={{ _: 'quarter', tablet: 'half' }}>
              {heading}
            </HeadingThree>
          )}
          {description && (
            <BodyOne
              color="onSurface.text.subdued"
              marginBottom={{ _: 'quarter', tablet: 'one' }}
            >
              {truncateWithEllipses(description, 120)}
            </BodyOne>
          )}
          <Flex alignItems="center">
            {/* TODO: Fix a11y issue when page is redone */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link as="p" marginRight="one">
              {formatMessage({ id: 'VIEW_MORE_DETAILS_SENTENCE_CASE' })}
            </Link>
            <Icon
              icon={ICONS.RIGHT_CHEVRON}
              size="12px"
              stroke="interactive.action.primary"
            />
          </Flex>
        </Box>
      </Flex>
    </InteractiveBox>
  );
};
