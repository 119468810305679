import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const SUBMIT_BENEFIT_SELECTION_MESSAGE_TYPE = 'submit_benefit_selection';

export const submitBenefitSelection = async (
  groupId: string,
  allocateImmediately?: boolean,
  planId?: string,
) => {
  // planId was an option that was available in service,
  // However could not find an instance of it being passed
  const params = {
    message_type: SUBMIT_BENEFIT_SELECTION_MESSAGE_TYPE,
    info: {
      group_id: groupId,
      allocate_immediately: !!allocateImmediately,
      plan_id: planId,
    },
  };

  return SocketAsFetch.fetch(params);
};
