import React from 'react';
import {
  QuietButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import backArrow from 'assets/back-arrow.svg';
import { Arrow, ArrowButtonProps } from '../arrow-button.component';

const StyledQuietButton = genesisStyled(QuietButton)(({ hidden, hoverColor }) =>
  css({
    border: 'none',
    display: 'block',
    paddingY: '19px',
    paddingX: '18px',
    '&:hover, &:focus': {
      backgroundColor: hoverColor,
    },
    visibility: `${hidden ? 'hidden' : 'visible'}`,
    opacity: `${hidden ? 0 : 1}`,
  }),
);

const StyledArrow = genesisStyled(Arrow)(
  css({
    display: 'block',
  }),
);

export const BackArrowButton = ({
  label,
  onClick,
  hidden = false,
  backgroundColor = 'decorative.brand.primary.pastel',
  hoverColor = 'decorative.brand.primary.brightest',
}: ArrowButtonProps) => (
  <StyledQuietButton
    hidden={hidden}
    onClick={onClick}
    transition="background-color 200ms ease-in-out, opacity 0.2s ease-in-out"
    hoverColor={hoverColor}
    backgroundColor={backgroundColor}
    borderRadius="circle"
    aria-label={label}
  >
    <StyledArrow aria-hidden src={backArrow} alt={label} />
  </StyledQuietButton>
);
