import { QuestionnaireItem } from 'fhir/r4';

export const handleItemTypeBoolean = (
  item: QuestionnaireItem,
): QuestionnaireItem => item;

export const handleAnswerTypeBoolean = (value: string) => [
  {
    valueBoolean: value === 'true',
  },
];
