import { ErrorContext } from '@leagueplatform/observability';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const postWidgetDismiss = async (
  messageType: string,
  params: { [x: string]: string },
  errorContext?: ErrorContext,
) =>
  SocketAsFetch.fetch(
    {
      message_type: messageType,
      info: {
        ...params,
      },
    },
    {
      errorContext,
    },
  );
