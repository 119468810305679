import { useEffect, useState } from 'react';

export function useWindowFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  function windowFocused() {
    setIsWindowFocused(true);
  }

  function windowUnfocused() {
    setIsWindowFocused(false);
  }

  useEffect(() => {
    window.addEventListener('focus', windowFocused);
    window.addEventListener('blur', windowUnfocused);

    return () => {
      window.removeEventListener('focus', windowFocused);
      window.removeEventListener('blur', windowUnfocused);
    };
  }, []);

  return { isWindowFocused };
}
