import { UserHealthActivityStatus } from '@leagueplatform/health-journey-common';

export const GetActivityGroup = (status?: UserHealthActivityStatus) => {
  let isCompleted = false;
  let isActive = false;
  let isMissed = false;

  switch (status) {
    case UserHealthActivityStatus.EXPIRED:
    case UserHealthActivityStatus.REMOVED:
      isMissed = true;
      break;

    case UserHealthActivityStatus.COMPLETED:
      isCompleted = true;
      break;

    case UserHealthActivityStatus.ACTIVE:
    case UserHealthActivityStatus.UPCOMING:
      isActive = true;
      break;

    default:
  }

  return { isCompleted, isActive, isMissed };
};
