import * as React from 'react';
import { HealthProfilePageWrapper } from '@leagueplatform/health-profile-common';
import {
  css,
  Flex,
  genesisStyled,
  HeadingOne,
  Text,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { MetricType } from '@leagueplatform/dashboard-api';
import { useHealthSources } from 'hooks/use-get-health-sources.hook';
import { useInfiniteHealthMetricsV2 } from 'hooks/use-get-health-metrics-v2.hook';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { usePageViewAnalytics } from '@leagueplatform/analytics';
import { PRODUCT_AREA, SCREEN_NAMES } from 'constants/analytics';
import { ErrorPanel } from 'components/error-panel';
import { DataSourceItem } from './data-source-item';

type AllRecordedDataProps = {
  match: {
    params: {
      dataType: MetricType;
    };
  };
};

type DataSourcesItemsProps = {
  isRefetching: boolean;
  refetch: () => void;
  isError: boolean;
  children: React.ReactNode;
};

const DataSourcesWidth = '486px';
const DataTypeText = genesisStyled('span')(
  css({
    fontWeight: 'bold',
  }),
);

function DataSourcesItems({
  children,
  isError,
  refetch,
  isRefetching,
}: DataSourcesItemsProps) {
  if (isError) {
    return <ErrorPanel isRefetching={isRefetching} onRetry={refetch} />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

function DataSourcesPageWrapper({ children }: React.PropsWithChildren<{}>) {
  const { formatMessage } = useIntl();
  return (
    <HealthProfilePageWrapper
      title={formatMessage({
        id: 'DATA_SOURCES',
      })}
    >
      {children}
    </HealthProfilePageWrapper>
  );
}

export function DataSources({
  match: {
    params: { dataType },
  },
}: AllRecordedDataProps) {
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.DASHBOARD,
    screen_name: SCREEN_NAMES.VIEW_DATA_SOURCES,
    metric_type: dataType,
  });
  const {
    isLoading,
    isRefetching,
    isError: isHealthSourcesError,
    data: healthSources,
    refetch,
  } = useHealthSources({
    filter: { type: dataType },
  });
  const {
    isLoading: isLoadingHealthMetrics,
    isError: isErrorHealthMetrics,
    data: healthMetrics,
  } = useInfiniteHealthMetricsV2({
    filter: {
      type: dataType,
    },
    fields: { type: ['type', 'value', 'source', 'unit', 'timestamp'] },
  });

  if (isLoading || isLoadingHealthMetrics) {
    return <LoadingIndicator />;
  }
  const isHealthPlanIncluded =
    healthMetrics?.pages[0].data[0].configs?.data.some(
      (metricConfig) => metricConfig.source === 'health_plan',
    );
  const isSelfReportedIncluded =
    healthMetrics?.pages[0].data[0].configs?.data.some(
      (metricConfig) =>
        metricConfig.source === ('self_reported' || 'self_reported_no_input'),
    );

  const noSourceData = !isHealthPlanIncluded && !healthSources?.data.length;

  const isOnlySelfReported = isSelfReportedIncluded && noSourceData;

  const metricDisplayName = healthMetrics?.pages[0].data[0].metricDisplayName;
  const isError =
    isHealthSourcesError && isErrorHealthMetrics && !isHealthPlanIncluded;

  // if healthSources has an error && health plan is not included && self reported is not included, we display the error panel
  if (isError || isErrorHealthMetrics) {
    return (
      <DataSourcesPageWrapper>
        <ErrorPanel isRefetching={isRefetching} onRetry={refetch} />
      </DataSourcesPageWrapper>
    );
  }

  return (
    <DataSourcesPageWrapper>
      <Flex flexDirection="column" maxWidth={DataSourcesWidth}>
        <HeadingOne as="h2" paddingBottom="oneAndHalf" paddingTop="oneAndHalf">
          {metricDisplayName}
        </HeadingOne>
        <Text paddingBottom="oneAndHalf">
          {formatMessage({
            id: 'SOURCES_CAN_UPDATE',
          })}{' '}
          <DataTypeText>{metricDisplayName}</DataTypeText>.
        </Text>
        <Text paddingBottom="oneAndHalf">
          {formatMessage({
            id: 'WHEN_MULTIPLE_SOURCES_AVAILABLE',
          })}
        </Text>
        <DataSourcesItems
          isError={isError}
          refetch={refetch}
          isRefetching={isRefetching}
        >
          {isOnlySelfReported ? (
            <DataSourceItem
              dataSource={{
                type: dataType,
                device: 'no_source_data',
              }}
            />
          ) : (
            <>
              {healthSources?.data.map((healthSource) => (
                <DataSourceItem dataSource={healthSource} />
              ))}
              {isHealthPlanIncluded && (
                <DataSourceItem
                  dataSource={{
                    type: dataType,
                    device: 'health_plan',
                  }}
                />
              )}
            </>
          )}
        </DataSourcesItems>
      </Flex>
    </DataSourcesPageWrapper>
  );
}
