import React from 'react';
import { useLocation } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
  PRODUCT_AREA,
} from '@leagueplatform/analytics';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  JOURNEY_ROUTES,
  SCREEN_NAMES as HEALTH_JOURNEY_SCREEN_NAMES,
} from '@leagueplatform/health-journey-common';
import {
  SidebarCard,
  GenesisRouterLink,
} from '@leagueplatform/web-common-components';
import {
  HeadingText,
  ParagraphText,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useRewardsSidebar } from '../../hooks/use-rewards-sidebar.hook';

// eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
function ErrorMessage() {
  const { formatMessage } = useIntl();
  return (
    <ParagraphText
      css={{
        typography: '$subtitleTwo',
        fontWeight: 'normal',
        paddingRight: '$oneAndHalf',
        marginY: '$one',
      }}
    >
      {formatMessage(
        { id: 'WERE_HAVING_TROUBLE' },
        {
          custCareLink: (
            <TextAction
              href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
              size="medium"
            >
              {formatMessage({ id: 'CONTACT_CUSTOMER_CARE' })}
            </TextAction>
          ),
        },
      )}
    </ParagraphText>
  );
}

function useRedeemDescription() {
  const { currentTierRewards, nextTierRewards, userRewardPoints } =
    useRewardsSidebar();
  const { formatMessage, formatNumber } = useIntl();

  if ((currentTierRewards?.league_points as number) > 0) {
    return formatMessage(
      { id: 'REDEEMABLE' },
      {
        value: formatNumber(currentTierRewards?.league_credit?.F || 0, {
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          currency: currentTierRewards?.league_credit?.C || 'CAD',
          minimumFractionDigits: 0,
        }),
      },
    );
  }

  return formatMessage(
    { id: 'REDEEM_NEXT_TIER_POINTS' },
    {
      points: formatNumber(
        (nextTierRewards?.league_points || 0) - (userRewardPoints || 0),
        { style: 'decimal' },
      ),
      value: formatNumber(nextTierRewards?.league_credit?.F || 0, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: nextTierRewards?.league_credit?.C || 'CAD',
        minimumFractionDigits: 0,
      }),
    },
  );
}

export function RewardsSidebarCard() {
  const { formatMessage, formatNumber } = useIntl();
  const { pathname } = useLocation();
  const description = useRedeemDescription();
  const { hasError, ready, userRewardPoints } = useRewardsSidebar();

  // If backend call succeeds and doesn't return data, don't render the widget
  if (!hasError && userRewardPoints === undefined) {
    return null;
  }

  return ready ? (
    <SidebarCard>
      <HeadingText level="1" size="md" css={{ color: '$onSurfaceTextSubdued' }}>
        {formatMessage({ id: 'POINTS_BALANCE' })}
      </HeadingText>
      {hasError ? (
        <ErrorMessage />
      ) : (
        <>
          <HeadingText level="3" size="xxl" css={{ marginY: '$quarter' }}>
            {/* TODO remove comment when `tsc-alias` is in */}
            {formatNumber(userRewardPoints!, { style: 'decimal' })}
          </HeadingText>
          <ParagraphText
            css={{
              typography: '$subtitleTwo',
              color: '$onSurfaceTextSubdued',
              fontWeight: 'normal',
              paddingRight: '$oneAndHalf',
              marginBottom: '$one',
            }}
          >
            {description}
          </ParagraphText>
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
      <GenesisRouterLink
        to="/member/get-rewarded"
        onClick={() =>
          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
            product_area: PRODUCT_AREA.REWARDS,
            sub_product_area: SUB_PRODUCT_AREA.POINTS,
            screen_name:
              (pathname === JOURNEY_ROUTES.journeyHome() &&
                HEALTH_JOURNEY_SCREEN_NAMES.ACTIVITY_TIMELINE) ||
              (pathname === getModulePath(LEAGUE_MODULE_NAMES.healthProfile) &&
                SCREEN_NAMES.HEALTH_PROFILE),
            detail: 'view points info',
          })
        }
      >
        {formatMessage({ id: 'VIEW_YOUR_REWARDS' })}
      </GenesisRouterLink>
    </SidebarCard>
  ) : null;
}
